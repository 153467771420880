import actionTypes from "../actionTypes";
import { getUserDataWithToken } from "../../utils/helperFunctions";

const { token } = getUserDataWithToken();

const initialState = {
  isAuthenticated: !!token,
  userData: {},
  isLoading: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.AUTH_LOGIN_DONE:
    case actionTypes.FETCH_MANAGER_DATA_DONE:
      return {
        ...state,
        isAuthenticated: true,
        userData: {
          name: action.payload.name,
          email: action.payload.email,
          id: action.payload.id,
        },
        isLoading: false,
      };

    case actionTypes.AUTH_LOGIN_FAIL:
    case actionTypes.FETCH_MANAGER_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        userData: null,
      };

    default:
      return state;
  }
}

export default authReducer;
