export const EVALUATION_STEP_1_FORM = "evaluation_step_1_form";
export const EVALUATION_STEP_2_FORM = "evaluation_step_2_form";
export const EVALUATION_STEP_3_1_FORM = "evaluation_step_3_1_form";
export const EVALUATION_STEP_3_2_FORM = "evaluation_step_3_2_form";
export const EVALUATION_STEP_3_3_FORM = "evaluation_step_3_3_form";
export const EVALUATION_STEP_3_4_FORM = "evaluation_step_3_4_form";
export const EVALUATION_STEP_3_5_FORM = "evaluation_step_3_5_form";
export const EVALUATION_STEP_4_0_FORM = "evaluation_step_4_0_form";
export const EVALUATION_STEP_4_1_FORM = "evaluation_step_4_1_form";
export const EVALUATION_STEP_4_2_FORM = "evaluation_step_4_2_form";
export const EVALUATION_STEP_4_3_FORM = "evaluation_step_4_3_form";
export const EVALUATION_STEP_4_4_FORM = "evaluation_step_4_4_form";
export const EVALUATION_STEP_4_5_FORM = "evaluation_step_4_5_form";
export const NEW_CLIENT_FORM = "new_client_form";

export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Maharashtra",
  "Madhya Pradesh",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Telangana",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman & Nicobar (UT)",
  "Chandigarh (UT)",
  "Dadra & Nagar Haveli and Daman & Diu (UT)",
  "Delhi [National Capital Territory (NCT)]",
  "Jammu & Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
];

export const banking_settlement_rule = [
  "Peak to Off-peak",
  "Peak to Normal to Off-peak",
  "Peak and Normal to Off-peak",
];

export const peak_off_peak = ["Peak", "Normal", "Off-peak"];

export const hours = [
  "0:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const solarData = [
  { panels: 2, price: 2000 },
  { panels: 5, price: 3000 },
  { panels: 10, price: 4000 },
  { panels: 20, price: 5000 },
  { panels: 30, price: 6000 },
];

export const windData = [
  { panels: 2, price: 1000 },
  { panels: 5, price: 1500 },
  { panels: 10, price: 2500 },
  { panels: 20, price: 3500 },
  { panels: 30, price: 4500 },
];

export const tableRowData = [
  { ClientId: 1, ClientName: "Arpit", OldReport: "1", NewReport: "1" },
  { ClientId: 2, ClientName: "John", OldReport: "2", NewReport: "2" },
  { ClientId: 3, ClientName: "Alice", OldReport: "3", NewReport: "3" },
  { ClientId: 4, ClientName: "Bob", OldReport: "4", NewReport: "4" },
  { ClientId: 5, ClientName: "Eve", OldReport: "5", NewReport: "5" },
  { ClientId: 6, ClientName: "Charlie", OldReport: "6", NewReport: "6" },
  { ClientId: 7, ClientName: "David", OldReport: "7", NewReport: "7" },
  { ClientId: 8, ClientName: "Fiona", OldReport: "8", NewReport: "8" },
  { ClientId: 9, ClientName: "Grace", OldReport: "9", NewReport: "9" },
  { ClientId: 10, ClientName: "Heidi", OldReport: "10", NewReport: "10" },
  { ClientId: 11, ClientName: "Ivy", OldReport: "11", NewReport: "11" },
  { ClientId: 12, ClientName: "Jack", OldReport: "12", NewReport: "12" },
  { ClientId: 13, ClientName: "Karen", OldReport: "13", NewReport: "13" },
  { ClientId: 14, ClientName: "Leo", OldReport: "14", NewReport: "14" },
  { ClientId: 15, ClientName: "Mallory", OldReport: "15", NewReport: "15" },
];

export const pvData = [
  {
    "timestamp": "01/04/24 1:00",
    "demand": 30076.07553,
    "solar_generated": 0,
    "wind_generated": 69.27363321,
    "marketplace_to_demand": 193.4696137,
    "grid_to_demand": 30708.53122,
    "govt_battery_charge": 76.73148284
  },
  {
    "timestamp": "01/04/24 2:00",
    "demand": 36288.77695,
    "solar_generated": 0,
    "wind_generated": 70.47434806,
    "marketplace_to_demand": 161.0774525,
    "grid_to_demand": 33397.23,
    "govt_battery_charge": 52.7831013
  },
  {
    "timestamp": "01/04/24 3:00",
    "demand": 32577.84243,
    "solar_generated": 0,
    "wind_generated": 80.1682927,
    "marketplace_to_demand": 41.05724188,
    "grid_to_demand": 32399.11328,
    "govt_battery_charge": 48.65652409
  },
  {
    "timestamp": "01/04/24 4:00",
    "demand": 36363.75664,
    "solar_generated": 0,
    "wind_generated": 73.77317069,
    "marketplace_to_demand": 164.0461714,
    "grid_to_demand": 30984.11688,
    "govt_battery_charge": 20.59414195
  },
  {
    "timestamp": "01/04/24 5:00",
    "demand": 34629.4892,
    "solar_generated": 0,
    "wind_generated": 78.73997706,
    "marketplace_to_demand": 57.44738729,
    "grid_to_demand": 36342.99052,
    "govt_battery_charge": 72.14858708
  },
  {
    "timestamp": "01/04/24 6:00",
    "demand": 30104.86673,
    "solar_generated": 17.04974163,
    "wind_generated": 54.00107333,
    "marketplace_to_demand": 188.6362436,
    "grid_to_demand": 35995.1888,
    "govt_battery_charge": 90.69222122
  },
  {
    "timestamp": "01/04/24 7:00",
    "demand": 30252.03823,
    "solar_generated": 7.625285178,
    "wind_generated": 79.01497056,
    "marketplace_to_demand": 133.8377909,
    "grid_to_demand": 30492.90977,
    "govt_battery_charge": 63.31191029
  },
  {
    "timestamp": "01/04/24 8:00",
    "demand": 31919.26285,
    "solar_generated": 17.33231208,
    "wind_generated": 63.16076586,
    "marketplace_to_demand": 52.85674718,
    "grid_to_demand": 34105.09656,
    "govt_battery_charge": 11.93977495
  },
  {
    "timestamp": "01/04/24 9:00",
    "demand": 30877.13245,
    "solar_generated": 18.72867662,
    "wind_generated": 59.40501635,
    "marketplace_to_demand": 164.287061,
    "grid_to_demand": 35970.9872,
    "govt_battery_charge": 98.29291734
  },
  {
    "timestamp": "01/04/24 10:00",
    "demand": 34306.94687,
    "solar_generated": 20.43805176,
    "wind_generated": 58.59332748,
    "marketplace_to_demand": 47.24696517,
    "grid_to_demand": 30430.02646,
    "govt_battery_charge": 72.32429951
  },
  {
    "timestamp": "01/04/24 11:00",
    "demand": 30838.80246,
    "solar_generated": 13.57423954,
    "wind_generated": 72.057919,
    "marketplace_to_demand": 144.8189828,
    "grid_to_demand": 30634.7856,
    "govt_battery_charge": 70.77615726
  },
  {
    "timestamp": "01/04/24 12:00",
    "demand": 33863.70202,
    "solar_generated": 7.546950346,
    "wind_generated": 72.69231555,
    "marketplace_to_demand": 31.51417809,
    "grid_to_demand": 30115.55436,
    "govt_battery_charge": 32.75095396
  },
  {
    "timestamp": "01/04/24 13:00",
    "demand": 30582.12942,
    "solar_generated": 8.44827316,
    "wind_generated": 67.03235813,
    "marketplace_to_demand": 1.898206883,
    "grid_to_demand": 34120.0624,
    "govt_battery_charge": 70.08735627
  },
  {
    "timestamp": "01/04/24 14:00",
    "demand": 36092.6203,
    "solar_generated": 23.75406874,
    "wind_generated": 65.58682258,
    "marketplace_to_demand": 58.81426271,
    "grid_to_demand": 34257.0504,
    "govt_battery_charge": 1.720100737
  },
  {
    "timestamp": "01/04/24 15:00",
    "demand": 34347.10054,
    "solar_generated": 22.75203096,
    "wind_generated": 76.15400816,
    "marketplace_to_demand": 104.4409666,
    "grid_to_demand": 30307.68985,
    "govt_battery_charge": 49.4986802
  },
  {
    "timestamp": "01/04/24 16:00",
    "demand": 33586.18205,
    "solar_generated": 7.239467898,
    "wind_generated": 74.58042008,
    "marketplace_to_demand": 26.85873996,
    "grid_to_demand": 36262.90833,
    "govt_battery_charge": 30.84034919
  },
  {
    "timestamp": "01/04/24 17:00",
    "demand": 32327.51362,
    "solar_generated": 14.95125617,
    "wind_generated": 63.70951307,
    "marketplace_to_demand": 90.28564904,
    "grid_to_demand": 31596.8852,
    "govt_battery_charge": 91.52175582
  },
  {
    "timestamp": "01/04/24 18:00",
    "demand": 34861.67064,
    "solar_generated": 0,
    "wind_generated": 55.33307295,
    "marketplace_to_demand": 116.1096817,
    "grid_to_demand": 32743.46968,
    "govt_battery_charge": 81.36612607
  },
  {
    "timestamp": "01/04/24 19:00",
    "demand": 34402.83334,
    "solar_generated": 0,
    "wind_generated": 74.09135159,
    "marketplace_to_demand": 109.6446394,
    "grid_to_demand": 33998.09202,
    "govt_battery_charge": 97.93130094
  },
  {
    "timestamp": "01/04/24 20:00",
    "demand": 34568.23737,
    "solar_generated": 0,
    "wind_generated": 63.95928878,
    "marketplace_to_demand": 56.04480406,
    "grid_to_demand": 32636.1602,
    "govt_battery_charge": 86.92947814
  },
  {
    "timestamp": "01/04/24 21:00",
    "demand": 36137.9129,
    "solar_generated": 0,
    "wind_generated": 66.61930651,
    "marketplace_to_demand": 143.9877415,
    "grid_to_demand": 35722.21596,
    "govt_battery_charge": 3.308004589
  },
  {
    "timestamp": "01/04/24 22:00",
    "demand": 30523.73399,
    "solar_generated": 0,
    "wind_generated": 55.51162049,
    "marketplace_to_demand": 149.8578179,
    "grid_to_demand": 32659.58842,
    "govt_battery_charge": 12.32188836
  },
  {
    "timestamp": "01/04/24 23:00",
    "demand": 31571.61136,
    "solar_generated": 0,
    "wind_generated": 62.55325308,
    "marketplace_to_demand": 95.98941154,
    "grid_to_demand": 32094.21628,
    "govt_battery_charge": 55.60269411
  },
  {
    "timestamp": "02/04/24 0:00",
    "demand": 32166.48857,
    "solar_generated": 0,
    "wind_generated": 79.61199373,
    "marketplace_to_demand": 176.2600151,
    "grid_to_demand": 35291.95487,
    "govt_battery_charge": 1.798531203
  },
  {
    "timestamp": "02/04/24 1:00",
    "demand": 34847.27865,
    "solar_generated": 0,
    "wind_generated": 62.27227454,
    "marketplace_to_demand": 72.21427823,
    "grid_to_demand": 30801.19129,
    "govt_battery_charge": 61.75192574
  },
  {
    "timestamp": "02/04/24 2:00",
    "demand": 34623.59223,
    "solar_generated": 0,
    "wind_generated": 79.38217646,
    "marketplace_to_demand": 166.9497124,
    "grid_to_demand": 34246.23126,
    "govt_battery_charge": 47.74291965
  },
  {
    "timestamp": "02/04/24 3:00",
    "demand": 32221.35073,
    "solar_generated": 0,
    "wind_generated": 62.44428155,
    "marketplace_to_demand": 177.9942196,
    "grid_to_demand": 35565.5914,
    "govt_battery_charge": 78.80565213
  },
  {
    "timestamp": "02/04/24 4:00",
    "demand": 32448.80972,
    "solar_generated": 0,
    "wind_generated": 63.17784392,
    "marketplace_to_demand": 195.6039058,
    "grid_to_demand": 35917.94212,
    "govt_battery_charge": 39.04104947
  },
  {
    "timestamp": "02/04/24 5:00",
    "demand": 35383.78387,
    "solar_generated": 0,
    "wind_generated": 60.28249902,
    "marketplace_to_demand": 45.49566727,
    "grid_to_demand": 34538.06534,
    "govt_battery_charge": 53.81006277
  },
  {
    "timestamp": "02/04/24 6:00",
    "demand": 36573.49756,
    "solar_generated": 11.76812445,
    "wind_generated": 78.98666902,
    "marketplace_to_demand": 76.23451418,
    "grid_to_demand": 31557.31373,
    "govt_battery_charge": 96.78117002
  },
  {
    "timestamp": "02/04/24 7:00",
    "demand": 33280.07742,
    "solar_generated": 18.97588792,
    "wind_generated": 72.52856,
    "marketplace_to_demand": 30.19071997,
    "grid_to_demand": 34242.10933,
    "govt_battery_charge": 53.88794426
  },
  {
    "timestamp": "02/04/24 8:00",
    "demand": 34873.94127,
    "solar_generated": 10.03936348,
    "wind_generated": 74.95027321,
    "marketplace_to_demand": 185.0485907,
    "grid_to_demand": 35749.18716,
    "govt_battery_charge": 13.57837717
  },
  {
    "timestamp": "02/04/24 9:00",
    "demand": 32840.20292,
    "solar_generated": 16.51066434,
    "wind_generated": 74.85924388,
    "marketplace_to_demand": 8.394735533,
    "grid_to_demand": 35163.08642,
    "govt_battery_charge": 79.94065714
  },
  {
    "timestamp": "02/04/24 10:00",
    "demand": 30325.70273,
    "solar_generated": 22.03112475,
    "wind_generated": 73.60521568,
    "marketplace_to_demand": 129.1400669,
    "grid_to_demand": 30915.16037,
    "govt_battery_charge": 20.61530484
  },
  {
    "timestamp": "02/04/24 11:00",
    "demand": 35236.87175,
    "solar_generated": 18.52226761,
    "wind_generated": 64.87230832,
    "marketplace_to_demand": 57.53066963,
    "grid_to_demand": 31168.34758,
    "govt_battery_charge": 90.95204723
  },
  {
    "timestamp": "02/04/24 12:00",
    "demand": 30049.37244,
    "solar_generated": 13.08406975,
    "wind_generated": 76.08621936,
    "marketplace_to_demand": 7.65255827,
    "grid_to_demand": 33713.70231,
    "govt_battery_charge": 30.75431181
  },
  {
    "timestamp": "02/04/24 13:00",
    "demand": 35096.80921,
    "solar_generated": 9.358163809,
    "wind_generated": 57.45344409,
    "marketplace_to_demand": 22.65719863,
    "grid_to_demand": 33329.17654,
    "govt_battery_charge": 4.666322424
  },
  {
    "timestamp": "02/04/24 14:00",
    "demand": 31458.72966,
    "solar_generated": 21.77039396,
    "wind_generated": 79.00213584,
    "marketplace_to_demand": 183.0270644,
    "grid_to_demand": 34888.36609,
    "govt_battery_charge": 47.85137479
  },
  {
    "timestamp": "02/04/24 15:00",
    "demand": 30847.95386,
    "solar_generated": 7.580628024,
    "wind_generated": 64.13140187,
    "marketplace_to_demand": 44.4076744,
    "grid_to_demand": 35092.4,
    "govt_battery_charge": 6.40539343
  },
  {
    "timestamp": "02/04/24 16:00",
    "demand": 32195.89641,
    "solar_generated": 21.97350792,
    "wind_generated": 54.07291415,
    "marketplace_to_demand": 189.124422,
    "grid_to_demand": 29998.59512,
    "govt_battery_charge": 18.55621809
  },
  {
    "timestamp": "02/04/24 17:00",
    "demand": 34458.24523,
    "solar_generated": 15.35668185,
    "wind_generated": 70.63454885,
    "marketplace_to_demand": 145.8857438,
    "grid_to_demand": 30594.75849,
    "govt_battery_charge": 25.4529435
  },
  {
    "timestamp": "02/04/24 18:00",
    "demand": 30928.92072,
    "solar_generated": 0,
    "wind_generated": 69.20431745,
    "marketplace_to_demand": 96.4011021,
    "grid_to_demand": 30855.11664,
    "govt_battery_charge": 3.645581856
  },
  {
    "timestamp": "02/04/24 19:00",
    "demand": 34143.58855,
    "solar_generated": 0,
    "wind_generated": 70.52058126,
    "marketplace_to_demand": 108.6255782,
    "grid_to_demand": 35190.76504,
    "govt_battery_charge": 5.514688119
  },
  {
    "timestamp": "02/04/24 20:00",
    "demand": 35800.68755,
    "solar_generated": 0,
    "wind_generated": 53.60237178,
    "marketplace_to_demand": 35.1018006,
    "grid_to_demand": 35088.83229,
    "govt_battery_charge": 43.77322172
  },
  {
    "timestamp": "02/04/24 21:00",
    "demand": 32364.04077,
    "solar_generated": 0,
    "wind_generated": 72.53813191,
    "marketplace_to_demand": 138.9725549,
    "grid_to_demand": 33929.78845,
    "govt_battery_charge": 21.7253104
  },
  {
    "timestamp": "02/04/24 22:00",
    "demand": 30306.31792,
    "solar_generated": 0,
    "wind_generated": 72.76311823,
    "marketplace_to_demand": 183.7169274,
    "grid_to_demand": 33855.86168,
    "govt_battery_charge": 69.86614921
  },
  {
    "timestamp": "02/04/24 23:00",
    "demand": 36253.40046,
    "solar_generated": 0,
    "wind_generated": 67.23528838,
    "marketplace_to_demand": 130.0121706,
    "grid_to_demand": 34693.57067,
    "govt_battery_charge": 23.1218795
  },
  {
    "timestamp": "03/04/24 0:00",
    "demand": 31622.84134,
    "solar_generated": 0,
    "wind_generated": 77.3758323,
    "marketplace_to_demand": 121.8268834,
    "grid_to_demand": 33981.71756,
    "govt_battery_charge": 7.695764075
  },
  {
    "timestamp": "03/04/24 1:00",
    "demand": 31939.33498,
    "solar_generated": 0,
    "wind_generated": 64.19995412,
    "marketplace_to_demand": 19.97147776,
    "grid_to_demand": 34665.06564,
    "govt_battery_charge": 56.61761893
  },
  {
    "timestamp": "03/04/24 2:00",
    "demand": 35190.93748,
    "solar_generated": 0,
    "wind_generated": 73.44735001,
    "marketplace_to_demand": 63.73302542,
    "grid_to_demand": 34201.11257,
    "govt_battery_charge": 52.13531569
  },
  {
    "timestamp": "03/04/24 3:00",
    "demand": 34753.00094,
    "solar_generated": 0,
    "wind_generated": 76.09206724,
    "marketplace_to_demand": 39.27081808,
    "grid_to_demand": 31970.0435,
    "govt_battery_charge": 29.2900699
  },
  {
    "timestamp": "03/04/24 4:00",
    "demand": 36179.63093,
    "solar_generated": 0,
    "wind_generated": 74.64437107,
    "marketplace_to_demand": 103.3159041,
    "grid_to_demand": 31103.82702,
    "govt_battery_charge": 22.93353913
  },
  {
    "timestamp": "03/04/24 5:00",
    "demand": 32194.7024,
    "solar_generated": 0,
    "wind_generated": 54.12481274,
    "marketplace_to_demand": 91.67630402,
    "grid_to_demand": 35596.95321,
    "govt_battery_charge": 26.92339525
  },
  {
    "timestamp": "03/04/24 6:00",
    "demand": 32145.76667,
    "solar_generated": 11.36678324,
    "wind_generated": 75.12425728,
    "marketplace_to_demand": 194.910817,
    "grid_to_demand": 31848.84938,
    "govt_battery_charge": 2.606762716
  },
  {
    "timestamp": "03/04/24 7:00",
    "demand": 35587.79336,
    "solar_generated": 18.12517633,
    "wind_generated": 73.02660024,
    "marketplace_to_demand": 53.5591957,
    "grid_to_demand": 30925.83359,
    "govt_battery_charge": 72.32106243
  },
  {
    "timestamp": "03/04/24 8:00",
    "demand": 35438.23727,
    "solar_generated": 7.864895,
    "wind_generated": 69.10807945,
    "marketplace_to_demand": 143.8651474,
    "grid_to_demand": 32708.24619,
    "govt_battery_charge": 48.37226614
  },
  {
    "timestamp": "03/04/24 9:00",
    "demand": 34357.42764,
    "solar_generated": 8.731913772,
    "wind_generated": 56.55941141,
    "marketplace_to_demand": 192.9688167,
    "grid_to_demand": 32935.05607,
    "govt_battery_charge": 58.23844766
  },
  {
    "timestamp": "03/04/24 10:00",
    "demand": 31294.42684,
    "solar_generated": 13.37826899,
    "wind_generated": 56.43263885,
    "marketplace_to_demand": 36.77117394,
    "grid_to_demand": 30301.54498,
    "govt_battery_charge": 3.279456693
  },
  {
    "timestamp": "03/04/24 11:00",
    "demand": 31298.61594,
    "solar_generated": 15.30981272,
    "wind_generated": 72.0527873,
    "marketplace_to_demand": 161.1384939,
    "grid_to_demand": 35369.46882,
    "govt_battery_charge": 34.34985775
  },
  {
    "timestamp": "03/04/24 12:00",
    "demand": 32359.03893,
    "solar_generated": 8.114029367,
    "wind_generated": 76.86022301,
    "marketplace_to_demand": 76.39378481,
    "grid_to_demand": 31645.64191,
    "govt_battery_charge": 4.302500046
  },
  {
    "timestamp": "03/04/24 13:00",
    "demand": 34079.83502,
    "solar_generated": 23.10322713,
    "wind_generated": 64.48139722,
    "marketplace_to_demand": 89.33742256,
    "grid_to_demand": 32301.8611,
    "govt_battery_charge": 81.8141164
  },
  {
    "timestamp": "03/04/24 14:00",
    "demand": 35940.57871,
    "solar_generated": 19.8253991,
    "wind_generated": 75.75351505,
    "marketplace_to_demand": 29.52187294,
    "grid_to_demand": 35966.94745,
    "govt_battery_charge": 94.58647382
  },
  {
    "timestamp": "03/04/24 15:00",
    "demand": 30923.56812,
    "solar_generated": 6.307157444,
    "wind_generated": 80.36335654,
    "marketplace_to_demand": 161.1429648,
    "grid_to_demand": 36231.4311,
    "govt_battery_charge": 33.87986679
  },
  {
    "timestamp": "03/04/24 16:00",
    "demand": 36198.95961,
    "solar_generated": 7.903324956,
    "wind_generated": 74.69875746,
    "marketplace_to_demand": 176.9638192,
    "grid_to_demand": 35725.14624,
    "govt_battery_charge": 92.07703256
  },
  {
    "timestamp": "03/04/24 17:00",
    "demand": 32371.52908,
    "solar_generated": 12.19165282,
    "wind_generated": 77.51005543,
    "marketplace_to_demand": 160.3871408,
    "grid_to_demand": 30553.53404,
    "govt_battery_charge": 61.07734534
  },
  {
    "timestamp": "03/04/24 18:00",
    "demand": 35463.08327,
    "solar_generated": 0,
    "wind_generated": 73.150257,
    "marketplace_to_demand": 165.8748898,
    "grid_to_demand": 36074.32749,
    "govt_battery_charge": 21.7165317
  },
  {
    "timestamp": "03/04/24 19:00",
    "demand": 32375.26797,
    "solar_generated": 0,
    "wind_generated": 55.18013481,
    "marketplace_to_demand": 56.46786026,
    "grid_to_demand": 33854.29697,
    "govt_battery_charge": 92.80336464
  },
  {
    "timestamp": "03/04/24 20:00",
    "demand": 31448.29156,
    "solar_generated": 0,
    "wind_generated": 67.38924516,
    "marketplace_to_demand": 32.37884663,
    "grid_to_demand": 30664.95862,
    "govt_battery_charge": 70.11239443
  },
  {
    "timestamp": "03/04/24 21:00",
    "demand": 36065.46879,
    "solar_generated": 0,
    "wind_generated": 55.95673386,
    "marketplace_to_demand": 172.9809598,
    "grid_to_demand": 34053.07489,
    "govt_battery_charge": 46.97290161
  },
  {
    "timestamp": "03/04/24 22:00",
    "demand": 30315.98402,
    "solar_generated": 0,
    "wind_generated": 66.81359126,
    "marketplace_to_demand": 38.65786447,
    "grid_to_demand": 34130.03446,
    "govt_battery_charge": 92.73314175
  },
  {
    "timestamp": "03/04/24 23:00",
    "demand": 33909.8389,
    "solar_generated": 0,
    "wind_generated": 69.4508857,
    "marketplace_to_demand": 153.0356608,
    "grid_to_demand": 31039.58023,
    "govt_battery_charge": 12.2336601
  },
  {
    "timestamp": "04/04/24 0:00",
    "demand": 35316.96909,
    "solar_generated": 0,
    "wind_generated": 71.15480843,
    "marketplace_to_demand": 60.05665349,
    "grid_to_demand": 32470.85417,
    "govt_battery_charge": 23.05912726
  },
  {
    "timestamp": "04/04/24 1:00",
    "demand": 36267.40371,
    "solar_generated": 0,
    "wind_generated": 71.70848165,
    "marketplace_to_demand": 196.5205565,
    "grid_to_demand": 34887.55354,
    "govt_battery_charge": 54.90933519
  },
  {
    "timestamp": "04/04/24 2:00",
    "demand": 31276.88425,
    "solar_generated": 0,
    "wind_generated": 68.25427791,
    "marketplace_to_demand": 158.8433351,
    "grid_to_demand": 33530.54229,
    "govt_battery_charge": 11.89846836
  },
  {
    "timestamp": "04/04/24 3:00",
    "demand": 32256.38982,
    "solar_generated": 0,
    "wind_generated": 65.57415523,
    "marketplace_to_demand": 122.3698099,
    "grid_to_demand": 32441.10229,
    "govt_battery_charge": 87.34821329
  },
  {
    "timestamp": "04/04/24 4:00",
    "demand": 36486.59313,
    "solar_generated": 0,
    "wind_generated": 56.26997763,
    "marketplace_to_demand": 9.102009903,
    "grid_to_demand": 30223.45481,
    "govt_battery_charge": 53.68278951
  },
  {
    "timestamp": "04/04/24 5:00",
    "demand": 34538.86298,
    "solar_generated": 0,
    "wind_generated": 63.86342754,
    "marketplace_to_demand": 118.057573,
    "grid_to_demand": 33236.23654,
    "govt_battery_charge": 27.77218869
  },
  {
    "timestamp": "04/04/24 6:00",
    "demand": 34291.4793,
    "solar_generated": 18.24828904,
    "wind_generated": 66.65162764,
    "marketplace_to_demand": 100.0467059,
    "grid_to_demand": 32837.7461,
    "govt_battery_charge": 6.877648617
  },
  {
    "timestamp": "04/04/24 7:00",
    "demand": 30920.4063,
    "solar_generated": 18.34707096,
    "wind_generated": 75.71723903,
    "marketplace_to_demand": 93.64692255,
    "grid_to_demand": 31045.87287,
    "govt_battery_charge": 77.63354463
  },
  {
    "timestamp": "04/04/24 8:00",
    "demand": 32307.36704,
    "solar_generated": 19.1543731,
    "wind_generated": 71.33844396,
    "marketplace_to_demand": 167.9191048,
    "grid_to_demand": 30723.86154,
    "govt_battery_charge": 79.50325797
  },
  {
    "timestamp": "04/04/24 9:00",
    "demand": 33709.16308,
    "solar_generated": 13.30761078,
    "wind_generated": 76.45761387,
    "marketplace_to_demand": 171.6261826,
    "grid_to_demand": 36083.65171,
    "govt_battery_charge": 10.94739689
  },
  {
    "timestamp": "04/04/24 10:00",
    "demand": 31144.75177,
    "solar_generated": 22.71449718,
    "wind_generated": 72.03038904,
    "marketplace_to_demand": 191.1259195,
    "grid_to_demand": 31320.87122,
    "govt_battery_charge": 32.81060465
  },
  {
    "timestamp": "04/04/24 11:00",
    "demand": 30265.74536,
    "solar_generated": 19.69372111,
    "wind_generated": 79.81549709,
    "marketplace_to_demand": 120.9411011,
    "grid_to_demand": 34310.59628,
    "govt_battery_charge": 2.013168674
  },
  {
    "timestamp": "04/04/24 12:00",
    "demand": 30347.31146,
    "solar_generated": 15.13578429,
    "wind_generated": 62.02384852,
    "marketplace_to_demand": 132.5921235,
    "grid_to_demand": 30767.06439,
    "govt_battery_charge": 92.40832783
  },
  {
    "timestamp": "04/04/24 13:00",
    "demand": 36050.0537,
    "solar_generated": 7.752313258,
    "wind_generated": 55.73897645,
    "marketplace_to_demand": 119.1989806,
    "grid_to_demand": 35737.59098,
    "govt_battery_charge": 12.30628998
  },
  {
    "timestamp": "04/04/24 14:00",
    "demand": 30640.84905,
    "solar_generated": 14.32771504,
    "wind_generated": 63.77285258,
    "marketplace_to_demand": 171.7873312,
    "grid_to_demand": 31470.24375,
    "govt_battery_charge": 53.68693519
  },
  {
    "timestamp": "04/04/24 15:00",
    "demand": 36393.68291,
    "solar_generated": 14.00358085,
    "wind_generated": 73.96462004,
    "marketplace_to_demand": 165.3206132,
    "grid_to_demand": 33445.19988,
    "govt_battery_charge": 87.12181464
  },
  {
    "timestamp": "04/04/24 16:00",
    "demand": 33146.83425,
    "solar_generated": 11.18696837,
    "wind_generated": 62.46726351,
    "marketplace_to_demand": 48.15749778,
    "grid_to_demand": 29980.48332,
    "govt_battery_charge": 35.10672878
  },
  {
    "timestamp": "04/04/24 17:00",
    "demand": 35917.98808,
    "solar_generated": 15.71588238,
    "wind_generated": 55.50780981,
    "marketplace_to_demand": 63.91508213,
    "grid_to_demand": 35300.31694,
    "govt_battery_charge": 46.17822106
  },
  {
    "timestamp": "04/04/24 18:00",
    "demand": 32838.66599,
    "solar_generated": 0,
    "wind_generated": 64.56030314,
    "marketplace_to_demand": 46.6512702,
    "grid_to_demand": 32120.44739,
    "govt_battery_charge": 62.85748477
  },
  {
    "timestamp": "04/04/24 19:00",
    "demand": 32814.82595,
    "solar_generated": 0,
    "wind_generated": 63.65055664,
    "marketplace_to_demand": 145.4373813,
    "grid_to_demand": 30360.94578,
    "govt_battery_charge": 57.75701704
  },
  {
    "timestamp": "04/04/24 20:00",
    "demand": 31693.53321,
    "solar_generated": 0,
    "wind_generated": 74.74017517,
    "marketplace_to_demand": 62.96647584,
    "grid_to_demand": 35349.92814,
    "govt_battery_charge": 15.53480502
  },
  {
    "timestamp": "04/04/24 21:00",
    "demand": 32705.24347,
    "solar_generated": 0,
    "wind_generated": 60.06016781,
    "marketplace_to_demand": 90.72707467,
    "grid_to_demand": 32839.53639,
    "govt_battery_charge": 78.07626893
  },
  {
    "timestamp": "04/04/24 22:00",
    "demand": 30558.19943,
    "solar_generated": 0,
    "wind_generated": 74.66378798,
    "marketplace_to_demand": 133.4129717,
    "grid_to_demand": 32760.10272,
    "govt_battery_charge": 75.89937525
  },
  {
    "timestamp": "04/04/24 23:00",
    "demand": 36099.20405,
    "solar_generated": 0,
    "wind_generated": 75.17636631,
    "marketplace_to_demand": 136.8160722,
    "grid_to_demand": 35518.6617,
    "govt_battery_charge": 57.29343792
  },
  {
    "timestamp": "05/04/24 0:00",
    "demand": 31690.77016,
    "solar_generated": 0,
    "wind_generated": 63.40100167,
    "marketplace_to_demand": 45.05810411,
    "grid_to_demand": 30399.00022,
    "govt_battery_charge": 40.82067871
  },
  {
    "timestamp": "05/04/24 1:00",
    "demand": 35038.73282,
    "solar_generated": 0,
    "wind_generated": 54.75392866,
    "marketplace_to_demand": 44.37711156,
    "grid_to_demand": 31807.39805,
    "govt_battery_charge": 35.78338753
  },
  {
    "timestamp": "05/04/24 2:00",
    "demand": 35174.1953,
    "solar_generated": 0,
    "wind_generated": 61.64821,
    "marketplace_to_demand": 163.9639198,
    "grid_to_demand": 33723.69444,
    "govt_battery_charge": 57.41825371
  },
  {
    "timestamp": "05/04/24 3:00",
    "demand": 32013.14514,
    "solar_generated": 0,
    "wind_generated": 73.4817056,
    "marketplace_to_demand": 46.47461756,
    "grid_to_demand": 32285.15184,
    "govt_battery_charge": 86.73103617
  },
  {
    "timestamp": "05/04/24 4:00",
    "demand": 35809.44779,
    "solar_generated": 0,
    "wind_generated": 65.02402536,
    "marketplace_to_demand": 180.3562268,
    "grid_to_demand": 36191.16716,
    "govt_battery_charge": 54.88669321
  },
  {
    "timestamp": "05/04/24 5:00",
    "demand": 35570.39356,
    "solar_generated": 0,
    "wind_generated": 54.54259834,
    "marketplace_to_demand": 78.09643256,
    "grid_to_demand": 32535.9873,
    "govt_battery_charge": 91.033083
  },
  {
    "timestamp": "05/04/24 6:00",
    "demand": 34766.98119,
    "solar_generated": 16.18550395,
    "wind_generated": 55.97959239,
    "marketplace_to_demand": 135.6337715,
    "grid_to_demand": 33371.02274,
    "govt_battery_charge": 93.37941664
  },
  {
    "timestamp": "05/04/24 7:00",
    "demand": 34988.43923,
    "solar_generated": 6.668764915,
    "wind_generated": 65.25511538,
    "marketplace_to_demand": 150.2641266,
    "grid_to_demand": 30626.82425,
    "govt_battery_charge": 37.17592948
  },
  {
    "timestamp": "05/04/24 8:00",
    "demand": 33917.56609,
    "solar_generated": 7.602236064,
    "wind_generated": 64.73469128,
    "marketplace_to_demand": 80.17280566,
    "grid_to_demand": 34965.80952,
    "govt_battery_charge": 42.61589117
  },
  {
    "timestamp": "05/04/24 9:00",
    "demand": 36478.98759,
    "solar_generated": 16.46432287,
    "wind_generated": 71.50162117,
    "marketplace_to_demand": 30.58766031,
    "grid_to_demand": 33382.58945,
    "govt_battery_charge": 14.35830701
  },
  {
    "timestamp": "05/04/24 10:00",
    "demand": 36175.78679,
    "solar_generated": 23.52188369,
    "wind_generated": 64.25134789,
    "marketplace_to_demand": 41.48192294,
    "grid_to_demand": 30231.87387,
    "govt_battery_charge": 56.9151996
  },
  {
    "timestamp": "05/04/24 11:00",
    "demand": 35780.03562,
    "solar_generated": 11.85733678,
    "wind_generated": 69.82570302,
    "marketplace_to_demand": 31.50868323,
    "grid_to_demand": 36237.11938,
    "govt_battery_charge": 83.68062381
  },
  {
    "timestamp": "05/04/24 12:00",
    "demand": 32756.88929,
    "solar_generated": 15.09581075,
    "wind_generated": 73.96097202,
    "marketplace_to_demand": 22.1646403,
    "grid_to_demand": 32791.32838,
    "govt_battery_charge": 17.98161154
  },
  {
    "timestamp": "05/04/24 13:00",
    "demand": 34259.13903,
    "solar_generated": 15.13319798,
    "wind_generated": 73.50957767,
    "marketplace_to_demand": 161.01735,
    "grid_to_demand": 36145.0152,
    "govt_battery_charge": 5.530397652
  },
  {
    "timestamp": "05/04/24 14:00",
    "demand": 30190.47755,
    "solar_generated": 7.624165815,
    "wind_generated": 74.54906547,
    "marketplace_to_demand": 153.2605396,
    "grid_to_demand": 36422.33239,
    "govt_battery_charge": 56.33274123
  },
  {
    "timestamp": "05/04/24 15:00",
    "demand": 34412.51986,
    "solar_generated": 9.315430305,
    "wind_generated": 67.19606788,
    "marketplace_to_demand": 145.6003567,
    "grid_to_demand": 33105.18584,
    "govt_battery_charge": 91.95126076
  },
  {
    "timestamp": "05/04/24 16:00",
    "demand": 33246.66646,
    "solar_generated": 6.890598964,
    "wind_generated": 70.66792913,
    "marketplace_to_demand": 168.7523253,
    "grid_to_demand": 32005.48299,
    "govt_battery_charge": 7.983132665
  },
  {
    "timestamp": "05/04/24 17:00",
    "demand": 35609.43597,
    "solar_generated": 7.306475787,
    "wind_generated": 54.88066885,
    "marketplace_to_demand": 39.77863578,
    "grid_to_demand": 30950.08313,
    "govt_battery_charge": 34.01399123
  },
  {
    "timestamp": "05/04/24 18:00",
    "demand": 30205.77586,
    "solar_generated": 0,
    "wind_generated": 57.01029023,
    "marketplace_to_demand": 147.9059665,
    "grid_to_demand": 35549.41889,
    "govt_battery_charge": 4.821132319
  },
  {
    "timestamp": "05/04/24 19:00",
    "demand": 33106.21603,
    "solar_generated": 0,
    "wind_generated": 65.5248327,
    "marketplace_to_demand": 157.4348314,
    "grid_to_demand": 35483.93753,
    "govt_battery_charge": 48.15340905
  },
  {
    "timestamp": "05/04/24 20:00",
    "demand": 30553.2966,
    "solar_generated": 0,
    "wind_generated": 73.81715424,
    "marketplace_to_demand": 119.5726828,
    "grid_to_demand": 31486.73325,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "05/04/24 21:00",
    "demand": 30161.26011,
    "solar_generated": 0,
    "wind_generated": 62.00712275,
    "marketplace_to_demand": 128.317389,
    "grid_to_demand": 32710.74793,
    "govt_battery_charge": 88.50606323
  },
  {
    "timestamp": "05/04/24 22:00",
    "demand": 32276.47835,
    "solar_generated": 0,
    "wind_generated": 78.74678878,
    "marketplace_to_demand": 21.46699124,
    "grid_to_demand": 32279.24111,
    "govt_battery_charge": 10.42751447
  },
  {
    "timestamp": "05/04/24 23:00",
    "demand": 32288.51209,
    "solar_generated": 0,
    "wind_generated": 72.17386908,
    "marketplace_to_demand": 43.58214497,
    "grid_to_demand": 36382.97766,
    "govt_battery_charge": 46.4645691
  },
  {
    "timestamp": "06/04/24 0:00",
    "demand": 33476.68117,
    "solar_generated": 0,
    "wind_generated": 76.76835037,
    "marketplace_to_demand": 86.18435251,
    "grid_to_demand": 33173.58503,
    "govt_battery_charge": 9.800769768
  },
  {
    "timestamp": "06/04/24 1:00",
    "demand": 34263.96222,
    "solar_generated": 0,
    "wind_generated": 63.65637125,
    "marketplace_to_demand": 179.7607157,
    "grid_to_demand": 36460.22345,
    "govt_battery_charge": 42.378407
  },
  {
    "timestamp": "06/04/24 2:00",
    "demand": 31510.07221,
    "solar_generated": 0,
    "wind_generated": 75.65927716,
    "marketplace_to_demand": 74.48812396,
    "grid_to_demand": 34062.9708,
    "govt_battery_charge": 4.448489936
  },
  {
    "timestamp": "06/04/24 3:00",
    "demand": 30453.61978,
    "solar_generated": 0,
    "wind_generated": 57.78846991,
    "marketplace_to_demand": 33.81426568,
    "grid_to_demand": 30287.73207,
    "govt_battery_charge": 78.96042238
  },
  {
    "timestamp": "06/04/24 4:00",
    "demand": 34318.88943,
    "solar_generated": 0,
    "wind_generated": 68.96130551,
    "marketplace_to_demand": 23.72861916,
    "grid_to_demand": 35136.37442,
    "govt_battery_charge": 32.3494876
  },
  {
    "timestamp": "06/04/24 5:00",
    "demand": 32011.41484,
    "solar_generated": 0,
    "wind_generated": 72.3372503,
    "marketplace_to_demand": 40.428834,
    "grid_to_demand": 34684.56592,
    "govt_battery_charge": 73.80929959
  },
  {
    "timestamp": "06/04/24 6:00",
    "demand": 36482.76081,
    "solar_generated": 7.69871846,
    "wind_generated": 55.01126847,
    "marketplace_to_demand": 69.28456495,
    "grid_to_demand": 30859.52582,
    "govt_battery_charge": 54.65961532
  },
  {
    "timestamp": "06/04/24 7:00",
    "demand": 31646.77671,
    "solar_generated": 16.95864868,
    "wind_generated": 77.30963978,
    "marketplace_to_demand": 142.2860737,
    "grid_to_demand": 36017.45227,
    "govt_battery_charge": 62.44965205
  },
  {
    "timestamp": "06/04/24 8:00",
    "demand": 31083.97923,
    "solar_generated": 16.84304565,
    "wind_generated": 59.24719206,
    "marketplace_to_demand": 96.41404721,
    "grid_to_demand": 33120.15474,
    "govt_battery_charge": 77.99459221
  },
  {
    "timestamp": "06/04/24 9:00",
    "demand": 35956.15881,
    "solar_generated": 6.296986414,
    "wind_generated": 58.62965461,
    "marketplace_to_demand": 3.394597309,
    "grid_to_demand": 32883.63833,
    "govt_battery_charge": 16.22742756
  },
  {
    "timestamp": "06/04/24 10:00",
    "demand": 36008.15452,
    "solar_generated": 7.561623304,
    "wind_generated": 65.80006992,
    "marketplace_to_demand": 100.0902042,
    "grid_to_demand": 35835.68592,
    "govt_battery_charge": 66.97344784
  },
  {
    "timestamp": "06/04/24 11:00",
    "demand": 36224.85729,
    "solar_generated": 6.264711984,
    "wind_generated": 71.91045955,
    "marketplace_to_demand": 0.250810614,
    "grid_to_demand": 32982.02846,
    "govt_battery_charge": 9.734395929
  },
  {
    "timestamp": "06/04/24 12:00",
    "demand": 30369.93816,
    "solar_generated": 16.77581793,
    "wind_generated": 79.22200095,
    "marketplace_to_demand": 106.8740643,
    "grid_to_demand": 31241.7119,
    "govt_battery_charge": 8.371674025
  },
  {
    "timestamp": "06/04/24 13:00",
    "demand": 30512.50522,
    "solar_generated": 17.62525333,
    "wind_generated": 80.22183168,
    "marketplace_to_demand": 105.1577782,
    "grid_to_demand": 31986.66484,
    "govt_battery_charge": 5.711800632
  },
  {
    "timestamp": "06/04/24 14:00",
    "demand": 30672.22059,
    "solar_generated": 21.15292287,
    "wind_generated": 61.09722989,
    "marketplace_to_demand": 78.26600197,
    "grid_to_demand": 34057.23377,
    "govt_battery_charge": 72.74504981
  },
  {
    "timestamp": "06/04/24 15:00",
    "demand": 36006.81309,
    "solar_generated": 21.51994272,
    "wind_generated": 67.01400566,
    "marketplace_to_demand": 45.91059562,
    "grid_to_demand": 30868.95537,
    "govt_battery_charge": 93.96312226
  },
  {
    "timestamp": "06/04/24 16:00",
    "demand": 32666.5262,
    "solar_generated": 20.49991558,
    "wind_generated": 79.77233238,
    "marketplace_to_demand": 166.7076559,
    "grid_to_demand": 30077.46988,
    "govt_battery_charge": 36.67878074
  },
  {
    "timestamp": "06/04/24 17:00",
    "demand": 33113.57651,
    "solar_generated": 13.31402948,
    "wind_generated": 76.59088787,
    "marketplace_to_demand": 105.1116198,
    "grid_to_demand": 36434.8,
    "govt_battery_charge": 81.98556338
  },
  {
    "timestamp": "06/04/24 18:00",
    "demand": 34257.59661,
    "solar_generated": 0,
    "wind_generated": 69.36890109,
    "marketplace_to_demand": 194.5470648,
    "grid_to_demand": 35283.39339,
    "govt_battery_charge": 46.10030295
  },
  {
    "timestamp": "06/04/24 19:00",
    "demand": 32103.23308,
    "solar_generated": 0,
    "wind_generated": 59.26748598,
    "marketplace_to_demand": 190.7244404,
    "grid_to_demand": 32571.30014,
    "govt_battery_charge": 37.58496894
  },
  {
    "timestamp": "06/04/24 20:00",
    "demand": 35210.34854,
    "solar_generated": 0,
    "wind_generated": 75.89799003,
    "marketplace_to_demand": 113.0126224,
    "grid_to_demand": 33638.06085,
    "govt_battery_charge": 2.612722364
  },
  {
    "timestamp": "06/04/24 21:00",
    "demand": 32441.32286,
    "solar_generated": 0,
    "wind_generated": 76.25866633,
    "marketplace_to_demand": 189.3106438,
    "grid_to_demand": 30727.8914,
    "govt_battery_charge": 91.55274968
  },
  {
    "timestamp": "06/04/24 22:00",
    "demand": 33449.53835,
    "solar_generated": 0,
    "wind_generated": 67.48210345,
    "marketplace_to_demand": 15.6654722,
    "grid_to_demand": 30056.93328,
    "govt_battery_charge": 33.97076265
  },
  {
    "timestamp": "06/04/24 23:00",
    "demand": 35015.97485,
    "solar_generated": 0,
    "wind_generated": 79.16536172,
    "marketplace_to_demand": 158.9773641,
    "grid_to_demand": 35768.23196,
    "govt_battery_charge": 88.01401811
  },
  {
    "timestamp": "07/04/24 0:00",
    "demand": 32342.13411,
    "solar_generated": 0,
    "wind_generated": 56.00676511,
    "marketplace_to_demand": 192.3075689,
    "grid_to_demand": 34879.34445,
    "govt_battery_charge": 51.06641216
  },
  {
    "timestamp": "07/04/24 1:00",
    "demand": 31768.68361,
    "solar_generated": 0,
    "wind_generated": 71.58786062,
    "marketplace_to_demand": 107.8717964,
    "grid_to_demand": 33884.83882,
    "govt_battery_charge": 19.99163252
  },
  {
    "timestamp": "07/04/24 2:00",
    "demand": 34530.52385,
    "solar_generated": 0,
    "wind_generated": 73.94165036,
    "marketplace_to_demand": 67.34207315,
    "grid_to_demand": 31250.72779,
    "govt_battery_charge": 56.696464
  },
  {
    "timestamp": "07/04/24 3:00",
    "demand": 31219.84886,
    "solar_generated": 0,
    "wind_generated": 55.34526837,
    "marketplace_to_demand": 106.0030171,
    "grid_to_demand": 32086.54775,
    "govt_battery_charge": 72.84579293
  },
  {
    "timestamp": "07/04/24 4:00",
    "demand": 30020.87143,
    "solar_generated": 0,
    "wind_generated": 65.33457696,
    "marketplace_to_demand": 99.2477514,
    "grid_to_demand": 33995.68278,
    "govt_battery_charge": 16.46646972
  },
  {
    "timestamp": "07/04/24 5:00",
    "demand": 33112.89302,
    "solar_generated": 0,
    "wind_generated": 60.29061778,
    "marketplace_to_demand": 191.7182843,
    "grid_to_demand": 30244.51016,
    "govt_battery_charge": 73.33055349
  },
  {
    "timestamp": "07/04/24 6:00",
    "demand": 35131.25252,
    "solar_generated": 14.59759506,
    "wind_generated": 54.38977285,
    "marketplace_to_demand": 135.9579804,
    "grid_to_demand": 34015.79555,
    "govt_battery_charge": 96.67259849
  },
  {
    "timestamp": "07/04/24 7:00",
    "demand": 35532.94619,
    "solar_generated": 13.5212426,
    "wind_generated": 67.18108799,
    "marketplace_to_demand": 192.90294,
    "grid_to_demand": 35664.51866,
    "govt_battery_charge": 82.67329578
  },
  {
    "timestamp": "07/04/24 8:00",
    "demand": 36219.41948,
    "solar_generated": 10.62539547,
    "wind_generated": 66.33817328,
    "marketplace_to_demand": 126.7471968,
    "grid_to_demand": 35434.9842,
    "govt_battery_charge": 13.36512911
  },
  {
    "timestamp": "07/04/24 9:00",
    "demand": 30618.95947,
    "solar_generated": 13.73304244,
    "wind_generated": 71.25022178,
    "marketplace_to_demand": 110.0638825,
    "grid_to_demand": 30415.39605,
    "govt_battery_charge": 27.07539645
  },
  {
    "timestamp": "07/04/24 10:00",
    "demand": 32281.2877,
    "solar_generated": 15.81937406,
    "wind_generated": 61.45951885,
    "marketplace_to_demand": 12.29424528,
    "grid_to_demand": 34500.53643,
    "govt_battery_charge": 10.00469757
  },
  {
    "timestamp": "07/04/24 11:00",
    "demand": 34801.7021,
    "solar_generated": 22.70562343,
    "wind_generated": 66.55233567,
    "marketplace_to_demand": 56.27246215,
    "grid_to_demand": 36148.83422,
    "govt_battery_charge": 91.71515845
  },
  {
    "timestamp": "07/04/24 12:00",
    "demand": 35624.40823,
    "solar_generated": 19.97991978,
    "wind_generated": 68.84731523,
    "marketplace_to_demand": 86.17587542,
    "grid_to_demand": 34418.92555,
    "govt_battery_charge": 76.22064534
  },
  {
    "timestamp": "07/04/24 13:00",
    "demand": 33602.2415,
    "solar_generated": 13.14581209,
    "wind_generated": 73.0178122,
    "marketplace_to_demand": 178.9080265,
    "grid_to_demand": 36197.59508,
    "govt_battery_charge": 39.20333787
  },
  {
    "timestamp": "07/04/24 14:00",
    "demand": 33651.21706,
    "solar_generated": 13.05110238,
    "wind_generated": 80.01985337,
    "marketplace_to_demand": 76.53228715,
    "grid_to_demand": 34865.78497,
    "govt_battery_charge": 59.73798569
  },
  {
    "timestamp": "07/04/24 15:00",
    "demand": 35213.83976,
    "solar_generated": 19.21267564,
    "wind_generated": 75.0821916,
    "marketplace_to_demand": 5.009018016,
    "grid_to_demand": 33650.99847,
    "govt_battery_charge": 76.76821671
  },
  {
    "timestamp": "07/04/24 16:00",
    "demand": 35841.65087,
    "solar_generated": 21.12348134,
    "wind_generated": 65.46513896,
    "marketplace_to_demand": 60.32916672,
    "grid_to_demand": 31445.86124,
    "govt_battery_charge": 27.68959553
  },
  {
    "timestamp": "07/04/24 17:00",
    "demand": 32595.06704,
    "solar_generated": 10.83198811,
    "wind_generated": 71.40607022,
    "marketplace_to_demand": 55.16830391,
    "grid_to_demand": 30956.23886,
    "govt_battery_charge": 93.10087955
  },
  {
    "timestamp": "07/04/24 18:00",
    "demand": 30398.27867,
    "solar_generated": 0,
    "wind_generated": 59.51643302,
    "marketplace_to_demand": 174.7188614,
    "grid_to_demand": 31713.93484,
    "govt_battery_charge": 76.77915373
  },
  {
    "timestamp": "07/04/24 19:00",
    "demand": 33389.36567,
    "solar_generated": 0,
    "wind_generated": 69.26385636,
    "marketplace_to_demand": 63.35852467,
    "grid_to_demand": 33925.63207,
    "govt_battery_charge": 91.4283075
  },
  {
    "timestamp": "07/04/24 20:00",
    "demand": 36018.06568,
    "solar_generated": 0,
    "wind_generated": 77.40047931,
    "marketplace_to_demand": 107.1121862,
    "grid_to_demand": 33700.68256,
    "govt_battery_charge": 80.9108354
  },
  {
    "timestamp": "07/04/24 21:00",
    "demand": 33515.47393,
    "solar_generated": 0,
    "wind_generated": 61.80143652,
    "marketplace_to_demand": 135.1079499,
    "grid_to_demand": 34069.64256,
    "govt_battery_charge": 87.64743959
  },
  {
    "timestamp": "07/04/24 22:00",
    "demand": 36430.47854,
    "solar_generated": 0,
    "wind_generated": 54.95325944,
    "marketplace_to_demand": 180.7210967,
    "grid_to_demand": 35965.52226,
    "govt_battery_charge": 69.02129527
  },
  {
    "timestamp": "07/04/24 23:00",
    "demand": 36293.02063,
    "solar_generated": 0,
    "wind_generated": 77.69263376,
    "marketplace_to_demand": 155.7594833,
    "grid_to_demand": 33383.23032,
    "govt_battery_charge": 31.01433422
  },
  {
    "timestamp": "08/04/24 0:00",
    "demand": 33876.85819,
    "solar_generated": 0,
    "wind_generated": 59.4222745,
    "marketplace_to_demand": 7.465341801,
    "grid_to_demand": 34909.50541,
    "govt_battery_charge": 8.65151939
  },
  {
    "timestamp": "08/04/24 1:00",
    "demand": 30852.10365,
    "solar_generated": 0,
    "wind_generated": 74.03004935,
    "marketplace_to_demand": 163.9191426,
    "grid_to_demand": 33664.24049,
    "govt_battery_charge": 26.79466976
  },
  {
    "timestamp": "08/04/24 2:00",
    "demand": 33509.54541,
    "solar_generated": 0,
    "wind_generated": 65.06813139,
    "marketplace_to_demand": 187.8065965,
    "grid_to_demand": 30047.4858,
    "govt_battery_charge": 5.630964454
  },
  {
    "timestamp": "08/04/24 3:00",
    "demand": 31327.30972,
    "solar_generated": 0,
    "wind_generated": 64.92412629,
    "marketplace_to_demand": 52.30821562,
    "grid_to_demand": 30946.25021,
    "govt_battery_charge": 31.95308621
  },
  {
    "timestamp": "08/04/24 4:00",
    "demand": 30128.16278,
    "solar_generated": 0,
    "wind_generated": 77.75961842,
    "marketplace_to_demand": 156.3046024,
    "grid_to_demand": 31066.52409,
    "govt_battery_charge": 18.84897969
  },
  {
    "timestamp": "08/04/24 5:00",
    "demand": 33034.33299,
    "solar_generated": 0,
    "wind_generated": 72.94944509,
    "marketplace_to_demand": 172.5774642,
    "grid_to_demand": 34540.83832,
    "govt_battery_charge": 61.95142255
  },
  {
    "timestamp": "08/04/24 6:00",
    "demand": 32803.37079,
    "solar_generated": 23.23260364,
    "wind_generated": 62.57378154,
    "marketplace_to_demand": 54.46222784,
    "grid_to_demand": 32447.06925,
    "govt_battery_charge": 45.8178555
  },
  {
    "timestamp": "08/04/24 7:00",
    "demand": 30953.6228,
    "solar_generated": 6.627748637,
    "wind_generated": 54.67620475,
    "marketplace_to_demand": 6.034527954,
    "grid_to_demand": 33896.55051,
    "govt_battery_charge": 48.0636016
  },
  {
    "timestamp": "08/04/24 8:00",
    "demand": 32665.0438,
    "solar_generated": 17.15486785,
    "wind_generated": 53.93790466,
    "marketplace_to_demand": 143.4531955,
    "grid_to_demand": 34404.78484,
    "govt_battery_charge": 85.18405376
  },
  {
    "timestamp": "08/04/24 9:00",
    "demand": 31165.77465,
    "solar_generated": 19.77049245,
    "wind_generated": 66.14767092,
    "marketplace_to_demand": 29.52282239,
    "grid_to_demand": 32839.95777,
    "govt_battery_charge": 96.55415827
  },
  {
    "timestamp": "08/04/24 10:00",
    "demand": 31858.78506,
    "solar_generated": 19.54428517,
    "wind_generated": 79.99599661,
    "marketplace_to_demand": 169.4062043,
    "grid_to_demand": 30527.69394,
    "govt_battery_charge": 11.16655207
  },
  {
    "timestamp": "08/04/24 11:00",
    "demand": 32999.46585,
    "solar_generated": 13.04295884,
    "wind_generated": 69.33473562,
    "marketplace_to_demand": 69.89631314,
    "grid_to_demand": 34806.65775,
    "govt_battery_charge": 1.888304785
  },
  {
    "timestamp": "08/04/24 12:00",
    "demand": 31674.30125,
    "solar_generated": 19.25688392,
    "wind_generated": 73.40832501,
    "marketplace_to_demand": 79.08576192,
    "grid_to_demand": 34855.20953,
    "govt_battery_charge": 42.63166237
  },
  {
    "timestamp": "08/04/24 13:00",
    "demand": 31130.69897,
    "solar_generated": 17.70526898,
    "wind_generated": 75.48627842,
    "marketplace_to_demand": 84.0655608,
    "grid_to_demand": 31898.42231,
    "govt_battery_charge": 54.08778388
  },
  {
    "timestamp": "08/04/24 14:00",
    "demand": 31106.37747,
    "solar_generated": 7.994711859,
    "wind_generated": 73.76907612,
    "marketplace_to_demand": 140.0384349,
    "grid_to_demand": 30257.28686,
    "govt_battery_charge": 70.84509893
  },
  {
    "timestamp": "08/04/24 15:00",
    "demand": 36288.05746,
    "solar_generated": 10.11045321,
    "wind_generated": 58.12867804,
    "marketplace_to_demand": 42.98455972,
    "grid_to_demand": 33154.84712,
    "govt_battery_charge": 32.3363041
  },
  {
    "timestamp": "08/04/24 16:00",
    "demand": 34624.34755,
    "solar_generated": 11.59268585,
    "wind_generated": 54.86155255,
    "marketplace_to_demand": 179.2611234,
    "grid_to_demand": 34811.4425,
    "govt_battery_charge": 21.62916155
  },
  {
    "timestamp": "08/04/24 17:00",
    "demand": 33144.26717,
    "solar_generated": 22.37575328,
    "wind_generated": 74.37344855,
    "marketplace_to_demand": 10.85161881,
    "grid_to_demand": 29998.3855,
    "govt_battery_charge": 81.04914806
  },
  {
    "timestamp": "08/04/24 18:00",
    "demand": 35321.56622,
    "solar_generated": 0,
    "wind_generated": 57.83124769,
    "marketplace_to_demand": 160.3161182,
    "grid_to_demand": 36377.17092,
    "govt_battery_charge": 30.82234159
  },
  {
    "timestamp": "08/04/24 19:00",
    "demand": 30566.34476,
    "solar_generated": 0,
    "wind_generated": 74.72163242,
    "marketplace_to_demand": 147.8638788,
    "grid_to_demand": 33645.5372,
    "govt_battery_charge": 31.9408824
  },
  {
    "timestamp": "08/04/24 20:00",
    "demand": 33822.05142,
    "solar_generated": 0,
    "wind_generated": 76.89724521,
    "marketplace_to_demand": 4.111441495,
    "grid_to_demand": 31909.52776,
    "govt_battery_charge": 22.77331376
  },
  {
    "timestamp": "08/04/24 21:00",
    "demand": 30385.63549,
    "solar_generated": 0,
    "wind_generated": 56.20861543,
    "marketplace_to_demand": 53.03097304,
    "grid_to_demand": 34037.56217,
    "govt_battery_charge": 78.99841905
  },
  {
    "timestamp": "08/04/24 22:00",
    "demand": 35291.19983,
    "solar_generated": 0,
    "wind_generated": 78.93415923,
    "marketplace_to_demand": 152.3920559,
    "grid_to_demand": 34044.81716,
    "govt_battery_charge": 6.71544729
  },
  {
    "timestamp": "08/04/24 23:00",
    "demand": 31226.1204,
    "solar_generated": 0,
    "wind_generated": 71.29880968,
    "marketplace_to_demand": 161.1111582,
    "grid_to_demand": 34106.23224,
    "govt_battery_charge": 66.51069399
  },
  {
    "timestamp": "09/04/24 0:00",
    "demand": 34800.96065,
    "solar_generated": 0,
    "wind_generated": 70.96109432,
    "marketplace_to_demand": 133.6111955,
    "grid_to_demand": 35703.52597,
    "govt_battery_charge": 27.84603286
  },
  {
    "timestamp": "09/04/24 1:00",
    "demand": 35169.70438,
    "solar_generated": 0,
    "wind_generated": 76.68025468,
    "marketplace_to_demand": 169.5332174,
    "grid_to_demand": 32297.45869,
    "govt_battery_charge": 49.59534243
  },
  {
    "timestamp": "09/04/24 2:00",
    "demand": 35503.88846,
    "solar_generated": 0,
    "wind_generated": 75.16949637,
    "marketplace_to_demand": 176.9751743,
    "grid_to_demand": 32369.4605,
    "govt_battery_charge": 58.20714889
  },
  {
    "timestamp": "09/04/24 3:00",
    "demand": 35441.6892,
    "solar_generated": 0,
    "wind_generated": 68.834337,
    "marketplace_to_demand": 70.83203944,
    "grid_to_demand": 35299.38244,
    "govt_battery_charge": 58.64791788
  },
  {
    "timestamp": "09/04/24 4:00",
    "demand": 32595.01494,
    "solar_generated": 0,
    "wind_generated": 64.86934972,
    "marketplace_to_demand": 117.3142179,
    "grid_to_demand": 32938.04695,
    "govt_battery_charge": 81.60559675
  },
  {
    "timestamp": "09/04/24 5:00",
    "demand": 33528.83639,
    "solar_generated": 0,
    "wind_generated": 55.1719671,
    "marketplace_to_demand": 89.10746757,
    "grid_to_demand": 32200.5692,
    "govt_battery_charge": 1.438383573
  },
  {
    "timestamp": "09/04/24 6:00",
    "demand": 30573.61903,
    "solar_generated": 14.44906919,
    "wind_generated": 67.54425781,
    "marketplace_to_demand": 22.63501793,
    "grid_to_demand": 32716.58123,
    "govt_battery_charge": 35.77089927
  },
  {
    "timestamp": "09/04/24 7:00",
    "demand": 33627.60288,
    "solar_generated": 8.935558795,
    "wind_generated": 75.67447748,
    "marketplace_to_demand": 24.24451213,
    "grid_to_demand": 36184.63513,
    "govt_battery_charge": 55.69339011
  },
  {
    "timestamp": "09/04/24 8:00",
    "demand": 32578.18754,
    "solar_generated": 20.6120588,
    "wind_generated": 73.95706036,
    "marketplace_to_demand": 67.75400736,
    "grid_to_demand": 31752.53585,
    "govt_battery_charge": 61.22210671
  },
  {
    "timestamp": "09/04/24 9:00",
    "demand": 33370.28526,
    "solar_generated": 21.05609735,
    "wind_generated": 75.92597117,
    "marketplace_to_demand": 145.054297,
    "grid_to_demand": 33242.9182,
    "govt_battery_charge": 46.30031463
  },
  {
    "timestamp": "09/04/24 10:00",
    "demand": 34087.3909,
    "solar_generated": 7.729650961,
    "wind_generated": 77.33496194,
    "marketplace_to_demand": 55.63700586,
    "grid_to_demand": 29953.38112,
    "govt_battery_charge": 58.53063234
  },
  {
    "timestamp": "09/04/24 11:00",
    "demand": 34950.92909,
    "solar_generated": 13.80915962,
    "wind_generated": 57.90688269,
    "marketplace_to_demand": 89.77154201,
    "grid_to_demand": 36110.57966,
    "govt_battery_charge": 78.70301142
  },
  {
    "timestamp": "09/04/24 12:00",
    "demand": 31393.62518,
    "solar_generated": 14.81654816,
    "wind_generated": 57.38247401,
    "marketplace_to_demand": 150.050038,
    "grid_to_demand": 33756.3823,
    "govt_battery_charge": 5.322979288
  },
  {
    "timestamp": "09/04/24 13:00",
    "demand": 34528.96361,
    "solar_generated": 22.55602556,
    "wind_generated": 69.4814226,
    "marketplace_to_demand": 70.56677778,
    "grid_to_demand": 33683.45741,
    "govt_battery_charge": 48.1273109
  },
  {
    "timestamp": "09/04/24 14:00",
    "demand": 33945.36183,
    "solar_generated": 18.407012,
    "wind_generated": 73.61964627,
    "marketplace_to_demand": 97.65216225,
    "grid_to_demand": 30668.33995,
    "govt_battery_charge": 20.73287691
  },
  {
    "timestamp": "09/04/24 15:00",
    "demand": 36173.00083,
    "solar_generated": 23.41404139,
    "wind_generated": 76.74970837,
    "marketplace_to_demand": 152.1213852,
    "grid_to_demand": 33379.8063,
    "govt_battery_charge": 54.33842922
  },
  {
    "timestamp": "09/04/24 16:00",
    "demand": 33255.45678,
    "solar_generated": 11.64964655,
    "wind_generated": 67.95136528,
    "marketplace_to_demand": 184.6300159,
    "grid_to_demand": 32350.2706,
    "govt_battery_charge": 21.16055609
  },
  {
    "timestamp": "09/04/24 17:00",
    "demand": 30930.70032,
    "solar_generated": 22.99942296,
    "wind_generated": 69.51336444,
    "marketplace_to_demand": 21.34714144,
    "grid_to_demand": 30207.27452,
    "govt_battery_charge": 77.72430853
  },
  {
    "timestamp": "09/04/24 18:00",
    "demand": 35902.46864,
    "solar_generated": 0,
    "wind_generated": 62.10755989,
    "marketplace_to_demand": 1.409537021,
    "grid_to_demand": 36098.47567,
    "govt_battery_charge": 66.91675236
  },
  {
    "timestamp": "09/04/24 19:00",
    "demand": 35983.65039,
    "solar_generated": 0,
    "wind_generated": 62.31388318,
    "marketplace_to_demand": 132.6445774,
    "grid_to_demand": 35672.84777,
    "govt_battery_charge": 23.7354897
  },
  {
    "timestamp": "09/04/24 20:00",
    "demand": 32956.71544,
    "solar_generated": 0,
    "wind_generated": 63.60378253,
    "marketplace_to_demand": 12.60150212,
    "grid_to_demand": 34001.04687,
    "govt_battery_charge": 77.14915108
  },
  {
    "timestamp": "09/04/24 21:00",
    "demand": 34859.22089,
    "solar_generated": 0,
    "wind_generated": 61.87491663,
    "marketplace_to_demand": 139.1601349,
    "grid_to_demand": 30519.36817,
    "govt_battery_charge": 50.67390827
  },
  {
    "timestamp": "09/04/24 22:00",
    "demand": 35910.77582,
    "solar_generated": 0,
    "wind_generated": 73.50035972,
    "marketplace_to_demand": 85.17042239,
    "grid_to_demand": 30866.54695,
    "govt_battery_charge": 34.19220748
  },
  {
    "timestamp": "09/04/24 23:00",
    "demand": 31803.03156,
    "solar_generated": 0,
    "wind_generated": 74.69697554,
    "marketplace_to_demand": 184.7326369,
    "grid_to_demand": 32887.41588,
    "govt_battery_charge": 98.51919906
  },
  {
    "timestamp": "10/04/24 0:00",
    "demand": 35775.47309,
    "solar_generated": 0,
    "wind_generated": 71.84598023,
    "marketplace_to_demand": 102.2867371,
    "grid_to_demand": 31349.83457,
    "govt_battery_charge": 46.8575907
  },
  {
    "timestamp": "10/04/24 1:00",
    "demand": 30680.12902,
    "solar_generated": 0,
    "wind_generated": 73.53535509,
    "marketplace_to_demand": 81.84465136,
    "grid_to_demand": 36179.09818,
    "govt_battery_charge": 96.12701142
  },
  {
    "timestamp": "10/04/24 2:00",
    "demand": 36022.89203,
    "solar_generated": 0,
    "wind_generated": 72.50575071,
    "marketplace_to_demand": 123.3736353,
    "grid_to_demand": 33239.77843,
    "govt_battery_charge": 40.13255156
  },
  {
    "timestamp": "10/04/24 3:00",
    "demand": 35716.2497,
    "solar_generated": 0,
    "wind_generated": 76.93318142,
    "marketplace_to_demand": 103.2270577,
    "grid_to_demand": 33455.20913,
    "govt_battery_charge": 5.25394017
  },
  {
    "timestamp": "10/04/24 4:00",
    "demand": 36018.39799,
    "solar_generated": 0,
    "wind_generated": 65.69152736,
    "marketplace_to_demand": 63.02179828,
    "grid_to_demand": 31060.09863,
    "govt_battery_charge": 78.93424567
  },
  {
    "timestamp": "10/04/24 5:00",
    "demand": 30168.72725,
    "solar_generated": 0,
    "wind_generated": 54.33484517,
    "marketplace_to_demand": 18.41873171,
    "grid_to_demand": 32700.62638,
    "govt_battery_charge": 4.318230633
  },
  {
    "timestamp": "10/04/24 6:00",
    "demand": 30470.90227,
    "solar_generated": 17.02237095,
    "wind_generated": 60.0637139,
    "marketplace_to_demand": 91.12211729,
    "grid_to_demand": 31023.97477,
    "govt_battery_charge": 75.71400446
  },
  {
    "timestamp": "10/04/24 7:00",
    "demand": 35018.41557,
    "solar_generated": 21.84353256,
    "wind_generated": 64.14425284,
    "marketplace_to_demand": 132.622131,
    "grid_to_demand": 32480.29347,
    "govt_battery_charge": 15.34654472
  },
  {
    "timestamp": "10/04/24 8:00",
    "demand": 32393.98819,
    "solar_generated": 16.02503561,
    "wind_generated": 67.6661169,
    "marketplace_to_demand": 7.963302104,
    "grid_to_demand": 33964.7657,
    "govt_battery_charge": 35.01919903
  },
  {
    "timestamp": "10/04/24 9:00",
    "demand": 34137.63312,
    "solar_generated": 13.47990641,
    "wind_generated": 64.35109414,
    "marketplace_to_demand": 34.08919931,
    "grid_to_demand": 36097.37185,
    "govt_battery_charge": 87.46278181
  },
  {
    "timestamp": "10/04/24 10:00",
    "demand": 31944.42114,
    "solar_generated": 8.185885847,
    "wind_generated": 61.08884317,
    "marketplace_to_demand": 149.4359308,
    "grid_to_demand": 34106.09134,
    "govt_battery_charge": 51.25233205
  },
  {
    "timestamp": "10/04/24 11:00",
    "demand": 31580.04416,
    "solar_generated": 8.033520963,
    "wind_generated": 60.56990865,
    "marketplace_to_demand": 166.0576603,
    "grid_to_demand": 30949.39398,
    "govt_battery_charge": 71.31870935
  },
  {
    "timestamp": "10/04/24 12:00",
    "demand": 30265.9752,
    "solar_generated": 11.09466183,
    "wind_generated": 61.90849545,
    "marketplace_to_demand": 138.2476378,
    "grid_to_demand": 32745.93427,
    "govt_battery_charge": 64.46311485
  },
  {
    "timestamp": "10/04/24 13:00",
    "demand": 34935.04759,
    "solar_generated": 16.09036827,
    "wind_generated": 75.8919968,
    "marketplace_to_demand": 38.99534058,
    "grid_to_demand": 34974.28179,
    "govt_battery_charge": 47.05718021
  },
  {
    "timestamp": "10/04/24 14:00",
    "demand": 34890.44428,
    "solar_generated": 15.56054628,
    "wind_generated": 59.96078827,
    "marketplace_to_demand": 9.584877899,
    "grid_to_demand": 35720.55195,
    "govt_battery_charge": 88.88131587
  },
  {
    "timestamp": "10/04/24 15:00",
    "demand": 33408.47475,
    "solar_generated": 7.378933766,
    "wind_generated": 79.97527083,
    "marketplace_to_demand": 137.0890299,
    "grid_to_demand": 36281.33422,
    "govt_battery_charge": 6.155894041
  },
  {
    "timestamp": "10/04/24 16:00",
    "demand": 34437.29929,
    "solar_generated": 14.181657,
    "wind_generated": 76.05622732,
    "marketplace_to_demand": 142.0130154,
    "grid_to_demand": 35702.81698,
    "govt_battery_charge": 21.99974908
  },
  {
    "timestamp": "10/04/24 17:00",
    "demand": 32837.40624,
    "solar_generated": 16.58207196,
    "wind_generated": 74.51480758,
    "marketplace_to_demand": 129.4053314,
    "grid_to_demand": 30083.93363,
    "govt_battery_charge": 72.44828954
  },
  {
    "timestamp": "10/04/24 18:00",
    "demand": 32161.51307,
    "solar_generated": 0,
    "wind_generated": 60.01025789,
    "marketplace_to_demand": 108.2225154,
    "grid_to_demand": 32023.32022,
    "govt_battery_charge": 1.368486977
  },
  {
    "timestamp": "10/04/24 19:00",
    "demand": 30879.7661,
    "solar_generated": 0,
    "wind_generated": 59.94613286,
    "marketplace_to_demand": 154.865521,
    "grid_to_demand": 33996.31333,
    "govt_battery_charge": 92.11831626
  },
  {
    "timestamp": "10/04/24 20:00",
    "demand": 36584.23779,
    "solar_generated": 0,
    "wind_generated": 74.44574147,
    "marketplace_to_demand": 117.8399344,
    "grid_to_demand": 30818.73793,
    "govt_battery_charge": 18.49201197
  },
  {
    "timestamp": "10/04/24 21:00",
    "demand": 32361.3324,
    "solar_generated": 0,
    "wind_generated": 76.4782927,
    "marketplace_to_demand": 173.3312775,
    "grid_to_demand": 33214.54372,
    "govt_battery_charge": 91.93327325
  },
  {
    "timestamp": "10/04/24 22:00",
    "demand": 30403.7858,
    "solar_generated": 0,
    "wind_generated": 58.64269467,
    "marketplace_to_demand": 4.404170129,
    "grid_to_demand": 34419.25976,
    "govt_battery_charge": 13.4138682
  },
  {
    "timestamp": "10/04/24 23:00",
    "demand": 35354.39816,
    "solar_generated": 0,
    "wind_generated": 63.21945248,
    "marketplace_to_demand": 4.55727361,
    "grid_to_demand": 32405.62068,
    "govt_battery_charge": 63.96783255
  },
  {
    "timestamp": "11/04/24 0:00",
    "demand": 30932.59165,
    "solar_generated": 0,
    "wind_generated": 64.83444524,
    "marketplace_to_demand": 120.9144143,
    "grid_to_demand": 33909.53698,
    "govt_battery_charge": 47.33107503
  },
  {
    "timestamp": "11/04/24 1:00",
    "demand": 31559.15045,
    "solar_generated": 0,
    "wind_generated": 75.52346219,
    "marketplace_to_demand": 66.41597423,
    "grid_to_demand": 36385.24336,
    "govt_battery_charge": 23.49972144
  },
  {
    "timestamp": "11/04/24 2:00",
    "demand": 33069.64592,
    "solar_generated": 0,
    "wind_generated": 67.85921468,
    "marketplace_to_demand": 22.71896542,
    "grid_to_demand": 35430.14401,
    "govt_battery_charge": 35.19816057
  },
  {
    "timestamp": "11/04/24 3:00",
    "demand": 36227.76999,
    "solar_generated": 0,
    "wind_generated": 66.18256502,
    "marketplace_to_demand": 107.3008111,
    "grid_to_demand": 32805.94661,
    "govt_battery_charge": 36.30880497
  },
  {
    "timestamp": "11/04/24 4:00",
    "demand": 33516.17511,
    "solar_generated": 0,
    "wind_generated": 74.56328526,
    "marketplace_to_demand": 169.5098681,
    "grid_to_demand": 35646.72284,
    "govt_battery_charge": 16.02530471
  },
  {
    "timestamp": "11/04/24 5:00",
    "demand": 33262.69689,
    "solar_generated": 0,
    "wind_generated": 62.38586974,
    "marketplace_to_demand": 161.7792161,
    "grid_to_demand": 33644.37341,
    "govt_battery_charge": 12.73523177
  },
  {
    "timestamp": "11/04/24 6:00",
    "demand": 31280.69405,
    "solar_generated": 10.16311699,
    "wind_generated": 56.75482752,
    "marketplace_to_demand": 28.70120196,
    "grid_to_demand": 35418.25976,
    "govt_battery_charge": 71.18376192
  },
  {
    "timestamp": "11/04/24 7:00",
    "demand": 36377.04059,
    "solar_generated": 22.72053451,
    "wind_generated": 61.64933296,
    "marketplace_to_demand": 70.76660172,
    "grid_to_demand": 31215.42703,
    "govt_battery_charge": 34.25900179
  },
  {
    "timestamp": "11/04/24 8:00",
    "demand": 31814.27996,
    "solar_generated": 12.50470126,
    "wind_generated": 72.79229407,
    "marketplace_to_demand": 4.880922039,
    "grid_to_demand": 30677.60955,
    "govt_battery_charge": 53.45244399
  },
  {
    "timestamp": "11/04/24 9:00",
    "demand": 30211.75981,
    "solar_generated": 13.10452383,
    "wind_generated": 75.61748455,
    "marketplace_to_demand": 186.6378529,
    "grid_to_demand": 33743.34813,
    "govt_battery_charge": 30.60765086
  },
  {
    "timestamp": "11/04/24 10:00",
    "demand": 35118.82679,
    "solar_generated": 19.44223171,
    "wind_generated": 72.2941808,
    "marketplace_to_demand": 19.69827048,
    "grid_to_demand": 33334.89681,
    "govt_battery_charge": 65.21987957
  },
  {
    "timestamp": "11/04/24 11:00",
    "demand": 30577.27787,
    "solar_generated": 20.95011733,
    "wind_generated": 55.75061194,
    "marketplace_to_demand": 81.08372473,
    "grid_to_demand": 33545.85278,
    "govt_battery_charge": 6.931884081
  },
  {
    "timestamp": "11/04/24 12:00",
    "demand": 30634.92743,
    "solar_generated": 11.73386925,
    "wind_generated": 65.73228861,
    "marketplace_to_demand": 180.87228,
    "grid_to_demand": 36436.51968,
    "govt_battery_charge": 53.21505746
  },
  {
    "timestamp": "11/04/24 13:00",
    "demand": 30624.57961,
    "solar_generated": 7.739483815,
    "wind_generated": 73.19361084,
    "marketplace_to_demand": 173.3504622,
    "grid_to_demand": 35545.49626,
    "govt_battery_charge": 38.39843288
  },
  {
    "timestamp": "11/04/24 14:00",
    "demand": 32912.34171,
    "solar_generated": 23.7751432,
    "wind_generated": 80.05876606,
    "marketplace_to_demand": 165.0524481,
    "grid_to_demand": 33555.20813,
    "govt_battery_charge": 87.82110552
  },
  {
    "timestamp": "11/04/24 15:00",
    "demand": 31186.35817,
    "solar_generated": 9.84980458,
    "wind_generated": 63.48450811,
    "marketplace_to_demand": 148.9019171,
    "grid_to_demand": 35836.35436,
    "govt_battery_charge": 6.604527646
  },
  {
    "timestamp": "11/04/24 16:00",
    "demand": 35125.96339,
    "solar_generated": 15.49762271,
    "wind_generated": 72.51876631,
    "marketplace_to_demand": 169.9813274,
    "grid_to_demand": 35892.58034,
    "govt_battery_charge": 67.29984912
  },
  {
    "timestamp": "11/04/24 17:00",
    "demand": 32881.37086,
    "solar_generated": 19.15857208,
    "wind_generated": 70.44030718,
    "marketplace_to_demand": 80.19189713,
    "grid_to_demand": 35822.81493,
    "govt_battery_charge": 82.03221115
  },
  {
    "timestamp": "11/04/24 18:00",
    "demand": 35709.44494,
    "solar_generated": 0,
    "wind_generated": 74.26922999,
    "marketplace_to_demand": 21.35539632,
    "grid_to_demand": 30041.10457,
    "govt_battery_charge": 74.74133
  },
  {
    "timestamp": "11/04/24 19:00",
    "demand": 31582.08892,
    "solar_generated": 0,
    "wind_generated": 70.73252652,
    "marketplace_to_demand": 111.1808097,
    "grid_to_demand": 35889.41303,
    "govt_battery_charge": 87.65097376
  },
  {
    "timestamp": "11/04/24 20:00",
    "demand": 31492.59922,
    "solar_generated": 0,
    "wind_generated": 63.34982359,
    "marketplace_to_demand": 187.8658535,
    "grid_to_demand": 31829.31236,
    "govt_battery_charge": 3.117450062
  },
  {
    "timestamp": "11/04/24 21:00",
    "demand": 34613.84025,
    "solar_generated": 0,
    "wind_generated": 74.04514277,
    "marketplace_to_demand": 45.40626989,
    "grid_to_demand": 35541.87508,
    "govt_battery_charge": 77.66919497
  },
  {
    "timestamp": "11/04/24 22:00",
    "demand": 33883.92506,
    "solar_generated": 0,
    "wind_generated": 76.70478323,
    "marketplace_to_demand": 31.2484803,
    "grid_to_demand": 30817.51118,
    "govt_battery_charge": 48.23252016
  },
  {
    "timestamp": "11/04/24 23:00",
    "demand": 35926.84638,
    "solar_generated": 0,
    "wind_generated": 71.69311753,
    "marketplace_to_demand": 85.36866386,
    "grid_to_demand": 36419.37179,
    "govt_battery_charge": 95.44088718
  },
  {
    "timestamp": "12/04/24 0:00",
    "demand": 32972.385,
    "solar_generated": 0,
    "wind_generated": 54.04059693,
    "marketplace_to_demand": 157.5539906,
    "grid_to_demand": 32863.79526,
    "govt_battery_charge": 68.77304645
  },
  {
    "timestamp": "12/04/24 1:00",
    "demand": 33720.95644,
    "solar_generated": 0,
    "wind_generated": 70.09458265,
    "marketplace_to_demand": 155.2698664,
    "grid_to_demand": 33473.69437,
    "govt_battery_charge": 76.82331897
  },
  {
    "timestamp": "12/04/24 2:00",
    "demand": 31262.94445,
    "solar_generated": 0,
    "wind_generated": 72.82357538,
    "marketplace_to_demand": 117.3687607,
    "grid_to_demand": 31055.66717,
    "govt_battery_charge": 82.0031672
  },
  {
    "timestamp": "12/04/24 3:00",
    "demand": 35687.06185,
    "solar_generated": 0,
    "wind_generated": 56.70387499,
    "marketplace_to_demand": 185.8531203,
    "grid_to_demand": 33608.26885,
    "govt_battery_charge": 85.84308767
  },
  {
    "timestamp": "12/04/24 4:00",
    "demand": 33821.89476,
    "solar_generated": 0,
    "wind_generated": 74.61986105,
    "marketplace_to_demand": 190.7097484,
    "grid_to_demand": 35129.60374,
    "govt_battery_charge": 55.95627144
  },
  {
    "timestamp": "12/04/24 5:00",
    "demand": 33881.21777,
    "solar_generated": 0,
    "wind_generated": 75.04937848,
    "marketplace_to_demand": 1.484477511,
    "grid_to_demand": 30754.97846,
    "govt_battery_charge": 11.51977142
  },
  {
    "timestamp": "12/04/24 6:00",
    "demand": 31254.48043,
    "solar_generated": 8.525337574,
    "wind_generated": 55.38374603,
    "marketplace_to_demand": 135.936696,
    "grid_to_demand": 34330.55418,
    "govt_battery_charge": 10.61584775
  },
  {
    "timestamp": "12/04/24 7:00",
    "demand": 32311.01434,
    "solar_generated": 13.33378881,
    "wind_generated": 77.59567474,
    "marketplace_to_demand": 159.2292958,
    "grid_to_demand": 35590.9939,
    "govt_battery_charge": 39.67508068
  },
  {
    "timestamp": "12/04/24 8:00",
    "demand": 31388.37297,
    "solar_generated": 15.69028517,
    "wind_generated": 74.32256242,
    "marketplace_to_demand": 47.78233988,
    "grid_to_demand": 31205.99309,
    "govt_battery_charge": 88.55334177
  },
  {
    "timestamp": "12/04/24 9:00",
    "demand": 30540.50755,
    "solar_generated": 11.85382813,
    "wind_generated": 65.9351633,
    "marketplace_to_demand": 20.66637255,
    "grid_to_demand": 30958.87861,
    "govt_battery_charge": 34.52975263
  },
  {
    "timestamp": "12/04/24 10:00",
    "demand": 31597.23643,
    "solar_generated": 9.048186043,
    "wind_generated": 79.89333942,
    "marketplace_to_demand": 5.84493042,
    "grid_to_demand": 32401.12368,
    "govt_battery_charge": 79.1052103
  },
  {
    "timestamp": "12/04/24 11:00",
    "demand": 31048.31335,
    "solar_generated": 11.47525021,
    "wind_generated": 65.62191699,
    "marketplace_to_demand": 96.10979803,
    "grid_to_demand": 33378.50043,
    "govt_battery_charge": 22.64377231
  },
  {
    "timestamp": "12/04/24 12:00",
    "demand": 32754.31388,
    "solar_generated": 7.01621828,
    "wind_generated": 53.85683124,
    "marketplace_to_demand": 121.4994643,
    "grid_to_demand": 34938.23815,
    "govt_battery_charge": 66.14756824
  },
  {
    "timestamp": "12/04/24 13:00",
    "demand": 34644.86181,
    "solar_generated": 21.09449857,
    "wind_generated": 80.15322222,
    "marketplace_to_demand": 60.1579914,
    "grid_to_demand": 32644.4522,
    "govt_battery_charge": 45.28104993
  },
  {
    "timestamp": "12/04/24 14:00",
    "demand": 33596.26829,
    "solar_generated": 21.29902571,
    "wind_generated": 57.45626203,
    "marketplace_to_demand": 23.88610202,
    "grid_to_demand": 33205.26722,
    "govt_battery_charge": 29.9864587
  },
  {
    "timestamp": "12/04/24 15:00",
    "demand": 32774.61307,
    "solar_generated": 6.932865091,
    "wind_generated": 56.15867762,
    "marketplace_to_demand": 110.3407159,
    "grid_to_demand": 31527.21365,
    "govt_battery_charge": 14.51299315
  },
  {
    "timestamp": "12/04/24 16:00",
    "demand": 35851.62153,
    "solar_generated": 19.62546543,
    "wind_generated": 63.69961396,
    "marketplace_to_demand": 198.8560288,
    "grid_to_demand": 34495.02699,
    "govt_battery_charge": 69.00864907
  },
  {
    "timestamp": "12/04/24 17:00",
    "demand": 32667.75797,
    "solar_generated": 12.35940296,
    "wind_generated": 65.75086165,
    "marketplace_to_demand": 196.7298787,
    "grid_to_demand": 35625.48667,
    "govt_battery_charge": 41.37763789
  },
  {
    "timestamp": "12/04/24 18:00",
    "demand": 30312.9255,
    "solar_generated": 0,
    "wind_generated": 60.45808581,
    "marketplace_to_demand": 181.5299434,
    "grid_to_demand": 32152.33345,
    "govt_battery_charge": 49.56309232
  },
  {
    "timestamp": "12/04/24 19:00",
    "demand": 32253.33965,
    "solar_generated": 0,
    "wind_generated": 66.59379753,
    "marketplace_to_demand": 130.2905533,
    "grid_to_demand": 30114.07418,
    "govt_battery_charge": 33.62157748
  },
  {
    "timestamp": "12/04/24 20:00",
    "demand": 32183.60658,
    "solar_generated": 0,
    "wind_generated": 73.70819144,
    "marketplace_to_demand": 9.157633801,
    "grid_to_demand": 31090.8711,
    "govt_battery_charge": 7.24367525
  },
  {
    "timestamp": "12/04/24 21:00",
    "demand": 32089.24647,
    "solar_generated": 0,
    "wind_generated": 57.23360248,
    "marketplace_to_demand": 179.9978433,
    "grid_to_demand": 34831.01031,
    "govt_battery_charge": 44.36553045
  },
  {
    "timestamp": "12/04/24 22:00",
    "demand": 32647.86686,
    "solar_generated": 0,
    "wind_generated": 55.10894287,
    "marketplace_to_demand": 194.9443202,
    "grid_to_demand": 34137.91108,
    "govt_battery_charge": 94.62373015
  },
  {
    "timestamp": "12/04/24 23:00",
    "demand": 29966.83812,
    "solar_generated": 0,
    "wind_generated": 62.01383666,
    "marketplace_to_demand": 23.12505641,
    "grid_to_demand": 34769.37672,
    "govt_battery_charge": 85.33857765
  },
  {
    "timestamp": "13/04/24 0:00",
    "demand": 33620.62967,
    "solar_generated": 0,
    "wind_generated": 54.53827206,
    "marketplace_to_demand": 17.70919177,
    "grid_to_demand": 30183.46158,
    "govt_battery_charge": 15.49072431
  },
  {
    "timestamp": "13/04/24 1:00",
    "demand": 34812.59882,
    "solar_generated": 0,
    "wind_generated": 75.31469874,
    "marketplace_to_demand": 140.1828205,
    "grid_to_demand": 31597.35405,
    "govt_battery_charge": 62.87650385
  },
  {
    "timestamp": "13/04/24 2:00",
    "demand": 29985.90019,
    "solar_generated": 0,
    "wind_generated": 72.39575906,
    "marketplace_to_demand": 125.6141654,
    "grid_to_demand": 31732.00119,
    "govt_battery_charge": 18.02156145
  },
  {
    "timestamp": "13/04/24 3:00",
    "demand": 31195.79367,
    "solar_generated": 0,
    "wind_generated": 74.04521463,
    "marketplace_to_demand": 107.4868901,
    "grid_to_demand": 30971.67444,
    "govt_battery_charge": 94.94996746
  },
  {
    "timestamp": "13/04/24 4:00",
    "demand": 32219.05845,
    "solar_generated": 0,
    "wind_generated": 70.13147721,
    "marketplace_to_demand": 1.262113641,
    "grid_to_demand": 35934.98974,
    "govt_battery_charge": 92.66257883
  },
  {
    "timestamp": "13/04/24 5:00",
    "demand": 35769.45073,
    "solar_generated": 0,
    "wind_generated": 72.85221257,
    "marketplace_to_demand": 155.8249298,
    "grid_to_demand": 33264.58448,
    "govt_battery_charge": 32.00086071
  },
  {
    "timestamp": "13/04/24 6:00",
    "demand": 33346.37969,
    "solar_generated": 13.41750223,
    "wind_generated": 65.08109458,
    "marketplace_to_demand": 130.35313,
    "grid_to_demand": 30799.8852,
    "govt_battery_charge": 32.00122378
  },
  {
    "timestamp": "13/04/24 7:00",
    "demand": 30128.67809,
    "solar_generated": 12.3717059,
    "wind_generated": 56.44867989,
    "marketplace_to_demand": 56.18266977,
    "grid_to_demand": 32777.39012,
    "govt_battery_charge": 78.59145683
  },
  {
    "timestamp": "13/04/24 8:00",
    "demand": 33260.70565,
    "solar_generated": 6.185398086,
    "wind_generated": 61.66153032,
    "marketplace_to_demand": 31.3875626,
    "grid_to_demand": 33789.12832,
    "govt_battery_charge": 42.49525241
  },
  {
    "timestamp": "13/04/24 9:00",
    "demand": 31124.47741,
    "solar_generated": 20.25317624,
    "wind_generated": 67.07981655,
    "marketplace_to_demand": 145.2366052,
    "grid_to_demand": 33243.14073,
    "govt_battery_charge": 17.55744532
  },
  {
    "timestamp": "13/04/24 10:00",
    "demand": 36389.97255,
    "solar_generated": 23.96228176,
    "wind_generated": 79.11685718,
    "marketplace_to_demand": 73.9309335,
    "grid_to_demand": 35824.02738,
    "govt_battery_charge": 28.93904368
  },
  {
    "timestamp": "13/04/24 11:00",
    "demand": 34450.41773,
    "solar_generated": 22.80574376,
    "wind_generated": 65.0925176,
    "marketplace_to_demand": 196.5976251,
    "grid_to_demand": 35042.9436,
    "govt_battery_charge": 92.96363451
  },
  {
    "timestamp": "13/04/24 12:00",
    "demand": 32732.19496,
    "solar_generated": 13.51740633,
    "wind_generated": 54.51112232,
    "marketplace_to_demand": 113.8492124,
    "grid_to_demand": 34819.37869,
    "govt_battery_charge": 22.03623397
  },
  {
    "timestamp": "13/04/24 13:00",
    "demand": 31134.92858,
    "solar_generated": 10.77816344,
    "wind_generated": 56.05729944,
    "marketplace_to_demand": 50.8963003,
    "grid_to_demand": 30194.50392,
    "govt_battery_charge": 13.11882538
  },
  {
    "timestamp": "13/04/24 14:00",
    "demand": 32301.93912,
    "solar_generated": 23.21661042,
    "wind_generated": 72.72389885,
    "marketplace_to_demand": 7.750377152,
    "grid_to_demand": 31183.51489,
    "govt_battery_charge": 77.82794245
  },
  {
    "timestamp": "13/04/24 15:00",
    "demand": 34348.75687,
    "solar_generated": 9.198165973,
    "wind_generated": 62.62035776,
    "marketplace_to_demand": 161.3831852,
    "grid_to_demand": 33576.98832,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "13/04/24 16:00",
    "demand": 32049.66046,
    "solar_generated": 10.17540922,
    "wind_generated": 73.16002616,
    "marketplace_to_demand": 163.889651,
    "grid_to_demand": 35678.01572,
    "govt_battery_charge": 18.45102226
  },
  {
    "timestamp": "13/04/24 17:00",
    "demand": 32774.04591,
    "solar_generated": 20.75441782,
    "wind_generated": 73.92447656,
    "marketplace_to_demand": 114.0726655,
    "grid_to_demand": 31241.27345,
    "govt_battery_charge": 48.76219244
  },
  {
    "timestamp": "13/04/24 18:00",
    "demand": 31740.61232,
    "solar_generated": 0,
    "wind_generated": 74.11398346,
    "marketplace_to_demand": 124.895159,
    "grid_to_demand": 34799.39556,
    "govt_battery_charge": 81.02984458
  },
  {
    "timestamp": "13/04/24 19:00",
    "demand": 32061.08606,
    "solar_generated": 0,
    "wind_generated": 69.37838289,
    "marketplace_to_demand": 12.06398053,
    "grid_to_demand": 36440.92404,
    "govt_battery_charge": 67.12752181
  },
  {
    "timestamp": "13/04/24 20:00",
    "demand": 33490.40114,
    "solar_generated": 0,
    "wind_generated": 59.7487905,
    "marketplace_to_demand": 30.14045612,
    "grid_to_demand": 34152.27157,
    "govt_battery_charge": 33.27931534
  },
  {
    "timestamp": "13/04/24 21:00",
    "demand": 31747.53576,
    "solar_generated": 0,
    "wind_generated": 65.00186466,
    "marketplace_to_demand": 114.595,
    "grid_to_demand": 33878.74015,
    "govt_battery_charge": 32.96512124
  },
  {
    "timestamp": "13/04/24 22:00",
    "demand": 30205.33096,
    "solar_generated": 0,
    "wind_generated": 71.00689824,
    "marketplace_to_demand": 146.3432413,
    "grid_to_demand": 30863.21077,
    "govt_battery_charge": 27.95366587
  },
  {
    "timestamp": "13/04/24 23:00",
    "demand": 34400.44688,
    "solar_generated": 0,
    "wind_generated": 76.90058536,
    "marketplace_to_demand": 174.9434793,
    "grid_to_demand": 34051.55954,
    "govt_battery_charge": 23.18169364
  },
  {
    "timestamp": "14/04/24 0:00",
    "demand": 31187.84994,
    "solar_generated": 0,
    "wind_generated": 71.82780619,
    "marketplace_to_demand": 34.47084498,
    "grid_to_demand": 33113.9362,
    "govt_battery_charge": 89.88620206
  },
  {
    "timestamp": "14/04/24 1:00",
    "demand": 33952.91831,
    "solar_generated": 0,
    "wind_generated": 78.0595567,
    "marketplace_to_demand": 33.1873687,
    "grid_to_demand": 36429.08225,
    "govt_battery_charge": 51.13452995
  },
  {
    "timestamp": "14/04/24 2:00",
    "demand": 35259.68745,
    "solar_generated": 0,
    "wind_generated": 68.94434168,
    "marketplace_to_demand": 15.23577822,
    "grid_to_demand": 35683.00302,
    "govt_battery_charge": 39.97031215
  },
  {
    "timestamp": "14/04/24 3:00",
    "demand": 31786.82126,
    "solar_generated": 0,
    "wind_generated": 75.07187127,
    "marketplace_to_demand": 182.7079482,
    "grid_to_demand": 35512.46247,
    "govt_battery_charge": 76.63837217
  },
  {
    "timestamp": "14/04/24 4:00",
    "demand": 33114.19967,
    "solar_generated": 0,
    "wind_generated": 57.25540929,
    "marketplace_to_demand": 75.76208272,
    "grid_to_demand": 30343.57019,
    "govt_battery_charge": 62.35188842
  },
  {
    "timestamp": "14/04/24 5:00",
    "demand": 33602.82226,
    "solar_generated": 0,
    "wind_generated": 79.69326957,
    "marketplace_to_demand": 105.1797302,
    "grid_to_demand": 30621.69968,
    "govt_battery_charge": 51.32769119
  },
  {
    "timestamp": "14/04/24 6:00",
    "demand": 36452.62655,
    "solar_generated": 12.29900307,
    "wind_generated": 72.16131294,
    "marketplace_to_demand": 177.8422767,
    "grid_to_demand": 34160.79788,
    "govt_battery_charge": 71.48173914
  },
  {
    "timestamp": "14/04/24 7:00",
    "demand": 32629.47066,
    "solar_generated": 23.9444574,
    "wind_generated": 53.63881804,
    "marketplace_to_demand": 176.563192,
    "grid_to_demand": 33324.9374,
    "govt_battery_charge": 14.62703169
  },
  {
    "timestamp": "14/04/24 8:00",
    "demand": 32109.45629,
    "solar_generated": 15.20817855,
    "wind_generated": 76.5577656,
    "marketplace_to_demand": 104.9151792,
    "grid_to_demand": 36420.7913,
    "govt_battery_charge": 57.17998422
  },
  {
    "timestamp": "14/04/24 9:00",
    "demand": 30375.28926,
    "solar_generated": 20.24536062,
    "wind_generated": 80.31856243,
    "marketplace_to_demand": 132.2123001,
    "grid_to_demand": 33853.82078,
    "govt_battery_charge": 42.91733208
  },
  {
    "timestamp": "14/04/24 10:00",
    "demand": 34150.12518,
    "solar_generated": 21.30981733,
    "wind_generated": 77.77034966,
    "marketplace_to_demand": 145.5661399,
    "grid_to_demand": 34232.11855,
    "govt_battery_charge": 15.02265797
  },
  {
    "timestamp": "14/04/24 11:00",
    "demand": 31391.02893,
    "solar_generated": 10.88265584,
    "wind_generated": 79.81488458,
    "marketplace_to_demand": 135.4995193,
    "grid_to_demand": 31429.86601,
    "govt_battery_charge": 79.73985358
  },
  {
    "timestamp": "14/04/24 12:00",
    "demand": 34596.49588,
    "solar_generated": 18.11424708,
    "wind_generated": 63.57924099,
    "marketplace_to_demand": 145.8139614,
    "grid_to_demand": 32111.9156,
    "govt_battery_charge": 75.00443187
  },
  {
    "timestamp": "14/04/24 13:00",
    "demand": 35971.38547,
    "solar_generated": 22.38381438,
    "wind_generated": 78.98129002,
    "marketplace_to_demand": 180.004176,
    "grid_to_demand": 36309.64388,
    "govt_battery_charge": 19.9574668
  },
  {
    "timestamp": "14/04/24 14:00",
    "demand": 34026.55986,
    "solar_generated": 18.20009256,
    "wind_generated": 56.91811626,
    "marketplace_to_demand": 128.2230212,
    "grid_to_demand": 30718.75989,
    "govt_battery_charge": 29.28009045
  },
  {
    "timestamp": "14/04/24 15:00",
    "demand": 31757.87428,
    "solar_generated": 18.30581224,
    "wind_generated": 75.13991467,
    "marketplace_to_demand": 100.3388428,
    "grid_to_demand": 36290.16581,
    "govt_battery_charge": 83.01428272
  },
  {
    "timestamp": "14/04/24 16:00",
    "demand": 34291.323,
    "solar_generated": 21.25717822,
    "wind_generated": 56.14150932,
    "marketplace_to_demand": 120.0292174,
    "grid_to_demand": 35848.05274,
    "govt_battery_charge": 93.19928577
  },
  {
    "timestamp": "14/04/24 17:00",
    "demand": 34552.02132,
    "solar_generated": 23.36000215,
    "wind_generated": 78.13018189,
    "marketplace_to_demand": 181.8469283,
    "grid_to_demand": 30971.5385,
    "govt_battery_charge": 75.24456675
  },
  {
    "timestamp": "14/04/24 18:00",
    "demand": 31115.74138,
    "solar_generated": 0,
    "wind_generated": 57.53910799,
    "marketplace_to_demand": 109.9671637,
    "grid_to_demand": 33742.84042,
    "govt_battery_charge": 23.31129963
  },
  {
    "timestamp": "14/04/24 19:00",
    "demand": 30775.37352,
    "solar_generated": 0,
    "wind_generated": 65.61078243,
    "marketplace_to_demand": 86.98168328,
    "grid_to_demand": 32653.51578,
    "govt_battery_charge": 39.8314994
  },
  {
    "timestamp": "14/04/24 20:00",
    "demand": 31041.0256,
    "solar_generated": 0,
    "wind_generated": 80.33363472,
    "marketplace_to_demand": 122.9930829,
    "grid_to_demand": 31928.24985,
    "govt_battery_charge": 12.9635672
  },
  {
    "timestamp": "14/04/24 21:00",
    "demand": 35057.36899,
    "solar_generated": 0,
    "wind_generated": 70.74392812,
    "marketplace_to_demand": 43.47591749,
    "grid_to_demand": 36495.13244,
    "govt_battery_charge": 99.09630038
  },
  {
    "timestamp": "14/04/24 22:00",
    "demand": 33392.13703,
    "solar_generated": 0,
    "wind_generated": 66.63960165,
    "marketplace_to_demand": 188.4424758,
    "grid_to_demand": 35365.71821,
    "govt_battery_charge": 9.986103477
  },
  {
    "timestamp": "14/04/24 23:00",
    "demand": 32937.93477,
    "solar_generated": 0,
    "wind_generated": 64.80981801,
    "marketplace_to_demand": 149.128258,
    "grid_to_demand": 32327.53865,
    "govt_battery_charge": 36.57682148
  },
  {
    "timestamp": "15/04/24 0:00",
    "demand": 33488.79398,
    "solar_generated": 0,
    "wind_generated": 60.27110667,
    "marketplace_to_demand": 9.898138293,
    "grid_to_demand": 30087.25716,
    "govt_battery_charge": 36.12195337
  },
  {
    "timestamp": "15/04/24 1:00",
    "demand": 33450.08791,
    "solar_generated": 0,
    "wind_generated": 79.42446264,
    "marketplace_to_demand": 109.5070647,
    "grid_to_demand": 36088.39423,
    "govt_battery_charge": 98.45404123
  },
  {
    "timestamp": "15/04/24 2:00",
    "demand": 31285.06807,
    "solar_generated": 0,
    "wind_generated": 56.1357717,
    "marketplace_to_demand": 191.0310932,
    "grid_to_demand": 32736.90765,
    "govt_battery_charge": 96.77974876
  },
  {
    "timestamp": "15/04/24 3:00",
    "demand": 32587.31679,
    "solar_generated": 0,
    "wind_generated": 62.47344873,
    "marketplace_to_demand": 129.8444562,
    "grid_to_demand": 35456.12204,
    "govt_battery_charge": 20.65940944
  },
  {
    "timestamp": "15/04/24 4:00",
    "demand": 36353.54401,
    "solar_generated": 0,
    "wind_generated": 53.65551629,
    "marketplace_to_demand": 48.51799828,
    "grid_to_demand": 35726.89584,
    "govt_battery_charge": 9.669034524
  },
  {
    "timestamp": "15/04/24 5:00",
    "demand": 36416.66306,
    "solar_generated": 0,
    "wind_generated": 59.23805543,
    "marketplace_to_demand": 195.9178721,
    "grid_to_demand": 35433.33673,
    "govt_battery_charge": 39.97854087
  },
  {
    "timestamp": "15/04/24 6:00",
    "demand": 34215.74755,
    "solar_generated": 13.07257516,
    "wind_generated": 70.61133544,
    "marketplace_to_demand": 129.2742921,
    "grid_to_demand": 31410.80704,
    "govt_battery_charge": 87.69715078
  },
  {
    "timestamp": "15/04/24 7:00",
    "demand": 32475.21169,
    "solar_generated": 19.95225612,
    "wind_generated": 67.76444352,
    "marketplace_to_demand": 175.3106235,
    "grid_to_demand": 33980.62712,
    "govt_battery_charge": 50.34493435
  },
  {
    "timestamp": "15/04/24 8:00",
    "demand": 34510.71877,
    "solar_generated": 11.5810933,
    "wind_generated": 77.68132105,
    "marketplace_to_demand": 128.5100635,
    "grid_to_demand": 30800.7464,
    "govt_battery_charge": 20.03480025
  },
  {
    "timestamp": "15/04/24 9:00",
    "demand": 33271.31775,
    "solar_generated": 21.00410855,
    "wind_generated": 63.19237611,
    "marketplace_to_demand": 145.9285649,
    "grid_to_demand": 36535.66787,
    "govt_battery_charge": 74.03892022
  },
  {
    "timestamp": "15/04/24 10:00",
    "demand": 31657.91191,
    "solar_generated": 20.56743178,
    "wind_generated": 58.70270884,
    "marketplace_to_demand": 172.2301065,
    "grid_to_demand": 33486.34523,
    "govt_battery_charge": 25.19982079
  },
  {
    "timestamp": "15/04/24 11:00",
    "demand": 31167.7057,
    "solar_generated": 23.67119928,
    "wind_generated": 61.46485714,
    "marketplace_to_demand": 33.39758186,
    "grid_to_demand": 31266.92771,
    "govt_battery_charge": 89.24547084
  },
  {
    "timestamp": "15/04/24 12:00",
    "demand": 31722.89501,
    "solar_generated": 18.42127086,
    "wind_generated": 65.59784799,
    "marketplace_to_demand": 0.209322533,
    "grid_to_demand": 35656.21512,
    "govt_battery_charge": 90.13688249
  },
  {
    "timestamp": "15/04/24 13:00",
    "demand": 34662.57385,
    "solar_generated": 14.79187195,
    "wind_generated": 68.58634879,
    "marketplace_to_demand": 107.3209626,
    "grid_to_demand": 33605.75658,
    "govt_battery_charge": 60.73558844
  },
  {
    "timestamp": "15/04/24 14:00",
    "demand": 33440.56645,
    "solar_generated": 20.29993331,
    "wind_generated": 60.79236886,
    "marketplace_to_demand": 157.1876137,
    "grid_to_demand": 36549.93421,
    "govt_battery_charge": 57.28864674
  },
  {
    "timestamp": "15/04/24 15:00",
    "demand": 31818.17924,
    "solar_generated": 12.67933765,
    "wind_generated": 67.34151439,
    "marketplace_to_demand": 42.44795702,
    "grid_to_demand": 30853.10569,
    "govt_battery_charge": 94.18633761
  },
  {
    "timestamp": "15/04/24 16:00",
    "demand": 31148.69911,
    "solar_generated": 15.46238281,
    "wind_generated": 70.38786647,
    "marketplace_to_demand": 51.54272398,
    "grid_to_demand": 34546.76157,
    "govt_battery_charge": 58.96760917
  },
  {
    "timestamp": "15/04/24 17:00",
    "demand": 34512.56455,
    "solar_generated": 13.97507707,
    "wind_generated": 58.16940791,
    "marketplace_to_demand": 104.4846457,
    "grid_to_demand": 35274.6947,
    "govt_battery_charge": 58.11031278
  },
  {
    "timestamp": "15/04/24 18:00",
    "demand": 32318.78677,
    "solar_generated": 0,
    "wind_generated": 71.05615758,
    "marketplace_to_demand": 27.88011148,
    "grid_to_demand": 31464.27087,
    "govt_battery_charge": 65.47510164
  },
  {
    "timestamp": "15/04/24 19:00",
    "demand": 32474.26028,
    "solar_generated": 0,
    "wind_generated": 77.61299527,
    "marketplace_to_demand": 144.7670344,
    "grid_to_demand": 30182.97738,
    "govt_battery_charge": 60.04395945
  },
  {
    "timestamp": "15/04/24 20:00",
    "demand": 30857.09457,
    "solar_generated": 0,
    "wind_generated": 63.12096911,
    "marketplace_to_demand": 5.823772969,
    "grid_to_demand": 36379.80659,
    "govt_battery_charge": 35.59193811
  },
  {
    "timestamp": "15/04/24 21:00",
    "demand": 31975.63401,
    "solar_generated": 0,
    "wind_generated": 78.23570725,
    "marketplace_to_demand": 172.8942496,
    "grid_to_demand": 30128.88398,
    "govt_battery_charge": 17.41737184
  },
  {
    "timestamp": "15/04/24 22:00",
    "demand": 31263.45681,
    "solar_generated": 0,
    "wind_generated": 57.83392629,
    "marketplace_to_demand": 36.94719409,
    "grid_to_demand": 32760.84624,
    "govt_battery_charge": 80.27457771
  },
  {
    "timestamp": "15/04/24 23:00",
    "demand": 30626.66846,
    "solar_generated": 0,
    "wind_generated": 56.33987851,
    "marketplace_to_demand": 31.98562798,
    "grid_to_demand": 30257.45797,
    "govt_battery_charge": 79.50809476
  },
  {
    "timestamp": "16/04/24 0:00",
    "demand": 33817.78894,
    "solar_generated": 0,
    "wind_generated": 67.86961244,
    "marketplace_to_demand": 188.9070087,
    "grid_to_demand": 34277.18294,
    "govt_battery_charge": 41.22612447
  },
  {
    "timestamp": "16/04/24 1:00",
    "demand": 30672.64157,
    "solar_generated": 0,
    "wind_generated": 78.54015442,
    "marketplace_to_demand": 123.2289063,
    "grid_to_demand": 35834.38769,
    "govt_battery_charge": 31.85627419
  },
  {
    "timestamp": "16/04/24 2:00",
    "demand": 33604.8844,
    "solar_generated": 0,
    "wind_generated": 57.11704117,
    "marketplace_to_demand": 176.5273556,
    "grid_to_demand": 31481.92324,
    "govt_battery_charge": 8.071882915
  },
  {
    "timestamp": "16/04/24 3:00",
    "demand": 35410.4178,
    "solar_generated": 0,
    "wind_generated": 64.01682684,
    "marketplace_to_demand": 153.8832725,
    "grid_to_demand": 30982.89734,
    "govt_battery_charge": 36.23265442
  },
  {
    "timestamp": "16/04/24 4:00",
    "demand": 30943.23655,
    "solar_generated": 0,
    "wind_generated": 74.40064611,
    "marketplace_to_demand": 161.6554093,
    "grid_to_demand": 30286.94553,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "16/04/24 5:00",
    "demand": 32944.0556,
    "solar_generated": 0,
    "wind_generated": 66.36672218,
    "marketplace_to_demand": 19.27659452,
    "grid_to_demand": 35255.57038,
    "govt_battery_charge": 60.29302857
  },
  {
    "timestamp": "16/04/24 6:00",
    "demand": 31113.3551,
    "solar_generated": 12.82988933,
    "wind_generated": 64.65038956,
    "marketplace_to_demand": 32.89962137,
    "grid_to_demand": 31643.50906,
    "govt_battery_charge": 45.13476513
  },
  {
    "timestamp": "16/04/24 7:00",
    "demand": 35799.09503,
    "solar_generated": 17.54796639,
    "wind_generated": 64.76761148,
    "marketplace_to_demand": 79.49654518,
    "grid_to_demand": 32290.70115,
    "govt_battery_charge": 32.41343838
  },
  {
    "timestamp": "16/04/24 8:00",
    "demand": 30433.35683,
    "solar_generated": 15.45823179,
    "wind_generated": 65.49416027,
    "marketplace_to_demand": 196.7382602,
    "grid_to_demand": 31325.23538,
    "govt_battery_charge": 3.700292069
  },
  {
    "timestamp": "16/04/24 9:00",
    "demand": 32905.40807,
    "solar_generated": 19.03419655,
    "wind_generated": 70.84243883,
    "marketplace_to_demand": 164.2455488,
    "grid_to_demand": 31153.0873,
    "govt_battery_charge": 18.94048079
  },
  {
    "timestamp": "16/04/24 10:00",
    "demand": 33438.46762,
    "solar_generated": 13.47802493,
    "wind_generated": 60.48844223,
    "marketplace_to_demand": 94.41087334,
    "grid_to_demand": 36030.5231,
    "govt_battery_charge": 66.34245993
  },
  {
    "timestamp": "16/04/24 11:00",
    "demand": 35164.43509,
    "solar_generated": 6.671864117,
    "wind_generated": 74.87925638,
    "marketplace_to_demand": 8.017221615,
    "grid_to_demand": 33891.11362,
    "govt_battery_charge": 92.05223109
  },
  {
    "timestamp": "16/04/24 12:00",
    "demand": 34439.41724,
    "solar_generated": 7.436230821,
    "wind_generated": 76.49965352,
    "marketplace_to_demand": 2.47650885,
    "grid_to_demand": 35610.63718,
    "govt_battery_charge": 9.960968829
  },
  {
    "timestamp": "16/04/24 13:00",
    "demand": 36144.40977,
    "solar_generated": 8.610645816,
    "wind_generated": 70.56986881,
    "marketplace_to_demand": 139.2080502,
    "grid_to_demand": 35922.01383,
    "govt_battery_charge": 95.76344996
  },
  {
    "timestamp": "16/04/24 14:00",
    "demand": 33979.87903,
    "solar_generated": 9.788999949,
    "wind_generated": 54.62300983,
    "marketplace_to_demand": 74.64446601,
    "grid_to_demand": 34143.71844,
    "govt_battery_charge": 21.44381671
  },
  {
    "timestamp": "16/04/24 15:00",
    "demand": 30725.21303,
    "solar_generated": 17.20046761,
    "wind_generated": 60.93313374,
    "marketplace_to_demand": 60.56457473,
    "grid_to_demand": 34393.13128,
    "govt_battery_charge": 37.49520217
  },
  {
    "timestamp": "16/04/24 16:00",
    "demand": 35855.4111,
    "solar_generated": 6.930314096,
    "wind_generated": 64.47521624,
    "marketplace_to_demand": 186.5614017,
    "grid_to_demand": 34751.6015,
    "govt_battery_charge": 6.680373512
  },
  {
    "timestamp": "16/04/24 17:00",
    "demand": 33961.41595,
    "solar_generated": 13.65265663,
    "wind_generated": 53.61691482,
    "marketplace_to_demand": 71.45050845,
    "grid_to_demand": 31381.45145,
    "govt_battery_charge": 52.73015476
  },
  {
    "timestamp": "16/04/24 18:00",
    "demand": 31076.31304,
    "solar_generated": 0,
    "wind_generated": 55.66056023,
    "marketplace_to_demand": 194.89531,
    "grid_to_demand": 36132.22602,
    "govt_battery_charge": 73.6033658
  },
  {
    "timestamp": "16/04/24 19:00",
    "demand": 33238.98381,
    "solar_generated": 0,
    "wind_generated": 67.36021381,
    "marketplace_to_demand": 158.3059604,
    "grid_to_demand": 35160.55734,
    "govt_battery_charge": 32.71879153
  },
  {
    "timestamp": "16/04/24 20:00",
    "demand": 33175.39966,
    "solar_generated": 0,
    "wind_generated": 65.32388379,
    "marketplace_to_demand": 191.983512,
    "grid_to_demand": 31515.72403,
    "govt_battery_charge": 95.14454332
  },
  {
    "timestamp": "16/04/24 21:00",
    "demand": 31754.94363,
    "solar_generated": 0,
    "wind_generated": 80.25259624,
    "marketplace_to_demand": 152.8831274,
    "grid_to_demand": 35793.32024,
    "govt_battery_charge": 77.63138778
  },
  {
    "timestamp": "16/04/24 22:00",
    "demand": 32732.38959,
    "solar_generated": 0,
    "wind_generated": 70.8174064,
    "marketplace_to_demand": 64.28643746,
    "grid_to_demand": 32063.85409,
    "govt_battery_charge": 80.26276776
  },
  {
    "timestamp": "16/04/24 23:00",
    "demand": 30451.6658,
    "solar_generated": 0,
    "wind_generated": 78.60145382,
    "marketplace_to_demand": 140.4425699,
    "grid_to_demand": 35848.21896,
    "govt_battery_charge": 21.47552638
  },
  {
    "timestamp": "17/04/24 0:00",
    "demand": 34348.04643,
    "solar_generated": 0,
    "wind_generated": 72.499481,
    "marketplace_to_demand": 5.501198888,
    "grid_to_demand": 33994.47253,
    "govt_battery_charge": 3.993375916
  },
  {
    "timestamp": "17/04/24 1:00",
    "demand": 34159.55026,
    "solar_generated": 0,
    "wind_generated": 68.79268217,
    "marketplace_to_demand": 177.4767401,
    "grid_to_demand": 32122.66625,
    "govt_battery_charge": 37.36929973
  },
  {
    "timestamp": "17/04/24 2:00",
    "demand": 34263.49504,
    "solar_generated": 0,
    "wind_generated": 71.44544094,
    "marketplace_to_demand": 19.16070543,
    "grid_to_demand": 34079.76608,
    "govt_battery_charge": 82.1588326
  },
  {
    "timestamp": "17/04/24 3:00",
    "demand": 32768.80977,
    "solar_generated": 0,
    "wind_generated": 74.38527627,
    "marketplace_to_demand": 152.2352202,
    "grid_to_demand": 31967.07926,
    "govt_battery_charge": 67.39074136
  },
  {
    "timestamp": "17/04/24 4:00",
    "demand": 35903.0097,
    "solar_generated": 0,
    "wind_generated": 76.25147564,
    "marketplace_to_demand": 160.6268877,
    "grid_to_demand": 31645.40357,
    "govt_battery_charge": 27.53038161
  },
  {
    "timestamp": "17/04/24 5:00",
    "demand": 30999.91046,
    "solar_generated": 0,
    "wind_generated": 62.72080967,
    "marketplace_to_demand": 123.0788972,
    "grid_to_demand": 30105.10657,
    "govt_battery_charge": 94.58325392
  },
  {
    "timestamp": "17/04/24 6:00",
    "demand": 30344.11277,
    "solar_generated": 8.895738071,
    "wind_generated": 73.9080006,
    "marketplace_to_demand": 56.91288145,
    "grid_to_demand": 34519.34012,
    "govt_battery_charge": 43.4219922
  },
  {
    "timestamp": "17/04/24 7:00",
    "demand": 30271.03198,
    "solar_generated": 21.10509986,
    "wind_generated": 61.89670371,
    "marketplace_to_demand": 86.99953626,
    "grid_to_demand": 30056.66435,
    "govt_battery_charge": 38.51196205
  },
  {
    "timestamp": "17/04/24 8:00",
    "demand": 31022.13431,
    "solar_generated": 13.28578312,
    "wind_generated": 70.05031145,
    "marketplace_to_demand": 2.742025489,
    "grid_to_demand": 34447.37343,
    "govt_battery_charge": 55.14866121
  },
  {
    "timestamp": "17/04/24 9:00",
    "demand": 33042.88217,
    "solar_generated": 20.1274115,
    "wind_generated": 71.67838994,
    "marketplace_to_demand": 57.8942439,
    "grid_to_demand": 31218.8658,
    "govt_battery_charge": 61.88082045
  },
  {
    "timestamp": "17/04/24 10:00",
    "demand": 30681.71042,
    "solar_generated": 20.1188186,
    "wind_generated": 63.44202344,
    "marketplace_to_demand": 169.8970359,
    "grid_to_demand": 32170.39069,
    "govt_battery_charge": 93.66975286
  },
  {
    "timestamp": "17/04/24 11:00",
    "demand": 35260.8856,
    "solar_generated": 16.83449844,
    "wind_generated": 65.67528492,
    "marketplace_to_demand": 89.32128024,
    "grid_to_demand": 33770.63966,
    "govt_battery_charge": 24.41980967
  },
  {
    "timestamp": "17/04/24 12:00",
    "demand": 32463.79139,
    "solar_generated": 18.47555129,
    "wind_generated": 67.54410732,
    "marketplace_to_demand": 150.4950374,
    "grid_to_demand": 31025.75077,
    "govt_battery_charge": 72.30085787
  },
  {
    "timestamp": "17/04/24 13:00",
    "demand": 31048.99447,
    "solar_generated": 8.916765005,
    "wind_generated": 75.59340241,
    "marketplace_to_demand": 74.90795321,
    "grid_to_demand": 30783.85758,
    "govt_battery_charge": 11.04734126
  },
  {
    "timestamp": "17/04/24 14:00",
    "demand": 35463.15026,
    "solar_generated": 10.57605262,
    "wind_generated": 79.22706654,
    "marketplace_to_demand": 109.5365628,
    "grid_to_demand": 30465.43284,
    "govt_battery_charge": 39.51342256
  },
  {
    "timestamp": "17/04/24 15:00",
    "demand": 31137.17726,
    "solar_generated": 19.97201658,
    "wind_generated": 73.47111655,
    "marketplace_to_demand": 137.0653416,
    "grid_to_demand": 32594.79808,
    "govt_battery_charge": 52.21566703
  },
  {
    "timestamp": "17/04/24 16:00",
    "demand": 34565.12155,
    "solar_generated": 10.36877368,
    "wind_generated": 79.94720049,
    "marketplace_to_demand": 83.93574221,
    "grid_to_demand": 33971.90323,
    "govt_battery_charge": 11.55992375
  },
  {
    "timestamp": "17/04/24 17:00",
    "demand": 32017.32528,
    "solar_generated": 17.92249247,
    "wind_generated": 71.0689039,
    "marketplace_to_demand": 149.281981,
    "grid_to_demand": 32338.1013,
    "govt_battery_charge": 95.06855736
  },
  {
    "timestamp": "17/04/24 18:00",
    "demand": 36474.39636,
    "solar_generated": 0,
    "wind_generated": 65.28229611,
    "marketplace_to_demand": 20.94344323,
    "grid_to_demand": 35713.48277,
    "govt_battery_charge": 27.24371397
  },
  {
    "timestamp": "17/04/24 19:00",
    "demand": 34434.94997,
    "solar_generated": 0,
    "wind_generated": 54.35227867,
    "marketplace_to_demand": 184.8566685,
    "grid_to_demand": 35193.32616,
    "govt_battery_charge": 25.37236396
  },
  {
    "timestamp": "17/04/24 20:00",
    "demand": 34045.63017,
    "solar_generated": 0,
    "wind_generated": 55.50569937,
    "marketplace_to_demand": 101.093865,
    "grid_to_demand": 31784.63665,
    "govt_battery_charge": 24.59910475
  },
  {
    "timestamp": "17/04/24 21:00",
    "demand": 36147.89475,
    "solar_generated": 0,
    "wind_generated": 53.72852339,
    "marketplace_to_demand": 35.60293492,
    "grid_to_demand": 31238.9754,
    "govt_battery_charge": 7.577102546
  },
  {
    "timestamp": "17/04/24 22:00",
    "demand": 32133.933,
    "solar_generated": 0,
    "wind_generated": 58.19572674,
    "marketplace_to_demand": 74.41727554,
    "grid_to_demand": 32650.26937,
    "govt_battery_charge": 95.52137621
  },
  {
    "timestamp": "17/04/24 23:00",
    "demand": 33553.77206,
    "solar_generated": 0,
    "wind_generated": 69.81152827,
    "marketplace_to_demand": 114.57877,
    "grid_to_demand": 32509.7601,
    "govt_battery_charge": 72.76244238
  },
  {
    "timestamp": "18/04/24 0:00",
    "demand": 32652.14512,
    "solar_generated": 0,
    "wind_generated": 64.12432939,
    "marketplace_to_demand": 54.79258997,
    "grid_to_demand": 35590.08884,
    "govt_battery_charge": 70.87920235
  },
  {
    "timestamp": "18/04/24 1:00",
    "demand": 36060.8654,
    "solar_generated": 0,
    "wind_generated": 68.32332261,
    "marketplace_to_demand": 163.3728361,
    "grid_to_demand": 33307.94296,
    "govt_battery_charge": 54.93948937
  },
  {
    "timestamp": "18/04/24 2:00",
    "demand": 36482.94998,
    "solar_generated": 0,
    "wind_generated": 63.34176686,
    "marketplace_to_demand": 39.78485813,
    "grid_to_demand": 35332.32868,
    "govt_battery_charge": 40.04651928
  },
  {
    "timestamp": "18/04/24 3:00",
    "demand": 33693.04961,
    "solar_generated": 0,
    "wind_generated": 57.4842752,
    "marketplace_to_demand": 133.0260796,
    "grid_to_demand": 32412.16236,
    "govt_battery_charge": 92.68274063
  },
  {
    "timestamp": "18/04/24 4:00",
    "demand": 31286.2379,
    "solar_generated": 0,
    "wind_generated": 62.47916022,
    "marketplace_to_demand": 55.34196905,
    "grid_to_demand": 33832.7774,
    "govt_battery_charge": 92.33257332
  },
  {
    "timestamp": "18/04/24 5:00",
    "demand": 33256.54836,
    "solar_generated": 0,
    "wind_generated": 59.93243874,
    "marketplace_to_demand": 68.12514859,
    "grid_to_demand": 32006.80614,
    "govt_battery_charge": 89.63646889
  },
  {
    "timestamp": "18/04/24 6:00",
    "demand": 31729.05254,
    "solar_generated": 20.59308368,
    "wind_generated": 80.17704069,
    "marketplace_to_demand": 150.5692585,
    "grid_to_demand": 30329.07979,
    "govt_battery_charge": 47.2913831
  },
  {
    "timestamp": "18/04/24 7:00",
    "demand": 30247.614,
    "solar_generated": 16.05878623,
    "wind_generated": 75.7552413,
    "marketplace_to_demand": 62.48631106,
    "grid_to_demand": 31984.70022,
    "govt_battery_charge": 95.49603595
  },
  {
    "timestamp": "18/04/24 8:00",
    "demand": 33956.98048,
    "solar_generated": 14.94561489,
    "wind_generated": 76.67123544,
    "marketplace_to_demand": 124.3980011,
    "grid_to_demand": 33192.71944,
    "govt_battery_charge": 31.78219481
  },
  {
    "timestamp": "18/04/24 9:00",
    "demand": 31358.43214,
    "solar_generated": 23.65078551,
    "wind_generated": 67.26949926,
    "marketplace_to_demand": 121.4918277,
    "grid_to_demand": 35269.0178,
    "govt_battery_charge": 53.75770291
  },
  {
    "timestamp": "18/04/24 10:00",
    "demand": 32252.98392,
    "solar_generated": 19.77073406,
    "wind_generated": 70.14804976,
    "marketplace_to_demand": 183.6640403,
    "grid_to_demand": 31014.59718,
    "govt_battery_charge": 41.18395554
  },
  {
    "timestamp": "18/04/24 11:00",
    "demand": 32759.00557,
    "solar_generated": 16.75414117,
    "wind_generated": 67.50420136,
    "marketplace_to_demand": 121.8396278,
    "grid_to_demand": 34608.23371,
    "govt_battery_charge": 87.62186751
  },
  {
    "timestamp": "18/04/24 12:00",
    "demand": 30705.5967,
    "solar_generated": 13.87987901,
    "wind_generated": 77.31891701,
    "marketplace_to_demand": 165.3993985,
    "grid_to_demand": 34445.30328,
    "govt_battery_charge": 15.04957315
  },
  {
    "timestamp": "18/04/24 13:00",
    "demand": 34120.57515,
    "solar_generated": 17.8876484,
    "wind_generated": 60.08319722,
    "marketplace_to_demand": 148.6321137,
    "grid_to_demand": 30313.38227,
    "govt_battery_charge": 49.7125494
  },
  {
    "timestamp": "18/04/24 14:00",
    "demand": 34840.71382,
    "solar_generated": 16.17230484,
    "wind_generated": 71.59635305,
    "marketplace_to_demand": 196.8171422,
    "grid_to_demand": 30017.80616,
    "govt_battery_charge": 26.64086197
  },
  {
    "timestamp": "18/04/24 15:00",
    "demand": 32756.76401,
    "solar_generated": 11.44546784,
    "wind_generated": 76.13340151,
    "marketplace_to_demand": 163.6683068,
    "grid_to_demand": 36014.77158,
    "govt_battery_charge": 68.71929708
  },
  {
    "timestamp": "18/04/24 16:00",
    "demand": 32762.88746,
    "solar_generated": 6.797922593,
    "wind_generated": 62.86874748,
    "marketplace_to_demand": 32.34089792,
    "grid_to_demand": 33053.72175,
    "govt_battery_charge": 45.16122466
  },
  {
    "timestamp": "18/04/24 17:00",
    "demand": 32652.38673,
    "solar_generated": 7.391452135,
    "wind_generated": 76.6804781,
    "marketplace_to_demand": 77.28807538,
    "grid_to_demand": 32649.51911,
    "govt_battery_charge": 87.79698548
  },
  {
    "timestamp": "18/04/24 18:00",
    "demand": 35756.8867,
    "solar_generated": 0,
    "wind_generated": 75.1448309,
    "marketplace_to_demand": 138.1277801,
    "grid_to_demand": 35495.61647,
    "govt_battery_charge": 65.29333334
  },
  {
    "timestamp": "18/04/24 19:00",
    "demand": 35546.05454,
    "solar_generated": 0,
    "wind_generated": 68.84731414,
    "marketplace_to_demand": 91.19080851,
    "grid_to_demand": 30145.00943,
    "govt_battery_charge": 72.41813563
  },
  {
    "timestamp": "18/04/24 20:00",
    "demand": 31369.87188,
    "solar_generated": 0,
    "wind_generated": 55.22680258,
    "marketplace_to_demand": 25.40127142,
    "grid_to_demand": 29996.39708,
    "govt_battery_charge": 34.56952994
  },
  {
    "timestamp": "18/04/24 21:00",
    "demand": 33314.61584,
    "solar_generated": 0,
    "wind_generated": 69.58857534,
    "marketplace_to_demand": 145.0375796,
    "grid_to_demand": 30935.23477,
    "govt_battery_charge": 47.47262335
  },
  {
    "timestamp": "18/04/24 22:00",
    "demand": 34822.35407,
    "solar_generated": 0,
    "wind_generated": 68.50778311,
    "marketplace_to_demand": 25.21055305,
    "grid_to_demand": 31035.30635,
    "govt_battery_charge": 67.57379714
  },
  {
    "timestamp": "18/04/24 23:00",
    "demand": 33621.93991,
    "solar_generated": 0,
    "wind_generated": 77.52453787,
    "marketplace_to_demand": 50.00949579,
    "grid_to_demand": 34004.80758,
    "govt_battery_charge": 43.5324844
  },
  {
    "timestamp": "19/04/24 0:00",
    "demand": 30897.33056,
    "solar_generated": 0,
    "wind_generated": 74.92110825,
    "marketplace_to_demand": 92.49088607,
    "grid_to_demand": 34986.19178,
    "govt_battery_charge": 69.86020226
  },
  {
    "timestamp": "19/04/24 1:00",
    "demand": 30537.40905,
    "solar_generated": 0,
    "wind_generated": 69.36118239,
    "marketplace_to_demand": 71.23241755,
    "grid_to_demand": 30970.81042,
    "govt_battery_charge": 89.04399644
  },
  {
    "timestamp": "19/04/24 2:00",
    "demand": 34481.59762,
    "solar_generated": 0,
    "wind_generated": 57.0244225,
    "marketplace_to_demand": 103.5932315,
    "grid_to_demand": 30834.97854,
    "govt_battery_charge": 24.88741633
  },
  {
    "timestamp": "19/04/24 3:00",
    "demand": 32931.19539,
    "solar_generated": 0,
    "wind_generated": 70.75218482,
    "marketplace_to_demand": 51.99174275,
    "grid_to_demand": 34743.36417,
    "govt_battery_charge": 15.63184289
  },
  {
    "timestamp": "19/04/24 4:00",
    "demand": 31170.08502,
    "solar_generated": 0,
    "wind_generated": 77.40609922,
    "marketplace_to_demand": 37.3632898,
    "grid_to_demand": 31091.3407,
    "govt_battery_charge": 8.802824595
  },
  {
    "timestamp": "19/04/24 5:00",
    "demand": 30534.74822,
    "solar_generated": 0,
    "wind_generated": 75.93130088,
    "marketplace_to_demand": 198.2321958,
    "grid_to_demand": 32302.01615,
    "govt_battery_charge": 57.74504001
  },
  {
    "timestamp": "19/04/24 6:00",
    "demand": 35621.04897,
    "solar_generated": 12.81292261,
    "wind_generated": 64.7406929,
    "marketplace_to_demand": 52.92871737,
    "grid_to_demand": 30768.38254,
    "govt_battery_charge": 29.54537254
  },
  {
    "timestamp": "19/04/24 7:00",
    "demand": 32037.82681,
    "solar_generated": 8.995757377,
    "wind_generated": 78.82908125,
    "marketplace_to_demand": 185.1828072,
    "grid_to_demand": 32580.72486,
    "govt_battery_charge": 17.88159327
  },
  {
    "timestamp": "19/04/24 8:00",
    "demand": 35530.14889,
    "solar_generated": 12.55156681,
    "wind_generated": 79.64229773,
    "marketplace_to_demand": 59.43722232,
    "grid_to_demand": 36293.0253,
    "govt_battery_charge": 92.56738867
  },
  {
    "timestamp": "19/04/24 9:00",
    "demand": 33654.61823,
    "solar_generated": 12.71404165,
    "wind_generated": 62.69557255,
    "marketplace_to_demand": 185.84474,
    "grid_to_demand": 36424.44055,
    "govt_battery_charge": 88.7764417
  },
  {
    "timestamp": "19/04/24 10:00",
    "demand": 30013.92249,
    "solar_generated": 22.39963413,
    "wind_generated": 74.19759595,
    "marketplace_to_demand": 6.827895703,
    "grid_to_demand": 32967.27185,
    "govt_battery_charge": 2.486835275
  },
  {
    "timestamp": "19/04/24 11:00",
    "demand": 31507.89019,
    "solar_generated": 6.596117283,
    "wind_generated": 76.01021062,
    "marketplace_to_demand": 128.7354693,
    "grid_to_demand": 33657.02373,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/04/24 12:00",
    "demand": 33869.63332,
    "solar_generated": 9.479344073,
    "wind_generated": 66.29617736,
    "marketplace_to_demand": 100.8016532,
    "grid_to_demand": 31187.07572,
    "govt_battery_charge": 96.30068161
  },
  {
    "timestamp": "19/04/24 13:00",
    "demand": 34409.63732,
    "solar_generated": 23.5960783,
    "wind_generated": 65.37905615,
    "marketplace_to_demand": 192.575695,
    "grid_to_demand": 35085.65994,
    "govt_battery_charge": 74.53096031
  },
  {
    "timestamp": "19/04/24 14:00",
    "demand": 34133.58871,
    "solar_generated": 22.74824508,
    "wind_generated": 64.26809532,
    "marketplace_to_demand": 35.79689458,
    "grid_to_demand": 34872.79723,
    "govt_battery_charge": 8.012517922
  },
  {
    "timestamp": "19/04/24 15:00",
    "demand": 30017.13976,
    "solar_generated": 14.21464594,
    "wind_generated": 76.49920244,
    "marketplace_to_demand": 196.7989788,
    "grid_to_demand": 31559.28613,
    "govt_battery_charge": 19.52568231
  },
  {
    "timestamp": "19/04/24 16:00",
    "demand": 34151.03505,
    "solar_generated": 12.91686387,
    "wind_generated": 56.89949929,
    "marketplace_to_demand": 122.8352292,
    "grid_to_demand": 35163.06338,
    "govt_battery_charge": 58.37373546
  },
  {
    "timestamp": "19/04/24 17:00",
    "demand": 31347.84928,
    "solar_generated": 6.99377365,
    "wind_generated": 63.42849975,
    "marketplace_to_demand": 159.4337569,
    "grid_to_demand": 35964.64319,
    "govt_battery_charge": 72.68861277
  },
  {
    "timestamp": "19/04/24 18:00",
    "demand": 31694.83955,
    "solar_generated": 0,
    "wind_generated": 70.07212902,
    "marketplace_to_demand": 9.775699909,
    "grid_to_demand": 31019.38701,
    "govt_battery_charge": 28.20367428
  },
  {
    "timestamp": "19/04/24 19:00",
    "demand": 31500.80226,
    "solar_generated": 0,
    "wind_generated": 68.10786926,
    "marketplace_to_demand": 59.70381435,
    "grid_to_demand": 33236.62709,
    "govt_battery_charge": 3.17422884
  },
  {
    "timestamp": "19/04/24 20:00",
    "demand": 30158.78086,
    "solar_generated": 0,
    "wind_generated": 74.72816033,
    "marketplace_to_demand": 153.2055042,
    "grid_to_demand": 34538.24018,
    "govt_battery_charge": 22.53092147
  },
  {
    "timestamp": "19/04/24 21:00",
    "demand": 32154.0816,
    "solar_generated": 0,
    "wind_generated": 57.92930198,
    "marketplace_to_demand": 69.13930382,
    "grid_to_demand": 33087.6292,
    "govt_battery_charge": 59.08867666
  },
  {
    "timestamp": "19/04/24 22:00",
    "demand": 30102.89138,
    "solar_generated": 0,
    "wind_generated": 55.52435671,
    "marketplace_to_demand": 142.874225,
    "grid_to_demand": 34454.73677,
    "govt_battery_charge": 3.081844425
  },
  {
    "timestamp": "19/04/24 23:00",
    "demand": 36231.47848,
    "solar_generated": 0,
    "wind_generated": 71.41970349,
    "marketplace_to_demand": 163.5677651,
    "grid_to_demand": 33649.75249,
    "govt_battery_charge": 85.58667726
  },
  {
    "timestamp": "20/04/24 0:00",
    "demand": 35705.53048,
    "solar_generated": 0,
    "wind_generated": 61.67203933,
    "marketplace_to_demand": 5.125892308,
    "grid_to_demand": 33058.86694,
    "govt_battery_charge": 40.28636542
  },
  {
    "timestamp": "20/04/24 1:00",
    "demand": 34710.89916,
    "solar_generated": 0,
    "wind_generated": 77.37805732,
    "marketplace_to_demand": 107.1379399,
    "grid_to_demand": 30442.72254,
    "govt_battery_charge": 56.9096254
  },
  {
    "timestamp": "20/04/24 2:00",
    "demand": 30167.14392,
    "solar_generated": 0,
    "wind_generated": 73.20650138,
    "marketplace_to_demand": 121.0696235,
    "grid_to_demand": 34647.94512,
    "govt_battery_charge": 68.95691333
  },
  {
    "timestamp": "20/04/24 3:00",
    "demand": 36248.84675,
    "solar_generated": 0,
    "wind_generated": 76.12721572,
    "marketplace_to_demand": 123.5072717,
    "grid_to_demand": 32779.28581,
    "govt_battery_charge": 67.10762719
  },
  {
    "timestamp": "20/04/24 4:00",
    "demand": 31263.11956,
    "solar_generated": 0,
    "wind_generated": 65.09450843,
    "marketplace_to_demand": 85.28416529,
    "grid_to_demand": 34288.03861,
    "govt_battery_charge": 44.9180414
  },
  {
    "timestamp": "20/04/24 5:00",
    "demand": 35475.2165,
    "solar_generated": 0,
    "wind_generated": 68.59665093,
    "marketplace_to_demand": 92.63662231,
    "grid_to_demand": 30426.46903,
    "govt_battery_charge": 88.05731144
  },
  {
    "timestamp": "20/04/24 6:00",
    "demand": 33481.96408,
    "solar_generated": 13.08737406,
    "wind_generated": 79.68078643,
    "marketplace_to_demand": 174.5940251,
    "grid_to_demand": 33025.11209,
    "govt_battery_charge": 45.98061414
  },
  {
    "timestamp": "20/04/24 7:00",
    "demand": 34654.63326,
    "solar_generated": 17.36228735,
    "wind_generated": 61.10730457,
    "marketplace_to_demand": 32.96763139,
    "grid_to_demand": 35523.23599,
    "govt_battery_charge": 52.52789808
  },
  {
    "timestamp": "20/04/24 8:00",
    "demand": 34801.34963,
    "solar_generated": 9.638322438,
    "wind_generated": 76.72205743,
    "marketplace_to_demand": 36.98099508,
    "grid_to_demand": 31969.85491,
    "govt_battery_charge": 64.18319629
  },
  {
    "timestamp": "20/04/24 9:00",
    "demand": 30431.99864,
    "solar_generated": 14.92194782,
    "wind_generated": 60.81916188,
    "marketplace_to_demand": 114.5340413,
    "grid_to_demand": 31108.01505,
    "govt_battery_charge": 47.3378536
  },
  {
    "timestamp": "20/04/24 10:00",
    "demand": 33878.5757,
    "solar_generated": 7.568309215,
    "wind_generated": 79.01703125,
    "marketplace_to_demand": 59.35809428,
    "grid_to_demand": 31694.6511,
    "govt_battery_charge": 50.09839214
  },
  {
    "timestamp": "20/04/24 11:00",
    "demand": 35925.60689,
    "solar_generated": 22.53130313,
    "wind_generated": 67.62794504,
    "marketplace_to_demand": 186.9923346,
    "grid_to_demand": 30666.01234,
    "govt_battery_charge": 63.33525775
  },
  {
    "timestamp": "20/04/24 12:00",
    "demand": 32197.81561,
    "solar_generated": 14.06241611,
    "wind_generated": 59.55629455,
    "marketplace_to_demand": 102.6012006,
    "grid_to_demand": 30232.7564,
    "govt_battery_charge": 67.38318437
  },
  {
    "timestamp": "20/04/24 13:00",
    "demand": 32953.80447,
    "solar_generated": 8.187140275,
    "wind_generated": 60.09614387,
    "marketplace_to_demand": 156.401773,
    "grid_to_demand": 33680.68881,
    "govt_battery_charge": 61.50981246
  },
  {
    "timestamp": "20/04/24 14:00",
    "demand": 35928.03566,
    "solar_generated": 18.7786853,
    "wind_generated": 61.43707681,
    "marketplace_to_demand": 189.9449044,
    "grid_to_demand": 33577.63902,
    "govt_battery_charge": 69.58145184
  },
  {
    "timestamp": "20/04/24 15:00",
    "demand": 31992.28206,
    "solar_generated": 21.75746757,
    "wind_generated": 77.51316107,
    "marketplace_to_demand": 79.97469717,
    "grid_to_demand": 34511.08631,
    "govt_battery_charge": 52.05992
  },
  {
    "timestamp": "20/04/24 16:00",
    "demand": 31004.66433,
    "solar_generated": 8.754966363,
    "wind_generated": 55.7897001,
    "marketplace_to_demand": 85.20900701,
    "grid_to_demand": 36218.50141,
    "govt_battery_charge": 33.97468208
  },
  {
    "timestamp": "20/04/24 17:00",
    "demand": 29982.48561,
    "solar_generated": 7.088741835,
    "wind_generated": 56.22687023,
    "marketplace_to_demand": 83.36925729,
    "grid_to_demand": 32576.40099,
    "govt_battery_charge": 1.573612021
  },
  {
    "timestamp": "20/04/24 18:00",
    "demand": 32474.53363,
    "solar_generated": 0,
    "wind_generated": 73.06445102,
    "marketplace_to_demand": 13.04401215,
    "grid_to_demand": 34815.12274,
    "govt_battery_charge": 87.46361289
  },
  {
    "timestamp": "20/04/24 19:00",
    "demand": 30132.46596,
    "solar_generated": 0,
    "wind_generated": 59.89600183,
    "marketplace_to_demand": 90.11963949,
    "grid_to_demand": 32747.87224,
    "govt_battery_charge": 22.92790003
  },
  {
    "timestamp": "20/04/24 20:00",
    "demand": 31267.51787,
    "solar_generated": 0,
    "wind_generated": 79.64974615,
    "marketplace_to_demand": 106.9312203,
    "grid_to_demand": 33910.02617,
    "govt_battery_charge": 42.98946004
  },
  {
    "timestamp": "20/04/24 21:00",
    "demand": 34954.0744,
    "solar_generated": 0,
    "wind_generated": 67.0063328,
    "marketplace_to_demand": 181.846813,
    "grid_to_demand": 31570.72018,
    "govt_battery_charge": 60.86273192
  },
  {
    "timestamp": "20/04/24 22:00",
    "demand": 35297.40201,
    "solar_generated": 0,
    "wind_generated": 79.1439322,
    "marketplace_to_demand": 37.40152274,
    "grid_to_demand": 32629.61913,
    "govt_battery_charge": 48.76645369
  },
  {
    "timestamp": "20/04/24 23:00",
    "demand": 33576.20156,
    "solar_generated": 0,
    "wind_generated": 55.0872241,
    "marketplace_to_demand": 174.8428004,
    "grid_to_demand": 31655.8518,
    "govt_battery_charge": 17.60991279
  },
  {
    "timestamp": "21/04/24 0:00",
    "demand": 32772.96308,
    "solar_generated": 0,
    "wind_generated": 62.44199254,
    "marketplace_to_demand": 162.8871481,
    "grid_to_demand": 32781.88736,
    "govt_battery_charge": 73.61499581
  },
  {
    "timestamp": "21/04/24 1:00",
    "demand": 36336.33865,
    "solar_generated": 0,
    "wind_generated": 62.47355249,
    "marketplace_to_demand": 28.25150283,
    "grid_to_demand": 31501.32927,
    "govt_battery_charge": 87.15600305
  },
  {
    "timestamp": "21/04/24 2:00",
    "demand": 33973.66724,
    "solar_generated": 0,
    "wind_generated": 59.72159673,
    "marketplace_to_demand": 153.846287,
    "grid_to_demand": 33244.6551,
    "govt_battery_charge": 16.29656449
  },
  {
    "timestamp": "21/04/24 3:00",
    "demand": 33570.64358,
    "solar_generated": 0,
    "wind_generated": 60.52366659,
    "marketplace_to_demand": 97.58758027,
    "grid_to_demand": 36300.11364,
    "govt_battery_charge": 16.00454055
  },
  {
    "timestamp": "21/04/24 4:00",
    "demand": 34966.92247,
    "solar_generated": 0,
    "wind_generated": 79.07263247,
    "marketplace_to_demand": 115.5388902,
    "grid_to_demand": 33218.48617,
    "govt_battery_charge": 4.646784049
  },
  {
    "timestamp": "21/04/24 5:00",
    "demand": 35150.38088,
    "solar_generated": 0,
    "wind_generated": 59.55479653,
    "marketplace_to_demand": 41.7809734,
    "grid_to_demand": 33468.97531,
    "govt_battery_charge": 56.78180138
  },
  {
    "timestamp": "21/04/24 6:00",
    "demand": 32036.46742,
    "solar_generated": 10.20133667,
    "wind_generated": 68.53431253,
    "marketplace_to_demand": 32.96027004,
    "grid_to_demand": 30450.85568,
    "govt_battery_charge": 96.29633075
  },
  {
    "timestamp": "21/04/24 7:00",
    "demand": 36438.31939,
    "solar_generated": 21.66488256,
    "wind_generated": 74.02321033,
    "marketplace_to_demand": 17.32594814,
    "grid_to_demand": 31654.70081,
    "govt_battery_charge": 67.65008915
  },
  {
    "timestamp": "21/04/24 8:00",
    "demand": 32341.86048,
    "solar_generated": 15.84573173,
    "wind_generated": 68.22401068,
    "marketplace_to_demand": 89.91678341,
    "grid_to_demand": 30606.23831,
    "govt_battery_charge": 43.30611867
  },
  {
    "timestamp": "21/04/24 9:00",
    "demand": 36458.17608,
    "solar_generated": 11.80918576,
    "wind_generated": 69.84694124,
    "marketplace_to_demand": 87.61841232,
    "grid_to_demand": 32225.12547,
    "govt_battery_charge": 93.29479957
  },
  {
    "timestamp": "21/04/24 10:00",
    "demand": 35904.12582,
    "solar_generated": 14.50110732,
    "wind_generated": 53.63910733,
    "marketplace_to_demand": 75.84209649,
    "grid_to_demand": 35907.79488,
    "govt_battery_charge": 88.0285337
  },
  {
    "timestamp": "21/04/24 11:00",
    "demand": 36536.07577,
    "solar_generated": 11.40932746,
    "wind_generated": 65.62660719,
    "marketplace_to_demand": 120.2559972,
    "grid_to_demand": 31376.14599,
    "govt_battery_charge": 58.24287542
  },
  {
    "timestamp": "21/04/24 12:00",
    "demand": 31545.54989,
    "solar_generated": 12.68052502,
    "wind_generated": 54.53606427,
    "marketplace_to_demand": 11.85512555,
    "grid_to_demand": 32956.63238,
    "govt_battery_charge": 11.17883197
  },
  {
    "timestamp": "21/04/24 13:00",
    "demand": 34376.49537,
    "solar_generated": 11.98346988,
    "wind_generated": 59.93514514,
    "marketplace_to_demand": 131.4004466,
    "grid_to_demand": 30195.98109,
    "govt_battery_charge": 76.77977722
  },
  {
    "timestamp": "21/04/24 14:00",
    "demand": 32706.16643,
    "solar_generated": 20.25748232,
    "wind_generated": 80.13073989,
    "marketplace_to_demand": 72.89361644,
    "grid_to_demand": 35134.8225,
    "govt_battery_charge": 60.60452165
  },
  {
    "timestamp": "21/04/24 15:00",
    "demand": 31026.80685,
    "solar_generated": 17.90584401,
    "wind_generated": 73.20148022,
    "marketplace_to_demand": 108.8826412,
    "grid_to_demand": 31696.4802,
    "govt_battery_charge": 9.558714727
  },
  {
    "timestamp": "21/04/24 16:00",
    "demand": 32591.87826,
    "solar_generated": 12.5787385,
    "wind_generated": 76.68903959,
    "marketplace_to_demand": 125.5672222,
    "grid_to_demand": 32126.22008,
    "govt_battery_charge": 79.54699413
  },
  {
    "timestamp": "21/04/24 17:00",
    "demand": 36334.97776,
    "solar_generated": 8.857732538,
    "wind_generated": 67.19984768,
    "marketplace_to_demand": 142.2727682,
    "grid_to_demand": 35290.45762,
    "govt_battery_charge": 29.35946853
  },
  {
    "timestamp": "21/04/24 18:00",
    "demand": 30837.43547,
    "solar_generated": 0,
    "wind_generated": 59.53570576,
    "marketplace_to_demand": 199.5197342,
    "grid_to_demand": 32336.28197,
    "govt_battery_charge": 52.98569771
  },
  {
    "timestamp": "21/04/24 19:00",
    "demand": 32098.85195,
    "solar_generated": 0,
    "wind_generated": 53.86999425,
    "marketplace_to_demand": 85.76806713,
    "grid_to_demand": 31503.61832,
    "govt_battery_charge": 81.52791893
  },
  {
    "timestamp": "21/04/24 20:00",
    "demand": 33253.0017,
    "solar_generated": 0,
    "wind_generated": 76.5887697,
    "marketplace_to_demand": 78.67786784,
    "grid_to_demand": 32566.57502,
    "govt_battery_charge": 46.53680258
  },
  {
    "timestamp": "21/04/24 21:00",
    "demand": 29988.67518,
    "solar_generated": 0,
    "wind_generated": 60.01067951,
    "marketplace_to_demand": 5.615085599,
    "grid_to_demand": 35808.66433,
    "govt_battery_charge": 68.04340789
  },
  {
    "timestamp": "21/04/24 22:00",
    "demand": 35461.69002,
    "solar_generated": 0,
    "wind_generated": 65.22136084,
    "marketplace_to_demand": 114.5252389,
    "grid_to_demand": 34482.3401,
    "govt_battery_charge": 6.166426892
  },
  {
    "timestamp": "21/04/24 23:00",
    "demand": 30156.11211,
    "solar_generated": 0,
    "wind_generated": 72.20264354,
    "marketplace_to_demand": 77.11433034,
    "grid_to_demand": 35098.81291,
    "govt_battery_charge": 30.38834504
  },
  {
    "timestamp": "22/04/24 0:00",
    "demand": 36336.09309,
    "solar_generated": 0,
    "wind_generated": 67.05761243,
    "marketplace_to_demand": 185.8829309,
    "grid_to_demand": 35114.86185,
    "govt_battery_charge": 9.199446727
  },
  {
    "timestamp": "22/04/24 1:00",
    "demand": 34083.59226,
    "solar_generated": 0,
    "wind_generated": 66.55685251,
    "marketplace_to_demand": 27.91923819,
    "grid_to_demand": 30807.12115,
    "govt_battery_charge": 32.47428235
  },
  {
    "timestamp": "22/04/24 2:00",
    "demand": 35656.15657,
    "solar_generated": 0,
    "wind_generated": 79.40858549,
    "marketplace_to_demand": 100.2615831,
    "grid_to_demand": 34956.26409,
    "govt_battery_charge": 8.265359912
  },
  {
    "timestamp": "22/04/24 3:00",
    "demand": 35562.34955,
    "solar_generated": 0,
    "wind_generated": 73.16805215,
    "marketplace_to_demand": 133.5663837,
    "grid_to_demand": 32852.04107,
    "govt_battery_charge": 93.01977529
  },
  {
    "timestamp": "22/04/24 4:00",
    "demand": 30509.45469,
    "solar_generated": 0,
    "wind_generated": 68.45282576,
    "marketplace_to_demand": 49.26804053,
    "grid_to_demand": 34692.73239,
    "govt_battery_charge": 16.63857121
  },
  {
    "timestamp": "22/04/24 5:00",
    "demand": 33850.4959,
    "solar_generated": 0,
    "wind_generated": 61.04537609,
    "marketplace_to_demand": 32.47635235,
    "grid_to_demand": 35035.74645,
    "govt_battery_charge": 50.10802916
  },
  {
    "timestamp": "22/04/24 6:00",
    "demand": 33912.39401,
    "solar_generated": 16.93317369,
    "wind_generated": 72.33668263,
    "marketplace_to_demand": 7.13113819,
    "grid_to_demand": 36377.33431,
    "govt_battery_charge": 82.78510246
  },
  {
    "timestamp": "22/04/24 7:00",
    "demand": 35901.03403,
    "solar_generated": 6.617369466,
    "wind_generated": 77.21410139,
    "marketplace_to_demand": 73.603197,
    "grid_to_demand": 34890.06941,
    "govt_battery_charge": 8.983130671
  },
  {
    "timestamp": "22/04/24 8:00",
    "demand": 31274.45925,
    "solar_generated": 16.55818032,
    "wind_generated": 69.15574629,
    "marketplace_to_demand": 83.62295421,
    "grid_to_demand": 35264.23122,
    "govt_battery_charge": 25.62005343
  },
  {
    "timestamp": "22/04/24 9:00",
    "demand": 36592.74439,
    "solar_generated": 15.27182154,
    "wind_generated": 53.62065333,
    "marketplace_to_demand": 30.65384598,
    "grid_to_demand": 30197.34966,
    "govt_battery_charge": 2.900340986
  },
  {
    "timestamp": "22/04/24 10:00",
    "demand": 36284.98121,
    "solar_generated": 23.03845605,
    "wind_generated": 68.67524112,
    "marketplace_to_demand": 43.87539357,
    "grid_to_demand": 31876.18759,
    "govt_battery_charge": 8.053838587
  },
  {
    "timestamp": "22/04/24 11:00",
    "demand": 34410.5212,
    "solar_generated": 9.950214443,
    "wind_generated": 56.55332682,
    "marketplace_to_demand": 19.94776502,
    "grid_to_demand": 30088.21483,
    "govt_battery_charge": 1.447147864
  },
  {
    "timestamp": "22/04/24 12:00",
    "demand": 33583.80198,
    "solar_generated": 17.32709525,
    "wind_generated": 77.56710531,
    "marketplace_to_demand": 126.9790111,
    "grid_to_demand": 35120.33796,
    "govt_battery_charge": 50.77589624
  },
  {
    "timestamp": "22/04/24 13:00",
    "demand": 33794.44512,
    "solar_generated": 8.95979839,
    "wind_generated": 66.07737455,
    "marketplace_to_demand": 192.1893777,
    "grid_to_demand": 33406.21524,
    "govt_battery_charge": 14.29441375
  },
  {
    "timestamp": "22/04/24 14:00",
    "demand": 31502.26598,
    "solar_generated": 12.34588905,
    "wind_generated": 67.96118675,
    "marketplace_to_demand": 167.6723435,
    "grid_to_demand": 34351.57069,
    "govt_battery_charge": 35.65979972
  },
  {
    "timestamp": "22/04/24 15:00",
    "demand": 36222.81535,
    "solar_generated": 19.09412761,
    "wind_generated": 66.25530164,
    "marketplace_to_demand": 55.45495809,
    "grid_to_demand": 32348.37082,
    "govt_battery_charge": 8.029854706
  },
  {
    "timestamp": "22/04/24 16:00",
    "demand": 36513.31124,
    "solar_generated": 12.89779063,
    "wind_generated": 68.58390815,
    "marketplace_to_demand": 29.92509146,
    "grid_to_demand": 34020.95494,
    "govt_battery_charge": 73.16107466
  },
  {
    "timestamp": "22/04/24 17:00",
    "demand": 30172.78687,
    "solar_generated": 13.33911622,
    "wind_generated": 58.6330954,
    "marketplace_to_demand": 101.5261975,
    "grid_to_demand": 35806.74732,
    "govt_battery_charge": 77.5337129
  },
  {
    "timestamp": "22/04/24 18:00",
    "demand": 31488.69601,
    "solar_generated": 0,
    "wind_generated": 78.93818487,
    "marketplace_to_demand": 199.9974601,
    "grid_to_demand": 31140.28246,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "22/04/24 19:00",
    "demand": 31030.45507,
    "solar_generated": 0,
    "wind_generated": 72.09456566,
    "marketplace_to_demand": 130.2784353,
    "grid_to_demand": 36530.8344,
    "govt_battery_charge": 97.63801139
  },
  {
    "timestamp": "22/04/24 20:00",
    "demand": 34578.97397,
    "solar_generated": 0,
    "wind_generated": 59.97444425,
    "marketplace_to_demand": 55.27452077,
    "grid_to_demand": 31173.20117,
    "govt_battery_charge": 89.98973016
  },
  {
    "timestamp": "22/04/24 21:00",
    "demand": 31995.3613,
    "solar_generated": 0,
    "wind_generated": 62.58850049,
    "marketplace_to_demand": 145.9378402,
    "grid_to_demand": 34921.35898,
    "govt_battery_charge": 36.87389151
  },
  {
    "timestamp": "22/04/24 22:00",
    "demand": 36061.67483,
    "solar_generated": 0,
    "wind_generated": 57.91337896,
    "marketplace_to_demand": 88.56592546,
    "grid_to_demand": 30003.76066,
    "govt_battery_charge": 51.08588716
  },
  {
    "timestamp": "22/04/24 23:00",
    "demand": 30668.91535,
    "solar_generated": 0,
    "wind_generated": 58.76651305,
    "marketplace_to_demand": 198.282858,
    "grid_to_demand": 33700.9284,
    "govt_battery_charge": 22.76927608
  },
  {
    "timestamp": "23/04/24 0:00",
    "demand": 31829.18971,
    "solar_generated": 0,
    "wind_generated": 67.11267233,
    "marketplace_to_demand": 191.2535498,
    "grid_to_demand": 35914.5676,
    "govt_battery_charge": 95.00927416
  },
  {
    "timestamp": "23/04/24 1:00",
    "demand": 35986.61563,
    "solar_generated": 0,
    "wind_generated": 65.49091267,
    "marketplace_to_demand": 165.857822,
    "grid_to_demand": 30693.42966,
    "govt_battery_charge": 75.6841854
  },
  {
    "timestamp": "23/04/24 2:00",
    "demand": 31765.6058,
    "solar_generated": 0,
    "wind_generated": 59.01523036,
    "marketplace_to_demand": 65.73974632,
    "grid_to_demand": 35431.63961,
    "govt_battery_charge": 24.28495241
  },
  {
    "timestamp": "23/04/24 3:00",
    "demand": 33913.23159,
    "solar_generated": 0,
    "wind_generated": 72.20516315,
    "marketplace_to_demand": 102.7180965,
    "grid_to_demand": 31893.10692,
    "govt_battery_charge": 51.04580699
  },
  {
    "timestamp": "23/04/24 4:00",
    "demand": 35688.76067,
    "solar_generated": 0,
    "wind_generated": 80.0964191,
    "marketplace_to_demand": 165.5956866,
    "grid_to_demand": 34283.16714,
    "govt_battery_charge": 77.72657593
  },
  {
    "timestamp": "23/04/24 5:00",
    "demand": 33963.42793,
    "solar_generated": 0,
    "wind_generated": 77.26655253,
    "marketplace_to_demand": 146.9921998,
    "grid_to_demand": 34143.25754,
    "govt_battery_charge": 74.37635459
  },
  {
    "timestamp": "23/04/24 6:00",
    "demand": 34654.82815,
    "solar_generated": 16.99175468,
    "wind_generated": 78.47113526,
    "marketplace_to_demand": 49.14718283,
    "grid_to_demand": 30706.70538,
    "govt_battery_charge": 3.817540332
  },
  {
    "timestamp": "23/04/24 7:00",
    "demand": 30781.1879,
    "solar_generated": 12.59924771,
    "wind_generated": 77.18558317,
    "marketplace_to_demand": 140.2173688,
    "grid_to_demand": 31687.28566,
    "govt_battery_charge": 25.03375726
  },
  {
    "timestamp": "23/04/24 8:00",
    "demand": 34408.90269,
    "solar_generated": 20.03061516,
    "wind_generated": 70.11921071,
    "marketplace_to_demand": 174.7855903,
    "grid_to_demand": 33303.31108,
    "govt_battery_charge": 22.96719093
  },
  {
    "timestamp": "23/04/24 9:00",
    "demand": 30689.8712,
    "solar_generated": 20.75362547,
    "wind_generated": 56.71133744,
    "marketplace_to_demand": 17.78529909,
    "grid_to_demand": 30991.40404,
    "govt_battery_charge": 86.36309326
  },
  {
    "timestamp": "23/04/24 10:00",
    "demand": 34426.87635,
    "solar_generated": 6.055205043,
    "wind_generated": 74.52921183,
    "marketplace_to_demand": 7.715212979,
    "grid_to_demand": 31706.02303,
    "govt_battery_charge": 8.707583606
  },
  {
    "timestamp": "23/04/24 11:00",
    "demand": 35692.11997,
    "solar_generated": 22.65173309,
    "wind_generated": 78.36506058,
    "marketplace_to_demand": 48.68046142,
    "grid_to_demand": 36032.67241,
    "govt_battery_charge": 22.82070708
  },
  {
    "timestamp": "23/04/24 12:00",
    "demand": 32518.83199,
    "solar_generated": 21.46262135,
    "wind_generated": 67.26930196,
    "marketplace_to_demand": 196.5757681,
    "grid_to_demand": 31307.21837,
    "govt_battery_charge": 48.9625136
  },
  {
    "timestamp": "23/04/24 13:00",
    "demand": 31138.23006,
    "solar_generated": 19.24793698,
    "wind_generated": 62.97051992,
    "marketplace_to_demand": 6.228831008,
    "grid_to_demand": 35487.10039,
    "govt_battery_charge": 57.42018421
  },
  {
    "timestamp": "23/04/24 14:00",
    "demand": 31063.49433,
    "solar_generated": 6.728189363,
    "wind_generated": 80.25431684,
    "marketplace_to_demand": 50.95176702,
    "grid_to_demand": 33090.65381,
    "govt_battery_charge": 74.16320571
  },
  {
    "timestamp": "23/04/24 15:00",
    "demand": 32376.73261,
    "solar_generated": 20.52648279,
    "wind_generated": 77.41211599,
    "marketplace_to_demand": 124.8094428,
    "grid_to_demand": 30910.66072,
    "govt_battery_charge": 68.61522989
  },
  {
    "timestamp": "23/04/24 16:00",
    "demand": 35486.58804,
    "solar_generated": 17.22607654,
    "wind_generated": 66.46856317,
    "marketplace_to_demand": 69.95634259,
    "grid_to_demand": 33090.62363,
    "govt_battery_charge": 24.14296506
  },
  {
    "timestamp": "23/04/24 17:00",
    "demand": 31513.19294,
    "solar_generated": 7.843834563,
    "wind_generated": 68.06265121,
    "marketplace_to_demand": 71.59961735,
    "grid_to_demand": 31926.93168,
    "govt_battery_charge": 93.8218821
  },
  {
    "timestamp": "23/04/24 18:00",
    "demand": 34037.02858,
    "solar_generated": 0,
    "wind_generated": 64.01977256,
    "marketplace_to_demand": 118.9079187,
    "grid_to_demand": 32888.81521,
    "govt_battery_charge": 83.06867072
  },
  {
    "timestamp": "23/04/24 19:00",
    "demand": 33264.98838,
    "solar_generated": 0,
    "wind_generated": 76.85070492,
    "marketplace_to_demand": 20.92499776,
    "grid_to_demand": 33661.11702,
    "govt_battery_charge": 36.49305796
  },
  {
    "timestamp": "23/04/24 20:00",
    "demand": 35339.01814,
    "solar_generated": 0,
    "wind_generated": 65.67942591,
    "marketplace_to_demand": 119.939797,
    "grid_to_demand": 34586.32788,
    "govt_battery_charge": 14.47026489
  },
  {
    "timestamp": "23/04/24 21:00",
    "demand": 30245.623,
    "solar_generated": 0,
    "wind_generated": 65.89516711,
    "marketplace_to_demand": 169.8198212,
    "grid_to_demand": 34367.33021,
    "govt_battery_charge": 7.836025707
  },
  {
    "timestamp": "23/04/24 22:00",
    "demand": 33795.25627,
    "solar_generated": 0,
    "wind_generated": 72.59092428,
    "marketplace_to_demand": 140.2541267,
    "grid_to_demand": 32455.92624,
    "govt_battery_charge": 39.20236376
  },
  {
    "timestamp": "23/04/24 23:00",
    "demand": 35736.97413,
    "solar_generated": 0,
    "wind_generated": 78.16705396,
    "marketplace_to_demand": 70.82818975,
    "grid_to_demand": 33975.49145,
    "govt_battery_charge": 24.28070657
  },
  {
    "timestamp": "24/04/24 0:00",
    "demand": 31447.74446,
    "solar_generated": 0,
    "wind_generated": 73.65977496,
    "marketplace_to_demand": 51.05142991,
    "grid_to_demand": 35838.14359,
    "govt_battery_charge": 78.55608994
  },
  {
    "timestamp": "24/04/24 1:00",
    "demand": 35430.14737,
    "solar_generated": 0,
    "wind_generated": 56.53964836,
    "marketplace_to_demand": 43.87676091,
    "grid_to_demand": 36419.89733,
    "govt_battery_charge": 31.6366748
  },
  {
    "timestamp": "24/04/24 2:00",
    "demand": 35200.76684,
    "solar_generated": 0,
    "wind_generated": 78.87987514,
    "marketplace_to_demand": 93.44794099,
    "grid_to_demand": 34607.69616,
    "govt_battery_charge": 55.8953382
  },
  {
    "timestamp": "24/04/24 3:00",
    "demand": 35720.24468,
    "solar_generated": 0,
    "wind_generated": 67.77889159,
    "marketplace_to_demand": 80.37322005,
    "grid_to_demand": 35018.48505,
    "govt_battery_charge": 70.84641304
  },
  {
    "timestamp": "24/04/24 4:00",
    "demand": 33901.78895,
    "solar_generated": 0,
    "wind_generated": 71.62214894,
    "marketplace_to_demand": 91.50373092,
    "grid_to_demand": 31542.92282,
    "govt_battery_charge": 2.017054116
  },
  {
    "timestamp": "24/04/24 5:00",
    "demand": 36092.60741,
    "solar_generated": 0,
    "wind_generated": 56.58705199,
    "marketplace_to_demand": 32.19152345,
    "grid_to_demand": 30936.76007,
    "govt_battery_charge": 58.46061606
  },
  {
    "timestamp": "24/04/24 6:00",
    "demand": 35218.68913,
    "solar_generated": 21.02987943,
    "wind_generated": 64.63211981,
    "marketplace_to_demand": 68.49184559,
    "grid_to_demand": 32751.82943,
    "govt_battery_charge": 82.14724853
  },
  {
    "timestamp": "24/04/24 7:00",
    "demand": 36443.75864,
    "solar_generated": 16.65587541,
    "wind_generated": 66.02394562,
    "marketplace_to_demand": 71.38461034,
    "grid_to_demand": 31289.26169,
    "govt_battery_charge": 95.86119258
  },
  {
    "timestamp": "24/04/24 8:00",
    "demand": 30393.74309,
    "solar_generated": 16.50618898,
    "wind_generated": 61.00486461,
    "marketplace_to_demand": 104.3783281,
    "grid_to_demand": 33497.86632,
    "govt_battery_charge": 70.63783705
  },
  {
    "timestamp": "24/04/24 9:00",
    "demand": 32007.25132,
    "solar_generated": 21.97937572,
    "wind_generated": 59.77682398,
    "marketplace_to_demand": 95.81259934,
    "grid_to_demand": 33935.54893,
    "govt_battery_charge": 20.92256104
  },
  {
    "timestamp": "24/04/24 10:00",
    "demand": 30008.35991,
    "solar_generated": 7.44882673,
    "wind_generated": 67.05671809,
    "marketplace_to_demand": 48.8172696,
    "grid_to_demand": 30707.06562,
    "govt_battery_charge": 30.04173879
  },
  {
    "timestamp": "24/04/24 11:00",
    "demand": 30991.73649,
    "solar_generated": 8.041133323,
    "wind_generated": 67.5071059,
    "marketplace_to_demand": 84.3380517,
    "grid_to_demand": 29987.22107,
    "govt_battery_charge": 87.99164285
  },
  {
    "timestamp": "24/04/24 12:00",
    "demand": 31682.33282,
    "solar_generated": 13.131213,
    "wind_generated": 75.15095147,
    "marketplace_to_demand": 82.42625014,
    "grid_to_demand": 34057.56591,
    "govt_battery_charge": 60.53998651
  },
  {
    "timestamp": "24/04/24 13:00",
    "demand": 35431.03732,
    "solar_generated": 19.88024754,
    "wind_generated": 68.42208533,
    "marketplace_to_demand": 136.4704649,
    "grid_to_demand": 35864.63155,
    "govt_battery_charge": 42.42220067
  },
  {
    "timestamp": "24/04/24 14:00",
    "demand": 34911.60619,
    "solar_generated": 13.50437923,
    "wind_generated": 71.20928486,
    "marketplace_to_demand": 135.3051812,
    "grid_to_demand": 33421.7559,
    "govt_battery_charge": 75.88261917
  },
  {
    "timestamp": "24/04/24 15:00",
    "demand": 36430.55962,
    "solar_generated": 17.82411048,
    "wind_generated": 55.39492735,
    "marketplace_to_demand": 147.488659,
    "grid_to_demand": 34308.71029,
    "govt_battery_charge": 95.58286947
  },
  {
    "timestamp": "24/04/24 16:00",
    "demand": 36014.35518,
    "solar_generated": 16.48459173,
    "wind_generated": 65.25008797,
    "marketplace_to_demand": 102.963784,
    "grid_to_demand": 35684.59389,
    "govt_battery_charge": 64.81109109
  },
  {
    "timestamp": "24/04/24 17:00",
    "demand": 32500.92895,
    "solar_generated": 15.19273407,
    "wind_generated": 55.66086376,
    "marketplace_to_demand": 4.688814302,
    "grid_to_demand": 32868.87366,
    "govt_battery_charge": 79.95114566
  },
  {
    "timestamp": "24/04/24 18:00",
    "demand": 32675.41411,
    "solar_generated": 0,
    "wind_generated": 58.45921099,
    "marketplace_to_demand": 93.79086695,
    "grid_to_demand": 31314.73573,
    "govt_battery_charge": 1.123722661
  },
  {
    "timestamp": "24/04/24 19:00",
    "demand": 34214.52007,
    "solar_generated": 0,
    "wind_generated": 58.14821949,
    "marketplace_to_demand": 21.6404462,
    "grid_to_demand": 34040.46077,
    "govt_battery_charge": 82.33780789
  },
  {
    "timestamp": "24/04/24 20:00",
    "demand": 30627.31653,
    "solar_generated": 0,
    "wind_generated": 59.63485009,
    "marketplace_to_demand": 50.60081803,
    "grid_to_demand": 34880.64249,
    "govt_battery_charge": 5.075633431
  },
  {
    "timestamp": "24/04/24 21:00",
    "demand": 33995.72728,
    "solar_generated": 0,
    "wind_generated": 58.67669853,
    "marketplace_to_demand": 114.1507797,
    "grid_to_demand": 30730.5207,
    "govt_battery_charge": 28.15761358
  },
  {
    "timestamp": "24/04/24 22:00",
    "demand": 36465.77368,
    "solar_generated": 0,
    "wind_generated": 54.63671106,
    "marketplace_to_demand": 121.212454,
    "grid_to_demand": 31559.35289,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "24/04/24 23:00",
    "demand": 33640.6599,
    "solar_generated": 0,
    "wind_generated": 56.15024945,
    "marketplace_to_demand": 59.07661279,
    "grid_to_demand": 30117.2051,
    "govt_battery_charge": 57.36791712
  },
  {
    "timestamp": "25/04/24 0:00",
    "demand": 35719.012,
    "solar_generated": 0,
    "wind_generated": 61.95549196,
    "marketplace_to_demand": 29.70621277,
    "grid_to_demand": 33817.75416,
    "govt_battery_charge": 16.20057442
  },
  {
    "timestamp": "25/04/24 1:00",
    "demand": 33262.1892,
    "solar_generated": 0,
    "wind_generated": 58.09070293,
    "marketplace_to_demand": 51.36173426,
    "grid_to_demand": 35651.6043,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "25/04/24 2:00",
    "demand": 32459.26137,
    "solar_generated": 0,
    "wind_generated": 67.6773707,
    "marketplace_to_demand": 34.58060371,
    "grid_to_demand": 32713.58447,
    "govt_battery_charge": 85.73955827
  },
  {
    "timestamp": "25/04/24 3:00",
    "demand": 35007.7596,
    "solar_generated": 0,
    "wind_generated": 80.21113272,
    "marketplace_to_demand": 191.4465139,
    "grid_to_demand": 31297.91522,
    "govt_battery_charge": 65.34646496
  },
  {
    "timestamp": "25/04/24 4:00",
    "demand": 32113.25323,
    "solar_generated": 0,
    "wind_generated": 64.97279389,
    "marketplace_to_demand": 168.2812934,
    "grid_to_demand": 36082.61966,
    "govt_battery_charge": 37.74834216
  },
  {
    "timestamp": "25/04/24 5:00",
    "demand": 33184.96496,
    "solar_generated": 0,
    "wind_generated": 55.36459346,
    "marketplace_to_demand": 98.42053582,
    "grid_to_demand": 35050.05571,
    "govt_battery_charge": 5.259905023
  },
  {
    "timestamp": "25/04/24 6:00",
    "demand": 31910.04369,
    "solar_generated": 20.09028924,
    "wind_generated": 77.8262431,
    "marketplace_to_demand": 124.7397604,
    "grid_to_demand": 34852.93243,
    "govt_battery_charge": 83.0186953
  },
  {
    "timestamp": "25/04/24 7:00",
    "demand": 34590.13558,
    "solar_generated": 7.039429702,
    "wind_generated": 58.37178844,
    "marketplace_to_demand": 95.64510964,
    "grid_to_demand": 33495.81928,
    "govt_battery_charge": 47.67864915
  },
  {
    "timestamp": "25/04/24 8:00",
    "demand": 33984.17222,
    "solar_generated": 21.03923879,
    "wind_generated": 63.44936399,
    "marketplace_to_demand": 126.8982572,
    "grid_to_demand": 35869.18263,
    "govt_battery_charge": 50.01594572
  },
  {
    "timestamp": "25/04/24 9:00",
    "demand": 35327.52849,
    "solar_generated": 20.68084876,
    "wind_generated": 77.4495388,
    "marketplace_to_demand": 22.81462533,
    "grid_to_demand": 30307.92941,
    "govt_battery_charge": 34.88089439
  },
  {
    "timestamp": "25/04/24 10:00",
    "demand": 36010.22822,
    "solar_generated": 9.704503358,
    "wind_generated": 76.92655354,
    "marketplace_to_demand": 57.57827668,
    "grid_to_demand": 30979.99435,
    "govt_battery_charge": 44.46719138
  },
  {
    "timestamp": "25/04/24 11:00",
    "demand": 32441.83862,
    "solar_generated": 21.78690818,
    "wind_generated": 62.85442149,
    "marketplace_to_demand": 52.26819825,
    "grid_to_demand": 36527.56695,
    "govt_battery_charge": 31.79812652
  },
  {
    "timestamp": "25/04/24 12:00",
    "demand": 33945.41376,
    "solar_generated": 20.83406199,
    "wind_generated": 68.27686764,
    "marketplace_to_demand": 25.77845454,
    "grid_to_demand": 32169.26694,
    "govt_battery_charge": 48.27745397
  },
  {
    "timestamp": "25/04/24 13:00",
    "demand": 32974.70748,
    "solar_generated": 12.53581399,
    "wind_generated": 68.36597876,
    "marketplace_to_demand": 16.94260946,
    "grid_to_demand": 32985.96244,
    "govt_battery_charge": 83.24574044
  },
  {
    "timestamp": "25/04/24 14:00",
    "demand": 32364.96746,
    "solar_generated": 16.85042748,
    "wind_generated": 77.46550727,
    "marketplace_to_demand": 85.84479008,
    "grid_to_demand": 36127.66545,
    "govt_battery_charge": 6.828843119
  },
  {
    "timestamp": "25/04/24 15:00",
    "demand": 35955.17482,
    "solar_generated": 15.48147317,
    "wind_generated": 54.07519901,
    "marketplace_to_demand": 143.9917683,
    "grid_to_demand": 35160.07772,
    "govt_battery_charge": 54.91732103
  },
  {
    "timestamp": "25/04/24 16:00",
    "demand": 30254.95854,
    "solar_generated": 15.40809233,
    "wind_generated": 67.793519,
    "marketplace_to_demand": 115.4784889,
    "grid_to_demand": 32306.56225,
    "govt_battery_charge": 66.3763795
  },
  {
    "timestamp": "25/04/24 17:00",
    "demand": 35553.24054,
    "solar_generated": 19.1447634,
    "wind_generated": 69.13597794,
    "marketplace_to_demand": 95.64132079,
    "grid_to_demand": 35960.30171,
    "govt_battery_charge": 29.36033779
  },
  {
    "timestamp": "25/04/24 18:00",
    "demand": 32198.84273,
    "solar_generated": 0,
    "wind_generated": 66.64516363,
    "marketplace_to_demand": 60.13257558,
    "grid_to_demand": 36321.2949,
    "govt_battery_charge": 33.80720402
  },
  {
    "timestamp": "25/04/24 19:00",
    "demand": 34452.63802,
    "solar_generated": 0,
    "wind_generated": 76.17235606,
    "marketplace_to_demand": 199.5899233,
    "grid_to_demand": 33140.04109,
    "govt_battery_charge": 67.41415754
  },
  {
    "timestamp": "25/04/24 20:00",
    "demand": 31995.85636,
    "solar_generated": 0,
    "wind_generated": 62.69443273,
    "marketplace_to_demand": 133.1977635,
    "grid_to_demand": 31729.76727,
    "govt_battery_charge": 10.59494548
  },
  {
    "timestamp": "25/04/24 21:00",
    "demand": 30350.59958,
    "solar_generated": 0,
    "wind_generated": 57.29300133,
    "marketplace_to_demand": 123.9518384,
    "grid_to_demand": 30279.0906,
    "govt_battery_charge": 72.83183105
  },
  {
    "timestamp": "25/04/24 22:00",
    "demand": 35487.63241,
    "solar_generated": 0,
    "wind_generated": 57.34172722,
    "marketplace_to_demand": 93.97638047,
    "grid_to_demand": 29990.17937,
    "govt_battery_charge": 2.315749321
  },
  {
    "timestamp": "25/04/24 23:00",
    "demand": 30172.89864,
    "solar_generated": 0,
    "wind_generated": 58.40925124,
    "marketplace_to_demand": 198.5966263,
    "grid_to_demand": 33761.1084,
    "govt_battery_charge": 76.33551056
  },
  {
    "timestamp": "26/04/24 0:00",
    "demand": 33978.24809,
    "solar_generated": 0,
    "wind_generated": 59.86778467,
    "marketplace_to_demand": 106.1452927,
    "grid_to_demand": 35079.27845,
    "govt_battery_charge": 29.68497968
  },
  {
    "timestamp": "26/04/24 1:00",
    "demand": 31091.2394,
    "solar_generated": 0,
    "wind_generated": 61.92049992,
    "marketplace_to_demand": 89.70538111,
    "grid_to_demand": 34343.76933,
    "govt_battery_charge": 37.58491296
  },
  {
    "timestamp": "26/04/24 2:00",
    "demand": 31273.95921,
    "solar_generated": 0,
    "wind_generated": 53.65661985,
    "marketplace_to_demand": 136.8575173,
    "grid_to_demand": 33715.29464,
    "govt_battery_charge": 48.30982797
  },
  {
    "timestamp": "26/04/24 3:00",
    "demand": 35479.31396,
    "solar_generated": 0,
    "wind_generated": 58.1543514,
    "marketplace_to_demand": 87.31287743,
    "grid_to_demand": 30246.96096,
    "govt_battery_charge": 89.41823915
  },
  {
    "timestamp": "26/04/24 4:00",
    "demand": 31362.59451,
    "solar_generated": 0,
    "wind_generated": 78.57411979,
    "marketplace_to_demand": 13.58247847,
    "grid_to_demand": 32901.41053,
    "govt_battery_charge": 7.258174823
  },
  {
    "timestamp": "26/04/24 5:00",
    "demand": 36350.99428,
    "solar_generated": 0,
    "wind_generated": 64.62291672,
    "marketplace_to_demand": 91.4319108,
    "grid_to_demand": 32111.71187,
    "govt_battery_charge": 44.98934991
  },
  {
    "timestamp": "26/04/24 6:00",
    "demand": 30164.70377,
    "solar_generated": 17.23504713,
    "wind_generated": 66.63159142,
    "marketplace_to_demand": 168.607293,
    "grid_to_demand": 34348.53038,
    "govt_battery_charge": 4.091315257
  },
  {
    "timestamp": "26/04/24 7:00",
    "demand": 31542.89239,
    "solar_generated": 22.33889738,
    "wind_generated": 60.12903125,
    "marketplace_to_demand": 23.64088856,
    "grid_to_demand": 33011.66808,
    "govt_battery_charge": 11.38438034
  },
  {
    "timestamp": "26/04/24 8:00",
    "demand": 34580.3043,
    "solar_generated": 23.45604579,
    "wind_generated": 59.47990564,
    "marketplace_to_demand": 113.0047383,
    "grid_to_demand": 30068.18458,
    "govt_battery_charge": 52.75806298
  },
  {
    "timestamp": "26/04/24 9:00",
    "demand": 30554.73253,
    "solar_generated": 19.47472352,
    "wind_generated": 74.55647415,
    "marketplace_to_demand": 119.0733119,
    "grid_to_demand": 35417.31723,
    "govt_battery_charge": 43.17975946
  },
  {
    "timestamp": "26/04/24 10:00",
    "demand": 30276.2181,
    "solar_generated": 10.92825631,
    "wind_generated": 73.53614581,
    "marketplace_to_demand": 53.33366075,
    "grid_to_demand": 30657.10175,
    "govt_battery_charge": 82.31814355
  },
  {
    "timestamp": "26/04/24 11:00",
    "demand": 36454.99824,
    "solar_generated": 7.260602723,
    "wind_generated": 75.8154623,
    "marketplace_to_demand": 45.54176784,
    "grid_to_demand": 34035.2479,
    "govt_battery_charge": 13.51843855
  },
  {
    "timestamp": "26/04/24 12:00",
    "demand": 33918.08183,
    "solar_generated": 13.07339906,
    "wind_generated": 63.39814036,
    "marketplace_to_demand": 78.14843668,
    "grid_to_demand": 34171.49622,
    "govt_battery_charge": 88.57631962
  },
  {
    "timestamp": "26/04/24 13:00",
    "demand": 36202.88751,
    "solar_generated": 11.30686988,
    "wind_generated": 79.65174239,
    "marketplace_to_demand": 108.4954052,
    "grid_to_demand": 32709.91488,
    "govt_battery_charge": 18.84678344
  },
  {
    "timestamp": "26/04/24 14:00",
    "demand": 31071.17795,
    "solar_generated": 10.1080313,
    "wind_generated": 59.04113713,
    "marketplace_to_demand": 186.1571602,
    "grid_to_demand": 33073.71216,
    "govt_battery_charge": 36.41317244
  },
  {
    "timestamp": "26/04/24 15:00",
    "demand": 35079.30501,
    "solar_generated": 9.159471037,
    "wind_generated": 76.95914562,
    "marketplace_to_demand": 84.50540262,
    "grid_to_demand": 33877.11018,
    "govt_battery_charge": 46.66659544
  },
  {
    "timestamp": "26/04/24 16:00",
    "demand": 30404.60963,
    "solar_generated": 10.19589191,
    "wind_generated": 69.50121899,
    "marketplace_to_demand": 8.480501604,
    "grid_to_demand": 34152.20434,
    "govt_battery_charge": 38.99860785
  },
  {
    "timestamp": "26/04/24 17:00",
    "demand": 33110.88043,
    "solar_generated": 13.02029978,
    "wind_generated": 76.88033267,
    "marketplace_to_demand": 147.1592488,
    "grid_to_demand": 30573.85859,
    "govt_battery_charge": 65.17997952
  },
  {
    "timestamp": "26/04/24 18:00",
    "demand": 34463.83997,
    "solar_generated": 0,
    "wind_generated": 64.66747335,
    "marketplace_to_demand": 180.2759811,
    "grid_to_demand": 31783.25842,
    "govt_battery_charge": 22.94335187
  },
  {
    "timestamp": "26/04/24 19:00",
    "demand": 31719.57683,
    "solar_generated": 0,
    "wind_generated": 71.6597077,
    "marketplace_to_demand": 140.3809746,
    "grid_to_demand": 36208.60709,
    "govt_battery_charge": 29.41722524
  },
  {
    "timestamp": "26/04/24 20:00",
    "demand": 31554.43027,
    "solar_generated": 0,
    "wind_generated": 67.38354667,
    "marketplace_to_demand": 17.33711208,
    "grid_to_demand": 30859.60558,
    "govt_battery_charge": 21.51298745
  },
  {
    "timestamp": "26/04/24 21:00",
    "demand": 34933.86729,
    "solar_generated": 0,
    "wind_generated": 53.66240606,
    "marketplace_to_demand": 98.84550622,
    "grid_to_demand": 30631.749,
    "govt_battery_charge": 97.76202843
  },
  {
    "timestamp": "26/04/24 22:00",
    "demand": 31419.32382,
    "solar_generated": 0,
    "wind_generated": 59.01486401,
    "marketplace_to_demand": 189.1913806,
    "grid_to_demand": 31346.79189,
    "govt_battery_charge": 21.91349426
  },
  {
    "timestamp": "26/04/24 23:00",
    "demand": 32128.61326,
    "solar_generated": 0,
    "wind_generated": 79.28930265,
    "marketplace_to_demand": 168.5622682,
    "grid_to_demand": 30631.5239,
    "govt_battery_charge": 62.85321434
  },
  {
    "timestamp": "27/04/24 0:00",
    "demand": 35446.23288,
    "solar_generated": 0,
    "wind_generated": 56.7073119,
    "marketplace_to_demand": 103.734559,
    "grid_to_demand": 33149.23667,
    "govt_battery_charge": 94.6676942
  },
  {
    "timestamp": "27/04/24 1:00",
    "demand": 34455.71064,
    "solar_generated": 0,
    "wind_generated": 64.36658088,
    "marketplace_to_demand": 15.37757827,
    "grid_to_demand": 33720.13999,
    "govt_battery_charge": 88.29697779
  },
  {
    "timestamp": "27/04/24 2:00",
    "demand": 34678.82066,
    "solar_generated": 0,
    "wind_generated": 78.98041752,
    "marketplace_to_demand": 80.22550303,
    "grid_to_demand": 35465.2076,
    "govt_battery_charge": 93.6148354
  },
  {
    "timestamp": "27/04/24 3:00",
    "demand": 30865.80045,
    "solar_generated": 0,
    "wind_generated": 71.44054204,
    "marketplace_to_demand": 158.9609304,
    "grid_to_demand": 36036.44225,
    "govt_battery_charge": 51.89345548
  },
  {
    "timestamp": "27/04/24 4:00",
    "demand": 33477.40061,
    "solar_generated": 0,
    "wind_generated": 72.252905,
    "marketplace_to_demand": 180.209097,
    "grid_to_demand": 35543.64983,
    "govt_battery_charge": 92.1129661
  },
  {
    "timestamp": "27/04/24 5:00",
    "demand": 31119.84181,
    "solar_generated": 0,
    "wind_generated": 54.64523207,
    "marketplace_to_demand": 26.6883846,
    "grid_to_demand": 33136.28843,
    "govt_battery_charge": 73.71147099
  },
  {
    "timestamp": "27/04/24 6:00",
    "demand": 33507.21739,
    "solar_generated": 12.15718094,
    "wind_generated": 75.49038633,
    "marketplace_to_demand": 187.6881686,
    "grid_to_demand": 33847.65261,
    "govt_battery_charge": 37.09273916
  },
  {
    "timestamp": "27/04/24 7:00",
    "demand": 31009.04827,
    "solar_generated": 18.84624232,
    "wind_generated": 71.47641286,
    "marketplace_to_demand": 5.799181985,
    "grid_to_demand": 30911.46496,
    "govt_battery_charge": 75.44899701
  },
  {
    "timestamp": "27/04/24 8:00",
    "demand": 33482.41306,
    "solar_generated": 8.836136784,
    "wind_generated": 54.03225336,
    "marketplace_to_demand": 133.1553534,
    "grid_to_demand": 32492.28922,
    "govt_battery_charge": 89.84278979
  },
  {
    "timestamp": "27/04/24 9:00",
    "demand": 36185.36006,
    "solar_generated": 14.3350551,
    "wind_generated": 75.0199685,
    "marketplace_to_demand": 118.1117902,
    "grid_to_demand": 34648.01494,
    "govt_battery_charge": 10.0289935
  },
  {
    "timestamp": "27/04/24 10:00",
    "demand": 32105.98478,
    "solar_generated": 12.76964477,
    "wind_generated": 56.82068084,
    "marketplace_to_demand": 154.5857448,
    "grid_to_demand": 33733.3433,
    "govt_battery_charge": 61.84453533
  },
  {
    "timestamp": "27/04/24 11:00",
    "demand": 34806.78476,
    "solar_generated": 21.21755142,
    "wind_generated": 55.05861018,
    "marketplace_to_demand": 20.33700667,
    "grid_to_demand": 30531.73819,
    "govt_battery_charge": 2.614054104
  },
  {
    "timestamp": "27/04/24 12:00",
    "demand": 33314.44423,
    "solar_generated": 23.86637818,
    "wind_generated": 74.54080408,
    "marketplace_to_demand": 24.72426073,
    "grid_to_demand": 33986.26979,
    "govt_battery_charge": 33.10892656
  },
  {
    "timestamp": "27/04/24 13:00",
    "demand": 30677.15464,
    "solar_generated": 15.95561501,
    "wind_generated": 78.89899783,
    "marketplace_to_demand": 27.8547046,
    "grid_to_demand": 30250.10428,
    "govt_battery_charge": 66.48522152
  },
  {
    "timestamp": "27/04/24 14:00",
    "demand": 29980.05436,
    "solar_generated": 11.53766079,
    "wind_generated": 69.94743322,
    "marketplace_to_demand": 149.8275479,
    "grid_to_demand": 32554.31746,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "27/04/24 15:00",
    "demand": 31478.30984,
    "solar_generated": 15.64104475,
    "wind_generated": 65.00037878,
    "marketplace_to_demand": 192.0129789,
    "grid_to_demand": 33589.31555,
    "govt_battery_charge": 45.80698694
  },
  {
    "timestamp": "27/04/24 16:00",
    "demand": 30602.06953,
    "solar_generated": 14.99382242,
    "wind_generated": 53.9221851,
    "marketplace_to_demand": 36.19569702,
    "grid_to_demand": 32016.62293,
    "govt_battery_charge": 61.60179166
  },
  {
    "timestamp": "27/04/24 17:00",
    "demand": 33196.08854,
    "solar_generated": 12.23423474,
    "wind_generated": 63.73412105,
    "marketplace_to_demand": 85.77828111,
    "grid_to_demand": 30624.6167,
    "govt_battery_charge": 78.59019714
  },
  {
    "timestamp": "27/04/24 18:00",
    "demand": 32615.82907,
    "solar_generated": 0,
    "wind_generated": 57.6856373,
    "marketplace_to_demand": 123.6867011,
    "grid_to_demand": 31307.18702,
    "govt_battery_charge": 83.948965
  },
  {
    "timestamp": "27/04/24 19:00",
    "demand": 30045.075,
    "solar_generated": 0,
    "wind_generated": 78.27572468,
    "marketplace_to_demand": 80.61181525,
    "grid_to_demand": 30659.43942,
    "govt_battery_charge": 83.49286745
  },
  {
    "timestamp": "27/04/24 20:00",
    "demand": 34926.25815,
    "solar_generated": 0,
    "wind_generated": 74.26730581,
    "marketplace_to_demand": 122.7142783,
    "grid_to_demand": 33129.16673,
    "govt_battery_charge": 60.33299471
  },
  {
    "timestamp": "27/04/24 21:00",
    "demand": 33993.91564,
    "solar_generated": 0,
    "wind_generated": 72.21080456,
    "marketplace_to_demand": 38.13976026,
    "grid_to_demand": 34071.12189,
    "govt_battery_charge": 62.03406799
  },
  {
    "timestamp": "27/04/24 22:00",
    "demand": 31005.6782,
    "solar_generated": 0,
    "wind_generated": 66.45632384,
    "marketplace_to_demand": 42.45364442,
    "grid_to_demand": 32486.85091,
    "govt_battery_charge": 97.89996061
  },
  {
    "timestamp": "27/04/24 23:00",
    "demand": 33324.30516,
    "solar_generated": 0,
    "wind_generated": 54.68334591,
    "marketplace_to_demand": 138.3045811,
    "grid_to_demand": 33362.95017,
    "govt_battery_charge": 1.927199075
  },
  {
    "timestamp": "28/04/24 0:00",
    "demand": 34382.83419,
    "solar_generated": 0,
    "wind_generated": 65.17255724,
    "marketplace_to_demand": 80.00998464,
    "grid_to_demand": 29951.08619,
    "govt_battery_charge": 66.80310171
  },
  {
    "timestamp": "28/04/24 1:00",
    "demand": 30774.56027,
    "solar_generated": 0,
    "wind_generated": 65.83755478,
    "marketplace_to_demand": 4.490653785,
    "grid_to_demand": 31895.2587,
    "govt_battery_charge": 80.20719826
  },
  {
    "timestamp": "28/04/24 2:00",
    "demand": 30188.19229,
    "solar_generated": 0,
    "wind_generated": 78.10113237,
    "marketplace_to_demand": 87.45772307,
    "grid_to_demand": 31956.60446,
    "govt_battery_charge": 18.79477711
  },
  {
    "timestamp": "28/04/24 3:00",
    "demand": 32582.23827,
    "solar_generated": 0,
    "wind_generated": 53.60750155,
    "marketplace_to_demand": 119.9975451,
    "grid_to_demand": 30291.08932,
    "govt_battery_charge": 23.466768
  },
  {
    "timestamp": "28/04/24 4:00",
    "demand": 35803.86413,
    "solar_generated": 0,
    "wind_generated": 71.89593885,
    "marketplace_to_demand": 32.63023817,
    "grid_to_demand": 35589.5949,
    "govt_battery_charge": 44.00999917
  },
  {
    "timestamp": "28/04/24 5:00",
    "demand": 31224.08207,
    "solar_generated": 0,
    "wind_generated": 62.51280768,
    "marketplace_to_demand": 180.6237134,
    "grid_to_demand": 33887.0174,
    "govt_battery_charge": 3.177903983
  },
  {
    "timestamp": "28/04/24 6:00",
    "demand": 29985.62724,
    "solar_generated": 6.878482652,
    "wind_generated": 68.72686715,
    "marketplace_to_demand": 91.74125103,
    "grid_to_demand": 35202.51182,
    "govt_battery_charge": 5.337228238
  },
  {
    "timestamp": "28/04/24 7:00",
    "demand": 35633.00503,
    "solar_generated": 16.44418955,
    "wind_generated": 62.56364401,
    "marketplace_to_demand": 57.34137641,
    "grid_to_demand": 36369.4362,
    "govt_battery_charge": 96.46596395
  },
  {
    "timestamp": "28/04/24 8:00",
    "demand": 33325.80438,
    "solar_generated": 16.04926969,
    "wind_generated": 66.35401667,
    "marketplace_to_demand": 124.5415337,
    "grid_to_demand": 31348.07727,
    "govt_battery_charge": 46.21585174
  },
  {
    "timestamp": "28/04/24 9:00",
    "demand": 34598.5767,
    "solar_generated": 6.908255003,
    "wind_generated": 54.41568934,
    "marketplace_to_demand": 147.6805215,
    "grid_to_demand": 34347.86093,
    "govt_battery_charge": 59.15668926
  },
  {
    "timestamp": "28/04/24 10:00",
    "demand": 34280.11375,
    "solar_generated": 16.42537066,
    "wind_generated": 68.49805858,
    "marketplace_to_demand": 109.9130412,
    "grid_to_demand": 32303.13182,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "28/04/24 11:00",
    "demand": 35184.62458,
    "solar_generated": 8.396397012,
    "wind_generated": 56.32449017,
    "marketplace_to_demand": 89.76441634,
    "grid_to_demand": 34071.22032,
    "govt_battery_charge": 14.43550315
  },
  {
    "timestamp": "28/04/24 12:00",
    "demand": 35532.07882,
    "solar_generated": 21.11180204,
    "wind_generated": 78.55865363,
    "marketplace_to_demand": 66.28791458,
    "grid_to_demand": 35749.49409,
    "govt_battery_charge": 35.60933711
  },
  {
    "timestamp": "28/04/24 13:00",
    "demand": 30370.10232,
    "solar_generated": 12.37024781,
    "wind_generated": 70.84017238,
    "marketplace_to_demand": 131.8106262,
    "grid_to_demand": 32004.21216,
    "govt_battery_charge": 73.19784721
  },
  {
    "timestamp": "28/04/24 14:00",
    "demand": 36507.47845,
    "solar_generated": 10.96343743,
    "wind_generated": 56.09238151,
    "marketplace_to_demand": 103.8784193,
    "grid_to_demand": 33873.40001,
    "govt_battery_charge": 14.00149345
  },
  {
    "timestamp": "28/04/24 15:00",
    "demand": 35360.82846,
    "solar_generated": 23.77527942,
    "wind_generated": 60.800709,
    "marketplace_to_demand": 55.1739146,
    "grid_to_demand": 30270.08004,
    "govt_battery_charge": 48.4566181
  },
  {
    "timestamp": "28/04/24 16:00",
    "demand": 34967.16756,
    "solar_generated": 13.54962588,
    "wind_generated": 78.81945963,
    "marketplace_to_demand": 7.546645125,
    "grid_to_demand": 35385.52988,
    "govt_battery_charge": 1.412618653
  },
  {
    "timestamp": "28/04/24 17:00",
    "demand": 35648.68086,
    "solar_generated": 15.09306198,
    "wind_generated": 54.88506752,
    "marketplace_to_demand": 99.66057568,
    "grid_to_demand": 31185.24172,
    "govt_battery_charge": 82.12197917
  },
  {
    "timestamp": "28/04/24 18:00",
    "demand": 35527.49534,
    "solar_generated": 0,
    "wind_generated": 79.89959609,
    "marketplace_to_demand": 174.8472847,
    "grid_to_demand": 35636.6146,
    "govt_battery_charge": 37.88135136
  },
  {
    "timestamp": "28/04/24 19:00",
    "demand": 30134.16497,
    "solar_generated": 0,
    "wind_generated": 77.08355717,
    "marketplace_to_demand": 59.028382,
    "grid_to_demand": 35777.11145,
    "govt_battery_charge": 96.22682628
  },
  {
    "timestamp": "28/04/24 20:00",
    "demand": 32291.83693,
    "solar_generated": 0,
    "wind_generated": 62.10360595,
    "marketplace_to_demand": 145.5052778,
    "grid_to_demand": 32306.75356,
    "govt_battery_charge": 94.02576366
  },
  {
    "timestamp": "28/04/24 21:00",
    "demand": 33127.99921,
    "solar_generated": 0,
    "wind_generated": 68.88047472,
    "marketplace_to_demand": 56.83775513,
    "grid_to_demand": 34926.83748,
    "govt_battery_charge": 41.48068157
  },
  {
    "timestamp": "28/04/24 22:00",
    "demand": 33452.45118,
    "solar_generated": 0,
    "wind_generated": 57.19229038,
    "marketplace_to_demand": 156.5837345,
    "grid_to_demand": 34331.08044,
    "govt_battery_charge": 34.60832062
  },
  {
    "timestamp": "28/04/24 23:00",
    "demand": 30214.29454,
    "solar_generated": 0,
    "wind_generated": 77.64436659,
    "marketplace_to_demand": 184.4678939,
    "grid_to_demand": 36210.55499,
    "govt_battery_charge": 22.8492578
  },
  {
    "timestamp": "29/04/24 0:00",
    "demand": 30208.81815,
    "solar_generated": 0,
    "wind_generated": 76.80706506,
    "marketplace_to_demand": 62.94128004,
    "grid_to_demand": 32955.23653,
    "govt_battery_charge": 28.97113728
  },
  {
    "timestamp": "29/04/24 1:00",
    "demand": 32760.51779,
    "solar_generated": 0,
    "wind_generated": 80.01380944,
    "marketplace_to_demand": 35.11855155,
    "grid_to_demand": 31208.86085,
    "govt_battery_charge": 11.25879799
  },
  {
    "timestamp": "29/04/24 2:00",
    "demand": 32555.20738,
    "solar_generated": 0,
    "wind_generated": 63.01857072,
    "marketplace_to_demand": 21.08915027,
    "grid_to_demand": 30571.19881,
    "govt_battery_charge": 76.70455769
  },
  {
    "timestamp": "29/04/24 3:00",
    "demand": 33579.84185,
    "solar_generated": 0,
    "wind_generated": 76.88243849,
    "marketplace_to_demand": 151.1195032,
    "grid_to_demand": 31531.42783,
    "govt_battery_charge": 85.4555599
  },
  {
    "timestamp": "29/04/24 4:00",
    "demand": 33464.74148,
    "solar_generated": 0,
    "wind_generated": 64.19317377,
    "marketplace_to_demand": 18.5210107,
    "grid_to_demand": 33637.78188,
    "govt_battery_charge": 46.68752263
  },
  {
    "timestamp": "29/04/24 5:00",
    "demand": 33782.84802,
    "solar_generated": 0,
    "wind_generated": 62.38388049,
    "marketplace_to_demand": 147.7263195,
    "grid_to_demand": 35536.26449,
    "govt_battery_charge": 49.55994255
  },
  {
    "timestamp": "29/04/24 6:00",
    "demand": 35953.25769,
    "solar_generated": 23.0964554,
    "wind_generated": 70.69858909,
    "marketplace_to_demand": 61.15155378,
    "grid_to_demand": 31221.81915,
    "govt_battery_charge": 56.5516897
  },
  {
    "timestamp": "29/04/24 7:00",
    "demand": 30978.34895,
    "solar_generated": 13.79884271,
    "wind_generated": 79.75857452,
    "marketplace_to_demand": 12.20156593,
    "grid_to_demand": 31720.26149,
    "govt_battery_charge": 8.65908441
  },
  {
    "timestamp": "29/04/24 8:00",
    "demand": 32007.18557,
    "solar_generated": 16.50114796,
    "wind_generated": 78.4860971,
    "marketplace_to_demand": 173.7599592,
    "grid_to_demand": 31549.71235,
    "govt_battery_charge": 1.678460258
  },
  {
    "timestamp": "29/04/24 9:00",
    "demand": 30427.14077,
    "solar_generated": 11.69518422,
    "wind_generated": 71.93106348,
    "marketplace_to_demand": 26.34475705,
    "grid_to_demand": 32077.28895,
    "govt_battery_charge": 56.75776017
  },
  {
    "timestamp": "29/04/24 10:00",
    "demand": 30145.71625,
    "solar_generated": 13.68447785,
    "wind_generated": 75.55224243,
    "marketplace_to_demand": 116.5696011,
    "grid_to_demand": 35708.72596,
    "govt_battery_charge": 10.09192128
  },
  {
    "timestamp": "29/04/24 11:00",
    "demand": 33277.08902,
    "solar_generated": 16.063621,
    "wind_generated": 63.75818781,
    "marketplace_to_demand": 76.63904325,
    "grid_to_demand": 33633.48413,
    "govt_battery_charge": 60.39624823
  },
  {
    "timestamp": "29/04/24 12:00",
    "demand": 30421.87545,
    "solar_generated": 20.35298162,
    "wind_generated": 70.85603587,
    "marketplace_to_demand": 84.74990525,
    "grid_to_demand": 35900.9675,
    "govt_battery_charge": 5.976988492
  },
  {
    "timestamp": "29/04/24 13:00",
    "demand": 31471.05736,
    "solar_generated": 16.5441617,
    "wind_generated": 60.18762411,
    "marketplace_to_demand": 133.0321766,
    "grid_to_demand": 31583.13325,
    "govt_battery_charge": 96.08199281
  },
  {
    "timestamp": "29/04/24 14:00",
    "demand": 30292.52735,
    "solar_generated": 20.86490387,
    "wind_generated": 68.40446644,
    "marketplace_to_demand": 96.37357157,
    "grid_to_demand": 30268.31213,
    "govt_battery_charge": 52.32664867
  },
  {
    "timestamp": "29/04/24 15:00",
    "demand": 32646.75812,
    "solar_generated": 10.99370486,
    "wind_generated": 72.8323062,
    "marketplace_to_demand": 14.7600685,
    "grid_to_demand": 30069.28337,
    "govt_battery_charge": 41.73856978
  },
  {
    "timestamp": "29/04/24 16:00",
    "demand": 30967.99133,
    "solar_generated": 7.101944512,
    "wind_generated": 69.35699101,
    "marketplace_to_demand": 174.9295478,
    "grid_to_demand": 30284.81958,
    "govt_battery_charge": 16.19747372
  },
  {
    "timestamp": "29/04/24 17:00",
    "demand": 34115.13454,
    "solar_generated": 7.470813146,
    "wind_generated": 67.22814792,
    "marketplace_to_demand": 26.31589225,
    "grid_to_demand": 33191.71809,
    "govt_battery_charge": 75.99980872
  },
  {
    "timestamp": "29/04/24 18:00",
    "demand": 34396.12805,
    "solar_generated": 0,
    "wind_generated": 74.99909804,
    "marketplace_to_demand": 170.4624344,
    "grid_to_demand": 35698.9787,
    "govt_battery_charge": 1.191559201
  },
  {
    "timestamp": "29/04/24 19:00",
    "demand": 35288.53008,
    "solar_generated": 0,
    "wind_generated": 61.45986677,
    "marketplace_to_demand": 135.7787311,
    "grid_to_demand": 36549.89768,
    "govt_battery_charge": 11.14620708
  },
  {
    "timestamp": "29/04/24 20:00",
    "demand": 32921.51692,
    "solar_generated": 0,
    "wind_generated": 61.48151944,
    "marketplace_to_demand": 188.277594,
    "grid_to_demand": 31441.11665,
    "govt_battery_charge": 85.38849301
  },
  {
    "timestamp": "29/04/24 21:00",
    "demand": 30213.142,
    "solar_generated": 0,
    "wind_generated": 63.94741533,
    "marketplace_to_demand": 196.2213077,
    "grid_to_demand": 31104.29515,
    "govt_battery_charge": 51.15024966
  },
  {
    "timestamp": "29/04/24 22:00",
    "demand": 35712.25387,
    "solar_generated": 0,
    "wind_generated": 58.57611814,
    "marketplace_to_demand": 18.90092943,
    "grid_to_demand": 29906.4606,
    "govt_battery_charge": 45.81123187
  },
  {
    "timestamp": "29/04/24 23:00",
    "demand": 34717.10931,
    "solar_generated": 0,
    "wind_generated": 54.87907116,
    "marketplace_to_demand": 38.65561496,
    "grid_to_demand": 30279.41412,
    "govt_battery_charge": 53.45569602
  },
  {
    "timestamp": "30/04/24 0:00",
    "demand": 30041.37959,
    "solar_generated": 0,
    "wind_generated": 72.96156056,
    "marketplace_to_demand": 86.37518121,
    "grid_to_demand": 31919.12362,
    "govt_battery_charge": 14.410674
  },
  {
    "timestamp": "30/04/24 1:00",
    "demand": 35559.32853,
    "solar_generated": 0,
    "wind_generated": 59.10922233,
    "marketplace_to_demand": 48.51049987,
    "grid_to_demand": 35739.11602,
    "govt_battery_charge": 44.39875576
  },
  {
    "timestamp": "30/04/24 2:00",
    "demand": 34215.57233,
    "solar_generated": 0,
    "wind_generated": 69.00005192,
    "marketplace_to_demand": 168.9458669,
    "grid_to_demand": 33270.39113,
    "govt_battery_charge": 83.95771994
  },
  {
    "timestamp": "30/04/24 3:00",
    "demand": 34841.43775,
    "solar_generated": 0,
    "wind_generated": 58.85143437,
    "marketplace_to_demand": 101.5124951,
    "grid_to_demand": 33204.80743,
    "govt_battery_charge": 83.3929895
  },
  {
    "timestamp": "30/04/24 4:00",
    "demand": 31638.8923,
    "solar_generated": 0,
    "wind_generated": 55.82004003,
    "marketplace_to_demand": 99.2811051,
    "grid_to_demand": 34732.15467,
    "govt_battery_charge": 64.80790402
  },
  {
    "timestamp": "30/04/24 5:00",
    "demand": 32447.2928,
    "solar_generated": 0,
    "wind_generated": 71.30181435,
    "marketplace_to_demand": 106.9663812,
    "grid_to_demand": 34143.36366,
    "govt_battery_charge": 81.22326481
  },
  {
    "timestamp": "30/04/24 6:00",
    "demand": 33812.31987,
    "solar_generated": 15.89925343,
    "wind_generated": 65.45042534,
    "marketplace_to_demand": 4.830627197,
    "grid_to_demand": 32825.54577,
    "govt_battery_charge": 64.75152306
  },
  {
    "timestamp": "30/04/24 7:00",
    "demand": 30858.01758,
    "solar_generated": 6.788746135,
    "wind_generated": 74.6817555,
    "marketplace_to_demand": 194.4160987,
    "grid_to_demand": 35072.65723,
    "govt_battery_charge": 32.84506816
  },
  {
    "timestamp": "30/04/24 8:00",
    "demand": 34584.35372,
    "solar_generated": 6.850702812,
    "wind_generated": 54.12795315,
    "marketplace_to_demand": 178.2907337,
    "grid_to_demand": 34903.2465,
    "govt_battery_charge": 46.67824136
  },
  {
    "timestamp": "30/04/24 9:00",
    "demand": 33028.68464,
    "solar_generated": 13.45283528,
    "wind_generated": 59.75533091,
    "marketplace_to_demand": 164.5179999,
    "grid_to_demand": 29961.7274,
    "govt_battery_charge": 43.14635023
  },
  {
    "timestamp": "30/04/24 10:00",
    "demand": 31002.61098,
    "solar_generated": 17.26308404,
    "wind_generated": 70.37366627,
    "marketplace_to_demand": 41.66317608,
    "grid_to_demand": 34803.81952,
    "govt_battery_charge": 87.7954995
  },
  {
    "timestamp": "30/04/24 11:00",
    "demand": 31463.16783,
    "solar_generated": 10.74745951,
    "wind_generated": 61.24828597,
    "marketplace_to_demand": 165.5362486,
    "grid_to_demand": 32596.2807,
    "govt_battery_charge": 86.38750525
  },
  {
    "timestamp": "30/04/24 12:00",
    "demand": 34958.31634,
    "solar_generated": 15.98398694,
    "wind_generated": 73.04477269,
    "marketplace_to_demand": 174.4822167,
    "grid_to_demand": 34317.15353,
    "govt_battery_charge": 73.7576319
  },
  {
    "timestamp": "30/04/24 13:00",
    "demand": 35601.41438,
    "solar_generated": 22.52136927,
    "wind_generated": 60.82327363,
    "marketplace_to_demand": 175.3120631,
    "grid_to_demand": 32554.6612,
    "govt_battery_charge": 6.855071493
  },
  {
    "timestamp": "30/04/24 14:00",
    "demand": 32028.228,
    "solar_generated": 7.43908379,
    "wind_generated": 68.68935289,
    "marketplace_to_demand": 114.2219597,
    "grid_to_demand": 33978.16241,
    "govt_battery_charge": 30.06242532
  },
  {
    "timestamp": "30/04/24 15:00",
    "demand": 32938.382,
    "solar_generated": 12.64637253,
    "wind_generated": 66.73010664,
    "marketplace_to_demand": 174.2462267,
    "grid_to_demand": 35555.08916,
    "govt_battery_charge": 54.21689698
  },
  {
    "timestamp": "30/04/24 16:00",
    "demand": 34570.04008,
    "solar_generated": 21.15116607,
    "wind_generated": 76.44910931,
    "marketplace_to_demand": 61.13867704,
    "grid_to_demand": 30772.50825,
    "govt_battery_charge": 45.92229328
  },
  {
    "timestamp": "30/04/24 17:00",
    "demand": 35469.60712,
    "solar_generated": 17.55281253,
    "wind_generated": 70.7700633,
    "marketplace_to_demand": 88.82480997,
    "grid_to_demand": 34037.12848,
    "govt_battery_charge": 48.30570347
  },
  {
    "timestamp": "30/04/24 18:00",
    "demand": 32835.99718,
    "solar_generated": 0,
    "wind_generated": 64.09672675,
    "marketplace_to_demand": 102.0313012,
    "grid_to_demand": 34152.73905,
    "govt_battery_charge": 84.36135713
  },
  {
    "timestamp": "30/04/24 19:00",
    "demand": 35668.03991,
    "solar_generated": 0,
    "wind_generated": 68.41168879,
    "marketplace_to_demand": 14.99120611,
    "grid_to_demand": 29990.40984,
    "govt_battery_charge": 30.81765544
  },
  {
    "timestamp": "30/04/24 20:00",
    "demand": 30573.58552,
    "solar_generated": 0,
    "wind_generated": 79.42926009,
    "marketplace_to_demand": 141.9583161,
    "grid_to_demand": 35114.22886,
    "govt_battery_charge": 49.98490199
  },
  {
    "timestamp": "30/04/24 21:00",
    "demand": 31939.39562,
    "solar_generated": 0,
    "wind_generated": 63.58815091,
    "marketplace_to_demand": 171.7747314,
    "grid_to_demand": 29913.90922,
    "govt_battery_charge": 81.07634509
  },
  {
    "timestamp": "30/04/24 22:00",
    "demand": 33469.82641,
    "solar_generated": 0,
    "wind_generated": 57.47899441,
    "marketplace_to_demand": 198.6323642,
    "grid_to_demand": 31293.84607,
    "govt_battery_charge": 12.88852495
  },
  {
    "timestamp": "30/04/24 23:00",
    "demand": 36245.61101,
    "solar_generated": 0,
    "wind_generated": 68.42288714,
    "marketplace_to_demand": 79.72048086,
    "grid_to_demand": 36273.93218,
    "govt_battery_charge": 36.58904331
  },
  {
    "timestamp": "01/05/24 0:00",
    "demand": 33740.06892,
    "solar_generated": 0,
    "wind_generated": 57.84185266,
    "marketplace_to_demand": 47.08979079,
    "grid_to_demand": 30073.84709,
    "govt_battery_charge": 20.17719661
  },
  {
    "timestamp": "01/05/24 1:00",
    "demand": 30022.22507,
    "solar_generated": 0,
    "wind_generated": 66.01621649,
    "marketplace_to_demand": 44.05194264,
    "grid_to_demand": 34855.55794,
    "govt_battery_charge": 61.20093652
  },
  {
    "timestamp": "01/05/24 2:00",
    "demand": 31619.19562,
    "solar_generated": 0,
    "wind_generated": 66.36404535,
    "marketplace_to_demand": 47.409471,
    "grid_to_demand": 35695.43955,
    "govt_battery_charge": 11.41698722
  },
  {
    "timestamp": "01/05/24 3:00",
    "demand": 31344.49367,
    "solar_generated": 0,
    "wind_generated": 74.35718847,
    "marketplace_to_demand": 167.2020909,
    "grid_to_demand": 34935.24082,
    "govt_battery_charge": 32.2081314
  },
  {
    "timestamp": "01/05/24 4:00",
    "demand": 31498.56274,
    "solar_generated": 0,
    "wind_generated": 55.55331555,
    "marketplace_to_demand": 41.06214879,
    "grid_to_demand": 33797.23219,
    "govt_battery_charge": 86.16286027
  },
  {
    "timestamp": "01/05/24 5:00",
    "demand": 34794.45682,
    "solar_generated": 0,
    "wind_generated": 77.52502591,
    "marketplace_to_demand": 147.9490536,
    "grid_to_demand": 30193.61741,
    "govt_battery_charge": 12.93373675
  },
  {
    "timestamp": "01/05/24 6:00",
    "demand": 30685.15297,
    "solar_generated": 23.96413253,
    "wind_generated": 60.46035536,
    "marketplace_to_demand": 12.3231589,
    "grid_to_demand": 35281.2688,
    "govt_battery_charge": 53.48335031
  },
  {
    "timestamp": "01/05/24 7:00",
    "demand": 34714.66998,
    "solar_generated": 20.80178281,
    "wind_generated": 70.18380155,
    "marketplace_to_demand": 117.7812763,
    "grid_to_demand": 34162.6421,
    "govt_battery_charge": 80.57710709
  },
  {
    "timestamp": "01/05/24 8:00",
    "demand": 35360.30724,
    "solar_generated": 21.62613086,
    "wind_generated": 79.1388187,
    "marketplace_to_demand": 183.594375,
    "grid_to_demand": 32375.00505,
    "govt_battery_charge": 87.9639074
  },
  {
    "timestamp": "01/05/24 9:00",
    "demand": 32324.12607,
    "solar_generated": 11.59161558,
    "wind_generated": 58.48921162,
    "marketplace_to_demand": 123.8109289,
    "grid_to_demand": 31851.98565,
    "govt_battery_charge": 29.40722834
  },
  {
    "timestamp": "01/05/24 10:00",
    "demand": 30578.22788,
    "solar_generated": 18.37007669,
    "wind_generated": 75.59647973,
    "marketplace_to_demand": 8.552210702,
    "grid_to_demand": 31895.12295,
    "govt_battery_charge": 22.53329393
  },
  {
    "timestamp": "01/05/24 11:00",
    "demand": 34992.5677,
    "solar_generated": 13.08485597,
    "wind_generated": 79.87212021,
    "marketplace_to_demand": 185.4843062,
    "grid_to_demand": 30387.57251,
    "govt_battery_charge": 48.83529085
  },
  {
    "timestamp": "01/05/24 12:00",
    "demand": 32370.87703,
    "solar_generated": 21.4086032,
    "wind_generated": 66.56439032,
    "marketplace_to_demand": 12.42147486,
    "grid_to_demand": 34763.03143,
    "govt_battery_charge": 85.35032244
  },
  {
    "timestamp": "01/05/24 13:00",
    "demand": 30051.73033,
    "solar_generated": 16.57830912,
    "wind_generated": 74.51585171,
    "marketplace_to_demand": 181.129238,
    "grid_to_demand": 33618.94709,
    "govt_battery_charge": 29.27705681
  },
  {
    "timestamp": "01/05/24 14:00",
    "demand": 34781.95677,
    "solar_generated": 16.97029223,
    "wind_generated": 64.29112064,
    "marketplace_to_demand": 135.2572616,
    "grid_to_demand": 31388.55726,
    "govt_battery_charge": 78.54599054
  },
  {
    "timestamp": "01/05/24 15:00",
    "demand": 33238.82225,
    "solar_generated": 18.51383317,
    "wind_generated": 56.90832309,
    "marketplace_to_demand": 157.2209123,
    "grid_to_demand": 36223.78286,
    "govt_battery_charge": 96.78284045
  },
  {
    "timestamp": "01/05/24 16:00",
    "demand": 35102.74246,
    "solar_generated": 12.98232599,
    "wind_generated": 58.59139805,
    "marketplace_to_demand": 30.76066296,
    "grid_to_demand": 32319.6952,
    "govt_battery_charge": 53.04729249
  },
  {
    "timestamp": "01/05/24 17:00",
    "demand": 30076.06317,
    "solar_generated": 22.55474331,
    "wind_generated": 73.74147185,
    "marketplace_to_demand": 116.4524219,
    "grid_to_demand": 30751.45817,
    "govt_battery_charge": 36.56784657
  },
  {
    "timestamp": "01/05/24 18:00",
    "demand": 33143.47902,
    "solar_generated": 0,
    "wind_generated": 71.5228787,
    "marketplace_to_demand": 41.42628163,
    "grid_to_demand": 35516.27194,
    "govt_battery_charge": 57.6735196
  },
  {
    "timestamp": "01/05/24 19:00",
    "demand": 30910.48487,
    "solar_generated": 0,
    "wind_generated": 66.75780212,
    "marketplace_to_demand": 176.4522449,
    "grid_to_demand": 33546.48676,
    "govt_battery_charge": 39.78988908
  },
  {
    "timestamp": "01/05/24 20:00",
    "demand": 32600.54033,
    "solar_generated": 0,
    "wind_generated": 56.30107427,
    "marketplace_to_demand": 165.6602281,
    "grid_to_demand": 32977.36632,
    "govt_battery_charge": 6.639869372
  },
  {
    "timestamp": "01/05/24 21:00",
    "demand": 35636.25515,
    "solar_generated": 0,
    "wind_generated": 61.9245585,
    "marketplace_to_demand": 74.64613535,
    "grid_to_demand": 32382.92675,
    "govt_battery_charge": 69.02947504
  },
  {
    "timestamp": "01/05/24 22:00",
    "demand": 31500.19642,
    "solar_generated": 0,
    "wind_generated": 71.06526645,
    "marketplace_to_demand": 57.97128464,
    "grid_to_demand": 34330.05282,
    "govt_battery_charge": 59.03560195
  },
  {
    "timestamp": "01/05/24 23:00",
    "demand": 31026.03566,
    "solar_generated": 0,
    "wind_generated": 76.34371306,
    "marketplace_to_demand": 113.1603082,
    "grid_to_demand": 32295.87056,
    "govt_battery_charge": 10.90443968
  },
  {
    "timestamp": "02/05/24 0:00",
    "demand": 35906.3101,
    "solar_generated": 0,
    "wind_generated": 53.7800916,
    "marketplace_to_demand": 71.88373566,
    "grid_to_demand": 33160.45265,
    "govt_battery_charge": 66.14856203
  },
  {
    "timestamp": "02/05/24 1:00",
    "demand": 35229.28662,
    "solar_generated": 0,
    "wind_generated": 63.13035673,
    "marketplace_to_demand": 50.63820384,
    "grid_to_demand": 35834.04132,
    "govt_battery_charge": 93.98064146
  },
  {
    "timestamp": "02/05/24 2:00",
    "demand": 32277.48752,
    "solar_generated": 0,
    "wind_generated": 57.77807861,
    "marketplace_to_demand": 110.0456629,
    "grid_to_demand": 36160.12801,
    "govt_battery_charge": 57.47412121
  },
  {
    "timestamp": "02/05/24 3:00",
    "demand": 31659.517,
    "solar_generated": 0,
    "wind_generated": 65.10643887,
    "marketplace_to_demand": 46.24974723,
    "grid_to_demand": 30315.88409,
    "govt_battery_charge": 40.12113587
  },
  {
    "timestamp": "02/05/24 4:00",
    "demand": 30092.2836,
    "solar_generated": 0,
    "wind_generated": 66.3153932,
    "marketplace_to_demand": 131.0566782,
    "grid_to_demand": 31538.53257,
    "govt_battery_charge": 18.69428988
  },
  {
    "timestamp": "02/05/24 5:00",
    "demand": 34615.97459,
    "solar_generated": 0,
    "wind_generated": 61.73472413,
    "marketplace_to_demand": 11.02398822,
    "grid_to_demand": 35822.94061,
    "govt_battery_charge": 75.2649403
  },
  {
    "timestamp": "02/05/24 6:00",
    "demand": 33586.04428,
    "solar_generated": 22.43674478,
    "wind_generated": 55.05379385,
    "marketplace_to_demand": 73.85291752,
    "grid_to_demand": 31111.17998,
    "govt_battery_charge": 83.69600629
  },
  {
    "timestamp": "02/05/24 7:00",
    "demand": 31565.778,
    "solar_generated": 10.06005901,
    "wind_generated": 69.56178839,
    "marketplace_to_demand": 93.02361506,
    "grid_to_demand": 32377.46187,
    "govt_battery_charge": 63.08251526
  },
  {
    "timestamp": "02/05/24 8:00",
    "demand": 33852.92401,
    "solar_generated": 22.40280744,
    "wind_generated": 79.83530186,
    "marketplace_to_demand": 159.0848593,
    "grid_to_demand": 33323.59269,
    "govt_battery_charge": 38.82692879
  },
  {
    "timestamp": "02/05/24 9:00",
    "demand": 31030.93408,
    "solar_generated": 20.01446481,
    "wind_generated": 65.11855048,
    "marketplace_to_demand": 12.23370184,
    "grid_to_demand": 34201.50064,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "02/05/24 10:00",
    "demand": 30865.06959,
    "solar_generated": 21.65048671,
    "wind_generated": 72.7747915,
    "marketplace_to_demand": 58.82927326,
    "grid_to_demand": 31143.49787,
    "govt_battery_charge": 36.13382293
  },
  {
    "timestamp": "02/05/24 11:00",
    "demand": 32431.4727,
    "solar_generated": 18.93895316,
    "wind_generated": 76.86302817,
    "marketplace_to_demand": 183.2748396,
    "grid_to_demand": 30687.94167,
    "govt_battery_charge": 44.90543829
  },
  {
    "timestamp": "02/05/24 12:00",
    "demand": 35466.84286,
    "solar_generated": 20.41455134,
    "wind_generated": 56.64264452,
    "marketplace_to_demand": 155.8703616,
    "grid_to_demand": 33205.89021,
    "govt_battery_charge": 11.46243073
  },
  {
    "timestamp": "02/05/24 13:00",
    "demand": 32702.47551,
    "solar_generated": 13.02565043,
    "wind_generated": 69.64798411,
    "marketplace_to_demand": 176.6209737,
    "grid_to_demand": 30855.22143,
    "govt_battery_charge": 25.12125233
  },
  {
    "timestamp": "02/05/24 14:00",
    "demand": 30148.82626,
    "solar_generated": 11.34467262,
    "wind_generated": 62.76894012,
    "marketplace_to_demand": 113.5057756,
    "grid_to_demand": 34570.47456,
    "govt_battery_charge": 48.97531231
  },
  {
    "timestamp": "02/05/24 15:00",
    "demand": 30459.72001,
    "solar_generated": 12.72092556,
    "wind_generated": 68.55045136,
    "marketplace_to_demand": 110.6829808,
    "grid_to_demand": 30325.10651,
    "govt_battery_charge": 45.01526496
  },
  {
    "timestamp": "02/05/24 16:00",
    "demand": 34278.96933,
    "solar_generated": 22.48222954,
    "wind_generated": 61.11097228,
    "marketplace_to_demand": 27.21901752,
    "grid_to_demand": 33553.11664,
    "govt_battery_charge": 65.27115667
  },
  {
    "timestamp": "02/05/24 17:00",
    "demand": 36123.84753,
    "solar_generated": 8.015661255,
    "wind_generated": 72.93744755,
    "marketplace_to_demand": 168.4859906,
    "grid_to_demand": 31317.97754,
    "govt_battery_charge": 77.99370539
  },
  {
    "timestamp": "02/05/24 18:00",
    "demand": 35464.85839,
    "solar_generated": 0,
    "wind_generated": 78.73565268,
    "marketplace_to_demand": 134.5438578,
    "grid_to_demand": 30393.82712,
    "govt_battery_charge": 51.71670189
  },
  {
    "timestamp": "02/05/24 19:00",
    "demand": 31637.32893,
    "solar_generated": 0,
    "wind_generated": 59.2093488,
    "marketplace_to_demand": 19.80187431,
    "grid_to_demand": 34822.22546,
    "govt_battery_charge": 93.26241435
  },
  {
    "timestamp": "02/05/24 20:00",
    "demand": 31490.11343,
    "solar_generated": 0,
    "wind_generated": 59.93272204,
    "marketplace_to_demand": 36.55439243,
    "grid_to_demand": 31297.50726,
    "govt_battery_charge": 81.32997427
  },
  {
    "timestamp": "02/05/24 21:00",
    "demand": 34790.07279,
    "solar_generated": 0,
    "wind_generated": 71.49912008,
    "marketplace_to_demand": 31.39228591,
    "grid_to_demand": 35768.53896,
    "govt_battery_charge": 37.75218604
  },
  {
    "timestamp": "02/05/24 22:00",
    "demand": 32188.29276,
    "solar_generated": 0,
    "wind_generated": 71.05673296,
    "marketplace_to_demand": 29.85729736,
    "grid_to_demand": 30908.74145,
    "govt_battery_charge": 24.49714894
  },
  {
    "timestamp": "02/05/24 23:00",
    "demand": 32188.03879,
    "solar_generated": 0,
    "wind_generated": 63.55162019,
    "marketplace_to_demand": 0.780029117,
    "grid_to_demand": 34733.49526,
    "govt_battery_charge": 78.20601569
  },
  {
    "timestamp": "03/05/24 0:00",
    "demand": 36082.90523,
    "solar_generated": 0,
    "wind_generated": 60.51230282,
    "marketplace_to_demand": 66.17232883,
    "grid_to_demand": 35166.58267,
    "govt_battery_charge": 42.60632281
  },
  {
    "timestamp": "03/05/24 1:00",
    "demand": 35020.39267,
    "solar_generated": 0,
    "wind_generated": 55.53694407,
    "marketplace_to_demand": 110.1811482,
    "grid_to_demand": 34380.28003,
    "govt_battery_charge": 46.79193994
  },
  {
    "timestamp": "03/05/24 2:00",
    "demand": 34453.08455,
    "solar_generated": 0,
    "wind_generated": 64.50707014,
    "marketplace_to_demand": 36.91535892,
    "grid_to_demand": 32919.77266,
    "govt_battery_charge": 47.39466177
  },
  {
    "timestamp": "03/05/24 3:00",
    "demand": 30273.18269,
    "solar_generated": 0,
    "wind_generated": 68.51664925,
    "marketplace_to_demand": 81.18050098,
    "grid_to_demand": 30434.08408,
    "govt_battery_charge": 94.20197455
  },
  {
    "timestamp": "03/05/24 4:00",
    "demand": 30737.10974,
    "solar_generated": 0,
    "wind_generated": 58.413434,
    "marketplace_to_demand": 13.89272946,
    "grid_to_demand": 32515.43253,
    "govt_battery_charge": 60.50070051
  },
  {
    "timestamp": "03/05/24 5:00",
    "demand": 34629.76776,
    "solar_generated": 0,
    "wind_generated": 55.34140128,
    "marketplace_to_demand": 151.0585223,
    "grid_to_demand": 34296.24631,
    "govt_battery_charge": 33.73680601
  },
  {
    "timestamp": "03/05/24 6:00",
    "demand": 34446.2243,
    "solar_generated": 17.48110165,
    "wind_generated": 57.00291299,
    "marketplace_to_demand": 184.462959,
    "grid_to_demand": 30860.5614,
    "govt_battery_charge": 21.08145505
  },
  {
    "timestamp": "03/05/24 7:00",
    "demand": 36540.82174,
    "solar_generated": 11.1358685,
    "wind_generated": 78.0729145,
    "marketplace_to_demand": 23.63233448,
    "grid_to_demand": 36423.6828,
    "govt_battery_charge": 22.8611135
  },
  {
    "timestamp": "03/05/24 8:00",
    "demand": 30647.62892,
    "solar_generated": 17.91309672,
    "wind_generated": 77.56216726,
    "marketplace_to_demand": 153.1371357,
    "grid_to_demand": 30547.93547,
    "govt_battery_charge": 72.00257507
  },
  {
    "timestamp": "03/05/24 9:00",
    "demand": 31676.39896,
    "solar_generated": 14.18515784,
    "wind_generated": 73.79210355,
    "marketplace_to_demand": 62.52195347,
    "grid_to_demand": 34215.51212,
    "govt_battery_charge": 59.62011894
  },
  {
    "timestamp": "03/05/24 10:00",
    "demand": 33451.75955,
    "solar_generated": 11.77064926,
    "wind_generated": 67.43740513,
    "marketplace_to_demand": 140.5788107,
    "grid_to_demand": 33088.94389,
    "govt_battery_charge": 80.79510324
  },
  {
    "timestamp": "03/05/24 11:00",
    "demand": 33068.25969,
    "solar_generated": 10.5480276,
    "wind_generated": 59.04529434,
    "marketplace_to_demand": 13.67743144,
    "grid_to_demand": 31548.38995,
    "govt_battery_charge": 38.78864749
  },
  {
    "timestamp": "03/05/24 12:00",
    "demand": 29970.96915,
    "solar_generated": 6.025744611,
    "wind_generated": 62.45729454,
    "marketplace_to_demand": 111.1816442,
    "grid_to_demand": 31727.48971,
    "govt_battery_charge": 62.43638971
  },
  {
    "timestamp": "03/05/24 13:00",
    "demand": 30290.37999,
    "solar_generated": 20.48863415,
    "wind_generated": 54.85435278,
    "marketplace_to_demand": 92.81791495,
    "grid_to_demand": 33365.92138,
    "govt_battery_charge": 80.10397096
  },
  {
    "timestamp": "03/05/24 14:00",
    "demand": 35213.07318,
    "solar_generated": 17.13033077,
    "wind_generated": 73.93210295,
    "marketplace_to_demand": 128.5365412,
    "grid_to_demand": 31847.47881,
    "govt_battery_charge": 54.9367002
  },
  {
    "timestamp": "03/05/24 15:00",
    "demand": 33912.52126,
    "solar_generated": 9.704997357,
    "wind_generated": 60.88136355,
    "marketplace_to_demand": 66.11126182,
    "grid_to_demand": 34056.11448,
    "govt_battery_charge": 43.04560713
  },
  {
    "timestamp": "03/05/24 16:00",
    "demand": 31175.15218,
    "solar_generated": 23.70363763,
    "wind_generated": 77.31521344,
    "marketplace_to_demand": 40.83845329,
    "grid_to_demand": 35252.34719,
    "govt_battery_charge": 58.18952059
  },
  {
    "timestamp": "03/05/24 17:00",
    "demand": 30954.62937,
    "solar_generated": 20.52982016,
    "wind_generated": 69.1875834,
    "marketplace_to_demand": 144.1922115,
    "grid_to_demand": 32945.48968,
    "govt_battery_charge": 3.540118866
  },
  {
    "timestamp": "03/05/24 18:00",
    "demand": 33653.80036,
    "solar_generated": 0,
    "wind_generated": 54.70628804,
    "marketplace_to_demand": 192.980864,
    "grid_to_demand": 34144.39569,
    "govt_battery_charge": 80.57375534
  },
  {
    "timestamp": "03/05/24 19:00",
    "demand": 34370.43401,
    "solar_generated": 0,
    "wind_generated": 59.00487437,
    "marketplace_to_demand": 154.646409,
    "grid_to_demand": 31936.0554,
    "govt_battery_charge": 84.36554117
  },
  {
    "timestamp": "03/05/24 20:00",
    "demand": 31432.45726,
    "solar_generated": 0,
    "wind_generated": 64.35410615,
    "marketplace_to_demand": 195.5039686,
    "grid_to_demand": 30090.05328,
    "govt_battery_charge": 95.6821609
  },
  {
    "timestamp": "03/05/24 21:00",
    "demand": 30945.13921,
    "solar_generated": 0,
    "wind_generated": 77.56481534,
    "marketplace_to_demand": 96.61121107,
    "grid_to_demand": 33017.88474,
    "govt_battery_charge": 36.8455592
  },
  {
    "timestamp": "03/05/24 22:00",
    "demand": 30875.07734,
    "solar_generated": 0,
    "wind_generated": 78.41854521,
    "marketplace_to_demand": 107.222787,
    "grid_to_demand": 34026.32532,
    "govt_battery_charge": 83.36342688
  },
  {
    "timestamp": "03/05/24 23:00",
    "demand": 35232.11667,
    "solar_generated": 0,
    "wind_generated": 56.87500292,
    "marketplace_to_demand": 148.4392048,
    "grid_to_demand": 32977.62328,
    "govt_battery_charge": 8.745068486
  },
  {
    "timestamp": "04/05/24 0:00",
    "demand": 33036.07393,
    "solar_generated": 0,
    "wind_generated": 62.54003938,
    "marketplace_to_demand": 98.44303445,
    "grid_to_demand": 36159.27647,
    "govt_battery_charge": 73.87107213
  },
  {
    "timestamp": "04/05/24 1:00",
    "demand": 33443.58052,
    "solar_generated": 0,
    "wind_generated": 57.20652586,
    "marketplace_to_demand": 115.0027941,
    "grid_to_demand": 32953.1822,
    "govt_battery_charge": 85.87636809
  },
  {
    "timestamp": "04/05/24 2:00",
    "demand": 33622.70752,
    "solar_generated": 0,
    "wind_generated": 53.8449301,
    "marketplace_to_demand": 137.9587404,
    "grid_to_demand": 31387.06544,
    "govt_battery_charge": 92.78641088
  },
  {
    "timestamp": "04/05/24 3:00",
    "demand": 31821.62598,
    "solar_generated": 0,
    "wind_generated": 68.95437486,
    "marketplace_to_demand": 198.4186465,
    "grid_to_demand": 35595.1181,
    "govt_battery_charge": 82.99553068
  },
  {
    "timestamp": "04/05/24 4:00",
    "demand": 35478.05516,
    "solar_generated": 0,
    "wind_generated": 70.46409612,
    "marketplace_to_demand": 183.1737106,
    "grid_to_demand": 34951.50428,
    "govt_battery_charge": 11.33715627
  },
  {
    "timestamp": "04/05/24 5:00",
    "demand": 30451.76612,
    "solar_generated": 0,
    "wind_generated": 58.8189382,
    "marketplace_to_demand": 27.61684773,
    "grid_to_demand": 31500.51546,
    "govt_battery_charge": 6.494052679
  },
  {
    "timestamp": "04/05/24 6:00",
    "demand": 35923.71228,
    "solar_generated": 14.77761608,
    "wind_generated": 62.39442361,
    "marketplace_to_demand": 105.2934576,
    "grid_to_demand": 31781.7437,
    "govt_battery_charge": 93.98586955
  },
  {
    "timestamp": "04/05/24 7:00",
    "demand": 31080.77238,
    "solar_generated": 13.54018352,
    "wind_generated": 75.93638203,
    "marketplace_to_demand": 115.3227006,
    "grid_to_demand": 33958.40487,
    "govt_battery_charge": 75.79212483
  },
  {
    "timestamp": "04/05/24 8:00",
    "demand": 33078.32225,
    "solar_generated": 11.61603844,
    "wind_generated": 68.46405079,
    "marketplace_to_demand": 10.99264157,
    "grid_to_demand": 30720.04917,
    "govt_battery_charge": 62.11399696
  },
  {
    "timestamp": "04/05/24 9:00",
    "demand": 31375.23628,
    "solar_generated": 8.655388086,
    "wind_generated": 67.0811847,
    "marketplace_to_demand": 157.2744444,
    "grid_to_demand": 35213.76316,
    "govt_battery_charge": 18.14617275
  },
  {
    "timestamp": "04/05/24 10:00",
    "demand": 36258.60227,
    "solar_generated": 7.338938853,
    "wind_generated": 54.0722452,
    "marketplace_to_demand": 100.3102223,
    "grid_to_demand": 36198.68396,
    "govt_battery_charge": 74.87772198
  },
  {
    "timestamp": "04/05/24 11:00",
    "demand": 30107.89305,
    "solar_generated": 7.623932989,
    "wind_generated": 61.4047256,
    "marketplace_to_demand": 83.8243193,
    "grid_to_demand": 36121.07518,
    "govt_battery_charge": 22.08823486
  },
  {
    "timestamp": "04/05/24 12:00",
    "demand": 31653.0498,
    "solar_generated": 19.88204596,
    "wind_generated": 79.59095841,
    "marketplace_to_demand": 36.31213855,
    "grid_to_demand": 36337.71907,
    "govt_battery_charge": 29.39952066
  },
  {
    "timestamp": "04/05/24 13:00",
    "demand": 32924.79343,
    "solar_generated": 16.97081437,
    "wind_generated": 66.84333473,
    "marketplace_to_demand": 136.1247875,
    "grid_to_demand": 34693.05307,
    "govt_battery_charge": 98.78062243
  },
  {
    "timestamp": "04/05/24 14:00",
    "demand": 32847.99674,
    "solar_generated": 13.4962516,
    "wind_generated": 63.27352143,
    "marketplace_to_demand": 75.4481808,
    "grid_to_demand": 30126.08227,
    "govt_battery_charge": 43.64924671
  },
  {
    "timestamp": "04/05/24 15:00",
    "demand": 30420.24111,
    "solar_generated": 12.00524465,
    "wind_generated": 60.19890453,
    "marketplace_to_demand": 27.99792376,
    "grid_to_demand": 30766.60403,
    "govt_battery_charge": 64.7785589
  },
  {
    "timestamp": "04/05/24 16:00",
    "demand": 36368.17537,
    "solar_generated": 12.44735011,
    "wind_generated": 55.40379934,
    "marketplace_to_demand": 71.36485029,
    "grid_to_demand": 30876.64254,
    "govt_battery_charge": 23.27862408
  },
  {
    "timestamp": "04/05/24 17:00",
    "demand": 34555.47508,
    "solar_generated": 11.29455229,
    "wind_generated": 60.93474436,
    "marketplace_to_demand": 140.3240781,
    "grid_to_demand": 36051.19921,
    "govt_battery_charge": 48.14014715
  },
  {
    "timestamp": "04/05/24 18:00",
    "demand": 32365.01151,
    "solar_generated": 0,
    "wind_generated": 76.53522199,
    "marketplace_to_demand": 94.22891854,
    "grid_to_demand": 33838.66921,
    "govt_battery_charge": 94.23817321
  },
  {
    "timestamp": "04/05/24 19:00",
    "demand": 31326.2645,
    "solar_generated": 0,
    "wind_generated": 75.30472528,
    "marketplace_to_demand": 182.509093,
    "grid_to_demand": 34257.0884,
    "govt_battery_charge": 62.26274725
  },
  {
    "timestamp": "04/05/24 20:00",
    "demand": 32184.32806,
    "solar_generated": 0,
    "wind_generated": 76.92109332,
    "marketplace_to_demand": 27.94551115,
    "grid_to_demand": 31394.95441,
    "govt_battery_charge": 19.36052
  },
  {
    "timestamp": "04/05/24 21:00",
    "demand": 30867.81751,
    "solar_generated": 0,
    "wind_generated": 78.16545034,
    "marketplace_to_demand": 171.1554296,
    "grid_to_demand": 32006.79737,
    "govt_battery_charge": 88.41037888
  },
  {
    "timestamp": "04/05/24 22:00",
    "demand": 35013.07907,
    "solar_generated": 0,
    "wind_generated": 68.3723657,
    "marketplace_to_demand": 122.1670555,
    "grid_to_demand": 33754.09968,
    "govt_battery_charge": 44.86006166
  },
  {
    "timestamp": "04/05/24 23:00",
    "demand": 32635.29466,
    "solar_generated": 0,
    "wind_generated": 61.13515807,
    "marketplace_to_demand": 1.996262654,
    "grid_to_demand": 33075.47212,
    "govt_battery_charge": 43.7337263
  },
  {
    "timestamp": "05/05/24 0:00",
    "demand": 33965.41245,
    "solar_generated": 0,
    "wind_generated": 56.03229037,
    "marketplace_to_demand": 128.0573072,
    "grid_to_demand": 30808.69089,
    "govt_battery_charge": 10.48988942
  },
  {
    "timestamp": "05/05/24 1:00",
    "demand": 30870.98512,
    "solar_generated": 0,
    "wind_generated": 54.71279956,
    "marketplace_to_demand": 133.8454648,
    "grid_to_demand": 34411.46597,
    "govt_battery_charge": 23.35546291
  },
  {
    "timestamp": "05/05/24 2:00",
    "demand": 32717.88611,
    "solar_generated": 0,
    "wind_generated": 54.69308279,
    "marketplace_to_demand": 89.61334749,
    "grid_to_demand": 36275.66759,
    "govt_battery_charge": 33.85645735
  },
  {
    "timestamp": "05/05/24 3:00",
    "demand": 31445.8937,
    "solar_generated": 0,
    "wind_generated": 75.03469618,
    "marketplace_to_demand": 30.20164478,
    "grid_to_demand": 31377.31898,
    "govt_battery_charge": 94.36906198
  },
  {
    "timestamp": "05/05/24 4:00",
    "demand": 33401.46787,
    "solar_generated": 0,
    "wind_generated": 56.00872213,
    "marketplace_to_demand": 146.6679225,
    "grid_to_demand": 30737.94322,
    "govt_battery_charge": 70.35344829
  },
  {
    "timestamp": "05/05/24 5:00",
    "demand": 32885.96764,
    "solar_generated": 0,
    "wind_generated": 76.37731028,
    "marketplace_to_demand": 65.47144357,
    "grid_to_demand": 32096.76024,
    "govt_battery_charge": 39.32887559
  },
  {
    "timestamp": "05/05/24 6:00",
    "demand": 33026.45181,
    "solar_generated": 7.167445094,
    "wind_generated": 67.89305436,
    "marketplace_to_demand": 158.1997613,
    "grid_to_demand": 35978.96373,
    "govt_battery_charge": 37.5732248
  },
  {
    "timestamp": "05/05/24 7:00",
    "demand": 34414.23563,
    "solar_generated": 12.17858269,
    "wind_generated": 79.07812251,
    "marketplace_to_demand": 103.5609521,
    "grid_to_demand": 34969.22761,
    "govt_battery_charge": 74.412925
  },
  {
    "timestamp": "05/05/24 8:00",
    "demand": 35421.54039,
    "solar_generated": 14.69306011,
    "wind_generated": 69.9643352,
    "marketplace_to_demand": 101.3779572,
    "grid_to_demand": 36311.85743,
    "govt_battery_charge": 83.6299943
  },
  {
    "timestamp": "05/05/24 9:00",
    "demand": 32306.01858,
    "solar_generated": 17.12748154,
    "wind_generated": 59.51404789,
    "marketplace_to_demand": 160.6926211,
    "grid_to_demand": 30112.33832,
    "govt_battery_charge": 46.49315948
  },
  {
    "timestamp": "05/05/24 10:00",
    "demand": 34462.28515,
    "solar_generated": 21.54989114,
    "wind_generated": 64.01456926,
    "marketplace_to_demand": 128.6985136,
    "grid_to_demand": 32668.55642,
    "govt_battery_charge": 18.75754137
  },
  {
    "timestamp": "05/05/24 11:00",
    "demand": 31618.29183,
    "solar_generated": 22.41250104,
    "wind_generated": 56.5996165,
    "marketplace_to_demand": 154.3737006,
    "grid_to_demand": 33228.9618,
    "govt_battery_charge": 43.64129745
  },
  {
    "timestamp": "05/05/24 12:00",
    "demand": 31646.07413,
    "solar_generated": 15.71717034,
    "wind_generated": 78.91285265,
    "marketplace_to_demand": 1.9489987,
    "grid_to_demand": 33699.96611,
    "govt_battery_charge": 29.90893595
  },
  {
    "timestamp": "05/05/24 13:00",
    "demand": 34032.83479,
    "solar_generated": 18.99209116,
    "wind_generated": 68.76266809,
    "marketplace_to_demand": 50.88769306,
    "grid_to_demand": 31068.47308,
    "govt_battery_charge": 61.11360534
  },
  {
    "timestamp": "05/05/24 14:00",
    "demand": 34128.19641,
    "solar_generated": 15.25081619,
    "wind_generated": 61.83285112,
    "marketplace_to_demand": 139.832524,
    "grid_to_demand": 32582.20726,
    "govt_battery_charge": 54.58360798
  },
  {
    "timestamp": "05/05/24 15:00",
    "demand": 36539.25568,
    "solar_generated": 11.99158601,
    "wind_generated": 74.27241372,
    "marketplace_to_demand": 151.0549779,
    "grid_to_demand": 35531.44216,
    "govt_battery_charge": 67.2126715
  },
  {
    "timestamp": "05/05/24 16:00",
    "demand": 36052.25124,
    "solar_generated": 23.79180813,
    "wind_generated": 54.07064492,
    "marketplace_to_demand": 116.6024668,
    "grid_to_demand": 35280.92994,
    "govt_battery_charge": 18.85627396
  },
  {
    "timestamp": "05/05/24 17:00",
    "demand": 29998.54273,
    "solar_generated": 10.07055303,
    "wind_generated": 64.92958728,
    "marketplace_to_demand": 192.4669209,
    "grid_to_demand": 35900.78204,
    "govt_battery_charge": 83.19331344
  },
  {
    "timestamp": "05/05/24 18:00",
    "demand": 32134.17245,
    "solar_generated": 0,
    "wind_generated": 75.88540278,
    "marketplace_to_demand": 49.40898728,
    "grid_to_demand": 35427.15535,
    "govt_battery_charge": 72.73673711
  },
  {
    "timestamp": "05/05/24 19:00",
    "demand": 35489.97746,
    "solar_generated": 0,
    "wind_generated": 53.84695642,
    "marketplace_to_demand": 29.6952956,
    "grid_to_demand": 32755.28724,
    "govt_battery_charge": 55.85759161
  },
  {
    "timestamp": "05/05/24 20:00",
    "demand": 36425.47569,
    "solar_generated": 0,
    "wind_generated": 69.52862905,
    "marketplace_to_demand": 8.646798587,
    "grid_to_demand": 31299.41662,
    "govt_battery_charge": 28.07453637
  },
  {
    "timestamp": "05/05/24 21:00",
    "demand": 33925.97235,
    "solar_generated": 0,
    "wind_generated": 79.9773549,
    "marketplace_to_demand": 164.9117979,
    "grid_to_demand": 31959.82891,
    "govt_battery_charge": 26.35522442
  },
  {
    "timestamp": "05/05/24 22:00",
    "demand": 36230.0902,
    "solar_generated": 0,
    "wind_generated": 77.86878517,
    "marketplace_to_demand": 31.62738857,
    "grid_to_demand": 33203.24029,
    "govt_battery_charge": 7.472659967
  },
  {
    "timestamp": "05/05/24 23:00",
    "demand": 31851.00096,
    "solar_generated": 0,
    "wind_generated": 61.42611027,
    "marketplace_to_demand": 73.71120978,
    "grid_to_demand": 35846.62771,
    "govt_battery_charge": 61.49576331
  },
  {
    "timestamp": "06/05/24 0:00",
    "demand": 30738.32592,
    "solar_generated": 0,
    "wind_generated": 70.89640761,
    "marketplace_to_demand": 88.46526031,
    "grid_to_demand": 34694.7535,
    "govt_battery_charge": 51.43469085
  },
  {
    "timestamp": "06/05/24 1:00",
    "demand": 34009.1707,
    "solar_generated": 0,
    "wind_generated": 62.92912352,
    "marketplace_to_demand": 140.4181144,
    "grid_to_demand": 33848.43003,
    "govt_battery_charge": 39.88173141
  },
  {
    "timestamp": "06/05/24 2:00",
    "demand": 35794.41619,
    "solar_generated": 0,
    "wind_generated": 75.97432453,
    "marketplace_to_demand": 192.3653601,
    "grid_to_demand": 34828.54804,
    "govt_battery_charge": 86.05386085
  },
  {
    "timestamp": "06/05/24 3:00",
    "demand": 32420.64563,
    "solar_generated": 0,
    "wind_generated": 58.60273057,
    "marketplace_to_demand": 147.7139151,
    "grid_to_demand": 34750.10249,
    "govt_battery_charge": 17.78905867
  },
  {
    "timestamp": "06/05/24 4:00",
    "demand": 30420.36369,
    "solar_generated": 0,
    "wind_generated": 62.19338427,
    "marketplace_to_demand": 102.9410229,
    "grid_to_demand": 35862.64167,
    "govt_battery_charge": 8.529000307
  },
  {
    "timestamp": "06/05/24 5:00",
    "demand": 30443.90194,
    "solar_generated": 0,
    "wind_generated": 75.91151324,
    "marketplace_to_demand": 149.7420913,
    "grid_to_demand": 35928.75585,
    "govt_battery_charge": 14.702737
  },
  {
    "timestamp": "06/05/24 6:00",
    "demand": 33455.89523,
    "solar_generated": 23.20639235,
    "wind_generated": 60.18936047,
    "marketplace_to_demand": 152.5450259,
    "grid_to_demand": 32795.32514,
    "govt_battery_charge": 31.69930959
  },
  {
    "timestamp": "06/05/24 7:00",
    "demand": 36299.69526,
    "solar_generated": 19.66165061,
    "wind_generated": 54.25796369,
    "marketplace_to_demand": 178.6893528,
    "grid_to_demand": 32776.0327,
    "govt_battery_charge": 25.96476963
  },
  {
    "timestamp": "06/05/24 8:00",
    "demand": 30034.10806,
    "solar_generated": 9.112099207,
    "wind_generated": 58.47771929,
    "marketplace_to_demand": 119.4970073,
    "grid_to_demand": 36177.43903,
    "govt_battery_charge": 85.5539598
  },
  {
    "timestamp": "06/05/24 9:00",
    "demand": 30779.8457,
    "solar_generated": 6.341006277,
    "wind_generated": 62.53762876,
    "marketplace_to_demand": 107.2402497,
    "grid_to_demand": 30702.08221,
    "govt_battery_charge": 9.56855069
  },
  {
    "timestamp": "06/05/24 10:00",
    "demand": 33302.23001,
    "solar_generated": 7.325080379,
    "wind_generated": 79.2915782,
    "marketplace_to_demand": 64.09605286,
    "grid_to_demand": 32868.13158,
    "govt_battery_charge": 76.8530242
  },
  {
    "timestamp": "06/05/24 11:00",
    "demand": 31500.4223,
    "solar_generated": 7.573933359,
    "wind_generated": 62.27561392,
    "marketplace_to_demand": 144.4406854,
    "grid_to_demand": 30118.54276,
    "govt_battery_charge": 25.75566452
  },
  {
    "timestamp": "06/05/24 12:00",
    "demand": 31288.52072,
    "solar_generated": 6.351396908,
    "wind_generated": 56.65140923,
    "marketplace_to_demand": 181.6791683,
    "grid_to_demand": 31996.92246,
    "govt_battery_charge": 26.93355546
  },
  {
    "timestamp": "06/05/24 13:00",
    "demand": 36167.24841,
    "solar_generated": 6.651781512,
    "wind_generated": 63.04213143,
    "marketplace_to_demand": 34.09419454,
    "grid_to_demand": 34197.47475,
    "govt_battery_charge": 92.17141635
  },
  {
    "timestamp": "06/05/24 14:00",
    "demand": 30575.9887,
    "solar_generated": 10.68256506,
    "wind_generated": 59.10241562,
    "marketplace_to_demand": 53.11268854,
    "grid_to_demand": 34145.14401,
    "govt_battery_charge": 37.79201189
  },
  {
    "timestamp": "06/05/24 15:00",
    "demand": 32732.89762,
    "solar_generated": 14.04771632,
    "wind_generated": 67.84473663,
    "marketplace_to_demand": 47.26855631,
    "grid_to_demand": 30404.96571,
    "govt_battery_charge": 57.81419411
  },
  {
    "timestamp": "06/05/24 16:00",
    "demand": 32415.00438,
    "solar_generated": 8.422067317,
    "wind_generated": 69.31841665,
    "marketplace_to_demand": 127.5206535,
    "grid_to_demand": 33949.89503,
    "govt_battery_charge": 7.162978081
  },
  {
    "timestamp": "06/05/24 17:00",
    "demand": 32049.01921,
    "solar_generated": 12.02096618,
    "wind_generated": 57.55657011,
    "marketplace_to_demand": 144.6667948,
    "grid_to_demand": 30859.53664,
    "govt_battery_charge": 17.76159516
  },
  {
    "timestamp": "06/05/24 18:00",
    "demand": 30366.2588,
    "solar_generated": 0,
    "wind_generated": 75.48378329,
    "marketplace_to_demand": 55.76735662,
    "grid_to_demand": 35080.3171,
    "govt_battery_charge": 16.6142054
  },
  {
    "timestamp": "06/05/24 19:00",
    "demand": 30262.4939,
    "solar_generated": 0,
    "wind_generated": 61.02438621,
    "marketplace_to_demand": 177.0881504,
    "grid_to_demand": 31941.67435,
    "govt_battery_charge": 2.150511862
  },
  {
    "timestamp": "06/05/24 20:00",
    "demand": 34455.30475,
    "solar_generated": 0,
    "wind_generated": 71.23573594,
    "marketplace_to_demand": 96.28648365,
    "grid_to_demand": 34472.03948,
    "govt_battery_charge": 27.45566279
  },
  {
    "timestamp": "06/05/24 21:00",
    "demand": 33459.09493,
    "solar_generated": 0,
    "wind_generated": 74.36890466,
    "marketplace_to_demand": 84.69325555,
    "grid_to_demand": 33360.42998,
    "govt_battery_charge": 78.10339385
  },
  {
    "timestamp": "06/05/24 22:00",
    "demand": 33256.34227,
    "solar_generated": 0,
    "wind_generated": 66.16343029,
    "marketplace_to_demand": 164.5148042,
    "grid_to_demand": 32163.25378,
    "govt_battery_charge": 52.06702033
  },
  {
    "timestamp": "06/05/24 23:00",
    "demand": 35917.00377,
    "solar_generated": 0,
    "wind_generated": 64.16460014,
    "marketplace_to_demand": 7.14004497,
    "grid_to_demand": 31367.32189,
    "govt_battery_charge": 32.54809572
  },
  {
    "timestamp": "07/05/24 0:00",
    "demand": 34867.59815,
    "solar_generated": 0,
    "wind_generated": 58.07460156,
    "marketplace_to_demand": 78.43549437,
    "grid_to_demand": 35008.48786,
    "govt_battery_charge": 20.54986035
  },
  {
    "timestamp": "07/05/24 1:00",
    "demand": 35145.45042,
    "solar_generated": 0,
    "wind_generated": 63.80432412,
    "marketplace_to_demand": 43.63907077,
    "grid_to_demand": 32175.3283,
    "govt_battery_charge": 69.24210739
  },
  {
    "timestamp": "07/05/24 2:00",
    "demand": 33665.14806,
    "solar_generated": 0,
    "wind_generated": 73.63235875,
    "marketplace_to_demand": 126.7869832,
    "grid_to_demand": 33081.59809,
    "govt_battery_charge": 1.90599057
  },
  {
    "timestamp": "07/05/24 3:00",
    "demand": 30758.70793,
    "solar_generated": 0,
    "wind_generated": 62.42874035,
    "marketplace_to_demand": 110.7317752,
    "grid_to_demand": 31796.46881,
    "govt_battery_charge": 5.769586854
  },
  {
    "timestamp": "07/05/24 4:00",
    "demand": 32419.78781,
    "solar_generated": 0,
    "wind_generated": 70.06206451,
    "marketplace_to_demand": 83.31344447,
    "grid_to_demand": 32623.45455,
    "govt_battery_charge": 63.76427407
  },
  {
    "timestamp": "07/05/24 5:00",
    "demand": 33771.73371,
    "solar_generated": 0,
    "wind_generated": 66.50039483,
    "marketplace_to_demand": 185.9513592,
    "grid_to_demand": 31193.88049,
    "govt_battery_charge": 39.74506197
  },
  {
    "timestamp": "07/05/24 6:00",
    "demand": 34851.66536,
    "solar_generated": 12.51256503,
    "wind_generated": 75.48589471,
    "marketplace_to_demand": 185.8295615,
    "grid_to_demand": 33195.20999,
    "govt_battery_charge": 42.34959307
  },
  {
    "timestamp": "07/05/24 7:00",
    "demand": 31798.78985,
    "solar_generated": 9.538111868,
    "wind_generated": 53.93549921,
    "marketplace_to_demand": 34.66028071,
    "grid_to_demand": 36274.70941,
    "govt_battery_charge": 91.59644646
  },
  {
    "timestamp": "07/05/24 8:00",
    "demand": 30828.70106,
    "solar_generated": 18.6058171,
    "wind_generated": 79.70182533,
    "marketplace_to_demand": 18.47880313,
    "grid_to_demand": 32112.23363,
    "govt_battery_charge": 59.38862915
  },
  {
    "timestamp": "07/05/24 9:00",
    "demand": 32128.13942,
    "solar_generated": 18.36076,
    "wind_generated": 66.63313196,
    "marketplace_to_demand": 108.1296465,
    "grid_to_demand": 32025.6944,
    "govt_battery_charge": 99.86925299
  },
  {
    "timestamp": "07/05/24 10:00",
    "demand": 31502.20136,
    "solar_generated": 14.04482745,
    "wind_generated": 59.18808927,
    "marketplace_to_demand": 20.63948974,
    "grid_to_demand": 36476.32132,
    "govt_battery_charge": 90.7037104
  },
  {
    "timestamp": "07/05/24 11:00",
    "demand": 35351.04795,
    "solar_generated": 14.37910521,
    "wind_generated": 56.76169274,
    "marketplace_to_demand": 29.16783589,
    "grid_to_demand": 33297.51721,
    "govt_battery_charge": 98.09580228
  },
  {
    "timestamp": "07/05/24 12:00",
    "demand": 32905.77851,
    "solar_generated": 23.31918229,
    "wind_generated": 71.49416937,
    "marketplace_to_demand": 9.379519594,
    "grid_to_demand": 32497.48041,
    "govt_battery_charge": 60.26058956
  },
  {
    "timestamp": "07/05/24 13:00",
    "demand": 31087.43878,
    "solar_generated": 16.01302577,
    "wind_generated": 62.93012672,
    "marketplace_to_demand": 190.3330585,
    "grid_to_demand": 35185.1958,
    "govt_battery_charge": 14.37570994
  },
  {
    "timestamp": "07/05/24 14:00",
    "demand": 32926.1332,
    "solar_generated": 8.688637562,
    "wind_generated": 67.20834334,
    "marketplace_to_demand": 80.43738291,
    "grid_to_demand": 30515.13679,
    "govt_battery_charge": 88.77770421
  },
  {
    "timestamp": "07/05/24 15:00",
    "demand": 31372.39544,
    "solar_generated": 19.34083898,
    "wind_generated": 74.91147082,
    "marketplace_to_demand": 143.9908394,
    "grid_to_demand": 32003.95882,
    "govt_battery_charge": 27.66653036
  },
  {
    "timestamp": "07/05/24 16:00",
    "demand": 31359.24157,
    "solar_generated": 10.05663648,
    "wind_generated": 59.20754163,
    "marketplace_to_demand": 190.6352123,
    "grid_to_demand": 31416.37286,
    "govt_battery_charge": 41.84737682
  },
  {
    "timestamp": "07/05/24 17:00",
    "demand": 35604.84841,
    "solar_generated": 17.75393386,
    "wind_generated": 66.50879194,
    "marketplace_to_demand": 24.47172038,
    "grid_to_demand": 30362.38052,
    "govt_battery_charge": 57.00668663
  },
  {
    "timestamp": "07/05/24 18:00",
    "demand": 35516.07433,
    "solar_generated": 0,
    "wind_generated": 80.08064349,
    "marketplace_to_demand": 12.35336599,
    "grid_to_demand": 31948.06188,
    "govt_battery_charge": 15.05800129
  },
  {
    "timestamp": "07/05/24 19:00",
    "demand": 35513.02873,
    "solar_generated": 0,
    "wind_generated": 57.63148388,
    "marketplace_to_demand": 5.196696466,
    "grid_to_demand": 30663.74619,
    "govt_battery_charge": 37.67486281
  },
  {
    "timestamp": "07/05/24 20:00",
    "demand": 32483.13307,
    "solar_generated": 0,
    "wind_generated": 63.7839965,
    "marketplace_to_demand": 133.5595425,
    "grid_to_demand": 34814.9249,
    "govt_battery_charge": 78.76319695
  },
  {
    "timestamp": "07/05/24 21:00",
    "demand": 33298.1373,
    "solar_generated": 0,
    "wind_generated": 76.41081874,
    "marketplace_to_demand": 71.68379708,
    "grid_to_demand": 30940.29118,
    "govt_battery_charge": 92.96785142
  },
  {
    "timestamp": "07/05/24 22:00",
    "demand": 35522.5165,
    "solar_generated": 0,
    "wind_generated": 76.61439053,
    "marketplace_to_demand": 60.88770275,
    "grid_to_demand": 32022.40115,
    "govt_battery_charge": 12.67072202
  },
  {
    "timestamp": "07/05/24 23:00",
    "demand": 35672.66465,
    "solar_generated": 0,
    "wind_generated": 58.05253542,
    "marketplace_to_demand": 71.49093942,
    "grid_to_demand": 34379.31863,
    "govt_battery_charge": 64.07668227
  },
  {
    "timestamp": "08/05/24 0:00",
    "demand": 36064.27584,
    "solar_generated": 0,
    "wind_generated": 65.44504421,
    "marketplace_to_demand": 144.5247496,
    "grid_to_demand": 31539.5613,
    "govt_battery_charge": 47.67369348
  },
  {
    "timestamp": "08/05/24 1:00",
    "demand": 31196.79464,
    "solar_generated": 0,
    "wind_generated": 74.75332466,
    "marketplace_to_demand": 163.7388399,
    "grid_to_demand": 31634.59747,
    "govt_battery_charge": 99.23913301
  },
  {
    "timestamp": "08/05/24 2:00",
    "demand": 31341.41265,
    "solar_generated": 0,
    "wind_generated": 67.01493327,
    "marketplace_to_demand": 67.58556733,
    "grid_to_demand": 31958.17738,
    "govt_battery_charge": 38.06659448
  },
  {
    "timestamp": "08/05/24 3:00",
    "demand": 33697.72573,
    "solar_generated": 0,
    "wind_generated": 59.1248423,
    "marketplace_to_demand": 31.05799907,
    "grid_to_demand": 32321.2551,
    "govt_battery_charge": 59.81869656
  },
  {
    "timestamp": "08/05/24 4:00",
    "demand": 30185.92574,
    "solar_generated": 0,
    "wind_generated": 69.89236123,
    "marketplace_to_demand": 16.63602206,
    "grid_to_demand": 34319.6258,
    "govt_battery_charge": 70.25157597
  },
  {
    "timestamp": "08/05/24 5:00",
    "demand": 34083.38879,
    "solar_generated": 0,
    "wind_generated": 69.96801388,
    "marketplace_to_demand": 168.9998369,
    "grid_to_demand": 32675.20637,
    "govt_battery_charge": 7.593533643
  },
  {
    "timestamp": "08/05/24 6:00",
    "demand": 36180.8768,
    "solar_generated": 15.51117565,
    "wind_generated": 66.61731579,
    "marketplace_to_demand": 174.5492407,
    "grid_to_demand": 34210.26422,
    "govt_battery_charge": 6.74832197
  },
  {
    "timestamp": "08/05/24 7:00",
    "demand": 34101.56921,
    "solar_generated": 7.130231339,
    "wind_generated": 61.67417832,
    "marketplace_to_demand": 13.07235407,
    "grid_to_demand": 35366.67478,
    "govt_battery_charge": 11.69556996
  },
  {
    "timestamp": "08/05/24 8:00",
    "demand": 32075.25741,
    "solar_generated": 11.11673195,
    "wind_generated": 77.56378455,
    "marketplace_to_demand": 63.5581184,
    "grid_to_demand": 30369.33889,
    "govt_battery_charge": 87.25124654
  },
  {
    "timestamp": "08/05/24 9:00",
    "demand": 30026.5727,
    "solar_generated": 21.96568422,
    "wind_generated": 76.65089992,
    "marketplace_to_demand": 79.16313457,
    "grid_to_demand": 35386.68096,
    "govt_battery_charge": 81.69260632
  },
  {
    "timestamp": "08/05/24 10:00",
    "demand": 31886.82661,
    "solar_generated": 6.876572271,
    "wind_generated": 70.76751103,
    "marketplace_to_demand": 50.63630472,
    "grid_to_demand": 34361.83296,
    "govt_battery_charge": 94.57722621
  },
  {
    "timestamp": "08/05/24 11:00",
    "demand": 35477.08273,
    "solar_generated": 17.64254555,
    "wind_generated": 79.85631852,
    "marketplace_to_demand": 20.22838967,
    "grid_to_demand": 32620.32407,
    "govt_battery_charge": 44.43675613
  },
  {
    "timestamp": "08/05/24 12:00",
    "demand": 32852.87321,
    "solar_generated": 11.91011552,
    "wind_generated": 67.21293956,
    "marketplace_to_demand": 171.6671212,
    "grid_to_demand": 33676.85293,
    "govt_battery_charge": 96.61518619
  },
  {
    "timestamp": "08/05/24 13:00",
    "demand": 34042.33034,
    "solar_generated": 22.457846,
    "wind_generated": 66.09197089,
    "marketplace_to_demand": 165.3584788,
    "grid_to_demand": 34022.49709,
    "govt_battery_charge": 86.00839864
  },
  {
    "timestamp": "08/05/24 14:00",
    "demand": 33276.0913,
    "solar_generated": 8.406001246,
    "wind_generated": 56.36171501,
    "marketplace_to_demand": 138.5903022,
    "grid_to_demand": 31683.74289,
    "govt_battery_charge": 63.91819158
  },
  {
    "timestamp": "08/05/24 15:00",
    "demand": 30468.2681,
    "solar_generated": 12.25437659,
    "wind_generated": 76.75624957,
    "marketplace_to_demand": 107.0917813,
    "grid_to_demand": 33484.4073,
    "govt_battery_charge": 50.48785274
  },
  {
    "timestamp": "08/05/24 16:00",
    "demand": 36269.305,
    "solar_generated": 17.91378006,
    "wind_generated": 59.51484533,
    "marketplace_to_demand": 157.8390779,
    "grid_to_demand": 31068.48507,
    "govt_battery_charge": 63.07243707
  },
  {
    "timestamp": "08/05/24 17:00",
    "demand": 34017.00135,
    "solar_generated": 12.78617614,
    "wind_generated": 54.50699763,
    "marketplace_to_demand": 99.18614273,
    "grid_to_demand": 31374.00401,
    "govt_battery_charge": 70.93929057
  },
  {
    "timestamp": "08/05/24 18:00",
    "demand": 31819.8455,
    "solar_generated": 0,
    "wind_generated": 72.8092633,
    "marketplace_to_demand": 164.020922,
    "grid_to_demand": 36398.79536,
    "govt_battery_charge": 91.89172387
  },
  {
    "timestamp": "08/05/24 19:00",
    "demand": 31061.25606,
    "solar_generated": 0,
    "wind_generated": 70.58772926,
    "marketplace_to_demand": 35.47642923,
    "grid_to_demand": 32193.88154,
    "govt_battery_charge": 21.63148962
  },
  {
    "timestamp": "08/05/24 20:00",
    "demand": 36274.14226,
    "solar_generated": 0,
    "wind_generated": 65.79102901,
    "marketplace_to_demand": 31.79815977,
    "grid_to_demand": 35277.87085,
    "govt_battery_charge": 3.320568944
  },
  {
    "timestamp": "08/05/24 21:00",
    "demand": 30092.66286,
    "solar_generated": 0,
    "wind_generated": 78.82931024,
    "marketplace_to_demand": 32.5855925,
    "grid_to_demand": 31455.97921,
    "govt_battery_charge": 84.57603706
  },
  {
    "timestamp": "08/05/24 22:00",
    "demand": 34122.59997,
    "solar_generated": 0,
    "wind_generated": 55.17805641,
    "marketplace_to_demand": 185.8566789,
    "grid_to_demand": 34580.92058,
    "govt_battery_charge": 17.62371354
  },
  {
    "timestamp": "08/05/24 23:00",
    "demand": 35253.10405,
    "solar_generated": 0,
    "wind_generated": 68.7127182,
    "marketplace_to_demand": 148.1996655,
    "grid_to_demand": 35178.7162,
    "govt_battery_charge": 93.33605474
  },
  {
    "timestamp": "09/05/24 0:00",
    "demand": 33399.72328,
    "solar_generated": 0,
    "wind_generated": 78.98646335,
    "marketplace_to_demand": 93.47184376,
    "grid_to_demand": 31236.22221,
    "govt_battery_charge": 60.73635649
  },
  {
    "timestamp": "09/05/24 1:00",
    "demand": 30505.60956,
    "solar_generated": 0,
    "wind_generated": 63.21456355,
    "marketplace_to_demand": 185.5861423,
    "grid_to_demand": 36083.0008,
    "govt_battery_charge": 38.63457453
  },
  {
    "timestamp": "09/05/24 2:00",
    "demand": 31036.91901,
    "solar_generated": 0,
    "wind_generated": 64.25004496,
    "marketplace_to_demand": 54.62291133,
    "grid_to_demand": 32720.60832,
    "govt_battery_charge": 84.20447449
  },
  {
    "timestamp": "09/05/24 3:00",
    "demand": 33978.06671,
    "solar_generated": 0,
    "wind_generated": 74.01594337,
    "marketplace_to_demand": 21.22864686,
    "grid_to_demand": 30859.66748,
    "govt_battery_charge": 45.45080058
  },
  {
    "timestamp": "09/05/24 4:00",
    "demand": 32978.36686,
    "solar_generated": 0,
    "wind_generated": 73.38626366,
    "marketplace_to_demand": 151.2191732,
    "grid_to_demand": 35511.45392,
    "govt_battery_charge": 82.44320796
  },
  {
    "timestamp": "09/05/24 5:00",
    "demand": 31275.4468,
    "solar_generated": 0,
    "wind_generated": 57.90132188,
    "marketplace_to_demand": 149.1553652,
    "grid_to_demand": 33976.87491,
    "govt_battery_charge": 33.21828694
  },
  {
    "timestamp": "09/05/24 6:00",
    "demand": 30152.89518,
    "solar_generated": 20.01617987,
    "wind_generated": 73.71737951,
    "marketplace_to_demand": 8.735278071,
    "grid_to_demand": 35236.30516,
    "govt_battery_charge": 27.43170713
  },
  {
    "timestamp": "09/05/24 7:00",
    "demand": 34727.88859,
    "solar_generated": 13.35280347,
    "wind_generated": 67.67525262,
    "marketplace_to_demand": 1.959782727,
    "grid_to_demand": 32114.31279,
    "govt_battery_charge": 1.015667335
  },
  {
    "timestamp": "09/05/24 8:00",
    "demand": 30416.63673,
    "solar_generated": 8.121557134,
    "wind_generated": 69.7364688,
    "marketplace_to_demand": 102.2464689,
    "grid_to_demand": 33952.98757,
    "govt_battery_charge": 44.50438654
  },
  {
    "timestamp": "09/05/24 9:00",
    "demand": 34565.34011,
    "solar_generated": 13.28060339,
    "wind_generated": 57.49141397,
    "marketplace_to_demand": 114.5003979,
    "grid_to_demand": 32648.27299,
    "govt_battery_charge": 16.84227264
  },
  {
    "timestamp": "09/05/24 10:00",
    "demand": 35877.36276,
    "solar_generated": 9.175057838,
    "wind_generated": 54.03944373,
    "marketplace_to_demand": 82.01185155,
    "grid_to_demand": 35401.53046,
    "govt_battery_charge": 93.88024162
  },
  {
    "timestamp": "09/05/24 11:00",
    "demand": 36606.76057,
    "solar_generated": 20.68280109,
    "wind_generated": 79.78065775,
    "marketplace_to_demand": 129.764889,
    "grid_to_demand": 33192.08735,
    "govt_battery_charge": 98.3405174
  },
  {
    "timestamp": "09/05/24 12:00",
    "demand": 35018.33076,
    "solar_generated": 14.4087971,
    "wind_generated": 66.50051268,
    "marketplace_to_demand": 166.7311448,
    "grid_to_demand": 34839.86669,
    "govt_battery_charge": 9.776717744
  },
  {
    "timestamp": "09/05/24 13:00",
    "demand": 32469.06037,
    "solar_generated": 6.355801347,
    "wind_generated": 58.79847748,
    "marketplace_to_demand": 74.64415853,
    "grid_to_demand": 35793.3097,
    "govt_battery_charge": 88.88254775
  },
  {
    "timestamp": "09/05/24 14:00",
    "demand": 31276.99016,
    "solar_generated": 12.64209669,
    "wind_generated": 66.64306135,
    "marketplace_to_demand": 109.0686699,
    "grid_to_demand": 33201.79198,
    "govt_battery_charge": 99.02711688
  },
  {
    "timestamp": "09/05/24 15:00",
    "demand": 30245.24497,
    "solar_generated": 21.84420547,
    "wind_generated": 56.87085494,
    "marketplace_to_demand": 14.2957445,
    "grid_to_demand": 36314.67079,
    "govt_battery_charge": 59.22546994
  },
  {
    "timestamp": "09/05/24 16:00",
    "demand": 36059.21592,
    "solar_generated": 22.01789695,
    "wind_generated": 67.71235548,
    "marketplace_to_demand": 190.0114421,
    "grid_to_demand": 33402.03837,
    "govt_battery_charge": 6.256417169
  },
  {
    "timestamp": "09/05/24 17:00",
    "demand": 31985.69719,
    "solar_generated": 18.9307701,
    "wind_generated": 78.94390829,
    "marketplace_to_demand": 44.49194897,
    "grid_to_demand": 30190.84854,
    "govt_battery_charge": 5.104162121
  },
  {
    "timestamp": "09/05/24 18:00",
    "demand": 33748.60056,
    "solar_generated": 0,
    "wind_generated": 55.45244165,
    "marketplace_to_demand": 35.4209176,
    "grid_to_demand": 31421.9132,
    "govt_battery_charge": 80.06325443
  },
  {
    "timestamp": "09/05/24 19:00",
    "demand": 35363.08654,
    "solar_generated": 0,
    "wind_generated": 59.13519082,
    "marketplace_to_demand": 65.82705234,
    "grid_to_demand": 35227.00857,
    "govt_battery_charge": 84.27398788
  },
  {
    "timestamp": "09/05/24 20:00",
    "demand": 34722.37111,
    "solar_generated": 0,
    "wind_generated": 71.03100371,
    "marketplace_to_demand": 104.2581332,
    "grid_to_demand": 35594.42274,
    "govt_battery_charge": 24.54251404
  },
  {
    "timestamp": "09/05/24 21:00",
    "demand": 33592.10917,
    "solar_generated": 0,
    "wind_generated": 63.28324848,
    "marketplace_to_demand": 171.1509235,
    "grid_to_demand": 33683.23544,
    "govt_battery_charge": 77.20304193
  },
  {
    "timestamp": "09/05/24 22:00",
    "demand": 33694.34402,
    "solar_generated": 0,
    "wind_generated": 73.14077591,
    "marketplace_to_demand": 67.29151383,
    "grid_to_demand": 32415.18964,
    "govt_battery_charge": 48.52459543
  },
  {
    "timestamp": "09/05/24 23:00",
    "demand": 32754.94918,
    "solar_generated": 0,
    "wind_generated": 76.92141138,
    "marketplace_to_demand": 131.2447674,
    "grid_to_demand": 34315.0656,
    "govt_battery_charge": 33.14110375
  },
  {
    "timestamp": "10/05/24 0:00",
    "demand": 32931.36625,
    "solar_generated": 0,
    "wind_generated": 73.88588583,
    "marketplace_to_demand": 51.9067223,
    "grid_to_demand": 35055.28852,
    "govt_battery_charge": 52.56052626
  },
  {
    "timestamp": "10/05/24 1:00",
    "demand": 35736.12539,
    "solar_generated": 0,
    "wind_generated": 67.52698249,
    "marketplace_to_demand": 65.39514504,
    "grid_to_demand": 35099.89589,
    "govt_battery_charge": 95.39330243
  },
  {
    "timestamp": "10/05/24 2:00",
    "demand": 30828.13898,
    "solar_generated": 0,
    "wind_generated": 56.39331746,
    "marketplace_to_demand": 39.37406899,
    "grid_to_demand": 30563.50419,
    "govt_battery_charge": 80.05201988
  },
  {
    "timestamp": "10/05/24 3:00",
    "demand": 32703.28614,
    "solar_generated": 0,
    "wind_generated": 54.1553726,
    "marketplace_to_demand": 45.0084353,
    "grid_to_demand": 30673.40987,
    "govt_battery_charge": 61.85797796
  },
  {
    "timestamp": "10/05/24 4:00",
    "demand": 31224.12036,
    "solar_generated": 0,
    "wind_generated": 65.86070251,
    "marketplace_to_demand": 114.6329194,
    "grid_to_demand": 30930.54663,
    "govt_battery_charge": 13.8605151
  },
  {
    "timestamp": "10/05/24 5:00",
    "demand": 36060.96304,
    "solar_generated": 0,
    "wind_generated": 66.45171385,
    "marketplace_to_demand": 193.0880816,
    "grid_to_demand": 34309.18874,
    "govt_battery_charge": 51.62940128
  },
  {
    "timestamp": "10/05/24 6:00",
    "demand": 34100.40891,
    "solar_generated": 15.46283503,
    "wind_generated": 73.75448728,
    "marketplace_to_demand": 105.029193,
    "grid_to_demand": 34907.07118,
    "govt_battery_charge": 10.6804673
  },
  {
    "timestamp": "10/05/24 7:00",
    "demand": 36309.37601,
    "solar_generated": 13.95231096,
    "wind_generated": 59.60812459,
    "marketplace_to_demand": 101.2409197,
    "grid_to_demand": 32209.82232,
    "govt_battery_charge": 33.21692122
  },
  {
    "timestamp": "10/05/24 8:00",
    "demand": 32820.61758,
    "solar_generated": 11.93012742,
    "wind_generated": 67.77261353,
    "marketplace_to_demand": 37.66954906,
    "grid_to_demand": 32048.42043,
    "govt_battery_charge": 35.05415903
  },
  {
    "timestamp": "10/05/24 9:00",
    "demand": 33198.13088,
    "solar_generated": 11.78925073,
    "wind_generated": 75.67547982,
    "marketplace_to_demand": 146.1136415,
    "grid_to_demand": 35558.20433,
    "govt_battery_charge": 13.4984107
  },
  {
    "timestamp": "10/05/24 10:00",
    "demand": 35581.54937,
    "solar_generated": 14.49165825,
    "wind_generated": 53.72740695,
    "marketplace_to_demand": 112.2115163,
    "grid_to_demand": 33064.50095,
    "govt_battery_charge": 5.533910074
  },
  {
    "timestamp": "10/05/24 11:00",
    "demand": 34685.61415,
    "solar_generated": 17.11969469,
    "wind_generated": 63.19680028,
    "marketplace_to_demand": 67.70245347,
    "grid_to_demand": 33708.12813,
    "govt_battery_charge": 71.00727413
  },
  {
    "timestamp": "10/05/24 12:00",
    "demand": 31230.15144,
    "solar_generated": 13.25736989,
    "wind_generated": 64.21798546,
    "marketplace_to_demand": 90.45506671,
    "grid_to_demand": 36466.39351,
    "govt_battery_charge": 30.79165775
  },
  {
    "timestamp": "10/05/24 13:00",
    "demand": 32911.83601,
    "solar_generated": 16.08420629,
    "wind_generated": 64.7253217,
    "marketplace_to_demand": 60.72133649,
    "grid_to_demand": 35695.21193,
    "govt_battery_charge": 64.02132411
  },
  {
    "timestamp": "10/05/24 14:00",
    "demand": 35205.51128,
    "solar_generated": 18.28746971,
    "wind_generated": 55.16875934,
    "marketplace_to_demand": 146.0726719,
    "grid_to_demand": 33690.57268,
    "govt_battery_charge": 50.5665819
  },
  {
    "timestamp": "10/05/24 15:00",
    "demand": 29999.45835,
    "solar_generated": 19.27789094,
    "wind_generated": 72.34153936,
    "marketplace_to_demand": 58.75235947,
    "grid_to_demand": 31409.75118,
    "govt_battery_charge": 72.37417689
  },
  {
    "timestamp": "10/05/24 16:00",
    "demand": 31422.62693,
    "solar_generated": 9.882837485,
    "wind_generated": 72.01509933,
    "marketplace_to_demand": 90.31431375,
    "grid_to_demand": 29937.60351,
    "govt_battery_charge": 54.39474512
  },
  {
    "timestamp": "10/05/24 17:00",
    "demand": 35546.2843,
    "solar_generated": 6.838759305,
    "wind_generated": 75.91014515,
    "marketplace_to_demand": 24.62565297,
    "grid_to_demand": 30767.76432,
    "govt_battery_charge": 30.59612387
  },
  {
    "timestamp": "10/05/24 18:00",
    "demand": 33341.55952,
    "solar_generated": 0,
    "wind_generated": 78.95265515,
    "marketplace_to_demand": 45.80294449,
    "grid_to_demand": 34189.63374,
    "govt_battery_charge": 23.3662295
  },
  {
    "timestamp": "10/05/24 19:00",
    "demand": 35486.22811,
    "solar_generated": 0,
    "wind_generated": 64.74817845,
    "marketplace_to_demand": 189.4130902,
    "grid_to_demand": 35478.38943,
    "govt_battery_charge": 98.37454477
  },
  {
    "timestamp": "10/05/24 20:00",
    "demand": 35342.05933,
    "solar_generated": 0,
    "wind_generated": 63.32386614,
    "marketplace_to_demand": 79.19121182,
    "grid_to_demand": 32581.70815,
    "govt_battery_charge": 87.24102531
  },
  {
    "timestamp": "10/05/24 21:00",
    "demand": 34993.21849,
    "solar_generated": 0,
    "wind_generated": 74.56191949,
    "marketplace_to_demand": 59.38434832,
    "grid_to_demand": 34602.05934,
    "govt_battery_charge": 3.682146236
  },
  {
    "timestamp": "10/05/24 22:00",
    "demand": 36411.69371,
    "solar_generated": 0,
    "wind_generated": 79.32398394,
    "marketplace_to_demand": 163.6753331,
    "grid_to_demand": 30438.94899,
    "govt_battery_charge": 62.88482647
  },
  {
    "timestamp": "10/05/24 23:00",
    "demand": 35485.53275,
    "solar_generated": 0,
    "wind_generated": 70.42655362,
    "marketplace_to_demand": 143.4544502,
    "grid_to_demand": 34055.01766,
    "govt_battery_charge": 11.25633958
  },
  {
    "timestamp": "11/05/24 0:00",
    "demand": 34771.94025,
    "solar_generated": 0,
    "wind_generated": 68.33593434,
    "marketplace_to_demand": 113.3534704,
    "grid_to_demand": 34756.88919,
    "govt_battery_charge": 35.27214271
  },
  {
    "timestamp": "11/05/24 1:00",
    "demand": 35875.09129,
    "solar_generated": 0,
    "wind_generated": 56.05534368,
    "marketplace_to_demand": 32.41483272,
    "grid_to_demand": 32341.23448,
    "govt_battery_charge": 44.12037349
  },
  {
    "timestamp": "11/05/24 2:00",
    "demand": 34936.4496,
    "solar_generated": 0,
    "wind_generated": 77.06956906,
    "marketplace_to_demand": 50.1937961,
    "grid_to_demand": 34483.79898,
    "govt_battery_charge": 56.47508267
  },
  {
    "timestamp": "11/05/24 3:00",
    "demand": 35213.30405,
    "solar_generated": 0,
    "wind_generated": 68.57515072,
    "marketplace_to_demand": 170.9559696,
    "grid_to_demand": 32693.78277,
    "govt_battery_charge": 37.64956647
  },
  {
    "timestamp": "11/05/24 4:00",
    "demand": 33983.74732,
    "solar_generated": 0,
    "wind_generated": 57.65388622,
    "marketplace_to_demand": 126.4472205,
    "grid_to_demand": 32269.8934,
    "govt_battery_charge": 95.09678423
  },
  {
    "timestamp": "11/05/24 5:00",
    "demand": 34869.52484,
    "solar_generated": 0,
    "wind_generated": 70.50282066,
    "marketplace_to_demand": 16.09384161,
    "grid_to_demand": 35163.08532,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "11/05/24 6:00",
    "demand": 31957.1173,
    "solar_generated": 7.276792987,
    "wind_generated": 79.2034563,
    "marketplace_to_demand": 7.796014835,
    "grid_to_demand": 30651.02241,
    "govt_battery_charge": 65.35952108
  },
  {
    "timestamp": "11/05/24 7:00",
    "demand": 36619.29371,
    "solar_generated": 18.29496176,
    "wind_generated": 56.89176214,
    "marketplace_to_demand": 182.2412612,
    "grid_to_demand": 35518.58522,
    "govt_battery_charge": 99.54517329
  },
  {
    "timestamp": "11/05/24 8:00",
    "demand": 32779.33644,
    "solar_generated": 19.82137948,
    "wind_generated": 71.83155016,
    "marketplace_to_demand": 78.65440936,
    "grid_to_demand": 36515.29292,
    "govt_battery_charge": 22.1040658
  },
  {
    "timestamp": "11/05/24 9:00",
    "demand": 34001.33734,
    "solar_generated": 18.92253086,
    "wind_generated": 58.67006272,
    "marketplace_to_demand": 176.5883094,
    "grid_to_demand": 31840.18155,
    "govt_battery_charge": 65.14914091
  },
  {
    "timestamp": "11/05/24 10:00",
    "demand": 31964.29054,
    "solar_generated": 21.75840531,
    "wind_generated": 54.13452229,
    "marketplace_to_demand": 38.31739783,
    "grid_to_demand": 33355.95183,
    "govt_battery_charge": 50.89198583
  },
  {
    "timestamp": "11/05/24 11:00",
    "demand": 35509.9406,
    "solar_generated": 10.96431172,
    "wind_generated": 62.22838982,
    "marketplace_to_demand": 190.6854033,
    "grid_to_demand": 34405.62948,
    "govt_battery_charge": 97.76124829
  },
  {
    "timestamp": "11/05/24 12:00",
    "demand": 34485.91424,
    "solar_generated": 17.24157177,
    "wind_generated": 54.93817688,
    "marketplace_to_demand": 161.231188,
    "grid_to_demand": 32324.36389,
    "govt_battery_charge": 82.65535821
  },
  {
    "timestamp": "11/05/24 13:00",
    "demand": 36090.76959,
    "solar_generated": 13.91949927,
    "wind_generated": 77.7994075,
    "marketplace_to_demand": 67.10954578,
    "grid_to_demand": 36327.40251,
    "govt_battery_charge": 74.26614837
  },
  {
    "timestamp": "11/05/24 14:00",
    "demand": 33837.63251,
    "solar_generated": 6.969732554,
    "wind_generated": 77.36272986,
    "marketplace_to_demand": 31.86900174,
    "grid_to_demand": 30847.53717,
    "govt_battery_charge": 15.53213648
  },
  {
    "timestamp": "11/05/24 15:00",
    "demand": 35940.90314,
    "solar_generated": 9.548006268,
    "wind_generated": 59.59512313,
    "marketplace_to_demand": 57.47873551,
    "grid_to_demand": 32109.37268,
    "govt_battery_charge": 99.05626367
  },
  {
    "timestamp": "11/05/24 16:00",
    "demand": 35122.50682,
    "solar_generated": 15.38075243,
    "wind_generated": 58.10804063,
    "marketplace_to_demand": 132.6792509,
    "grid_to_demand": 30982.12456,
    "govt_battery_charge": 48.42949228
  },
  {
    "timestamp": "11/05/24 17:00",
    "demand": 33131.47516,
    "solar_generated": 7.616824004,
    "wind_generated": 66.22237022,
    "marketplace_to_demand": 183.7730726,
    "grid_to_demand": 32309.89405,
    "govt_battery_charge": 80.61591364
  },
  {
    "timestamp": "11/05/24 18:00",
    "demand": 31376.27098,
    "solar_generated": 0,
    "wind_generated": 68.20858272,
    "marketplace_to_demand": 176.3125928,
    "grid_to_demand": 30507.1544,
    "govt_battery_charge": 22.62332749
  },
  {
    "timestamp": "11/05/24 19:00",
    "demand": 30060.12387,
    "solar_generated": 0,
    "wind_generated": 73.83373866,
    "marketplace_to_demand": 59.77860799,
    "grid_to_demand": 35176.58069,
    "govt_battery_charge": 32.8252829
  },
  {
    "timestamp": "11/05/24 20:00",
    "demand": 31727.3208,
    "solar_generated": 0,
    "wind_generated": 74.51423923,
    "marketplace_to_demand": 177.1137309,
    "grid_to_demand": 35575.02986,
    "govt_battery_charge": 36.3136929
  },
  {
    "timestamp": "11/05/24 21:00",
    "demand": 31005.85142,
    "solar_generated": 0,
    "wind_generated": 53.79118928,
    "marketplace_to_demand": 141.9044179,
    "grid_to_demand": 32196.1767,
    "govt_battery_charge": 29.12152392
  },
  {
    "timestamp": "11/05/24 22:00",
    "demand": 31357.16644,
    "solar_generated": 0,
    "wind_generated": 62.48664554,
    "marketplace_to_demand": 31.4464033,
    "grid_to_demand": 34053.01578,
    "govt_battery_charge": 94.17123326
  },
  {
    "timestamp": "11/05/24 23:00",
    "demand": 36154.6569,
    "solar_generated": 0,
    "wind_generated": 72.03070356,
    "marketplace_to_demand": 73.37797443,
    "grid_to_demand": 35856.05072,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "12/05/24 0:00",
    "demand": 32171.6142,
    "solar_generated": 0,
    "wind_generated": 62.42486208,
    "marketplace_to_demand": 9.193262907,
    "grid_to_demand": 31243.382,
    "govt_battery_charge": 63.5491069
  },
  {
    "timestamp": "12/05/24 1:00",
    "demand": 30855.52067,
    "solar_generated": 0,
    "wind_generated": 62.70961906,
    "marketplace_to_demand": 9.093428762,
    "grid_to_demand": 36023.75012,
    "govt_battery_charge": 40.17106103
  },
  {
    "timestamp": "12/05/24 2:00",
    "demand": 35767.36124,
    "solar_generated": 0,
    "wind_generated": 60.90484961,
    "marketplace_to_demand": 195.4485867,
    "grid_to_demand": 32811.51917,
    "govt_battery_charge": 23.19674479
  },
  {
    "timestamp": "12/05/24 3:00",
    "demand": 32828.53634,
    "solar_generated": 0,
    "wind_generated": 54.4791783,
    "marketplace_to_demand": 70.06831327,
    "grid_to_demand": 30320.36059,
    "govt_battery_charge": 34.12321417
  },
  {
    "timestamp": "12/05/24 4:00",
    "demand": 36525.06539,
    "solar_generated": 0,
    "wind_generated": 69.7138274,
    "marketplace_to_demand": 160.038789,
    "grid_to_demand": 33166.51168,
    "govt_battery_charge": 54.05138578
  },
  {
    "timestamp": "12/05/24 5:00",
    "demand": 33582.26684,
    "solar_generated": 0,
    "wind_generated": 75.79958309,
    "marketplace_to_demand": 144.3623744,
    "grid_to_demand": 35593.29542,
    "govt_battery_charge": 61.86734878
  },
  {
    "timestamp": "12/05/24 6:00",
    "demand": 34701.60882,
    "solar_generated": 15.4032456,
    "wind_generated": 72.93539189,
    "marketplace_to_demand": 136.7188868,
    "grid_to_demand": 36142.85154,
    "govt_battery_charge": 13.26007538
  },
  {
    "timestamp": "12/05/24 7:00",
    "demand": 34632.98227,
    "solar_generated": 23.01272677,
    "wind_generated": 79.29448006,
    "marketplace_to_demand": 9.659222009,
    "grid_to_demand": 31871.19802,
    "govt_battery_charge": 62.22069773
  },
  {
    "timestamp": "12/05/24 8:00",
    "demand": 34319.58626,
    "solar_generated": 23.75789924,
    "wind_generated": 76.47833762,
    "marketplace_to_demand": 143.1298362,
    "grid_to_demand": 35113.62786,
    "govt_battery_charge": 34.54313971
  },
  {
    "timestamp": "12/05/24 9:00",
    "demand": 30738.57792,
    "solar_generated": 16.31462629,
    "wind_generated": 67.29383144,
    "marketplace_to_demand": 135.4177837,
    "grid_to_demand": 33587.09691,
    "govt_battery_charge": 16.02057331
  },
  {
    "timestamp": "12/05/24 10:00",
    "demand": 30716.14478,
    "solar_generated": 11.35555711,
    "wind_generated": 59.10777254,
    "marketplace_to_demand": 184.6173623,
    "grid_to_demand": 35671.59419,
    "govt_battery_charge": 66.17711908
  },
  {
    "timestamp": "12/05/24 11:00",
    "demand": 36344.044,
    "solar_generated": 7.168264793,
    "wind_generated": 64.39973795,
    "marketplace_to_demand": 196.2314271,
    "grid_to_demand": 34404.99425,
    "govt_battery_charge": 79.03758192
  },
  {
    "timestamp": "12/05/24 12:00",
    "demand": 30437.01178,
    "solar_generated": 11.06291487,
    "wind_generated": 71.96225017,
    "marketplace_to_demand": 149.0786232,
    "grid_to_demand": 34330.33999,
    "govt_battery_charge": 90.50408813
  },
  {
    "timestamp": "12/05/24 13:00",
    "demand": 34007.30579,
    "solar_generated": 23.80545145,
    "wind_generated": 80.16500502,
    "marketplace_to_demand": 17.75499686,
    "grid_to_demand": 30532.07491,
    "govt_battery_charge": 22.85698518
  },
  {
    "timestamp": "12/05/24 14:00",
    "demand": 32153.99716,
    "solar_generated": 16.31234937,
    "wind_generated": 77.51424973,
    "marketplace_to_demand": 7.492345772,
    "grid_to_demand": 31082.33882,
    "govt_battery_charge": 24.26624991
  },
  {
    "timestamp": "12/05/24 15:00",
    "demand": 32575.11722,
    "solar_generated": 8.887053542,
    "wind_generated": 77.09711245,
    "marketplace_to_demand": 143.9482028,
    "grid_to_demand": 35958.24532,
    "govt_battery_charge": 44.23794093
  },
  {
    "timestamp": "12/05/24 16:00",
    "demand": 30097.56637,
    "solar_generated": 22.65146688,
    "wind_generated": 69.69541967,
    "marketplace_to_demand": 108.5384791,
    "grid_to_demand": 32697.48503,
    "govt_battery_charge": 92.33809715
  },
  {
    "timestamp": "12/05/24 17:00",
    "demand": 35194.93772,
    "solar_generated": 21.67819164,
    "wind_generated": 56.50396849,
    "marketplace_to_demand": 196.2021546,
    "grid_to_demand": 30124.92655,
    "govt_battery_charge": 16.9411374
  },
  {
    "timestamp": "12/05/24 18:00",
    "demand": 33307.36195,
    "solar_generated": 0,
    "wind_generated": 65.53239072,
    "marketplace_to_demand": 74.88985192,
    "grid_to_demand": 30216.00849,
    "govt_battery_charge": 41.762494
  },
  {
    "timestamp": "12/05/24 19:00",
    "demand": 35221.38986,
    "solar_generated": 0,
    "wind_generated": 78.31207535,
    "marketplace_to_demand": 99.6186432,
    "grid_to_demand": 34100.3377,
    "govt_battery_charge": 57.75985055
  },
  {
    "timestamp": "12/05/24 20:00",
    "demand": 34532.52436,
    "solar_generated": 0,
    "wind_generated": 64.92451148,
    "marketplace_to_demand": 161.3919766,
    "grid_to_demand": 36461.48962,
    "govt_battery_charge": 81.48544711
  },
  {
    "timestamp": "12/05/24 21:00",
    "demand": 34317.08745,
    "solar_generated": 0,
    "wind_generated": 80.23088962,
    "marketplace_to_demand": 32.3500009,
    "grid_to_demand": 30741.01841,
    "govt_battery_charge": 62.80474852
  },
  {
    "timestamp": "12/05/24 22:00",
    "demand": 32978.12313,
    "solar_generated": 0,
    "wind_generated": 74.03669471,
    "marketplace_to_demand": 128.7769401,
    "grid_to_demand": 31933.30779,
    "govt_battery_charge": 60.02113853
  },
  {
    "timestamp": "12/05/24 23:00",
    "demand": 33905.66071,
    "solar_generated": 0,
    "wind_generated": 74.44705319,
    "marketplace_to_demand": 2.366102374,
    "grid_to_demand": 34370.56569,
    "govt_battery_charge": 63.2896686
  },
  {
    "timestamp": "13/05/24 0:00",
    "demand": 36053.73429,
    "solar_generated": 0,
    "wind_generated": 65.42094932,
    "marketplace_to_demand": 7.644384471,
    "grid_to_demand": 36495.7726,
    "govt_battery_charge": 55.01885358
  },
  {
    "timestamp": "13/05/24 1:00",
    "demand": 30790.39334,
    "solar_generated": 0,
    "wind_generated": 58.79976431,
    "marketplace_to_demand": 69.13705321,
    "grid_to_demand": 34285.48424,
    "govt_battery_charge": 11.14737699
  },
  {
    "timestamp": "13/05/24 2:00",
    "demand": 30946.35288,
    "solar_generated": 0,
    "wind_generated": 55.07763143,
    "marketplace_to_demand": 33.61545412,
    "grid_to_demand": 30880.23028,
    "govt_battery_charge": 22.86049395
  },
  {
    "timestamp": "13/05/24 3:00",
    "demand": 35128.16276,
    "solar_generated": 0,
    "wind_generated": 58.09839033,
    "marketplace_to_demand": 97.92245933,
    "grid_to_demand": 33062.43981,
    "govt_battery_charge": 16.45650654
  },
  {
    "timestamp": "13/05/24 4:00",
    "demand": 34658.57514,
    "solar_generated": 0,
    "wind_generated": 75.33494408,
    "marketplace_to_demand": 100.8879173,
    "grid_to_demand": 33862.29612,
    "govt_battery_charge": 18.32428686
  },
  {
    "timestamp": "13/05/24 5:00",
    "demand": 34082.99175,
    "solar_generated": 0,
    "wind_generated": 64.66934618,
    "marketplace_to_demand": 6.917115318,
    "grid_to_demand": 31651.27804,
    "govt_battery_charge": 86.17636596
  },
  {
    "timestamp": "13/05/24 6:00",
    "demand": 35568.01728,
    "solar_generated": 17.08412168,
    "wind_generated": 59.16695847,
    "marketplace_to_demand": 35.80768468,
    "grid_to_demand": 31810.04008,
    "govt_battery_charge": 14.36267009
  },
  {
    "timestamp": "13/05/24 7:00",
    "demand": 36347.69986,
    "solar_generated": 20.6010901,
    "wind_generated": 54.75589924,
    "marketplace_to_demand": 112.6215233,
    "grid_to_demand": 35937.03361,
    "govt_battery_charge": 22.28681193
  },
  {
    "timestamp": "13/05/24 8:00",
    "demand": 34227.5834,
    "solar_generated": 10.81548138,
    "wind_generated": 60.26425671,
    "marketplace_to_demand": 27.23823127,
    "grid_to_demand": 32145.24464,
    "govt_battery_charge": 61.86047776
  },
  {
    "timestamp": "13/05/24 9:00",
    "demand": 31619.62245,
    "solar_generated": 18.01626171,
    "wind_generated": 55.54158047,
    "marketplace_to_demand": 174.1794902,
    "grid_to_demand": 34511.25123,
    "govt_battery_charge": 73.73860427
  },
  {
    "timestamp": "13/05/24 10:00",
    "demand": 33566.42602,
    "solar_generated": 8.835311331,
    "wind_generated": 78.36743434,
    "marketplace_to_demand": 158.5751964,
    "grid_to_demand": 35099.41616,
    "govt_battery_charge": 62.81808788
  },
  {
    "timestamp": "13/05/24 11:00",
    "demand": 35063.95311,
    "solar_generated": 15.08737157,
    "wind_generated": 78.71906481,
    "marketplace_to_demand": 7.55037601,
    "grid_to_demand": 34921.24276,
    "govt_battery_charge": 89.14894293
  },
  {
    "timestamp": "13/05/24 12:00",
    "demand": 33493.5374,
    "solar_generated": 20.19817161,
    "wind_generated": 72.71219959,
    "marketplace_to_demand": 27.52398834,
    "grid_to_demand": 35225.12703,
    "govt_battery_charge": 29.63468621
  },
  {
    "timestamp": "13/05/24 13:00",
    "demand": 32065.0983,
    "solar_generated": 19.50683521,
    "wind_generated": 79.03076367,
    "marketplace_to_demand": 44.9626764,
    "grid_to_demand": 31818.22395,
    "govt_battery_charge": 70.75373593
  },
  {
    "timestamp": "13/05/24 14:00",
    "demand": 36039.85458,
    "solar_generated": 18.04937331,
    "wind_generated": 71.10515128,
    "marketplace_to_demand": 167.0751208,
    "grid_to_demand": 35923.55079,
    "govt_battery_charge": 35.5519255
  },
  {
    "timestamp": "13/05/24 15:00",
    "demand": 30964.02247,
    "solar_generated": 14.37479836,
    "wind_generated": 58.35984228,
    "marketplace_to_demand": 6.955563719,
    "grid_to_demand": 30791.69464,
    "govt_battery_charge": 9.789011269
  },
  {
    "timestamp": "13/05/24 16:00",
    "demand": 30489.9135,
    "solar_generated": 16.31091542,
    "wind_generated": 70.00814783,
    "marketplace_to_demand": 22.33460831,
    "grid_to_demand": 36452.48325,
    "govt_battery_charge": 47.01951483
  },
  {
    "timestamp": "13/05/24 17:00",
    "demand": 32969.98306,
    "solar_generated": 18.30866502,
    "wind_generated": 56.59968263,
    "marketplace_to_demand": 137.5371524,
    "grid_to_demand": 34620.1084,
    "govt_battery_charge": 20.59585158
  },
  {
    "timestamp": "13/05/24 18:00",
    "demand": 31720.59336,
    "solar_generated": 0,
    "wind_generated": 56.62367666,
    "marketplace_to_demand": 114.003062,
    "grid_to_demand": 32624.8783,
    "govt_battery_charge": 42.36420339
  },
  {
    "timestamp": "13/05/24 19:00",
    "demand": 31261.61213,
    "solar_generated": 0,
    "wind_generated": 61.34056728,
    "marketplace_to_demand": 55.61461603,
    "grid_to_demand": 32257.92684,
    "govt_battery_charge": 49.63907888
  },
  {
    "timestamp": "13/05/24 20:00",
    "demand": 32521.88837,
    "solar_generated": 0,
    "wind_generated": 73.88628813,
    "marketplace_to_demand": 40.44432737,
    "grid_to_demand": 29992.26025,
    "govt_battery_charge": 17.4635184
  },
  {
    "timestamp": "13/05/24 21:00",
    "demand": 30154.88721,
    "solar_generated": 0,
    "wind_generated": 77.52915713,
    "marketplace_to_demand": 71.27628156,
    "grid_to_demand": 29951.00169,
    "govt_battery_charge": 94.10241163
  },
  {
    "timestamp": "13/05/24 22:00",
    "demand": 36237.8347,
    "solar_generated": 0,
    "wind_generated": 73.07271494,
    "marketplace_to_demand": 67.07778386,
    "grid_to_demand": 35338.25669,
    "govt_battery_charge": 51.39145521
  },
  {
    "timestamp": "13/05/24 23:00",
    "demand": 31313.13443,
    "solar_generated": 0,
    "wind_generated": 66.15391884,
    "marketplace_to_demand": 95.76210565,
    "grid_to_demand": 34975.76963,
    "govt_battery_charge": 92.12861141
  },
  {
    "timestamp": "14/05/24 0:00",
    "demand": 31777.95293,
    "solar_generated": 0,
    "wind_generated": 59.93512454,
    "marketplace_to_demand": 142.1244464,
    "grid_to_demand": 32661.3415,
    "govt_battery_charge": 30.04071844
  },
  {
    "timestamp": "14/05/24 1:00",
    "demand": 36068.6825,
    "solar_generated": 0,
    "wind_generated": 55.80695616,
    "marketplace_to_demand": 113.2235424,
    "grid_to_demand": 35854.53646,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "14/05/24 2:00",
    "demand": 34471.2002,
    "solar_generated": 0,
    "wind_generated": 64.29352521,
    "marketplace_to_demand": 68.82301024,
    "grid_to_demand": 31827.3685,
    "govt_battery_charge": 43.20426761
  },
  {
    "timestamp": "14/05/24 3:00",
    "demand": 30555.46853,
    "solar_generated": 0,
    "wind_generated": 63.53798802,
    "marketplace_to_demand": 3.156592395,
    "grid_to_demand": 30406.23505,
    "govt_battery_charge": 4.065170428
  },
  {
    "timestamp": "14/05/24 4:00",
    "demand": 36384.06049,
    "solar_generated": 0,
    "wind_generated": 73.24863822,
    "marketplace_to_demand": 25.74171675,
    "grid_to_demand": 35887.14535,
    "govt_battery_charge": 88.4213812
  },
  {
    "timestamp": "14/05/24 5:00",
    "demand": 35853.14592,
    "solar_generated": 0,
    "wind_generated": 63.79186515,
    "marketplace_to_demand": 180.8654397,
    "grid_to_demand": 34137.42304,
    "govt_battery_charge": 53.21059027
  },
  {
    "timestamp": "14/05/24 6:00",
    "demand": 30393.26611,
    "solar_generated": 10.37464726,
    "wind_generated": 70.22999109,
    "marketplace_to_demand": 133.2876905,
    "grid_to_demand": 34656.10061,
    "govt_battery_charge": 35.09166391
  },
  {
    "timestamp": "14/05/24 7:00",
    "demand": 30951.62241,
    "solar_generated": 10.77145576,
    "wind_generated": 66.17612412,
    "marketplace_to_demand": 19.91597997,
    "grid_to_demand": 31219.99073,
    "govt_battery_charge": 79.01618842
  },
  {
    "timestamp": "14/05/24 8:00",
    "demand": 36505.68759,
    "solar_generated": 11.03998555,
    "wind_generated": 71.01642124,
    "marketplace_to_demand": 113.6090464,
    "grid_to_demand": 36550.90405,
    "govt_battery_charge": 51.63739896
  },
  {
    "timestamp": "14/05/24 9:00",
    "demand": 36111.42427,
    "solar_generated": 9.589762093,
    "wind_generated": 58.69487477,
    "marketplace_to_demand": 14.61191218,
    "grid_to_demand": 33388.80041,
    "govt_battery_charge": 56.95464987
  },
  {
    "timestamp": "14/05/24 10:00",
    "demand": 31061.80933,
    "solar_generated": 12.30865596,
    "wind_generated": 64.26441664,
    "marketplace_to_demand": 53.26677366,
    "grid_to_demand": 32275.69682,
    "govt_battery_charge": 17.73629871
  },
  {
    "timestamp": "14/05/24 11:00",
    "demand": 35478.28035,
    "solar_generated": 11.96428693,
    "wind_generated": 60.18047406,
    "marketplace_to_demand": 155.8694572,
    "grid_to_demand": 32572.30684,
    "govt_battery_charge": 74.53297421
  },
  {
    "timestamp": "14/05/24 12:00",
    "demand": 36575.41724,
    "solar_generated": 18.17537591,
    "wind_generated": 71.05980789,
    "marketplace_to_demand": 170.8339399,
    "grid_to_demand": 36007.38124,
    "govt_battery_charge": 88.68850122
  },
  {
    "timestamp": "14/05/24 13:00",
    "demand": 34148.59506,
    "solar_generated": 9.241861904,
    "wind_generated": 73.49068717,
    "marketplace_to_demand": 38.41895742,
    "grid_to_demand": 32389.37238,
    "govt_battery_charge": 1.031084387
  },
  {
    "timestamp": "14/05/24 14:00",
    "demand": 35752.9311,
    "solar_generated": 14.93094845,
    "wind_generated": 54.22907777,
    "marketplace_to_demand": 153.5328023,
    "grid_to_demand": 30491.44882,
    "govt_battery_charge": 72.29400955
  },
  {
    "timestamp": "14/05/24 15:00",
    "demand": 34605.84499,
    "solar_generated": 17.58936075,
    "wind_generated": 59.52222877,
    "marketplace_to_demand": 43.63657007,
    "grid_to_demand": 32667.89003,
    "govt_battery_charge": 64.03180378
  },
  {
    "timestamp": "14/05/24 16:00",
    "demand": 36252.19117,
    "solar_generated": 17.61604827,
    "wind_generated": 62.76164782,
    "marketplace_to_demand": 34.96181757,
    "grid_to_demand": 35699.31199,
    "govt_battery_charge": 79.94669786
  },
  {
    "timestamp": "14/05/24 17:00",
    "demand": 35691.01019,
    "solar_generated": 23.30118163,
    "wind_generated": 67.42603601,
    "marketplace_to_demand": 116.4691344,
    "grid_to_demand": 30839.93178,
    "govt_battery_charge": 36.15309754
  },
  {
    "timestamp": "14/05/24 18:00",
    "demand": 30148.69745,
    "solar_generated": 0,
    "wind_generated": 74.5939757,
    "marketplace_to_demand": 13.15908019,
    "grid_to_demand": 30720.38957,
    "govt_battery_charge": 88.61569019
  },
  {
    "timestamp": "14/05/24 19:00",
    "demand": 31181.54778,
    "solar_generated": 0,
    "wind_generated": 55.1168575,
    "marketplace_to_demand": 84.47382835,
    "grid_to_demand": 31205.00323,
    "govt_battery_charge": 23.98027663
  },
  {
    "timestamp": "14/05/24 20:00",
    "demand": 34417.46613,
    "solar_generated": 0,
    "wind_generated": 58.98304088,
    "marketplace_to_demand": 122.510252,
    "grid_to_demand": 35233.51277,
    "govt_battery_charge": 64.53247686
  },
  {
    "timestamp": "14/05/24 21:00",
    "demand": 33850.43243,
    "solar_generated": 0,
    "wind_generated": 54.62585237,
    "marketplace_to_demand": 166.5208699,
    "grid_to_demand": 31423.70937,
    "govt_battery_charge": 21.87002252
  },
  {
    "timestamp": "14/05/24 22:00",
    "demand": 34541.82409,
    "solar_generated": 0,
    "wind_generated": 70.48435539,
    "marketplace_to_demand": 155.2397871,
    "grid_to_demand": 32852.73153,
    "govt_battery_charge": 4.353736914
  },
  {
    "timestamp": "14/05/24 23:00",
    "demand": 36188.26978,
    "solar_generated": 0,
    "wind_generated": 65.95442211,
    "marketplace_to_demand": 117.2344081,
    "grid_to_demand": 34415.52212,
    "govt_battery_charge": 71.26740346
  },
  {
    "timestamp": "15/05/24 0:00",
    "demand": 34400.01396,
    "solar_generated": 0,
    "wind_generated": 64.08957021,
    "marketplace_to_demand": 70.98384396,
    "grid_to_demand": 34218.64141,
    "govt_battery_charge": 62.21134472
  },
  {
    "timestamp": "15/05/24 1:00",
    "demand": 35770.67434,
    "solar_generated": 0,
    "wind_generated": 73.3925414,
    "marketplace_to_demand": 62.35249819,
    "grid_to_demand": 32383.62789,
    "govt_battery_charge": 28.73306395
  },
  {
    "timestamp": "15/05/24 2:00",
    "demand": 32561.76897,
    "solar_generated": 0,
    "wind_generated": 67.38465304,
    "marketplace_to_demand": 118.0415738,
    "grid_to_demand": 34735.18281,
    "govt_battery_charge": 98.88873862
  },
  {
    "timestamp": "15/05/24 3:00",
    "demand": 33043.99623,
    "solar_generated": 0,
    "wind_generated": 71.47436478,
    "marketplace_to_demand": 51.12583687,
    "grid_to_demand": 32028.62447,
    "govt_battery_charge": 93.86682064
  },
  {
    "timestamp": "15/05/24 4:00",
    "demand": 33792.46574,
    "solar_generated": 0,
    "wind_generated": 62.53791265,
    "marketplace_to_demand": 168.939826,
    "grid_to_demand": 36211.5186,
    "govt_battery_charge": 34.02186234
  },
  {
    "timestamp": "15/05/24 5:00",
    "demand": 33583.05869,
    "solar_generated": 0,
    "wind_generated": 60.56144311,
    "marketplace_to_demand": 6.904409646,
    "grid_to_demand": 32203.67188,
    "govt_battery_charge": 70.27168717
  },
  {
    "timestamp": "15/05/24 6:00",
    "demand": 35595.11908,
    "solar_generated": 8.6596575,
    "wind_generated": 61.40937538,
    "marketplace_to_demand": 25.18069244,
    "grid_to_demand": 35129.2032,
    "govt_battery_charge": 61.88605406
  },
  {
    "timestamp": "15/05/24 7:00",
    "demand": 35222.56934,
    "solar_generated": 19.85705833,
    "wind_generated": 77.90906862,
    "marketplace_to_demand": 132.2944745,
    "grid_to_demand": 32412.84183,
    "govt_battery_charge": 46.05921379
  },
  {
    "timestamp": "15/05/24 8:00",
    "demand": 31916.08764,
    "solar_generated": 15.46886866,
    "wind_generated": 62.34296808,
    "marketplace_to_demand": 79.8966049,
    "grid_to_demand": 33250.98533,
    "govt_battery_charge": 24.75219471
  },
  {
    "timestamp": "15/05/24 9:00",
    "demand": 30513.73257,
    "solar_generated": 21.19027259,
    "wind_generated": 63.40264978,
    "marketplace_to_demand": 110.2335576,
    "grid_to_demand": 32570.2241,
    "govt_battery_charge": 67.3306927
  },
  {
    "timestamp": "15/05/24 10:00",
    "demand": 30278.85465,
    "solar_generated": 6.367498242,
    "wind_generated": 69.67053936,
    "marketplace_to_demand": 20.04009474,
    "grid_to_demand": 33367.79848,
    "govt_battery_charge": 57.12814985
  },
  {
    "timestamp": "15/05/24 11:00",
    "demand": 33146.69557,
    "solar_generated": 20.31137445,
    "wind_generated": 77.8019394,
    "marketplace_to_demand": 55.95532598,
    "grid_to_demand": 32461.95145,
    "govt_battery_charge": 92.7894601
  },
  {
    "timestamp": "15/05/24 12:00",
    "demand": 36605.89121,
    "solar_generated": 23.90002372,
    "wind_generated": 74.71211558,
    "marketplace_to_demand": 176.6814157,
    "grid_to_demand": 33750.27781,
    "govt_battery_charge": 71.95266239
  },
  {
    "timestamp": "15/05/24 13:00",
    "demand": 30557.92664,
    "solar_generated": 11.99157027,
    "wind_generated": 58.79693403,
    "marketplace_to_demand": 35.53653759,
    "grid_to_demand": 36001.06818,
    "govt_battery_charge": 96.18837273
  },
  {
    "timestamp": "15/05/24 14:00",
    "demand": 30284.57074,
    "solar_generated": 14.74035092,
    "wind_generated": 79.76051469,
    "marketplace_to_demand": 128.379038,
    "grid_to_demand": 33858.77362,
    "govt_battery_charge": 51.47553786
  },
  {
    "timestamp": "15/05/24 15:00",
    "demand": 36184.86016,
    "solar_generated": 17.31634275,
    "wind_generated": 64.71016411,
    "marketplace_to_demand": 124.7918444,
    "grid_to_demand": 34182.6488,
    "govt_battery_charge": 97.72646996
  },
  {
    "timestamp": "15/05/24 16:00",
    "demand": 33678.35493,
    "solar_generated": 12.8435026,
    "wind_generated": 66.51613274,
    "marketplace_to_demand": 109.6930561,
    "grid_to_demand": 34721.17932,
    "govt_battery_charge": 63.51354337
  },
  {
    "timestamp": "15/05/24 17:00",
    "demand": 31910.68111,
    "solar_generated": 6.743666858,
    "wind_generated": 61.26285381,
    "marketplace_to_demand": 108.3983594,
    "grid_to_demand": 32511.8767,
    "govt_battery_charge": 98.0773315
  },
  {
    "timestamp": "15/05/24 18:00",
    "demand": 35610.21177,
    "solar_generated": 0,
    "wind_generated": 55.33306578,
    "marketplace_to_demand": 117.3670977,
    "grid_to_demand": 35437.18865,
    "govt_battery_charge": 30.72067862
  },
  {
    "timestamp": "15/05/24 19:00",
    "demand": 36087.08909,
    "solar_generated": 0,
    "wind_generated": 71.17562201,
    "marketplace_to_demand": 187.9235032,
    "grid_to_demand": 34916.60416,
    "govt_battery_charge": 1.825550759
  },
  {
    "timestamp": "15/05/24 20:00",
    "demand": 33883.44915,
    "solar_generated": 0,
    "wind_generated": 67.16012458,
    "marketplace_to_demand": 38.34824307,
    "grid_to_demand": 31324.44243,
    "govt_battery_charge": 2.3042267
  },
  {
    "timestamp": "15/05/24 21:00",
    "demand": 33838.61237,
    "solar_generated": 0,
    "wind_generated": 68.51256202,
    "marketplace_to_demand": 92.46949629,
    "grid_to_demand": 35123.84517,
    "govt_battery_charge": 28.42322417
  },
  {
    "timestamp": "15/05/24 22:00",
    "demand": 35964.4949,
    "solar_generated": 0,
    "wind_generated": 74.86204053,
    "marketplace_to_demand": 104.387645,
    "grid_to_demand": 34441.86919,
    "govt_battery_charge": 51.81326991
  },
  {
    "timestamp": "15/05/24 23:00",
    "demand": 33884.05986,
    "solar_generated": 0,
    "wind_generated": 77.111504,
    "marketplace_to_demand": 24.03834423,
    "grid_to_demand": 33984.97141,
    "govt_battery_charge": 71.63753082
  },
  {
    "timestamp": "16/05/24 0:00",
    "demand": 31996.96936,
    "solar_generated": 0,
    "wind_generated": 62.94428989,
    "marketplace_to_demand": 128.5862965,
    "grid_to_demand": 31839.07446,
    "govt_battery_charge": 31.85730405
  },
  {
    "timestamp": "16/05/24 1:00",
    "demand": 36590.81186,
    "solar_generated": 0,
    "wind_generated": 77.17434303,
    "marketplace_to_demand": 117.5623657,
    "grid_to_demand": 33040.66532,
    "govt_battery_charge": 44.98272741
  },
  {
    "timestamp": "16/05/24 2:00",
    "demand": 32657.14337,
    "solar_generated": 0,
    "wind_generated": 62.72236906,
    "marketplace_to_demand": 47.36871766,
    "grid_to_demand": 35246.4783,
    "govt_battery_charge": 48.89689909
  },
  {
    "timestamp": "16/05/24 3:00",
    "demand": 33809.86781,
    "solar_generated": 0,
    "wind_generated": 62.73074026,
    "marketplace_to_demand": 22.1374264,
    "grid_to_demand": 34445.10918,
    "govt_battery_charge": 45.41943601
  },
  {
    "timestamp": "16/05/24 4:00",
    "demand": 34773.5989,
    "solar_generated": 0,
    "wind_generated": 67.88827145,
    "marketplace_to_demand": 76.38051961,
    "grid_to_demand": 33499.75467,
    "govt_battery_charge": 8.690862548
  },
  {
    "timestamp": "16/05/24 5:00",
    "demand": 31114.6117,
    "solar_generated": 0,
    "wind_generated": 77.49138386,
    "marketplace_to_demand": 64.48648286,
    "grid_to_demand": 33249.11328,
    "govt_battery_charge": 8.956346855
  },
  {
    "timestamp": "16/05/24 6:00",
    "demand": 30987.91606,
    "solar_generated": 7.714430566,
    "wind_generated": 76.13525448,
    "marketplace_to_demand": 127.9933266,
    "grid_to_demand": 35708.826,
    "govt_battery_charge": 4.281598945
  },
  {
    "timestamp": "16/05/24 7:00",
    "demand": 32482.11904,
    "solar_generated": 13.48993875,
    "wind_generated": 57.59266774,
    "marketplace_to_demand": 23.56422654,
    "grid_to_demand": 34309.52513,
    "govt_battery_charge": 39.06147252
  },
  {
    "timestamp": "16/05/24 8:00",
    "demand": 33548.56761,
    "solar_generated": 23.9944429,
    "wind_generated": 76.83923486,
    "marketplace_to_demand": 74.55271655,
    "grid_to_demand": 36338.28556,
    "govt_battery_charge": 24.45252076
  },
  {
    "timestamp": "16/05/24 9:00",
    "demand": 32217.70565,
    "solar_generated": 9.531050356,
    "wind_generated": 75.47065803,
    "marketplace_to_demand": 63.07148926,
    "grid_to_demand": 33426.01482,
    "govt_battery_charge": 76.28392405
  },
  {
    "timestamp": "16/05/24 10:00",
    "demand": 33137.23275,
    "solar_generated": 21.88540555,
    "wind_generated": 68.18741306,
    "marketplace_to_demand": 89.49751036,
    "grid_to_demand": 32416.90589,
    "govt_battery_charge": 51.87540719
  },
  {
    "timestamp": "16/05/24 11:00",
    "demand": 30914.34502,
    "solar_generated": 13.46153048,
    "wind_generated": 78.71646614,
    "marketplace_to_demand": 136.1284573,
    "grid_to_demand": 33062.82846,
    "govt_battery_charge": 10.91476231
  },
  {
    "timestamp": "16/05/24 12:00",
    "demand": 35807.45761,
    "solar_generated": 20.25753277,
    "wind_generated": 63.55416904,
    "marketplace_to_demand": 62.84584411,
    "grid_to_demand": 34308.06019,
    "govt_battery_charge": 94.88344729
  },
  {
    "timestamp": "16/05/24 13:00",
    "demand": 33454.76695,
    "solar_generated": 21.91387917,
    "wind_generated": 76.17700568,
    "marketplace_to_demand": 93.8703949,
    "grid_to_demand": 30081.35208,
    "govt_battery_charge": 18.57783087
  },
  {
    "timestamp": "16/05/24 14:00",
    "demand": 30183.10865,
    "solar_generated": 22.84112677,
    "wind_generated": 63.60980612,
    "marketplace_to_demand": 95.32454128,
    "grid_to_demand": 35192.31569,
    "govt_battery_charge": 67.60098623
  },
  {
    "timestamp": "16/05/24 15:00",
    "demand": 31595.49441,
    "solar_generated": 12.64052419,
    "wind_generated": 76.49387118,
    "marketplace_to_demand": 24.98309965,
    "grid_to_demand": 35860.77263,
    "govt_battery_charge": 72.12357053
  },
  {
    "timestamp": "16/05/24 16:00",
    "demand": 36297.99655,
    "solar_generated": 9.78518069,
    "wind_generated": 61.34276717,
    "marketplace_to_demand": 188.3827291,
    "grid_to_demand": 34285.74196,
    "govt_battery_charge": 4.122679011
  },
  {
    "timestamp": "16/05/24 17:00",
    "demand": 35124.51358,
    "solar_generated": 11.5740018,
    "wind_generated": 72.74351658,
    "marketplace_to_demand": 113.4212864,
    "grid_to_demand": 35888.13226,
    "govt_battery_charge": 82.98548469
  },
  {
    "timestamp": "16/05/24 18:00",
    "demand": 35254.24888,
    "solar_generated": 0,
    "wind_generated": 79.2781161,
    "marketplace_to_demand": 176.6267605,
    "grid_to_demand": 35844.88388,
    "govt_battery_charge": 56.37887631
  },
  {
    "timestamp": "16/05/24 19:00",
    "demand": 33444.48525,
    "solar_generated": 0,
    "wind_generated": 65.97341788,
    "marketplace_to_demand": 22.05411773,
    "grid_to_demand": 34326.36989,
    "govt_battery_charge": 97.39609886
  },
  {
    "timestamp": "16/05/24 20:00",
    "demand": 32642.79636,
    "solar_generated": 0,
    "wind_generated": 74.51345019,
    "marketplace_to_demand": 97.02295328,
    "grid_to_demand": 33298.39088,
    "govt_battery_charge": 51.93059369
  },
  {
    "timestamp": "16/05/24 21:00",
    "demand": 35725.94641,
    "solar_generated": 0,
    "wind_generated": 77.3761366,
    "marketplace_to_demand": 85.0291717,
    "grid_to_demand": 34240.2238,
    "govt_battery_charge": 51.0015718
  },
  {
    "timestamp": "16/05/24 22:00",
    "demand": 31239.71034,
    "solar_generated": 0,
    "wind_generated": 73.06974983,
    "marketplace_to_demand": 18.87283292,
    "grid_to_demand": 31750.42061,
    "govt_battery_charge": 88.57914012
  },
  {
    "timestamp": "16/05/24 23:00",
    "demand": 30589.28417,
    "solar_generated": 0,
    "wind_generated": 64.31254768,
    "marketplace_to_demand": 147.3774146,
    "grid_to_demand": 31790.70216,
    "govt_battery_charge": 77.68770603
  },
  {
    "timestamp": "17/05/24 0:00",
    "demand": 33776.54766,
    "solar_generated": 0,
    "wind_generated": 56.21478608,
    "marketplace_to_demand": 114.8996794,
    "grid_to_demand": 34132.85011,
    "govt_battery_charge": 18.58618162
  },
  {
    "timestamp": "17/05/24 1:00",
    "demand": 30449.06666,
    "solar_generated": 0,
    "wind_generated": 55.89060445,
    "marketplace_to_demand": 152.2263721,
    "grid_to_demand": 36187.81227,
    "govt_battery_charge": 86.6394288
  },
  {
    "timestamp": "17/05/24 2:00",
    "demand": 31411.31876,
    "solar_generated": 0,
    "wind_generated": 62.55148056,
    "marketplace_to_demand": 31.63481543,
    "grid_to_demand": 35014.31861,
    "govt_battery_charge": 21.95271759
  },
  {
    "timestamp": "17/05/24 3:00",
    "demand": 34960.24448,
    "solar_generated": 0,
    "wind_generated": 77.00425126,
    "marketplace_to_demand": 20.17320159,
    "grid_to_demand": 33617.91412,
    "govt_battery_charge": 8.572505997
  },
  {
    "timestamp": "17/05/24 4:00",
    "demand": 33731.34031,
    "solar_generated": 0,
    "wind_generated": 74.6358755,
    "marketplace_to_demand": 195.248984,
    "grid_to_demand": 36383.81771,
    "govt_battery_charge": 62.55996508
  },
  {
    "timestamp": "17/05/24 5:00",
    "demand": 35780.32852,
    "solar_generated": 0,
    "wind_generated": 57.82369472,
    "marketplace_to_demand": 32.40116613,
    "grid_to_demand": 34361.80477,
    "govt_battery_charge": 57.5973253
  },
  {
    "timestamp": "17/05/24 6:00",
    "demand": 30939.19226,
    "solar_generated": 6.68952564,
    "wind_generated": 78.16037264,
    "marketplace_to_demand": 80.57496432,
    "grid_to_demand": 34360.57712,
    "govt_battery_charge": 65.29189108
  },
  {
    "timestamp": "17/05/24 7:00",
    "demand": 33761.03045,
    "solar_generated": 13.93946074,
    "wind_generated": 80.34504264,
    "marketplace_to_demand": 90.11715395,
    "grid_to_demand": 33427.95467,
    "govt_battery_charge": 60.3412609
  },
  {
    "timestamp": "17/05/24 8:00",
    "demand": 31938.63988,
    "solar_generated": 7.902928549,
    "wind_generated": 74.74697921,
    "marketplace_to_demand": 130.1224762,
    "grid_to_demand": 30864.94222,
    "govt_battery_charge": 37.41450012
  },
  {
    "timestamp": "17/05/24 9:00",
    "demand": 30445.00577,
    "solar_generated": 16.03251735,
    "wind_generated": 56.5518101,
    "marketplace_to_demand": 181.1650254,
    "grid_to_demand": 33123.03849,
    "govt_battery_charge": 24.18516747
  },
  {
    "timestamp": "17/05/24 10:00",
    "demand": 33182.41637,
    "solar_generated": 20.67730426,
    "wind_generated": 57.38785458,
    "marketplace_to_demand": 50.05481011,
    "grid_to_demand": 31022.74309,
    "govt_battery_charge": 27.99626246
  },
  {
    "timestamp": "17/05/24 11:00",
    "demand": 36511.07396,
    "solar_generated": 19.46531226,
    "wind_generated": 73.62032696,
    "marketplace_to_demand": 17.58912674,
    "grid_to_demand": 33804.54719,
    "govt_battery_charge": 67.39887927
  },
  {
    "timestamp": "17/05/24 12:00",
    "demand": 31300.61782,
    "solar_generated": 9.53687988,
    "wind_generated": 63.07242818,
    "marketplace_to_demand": 178.0759832,
    "grid_to_demand": 36535.63059,
    "govt_battery_charge": 39.52787057
  },
  {
    "timestamp": "17/05/24 13:00",
    "demand": 32181.26738,
    "solar_generated": 15.46091121,
    "wind_generated": 60.19668962,
    "marketplace_to_demand": 40.9610727,
    "grid_to_demand": 32697.13088,
    "govt_battery_charge": 47.32357117
  },
  {
    "timestamp": "17/05/24 14:00",
    "demand": 31862.96866,
    "solar_generated": 11.46052598,
    "wind_generated": 59.01387592,
    "marketplace_to_demand": 141.5520666,
    "grid_to_demand": 32308.38226,
    "govt_battery_charge": 55.58865983
  },
  {
    "timestamp": "17/05/24 15:00",
    "demand": 31737.79465,
    "solar_generated": 15.39391532,
    "wind_generated": 66.36084021,
    "marketplace_to_demand": 198.6795413,
    "grid_to_demand": 36304.98162,
    "govt_battery_charge": 89.91990061
  },
  {
    "timestamp": "17/05/24 16:00",
    "demand": 34448.59094,
    "solar_generated": 8.800594948,
    "wind_generated": 62.30511075,
    "marketplace_to_demand": 15.51771468,
    "grid_to_demand": 36046.47465,
    "govt_battery_charge": 73.78628047
  },
  {
    "timestamp": "17/05/24 17:00",
    "demand": 32617.31025,
    "solar_generated": 6.432309694,
    "wind_generated": 72.92824116,
    "marketplace_to_demand": 54.5395963,
    "grid_to_demand": 31283.18458,
    "govt_battery_charge": 24.35712411
  },
  {
    "timestamp": "17/05/24 18:00",
    "demand": 33518.77225,
    "solar_generated": 0,
    "wind_generated": 63.48640319,
    "marketplace_to_demand": 91.82052813,
    "grid_to_demand": 33298.33962,
    "govt_battery_charge": 69.49342211
  },
  {
    "timestamp": "17/05/24 19:00",
    "demand": 30116.15168,
    "solar_generated": 0,
    "wind_generated": 74.28084898,
    "marketplace_to_demand": 134.5688789,
    "grid_to_demand": 32529.29314,
    "govt_battery_charge": 61.13309348
  },
  {
    "timestamp": "17/05/24 20:00",
    "demand": 35395.05183,
    "solar_generated": 0,
    "wind_generated": 66.73890687,
    "marketplace_to_demand": 58.32383925,
    "grid_to_demand": 31753.58413,
    "govt_battery_charge": 65.79551596
  },
  {
    "timestamp": "17/05/24 21:00",
    "demand": 34029.88699,
    "solar_generated": 0,
    "wind_generated": 78.68415565,
    "marketplace_to_demand": 99.43223531,
    "grid_to_demand": 30082.44404,
    "govt_battery_charge": 23.89009622
  },
  {
    "timestamp": "17/05/24 22:00",
    "demand": 36122.18159,
    "solar_generated": 0,
    "wind_generated": 77.68251865,
    "marketplace_to_demand": 27.29290576,
    "grid_to_demand": 30692.15593,
    "govt_battery_charge": 31.24356485
  },
  {
    "timestamp": "17/05/24 23:00",
    "demand": 35676.91917,
    "solar_generated": 0,
    "wind_generated": 64.38593934,
    "marketplace_to_demand": 10.55306065,
    "grid_to_demand": 35953.86758,
    "govt_battery_charge": 76.60717811
  },
  {
    "timestamp": "18/05/24 0:00",
    "demand": 32100.15167,
    "solar_generated": 0,
    "wind_generated": 78.22544681,
    "marketplace_to_demand": 150.7440052,
    "grid_to_demand": 30097.25776,
    "govt_battery_charge": 35.85440821
  },
  {
    "timestamp": "18/05/24 1:00",
    "demand": 31529.07745,
    "solar_generated": 0,
    "wind_generated": 59.21472864,
    "marketplace_to_demand": 150.4308954,
    "grid_to_demand": 30829.81078,
    "govt_battery_charge": 67.56063489
  },
  {
    "timestamp": "18/05/24 2:00",
    "demand": 35089.02257,
    "solar_generated": 0,
    "wind_generated": 62.81301738,
    "marketplace_to_demand": 116.9823168,
    "grid_to_demand": 31643.92334,
    "govt_battery_charge": 66.83041259
  },
  {
    "timestamp": "18/05/24 3:00",
    "demand": 31291.1047,
    "solar_generated": 0,
    "wind_generated": 64.657676,
    "marketplace_to_demand": 117.4040095,
    "grid_to_demand": 30207.61381,
    "govt_battery_charge": 12.13545182
  },
  {
    "timestamp": "18/05/24 4:00",
    "demand": 33733.10918,
    "solar_generated": 0,
    "wind_generated": 73.41424406,
    "marketplace_to_demand": 65.70047776,
    "grid_to_demand": 34732.0358,
    "govt_battery_charge": 67.6871909
  },
  {
    "timestamp": "18/05/24 5:00",
    "demand": 33800.22963,
    "solar_generated": 0,
    "wind_generated": 58.93067826,
    "marketplace_to_demand": 135.8432387,
    "grid_to_demand": 33621.87892,
    "govt_battery_charge": 23.27658424
  },
  {
    "timestamp": "18/05/24 6:00",
    "demand": 35218.65519,
    "solar_generated": 7.657843407,
    "wind_generated": 58.37726741,
    "marketplace_to_demand": 44.35082795,
    "grid_to_demand": 36382.781,
    "govt_battery_charge": 85.48673247
  },
  {
    "timestamp": "18/05/24 7:00",
    "demand": 35029.57717,
    "solar_generated": 21.21042631,
    "wind_generated": 58.71014322,
    "marketplace_to_demand": 92.86483834,
    "grid_to_demand": 31426.05161,
    "govt_battery_charge": 83.92952958
  },
  {
    "timestamp": "18/05/24 8:00",
    "demand": 32843.56752,
    "solar_generated": 12.48375983,
    "wind_generated": 58.84357952,
    "marketplace_to_demand": 172.274475,
    "grid_to_demand": 33110.4152,
    "govt_battery_charge": 89.89058245
  },
  {
    "timestamp": "18/05/24 9:00",
    "demand": 34289.08141,
    "solar_generated": 6.138739319,
    "wind_generated": 65.99239362,
    "marketplace_to_demand": 186.3952737,
    "grid_to_demand": 30437.10356,
    "govt_battery_charge": 3.462116119
  },
  {
    "timestamp": "18/05/24 10:00",
    "demand": 36515.54093,
    "solar_generated": 13.67688571,
    "wind_generated": 75.48656673,
    "marketplace_to_demand": 68.3644862,
    "grid_to_demand": 34295.04097,
    "govt_battery_charge": 13.24481108
  },
  {
    "timestamp": "18/05/24 11:00",
    "demand": 34541.27982,
    "solar_generated": 10.97606405,
    "wind_generated": 56.07957331,
    "marketplace_to_demand": 117.8441616,
    "grid_to_demand": 30509.17492,
    "govt_battery_charge": 2.696292131
  },
  {
    "timestamp": "18/05/24 12:00",
    "demand": 34102.42133,
    "solar_generated": 16.20088818,
    "wind_generated": 75.86610562,
    "marketplace_to_demand": 101.1838668,
    "grid_to_demand": 32705.03848,
    "govt_battery_charge": 70.9718666
  },
  {
    "timestamp": "18/05/24 13:00",
    "demand": 34934.17979,
    "solar_generated": 20.56891415,
    "wind_generated": 66.39063105,
    "marketplace_to_demand": 157.6156952,
    "grid_to_demand": 33052.6168,
    "govt_battery_charge": 13.91493669
  },
  {
    "timestamp": "18/05/24 14:00",
    "demand": 31275.80809,
    "solar_generated": 17.02974357,
    "wind_generated": 67.14075931,
    "marketplace_to_demand": 131.0350424,
    "grid_to_demand": 35489.82017,
    "govt_battery_charge": 46.03995257
  },
  {
    "timestamp": "18/05/24 15:00",
    "demand": 33637.46877,
    "solar_generated": 6.886306442,
    "wind_generated": 65.97703619,
    "marketplace_to_demand": 178.4755222,
    "grid_to_demand": 35793.14441,
    "govt_battery_charge": 44.32628638
  },
  {
    "timestamp": "18/05/24 16:00",
    "demand": 32292.09909,
    "solar_generated": 10.69436969,
    "wind_generated": 64.80737562,
    "marketplace_to_demand": 164.8411141,
    "grid_to_demand": 32121.43017,
    "govt_battery_charge": 19.74179876
  },
  {
    "timestamp": "18/05/24 17:00",
    "demand": 31256.60011,
    "solar_generated": 15.66919514,
    "wind_generated": 79.77459481,
    "marketplace_to_demand": 4.540069843,
    "grid_to_demand": 36085.10552,
    "govt_battery_charge": 26.1193597
  },
  {
    "timestamp": "18/05/24 18:00",
    "demand": 30466.36379,
    "solar_generated": 0,
    "wind_generated": 78.54002178,
    "marketplace_to_demand": 112.0596685,
    "grid_to_demand": 34046.17444,
    "govt_battery_charge": 22.45057663
  },
  {
    "timestamp": "18/05/24 19:00",
    "demand": 36010.2807,
    "solar_generated": 0,
    "wind_generated": 63.30645553,
    "marketplace_to_demand": 54.50726652,
    "grid_to_demand": 35610.63399,
    "govt_battery_charge": 99.66999195
  },
  {
    "timestamp": "18/05/24 20:00",
    "demand": 35298.00507,
    "solar_generated": 0,
    "wind_generated": 55.60427025,
    "marketplace_to_demand": 7.33854005,
    "grid_to_demand": 32409.51282,
    "govt_battery_charge": 36.17240995
  },
  {
    "timestamp": "18/05/24 21:00",
    "demand": 35829.7924,
    "solar_generated": 0,
    "wind_generated": 66.71094844,
    "marketplace_to_demand": 76.15277081,
    "grid_to_demand": 33249.96558,
    "govt_battery_charge": 65.68248619
  },
  {
    "timestamp": "18/05/24 22:00",
    "demand": 33619.14988,
    "solar_generated": 0,
    "wind_generated": 54.58676881,
    "marketplace_to_demand": 181.9955078,
    "grid_to_demand": 31344.84552,
    "govt_battery_charge": 51.08489466
  },
  {
    "timestamp": "18/05/24 23:00",
    "demand": 32259.95624,
    "solar_generated": 0,
    "wind_generated": 76.53914509,
    "marketplace_to_demand": 118.7823252,
    "grid_to_demand": 30307.93873,
    "govt_battery_charge": 87.42739175
  },
  {
    "timestamp": "19/05/24 0:00",
    "demand": 34544.56857,
    "solar_generated": 0,
    "wind_generated": 74.70861221,
    "marketplace_to_demand": 100.8621592,
    "grid_to_demand": 31353.79942,
    "govt_battery_charge": 73.33799681
  },
  {
    "timestamp": "19/05/24 1:00",
    "demand": 36625.36749,
    "solar_generated": 0,
    "wind_generated": 58.10676896,
    "marketplace_to_demand": 52.36993916,
    "grid_to_demand": 34726.99834,
    "govt_battery_charge": 50.98026357
  },
  {
    "timestamp": "19/05/24 2:00",
    "demand": 34114.66136,
    "solar_generated": 0,
    "wind_generated": 62.84410466,
    "marketplace_to_demand": 149.4764744,
    "grid_to_demand": 33901.10711,
    "govt_battery_charge": 62.36316172
  },
  {
    "timestamp": "19/05/24 3:00",
    "demand": 33387.22138,
    "solar_generated": 0,
    "wind_generated": 74.11795677,
    "marketplace_to_demand": 49.61502355,
    "grid_to_demand": 36039.58086,
    "govt_battery_charge": 79.4428721
  },
  {
    "timestamp": "19/05/24 4:00",
    "demand": 33389.9259,
    "solar_generated": 0,
    "wind_generated": 60.83666029,
    "marketplace_to_demand": 68.04445064,
    "grid_to_demand": 34410.27298,
    "govt_battery_charge": 5.922379534
  },
  {
    "timestamp": "19/05/24 5:00",
    "demand": 31298.50521,
    "solar_generated": 0,
    "wind_generated": 73.2666658,
    "marketplace_to_demand": 75.93846931,
    "grid_to_demand": 31349.9409,
    "govt_battery_charge": 41.99159318
  },
  {
    "timestamp": "19/05/24 6:00",
    "demand": 31857.17207,
    "solar_generated": 23.06067908,
    "wind_generated": 60.33124846,
    "marketplace_to_demand": 138.5347704,
    "grid_to_demand": 31391.25755,
    "govt_battery_charge": 39.00751072
  },
  {
    "timestamp": "19/05/24 7:00",
    "demand": 35995.09386,
    "solar_generated": 9.589845655,
    "wind_generated": 56.30433166,
    "marketplace_to_demand": 189.4210473,
    "grid_to_demand": 31485.47454,
    "govt_battery_charge": 14.29826329
  },
  {
    "timestamp": "19/05/24 8:00",
    "demand": 32412.73056,
    "solar_generated": 22.69663846,
    "wind_generated": 53.67041942,
    "marketplace_to_demand": 73.82044943,
    "grid_to_demand": 34323.96617,
    "govt_battery_charge": 15.38396058
  },
  {
    "timestamp": "19/05/24 9:00",
    "demand": 34074.06135,
    "solar_generated": 9.670014653,
    "wind_generated": 71.53070262,
    "marketplace_to_demand": 149.3695202,
    "grid_to_demand": 31703.8628,
    "govt_battery_charge": 98.81367693
  },
  {
    "timestamp": "19/05/24 10:00",
    "demand": 33567.55592,
    "solar_generated": 19.49474867,
    "wind_generated": 78.18580232,
    "marketplace_to_demand": 147.0327684,
    "grid_to_demand": 34969.79105,
    "govt_battery_charge": 74.34741522
  },
  {
    "timestamp": "19/05/24 11:00",
    "demand": 31123.30016,
    "solar_generated": 13.57368578,
    "wind_generated": 63.70340851,
    "marketplace_to_demand": 126.2827743,
    "grid_to_demand": 30278.63592,
    "govt_battery_charge": 41.62246332
  },
  {
    "timestamp": "19/05/24 12:00",
    "demand": 31648.03506,
    "solar_generated": 10.78572604,
    "wind_generated": 79.31427211,
    "marketplace_to_demand": 91.95396163,
    "grid_to_demand": 31682.03558,
    "govt_battery_charge": 92.30741884
  },
  {
    "timestamp": "19/05/24 13:00",
    "demand": 31553.59601,
    "solar_generated": 23.74965314,
    "wind_generated": 73.79188897,
    "marketplace_to_demand": 10.99852466,
    "grid_to_demand": 30958.78215,
    "govt_battery_charge": 42.82069462
  },
  {
    "timestamp": "19/05/24 14:00",
    "demand": 31861.03788,
    "solar_generated": 18.49697416,
    "wind_generated": 79.3223801,
    "marketplace_to_demand": 20.27864745,
    "grid_to_demand": 33180.81948,
    "govt_battery_charge": 60.94262948
  },
  {
    "timestamp": "19/05/24 15:00",
    "demand": 33286.3922,
    "solar_generated": 21.10630257,
    "wind_generated": 73.13722423,
    "marketplace_to_demand": 125.935118,
    "grid_to_demand": 36395.1421,
    "govt_battery_charge": 58.03009802
  },
  {
    "timestamp": "19/05/24 16:00",
    "demand": 35829.05502,
    "solar_generated": 19.18938471,
    "wind_generated": 66.44113416,
    "marketplace_to_demand": 30.79785761,
    "grid_to_demand": 36405.22473,
    "govt_battery_charge": 50.53412583
  },
  {
    "timestamp": "19/05/24 17:00",
    "demand": 33173.34521,
    "solar_generated": 23.52939055,
    "wind_generated": 78.85581231,
    "marketplace_to_demand": 168.8371086,
    "grid_to_demand": 30568.5276,
    "govt_battery_charge": 47.83764215
  },
  {
    "timestamp": "19/05/24 18:00",
    "demand": 32312.29358,
    "solar_generated": 0,
    "wind_generated": 70.47820734,
    "marketplace_to_demand": 156.0482484,
    "grid_to_demand": 34481.57936,
    "govt_battery_charge": 81.097311
  },
  {
    "timestamp": "19/05/24 19:00",
    "demand": 31222.08625,
    "solar_generated": 0,
    "wind_generated": 72.12647624,
    "marketplace_to_demand": 93.88331467,
    "grid_to_demand": 31127.12168,
    "govt_battery_charge": 23.50594283
  },
  {
    "timestamp": "19/05/24 20:00",
    "demand": 34981.74487,
    "solar_generated": 0,
    "wind_generated": 70.79502681,
    "marketplace_to_demand": 72.08972602,
    "grid_to_demand": 30829.62935,
    "govt_battery_charge": 22.88826982
  },
  {
    "timestamp": "19/05/24 21:00",
    "demand": 35005.09522,
    "solar_generated": 0,
    "wind_generated": 72.14401853,
    "marketplace_to_demand": 92.80109983,
    "grid_to_demand": 33800.99514,
    "govt_battery_charge": 28.31126705
  },
  {
    "timestamp": "19/05/24 22:00",
    "demand": 30736.61392,
    "solar_generated": 0,
    "wind_generated": 60.03466526,
    "marketplace_to_demand": 80.10261727,
    "grid_to_demand": 34626.06325,
    "govt_battery_charge": 39.64779968
  },
  {
    "timestamp": "19/05/24 23:00",
    "demand": 32903.85512,
    "solar_generated": 0,
    "wind_generated": 69.25924581,
    "marketplace_to_demand": 122.0400789,
    "grid_to_demand": 32112.24423,
    "govt_battery_charge": 22.06860991
  },
  {
    "timestamp": "20/05/24 0:00",
    "demand": 34352.8789,
    "solar_generated": 0,
    "wind_generated": 62.75592177,
    "marketplace_to_demand": 126.8147718,
    "grid_to_demand": 34785.12668,
    "govt_battery_charge": 38.672602
  },
  {
    "timestamp": "20/05/24 1:00",
    "demand": 34886.73353,
    "solar_generated": 0,
    "wind_generated": 79.47988726,
    "marketplace_to_demand": 101.9448469,
    "grid_to_demand": 36059.98083,
    "govt_battery_charge": 54.06409784
  },
  {
    "timestamp": "20/05/24 2:00",
    "demand": 30625.3432,
    "solar_generated": 0,
    "wind_generated": 65.77156442,
    "marketplace_to_demand": 95.25309909,
    "grid_to_demand": 32658.1893,
    "govt_battery_charge": 52.73631326
  },
  {
    "timestamp": "20/05/24 3:00",
    "demand": 30350.0763,
    "solar_generated": 0,
    "wind_generated": 58.99540876,
    "marketplace_to_demand": 1.681562097,
    "grid_to_demand": 31488.67885,
    "govt_battery_charge": 96.62608051
  },
  {
    "timestamp": "20/05/24 4:00",
    "demand": 34119.70502,
    "solar_generated": 0,
    "wind_generated": 57.52110145,
    "marketplace_to_demand": 42.01021247,
    "grid_to_demand": 34550.97165,
    "govt_battery_charge": 99.21135176
  },
  {
    "timestamp": "20/05/24 5:00",
    "demand": 34800.75758,
    "solar_generated": 0,
    "wind_generated": 55.2409262,
    "marketplace_to_demand": 80.44099618,
    "grid_to_demand": 31702.83879,
    "govt_battery_charge": 12.35022236
  },
  {
    "timestamp": "20/05/24 6:00",
    "demand": 35819.30171,
    "solar_generated": 21.69280295,
    "wind_generated": 74.73741432,
    "marketplace_to_demand": 35.47925324,
    "grid_to_demand": 30051.28739,
    "govt_battery_charge": 27.1422368
  },
  {
    "timestamp": "20/05/24 7:00",
    "demand": 31459.83269,
    "solar_generated": 19.23116916,
    "wind_generated": 75.63556411,
    "marketplace_to_demand": 78.17936069,
    "grid_to_demand": 33040.74592,
    "govt_battery_charge": 23.9906019
  },
  {
    "timestamp": "20/05/24 8:00",
    "demand": 29983.96909,
    "solar_generated": 12.12685077,
    "wind_generated": 59.97701308,
    "marketplace_to_demand": 134.3745649,
    "grid_to_demand": 36092.96519,
    "govt_battery_charge": 49.39933209
  },
  {
    "timestamp": "20/05/24 9:00",
    "demand": 32523.08185,
    "solar_generated": 23.37051561,
    "wind_generated": 68.10425398,
    "marketplace_to_demand": 22.67242231,
    "grid_to_demand": 34190.1522,
    "govt_battery_charge": 20.64331337
  },
  {
    "timestamp": "20/05/24 10:00",
    "demand": 31036.02765,
    "solar_generated": 22.31313896,
    "wind_generated": 79.19210311,
    "marketplace_to_demand": 30.20599411,
    "grid_to_demand": 32119.79785,
    "govt_battery_charge": 95.68946807
  },
  {
    "timestamp": "20/05/24 11:00",
    "demand": 32342.83585,
    "solar_generated": 9.134119049,
    "wind_generated": 74.79004299,
    "marketplace_to_demand": 63.86809019,
    "grid_to_demand": 34825.08487,
    "govt_battery_charge": 54.60598116
  },
  {
    "timestamp": "20/05/24 12:00",
    "demand": 32681.15005,
    "solar_generated": 6.505094668,
    "wind_generated": 64.51817032,
    "marketplace_to_demand": 1.455963316,
    "grid_to_demand": 34730.87709,
    "govt_battery_charge": 40.44128157
  },
  {
    "timestamp": "20/05/24 13:00",
    "demand": 31072.55114,
    "solar_generated": 23.55532259,
    "wind_generated": 55.2523872,
    "marketplace_to_demand": 5.996679898,
    "grid_to_demand": 36207.72338,
    "govt_battery_charge": 17.54628903
  },
  {
    "timestamp": "20/05/24 14:00",
    "demand": 31681.82375,
    "solar_generated": 22.91579365,
    "wind_generated": 78.73678981,
    "marketplace_to_demand": 85.59333614,
    "grid_to_demand": 32908.16037,
    "govt_battery_charge": 37.47263756
  },
  {
    "timestamp": "20/05/24 15:00",
    "demand": 31993.57806,
    "solar_generated": 20.44682692,
    "wind_generated": 70.52117516,
    "marketplace_to_demand": 41.76490617,
    "grid_to_demand": 35297.27302,
    "govt_battery_charge": 67.01889276
  },
  {
    "timestamp": "20/05/24 16:00",
    "demand": 31837.57889,
    "solar_generated": 23.95105833,
    "wind_generated": 67.44312375,
    "marketplace_to_demand": 159.4934295,
    "grid_to_demand": 33975.06292,
    "govt_battery_charge": 27.4699138
  },
  {
    "timestamp": "20/05/24 17:00",
    "demand": 33065.66064,
    "solar_generated": 20.47269636,
    "wind_generated": 53.80982419,
    "marketplace_to_demand": 142.3559494,
    "grid_to_demand": 33518.75824,
    "govt_battery_charge": 22.5691531
  },
  {
    "timestamp": "20/05/24 18:00",
    "demand": 36056.34326,
    "solar_generated": 0,
    "wind_generated": 75.93277327,
    "marketplace_to_demand": 187.349087,
    "grid_to_demand": 34873.65418,
    "govt_battery_charge": 92.60489342
  },
  {
    "timestamp": "20/05/24 19:00",
    "demand": 35757.37386,
    "solar_generated": 0,
    "wind_generated": 68.95539599,
    "marketplace_to_demand": 155.4020304,
    "grid_to_demand": 35170.58701,
    "govt_battery_charge": 84.04831838
  },
  {
    "timestamp": "20/05/24 20:00",
    "demand": 30746.58831,
    "solar_generated": 0,
    "wind_generated": 79.1120994,
    "marketplace_to_demand": 107.0893217,
    "grid_to_demand": 36542.17302,
    "govt_battery_charge": 31.36844389
  },
  {
    "timestamp": "20/05/24 21:00",
    "demand": 32362.51723,
    "solar_generated": 0,
    "wind_generated": 65.19785135,
    "marketplace_to_demand": 65.17316232,
    "grid_to_demand": 36255.42725,
    "govt_battery_charge": 8.074560382
  },
  {
    "timestamp": "20/05/24 22:00",
    "demand": 36154.51251,
    "solar_generated": 0,
    "wind_generated": 76.32844464,
    "marketplace_to_demand": 15.02904001,
    "grid_to_demand": 32171.66607,
    "govt_battery_charge": 33.93450559
  },
  {
    "timestamp": "20/05/24 23:00",
    "demand": 32723.98018,
    "solar_generated": 0,
    "wind_generated": 74.22044799,
    "marketplace_to_demand": 122.143322,
    "grid_to_demand": 36455.30304,
    "govt_battery_charge": 75.99913148
  },
  {
    "timestamp": "21/05/24 0:00",
    "demand": 33359.35344,
    "solar_generated": 0,
    "wind_generated": 66.37820462,
    "marketplace_to_demand": 75.79542843,
    "grid_to_demand": 36308.24304,
    "govt_battery_charge": 52.35947931
  },
  {
    "timestamp": "21/05/24 1:00",
    "demand": 32980.11201,
    "solar_generated": 0,
    "wind_generated": 63.00995345,
    "marketplace_to_demand": 162.0665605,
    "grid_to_demand": 31812.25545,
    "govt_battery_charge": 49.74468955
  },
  {
    "timestamp": "21/05/24 2:00",
    "demand": 34549.07963,
    "solar_generated": 0,
    "wind_generated": 69.67756565,
    "marketplace_to_demand": 5.645442257,
    "grid_to_demand": 31644.78,
    "govt_battery_charge": 88.51260609
  },
  {
    "timestamp": "21/05/24 3:00",
    "demand": 31322.13344,
    "solar_generated": 0,
    "wind_generated": 59.49905339,
    "marketplace_to_demand": 167.7889209,
    "grid_to_demand": 34493.15222,
    "govt_battery_charge": 33.23159388
  },
  {
    "timestamp": "21/05/24 4:00",
    "demand": 31784.20341,
    "solar_generated": 0,
    "wind_generated": 75.90492813,
    "marketplace_to_demand": 7.460586664,
    "grid_to_demand": 35112.94874,
    "govt_battery_charge": 68.16426742
  },
  {
    "timestamp": "21/05/24 5:00",
    "demand": 30387.75559,
    "solar_generated": 0,
    "wind_generated": 59.51354727,
    "marketplace_to_demand": 72.63975295,
    "grid_to_demand": 30682.14261,
    "govt_battery_charge": 93.88124473
  },
  {
    "timestamp": "21/05/24 6:00",
    "demand": 35594.51289,
    "solar_generated": 20.08698828,
    "wind_generated": 60.24920711,
    "marketplace_to_demand": 185.6159112,
    "grid_to_demand": 33459.42338,
    "govt_battery_charge": 54.30317702
  },
  {
    "timestamp": "21/05/24 7:00",
    "demand": 30743.90507,
    "solar_generated": 6.082762871,
    "wind_generated": 66.60218146,
    "marketplace_to_demand": 129.1104851,
    "grid_to_demand": 34425.50584,
    "govt_battery_charge": 44.59205439
  },
  {
    "timestamp": "21/05/24 8:00",
    "demand": 32747.8993,
    "solar_generated": 11.72838795,
    "wind_generated": 67.14516632,
    "marketplace_to_demand": 150.6866608,
    "grid_to_demand": 33497.29945,
    "govt_battery_charge": 57.41091747
  },
  {
    "timestamp": "21/05/24 9:00",
    "demand": 36033.14902,
    "solar_generated": 14.2652164,
    "wind_generated": 66.12495844,
    "marketplace_to_demand": 133.4296798,
    "grid_to_demand": 30663.46972,
    "govt_battery_charge": 51.63308499
  },
  {
    "timestamp": "21/05/24 10:00",
    "demand": 35503.41249,
    "solar_generated": 18.24786894,
    "wind_generated": 54.40058243,
    "marketplace_to_demand": 52.71271268,
    "grid_to_demand": 32986.07554,
    "govt_battery_charge": 88.94473769
  },
  {
    "timestamp": "21/05/24 11:00",
    "demand": 35091.14748,
    "solar_generated": 6.852826352,
    "wind_generated": 79.09146335,
    "marketplace_to_demand": 123.618408,
    "grid_to_demand": 32786.70178,
    "govt_battery_charge": 31.83308166
  },
  {
    "timestamp": "21/05/24 12:00",
    "demand": 35830.97343,
    "solar_generated": 12.32819207,
    "wind_generated": 56.1873318,
    "marketplace_to_demand": 91.06711518,
    "grid_to_demand": 35122.44288,
    "govt_battery_charge": 71.11824266
  },
  {
    "timestamp": "21/05/24 13:00",
    "demand": 36351.83726,
    "solar_generated": 8.393348132,
    "wind_generated": 54.78216531,
    "marketplace_to_demand": 38.28130219,
    "grid_to_demand": 34089.85619,
    "govt_battery_charge": 96.46914304
  },
  {
    "timestamp": "21/05/24 14:00",
    "demand": 32513.75894,
    "solar_generated": 11.00234004,
    "wind_generated": 68.8661998,
    "marketplace_to_demand": 56.91122495,
    "grid_to_demand": 33603.85958,
    "govt_battery_charge": 98.40389951
  },
  {
    "timestamp": "21/05/24 15:00",
    "demand": 32760.31884,
    "solar_generated": 18.44297671,
    "wind_generated": 64.40464413,
    "marketplace_to_demand": 191.7159153,
    "grid_to_demand": 30799.35884,
    "govt_battery_charge": 68.31865593
  },
  {
    "timestamp": "21/05/24 16:00",
    "demand": 35896.07938,
    "solar_generated": 19.49442703,
    "wind_generated": 66.74744485,
    "marketplace_to_demand": 155.0072638,
    "grid_to_demand": 31438.52391,
    "govt_battery_charge": 25.66713109
  },
  {
    "timestamp": "21/05/24 17:00",
    "demand": 35256.34103,
    "solar_generated": 18.91981653,
    "wind_generated": 58.22099096,
    "marketplace_to_demand": 131.66147,
    "grid_to_demand": 34625.44932,
    "govt_battery_charge": 91.46769204
  },
  {
    "timestamp": "21/05/24 18:00",
    "demand": 34999.4399,
    "solar_generated": 0,
    "wind_generated": 55.10490925,
    "marketplace_to_demand": 41.01430608,
    "grid_to_demand": 31588.65098,
    "govt_battery_charge": 54.24018429
  },
  {
    "timestamp": "21/05/24 19:00",
    "demand": 31227.3691,
    "solar_generated": 0,
    "wind_generated": 61.27028923,
    "marketplace_to_demand": 70.46114931,
    "grid_to_demand": 32873.32491,
    "govt_battery_charge": 46.51267664
  },
  {
    "timestamp": "21/05/24 20:00",
    "demand": 33196.99864,
    "solar_generated": 0,
    "wind_generated": 76.57010411,
    "marketplace_to_demand": 41.21841526,
    "grid_to_demand": 32572.50225,
    "govt_battery_charge": 76.08087821
  },
  {
    "timestamp": "21/05/24 21:00",
    "demand": 33085.2525,
    "solar_generated": 0,
    "wind_generated": 70.0432251,
    "marketplace_to_demand": 20.67035069,
    "grid_to_demand": 34205.69925,
    "govt_battery_charge": 56.77826308
  },
  {
    "timestamp": "21/05/24 22:00",
    "demand": 35282.17451,
    "solar_generated": 0,
    "wind_generated": 67.45303618,
    "marketplace_to_demand": 45.38701257,
    "grid_to_demand": 31136.10159,
    "govt_battery_charge": 83.17466176
  },
  {
    "timestamp": "21/05/24 23:00",
    "demand": 29985.27007,
    "solar_generated": 0,
    "wind_generated": 71.30242322,
    "marketplace_to_demand": 21.45781572,
    "grid_to_demand": 30463.71337,
    "govt_battery_charge": 83.72883344
  },
  {
    "timestamp": "22/05/24 0:00",
    "demand": 31472.53142,
    "solar_generated": 0,
    "wind_generated": 65.09008883,
    "marketplace_to_demand": 75.90291642,
    "grid_to_demand": 33819.31419,
    "govt_battery_charge": 31.22850289
  },
  {
    "timestamp": "22/05/24 1:00",
    "demand": 32608.11336,
    "solar_generated": 0,
    "wind_generated": 63.95289932,
    "marketplace_to_demand": 31.95657184,
    "grid_to_demand": 33627.45831,
    "govt_battery_charge": 59.52813056
  },
  {
    "timestamp": "22/05/24 2:00",
    "demand": 31037.67768,
    "solar_generated": 0,
    "wind_generated": 54.0722289,
    "marketplace_to_demand": 180.6673094,
    "grid_to_demand": 36010.57498,
    "govt_battery_charge": 4.293512072
  },
  {
    "timestamp": "22/05/24 3:00",
    "demand": 34983.03638,
    "solar_generated": 0,
    "wind_generated": 62.86879218,
    "marketplace_to_demand": 53.96789096,
    "grid_to_demand": 34168.19201,
    "govt_battery_charge": 64.68736641
  },
  {
    "timestamp": "22/05/24 4:00",
    "demand": 34020.11009,
    "solar_generated": 0,
    "wind_generated": 64.18482454,
    "marketplace_to_demand": 174.0174454,
    "grid_to_demand": 35771.35931,
    "govt_battery_charge": 62.42589358
  },
  {
    "timestamp": "22/05/24 5:00",
    "demand": 32725.18486,
    "solar_generated": 0,
    "wind_generated": 72.91161027,
    "marketplace_to_demand": 121.8308931,
    "grid_to_demand": 31007.24184,
    "govt_battery_charge": 72.2874158
  },
  {
    "timestamp": "22/05/24 6:00",
    "demand": 33646.31421,
    "solar_generated": 23.04553471,
    "wind_generated": 75.68212267,
    "marketplace_to_demand": 118.6280789,
    "grid_to_demand": 34409.86173,
    "govt_battery_charge": 56.82455976
  },
  {
    "timestamp": "22/05/24 7:00",
    "demand": 31045.40863,
    "solar_generated": 10.32132005,
    "wind_generated": 54.9151701,
    "marketplace_to_demand": 160.8388281,
    "grid_to_demand": 34486.50922,
    "govt_battery_charge": 64.87302359
  },
  {
    "timestamp": "22/05/24 8:00",
    "demand": 31368.37726,
    "solar_generated": 18.91698439,
    "wind_generated": 67.93165733,
    "marketplace_to_demand": 123.375693,
    "grid_to_demand": 34095.80794,
    "govt_battery_charge": 46.02978866
  },
  {
    "timestamp": "22/05/24 9:00",
    "demand": 34781.63059,
    "solar_generated": 22.96993045,
    "wind_generated": 68.49128862,
    "marketplace_to_demand": 107.9306374,
    "grid_to_demand": 34663.99282,
    "govt_battery_charge": 37.36124814
  },
  {
    "timestamp": "22/05/24 10:00",
    "demand": 33295.92917,
    "solar_generated": 15.65791041,
    "wind_generated": 74.18810351,
    "marketplace_to_demand": 115.0204882,
    "grid_to_demand": 36512.39245,
    "govt_battery_charge": 92.52973609
  },
  {
    "timestamp": "22/05/24 11:00",
    "demand": 34582.95632,
    "solar_generated": 13.08285179,
    "wind_generated": 56.29156502,
    "marketplace_to_demand": 62.70317814,
    "grid_to_demand": 30353.7628,
    "govt_battery_charge": 64.4457055
  },
  {
    "timestamp": "22/05/24 12:00",
    "demand": 32284.54567,
    "solar_generated": 17.87869668,
    "wind_generated": 73.49172588,
    "marketplace_to_demand": 52.87473112,
    "grid_to_demand": 33113.59738,
    "govt_battery_charge": 33.12467046
  },
  {
    "timestamp": "22/05/24 13:00",
    "demand": 33789.05965,
    "solar_generated": 10.87154292,
    "wind_generated": 57.38140705,
    "marketplace_to_demand": 8.781937139,
    "grid_to_demand": 35311.14471,
    "govt_battery_charge": 10.06381513
  },
  {
    "timestamp": "22/05/24 14:00",
    "demand": 34713.37662,
    "solar_generated": 10.02232232,
    "wind_generated": 68.95630887,
    "marketplace_to_demand": 113.4506023,
    "grid_to_demand": 34007.32673,
    "govt_battery_charge": 84.66351882
  },
  {
    "timestamp": "22/05/24 15:00",
    "demand": 31428.44854,
    "solar_generated": 9.357896446,
    "wind_generated": 69.43902367,
    "marketplace_to_demand": 49.40616235,
    "grid_to_demand": 32732.22638,
    "govt_battery_charge": 94.87336101
  },
  {
    "timestamp": "22/05/24 16:00",
    "demand": 31425.03102,
    "solar_generated": 14.67397736,
    "wind_generated": 62.79058619,
    "marketplace_to_demand": 16.77785834,
    "grid_to_demand": 32000.72295,
    "govt_battery_charge": 5.716900225
  },
  {
    "timestamp": "22/05/24 17:00",
    "demand": 31419.809,
    "solar_generated": 8.046391977,
    "wind_generated": 59.46722069,
    "marketplace_to_demand": 137.85527,
    "grid_to_demand": 33478.34713,
    "govt_battery_charge": 92.79367342
  },
  {
    "timestamp": "22/05/24 18:00",
    "demand": 33611.31466,
    "solar_generated": 0,
    "wind_generated": 67.4606108,
    "marketplace_to_demand": 71.13174538,
    "grid_to_demand": 32749.69044,
    "govt_battery_charge": 30.57684207
  },
  {
    "timestamp": "22/05/24 19:00",
    "demand": 36175.70719,
    "solar_generated": 0,
    "wind_generated": 74.67044663,
    "marketplace_to_demand": 171.8980922,
    "grid_to_demand": 33501.77137,
    "govt_battery_charge": 61.0382605
  },
  {
    "timestamp": "22/05/24 20:00",
    "demand": 30186.52222,
    "solar_generated": 0,
    "wind_generated": 77.08086315,
    "marketplace_to_demand": 122.3717155,
    "grid_to_demand": 29961.69342,
    "govt_battery_charge": 29.24563077
  },
  {
    "timestamp": "22/05/24 21:00",
    "demand": 32227.36657,
    "solar_generated": 0,
    "wind_generated": 58.2905658,
    "marketplace_to_demand": 11.72210262,
    "grid_to_demand": 30850.58223,
    "govt_battery_charge": 17.22641397
  },
  {
    "timestamp": "22/05/24 22:00",
    "demand": 31040.26306,
    "solar_generated": 0,
    "wind_generated": 56.52469365,
    "marketplace_to_demand": 151.0413261,
    "grid_to_demand": 31890.1265,
    "govt_battery_charge": 82.71623246
  },
  {
    "timestamp": "22/05/24 23:00",
    "demand": 34539.47831,
    "solar_generated": 0,
    "wind_generated": 58.81890922,
    "marketplace_to_demand": 44.55453699,
    "grid_to_demand": 32600.50441,
    "govt_battery_charge": 57.36784748
  },
  {
    "timestamp": "23/05/24 0:00",
    "demand": 35388.66224,
    "solar_generated": 0,
    "wind_generated": 57.01310297,
    "marketplace_to_demand": 194.5481945,
    "grid_to_demand": 31004.31902,
    "govt_battery_charge": 28.42646962
  },
  {
    "timestamp": "23/05/24 1:00",
    "demand": 30469.85273,
    "solar_generated": 0,
    "wind_generated": 59.50849199,
    "marketplace_to_demand": 16.93123161,
    "grid_to_demand": 36296.23685,
    "govt_battery_charge": 88.57823306
  },
  {
    "timestamp": "23/05/24 2:00",
    "demand": 36343.7325,
    "solar_generated": 0,
    "wind_generated": 54.53050834,
    "marketplace_to_demand": 135.2877698,
    "grid_to_demand": 36079.97684,
    "govt_battery_charge": 71.22774512
  },
  {
    "timestamp": "23/05/24 3:00",
    "demand": 34996.45651,
    "solar_generated": 0,
    "wind_generated": 66.71320597,
    "marketplace_to_demand": 92.07598255,
    "grid_to_demand": 34162.60029,
    "govt_battery_charge": 36.63475598
  },
  {
    "timestamp": "23/05/24 4:00",
    "demand": 30012.69375,
    "solar_generated": 0,
    "wind_generated": 59.26599091,
    "marketplace_to_demand": 90.30389684,
    "grid_to_demand": 34975.72201,
    "govt_battery_charge": 35.6297327
  },
  {
    "timestamp": "23/05/24 5:00",
    "demand": 36008.57624,
    "solar_generated": 0,
    "wind_generated": 58.24633106,
    "marketplace_to_demand": 160.674219,
    "grid_to_demand": 32447.28685,
    "govt_battery_charge": 32.00011564
  },
  {
    "timestamp": "23/05/24 6:00",
    "demand": 30275.72389,
    "solar_generated": 17.81939007,
    "wind_generated": 65.90958238,
    "marketplace_to_demand": 101.0959957,
    "grid_to_demand": 32828.80604,
    "govt_battery_charge": 85.33935774
  },
  {
    "timestamp": "23/05/24 7:00",
    "demand": 33250.9758,
    "solar_generated": 14.30214255,
    "wind_generated": 74.13430581,
    "marketplace_to_demand": 135.8619181,
    "grid_to_demand": 36156.2757,
    "govt_battery_charge": 4.759188861
  },
  {
    "timestamp": "23/05/24 8:00",
    "demand": 31640.50699,
    "solar_generated": 12.77315564,
    "wind_generated": 74.65640677,
    "marketplace_to_demand": 194.0317769,
    "grid_to_demand": 31281.95004,
    "govt_battery_charge": 70.85507574
  },
  {
    "timestamp": "23/05/24 9:00",
    "demand": 35424.58386,
    "solar_generated": 21.50954872,
    "wind_generated": 57.97673508,
    "marketplace_to_demand": 178.2830592,
    "grid_to_demand": 35931.51082,
    "govt_battery_charge": 27.45707077
  },
  {
    "timestamp": "23/05/24 10:00",
    "demand": 32228.6549,
    "solar_generated": 7.416862964,
    "wind_generated": 73.59493673,
    "marketplace_to_demand": 57.80825799,
    "grid_to_demand": 31643.34756,
    "govt_battery_charge": 65.25747946
  },
  {
    "timestamp": "23/05/24 11:00",
    "demand": 32270.30475,
    "solar_generated": 23.8303645,
    "wind_generated": 72.49143156,
    "marketplace_to_demand": 174.9623262,
    "grid_to_demand": 34050.4013,
    "govt_battery_charge": 43.36001958
  },
  {
    "timestamp": "23/05/24 12:00",
    "demand": 30215.40484,
    "solar_generated": 22.93018091,
    "wind_generated": 55.90672197,
    "marketplace_to_demand": 26.92393418,
    "grid_to_demand": 32250.95815,
    "govt_battery_charge": 64.81095056
  },
  {
    "timestamp": "23/05/24 13:00",
    "demand": 33086.84884,
    "solar_generated": 22.56926549,
    "wind_generated": 77.98866881,
    "marketplace_to_demand": 16.49091522,
    "grid_to_demand": 33471.88169,
    "govt_battery_charge": 78.94812231
  },
  {
    "timestamp": "23/05/24 14:00",
    "demand": 34601.96649,
    "solar_generated": 6.668569759,
    "wind_generated": 68.88852523,
    "marketplace_to_demand": 125.9294735,
    "grid_to_demand": 33442.73908,
    "govt_battery_charge": 27.24834725
  },
  {
    "timestamp": "23/05/24 15:00",
    "demand": 35409.15996,
    "solar_generated": 6.228836325,
    "wind_generated": 64.54740974,
    "marketplace_to_demand": 77.36953353,
    "grid_to_demand": 32117.00486,
    "govt_battery_charge": 85.20944382
  },
  {
    "timestamp": "23/05/24 16:00",
    "demand": 31212.25719,
    "solar_generated": 6.31065285,
    "wind_generated": 63.80830034,
    "marketplace_to_demand": 193.789772,
    "grid_to_demand": 30130.42976,
    "govt_battery_charge": 65.15036969
  },
  {
    "timestamp": "23/05/24 17:00",
    "demand": 33334.1627,
    "solar_generated": 22.79609781,
    "wind_generated": 56.49805052,
    "marketplace_to_demand": 27.6433674,
    "grid_to_demand": 35596.60329,
    "govt_battery_charge": 84.00382986
  },
  {
    "timestamp": "23/05/24 18:00",
    "demand": 35979.05631,
    "solar_generated": 0,
    "wind_generated": 78.16000725,
    "marketplace_to_demand": 180.353131,
    "grid_to_demand": 33269.90584,
    "govt_battery_charge": 2.277483834
  },
  {
    "timestamp": "23/05/24 19:00",
    "demand": 32339.26438,
    "solar_generated": 0,
    "wind_generated": 75.27680715,
    "marketplace_to_demand": 18.94258282,
    "grid_to_demand": 36124.38607,
    "govt_battery_charge": 77.51496652
  },
  {
    "timestamp": "23/05/24 20:00",
    "demand": 30618.61321,
    "solar_generated": 0,
    "wind_generated": 75.15331556,
    "marketplace_to_demand": 22.64976616,
    "grid_to_demand": 33534.2041,
    "govt_battery_charge": 76.95421385
  },
  {
    "timestamp": "23/05/24 21:00",
    "demand": 36099.2321,
    "solar_generated": 0,
    "wind_generated": 59.78824787,
    "marketplace_to_demand": 51.81799265,
    "grid_to_demand": 29945.23501,
    "govt_battery_charge": 85.78130423
  },
  {
    "timestamp": "23/05/24 22:00",
    "demand": 35963.18771,
    "solar_generated": 0,
    "wind_generated": 62.58234632,
    "marketplace_to_demand": 15.96934524,
    "grid_to_demand": 34500.2131,
    "govt_battery_charge": 17.3543755
  },
  {
    "timestamp": "23/05/24 23:00",
    "demand": 30814.03148,
    "solar_generated": 0,
    "wind_generated": 64.75434894,
    "marketplace_to_demand": 124.4632754,
    "grid_to_demand": 30636.37612,
    "govt_battery_charge": 84.1631611
  },
  {
    "timestamp": "24/05/24 0:00",
    "demand": 30736.27662,
    "solar_generated": 0,
    "wind_generated": 74.31561895,
    "marketplace_to_demand": 122.1615655,
    "grid_to_demand": 35022.2005,
    "govt_battery_charge": 55.41747588
  },
  {
    "timestamp": "24/05/24 1:00",
    "demand": 35536.87964,
    "solar_generated": 0,
    "wind_generated": 57.09223873,
    "marketplace_to_demand": 21.10239397,
    "grid_to_demand": 33929.59179,
    "govt_battery_charge": 7.16780514
  },
  {
    "timestamp": "24/05/24 2:00",
    "demand": 36192.21966,
    "solar_generated": 0,
    "wind_generated": 77.52261816,
    "marketplace_to_demand": 111.4733964,
    "grid_to_demand": 35560.0674,
    "govt_battery_charge": 85.55974157
  },
  {
    "timestamp": "24/05/24 3:00",
    "demand": 33163.50321,
    "solar_generated": 0,
    "wind_generated": 73.80389984,
    "marketplace_to_demand": 40.45647156,
    "grid_to_demand": 36171.17835,
    "govt_battery_charge": 46.3020018
  },
  {
    "timestamp": "24/05/24 4:00",
    "demand": 34005.22059,
    "solar_generated": 0,
    "wind_generated": 79.74768878,
    "marketplace_to_demand": 189.5969932,
    "grid_to_demand": 31457.37589,
    "govt_battery_charge": 67.24209976
  },
  {
    "timestamp": "24/05/24 5:00",
    "demand": 34704.57092,
    "solar_generated": 0,
    "wind_generated": 71.47616951,
    "marketplace_to_demand": 44.0987651,
    "grid_to_demand": 32500.13543,
    "govt_battery_charge": 81.6757829
  },
  {
    "timestamp": "24/05/24 6:00",
    "demand": 33272.46599,
    "solar_generated": 8.67351651,
    "wind_generated": 66.61991769,
    "marketplace_to_demand": 184.6892038,
    "grid_to_demand": 32308.03262,
    "govt_battery_charge": 77.4423866
  },
  {
    "timestamp": "24/05/24 7:00",
    "demand": 36438.74595,
    "solar_generated": 6.632418152,
    "wind_generated": 67.87016559,
    "marketplace_to_demand": 165.936518,
    "grid_to_demand": 35998.04575,
    "govt_battery_charge": 72.8810857
  },
  {
    "timestamp": "24/05/24 8:00",
    "demand": 33023.79541,
    "solar_generated": 13.78171624,
    "wind_generated": 71.03875588,
    "marketplace_to_demand": 46.20667071,
    "grid_to_demand": 30640.02105,
    "govt_battery_charge": 17.49277666
  },
  {
    "timestamp": "24/05/24 9:00",
    "demand": 34064.85814,
    "solar_generated": 9.597372981,
    "wind_generated": 72.5231338,
    "marketplace_to_demand": 169.538607,
    "grid_to_demand": 34363.0668,
    "govt_battery_charge": 98.2157843
  },
  {
    "timestamp": "24/05/24 10:00",
    "demand": 33253.09895,
    "solar_generated": 19.10154036,
    "wind_generated": 58.88944151,
    "marketplace_to_demand": 136.165889,
    "grid_to_demand": 36317.33689,
    "govt_battery_charge": 96.65382738
  },
  {
    "timestamp": "24/05/24 11:00",
    "demand": 31148.33986,
    "solar_generated": 12.2877672,
    "wind_generated": 60.58890557,
    "marketplace_to_demand": 117.0120794,
    "grid_to_demand": 33689.26194,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "24/05/24 12:00",
    "demand": 36480.67552,
    "solar_generated": 19.9067682,
    "wind_generated": 61.02757015,
    "marketplace_to_demand": 41.05765241,
    "grid_to_demand": 34586.60766,
    "govt_battery_charge": 64.27399937
  },
  {
    "timestamp": "24/05/24 13:00",
    "demand": 34969.9577,
    "solar_generated": 6.284984729,
    "wind_generated": 71.41834314,
    "marketplace_to_demand": 81.12752921,
    "grid_to_demand": 32229.9425,
    "govt_battery_charge": 54.63662096
  },
  {
    "timestamp": "24/05/24 14:00",
    "demand": 35438.42404,
    "solar_generated": 23.37594338,
    "wind_generated": 74.8058532,
    "marketplace_to_demand": 141.5790175,
    "grid_to_demand": 31593.50539,
    "govt_battery_charge": 60.85158957
  },
  {
    "timestamp": "24/05/24 15:00",
    "demand": 33563.45133,
    "solar_generated": 10.66208425,
    "wind_generated": 75.4124222,
    "marketplace_to_demand": 150.5104233,
    "grid_to_demand": 35758.22074,
    "govt_battery_charge": 22.36439169
  },
  {
    "timestamp": "24/05/24 16:00",
    "demand": 35991.3377,
    "solar_generated": 6.446553239,
    "wind_generated": 64.31393711,
    "marketplace_to_demand": 155.7408273,
    "grid_to_demand": 35674.28718,
    "govt_battery_charge": 6.652650739
  },
  {
    "timestamp": "24/05/24 17:00",
    "demand": 30936.93817,
    "solar_generated": 17.20850557,
    "wind_generated": 71.91088988,
    "marketplace_to_demand": 59.77270097,
    "grid_to_demand": 34289.82347,
    "govt_battery_charge": 96.58401056
  },
  {
    "timestamp": "24/05/24 18:00",
    "demand": 36308.3825,
    "solar_generated": 0,
    "wind_generated": 78.82558707,
    "marketplace_to_demand": 146.0891913,
    "grid_to_demand": 35468.82163,
    "govt_battery_charge": 2.341964066
  },
  {
    "timestamp": "24/05/24 19:00",
    "demand": 36175.34983,
    "solar_generated": 0,
    "wind_generated": 69.27731668,
    "marketplace_to_demand": 35.08637666,
    "grid_to_demand": 34021.05589,
    "govt_battery_charge": 6.622178879
  },
  {
    "timestamp": "24/05/24 20:00",
    "demand": 33782.56164,
    "solar_generated": 0,
    "wind_generated": 71.07640252,
    "marketplace_to_demand": 164.8452027,
    "grid_to_demand": 34674.14118,
    "govt_battery_charge": 56.7339695
  },
  {
    "timestamp": "24/05/24 21:00",
    "demand": 32143.8477,
    "solar_generated": 0,
    "wind_generated": 72.51478734,
    "marketplace_to_demand": 101.4421123,
    "grid_to_demand": 34212.626,
    "govt_battery_charge": 81.37966646
  },
  {
    "timestamp": "24/05/24 22:00",
    "demand": 34851.40595,
    "solar_generated": 0,
    "wind_generated": 65.04207347,
    "marketplace_to_demand": 147.5313347,
    "grid_to_demand": 35796.95694,
    "govt_battery_charge": 16.44155203
  },
  {
    "timestamp": "24/05/24 23:00",
    "demand": 30041.20409,
    "solar_generated": 0,
    "wind_generated": 67.79237642,
    "marketplace_to_demand": 46.15914688,
    "grid_to_demand": 31397.83405,
    "govt_battery_charge": 92.47475851
  },
  {
    "timestamp": "25/05/24 0:00",
    "demand": 32417.31516,
    "solar_generated": 0,
    "wind_generated": 75.2744982,
    "marketplace_to_demand": 23.44272181,
    "grid_to_demand": 32904.81369,
    "govt_battery_charge": 15.26229037
  },
  {
    "timestamp": "25/05/24 1:00",
    "demand": 36319.17493,
    "solar_generated": 0,
    "wind_generated": 71.87400789,
    "marketplace_to_demand": 17.06876635,
    "grid_to_demand": 31709.02424,
    "govt_battery_charge": 59.85497006
  },
  {
    "timestamp": "25/05/24 2:00",
    "demand": 34449.92541,
    "solar_generated": 0,
    "wind_generated": 71.55901921,
    "marketplace_to_demand": 121.4598088,
    "grid_to_demand": 31939.63719,
    "govt_battery_charge": 25.32305662
  },
  {
    "timestamp": "25/05/24 3:00",
    "demand": 35982.35955,
    "solar_generated": 0,
    "wind_generated": 60.47445025,
    "marketplace_to_demand": 173.6474386,
    "grid_to_demand": 34032.68658,
    "govt_battery_charge": 86.84458326
  },
  {
    "timestamp": "25/05/24 4:00",
    "demand": 36385.29301,
    "solar_generated": 0,
    "wind_generated": 77.51282984,
    "marketplace_to_demand": 196.2907252,
    "grid_to_demand": 33853.98263,
    "govt_battery_charge": 63.16845575
  },
  {
    "timestamp": "25/05/24 5:00",
    "demand": 30370.3442,
    "solar_generated": 0,
    "wind_generated": 77.57062315,
    "marketplace_to_demand": 58.80420549,
    "grid_to_demand": 33018.21039,
    "govt_battery_charge": 9.21656004
  },
  {
    "timestamp": "25/05/24 6:00",
    "demand": 35930.83237,
    "solar_generated": 21.54038312,
    "wind_generated": 64.0884231,
    "marketplace_to_demand": 21.37294901,
    "grid_to_demand": 34673.53566,
    "govt_battery_charge": 52.88413281
  },
  {
    "timestamp": "25/05/24 7:00",
    "demand": 36160.64187,
    "solar_generated": 10.97171297,
    "wind_generated": 70.02896479,
    "marketplace_to_demand": 195.7054071,
    "grid_to_demand": 34633.78482,
    "govt_battery_charge": 61.79555304
  },
  {
    "timestamp": "25/05/24 8:00",
    "demand": 30810.13197,
    "solar_generated": 8.297433281,
    "wind_generated": 70.9727457,
    "marketplace_to_demand": 30.96560275,
    "grid_to_demand": 34484.76223,
    "govt_battery_charge": 67.97431335
  },
  {
    "timestamp": "25/05/24 9:00",
    "demand": 33975.56654,
    "solar_generated": 22.11196236,
    "wind_generated": 77.49768311,
    "marketplace_to_demand": 62.11059097,
    "grid_to_demand": 32980.38356,
    "govt_battery_charge": 14.84385568
  },
  {
    "timestamp": "25/05/24 10:00",
    "demand": 30923.31347,
    "solar_generated": 14.60028995,
    "wind_generated": 57.71051282,
    "marketplace_to_demand": 126.2026343,
    "grid_to_demand": 35967.99774,
    "govt_battery_charge": 12.42741687
  },
  {
    "timestamp": "25/05/24 11:00",
    "demand": 35000.18358,
    "solar_generated": 16.56985407,
    "wind_generated": 66.52250826,
    "marketplace_to_demand": 146.8729335,
    "grid_to_demand": 35732.64954,
    "govt_battery_charge": 48.53098222
  },
  {
    "timestamp": "25/05/24 12:00",
    "demand": 30972.37322,
    "solar_generated": 14.69766909,
    "wind_generated": 57.68584979,
    "marketplace_to_demand": 162.261609,
    "grid_to_demand": 31110.88167,
    "govt_battery_charge": 18.67444842
  },
  {
    "timestamp": "25/05/24 13:00",
    "demand": 32818.84249,
    "solar_generated": 19.91088607,
    "wind_generated": 59.11340629,
    "marketplace_to_demand": 71.43683946,
    "grid_to_demand": 32132.61003,
    "govt_battery_charge": 80.54125537
  },
  {
    "timestamp": "25/05/24 14:00",
    "demand": 30214.80759,
    "solar_generated": 17.47683762,
    "wind_generated": 68.05767846,
    "marketplace_to_demand": 76.58551709,
    "grid_to_demand": 33820.5371,
    "govt_battery_charge": 84.3149089
  },
  {
    "timestamp": "25/05/24 15:00",
    "demand": 34484.66492,
    "solar_generated": 20.31172724,
    "wind_generated": 67.70984374,
    "marketplace_to_demand": 22.92290139,
    "grid_to_demand": 33316.42186,
    "govt_battery_charge": 34.41574102
  },
  {
    "timestamp": "25/05/24 16:00",
    "demand": 36243.88752,
    "solar_generated": 9.312573992,
    "wind_generated": 60.07012627,
    "marketplace_to_demand": 0.642313434,
    "grid_to_demand": 30186.35769,
    "govt_battery_charge": 37.39387842
  },
  {
    "timestamp": "25/05/24 17:00",
    "demand": 31295.08455,
    "solar_generated": 14.26802394,
    "wind_generated": 69.17317268,
    "marketplace_to_demand": 13.0790468,
    "grid_to_demand": 35608.82382,
    "govt_battery_charge": 53.30000208
  },
  {
    "timestamp": "25/05/24 18:00",
    "demand": 34139.84968,
    "solar_generated": 0,
    "wind_generated": 78.33027338,
    "marketplace_to_demand": 62.7987342,
    "grid_to_demand": 31328.17539,
    "govt_battery_charge": 50.56458697
  },
  {
    "timestamp": "25/05/24 19:00",
    "demand": 35942.44059,
    "solar_generated": 0,
    "wind_generated": 72.25015221,
    "marketplace_to_demand": 22.81117221,
    "grid_to_demand": 34472.41707,
    "govt_battery_charge": 54.39035783
  },
  {
    "timestamp": "25/05/24 20:00",
    "demand": 32510.54167,
    "solar_generated": 0,
    "wind_generated": 61.17933552,
    "marketplace_to_demand": 84.42807257,
    "grid_to_demand": 31749.32238,
    "govt_battery_charge": 26.17530124
  },
  {
    "timestamp": "25/05/24 21:00",
    "demand": 31998.66735,
    "solar_generated": 0,
    "wind_generated": 61.01841486,
    "marketplace_to_demand": 71.6449678,
    "grid_to_demand": 32848.24941,
    "govt_battery_charge": 97.94985017
  },
  {
    "timestamp": "25/05/24 22:00",
    "demand": 30145.20139,
    "solar_generated": 0,
    "wind_generated": 59.12193379,
    "marketplace_to_demand": 105.4539442,
    "grid_to_demand": 33072.14476,
    "govt_battery_charge": 45.85219047
  },
  {
    "timestamp": "25/05/24 23:00",
    "demand": 33581.34,
    "solar_generated": 0,
    "wind_generated": 60.46377607,
    "marketplace_to_demand": 160.1524219,
    "grid_to_demand": 33870.16351,
    "govt_battery_charge": 91.99695325
  },
  {
    "timestamp": "26/05/24 0:00",
    "demand": 31196.20658,
    "solar_generated": 0,
    "wind_generated": 67.91976561,
    "marketplace_to_demand": 74.47308192,
    "grid_to_demand": 31968.08168,
    "govt_battery_charge": 53.15284965
  },
  {
    "timestamp": "26/05/24 1:00",
    "demand": 34460.65483,
    "solar_generated": 0,
    "wind_generated": 63.28136075,
    "marketplace_to_demand": 106.3738031,
    "grid_to_demand": 34883.88028,
    "govt_battery_charge": 19.90813239
  },
  {
    "timestamp": "26/05/24 2:00",
    "demand": 34481.11097,
    "solar_generated": 0,
    "wind_generated": 67.09820136,
    "marketplace_to_demand": 95.29434561,
    "grid_to_demand": 34044.80576,
    "govt_battery_charge": 21.02413154
  },
  {
    "timestamp": "26/05/24 3:00",
    "demand": 31545.28246,
    "solar_generated": 0,
    "wind_generated": 57.79775706,
    "marketplace_to_demand": 96.90473918,
    "grid_to_demand": 32340.59085,
    "govt_battery_charge": 99.49051592
  },
  {
    "timestamp": "26/05/24 4:00",
    "demand": 34613.47147,
    "solar_generated": 0,
    "wind_generated": 53.87973684,
    "marketplace_to_demand": 24.21642836,
    "grid_to_demand": 35039.81136,
    "govt_battery_charge": 95.5406904
  },
  {
    "timestamp": "26/05/24 5:00",
    "demand": 34963.27567,
    "solar_generated": 0,
    "wind_generated": 76.50384276,
    "marketplace_to_demand": 108.22285,
    "grid_to_demand": 35456.86462,
    "govt_battery_charge": 4.721647421
  },
  {
    "timestamp": "26/05/24 6:00",
    "demand": 35675.32801,
    "solar_generated": 15.96525606,
    "wind_generated": 60.66210054,
    "marketplace_to_demand": 43.96790212,
    "grid_to_demand": 29998.96805,
    "govt_battery_charge": 73.2354752
  },
  {
    "timestamp": "26/05/24 7:00",
    "demand": 34944.76096,
    "solar_generated": 8.512961114,
    "wind_generated": 63.78220833,
    "marketplace_to_demand": 0.248524255,
    "grid_to_demand": 31494.84733,
    "govt_battery_charge": 37.9944971
  },
  {
    "timestamp": "26/05/24 8:00",
    "demand": 35272.4255,
    "solar_generated": 12.76978626,
    "wind_generated": 61.93059014,
    "marketplace_to_demand": 56.15261186,
    "grid_to_demand": 30311.62089,
    "govt_battery_charge": 28.97956518
  },
  {
    "timestamp": "26/05/24 9:00",
    "demand": 32669.03561,
    "solar_generated": 16.32325105,
    "wind_generated": 72.36245488,
    "marketplace_to_demand": 179.8829701,
    "grid_to_demand": 30958.64418,
    "govt_battery_charge": 50.24004736
  },
  {
    "timestamp": "26/05/24 10:00",
    "demand": 33175.4658,
    "solar_generated": 21.27226149,
    "wind_generated": 80.18324852,
    "marketplace_to_demand": 150.5800459,
    "grid_to_demand": 33123.03894,
    "govt_battery_charge": 18.2550953
  },
  {
    "timestamp": "26/05/24 11:00",
    "demand": 31261.41504,
    "solar_generated": 16.07470563,
    "wind_generated": 78.33445597,
    "marketplace_to_demand": 104.8609564,
    "grid_to_demand": 31179.20088,
    "govt_battery_charge": 31.67316666
  },
  {
    "timestamp": "26/05/24 12:00",
    "demand": 32813.32291,
    "solar_generated": 12.7750081,
    "wind_generated": 61.39362957,
    "marketplace_to_demand": 37.27777868,
    "grid_to_demand": 33150.87455,
    "govt_battery_charge": 76.39301822
  },
  {
    "timestamp": "26/05/24 13:00",
    "demand": 34407.71063,
    "solar_generated": 14.61226328,
    "wind_generated": 60.29629038,
    "marketplace_to_demand": 97.53295291,
    "grid_to_demand": 33928.82971,
    "govt_battery_charge": 91.45110495
  },
  {
    "timestamp": "26/05/24 14:00",
    "demand": 34991.75131,
    "solar_generated": 21.77555775,
    "wind_generated": 55.84713664,
    "marketplace_to_demand": 24.59351288,
    "grid_to_demand": 31298.71534,
    "govt_battery_charge": 56.59108277
  },
  {
    "timestamp": "26/05/24 15:00",
    "demand": 30858.55142,
    "solar_generated": 6.346916632,
    "wind_generated": 76.47008816,
    "marketplace_to_demand": 17.28108078,
    "grid_to_demand": 29962.07144,
    "govt_battery_charge": 84.35470424
  },
  {
    "timestamp": "26/05/24 16:00",
    "demand": 32556.29617,
    "solar_generated": 12.34143786,
    "wind_generated": 59.1050931,
    "marketplace_to_demand": 9.908017038,
    "grid_to_demand": 30777.32006,
    "govt_battery_charge": 22.73889219
  },
  {
    "timestamp": "26/05/24 17:00",
    "demand": 32074.96859,
    "solar_generated": 6.309611017,
    "wind_generated": 79.01968714,
    "marketplace_to_demand": 38.88997064,
    "grid_to_demand": 32359.80124,
    "govt_battery_charge": 14.57293827
  },
  {
    "timestamp": "26/05/24 18:00",
    "demand": 34459.40483,
    "solar_generated": 0,
    "wind_generated": 64.65444623,
    "marketplace_to_demand": 79.0604481,
    "grid_to_demand": 33601.72062,
    "govt_battery_charge": 66.63371784
  },
  {
    "timestamp": "26/05/24 19:00",
    "demand": 30567.88796,
    "solar_generated": 0,
    "wind_generated": 76.46003322,
    "marketplace_to_demand": 90.99603832,
    "grid_to_demand": 34084.43532,
    "govt_battery_charge": 41.50220312
  },
  {
    "timestamp": "26/05/24 20:00",
    "demand": 33313.53526,
    "solar_generated": 0,
    "wind_generated": 65.64384238,
    "marketplace_to_demand": 115.7460423,
    "grid_to_demand": 32883.78574,
    "govt_battery_charge": 14.0902484
  },
  {
    "timestamp": "26/05/24 21:00",
    "demand": 31672.71121,
    "solar_generated": 0,
    "wind_generated": 63.84213828,
    "marketplace_to_demand": 140.6370464,
    "grid_to_demand": 36331.55256,
    "govt_battery_charge": 23.98583424
  },
  {
    "timestamp": "26/05/24 22:00",
    "demand": 32598.76229,
    "solar_generated": 0,
    "wind_generated": 75.45716363,
    "marketplace_to_demand": 188.8374773,
    "grid_to_demand": 32278.68732,
    "govt_battery_charge": 22.46368503
  },
  {
    "timestamp": "26/05/24 23:00",
    "demand": 30545.89455,
    "solar_generated": 0,
    "wind_generated": 74.6033425,
    "marketplace_to_demand": 106.2878795,
    "grid_to_demand": 32784.37273,
    "govt_battery_charge": 5.251938777
  },
  {
    "timestamp": "27/05/24 0:00",
    "demand": 34500.61464,
    "solar_generated": 0,
    "wind_generated": 79.01664206,
    "marketplace_to_demand": 86.34240848,
    "grid_to_demand": 35030.53354,
    "govt_battery_charge": 35.64808235
  },
  {
    "timestamp": "27/05/24 1:00",
    "demand": 31801.50054,
    "solar_generated": 0,
    "wind_generated": 68.45087294,
    "marketplace_to_demand": 182.5327542,
    "grid_to_demand": 34649.72804,
    "govt_battery_charge": 29.85800304
  },
  {
    "timestamp": "27/05/24 2:00",
    "demand": 31290.13228,
    "solar_generated": 0,
    "wind_generated": 62.88264156,
    "marketplace_to_demand": 199.1467543,
    "grid_to_demand": 33145.49875,
    "govt_battery_charge": 59.81677167
  },
  {
    "timestamp": "27/05/24 3:00",
    "demand": 32675.16441,
    "solar_generated": 0,
    "wind_generated": 62.95412612,
    "marketplace_to_demand": 32.76763391,
    "grid_to_demand": 30407.54139,
    "govt_battery_charge": 42.88425278
  },
  {
    "timestamp": "27/05/24 4:00",
    "demand": 33796.44385,
    "solar_generated": 0,
    "wind_generated": 79.04201063,
    "marketplace_to_demand": 100.7601647,
    "grid_to_demand": 31597.90798,
    "govt_battery_charge": 47.08202956
  },
  {
    "timestamp": "27/05/24 5:00",
    "demand": 36072.06133,
    "solar_generated": 0,
    "wind_generated": 71.76740639,
    "marketplace_to_demand": 180.2940335,
    "grid_to_demand": 30988.7612,
    "govt_battery_charge": 79.23020282
  },
  {
    "timestamp": "27/05/24 6:00",
    "demand": 31736.8235,
    "solar_generated": 11.19452888,
    "wind_generated": 68.41095017,
    "marketplace_to_demand": 74.18090856,
    "grid_to_demand": 32881.05034,
    "govt_battery_charge": 45.39155599
  },
  {
    "timestamp": "27/05/24 7:00",
    "demand": 35138.82016,
    "solar_generated": 8.758968483,
    "wind_generated": 72.52607645,
    "marketplace_to_demand": 169.0945768,
    "grid_to_demand": 31933.02133,
    "govt_battery_charge": 99.39292655
  },
  {
    "timestamp": "27/05/24 8:00",
    "demand": 34845.2509,
    "solar_generated": 15.70445193,
    "wind_generated": 64.20832088,
    "marketplace_to_demand": 49.73260254,
    "grid_to_demand": 31511.60714,
    "govt_battery_charge": 31.12231219
  },
  {
    "timestamp": "27/05/24 9:00",
    "demand": 31363.08642,
    "solar_generated": 14.93272868,
    "wind_generated": 58.7329139,
    "marketplace_to_demand": 94.88466073,
    "grid_to_demand": 34888.33178,
    "govt_battery_charge": 25.77559535
  },
  {
    "timestamp": "27/05/24 10:00",
    "demand": 33838.67906,
    "solar_generated": 10.67193586,
    "wind_generated": 79.82815249,
    "marketplace_to_demand": 49.09747658,
    "grid_to_demand": 34773.44389,
    "govt_battery_charge": 47.68958407
  },
  {
    "timestamp": "27/05/24 11:00",
    "demand": 32270.93914,
    "solar_generated": 7.53332281,
    "wind_generated": 59.1035205,
    "marketplace_to_demand": 112.2246809,
    "grid_to_demand": 33475.09209,
    "govt_battery_charge": 28.13052823
  },
  {
    "timestamp": "27/05/24 12:00",
    "demand": 30761.89746,
    "solar_generated": 12.83924859,
    "wind_generated": 67.30534108,
    "marketplace_to_demand": 94.06089073,
    "grid_to_demand": 33266.38794,
    "govt_battery_charge": 38.5916415
  },
  {
    "timestamp": "27/05/24 13:00",
    "demand": 36341.91931,
    "solar_generated": 22.26501532,
    "wind_generated": 56.36968181,
    "marketplace_to_demand": 21.05929139,
    "grid_to_demand": 34196.76137,
    "govt_battery_charge": 41.67821923
  },
  {
    "timestamp": "27/05/24 14:00",
    "demand": 35297.14437,
    "solar_generated": 8.654205903,
    "wind_generated": 63.36382081,
    "marketplace_to_demand": 122.3278139,
    "grid_to_demand": 31336.50008,
    "govt_battery_charge": 83.86467387
  },
  {
    "timestamp": "27/05/24 15:00",
    "demand": 30186.48608,
    "solar_generated": 8.122616805,
    "wind_generated": 56.26797519,
    "marketplace_to_demand": 88.87430421,
    "grid_to_demand": 30099.13584,
    "govt_battery_charge": 98.16464041
  },
  {
    "timestamp": "27/05/24 16:00",
    "demand": 30349.52972,
    "solar_generated": 16.59431817,
    "wind_generated": 58.70995175,
    "marketplace_to_demand": 65.22651791,
    "grid_to_demand": 30999.12328,
    "govt_battery_charge": 22.48920585
  },
  {
    "timestamp": "27/05/24 17:00",
    "demand": 32777.44898,
    "solar_generated": 18.75798555,
    "wind_generated": 75.614945,
    "marketplace_to_demand": 82.98117547,
    "grid_to_demand": 33771.53647,
    "govt_battery_charge": 12.28345109
  },
  {
    "timestamp": "27/05/24 18:00",
    "demand": 31606.48786,
    "solar_generated": 0,
    "wind_generated": 67.46307847,
    "marketplace_to_demand": 138.8950425,
    "grid_to_demand": 36439.38446,
    "govt_battery_charge": 83.75770817
  },
  {
    "timestamp": "27/05/24 19:00",
    "demand": 30270.01419,
    "solar_generated": 0,
    "wind_generated": 64.95103688,
    "marketplace_to_demand": 119.2736961,
    "grid_to_demand": 35514.80304,
    "govt_battery_charge": 38.95935052
  },
  {
    "timestamp": "27/05/24 20:00",
    "demand": 33023.91703,
    "solar_generated": 0,
    "wind_generated": 59.10238272,
    "marketplace_to_demand": 157.3915249,
    "grid_to_demand": 31444.52517,
    "govt_battery_charge": 32.5606959
  },
  {
    "timestamp": "27/05/24 21:00",
    "demand": 34062.83437,
    "solar_generated": 0,
    "wind_generated": 72.9881608,
    "marketplace_to_demand": 157.7080077,
    "grid_to_demand": 33889.10934,
    "govt_battery_charge": 84.04059789
  },
  {
    "timestamp": "27/05/24 22:00",
    "demand": 35590.27521,
    "solar_generated": 0,
    "wind_generated": 75.66816643,
    "marketplace_to_demand": 149.0629885,
    "grid_to_demand": 31622.54954,
    "govt_battery_charge": 97.97428645
  },
  {
    "timestamp": "27/05/24 23:00",
    "demand": 33099.68241,
    "solar_generated": 0,
    "wind_generated": 71.01244219,
    "marketplace_to_demand": 28.63810178,
    "grid_to_demand": 35795.36935,
    "govt_battery_charge": 27.31503337
  },
  {
    "timestamp": "28/05/24 0:00",
    "demand": 31242.26729,
    "solar_generated": 0,
    "wind_generated": 58.53607543,
    "marketplace_to_demand": 52.9463489,
    "grid_to_demand": 36425.2938,
    "govt_battery_charge": 76.19433841
  },
  {
    "timestamp": "28/05/24 1:00",
    "demand": 33041.23251,
    "solar_generated": 0,
    "wind_generated": 70.63843838,
    "marketplace_to_demand": 190.8154671,
    "grid_to_demand": 31108.72254,
    "govt_battery_charge": 40.63666199
  },
  {
    "timestamp": "28/05/24 2:00",
    "demand": 35371.25069,
    "solar_generated": 0,
    "wind_generated": 67.59116995,
    "marketplace_to_demand": 5.515021048,
    "grid_to_demand": 36443.4345,
    "govt_battery_charge": 68.96460799
  },
  {
    "timestamp": "28/05/24 3:00",
    "demand": 33520.66988,
    "solar_generated": 0,
    "wind_generated": 68.95294277,
    "marketplace_to_demand": 19.78833995,
    "grid_to_demand": 36280.67085,
    "govt_battery_charge": 31.5863508
  },
  {
    "timestamp": "28/05/24 4:00",
    "demand": 31732.13582,
    "solar_generated": 0,
    "wind_generated": 62.25688552,
    "marketplace_to_demand": 118.1773101,
    "grid_to_demand": 32743.80016,
    "govt_battery_charge": 79.06466757
  },
  {
    "timestamp": "28/05/24 5:00",
    "demand": 32321.97628,
    "solar_generated": 0,
    "wind_generated": 67.68485337,
    "marketplace_to_demand": 7.37246432,
    "grid_to_demand": 36258.27992,
    "govt_battery_charge": 74.26193101
  },
  {
    "timestamp": "28/05/24 6:00",
    "demand": 31353.60557,
    "solar_generated": 14.17492374,
    "wind_generated": 55.7363901,
    "marketplace_to_demand": 110.1004599,
    "grid_to_demand": 30847.3612,
    "govt_battery_charge": 79.42392239
  },
  {
    "timestamp": "28/05/24 7:00",
    "demand": 32797.93498,
    "solar_generated": 15.42795262,
    "wind_generated": 76.06746201,
    "marketplace_to_demand": 144.2481579,
    "grid_to_demand": 35572.99966,
    "govt_battery_charge": 95.15332417
  },
  {
    "timestamp": "28/05/24 8:00",
    "demand": 36387.125,
    "solar_generated": 21.87537155,
    "wind_generated": 75.38434172,
    "marketplace_to_demand": 136.2532831,
    "grid_to_demand": 32130.19482,
    "govt_battery_charge": 41.67308683
  },
  {
    "timestamp": "28/05/24 9:00",
    "demand": 33715.82209,
    "solar_generated": 15.16495656,
    "wind_generated": 67.21330009,
    "marketplace_to_demand": 91.44571433,
    "grid_to_demand": 33139.54947,
    "govt_battery_charge": 75.85234517
  },
  {
    "timestamp": "28/05/24 10:00",
    "demand": 32664.77384,
    "solar_generated": 17.27410676,
    "wind_generated": 58.42855525,
    "marketplace_to_demand": 128.7263874,
    "grid_to_demand": 30420.0918,
    "govt_battery_charge": 58.09771957
  },
  {
    "timestamp": "28/05/24 11:00",
    "demand": 35295.33471,
    "solar_generated": 22.69130867,
    "wind_generated": 75.5787192,
    "marketplace_to_demand": 58.82659966,
    "grid_to_demand": 30457.35759,
    "govt_battery_charge": 43.91968642
  },
  {
    "timestamp": "28/05/24 12:00",
    "demand": 32777.07142,
    "solar_generated": 19.94522543,
    "wind_generated": 75.89466162,
    "marketplace_to_demand": 99.84907831,
    "grid_to_demand": 31814.43702,
    "govt_battery_charge": 57.73264646
  },
  {
    "timestamp": "28/05/24 13:00",
    "demand": 30781.26381,
    "solar_generated": 10.13042546,
    "wind_generated": 65.78059167,
    "marketplace_to_demand": 136.8663174,
    "grid_to_demand": 35384.80897,
    "govt_battery_charge": 23.13875351
  },
  {
    "timestamp": "28/05/24 14:00",
    "demand": 30468.05339,
    "solar_generated": 19.50445599,
    "wind_generated": 71.29740869,
    "marketplace_to_demand": 3.080536803,
    "grid_to_demand": 33480.23341,
    "govt_battery_charge": 36.29915668
  },
  {
    "timestamp": "28/05/24 15:00",
    "demand": 33719.28228,
    "solar_generated": 23.3235326,
    "wind_generated": 78.13456525,
    "marketplace_to_demand": 49.39978895,
    "grid_to_demand": 34106.69937,
    "govt_battery_charge": 58.25917117
  },
  {
    "timestamp": "28/05/24 16:00",
    "demand": 33410.81631,
    "solar_generated": 19.15840651,
    "wind_generated": 66.71278791,
    "marketplace_to_demand": 165.4141937,
    "grid_to_demand": 32203.20309,
    "govt_battery_charge": 40.59976292
  },
  {
    "timestamp": "28/05/24 17:00",
    "demand": 33739.06265,
    "solar_generated": 21.22926682,
    "wind_generated": 72.24699216,
    "marketplace_to_demand": 0.903260262,
    "grid_to_demand": 34692.04739,
    "govt_battery_charge": 8.873707758
  },
  {
    "timestamp": "28/05/24 18:00",
    "demand": 32467.10925,
    "solar_generated": 0,
    "wind_generated": 61.6621605,
    "marketplace_to_demand": 32.35605544,
    "grid_to_demand": 34755.17022,
    "govt_battery_charge": 20.27328165
  },
  {
    "timestamp": "28/05/24 19:00",
    "demand": 36330.17502,
    "solar_generated": 0,
    "wind_generated": 63.72825663,
    "marketplace_to_demand": 6.239960721,
    "grid_to_demand": 30808.17193,
    "govt_battery_charge": 35.76825464
  },
  {
    "timestamp": "28/05/24 20:00",
    "demand": 36311.02948,
    "solar_generated": 0,
    "wind_generated": 76.42048255,
    "marketplace_to_demand": 29.84823102,
    "grid_to_demand": 34053.31756,
    "govt_battery_charge": 46.41138116
  },
  {
    "timestamp": "28/05/24 21:00",
    "demand": 30306.34034,
    "solar_generated": 0,
    "wind_generated": 66.80517883,
    "marketplace_to_demand": 105.8236855,
    "grid_to_demand": 32614.83576,
    "govt_battery_charge": 34.9302403
  },
  {
    "timestamp": "28/05/24 22:00",
    "demand": 32426.26991,
    "solar_generated": 0,
    "wind_generated": 79.61174763,
    "marketplace_to_demand": 189.6748357,
    "grid_to_demand": 35702.12219,
    "govt_battery_charge": 74.76242973
  },
  {
    "timestamp": "28/05/24 23:00",
    "demand": 35086.11855,
    "solar_generated": 0,
    "wind_generated": 59.77474477,
    "marketplace_to_demand": 181.3708359,
    "grid_to_demand": 35568.15709,
    "govt_battery_charge": 96.41041399
  },
  {
    "timestamp": "29/05/24 0:00",
    "demand": 36603.17807,
    "solar_generated": 0,
    "wind_generated": 66.29069804,
    "marketplace_to_demand": 159.3363977,
    "grid_to_demand": 34998.09953,
    "govt_battery_charge": 94.71040103
  },
  {
    "timestamp": "29/05/24 1:00",
    "demand": 32056.9357,
    "solar_generated": 0,
    "wind_generated": 61.43329686,
    "marketplace_to_demand": 88.82185642,
    "grid_to_demand": 35316.3817,
    "govt_battery_charge": 30.64197335
  },
  {
    "timestamp": "29/05/24 2:00",
    "demand": 31569.89131,
    "solar_generated": 0,
    "wind_generated": 73.41056373,
    "marketplace_to_demand": 128.8334154,
    "grid_to_demand": 31138.71006,
    "govt_battery_charge": 61.9164352
  },
  {
    "timestamp": "29/05/24 3:00",
    "demand": 35782.93517,
    "solar_generated": 0,
    "wind_generated": 68.47454727,
    "marketplace_to_demand": 192.1957885,
    "grid_to_demand": 35657.14239,
    "govt_battery_charge": 21.42236001
  },
  {
    "timestamp": "29/05/24 4:00",
    "demand": 34990.3024,
    "solar_generated": 0,
    "wind_generated": 77.17754682,
    "marketplace_to_demand": 147.3293815,
    "grid_to_demand": 31633.8192,
    "govt_battery_charge": 2.159236678
  },
  {
    "timestamp": "29/05/24 5:00",
    "demand": 34261.02882,
    "solar_generated": 0,
    "wind_generated": 76.72177385,
    "marketplace_to_demand": 15.45771026,
    "grid_to_demand": 32492.21594,
    "govt_battery_charge": 42.67728151
  },
  {
    "timestamp": "29/05/24 6:00",
    "demand": 30433.68366,
    "solar_generated": 6.204474137,
    "wind_generated": 76.01627754,
    "marketplace_to_demand": 115.2907088,
    "grid_to_demand": 36404.63433,
    "govt_battery_charge": 16.49449256
  },
  {
    "timestamp": "29/05/24 7:00",
    "demand": 34780.20985,
    "solar_generated": 14.57217871,
    "wind_generated": 59.97906085,
    "marketplace_to_demand": 82.45508703,
    "grid_to_demand": 36113.83612,
    "govt_battery_charge": 20.00958713
  },
  {
    "timestamp": "29/05/24 8:00",
    "demand": 36589.67502,
    "solar_generated": 8.663113972,
    "wind_generated": 64.40035871,
    "marketplace_to_demand": 75.90735328,
    "grid_to_demand": 31575.56437,
    "govt_battery_charge": 83.1754287
  },
  {
    "timestamp": "29/05/24 9:00",
    "demand": 31791.08219,
    "solar_generated": 21.74251834,
    "wind_generated": 63.92563074,
    "marketplace_to_demand": 52.49171333,
    "grid_to_demand": 35184.23114,
    "govt_battery_charge": 34.10870921
  },
  {
    "timestamp": "29/05/24 10:00",
    "demand": 36473.29414,
    "solar_generated": 10.57407432,
    "wind_generated": 75.17299178,
    "marketplace_to_demand": 94.20017757,
    "grid_to_demand": 35017.81227,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "29/05/24 11:00",
    "demand": 32472.4402,
    "solar_generated": 13.39280174,
    "wind_generated": 61.08481593,
    "marketplace_to_demand": 110.3802407,
    "grid_to_demand": 32806.84052,
    "govt_battery_charge": 74.40961433
  },
  {
    "timestamp": "29/05/24 12:00",
    "demand": 33647.6034,
    "solar_generated": 7.453090673,
    "wind_generated": 80.01033714,
    "marketplace_to_demand": 50.33211767,
    "grid_to_demand": 34407.36705,
    "govt_battery_charge": 86.79092178
  },
  {
    "timestamp": "29/05/24 13:00",
    "demand": 31336.00274,
    "solar_generated": 9.562493435,
    "wind_generated": 56.9526403,
    "marketplace_to_demand": 60.82729157,
    "grid_to_demand": 31971.12255,
    "govt_battery_charge": 78.84912851
  },
  {
    "timestamp": "29/05/24 14:00",
    "demand": 34752.33616,
    "solar_generated": 8.856867851,
    "wind_generated": 67.06905411,
    "marketplace_to_demand": 194.3274379,
    "grid_to_demand": 32071.65227,
    "govt_battery_charge": 44.98508834
  },
  {
    "timestamp": "29/05/24 15:00",
    "demand": 31194.2024,
    "solar_generated": 23.13501931,
    "wind_generated": 75.00690045,
    "marketplace_to_demand": 131.6746895,
    "grid_to_demand": 35632.09068,
    "govt_battery_charge": 43.1504194
  },
  {
    "timestamp": "29/05/24 16:00",
    "demand": 35009.29608,
    "solar_generated": 10.67224507,
    "wind_generated": 69.5096845,
    "marketplace_to_demand": 103.4186403,
    "grid_to_demand": 35400.70286,
    "govt_battery_charge": 80.70103557
  },
  {
    "timestamp": "29/05/24 17:00",
    "demand": 33328.48184,
    "solar_generated": 13.15382202,
    "wind_generated": 66.85960226,
    "marketplace_to_demand": 169.9099558,
    "grid_to_demand": 35870.89657,
    "govt_battery_charge": 74.56198006
  },
  {
    "timestamp": "29/05/24 18:00",
    "demand": 34524.91952,
    "solar_generated": 0,
    "wind_generated": 56.21450869,
    "marketplace_to_demand": 149.5133503,
    "grid_to_demand": 33545.20535,
    "govt_battery_charge": 81.32769518
  },
  {
    "timestamp": "29/05/24 19:00",
    "demand": 30776.70468,
    "solar_generated": 0,
    "wind_generated": 64.89278891,
    "marketplace_to_demand": 167.3538205,
    "grid_to_demand": 33274.92174,
    "govt_battery_charge": 24.804852
  },
  {
    "timestamp": "29/05/24 20:00",
    "demand": 35005.67374,
    "solar_generated": 0,
    "wind_generated": 64.88687127,
    "marketplace_to_demand": 114.7858753,
    "grid_to_demand": 32183.08781,
    "govt_battery_charge": 64.52814444
  },
  {
    "timestamp": "29/05/24 21:00",
    "demand": 33501.07051,
    "solar_generated": 0,
    "wind_generated": 66.38276335,
    "marketplace_to_demand": 47.32834061,
    "grid_to_demand": 32289.60637,
    "govt_battery_charge": 98.65472121
  },
  {
    "timestamp": "29/05/24 22:00",
    "demand": 31128.79339,
    "solar_generated": 0,
    "wind_generated": 59.79500346,
    "marketplace_to_demand": 196.9910305,
    "grid_to_demand": 31448.15151,
    "govt_battery_charge": 70.98198068
  },
  {
    "timestamp": "29/05/24 23:00",
    "demand": 33424.28359,
    "solar_generated": 0,
    "wind_generated": 73.82472816,
    "marketplace_to_demand": 10.03402339,
    "grid_to_demand": 35270.91471,
    "govt_battery_charge": 3.912365358
  },
  {
    "timestamp": "30/05/24 0:00",
    "demand": 32018.5932,
    "solar_generated": 0,
    "wind_generated": 55.34583599,
    "marketplace_to_demand": 163.2251328,
    "grid_to_demand": 36460.293,
    "govt_battery_charge": 35.4984615
  },
  {
    "timestamp": "30/05/24 1:00",
    "demand": 35421.99678,
    "solar_generated": 0,
    "wind_generated": 56.66487629,
    "marketplace_to_demand": 91.82038613,
    "grid_to_demand": 29999.41693,
    "govt_battery_charge": 36.26388717
  },
  {
    "timestamp": "30/05/24 2:00",
    "demand": 33595.27336,
    "solar_generated": 0,
    "wind_generated": 61.47752114,
    "marketplace_to_demand": 63.38119308,
    "grid_to_demand": 30884.63203,
    "govt_battery_charge": 25.25450618
  },
  {
    "timestamp": "30/05/24 3:00",
    "demand": 32186.43922,
    "solar_generated": 0,
    "wind_generated": 71.72313842,
    "marketplace_to_demand": 45.19483523,
    "grid_to_demand": 35558.3837,
    "govt_battery_charge": 58.33201994
  },
  {
    "timestamp": "30/05/24 4:00",
    "demand": 32892.90348,
    "solar_generated": 0,
    "wind_generated": 58.46084656,
    "marketplace_to_demand": 135.6260637,
    "grid_to_demand": 32184.69485,
    "govt_battery_charge": 18.37508518
  },
  {
    "timestamp": "30/05/24 5:00",
    "demand": 30636.98131,
    "solar_generated": 0,
    "wind_generated": 56.14355375,
    "marketplace_to_demand": 84.20977864,
    "grid_to_demand": 31381.82179,
    "govt_battery_charge": 95.06301039
  },
  {
    "timestamp": "30/05/24 6:00",
    "demand": 35337.56692,
    "solar_generated": 8.288814584,
    "wind_generated": 79.34067,
    "marketplace_to_demand": 168.9241622,
    "grid_to_demand": 30137.74179,
    "govt_battery_charge": 69.15769078
  },
  {
    "timestamp": "30/05/24 7:00",
    "demand": 34683.87031,
    "solar_generated": 12.79033794,
    "wind_generated": 79.41324467,
    "marketplace_to_demand": 189.6943252,
    "grid_to_demand": 33631.94914,
    "govt_battery_charge": 50.15061402
  },
  {
    "timestamp": "30/05/24 8:00",
    "demand": 35227.72398,
    "solar_generated": 22.85546083,
    "wind_generated": 54.66400464,
    "marketplace_to_demand": 115.6269364,
    "grid_to_demand": 34265.74088,
    "govt_battery_charge": 87.73281082
  },
  {
    "timestamp": "30/05/24 9:00",
    "demand": 32210.86456,
    "solar_generated": 15.68609624,
    "wind_generated": 77.12141961,
    "marketplace_to_demand": 29.56189587,
    "grid_to_demand": 36454.89878,
    "govt_battery_charge": 83.45845815
  },
  {
    "timestamp": "30/05/24 10:00",
    "demand": 35509.08314,
    "solar_generated": 13.49374733,
    "wind_generated": 56.83489675,
    "marketplace_to_demand": 66.69517263,
    "grid_to_demand": 35756.91463,
    "govt_battery_charge": 75.03952904
  },
  {
    "timestamp": "30/05/24 11:00",
    "demand": 36318.21048,
    "solar_generated": 15.70950903,
    "wind_generated": 58.21321107,
    "marketplace_to_demand": 45.61410656,
    "grid_to_demand": 34498.68904,
    "govt_battery_charge": 75.09925124
  },
  {
    "timestamp": "30/05/24 12:00",
    "demand": 30383.54442,
    "solar_generated": 17.99762719,
    "wind_generated": 78.63228969,
    "marketplace_to_demand": 118.6246019,
    "grid_to_demand": 33152.84226,
    "govt_battery_charge": 86.04239627
  },
  {
    "timestamp": "30/05/24 13:00",
    "demand": 30050.79804,
    "solar_generated": 21.40026897,
    "wind_generated": 57.09837863,
    "marketplace_to_demand": 102.2734758,
    "grid_to_demand": 30025.77012,
    "govt_battery_charge": 59.76170312
  },
  {
    "timestamp": "30/05/24 14:00",
    "demand": 31963.34278,
    "solar_generated": 11.54255549,
    "wind_generated": 62.19350485,
    "marketplace_to_demand": 18.3483334,
    "grid_to_demand": 32927.11885,
    "govt_battery_charge": 35.71883472
  },
  {
    "timestamp": "30/05/24 15:00",
    "demand": 34100.50692,
    "solar_generated": 20.28775089,
    "wind_generated": 70.11230157,
    "marketplace_to_demand": 59.31733125,
    "grid_to_demand": 35333.27305,
    "govt_battery_charge": 98.18739415
  },
  {
    "timestamp": "30/05/24 16:00",
    "demand": 33485.80621,
    "solar_generated": 9.659126573,
    "wind_generated": 78.25009497,
    "marketplace_to_demand": 72.58872138,
    "grid_to_demand": 30441.48701,
    "govt_battery_charge": 37.24580869
  },
  {
    "timestamp": "30/05/24 17:00",
    "demand": 32939.08066,
    "solar_generated": 11.26042314,
    "wind_generated": 79.17787233,
    "marketplace_to_demand": 177.9904986,
    "grid_to_demand": 30562.82354,
    "govt_battery_charge": 53.03766412
  },
  {
    "timestamp": "30/05/24 18:00",
    "demand": 32093.7099,
    "solar_generated": 0,
    "wind_generated": 68.59540188,
    "marketplace_to_demand": 52.23980771,
    "grid_to_demand": 33157.7554,
    "govt_battery_charge": 21.45113406
  },
  {
    "timestamp": "30/05/24 19:00",
    "demand": 31805.9656,
    "solar_generated": 0,
    "wind_generated": 63.43454421,
    "marketplace_to_demand": 64.41156351,
    "grid_to_demand": 33988.49204,
    "govt_battery_charge": 4.781596393
  },
  {
    "timestamp": "30/05/24 20:00",
    "demand": 33298.60342,
    "solar_generated": 0,
    "wind_generated": 78.23361907,
    "marketplace_to_demand": 43.15567572,
    "grid_to_demand": 34267.38691,
    "govt_battery_charge": 70.73959684
  },
  {
    "timestamp": "30/05/24 21:00",
    "demand": 32314.5927,
    "solar_generated": 0,
    "wind_generated": 59.53675818,
    "marketplace_to_demand": 75.47872474,
    "grid_to_demand": 36551.31881,
    "govt_battery_charge": 64.03287788
  },
  {
    "timestamp": "30/05/24 22:00",
    "demand": 30920.97656,
    "solar_generated": 0,
    "wind_generated": 80.14806985,
    "marketplace_to_demand": 36.98616479,
    "grid_to_demand": 36377.53463,
    "govt_battery_charge": 70.37817847
  },
  {
    "timestamp": "30/05/24 23:00",
    "demand": 33801.46158,
    "solar_generated": 0,
    "wind_generated": 65.29803486,
    "marketplace_to_demand": 45.8516097,
    "grid_to_demand": 31509.94389,
    "govt_battery_charge": 35.01406418
  },
  {
    "timestamp": "31/05/24 0:00",
    "demand": 34485.68697,
    "solar_generated": 0,
    "wind_generated": 66.12182799,
    "marketplace_to_demand": 168.7156429,
    "grid_to_demand": 31239.10708,
    "govt_battery_charge": 45.26886903
  },
  {
    "timestamp": "31/05/24 1:00",
    "demand": 35557.67292,
    "solar_generated": 0,
    "wind_generated": 55.94897596,
    "marketplace_to_demand": 79.27953641,
    "grid_to_demand": 35196.36765,
    "govt_battery_charge": 56.68010747
  },
  {
    "timestamp": "31/05/24 2:00",
    "demand": 33371.17883,
    "solar_generated": 0,
    "wind_generated": 79.23337495,
    "marketplace_to_demand": 140.4029219,
    "grid_to_demand": 31209.07315,
    "govt_battery_charge": 87.80499442
  },
  {
    "timestamp": "31/05/24 3:00",
    "demand": 31353.82499,
    "solar_generated": 0,
    "wind_generated": 58.72249951,
    "marketplace_to_demand": 47.28458494,
    "grid_to_demand": 31693.99384,
    "govt_battery_charge": 28.40670464
  },
  {
    "timestamp": "31/05/24 4:00",
    "demand": 35351.07375,
    "solar_generated": 0,
    "wind_generated": 55.10928816,
    "marketplace_to_demand": 36.43673661,
    "grid_to_demand": 31946.67755,
    "govt_battery_charge": 85.00046018
  },
  {
    "timestamp": "31/05/24 5:00",
    "demand": 30177.18034,
    "solar_generated": 0,
    "wind_generated": 58.14528135,
    "marketplace_to_demand": 110.0794239,
    "grid_to_demand": 30938.80589,
    "govt_battery_charge": 59.30769975
  },
  {
    "timestamp": "31/05/24 6:00",
    "demand": 31899.87034,
    "solar_generated": 23.38886376,
    "wind_generated": 77.26015152,
    "marketplace_to_demand": 111.28078,
    "grid_to_demand": 32826.93318,
    "govt_battery_charge": 12.05189236
  },
  {
    "timestamp": "31/05/24 7:00",
    "demand": 31813.42574,
    "solar_generated": 12.4467104,
    "wind_generated": 78.60031961,
    "marketplace_to_demand": 59.85829128,
    "grid_to_demand": 31897.80419,
    "govt_battery_charge": 68.99551342
  },
  {
    "timestamp": "31/05/24 8:00",
    "demand": 33272.7045,
    "solar_generated": 15.90830335,
    "wind_generated": 76.96170514,
    "marketplace_to_demand": 48.23970567,
    "grid_to_demand": 32396.5412,
    "govt_battery_charge": 96.95033818
  },
  {
    "timestamp": "31/05/24 9:00",
    "demand": 31066.87045,
    "solar_generated": 21.85164261,
    "wind_generated": 72.60168036,
    "marketplace_to_demand": 119.641621,
    "grid_to_demand": 33045.67386,
    "govt_battery_charge": 50.92567905
  },
  {
    "timestamp": "31/05/24 10:00",
    "demand": 32310.20467,
    "solar_generated": 6.805576654,
    "wind_generated": 68.16111916,
    "marketplace_to_demand": 98.59199188,
    "grid_to_demand": 35048.28466,
    "govt_battery_charge": 13.14545377
  },
  {
    "timestamp": "31/05/24 11:00",
    "demand": 35961.80243,
    "solar_generated": 7.10586083,
    "wind_generated": 70.52492139,
    "marketplace_to_demand": 63.12462427,
    "grid_to_demand": 35003.72744,
    "govt_battery_charge": 40.90107864
  },
  {
    "timestamp": "31/05/24 12:00",
    "demand": 36575.06457,
    "solar_generated": 18.43529197,
    "wind_generated": 59.87762515,
    "marketplace_to_demand": 128.0084527,
    "grid_to_demand": 33227.12762,
    "govt_battery_charge": 34.91421348
  },
  {
    "timestamp": "31/05/24 13:00",
    "demand": 31867.80356,
    "solar_generated": 9.3671282,
    "wind_generated": 71.36589272,
    "marketplace_to_demand": 52.42970368,
    "grid_to_demand": 30360.91087,
    "govt_battery_charge": 26.61461283
  },
  {
    "timestamp": "31/05/24 14:00",
    "demand": 31193.82079,
    "solar_generated": 13.09608723,
    "wind_generated": 57.49708117,
    "marketplace_to_demand": 15.22253688,
    "grid_to_demand": 36492.74601,
    "govt_battery_charge": 17.6649977
  },
  {
    "timestamp": "31/05/24 15:00",
    "demand": 31363.34665,
    "solar_generated": 8.490736832,
    "wind_generated": 73.71067832,
    "marketplace_to_demand": 123.5133194,
    "grid_to_demand": 33805.70492,
    "govt_battery_charge": 34.19794603
  },
  {
    "timestamp": "31/05/24 16:00",
    "demand": 35738.12482,
    "solar_generated": 22.67487062,
    "wind_generated": 75.59545725,
    "marketplace_to_demand": 122.290426,
    "grid_to_demand": 31587.06381,
    "govt_battery_charge": 29.20810712
  },
  {
    "timestamp": "31/05/24 17:00",
    "demand": 35888.07967,
    "solar_generated": 11.89415746,
    "wind_generated": 64.86523726,
    "marketplace_to_demand": 2.067549819,
    "grid_to_demand": 32624.02772,
    "govt_battery_charge": 56.65959371
  },
  {
    "timestamp": "31/05/24 18:00",
    "demand": 36477.61181,
    "solar_generated": 0,
    "wind_generated": 78.32608525,
    "marketplace_to_demand": 138.5371088,
    "grid_to_demand": 30389.79782,
    "govt_battery_charge": 74.71593604
  },
  {
    "timestamp": "31/05/24 19:00",
    "demand": 31892.16029,
    "solar_generated": 0,
    "wind_generated": 60.2256201,
    "marketplace_to_demand": 88.94688301,
    "grid_to_demand": 30296.02661,
    "govt_battery_charge": 46.0222519
  },
  {
    "timestamp": "31/05/24 20:00",
    "demand": 32878.98604,
    "solar_generated": 0,
    "wind_generated": 64.11710656,
    "marketplace_to_demand": 167.0734895,
    "grid_to_demand": 31198.60925,
    "govt_battery_charge": 12.03537913
  },
  {
    "timestamp": "31/05/24 21:00",
    "demand": 36565.25742,
    "solar_generated": 0,
    "wind_generated": 69.8146228,
    "marketplace_to_demand": 4.327870591,
    "grid_to_demand": 35706.24726,
    "govt_battery_charge": 48.24967437
  },
  {
    "timestamp": "31/05/24 22:00",
    "demand": 31095.91998,
    "solar_generated": 0,
    "wind_generated": 64.28459352,
    "marketplace_to_demand": 29.23583625,
    "grid_to_demand": 33300.48238,
    "govt_battery_charge": 51.84875862
  },
  {
    "timestamp": "31/05/24 23:00",
    "demand": 34310.62059,
    "solar_generated": 0,
    "wind_generated": 59.48225029,
    "marketplace_to_demand": 173.7640601,
    "grid_to_demand": 33542.42584,
    "govt_battery_charge": 97.05829681
  },
  {
    "timestamp": "01/06/24 0:00",
    "demand": 36125.92703,
    "solar_generated": 0,
    "wind_generated": 65.16986675,
    "marketplace_to_demand": 60.15669588,
    "grid_to_demand": 34771.55187,
    "govt_battery_charge": 30.94256437
  },
  {
    "timestamp": "01/06/24 1:00",
    "demand": 35165.95509,
    "solar_generated": 0,
    "wind_generated": 71.99373667,
    "marketplace_to_demand": 191.9332445,
    "grid_to_demand": 34088.01594,
    "govt_battery_charge": 4.781649584
  },
  {
    "timestamp": "01/06/24 2:00",
    "demand": 30664.14637,
    "solar_generated": 0,
    "wind_generated": 70.45100583,
    "marketplace_to_demand": 22.29957067,
    "grid_to_demand": 35495.52065,
    "govt_battery_charge": 29.01512285
  },
  {
    "timestamp": "01/06/24 3:00",
    "demand": 32470.85755,
    "solar_generated": 0,
    "wind_generated": 72.04200698,
    "marketplace_to_demand": 180.3339246,
    "grid_to_demand": 32779.78466,
    "govt_battery_charge": 1.55837005
  },
  {
    "timestamp": "01/06/24 4:00",
    "demand": 35330.93921,
    "solar_generated": 0,
    "wind_generated": 65.07747933,
    "marketplace_to_demand": 103.0363353,
    "grid_to_demand": 31785.34139,
    "govt_battery_charge": 84.34279691
  },
  {
    "timestamp": "01/06/24 5:00",
    "demand": 31838.08885,
    "solar_generated": 0,
    "wind_generated": 59.18451092,
    "marketplace_to_demand": 174.6177027,
    "grid_to_demand": 31574.40744,
    "govt_battery_charge": 47.37823528
  },
  {
    "timestamp": "01/06/24 6:00",
    "demand": 34368.86693,
    "solar_generated": 8.249861299,
    "wind_generated": 77.42437391,
    "marketplace_to_demand": 197.7268434,
    "grid_to_demand": 32219.25965,
    "govt_battery_charge": 76.59191634
  },
  {
    "timestamp": "01/06/24 7:00",
    "demand": 35988.35357,
    "solar_generated": 14.41956476,
    "wind_generated": 79.36308823,
    "marketplace_to_demand": 160.7771456,
    "grid_to_demand": 32206.70371,
    "govt_battery_charge": 57.09489353
  },
  {
    "timestamp": "01/06/24 8:00",
    "demand": 31758.3886,
    "solar_generated": 16.88563257,
    "wind_generated": 61.56138812,
    "marketplace_to_demand": 154.0707339,
    "grid_to_demand": 30892.62105,
    "govt_battery_charge": 83.07314963
  },
  {
    "timestamp": "01/06/24 9:00",
    "demand": 29984.72654,
    "solar_generated": 9.667088257,
    "wind_generated": 61.26269266,
    "marketplace_to_demand": 125.9384475,
    "grid_to_demand": 31047.09903,
    "govt_battery_charge": 61.1124936
  },
  {
    "timestamp": "01/06/24 10:00",
    "demand": 31694.26414,
    "solar_generated": 13.69437558,
    "wind_generated": 76.5164609,
    "marketplace_to_demand": 30.38094707,
    "grid_to_demand": 34484.87202,
    "govt_battery_charge": 95.69826563
  },
  {
    "timestamp": "01/06/24 11:00",
    "demand": 32890.30518,
    "solar_generated": 16.8038095,
    "wind_generated": 53.60664087,
    "marketplace_to_demand": 142.6116402,
    "grid_to_demand": 31348.63443,
    "govt_battery_charge": 24.62325302
  },
  {
    "timestamp": "01/06/24 12:00",
    "demand": 35881.11684,
    "solar_generated": 13.44630812,
    "wind_generated": 56.12010542,
    "marketplace_to_demand": 193.6313081,
    "grid_to_demand": 35261.30582,
    "govt_battery_charge": 21.70999727
  },
  {
    "timestamp": "01/06/24 13:00",
    "demand": 34016.70158,
    "solar_generated": 11.90026462,
    "wind_generated": 64.31764237,
    "marketplace_to_demand": 61.66689955,
    "grid_to_demand": 32201.31688,
    "govt_battery_charge": 44.89653767
  },
  {
    "timestamp": "01/06/24 14:00",
    "demand": 35990.53343,
    "solar_generated": 17.88504478,
    "wind_generated": 54.85637452,
    "marketplace_to_demand": 85.02960568,
    "grid_to_demand": 34982.83908,
    "govt_battery_charge": 17.24890999
  },
  {
    "timestamp": "01/06/24 15:00",
    "demand": 31080.39567,
    "solar_generated": 18.07795875,
    "wind_generated": 56.1709713,
    "marketplace_to_demand": 172.8065783,
    "grid_to_demand": 30387.31604,
    "govt_battery_charge": 88.82161585
  },
  {
    "timestamp": "01/06/24 16:00",
    "demand": 35385.32285,
    "solar_generated": 21.35009634,
    "wind_generated": 64.67033585,
    "marketplace_to_demand": 77.56299996,
    "grid_to_demand": 30973.66279,
    "govt_battery_charge": 31.97526265
  },
  {
    "timestamp": "01/06/24 17:00",
    "demand": 34391.92544,
    "solar_generated": 14.64007442,
    "wind_generated": 73.09975461,
    "marketplace_to_demand": 97.81318349,
    "grid_to_demand": 30117.84264,
    "govt_battery_charge": 84.92633974
  },
  {
    "timestamp": "01/06/24 18:00",
    "demand": 33918.62157,
    "solar_generated": 0,
    "wind_generated": 70.44765285,
    "marketplace_to_demand": 74.36536677,
    "grid_to_demand": 34351.61016,
    "govt_battery_charge": 65.67553758
  },
  {
    "timestamp": "01/06/24 19:00",
    "demand": 36385.48215,
    "solar_generated": 0,
    "wind_generated": 57.18672001,
    "marketplace_to_demand": 33.13335497,
    "grid_to_demand": 30025.23844,
    "govt_battery_charge": 33.66537674
  },
  {
    "timestamp": "01/06/24 20:00",
    "demand": 30488.48208,
    "solar_generated": 0,
    "wind_generated": 78.79709528,
    "marketplace_to_demand": 193.107921,
    "grid_to_demand": 34398.96331,
    "govt_battery_charge": 57.88433538
  },
  {
    "timestamp": "01/06/24 21:00",
    "demand": 32492.82456,
    "solar_generated": 0,
    "wind_generated": 79.77315173,
    "marketplace_to_demand": 52.44531482,
    "grid_to_demand": 30425.27171,
    "govt_battery_charge": 43.45353368
  },
  {
    "timestamp": "01/06/24 22:00",
    "demand": 34999.61727,
    "solar_generated": 0,
    "wind_generated": 54.33761659,
    "marketplace_to_demand": 85.90464504,
    "grid_to_demand": 30717.1442,
    "govt_battery_charge": 65.78738774
  },
  {
    "timestamp": "01/06/24 23:00",
    "demand": 31695.81902,
    "solar_generated": 0,
    "wind_generated": 73.40370129,
    "marketplace_to_demand": 64.65454453,
    "grid_to_demand": 31211.96479,
    "govt_battery_charge": 62.86991315
  },
  {
    "timestamp": "02/06/24 0:00",
    "demand": 35054.62144,
    "solar_generated": 0,
    "wind_generated": 55.8471316,
    "marketplace_to_demand": 127.7355506,
    "grid_to_demand": 36074.38866,
    "govt_battery_charge": 88.66129754
  },
  {
    "timestamp": "02/06/24 1:00",
    "demand": 32707.55102,
    "solar_generated": 0,
    "wind_generated": 64.02773583,
    "marketplace_to_demand": 7.708881897,
    "grid_to_demand": 32214.63302,
    "govt_battery_charge": 34.33065141
  },
  {
    "timestamp": "02/06/24 2:00",
    "demand": 31062.60144,
    "solar_generated": 0,
    "wind_generated": 73.63051607,
    "marketplace_to_demand": 123.4974014,
    "grid_to_demand": 35384.23959,
    "govt_battery_charge": 40.13131897
  },
  {
    "timestamp": "02/06/24 3:00",
    "demand": 30823.95112,
    "solar_generated": 0,
    "wind_generated": 64.47727133,
    "marketplace_to_demand": 98.9903303,
    "grid_to_demand": 34965.77241,
    "govt_battery_charge": 46.56558853
  },
  {
    "timestamp": "02/06/24 4:00",
    "demand": 34392.64015,
    "solar_generated": 0,
    "wind_generated": 63.84714032,
    "marketplace_to_demand": 71.88986767,
    "grid_to_demand": 34497.03826,
    "govt_battery_charge": 48.10610004
  },
  {
    "timestamp": "02/06/24 5:00",
    "demand": 31123.9376,
    "solar_generated": 0,
    "wind_generated": 67.74994629,
    "marketplace_to_demand": 92.22723569,
    "grid_to_demand": 35178.33169,
    "govt_battery_charge": 19.24644493
  },
  {
    "timestamp": "02/06/24 6:00",
    "demand": 36556.77298,
    "solar_generated": 12.48478326,
    "wind_generated": 62.9583363,
    "marketplace_to_demand": 19.05780968,
    "grid_to_demand": 32692.24118,
    "govt_battery_charge": 16.20468972
  },
  {
    "timestamp": "02/06/24 7:00",
    "demand": 34969.08359,
    "solar_generated": 11.56016241,
    "wind_generated": 78.21223583,
    "marketplace_to_demand": 87.92021044,
    "grid_to_demand": 30469.3438,
    "govt_battery_charge": 48.07688613
  },
  {
    "timestamp": "02/06/24 8:00",
    "demand": 34598.46401,
    "solar_generated": 16.45806192,
    "wind_generated": 73.35080731,
    "marketplace_to_demand": 161.0809033,
    "grid_to_demand": 34444.88337,
    "govt_battery_charge": 79.9033977
  },
  {
    "timestamp": "02/06/24 9:00",
    "demand": 31953.83076,
    "solar_generated": 19.80558266,
    "wind_generated": 63.24486883,
    "marketplace_to_demand": 59.20049045,
    "grid_to_demand": 32333.19179,
    "govt_battery_charge": 19.09666567
  },
  {
    "timestamp": "02/06/24 10:00",
    "demand": 34799.54763,
    "solar_generated": 10.77752794,
    "wind_generated": 61.3912328,
    "marketplace_to_demand": 43.71703428,
    "grid_to_demand": 32606.47379,
    "govt_battery_charge": 34.18701514
  },
  {
    "timestamp": "02/06/24 11:00",
    "demand": 31598.82866,
    "solar_generated": 21.24227097,
    "wind_generated": 65.03992891,
    "marketplace_to_demand": 54.24302718,
    "grid_to_demand": 31818.94643,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "02/06/24 12:00",
    "demand": 34359.26376,
    "solar_generated": 14.6810835,
    "wind_generated": 53.62400804,
    "marketplace_to_demand": 146.3714527,
    "grid_to_demand": 34849.81333,
    "govt_battery_charge": 4.630159088
  },
  {
    "timestamp": "02/06/24 13:00",
    "demand": 35256.53943,
    "solar_generated": 23.0693593,
    "wind_generated": 70.77065683,
    "marketplace_to_demand": 114.2043459,
    "grid_to_demand": 29973.35073,
    "govt_battery_charge": 21.55583866
  },
  {
    "timestamp": "02/06/24 14:00",
    "demand": 34294.17009,
    "solar_generated": 17.62048454,
    "wind_generated": 58.53898968,
    "marketplace_to_demand": 187.8433737,
    "grid_to_demand": 35779.16784,
    "govt_battery_charge": 79.7777754
  },
  {
    "timestamp": "02/06/24 15:00",
    "demand": 33489.17676,
    "solar_generated": 6.661962797,
    "wind_generated": 69.67861092,
    "marketplace_to_demand": 82.1496774,
    "grid_to_demand": 31128.78207,
    "govt_battery_charge": 91.35599111
  },
  {
    "timestamp": "02/06/24 16:00",
    "demand": 34501.03207,
    "solar_generated": 19.37814991,
    "wind_generated": 70.30749307,
    "marketplace_to_demand": 137.5223334,
    "grid_to_demand": 35748.55878,
    "govt_battery_charge": 78.91909305
  },
  {
    "timestamp": "02/06/24 17:00",
    "demand": 32368.73571,
    "solar_generated": 16.30136769,
    "wind_generated": 78.74809686,
    "marketplace_to_demand": 116.1834839,
    "grid_to_demand": 31068.0721,
    "govt_battery_charge": 94.70321165
  },
  {
    "timestamp": "02/06/24 18:00",
    "demand": 34230.57197,
    "solar_generated": 0,
    "wind_generated": 74.48828054,
    "marketplace_to_demand": 142.3009542,
    "grid_to_demand": 33874.73474,
    "govt_battery_charge": 32.60205019
  },
  {
    "timestamp": "02/06/24 19:00",
    "demand": 32212.86003,
    "solar_generated": 0,
    "wind_generated": 76.44177365,
    "marketplace_to_demand": 168.5936388,
    "grid_to_demand": 32869.19764,
    "govt_battery_charge": 53.27485925
  },
  {
    "timestamp": "02/06/24 20:00",
    "demand": 34114.61168,
    "solar_generated": 0,
    "wind_generated": 67.8128493,
    "marketplace_to_demand": 131.9455304,
    "grid_to_demand": 36535.64313,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "02/06/24 21:00",
    "demand": 31410.83328,
    "solar_generated": 0,
    "wind_generated": 60.10098848,
    "marketplace_to_demand": 107.039379,
    "grid_to_demand": 31882.17539,
    "govt_battery_charge": 73.78375267
  },
  {
    "timestamp": "02/06/24 22:00",
    "demand": 36185.43719,
    "solar_generated": 0,
    "wind_generated": 72.04493948,
    "marketplace_to_demand": 7.283426539,
    "grid_to_demand": 35535.48684,
    "govt_battery_charge": 94.83639295
  },
  {
    "timestamp": "02/06/24 23:00",
    "demand": 34126.70873,
    "solar_generated": 0,
    "wind_generated": 57.97729428,
    "marketplace_to_demand": 69.67763943,
    "grid_to_demand": 36542.18324,
    "govt_battery_charge": 11.93839263
  },
  {
    "timestamp": "03/06/24 0:00",
    "demand": 32852.75648,
    "solar_generated": 0,
    "wind_generated": 69.65586486,
    "marketplace_to_demand": 103.6035076,
    "grid_to_demand": 30997.56609,
    "govt_battery_charge": 81.34026588
  },
  {
    "timestamp": "03/06/24 1:00",
    "demand": 33204.62897,
    "solar_generated": 0,
    "wind_generated": 64.08212893,
    "marketplace_to_demand": 40.13570023,
    "grid_to_demand": 35110.83945,
    "govt_battery_charge": 76.80810608
  },
  {
    "timestamp": "03/06/24 2:00",
    "demand": 30184.87779,
    "solar_generated": 0,
    "wind_generated": 67.4886858,
    "marketplace_to_demand": 11.92846313,
    "grid_to_demand": 36440.66742,
    "govt_battery_charge": 57.11809894
  },
  {
    "timestamp": "03/06/24 3:00",
    "demand": 32360.42471,
    "solar_generated": 0,
    "wind_generated": 60.9668716,
    "marketplace_to_demand": 109.9118496,
    "grid_to_demand": 33425.74427,
    "govt_battery_charge": 15.12509515
  },
  {
    "timestamp": "03/06/24 4:00",
    "demand": 30173.49683,
    "solar_generated": 0,
    "wind_generated": 55.41450818,
    "marketplace_to_demand": 73.06343341,
    "grid_to_demand": 34777.6638,
    "govt_battery_charge": 59.08397642
  },
  {
    "timestamp": "03/06/24 5:00",
    "demand": 30353.93578,
    "solar_generated": 0,
    "wind_generated": 75.07285595,
    "marketplace_to_demand": 100.0146384,
    "grid_to_demand": 36519.34174,
    "govt_battery_charge": 6.542534615
  },
  {
    "timestamp": "03/06/24 6:00",
    "demand": 33435.93621,
    "solar_generated": 9.335619781,
    "wind_generated": 73.18798431,
    "marketplace_to_demand": 97.57987116,
    "grid_to_demand": 34260.23497,
    "govt_battery_charge": 2.569631202
  },
  {
    "timestamp": "03/06/24 7:00",
    "demand": 31905.61098,
    "solar_generated": 19.60639534,
    "wind_generated": 61.05198924,
    "marketplace_to_demand": 81.89209301,
    "grid_to_demand": 32963.41802,
    "govt_battery_charge": 26.84890921
  },
  {
    "timestamp": "03/06/24 8:00",
    "demand": 33699.06227,
    "solar_generated": 19.74214296,
    "wind_generated": 60.47370432,
    "marketplace_to_demand": 184.7193716,
    "grid_to_demand": 34730.40649,
    "govt_battery_charge": 78.91574012
  },
  {
    "timestamp": "03/06/24 9:00",
    "demand": 35761.41467,
    "solar_generated": 19.92390787,
    "wind_generated": 77.44653614,
    "marketplace_to_demand": 31.31109838,
    "grid_to_demand": 32854.51959,
    "govt_battery_charge": 87.47453083
  },
  {
    "timestamp": "03/06/24 10:00",
    "demand": 33961.06593,
    "solar_generated": 16.34332548,
    "wind_generated": 76.84816184,
    "marketplace_to_demand": 28.52684849,
    "grid_to_demand": 32321.74768,
    "govt_battery_charge": 25.77697623
  },
  {
    "timestamp": "03/06/24 11:00",
    "demand": 32025.0758,
    "solar_generated": 21.63225049,
    "wind_generated": 64.34280092,
    "marketplace_to_demand": 49.21971915,
    "grid_to_demand": 36067.73259,
    "govt_battery_charge": 72.6365506
  },
  {
    "timestamp": "03/06/24 12:00",
    "demand": 36299.30869,
    "solar_generated": 22.68277048,
    "wind_generated": 76.08581071,
    "marketplace_to_demand": 53.70301037,
    "grid_to_demand": 30804.50117,
    "govt_battery_charge": 7.143015223
  },
  {
    "timestamp": "03/06/24 13:00",
    "demand": 30718.21202,
    "solar_generated": 18.14905748,
    "wind_generated": 54.88616693,
    "marketplace_to_demand": 86.43368615,
    "grid_to_demand": 32414.47421,
    "govt_battery_charge": 13.19945
  },
  {
    "timestamp": "03/06/24 14:00",
    "demand": 34920.49632,
    "solar_generated": 20.70990307,
    "wind_generated": 58.96777154,
    "marketplace_to_demand": 56.68725743,
    "grid_to_demand": 34105.39436,
    "govt_battery_charge": 5.213918634
  },
  {
    "timestamp": "03/06/24 15:00",
    "demand": 32928.35428,
    "solar_generated": 22.18059485,
    "wind_generated": 75.08007577,
    "marketplace_to_demand": 6.738522751,
    "grid_to_demand": 34751.43523,
    "govt_battery_charge": 82.4883117
  },
  {
    "timestamp": "03/06/24 16:00",
    "demand": 30014.22988,
    "solar_generated": 12.68564556,
    "wind_generated": 58.51185371,
    "marketplace_to_demand": 21.8351805,
    "grid_to_demand": 35729.91495,
    "govt_battery_charge": 80.31599672
  },
  {
    "timestamp": "03/06/24 17:00",
    "demand": 30822.83299,
    "solar_generated": 19.69397199,
    "wind_generated": 63.70580026,
    "marketplace_to_demand": 161.2100971,
    "grid_to_demand": 32730.28801,
    "govt_battery_charge": 77.33023978
  },
  {
    "timestamp": "03/06/24 18:00",
    "demand": 32473.91023,
    "solar_generated": 0,
    "wind_generated": 75.9907131,
    "marketplace_to_demand": 38.58553165,
    "grid_to_demand": 32576.3489,
    "govt_battery_charge": 39.53156367
  },
  {
    "timestamp": "03/06/24 19:00",
    "demand": 30930.09125,
    "solar_generated": 0,
    "wind_generated": 70.09845361,
    "marketplace_to_demand": 68.79114366,
    "grid_to_demand": 31810.58765,
    "govt_battery_charge": 40.72321983
  },
  {
    "timestamp": "03/06/24 20:00",
    "demand": 31799.7438,
    "solar_generated": 0,
    "wind_generated": 77.23140986,
    "marketplace_to_demand": 178.2697715,
    "grid_to_demand": 34211.85134,
    "govt_battery_charge": 93.10957455
  },
  {
    "timestamp": "03/06/24 21:00",
    "demand": 32692.93897,
    "solar_generated": 0,
    "wind_generated": 58.2526796,
    "marketplace_to_demand": 102.4281726,
    "grid_to_demand": 32405.2034,
    "govt_battery_charge": 53.57970765
  },
  {
    "timestamp": "03/06/24 22:00",
    "demand": 33910.60913,
    "solar_generated": 0,
    "wind_generated": 55.42314708,
    "marketplace_to_demand": 0.969371181,
    "grid_to_demand": 34764.04165,
    "govt_battery_charge": 17.92438631
  },
  {
    "timestamp": "03/06/24 23:00",
    "demand": 35359.36476,
    "solar_generated": 0,
    "wind_generated": 56.13068276,
    "marketplace_to_demand": 76.852049,
    "grid_to_demand": 31975.21233,
    "govt_battery_charge": 58.72886128
  },
  {
    "timestamp": "04/06/24 0:00",
    "demand": 30826.84185,
    "solar_generated": 0,
    "wind_generated": 61.48828468,
    "marketplace_to_demand": 85.64532403,
    "grid_to_demand": 30352.41389,
    "govt_battery_charge": 5.024128893
  },
  {
    "timestamp": "04/06/24 1:00",
    "demand": 30359.49333,
    "solar_generated": 0,
    "wind_generated": 57.13988026,
    "marketplace_to_demand": 142.8918575,
    "grid_to_demand": 31264.06423,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "04/06/24 2:00",
    "demand": 34910.56755,
    "solar_generated": 0,
    "wind_generated": 53.65820033,
    "marketplace_to_demand": 12.18319426,
    "grid_to_demand": 34647.69409,
    "govt_battery_charge": 77.66438443
  },
  {
    "timestamp": "04/06/24 3:00",
    "demand": 30434.57043,
    "solar_generated": 0,
    "wind_generated": 55.96035793,
    "marketplace_to_demand": 143.8507791,
    "grid_to_demand": 34980.1886,
    "govt_battery_charge": 11.07310814
  },
  {
    "timestamp": "04/06/24 4:00",
    "demand": 32849.9268,
    "solar_generated": 0,
    "wind_generated": 69.59618476,
    "marketplace_to_demand": 6.997490621,
    "grid_to_demand": 31634.82683,
    "govt_battery_charge": 74.93375816
  },
  {
    "timestamp": "04/06/24 5:00",
    "demand": 34463.33736,
    "solar_generated": 0,
    "wind_generated": 66.4060678,
    "marketplace_to_demand": 78.14782511,
    "grid_to_demand": 32651.17251,
    "govt_battery_charge": 5.375290012
  },
  {
    "timestamp": "04/06/24 6:00",
    "demand": 34085.76508,
    "solar_generated": 20.1008173,
    "wind_generated": 75.0182412,
    "marketplace_to_demand": 98.99710561,
    "grid_to_demand": 35166.40352,
    "govt_battery_charge": 70.03236605
  },
  {
    "timestamp": "04/06/24 7:00",
    "demand": 34771.72763,
    "solar_generated": 23.91280452,
    "wind_generated": 65.3373405,
    "marketplace_to_demand": 193.6836563,
    "grid_to_demand": 30158.57123,
    "govt_battery_charge": 3.125596616
  },
  {
    "timestamp": "04/06/24 8:00",
    "demand": 31965.01566,
    "solar_generated": 16.17624943,
    "wind_generated": 55.90751326,
    "marketplace_to_demand": 20.38759718,
    "grid_to_demand": 35335.06611,
    "govt_battery_charge": 19.25681943
  },
  {
    "timestamp": "04/06/24 9:00",
    "demand": 33799.45879,
    "solar_generated": 15.25105927,
    "wind_generated": 80.23305015,
    "marketplace_to_demand": 199.988058,
    "grid_to_demand": 36218.80506,
    "govt_battery_charge": 83.20168465
  },
  {
    "timestamp": "04/06/24 10:00",
    "demand": 31353.08075,
    "solar_generated": 13.5359843,
    "wind_generated": 61.49950016,
    "marketplace_to_demand": 69.04917748,
    "grid_to_demand": 34633.18376,
    "govt_battery_charge": 5.554549042
  },
  {
    "timestamp": "04/06/24 11:00",
    "demand": 35983.41436,
    "solar_generated": 11.863579,
    "wind_generated": 72.91352165,
    "marketplace_to_demand": 31.89725152,
    "grid_to_demand": 30087.81914,
    "govt_battery_charge": 37.65511002
  },
  {
    "timestamp": "04/06/24 12:00",
    "demand": 32874.79931,
    "solar_generated": 10.6229586,
    "wind_generated": 77.29358327,
    "marketplace_to_demand": 167.066469,
    "grid_to_demand": 34125.8344,
    "govt_battery_charge": 76.04757028
  },
  {
    "timestamp": "04/06/24 13:00",
    "demand": 31721.05992,
    "solar_generated": 7.530164599,
    "wind_generated": 78.72121049,
    "marketplace_to_demand": 45.50701505,
    "grid_to_demand": 35284.85573,
    "govt_battery_charge": 26.6197404
  },
  {
    "timestamp": "04/06/24 14:00",
    "demand": 35456.1103,
    "solar_generated": 6.165537751,
    "wind_generated": 79.00570988,
    "marketplace_to_demand": 4.193502373,
    "grid_to_demand": 33062.80729,
    "govt_battery_charge": 37.44611035
  },
  {
    "timestamp": "04/06/24 15:00",
    "demand": 33876.93823,
    "solar_generated": 16.51268847,
    "wind_generated": 79.16115913,
    "marketplace_to_demand": 115.0379443,
    "grid_to_demand": 33955.54065,
    "govt_battery_charge": 23.58883089
  },
  {
    "timestamp": "04/06/24 16:00",
    "demand": 29974.80683,
    "solar_generated": 17.27249867,
    "wind_generated": 78.18314206,
    "marketplace_to_demand": 63.03272344,
    "grid_to_demand": 31804.37525,
    "govt_battery_charge": 38.82285615
  },
  {
    "timestamp": "04/06/24 17:00",
    "demand": 33542.64637,
    "solar_generated": 23.82599848,
    "wind_generated": 79.23204979,
    "marketplace_to_demand": 96.24279761,
    "grid_to_demand": 31904.87549,
    "govt_battery_charge": 67.52217879
  },
  {
    "timestamp": "04/06/24 18:00",
    "demand": 32530.47055,
    "solar_generated": 0,
    "wind_generated": 60.05686295,
    "marketplace_to_demand": 30.61947632,
    "grid_to_demand": 33233.39278,
    "govt_battery_charge": 28.08021957
  },
  {
    "timestamp": "04/06/24 19:00",
    "demand": 35669.64373,
    "solar_generated": 0,
    "wind_generated": 73.9303778,
    "marketplace_to_demand": 80.78712031,
    "grid_to_demand": 32670.97547,
    "govt_battery_charge": 18.37505006
  },
  {
    "timestamp": "04/06/24 20:00",
    "demand": 31365.84223,
    "solar_generated": 0,
    "wind_generated": 79.99704763,
    "marketplace_to_demand": 136.5032727,
    "grid_to_demand": 34074.38309,
    "govt_battery_charge": 70.83341531
  },
  {
    "timestamp": "04/06/24 21:00",
    "demand": 32122.22928,
    "solar_generated": 0,
    "wind_generated": 57.41097711,
    "marketplace_to_demand": 55.64160801,
    "grid_to_demand": 33850.12821,
    "govt_battery_charge": 23.97725324
  },
  {
    "timestamp": "04/06/24 22:00",
    "demand": 33841.97774,
    "solar_generated": 0,
    "wind_generated": 58.88497216,
    "marketplace_to_demand": 103.0609407,
    "grid_to_demand": 33216.43847,
    "govt_battery_charge": 59.42672667
  },
  {
    "timestamp": "04/06/24 23:00",
    "demand": 35092.808,
    "solar_generated": 0,
    "wind_generated": 73.48952674,
    "marketplace_to_demand": 123.1306778,
    "grid_to_demand": 32756.75689,
    "govt_battery_charge": 26.60072925
  },
  {
    "timestamp": "05/06/24 0:00",
    "demand": 31136.35098,
    "solar_generated": 0,
    "wind_generated": 75.8779785,
    "marketplace_to_demand": 194.8229943,
    "grid_to_demand": 35243.44229,
    "govt_battery_charge": 56.88181655
  },
  {
    "timestamp": "05/06/24 1:00",
    "demand": 36275.53133,
    "solar_generated": 0,
    "wind_generated": 70.81683851,
    "marketplace_to_demand": 189.8930979,
    "grid_to_demand": 34701.72725,
    "govt_battery_charge": 97.66753002
  },
  {
    "timestamp": "05/06/24 2:00",
    "demand": 34766.61503,
    "solar_generated": 0,
    "wind_generated": 67.03137706,
    "marketplace_to_demand": 175.8897377,
    "grid_to_demand": 35272.95775,
    "govt_battery_charge": 95.97575308
  },
  {
    "timestamp": "05/06/24 3:00",
    "demand": 34056.39569,
    "solar_generated": 0,
    "wind_generated": 59.1920746,
    "marketplace_to_demand": 31.24402904,
    "grid_to_demand": 34079.78456,
    "govt_battery_charge": 65.82932295
  },
  {
    "timestamp": "05/06/24 4:00",
    "demand": 33978.02001,
    "solar_generated": 0,
    "wind_generated": 56.23700673,
    "marketplace_to_demand": 87.78970961,
    "grid_to_demand": 35805.99309,
    "govt_battery_charge": 88.0955283
  },
  {
    "timestamp": "05/06/24 5:00",
    "demand": 32669.07015,
    "solar_generated": 0,
    "wind_generated": 65.15373745,
    "marketplace_to_demand": 137.0195238,
    "grid_to_demand": 33555.2927,
    "govt_battery_charge": 7.983125595
  },
  {
    "timestamp": "05/06/24 6:00",
    "demand": 36099.22728,
    "solar_generated": 23.02232043,
    "wind_generated": 74.56810565,
    "marketplace_to_demand": 63.33996974,
    "grid_to_demand": 32800.6409,
    "govt_battery_charge": 88.28332697
  },
  {
    "timestamp": "05/06/24 7:00",
    "demand": 36531.96028,
    "solar_generated": 9.980662337,
    "wind_generated": 64.02260908,
    "marketplace_to_demand": 127.9102132,
    "grid_to_demand": 32620.36301,
    "govt_battery_charge": 60.12918566
  },
  {
    "timestamp": "05/06/24 8:00",
    "demand": 32650.35432,
    "solar_generated": 6.907487025,
    "wind_generated": 63.35143305,
    "marketplace_to_demand": 37.40736386,
    "grid_to_demand": 34756.02316,
    "govt_battery_charge": 57.9622511
  },
  {
    "timestamp": "05/06/24 9:00",
    "demand": 32611.77843,
    "solar_generated": 20.65671184,
    "wind_generated": 59.04589332,
    "marketplace_to_demand": 79.38355338,
    "grid_to_demand": 32885.83721,
    "govt_battery_charge": 16.37107418
  },
  {
    "timestamp": "05/06/24 10:00",
    "demand": 33117.41389,
    "solar_generated": 22.05451412,
    "wind_generated": 61.51813865,
    "marketplace_to_demand": 188.2495835,
    "grid_to_demand": 33583.89997,
    "govt_battery_charge": 31.02674635
  },
  {
    "timestamp": "05/06/24 11:00",
    "demand": 31954.34462,
    "solar_generated": 15.50046252,
    "wind_generated": 67.02116866,
    "marketplace_to_demand": 83.4560978,
    "grid_to_demand": 31330.31277,
    "govt_battery_charge": 71.8708614
  },
  {
    "timestamp": "05/06/24 12:00",
    "demand": 30499.24297,
    "solar_generated": 11.25905316,
    "wind_generated": 63.23276677,
    "marketplace_to_demand": 137.7442857,
    "grid_to_demand": 30768.12169,
    "govt_battery_charge": 9.188030809
  },
  {
    "timestamp": "05/06/24 13:00",
    "demand": 32820.27645,
    "solar_generated": 19.07636633,
    "wind_generated": 63.45781258,
    "marketplace_to_demand": 79.23450133,
    "grid_to_demand": 31951.64421,
    "govt_battery_charge": 35.45329057
  },
  {
    "timestamp": "05/06/24 14:00",
    "demand": 30834.28832,
    "solar_generated": 9.606546639,
    "wind_generated": 64.80341132,
    "marketplace_to_demand": 138.5950154,
    "grid_to_demand": 32107.35395,
    "govt_battery_charge": 70.03694983
  },
  {
    "timestamp": "05/06/24 15:00",
    "demand": 29970.5743,
    "solar_generated": 20.87919092,
    "wind_generated": 65.71440185,
    "marketplace_to_demand": 104.5259895,
    "grid_to_demand": 34476.13224,
    "govt_battery_charge": 98.44322847
  },
  {
    "timestamp": "05/06/24 16:00",
    "demand": 33197.33501,
    "solar_generated": 19.68071246,
    "wind_generated": 58.46871577,
    "marketplace_to_demand": 102.9744048,
    "grid_to_demand": 35166.95734,
    "govt_battery_charge": 21.15465379
  },
  {
    "timestamp": "05/06/24 17:00",
    "demand": 31324.22501,
    "solar_generated": 9.685798066,
    "wind_generated": 54.84664543,
    "marketplace_to_demand": 182.0381335,
    "grid_to_demand": 32985.84063,
    "govt_battery_charge": 25.49253611
  },
  {
    "timestamp": "05/06/24 18:00",
    "demand": 33524.94467,
    "solar_generated": 0,
    "wind_generated": 80.21754614,
    "marketplace_to_demand": 12.87270731,
    "grid_to_demand": 32477.56323,
    "govt_battery_charge": 98.30173487
  },
  {
    "timestamp": "05/06/24 19:00",
    "demand": 33945.09744,
    "solar_generated": 0,
    "wind_generated": 68.19171344,
    "marketplace_to_demand": 168.9263303,
    "grid_to_demand": 33325.98108,
    "govt_battery_charge": 74.29190774
  },
  {
    "timestamp": "05/06/24 20:00",
    "demand": 32314.40842,
    "solar_generated": 0,
    "wind_generated": 54.36287369,
    "marketplace_to_demand": 99.30139481,
    "grid_to_demand": 35469.47737,
    "govt_battery_charge": 91.36885376
  },
  {
    "timestamp": "05/06/24 21:00",
    "demand": 35052.58514,
    "solar_generated": 0,
    "wind_generated": 79.93788263,
    "marketplace_to_demand": 24.07397455,
    "grid_to_demand": 31692.0355,
    "govt_battery_charge": 72.43531515
  },
  {
    "timestamp": "05/06/24 22:00",
    "demand": 36087.95005,
    "solar_generated": 0,
    "wind_generated": 75.65198707,
    "marketplace_to_demand": 76.68958986,
    "grid_to_demand": 32560.92178,
    "govt_battery_charge": 30.56938866
  },
  {
    "timestamp": "05/06/24 23:00",
    "demand": 31370.14732,
    "solar_generated": 0,
    "wind_generated": 56.45221156,
    "marketplace_to_demand": 132.8537975,
    "grid_to_demand": 33372.90053,
    "govt_battery_charge": 40.05473993
  },
  {
    "timestamp": "06/06/24 0:00",
    "demand": 31886.89088,
    "solar_generated": 0,
    "wind_generated": 67.26253964,
    "marketplace_to_demand": 50.95627318,
    "grid_to_demand": 30677.17765,
    "govt_battery_charge": 90.56415821
  },
  {
    "timestamp": "06/06/24 1:00",
    "demand": 35960.16215,
    "solar_generated": 0,
    "wind_generated": 76.31410373,
    "marketplace_to_demand": 167.4218982,
    "grid_to_demand": 34527.16232,
    "govt_battery_charge": 2.730970001
  },
  {
    "timestamp": "06/06/24 2:00",
    "demand": 34590.22184,
    "solar_generated": 0,
    "wind_generated": 64.09619963,
    "marketplace_to_demand": 29.13631313,
    "grid_to_demand": 32853.01458,
    "govt_battery_charge": 75.36575286
  },
  {
    "timestamp": "06/06/24 3:00",
    "demand": 30745.06075,
    "solar_generated": 0,
    "wind_generated": 54.79285305,
    "marketplace_to_demand": 102.9489534,
    "grid_to_demand": 31481.69942,
    "govt_battery_charge": 2.077072067
  },
  {
    "timestamp": "06/06/24 4:00",
    "demand": 30800.30147,
    "solar_generated": 0,
    "wind_generated": 56.41981692,
    "marketplace_to_demand": 156.3152298,
    "grid_to_demand": 35198.45455,
    "govt_battery_charge": 71.91153578
  },
  {
    "timestamp": "06/06/24 5:00",
    "demand": 35853.63338,
    "solar_generated": 0,
    "wind_generated": 54.7522953,
    "marketplace_to_demand": 167.2112565,
    "grid_to_demand": 33296.90989,
    "govt_battery_charge": 57.21344518
  },
  {
    "timestamp": "06/06/24 6:00",
    "demand": 31906.4523,
    "solar_generated": 19.94950692,
    "wind_generated": 57.81466047,
    "marketplace_to_demand": 61.42298669,
    "grid_to_demand": 30411.45169,
    "govt_battery_charge": 98.91354257
  },
  {
    "timestamp": "06/06/24 7:00",
    "demand": 33879.82807,
    "solar_generated": 13.18214846,
    "wind_generated": 70.00279257,
    "marketplace_to_demand": 8.534749047,
    "grid_to_demand": 34138.44579,
    "govt_battery_charge": 84.85951772
  },
  {
    "timestamp": "06/06/24 8:00",
    "demand": 33607.71804,
    "solar_generated": 12.6195103,
    "wind_generated": 60.29281164,
    "marketplace_to_demand": 134.977942,
    "grid_to_demand": 30363.43181,
    "govt_battery_charge": 35.51822016
  },
  {
    "timestamp": "06/06/24 9:00",
    "demand": 33612.91655,
    "solar_generated": 7.192419722,
    "wind_generated": 68.25201066,
    "marketplace_to_demand": 23.02855794,
    "grid_to_demand": 31393.06882,
    "govt_battery_charge": 4.48059279
  },
  {
    "timestamp": "06/06/24 10:00",
    "demand": 35816.31566,
    "solar_generated": 20.11588315,
    "wind_generated": 77.08844417,
    "marketplace_to_demand": 190.3943305,
    "grid_to_demand": 32610.75012,
    "govt_battery_charge": 58.83468102
  },
  {
    "timestamp": "06/06/24 11:00",
    "demand": 34319.54379,
    "solar_generated": 14.24740544,
    "wind_generated": 53.94862351,
    "marketplace_to_demand": 25.43247309,
    "grid_to_demand": 30559.80138,
    "govt_battery_charge": 30.56565196
  },
  {
    "timestamp": "06/06/24 12:00",
    "demand": 30819.07731,
    "solar_generated": 12.11668922,
    "wind_generated": 54.14572476,
    "marketplace_to_demand": 179.1608383,
    "grid_to_demand": 33086.38797,
    "govt_battery_charge": 90.18787937
  },
  {
    "timestamp": "06/06/24 13:00",
    "demand": 31876.24185,
    "solar_generated": 11.06764554,
    "wind_generated": 64.3669198,
    "marketplace_to_demand": 128.0186034,
    "grid_to_demand": 31844.41253,
    "govt_battery_charge": 52.51992698
  },
  {
    "timestamp": "06/06/24 14:00",
    "demand": 33436.83309,
    "solar_generated": 13.86499076,
    "wind_generated": 68.61536541,
    "marketplace_to_demand": 62.62926002,
    "grid_to_demand": 35278.30834,
    "govt_battery_charge": 94.54039639
  },
  {
    "timestamp": "06/06/24 15:00",
    "demand": 32613.91679,
    "solar_generated": 22.6811784,
    "wind_generated": 74.95567305,
    "marketplace_to_demand": 177.9962261,
    "grid_to_demand": 32964.91853,
    "govt_battery_charge": 87.2066144
  },
  {
    "timestamp": "06/06/24 16:00",
    "demand": 32687.01121,
    "solar_generated": 11.72230764,
    "wind_generated": 78.94131821,
    "marketplace_to_demand": 70.37710277,
    "grid_to_demand": 31146.78525,
    "govt_battery_charge": 24.70350849
  },
  {
    "timestamp": "06/06/24 17:00",
    "demand": 35775.40583,
    "solar_generated": 19.89344213,
    "wind_generated": 74.56159796,
    "marketplace_to_demand": 150.1988985,
    "grid_to_demand": 36450.23697,
    "govt_battery_charge": 56.78074901
  },
  {
    "timestamp": "06/06/24 18:00",
    "demand": 32903.8998,
    "solar_generated": 0,
    "wind_generated": 58.61482954,
    "marketplace_to_demand": 25.09643359,
    "grid_to_demand": 30614.08635,
    "govt_battery_charge": 49.52855967
  },
  {
    "timestamp": "06/06/24 19:00",
    "demand": 30292.07699,
    "solar_generated": 0,
    "wind_generated": 79.83835138,
    "marketplace_to_demand": 141.6270513,
    "grid_to_demand": 34067.23732,
    "govt_battery_charge": 69.02518475
  },
  {
    "timestamp": "06/06/24 20:00",
    "demand": 34763.09694,
    "solar_generated": 0,
    "wind_generated": 66.23775599,
    "marketplace_to_demand": 106.2502597,
    "grid_to_demand": 32647.99442,
    "govt_battery_charge": 14.5060332
  },
  {
    "timestamp": "06/06/24 21:00",
    "demand": 36478.78287,
    "solar_generated": 0,
    "wind_generated": 58.26978748,
    "marketplace_to_demand": 4.57653617,
    "grid_to_demand": 32303.31751,
    "govt_battery_charge": 39.26019306
  },
  {
    "timestamp": "06/06/24 22:00",
    "demand": 35299.6183,
    "solar_generated": 0,
    "wind_generated": 59.75813539,
    "marketplace_to_demand": 127.6198725,
    "grid_to_demand": 33562.43977,
    "govt_battery_charge": 35.96429525
  },
  {
    "timestamp": "06/06/24 23:00",
    "demand": 35564.00423,
    "solar_generated": 0,
    "wind_generated": 65.87397829,
    "marketplace_to_demand": 99.05654427,
    "grid_to_demand": 32293.74212,
    "govt_battery_charge": 62.21428795
  },
  {
    "timestamp": "07/06/24 0:00",
    "demand": 36322.55717,
    "solar_generated": 0,
    "wind_generated": 54.82047319,
    "marketplace_to_demand": 73.71987996,
    "grid_to_demand": 32704.37705,
    "govt_battery_charge": 55.03371077
  },
  {
    "timestamp": "07/06/24 1:00",
    "demand": 35477.9497,
    "solar_generated": 0,
    "wind_generated": 56.50517774,
    "marketplace_to_demand": 180.3181928,
    "grid_to_demand": 33681.41774,
    "govt_battery_charge": 56.50626566
  },
  {
    "timestamp": "07/06/24 2:00",
    "demand": 33808.94111,
    "solar_generated": 0,
    "wind_generated": 70.32260613,
    "marketplace_to_demand": 114.5510219,
    "grid_to_demand": 34041.10173,
    "govt_battery_charge": 3.205793541
  },
  {
    "timestamp": "07/06/24 3:00",
    "demand": 31802.24853,
    "solar_generated": 0,
    "wind_generated": 76.29748516,
    "marketplace_to_demand": 59.77921131,
    "grid_to_demand": 35878.50807,
    "govt_battery_charge": 64.81151529
  },
  {
    "timestamp": "07/06/24 4:00",
    "demand": 35190.68904,
    "solar_generated": 0,
    "wind_generated": 55.34958149,
    "marketplace_to_demand": 51.2268983,
    "grid_to_demand": 35697.67531,
    "govt_battery_charge": 40.74046685
  },
  {
    "timestamp": "07/06/24 5:00",
    "demand": 34785.31489,
    "solar_generated": 0,
    "wind_generated": 57.43580492,
    "marketplace_to_demand": 166.7154185,
    "grid_to_demand": 33182.99377,
    "govt_battery_charge": 58.52810106
  },
  {
    "timestamp": "07/06/24 6:00",
    "demand": 36063.60852,
    "solar_generated": 11.50561503,
    "wind_generated": 71.16720544,
    "marketplace_to_demand": 154.5875872,
    "grid_to_demand": 34953.40813,
    "govt_battery_charge": 68.91686527
  },
  {
    "timestamp": "07/06/24 7:00",
    "demand": 31243.29663,
    "solar_generated": 11.19068802,
    "wind_generated": 65.67136188,
    "marketplace_to_demand": 181.9884563,
    "grid_to_demand": 33256.68005,
    "govt_battery_charge": 61.59034075
  },
  {
    "timestamp": "07/06/24 8:00",
    "demand": 36062.64557,
    "solar_generated": 14.20109793,
    "wind_generated": 55.18897072,
    "marketplace_to_demand": 64.86853575,
    "grid_to_demand": 35907.01883,
    "govt_battery_charge": 96.35714237
  },
  {
    "timestamp": "07/06/24 9:00",
    "demand": 34036.89344,
    "solar_generated": 22.73356394,
    "wind_generated": 71.2306791,
    "marketplace_to_demand": 19.56875225,
    "grid_to_demand": 31711.96465,
    "govt_battery_charge": 42.61325497
  },
  {
    "timestamp": "07/06/24 10:00",
    "demand": 33853.20659,
    "solar_generated": 13.92173154,
    "wind_generated": 60.04207063,
    "marketplace_to_demand": 42.13392258,
    "grid_to_demand": 30427.69794,
    "govt_battery_charge": 2.066403285
  },
  {
    "timestamp": "07/06/24 11:00",
    "demand": 35095.82629,
    "solar_generated": 23.57090563,
    "wind_generated": 64.71632742,
    "marketplace_to_demand": 42.46339979,
    "grid_to_demand": 31602.83011,
    "govt_battery_charge": 18.7289552
  },
  {
    "timestamp": "07/06/24 12:00",
    "demand": 32727.52918,
    "solar_generated": 23.60004098,
    "wind_generated": 75.649697,
    "marketplace_to_demand": 99.52880014,
    "grid_to_demand": 31556.72001,
    "govt_battery_charge": 7.951267167
  },
  {
    "timestamp": "07/06/24 13:00",
    "demand": 32756.58589,
    "solar_generated": 16.30993422,
    "wind_generated": 54.21485349,
    "marketplace_to_demand": 17.60215438,
    "grid_to_demand": 32639.03478,
    "govt_battery_charge": 51.6251879
  },
  {
    "timestamp": "07/06/24 14:00",
    "demand": 33943.37419,
    "solar_generated": 18.77613542,
    "wind_generated": 65.00335026,
    "marketplace_to_demand": 51.23499212,
    "grid_to_demand": 34933.02199,
    "govt_battery_charge": 68.7688131
  },
  {
    "timestamp": "07/06/24 15:00",
    "demand": 31112.57036,
    "solar_generated": 12.1286969,
    "wind_generated": 74.11226066,
    "marketplace_to_demand": 169.3186814,
    "grid_to_demand": 33410.19019,
    "govt_battery_charge": 3.636528
  },
  {
    "timestamp": "07/06/24 16:00",
    "demand": 35863.98088,
    "solar_generated": 17.26857221,
    "wind_generated": 68.5256725,
    "marketplace_to_demand": 192.7306118,
    "grid_to_demand": 31683.4141,
    "govt_battery_charge": 12.90407218
  },
  {
    "timestamp": "07/06/24 17:00",
    "demand": 34331.6949,
    "solar_generated": 14.0512327,
    "wind_generated": 55.91171845,
    "marketplace_to_demand": 35.64513339,
    "grid_to_demand": 30830.8126,
    "govt_battery_charge": 61.33976727
  },
  {
    "timestamp": "07/06/24 18:00",
    "demand": 31001.23819,
    "solar_generated": 0,
    "wind_generated": 56.95551768,
    "marketplace_to_demand": 16.4205421,
    "grid_to_demand": 33710.58402,
    "govt_battery_charge": 45.06674782
  },
  {
    "timestamp": "07/06/24 19:00",
    "demand": 34200.3828,
    "solar_generated": 0,
    "wind_generated": 66.42883997,
    "marketplace_to_demand": 85.12543178,
    "grid_to_demand": 33294.43964,
    "govt_battery_charge": 22.01414446
  },
  {
    "timestamp": "07/06/24 20:00",
    "demand": 32759.60204,
    "solar_generated": 0,
    "wind_generated": 72.79112541,
    "marketplace_to_demand": 64.09046358,
    "grid_to_demand": 34283.528,
    "govt_battery_charge": 45.18097679
  },
  {
    "timestamp": "07/06/24 21:00",
    "demand": 31280.02815,
    "solar_generated": 0,
    "wind_generated": 71.50945736,
    "marketplace_to_demand": 69.18762289,
    "grid_to_demand": 35192.02551,
    "govt_battery_charge": 92.45554002
  },
  {
    "timestamp": "07/06/24 22:00",
    "demand": 35035.40716,
    "solar_generated": 0,
    "wind_generated": 72.38760753,
    "marketplace_to_demand": 137.1244133,
    "grid_to_demand": 33795.67397,
    "govt_battery_charge": 20.39804758
  },
  {
    "timestamp": "07/06/24 23:00",
    "demand": 31410.74609,
    "solar_generated": 0,
    "wind_generated": 79.22606837,
    "marketplace_to_demand": 51.50978535,
    "grid_to_demand": 30461.91239,
    "govt_battery_charge": 43.56762159
  },
  {
    "timestamp": "08/06/24 0:00",
    "demand": 35901.76627,
    "solar_generated": 0,
    "wind_generated": 68.33929489,
    "marketplace_to_demand": 153.5528657,
    "grid_to_demand": 32979.84383,
    "govt_battery_charge": 24.05372228
  },
  {
    "timestamp": "08/06/24 1:00",
    "demand": 34678.65993,
    "solar_generated": 0,
    "wind_generated": 63.23806116,
    "marketplace_to_demand": 143.6138427,
    "grid_to_demand": 32871.57819,
    "govt_battery_charge": 17.00518003
  },
  {
    "timestamp": "08/06/24 2:00",
    "demand": 34774.10726,
    "solar_generated": 0,
    "wind_generated": 58.12063158,
    "marketplace_to_demand": 130.2069357,
    "grid_to_demand": 33409.72647,
    "govt_battery_charge": 62.02982775
  },
  {
    "timestamp": "08/06/24 3:00",
    "demand": 31984.78438,
    "solar_generated": 0,
    "wind_generated": 69.18223144,
    "marketplace_to_demand": 140.0759591,
    "grid_to_demand": 33908.7477,
    "govt_battery_charge": 66.85436643
  },
  {
    "timestamp": "08/06/24 4:00",
    "demand": 31778.22879,
    "solar_generated": 0,
    "wind_generated": 61.93464242,
    "marketplace_to_demand": 164.3735218,
    "grid_to_demand": 31354.75047,
    "govt_battery_charge": 81.38286911
  },
  {
    "timestamp": "08/06/24 5:00",
    "demand": 36172.20294,
    "solar_generated": 0,
    "wind_generated": 77.17978217,
    "marketplace_to_demand": 150.3372886,
    "grid_to_demand": 33579.63909,
    "govt_battery_charge": 86.95723002
  },
  {
    "timestamp": "08/06/24 6:00",
    "demand": 30697.17816,
    "solar_generated": 19.3451706,
    "wind_generated": 56.26747135,
    "marketplace_to_demand": 143.7982286,
    "grid_to_demand": 30794.21481,
    "govt_battery_charge": 30.35695641
  },
  {
    "timestamp": "08/06/24 7:00",
    "demand": 30385.86037,
    "solar_generated": 13.76960622,
    "wind_generated": 70.57169336,
    "marketplace_to_demand": 32.52484791,
    "grid_to_demand": 36358.52733,
    "govt_battery_charge": 90.07381795
  },
  {
    "timestamp": "08/06/24 8:00",
    "demand": 31195.91994,
    "solar_generated": 10.47050915,
    "wind_generated": 58.8108691,
    "marketplace_to_demand": 0.923239896,
    "grid_to_demand": 30400.87878,
    "govt_battery_charge": 79.714631
  },
  {
    "timestamp": "08/06/24 9:00",
    "demand": 30887.0253,
    "solar_generated": 11.49485956,
    "wind_generated": 68.36852014,
    "marketplace_to_demand": 158.1487902,
    "grid_to_demand": 32432.43392,
    "govt_battery_charge": 51.09458211
  },
  {
    "timestamp": "08/06/24 10:00",
    "demand": 31436.75716,
    "solar_generated": 21.43347953,
    "wind_generated": 69.62977048,
    "marketplace_to_demand": 161.3254185,
    "grid_to_demand": 33688.81684,
    "govt_battery_charge": 86.20653581
  },
  {
    "timestamp": "08/06/24 11:00",
    "demand": 29991.28657,
    "solar_generated": 16.01227344,
    "wind_generated": 71.93844694,
    "marketplace_to_demand": 180.8443386,
    "grid_to_demand": 34717.2871,
    "govt_battery_charge": 92.15802811
  },
  {
    "timestamp": "08/06/24 12:00",
    "demand": 36093.54864,
    "solar_generated": 6.870084861,
    "wind_generated": 61.74760953,
    "marketplace_to_demand": 197.2742594,
    "grid_to_demand": 35464.00965,
    "govt_battery_charge": 66.75079461
  },
  {
    "timestamp": "08/06/24 13:00",
    "demand": 34406.88679,
    "solar_generated": 22.32312723,
    "wind_generated": 58.96371442,
    "marketplace_to_demand": 105.4788359,
    "grid_to_demand": 32065.37669,
    "govt_battery_charge": 17.17951688
  },
  {
    "timestamp": "08/06/24 14:00",
    "demand": 31650.59056,
    "solar_generated": 18.86035769,
    "wind_generated": 59.97483163,
    "marketplace_to_demand": 111.9852192,
    "grid_to_demand": 30652.96646,
    "govt_battery_charge": 94.52242895
  },
  {
    "timestamp": "08/06/24 15:00",
    "demand": 32233.15255,
    "solar_generated": 15.9289255,
    "wind_generated": 70.92340608,
    "marketplace_to_demand": 107.9713911,
    "grid_to_demand": 31351.85004,
    "govt_battery_charge": 65.9623029
  },
  {
    "timestamp": "08/06/24 16:00",
    "demand": 31687.44093,
    "solar_generated": 9.79462645,
    "wind_generated": 67.67662285,
    "marketplace_to_demand": 141.5211567,
    "grid_to_demand": 34816.00456,
    "govt_battery_charge": 24.58605389
  },
  {
    "timestamp": "08/06/24 17:00",
    "demand": 30847.55488,
    "solar_generated": 14.60933396,
    "wind_generated": 78.48765502,
    "marketplace_to_demand": 160.3423943,
    "grid_to_demand": 31556.7495,
    "govt_battery_charge": 13.76900851
  },
  {
    "timestamp": "08/06/24 18:00",
    "demand": 35530.95256,
    "solar_generated": 0,
    "wind_generated": 80.22175609,
    "marketplace_to_demand": 114.008837,
    "grid_to_demand": 36330.14685,
    "govt_battery_charge": 30.86896226
  },
  {
    "timestamp": "08/06/24 19:00",
    "demand": 31287.8504,
    "solar_generated": 0,
    "wind_generated": 73.41270703,
    "marketplace_to_demand": 169.9252473,
    "grid_to_demand": 32124.69207,
    "govt_battery_charge": 24.00097524
  },
  {
    "timestamp": "08/06/24 20:00",
    "demand": 32023.92924,
    "solar_generated": 0,
    "wind_generated": 79.45413525,
    "marketplace_to_demand": 36.94447426,
    "grid_to_demand": 31271.38928,
    "govt_battery_charge": 33.72812509
  },
  {
    "timestamp": "08/06/24 21:00",
    "demand": 32315.67584,
    "solar_generated": 0,
    "wind_generated": 77.02216936,
    "marketplace_to_demand": 9.311386838,
    "grid_to_demand": 35832.63131,
    "govt_battery_charge": 74.12234981
  },
  {
    "timestamp": "08/06/24 22:00",
    "demand": 34800.99277,
    "solar_generated": 0,
    "wind_generated": 74.86513312,
    "marketplace_to_demand": 30.2848913,
    "grid_to_demand": 36034.40054,
    "govt_battery_charge": 20.10563281
  },
  {
    "timestamp": "08/06/24 23:00",
    "demand": 34209.91044,
    "solar_generated": 0,
    "wind_generated": 74.84439594,
    "marketplace_to_demand": 71.55111797,
    "grid_to_demand": 33884.48932,
    "govt_battery_charge": 72.26973566
  },
  {
    "timestamp": "09/06/24 0:00",
    "demand": 35681.43097,
    "solar_generated": 0,
    "wind_generated": 58.70799186,
    "marketplace_to_demand": 12.19202659,
    "grid_to_demand": 32673.27124,
    "govt_battery_charge": 44.15964533
  },
  {
    "timestamp": "09/06/24 1:00",
    "demand": 31145.19465,
    "solar_generated": 0,
    "wind_generated": 61.3808073,
    "marketplace_to_demand": 124.8389751,
    "grid_to_demand": 35677.26299,
    "govt_battery_charge": 99.42672225
  },
  {
    "timestamp": "09/06/24 2:00",
    "demand": 32424.92152,
    "solar_generated": 0,
    "wind_generated": 66.25268424,
    "marketplace_to_demand": 121.1364229,
    "grid_to_demand": 31506.23201,
    "govt_battery_charge": 15.30669698
  },
  {
    "timestamp": "09/06/24 3:00",
    "demand": 30547.6675,
    "solar_generated": 0,
    "wind_generated": 69.03995499,
    "marketplace_to_demand": 140.0837814,
    "grid_to_demand": 32449.96499,
    "govt_battery_charge": 20.83635405
  },
  {
    "timestamp": "09/06/24 4:00",
    "demand": 35748.59852,
    "solar_generated": 0,
    "wind_generated": 55.47707047,
    "marketplace_to_demand": 161.7136736,
    "grid_to_demand": 34812.1298,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/06/24 5:00",
    "demand": 33564.66218,
    "solar_generated": 0,
    "wind_generated": 74.99026485,
    "marketplace_to_demand": 26.28050882,
    "grid_to_demand": 31030.51166,
    "govt_battery_charge": 41.43575701
  },
  {
    "timestamp": "09/06/24 6:00",
    "demand": 35877.78567,
    "solar_generated": 13.53133275,
    "wind_generated": 55.84982966,
    "marketplace_to_demand": 63.65102822,
    "grid_to_demand": 35507.65571,
    "govt_battery_charge": 3.790584093
  },
  {
    "timestamp": "09/06/24 7:00",
    "demand": 33736.01002,
    "solar_generated": 22.9113884,
    "wind_generated": 63.62061193,
    "marketplace_to_demand": 69.96159684,
    "grid_to_demand": 32450.60113,
    "govt_battery_charge": 35.3199967
  },
  {
    "timestamp": "09/06/24 8:00",
    "demand": 31491.18834,
    "solar_generated": 12.76554653,
    "wind_generated": 80.010825,
    "marketplace_to_demand": 29.19468343,
    "grid_to_demand": 31962.88884,
    "govt_battery_charge": 73.77112136
  },
  {
    "timestamp": "09/06/24 9:00",
    "demand": 33381.0458,
    "solar_generated": 23.54189391,
    "wind_generated": 72.54206223,
    "marketplace_to_demand": 126.7960033,
    "grid_to_demand": 31176.42781,
    "govt_battery_charge": 72.62561541
  },
  {
    "timestamp": "09/06/24 10:00",
    "demand": 36122.5101,
    "solar_generated": 6.339110417,
    "wind_generated": 76.85998003,
    "marketplace_to_demand": 150.2969756,
    "grid_to_demand": 34177.13688,
    "govt_battery_charge": 63.54794122
  },
  {
    "timestamp": "09/06/24 11:00",
    "demand": 35624.6329,
    "solar_generated": 16.34684308,
    "wind_generated": 74.09517325,
    "marketplace_to_demand": 111.7427432,
    "grid_to_demand": 33813.49241,
    "govt_battery_charge": 10.08166873
  },
  {
    "timestamp": "09/06/24 12:00",
    "demand": 30799.53157,
    "solar_generated": 6.774345377,
    "wind_generated": 62.69616211,
    "marketplace_to_demand": 13.5911022,
    "grid_to_demand": 36217.15915,
    "govt_battery_charge": 90.88527309
  },
  {
    "timestamp": "09/06/24 13:00",
    "demand": 34078.11869,
    "solar_generated": 14.74372722,
    "wind_generated": 62.30096577,
    "marketplace_to_demand": 71.01132168,
    "grid_to_demand": 33432.53321,
    "govt_battery_charge": 61.58719134
  },
  {
    "timestamp": "09/06/24 14:00",
    "demand": 30202.49869,
    "solar_generated": 12.61173845,
    "wind_generated": 57.67831047,
    "marketplace_to_demand": 158.3584716,
    "grid_to_demand": 31834.64047,
    "govt_battery_charge": 37.63084005
  },
  {
    "timestamp": "09/06/24 15:00",
    "demand": 35972.71722,
    "solar_generated": 10.7442433,
    "wind_generated": 56.50690153,
    "marketplace_to_demand": 37.30252326,
    "grid_to_demand": 35167.94007,
    "govt_battery_charge": 22.75336002
  },
  {
    "timestamp": "09/06/24 16:00",
    "demand": 34472.45844,
    "solar_generated": 14.05879841,
    "wind_generated": 80.0893674,
    "marketplace_to_demand": 173.7531101,
    "grid_to_demand": 30112.43718,
    "govt_battery_charge": 66.51979684
  },
  {
    "timestamp": "09/06/24 17:00",
    "demand": 32778.04137,
    "solar_generated": 11.11853867,
    "wind_generated": 76.07242091,
    "marketplace_to_demand": 80.98893663,
    "grid_to_demand": 33015.9296,
    "govt_battery_charge": 3.716745623
  },
  {
    "timestamp": "09/06/24 18:00",
    "demand": 32465.49214,
    "solar_generated": 0,
    "wind_generated": 70.5408841,
    "marketplace_to_demand": 94.96998536,
    "grid_to_demand": 32134.35573,
    "govt_battery_charge": 89.40326291
  },
  {
    "timestamp": "09/06/24 19:00",
    "demand": 35708.99803,
    "solar_generated": 0,
    "wind_generated": 61.2813925,
    "marketplace_to_demand": 47.60947537,
    "grid_to_demand": 32418.81208,
    "govt_battery_charge": 67.58278163
  },
  {
    "timestamp": "09/06/24 20:00",
    "demand": 30085.84493,
    "solar_generated": 0,
    "wind_generated": 77.35561813,
    "marketplace_to_demand": 198.3559581,
    "grid_to_demand": 35949.75034,
    "govt_battery_charge": 6.031636106
  },
  {
    "timestamp": "09/06/24 21:00",
    "demand": 30098.35979,
    "solar_generated": 0,
    "wind_generated": 74.72180592,
    "marketplace_to_demand": 196.465305,
    "grid_to_demand": 32325.63114,
    "govt_battery_charge": 28.74302863
  },
  {
    "timestamp": "09/06/24 22:00",
    "demand": 33338.21722,
    "solar_generated": 0,
    "wind_generated": 76.86003734,
    "marketplace_to_demand": 29.50420292,
    "grid_to_demand": 30680.19955,
    "govt_battery_charge": 40.3660573
  },
  {
    "timestamp": "09/06/24 23:00",
    "demand": 31183.50862,
    "solar_generated": 0,
    "wind_generated": 74.35039429,
    "marketplace_to_demand": 110.6748923,
    "grid_to_demand": 33803.47288,
    "govt_battery_charge": 60.07902396
  },
  {
    "timestamp": "10/06/24 0:00",
    "demand": 32813.80902,
    "solar_generated": 0,
    "wind_generated": 78.28333709,
    "marketplace_to_demand": 6.836882032,
    "grid_to_demand": 33157.04223,
    "govt_battery_charge": 9.229359991
  },
  {
    "timestamp": "10/06/24 1:00",
    "demand": 32376.83069,
    "solar_generated": 0,
    "wind_generated": 66.44217133,
    "marketplace_to_demand": 38.06200556,
    "grid_to_demand": 30286.37717,
    "govt_battery_charge": 99.17634664
  },
  {
    "timestamp": "10/06/24 2:00",
    "demand": 33155.76837,
    "solar_generated": 0,
    "wind_generated": 72.43435045,
    "marketplace_to_demand": 62.08226041,
    "grid_to_demand": 34034.14376,
    "govt_battery_charge": 26.29856095
  },
  {
    "timestamp": "10/06/24 3:00",
    "demand": 36141.7144,
    "solar_generated": 0,
    "wind_generated": 72.46672893,
    "marketplace_to_demand": 142.0713508,
    "grid_to_demand": 34213.75861,
    "govt_battery_charge": 24.77056287
  },
  {
    "timestamp": "10/06/24 4:00",
    "demand": 32764.76491,
    "solar_generated": 0,
    "wind_generated": 74.99225938,
    "marketplace_to_demand": 22.35622333,
    "grid_to_demand": 32193.10134,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "10/06/24 5:00",
    "demand": 30348.76615,
    "solar_generated": 0,
    "wind_generated": 78.23740608,
    "marketplace_to_demand": 126.5750807,
    "grid_to_demand": 36392.2163,
    "govt_battery_charge": 79.49370386
  },
  {
    "timestamp": "10/06/24 6:00",
    "demand": 31603.04189,
    "solar_generated": 11.07153035,
    "wind_generated": 78.33049109,
    "marketplace_to_demand": 5.667295406,
    "grid_to_demand": 34335.21139,
    "govt_battery_charge": 49.00348341
  },
  {
    "timestamp": "10/06/24 7:00",
    "demand": 34810.24003,
    "solar_generated": 15.69822389,
    "wind_generated": 54.19880477,
    "marketplace_to_demand": 28.54966941,
    "grid_to_demand": 33180.04151,
    "govt_battery_charge": 47.64352218
  },
  {
    "timestamp": "10/06/24 8:00",
    "demand": 32590.13748,
    "solar_generated": 7.43728735,
    "wind_generated": 79.95622311,
    "marketplace_to_demand": 149.0114102,
    "grid_to_demand": 31861.30685,
    "govt_battery_charge": 40.29170981
  },
  {
    "timestamp": "10/06/24 9:00",
    "demand": 33864.83618,
    "solar_generated": 19.77067551,
    "wind_generated": 54.0464005,
    "marketplace_to_demand": 186.6896766,
    "grid_to_demand": 32634.3324,
    "govt_battery_charge": 43.71229804
  },
  {
    "timestamp": "10/06/24 10:00",
    "demand": 34290.34913,
    "solar_generated": 20.31107012,
    "wind_generated": 61.4816776,
    "marketplace_to_demand": 132.3657579,
    "grid_to_demand": 31345.20941,
    "govt_battery_charge": 78.43673438
  },
  {
    "timestamp": "10/06/24 11:00",
    "demand": 32188.15842,
    "solar_generated": 15.55884451,
    "wind_generated": 64.9075424,
    "marketplace_to_demand": 127.7790148,
    "grid_to_demand": 30855.72797,
    "govt_battery_charge": 8.290369363
  },
  {
    "timestamp": "10/06/24 12:00",
    "demand": 31338.58609,
    "solar_generated": 15.53567711,
    "wind_generated": 67.22915045,
    "marketplace_to_demand": 147.2811725,
    "grid_to_demand": 34131.27662,
    "govt_battery_charge": 50.17781417
  },
  {
    "timestamp": "10/06/24 13:00",
    "demand": 34784.89694,
    "solar_generated": 19.12274145,
    "wind_generated": 80.06783869,
    "marketplace_to_demand": 63.8176517,
    "grid_to_demand": 32629.15124,
    "govt_battery_charge": 89.29585558
  },
  {
    "timestamp": "10/06/24 14:00",
    "demand": 33166.0262,
    "solar_generated": 17.27798195,
    "wind_generated": 75.61006074,
    "marketplace_to_demand": 67.66242607,
    "grid_to_demand": 31254.44598,
    "govt_battery_charge": 9.947604505
  },
  {
    "timestamp": "10/06/24 15:00",
    "demand": 36136.93607,
    "solar_generated": 22.8159234,
    "wind_generated": 60.19153224,
    "marketplace_to_demand": 198.314393,
    "grid_to_demand": 33500.44006,
    "govt_battery_charge": 75.61819942
  },
  {
    "timestamp": "10/06/24 16:00",
    "demand": 30020.76321,
    "solar_generated": 7.774969243,
    "wind_generated": 73.55969319,
    "marketplace_to_demand": 72.66961686,
    "grid_to_demand": 30316.01148,
    "govt_battery_charge": 10.39495777
  },
  {
    "timestamp": "10/06/24 17:00",
    "demand": 31930.50828,
    "solar_generated": 16.67761979,
    "wind_generated": 64.41906307,
    "marketplace_to_demand": 38.40908375,
    "grid_to_demand": 30760.4856,
    "govt_battery_charge": 9.322598254
  },
  {
    "timestamp": "10/06/24 18:00",
    "demand": 31114.56438,
    "solar_generated": 0,
    "wind_generated": 65.44452284,
    "marketplace_to_demand": 87.47165272,
    "grid_to_demand": 31101.49017,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "10/06/24 19:00",
    "demand": 33558.09471,
    "solar_generated": 0,
    "wind_generated": 55.9413578,
    "marketplace_to_demand": 30.87914463,
    "grid_to_demand": 32445.97451,
    "govt_battery_charge": 53.90449382
  },
  {
    "timestamp": "10/06/24 20:00",
    "demand": 36290.30707,
    "solar_generated": 0,
    "wind_generated": 56.56879572,
    "marketplace_to_demand": 140.3376888,
    "grid_to_demand": 31530.68076,
    "govt_battery_charge": 80.65602022
  },
  {
    "timestamp": "10/06/24 21:00",
    "demand": 35702.36157,
    "solar_generated": 0,
    "wind_generated": 70.07933725,
    "marketplace_to_demand": 188.0232241,
    "grid_to_demand": 30318.24209,
    "govt_battery_charge": 42.1087538
  },
  {
    "timestamp": "10/06/24 22:00",
    "demand": 36042.78272,
    "solar_generated": 0,
    "wind_generated": 58.73404705,
    "marketplace_to_demand": 198.7053683,
    "grid_to_demand": 34825.39681,
    "govt_battery_charge": 7.048885456
  },
  {
    "timestamp": "10/06/24 23:00",
    "demand": 34633.63984,
    "solar_generated": 0,
    "wind_generated": 75.79821384,
    "marketplace_to_demand": 155.7853736,
    "grid_to_demand": 34816.83668,
    "govt_battery_charge": 23.1988551
  },
  {
    "timestamp": "11/06/24 0:00",
    "demand": 31212.56179,
    "solar_generated": 0,
    "wind_generated": 72.46649205,
    "marketplace_to_demand": 107.2725231,
    "grid_to_demand": 32123.08153,
    "govt_battery_charge": 99.1705834
  },
  {
    "timestamp": "11/06/24 1:00",
    "demand": 30673.8597,
    "solar_generated": 0,
    "wind_generated": 73.28145702,
    "marketplace_to_demand": 90.6027597,
    "grid_to_demand": 30211.2646,
    "govt_battery_charge": 15.29655015
  },
  {
    "timestamp": "11/06/24 2:00",
    "demand": 30677.84834,
    "solar_generated": 0,
    "wind_generated": 69.52664236,
    "marketplace_to_demand": 192.998125,
    "grid_to_demand": 30126.84175,
    "govt_battery_charge": 16.56501056
  },
  {
    "timestamp": "11/06/24 3:00",
    "demand": 32307.0131,
    "solar_generated": 0,
    "wind_generated": 71.03169376,
    "marketplace_to_demand": 176.7110334,
    "grid_to_demand": 31599.68113,
    "govt_battery_charge": 34.2773311
  },
  {
    "timestamp": "11/06/24 4:00",
    "demand": 33604.13948,
    "solar_generated": 0,
    "wind_generated": 67.50232689,
    "marketplace_to_demand": 101.2945745,
    "grid_to_demand": 31301.13697,
    "govt_battery_charge": 5.768598486
  },
  {
    "timestamp": "11/06/24 5:00",
    "demand": 35349.49961,
    "solar_generated": 0,
    "wind_generated": 78.91406069,
    "marketplace_to_demand": 115.9915886,
    "grid_to_demand": 32953.48944,
    "govt_battery_charge": 11.54030794
  },
  {
    "timestamp": "11/06/24 6:00",
    "demand": 30414.39104,
    "solar_generated": 15.68279006,
    "wind_generated": 59.75484102,
    "marketplace_to_demand": 134.5709842,
    "grid_to_demand": 31263.36651,
    "govt_battery_charge": 99.96647717
  },
  {
    "timestamp": "11/06/24 7:00",
    "demand": 32901.43544,
    "solar_generated": 15.42352549,
    "wind_generated": 77.634321,
    "marketplace_to_demand": 179.2010649,
    "grid_to_demand": 32259.76275,
    "govt_battery_charge": 20.93842894
  },
  {
    "timestamp": "11/06/24 8:00",
    "demand": 32793.52189,
    "solar_generated": 6.952515452,
    "wind_generated": 75.73522246,
    "marketplace_to_demand": 72.8311099,
    "grid_to_demand": 35580.12706,
    "govt_battery_charge": 54.96648421
  },
  {
    "timestamp": "11/06/24 9:00",
    "demand": 35069.29373,
    "solar_generated": 15.30247503,
    "wind_generated": 70.03070488,
    "marketplace_to_demand": 42.55025995,
    "grid_to_demand": 35546.28142,
    "govt_battery_charge": 32.9038442
  },
  {
    "timestamp": "11/06/24 10:00",
    "demand": 32165.81597,
    "solar_generated": 13.61138987,
    "wind_generated": 74.13561533,
    "marketplace_to_demand": 96.49043921,
    "grid_to_demand": 33720.78469,
    "govt_battery_charge": 37.52648155
  },
  {
    "timestamp": "11/06/24 11:00",
    "demand": 36621.19252,
    "solar_generated": 18.00726951,
    "wind_generated": 79.00954426,
    "marketplace_to_demand": 73.78237953,
    "grid_to_demand": 35164.1704,
    "govt_battery_charge": 15.72125762
  },
  {
    "timestamp": "11/06/24 12:00",
    "demand": 36303.52632,
    "solar_generated": 18.55609963,
    "wind_generated": 66.53398112,
    "marketplace_to_demand": 69.16526095,
    "grid_to_demand": 31627.85585,
    "govt_battery_charge": 51.55427655
  },
  {
    "timestamp": "11/06/24 13:00",
    "demand": 36257.86698,
    "solar_generated": 11.50671983,
    "wind_generated": 61.6054568,
    "marketplace_to_demand": 72.08008292,
    "grid_to_demand": 35203.71757,
    "govt_battery_charge": 98.82377688
  },
  {
    "timestamp": "11/06/24 14:00",
    "demand": 34000.6185,
    "solar_generated": 22.26432787,
    "wind_generated": 60.66813546,
    "marketplace_to_demand": 26.00670848,
    "grid_to_demand": 35795.41107,
    "govt_battery_charge": 90.86793673
  },
  {
    "timestamp": "11/06/24 15:00",
    "demand": 30873.95153,
    "solar_generated": 11.84986765,
    "wind_generated": 69.69041782,
    "marketplace_to_demand": 31.59672076,
    "grid_to_demand": 32097.68214,
    "govt_battery_charge": 40.16407555
  },
  {
    "timestamp": "11/06/24 16:00",
    "demand": 33486.20219,
    "solar_generated": 23.81036504,
    "wind_generated": 63.02639559,
    "marketplace_to_demand": 118.680837,
    "grid_to_demand": 33492.06422,
    "govt_battery_charge": 24.44077487
  },
  {
    "timestamp": "11/06/24 17:00",
    "demand": 35162.30622,
    "solar_generated": 12.03685319,
    "wind_generated": 73.06699235,
    "marketplace_to_demand": 22.53728059,
    "grid_to_demand": 34037.3509,
    "govt_battery_charge": 86.96144396
  },
  {
    "timestamp": "11/06/24 18:00",
    "demand": 35450.79771,
    "solar_generated": 0,
    "wind_generated": 66.79535477,
    "marketplace_to_demand": 103.2622783,
    "grid_to_demand": 30412.99034,
    "govt_battery_charge": 63.36236799
  },
  {
    "timestamp": "11/06/24 19:00",
    "demand": 30592.4939,
    "solar_generated": 0,
    "wind_generated": 62.27545785,
    "marketplace_to_demand": 118.1653392,
    "grid_to_demand": 33971.32929,
    "govt_battery_charge": 38.2175691
  },
  {
    "timestamp": "11/06/24 20:00",
    "demand": 36234.0021,
    "solar_generated": 0,
    "wind_generated": 57.79993818,
    "marketplace_to_demand": 78.03203029,
    "grid_to_demand": 32626.61983,
    "govt_battery_charge": 7.767366198
  },
  {
    "timestamp": "11/06/24 21:00",
    "demand": 33922.03437,
    "solar_generated": 0,
    "wind_generated": 68.96531651,
    "marketplace_to_demand": 42.55278374,
    "grid_to_demand": 36310.43499,
    "govt_battery_charge": 72.51455709
  },
  {
    "timestamp": "11/06/24 22:00",
    "demand": 33307.24659,
    "solar_generated": 0,
    "wind_generated": 75.73020759,
    "marketplace_to_demand": 177.594219,
    "grid_to_demand": 32036.21421,
    "govt_battery_charge": 93.15396956
  },
  {
    "timestamp": "11/06/24 23:00",
    "demand": 35983.65119,
    "solar_generated": 0,
    "wind_generated": 69.14445819,
    "marketplace_to_demand": 114.4726673,
    "grid_to_demand": 36461.31864,
    "govt_battery_charge": 49.12883621
  },
  {
    "timestamp": "12/06/24 0:00",
    "demand": 31315.34871,
    "solar_generated": 0,
    "wind_generated": 58.46321094,
    "marketplace_to_demand": 30.48990592,
    "grid_to_demand": 30014.16569,
    "govt_battery_charge": 21.83274101
  },
  {
    "timestamp": "12/06/24 1:00",
    "demand": 35381.96203,
    "solar_generated": 0,
    "wind_generated": 55.50688482,
    "marketplace_to_demand": 194.0425306,
    "grid_to_demand": 30705.69584,
    "govt_battery_charge": 24.17096375
  },
  {
    "timestamp": "12/06/24 2:00",
    "demand": 31898.39182,
    "solar_generated": 0,
    "wind_generated": 76.07394519,
    "marketplace_to_demand": 103.076736,
    "grid_to_demand": 31978.32455,
    "govt_battery_charge": 98.23731179
  },
  {
    "timestamp": "12/06/24 3:00",
    "demand": 33029.80883,
    "solar_generated": 0,
    "wind_generated": 61.53776236,
    "marketplace_to_demand": 88.9493462,
    "grid_to_demand": 35859.47832,
    "govt_battery_charge": 27.97901521
  },
  {
    "timestamp": "12/06/24 4:00",
    "demand": 35149.24456,
    "solar_generated": 0,
    "wind_generated": 66.54881194,
    "marketplace_to_demand": 4.716491549,
    "grid_to_demand": 30602.33738,
    "govt_battery_charge": 34.47331309
  },
  {
    "timestamp": "12/06/24 5:00",
    "demand": 31209.94656,
    "solar_generated": 0,
    "wind_generated": 69.2587509,
    "marketplace_to_demand": 123.6756442,
    "grid_to_demand": 30869.87617,
    "govt_battery_charge": 79.14478861
  },
  {
    "timestamp": "12/06/24 6:00",
    "demand": 33409.34174,
    "solar_generated": 18.54466047,
    "wind_generated": 56.43113852,
    "marketplace_to_demand": 86.167908,
    "grid_to_demand": 36112.79785,
    "govt_battery_charge": 68.07092088
  },
  {
    "timestamp": "12/06/24 7:00",
    "demand": 32284.53567,
    "solar_generated": 13.41103472,
    "wind_generated": 64.66502596,
    "marketplace_to_demand": 105.3009516,
    "grid_to_demand": 34691.37634,
    "govt_battery_charge": 24.54280977
  },
  {
    "timestamp": "12/06/24 8:00",
    "demand": 32659.9235,
    "solar_generated": 13.54046904,
    "wind_generated": 67.81570071,
    "marketplace_to_demand": 143.3919701,
    "grid_to_demand": 35423.6187,
    "govt_battery_charge": 82.61807697
  },
  {
    "timestamp": "12/06/24 9:00",
    "demand": 33170.2094,
    "solar_generated": 11.90483193,
    "wind_generated": 69.86527489,
    "marketplace_to_demand": 84.56589656,
    "grid_to_demand": 35074.74268,
    "govt_battery_charge": 91.77290599
  },
  {
    "timestamp": "12/06/24 10:00",
    "demand": 32631.02016,
    "solar_generated": 8.236044752,
    "wind_generated": 64.60662326,
    "marketplace_to_demand": 165.1173852,
    "grid_to_demand": 34864.68706,
    "govt_battery_charge": 76.08472308
  },
  {
    "timestamp": "12/06/24 11:00",
    "demand": 30331.66404,
    "solar_generated": 21.97355021,
    "wind_generated": 75.06927422,
    "marketplace_to_demand": 31.1004844,
    "grid_to_demand": 30596.22891,
    "govt_battery_charge": 55.88649883
  },
  {
    "timestamp": "12/06/24 12:00",
    "demand": 35881.05408,
    "solar_generated": 17.42591801,
    "wind_generated": 65.63278244,
    "marketplace_to_demand": 101.1950234,
    "grid_to_demand": 34136.76995,
    "govt_battery_charge": 7.744801274
  },
  {
    "timestamp": "12/06/24 13:00",
    "demand": 35635.14481,
    "solar_generated": 9.89868205,
    "wind_generated": 72.92812274,
    "marketplace_to_demand": 145.9639436,
    "grid_to_demand": 30194.71472,
    "govt_battery_charge": 17.59138396
  },
  {
    "timestamp": "12/06/24 14:00",
    "demand": 32996.02041,
    "solar_generated": 10.85776271,
    "wind_generated": 56.80175139,
    "marketplace_to_demand": 1.118766098,
    "grid_to_demand": 34775.80875,
    "govt_battery_charge": 16.01380759
  },
  {
    "timestamp": "12/06/24 15:00",
    "demand": 32549.386,
    "solar_generated": 12.66604497,
    "wind_generated": 79.46797505,
    "marketplace_to_demand": 53.18322098,
    "grid_to_demand": 31939.07196,
    "govt_battery_charge": 25.91830199
  },
  {
    "timestamp": "12/06/24 16:00",
    "demand": 32123.23929,
    "solar_generated": 22.32708576,
    "wind_generated": 71.51793191,
    "marketplace_to_demand": 96.66679011,
    "grid_to_demand": 35561.17171,
    "govt_battery_charge": 46.16251566
  },
  {
    "timestamp": "12/06/24 17:00",
    "demand": 30785.56877,
    "solar_generated": 12.54878039,
    "wind_generated": 71.51183239,
    "marketplace_to_demand": 141.8365217,
    "grid_to_demand": 34643.06117,
    "govt_battery_charge": 45.42788851
  },
  {
    "timestamp": "12/06/24 18:00",
    "demand": 31724.21577,
    "solar_generated": 0,
    "wind_generated": 54.58121166,
    "marketplace_to_demand": 193.8649497,
    "grid_to_demand": 33724.41887,
    "govt_battery_charge": 16.16501037
  },
  {
    "timestamp": "12/06/24 19:00",
    "demand": 36596.18489,
    "solar_generated": 0,
    "wind_generated": 57.8829572,
    "marketplace_to_demand": 164.5576412,
    "grid_to_demand": 33427.17,
    "govt_battery_charge": 27.05991592
  },
  {
    "timestamp": "12/06/24 20:00",
    "demand": 36417.82546,
    "solar_generated": 0,
    "wind_generated": 70.37544647,
    "marketplace_to_demand": 39.31038506,
    "grid_to_demand": 36040.05863,
    "govt_battery_charge": 98.14293422
  },
  {
    "timestamp": "12/06/24 21:00",
    "demand": 34386.72573,
    "solar_generated": 0,
    "wind_generated": 75.27320616,
    "marketplace_to_demand": 186.247323,
    "grid_to_demand": 30250.61141,
    "govt_battery_charge": 63.25973832
  },
  {
    "timestamp": "12/06/24 22:00",
    "demand": 34024.43671,
    "solar_generated": 0,
    "wind_generated": 77.44891443,
    "marketplace_to_demand": 183.888752,
    "grid_to_demand": 30318.13312,
    "govt_battery_charge": 90.24028682
  },
  {
    "timestamp": "12/06/24 23:00",
    "demand": 32876.94235,
    "solar_generated": 0,
    "wind_generated": 63.54822033,
    "marketplace_to_demand": 123.0681628,
    "grid_to_demand": 35549.79984,
    "govt_battery_charge": 13.44795831
  },
  {
    "timestamp": "13/06/24 0:00",
    "demand": 32648.79837,
    "solar_generated": 0,
    "wind_generated": 75.17444729,
    "marketplace_to_demand": 165.7049857,
    "grid_to_demand": 32746.95354,
    "govt_battery_charge": 96.53086498
  },
  {
    "timestamp": "13/06/24 1:00",
    "demand": 32453.39228,
    "solar_generated": 0,
    "wind_generated": 64.38623792,
    "marketplace_to_demand": 54.88279933,
    "grid_to_demand": 33536.38821,
    "govt_battery_charge": 4.017288843
  },
  {
    "timestamp": "13/06/24 2:00",
    "demand": 35721.367,
    "solar_generated": 0,
    "wind_generated": 71.41855096,
    "marketplace_to_demand": 43.15075719,
    "grid_to_demand": 34568.63331,
    "govt_battery_charge": 38.86943784
  },
  {
    "timestamp": "13/06/24 3:00",
    "demand": 36000.72564,
    "solar_generated": 0,
    "wind_generated": 76.40753878,
    "marketplace_to_demand": 136.0076177,
    "grid_to_demand": 36515.6047,
    "govt_battery_charge": 14.09587323
  },
  {
    "timestamp": "13/06/24 4:00",
    "demand": 31909.06391,
    "solar_generated": 0,
    "wind_generated": 78.4554322,
    "marketplace_to_demand": 24.23066178,
    "grid_to_demand": 33969.1134,
    "govt_battery_charge": 32.29665215
  },
  {
    "timestamp": "13/06/24 5:00",
    "demand": 35388.0956,
    "solar_generated": 0,
    "wind_generated": 69.94902137,
    "marketplace_to_demand": 173.3585518,
    "grid_to_demand": 32235.78605,
    "govt_battery_charge": 15.83447182
  },
  {
    "timestamp": "13/06/24 6:00",
    "demand": 34741.10443,
    "solar_generated": 17.89420818,
    "wind_generated": 61.02999287,
    "marketplace_to_demand": 177.3816958,
    "grid_to_demand": 32955.23592,
    "govt_battery_charge": 2.323190436
  },
  {
    "timestamp": "13/06/24 7:00",
    "demand": 30411.75729,
    "solar_generated": 13.61603281,
    "wind_generated": 74.96329483,
    "marketplace_to_demand": 22.43935423,
    "grid_to_demand": 32196.58509,
    "govt_battery_charge": 32.11866497
  },
  {
    "timestamp": "13/06/24 8:00",
    "demand": 35705.34352,
    "solar_generated": 12.52838486,
    "wind_generated": 72.37228531,
    "marketplace_to_demand": 125.7137591,
    "grid_to_demand": 32774.03434,
    "govt_battery_charge": 59.05961019
  },
  {
    "timestamp": "13/06/24 9:00",
    "demand": 34126.26513,
    "solar_generated": 23.62358121,
    "wind_generated": 69.79757623,
    "marketplace_to_demand": 153.8593586,
    "grid_to_demand": 36395.14466,
    "govt_battery_charge": 16.93685239
  },
  {
    "timestamp": "13/06/24 10:00",
    "demand": 33015.9153,
    "solar_generated": 11.58826754,
    "wind_generated": 60.92495335,
    "marketplace_to_demand": 159.9932076,
    "grid_to_demand": 34428.06548,
    "govt_battery_charge": 89.11072724
  },
  {
    "timestamp": "13/06/24 11:00",
    "demand": 34125.34488,
    "solar_generated": 15.48907346,
    "wind_generated": 71.60270615,
    "marketplace_to_demand": 111.7503654,
    "grid_to_demand": 32757.87623,
    "govt_battery_charge": 29.84899952
  },
  {
    "timestamp": "13/06/24 12:00",
    "demand": 34136.84945,
    "solar_generated": 13.12954109,
    "wind_generated": 76.61422281,
    "marketplace_to_demand": 108.2816007,
    "grid_to_demand": 36325.1386,
    "govt_battery_charge": 85.54205007
  },
  {
    "timestamp": "13/06/24 13:00",
    "demand": 32707.69566,
    "solar_generated": 7.237783216,
    "wind_generated": 57.98003665,
    "marketplace_to_demand": 93.76175636,
    "grid_to_demand": 30074.8829,
    "govt_battery_charge": 2.054932849
  },
  {
    "timestamp": "13/06/24 14:00",
    "demand": 34971.09054,
    "solar_generated": 13.40006342,
    "wind_generated": 54.35877648,
    "marketplace_to_demand": 43.50013421,
    "grid_to_demand": 35655.29751,
    "govt_battery_charge": 53.8658223
  },
  {
    "timestamp": "13/06/24 15:00",
    "demand": 34168.73525,
    "solar_generated": 11.57246338,
    "wind_generated": 76.9944797,
    "marketplace_to_demand": 23.38990864,
    "grid_to_demand": 35341.11517,
    "govt_battery_charge": 89.10052077
  },
  {
    "timestamp": "13/06/24 16:00",
    "demand": 30913.23229,
    "solar_generated": 6.656847282,
    "wind_generated": 55.42905863,
    "marketplace_to_demand": 136.6812136,
    "grid_to_demand": 30123.42365,
    "govt_battery_charge": 54.55971929
  },
  {
    "timestamp": "13/06/24 17:00",
    "demand": 34131.74939,
    "solar_generated": 23.54791844,
    "wind_generated": 65.76890961,
    "marketplace_to_demand": 54.90412422,
    "grid_to_demand": 30924.7211,
    "govt_battery_charge": 69.03520794
  },
  {
    "timestamp": "13/06/24 18:00",
    "demand": 33514.4197,
    "solar_generated": 0,
    "wind_generated": 61.58645429,
    "marketplace_to_demand": 190.3609494,
    "grid_to_demand": 35165.81058,
    "govt_battery_charge": 9.615029317
  },
  {
    "timestamp": "13/06/24 19:00",
    "demand": 34700.67854,
    "solar_generated": 0,
    "wind_generated": 60.28350145,
    "marketplace_to_demand": 58.15800661,
    "grid_to_demand": 33446.4719,
    "govt_battery_charge": 64.11268525
  },
  {
    "timestamp": "13/06/24 20:00",
    "demand": 30713.39238,
    "solar_generated": 0,
    "wind_generated": 77.7368943,
    "marketplace_to_demand": 157.4223878,
    "grid_to_demand": 34059.83318,
    "govt_battery_charge": 52.34982033
  },
  {
    "timestamp": "13/06/24 21:00",
    "demand": 35516.50794,
    "solar_generated": 0,
    "wind_generated": 71.77268788,
    "marketplace_to_demand": 1.096348905,
    "grid_to_demand": 30726.46388,
    "govt_battery_charge": 37.25511978
  },
  {
    "timestamp": "13/06/24 22:00",
    "demand": 34941.98621,
    "solar_generated": 0,
    "wind_generated": 59.47497269,
    "marketplace_to_demand": 73.00931236,
    "grid_to_demand": 33833.87404,
    "govt_battery_charge": 81.14366438
  },
  {
    "timestamp": "13/06/24 23:00",
    "demand": 30014.20781,
    "solar_generated": 0,
    "wind_generated": 75.1538572,
    "marketplace_to_demand": 7.235768823,
    "grid_to_demand": 31264.36595,
    "govt_battery_charge": 28.01863939
  },
  {
    "timestamp": "14/06/24 0:00",
    "demand": 32267.62331,
    "solar_generated": 0,
    "wind_generated": 61.25687008,
    "marketplace_to_demand": 48.99708259,
    "grid_to_demand": 31519.98666,
    "govt_battery_charge": 95.70922349
  },
  {
    "timestamp": "14/06/24 1:00",
    "demand": 31339.20328,
    "solar_generated": 0,
    "wind_generated": 60.69667498,
    "marketplace_to_demand": 107.218346,
    "grid_to_demand": 35348.39737,
    "govt_battery_charge": 69.35851049
  },
  {
    "timestamp": "14/06/24 2:00",
    "demand": 33098.50482,
    "solar_generated": 0,
    "wind_generated": 80.00314547,
    "marketplace_to_demand": 1.995828854,
    "grid_to_demand": 35708.34259,
    "govt_battery_charge": 86.43027134
  },
  {
    "timestamp": "14/06/24 3:00",
    "demand": 34332.63199,
    "solar_generated": 0,
    "wind_generated": 78.05197047,
    "marketplace_to_demand": 187.9298406,
    "grid_to_demand": 34209.56132,
    "govt_battery_charge": 53.00187697
  },
  {
    "timestamp": "14/06/24 4:00",
    "demand": 30253.06282,
    "solar_generated": 0,
    "wind_generated": 78.3266795,
    "marketplace_to_demand": 168.9753885,
    "grid_to_demand": 35850.39395,
    "govt_battery_charge": 89.24087507
  },
  {
    "timestamp": "14/06/24 5:00",
    "demand": 31541.25665,
    "solar_generated": 0,
    "wind_generated": 63.06282015,
    "marketplace_to_demand": 57.7985667,
    "grid_to_demand": 34123.80451,
    "govt_battery_charge": 51.50852843
  },
  {
    "timestamp": "14/06/24 6:00",
    "demand": 36284.96469,
    "solar_generated": 11.36432661,
    "wind_generated": 54.35487159,
    "marketplace_to_demand": 184.4138471,
    "grid_to_demand": 32667.07007,
    "govt_battery_charge": 9.745705164
  },
  {
    "timestamp": "14/06/24 7:00",
    "demand": 33427.12355,
    "solar_generated": 19.00769443,
    "wind_generated": 68.62858461,
    "marketplace_to_demand": 9.25540018,
    "grid_to_demand": 34508.19177,
    "govt_battery_charge": 7.777574819
  },
  {
    "timestamp": "14/06/24 8:00",
    "demand": 33869.24828,
    "solar_generated": 16.93668974,
    "wind_generated": 65.51857346,
    "marketplace_to_demand": 86.89697484,
    "grid_to_demand": 31489.31807,
    "govt_battery_charge": 15.33832187
  },
  {
    "timestamp": "14/06/24 9:00",
    "demand": 33470.07117,
    "solar_generated": 10.31189129,
    "wind_generated": 70.7468962,
    "marketplace_to_demand": 97.78153642,
    "grid_to_demand": 34533.29563,
    "govt_battery_charge": 63.14343636
  },
  {
    "timestamp": "14/06/24 10:00",
    "demand": 35127.88349,
    "solar_generated": 10.80159718,
    "wind_generated": 71.63752013,
    "marketplace_to_demand": 56.36859097,
    "grid_to_demand": 30151.84921,
    "govt_battery_charge": 25.44083716
  },
  {
    "timestamp": "14/06/24 11:00",
    "demand": 33204.71632,
    "solar_generated": 20.49515746,
    "wind_generated": 72.37611773,
    "marketplace_to_demand": 6.573314641,
    "grid_to_demand": 32221.95042,
    "govt_battery_charge": 57.57427436
  },
  {
    "timestamp": "14/06/24 12:00",
    "demand": 33947.35216,
    "solar_generated": 9.629426599,
    "wind_generated": 64.14401004,
    "marketplace_to_demand": 16.68209589,
    "grid_to_demand": 33200.87715,
    "govt_battery_charge": 7.193359608
  },
  {
    "timestamp": "14/06/24 13:00",
    "demand": 30370.18236,
    "solar_generated": 17.10253628,
    "wind_generated": 59.82971056,
    "marketplace_to_demand": 132.5138592,
    "grid_to_demand": 33599.75177,
    "govt_battery_charge": 43.88213405
  },
  {
    "timestamp": "14/06/24 14:00",
    "demand": 33147.46448,
    "solar_generated": 6.588337663,
    "wind_generated": 54.5965798,
    "marketplace_to_demand": 72.91799088,
    "grid_to_demand": 35147.81009,
    "govt_battery_charge": 66.66454879
  },
  {
    "timestamp": "14/06/24 15:00",
    "demand": 36329.33345,
    "solar_generated": 15.50137475,
    "wind_generated": 62.58610388,
    "marketplace_to_demand": 129.3455803,
    "grid_to_demand": 35440.93061,
    "govt_battery_charge": 99.79136873
  },
  {
    "timestamp": "14/06/24 16:00",
    "demand": 32511.81597,
    "solar_generated": 18.81471785,
    "wind_generated": 79.49264316,
    "marketplace_to_demand": 74.62574179,
    "grid_to_demand": 32904.4365,
    "govt_battery_charge": 55.96965523
  },
  {
    "timestamp": "14/06/24 17:00",
    "demand": 34954.86376,
    "solar_generated": 21.60370809,
    "wind_generated": 61.48882629,
    "marketplace_to_demand": 156.0141467,
    "grid_to_demand": 34089.43796,
    "govt_battery_charge": 25.11532618
  },
  {
    "timestamp": "14/06/24 18:00",
    "demand": 35433.05547,
    "solar_generated": 0,
    "wind_generated": 69.0490755,
    "marketplace_to_demand": 179.7063566,
    "grid_to_demand": 36504.71697,
    "govt_battery_charge": 27.02340922
  },
  {
    "timestamp": "14/06/24 19:00",
    "demand": 33917.80025,
    "solar_generated": 0,
    "wind_generated": 75.2513322,
    "marketplace_to_demand": 85.78993428,
    "grid_to_demand": 31615.07652,
    "govt_battery_charge": 75.47557121
  },
  {
    "timestamp": "14/06/24 20:00",
    "demand": 33714.25327,
    "solar_generated": 0,
    "wind_generated": 71.74091779,
    "marketplace_to_demand": 152.8407408,
    "grid_to_demand": 35177.65134,
    "govt_battery_charge": 20.94142783
  },
  {
    "timestamp": "14/06/24 21:00",
    "demand": 34756.32795,
    "solar_generated": 0,
    "wind_generated": 70.45752516,
    "marketplace_to_demand": 65.47084138,
    "grid_to_demand": 32437.10894,
    "govt_battery_charge": 94.05539134
  },
  {
    "timestamp": "14/06/24 22:00",
    "demand": 30581.81685,
    "solar_generated": 0,
    "wind_generated": 57.52347334,
    "marketplace_to_demand": 30.76442819,
    "grid_to_demand": 30842.98979,
    "govt_battery_charge": 47.05022631
  },
  {
    "timestamp": "14/06/24 23:00",
    "demand": 30041.77432,
    "solar_generated": 0,
    "wind_generated": 76.42244199,
    "marketplace_to_demand": 91.61923594,
    "grid_to_demand": 30118.90747,
    "govt_battery_charge": 49.97322864
  },
  {
    "timestamp": "15/06/24 0:00",
    "demand": 31764.2205,
    "solar_generated": 0,
    "wind_generated": 59.58874486,
    "marketplace_to_demand": 153.1271361,
    "grid_to_demand": 33648.08759,
    "govt_battery_charge": 38.64805859
  },
  {
    "timestamp": "15/06/24 1:00",
    "demand": 33202.6853,
    "solar_generated": 0,
    "wind_generated": 71.40951801,
    "marketplace_to_demand": 65.28574356,
    "grid_to_demand": 32011.41599,
    "govt_battery_charge": 51.76956148
  },
  {
    "timestamp": "15/06/24 2:00",
    "demand": 33544.74436,
    "solar_generated": 0,
    "wind_generated": 75.21479704,
    "marketplace_to_demand": 132.9714675,
    "grid_to_demand": 34977.14326,
    "govt_battery_charge": 23.92213926
  },
  {
    "timestamp": "15/06/24 3:00",
    "demand": 34113.68991,
    "solar_generated": 0,
    "wind_generated": 61.18397968,
    "marketplace_to_demand": 167.2380916,
    "grid_to_demand": 31340.92273,
    "govt_battery_charge": 6.82132192
  },
  {
    "timestamp": "15/06/24 4:00",
    "demand": 32194.17665,
    "solar_generated": 0,
    "wind_generated": 77.67292625,
    "marketplace_to_demand": 196.7312053,
    "grid_to_demand": 32342.42731,
    "govt_battery_charge": 92.27112721
  },
  {
    "timestamp": "15/06/24 5:00",
    "demand": 31018.79142,
    "solar_generated": 0,
    "wind_generated": 55.18753428,
    "marketplace_to_demand": 94.82223849,
    "grid_to_demand": 32911.61339,
    "govt_battery_charge": 98.79170649
  },
  {
    "timestamp": "15/06/24 6:00",
    "demand": 34954.78704,
    "solar_generated": 21.99226301,
    "wind_generated": 65.01493748,
    "marketplace_to_demand": 147.2935019,
    "grid_to_demand": 36189.75319,
    "govt_battery_charge": 40.2493904
  },
  {
    "timestamp": "15/06/24 7:00",
    "demand": 30800.70316,
    "solar_generated": 12.67113554,
    "wind_generated": 65.22680433,
    "marketplace_to_demand": 137.6740968,
    "grid_to_demand": 33201.01334,
    "govt_battery_charge": 92.51926095
  },
  {
    "timestamp": "15/06/24 8:00",
    "demand": 31187.03613,
    "solar_generated": 10.61764693,
    "wind_generated": 67.61562902,
    "marketplace_to_demand": 198.8966456,
    "grid_to_demand": 34472.1134,
    "govt_battery_charge": 62.100722
  },
  {
    "timestamp": "15/06/24 9:00",
    "demand": 36470.16666,
    "solar_generated": 21.74349884,
    "wind_generated": 61.28224066,
    "marketplace_to_demand": 7.519645705,
    "grid_to_demand": 32603.74368,
    "govt_battery_charge": 8.415926216
  },
  {
    "timestamp": "15/06/24 10:00",
    "demand": 35777.41213,
    "solar_generated": 11.15520935,
    "wind_generated": 74.14675401,
    "marketplace_to_demand": 99.24365042,
    "grid_to_demand": 31656.75711,
    "govt_battery_charge": 29.4295755
  },
  {
    "timestamp": "15/06/24 11:00",
    "demand": 32601.82708,
    "solar_generated": 16.54589336,
    "wind_generated": 64.83190611,
    "marketplace_to_demand": 166.8867066,
    "grid_to_demand": 33769.44414,
    "govt_battery_charge": 41.34552858
  },
  {
    "timestamp": "15/06/24 12:00",
    "demand": 34175.86003,
    "solar_generated": 6.704069095,
    "wind_generated": 75.80961711,
    "marketplace_to_demand": 35.21504224,
    "grid_to_demand": 31490.56334,
    "govt_battery_charge": 57.77953941
  },
  {
    "timestamp": "15/06/24 13:00",
    "demand": 35820.07761,
    "solar_generated": 13.98393495,
    "wind_generated": 67.40326032,
    "marketplace_to_demand": 133.7351298,
    "grid_to_demand": 31093.699,
    "govt_battery_charge": 38.64288777
  },
  {
    "timestamp": "15/06/24 14:00",
    "demand": 34594.38176,
    "solar_generated": 12.16956276,
    "wind_generated": 57.58461439,
    "marketplace_to_demand": 145.9241019,
    "grid_to_demand": 33208.77786,
    "govt_battery_charge": 76.57949474
  },
  {
    "timestamp": "15/06/24 15:00",
    "demand": 33651.30125,
    "solar_generated": 13.26397426,
    "wind_generated": 64.30262229,
    "marketplace_to_demand": 93.01555952,
    "grid_to_demand": 34959.8832,
    "govt_battery_charge": 14.89865136
  },
  {
    "timestamp": "15/06/24 16:00",
    "demand": 35419.36398,
    "solar_generated": 9.487124295,
    "wind_generated": 65.72425365,
    "marketplace_to_demand": 82.56217312,
    "grid_to_demand": 31940.85416,
    "govt_battery_charge": 17.51962468
  },
  {
    "timestamp": "15/06/24 17:00",
    "demand": 32789.90273,
    "solar_generated": 21.4042259,
    "wind_generated": 63.57180364,
    "marketplace_to_demand": 54.07299174,
    "grid_to_demand": 33386.18963,
    "govt_battery_charge": 15.62992563
  },
  {
    "timestamp": "15/06/24 18:00",
    "demand": 30673.00285,
    "solar_generated": 0,
    "wind_generated": 62.46966498,
    "marketplace_to_demand": 138.5569181,
    "grid_to_demand": 36105.93019,
    "govt_battery_charge": 52.60299709
  },
  {
    "timestamp": "15/06/24 19:00",
    "demand": 31126.30776,
    "solar_generated": 0,
    "wind_generated": 54.98305686,
    "marketplace_to_demand": 169.7273888,
    "grid_to_demand": 32438.22133,
    "govt_battery_charge": 33.5320831
  },
  {
    "timestamp": "15/06/24 20:00",
    "demand": 35429.31929,
    "solar_generated": 0,
    "wind_generated": 76.67650828,
    "marketplace_to_demand": 142.6128796,
    "grid_to_demand": 33413.85195,
    "govt_battery_charge": 22.97976734
  },
  {
    "timestamp": "15/06/24 21:00",
    "demand": 34444.82797,
    "solar_generated": 0,
    "wind_generated": 55.18691696,
    "marketplace_to_demand": 195.1416644,
    "grid_to_demand": 33287.69572,
    "govt_battery_charge": 93.38111014
  },
  {
    "timestamp": "15/06/24 22:00",
    "demand": 30019.66139,
    "solar_generated": 0,
    "wind_generated": 63.19280287,
    "marketplace_to_demand": 85.54171424,
    "grid_to_demand": 36378.91922,
    "govt_battery_charge": 67.47595651
  },
  {
    "timestamp": "15/06/24 23:00",
    "demand": 32391.17505,
    "solar_generated": 0,
    "wind_generated": 78.41090679,
    "marketplace_to_demand": 177.4767729,
    "grid_to_demand": 32888.12446,
    "govt_battery_charge": 17.70397457
  },
  {
    "timestamp": "16/06/24 0:00",
    "demand": 32559.18181,
    "solar_generated": 0,
    "wind_generated": 62.49715637,
    "marketplace_to_demand": 17.95546462,
    "grid_to_demand": 36030.31486,
    "govt_battery_charge": 2.218559655
  },
  {
    "timestamp": "16/06/24 1:00",
    "demand": 33635.24968,
    "solar_generated": 0,
    "wind_generated": 62.66425839,
    "marketplace_to_demand": 1.388492021,
    "grid_to_demand": 36045.42461,
    "govt_battery_charge": 4.307791669
  },
  {
    "timestamp": "16/06/24 2:00",
    "demand": 36106.78843,
    "solar_generated": 0,
    "wind_generated": 53.81928643,
    "marketplace_to_demand": 196.0075743,
    "grid_to_demand": 31698.43668,
    "govt_battery_charge": 46.44081345
  },
  {
    "timestamp": "16/06/24 3:00",
    "demand": 35252.09266,
    "solar_generated": 0,
    "wind_generated": 68.38795666,
    "marketplace_to_demand": 168.2912458,
    "grid_to_demand": 36365.11048,
    "govt_battery_charge": 64.00276559
  },
  {
    "timestamp": "16/06/24 4:00",
    "demand": 33525.15646,
    "solar_generated": 0,
    "wind_generated": 59.53351115,
    "marketplace_to_demand": 107.3799678,
    "grid_to_demand": 35249.16043,
    "govt_battery_charge": 40.65501079
  },
  {
    "timestamp": "16/06/24 5:00",
    "demand": 30326.36578,
    "solar_generated": 0,
    "wind_generated": 75.45553256,
    "marketplace_to_demand": 195.2751995,
    "grid_to_demand": 30140.27665,
    "govt_battery_charge": 99.61379923
  },
  {
    "timestamp": "16/06/24 6:00",
    "demand": 35902.36518,
    "solar_generated": 6.597687398,
    "wind_generated": 59.54864157,
    "marketplace_to_demand": 186.9215287,
    "grid_to_demand": 32819.50892,
    "govt_battery_charge": 64.71318797
  },
  {
    "timestamp": "16/06/24 7:00",
    "demand": 35783.09747,
    "solar_generated": 9.073848165,
    "wind_generated": 70.3319145,
    "marketplace_to_demand": 62.32040864,
    "grid_to_demand": 34361.9551,
    "govt_battery_charge": 37.44215116
  },
  {
    "timestamp": "16/06/24 8:00",
    "demand": 31272.94093,
    "solar_generated": 16.78425624,
    "wind_generated": 70.11699906,
    "marketplace_to_demand": 43.67880274,
    "grid_to_demand": 33399.05335,
    "govt_battery_charge": 94.15575332
  },
  {
    "timestamp": "16/06/24 9:00",
    "demand": 32654.24997,
    "solar_generated": 13.39929861,
    "wind_generated": 74.71791427,
    "marketplace_to_demand": 193.6402086,
    "grid_to_demand": 34696.9154,
    "govt_battery_charge": 63.52245596
  },
  {
    "timestamp": "16/06/24 10:00",
    "demand": 35873.56194,
    "solar_generated": 15.66718523,
    "wind_generated": 65.68114423,
    "marketplace_to_demand": 3.599753976,
    "grid_to_demand": 36347.56453,
    "govt_battery_charge": 7.174261327
  },
  {
    "timestamp": "16/06/24 11:00",
    "demand": 32903.05899,
    "solar_generated": 22.28400853,
    "wind_generated": 78.36024798,
    "marketplace_to_demand": 25.86194461,
    "grid_to_demand": 31594.99279,
    "govt_battery_charge": 93.00298618
  },
  {
    "timestamp": "16/06/24 12:00",
    "demand": 32488.13862,
    "solar_generated": 14.53403507,
    "wind_generated": 65.23616326,
    "marketplace_to_demand": 186.2432779,
    "grid_to_demand": 30875.5481,
    "govt_battery_charge": 9.62262865
  },
  {
    "timestamp": "16/06/24 13:00",
    "demand": 33663.17936,
    "solar_generated": 20.69445608,
    "wind_generated": 75.81244662,
    "marketplace_to_demand": 189.869604,
    "grid_to_demand": 30083.40482,
    "govt_battery_charge": 75.5268043
  },
  {
    "timestamp": "16/06/24 14:00",
    "demand": 33435.8195,
    "solar_generated": 22.29846838,
    "wind_generated": 71.64378189,
    "marketplace_to_demand": 146.1488057,
    "grid_to_demand": 34502.06669,
    "govt_battery_charge": 66.75049213
  },
  {
    "timestamp": "16/06/24 15:00",
    "demand": 33999.10208,
    "solar_generated": 21.81727873,
    "wind_generated": 57.13613194,
    "marketplace_to_demand": 117.8447079,
    "grid_to_demand": 31699.33522,
    "govt_battery_charge": 43.87621804
  },
  {
    "timestamp": "16/06/24 16:00",
    "demand": 35011.04288,
    "solar_generated": 6.358232205,
    "wind_generated": 57.54549556,
    "marketplace_to_demand": 134.9631357,
    "grid_to_demand": 30933.92456,
    "govt_battery_charge": 86.52225729
  },
  {
    "timestamp": "16/06/24 17:00",
    "demand": 31577.30942,
    "solar_generated": 9.953233314,
    "wind_generated": 77.28821673,
    "marketplace_to_demand": 114.5742462,
    "grid_to_demand": 35200.26409,
    "govt_battery_charge": 73.82239797
  },
  {
    "timestamp": "16/06/24 18:00",
    "demand": 31600.41431,
    "solar_generated": 0,
    "wind_generated": 66.35524096,
    "marketplace_to_demand": 30.30534032,
    "grid_to_demand": 31832.35259,
    "govt_battery_charge": 69.44332451
  },
  {
    "timestamp": "16/06/24 19:00",
    "demand": 33867.27617,
    "solar_generated": 0,
    "wind_generated": 59.76682706,
    "marketplace_to_demand": 101.94689,
    "grid_to_demand": 34503.94212,
    "govt_battery_charge": 88.68536968
  },
  {
    "timestamp": "16/06/24 20:00",
    "demand": 36557.42742,
    "solar_generated": 0,
    "wind_generated": 76.1839276,
    "marketplace_to_demand": 8.420796932,
    "grid_to_demand": 34654.84352,
    "govt_battery_charge": 62.71742454
  },
  {
    "timestamp": "16/06/24 21:00",
    "demand": 34256.5859,
    "solar_generated": 0,
    "wind_generated": 72.54805348,
    "marketplace_to_demand": 179.7487727,
    "grid_to_demand": 33311.44665,
    "govt_battery_charge": 6.854202016
  },
  {
    "timestamp": "16/06/24 22:00",
    "demand": 36587.54303,
    "solar_generated": 0,
    "wind_generated": 63.49249588,
    "marketplace_to_demand": 193.5425851,
    "grid_to_demand": 30381.94762,
    "govt_battery_charge": 67.94964331
  },
  {
    "timestamp": "16/06/24 23:00",
    "demand": 34000.58186,
    "solar_generated": 0,
    "wind_generated": 57.90380202,
    "marketplace_to_demand": 84.92854951,
    "grid_to_demand": 30223.82206,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "17/06/24 0:00",
    "demand": 35104.63982,
    "solar_generated": 0,
    "wind_generated": 74.25444178,
    "marketplace_to_demand": 43.50932741,
    "grid_to_demand": 34250.78382,
    "govt_battery_charge": 50.09936852
  },
  {
    "timestamp": "17/06/24 1:00",
    "demand": 32509.8889,
    "solar_generated": 0,
    "wind_generated": 58.1986944,
    "marketplace_to_demand": 67.80457976,
    "grid_to_demand": 33238.5399,
    "govt_battery_charge": 58.69657425
  },
  {
    "timestamp": "17/06/24 2:00",
    "demand": 30402.27773,
    "solar_generated": 0,
    "wind_generated": 64.58083451,
    "marketplace_to_demand": 185.1183206,
    "grid_to_demand": 35255.51273,
    "govt_battery_charge": 36.50558026
  },
  {
    "timestamp": "17/06/24 3:00",
    "demand": 32857.05441,
    "solar_generated": 0,
    "wind_generated": 65.29088447,
    "marketplace_to_demand": 195.9113146,
    "grid_to_demand": 33074.84917,
    "govt_battery_charge": 21.44869674
  },
  {
    "timestamp": "17/06/24 4:00",
    "demand": 31719.409,
    "solar_generated": 0,
    "wind_generated": 68.862041,
    "marketplace_to_demand": 92.74322539,
    "grid_to_demand": 33685.92598,
    "govt_battery_charge": 6.036185376
  },
  {
    "timestamp": "17/06/24 5:00",
    "demand": 30064.73475,
    "solar_generated": 0,
    "wind_generated": 64.04847842,
    "marketplace_to_demand": 45.39926644,
    "grid_to_demand": 35602.21263,
    "govt_battery_charge": 26.26534831
  },
  {
    "timestamp": "17/06/24 6:00",
    "demand": 34434.00173,
    "solar_generated": 7.408796916,
    "wind_generated": 71.7300302,
    "marketplace_to_demand": 31.42256045,
    "grid_to_demand": 31395.4374,
    "govt_battery_charge": 23.21539413
  },
  {
    "timestamp": "17/06/24 7:00",
    "demand": 31358.88163,
    "solar_generated": 6.342210334,
    "wind_generated": 64.7927845,
    "marketplace_to_demand": 128.044881,
    "grid_to_demand": 36057.53402,
    "govt_battery_charge": 20.4010316
  },
  {
    "timestamp": "17/06/24 8:00",
    "demand": 31714.76817,
    "solar_generated": 16.9571297,
    "wind_generated": 67.14934265,
    "marketplace_to_demand": 54.97060773,
    "grid_to_demand": 31683.04736,
    "govt_battery_charge": 38.46212354
  },
  {
    "timestamp": "17/06/24 9:00",
    "demand": 36095.97859,
    "solar_generated": 9.222749508,
    "wind_generated": 55.54334942,
    "marketplace_to_demand": 109.7788469,
    "grid_to_demand": 33085.79303,
    "govt_battery_charge": 48.22200675
  },
  {
    "timestamp": "17/06/24 10:00",
    "demand": 33208.86777,
    "solar_generated": 20.86766994,
    "wind_generated": 56.84300511,
    "marketplace_to_demand": 163.3885013,
    "grid_to_demand": 30214.21976,
    "govt_battery_charge": 77.35712678
  },
  {
    "timestamp": "17/06/24 11:00",
    "demand": 33558.33464,
    "solar_generated": 22.43599675,
    "wind_generated": 67.08173687,
    "marketplace_to_demand": 70.98965097,
    "grid_to_demand": 35637.04719,
    "govt_battery_charge": 33.78972714
  },
  {
    "timestamp": "17/06/24 12:00",
    "demand": 31664.87972,
    "solar_generated": 12.21087325,
    "wind_generated": 61.45344833,
    "marketplace_to_demand": 111.1438495,
    "grid_to_demand": 35240.83191,
    "govt_battery_charge": 54.59769399
  },
  {
    "timestamp": "17/06/24 13:00",
    "demand": 30696.06336,
    "solar_generated": 12.46831766,
    "wind_generated": 59.87360274,
    "marketplace_to_demand": 129.4280146,
    "grid_to_demand": 31396.29485,
    "govt_battery_charge": 20.45202726
  },
  {
    "timestamp": "17/06/24 14:00",
    "demand": 31443.07157,
    "solar_generated": 12.03013044,
    "wind_generated": 69.07751779,
    "marketplace_to_demand": 89.70841467,
    "grid_to_demand": 34934.63446,
    "govt_battery_charge": 96.00017397
  },
  {
    "timestamp": "17/06/24 15:00",
    "demand": 35135.06392,
    "solar_generated": 20.42235692,
    "wind_generated": 76.39534892,
    "marketplace_to_demand": 158.2017217,
    "grid_to_demand": 35544.79931,
    "govt_battery_charge": 75.86158027
  },
  {
    "timestamp": "17/06/24 16:00",
    "demand": 36200.09468,
    "solar_generated": 6.878776441,
    "wind_generated": 78.86207875,
    "marketplace_to_demand": 19.44035301,
    "grid_to_demand": 31154.77293,
    "govt_battery_charge": 42.09125929
  },
  {
    "timestamp": "17/06/24 17:00",
    "demand": 36209.28351,
    "solar_generated": 21.51904245,
    "wind_generated": 56.28229055,
    "marketplace_to_demand": 142.6400962,
    "grid_to_demand": 32054.85869,
    "govt_battery_charge": 19.99135758
  },
  {
    "timestamp": "17/06/24 18:00",
    "demand": 35945.4071,
    "solar_generated": 0,
    "wind_generated": 64.89876196,
    "marketplace_to_demand": 9.089745714,
    "grid_to_demand": 30146.68854,
    "govt_battery_charge": 31.63625396
  },
  {
    "timestamp": "17/06/24 19:00",
    "demand": 36474.0787,
    "solar_generated": 0,
    "wind_generated": 71.38571049,
    "marketplace_to_demand": 87.7287864,
    "grid_to_demand": 34188.61173,
    "govt_battery_charge": 89.56609551
  },
  {
    "timestamp": "17/06/24 20:00",
    "demand": 34894.00533,
    "solar_generated": 0,
    "wind_generated": 79.91627458,
    "marketplace_to_demand": 170.4307347,
    "grid_to_demand": 34747.27695,
    "govt_battery_charge": 84.77702829
  },
  {
    "timestamp": "17/06/24 21:00",
    "demand": 33106.91802,
    "solar_generated": 0,
    "wind_generated": 76.71851883,
    "marketplace_to_demand": 72.73397733,
    "grid_to_demand": 33447.78003,
    "govt_battery_charge": 14.23187421
  },
  {
    "timestamp": "17/06/24 22:00",
    "demand": 31614.34952,
    "solar_generated": 0,
    "wind_generated": 67.38321006,
    "marketplace_to_demand": 190.0732251,
    "grid_to_demand": 35924.99251,
    "govt_battery_charge": 55.78053209
  },
  {
    "timestamp": "17/06/24 23:00",
    "demand": 30134.92311,
    "solar_generated": 0,
    "wind_generated": 57.58721448,
    "marketplace_to_demand": 169.6011389,
    "grid_to_demand": 31783.81192,
    "govt_battery_charge": 93.50802234
  },
  {
    "timestamp": "18/06/24 0:00",
    "demand": 36395.48963,
    "solar_generated": 0,
    "wind_generated": 55.28053448,
    "marketplace_to_demand": 113.9816988,
    "grid_to_demand": 33112.81801,
    "govt_battery_charge": 96.87782257
  },
  {
    "timestamp": "18/06/24 1:00",
    "demand": 34941.66796,
    "solar_generated": 0,
    "wind_generated": 72.52093243,
    "marketplace_to_demand": 69.36313065,
    "grid_to_demand": 30580.66925,
    "govt_battery_charge": 59.13103216
  },
  {
    "timestamp": "18/06/24 2:00",
    "demand": 31326.33846,
    "solar_generated": 0,
    "wind_generated": 58.96553294,
    "marketplace_to_demand": 107.2489233,
    "grid_to_demand": 36459.48954,
    "govt_battery_charge": 88.64440743
  },
  {
    "timestamp": "18/06/24 3:00",
    "demand": 35013.18887,
    "solar_generated": 0,
    "wind_generated": 75.56251392,
    "marketplace_to_demand": 99.54417264,
    "grid_to_demand": 35990.94669,
    "govt_battery_charge": 69.98725578
  },
  {
    "timestamp": "18/06/24 4:00",
    "demand": 36329.37322,
    "solar_generated": 0,
    "wind_generated": 62.07350772,
    "marketplace_to_demand": 183.6710824,
    "grid_to_demand": 36213.22741,
    "govt_battery_charge": 95.30600544
  },
  {
    "timestamp": "18/06/24 5:00",
    "demand": 31638.13525,
    "solar_generated": 0,
    "wind_generated": 55.62839769,
    "marketplace_to_demand": 107.9914407,
    "grid_to_demand": 32738.67318,
    "govt_battery_charge": 14.62910058
  },
  {
    "timestamp": "18/06/24 6:00",
    "demand": 32422.77124,
    "solar_generated": 7.198112273,
    "wind_generated": 77.72872167,
    "marketplace_to_demand": 148.0332545,
    "grid_to_demand": 34498.82279,
    "govt_battery_charge": 43.952028
  },
  {
    "timestamp": "18/06/24 7:00",
    "demand": 32330.07827,
    "solar_generated": 15.31332621,
    "wind_generated": 63.14234782,
    "marketplace_to_demand": 158.1774535,
    "grid_to_demand": 30438.59453,
    "govt_battery_charge": 85.08349095
  },
  {
    "timestamp": "18/06/24 8:00",
    "demand": 34971.32979,
    "solar_generated": 9.953888878,
    "wind_generated": 72.99452825,
    "marketplace_to_demand": 147.519486,
    "grid_to_demand": 30131.10769,
    "govt_battery_charge": 13.75230626
  },
  {
    "timestamp": "18/06/24 9:00",
    "demand": 34583.53688,
    "solar_generated": 16.05767754,
    "wind_generated": 65.30600128,
    "marketplace_to_demand": 46.84798757,
    "grid_to_demand": 33886.92661,
    "govt_battery_charge": 34.89261938
  },
  {
    "timestamp": "18/06/24 10:00",
    "demand": 33914.57739,
    "solar_generated": 12.17103088,
    "wind_generated": 73.38319499,
    "marketplace_to_demand": 144.4094594,
    "grid_to_demand": 31059.41258,
    "govt_battery_charge": 23.33382876
  },
  {
    "timestamp": "18/06/24 11:00",
    "demand": 34927.23817,
    "solar_generated": 22.15795492,
    "wind_generated": 77.53834297,
    "marketplace_to_demand": 59.22032786,
    "grid_to_demand": 34125.18505,
    "govt_battery_charge": 68.18298384
  },
  {
    "timestamp": "18/06/24 12:00",
    "demand": 34580.00807,
    "solar_generated": 10.65718843,
    "wind_generated": 66.8038658,
    "marketplace_to_demand": 123.0905552,
    "grid_to_demand": 30171.29772,
    "govt_battery_charge": 71.43521447
  },
  {
    "timestamp": "18/06/24 13:00",
    "demand": 33774.1214,
    "solar_generated": 17.15240416,
    "wind_generated": 58.10036163,
    "marketplace_to_demand": 6.186386566,
    "grid_to_demand": 31170.88126,
    "govt_battery_charge": 12.60172636
  },
  {
    "timestamp": "18/06/24 14:00",
    "demand": 32029.33834,
    "solar_generated": 20.33096446,
    "wind_generated": 75.54676886,
    "marketplace_to_demand": 191.2719041,
    "grid_to_demand": 30459.38949,
    "govt_battery_charge": 25.53045152
  },
  {
    "timestamp": "18/06/24 15:00",
    "demand": 34456.00592,
    "solar_generated": 10.14050649,
    "wind_generated": 73.32113989,
    "marketplace_to_demand": 62.96305922,
    "grid_to_demand": 34238.3962,
    "govt_battery_charge": 84.38092789
  },
  {
    "timestamp": "18/06/24 16:00",
    "demand": 34961.12472,
    "solar_generated": 18.37862025,
    "wind_generated": 78.8954262,
    "marketplace_to_demand": 112.5709511,
    "grid_to_demand": 33777.80175,
    "govt_battery_charge": 6.133583064
  },
  {
    "timestamp": "18/06/24 17:00",
    "demand": 35077.53261,
    "solar_generated": 7.903672104,
    "wind_generated": 54.44615026,
    "marketplace_to_demand": 46.74523739,
    "grid_to_demand": 31234.53958,
    "govt_battery_charge": 59.20092321
  },
  {
    "timestamp": "18/06/24 18:00",
    "demand": 33636.23604,
    "solar_generated": 0,
    "wind_generated": 80.03324748,
    "marketplace_to_demand": 18.35437165,
    "grid_to_demand": 34449.91891,
    "govt_battery_charge": 78.81343474
  },
  {
    "timestamp": "18/06/24 19:00",
    "demand": 31564.63365,
    "solar_generated": 0,
    "wind_generated": 68.35160291,
    "marketplace_to_demand": 119.1835142,
    "grid_to_demand": 33251.67938,
    "govt_battery_charge": 14.08649303
  },
  {
    "timestamp": "18/06/24 20:00",
    "demand": 32021.5613,
    "solar_generated": 0,
    "wind_generated": 69.24131853,
    "marketplace_to_demand": 126.1816326,
    "grid_to_demand": 36403.48147,
    "govt_battery_charge": 46.42174268
  },
  {
    "timestamp": "18/06/24 21:00",
    "demand": 34065.54723,
    "solar_generated": 0,
    "wind_generated": 66.62080648,
    "marketplace_to_demand": 93.68372996,
    "grid_to_demand": 34943.85295,
    "govt_battery_charge": 49.61576967
  },
  {
    "timestamp": "18/06/24 22:00",
    "demand": 30775.13762,
    "solar_generated": 0,
    "wind_generated": 61.21698217,
    "marketplace_to_demand": 126.0684051,
    "grid_to_demand": 35684.78763,
    "govt_battery_charge": 95.45811514
  },
  {
    "timestamp": "18/06/24 23:00",
    "demand": 35180.39368,
    "solar_generated": 0,
    "wind_generated": 64.07001197,
    "marketplace_to_demand": 137.6283703,
    "grid_to_demand": 32256.42646,
    "govt_battery_charge": 40.52112937
  },
  {
    "timestamp": "19/06/24 0:00",
    "demand": 30444.67328,
    "solar_generated": 0,
    "wind_generated": 56.17700403,
    "marketplace_to_demand": 9.104388635,
    "grid_to_demand": 33701.85403,
    "govt_battery_charge": 87.03158707
  },
  {
    "timestamp": "19/06/24 1:00",
    "demand": 33718.38214,
    "solar_generated": 0,
    "wind_generated": 72.57846362,
    "marketplace_to_demand": 36.58417871,
    "grid_to_demand": 35036.11265,
    "govt_battery_charge": 67.4935224
  },
  {
    "timestamp": "19/06/24 2:00",
    "demand": 31476.20945,
    "solar_generated": 0,
    "wind_generated": 54.32940806,
    "marketplace_to_demand": 11.82452528,
    "grid_to_demand": 32197.46548,
    "govt_battery_charge": 11.0515272
  },
  {
    "timestamp": "19/06/24 3:00",
    "demand": 34635.62204,
    "solar_generated": 0,
    "wind_generated": 76.99726498,
    "marketplace_to_demand": 167.5841159,
    "grid_to_demand": 31102.61627,
    "govt_battery_charge": 14.60940318
  },
  {
    "timestamp": "19/06/24 4:00",
    "demand": 35280.77142,
    "solar_generated": 0,
    "wind_generated": 77.01219235,
    "marketplace_to_demand": 34.28562646,
    "grid_to_demand": 35363.07534,
    "govt_battery_charge": 90.63868031
  },
  {
    "timestamp": "19/06/24 5:00",
    "demand": 35383.46943,
    "solar_generated": 0,
    "wind_generated": 64.38723913,
    "marketplace_to_demand": 46.067731,
    "grid_to_demand": 35712.83497,
    "govt_battery_charge": 54.65198421
  },
  {
    "timestamp": "19/06/24 6:00",
    "demand": 33908.13524,
    "solar_generated": 19.14916138,
    "wind_generated": 56.62097978,
    "marketplace_to_demand": 86.39496559,
    "grid_to_demand": 31935.50599,
    "govt_battery_charge": 42.9468198
  },
  {
    "timestamp": "19/06/24 7:00",
    "demand": 34656.31082,
    "solar_generated": 11.28246755,
    "wind_generated": 73.97321409,
    "marketplace_to_demand": 50.39897445,
    "grid_to_demand": 34352.10262,
    "govt_battery_charge": 23.2496378
  },
  {
    "timestamp": "19/06/24 8:00",
    "demand": 32295.13258,
    "solar_generated": 16.50229087,
    "wind_generated": 57.2810638,
    "marketplace_to_demand": 52.19095406,
    "grid_to_demand": 30092.98335,
    "govt_battery_charge": 88.50792127
  },
  {
    "timestamp": "19/06/24 9:00",
    "demand": 30847.21402,
    "solar_generated": 15.38681445,
    "wind_generated": 70.91402629,
    "marketplace_to_demand": 152.8051841,
    "grid_to_demand": 30692.48499,
    "govt_battery_charge": 86.95864088
  },
  {
    "timestamp": "19/06/24 10:00",
    "demand": 32887.75075,
    "solar_generated": 9.47734052,
    "wind_generated": 62.25687646,
    "marketplace_to_demand": 51.93934542,
    "grid_to_demand": 31698.16565,
    "govt_battery_charge": 24.76138976
  },
  {
    "timestamp": "19/06/24 11:00",
    "demand": 36285.9633,
    "solar_generated": 13.07228382,
    "wind_generated": 64.36444914,
    "marketplace_to_demand": 167.2275304,
    "grid_to_demand": 35415.34527,
    "govt_battery_charge": 34.08191127
  },
  {
    "timestamp": "19/06/24 12:00",
    "demand": 31369.97759,
    "solar_generated": 7.554711807,
    "wind_generated": 78.83542452,
    "marketplace_to_demand": 126.4860312,
    "grid_to_demand": 30470.0629,
    "govt_battery_charge": 52.12942736
  },
  {
    "timestamp": "19/06/24 13:00",
    "demand": 34791.10306,
    "solar_generated": 9.543929901,
    "wind_generated": 61.81162411,
    "marketplace_to_demand": 181.5536639,
    "grid_to_demand": 31212.22444,
    "govt_battery_charge": 92.43824063
  },
  {
    "timestamp": "19/06/24 14:00",
    "demand": 33762.55771,
    "solar_generated": 22.45428657,
    "wind_generated": 72.42641883,
    "marketplace_to_demand": 123.8362506,
    "grid_to_demand": 30841.91736,
    "govt_battery_charge": 7.346785181
  },
  {
    "timestamp": "19/06/24 15:00",
    "demand": 34989.57017,
    "solar_generated": 10.8095744,
    "wind_generated": 70.38747023,
    "marketplace_to_demand": 177.6537624,
    "grid_to_demand": 31127.86992,
    "govt_battery_charge": 44.66574028
  },
  {
    "timestamp": "19/06/24 16:00",
    "demand": 31512.25068,
    "solar_generated": 7.34204935,
    "wind_generated": 53.72887977,
    "marketplace_to_demand": 106.3965721,
    "grid_to_demand": 34925.93803,
    "govt_battery_charge": 27.61093094
  },
  {
    "timestamp": "19/06/24 17:00",
    "demand": 32048.64774,
    "solar_generated": 7.963251106,
    "wind_generated": 64.85680208,
    "marketplace_to_demand": 181.8658032,
    "grid_to_demand": 30762.80561,
    "govt_battery_charge": 22.54695514
  },
  {
    "timestamp": "19/06/24 18:00",
    "demand": 30154.09007,
    "solar_generated": 0,
    "wind_generated": 76.54262204,
    "marketplace_to_demand": 111.8172677,
    "grid_to_demand": 35789.18758,
    "govt_battery_charge": 61.70982995
  },
  {
    "timestamp": "19/06/24 19:00",
    "demand": 30369.48959,
    "solar_generated": 0,
    "wind_generated": 54.03814521,
    "marketplace_to_demand": 49.6795552,
    "grid_to_demand": 34136.71993,
    "govt_battery_charge": 33.61784826
  },
  {
    "timestamp": "19/06/24 20:00",
    "demand": 36413.19005,
    "solar_generated": 0,
    "wind_generated": 53.96208503,
    "marketplace_to_demand": 133.1980468,
    "grid_to_demand": 33949.84313,
    "govt_battery_charge": 50.42206241
  },
  {
    "timestamp": "19/06/24 21:00",
    "demand": 36219.23951,
    "solar_generated": 0,
    "wind_generated": 62.0017539,
    "marketplace_to_demand": 30.11088842,
    "grid_to_demand": 31220.62469,
    "govt_battery_charge": 64.51510858
  },
  {
    "timestamp": "19/06/24 22:00",
    "demand": 34471.93721,
    "solar_generated": 0,
    "wind_generated": 58.67348619,
    "marketplace_to_demand": 46.04116653,
    "grid_to_demand": 35455.31216,
    "govt_battery_charge": 56.23185417
  },
  {
    "timestamp": "19/06/24 23:00",
    "demand": 35246.7107,
    "solar_generated": 0,
    "wind_generated": 61.70600234,
    "marketplace_to_demand": 109.5213861,
    "grid_to_demand": 35202.57528,
    "govt_battery_charge": 9.422590073
  },
  {
    "timestamp": "20/06/24 0:00",
    "demand": 35596.23538,
    "solar_generated": 0,
    "wind_generated": 66.73856752,
    "marketplace_to_demand": 94.71089145,
    "grid_to_demand": 35019.80824,
    "govt_battery_charge": 96.23458575
  },
  {
    "timestamp": "20/06/24 1:00",
    "demand": 36495.2534,
    "solar_generated": 0,
    "wind_generated": 73.91497922,
    "marketplace_to_demand": 142.5622371,
    "grid_to_demand": 36475.05163,
    "govt_battery_charge": 23.14412378
  },
  {
    "timestamp": "20/06/24 2:00",
    "demand": 30373.48797,
    "solar_generated": 0,
    "wind_generated": 69.9525265,
    "marketplace_to_demand": 177.4698722,
    "grid_to_demand": 34405.53919,
    "govt_battery_charge": 20.83670915
  },
  {
    "timestamp": "20/06/24 3:00",
    "demand": 34555.12211,
    "solar_generated": 0,
    "wind_generated": 74.38572886,
    "marketplace_to_demand": 69.98902096,
    "grid_to_demand": 32138.42372,
    "govt_battery_charge": 27.03845099
  },
  {
    "timestamp": "20/06/24 4:00",
    "demand": 35121.72044,
    "solar_generated": 0,
    "wind_generated": 72.19730596,
    "marketplace_to_demand": 59.44075023,
    "grid_to_demand": 35421.2932,
    "govt_battery_charge": 99.14108555
  },
  {
    "timestamp": "20/06/24 5:00",
    "demand": 32471.4669,
    "solar_generated": 0,
    "wind_generated": 76.27873571,
    "marketplace_to_demand": 1.998719913,
    "grid_to_demand": 32823.58269,
    "govt_battery_charge": 50.65154625
  },
  {
    "timestamp": "20/06/24 6:00",
    "demand": 30260.28574,
    "solar_generated": 12.53887374,
    "wind_generated": 80.26991738,
    "marketplace_to_demand": 48.73680831,
    "grid_to_demand": 32006.63818,
    "govt_battery_charge": 60.88151817
  },
  {
    "timestamp": "20/06/24 7:00",
    "demand": 32900.59343,
    "solar_generated": 8.322028523,
    "wind_generated": 68.10189428,
    "marketplace_to_demand": 132.2486391,
    "grid_to_demand": 34296.86727,
    "govt_battery_charge": 84.96873918
  },
  {
    "timestamp": "20/06/24 8:00",
    "demand": 31455.63285,
    "solar_generated": 14.19492353,
    "wind_generated": 70.55080543,
    "marketplace_to_demand": 113.9912063,
    "grid_to_demand": 31005.3678,
    "govt_battery_charge": 88.44161686
  },
  {
    "timestamp": "20/06/24 9:00",
    "demand": 34027.90766,
    "solar_generated": 16.12874768,
    "wind_generated": 55.4525158,
    "marketplace_to_demand": 69.56843267,
    "grid_to_demand": 36064.5983,
    "govt_battery_charge": 35.77922898
  },
  {
    "timestamp": "20/06/24 10:00",
    "demand": 36442.30192,
    "solar_generated": 21.69283183,
    "wind_generated": 76.5169356,
    "marketplace_to_demand": 168.148355,
    "grid_to_demand": 33043.74866,
    "govt_battery_charge": 93.00723303
  },
  {
    "timestamp": "20/06/24 11:00",
    "demand": 34964.84902,
    "solar_generated": 21.75753084,
    "wind_generated": 75.49318916,
    "marketplace_to_demand": 46.61714768,
    "grid_to_demand": 31827.22902,
    "govt_battery_charge": 14.50740972
  },
  {
    "timestamp": "20/06/24 12:00",
    "demand": 35418.90306,
    "solar_generated": 23.09901501,
    "wind_generated": 65.0198226,
    "marketplace_to_demand": 22.67912548,
    "grid_to_demand": 31795.83054,
    "govt_battery_charge": 61.66596388
  },
  {
    "timestamp": "20/06/24 13:00",
    "demand": 32268.2157,
    "solar_generated": 9.067388086,
    "wind_generated": 54.15948037,
    "marketplace_to_demand": 104.6411078,
    "grid_to_demand": 31386.22747,
    "govt_battery_charge": 78.29071746
  },
  {
    "timestamp": "20/06/24 14:00",
    "demand": 30957.14676,
    "solar_generated": 11.78906842,
    "wind_generated": 61.03689488,
    "marketplace_to_demand": 51.55658422,
    "grid_to_demand": 32589.52253,
    "govt_battery_charge": 47.62241078
  },
  {
    "timestamp": "20/06/24 15:00",
    "demand": 31353.24148,
    "solar_generated": 12.23241397,
    "wind_generated": 66.59950672,
    "marketplace_to_demand": 92.09370428,
    "grid_to_demand": 30420.55062,
    "govt_battery_charge": 4.252774936
  },
  {
    "timestamp": "20/06/24 16:00",
    "demand": 32644.7201,
    "solar_generated": 6.241861589,
    "wind_generated": 73.21409909,
    "marketplace_to_demand": 163.9212597,
    "grid_to_demand": 32703.1315,
    "govt_battery_charge": 86.83174525
  },
  {
    "timestamp": "20/06/24 17:00",
    "demand": 34278.33591,
    "solar_generated": 23.96114144,
    "wind_generated": 65.1115793,
    "marketplace_to_demand": 130.7153838,
    "grid_to_demand": 31510.50611,
    "govt_battery_charge": 71.51418205
  },
  {
    "timestamp": "20/06/24 18:00",
    "demand": 35698.25778,
    "solar_generated": 0,
    "wind_generated": 57.6581416,
    "marketplace_to_demand": 19.01947668,
    "grid_to_demand": 31272.33889,
    "govt_battery_charge": 50.76308817
  },
  {
    "timestamp": "20/06/24 19:00",
    "demand": 31418.75192,
    "solar_generated": 0,
    "wind_generated": 79.3226184,
    "marketplace_to_demand": 166.2716997,
    "grid_to_demand": 30138.52495,
    "govt_battery_charge": 9.696410206
  },
  {
    "timestamp": "20/06/24 20:00",
    "demand": 34049.61845,
    "solar_generated": 0,
    "wind_generated": 58.9447632,
    "marketplace_to_demand": 92.72743302,
    "grid_to_demand": 30832.0014,
    "govt_battery_charge": 77.64466721
  },
  {
    "timestamp": "20/06/24 21:00",
    "demand": 31326.15513,
    "solar_generated": 0,
    "wind_generated": 58.44416424,
    "marketplace_to_demand": 1.15195366,
    "grid_to_demand": 31263.53792,
    "govt_battery_charge": 12.49997299
  },
  {
    "timestamp": "20/06/24 22:00",
    "demand": 35907.53652,
    "solar_generated": 0,
    "wind_generated": 69.11380668,
    "marketplace_to_demand": 99.19561081,
    "grid_to_demand": 33300.11696,
    "govt_battery_charge": 58.93127702
  },
  {
    "timestamp": "20/06/24 23:00",
    "demand": 36208.23244,
    "solar_generated": 0,
    "wind_generated": 54.40964429,
    "marketplace_to_demand": 38.64213662,
    "grid_to_demand": 32445.24279,
    "govt_battery_charge": 98.48869078
  },
  {
    "timestamp": "21/06/24 0:00",
    "demand": 30474.55036,
    "solar_generated": 0,
    "wind_generated": 72.02819224,
    "marketplace_to_demand": 74.32133646,
    "grid_to_demand": 32305.27122,
    "govt_battery_charge": 92.89212904
  },
  {
    "timestamp": "21/06/24 1:00",
    "demand": 34982.878,
    "solar_generated": 0,
    "wind_generated": 67.58971408,
    "marketplace_to_demand": 112.7839375,
    "grid_to_demand": 35588.35629,
    "govt_battery_charge": 41.07985216
  },
  {
    "timestamp": "21/06/24 2:00",
    "demand": 33060.53622,
    "solar_generated": 0,
    "wind_generated": 64.09212453,
    "marketplace_to_demand": 125.369275,
    "grid_to_demand": 33043.09991,
    "govt_battery_charge": 24.70576334
  },
  {
    "timestamp": "21/06/24 3:00",
    "demand": 31610.19764,
    "solar_generated": 0,
    "wind_generated": 59.71228111,
    "marketplace_to_demand": 122.1667264,
    "grid_to_demand": 32923.40033,
    "govt_battery_charge": 82.25441785
  },
  {
    "timestamp": "21/06/24 4:00",
    "demand": 31896.65563,
    "solar_generated": 0,
    "wind_generated": 71.96789474,
    "marketplace_to_demand": 164.0005717,
    "grid_to_demand": 36506.74333,
    "govt_battery_charge": 15.43036708
  },
  {
    "timestamp": "21/06/24 5:00",
    "demand": 35999.82147,
    "solar_generated": 0,
    "wind_generated": 54.0689811,
    "marketplace_to_demand": 186.9513682,
    "grid_to_demand": 33431.4647,
    "govt_battery_charge": 98.82130204
  },
  {
    "timestamp": "21/06/24 6:00",
    "demand": 30003.85624,
    "solar_generated": 17.59591267,
    "wind_generated": 56.71217184,
    "marketplace_to_demand": 1.095730612,
    "grid_to_demand": 35346.35925,
    "govt_battery_charge": 84.65034401
  },
  {
    "timestamp": "21/06/24 7:00",
    "demand": 30488.8134,
    "solar_generated": 13.12657823,
    "wind_generated": 56.17256376,
    "marketplace_to_demand": 192.3994589,
    "grid_to_demand": 33936.45439,
    "govt_battery_charge": 8.26602892
  },
  {
    "timestamp": "21/06/24 8:00",
    "demand": 35650.63523,
    "solar_generated": 13.35411851,
    "wind_generated": 72.8311885,
    "marketplace_to_demand": 184.0425693,
    "grid_to_demand": 34379.73066,
    "govt_battery_charge": 87.76992847
  },
  {
    "timestamp": "21/06/24 9:00",
    "demand": 36055.08143,
    "solar_generated": 23.00708585,
    "wind_generated": 78.1924347,
    "marketplace_to_demand": 105.1340658,
    "grid_to_demand": 32523.47993,
    "govt_battery_charge": 17.48340458
  },
  {
    "timestamp": "21/06/24 10:00",
    "demand": 30828.26258,
    "solar_generated": 9.121127024,
    "wind_generated": 62.2637529,
    "marketplace_to_demand": 83.00460527,
    "grid_to_demand": 30094.24086,
    "govt_battery_charge": 3.63013311
  },
  {
    "timestamp": "21/06/24 11:00",
    "demand": 33642.86428,
    "solar_generated": 8.727972434,
    "wind_generated": 72.57695746,
    "marketplace_to_demand": 133.9257232,
    "grid_to_demand": 35219.37457,
    "govt_battery_charge": 44.40065613
  },
  {
    "timestamp": "21/06/24 12:00",
    "demand": 35983.18748,
    "solar_generated": 17.55690843,
    "wind_generated": 75.09930284,
    "marketplace_to_demand": 43.02555376,
    "grid_to_demand": 32346.38176,
    "govt_battery_charge": 72.16393649
  },
  {
    "timestamp": "21/06/24 13:00",
    "demand": 34620.89191,
    "solar_generated": 23.58582511,
    "wind_generated": 61.29015534,
    "marketplace_to_demand": 167.8483999,
    "grid_to_demand": 32446.43359,
    "govt_battery_charge": 70.86022792
  },
  {
    "timestamp": "21/06/24 14:00",
    "demand": 36480.06359,
    "solar_generated": 13.30076987,
    "wind_generated": 65.48570583,
    "marketplace_to_demand": 199.3351182,
    "grid_to_demand": 33592.93518,
    "govt_battery_charge": 28.89930411
  },
  {
    "timestamp": "21/06/24 15:00",
    "demand": 35967.99378,
    "solar_generated": 9.831850006,
    "wind_generated": 71.29703382,
    "marketplace_to_demand": 180.5843445,
    "grid_to_demand": 35015.79442,
    "govt_battery_charge": 91.39905929
  },
  {
    "timestamp": "21/06/24 16:00",
    "demand": 31959.94432,
    "solar_generated": 22.91634283,
    "wind_generated": 77.81044277,
    "marketplace_to_demand": 121.4434316,
    "grid_to_demand": 32711.43418,
    "govt_battery_charge": 49.85735234
  },
  {
    "timestamp": "21/06/24 17:00",
    "demand": 34947.33967,
    "solar_generated": 12.15772478,
    "wind_generated": 76.47055746,
    "marketplace_to_demand": 50.22714366,
    "grid_to_demand": 33706.29304,
    "govt_battery_charge": 78.21949887
  },
  {
    "timestamp": "21/06/24 18:00",
    "demand": 35549.71886,
    "solar_generated": 0,
    "wind_generated": 70.43011441,
    "marketplace_to_demand": 121.9105242,
    "grid_to_demand": 31003.92361,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "21/06/24 19:00",
    "demand": 35766.94299,
    "solar_generated": 0,
    "wind_generated": 70.10393856,
    "marketplace_to_demand": 117.0129115,
    "grid_to_demand": 35058.77861,
    "govt_battery_charge": 15.42958683
  },
  {
    "timestamp": "21/06/24 20:00",
    "demand": 36619.03784,
    "solar_generated": 0,
    "wind_generated": 55.89667195,
    "marketplace_to_demand": 168.6358459,
    "grid_to_demand": 30634.65623,
    "govt_battery_charge": 82.41137332
  },
  {
    "timestamp": "21/06/24 21:00",
    "demand": 32883.8447,
    "solar_generated": 0,
    "wind_generated": 58.33984748,
    "marketplace_to_demand": 76.277944,
    "grid_to_demand": 31451.89461,
    "govt_battery_charge": 99.87536675
  },
  {
    "timestamp": "21/06/24 22:00",
    "demand": 31917.325,
    "solar_generated": 0,
    "wind_generated": 61.4638552,
    "marketplace_to_demand": 27.86643584,
    "grid_to_demand": 32540.50497,
    "govt_battery_charge": 58.84587419
  },
  {
    "timestamp": "21/06/24 23:00",
    "demand": 36147.59953,
    "solar_generated": 0,
    "wind_generated": 63.7697958,
    "marketplace_to_demand": 194.1633649,
    "grid_to_demand": 30727.59896,
    "govt_battery_charge": 90.2326193
  },
  {
    "timestamp": "22/06/24 0:00",
    "demand": 36155.39458,
    "solar_generated": 0,
    "wind_generated": 66.09090709,
    "marketplace_to_demand": 0.749536283,
    "grid_to_demand": 34529.66069,
    "govt_battery_charge": 93.23412225
  },
  {
    "timestamp": "22/06/24 1:00",
    "demand": 31280.83986,
    "solar_generated": 0,
    "wind_generated": 71.51004336,
    "marketplace_to_demand": 10.10163049,
    "grid_to_demand": 32440.0183,
    "govt_battery_charge": 28.65281522
  },
  {
    "timestamp": "22/06/24 2:00",
    "demand": 30134.1259,
    "solar_generated": 0,
    "wind_generated": 78.14046146,
    "marketplace_to_demand": 9.364690691,
    "grid_to_demand": 36433.41699,
    "govt_battery_charge": 48.61028887
  },
  {
    "timestamp": "22/06/24 3:00",
    "demand": 30759.09832,
    "solar_generated": 0,
    "wind_generated": 67.65892998,
    "marketplace_to_demand": 158.9375892,
    "grid_to_demand": 36456.15015,
    "govt_battery_charge": 55.62448327
  },
  {
    "timestamp": "22/06/24 4:00",
    "demand": 32753.30753,
    "solar_generated": 0,
    "wind_generated": 73.57057568,
    "marketplace_to_demand": 150.1518382,
    "grid_to_demand": 29978.75666,
    "govt_battery_charge": 29.03444103
  },
  {
    "timestamp": "22/06/24 5:00",
    "demand": 35339.26903,
    "solar_generated": 0,
    "wind_generated": 69.56425945,
    "marketplace_to_demand": 178.3197063,
    "grid_to_demand": 32721.09786,
    "govt_battery_charge": 75.8814389
  },
  {
    "timestamp": "22/06/24 6:00",
    "demand": 31076.57603,
    "solar_generated": 19.52138935,
    "wind_generated": 65.77135214,
    "marketplace_to_demand": 137.91967,
    "grid_to_demand": 32850.17459,
    "govt_battery_charge": 41.94564226
  },
  {
    "timestamp": "22/06/24 7:00",
    "demand": 31191.4512,
    "solar_generated": 11.8002111,
    "wind_generated": 57.3871558,
    "marketplace_to_demand": 49.60775027,
    "grid_to_demand": 30088.57063,
    "govt_battery_charge": 13.74337742
  },
  {
    "timestamp": "22/06/24 8:00",
    "demand": 35053.17371,
    "solar_generated": 18.92120847,
    "wind_generated": 79.33214689,
    "marketplace_to_demand": 190.8680344,
    "grid_to_demand": 30667.91276,
    "govt_battery_charge": 62.73078853
  },
  {
    "timestamp": "22/06/24 9:00",
    "demand": 30789.64278,
    "solar_generated": 16.6815943,
    "wind_generated": 63.28562942,
    "marketplace_to_demand": 86.6747945,
    "grid_to_demand": 35096.24615,
    "govt_battery_charge": 66.51215431
  },
  {
    "timestamp": "22/06/24 10:00",
    "demand": 32105.54185,
    "solar_generated": 18.25607637,
    "wind_generated": 70.52437582,
    "marketplace_to_demand": 12.76376059,
    "grid_to_demand": 34391.48594,
    "govt_battery_charge": 81.70259405
  },
  {
    "timestamp": "22/06/24 11:00",
    "demand": 35422.60496,
    "solar_generated": 23.56308868,
    "wind_generated": 59.08621881,
    "marketplace_to_demand": 173.0134019,
    "grid_to_demand": 34430.10826,
    "govt_battery_charge": 29.14791069
  },
  {
    "timestamp": "22/06/24 12:00",
    "demand": 32409.41703,
    "solar_generated": 12.15164566,
    "wind_generated": 71.47351419,
    "marketplace_to_demand": 135.4789684,
    "grid_to_demand": 31641.66933,
    "govt_battery_charge": 40.24246436
  },
  {
    "timestamp": "22/06/24 13:00",
    "demand": 30158.72597,
    "solar_generated": 7.812766297,
    "wind_generated": 72.46165833,
    "marketplace_to_demand": 45.84332097,
    "grid_to_demand": 32269.94674,
    "govt_battery_charge": 51.12516536
  },
  {
    "timestamp": "22/06/24 14:00",
    "demand": 30459.76751,
    "solar_generated": 7.609500762,
    "wind_generated": 78.51376184,
    "marketplace_to_demand": 154.9679781,
    "grid_to_demand": 33032.68576,
    "govt_battery_charge": 1.323753533
  },
  {
    "timestamp": "22/06/24 15:00",
    "demand": 34747.13256,
    "solar_generated": 18.47750721,
    "wind_generated": 70.97972023,
    "marketplace_to_demand": 125.067284,
    "grid_to_demand": 32096.83764,
    "govt_battery_charge": 42.877702
  },
  {
    "timestamp": "22/06/24 16:00",
    "demand": 34495.72077,
    "solar_generated": 17.22446427,
    "wind_generated": 57.82787846,
    "marketplace_to_demand": 36.38006304,
    "grid_to_demand": 35577.13106,
    "govt_battery_charge": 17.34176781
  },
  {
    "timestamp": "22/06/24 17:00",
    "demand": 35316.26602,
    "solar_generated": 8.489469879,
    "wind_generated": 66.49449907,
    "marketplace_to_demand": 154.6406873,
    "grid_to_demand": 30908.82376,
    "govt_battery_charge": 9.928201914
  },
  {
    "timestamp": "22/06/24 18:00",
    "demand": 34596.93579,
    "solar_generated": 0,
    "wind_generated": 65.92323212,
    "marketplace_to_demand": 142.6015976,
    "grid_to_demand": 32021.82158,
    "govt_battery_charge": 20.75589527
  },
  {
    "timestamp": "22/06/24 19:00",
    "demand": 32057.13697,
    "solar_generated": 0,
    "wind_generated": 74.70698892,
    "marketplace_to_demand": 51.66092636,
    "grid_to_demand": 29979.67194,
    "govt_battery_charge": 6.531656705
  },
  {
    "timestamp": "22/06/24 20:00",
    "demand": 34369.21957,
    "solar_generated": 0,
    "wind_generated": 68.14062979,
    "marketplace_to_demand": 16.49380794,
    "grid_to_demand": 31237.3379,
    "govt_battery_charge": 39.11477114
  },
  {
    "timestamp": "22/06/24 21:00",
    "demand": 33618.11635,
    "solar_generated": 0,
    "wind_generated": 57.77217612,
    "marketplace_to_demand": 161.3876322,
    "grid_to_demand": 34968.29711,
    "govt_battery_charge": 80.60731949
  },
  {
    "timestamp": "22/06/24 22:00",
    "demand": 34304.88329,
    "solar_generated": 0,
    "wind_generated": 55.70675007,
    "marketplace_to_demand": 76.8789818,
    "grid_to_demand": 35686.05575,
    "govt_battery_charge": 31.32860722
  },
  {
    "timestamp": "22/06/24 23:00",
    "demand": 30762.63557,
    "solar_generated": 0,
    "wind_generated": 70.25184879,
    "marketplace_to_demand": 93.01236584,
    "grid_to_demand": 30330.90167,
    "govt_battery_charge": 89.37372971
  },
  {
    "timestamp": "23/06/24 0:00",
    "demand": 36504.87199,
    "solar_generated": 0,
    "wind_generated": 63.01228049,
    "marketplace_to_demand": 159.2388493,
    "grid_to_demand": 36082.37514,
    "govt_battery_charge": 90.18509992
  },
  {
    "timestamp": "23/06/24 1:00",
    "demand": 33866.48513,
    "solar_generated": 0,
    "wind_generated": 55.34822086,
    "marketplace_to_demand": 21.4607889,
    "grid_to_demand": 31345.26142,
    "govt_battery_charge": 45.36799956
  },
  {
    "timestamp": "23/06/24 2:00",
    "demand": 34617.43945,
    "solar_generated": 0,
    "wind_generated": 56.1027549,
    "marketplace_to_demand": 165.9350491,
    "grid_to_demand": 32232.52057,
    "govt_battery_charge": 93.89188791
  },
  {
    "timestamp": "23/06/24 3:00",
    "demand": 31094.31435,
    "solar_generated": 0,
    "wind_generated": 62.37928938,
    "marketplace_to_demand": 38.49646956,
    "grid_to_demand": 34834.5798,
    "govt_battery_charge": 95.15182101
  },
  {
    "timestamp": "23/06/24 4:00",
    "demand": 32659.92471,
    "solar_generated": 0,
    "wind_generated": 77.49237742,
    "marketplace_to_demand": 106.3079244,
    "grid_to_demand": 30740.4381,
    "govt_battery_charge": 46.55617043
  },
  {
    "timestamp": "23/06/24 5:00",
    "demand": 31526.45069,
    "solar_generated": 0,
    "wind_generated": 70.32390128,
    "marketplace_to_demand": 90.86196249,
    "grid_to_demand": 33285.941,
    "govt_battery_charge": 13.93748569
  },
  {
    "timestamp": "23/06/24 6:00",
    "demand": 35585.55741,
    "solar_generated": 20.3842138,
    "wind_generated": 62.90314146,
    "marketplace_to_demand": 169.155309,
    "grid_to_demand": 32423.03576,
    "govt_battery_charge": 98.24377905
  },
  {
    "timestamp": "23/06/24 7:00",
    "demand": 36073.68299,
    "solar_generated": 10.55557084,
    "wind_generated": 76.96588341,
    "marketplace_to_demand": 90.86033448,
    "grid_to_demand": 34900.48298,
    "govt_battery_charge": 53.66374399
  },
  {
    "timestamp": "23/06/24 8:00",
    "demand": 30063.29283,
    "solar_generated": 8.430856414,
    "wind_generated": 77.24706381,
    "marketplace_to_demand": 91.57860844,
    "grid_to_demand": 30574.49572,
    "govt_battery_charge": 17.07224305
  },
  {
    "timestamp": "23/06/24 9:00",
    "demand": 32669.34772,
    "solar_generated": 20.69357382,
    "wind_generated": 55.45655296,
    "marketplace_to_demand": 54.75442258,
    "grid_to_demand": 32228.39749,
    "govt_battery_charge": 22.84248775
  },
  {
    "timestamp": "23/06/24 10:00",
    "demand": 31205.47383,
    "solar_generated": 8.765589156,
    "wind_generated": 54.51611817,
    "marketplace_to_demand": 164.8132199,
    "grid_to_demand": 32758.594,
    "govt_battery_charge": 37.65110254
  },
  {
    "timestamp": "23/06/24 11:00",
    "demand": 32150.88625,
    "solar_generated": 22.79909419,
    "wind_generated": 79.6689133,
    "marketplace_to_demand": 40.5149227,
    "grid_to_demand": 34472.3414,
    "govt_battery_charge": 9.801331997
  },
  {
    "timestamp": "23/06/24 12:00",
    "demand": 35233.015,
    "solar_generated": 15.33301391,
    "wind_generated": 72.11137955,
    "marketplace_to_demand": 86.75074759,
    "grid_to_demand": 33078.03506,
    "govt_battery_charge": 22.18001135
  },
  {
    "timestamp": "23/06/24 13:00",
    "demand": 30517.46164,
    "solar_generated": 8.42288898,
    "wind_generated": 62.17060643,
    "marketplace_to_demand": 15.92018264,
    "grid_to_demand": 34738.07926,
    "govt_battery_charge": 60.17364072
  },
  {
    "timestamp": "23/06/24 14:00",
    "demand": 35247.81662,
    "solar_generated": 22.13873155,
    "wind_generated": 59.93932443,
    "marketplace_to_demand": 31.87556055,
    "grid_to_demand": 30620.4135,
    "govt_battery_charge": 68.60027613
  },
  {
    "timestamp": "23/06/24 15:00",
    "demand": 36024.59705,
    "solar_generated": 14.58511013,
    "wind_generated": 65.70220461,
    "marketplace_to_demand": 194.8355182,
    "grid_to_demand": 34223.54207,
    "govt_battery_charge": 38.15133694
  },
  {
    "timestamp": "23/06/24 16:00",
    "demand": 32382.71457,
    "solar_generated": 22.49083681,
    "wind_generated": 79.97875154,
    "marketplace_to_demand": 15.91983014,
    "grid_to_demand": 30934.81052,
    "govt_battery_charge": 9.676516037
  },
  {
    "timestamp": "23/06/24 17:00",
    "demand": 33631.34109,
    "solar_generated": 22.04261494,
    "wind_generated": 78.35079868,
    "marketplace_to_demand": 6.090860596,
    "grid_to_demand": 36171.47888,
    "govt_battery_charge": 36.87742967
  },
  {
    "timestamp": "23/06/24 18:00",
    "demand": 32225.33611,
    "solar_generated": 0,
    "wind_generated": 71.08809163,
    "marketplace_to_demand": 178.3424405,
    "grid_to_demand": 30323.70684,
    "govt_battery_charge": 54.08574306
  },
  {
    "timestamp": "23/06/24 19:00",
    "demand": 35830.61096,
    "solar_generated": 0,
    "wind_generated": 58.22511855,
    "marketplace_to_demand": 56.22275437,
    "grid_to_demand": 32018.63436,
    "govt_battery_charge": 85.69942753
  },
  {
    "timestamp": "23/06/24 20:00",
    "demand": 35027.90978,
    "solar_generated": 0,
    "wind_generated": 56.61161151,
    "marketplace_to_demand": 188.9637401,
    "grid_to_demand": 31656.45781,
    "govt_battery_charge": 33.75080632
  },
  {
    "timestamp": "23/06/24 21:00",
    "demand": 33522.10235,
    "solar_generated": 0,
    "wind_generated": 57.04364841,
    "marketplace_to_demand": 36.7124384,
    "grid_to_demand": 32500.28618,
    "govt_battery_charge": 27.24265429
  },
  {
    "timestamp": "23/06/24 22:00",
    "demand": 31554.85513,
    "solar_generated": 0,
    "wind_generated": 67.06665394,
    "marketplace_to_demand": 72.83558604,
    "grid_to_demand": 34199.62863,
    "govt_battery_charge": 5.539563385
  },
  {
    "timestamp": "23/06/24 23:00",
    "demand": 31093.4235,
    "solar_generated": 0,
    "wind_generated": 64.16037903,
    "marketplace_to_demand": 131.7598832,
    "grid_to_demand": 30186.49286,
    "govt_battery_charge": 27.22763093
  },
  {
    "timestamp": "24/06/24 0:00",
    "demand": 31185.6036,
    "solar_generated": 0,
    "wind_generated": 54.75399634,
    "marketplace_to_demand": 134.7874238,
    "grid_to_demand": 32534.28041,
    "govt_battery_charge": 35.8985569
  },
  {
    "timestamp": "24/06/24 1:00",
    "demand": 32535.0465,
    "solar_generated": 0,
    "wind_generated": 64.85207926,
    "marketplace_to_demand": 172.4965066,
    "grid_to_demand": 34377.15862,
    "govt_battery_charge": 21.0808888
  },
  {
    "timestamp": "24/06/24 2:00",
    "demand": 30639.80843,
    "solar_generated": 0,
    "wind_generated": 72.40210735,
    "marketplace_to_demand": 71.39481509,
    "grid_to_demand": 32307.59302,
    "govt_battery_charge": 15.67976518
  },
  {
    "timestamp": "24/06/24 3:00",
    "demand": 35181.74321,
    "solar_generated": 0,
    "wind_generated": 57.33469857,
    "marketplace_to_demand": 19.01906295,
    "grid_to_demand": 34566.89529,
    "govt_battery_charge": 80.18345675
  },
  {
    "timestamp": "24/06/24 4:00",
    "demand": 30008.97717,
    "solar_generated": 0,
    "wind_generated": 59.91960793,
    "marketplace_to_demand": 75.95062603,
    "grid_to_demand": 35041.93662,
    "govt_battery_charge": 58.57976286
  },
  {
    "timestamp": "24/06/24 5:00",
    "demand": 32526.21623,
    "solar_generated": 0,
    "wind_generated": 63.86918031,
    "marketplace_to_demand": 48.78467367,
    "grid_to_demand": 33609.56983,
    "govt_battery_charge": 2.927231863
  },
  {
    "timestamp": "24/06/24 6:00",
    "demand": 33254.62443,
    "solar_generated": 18.78143244,
    "wind_generated": 70.64783831,
    "marketplace_to_demand": 56.39959954,
    "grid_to_demand": 31266.2596,
    "govt_battery_charge": 50.44967588
  },
  {
    "timestamp": "24/06/24 7:00",
    "demand": 32794.40867,
    "solar_generated": 15.55775137,
    "wind_generated": 69.58738799,
    "marketplace_to_demand": 173.4559569,
    "grid_to_demand": 33807.34761,
    "govt_battery_charge": 84.12699955
  },
  {
    "timestamp": "24/06/24 8:00",
    "demand": 36293.43314,
    "solar_generated": 15.8420693,
    "wind_generated": 78.574286,
    "marketplace_to_demand": 118.6862292,
    "grid_to_demand": 29962.04839,
    "govt_battery_charge": 16.55208372
  },
  {
    "timestamp": "24/06/24 9:00",
    "demand": 33811.69216,
    "solar_generated": 11.09271621,
    "wind_generated": 71.47732239,
    "marketplace_to_demand": 194.5614812,
    "grid_to_demand": 30489.0619,
    "govt_battery_charge": 64.81797323
  },
  {
    "timestamp": "24/06/24 10:00",
    "demand": 30977.25964,
    "solar_generated": 8.645499145,
    "wind_generated": 75.5020611,
    "marketplace_to_demand": 181.6037196,
    "grid_to_demand": 30241.50692,
    "govt_battery_charge": 85.63657383
  },
  {
    "timestamp": "24/06/24 11:00",
    "demand": 33709.2759,
    "solar_generated": 18.09607462,
    "wind_generated": 62.31478775,
    "marketplace_to_demand": 93.47572916,
    "grid_to_demand": 35340.60439,
    "govt_battery_charge": 67.67931307
  },
  {
    "timestamp": "24/06/24 12:00",
    "demand": 34407.19945,
    "solar_generated": 17.13381706,
    "wind_generated": 54.73889409,
    "marketplace_to_demand": 8.540804891,
    "grid_to_demand": 33885.77791,
    "govt_battery_charge": 56.94239776
  },
  {
    "timestamp": "24/06/24 13:00",
    "demand": 36190.49408,
    "solar_generated": 17.19573731,
    "wind_generated": 66.26662589,
    "marketplace_to_demand": 81.28263374,
    "grid_to_demand": 31548.66893,
    "govt_battery_charge": 86.53469902
  },
  {
    "timestamp": "24/06/24 14:00",
    "demand": 35778.11704,
    "solar_generated": 21.63201793,
    "wind_generated": 64.9960509,
    "marketplace_to_demand": 112.001168,
    "grid_to_demand": 31778.18407,
    "govt_battery_charge": 19.68218397
  },
  {
    "timestamp": "24/06/24 15:00",
    "demand": 31158.49862,
    "solar_generated": 6.203831106,
    "wind_generated": 79.52052316,
    "marketplace_to_demand": 180.9727043,
    "grid_to_demand": 31095.26604,
    "govt_battery_charge": 50.0195769
  },
  {
    "timestamp": "24/06/24 16:00",
    "demand": 35606.83885,
    "solar_generated": 6.912514559,
    "wind_generated": 66.55046903,
    "marketplace_to_demand": 70.79713189,
    "grid_to_demand": 32419.81825,
    "govt_battery_charge": 47.65111329
  },
  {
    "timestamp": "24/06/24 17:00",
    "demand": 32271.37039,
    "solar_generated": 10.96559173,
    "wind_generated": 60.49384595,
    "marketplace_to_demand": 98.60755431,
    "grid_to_demand": 33510.89395,
    "govt_battery_charge": 42.08559219
  },
  {
    "timestamp": "24/06/24 18:00",
    "demand": 31111.82887,
    "solar_generated": 0,
    "wind_generated": 78.35222279,
    "marketplace_to_demand": 155.7330596,
    "grid_to_demand": 33322.02786,
    "govt_battery_charge": 78.72150316
  },
  {
    "timestamp": "24/06/24 19:00",
    "demand": 31527.43457,
    "solar_generated": 0,
    "wind_generated": 68.76530879,
    "marketplace_to_demand": 78.12675949,
    "grid_to_demand": 30345.72775,
    "govt_battery_charge": 10.30484985
  },
  {
    "timestamp": "24/06/24 20:00",
    "demand": 32744.36707,
    "solar_generated": 0,
    "wind_generated": 61.23844988,
    "marketplace_to_demand": 22.33035328,
    "grid_to_demand": 35446.27213,
    "govt_battery_charge": 41.03049424
  },
  {
    "timestamp": "24/06/24 21:00",
    "demand": 33758.64338,
    "solar_generated": 0,
    "wind_generated": 76.33055367,
    "marketplace_to_demand": 96.06146347,
    "grid_to_demand": 32404.78765,
    "govt_battery_charge": 45.83168993
  },
  {
    "timestamp": "24/06/24 22:00",
    "demand": 33753.37159,
    "solar_generated": 0,
    "wind_generated": 66.55648183,
    "marketplace_to_demand": 129.0689086,
    "grid_to_demand": 34447.05048,
    "govt_battery_charge": 75.34566812
  },
  {
    "timestamp": "24/06/24 23:00",
    "demand": 30214.68949,
    "solar_generated": 0,
    "wind_generated": 79.98109427,
    "marketplace_to_demand": 69.97744946,
    "grid_to_demand": 33764.72223,
    "govt_battery_charge": 57.42873791
  },
  {
    "timestamp": "25/06/24 0:00",
    "demand": 32286.75315,
    "solar_generated": 0,
    "wind_generated": 61.31052981,
    "marketplace_to_demand": 77.388116,
    "grid_to_demand": 35526.75343,
    "govt_battery_charge": 76.52900914
  },
  {
    "timestamp": "25/06/24 1:00",
    "demand": 31658.62311,
    "solar_generated": 0,
    "wind_generated": 72.27562694,
    "marketplace_to_demand": 22.86497832,
    "grid_to_demand": 30777.84488,
    "govt_battery_charge": 85.47367588
  },
  {
    "timestamp": "25/06/24 2:00",
    "demand": 30863.40499,
    "solar_generated": 0,
    "wind_generated": 65.73578477,
    "marketplace_to_demand": 79.56468181,
    "grid_to_demand": 32943.83372,
    "govt_battery_charge": 4.100663831
  },
  {
    "timestamp": "25/06/24 3:00",
    "demand": 31931.75874,
    "solar_generated": 0,
    "wind_generated": 72.3529836,
    "marketplace_to_demand": 143.8460596,
    "grid_to_demand": 32769.62471,
    "govt_battery_charge": 47.08897476
  },
  {
    "timestamp": "25/06/24 4:00",
    "demand": 31685.02171,
    "solar_generated": 0,
    "wind_generated": 79.88986444,
    "marketplace_to_demand": 195.7951408,
    "grid_to_demand": 32417.21833,
    "govt_battery_charge": 87.60918097
  },
  {
    "timestamp": "25/06/24 5:00",
    "demand": 30259.97355,
    "solar_generated": 0,
    "wind_generated": 76.77138308,
    "marketplace_to_demand": 80.04425978,
    "grid_to_demand": 35133.32899,
    "govt_battery_charge": 89.79064202
  },
  {
    "timestamp": "25/06/24 6:00",
    "demand": 31878.45137,
    "solar_generated": 10.0180087,
    "wind_generated": 70.09316786,
    "marketplace_to_demand": 75.99562393,
    "grid_to_demand": 31643.39427,
    "govt_battery_charge": 73.01220553
  },
  {
    "timestamp": "25/06/24 7:00",
    "demand": 33000.54868,
    "solar_generated": 17.57478863,
    "wind_generated": 55.50692825,
    "marketplace_to_demand": 156.9978171,
    "grid_to_demand": 35782.15628,
    "govt_battery_charge": 7.944329922
  },
  {
    "timestamp": "25/06/24 8:00",
    "demand": 35798.41164,
    "solar_generated": 8.975110257,
    "wind_generated": 58.52794818,
    "marketplace_to_demand": 181.697786,
    "grid_to_demand": 35290.21801,
    "govt_battery_charge": 25.87521128
  },
  {
    "timestamp": "25/06/24 9:00",
    "demand": 33266.09483,
    "solar_generated": 21.7651544,
    "wind_generated": 75.81782834,
    "marketplace_to_demand": 46.25376582,
    "grid_to_demand": 34977.17567,
    "govt_battery_charge": 84.2952381
  },
  {
    "timestamp": "25/06/24 10:00",
    "demand": 32969.991,
    "solar_generated": 16.48025208,
    "wind_generated": 64.49038373,
    "marketplace_to_demand": 187.8888084,
    "grid_to_demand": 33918.37695,
    "govt_battery_charge": 20.28045616
  },
  {
    "timestamp": "25/06/24 11:00",
    "demand": 32032.6824,
    "solar_generated": 10.70121699,
    "wind_generated": 69.01600891,
    "marketplace_to_demand": 34.96783396,
    "grid_to_demand": 35552.09947,
    "govt_battery_charge": 94.84125975
  },
  {
    "timestamp": "25/06/24 12:00",
    "demand": 30996.28093,
    "solar_generated": 7.450275276,
    "wind_generated": 79.38306882,
    "marketplace_to_demand": 132.0217658,
    "grid_to_demand": 36394.62424,
    "govt_battery_charge": 44.80620167
  },
  {
    "timestamp": "25/06/24 13:00",
    "demand": 30918.77378,
    "solar_generated": 10.98133888,
    "wind_generated": 71.71435658,
    "marketplace_to_demand": 158.994614,
    "grid_to_demand": 30012.54826,
    "govt_battery_charge": 82.37716354
  },
  {
    "timestamp": "25/06/24 14:00",
    "demand": 35350.62236,
    "solar_generated": 7.74797044,
    "wind_generated": 54.49487469,
    "marketplace_to_demand": 62.82973555,
    "grid_to_demand": 34391.03816,
    "govt_battery_charge": 31.39797925
  },
  {
    "timestamp": "25/06/24 15:00",
    "demand": 31271.16528,
    "solar_generated": 8.260804815,
    "wind_generated": 69.26761707,
    "marketplace_to_demand": 67.23509476,
    "grid_to_demand": 33889.28137,
    "govt_battery_charge": 41.98818783
  },
  {
    "timestamp": "25/06/24 16:00",
    "demand": 34932.81231,
    "solar_generated": 22.18549332,
    "wind_generated": 66.78367732,
    "marketplace_to_demand": 131.9600186,
    "grid_to_demand": 31157.47211,
    "govt_battery_charge": 11.88797752
  },
  {
    "timestamp": "25/06/24 17:00",
    "demand": 36053.62745,
    "solar_generated": 18.75741356,
    "wind_generated": 77.04572984,
    "marketplace_to_demand": 41.67554298,
    "grid_to_demand": 32486.58198,
    "govt_battery_charge": 35.02504875
  },
  {
    "timestamp": "25/06/24 18:00",
    "demand": 34839.97293,
    "solar_generated": 0,
    "wind_generated": 65.60293723,
    "marketplace_to_demand": 129.10005,
    "grid_to_demand": 34552.42295,
    "govt_battery_charge": 46.1638352
  },
  {
    "timestamp": "25/06/24 19:00",
    "demand": 33248.02498,
    "solar_generated": 0,
    "wind_generated": 73.70203096,
    "marketplace_to_demand": 126.3423727,
    "grid_to_demand": 30869.35692,
    "govt_battery_charge": 28.18905857
  },
  {
    "timestamp": "25/06/24 20:00",
    "demand": 32437.70316,
    "solar_generated": 0,
    "wind_generated": 56.11062764,
    "marketplace_to_demand": 109.5540334,
    "grid_to_demand": 30847.22068,
    "govt_battery_charge": 44.24252113
  },
  {
    "timestamp": "25/06/24 21:00",
    "demand": 34507.39374,
    "solar_generated": 0,
    "wind_generated": 77.45256693,
    "marketplace_to_demand": 57.50782046,
    "grid_to_demand": 31888.18355,
    "govt_battery_charge": 14.51097321
  },
  {
    "timestamp": "25/06/24 22:00",
    "demand": 30538.77474,
    "solar_generated": 0,
    "wind_generated": 72.37007278,
    "marketplace_to_demand": 97.74262033,
    "grid_to_demand": 30513.7172,
    "govt_battery_charge": 57.08015345
  },
  {
    "timestamp": "25/06/24 23:00",
    "demand": 35329.90661,
    "solar_generated": 0,
    "wind_generated": 67.11792601,
    "marketplace_to_demand": 40.68342521,
    "grid_to_demand": 31353.69066,
    "govt_battery_charge": 94.92375442
  },
  {
    "timestamp": "26/06/24 0:00",
    "demand": 35771.9979,
    "solar_generated": 0,
    "wind_generated": 61.20143178,
    "marketplace_to_demand": 32.43674544,
    "grid_to_demand": 30139.88903,
    "govt_battery_charge": 28.63331765
  },
  {
    "timestamp": "26/06/24 1:00",
    "demand": 31736.03583,
    "solar_generated": 0,
    "wind_generated": 55.58852384,
    "marketplace_to_demand": 124.1387501,
    "grid_to_demand": 32642.8946,
    "govt_battery_charge": 77.848363
  },
  {
    "timestamp": "26/06/24 2:00",
    "demand": 35097.42501,
    "solar_generated": 0,
    "wind_generated": 54.83128949,
    "marketplace_to_demand": 47.51964105,
    "grid_to_demand": 30279.14053,
    "govt_battery_charge": 51.6875275
  },
  {
    "timestamp": "26/06/24 3:00",
    "demand": 30544.67577,
    "solar_generated": 0,
    "wind_generated": 73.15355319,
    "marketplace_to_demand": 91.51578821,
    "grid_to_demand": 34709.27353,
    "govt_battery_charge": 1.573374648
  },
  {
    "timestamp": "26/06/24 4:00",
    "demand": 30842.93077,
    "solar_generated": 0,
    "wind_generated": 68.54442265,
    "marketplace_to_demand": 94.68896961,
    "grid_to_demand": 35588.80511,
    "govt_battery_charge": 2.881959075
  },
  {
    "timestamp": "26/06/24 5:00",
    "demand": 30268.43294,
    "solar_generated": 0,
    "wind_generated": 60.61313749,
    "marketplace_to_demand": 190.0857845,
    "grid_to_demand": 36452.28542,
    "govt_battery_charge": 91.92002131
  },
  {
    "timestamp": "26/06/24 6:00",
    "demand": 30053.2024,
    "solar_generated": 7.436071372,
    "wind_generated": 77.47429173,
    "marketplace_to_demand": 102.3338043,
    "grid_to_demand": 33566.94857,
    "govt_battery_charge": 66.66371739
  },
  {
    "timestamp": "26/06/24 7:00",
    "demand": 36278.32802,
    "solar_generated": 10.9913965,
    "wind_generated": 57.65473914,
    "marketplace_to_demand": 46.67375293,
    "grid_to_demand": 33176.44816,
    "govt_battery_charge": 47.97062476
  },
  {
    "timestamp": "26/06/24 8:00",
    "demand": 33146.48154,
    "solar_generated": 22.20748273,
    "wind_generated": 78.86220072,
    "marketplace_to_demand": 59.4746068,
    "grid_to_demand": 33295.63695,
    "govt_battery_charge": 46.29761425
  },
  {
    "timestamp": "26/06/24 9:00",
    "demand": 33035.64334,
    "solar_generated": 23.55783195,
    "wind_generated": 64.16630019,
    "marketplace_to_demand": 99.88346116,
    "grid_to_demand": 36530.49889,
    "govt_battery_charge": 11.97487916
  },
  {
    "timestamp": "26/06/24 10:00",
    "demand": 31085.11512,
    "solar_generated": 6.972175347,
    "wind_generated": 70.04032042,
    "marketplace_to_demand": 171.4430807,
    "grid_to_demand": 34255.87498,
    "govt_battery_charge": 38.35024896
  },
  {
    "timestamp": "26/06/24 11:00",
    "demand": 35693.27349,
    "solar_generated": 8.732845186,
    "wind_generated": 61.87611097,
    "marketplace_to_demand": 60.42792413,
    "grid_to_demand": 34004.34984,
    "govt_battery_charge": 83.46343795
  },
  {
    "timestamp": "26/06/24 12:00",
    "demand": 30045.31325,
    "solar_generated": 10.27416624,
    "wind_generated": 60.23335581,
    "marketplace_to_demand": 157.1178336,
    "grid_to_demand": 33769.37767,
    "govt_battery_charge": 15.04899079
  },
  {
    "timestamp": "26/06/24 13:00",
    "demand": 30899.09326,
    "solar_generated": 8.813058073,
    "wind_generated": 76.18700089,
    "marketplace_to_demand": 94.98442936,
    "grid_to_demand": 32896.76024,
    "govt_battery_charge": 36.0392871
  },
  {
    "timestamp": "26/06/24 14:00",
    "demand": 35174.35539,
    "solar_generated": 9.568636589,
    "wind_generated": 79.33652373,
    "marketplace_to_demand": 67.75111925,
    "grid_to_demand": 35455.01762,
    "govt_battery_charge": 56.80977714
  },
  {
    "timestamp": "26/06/24 15:00",
    "demand": 36415.68099,
    "solar_generated": 20.21629797,
    "wind_generated": 55.97175413,
    "marketplace_to_demand": 12.13011249,
    "grid_to_demand": 31925.7355,
    "govt_battery_charge": 5.255079141
  },
  {
    "timestamp": "26/06/24 16:00",
    "demand": 32026.06368,
    "solar_generated": 14.67519283,
    "wind_generated": 69.63514975,
    "marketplace_to_demand": 133.0919837,
    "grid_to_demand": 34563.9284,
    "govt_battery_charge": 9.730250714
  },
  {
    "timestamp": "26/06/24 17:00",
    "demand": 36336.81957,
    "solar_generated": 15.94042175,
    "wind_generated": 63.44775541,
    "marketplace_to_demand": 7.242345734,
    "grid_to_demand": 34430.08644,
    "govt_battery_charge": 92.27163415
  },
  {
    "timestamp": "26/06/24 18:00",
    "demand": 35759.21978,
    "solar_generated": 0,
    "wind_generated": 64.24359348,
    "marketplace_to_demand": 162.9752196,
    "grid_to_demand": 30655.41513,
    "govt_battery_charge": 91.92426588
  },
  {
    "timestamp": "26/06/24 19:00",
    "demand": 33552.65231,
    "solar_generated": 0,
    "wind_generated": 56.32781158,
    "marketplace_to_demand": 35.17678272,
    "grid_to_demand": 31182.79134,
    "govt_battery_charge": 69.71137182
  },
  {
    "timestamp": "26/06/24 20:00",
    "demand": 33755.91837,
    "solar_generated": 0,
    "wind_generated": 55.03977403,
    "marketplace_to_demand": 10.68619273,
    "grid_to_demand": 34677.48329,
    "govt_battery_charge": 59.25453309
  },
  {
    "timestamp": "26/06/24 21:00",
    "demand": 31745.77016,
    "solar_generated": 0,
    "wind_generated": 79.86553794,
    "marketplace_to_demand": 6.837251047,
    "grid_to_demand": 34181.12699,
    "govt_battery_charge": 20.72419919
  },
  {
    "timestamp": "26/06/24 22:00",
    "demand": 36374.77079,
    "solar_generated": 0,
    "wind_generated": 68.15677271,
    "marketplace_to_demand": 96.771752,
    "grid_to_demand": 30086.45666,
    "govt_battery_charge": 24.09323027
  },
  {
    "timestamp": "26/06/24 23:00",
    "demand": 34906.60764,
    "solar_generated": 0,
    "wind_generated": 61.57737801,
    "marketplace_to_demand": 22.99629771,
    "grid_to_demand": 30311.02227,
    "govt_battery_charge": 60.75648123
  },
  {
    "timestamp": "27/06/24 0:00",
    "demand": 32998.90342,
    "solar_generated": 0,
    "wind_generated": 60.04848495,
    "marketplace_to_demand": 136.0559731,
    "grid_to_demand": 33446.12959,
    "govt_battery_charge": 7.412350964
  },
  {
    "timestamp": "27/06/24 1:00",
    "demand": 33983.47683,
    "solar_generated": 0,
    "wind_generated": 60.30186003,
    "marketplace_to_demand": 189.1161732,
    "grid_to_demand": 31348.8599,
    "govt_battery_charge": 98.28985207
  },
  {
    "timestamp": "27/06/24 2:00",
    "demand": 31858.26078,
    "solar_generated": 0,
    "wind_generated": 75.09111063,
    "marketplace_to_demand": 1.127940801,
    "grid_to_demand": 36030.54753,
    "govt_battery_charge": 26.78274092
  },
  {
    "timestamp": "27/06/24 3:00",
    "demand": 34762.809,
    "solar_generated": 0,
    "wind_generated": 69.77995398,
    "marketplace_to_demand": 96.99176911,
    "grid_to_demand": 30015.72178,
    "govt_battery_charge": 86.78085894
  },
  {
    "timestamp": "27/06/24 4:00",
    "demand": 34526.95732,
    "solar_generated": 0,
    "wind_generated": 74.08952671,
    "marketplace_to_demand": 133.1532152,
    "grid_to_demand": 34897.7935,
    "govt_battery_charge": 13.32044331
  },
  {
    "timestamp": "27/06/24 5:00",
    "demand": 30900.82056,
    "solar_generated": 0,
    "wind_generated": 75.88630609,
    "marketplace_to_demand": 28.46460204,
    "grid_to_demand": 29908.18622,
    "govt_battery_charge": 52.9686625
  },
  {
    "timestamp": "27/06/24 6:00",
    "demand": 30890.77437,
    "solar_generated": 22.06266014,
    "wind_generated": 64.2144584,
    "marketplace_to_demand": 83.77877216,
    "grid_to_demand": 31758.74664,
    "govt_battery_charge": 14.62253252
  },
  {
    "timestamp": "27/06/24 7:00",
    "demand": 34055.79058,
    "solar_generated": 20.57546204,
    "wind_generated": 59.78079719,
    "marketplace_to_demand": 61.37382206,
    "grid_to_demand": 34071.44036,
    "govt_battery_charge": 12.63985478
  },
  {
    "timestamp": "27/06/24 8:00",
    "demand": 32526.155,
    "solar_generated": 20.00781719,
    "wind_generated": 76.53586022,
    "marketplace_to_demand": 13.07094945,
    "grid_to_demand": 30890.51899,
    "govt_battery_charge": 59.94613831
  },
  {
    "timestamp": "27/06/24 9:00",
    "demand": 30465.3058,
    "solar_generated": 8.890356001,
    "wind_generated": 67.00207479,
    "marketplace_to_demand": 193.2255616,
    "grid_to_demand": 31201.34929,
    "govt_battery_charge": 39.65848565
  },
  {
    "timestamp": "27/06/24 10:00",
    "demand": 33112.85343,
    "solar_generated": 13.82303179,
    "wind_generated": 61.44266041,
    "marketplace_to_demand": 143.7853299,
    "grid_to_demand": 34048.03728,
    "govt_battery_charge": 77.83229183
  },
  {
    "timestamp": "27/06/24 11:00",
    "demand": 31959.76895,
    "solar_generated": 7.886350691,
    "wind_generated": 69.20368624,
    "marketplace_to_demand": 28.85957076,
    "grid_to_demand": 35722.91588,
    "govt_battery_charge": 51.77596147
  },
  {
    "timestamp": "27/06/24 12:00",
    "demand": 32212.33792,
    "solar_generated": 18.97614588,
    "wind_generated": 76.06866598,
    "marketplace_to_demand": 8.61015789,
    "grid_to_demand": 36070.56931,
    "govt_battery_charge": 66.6512851
  },
  {
    "timestamp": "27/06/24 13:00",
    "demand": 30004.47403,
    "solar_generated": 14.95322076,
    "wind_generated": 66.63344565,
    "marketplace_to_demand": 168.2736999,
    "grid_to_demand": 33772.11253,
    "govt_battery_charge": 55.73066721
  },
  {
    "timestamp": "27/06/24 14:00",
    "demand": 30466.71202,
    "solar_generated": 16.27260662,
    "wind_generated": 69.22836912,
    "marketplace_to_demand": 45.7510778,
    "grid_to_demand": 36535.68373,
    "govt_battery_charge": 93.47620164
  },
  {
    "timestamp": "27/06/24 15:00",
    "demand": 32888.69292,
    "solar_generated": 7.889268205,
    "wind_generated": 53.84981278,
    "marketplace_to_demand": 5.439356758,
    "grid_to_demand": 35162.24062,
    "govt_battery_charge": 41.57692055
  },
  {
    "timestamp": "27/06/24 16:00",
    "demand": 30973.97436,
    "solar_generated": 13.45994066,
    "wind_generated": 77.98363455,
    "marketplace_to_demand": 39.05951819,
    "grid_to_demand": 35870.70295,
    "govt_battery_charge": 18.93783603
  },
  {
    "timestamp": "27/06/24 17:00",
    "demand": 33792.04864,
    "solar_generated": 8.797139817,
    "wind_generated": 66.6433835,
    "marketplace_to_demand": 146.6563433,
    "grid_to_demand": 32338.05823,
    "govt_battery_charge": 91.33035176
  },
  {
    "timestamp": "27/06/24 18:00",
    "demand": 30108.68043,
    "solar_generated": 0,
    "wind_generated": 80.11938304,
    "marketplace_to_demand": 37.12361151,
    "grid_to_demand": 30736.26823,
    "govt_battery_charge": 90.80984711
  },
  {
    "timestamp": "27/06/24 19:00",
    "demand": 31552.97173,
    "solar_generated": 0,
    "wind_generated": 60.47254023,
    "marketplace_to_demand": 148.8458799,
    "grid_to_demand": 35114.1671,
    "govt_battery_charge": 33.04473769
  },
  {
    "timestamp": "27/06/24 20:00",
    "demand": 32533.66052,
    "solar_generated": 0,
    "wind_generated": 72.33424968,
    "marketplace_to_demand": 98.98111307,
    "grid_to_demand": 32542.16732,
    "govt_battery_charge": 15.56283002
  },
  {
    "timestamp": "27/06/24 21:00",
    "demand": 32070.38176,
    "solar_generated": 0,
    "wind_generated": 73.52750971,
    "marketplace_to_demand": 109.1896996,
    "grid_to_demand": 32740.12364,
    "govt_battery_charge": 47.22107465
  },
  {
    "timestamp": "27/06/24 22:00",
    "demand": 30880.64471,
    "solar_generated": 0,
    "wind_generated": 67.06427819,
    "marketplace_to_demand": 36.07119801,
    "grid_to_demand": 33271.02407,
    "govt_battery_charge": 42.75453796
  },
  {
    "timestamp": "27/06/24 23:00",
    "demand": 33301.00108,
    "solar_generated": 0,
    "wind_generated": 61.83531,
    "marketplace_to_demand": 120.7772955,
    "grid_to_demand": 32826.20073,
    "govt_battery_charge": 32.44164318
  },
  {
    "timestamp": "28/06/24 0:00",
    "demand": 35479.73137,
    "solar_generated": 0,
    "wind_generated": 70.61010445,
    "marketplace_to_demand": 104.7305975,
    "grid_to_demand": 33482.10792,
    "govt_battery_charge": 52.6610522
  },
  {
    "timestamp": "28/06/24 1:00",
    "demand": 36345.96348,
    "solar_generated": 0,
    "wind_generated": 79.15380693,
    "marketplace_to_demand": 116.6916361,
    "grid_to_demand": 32978.3536,
    "govt_battery_charge": 65.74720183
  },
  {
    "timestamp": "28/06/24 2:00",
    "demand": 35805.28151,
    "solar_generated": 0,
    "wind_generated": 60.6416682,
    "marketplace_to_demand": 57.11572159,
    "grid_to_demand": 33081.30079,
    "govt_battery_charge": 56.50434422
  },
  {
    "timestamp": "28/06/24 3:00",
    "demand": 36344.37635,
    "solar_generated": 0,
    "wind_generated": 56.56017277,
    "marketplace_to_demand": 32.34689531,
    "grid_to_demand": 35897.95985,
    "govt_battery_charge": 41.23233864
  },
  {
    "timestamp": "28/06/24 4:00",
    "demand": 33078.55488,
    "solar_generated": 0,
    "wind_generated": 60.90482454,
    "marketplace_to_demand": 65.70867229,
    "grid_to_demand": 33971.84945,
    "govt_battery_charge": 68.922763
  },
  {
    "timestamp": "28/06/24 5:00",
    "demand": 31924.43439,
    "solar_generated": 0,
    "wind_generated": 72.49640375,
    "marketplace_to_demand": 103.9874026,
    "grid_to_demand": 31257.33586,
    "govt_battery_charge": 35.48606615
  },
  {
    "timestamp": "28/06/24 6:00",
    "demand": 33180.88039,
    "solar_generated": 21.3940031,
    "wind_generated": 80.30755195,
    "marketplace_to_demand": 26.5648435,
    "grid_to_demand": 35590.57991,
    "govt_battery_charge": 19.22309137
  },
  {
    "timestamp": "28/06/24 7:00",
    "demand": 31359.04477,
    "solar_generated": 19.22657687,
    "wind_generated": 76.30657739,
    "marketplace_to_demand": 71.97239541,
    "grid_to_demand": 35710.45941,
    "govt_battery_charge": 73.4944331
  },
  {
    "timestamp": "28/06/24 8:00",
    "demand": 33403.18071,
    "solar_generated": 14.74826822,
    "wind_generated": 70.62167524,
    "marketplace_to_demand": 161.4761497,
    "grid_to_demand": 31149.32259,
    "govt_battery_charge": 60.05637002
  },
  {
    "timestamp": "28/06/24 9:00",
    "demand": 31444.08569,
    "solar_generated": 23.28053825,
    "wind_generated": 74.9957812,
    "marketplace_to_demand": 169.815911,
    "grid_to_demand": 30362.44196,
    "govt_battery_charge": 78.77707129
  },
  {
    "timestamp": "28/06/24 10:00",
    "demand": 32078.94386,
    "solar_generated": 11.46956374,
    "wind_generated": 73.30978885,
    "marketplace_to_demand": 166.6324133,
    "grid_to_demand": 32307.82428,
    "govt_battery_charge": 5.378375519
  },
  {
    "timestamp": "28/06/24 11:00",
    "demand": 34362.40685,
    "solar_generated": 17.04543538,
    "wind_generated": 76.50034159,
    "marketplace_to_demand": 177.677213,
    "grid_to_demand": 33896.97041,
    "govt_battery_charge": 42.94012348
  },
  {
    "timestamp": "28/06/24 12:00",
    "demand": 35719.48506,
    "solar_generated": 16.20726294,
    "wind_generated": 54.07036742,
    "marketplace_to_demand": 116.1455502,
    "grid_to_demand": 34626.4486,
    "govt_battery_charge": 56.26061563
  },
  {
    "timestamp": "28/06/24 13:00",
    "demand": 32856.63128,
    "solar_generated": 18.30323176,
    "wind_generated": 63.68109091,
    "marketplace_to_demand": 139.3852005,
    "grid_to_demand": 30441.9249,
    "govt_battery_charge": 22.6649893
  },
  {
    "timestamp": "28/06/24 14:00",
    "demand": 31644.67413,
    "solar_generated": 22.45951321,
    "wind_generated": 72.51556276,
    "marketplace_to_demand": 119.7496819,
    "grid_to_demand": 35241.03374,
    "govt_battery_charge": 59.38307671
  },
  {
    "timestamp": "28/06/24 15:00",
    "demand": 34668.57752,
    "solar_generated": 23.25669471,
    "wind_generated": 71.66260943,
    "marketplace_to_demand": 67.52230838,
    "grid_to_demand": 32631.21001,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "28/06/24 16:00",
    "demand": 36120.90337,
    "solar_generated": 18.81803715,
    "wind_generated": 54.79443079,
    "marketplace_to_demand": 140.4384565,
    "grid_to_demand": 31940.52143,
    "govt_battery_charge": 35.67716698
  },
  {
    "timestamp": "28/06/24 17:00",
    "demand": 30317.90428,
    "solar_generated": 12.47218299,
    "wind_generated": 72.33228577,
    "marketplace_to_demand": 58.23351347,
    "grid_to_demand": 35218.09594,
    "govt_battery_charge": 74.88377706
  },
  {
    "timestamp": "28/06/24 18:00",
    "demand": 35958.91542,
    "solar_generated": 0,
    "wind_generated": 76.70197176,
    "marketplace_to_demand": 61.00938303,
    "grid_to_demand": 29995.16302,
    "govt_battery_charge": 43.61913418
  },
  {
    "timestamp": "28/06/24 19:00",
    "demand": 34243.92709,
    "solar_generated": 0,
    "wind_generated": 58.86450856,
    "marketplace_to_demand": 111.6255897,
    "grid_to_demand": 33771.59101,
    "govt_battery_charge": 49.12529203
  },
  {
    "timestamp": "28/06/24 20:00",
    "demand": 35804.05776,
    "solar_generated": 0,
    "wind_generated": 78.40127107,
    "marketplace_to_demand": 132.0360596,
    "grid_to_demand": 33423.42965,
    "govt_battery_charge": 31.71751971
  },
  {
    "timestamp": "28/06/24 21:00",
    "demand": 32318.42457,
    "solar_generated": 0,
    "wind_generated": 62.18508425,
    "marketplace_to_demand": 23.66822864,
    "grid_to_demand": 31565.80606,
    "govt_battery_charge": 27.51152047
  },
  {
    "timestamp": "28/06/24 22:00",
    "demand": 30561.28671,
    "solar_generated": 0,
    "wind_generated": 58.2561831,
    "marketplace_to_demand": 59.13799326,
    "grid_to_demand": 31308.2088,
    "govt_battery_charge": 91.56749413
  },
  {
    "timestamp": "28/06/24 23:00",
    "demand": 32868.35978,
    "solar_generated": 0,
    "wind_generated": 57.44976673,
    "marketplace_to_demand": 42.77560991,
    "grid_to_demand": 30877.42127,
    "govt_battery_charge": 69.71566409
  },
  {
    "timestamp": "29/06/24 0:00",
    "demand": 31327.51541,
    "solar_generated": 0,
    "wind_generated": 67.27183742,
    "marketplace_to_demand": 79.20385933,
    "grid_to_demand": 32632.07411,
    "govt_battery_charge": 44.27416441
  },
  {
    "timestamp": "29/06/24 1:00",
    "demand": 33789.26812,
    "solar_generated": 0,
    "wind_generated": 63.44643408,
    "marketplace_to_demand": 152.9167817,
    "grid_to_demand": 36101.64701,
    "govt_battery_charge": 49.45483144
  },
  {
    "timestamp": "29/06/24 2:00",
    "demand": 33916.80618,
    "solar_generated": 0,
    "wind_generated": 72.00354463,
    "marketplace_to_demand": 58.1573488,
    "grid_to_demand": 31614.00974,
    "govt_battery_charge": 79.8161314
  },
  {
    "timestamp": "29/06/24 3:00",
    "demand": 33783.07828,
    "solar_generated": 0,
    "wind_generated": 74.8576239,
    "marketplace_to_demand": 14.01341896,
    "grid_to_demand": 33579.26621,
    "govt_battery_charge": 53.70163211
  },
  {
    "timestamp": "29/06/24 4:00",
    "demand": 32287.47307,
    "solar_generated": 0,
    "wind_generated": 63.44451781,
    "marketplace_to_demand": 104.3737798,
    "grid_to_demand": 33907.34041,
    "govt_battery_charge": 92.12307339
  },
  {
    "timestamp": "29/06/24 5:00",
    "demand": 35302.91538,
    "solar_generated": 0,
    "wind_generated": 58.43722516,
    "marketplace_to_demand": 198.8021094,
    "grid_to_demand": 35605.3491,
    "govt_battery_charge": 62.444301
  },
  {
    "timestamp": "29/06/24 6:00",
    "demand": 32461.73261,
    "solar_generated": 9.92790675,
    "wind_generated": 55.0874471,
    "marketplace_to_demand": 9.682411636,
    "grid_to_demand": 35168.13275,
    "govt_battery_charge": 35.58876883
  },
  {
    "timestamp": "29/06/24 7:00",
    "demand": 32314.29758,
    "solar_generated": 19.32625049,
    "wind_generated": 71.51932811,
    "marketplace_to_demand": 172.0013481,
    "grid_to_demand": 32910.83608,
    "govt_battery_charge": 88.22720418
  },
  {
    "timestamp": "29/06/24 8:00",
    "demand": 30991.42929,
    "solar_generated": 14.16862275,
    "wind_generated": 68.77640496,
    "marketplace_to_demand": 21.39039036,
    "grid_to_demand": 30758.1266,
    "govt_battery_charge": 64.75882046
  },
  {
    "timestamp": "29/06/24 9:00",
    "demand": 35191.02519,
    "solar_generated": 22.9186002,
    "wind_generated": 76.68410229,
    "marketplace_to_demand": 34.64172797,
    "grid_to_demand": 31760.19762,
    "govt_battery_charge": 84.19423433
  },
  {
    "timestamp": "29/06/24 10:00",
    "demand": 31113.872,
    "solar_generated": 19.10110724,
    "wind_generated": 70.457158,
    "marketplace_to_demand": 136.3958726,
    "grid_to_demand": 35177.75976,
    "govt_battery_charge": 76.69683249
  },
  {
    "timestamp": "29/06/24 11:00",
    "demand": 29969.90472,
    "solar_generated": 22.77922673,
    "wind_generated": 76.88104526,
    "marketplace_to_demand": 125.1194354,
    "grid_to_demand": 32479.55236,
    "govt_battery_charge": 72.18554911
  },
  {
    "timestamp": "29/06/24 12:00",
    "demand": 35420.04243,
    "solar_generated": 14.27127867,
    "wind_generated": 67.82763995,
    "marketplace_to_demand": 25.12845164,
    "grid_to_demand": 30340.83377,
    "govt_battery_charge": 81.43972119
  },
  {
    "timestamp": "29/06/24 13:00",
    "demand": 35062.28953,
    "solar_generated": 9.060281356,
    "wind_generated": 75.95763226,
    "marketplace_to_demand": 97.61347305,
    "grid_to_demand": 35094.60539,
    "govt_battery_charge": 13.71497478
  },
  {
    "timestamp": "29/06/24 14:00",
    "demand": 31108.11883,
    "solar_generated": 11.11538555,
    "wind_generated": 73.38389207,
    "marketplace_to_demand": 8.571098597,
    "grid_to_demand": 35573.36334,
    "govt_battery_charge": 33.76626173
  },
  {
    "timestamp": "29/06/24 15:00",
    "demand": 32015.85061,
    "solar_generated": 12.04497994,
    "wind_generated": 75.6880608,
    "marketplace_to_demand": 108.4871664,
    "grid_to_demand": 30526.69244,
    "govt_battery_charge": 84.72847087
  },
  {
    "timestamp": "29/06/24 16:00",
    "demand": 34250.22217,
    "solar_generated": 12.23668214,
    "wind_generated": 62.60764449,
    "marketplace_to_demand": 64.94365479,
    "grid_to_demand": 33370.4109,
    "govt_battery_charge": 56.95716078
  },
  {
    "timestamp": "29/06/24 17:00",
    "demand": 30399.18028,
    "solar_generated": 10.04868308,
    "wind_generated": 55.42751556,
    "marketplace_to_demand": 105.2194026,
    "grid_to_demand": 32435.49627,
    "govt_battery_charge": 96.86907064
  },
  {
    "timestamp": "29/06/24 18:00",
    "demand": 32654.79113,
    "solar_generated": 0,
    "wind_generated": 60.49142922,
    "marketplace_to_demand": 62.68026019,
    "grid_to_demand": 32256.90979,
    "govt_battery_charge": 51.13449613
  },
  {
    "timestamp": "29/06/24 19:00",
    "demand": 32124.24972,
    "solar_generated": 0,
    "wind_generated": 68.19437359,
    "marketplace_to_demand": 73.36578448,
    "grid_to_demand": 31235.42031,
    "govt_battery_charge": 93.1295199
  },
  {
    "timestamp": "29/06/24 20:00",
    "demand": 32503.94482,
    "solar_generated": 0,
    "wind_generated": 72.6854495,
    "marketplace_to_demand": 146.230092,
    "grid_to_demand": 30514.6263,
    "govt_battery_charge": 27.59013909
  },
  {
    "timestamp": "29/06/24 21:00",
    "demand": 31942.5894,
    "solar_generated": 0,
    "wind_generated": 74.56415426,
    "marketplace_to_demand": 159.8882611,
    "grid_to_demand": 34526.7965,
    "govt_battery_charge": 65.62735769
  },
  {
    "timestamp": "29/06/24 22:00",
    "demand": 30847.66898,
    "solar_generated": 0,
    "wind_generated": 54.08907802,
    "marketplace_to_demand": 150.5084469,
    "grid_to_demand": 31059.31069,
    "govt_battery_charge": 49.73366646
  },
  {
    "timestamp": "29/06/24 23:00",
    "demand": 31581.84553,
    "solar_generated": 0,
    "wind_generated": 79.94883592,
    "marketplace_to_demand": 112.6343521,
    "grid_to_demand": 36393.51211,
    "govt_battery_charge": 91.39293969
  },
  {
    "timestamp": "30/06/24 0:00",
    "demand": 34907.83492,
    "solar_generated": 0,
    "wind_generated": 64.75757199,
    "marketplace_to_demand": 95.59745607,
    "grid_to_demand": 32006.53123,
    "govt_battery_charge": 3.439651284
  },
  {
    "timestamp": "30/06/24 1:00",
    "demand": 31429.28627,
    "solar_generated": 0,
    "wind_generated": 55.60336598,
    "marketplace_to_demand": 68.41974765,
    "grid_to_demand": 31233.85186,
    "govt_battery_charge": 59.16742629
  },
  {
    "timestamp": "30/06/24 2:00",
    "demand": 32259.13571,
    "solar_generated": 0,
    "wind_generated": 72.85878089,
    "marketplace_to_demand": 130.9536505,
    "grid_to_demand": 33338.41516,
    "govt_battery_charge": 3.572431815
  },
  {
    "timestamp": "30/06/24 3:00",
    "demand": 33004.7351,
    "solar_generated": 0,
    "wind_generated": 78.33176544,
    "marketplace_to_demand": 179.9355526,
    "grid_to_demand": 36334.74874,
    "govt_battery_charge": 50.7861566
  },
  {
    "timestamp": "30/06/24 4:00",
    "demand": 34139.98216,
    "solar_generated": 0,
    "wind_generated": 68.17051136,
    "marketplace_to_demand": 68.82823101,
    "grid_to_demand": 32126.82746,
    "govt_battery_charge": 21.48144739
  },
  {
    "timestamp": "30/06/24 5:00",
    "demand": 33658.73844,
    "solar_generated": 0,
    "wind_generated": 61.31940758,
    "marketplace_to_demand": 55.5553415,
    "grid_to_demand": 35423.3673,
    "govt_battery_charge": 72.20728874
  },
  {
    "timestamp": "30/06/24 6:00",
    "demand": 31814.56405,
    "solar_generated": 23.76371854,
    "wind_generated": 70.45628855,
    "marketplace_to_demand": 196.0928422,
    "grid_to_demand": 32112.98597,
    "govt_battery_charge": 2.316969098
  },
  {
    "timestamp": "30/06/24 7:00",
    "demand": 30903.28991,
    "solar_generated": 21.46212529,
    "wind_generated": 59.19324169,
    "marketplace_to_demand": 90.0133258,
    "grid_to_demand": 33719.1322,
    "govt_battery_charge": 40.12002249
  },
  {
    "timestamp": "30/06/24 8:00",
    "demand": 36019.21735,
    "solar_generated": 12.69844647,
    "wind_generated": 76.3406238,
    "marketplace_to_demand": 5.670901317,
    "grid_to_demand": 36529.59112,
    "govt_battery_charge": 52.25153711
  },
  {
    "timestamp": "30/06/24 9:00",
    "demand": 32349.79824,
    "solar_generated": 20.09663273,
    "wind_generated": 73.06411869,
    "marketplace_to_demand": 14.18575059,
    "grid_to_demand": 30364.88655,
    "govt_battery_charge": 50.95250004
  },
  {
    "timestamp": "30/06/24 10:00",
    "demand": 33124.90191,
    "solar_generated": 11.18810558,
    "wind_generated": 61.75244396,
    "marketplace_to_demand": 8.980140141,
    "grid_to_demand": 34372.06058,
    "govt_battery_charge": 30.21099654
  },
  {
    "timestamp": "30/06/24 11:00",
    "demand": 30986.31443,
    "solar_generated": 9.135970725,
    "wind_generated": 78.46581316,
    "marketplace_to_demand": 137.1549401,
    "grid_to_demand": 33654.58996,
    "govt_battery_charge": 86.97035918
  },
  {
    "timestamp": "30/06/24 12:00",
    "demand": 30320.79791,
    "solar_generated": 15.95440792,
    "wind_generated": 72.15172676,
    "marketplace_to_demand": 111.8688571,
    "grid_to_demand": 34728.50488,
    "govt_battery_charge": 78.13279226
  },
  {
    "timestamp": "30/06/24 13:00",
    "demand": 32269.57169,
    "solar_generated": 20.92830062,
    "wind_generated": 65.69617336,
    "marketplace_to_demand": 51.25623174,
    "grid_to_demand": 34111.26654,
    "govt_battery_charge": 28.65079689
  },
  {
    "timestamp": "30/06/24 14:00",
    "demand": 36139.45894,
    "solar_generated": 8.932869779,
    "wind_generated": 59.54845764,
    "marketplace_to_demand": 97.42462868,
    "grid_to_demand": 33309.65091,
    "govt_battery_charge": 43.35661322
  },
  {
    "timestamp": "30/06/24 15:00",
    "demand": 35995.29662,
    "solar_generated": 18.37211699,
    "wind_generated": 67.00754644,
    "marketplace_to_demand": 125.2374098,
    "grid_to_demand": 35035.50373,
    "govt_battery_charge": 83.39534821
  },
  {
    "timestamp": "30/06/24 16:00",
    "demand": 35509.12656,
    "solar_generated": 13.31877546,
    "wind_generated": 72.33121605,
    "marketplace_to_demand": 149.0207364,
    "grid_to_demand": 35348.8868,
    "govt_battery_charge": 75.77032758
  },
  {
    "timestamp": "30/06/24 17:00",
    "demand": 36571.79745,
    "solar_generated": 19.80814247,
    "wind_generated": 64.56795347,
    "marketplace_to_demand": 112.2327597,
    "grid_to_demand": 35121.27364,
    "govt_battery_charge": 69.88798802
  },
  {
    "timestamp": "30/06/24 18:00",
    "demand": 35293.05218,
    "solar_generated": 0,
    "wind_generated": 72.95272046,
    "marketplace_to_demand": 118.7469336,
    "grid_to_demand": 34775.45605,
    "govt_battery_charge": 51.73623054
  },
  {
    "timestamp": "30/06/24 19:00",
    "demand": 32193.86241,
    "solar_generated": 0,
    "wind_generated": 58.50523779,
    "marketplace_to_demand": 92.06976141,
    "grid_to_demand": 35788.2037,
    "govt_battery_charge": 84.59614361
  },
  {
    "timestamp": "30/06/24 20:00",
    "demand": 32398.38351,
    "solar_generated": 0,
    "wind_generated": 54.55304266,
    "marketplace_to_demand": 98.35593948,
    "grid_to_demand": 30553.08963,
    "govt_battery_charge": 16.00889533
  },
  {
    "timestamp": "30/06/24 21:00",
    "demand": 36320.12468,
    "solar_generated": 0,
    "wind_generated": 67.07831746,
    "marketplace_to_demand": 21.76816432,
    "grid_to_demand": 31506.0058,
    "govt_battery_charge": 97.96160103
  },
  {
    "timestamp": "30/06/24 22:00",
    "demand": 31052.27087,
    "solar_generated": 0,
    "wind_generated": 72.05985334,
    "marketplace_to_demand": 193.6496557,
    "grid_to_demand": 31746.35557,
    "govt_battery_charge": 28.9461205
  },
  {
    "timestamp": "30/06/24 23:00",
    "demand": 33134.13964,
    "solar_generated": 0,
    "wind_generated": 63.6224256,
    "marketplace_to_demand": 119.288844,
    "grid_to_demand": 30034.15783,
    "govt_battery_charge": 25.59266055
  },
  {
    "timestamp": "01/07/24 0:00",
    "demand": 33335.30957,
    "solar_generated": 0,
    "wind_generated": 64.80266732,
    "marketplace_to_demand": 5.512791513,
    "grid_to_demand": 36527.43251,
    "govt_battery_charge": 52.38864456
  },
  {
    "timestamp": "01/07/24 1:00",
    "demand": 35910.78299,
    "solar_generated": 0,
    "wind_generated": 63.47723492,
    "marketplace_to_demand": 124.8568249,
    "grid_to_demand": 36523.51505,
    "govt_battery_charge": 9.307114301
  },
  {
    "timestamp": "01/07/24 2:00",
    "demand": 31419.65238,
    "solar_generated": 0,
    "wind_generated": 72.61925075,
    "marketplace_to_demand": 120.1263173,
    "grid_to_demand": 36423.65054,
    "govt_battery_charge": 74.37204037
  },
  {
    "timestamp": "01/07/24 3:00",
    "demand": 36246.73777,
    "solar_generated": 0,
    "wind_generated": 78.3140228,
    "marketplace_to_demand": 40.79971356,
    "grid_to_demand": 34914.67584,
    "govt_battery_charge": 85.09747952
  },
  {
    "timestamp": "01/07/24 4:00",
    "demand": 35044.88406,
    "solar_generated": 0,
    "wind_generated": 56.85950064,
    "marketplace_to_demand": 155.6309652,
    "grid_to_demand": 34455.30787,
    "govt_battery_charge": 2.109390253
  },
  {
    "timestamp": "01/07/24 5:00",
    "demand": 31756.88484,
    "solar_generated": 0,
    "wind_generated": 58.40141047,
    "marketplace_to_demand": 85.56979492,
    "grid_to_demand": 33319.90011,
    "govt_battery_charge": 53.93669004
  },
  {
    "timestamp": "01/07/24 6:00",
    "demand": 30764.01578,
    "solar_generated": 17.05394724,
    "wind_generated": 65.59005725,
    "marketplace_to_demand": 192.2393445,
    "grid_to_demand": 30702.66767,
    "govt_battery_charge": 96.66837589
  },
  {
    "timestamp": "01/07/24 7:00",
    "demand": 30101.06838,
    "solar_generated": 23.65299464,
    "wind_generated": 70.6055471,
    "marketplace_to_demand": 151.6094531,
    "grid_to_demand": 31654.89772,
    "govt_battery_charge": 91.81605012
  },
  {
    "timestamp": "01/07/24 8:00",
    "demand": 36438.42304,
    "solar_generated": 13.62440821,
    "wind_generated": 55.94612166,
    "marketplace_to_demand": 162.9597434,
    "grid_to_demand": 34663.3444,
    "govt_battery_charge": 98.72736339
  },
  {
    "timestamp": "01/07/24 9:00",
    "demand": 32280.59435,
    "solar_generated": 15.57612381,
    "wind_generated": 65.66968755,
    "marketplace_to_demand": 10.8794767,
    "grid_to_demand": 31270.641,
    "govt_battery_charge": 61.96193138
  },
  {
    "timestamp": "01/07/24 10:00",
    "demand": 33280.29644,
    "solar_generated": 8.276769984,
    "wind_generated": 58.68186789,
    "marketplace_to_demand": 81.61821652,
    "grid_to_demand": 30429.14858,
    "govt_battery_charge": 83.01214022
  },
  {
    "timestamp": "01/07/24 11:00",
    "demand": 36410.19024,
    "solar_generated": 9.042259303,
    "wind_generated": 72.99782805,
    "marketplace_to_demand": 130.1121219,
    "grid_to_demand": 32234.97343,
    "govt_battery_charge": 50.58322683
  },
  {
    "timestamp": "01/07/24 12:00",
    "demand": 33619.06321,
    "solar_generated": 10.23156405,
    "wind_generated": 63.86473939,
    "marketplace_to_demand": 26.5042905,
    "grid_to_demand": 32661.90495,
    "govt_battery_charge": 20.4820047
  },
  {
    "timestamp": "01/07/24 13:00",
    "demand": 35853.11986,
    "solar_generated": 16.36323756,
    "wind_generated": 71.08739737,
    "marketplace_to_demand": 143.2475073,
    "grid_to_demand": 33577.75228,
    "govt_battery_charge": 8.240448123
  },
  {
    "timestamp": "01/07/24 14:00",
    "demand": 34135.79177,
    "solar_generated": 18.04746071,
    "wind_generated": 71.05565669,
    "marketplace_to_demand": 25.49143199,
    "grid_to_demand": 32089.74067,
    "govt_battery_charge": 47.98065711
  },
  {
    "timestamp": "01/07/24 15:00",
    "demand": 31381.50061,
    "solar_generated": 8.433359671,
    "wind_generated": 55.2747264,
    "marketplace_to_demand": 138.7219348,
    "grid_to_demand": 30228.99351,
    "govt_battery_charge": 34.06218105
  },
  {
    "timestamp": "01/07/24 16:00",
    "demand": 33278.00278,
    "solar_generated": 14.62388986,
    "wind_generated": 59.22893173,
    "marketplace_to_demand": 112.0745044,
    "grid_to_demand": 30244.35616,
    "govt_battery_charge": 83.95248977
  },
  {
    "timestamp": "01/07/24 17:00",
    "demand": 36344.80152,
    "solar_generated": 8.290776619,
    "wind_generated": 73.25924397,
    "marketplace_to_demand": 7.215662572,
    "grid_to_demand": 33096.97743,
    "govt_battery_charge": 17.85183256
  },
  {
    "timestamp": "01/07/24 18:00",
    "demand": 32003.49209,
    "solar_generated": 0,
    "wind_generated": 54.61580548,
    "marketplace_to_demand": 20.07473851,
    "grid_to_demand": 34685.06758,
    "govt_battery_charge": 1.099727059
  },
  {
    "timestamp": "01/07/24 19:00",
    "demand": 35042.57944,
    "solar_generated": 0,
    "wind_generated": 59.53609564,
    "marketplace_to_demand": 55.45200005,
    "grid_to_demand": 32361.08067,
    "govt_battery_charge": 85.20493749
  },
  {
    "timestamp": "01/07/24 20:00",
    "demand": 36187.03998,
    "solar_generated": 0,
    "wind_generated": 79.75305553,
    "marketplace_to_demand": 87.7271088,
    "grid_to_demand": 34673.83824,
    "govt_battery_charge": 27.88899991
  },
  {
    "timestamp": "01/07/24 21:00",
    "demand": 31863.96588,
    "solar_generated": 0,
    "wind_generated": 75.78576412,
    "marketplace_to_demand": 148.7934789,
    "grid_to_demand": 31462.20501,
    "govt_battery_charge": 86.36041136
  },
  {
    "timestamp": "01/07/24 22:00",
    "demand": 32514.89097,
    "solar_generated": 0,
    "wind_generated": 77.51128029,
    "marketplace_to_demand": 159.1984409,
    "grid_to_demand": 30363.17981,
    "govt_battery_charge": 87.21264228
  },
  {
    "timestamp": "01/07/24 23:00",
    "demand": 30585.78833,
    "solar_generated": 0,
    "wind_generated": 78.55474532,
    "marketplace_to_demand": 170.744941,
    "grid_to_demand": 33292.82099,
    "govt_battery_charge": 34.44497811
  },
  {
    "timestamp": "02/07/24 0:00",
    "demand": 31373.02069,
    "solar_generated": 0,
    "wind_generated": 70.17410187,
    "marketplace_to_demand": 80.12879684,
    "grid_to_demand": 30053.89298,
    "govt_battery_charge": 11.41154893
  },
  {
    "timestamp": "02/07/24 1:00",
    "demand": 34049.54901,
    "solar_generated": 0,
    "wind_generated": 78.59573256,
    "marketplace_to_demand": 144.0940309,
    "grid_to_demand": 33295.77494,
    "govt_battery_charge": 16.59961715
  },
  {
    "timestamp": "02/07/24 2:00",
    "demand": 35287.74663,
    "solar_generated": 0,
    "wind_generated": 70.74311339,
    "marketplace_to_demand": 58.01665922,
    "grid_to_demand": 31234.8488,
    "govt_battery_charge": 11.98596615
  },
  {
    "timestamp": "02/07/24 3:00",
    "demand": 36442.45908,
    "solar_generated": 0,
    "wind_generated": 59.84496228,
    "marketplace_to_demand": 194.5286698,
    "grid_to_demand": 33287.71707,
    "govt_battery_charge": 58.59840624
  },
  {
    "timestamp": "02/07/24 4:00",
    "demand": 36049.16664,
    "solar_generated": 0,
    "wind_generated": 75.12804577,
    "marketplace_to_demand": 13.5649744,
    "grid_to_demand": 32051.22502,
    "govt_battery_charge": 31.43023299
  },
  {
    "timestamp": "02/07/24 5:00",
    "demand": 34193.64739,
    "solar_generated": 0,
    "wind_generated": 67.48595067,
    "marketplace_to_demand": 93.56386432,
    "grid_to_demand": 32341.49425,
    "govt_battery_charge": 72.81391435
  },
  {
    "timestamp": "02/07/24 6:00",
    "demand": 31790.64092,
    "solar_generated": 16.98912759,
    "wind_generated": 62.66916485,
    "marketplace_to_demand": 10.05469817,
    "grid_to_demand": 34694.2418,
    "govt_battery_charge": 98.74955744
  },
  {
    "timestamp": "02/07/24 7:00",
    "demand": 31482.38512,
    "solar_generated": 15.33636674,
    "wind_generated": 74.38532251,
    "marketplace_to_demand": 72.45208719,
    "grid_to_demand": 29926.24161,
    "govt_battery_charge": 69.00736868
  },
  {
    "timestamp": "02/07/24 8:00",
    "demand": 35427.92205,
    "solar_generated": 20.29083592,
    "wind_generated": 60.52245506,
    "marketplace_to_demand": 18.09681246,
    "grid_to_demand": 35518.08651,
    "govt_battery_charge": 22.06683265
  },
  {
    "timestamp": "02/07/24 9:00",
    "demand": 32454.18365,
    "solar_generated": 12.78498846,
    "wind_generated": 55.28942798,
    "marketplace_to_demand": 20.34047426,
    "grid_to_demand": 33771.2151,
    "govt_battery_charge": 15.8725526
  },
  {
    "timestamp": "02/07/24 10:00",
    "demand": 30630.1605,
    "solar_generated": 11.92118768,
    "wind_generated": 65.22355156,
    "marketplace_to_demand": 174.5325881,
    "grid_to_demand": 30816.38797,
    "govt_battery_charge": 92.90433926
  },
  {
    "timestamp": "02/07/24 11:00",
    "demand": 35676.19588,
    "solar_generated": 16.73898443,
    "wind_generated": 70.19781241,
    "marketplace_to_demand": 40.51322881,
    "grid_to_demand": 34673.6891,
    "govt_battery_charge": 50.60666871
  },
  {
    "timestamp": "02/07/24 12:00",
    "demand": 35788.60111,
    "solar_generated": 14.48079961,
    "wind_generated": 69.55929454,
    "marketplace_to_demand": 107.5451288,
    "grid_to_demand": 35984.00082,
    "govt_battery_charge": 62.25539294
  },
  {
    "timestamp": "02/07/24 13:00",
    "demand": 30275.02577,
    "solar_generated": 14.31795279,
    "wind_generated": 56.59308151,
    "marketplace_to_demand": 163.2196766,
    "grid_to_demand": 33052.69603,
    "govt_battery_charge": 88.73424454
  },
  {
    "timestamp": "02/07/24 14:00",
    "demand": 30121.71046,
    "solar_generated": 9.102292125,
    "wind_generated": 64.79257717,
    "marketplace_to_demand": 164.24183,
    "grid_to_demand": 29973.37139,
    "govt_battery_charge": 56.07193688
  },
  {
    "timestamp": "02/07/24 15:00",
    "demand": 33580.09207,
    "solar_generated": 14.47893828,
    "wind_generated": 68.87363654,
    "marketplace_to_demand": 50.29638603,
    "grid_to_demand": 33043.93193,
    "govt_battery_charge": 1.651258245
  },
  {
    "timestamp": "02/07/24 16:00",
    "demand": 32280.34278,
    "solar_generated": 8.605089889,
    "wind_generated": 76.64520103,
    "marketplace_to_demand": 121.4784537,
    "grid_to_demand": 31161.90035,
    "govt_battery_charge": 4.031295161
  },
  {
    "timestamp": "02/07/24 17:00",
    "demand": 34927.38588,
    "solar_generated": 13.7705978,
    "wind_generated": 53.92468614,
    "marketplace_to_demand": 64.16799883,
    "grid_to_demand": 35412.23322,
    "govt_battery_charge": 92.16921414
  },
  {
    "timestamp": "02/07/24 18:00",
    "demand": 35176.68208,
    "solar_generated": 0,
    "wind_generated": 59.63265607,
    "marketplace_to_demand": 51.03707582,
    "grid_to_demand": 34681.22657,
    "govt_battery_charge": 54.97017411
  },
  {
    "timestamp": "02/07/24 19:00",
    "demand": 34727.55506,
    "solar_generated": 0,
    "wind_generated": 69.60532711,
    "marketplace_to_demand": 58.34162947,
    "grid_to_demand": 30573.05394,
    "govt_battery_charge": 16.45740839
  },
  {
    "timestamp": "02/07/24 20:00",
    "demand": 33811.75944,
    "solar_generated": 0,
    "wind_generated": 57.9171051,
    "marketplace_to_demand": 0.852450424,
    "grid_to_demand": 34389.53122,
    "govt_battery_charge": 98.74510283
  },
  {
    "timestamp": "02/07/24 21:00",
    "demand": 35460.64706,
    "solar_generated": 0,
    "wind_generated": 53.732147,
    "marketplace_to_demand": 188.3907396,
    "grid_to_demand": 36251.6335,
    "govt_battery_charge": 63.05242412
  },
  {
    "timestamp": "02/07/24 22:00",
    "demand": 36443.90515,
    "solar_generated": 0,
    "wind_generated": 70.36405722,
    "marketplace_to_demand": 6.399742193,
    "grid_to_demand": 30385.319,
    "govt_battery_charge": 91.67591477
  },
  {
    "timestamp": "02/07/24 23:00",
    "demand": 30453.16874,
    "solar_generated": 0,
    "wind_generated": 61.37709165,
    "marketplace_to_demand": 177.0308909,
    "grid_to_demand": 30532.28036,
    "govt_battery_charge": 55.4782577
  },
  {
    "timestamp": "03/07/24 0:00",
    "demand": 36493.24599,
    "solar_generated": 0,
    "wind_generated": 76.27902818,
    "marketplace_to_demand": 152.2190975,
    "grid_to_demand": 31526.6421,
    "govt_battery_charge": 37.45686746
  },
  {
    "timestamp": "03/07/24 1:00",
    "demand": 33438.52015,
    "solar_generated": 0,
    "wind_generated": 70.86698197,
    "marketplace_to_demand": 63.72513026,
    "grid_to_demand": 34516.49533,
    "govt_battery_charge": 40.95608607
  },
  {
    "timestamp": "03/07/24 2:00",
    "demand": 31284.80801,
    "solar_generated": 0,
    "wind_generated": 68.98544759,
    "marketplace_to_demand": 93.62043639,
    "grid_to_demand": 32032.27433,
    "govt_battery_charge": 33.73599727
  },
  {
    "timestamp": "03/07/24 3:00",
    "demand": 32694.76282,
    "solar_generated": 0,
    "wind_generated": 70.07273502,
    "marketplace_to_demand": 187.5990467,
    "grid_to_demand": 34255.11274,
    "govt_battery_charge": 74.73530417
  },
  {
    "timestamp": "03/07/24 4:00",
    "demand": 31797.01021,
    "solar_generated": 0,
    "wind_generated": 67.01311972,
    "marketplace_to_demand": 172.2434859,
    "grid_to_demand": 35113.83969,
    "govt_battery_charge": 47.51677636
  },
  {
    "timestamp": "03/07/24 5:00",
    "demand": 31034.94102,
    "solar_generated": 0,
    "wind_generated": 63.52223775,
    "marketplace_to_demand": 57.61073726,
    "grid_to_demand": 35584.81374,
    "govt_battery_charge": 88.11326272
  },
  {
    "timestamp": "03/07/24 6:00",
    "demand": 33542.22594,
    "solar_generated": 6.616852195,
    "wind_generated": 63.2421905,
    "marketplace_to_demand": 118.0139378,
    "grid_to_demand": 31504.647,
    "govt_battery_charge": 89.23351731
  },
  {
    "timestamp": "03/07/24 7:00",
    "demand": 33829.69311,
    "solar_generated": 12.05067713,
    "wind_generated": 74.5799558,
    "marketplace_to_demand": 143.7642114,
    "grid_to_demand": 30082.91404,
    "govt_battery_charge": 33.33444728
  },
  {
    "timestamp": "03/07/24 8:00",
    "demand": 33288.83938,
    "solar_generated": 11.63523407,
    "wind_generated": 63.21905114,
    "marketplace_to_demand": 7.868588529,
    "grid_to_demand": 33386.08904,
    "govt_battery_charge": 16.59611591
  },
  {
    "timestamp": "03/07/24 9:00",
    "demand": 35495.40326,
    "solar_generated": 21.24706563,
    "wind_generated": 59.19845127,
    "marketplace_to_demand": 25.77471107,
    "grid_to_demand": 32672.60582,
    "govt_battery_charge": 62.36054568
  },
  {
    "timestamp": "03/07/24 10:00",
    "demand": 34907.10043,
    "solar_generated": 13.98680019,
    "wind_generated": 70.31464957,
    "marketplace_to_demand": 42.52301558,
    "grid_to_demand": 34480.71563,
    "govt_battery_charge": 39.01891107
  },
  {
    "timestamp": "03/07/24 11:00",
    "demand": 34555.52876,
    "solar_generated": 7.805663383,
    "wind_generated": 74.53737065,
    "marketplace_to_demand": 196.5753754,
    "grid_to_demand": 34933.12781,
    "govt_battery_charge": 23.07025171
  },
  {
    "timestamp": "03/07/24 12:00",
    "demand": 35389.04908,
    "solar_generated": 21.21357186,
    "wind_generated": 75.0439091,
    "marketplace_to_demand": 69.86591608,
    "grid_to_demand": 32697.70068,
    "govt_battery_charge": 66.75637652
  },
  {
    "timestamp": "03/07/24 13:00",
    "demand": 33474.26669,
    "solar_generated": 11.99807301,
    "wind_generated": 77.65236054,
    "marketplace_to_demand": 51.1607142,
    "grid_to_demand": 36545.60031,
    "govt_battery_charge": 89.12931199
  },
  {
    "timestamp": "03/07/24 14:00",
    "demand": 34275.60863,
    "solar_generated": 17.11909572,
    "wind_generated": 56.55515159,
    "marketplace_to_demand": 94.98505228,
    "grid_to_demand": 34189.4342,
    "govt_battery_charge": 40.56850832
  },
  {
    "timestamp": "03/07/24 15:00",
    "demand": 33933.71569,
    "solar_generated": 14.79231542,
    "wind_generated": 56.06411665,
    "marketplace_to_demand": 26.29533412,
    "grid_to_demand": 36010.31899,
    "govt_battery_charge": 67.85877628
  },
  {
    "timestamp": "03/07/24 16:00",
    "demand": 30839.67322,
    "solar_generated": 17.12205855,
    "wind_generated": 67.18024343,
    "marketplace_to_demand": 23.34294922,
    "grid_to_demand": 34447.7916,
    "govt_battery_charge": 82.84483774
  },
  {
    "timestamp": "03/07/24 17:00",
    "demand": 32801.3465,
    "solar_generated": 12.37261558,
    "wind_generated": 55.73493444,
    "marketplace_to_demand": 123.7046775,
    "grid_to_demand": 29936.45483,
    "govt_battery_charge": 42.87743047
  },
  {
    "timestamp": "03/07/24 18:00",
    "demand": 32707.80676,
    "solar_generated": 0,
    "wind_generated": 71.2157999,
    "marketplace_to_demand": 181.3200126,
    "grid_to_demand": 34622.062,
    "govt_battery_charge": 63.05998684
  },
  {
    "timestamp": "03/07/24 19:00",
    "demand": 32155.6076,
    "solar_generated": 0,
    "wind_generated": 68.21102491,
    "marketplace_to_demand": 182.302187,
    "grid_to_demand": 30689.19581,
    "govt_battery_charge": 50.37194175
  },
  {
    "timestamp": "03/07/24 20:00",
    "demand": 34578.52618,
    "solar_generated": 0,
    "wind_generated": 75.39071004,
    "marketplace_to_demand": 24.23327448,
    "grid_to_demand": 35781.2373,
    "govt_battery_charge": 57.26764193
  },
  {
    "timestamp": "03/07/24 21:00",
    "demand": 31333.56508,
    "solar_generated": 0,
    "wind_generated": 64.55248296,
    "marketplace_to_demand": 172.0749369,
    "grid_to_demand": 30002.55517,
    "govt_battery_charge": 53.73053678
  },
  {
    "timestamp": "03/07/24 22:00",
    "demand": 33377.50865,
    "solar_generated": 0,
    "wind_generated": 78.6028727,
    "marketplace_to_demand": 56.89938662,
    "grid_to_demand": 35932.30212,
    "govt_battery_charge": 86.21635513
  },
  {
    "timestamp": "03/07/24 23:00",
    "demand": 31305.15785,
    "solar_generated": 0,
    "wind_generated": 72.64705252,
    "marketplace_to_demand": 26.94920054,
    "grid_to_demand": 30431.67523,
    "govt_battery_charge": 97.90877284
  },
  {
    "timestamp": "04/07/24 0:00",
    "demand": 31513.28838,
    "solar_generated": 0,
    "wind_generated": 62.50135088,
    "marketplace_to_demand": 70.00622413,
    "grid_to_demand": 32617.61004,
    "govt_battery_charge": 26.04674244
  },
  {
    "timestamp": "04/07/24 1:00",
    "demand": 34152.28527,
    "solar_generated": 0,
    "wind_generated": 73.96857078,
    "marketplace_to_demand": 176.0264491,
    "grid_to_demand": 35004.65112,
    "govt_battery_charge": 89.8189123
  },
  {
    "timestamp": "04/07/24 2:00",
    "demand": 36340.23029,
    "solar_generated": 0,
    "wind_generated": 68.22295933,
    "marketplace_to_demand": 178.7864158,
    "grid_to_demand": 35570.83208,
    "govt_battery_charge": 73.69036087
  },
  {
    "timestamp": "04/07/24 3:00",
    "demand": 36327.79769,
    "solar_generated": 0,
    "wind_generated": 69.74399264,
    "marketplace_to_demand": 173.5061973,
    "grid_to_demand": 36460.4823,
    "govt_battery_charge": 35.93732935
  },
  {
    "timestamp": "04/07/24 4:00",
    "demand": 32510.70382,
    "solar_generated": 0,
    "wind_generated": 56.68665263,
    "marketplace_to_demand": 41.83065807,
    "grid_to_demand": 32039.10897,
    "govt_battery_charge": 30.41142697
  },
  {
    "timestamp": "04/07/24 5:00",
    "demand": 31956.64048,
    "solar_generated": 0,
    "wind_generated": 58.45014586,
    "marketplace_to_demand": 29.01385291,
    "grid_to_demand": 31578.07658,
    "govt_battery_charge": 78.76642164
  },
  {
    "timestamp": "04/07/24 6:00",
    "demand": 30127.61696,
    "solar_generated": 18.25212377,
    "wind_generated": 72.98011781,
    "marketplace_to_demand": 81.93788825,
    "grid_to_demand": 33568.35103,
    "govt_battery_charge": 80.70549758
  },
  {
    "timestamp": "04/07/24 7:00",
    "demand": 32890.01536,
    "solar_generated": 23.77079966,
    "wind_generated": 80.25937447,
    "marketplace_to_demand": 50.98572015,
    "grid_to_demand": 32599.45823,
    "govt_battery_charge": 89.30468598
  },
  {
    "timestamp": "04/07/24 8:00",
    "demand": 35860.32505,
    "solar_generated": 13.70766944,
    "wind_generated": 58.66180303,
    "marketplace_to_demand": 167.6064993,
    "grid_to_demand": 30646.35034,
    "govt_battery_charge": 11.86759357
  },
  {
    "timestamp": "04/07/24 9:00",
    "demand": 34856.32002,
    "solar_generated": 14.01194765,
    "wind_generated": 73.26924781,
    "marketplace_to_demand": 134.9658571,
    "grid_to_demand": 35869.59123,
    "govt_battery_charge": 76.27771359
  },
  {
    "timestamp": "04/07/24 10:00",
    "demand": 36518.13238,
    "solar_generated": 16.60749853,
    "wind_generated": 54.45412495,
    "marketplace_to_demand": 104.0619798,
    "grid_to_demand": 31745.06613,
    "govt_battery_charge": 2.583939859
  },
  {
    "timestamp": "04/07/24 11:00",
    "demand": 31808.79553,
    "solar_generated": 17.34460206,
    "wind_generated": 57.54854091,
    "marketplace_to_demand": 89.13387444,
    "grid_to_demand": 33345.08288,
    "govt_battery_charge": 77.12739235
  },
  {
    "timestamp": "04/07/24 12:00",
    "demand": 34516.22963,
    "solar_generated": 10.11146385,
    "wind_generated": 75.39663653,
    "marketplace_to_demand": 49.51315644,
    "grid_to_demand": 31596.13658,
    "govt_battery_charge": 87.76998207
  },
  {
    "timestamp": "04/07/24 13:00",
    "demand": 34343.43277,
    "solar_generated": 12.30860671,
    "wind_generated": 59.66327933,
    "marketplace_to_demand": 74.8225919,
    "grid_to_demand": 36159.41464,
    "govt_battery_charge": 83.97266695
  },
  {
    "timestamp": "04/07/24 14:00",
    "demand": 33835.23616,
    "solar_generated": 14.41680936,
    "wind_generated": 58.03684656,
    "marketplace_to_demand": 130.9837221,
    "grid_to_demand": 33680.39145,
    "govt_battery_charge": 91.00007979
  },
  {
    "timestamp": "04/07/24 15:00",
    "demand": 30950.1633,
    "solar_generated": 6.391121005,
    "wind_generated": 77.84342451,
    "marketplace_to_demand": 4.741305314,
    "grid_to_demand": 32686.71299,
    "govt_battery_charge": 7.26137556
  },
  {
    "timestamp": "04/07/24 16:00",
    "demand": 31130.02038,
    "solar_generated": 13.17832647,
    "wind_generated": 58.76573445,
    "marketplace_to_demand": 171.0064139,
    "grid_to_demand": 35740.12374,
    "govt_battery_charge": 40.91786078
  },
  {
    "timestamp": "04/07/24 17:00",
    "demand": 36351.67006,
    "solar_generated": 23.58686546,
    "wind_generated": 59.74818639,
    "marketplace_to_demand": 144.1993936,
    "grid_to_demand": 36062.08777,
    "govt_battery_charge": 80.53247462
  },
  {
    "timestamp": "04/07/24 18:00",
    "demand": 36381.1792,
    "solar_generated": 0,
    "wind_generated": 59.73370776,
    "marketplace_to_demand": 47.04485194,
    "grid_to_demand": 35088.15676,
    "govt_battery_charge": 7.91576351
  },
  {
    "timestamp": "04/07/24 19:00",
    "demand": 34188.41142,
    "solar_generated": 0,
    "wind_generated": 54.58438503,
    "marketplace_to_demand": 31.55253442,
    "grid_to_demand": 32247.41114,
    "govt_battery_charge": 64.44582355
  },
  {
    "timestamp": "04/07/24 20:00",
    "demand": 33167.58889,
    "solar_generated": 0,
    "wind_generated": 54.22757009,
    "marketplace_to_demand": 90.7529771,
    "grid_to_demand": 34688.27617,
    "govt_battery_charge": 66.26396858
  },
  {
    "timestamp": "04/07/24 21:00",
    "demand": 30084.70396,
    "solar_generated": 0,
    "wind_generated": 76.35967998,
    "marketplace_to_demand": 71.44760567,
    "grid_to_demand": 33879.61672,
    "govt_battery_charge": 55.04962516
  },
  {
    "timestamp": "04/07/24 22:00",
    "demand": 35192.9785,
    "solar_generated": 0,
    "wind_generated": 69.42692313,
    "marketplace_to_demand": 102.8131025,
    "grid_to_demand": 33742.94574,
    "govt_battery_charge": 81.96506474
  },
  {
    "timestamp": "04/07/24 23:00",
    "demand": 30207.72028,
    "solar_generated": 0,
    "wind_generated": 76.04088612,
    "marketplace_to_demand": 132.9787148,
    "grid_to_demand": 30073.20793,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "05/07/24 0:00",
    "demand": 30819.71453,
    "solar_generated": 0,
    "wind_generated": 72.24213038,
    "marketplace_to_demand": 168.3374084,
    "grid_to_demand": 32328.35493,
    "govt_battery_charge": 48.45622791
  },
  {
    "timestamp": "05/07/24 1:00",
    "demand": 33852.59361,
    "solar_generated": 0,
    "wind_generated": 61.37097212,
    "marketplace_to_demand": 132.225255,
    "grid_to_demand": 33906.39316,
    "govt_battery_charge": 25.97606137
  },
  {
    "timestamp": "05/07/24 2:00",
    "demand": 35181.7369,
    "solar_generated": 0,
    "wind_generated": 74.46324335,
    "marketplace_to_demand": 45.74347409,
    "grid_to_demand": 36206.42394,
    "govt_battery_charge": 73.6015466
  },
  {
    "timestamp": "05/07/24 3:00",
    "demand": 34569.74355,
    "solar_generated": 0,
    "wind_generated": 69.29778542,
    "marketplace_to_demand": 194.460759,
    "grid_to_demand": 30501.29776,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "05/07/24 4:00",
    "demand": 30804.05968,
    "solar_generated": 0,
    "wind_generated": 55.17736419,
    "marketplace_to_demand": 109.3367912,
    "grid_to_demand": 35127.96181,
    "govt_battery_charge": 78.2217539
  },
  {
    "timestamp": "05/07/24 5:00",
    "demand": 33470.93464,
    "solar_generated": 0,
    "wind_generated": 66.70556916,
    "marketplace_to_demand": 111.535251,
    "grid_to_demand": 34056.82023,
    "govt_battery_charge": 9.812830721
  },
  {
    "timestamp": "05/07/24 6:00",
    "demand": 34159.43507,
    "solar_generated": 10.34045723,
    "wind_generated": 71.56083992,
    "marketplace_to_demand": 131.2159954,
    "grid_to_demand": 35989.35462,
    "govt_battery_charge": 39.30581783
  },
  {
    "timestamp": "05/07/24 7:00",
    "demand": 31896.63642,
    "solar_generated": 18.3338032,
    "wind_generated": 75.22627936,
    "marketplace_to_demand": 114.6218811,
    "grid_to_demand": 31584.14125,
    "govt_battery_charge": 60.50159893
  },
  {
    "timestamp": "05/07/24 8:00",
    "demand": 30806.06997,
    "solar_generated": 22.90036659,
    "wind_generated": 66.15302866,
    "marketplace_to_demand": 182.2416605,
    "grid_to_demand": 33665.77299,
    "govt_battery_charge": 65.12218744
  },
  {
    "timestamp": "05/07/24 9:00",
    "demand": 32845.0855,
    "solar_generated": 7.442233693,
    "wind_generated": 62.02753035,
    "marketplace_to_demand": 126.4828123,
    "grid_to_demand": 30224.35381,
    "govt_battery_charge": 24.07957876
  },
  {
    "timestamp": "05/07/24 10:00",
    "demand": 33509.00825,
    "solar_generated": 23.81730872,
    "wind_generated": 54.90429365,
    "marketplace_to_demand": 107.3516031,
    "grid_to_demand": 35654.80282,
    "govt_battery_charge": 1.465688331
  },
  {
    "timestamp": "05/07/24 11:00",
    "demand": 30966.12564,
    "solar_generated": 20.21247496,
    "wind_generated": 70.51329492,
    "marketplace_to_demand": 142.1649388,
    "grid_to_demand": 34628.66773,
    "govt_battery_charge": 73.42752255
  },
  {
    "timestamp": "05/07/24 12:00",
    "demand": 30667.58263,
    "solar_generated": 10.48908758,
    "wind_generated": 55.22394207,
    "marketplace_to_demand": 131.2146516,
    "grid_to_demand": 33025.06038,
    "govt_battery_charge": 45.37298225
  },
  {
    "timestamp": "05/07/24 13:00",
    "demand": 33701.25243,
    "solar_generated": 19.87518426,
    "wind_generated": 66.00816634,
    "marketplace_to_demand": 36.69053477,
    "grid_to_demand": 35892.22901,
    "govt_battery_charge": 3.699428381
  },
  {
    "timestamp": "05/07/24 14:00",
    "demand": 34419.56063,
    "solar_generated": 21.23680388,
    "wind_generated": 66.75772508,
    "marketplace_to_demand": 8.042126553,
    "grid_to_demand": 35701.94026,
    "govt_battery_charge": 29.16801661
  },
  {
    "timestamp": "05/07/24 15:00",
    "demand": 31959.16304,
    "solar_generated": 20.45588886,
    "wind_generated": 71.47339895,
    "marketplace_to_demand": 133.2566921,
    "grid_to_demand": 32144.59915,
    "govt_battery_charge": 29.59966654
  },
  {
    "timestamp": "05/07/24 16:00",
    "demand": 30563.63512,
    "solar_generated": 20.36559778,
    "wind_generated": 76.54509719,
    "marketplace_to_demand": 94.7300194,
    "grid_to_demand": 34727.48004,
    "govt_battery_charge": 53.64589239
  },
  {
    "timestamp": "05/07/24 17:00",
    "demand": 32600.8912,
    "solar_generated": 14.83394503,
    "wind_generated": 76.65085216,
    "marketplace_to_demand": 54.130461,
    "grid_to_demand": 33139.2213,
    "govt_battery_charge": 19.29530622
  },
  {
    "timestamp": "05/07/24 18:00",
    "demand": 34810.52566,
    "solar_generated": 0,
    "wind_generated": 72.91866703,
    "marketplace_to_demand": 86.45157318,
    "grid_to_demand": 32743.14271,
    "govt_battery_charge": 3.997073784
  },
  {
    "timestamp": "05/07/24 19:00",
    "demand": 35825.42701,
    "solar_generated": 0,
    "wind_generated": 80.22010172,
    "marketplace_to_demand": 172.5138565,
    "grid_to_demand": 34282.31416,
    "govt_battery_charge": 60.20395547
  },
  {
    "timestamp": "05/07/24 20:00",
    "demand": 34589.30294,
    "solar_generated": 0,
    "wind_generated": 74.1719492,
    "marketplace_to_demand": 106.6569901,
    "grid_to_demand": 31448.51568,
    "govt_battery_charge": 52.47455368
  },
  {
    "timestamp": "05/07/24 21:00",
    "demand": 35483.02661,
    "solar_generated": 0,
    "wind_generated": 72.39832868,
    "marketplace_to_demand": 18.22708328,
    "grid_to_demand": 30747.06986,
    "govt_battery_charge": 75.77836724
  },
  {
    "timestamp": "05/07/24 22:00",
    "demand": 33488.76418,
    "solar_generated": 0,
    "wind_generated": 71.68552848,
    "marketplace_to_demand": 181.8850042,
    "grid_to_demand": 33548.5885,
    "govt_battery_charge": 5.02801048
  },
  {
    "timestamp": "05/07/24 23:00",
    "demand": 36165.09168,
    "solar_generated": 0,
    "wind_generated": 69.80541326,
    "marketplace_to_demand": 152.3843645,
    "grid_to_demand": 35468.24348,
    "govt_battery_charge": 2.988307751
  },
  {
    "timestamp": "06/07/24 0:00",
    "demand": 30462.56205,
    "solar_generated": 0,
    "wind_generated": 58.88433857,
    "marketplace_to_demand": 97.99006134,
    "grid_to_demand": 34143.6151,
    "govt_battery_charge": 19.6479311
  },
  {
    "timestamp": "06/07/24 1:00",
    "demand": 35747.5237,
    "solar_generated": 0,
    "wind_generated": 68.55282535,
    "marketplace_to_demand": 144.0623341,
    "grid_to_demand": 35590.71518,
    "govt_battery_charge": 98.92430984
  },
  {
    "timestamp": "06/07/24 2:00",
    "demand": 36499.98959,
    "solar_generated": 0,
    "wind_generated": 67.7703278,
    "marketplace_to_demand": 137.1821799,
    "grid_to_demand": 32511.95871,
    "govt_battery_charge": 81.02330652
  },
  {
    "timestamp": "06/07/24 3:00",
    "demand": 31355.55116,
    "solar_generated": 0,
    "wind_generated": 72.56108088,
    "marketplace_to_demand": 21.96585106,
    "grid_to_demand": 36202.12787,
    "govt_battery_charge": 19.81334537
  },
  {
    "timestamp": "06/07/24 4:00",
    "demand": 30399.55743,
    "solar_generated": 0,
    "wind_generated": 66.49394702,
    "marketplace_to_demand": 52.26634273,
    "grid_to_demand": 33767.61556,
    "govt_battery_charge": 9.035804829
  },
  {
    "timestamp": "06/07/24 5:00",
    "demand": 32260.15587,
    "solar_generated": 0,
    "wind_generated": 73.04586367,
    "marketplace_to_demand": 84.29947296,
    "grid_to_demand": 35520.19174,
    "govt_battery_charge": 24.46358317
  },
  {
    "timestamp": "06/07/24 6:00",
    "demand": 34664.53923,
    "solar_generated": 8.443003588,
    "wind_generated": 79.50585007,
    "marketplace_to_demand": 77.27259037,
    "grid_to_demand": 36381.12678,
    "govt_battery_charge": 6.181070018
  },
  {
    "timestamp": "06/07/24 7:00",
    "demand": 31452.533,
    "solar_generated": 14.56804947,
    "wind_generated": 77.56437912,
    "marketplace_to_demand": 123.2172025,
    "grid_to_demand": 34879.9908,
    "govt_battery_charge": 28.65570992
  },
  {
    "timestamp": "06/07/24 8:00",
    "demand": 31068.46876,
    "solar_generated": 14.90069514,
    "wind_generated": 78.88188285,
    "marketplace_to_demand": 0.241559368,
    "grid_to_demand": 30267.34979,
    "govt_battery_charge": 21.8835121
  },
  {
    "timestamp": "06/07/24 9:00",
    "demand": 35500.84152,
    "solar_generated": 20.97272711,
    "wind_generated": 63.69729884,
    "marketplace_to_demand": 96.28996822,
    "grid_to_demand": 34441.37863,
    "govt_battery_charge": 21.8558882
  },
  {
    "timestamp": "06/07/24 10:00",
    "demand": 30557.5888,
    "solar_generated": 19.85876292,
    "wind_generated": 75.04806921,
    "marketplace_to_demand": 11.47923541,
    "grid_to_demand": 34528.64205,
    "govt_battery_charge": 88.71653388
  },
  {
    "timestamp": "06/07/24 11:00",
    "demand": 36297.1963,
    "solar_generated": 7.324931348,
    "wind_generated": 62.40903629,
    "marketplace_to_demand": 146.8462716,
    "grid_to_demand": 32566.15541,
    "govt_battery_charge": 70.19323212
  },
  {
    "timestamp": "06/07/24 12:00",
    "demand": 36498.52843,
    "solar_generated": 11.07688246,
    "wind_generated": 77.85858329,
    "marketplace_to_demand": 183.2073838,
    "grid_to_demand": 32409.29372,
    "govt_battery_charge": 90.22676807
  },
  {
    "timestamp": "06/07/24 13:00",
    "demand": 30954.6158,
    "solar_generated": 8.224095421,
    "wind_generated": 53.75215027,
    "marketplace_to_demand": 139.9331859,
    "grid_to_demand": 35880.98201,
    "govt_battery_charge": 13.35765129
  },
  {
    "timestamp": "06/07/24 14:00",
    "demand": 34313.64651,
    "solar_generated": 17.84273498,
    "wind_generated": 75.00786236,
    "marketplace_to_demand": 143.654648,
    "grid_to_demand": 30058.36283,
    "govt_battery_charge": 68.41427774
  },
  {
    "timestamp": "06/07/24 15:00",
    "demand": 35171.01687,
    "solar_generated": 14.40718303,
    "wind_generated": 61.66905671,
    "marketplace_to_demand": 131.1514755,
    "grid_to_demand": 36116.84767,
    "govt_battery_charge": 46.76040097
  },
  {
    "timestamp": "06/07/24 16:00",
    "demand": 31774.77563,
    "solar_generated": 7.672968886,
    "wind_generated": 63.71414073,
    "marketplace_to_demand": 189.5073554,
    "grid_to_demand": 30122.26207,
    "govt_battery_charge": 45.74169382
  },
  {
    "timestamp": "06/07/24 17:00",
    "demand": 31743.24973,
    "solar_generated": 18.09233911,
    "wind_generated": 71.71264238,
    "marketplace_to_demand": 180.4288019,
    "grid_to_demand": 31826.39256,
    "govt_battery_charge": 97.94743239
  },
  {
    "timestamp": "06/07/24 18:00",
    "demand": 34298.54738,
    "solar_generated": 0,
    "wind_generated": 69.44685368,
    "marketplace_to_demand": 183.1048805,
    "grid_to_demand": 35825.35143,
    "govt_battery_charge": 46.88958798
  },
  {
    "timestamp": "06/07/24 19:00",
    "demand": 31169.99028,
    "solar_generated": 0,
    "wind_generated": 59.45244372,
    "marketplace_to_demand": 152.8626531,
    "grid_to_demand": 30040.90378,
    "govt_battery_charge": 86.86053643
  },
  {
    "timestamp": "06/07/24 20:00",
    "demand": 33871.52435,
    "solar_generated": 0,
    "wind_generated": 69.50435934,
    "marketplace_to_demand": 184.3702696,
    "grid_to_demand": 30481.57912,
    "govt_battery_charge": 14.98185094
  },
  {
    "timestamp": "06/07/24 21:00",
    "demand": 34176.07976,
    "solar_generated": 0,
    "wind_generated": 59.65484179,
    "marketplace_to_demand": 150.7115372,
    "grid_to_demand": 31836.32093,
    "govt_battery_charge": 7.471830076
  },
  {
    "timestamp": "06/07/24 22:00",
    "demand": 35655.98584,
    "solar_generated": 0,
    "wind_generated": 60.29953962,
    "marketplace_to_demand": 162.8828884,
    "grid_to_demand": 33765.05536,
    "govt_battery_charge": 14.14793572
  },
  {
    "timestamp": "06/07/24 23:00",
    "demand": 35213.09819,
    "solar_generated": 0,
    "wind_generated": 69.23560239,
    "marketplace_to_demand": 41.27931382,
    "grid_to_demand": 32473.59448,
    "govt_battery_charge": 54.148519
  },
  {
    "timestamp": "07/07/24 0:00",
    "demand": 32076.45892,
    "solar_generated": 0,
    "wind_generated": 64.75140321,
    "marketplace_to_demand": 32.05481839,
    "grid_to_demand": 34200.06304,
    "govt_battery_charge": 87.49217211
  },
  {
    "timestamp": "07/07/24 1:00",
    "demand": 36244.88288,
    "solar_generated": 0,
    "wind_generated": 58.34763769,
    "marketplace_to_demand": 12.00214117,
    "grid_to_demand": 33649.69044,
    "govt_battery_charge": 88.29325422
  },
  {
    "timestamp": "07/07/24 2:00",
    "demand": 32950.75702,
    "solar_generated": 0,
    "wind_generated": 70.14529362,
    "marketplace_to_demand": 106.0662935,
    "grid_to_demand": 36346.43951,
    "govt_battery_charge": 63.61941282
  },
  {
    "timestamp": "07/07/24 3:00",
    "demand": 33589.41091,
    "solar_generated": 0,
    "wind_generated": 60.50617944,
    "marketplace_to_demand": 164.7374903,
    "grid_to_demand": 34084.55971,
    "govt_battery_charge": 77.86767972
  },
  {
    "timestamp": "07/07/24 4:00",
    "demand": 34563.00269,
    "solar_generated": 0,
    "wind_generated": 55.87171885,
    "marketplace_to_demand": 94.87577189,
    "grid_to_demand": 35445.24734,
    "govt_battery_charge": 68.67643748
  },
  {
    "timestamp": "07/07/24 5:00",
    "demand": 31133.85017,
    "solar_generated": 0,
    "wind_generated": 64.60834395,
    "marketplace_to_demand": 180.0103415,
    "grid_to_demand": 34233.49542,
    "govt_battery_charge": 5.925054335
  },
  {
    "timestamp": "07/07/24 6:00",
    "demand": 33162.6101,
    "solar_generated": 11.64262737,
    "wind_generated": 72.58121869,
    "marketplace_to_demand": 80.28512442,
    "grid_to_demand": 31150.45939,
    "govt_battery_charge": 45.85971889
  },
  {
    "timestamp": "07/07/24 7:00",
    "demand": 35426.57482,
    "solar_generated": 6.845894716,
    "wind_generated": 73.05953024,
    "marketplace_to_demand": 198.8816819,
    "grid_to_demand": 34167.18238,
    "govt_battery_charge": 31.47609383
  },
  {
    "timestamp": "07/07/24 8:00",
    "demand": 31444.18899,
    "solar_generated": 15.53074699,
    "wind_generated": 68.04592637,
    "marketplace_to_demand": 81.5065632,
    "grid_to_demand": 35811.61643,
    "govt_battery_charge": 12.37411272
  },
  {
    "timestamp": "07/07/24 9:00",
    "demand": 36334.17928,
    "solar_generated": 11.41479607,
    "wind_generated": 70.67547501,
    "marketplace_to_demand": 132.887583,
    "grid_to_demand": 31029.54335,
    "govt_battery_charge": 73.41137419
  },
  {
    "timestamp": "07/07/24 10:00",
    "demand": 31883.55942,
    "solar_generated": 17.2256623,
    "wind_generated": 68.46233841,
    "marketplace_to_demand": 85.81886489,
    "grid_to_demand": 32204.33744,
    "govt_battery_charge": 20.97648583
  },
  {
    "timestamp": "07/07/24 11:00",
    "demand": 33387.09966,
    "solar_generated": 10.73518637,
    "wind_generated": 61.33201282,
    "marketplace_to_demand": 157.3999351,
    "grid_to_demand": 36030.25591,
    "govt_battery_charge": 85.54629766
  },
  {
    "timestamp": "07/07/24 12:00",
    "demand": 32995.27567,
    "solar_generated": 11.70080489,
    "wind_generated": 60.66272537,
    "marketplace_to_demand": 97.66234532,
    "grid_to_demand": 36333.30481,
    "govt_battery_charge": 69.42076175
  },
  {
    "timestamp": "07/07/24 13:00",
    "demand": 32382.81705,
    "solar_generated": 11.73683241,
    "wind_generated": 56.63503432,
    "marketplace_to_demand": 157.9372581,
    "grid_to_demand": 31023.48296,
    "govt_battery_charge": 26.56029655
  },
  {
    "timestamp": "07/07/24 14:00",
    "demand": 33194.9604,
    "solar_generated": 21.00971692,
    "wind_generated": 66.61159837,
    "marketplace_to_demand": 109.448163,
    "grid_to_demand": 36343.33617,
    "govt_battery_charge": 62.49368783
  },
  {
    "timestamp": "07/07/24 15:00",
    "demand": 35281.49181,
    "solar_generated": 22.34962886,
    "wind_generated": 76.26358437,
    "marketplace_to_demand": 116.9663238,
    "grid_to_demand": 36415.09607,
    "govt_battery_charge": 14.95924236
  },
  {
    "timestamp": "07/07/24 16:00",
    "demand": 33798.46446,
    "solar_generated": 22.29517921,
    "wind_generated": 75.52816206,
    "marketplace_to_demand": 140.4031463,
    "grid_to_demand": 34814.54344,
    "govt_battery_charge": 32.73835238
  },
  {
    "timestamp": "07/07/24 17:00",
    "demand": 35353.56685,
    "solar_generated": 12.85506925,
    "wind_generated": 76.88470248,
    "marketplace_to_demand": 71.08524849,
    "grid_to_demand": 35610.14007,
    "govt_battery_charge": 65.14581438
  },
  {
    "timestamp": "07/07/24 18:00",
    "demand": 36202.61817,
    "solar_generated": 0,
    "wind_generated": 80.24776002,
    "marketplace_to_demand": 173.1150865,
    "grid_to_demand": 36257.48386,
    "govt_battery_charge": 74.5914501
  },
  {
    "timestamp": "07/07/24 19:00",
    "demand": 32392.22368,
    "solar_generated": 0,
    "wind_generated": 61.40790063,
    "marketplace_to_demand": 131.0345382,
    "grid_to_demand": 30873.41697,
    "govt_battery_charge": 54.68483546
  },
  {
    "timestamp": "07/07/24 20:00",
    "demand": 30030.00133,
    "solar_generated": 0,
    "wind_generated": 77.49219139,
    "marketplace_to_demand": 135.7479537,
    "grid_to_demand": 32348.40826,
    "govt_battery_charge": 58.41726857
  },
  {
    "timestamp": "07/07/24 21:00",
    "demand": 30356.84838,
    "solar_generated": 0,
    "wind_generated": 68.43042778,
    "marketplace_to_demand": 93.85591351,
    "grid_to_demand": 35702.13838,
    "govt_battery_charge": 15.66063532
  },
  {
    "timestamp": "07/07/24 22:00",
    "demand": 35862.06899,
    "solar_generated": 0,
    "wind_generated": 69.86415433,
    "marketplace_to_demand": 183.2544031,
    "grid_to_demand": 30537.0058,
    "govt_battery_charge": 80.2674808
  },
  {
    "timestamp": "07/07/24 23:00",
    "demand": 34866.11076,
    "solar_generated": 0,
    "wind_generated": 64.175451,
    "marketplace_to_demand": 55.84433458,
    "grid_to_demand": 31847.46375,
    "govt_battery_charge": 24.1356761
  },
  {
    "timestamp": "08/07/24 0:00",
    "demand": 32668.74233,
    "solar_generated": 0,
    "wind_generated": 76.99323722,
    "marketplace_to_demand": 130.1425286,
    "grid_to_demand": 36042.07514,
    "govt_battery_charge": 48.12287999
  },
  {
    "timestamp": "08/07/24 1:00",
    "demand": 33513.44088,
    "solar_generated": 0,
    "wind_generated": 79.98074245,
    "marketplace_to_demand": 170.5536291,
    "grid_to_demand": 35549.48164,
    "govt_battery_charge": 54.91129769
  },
  {
    "timestamp": "08/07/24 2:00",
    "demand": 35509.58999,
    "solar_generated": 0,
    "wind_generated": 59.58201239,
    "marketplace_to_demand": 131.2031162,
    "grid_to_demand": 29967.42285,
    "govt_battery_charge": 12.58786839
  },
  {
    "timestamp": "08/07/24 3:00",
    "demand": 36262.26911,
    "solar_generated": 0,
    "wind_generated": 69.23914285,
    "marketplace_to_demand": 90.56076036,
    "grid_to_demand": 36522.22742,
    "govt_battery_charge": 50.5943954
  },
  {
    "timestamp": "08/07/24 4:00",
    "demand": 30556.35026,
    "solar_generated": 0,
    "wind_generated": 73.67001442,
    "marketplace_to_demand": 167.1389597,
    "grid_to_demand": 33165.61448,
    "govt_battery_charge": 42.85767392
  },
  {
    "timestamp": "08/07/24 5:00",
    "demand": 32300.60379,
    "solar_generated": 0,
    "wind_generated": 67.4931379,
    "marketplace_to_demand": 36.27850695,
    "grid_to_demand": 35002.8449,
    "govt_battery_charge": 45.52968593
  },
  {
    "timestamp": "08/07/24 6:00",
    "demand": 31593.16693,
    "solar_generated": 10.30533827,
    "wind_generated": 64.1408025,
    "marketplace_to_demand": 86.16650615,
    "grid_to_demand": 31100.94324,
    "govt_battery_charge": 70.90059848
  },
  {
    "timestamp": "08/07/24 7:00",
    "demand": 33844.37672,
    "solar_generated": 10.38952616,
    "wind_generated": 69.35154822,
    "marketplace_to_demand": 77.25540237,
    "grid_to_demand": 31552.16228,
    "govt_battery_charge": 90.30983622
  },
  {
    "timestamp": "08/07/24 8:00",
    "demand": 30925.4943,
    "solar_generated": 17.92308041,
    "wind_generated": 68.70126579,
    "marketplace_to_demand": 74.27500653,
    "grid_to_demand": 30406.41932,
    "govt_battery_charge": 97.58161729
  },
  {
    "timestamp": "08/07/24 9:00",
    "demand": 35114.83994,
    "solar_generated": 14.79038878,
    "wind_generated": 67.74190845,
    "marketplace_to_demand": 184.7382052,
    "grid_to_demand": 33659.87396,
    "govt_battery_charge": 47.96865443
  },
  {
    "timestamp": "08/07/24 10:00",
    "demand": 35786.25665,
    "solar_generated": 12.78037533,
    "wind_generated": 79.04098031,
    "marketplace_to_demand": 155.7320636,
    "grid_to_demand": 34334.87887,
    "govt_battery_charge": 70.0458191
  },
  {
    "timestamp": "08/07/24 11:00",
    "demand": 35843.15922,
    "solar_generated": 6.928690705,
    "wind_generated": 65.79975687,
    "marketplace_to_demand": 144.0135642,
    "grid_to_demand": 34749.37356,
    "govt_battery_charge": 92.87700011
  },
  {
    "timestamp": "08/07/24 12:00",
    "demand": 31363.15236,
    "solar_generated": 10.50250235,
    "wind_generated": 71.66964362,
    "marketplace_to_demand": 7.97723022,
    "grid_to_demand": 35186.5801,
    "govt_battery_charge": 6.922929559
  },
  {
    "timestamp": "08/07/24 13:00",
    "demand": 30664.71478,
    "solar_generated": 9.906831241,
    "wind_generated": 57.66918583,
    "marketplace_to_demand": 3.955010181,
    "grid_to_demand": 36425.72559,
    "govt_battery_charge": 22.02964673
  },
  {
    "timestamp": "08/07/24 14:00",
    "demand": 33836.1814,
    "solar_generated": 17.63048859,
    "wind_generated": 57.00809672,
    "marketplace_to_demand": 158.7708251,
    "grid_to_demand": 33040.55468,
    "govt_battery_charge": 82.04971985
  },
  {
    "timestamp": "08/07/24 15:00",
    "demand": 36408.86382,
    "solar_generated": 16.4945016,
    "wind_generated": 77.14267526,
    "marketplace_to_demand": 63.2902173,
    "grid_to_demand": 32952.01211,
    "govt_battery_charge": 36.28867354
  },
  {
    "timestamp": "08/07/24 16:00",
    "demand": 31822.0962,
    "solar_generated": 22.80564304,
    "wind_generated": 57.15775596,
    "marketplace_to_demand": 170.7253411,
    "grid_to_demand": 35375.18082,
    "govt_battery_charge": 88.37676056
  },
  {
    "timestamp": "08/07/24 17:00",
    "demand": 34125.22636,
    "solar_generated": 13.73411086,
    "wind_generated": 60.36952186,
    "marketplace_to_demand": 37.16456266,
    "grid_to_demand": 34072.89862,
    "govt_battery_charge": 52.03394956
  },
  {
    "timestamp": "08/07/24 18:00",
    "demand": 31152.64334,
    "solar_generated": 0,
    "wind_generated": 60.10273251,
    "marketplace_to_demand": 45.55379426,
    "grid_to_demand": 36149.83391,
    "govt_battery_charge": 69.02562564
  },
  {
    "timestamp": "08/07/24 19:00",
    "demand": 32411.65428,
    "solar_generated": 0,
    "wind_generated": 63.27024445,
    "marketplace_to_demand": 16.53639182,
    "grid_to_demand": 33660.6703,
    "govt_battery_charge": 99.27213778
  },
  {
    "timestamp": "08/07/24 20:00",
    "demand": 36173.4866,
    "solar_generated": 0,
    "wind_generated": 73.36124923,
    "marketplace_to_demand": 141.8918923,
    "grid_to_demand": 30355.25041,
    "govt_battery_charge": 26.2570539
  },
  {
    "timestamp": "08/07/24 21:00",
    "demand": 33558.51795,
    "solar_generated": 0,
    "wind_generated": 73.71997668,
    "marketplace_to_demand": 157.7250032,
    "grid_to_demand": 33233.52645,
    "govt_battery_charge": 7.767209365
  },
  {
    "timestamp": "08/07/24 22:00",
    "demand": 35991.11253,
    "solar_generated": 0,
    "wind_generated": 78.60990881,
    "marketplace_to_demand": 111.9724102,
    "grid_to_demand": 30867.72873,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "08/07/24 23:00",
    "demand": 36259.87619,
    "solar_generated": 0,
    "wind_generated": 72.82784941,
    "marketplace_to_demand": 104.0181431,
    "grid_to_demand": 32819.84829,
    "govt_battery_charge": 55.80260291
  },
  {
    "timestamp": "09/07/24 0:00",
    "demand": 32181.36442,
    "solar_generated": 0,
    "wind_generated": 65.24235401,
    "marketplace_to_demand": 8.781022473,
    "grid_to_demand": 32609.07662,
    "govt_battery_charge": 12.78427778
  },
  {
    "timestamp": "09/07/24 1:00",
    "demand": 34935.82232,
    "solar_generated": 0,
    "wind_generated": 69.03983579,
    "marketplace_to_demand": 133.2459357,
    "grid_to_demand": 35276.87435,
    "govt_battery_charge": 81.90913534
  },
  {
    "timestamp": "09/07/24 2:00",
    "demand": 36316.63314,
    "solar_generated": 0,
    "wind_generated": 66.5690159,
    "marketplace_to_demand": 7.641054251,
    "grid_to_demand": 34902.7334,
    "govt_battery_charge": 77.79700876
  },
  {
    "timestamp": "09/07/24 3:00",
    "demand": 31205.18095,
    "solar_generated": 0,
    "wind_generated": 70.33930224,
    "marketplace_to_demand": 182.391177,
    "grid_to_demand": 33325.43948,
    "govt_battery_charge": 50.89991367
  },
  {
    "timestamp": "09/07/24 4:00",
    "demand": 35220.68654,
    "solar_generated": 0,
    "wind_generated": 57.79270677,
    "marketplace_to_demand": 109.8558194,
    "grid_to_demand": 32049.69366,
    "govt_battery_charge": 54.29362418
  },
  {
    "timestamp": "09/07/24 5:00",
    "demand": 36414.35702,
    "solar_generated": 0,
    "wind_generated": 79.80189394,
    "marketplace_to_demand": 186.118026,
    "grid_to_demand": 34907.62694,
    "govt_battery_charge": 83.2075839
  },
  {
    "timestamp": "09/07/24 6:00",
    "demand": 33840.67008,
    "solar_generated": 18.28562484,
    "wind_generated": 61.40508881,
    "marketplace_to_demand": 118.9782483,
    "grid_to_demand": 35509.77575,
    "govt_battery_charge": 21.27671541
  },
  {
    "timestamp": "09/07/24 7:00",
    "demand": 31780.70127,
    "solar_generated": 21.68683339,
    "wind_generated": 70.32846045,
    "marketplace_to_demand": 49.59562623,
    "grid_to_demand": 31159.12576,
    "govt_battery_charge": 63.84057361
  },
  {
    "timestamp": "09/07/24 8:00",
    "demand": 36003.13623,
    "solar_generated": 11.29804674,
    "wind_generated": 71.89850831,
    "marketplace_to_demand": 27.53736304,
    "grid_to_demand": 34076.47515,
    "govt_battery_charge": 44.30362836
  },
  {
    "timestamp": "09/07/24 9:00",
    "demand": 34303.03483,
    "solar_generated": 21.80410702,
    "wind_generated": 67.0446887,
    "marketplace_to_demand": 71.86981094,
    "grid_to_demand": 34043.07247,
    "govt_battery_charge": 30.47236169
  },
  {
    "timestamp": "09/07/24 10:00",
    "demand": 32702.75577,
    "solar_generated": 12.07167094,
    "wind_generated": 79.04811293,
    "marketplace_to_demand": 186.0191968,
    "grid_to_demand": 31893.81679,
    "govt_battery_charge": 22.34543239
  },
  {
    "timestamp": "09/07/24 11:00",
    "demand": 35575.06765,
    "solar_generated": 20.00526637,
    "wind_generated": 67.65923804,
    "marketplace_to_demand": 180.1875797,
    "grid_to_demand": 36304.31718,
    "govt_battery_charge": 5.719144827
  },
  {
    "timestamp": "09/07/24 12:00",
    "demand": 35500.12315,
    "solar_generated": 9.217058672,
    "wind_generated": 66.93817228,
    "marketplace_to_demand": 4.785852,
    "grid_to_demand": 30412.95498,
    "govt_battery_charge": 40.94191795
  },
  {
    "timestamp": "09/07/24 13:00",
    "demand": 35348.89512,
    "solar_generated": 16.94395896,
    "wind_generated": 68.04693211,
    "marketplace_to_demand": 148.6302203,
    "grid_to_demand": 33894.9878,
    "govt_battery_charge": 7.111450864
  },
  {
    "timestamp": "09/07/24 14:00",
    "demand": 31127.58703,
    "solar_generated": 19.35143868,
    "wind_generated": 72.88404583,
    "marketplace_to_demand": 39.23157598,
    "grid_to_demand": 33826.97839,
    "govt_battery_charge": 43.92965665
  },
  {
    "timestamp": "09/07/24 15:00",
    "demand": 33370.67422,
    "solar_generated": 8.965253535,
    "wind_generated": 63.18221758,
    "marketplace_to_demand": 110.9600861,
    "grid_to_demand": 34375.98234,
    "govt_battery_charge": 92.10237154
  },
  {
    "timestamp": "09/07/24 16:00",
    "demand": 34227.49393,
    "solar_generated": 8.0619119,
    "wind_generated": 62.67549035,
    "marketplace_to_demand": 41.09704494,
    "grid_to_demand": 36021.08236,
    "govt_battery_charge": 94.3209257
  },
  {
    "timestamp": "09/07/24 17:00",
    "demand": 33772.01315,
    "solar_generated": 11.6022862,
    "wind_generated": 56.48870193,
    "marketplace_to_demand": 23.38435458,
    "grid_to_demand": 31089.91829,
    "govt_battery_charge": 17.1737617
  },
  {
    "timestamp": "09/07/24 18:00",
    "demand": 34050.49801,
    "solar_generated": 0,
    "wind_generated": 58.09488173,
    "marketplace_to_demand": 131.6692478,
    "grid_to_demand": 32470.54211,
    "govt_battery_charge": 3.361370585
  },
  {
    "timestamp": "09/07/24 19:00",
    "demand": 34014.03296,
    "solar_generated": 0,
    "wind_generated": 64.36853673,
    "marketplace_to_demand": 159.0480528,
    "grid_to_demand": 35049.54663,
    "govt_battery_charge": 7.087038008
  },
  {
    "timestamp": "09/07/24 20:00",
    "demand": 33573.52631,
    "solar_generated": 0,
    "wind_generated": 57.18981712,
    "marketplace_to_demand": 125.6120063,
    "grid_to_demand": 36040.71297,
    "govt_battery_charge": 4.656436799
  },
  {
    "timestamp": "09/07/24 21:00",
    "demand": 31548.21194,
    "solar_generated": 0,
    "wind_generated": 72.03567647,
    "marketplace_to_demand": 48.40000112,
    "grid_to_demand": 33579.71257,
    "govt_battery_charge": 59.00566011
  },
  {
    "timestamp": "09/07/24 22:00",
    "demand": 31576.58714,
    "solar_generated": 0,
    "wind_generated": 56.70422974,
    "marketplace_to_demand": 71.415037,
    "grid_to_demand": 32285.13094,
    "govt_battery_charge": 36.48916805
  },
  {
    "timestamp": "09/07/24 23:00",
    "demand": 35593.02942,
    "solar_generated": 0,
    "wind_generated": 62.13089056,
    "marketplace_to_demand": 73.93282746,
    "grid_to_demand": 32856.13892,
    "govt_battery_charge": 2.158739421
  },
  {
    "timestamp": "10/07/24 0:00",
    "demand": 32997.69877,
    "solar_generated": 0,
    "wind_generated": 60.433077,
    "marketplace_to_demand": 32.35745837,
    "grid_to_demand": 34223.63662,
    "govt_battery_charge": 95.82710761
  },
  {
    "timestamp": "10/07/24 1:00",
    "demand": 31966.14511,
    "solar_generated": 0,
    "wind_generated": 76.15889254,
    "marketplace_to_demand": 8.179240461,
    "grid_to_demand": 31776.13617,
    "govt_battery_charge": 52.20677739
  },
  {
    "timestamp": "10/07/24 2:00",
    "demand": 32118.59234,
    "solar_generated": 0,
    "wind_generated": 73.96762781,
    "marketplace_to_demand": 96.60504166,
    "grid_to_demand": 32393.55311,
    "govt_battery_charge": 53.23208321
  },
  {
    "timestamp": "10/07/24 3:00",
    "demand": 32907.76142,
    "solar_generated": 0,
    "wind_generated": 76.58182969,
    "marketplace_to_demand": 197.5424639,
    "grid_to_demand": 31745.76883,
    "govt_battery_charge": 82.5714007
  },
  {
    "timestamp": "10/07/24 4:00",
    "demand": 35299.55978,
    "solar_generated": 0,
    "wind_generated": 70.33879218,
    "marketplace_to_demand": 15.08893001,
    "grid_to_demand": 34541.24898,
    "govt_battery_charge": 25.51010823
  },
  {
    "timestamp": "10/07/24 5:00",
    "demand": 30261.83024,
    "solar_generated": 0,
    "wind_generated": 60.63363632,
    "marketplace_to_demand": 126.2734515,
    "grid_to_demand": 30260.79768,
    "govt_battery_charge": 20.20991732
  },
  {
    "timestamp": "10/07/24 6:00",
    "demand": 32577.53172,
    "solar_generated": 15.56885316,
    "wind_generated": 74.9140275,
    "marketplace_to_demand": 153.9991873,
    "grid_to_demand": 34677.42495,
    "govt_battery_charge": 51.82211675
  },
  {
    "timestamp": "10/07/24 7:00",
    "demand": 35400.79749,
    "solar_generated": 18.77822445,
    "wind_generated": 57.84551196,
    "marketplace_to_demand": 136.8071998,
    "grid_to_demand": 31482.44826,
    "govt_battery_charge": 98.34238898
  },
  {
    "timestamp": "10/07/24 8:00",
    "demand": 36249.38621,
    "solar_generated": 16.66008427,
    "wind_generated": 66.59149396,
    "marketplace_to_demand": 118.4336422,
    "grid_to_demand": 33488.02601,
    "govt_battery_charge": 35.43289441
  },
  {
    "timestamp": "10/07/24 9:00",
    "demand": 32155.64426,
    "solar_generated": 7.701882254,
    "wind_generated": 70.49870626,
    "marketplace_to_demand": 58.60013165,
    "grid_to_demand": 35938.28462,
    "govt_battery_charge": 56.99257518
  },
  {
    "timestamp": "10/07/24 10:00",
    "demand": 30421.78249,
    "solar_generated": 17.17520247,
    "wind_generated": 61.52259591,
    "marketplace_to_demand": 25.32832641,
    "grid_to_demand": 32648.11651,
    "govt_battery_charge": 56.13317536
  },
  {
    "timestamp": "10/07/24 11:00",
    "demand": 35723.61817,
    "solar_generated": 17.23494769,
    "wind_generated": 69.42513949,
    "marketplace_to_demand": 49.97548439,
    "grid_to_demand": 34600.76183,
    "govt_battery_charge": 65.73088952
  },
  {
    "timestamp": "10/07/24 12:00",
    "demand": 32500.68446,
    "solar_generated": 17.91178817,
    "wind_generated": 66.9478606,
    "marketplace_to_demand": 156.1145057,
    "grid_to_demand": 32302.35471,
    "govt_battery_charge": 81.04148592
  },
  {
    "timestamp": "10/07/24 13:00",
    "demand": 33407.46728,
    "solar_generated": 7.771366335,
    "wind_generated": 76.75859079,
    "marketplace_to_demand": 95.41164991,
    "grid_to_demand": 31846.26335,
    "govt_battery_charge": 22.40302816
  },
  {
    "timestamp": "10/07/24 14:00",
    "demand": 34472.54506,
    "solar_generated": 18.56787305,
    "wind_generated": 70.8914359,
    "marketplace_to_demand": 13.38422884,
    "grid_to_demand": 32943.25118,
    "govt_battery_charge": 87.53272807
  },
  {
    "timestamp": "10/07/24 15:00",
    "demand": 35220.38041,
    "solar_generated": 10.73240786,
    "wind_generated": 77.3164334,
    "marketplace_to_demand": 16.77965101,
    "grid_to_demand": 32573.25542,
    "govt_battery_charge": 54.52314165
  },
  {
    "timestamp": "10/07/24 16:00",
    "demand": 34555.15451,
    "solar_generated": 9.311476219,
    "wind_generated": 70.30574541,
    "marketplace_to_demand": 81.68009507,
    "grid_to_demand": 33785.9789,
    "govt_battery_charge": 68.96105948
  },
  {
    "timestamp": "10/07/24 17:00",
    "demand": 30003.89308,
    "solar_generated": 12.86761421,
    "wind_generated": 68.30459195,
    "marketplace_to_demand": 8.293213533,
    "grid_to_demand": 32749.60349,
    "govt_battery_charge": 95.99498796
  },
  {
    "timestamp": "10/07/24 18:00",
    "demand": 35199.97236,
    "solar_generated": 0,
    "wind_generated": 67.72824374,
    "marketplace_to_demand": 138.9162158,
    "grid_to_demand": 32651.84856,
    "govt_battery_charge": 95.79835974
  },
  {
    "timestamp": "10/07/24 19:00",
    "demand": 31367.09999,
    "solar_generated": 0,
    "wind_generated": 63.80658327,
    "marketplace_to_demand": 20.54180519,
    "grid_to_demand": 35439.36544,
    "govt_battery_charge": 24.62973283
  },
  {
    "timestamp": "10/07/24 20:00",
    "demand": 32022.32731,
    "solar_generated": 0,
    "wind_generated": 75.93632818,
    "marketplace_to_demand": 156.7553158,
    "grid_to_demand": 34373.66901,
    "govt_battery_charge": 12.58184853
  },
  {
    "timestamp": "10/07/24 21:00",
    "demand": 30174.28361,
    "solar_generated": 0,
    "wind_generated": 74.6479888,
    "marketplace_to_demand": 137.3403409,
    "grid_to_demand": 35336.5494,
    "govt_battery_charge": 19.28156893
  },
  {
    "timestamp": "10/07/24 22:00",
    "demand": 35408.65781,
    "solar_generated": 0,
    "wind_generated": 65.76485565,
    "marketplace_to_demand": 70.73644559,
    "grid_to_demand": 33629.70638,
    "govt_battery_charge": 58.95828046
  },
  {
    "timestamp": "10/07/24 23:00",
    "demand": 36151.17264,
    "solar_generated": 0,
    "wind_generated": 65.41607837,
    "marketplace_to_demand": 146.4583925,
    "grid_to_demand": 35308.46277,
    "govt_battery_charge": 87.5876468
  },
  {
    "timestamp": "11/07/24 0:00",
    "demand": 30989.47207,
    "solar_generated": 0,
    "wind_generated": 57.44191316,
    "marketplace_to_demand": 52.98861247,
    "grid_to_demand": 31528.20909,
    "govt_battery_charge": 20.55373302
  },
  {
    "timestamp": "11/07/24 1:00",
    "demand": 35581.70461,
    "solar_generated": 0,
    "wind_generated": 59.50214661,
    "marketplace_to_demand": 11.34903411,
    "grid_to_demand": 34810.62518,
    "govt_battery_charge": 2.082372044
  },
  {
    "timestamp": "11/07/24 2:00",
    "demand": 31434.34926,
    "solar_generated": 0,
    "wind_generated": 78.224405,
    "marketplace_to_demand": 143.2397532,
    "grid_to_demand": 33393.30677,
    "govt_battery_charge": 35.56967461
  },
  {
    "timestamp": "11/07/24 3:00",
    "demand": 32084.34972,
    "solar_generated": 0,
    "wind_generated": 76.62242401,
    "marketplace_to_demand": 33.59552432,
    "grid_to_demand": 35692.69518,
    "govt_battery_charge": 93.38021703
  },
  {
    "timestamp": "11/07/24 4:00",
    "demand": 30725.73236,
    "solar_generated": 0,
    "wind_generated": 55.83677824,
    "marketplace_to_demand": 95.92492779,
    "grid_to_demand": 36521.44106,
    "govt_battery_charge": 51.06557054
  },
  {
    "timestamp": "11/07/24 5:00",
    "demand": 31964.18843,
    "solar_generated": 0,
    "wind_generated": 74.60705285,
    "marketplace_to_demand": 9.812992688,
    "grid_to_demand": 36191.51795,
    "govt_battery_charge": 3.039727749
  },
  {
    "timestamp": "11/07/24 6:00",
    "demand": 32072.81653,
    "solar_generated": 10.06787823,
    "wind_generated": 58.20905254,
    "marketplace_to_demand": 117.7206451,
    "grid_to_demand": 34982.8186,
    "govt_battery_charge": 44.37005483
  },
  {
    "timestamp": "11/07/24 7:00",
    "demand": 31204.53513,
    "solar_generated": 9.133320403,
    "wind_generated": 66.55058242,
    "marketplace_to_demand": 22.29223854,
    "grid_to_demand": 35595.42024,
    "govt_battery_charge": 63.82514926
  },
  {
    "timestamp": "11/07/24 8:00",
    "demand": 31066.84457,
    "solar_generated": 18.1994745,
    "wind_generated": 77.57633385,
    "marketplace_to_demand": 188.6861617,
    "grid_to_demand": 36042.72379,
    "govt_battery_charge": 90.00500771
  },
  {
    "timestamp": "11/07/24 9:00",
    "demand": 36128.36546,
    "solar_generated": 20.37623025,
    "wind_generated": 73.75463202,
    "marketplace_to_demand": 26.07593858,
    "grid_to_demand": 32708.00689,
    "govt_battery_charge": 29.35476346
  },
  {
    "timestamp": "11/07/24 10:00",
    "demand": 36510.5637,
    "solar_generated": 15.62048843,
    "wind_generated": 77.89147897,
    "marketplace_to_demand": 63.05270359,
    "grid_to_demand": 35123.94992,
    "govt_battery_charge": 91.45001894
  },
  {
    "timestamp": "11/07/24 11:00",
    "demand": 35178.16416,
    "solar_generated": 19.81259849,
    "wind_generated": 80.2659232,
    "marketplace_to_demand": 105.1863016,
    "grid_to_demand": 36166.86363,
    "govt_battery_charge": 19.68570834
  },
  {
    "timestamp": "11/07/24 12:00",
    "demand": 32259.19985,
    "solar_generated": 20.73255676,
    "wind_generated": 59.43278124,
    "marketplace_to_demand": 172.1914361,
    "grid_to_demand": 32495.72793,
    "govt_battery_charge": 46.02112704
  },
  {
    "timestamp": "11/07/24 13:00",
    "demand": 33689.3905,
    "solar_generated": 15.64429769,
    "wind_generated": 69.70219985,
    "marketplace_to_demand": 120.3079386,
    "grid_to_demand": 31230.26107,
    "govt_battery_charge": 54.00808932
  },
  {
    "timestamp": "11/07/24 14:00",
    "demand": 32274.03966,
    "solar_generated": 13.92446476,
    "wind_generated": 78.79261321,
    "marketplace_to_demand": 116.0085246,
    "grid_to_demand": 32456.93383,
    "govt_battery_charge": 75.51947465
  },
  {
    "timestamp": "11/07/24 15:00",
    "demand": 30481.40185,
    "solar_generated": 11.53628757,
    "wind_generated": 61.94757748,
    "marketplace_to_demand": 129.0503622,
    "grid_to_demand": 34055.41645,
    "govt_battery_charge": 44.40917823
  },
  {
    "timestamp": "11/07/24 16:00",
    "demand": 32485.21558,
    "solar_generated": 9.525493214,
    "wind_generated": 65.20600109,
    "marketplace_to_demand": 58.75755471,
    "grid_to_demand": 31327.72544,
    "govt_battery_charge": 80.95558577
  },
  {
    "timestamp": "11/07/24 17:00",
    "demand": 31081.17126,
    "solar_generated": 11.03960133,
    "wind_generated": 62.31937097,
    "marketplace_to_demand": 19.86290063,
    "grid_to_demand": 31412.11733,
    "govt_battery_charge": 37.4714778
  },
  {
    "timestamp": "11/07/24 18:00",
    "demand": 33788.11625,
    "solar_generated": 0,
    "wind_generated": 66.23487445,
    "marketplace_to_demand": 140.543049,
    "grid_to_demand": 35311.61424,
    "govt_battery_charge": 26.81842574
  },
  {
    "timestamp": "11/07/24 19:00",
    "demand": 35509.88862,
    "solar_generated": 0,
    "wind_generated": 77.69018234,
    "marketplace_to_demand": 179.7599986,
    "grid_to_demand": 34443.02667,
    "govt_battery_charge": 20.49708909
  },
  {
    "timestamp": "11/07/24 20:00",
    "demand": 33875.18821,
    "solar_generated": 0,
    "wind_generated": 78.00170368,
    "marketplace_to_demand": 57.59861897,
    "grid_to_demand": 31999.75725,
    "govt_battery_charge": 84.8073311
  },
  {
    "timestamp": "11/07/24 21:00",
    "demand": 31023.71756,
    "solar_generated": 0,
    "wind_generated": 57.14254901,
    "marketplace_to_demand": 111.3139136,
    "grid_to_demand": 34635.32803,
    "govt_battery_charge": 32.40429429
  },
  {
    "timestamp": "11/07/24 22:00",
    "demand": 31328.14809,
    "solar_generated": 0,
    "wind_generated": 55.58018856,
    "marketplace_to_demand": 71.57428924,
    "grid_to_demand": 32543.3297,
    "govt_battery_charge": 29.46500481
  },
  {
    "timestamp": "11/07/24 23:00",
    "demand": 34454.81728,
    "solar_generated": 0,
    "wind_generated": 58.5564806,
    "marketplace_to_demand": 173.0524171,
    "grid_to_demand": 34269.97435,
    "govt_battery_charge": 60.04806782
  },
  {
    "timestamp": "12/07/24 0:00",
    "demand": 35290.73142,
    "solar_generated": 0,
    "wind_generated": 53.95983523,
    "marketplace_to_demand": 189.7825335,
    "grid_to_demand": 35372.58045,
    "govt_battery_charge": 95.8199249
  },
  {
    "timestamp": "12/07/24 1:00",
    "demand": 34577.06808,
    "solar_generated": 0,
    "wind_generated": 61.74368808,
    "marketplace_to_demand": 119.3944777,
    "grid_to_demand": 30533.82984,
    "govt_battery_charge": 93.80266707
  },
  {
    "timestamp": "12/07/24 2:00",
    "demand": 31727.8221,
    "solar_generated": 0,
    "wind_generated": 68.47256254,
    "marketplace_to_demand": 2.773732658,
    "grid_to_demand": 30101.10607,
    "govt_battery_charge": 93.48973216
  },
  {
    "timestamp": "12/07/24 3:00",
    "demand": 34068.63601,
    "solar_generated": 0,
    "wind_generated": 62.31556608,
    "marketplace_to_demand": 124.2746142,
    "grid_to_demand": 33220.99557,
    "govt_battery_charge": 55.76271373
  },
  {
    "timestamp": "12/07/24 4:00",
    "demand": 31919.62798,
    "solar_generated": 0,
    "wind_generated": 72.43818005,
    "marketplace_to_demand": 184.8040254,
    "grid_to_demand": 36464.95053,
    "govt_battery_charge": 90.3259216
  },
  {
    "timestamp": "12/07/24 5:00",
    "demand": 30585.39662,
    "solar_generated": 0,
    "wind_generated": 66.95734052,
    "marketplace_to_demand": 161.6157089,
    "grid_to_demand": 29919.60679,
    "govt_battery_charge": 57.93812502
  },
  {
    "timestamp": "12/07/24 6:00",
    "demand": 31670.5869,
    "solar_generated": 7.295295767,
    "wind_generated": 55.49672563,
    "marketplace_to_demand": 65.90016191,
    "grid_to_demand": 35801.61493,
    "govt_battery_charge": 80.29739202
  },
  {
    "timestamp": "12/07/24 7:00",
    "demand": 31165.88197,
    "solar_generated": 22.8351333,
    "wind_generated": 70.4217805,
    "marketplace_to_demand": 54.1997798,
    "grid_to_demand": 29913.7963,
    "govt_battery_charge": 20.70738129
  },
  {
    "timestamp": "12/07/24 8:00",
    "demand": 30619.72769,
    "solar_generated": 21.99664572,
    "wind_generated": 54.1655481,
    "marketplace_to_demand": 186.860405,
    "grid_to_demand": 29913.19063,
    "govt_battery_charge": 93.93885502
  },
  {
    "timestamp": "12/07/24 9:00",
    "demand": 35294.78318,
    "solar_generated": 21.51766916,
    "wind_generated": 65.79167704,
    "marketplace_to_demand": 161.8927539,
    "grid_to_demand": 34238.50055,
    "govt_battery_charge": 90.84709372
  },
  {
    "timestamp": "12/07/24 10:00",
    "demand": 36617.79351,
    "solar_generated": 15.09667368,
    "wind_generated": 68.42524447,
    "marketplace_to_demand": 122.833179,
    "grid_to_demand": 31735.33283,
    "govt_battery_charge": 58.79732392
  },
  {
    "timestamp": "12/07/24 11:00",
    "demand": 32171.35112,
    "solar_generated": 22.62216343,
    "wind_generated": 63.24144567,
    "marketplace_to_demand": 100.029544,
    "grid_to_demand": 32863.64082,
    "govt_battery_charge": 77.82837814
  },
  {
    "timestamp": "12/07/24 12:00",
    "demand": 31660.30316,
    "solar_generated": 18.8952704,
    "wind_generated": 58.52490605,
    "marketplace_to_demand": 159.7202174,
    "grid_to_demand": 33574.08627,
    "govt_battery_charge": 51.14037762
  },
  {
    "timestamp": "12/07/24 13:00",
    "demand": 33124.98805,
    "solar_generated": 9.710977043,
    "wind_generated": 55.11446707,
    "marketplace_to_demand": 165.0731487,
    "grid_to_demand": 33930.85393,
    "govt_battery_charge": 23.62379651
  },
  {
    "timestamp": "12/07/24 14:00",
    "demand": 36348.06149,
    "solar_generated": 23.89832314,
    "wind_generated": 79.76253906,
    "marketplace_to_demand": 78.43400115,
    "grid_to_demand": 30413.74312,
    "govt_battery_charge": 62.22100388
  },
  {
    "timestamp": "12/07/24 15:00",
    "demand": 33429.67949,
    "solar_generated": 23.3261645,
    "wind_generated": 53.78175746,
    "marketplace_to_demand": 38.94479124,
    "grid_to_demand": 30086.12937,
    "govt_battery_charge": 82.51609545
  },
  {
    "timestamp": "12/07/24 16:00",
    "demand": 32844.96761,
    "solar_generated": 9.278522352,
    "wind_generated": 61.05059075,
    "marketplace_to_demand": 49.48911412,
    "grid_to_demand": 33235.16683,
    "govt_battery_charge": 58.12440366
  },
  {
    "timestamp": "12/07/24 17:00",
    "demand": 35186.46206,
    "solar_generated": 14.94982233,
    "wind_generated": 65.37591446,
    "marketplace_to_demand": 76.4273891,
    "grid_to_demand": 30648.59557,
    "govt_battery_charge": 50.89767712
  },
  {
    "timestamp": "12/07/24 18:00",
    "demand": 33227.38832,
    "solar_generated": 0,
    "wind_generated": 70.73451253,
    "marketplace_to_demand": 47.98253784,
    "grid_to_demand": 35918.98665,
    "govt_battery_charge": 54.2358969
  },
  {
    "timestamp": "12/07/24 19:00",
    "demand": 32251.76131,
    "solar_generated": 0,
    "wind_generated": 58.79081023,
    "marketplace_to_demand": 0.504968652,
    "grid_to_demand": 35012.62213,
    "govt_battery_charge": 31.01368078
  },
  {
    "timestamp": "12/07/24 20:00",
    "demand": 34645.02148,
    "solar_generated": 0,
    "wind_generated": 54.65815235,
    "marketplace_to_demand": 100.1333194,
    "grid_to_demand": 35056.87294,
    "govt_battery_charge": 74.71154169
  },
  {
    "timestamp": "12/07/24 21:00",
    "demand": 32175.88323,
    "solar_generated": 0,
    "wind_generated": 69.35392944,
    "marketplace_to_demand": 189.6060369,
    "grid_to_demand": 30381.61416,
    "govt_battery_charge": 63.85927427
  },
  {
    "timestamp": "12/07/24 22:00",
    "demand": 35849.88552,
    "solar_generated": 0,
    "wind_generated": 54.33316521,
    "marketplace_to_demand": 181.9451185,
    "grid_to_demand": 34036.60583,
    "govt_battery_charge": 8.426915932
  },
  {
    "timestamp": "12/07/24 23:00",
    "demand": 30786.64954,
    "solar_generated": 0,
    "wind_generated": 63.63038791,
    "marketplace_to_demand": 34.54346653,
    "grid_to_demand": 31578.66344,
    "govt_battery_charge": 67.30646837
  },
  {
    "timestamp": "13/07/24 0:00",
    "demand": 30690.04369,
    "solar_generated": 0,
    "wind_generated": 55.39214309,
    "marketplace_to_demand": 133.7011627,
    "grid_to_demand": 32651.60046,
    "govt_battery_charge": 39.35772569
  },
  {
    "timestamp": "13/07/24 1:00",
    "demand": 32929.40812,
    "solar_generated": 0,
    "wind_generated": 54.44237248,
    "marketplace_to_demand": 47.20546668,
    "grid_to_demand": 33307.54905,
    "govt_battery_charge": 70.5845595
  },
  {
    "timestamp": "13/07/24 2:00",
    "demand": 30562.70152,
    "solar_generated": 0,
    "wind_generated": 70.18539588,
    "marketplace_to_demand": 11.45749373,
    "grid_to_demand": 35570.21237,
    "govt_battery_charge": 20.4455743
  },
  {
    "timestamp": "13/07/24 3:00",
    "demand": 34886.20105,
    "solar_generated": 0,
    "wind_generated": 58.54789912,
    "marketplace_to_demand": 65.27582493,
    "grid_to_demand": 33798.33937,
    "govt_battery_charge": 44.57913253
  },
  {
    "timestamp": "13/07/24 4:00",
    "demand": 34375.10898,
    "solar_generated": 0,
    "wind_generated": 73.6760998,
    "marketplace_to_demand": 103.3974604,
    "grid_to_demand": 33890.43668,
    "govt_battery_charge": 58.97950204
  },
  {
    "timestamp": "13/07/24 5:00",
    "demand": 31179.06544,
    "solar_generated": 0,
    "wind_generated": 76.7620516,
    "marketplace_to_demand": 28.68618228,
    "grid_to_demand": 31245.46487,
    "govt_battery_charge": 63.35736805
  },
  {
    "timestamp": "13/07/24 6:00",
    "demand": 30807.4198,
    "solar_generated": 20.88784544,
    "wind_generated": 77.84695672,
    "marketplace_to_demand": 137.1139303,
    "grid_to_demand": 30281.6769,
    "govt_battery_charge": 54.3811838
  },
  {
    "timestamp": "13/07/24 7:00",
    "demand": 30887.86749,
    "solar_generated": 17.85745134,
    "wind_generated": 72.09490079,
    "marketplace_to_demand": 49.65039178,
    "grid_to_demand": 30395.17166,
    "govt_battery_charge": 26.77137161
  },
  {
    "timestamp": "13/07/24 8:00",
    "demand": 30006.12027,
    "solar_generated": 17.86428342,
    "wind_generated": 69.86337292,
    "marketplace_to_demand": 91.46949828,
    "grid_to_demand": 33427.57601,
    "govt_battery_charge": 88.05225993
  },
  {
    "timestamp": "13/07/24 9:00",
    "demand": 32406.48138,
    "solar_generated": 15.0763793,
    "wind_generated": 75.73404863,
    "marketplace_to_demand": 63.98327581,
    "grid_to_demand": 30640.71775,
    "govt_battery_charge": 22.84323743
  },
  {
    "timestamp": "13/07/24 10:00",
    "demand": 35925.31069,
    "solar_generated": 8.434682414,
    "wind_generated": 68.54847269,
    "marketplace_to_demand": 87.41306163,
    "grid_to_demand": 35284.84556,
    "govt_battery_charge": 13.03407172
  },
  {
    "timestamp": "13/07/24 11:00",
    "demand": 35281.45221,
    "solar_generated": 12.08080169,
    "wind_generated": 64.94495429,
    "marketplace_to_demand": 123.4067261,
    "grid_to_demand": 34434.65637,
    "govt_battery_charge": 2.052188893
  },
  {
    "timestamp": "13/07/24 12:00",
    "demand": 36516.66905,
    "solar_generated": 13.73353384,
    "wind_generated": 79.27864466,
    "marketplace_to_demand": 138.6005793,
    "grid_to_demand": 34872.07614,
    "govt_battery_charge": 73.14745674
  },
  {
    "timestamp": "13/07/24 13:00",
    "demand": 32263.11058,
    "solar_generated": 12.96757575,
    "wind_generated": 59.88253269,
    "marketplace_to_demand": 163.8025199,
    "grid_to_demand": 30664.98544,
    "govt_battery_charge": 18.24627932
  },
  {
    "timestamp": "13/07/24 14:00",
    "demand": 35360.41315,
    "solar_generated": 10.26409584,
    "wind_generated": 80.3214008,
    "marketplace_to_demand": 170.41003,
    "grid_to_demand": 30016.75447,
    "govt_battery_charge": 24.9344484
  },
  {
    "timestamp": "13/07/24 15:00",
    "demand": 34003.19641,
    "solar_generated": 21.07791942,
    "wind_generated": 66.25895175,
    "marketplace_to_demand": 103.157505,
    "grid_to_demand": 31977.57117,
    "govt_battery_charge": 15.07422285
  },
  {
    "timestamp": "13/07/24 16:00",
    "demand": 34406.42652,
    "solar_generated": 12.86270999,
    "wind_generated": 61.07442094,
    "marketplace_to_demand": 139.0141697,
    "grid_to_demand": 32678.90838,
    "govt_battery_charge": 95.13320912
  },
  {
    "timestamp": "13/07/24 17:00",
    "demand": 33071.71746,
    "solar_generated": 11.21761744,
    "wind_generated": 73.31166778,
    "marketplace_to_demand": 134.8966262,
    "grid_to_demand": 35761.32678,
    "govt_battery_charge": 69.93390446
  },
  {
    "timestamp": "13/07/24 18:00",
    "demand": 34067.19509,
    "solar_generated": 0,
    "wind_generated": 55.35679304,
    "marketplace_to_demand": 155.9245093,
    "grid_to_demand": 33423.00849,
    "govt_battery_charge": 66.44278065
  },
  {
    "timestamp": "13/07/24 19:00",
    "demand": 35471.63501,
    "solar_generated": 0,
    "wind_generated": 65.13970331,
    "marketplace_to_demand": 77.8642399,
    "grid_to_demand": 30376.53854,
    "govt_battery_charge": 65.56201476
  },
  {
    "timestamp": "13/07/24 20:00",
    "demand": 35725.14645,
    "solar_generated": 0,
    "wind_generated": 57.45844757,
    "marketplace_to_demand": 64.51547774,
    "grid_to_demand": 35013.17326,
    "govt_battery_charge": 92.4501055
  },
  {
    "timestamp": "13/07/24 21:00",
    "demand": 31704.16268,
    "solar_generated": 0,
    "wind_generated": 63.21987931,
    "marketplace_to_demand": 103.1726636,
    "grid_to_demand": 30785.96993,
    "govt_battery_charge": 10.35750223
  },
  {
    "timestamp": "13/07/24 22:00",
    "demand": 36257.82153,
    "solar_generated": 0,
    "wind_generated": 75.0286795,
    "marketplace_to_demand": 23.85193571,
    "grid_to_demand": 30862.44173,
    "govt_battery_charge": 98.85846294
  },
  {
    "timestamp": "13/07/24 23:00",
    "demand": 34704.01345,
    "solar_generated": 0,
    "wind_generated": 62.21821319,
    "marketplace_to_demand": 138.670336,
    "grid_to_demand": 32172.03125,
    "govt_battery_charge": 69.8247665
  },
  {
    "timestamp": "14/07/24 0:00",
    "demand": 32119.31142,
    "solar_generated": 0,
    "wind_generated": 79.0818835,
    "marketplace_to_demand": 113.6529123,
    "grid_to_demand": 34554.41838,
    "govt_battery_charge": 68.70858329
  },
  {
    "timestamp": "14/07/24 1:00",
    "demand": 36458.60522,
    "solar_generated": 0,
    "wind_generated": 63.83874615,
    "marketplace_to_demand": 112.2006061,
    "grid_to_demand": 32691.10322,
    "govt_battery_charge": 21.86779557
  },
  {
    "timestamp": "14/07/24 2:00",
    "demand": 33644.51963,
    "solar_generated": 0,
    "wind_generated": 71.38698439,
    "marketplace_to_demand": 127.4839733,
    "grid_to_demand": 33985.99995,
    "govt_battery_charge": 54.92824887
  },
  {
    "timestamp": "14/07/24 3:00",
    "demand": 31659.6649,
    "solar_generated": 0,
    "wind_generated": 77.04068937,
    "marketplace_to_demand": 95.69343249,
    "grid_to_demand": 30239.43699,
    "govt_battery_charge": 65.78279268
  },
  {
    "timestamp": "14/07/24 4:00",
    "demand": 30937.30818,
    "solar_generated": 0,
    "wind_generated": 57.37073568,
    "marketplace_to_demand": 6.73233,
    "grid_to_demand": 36109.90145,
    "govt_battery_charge": 13.25067642
  },
  {
    "timestamp": "14/07/24 5:00",
    "demand": 31563.0665,
    "solar_generated": 0,
    "wind_generated": 53.62576576,
    "marketplace_to_demand": 81.850715,
    "grid_to_demand": 33827.26244,
    "govt_battery_charge": 18.78873579
  },
  {
    "timestamp": "14/07/24 6:00",
    "demand": 30774.01686,
    "solar_generated": 8.350179288,
    "wind_generated": 60.10709215,
    "marketplace_to_demand": 104.5780447,
    "grid_to_demand": 31707.32257,
    "govt_battery_charge": 65.31482556
  },
  {
    "timestamp": "14/07/24 7:00",
    "demand": 33691.21082,
    "solar_generated": 7.055581257,
    "wind_generated": 68.82290741,
    "marketplace_to_demand": 154.1545244,
    "grid_to_demand": 35786.21241,
    "govt_battery_charge": 60.38394283
  },
  {
    "timestamp": "14/07/24 8:00",
    "demand": 35716.12976,
    "solar_generated": 11.70999635,
    "wind_generated": 77.36992379,
    "marketplace_to_demand": 70.04143562,
    "grid_to_demand": 34177.7844,
    "govt_battery_charge": 16.95746747
  },
  {
    "timestamp": "14/07/24 9:00",
    "demand": 35175.55078,
    "solar_generated": 18.57852389,
    "wind_generated": 73.93267144,
    "marketplace_to_demand": 22.25850914,
    "grid_to_demand": 34689.88563,
    "govt_battery_charge": 29.7786386
  },
  {
    "timestamp": "14/07/24 10:00",
    "demand": 32743.01712,
    "solar_generated": 15.37901251,
    "wind_generated": 78.12172744,
    "marketplace_to_demand": 44.87945919,
    "grid_to_demand": 29913.51767,
    "govt_battery_charge": 40.5078023
  },
  {
    "timestamp": "14/07/24 11:00",
    "demand": 34589.03312,
    "solar_generated": 8.566384041,
    "wind_generated": 71.02177142,
    "marketplace_to_demand": 187.8573576,
    "grid_to_demand": 34275.04452,
    "govt_battery_charge": 48.52089982
  },
  {
    "timestamp": "14/07/24 12:00",
    "demand": 32214.16397,
    "solar_generated": 22.23360987,
    "wind_generated": 59.27764475,
    "marketplace_to_demand": 62.73419169,
    "grid_to_demand": 36536.79697,
    "govt_battery_charge": 11.76613321
  },
  {
    "timestamp": "14/07/24 13:00",
    "demand": 36307.01274,
    "solar_generated": 11.5881797,
    "wind_generated": 77.47232141,
    "marketplace_to_demand": 177.3832757,
    "grid_to_demand": 34636.66114,
    "govt_battery_charge": 12.21163309
  },
  {
    "timestamp": "14/07/24 14:00",
    "demand": 34438.93999,
    "solar_generated": 20.8290511,
    "wind_generated": 61.50061721,
    "marketplace_to_demand": 63.23912974,
    "grid_to_demand": 32334.21483,
    "govt_battery_charge": 5.6778732
  },
  {
    "timestamp": "14/07/24 15:00",
    "demand": 34050.96605,
    "solar_generated": 9.324570661,
    "wind_generated": 80.08600425,
    "marketplace_to_demand": 86.89594695,
    "grid_to_demand": 30444.44622,
    "govt_battery_charge": 94.35088134
  },
  {
    "timestamp": "14/07/24 16:00",
    "demand": 33021.37192,
    "solar_generated": 7.775208701,
    "wind_generated": 71.38006557,
    "marketplace_to_demand": 101.0889013,
    "grid_to_demand": 35423.71631,
    "govt_battery_charge": 15.85432045
  },
  {
    "timestamp": "14/07/24 17:00",
    "demand": 33333.79675,
    "solar_generated": 9.19952983,
    "wind_generated": 56.91600335,
    "marketplace_to_demand": 34.08547262,
    "grid_to_demand": 31706.21543,
    "govt_battery_charge": 79.15725804
  },
  {
    "timestamp": "14/07/24 18:00",
    "demand": 31866.07604,
    "solar_generated": 0,
    "wind_generated": 69.79857925,
    "marketplace_to_demand": 171.9178888,
    "grid_to_demand": 34534.0952,
    "govt_battery_charge": 23.96928844
  },
  {
    "timestamp": "14/07/24 19:00",
    "demand": 31674.28451,
    "solar_generated": 0,
    "wind_generated": 67.46315942,
    "marketplace_to_demand": 177.3907053,
    "grid_to_demand": 33633.69776,
    "govt_battery_charge": 19.63564685
  },
  {
    "timestamp": "14/07/24 20:00",
    "demand": 33489.59539,
    "solar_generated": 0,
    "wind_generated": 65.84772656,
    "marketplace_to_demand": 158.7386473,
    "grid_to_demand": 34394.82895,
    "govt_battery_charge": 66.24405008
  },
  {
    "timestamp": "14/07/24 21:00",
    "demand": 35950.11115,
    "solar_generated": 0,
    "wind_generated": 68.99430798,
    "marketplace_to_demand": 134.2289624,
    "grid_to_demand": 35664.55124,
    "govt_battery_charge": 1.73721598
  },
  {
    "timestamp": "14/07/24 22:00",
    "demand": 31422.17883,
    "solar_generated": 0,
    "wind_generated": 65.73819386,
    "marketplace_to_demand": 97.41559046,
    "grid_to_demand": 34033.22007,
    "govt_battery_charge": 33.73907768
  },
  {
    "timestamp": "14/07/24 23:00",
    "demand": 34368.25886,
    "solar_generated": 0,
    "wind_generated": 57.63824175,
    "marketplace_to_demand": 106.5488661,
    "grid_to_demand": 34722.52083,
    "govt_battery_charge": 55.46649477
  },
  {
    "timestamp": "15/07/24 0:00",
    "demand": 34016.09644,
    "solar_generated": 0,
    "wind_generated": 59.23596932,
    "marketplace_to_demand": 197.5711097,
    "grid_to_demand": 34915.78611,
    "govt_battery_charge": 35.45694533
  },
  {
    "timestamp": "15/07/24 1:00",
    "demand": 30055.99587,
    "solar_generated": 0,
    "wind_generated": 56.59758015,
    "marketplace_to_demand": 61.27714227,
    "grid_to_demand": 35968.65912,
    "govt_battery_charge": 42.29302875
  },
  {
    "timestamp": "15/07/24 2:00",
    "demand": 34744.42343,
    "solar_generated": 0,
    "wind_generated": 56.40693551,
    "marketplace_to_demand": 113.5914798,
    "grid_to_demand": 33591.26768,
    "govt_battery_charge": 95.90081293
  },
  {
    "timestamp": "15/07/24 3:00",
    "demand": 34080.18528,
    "solar_generated": 0,
    "wind_generated": 68.895472,
    "marketplace_to_demand": 71.06480673,
    "grid_to_demand": 32996.69295,
    "govt_battery_charge": 32.31140193
  },
  {
    "timestamp": "15/07/24 4:00",
    "demand": 35002.9378,
    "solar_generated": 0,
    "wind_generated": 54.28154999,
    "marketplace_to_demand": 159.9681428,
    "grid_to_demand": 35524.26502,
    "govt_battery_charge": 84.94123604
  },
  {
    "timestamp": "15/07/24 5:00",
    "demand": 35678.85617,
    "solar_generated": 0,
    "wind_generated": 64.72610361,
    "marketplace_to_demand": 104.7986632,
    "grid_to_demand": 34760.67511,
    "govt_battery_charge": 34.92156372
  },
  {
    "timestamp": "15/07/24 6:00",
    "demand": 30396.16255,
    "solar_generated": 18.76832825,
    "wind_generated": 75.9212709,
    "marketplace_to_demand": 84.56365165,
    "grid_to_demand": 30099.80196,
    "govt_battery_charge": 85.55640749
  },
  {
    "timestamp": "15/07/24 7:00",
    "demand": 33617.88931,
    "solar_generated": 8.635076867,
    "wind_generated": 63.62658988,
    "marketplace_to_demand": 33.49618066,
    "grid_to_demand": 31564.51337,
    "govt_battery_charge": 85.38908388
  },
  {
    "timestamp": "15/07/24 8:00",
    "demand": 34377.83628,
    "solar_generated": 10.82213382,
    "wind_generated": 58.8499511,
    "marketplace_to_demand": 154.6541561,
    "grid_to_demand": 30345.13934,
    "govt_battery_charge": 82.06952335
  },
  {
    "timestamp": "15/07/24 9:00",
    "demand": 35378.53097,
    "solar_generated": 8.886415715,
    "wind_generated": 58.27658556,
    "marketplace_to_demand": 26.02447141,
    "grid_to_demand": 35230.41593,
    "govt_battery_charge": 98.39010754
  },
  {
    "timestamp": "15/07/24 10:00",
    "demand": 30385.82493,
    "solar_generated": 19.19487489,
    "wind_generated": 74.30400947,
    "marketplace_to_demand": 17.97612972,
    "grid_to_demand": 35250.31713,
    "govt_battery_charge": 62.70140208
  },
  {
    "timestamp": "15/07/24 11:00",
    "demand": 35557.2724,
    "solar_generated": 22.12658921,
    "wind_generated": 70.5094092,
    "marketplace_to_demand": 181.5419525,
    "grid_to_demand": 35392.39322,
    "govt_battery_charge": 55.75368825
  },
  {
    "timestamp": "15/07/24 12:00",
    "demand": 32377.63948,
    "solar_generated": 23.56157977,
    "wind_generated": 74.77006331,
    "marketplace_to_demand": 38.02492373,
    "grid_to_demand": 32180.51358,
    "govt_battery_charge": 33.73714571
  },
  {
    "timestamp": "15/07/24 13:00",
    "demand": 33737.26694,
    "solar_generated": 15.8366243,
    "wind_generated": 55.61999105,
    "marketplace_to_demand": 189.6900564,
    "grid_to_demand": 31775.00451,
    "govt_battery_charge": 4.116343131
  },
  {
    "timestamp": "15/07/24 14:00",
    "demand": 30349.93027,
    "solar_generated": 18.06041346,
    "wind_generated": 72.05752023,
    "marketplace_to_demand": 156.2693891,
    "grid_to_demand": 31397.0131,
    "govt_battery_charge": 68.50529389
  },
  {
    "timestamp": "15/07/24 15:00",
    "demand": 36064.16221,
    "solar_generated": 10.37911152,
    "wind_generated": 58.43518048,
    "marketplace_to_demand": 65.23568036,
    "grid_to_demand": 31697.85305,
    "govt_battery_charge": 7.768949741
  },
  {
    "timestamp": "15/07/24 16:00",
    "demand": 35447.00451,
    "solar_generated": 18.37858083,
    "wind_generated": 66.01082323,
    "marketplace_to_demand": 43.00078002,
    "grid_to_demand": 34157.80517,
    "govt_battery_charge": 41.05107237
  },
  {
    "timestamp": "15/07/24 17:00",
    "demand": 35477.28628,
    "solar_generated": 19.52375525,
    "wind_generated": 55.29508626,
    "marketplace_to_demand": 127.4636469,
    "grid_to_demand": 31678.11919,
    "govt_battery_charge": 17.52752108
  },
  {
    "timestamp": "15/07/24 18:00",
    "demand": 32018.80379,
    "solar_generated": 0,
    "wind_generated": 72.39785319,
    "marketplace_to_demand": 96.87600663,
    "grid_to_demand": 31157.23018,
    "govt_battery_charge": 12.84734949
  },
  {
    "timestamp": "15/07/24 19:00",
    "demand": 32082.68827,
    "solar_generated": 0,
    "wind_generated": 76.4661048,
    "marketplace_to_demand": 39.89987338,
    "grid_to_demand": 32594.71976,
    "govt_battery_charge": 44.3228213
  },
  {
    "timestamp": "15/07/24 20:00",
    "demand": 33311.76094,
    "solar_generated": 0,
    "wind_generated": 56.6138197,
    "marketplace_to_demand": 187.8969202,
    "grid_to_demand": 36516.07892,
    "govt_battery_charge": 43.0028599
  },
  {
    "timestamp": "15/07/24 21:00",
    "demand": 33287.94761,
    "solar_generated": 0,
    "wind_generated": 78.9239213,
    "marketplace_to_demand": 190.5846513,
    "grid_to_demand": 30254.72024,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "15/07/24 22:00",
    "demand": 30523.62865,
    "solar_generated": 0,
    "wind_generated": 65.19000813,
    "marketplace_to_demand": 179.5096228,
    "grid_to_demand": 33812.8293,
    "govt_battery_charge": 46.77269093
  },
  {
    "timestamp": "15/07/24 23:00",
    "demand": 34318.26317,
    "solar_generated": 0,
    "wind_generated": 59.95682252,
    "marketplace_to_demand": 157.5105996,
    "grid_to_demand": 34360.09022,
    "govt_battery_charge": 78.19909933
  },
  {
    "timestamp": "16/07/24 0:00",
    "demand": 36435.95386,
    "solar_generated": 0,
    "wind_generated": 58.36971289,
    "marketplace_to_demand": 107.0998467,
    "grid_to_demand": 34496.72701,
    "govt_battery_charge": 6.084727883
  },
  {
    "timestamp": "16/07/24 1:00",
    "demand": 32687.72244,
    "solar_generated": 0,
    "wind_generated": 71.39366467,
    "marketplace_to_demand": 51.43636063,
    "grid_to_demand": 33610.15259,
    "govt_battery_charge": 54.68309651
  },
  {
    "timestamp": "16/07/24 2:00",
    "demand": 34848.12618,
    "solar_generated": 0,
    "wind_generated": 79.53871986,
    "marketplace_to_demand": 113.3337452,
    "grid_to_demand": 36195.66045,
    "govt_battery_charge": 95.59041737
  },
  {
    "timestamp": "16/07/24 3:00",
    "demand": 30114.0696,
    "solar_generated": 0,
    "wind_generated": 59.63968586,
    "marketplace_to_demand": 1.445988457,
    "grid_to_demand": 33060.06506,
    "govt_battery_charge": 34.85729052
  },
  {
    "timestamp": "16/07/24 4:00",
    "demand": 35203.2284,
    "solar_generated": 0,
    "wind_generated": 67.9948453,
    "marketplace_to_demand": 196.8979611,
    "grid_to_demand": 34194.38416,
    "govt_battery_charge": 70.8076405
  },
  {
    "timestamp": "16/07/24 5:00",
    "demand": 36411.71472,
    "solar_generated": 0,
    "wind_generated": 53.90301983,
    "marketplace_to_demand": 57.07207304,
    "grid_to_demand": 36185.08758,
    "govt_battery_charge": 5.783111234
  },
  {
    "timestamp": "16/07/24 6:00",
    "demand": 32293.24531,
    "solar_generated": 11.95791673,
    "wind_generated": 72.55329695,
    "marketplace_to_demand": 73.15649206,
    "grid_to_demand": 34672.9034,
    "govt_battery_charge": 81.93837135
  },
  {
    "timestamp": "16/07/24 7:00",
    "demand": 31495.94741,
    "solar_generated": 10.41147898,
    "wind_generated": 79.43086852,
    "marketplace_to_demand": 132.1031451,
    "grid_to_demand": 30540.07414,
    "govt_battery_charge": 68.20094122
  },
  {
    "timestamp": "16/07/24 8:00",
    "demand": 30069.88241,
    "solar_generated": 8.328165787,
    "wind_generated": 68.31703959,
    "marketplace_to_demand": 104.6881529,
    "grid_to_demand": 30384.64243,
    "govt_battery_charge": 82.34345605
  },
  {
    "timestamp": "16/07/24 9:00",
    "demand": 31524.34726,
    "solar_generated": 7.617298672,
    "wind_generated": 56.06334106,
    "marketplace_to_demand": 116.1707197,
    "grid_to_demand": 34387.89549,
    "govt_battery_charge": 64.70316124
  },
  {
    "timestamp": "16/07/24 10:00",
    "demand": 30199.60647,
    "solar_generated": 15.14245955,
    "wind_generated": 73.65222478,
    "marketplace_to_demand": 137.4538655,
    "grid_to_demand": 35052.72276,
    "govt_battery_charge": 19.90594477
  },
  {
    "timestamp": "16/07/24 11:00",
    "demand": 33713.88183,
    "solar_generated": 6.885782475,
    "wind_generated": 74.71430476,
    "marketplace_to_demand": 68.34072069,
    "grid_to_demand": 30229.01475,
    "govt_battery_charge": 69.28788708
  },
  {
    "timestamp": "16/07/24 12:00",
    "demand": 31569.7899,
    "solar_generated": 20.08871301,
    "wind_generated": 57.76223533,
    "marketplace_to_demand": 40.9650906,
    "grid_to_demand": 30941.76848,
    "govt_battery_charge": 1.867767123
  },
  {
    "timestamp": "16/07/24 13:00",
    "demand": 31080.42073,
    "solar_generated": 10.72223656,
    "wind_generated": 80.21723576,
    "marketplace_to_demand": 63.7928728,
    "grid_to_demand": 35735.97547,
    "govt_battery_charge": 38.91955955
  },
  {
    "timestamp": "16/07/24 14:00",
    "demand": 34549.25644,
    "solar_generated": 13.02262828,
    "wind_generated": 58.35994072,
    "marketplace_to_demand": 54.43899133,
    "grid_to_demand": 33390.2125,
    "govt_battery_charge": 47.91891079
  },
  {
    "timestamp": "16/07/24 15:00",
    "demand": 31079.93676,
    "solar_generated": 14.51932917,
    "wind_generated": 75.76795938,
    "marketplace_to_demand": 46.18173796,
    "grid_to_demand": 34100.82021,
    "govt_battery_charge": 63.71584938
  },
  {
    "timestamp": "16/07/24 16:00",
    "demand": 30956.07907,
    "solar_generated": 19.60995206,
    "wind_generated": 78.04401028,
    "marketplace_to_demand": 27.2111095,
    "grid_to_demand": 32325.9149,
    "govt_battery_charge": 1.682236234
  },
  {
    "timestamp": "16/07/24 17:00",
    "demand": 31750.02377,
    "solar_generated": 8.724040828,
    "wind_generated": 74.86353979,
    "marketplace_to_demand": 45.4752046,
    "grid_to_demand": 30647.08442,
    "govt_battery_charge": 52.59366172
  },
  {
    "timestamp": "16/07/24 18:00",
    "demand": 33073.25145,
    "solar_generated": 0,
    "wind_generated": 59.95545562,
    "marketplace_to_demand": 154.1426222,
    "grid_to_demand": 34997.99558,
    "govt_battery_charge": 74.61801532
  },
  {
    "timestamp": "16/07/24 19:00",
    "demand": 32362.05425,
    "solar_generated": 0,
    "wind_generated": 65.41503664,
    "marketplace_to_demand": 69.75087312,
    "grid_to_demand": 34125.65316,
    "govt_battery_charge": 41.2287575
  },
  {
    "timestamp": "16/07/24 20:00",
    "demand": 33473.91086,
    "solar_generated": 0,
    "wind_generated": 79.13179457,
    "marketplace_to_demand": 118.5982413,
    "grid_to_demand": 32414.65417,
    "govt_battery_charge": 76.63586374
  },
  {
    "timestamp": "16/07/24 21:00",
    "demand": 33005.40957,
    "solar_generated": 0,
    "wind_generated": 79.71929892,
    "marketplace_to_demand": 47.36540091,
    "grid_to_demand": 32089.50982,
    "govt_battery_charge": 43.03315304
  },
  {
    "timestamp": "16/07/24 22:00",
    "demand": 35296.64512,
    "solar_generated": 0,
    "wind_generated": 62.01583591,
    "marketplace_to_demand": 129.6746661,
    "grid_to_demand": 32142.27643,
    "govt_battery_charge": 99.27776799
  },
  {
    "timestamp": "16/07/24 23:00",
    "demand": 30610.58888,
    "solar_generated": 0,
    "wind_generated": 77.14492634,
    "marketplace_to_demand": 125.4597545,
    "grid_to_demand": 34081.729,
    "govt_battery_charge": 26.33907878
  },
  {
    "timestamp": "17/07/24 0:00",
    "demand": 30076.71227,
    "solar_generated": 0,
    "wind_generated": 77.69210506,
    "marketplace_to_demand": 21.38844891,
    "grid_to_demand": 30659.13338,
    "govt_battery_charge": 76.8786368
  },
  {
    "timestamp": "17/07/24 1:00",
    "demand": 34477.40709,
    "solar_generated": 0,
    "wind_generated": 56.83115538,
    "marketplace_to_demand": 99.33724497,
    "grid_to_demand": 29988.01084,
    "govt_battery_charge": 73.36292223
  },
  {
    "timestamp": "17/07/24 2:00",
    "demand": 31433.64577,
    "solar_generated": 0,
    "wind_generated": 75.34996166,
    "marketplace_to_demand": 99.54793967,
    "grid_to_demand": 30627.83036,
    "govt_battery_charge": 90.24473558
  },
  {
    "timestamp": "17/07/24 3:00",
    "demand": 33947.5687,
    "solar_generated": 0,
    "wind_generated": 71.07202093,
    "marketplace_to_demand": 150.6703314,
    "grid_to_demand": 30253.22534,
    "govt_battery_charge": 82.54521391
  },
  {
    "timestamp": "17/07/24 4:00",
    "demand": 34254.75393,
    "solar_generated": 0,
    "wind_generated": 70.7766114,
    "marketplace_to_demand": 196.5082487,
    "grid_to_demand": 35003.88015,
    "govt_battery_charge": 57.65089214
  },
  {
    "timestamp": "17/07/24 5:00",
    "demand": 35940.88638,
    "solar_generated": 0,
    "wind_generated": 73.98712595,
    "marketplace_to_demand": 69.20433558,
    "grid_to_demand": 30303.62125,
    "govt_battery_charge": 92.0799655
  },
  {
    "timestamp": "17/07/24 6:00",
    "demand": 31541.45045,
    "solar_generated": 23.39312499,
    "wind_generated": 67.40809727,
    "marketplace_to_demand": 166.1529305,
    "grid_to_demand": 36541.81409,
    "govt_battery_charge": 38.58026037
  },
  {
    "timestamp": "17/07/24 7:00",
    "demand": 31770.6085,
    "solar_generated": 14.4300606,
    "wind_generated": 75.95123222,
    "marketplace_to_demand": 61.47850513,
    "grid_to_demand": 31355.18297,
    "govt_battery_charge": 29.51554436
  },
  {
    "timestamp": "17/07/24 8:00",
    "demand": 36301.28085,
    "solar_generated": 19.2258591,
    "wind_generated": 65.16364165,
    "marketplace_to_demand": 25.53760514,
    "grid_to_demand": 34750.13575,
    "govt_battery_charge": 90.87603147
  },
  {
    "timestamp": "17/07/24 9:00",
    "demand": 32221.36786,
    "solar_generated": 8.510090981,
    "wind_generated": 79.99019589,
    "marketplace_to_demand": 76.80766037,
    "grid_to_demand": 32112.368,
    "govt_battery_charge": 91.00649849
  },
  {
    "timestamp": "17/07/24 10:00",
    "demand": 34204.13623,
    "solar_generated": 21.46849267,
    "wind_generated": 73.03363383,
    "marketplace_to_demand": 99.47569814,
    "grid_to_demand": 36332.86826,
    "govt_battery_charge": 55.1523639
  },
  {
    "timestamp": "17/07/24 11:00",
    "demand": 31639.83333,
    "solar_generated": 15.97086131,
    "wind_generated": 64.60320795,
    "marketplace_to_demand": 8.491549979,
    "grid_to_demand": 32224.89321,
    "govt_battery_charge": 92.69811565
  },
  {
    "timestamp": "17/07/24 12:00",
    "demand": 36370.86658,
    "solar_generated": 18.95822781,
    "wind_generated": 78.15855338,
    "marketplace_to_demand": 97.2709858,
    "grid_to_demand": 30107.88182,
    "govt_battery_charge": 1.344981111
  },
  {
    "timestamp": "17/07/24 13:00",
    "demand": 34761.9384,
    "solar_generated": 21.45311638,
    "wind_generated": 80.27634523,
    "marketplace_to_demand": 151.7789882,
    "grid_to_demand": 35581.59443,
    "govt_battery_charge": 99.67601561
  },
  {
    "timestamp": "17/07/24 14:00",
    "demand": 31886.03261,
    "solar_generated": 10.91517943,
    "wind_generated": 74.95765434,
    "marketplace_to_demand": 137.7776169,
    "grid_to_demand": 36247.96013,
    "govt_battery_charge": 64.96935767
  },
  {
    "timestamp": "17/07/24 15:00",
    "demand": 35530.12679,
    "solar_generated": 22.8183814,
    "wind_generated": 65.49224799,
    "marketplace_to_demand": 107.8398397,
    "grid_to_demand": 30038.39926,
    "govt_battery_charge": 82.75680094
  },
  {
    "timestamp": "17/07/24 16:00",
    "demand": 34325.81956,
    "solar_generated": 8.244630613,
    "wind_generated": 61.13811713,
    "marketplace_to_demand": 18.52854838,
    "grid_to_demand": 36288.57738,
    "govt_battery_charge": 43.06326526
  },
  {
    "timestamp": "17/07/24 17:00",
    "demand": 32123.96161,
    "solar_generated": 13.74388817,
    "wind_generated": 69.39806362,
    "marketplace_to_demand": 183.9046286,
    "grid_to_demand": 36175.33173,
    "govt_battery_charge": 94.21163593
  },
  {
    "timestamp": "17/07/24 18:00",
    "demand": 35368.58005,
    "solar_generated": 0,
    "wind_generated": 62.70415233,
    "marketplace_to_demand": 76.29970985,
    "grid_to_demand": 31950.6508,
    "govt_battery_charge": 15.19880335
  },
  {
    "timestamp": "17/07/24 19:00",
    "demand": 32786.37495,
    "solar_generated": 0,
    "wind_generated": 74.29323194,
    "marketplace_to_demand": 105.1060474,
    "grid_to_demand": 30919.97383,
    "govt_battery_charge": 76.9775826
  },
  {
    "timestamp": "17/07/24 20:00",
    "demand": 36589.82482,
    "solar_generated": 0,
    "wind_generated": 77.03110077,
    "marketplace_to_demand": 107.5300192,
    "grid_to_demand": 36070.67832,
    "govt_battery_charge": 92.90926136
  },
  {
    "timestamp": "17/07/24 21:00",
    "demand": 31955.94093,
    "solar_generated": 0,
    "wind_generated": 63.17394301,
    "marketplace_to_demand": 65.04821707,
    "grid_to_demand": 35363.853,
    "govt_battery_charge": 70.44206509
  },
  {
    "timestamp": "17/07/24 22:00",
    "demand": 32740.26205,
    "solar_generated": 0,
    "wind_generated": 71.06417936,
    "marketplace_to_demand": 191.5780452,
    "grid_to_demand": 35056.86515,
    "govt_battery_charge": 30.12200281
  },
  {
    "timestamp": "17/07/24 23:00",
    "demand": 36549.76107,
    "solar_generated": 0,
    "wind_generated": 78.93136326,
    "marketplace_to_demand": 97.04465994,
    "grid_to_demand": 32274.66184,
    "govt_battery_charge": 3.802478324
  },
  {
    "timestamp": "18/07/24 0:00",
    "demand": 35172.48787,
    "solar_generated": 0,
    "wind_generated": 74.35896349,
    "marketplace_to_demand": 63.38852069,
    "grid_to_demand": 36135.8308,
    "govt_battery_charge": 26.05279541
  },
  {
    "timestamp": "18/07/24 1:00",
    "demand": 31368.29199,
    "solar_generated": 0,
    "wind_generated": 71.33187756,
    "marketplace_to_demand": 53.52264702,
    "grid_to_demand": 35615.39935,
    "govt_battery_charge": 36.28410655
  },
  {
    "timestamp": "18/07/24 2:00",
    "demand": 34723.37983,
    "solar_generated": 0,
    "wind_generated": 71.93130765,
    "marketplace_to_demand": 29.20163154,
    "grid_to_demand": 33617.32672,
    "govt_battery_charge": 78.67221302
  },
  {
    "timestamp": "18/07/24 3:00",
    "demand": 30693.82649,
    "solar_generated": 0,
    "wind_generated": 63.13719099,
    "marketplace_to_demand": 15.31333723,
    "grid_to_demand": 34729.74413,
    "govt_battery_charge": 23.02156706
  },
  {
    "timestamp": "18/07/24 4:00",
    "demand": 30282.56837,
    "solar_generated": 0,
    "wind_generated": 54.01115016,
    "marketplace_to_demand": 124.9300271,
    "grid_to_demand": 30215.50252,
    "govt_battery_charge": 57.35710703
  },
  {
    "timestamp": "18/07/24 5:00",
    "demand": 32416.29616,
    "solar_generated": 0,
    "wind_generated": 78.75000592,
    "marketplace_to_demand": 85.15589388,
    "grid_to_demand": 32759.58719,
    "govt_battery_charge": 39.65683576
  },
  {
    "timestamp": "18/07/24 6:00",
    "demand": 34352.42077,
    "solar_generated": 15.36986165,
    "wind_generated": 77.17837919,
    "marketplace_to_demand": 112.4391814,
    "grid_to_demand": 31755.69445,
    "govt_battery_charge": 43.96042692
  },
  {
    "timestamp": "18/07/24 7:00",
    "demand": 31360.99007,
    "solar_generated": 15.23121791,
    "wind_generated": 61.73863373,
    "marketplace_to_demand": 63.22551337,
    "grid_to_demand": 34390.20794,
    "govt_battery_charge": 60.93128775
  },
  {
    "timestamp": "18/07/24 8:00",
    "demand": 30670.46744,
    "solar_generated": 12.8594644,
    "wind_generated": 62.93357175,
    "marketplace_to_demand": 164.7613361,
    "grid_to_demand": 36027.82986,
    "govt_battery_charge": 38.16509051
  },
  {
    "timestamp": "18/07/24 9:00",
    "demand": 33353.54517,
    "solar_generated": 23.70839979,
    "wind_generated": 72.04263148,
    "marketplace_to_demand": 2.359512992,
    "grid_to_demand": 31634.05648,
    "govt_battery_charge": 7.286614705
  },
  {
    "timestamp": "18/07/24 10:00",
    "demand": 34682.82451,
    "solar_generated": 16.56087686,
    "wind_generated": 78.37206833,
    "marketplace_to_demand": 157.781096,
    "grid_to_demand": 35245.86306,
    "govt_battery_charge": 24.99989752
  },
  {
    "timestamp": "18/07/24 11:00",
    "demand": 34816.11803,
    "solar_generated": 11.46031228,
    "wind_generated": 68.69791277,
    "marketplace_to_demand": 137.8457981,
    "grid_to_demand": 31048.5871,
    "govt_battery_charge": 94.35261276
  },
  {
    "timestamp": "18/07/24 12:00",
    "demand": 33690.83775,
    "solar_generated": 12.01327284,
    "wind_generated": 71.39423111,
    "marketplace_to_demand": 168.4804118,
    "grid_to_demand": 29979.17225,
    "govt_battery_charge": 6.543130819
  },
  {
    "timestamp": "18/07/24 13:00",
    "demand": 35972.28043,
    "solar_generated": 22.21954979,
    "wind_generated": 67.87275735,
    "marketplace_to_demand": 196.5919747,
    "grid_to_demand": 35944.6442,
    "govt_battery_charge": 50.10972434
  },
  {
    "timestamp": "18/07/24 14:00",
    "demand": 30648.93256,
    "solar_generated": 18.83978221,
    "wind_generated": 75.07197856,
    "marketplace_to_demand": 172.9869914,
    "grid_to_demand": 31561.76184,
    "govt_battery_charge": 89.88853036
  },
  {
    "timestamp": "18/07/24 15:00",
    "demand": 32227.71589,
    "solar_generated": 19.4956355,
    "wind_generated": 67.62930733,
    "marketplace_to_demand": 100.7425056,
    "grid_to_demand": 31666.76748,
    "govt_battery_charge": 52.04964291
  },
  {
    "timestamp": "18/07/24 16:00",
    "demand": 30155.34643,
    "solar_generated": 18.38212576,
    "wind_generated": 68.60887232,
    "marketplace_to_demand": 187.383517,
    "grid_to_demand": 31310.07754,
    "govt_battery_charge": 71.24265152
  },
  {
    "timestamp": "18/07/24 17:00",
    "demand": 36278.61192,
    "solar_generated": 10.24249366,
    "wind_generated": 58.30936254,
    "marketplace_to_demand": 69.89600023,
    "grid_to_demand": 32899.88923,
    "govt_battery_charge": 19.38682865
  },
  {
    "timestamp": "18/07/24 18:00",
    "demand": 32790.54587,
    "solar_generated": 0,
    "wind_generated": 76.20956219,
    "marketplace_to_demand": 8.003675454,
    "grid_to_demand": 31331.19041,
    "govt_battery_charge": 39.65317497
  },
  {
    "timestamp": "18/07/24 19:00",
    "demand": 30540.6102,
    "solar_generated": 0,
    "wind_generated": 63.58747835,
    "marketplace_to_demand": 192.5747508,
    "grid_to_demand": 29917.47194,
    "govt_battery_charge": 89.49162683
  },
  {
    "timestamp": "18/07/24 20:00",
    "demand": 33946.57053,
    "solar_generated": 0,
    "wind_generated": 65.41018349,
    "marketplace_to_demand": 124.8724502,
    "grid_to_demand": 32139.17845,
    "govt_battery_charge": 63.0356924
  },
  {
    "timestamp": "18/07/24 21:00",
    "demand": 34702.39763,
    "solar_generated": 0,
    "wind_generated": 55.14964924,
    "marketplace_to_demand": 122.2732434,
    "grid_to_demand": 31328.719,
    "govt_battery_charge": 13.25247147
  },
  {
    "timestamp": "18/07/24 22:00",
    "demand": 30023.24799,
    "solar_generated": 0,
    "wind_generated": 75.86008174,
    "marketplace_to_demand": 12.45015775,
    "grid_to_demand": 34986.94892,
    "govt_battery_charge": 78.20823308
  },
  {
    "timestamp": "18/07/24 23:00",
    "demand": 31300.28989,
    "solar_generated": 0,
    "wind_generated": 76.38282414,
    "marketplace_to_demand": 37.89804409,
    "grid_to_demand": 32321.48837,
    "govt_battery_charge": 91.33970748
  },
  {
    "timestamp": "19/07/24 0:00",
    "demand": 30275.51521,
    "solar_generated": 0,
    "wind_generated": 55.03046182,
    "marketplace_to_demand": 162.9317468,
    "grid_to_demand": 32634.24672,
    "govt_battery_charge": 5.516834089
  },
  {
    "timestamp": "19/07/24 1:00",
    "demand": 31988.66089,
    "solar_generated": 0,
    "wind_generated": 65.99822056,
    "marketplace_to_demand": 147.6019942,
    "grid_to_demand": 35107.38529,
    "govt_battery_charge": 24.30171265
  },
  {
    "timestamp": "19/07/24 2:00",
    "demand": 30283.17705,
    "solar_generated": 0,
    "wind_generated": 59.97375575,
    "marketplace_to_demand": 104.7168635,
    "grid_to_demand": 31164.1096,
    "govt_battery_charge": 81.79145375
  },
  {
    "timestamp": "19/07/24 3:00",
    "demand": 33935.60366,
    "solar_generated": 0,
    "wind_generated": 66.34553766,
    "marketplace_to_demand": 58.71604869,
    "grid_to_demand": 35468.89473,
    "govt_battery_charge": 39.52075658
  },
  {
    "timestamp": "19/07/24 4:00",
    "demand": 31554.20226,
    "solar_generated": 0,
    "wind_generated": 70.51316995,
    "marketplace_to_demand": 141.6437494,
    "grid_to_demand": 30802.33755,
    "govt_battery_charge": 2.146527574
  },
  {
    "timestamp": "19/07/24 5:00",
    "demand": 36038.51576,
    "solar_generated": 0,
    "wind_generated": 77.67735835,
    "marketplace_to_demand": 104.9334269,
    "grid_to_demand": 36229.57605,
    "govt_battery_charge": 1.600525498
  },
  {
    "timestamp": "19/07/24 6:00",
    "demand": 35877.54871,
    "solar_generated": 11.1859659,
    "wind_generated": 72.35061002,
    "marketplace_to_demand": 26.32886564,
    "grid_to_demand": 34960.04849,
    "govt_battery_charge": 22.34241071
  },
  {
    "timestamp": "19/07/24 7:00",
    "demand": 31133.74699,
    "solar_generated": 18.85146289,
    "wind_generated": 59.48124189,
    "marketplace_to_demand": 112.6862572,
    "grid_to_demand": 32499.88406,
    "govt_battery_charge": 83.364254
  },
  {
    "timestamp": "19/07/24 8:00",
    "demand": 32963.42176,
    "solar_generated": 21.13846352,
    "wind_generated": 67.5326324,
    "marketplace_to_demand": 54.60312262,
    "grid_to_demand": 31257.98453,
    "govt_battery_charge": 77.78129068
  },
  {
    "timestamp": "19/07/24 9:00",
    "demand": 31949.23288,
    "solar_generated": 18.31198635,
    "wind_generated": 67.74199927,
    "marketplace_to_demand": 75.09423562,
    "grid_to_demand": 34644.34875,
    "govt_battery_charge": 52.01169722
  },
  {
    "timestamp": "19/07/24 10:00",
    "demand": 30137.84441,
    "solar_generated": 15.03978903,
    "wind_generated": 65.24586456,
    "marketplace_to_demand": 83.65007943,
    "grid_to_demand": 35591.65304,
    "govt_battery_charge": 67.36899088
  },
  {
    "timestamp": "19/07/24 11:00",
    "demand": 33230.22932,
    "solar_generated": 12.02107566,
    "wind_generated": 64.17289767,
    "marketplace_to_demand": 62.10173108,
    "grid_to_demand": 30426.35015,
    "govt_battery_charge": 15.74990351
  },
  {
    "timestamp": "19/07/24 12:00",
    "demand": 30630.50523,
    "solar_generated": 14.85920361,
    "wind_generated": 58.18194825,
    "marketplace_to_demand": 175.1378983,
    "grid_to_demand": 34177.14985,
    "govt_battery_charge": 96.1615916
  },
  {
    "timestamp": "19/07/24 13:00",
    "demand": 31843.09005,
    "solar_generated": 16.20653916,
    "wind_generated": 80.10444006,
    "marketplace_to_demand": 178.923908,
    "grid_to_demand": 33661.52153,
    "govt_battery_charge": 94.43755611
  },
  {
    "timestamp": "19/07/24 14:00",
    "demand": 32974.83192,
    "solar_generated": 9.748849541,
    "wind_generated": 70.90376145,
    "marketplace_to_demand": 170.0533324,
    "grid_to_demand": 35821.80752,
    "govt_battery_charge": 5.865050628
  },
  {
    "timestamp": "19/07/24 15:00",
    "demand": 30936.85529,
    "solar_generated": 12.06172262,
    "wind_generated": 63.74346996,
    "marketplace_to_demand": 100.870066,
    "grid_to_demand": 32606.82753,
    "govt_battery_charge": 17.21228924
  },
  {
    "timestamp": "19/07/24 16:00",
    "demand": 35384.53264,
    "solar_generated": 10.33026649,
    "wind_generated": 71.97358149,
    "marketplace_to_demand": 109.2709048,
    "grid_to_demand": 36044.97897,
    "govt_battery_charge": 98.36046488
  },
  {
    "timestamp": "19/07/24 17:00",
    "demand": 35245.75362,
    "solar_generated": 15.74347198,
    "wind_generated": 74.0119971,
    "marketplace_to_demand": 71.65455072,
    "grid_to_demand": 33471.4924,
    "govt_battery_charge": 84.15073272
  },
  {
    "timestamp": "19/07/24 18:00",
    "demand": 30604.18275,
    "solar_generated": 0,
    "wind_generated": 75.75936324,
    "marketplace_to_demand": 177.1580422,
    "grid_to_demand": 32824.44803,
    "govt_battery_charge": 52.86070522
  },
  {
    "timestamp": "19/07/24 19:00",
    "demand": 32058.15117,
    "solar_generated": 0,
    "wind_generated": 80.34389447,
    "marketplace_to_demand": 113.505307,
    "grid_to_demand": 32844.20257,
    "govt_battery_charge": 21.49268151
  },
  {
    "timestamp": "19/07/24 20:00",
    "demand": 32011.40386,
    "solar_generated": 0,
    "wind_generated": 60.25044989,
    "marketplace_to_demand": 49.1086318,
    "grid_to_demand": 31606.24592,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/07/24 21:00",
    "demand": 31886.20151,
    "solar_generated": 0,
    "wind_generated": 79.22992395,
    "marketplace_to_demand": 189.860085,
    "grid_to_demand": 32901.38464,
    "govt_battery_charge": 72.1622465
  },
  {
    "timestamp": "19/07/24 22:00",
    "demand": 35777.57931,
    "solar_generated": 0,
    "wind_generated": 57.82890257,
    "marketplace_to_demand": 170.7147361,
    "grid_to_demand": 36164.24425,
    "govt_battery_charge": 82.98808194
  },
  {
    "timestamp": "19/07/24 23:00",
    "demand": 34409.02459,
    "solar_generated": 0,
    "wind_generated": 70.62240805,
    "marketplace_to_demand": 0.627733175,
    "grid_to_demand": 35913.7139,
    "govt_battery_charge": 7.680848551
  },
  {
    "timestamp": "20/07/24 0:00",
    "demand": 33202.22011,
    "solar_generated": 0,
    "wind_generated": 65.79463914,
    "marketplace_to_demand": 39.22916839,
    "grid_to_demand": 36095.54919,
    "govt_battery_charge": 81.00979449
  },
  {
    "timestamp": "20/07/24 1:00",
    "demand": 30587.55633,
    "solar_generated": 0,
    "wind_generated": 72.9412367,
    "marketplace_to_demand": 19.38789153,
    "grid_to_demand": 32880.23328,
    "govt_battery_charge": 89.07245396
  },
  {
    "timestamp": "20/07/24 2:00",
    "demand": 32944.75145,
    "solar_generated": 0,
    "wind_generated": 61.20438284,
    "marketplace_to_demand": 36.91963143,
    "grid_to_demand": 30831.87599,
    "govt_battery_charge": 86.55935678
  },
  {
    "timestamp": "20/07/24 3:00",
    "demand": 35445.92801,
    "solar_generated": 0,
    "wind_generated": 78.37347882,
    "marketplace_to_demand": 127.3479577,
    "grid_to_demand": 30202.47273,
    "govt_battery_charge": 26.67025566
  },
  {
    "timestamp": "20/07/24 4:00",
    "demand": 34970.52901,
    "solar_generated": 0,
    "wind_generated": 58.7288391,
    "marketplace_to_demand": 140.2224584,
    "grid_to_demand": 31754.19717,
    "govt_battery_charge": 63.55830492
  },
  {
    "timestamp": "20/07/24 5:00",
    "demand": 31816.32542,
    "solar_generated": 0,
    "wind_generated": 61.65797065,
    "marketplace_to_demand": 61.55696625,
    "grid_to_demand": 31250.8723,
    "govt_battery_charge": 93.84131424
  },
  {
    "timestamp": "20/07/24 6:00",
    "demand": 35603.62968,
    "solar_generated": 8.617535785,
    "wind_generated": 70.3151725,
    "marketplace_to_demand": 88.35658334,
    "grid_to_demand": 34886.38942,
    "govt_battery_charge": 1.540402211
  },
  {
    "timestamp": "20/07/24 7:00",
    "demand": 30815.65846,
    "solar_generated": 11.79357435,
    "wind_generated": 78.56621413,
    "marketplace_to_demand": 178.9735829,
    "grid_to_demand": 33903.66954,
    "govt_battery_charge": 16.45251657
  },
  {
    "timestamp": "20/07/24 8:00",
    "demand": 32182.23507,
    "solar_generated": 13.20033609,
    "wind_generated": 68.37261617,
    "marketplace_to_demand": 105.7971718,
    "grid_to_demand": 32675.52329,
    "govt_battery_charge": 89.80520464
  },
  {
    "timestamp": "20/07/24 9:00",
    "demand": 34013.14589,
    "solar_generated": 10.17104474,
    "wind_generated": 78.36507019,
    "marketplace_to_demand": 174.4907875,
    "grid_to_demand": 34176.66078,
    "govt_battery_charge": 53.83997513
  },
  {
    "timestamp": "20/07/24 10:00",
    "demand": 30689.53732,
    "solar_generated": 23.83931998,
    "wind_generated": 79.94352462,
    "marketplace_to_demand": 161.8352536,
    "grid_to_demand": 34799.72723,
    "govt_battery_charge": 62.66318547
  },
  {
    "timestamp": "20/07/24 11:00",
    "demand": 34437.11356,
    "solar_generated": 8.634867091,
    "wind_generated": 76.37566639,
    "marketplace_to_demand": 31.56203202,
    "grid_to_demand": 32669.63695,
    "govt_battery_charge": 19.7741288
  },
  {
    "timestamp": "20/07/24 12:00",
    "demand": 33276.66426,
    "solar_generated": 15.40327495,
    "wind_generated": 79.04109507,
    "marketplace_to_demand": 127.7628869,
    "grid_to_demand": 33153.67913,
    "govt_battery_charge": 61.67684341
  },
  {
    "timestamp": "20/07/24 13:00",
    "demand": 36323.45217,
    "solar_generated": 14.73721381,
    "wind_generated": 57.31030252,
    "marketplace_to_demand": 0.931143249,
    "grid_to_demand": 31363.63197,
    "govt_battery_charge": 8.066302189
  },
  {
    "timestamp": "20/07/24 14:00",
    "demand": 33305.48692,
    "solar_generated": 22.34679698,
    "wind_generated": 78.7950175,
    "marketplace_to_demand": 189.890276,
    "grid_to_demand": 36051.01828,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "20/07/24 15:00",
    "demand": 35642.20479,
    "solar_generated": 14.33732443,
    "wind_generated": 58.21595659,
    "marketplace_to_demand": 128.6800846,
    "grid_to_demand": 35536.28586,
    "govt_battery_charge": 70.07015183
  },
  {
    "timestamp": "20/07/24 16:00",
    "demand": 30999.10364,
    "solar_generated": 18.31835533,
    "wind_generated": 64.78038745,
    "marketplace_to_demand": 67.81526797,
    "grid_to_demand": 34271.33274,
    "govt_battery_charge": 18.79051471
  },
  {
    "timestamp": "20/07/24 17:00",
    "demand": 32636.42027,
    "solar_generated": 18.94216051,
    "wind_generated": 75.93041134,
    "marketplace_to_demand": 77.44961805,
    "grid_to_demand": 32707.30469,
    "govt_battery_charge": 18.52948032
  },
  {
    "timestamp": "20/07/24 18:00",
    "demand": 34232.01507,
    "solar_generated": 0,
    "wind_generated": 64.42338437,
    "marketplace_to_demand": 72.92493992,
    "grid_to_demand": 36138.69314,
    "govt_battery_charge": 17.46302992
  },
  {
    "timestamp": "20/07/24 19:00",
    "demand": 34626.98372,
    "solar_generated": 0,
    "wind_generated": 60.03820566,
    "marketplace_to_demand": 164.5970301,
    "grid_to_demand": 31532.72534,
    "govt_battery_charge": 24.43564467
  },
  {
    "timestamp": "20/07/24 20:00",
    "demand": 35342.24731,
    "solar_generated": 0,
    "wind_generated": 55.90683647,
    "marketplace_to_demand": 113.2682157,
    "grid_to_demand": 31478.46395,
    "govt_battery_charge": 70.31746559
  },
  {
    "timestamp": "20/07/24 21:00",
    "demand": 36500.53968,
    "solar_generated": 0,
    "wind_generated": 75.27702818,
    "marketplace_to_demand": 144.8118632,
    "grid_to_demand": 34124.99509,
    "govt_battery_charge": 34.89987809
  },
  {
    "timestamp": "20/07/24 22:00",
    "demand": 31874.2869,
    "solar_generated": 0,
    "wind_generated": 75.88476356,
    "marketplace_to_demand": 149.0941715,
    "grid_to_demand": 32901.45972,
    "govt_battery_charge": 17.99379123
  },
  {
    "timestamp": "20/07/24 23:00",
    "demand": 30331.01703,
    "solar_generated": 0,
    "wind_generated": 54.11009309,
    "marketplace_to_demand": 49.50555957,
    "grid_to_demand": 35717.50629,
    "govt_battery_charge": 76.93880913
  },
  {
    "timestamp": "21/07/24 0:00",
    "demand": 35254.32168,
    "solar_generated": 0,
    "wind_generated": 67.51057761,
    "marketplace_to_demand": 39.49483616,
    "grid_to_demand": 33151.06539,
    "govt_battery_charge": 11.98128059
  },
  {
    "timestamp": "21/07/24 1:00",
    "demand": 35266.5235,
    "solar_generated": 0,
    "wind_generated": 74.80502484,
    "marketplace_to_demand": 121.939371,
    "grid_to_demand": 36117.71649,
    "govt_battery_charge": 29.16912327
  },
  {
    "timestamp": "21/07/24 2:00",
    "demand": 30471.54241,
    "solar_generated": 0,
    "wind_generated": 66.16279865,
    "marketplace_to_demand": 14.2289909,
    "grid_to_demand": 31043.30301,
    "govt_battery_charge": 67.11174119
  },
  {
    "timestamp": "21/07/24 3:00",
    "demand": 30849.23215,
    "solar_generated": 0,
    "wind_generated": 57.27252599,
    "marketplace_to_demand": 72.31208616,
    "grid_to_demand": 34883.98414,
    "govt_battery_charge": 44.82276686
  },
  {
    "timestamp": "21/07/24 4:00",
    "demand": 36036.17092,
    "solar_generated": 0,
    "wind_generated": 60.43665126,
    "marketplace_to_demand": 6.697400103,
    "grid_to_demand": 31203.56707,
    "govt_battery_charge": 93.19713751
  },
  {
    "timestamp": "21/07/24 5:00",
    "demand": 31425.96955,
    "solar_generated": 0,
    "wind_generated": 60.16264418,
    "marketplace_to_demand": 136.4384831,
    "grid_to_demand": 31306.32625,
    "govt_battery_charge": 69.17729541
  },
  {
    "timestamp": "21/07/24 6:00",
    "demand": 33344.77645,
    "solar_generated": 9.950613265,
    "wind_generated": 73.69272323,
    "marketplace_to_demand": 150.8454506,
    "grid_to_demand": 32777.59975,
    "govt_battery_charge": 49.86946103
  },
  {
    "timestamp": "21/07/24 7:00",
    "demand": 33340.08624,
    "solar_generated": 9.314261132,
    "wind_generated": 75.10035921,
    "marketplace_to_demand": 100.7613463,
    "grid_to_demand": 30992.97637,
    "govt_battery_charge": 17.65725818
  },
  {
    "timestamp": "21/07/24 8:00",
    "demand": 33112.58092,
    "solar_generated": 17.55413084,
    "wind_generated": 59.53401966,
    "marketplace_to_demand": 154.1970506,
    "grid_to_demand": 34867.46143,
    "govt_battery_charge": 57.26343787
  },
  {
    "timestamp": "21/07/24 9:00",
    "demand": 36243.32451,
    "solar_generated": 14.05348698,
    "wind_generated": 66.21874504,
    "marketplace_to_demand": 184.2994899,
    "grid_to_demand": 35274.75975,
    "govt_battery_charge": 1.810972848
  },
  {
    "timestamp": "21/07/24 10:00",
    "demand": 35901.99445,
    "solar_generated": 22.74199266,
    "wind_generated": 59.00777452,
    "marketplace_to_demand": 59.45267115,
    "grid_to_demand": 33752.21944,
    "govt_battery_charge": 36.82780467
  },
  {
    "timestamp": "21/07/24 11:00",
    "demand": 32661.31683,
    "solar_generated": 10.42292837,
    "wind_generated": 63.49684107,
    "marketplace_to_demand": 39.90001881,
    "grid_to_demand": 36486.01467,
    "govt_battery_charge": 64.87936666
  },
  {
    "timestamp": "21/07/24 12:00",
    "demand": 34638.38654,
    "solar_generated": 15.92886757,
    "wind_generated": 72.77385566,
    "marketplace_to_demand": 99.09606035,
    "grid_to_demand": 30352.38207,
    "govt_battery_charge": 70.18162523
  },
  {
    "timestamp": "21/07/24 13:00",
    "demand": 33409.38689,
    "solar_generated": 18.84290682,
    "wind_generated": 59.02069118,
    "marketplace_to_demand": 95.79162389,
    "grid_to_demand": 31061.29072,
    "govt_battery_charge": 66.58076857
  },
  {
    "timestamp": "21/07/24 14:00",
    "demand": 30918.76104,
    "solar_generated": 13.86377334,
    "wind_generated": 53.82389469,
    "marketplace_to_demand": 162.7423748,
    "grid_to_demand": 35193.70064,
    "govt_battery_charge": 97.80004386
  },
  {
    "timestamp": "21/07/24 15:00",
    "demand": 33731.0664,
    "solar_generated": 22.71940181,
    "wind_generated": 77.94738453,
    "marketplace_to_demand": 23.43406669,
    "grid_to_demand": 34883.09287,
    "govt_battery_charge": 77.96936867
  },
  {
    "timestamp": "21/07/24 16:00",
    "demand": 34061.29589,
    "solar_generated": 16.80263607,
    "wind_generated": 55.9093668,
    "marketplace_to_demand": 149.0241317,
    "grid_to_demand": 32552.65396,
    "govt_battery_charge": 67.93409511
  },
  {
    "timestamp": "21/07/24 17:00",
    "demand": 35433.75534,
    "solar_generated": 18.10711035,
    "wind_generated": 69.05499794,
    "marketplace_to_demand": 161.3247501,
    "grid_to_demand": 35174.29811,
    "govt_battery_charge": 49.10579281
  },
  {
    "timestamp": "21/07/24 18:00",
    "demand": 31556.4781,
    "solar_generated": 0,
    "wind_generated": 72.53715779,
    "marketplace_to_demand": 134.2130891,
    "grid_to_demand": 29937.47478,
    "govt_battery_charge": 26.29307451
  },
  {
    "timestamp": "21/07/24 19:00",
    "demand": 30660.55819,
    "solar_generated": 0,
    "wind_generated": 59.72030296,
    "marketplace_to_demand": 134.1194568,
    "grid_to_demand": 35834.32648,
    "govt_battery_charge": 99.88021338
  },
  {
    "timestamp": "21/07/24 20:00",
    "demand": 34925.01317,
    "solar_generated": 0,
    "wind_generated": 68.61891786,
    "marketplace_to_demand": 140.3582135,
    "grid_to_demand": 30239.80759,
    "govt_battery_charge": 43.46213551
  },
  {
    "timestamp": "21/07/24 21:00",
    "demand": 33062.44231,
    "solar_generated": 0,
    "wind_generated": 74.10315961,
    "marketplace_to_demand": 76.2665815,
    "grid_to_demand": 36454.70099,
    "govt_battery_charge": 32.47076616
  },
  {
    "timestamp": "21/07/24 22:00",
    "demand": 31907.06298,
    "solar_generated": 0,
    "wind_generated": 53.66804843,
    "marketplace_to_demand": 38.43493981,
    "grid_to_demand": 30624.06553,
    "govt_battery_charge": 76.09825391
  },
  {
    "timestamp": "21/07/24 23:00",
    "demand": 33566.43842,
    "solar_generated": 0,
    "wind_generated": 61.96843714,
    "marketplace_to_demand": 117.4519169,
    "grid_to_demand": 34355.29066,
    "govt_battery_charge": 14.32875943
  },
  {
    "timestamp": "22/07/24 0:00",
    "demand": 35811.3445,
    "solar_generated": 0,
    "wind_generated": 66.91579716,
    "marketplace_to_demand": 88.18781245,
    "grid_to_demand": 36131.64007,
    "govt_battery_charge": 19.8431241
  },
  {
    "timestamp": "22/07/24 1:00",
    "demand": 35552.17055,
    "solar_generated": 0,
    "wind_generated": 57.55950085,
    "marketplace_to_demand": 26.15743419,
    "grid_to_demand": 34478.38322,
    "govt_battery_charge": 79.60909736
  },
  {
    "timestamp": "22/07/24 2:00",
    "demand": 34262.33984,
    "solar_generated": 0,
    "wind_generated": 55.66781786,
    "marketplace_to_demand": 53.95295098,
    "grid_to_demand": 33735.89839,
    "govt_battery_charge": 22.28700389
  },
  {
    "timestamp": "22/07/24 3:00",
    "demand": 32794.93873,
    "solar_generated": 0,
    "wind_generated": 66.30175669,
    "marketplace_to_demand": 143.522942,
    "grid_to_demand": 33723.94571,
    "govt_battery_charge": 16.50046987
  },
  {
    "timestamp": "22/07/24 4:00",
    "demand": 31790.11875,
    "solar_generated": 0,
    "wind_generated": 62.48225673,
    "marketplace_to_demand": 93.56067445,
    "grid_to_demand": 33512.16379,
    "govt_battery_charge": 36.39006597
  },
  {
    "timestamp": "22/07/24 5:00",
    "demand": 34213.65108,
    "solar_generated": 0,
    "wind_generated": 78.55922847,
    "marketplace_to_demand": 172.3105865,
    "grid_to_demand": 30580.6389,
    "govt_battery_charge": 18.4178064
  },
  {
    "timestamp": "22/07/24 6:00",
    "demand": 30087.67401,
    "solar_generated": 9.490487322,
    "wind_generated": 62.45046395,
    "marketplace_to_demand": 58.0351021,
    "grid_to_demand": 31835.69943,
    "govt_battery_charge": 68.14351925
  },
  {
    "timestamp": "22/07/24 7:00",
    "demand": 32536.21495,
    "solar_generated": 22.10237046,
    "wind_generated": 59.32762061,
    "marketplace_to_demand": 9.363941388,
    "grid_to_demand": 33752.22442,
    "govt_battery_charge": 21.54572253
  },
  {
    "timestamp": "22/07/24 8:00",
    "demand": 32471.56685,
    "solar_generated": 22.44476442,
    "wind_generated": 68.61663638,
    "marketplace_to_demand": 95.40339471,
    "grid_to_demand": 33663.88641,
    "govt_battery_charge": 90.93425528
  },
  {
    "timestamp": "22/07/24 9:00",
    "demand": 34887.3118,
    "solar_generated": 23.3837025,
    "wind_generated": 76.33930289,
    "marketplace_to_demand": 104.6694654,
    "grid_to_demand": 30063.85956,
    "govt_battery_charge": 10.2374334
  },
  {
    "timestamp": "22/07/24 10:00",
    "demand": 33547.01888,
    "solar_generated": 16.96367189,
    "wind_generated": 65.01076042,
    "marketplace_to_demand": 71.94795909,
    "grid_to_demand": 35700.29281,
    "govt_battery_charge": 90.61829472
  },
  {
    "timestamp": "22/07/24 11:00",
    "demand": 33017.48182,
    "solar_generated": 12.69053289,
    "wind_generated": 74.90220447,
    "marketplace_to_demand": 112.4098891,
    "grid_to_demand": 33058.40968,
    "govt_battery_charge": 9.026014207
  },
  {
    "timestamp": "22/07/24 12:00",
    "demand": 34353.64249,
    "solar_generated": 8.927186327,
    "wind_generated": 59.53083363,
    "marketplace_to_demand": 173.8021504,
    "grid_to_demand": 34037.57289,
    "govt_battery_charge": 13.25734652
  },
  {
    "timestamp": "22/07/24 13:00",
    "demand": 36336.77235,
    "solar_generated": 19.07770728,
    "wind_generated": 64.44113569,
    "marketplace_to_demand": 127.2962462,
    "grid_to_demand": 30008.06996,
    "govt_battery_charge": 51.35985862
  },
  {
    "timestamp": "22/07/24 14:00",
    "demand": 31939.3043,
    "solar_generated": 12.95544674,
    "wind_generated": 60.42474555,
    "marketplace_to_demand": 111.9975993,
    "grid_to_demand": 36128.335,
    "govt_battery_charge": 72.41474971
  },
  {
    "timestamp": "22/07/24 15:00",
    "demand": 32839.42242,
    "solar_generated": 9.545787975,
    "wind_generated": 69.89286435,
    "marketplace_to_demand": 116.1160957,
    "grid_to_demand": 34932.40872,
    "govt_battery_charge": 67.39528662
  },
  {
    "timestamp": "22/07/24 16:00",
    "demand": 34144.22764,
    "solar_generated": 16.86650381,
    "wind_generated": 65.61809537,
    "marketplace_to_demand": 68.65213109,
    "grid_to_demand": 33597.7461,
    "govt_battery_charge": 22.70164664
  },
  {
    "timestamp": "22/07/24 17:00",
    "demand": 31282.87554,
    "solar_generated": 10.71694873,
    "wind_generated": 56.60754849,
    "marketplace_to_demand": 68.96862751,
    "grid_to_demand": 31447.05781,
    "govt_battery_charge": 47.86367087
  },
  {
    "timestamp": "22/07/24 18:00",
    "demand": 33319.68197,
    "solar_generated": 0,
    "wind_generated": 75.53789809,
    "marketplace_to_demand": 62.916934,
    "grid_to_demand": 33857.65343,
    "govt_battery_charge": 50.66841342
  },
  {
    "timestamp": "22/07/24 19:00",
    "demand": 34862.86887,
    "solar_generated": 0,
    "wind_generated": 58.24044229,
    "marketplace_to_demand": 166.8844976,
    "grid_to_demand": 34071.75711,
    "govt_battery_charge": 87.76314167
  },
  {
    "timestamp": "22/07/24 20:00",
    "demand": 32681.65036,
    "solar_generated": 0,
    "wind_generated": 67.72853795,
    "marketplace_to_demand": 108.8688682,
    "grid_to_demand": 34727.3679,
    "govt_battery_charge": 6.445305595
  },
  {
    "timestamp": "22/07/24 21:00",
    "demand": 32183.91149,
    "solar_generated": 0,
    "wind_generated": 75.59681347,
    "marketplace_to_demand": 132.8887617,
    "grid_to_demand": 33262.55594,
    "govt_battery_charge": 18.74193983
  },
  {
    "timestamp": "22/07/24 22:00",
    "demand": 30634.3174,
    "solar_generated": 0,
    "wind_generated": 74.05707307,
    "marketplace_to_demand": 60.80191996,
    "grid_to_demand": 30170.81792,
    "govt_battery_charge": 87.03808127
  },
  {
    "timestamp": "22/07/24 23:00",
    "demand": 30879.71313,
    "solar_generated": 0,
    "wind_generated": 55.38725891,
    "marketplace_to_demand": 139.624835,
    "grid_to_demand": 34875.61353,
    "govt_battery_charge": 2.715274835
  },
  {
    "timestamp": "23/07/24 0:00",
    "demand": 34820.87276,
    "solar_generated": 0,
    "wind_generated": 70.03642241,
    "marketplace_to_demand": 131.8509695,
    "grid_to_demand": 30018.42779,
    "govt_battery_charge": 2.632774005
  },
  {
    "timestamp": "23/07/24 1:00",
    "demand": 33291.09943,
    "solar_generated": 0,
    "wind_generated": 71.8098533,
    "marketplace_to_demand": 59.46178097,
    "grid_to_demand": 31117.41651,
    "govt_battery_charge": 9.294937917
  },
  {
    "timestamp": "23/07/24 2:00",
    "demand": 33503.75559,
    "solar_generated": 0,
    "wind_generated": 69.51442991,
    "marketplace_to_demand": 53.1863189,
    "grid_to_demand": 35022.64382,
    "govt_battery_charge": 90.32830064
  },
  {
    "timestamp": "23/07/24 3:00",
    "demand": 34814.38496,
    "solar_generated": 0,
    "wind_generated": 70.20366503,
    "marketplace_to_demand": 150.8919053,
    "grid_to_demand": 33150.8111,
    "govt_battery_charge": 34.48294899
  },
  {
    "timestamp": "23/07/24 4:00",
    "demand": 35776.94635,
    "solar_generated": 0,
    "wind_generated": 60.60990087,
    "marketplace_to_demand": 153.8215571,
    "grid_to_demand": 35698.08924,
    "govt_battery_charge": 62.71413513
  },
  {
    "timestamp": "23/07/24 5:00",
    "demand": 36078.10365,
    "solar_generated": 0,
    "wind_generated": 55.04336558,
    "marketplace_to_demand": 12.50337146,
    "grid_to_demand": 35547.23498,
    "govt_battery_charge": 22.374502
  },
  {
    "timestamp": "23/07/24 6:00",
    "demand": 34937.20053,
    "solar_generated": 18.69983399,
    "wind_generated": 67.51712266,
    "marketplace_to_demand": 69.8734525,
    "grid_to_demand": 32661.91821,
    "govt_battery_charge": 33.69479322
  },
  {
    "timestamp": "23/07/24 7:00",
    "demand": 32100.78125,
    "solar_generated": 11.21694725,
    "wind_generated": 63.73942066,
    "marketplace_to_demand": 35.8724366,
    "grid_to_demand": 34121.36065,
    "govt_battery_charge": 56.78577044
  },
  {
    "timestamp": "23/07/24 8:00",
    "demand": 35855.85378,
    "solar_generated": 18.38795503,
    "wind_generated": 75.60912504,
    "marketplace_to_demand": 130.1309104,
    "grid_to_demand": 30620.62406,
    "govt_battery_charge": 16.92020297
  },
  {
    "timestamp": "23/07/24 9:00",
    "demand": 35281.05663,
    "solar_generated": 18.53394876,
    "wind_generated": 79.94076642,
    "marketplace_to_demand": 169.3253786,
    "grid_to_demand": 33277.02232,
    "govt_battery_charge": 35.81963451
  },
  {
    "timestamp": "23/07/24 10:00",
    "demand": 34604.3013,
    "solar_generated": 8.909823039,
    "wind_generated": 79.24307679,
    "marketplace_to_demand": 172.3542014,
    "grid_to_demand": 34407.23115,
    "govt_battery_charge": 19.30179071
  },
  {
    "timestamp": "23/07/24 11:00",
    "demand": 35439.59992,
    "solar_generated": 19.00329627,
    "wind_generated": 78.37858191,
    "marketplace_to_demand": 150.5807191,
    "grid_to_demand": 30855.18227,
    "govt_battery_charge": 4.916570201
  },
  {
    "timestamp": "23/07/24 12:00",
    "demand": 35353.04793,
    "solar_generated": 18.59707101,
    "wind_generated": 79.80215452,
    "marketplace_to_demand": 34.24569172,
    "grid_to_demand": 33128.49278,
    "govt_battery_charge": 66.22697474
  },
  {
    "timestamp": "23/07/24 13:00",
    "demand": 33624.44362,
    "solar_generated": 7.0365261,
    "wind_generated": 64.50426285,
    "marketplace_to_demand": 131.6696854,
    "grid_to_demand": 33893.05458,
    "govt_battery_charge": 34.11218532
  },
  {
    "timestamp": "23/07/24 14:00",
    "demand": 30299.93104,
    "solar_generated": 19.43758747,
    "wind_generated": 54.38483342,
    "marketplace_to_demand": 101.7026471,
    "grid_to_demand": 32539.14338,
    "govt_battery_charge": 37.55377848
  },
  {
    "timestamp": "23/07/24 15:00",
    "demand": 31457.81729,
    "solar_generated": 14.05950971,
    "wind_generated": 56.82160497,
    "marketplace_to_demand": 172.3036532,
    "grid_to_demand": 33756.63463,
    "govt_battery_charge": 98.02212233
  },
  {
    "timestamp": "23/07/24 16:00",
    "demand": 32644.04959,
    "solar_generated": 13.73667507,
    "wind_generated": 63.21426893,
    "marketplace_to_demand": 148.9240013,
    "grid_to_demand": 31166.33496,
    "govt_battery_charge": 17.55818526
  },
  {
    "timestamp": "23/07/24 17:00",
    "demand": 35896.17656,
    "solar_generated": 19.0077375,
    "wind_generated": 63.62136449,
    "marketplace_to_demand": 57.77730834,
    "grid_to_demand": 32931.59617,
    "govt_battery_charge": 95.12125796
  },
  {
    "timestamp": "23/07/24 18:00",
    "demand": 31062.91638,
    "solar_generated": 0,
    "wind_generated": 78.98686096,
    "marketplace_to_demand": 70.07767935,
    "grid_to_demand": 30467.97684,
    "govt_battery_charge": 61.4637862
  },
  {
    "timestamp": "23/07/24 19:00",
    "demand": 33702.19473,
    "solar_generated": 0,
    "wind_generated": 74.67443467,
    "marketplace_to_demand": 108.0688764,
    "grid_to_demand": 30439.88898,
    "govt_battery_charge": 47.45022893
  },
  {
    "timestamp": "23/07/24 20:00",
    "demand": 34388.48585,
    "solar_generated": 0,
    "wind_generated": 56.78974426,
    "marketplace_to_demand": 81.50856782,
    "grid_to_demand": 31836.63812,
    "govt_battery_charge": 97.32888585
  },
  {
    "timestamp": "23/07/24 21:00",
    "demand": 35712.81482,
    "solar_generated": 0,
    "wind_generated": 69.30642003,
    "marketplace_to_demand": 11.73675626,
    "grid_to_demand": 33603.9519,
    "govt_battery_charge": 36.65394804
  },
  {
    "timestamp": "23/07/24 22:00",
    "demand": 35134.78836,
    "solar_generated": 0,
    "wind_generated": 53.79898297,
    "marketplace_to_demand": 86.83303848,
    "grid_to_demand": 33925.06836,
    "govt_battery_charge": 47.50830266
  },
  {
    "timestamp": "23/07/24 23:00",
    "demand": 33227.16881,
    "solar_generated": 0,
    "wind_generated": 55.48974317,
    "marketplace_to_demand": 179.89893,
    "grid_to_demand": 34047.53496,
    "govt_battery_charge": 93.76800339
  },
  {
    "timestamp": "24/07/24 0:00",
    "demand": 35254.18034,
    "solar_generated": 0,
    "wind_generated": 60.71317881,
    "marketplace_to_demand": 133.6472455,
    "grid_to_demand": 33431.22784,
    "govt_battery_charge": 24.89259232
  },
  {
    "timestamp": "24/07/24 1:00",
    "demand": 30535.25292,
    "solar_generated": 0,
    "wind_generated": 77.96322944,
    "marketplace_to_demand": 132.554258,
    "grid_to_demand": 36272.87839,
    "govt_battery_charge": 53.77427151
  },
  {
    "timestamp": "24/07/24 2:00",
    "demand": 33878.73092,
    "solar_generated": 0,
    "wind_generated": 64.52694887,
    "marketplace_to_demand": 60.19823544,
    "grid_to_demand": 35995.93398,
    "govt_battery_charge": 70.21386967
  },
  {
    "timestamp": "24/07/24 3:00",
    "demand": 31142.40323,
    "solar_generated": 0,
    "wind_generated": 79.52466102,
    "marketplace_to_demand": 161.2300193,
    "grid_to_demand": 30199.96384,
    "govt_battery_charge": 13.46101185
  },
  {
    "timestamp": "24/07/24 4:00",
    "demand": 33791.76422,
    "solar_generated": 0,
    "wind_generated": 56.3201142,
    "marketplace_to_demand": 183.4219087,
    "grid_to_demand": 30510.77798,
    "govt_battery_charge": 7.356846362
  },
  {
    "timestamp": "24/07/24 5:00",
    "demand": 34803.41033,
    "solar_generated": 0,
    "wind_generated": 65.41468827,
    "marketplace_to_demand": 45.38824849,
    "grid_to_demand": 31517.51067,
    "govt_battery_charge": 8.185766213
  },
  {
    "timestamp": "24/07/24 6:00",
    "demand": 30922.18687,
    "solar_generated": 22.10997682,
    "wind_generated": 65.72327933,
    "marketplace_to_demand": 93.6550436,
    "grid_to_demand": 30125.47972,
    "govt_battery_charge": 7.842449551
  },
  {
    "timestamp": "24/07/24 7:00",
    "demand": 34325.06411,
    "solar_generated": 23.48315283,
    "wind_generated": 72.09089527,
    "marketplace_to_demand": 159.8015299,
    "grid_to_demand": 35561.43285,
    "govt_battery_charge": 77.30676759
  },
  {
    "timestamp": "24/07/24 8:00",
    "demand": 34086.05293,
    "solar_generated": 16.37199849,
    "wind_generated": 77.84840592,
    "marketplace_to_demand": 20.01802731,
    "grid_to_demand": 32940.66052,
    "govt_battery_charge": 9.306900604
  },
  {
    "timestamp": "24/07/24 9:00",
    "demand": 35456.96463,
    "solar_generated": 20.78309884,
    "wind_generated": 68.39873344,
    "marketplace_to_demand": 107.6913243,
    "grid_to_demand": 31115.88425,
    "govt_battery_charge": 43.3667155
  },
  {
    "timestamp": "24/07/24 10:00",
    "demand": 34958.33061,
    "solar_generated": 23.67591164,
    "wind_generated": 59.09380501,
    "marketplace_to_demand": 26.79613094,
    "grid_to_demand": 29927.72261,
    "govt_battery_charge": 92.09755882
  },
  {
    "timestamp": "24/07/24 11:00",
    "demand": 31120.29602,
    "solar_generated": 9.156438633,
    "wind_generated": 64.27601929,
    "marketplace_to_demand": 199.5235623,
    "grid_to_demand": 33431.63162,
    "govt_battery_charge": 27.29327872
  },
  {
    "timestamp": "24/07/24 12:00",
    "demand": 36484.80308,
    "solar_generated": 7.87127929,
    "wind_generated": 76.34300123,
    "marketplace_to_demand": 59.91557632,
    "grid_to_demand": 33759.57919,
    "govt_battery_charge": 24.6941686
  },
  {
    "timestamp": "24/07/24 13:00",
    "demand": 31262.20863,
    "solar_generated": 22.57985538,
    "wind_generated": 67.04775057,
    "marketplace_to_demand": 24.58621269,
    "grid_to_demand": 36220.94919,
    "govt_battery_charge": 39.02803491
  },
  {
    "timestamp": "24/07/24 14:00",
    "demand": 36383.00573,
    "solar_generated": 10.35517067,
    "wind_generated": 78.17520684,
    "marketplace_to_demand": 93.37203963,
    "grid_to_demand": 31873.76404,
    "govt_battery_charge": 56.73112559
  },
  {
    "timestamp": "24/07/24 15:00",
    "demand": 29975.17886,
    "solar_generated": 20.05667804,
    "wind_generated": 58.16609358,
    "marketplace_to_demand": 197.8033923,
    "grid_to_demand": 35814.78464,
    "govt_battery_charge": 88.88364423
  },
  {
    "timestamp": "24/07/24 16:00",
    "demand": 35788.34998,
    "solar_generated": 16.55168659,
    "wind_generated": 55.7601543,
    "marketplace_to_demand": 6.696979745,
    "grid_to_demand": 34411.21136,
    "govt_battery_charge": 86.26701953
  },
  {
    "timestamp": "24/07/24 17:00",
    "demand": 33566.90305,
    "solar_generated": 22.1918244,
    "wind_generated": 70.29926596,
    "marketplace_to_demand": 134.7476519,
    "grid_to_demand": 31751.52151,
    "govt_battery_charge": 44.78808332
  },
  {
    "timestamp": "24/07/24 18:00",
    "demand": 31697.50338,
    "solar_generated": 0,
    "wind_generated": 59.51131714,
    "marketplace_to_demand": 120.2460773,
    "grid_to_demand": 34623.65752,
    "govt_battery_charge": 84.77230783
  },
  {
    "timestamp": "24/07/24 19:00",
    "demand": 31246.38527,
    "solar_generated": 0,
    "wind_generated": 55.5203751,
    "marketplace_to_demand": 116.9150927,
    "grid_to_demand": 31141.24586,
    "govt_battery_charge": 92.79254598
  },
  {
    "timestamp": "24/07/24 20:00",
    "demand": 35886.59902,
    "solar_generated": 0,
    "wind_generated": 64.21117046,
    "marketplace_to_demand": 41.32725356,
    "grid_to_demand": 33773.7367,
    "govt_battery_charge": 75.43611845
  },
  {
    "timestamp": "24/07/24 21:00",
    "demand": 32306.8682,
    "solar_generated": 0,
    "wind_generated": 64.4183469,
    "marketplace_to_demand": 78.61996584,
    "grid_to_demand": 30060.62677,
    "govt_battery_charge": 54.36068161
  },
  {
    "timestamp": "24/07/24 22:00",
    "demand": 32580.0749,
    "solar_generated": 0,
    "wind_generated": 63.96107387,
    "marketplace_to_demand": 163.2768866,
    "grid_to_demand": 30777.01526,
    "govt_battery_charge": 67.62782615
  },
  {
    "timestamp": "24/07/24 23:00",
    "demand": 34700.3869,
    "solar_generated": 0,
    "wind_generated": 63.59467919,
    "marketplace_to_demand": 115.712129,
    "grid_to_demand": 36215.61304,
    "govt_battery_charge": 55.95283413
  },
  {
    "timestamp": "25/07/24 0:00",
    "demand": 36597.30187,
    "solar_generated": 0,
    "wind_generated": 74.8069166,
    "marketplace_to_demand": 152.5569626,
    "grid_to_demand": 32434.14978,
    "govt_battery_charge": 28.69377947
  },
  {
    "timestamp": "25/07/24 1:00",
    "demand": 36462.6874,
    "solar_generated": 0,
    "wind_generated": 71.0013965,
    "marketplace_to_demand": 169.5648231,
    "grid_to_demand": 34490.54027,
    "govt_battery_charge": 80.8424446
  },
  {
    "timestamp": "25/07/24 2:00",
    "demand": 34159.28766,
    "solar_generated": 0,
    "wind_generated": 79.40517691,
    "marketplace_to_demand": 10.43649792,
    "grid_to_demand": 34994.62443,
    "govt_battery_charge": 59.60379166
  },
  {
    "timestamp": "25/07/24 3:00",
    "demand": 35770.68133,
    "solar_generated": 0,
    "wind_generated": 67.48595193,
    "marketplace_to_demand": 73.97819421,
    "grid_to_demand": 32937.86343,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "25/07/24 4:00",
    "demand": 32120.67328,
    "solar_generated": 0,
    "wind_generated": 78.19638871,
    "marketplace_to_demand": 40.59648382,
    "grid_to_demand": 31829.11867,
    "govt_battery_charge": 97.20796357
  },
  {
    "timestamp": "25/07/24 5:00",
    "demand": 35945.21829,
    "solar_generated": 0,
    "wind_generated": 56.29342849,
    "marketplace_to_demand": 182.9468269,
    "grid_to_demand": 30306.04669,
    "govt_battery_charge": 45.91511081
  },
  {
    "timestamp": "25/07/24 6:00",
    "demand": 31741.26185,
    "solar_generated": 21.68564216,
    "wind_generated": 73.28020261,
    "marketplace_to_demand": 55.32607539,
    "grid_to_demand": 32178.29849,
    "govt_battery_charge": 68.48714337
  },
  {
    "timestamp": "25/07/24 7:00",
    "demand": 31561.60383,
    "solar_generated": 8.559631085,
    "wind_generated": 53.94780546,
    "marketplace_to_demand": 16.33883572,
    "grid_to_demand": 33514.22678,
    "govt_battery_charge": 99.62726059
  },
  {
    "timestamp": "25/07/24 8:00",
    "demand": 31648.48088,
    "solar_generated": 18.6976871,
    "wind_generated": 76.27269603,
    "marketplace_to_demand": 8.092435305,
    "grid_to_demand": 34617.48179,
    "govt_battery_charge": 51.94312701
  },
  {
    "timestamp": "25/07/24 9:00",
    "demand": 36151.02372,
    "solar_generated": 12.8880538,
    "wind_generated": 65.48090158,
    "marketplace_to_demand": 13.16095831,
    "grid_to_demand": 30957.64693,
    "govt_battery_charge": 95.19201622
  },
  {
    "timestamp": "25/07/24 10:00",
    "demand": 30058.39903,
    "solar_generated": 23.28452316,
    "wind_generated": 56.01063448,
    "marketplace_to_demand": 135.6911836,
    "grid_to_demand": 34307.27461,
    "govt_battery_charge": 10.26469216
  },
  {
    "timestamp": "25/07/24 11:00",
    "demand": 36178.39768,
    "solar_generated": 6.022394652,
    "wind_generated": 61.27360176,
    "marketplace_to_demand": 36.664618,
    "grid_to_demand": 35982.09287,
    "govt_battery_charge": 90.90049473
  },
  {
    "timestamp": "25/07/24 12:00",
    "demand": 33518.94159,
    "solar_generated": 14.84006429,
    "wind_generated": 77.30427,
    "marketplace_to_demand": 6.177681877,
    "grid_to_demand": 35227.49863,
    "govt_battery_charge": 75.03067015
  },
  {
    "timestamp": "25/07/24 13:00",
    "demand": 35568.80941,
    "solar_generated": 9.79280643,
    "wind_generated": 56.44305615,
    "marketplace_to_demand": 190.1004096,
    "grid_to_demand": 30294.02848,
    "govt_battery_charge": 89.40190399
  },
  {
    "timestamp": "25/07/24 14:00",
    "demand": 33338.54599,
    "solar_generated": 7.433758662,
    "wind_generated": 72.99001671,
    "marketplace_to_demand": 145.7519343,
    "grid_to_demand": 34373.72061,
    "govt_battery_charge": 6.177876599
  },
  {
    "timestamp": "25/07/24 15:00",
    "demand": 34515.39391,
    "solar_generated": 13.12854273,
    "wind_generated": 75.36713508,
    "marketplace_to_demand": 77.53836447,
    "grid_to_demand": 34008.15171,
    "govt_battery_charge": 7.934332751
  },
  {
    "timestamp": "25/07/24 16:00",
    "demand": 32610.54918,
    "solar_generated": 19.40938895,
    "wind_generated": 65.73375782,
    "marketplace_to_demand": 141.3803784,
    "grid_to_demand": 33545.20813,
    "govt_battery_charge": 60.33514926
  },
  {
    "timestamp": "25/07/24 17:00",
    "demand": 35748.53131,
    "solar_generated": 8.717885094,
    "wind_generated": 59.59072659,
    "marketplace_to_demand": 126.4391971,
    "grid_to_demand": 33827.32439,
    "govt_battery_charge": 1.531515369
  },
  {
    "timestamp": "25/07/24 18:00",
    "demand": 33640.51132,
    "solar_generated": 0,
    "wind_generated": 73.65139542,
    "marketplace_to_demand": 30.31246591,
    "grid_to_demand": 31404.71849,
    "govt_battery_charge": 46.12552371
  },
  {
    "timestamp": "25/07/24 19:00",
    "demand": 30881.5362,
    "solar_generated": 0,
    "wind_generated": 72.6765212,
    "marketplace_to_demand": 96.48789149,
    "grid_to_demand": 32507.05022,
    "govt_battery_charge": 89.81666594
  },
  {
    "timestamp": "25/07/24 20:00",
    "demand": 32173.24322,
    "solar_generated": 0,
    "wind_generated": 62.032605,
    "marketplace_to_demand": 31.87232261,
    "grid_to_demand": 33867.87423,
    "govt_battery_charge": 22.27801645
  },
  {
    "timestamp": "25/07/24 21:00",
    "demand": 30034.41167,
    "solar_generated": 0,
    "wind_generated": 72.03557237,
    "marketplace_to_demand": 194.9724558,
    "grid_to_demand": 34159.87227,
    "govt_battery_charge": 67.51706937
  },
  {
    "timestamp": "25/07/24 22:00",
    "demand": 32856.06046,
    "solar_generated": 0,
    "wind_generated": 70.75482985,
    "marketplace_to_demand": 192.9367165,
    "grid_to_demand": 31915.00032,
    "govt_battery_charge": 40.60934525
  },
  {
    "timestamp": "25/07/24 23:00",
    "demand": 30271.83751,
    "solar_generated": 0,
    "wind_generated": 66.04329118,
    "marketplace_to_demand": 190.9700591,
    "grid_to_demand": 30950.15682,
    "govt_battery_charge": 65.09659268
  },
  {
    "timestamp": "26/07/24 0:00",
    "demand": 34208.03688,
    "solar_generated": 0,
    "wind_generated": 59.92186469,
    "marketplace_to_demand": 99.48302353,
    "grid_to_demand": 34494.70023,
    "govt_battery_charge": 30.2146319
  },
  {
    "timestamp": "26/07/24 1:00",
    "demand": 30971.52252,
    "solar_generated": 0,
    "wind_generated": 58.56058645,
    "marketplace_to_demand": 92.9502384,
    "grid_to_demand": 34345.92738,
    "govt_battery_charge": 94.2349741
  },
  {
    "timestamp": "26/07/24 2:00",
    "demand": 32980.19038,
    "solar_generated": 0,
    "wind_generated": 72.54615854,
    "marketplace_to_demand": 166.2855562,
    "grid_to_demand": 34718.07743,
    "govt_battery_charge": 76.80108443
  },
  {
    "timestamp": "26/07/24 3:00",
    "demand": 34683.628,
    "solar_generated": 0,
    "wind_generated": 66.36771641,
    "marketplace_to_demand": 30.54656884,
    "grid_to_demand": 34572.49844,
    "govt_battery_charge": 34.78276831
  },
  {
    "timestamp": "26/07/24 4:00",
    "demand": 35983.57516,
    "solar_generated": 0,
    "wind_generated": 74.69327354,
    "marketplace_to_demand": 165.360036,
    "grid_to_demand": 30810.81894,
    "govt_battery_charge": 55.7173772
  },
  {
    "timestamp": "26/07/24 5:00",
    "demand": 32888.31664,
    "solar_generated": 0,
    "wind_generated": 56.82562213,
    "marketplace_to_demand": 123.744471,
    "grid_to_demand": 30628.12946,
    "govt_battery_charge": 48.25939748
  },
  {
    "timestamp": "26/07/24 6:00",
    "demand": 34786.06515,
    "solar_generated": 8.578625837,
    "wind_generated": 75.52466345,
    "marketplace_to_demand": 83.16729244,
    "grid_to_demand": 33097.74097,
    "govt_battery_charge": 71.30703584
  },
  {
    "timestamp": "26/07/24 7:00",
    "demand": 34416.97329,
    "solar_generated": 13.27164079,
    "wind_generated": 61.75090303,
    "marketplace_to_demand": 198.9815719,
    "grid_to_demand": 34453.10405,
    "govt_battery_charge": 5.388372569
  },
  {
    "timestamp": "26/07/24 8:00",
    "demand": 33100.75358,
    "solar_generated": 11.79855221,
    "wind_generated": 62.05253501,
    "marketplace_to_demand": 123.4377493,
    "grid_to_demand": 35891.5705,
    "govt_battery_charge": 87.12588155
  },
  {
    "timestamp": "26/07/24 9:00",
    "demand": 34970.4636,
    "solar_generated": 17.41824368,
    "wind_generated": 74.4229042,
    "marketplace_to_demand": 177.8935447,
    "grid_to_demand": 32857.33271,
    "govt_battery_charge": 46.61498027
  },
  {
    "timestamp": "26/07/24 10:00",
    "demand": 30023.08994,
    "solar_generated": 10.80443625,
    "wind_generated": 58.19747217,
    "marketplace_to_demand": 189.1764784,
    "grid_to_demand": 35630.26073,
    "govt_battery_charge": 47.50087327
  },
  {
    "timestamp": "26/07/24 11:00",
    "demand": 36125.30067,
    "solar_generated": 8.572590134,
    "wind_generated": 68.16629909,
    "marketplace_to_demand": 75.65260681,
    "grid_to_demand": 31783.67243,
    "govt_battery_charge": 22.53887831
  },
  {
    "timestamp": "26/07/24 12:00",
    "demand": 35179.78376,
    "solar_generated": 22.81377802,
    "wind_generated": 74.85380379,
    "marketplace_to_demand": 159.4186556,
    "grid_to_demand": 35427.82352,
    "govt_battery_charge": 4.994233547
  },
  {
    "timestamp": "26/07/24 13:00",
    "demand": 35947.43655,
    "solar_generated": 17.72972078,
    "wind_generated": 61.24910049,
    "marketplace_to_demand": 147.5262083,
    "grid_to_demand": 31461.29979,
    "govt_battery_charge": 82.47041043
  },
  {
    "timestamp": "26/07/24 14:00",
    "demand": 35068.98888,
    "solar_generated": 13.10833076,
    "wind_generated": 67.60342637,
    "marketplace_to_demand": 103.2687,
    "grid_to_demand": 35856.16965,
    "govt_battery_charge": 29.65330271
  },
  {
    "timestamp": "26/07/24 15:00",
    "demand": 31639.72032,
    "solar_generated": 22.92611296,
    "wind_generated": 75.42912729,
    "marketplace_to_demand": 165.8462714,
    "grid_to_demand": 31458.8283,
    "govt_battery_charge": 30.90985384
  },
  {
    "timestamp": "26/07/24 16:00",
    "demand": 34588.71795,
    "solar_generated": 7.672768809,
    "wind_generated": 69.90406965,
    "marketplace_to_demand": 192.3073231,
    "grid_to_demand": 35995.55488,
    "govt_battery_charge": 58.47848528
  },
  {
    "timestamp": "26/07/24 17:00",
    "demand": 30308.35836,
    "solar_generated": 18.94728807,
    "wind_generated": 79.40536835,
    "marketplace_to_demand": 191.9123396,
    "grid_to_demand": 34548.27079,
    "govt_battery_charge": 52.9859906
  },
  {
    "timestamp": "26/07/24 18:00",
    "demand": 32018.72829,
    "solar_generated": 0,
    "wind_generated": 75.84560887,
    "marketplace_to_demand": 34.78346027,
    "grid_to_demand": 33378.15689,
    "govt_battery_charge": 48.83404174
  },
  {
    "timestamp": "26/07/24 19:00",
    "demand": 33285.80535,
    "solar_generated": 0,
    "wind_generated": 55.28915193,
    "marketplace_to_demand": 192.6163708,
    "grid_to_demand": 31645.9301,
    "govt_battery_charge": 77.25097884
  },
  {
    "timestamp": "26/07/24 20:00",
    "demand": 34377.33809,
    "solar_generated": 0,
    "wind_generated": 57.63370028,
    "marketplace_to_demand": 149.7506139,
    "grid_to_demand": 30494.34261,
    "govt_battery_charge": 17.68943576
  },
  {
    "timestamp": "26/07/24 21:00",
    "demand": 35544.52806,
    "solar_generated": 0,
    "wind_generated": 54.52389369,
    "marketplace_to_demand": 10.06362803,
    "grid_to_demand": 31770.53345,
    "govt_battery_charge": 22.9462239
  },
  {
    "timestamp": "26/07/24 22:00",
    "demand": 36588.28927,
    "solar_generated": 0,
    "wind_generated": 57.29025905,
    "marketplace_to_demand": 85.79750792,
    "grid_to_demand": 35095.79975,
    "govt_battery_charge": 19.52563009
  },
  {
    "timestamp": "26/07/24 23:00",
    "demand": 35638.32931,
    "solar_generated": 0,
    "wind_generated": 75.99768929,
    "marketplace_to_demand": 99.32802791,
    "grid_to_demand": 33419.95525,
    "govt_battery_charge": 64.01294908
  },
  {
    "timestamp": "27/07/24 0:00",
    "demand": 30498.75599,
    "solar_generated": 0,
    "wind_generated": 58.05578131,
    "marketplace_to_demand": 46.74685674,
    "grid_to_demand": 33316.89941,
    "govt_battery_charge": 86.78060397
  },
  {
    "timestamp": "27/07/24 1:00",
    "demand": 32793.97632,
    "solar_generated": 0,
    "wind_generated": 56.88246132,
    "marketplace_to_demand": 77.01105217,
    "grid_to_demand": 30845.50797,
    "govt_battery_charge": 35.76992027
  },
  {
    "timestamp": "27/07/24 2:00",
    "demand": 34808.40374,
    "solar_generated": 0,
    "wind_generated": 56.33256334,
    "marketplace_to_demand": 138.608373,
    "grid_to_demand": 32726.06186,
    "govt_battery_charge": 59.3958857
  },
  {
    "timestamp": "27/07/24 3:00",
    "demand": 34399.22784,
    "solar_generated": 0,
    "wind_generated": 57.06394103,
    "marketplace_to_demand": 199.3289888,
    "grid_to_demand": 33597.468,
    "govt_battery_charge": 37.34496952
  },
  {
    "timestamp": "27/07/24 4:00",
    "demand": 34061.13158,
    "solar_generated": 0,
    "wind_generated": 60.02932789,
    "marketplace_to_demand": 192.788436,
    "grid_to_demand": 31723.51234,
    "govt_battery_charge": 87.71474266
  },
  {
    "timestamp": "27/07/24 5:00",
    "demand": 35503.14874,
    "solar_generated": 0,
    "wind_generated": 78.72016424,
    "marketplace_to_demand": 152.6104079,
    "grid_to_demand": 34459.44269,
    "govt_battery_charge": 22.62614824
  },
  {
    "timestamp": "27/07/24 6:00",
    "demand": 34988.81803,
    "solar_generated": 19.28589665,
    "wind_generated": 66.41540382,
    "marketplace_to_demand": 132.4048936,
    "grid_to_demand": 31221.07248,
    "govt_battery_charge": 58.90523564
  },
  {
    "timestamp": "27/07/24 7:00",
    "demand": 32453.42344,
    "solar_generated": 22.37940875,
    "wind_generated": 62.9952681,
    "marketplace_to_demand": 29.3844708,
    "grid_to_demand": 35134.47055,
    "govt_battery_charge": 18.51319929
  },
  {
    "timestamp": "27/07/24 8:00",
    "demand": 34439.25575,
    "solar_generated": 21.4374185,
    "wind_generated": 75.38771324,
    "marketplace_to_demand": 71.4685164,
    "grid_to_demand": 36038.36568,
    "govt_battery_charge": 3.387854838
  },
  {
    "timestamp": "27/07/24 9:00",
    "demand": 33939.5373,
    "solar_generated": 22.73500699,
    "wind_generated": 54.03185569,
    "marketplace_to_demand": 108.4822526,
    "grid_to_demand": 33758.09888,
    "govt_battery_charge": 28.64414839
  },
  {
    "timestamp": "27/07/24 10:00",
    "demand": 33503.9693,
    "solar_generated": 7.940946815,
    "wind_generated": 79.5268366,
    "marketplace_to_demand": 51.07208652,
    "grid_to_demand": 33650.93906,
    "govt_battery_charge": 54.76813154
  },
  {
    "timestamp": "27/07/24 11:00",
    "demand": 31226.6348,
    "solar_generated": 21.88488198,
    "wind_generated": 62.24605437,
    "marketplace_to_demand": 128.9878267,
    "grid_to_demand": 33325.75741,
    "govt_battery_charge": 80.29955219
  },
  {
    "timestamp": "27/07/24 12:00",
    "demand": 33180.14264,
    "solar_generated": 15.4044383,
    "wind_generated": 70.02238797,
    "marketplace_to_demand": 197.8811314,
    "grid_to_demand": 31695.76084,
    "govt_battery_charge": 78.81671339
  },
  {
    "timestamp": "27/07/24 13:00",
    "demand": 30711.01387,
    "solar_generated": 6.252123721,
    "wind_generated": 77.3094846,
    "marketplace_to_demand": 2.813139669,
    "grid_to_demand": 33242.67066,
    "govt_battery_charge": 54.41968439
  },
  {
    "timestamp": "27/07/24 14:00",
    "demand": 32563.74432,
    "solar_generated": 9.719281539,
    "wind_generated": 61.73484556,
    "marketplace_to_demand": 180.8735424,
    "grid_to_demand": 34167.20358,
    "govt_battery_charge": 98.69863179
  },
  {
    "timestamp": "27/07/24 15:00",
    "demand": 34194.65214,
    "solar_generated": 11.98264381,
    "wind_generated": 77.11447429,
    "marketplace_to_demand": 31.38616382,
    "grid_to_demand": 31512.73987,
    "govt_battery_charge": 18.92123583
  },
  {
    "timestamp": "27/07/24 16:00",
    "demand": 32568.42801,
    "solar_generated": 17.24307052,
    "wind_generated": 68.26451492,
    "marketplace_to_demand": 8.968910348,
    "grid_to_demand": 33233.59134,
    "govt_battery_charge": 50.09633582
  },
  {
    "timestamp": "27/07/24 17:00",
    "demand": 32377.52942,
    "solar_generated": 15.64055362,
    "wind_generated": 62.2220192,
    "marketplace_to_demand": 191.0668281,
    "grid_to_demand": 32146.99481,
    "govt_battery_charge": 91.73039016
  },
  {
    "timestamp": "27/07/24 18:00",
    "demand": 35189.77148,
    "solar_generated": 0,
    "wind_generated": 66.84665208,
    "marketplace_to_demand": 46.53351915,
    "grid_to_demand": 32358.02306,
    "govt_battery_charge": 57.47223835
  },
  {
    "timestamp": "27/07/24 19:00",
    "demand": 30213.96366,
    "solar_generated": 0,
    "wind_generated": 70.320258,
    "marketplace_to_demand": 184.7746928,
    "grid_to_demand": 32672.28981,
    "govt_battery_charge": 86.57414517
  },
  {
    "timestamp": "27/07/24 20:00",
    "demand": 33395.55717,
    "solar_generated": 0,
    "wind_generated": 57.72560669,
    "marketplace_to_demand": 185.764756,
    "grid_to_demand": 30660.54696,
    "govt_battery_charge": 39.47456806
  },
  {
    "timestamp": "27/07/24 21:00",
    "demand": 36210.47458,
    "solar_generated": 0,
    "wind_generated": 73.10499866,
    "marketplace_to_demand": 179.2135135,
    "grid_to_demand": 35992.07464,
    "govt_battery_charge": 22.55527387
  },
  {
    "timestamp": "27/07/24 22:00",
    "demand": 33955.11988,
    "solar_generated": 0,
    "wind_generated": 69.12017972,
    "marketplace_to_demand": 185.2827936,
    "grid_to_demand": 36193.31666,
    "govt_battery_charge": 28.88320273
  },
  {
    "timestamp": "27/07/24 23:00",
    "demand": 31258.67653,
    "solar_generated": 0,
    "wind_generated": 74.37070558,
    "marketplace_to_demand": 148.2552746,
    "grid_to_demand": 31512.87923,
    "govt_battery_charge": 94.44971305
  },
  {
    "timestamp": "28/07/24 0:00",
    "demand": 34900.60027,
    "solar_generated": 0,
    "wind_generated": 75.3137886,
    "marketplace_to_demand": 73.51166111,
    "grid_to_demand": 36237.3299,
    "govt_battery_charge": 51.70229448
  },
  {
    "timestamp": "28/07/24 1:00",
    "demand": 33668.33548,
    "solar_generated": 0,
    "wind_generated": 69.82809466,
    "marketplace_to_demand": 79.37162065,
    "grid_to_demand": 32417.66272,
    "govt_battery_charge": 21.97672326
  },
  {
    "timestamp": "28/07/24 2:00",
    "demand": 30028.45731,
    "solar_generated": 0,
    "wind_generated": 69.91986111,
    "marketplace_to_demand": 102.8595556,
    "grid_to_demand": 34648.74431,
    "govt_battery_charge": 17.25040482
  },
  {
    "timestamp": "28/07/24 3:00",
    "demand": 31401.90039,
    "solar_generated": 0,
    "wind_generated": 61.78811118,
    "marketplace_to_demand": 55.96709421,
    "grid_to_demand": 35283.34514,
    "govt_battery_charge": 57.72104849
  },
  {
    "timestamp": "28/07/24 4:00",
    "demand": 35886.81352,
    "solar_generated": 0,
    "wind_generated": 70.213827,
    "marketplace_to_demand": 79.77601515,
    "grid_to_demand": 31245.56304,
    "govt_battery_charge": 15.55304561
  },
  {
    "timestamp": "28/07/24 5:00",
    "demand": 31706.89287,
    "solar_generated": 0,
    "wind_generated": 54.58366129,
    "marketplace_to_demand": 27.65533819,
    "grid_to_demand": 36400.77838,
    "govt_battery_charge": 32.90208238
  },
  {
    "timestamp": "28/07/24 6:00",
    "demand": 31372.23343,
    "solar_generated": 15.42993168,
    "wind_generated": 73.67724929,
    "marketplace_to_demand": 123.6592116,
    "grid_to_demand": 34809.82624,
    "govt_battery_charge": 63.78688553
  },
  {
    "timestamp": "28/07/24 7:00",
    "demand": 33699.54233,
    "solar_generated": 10.92350031,
    "wind_generated": 73.65005237,
    "marketplace_to_demand": 199.6049333,
    "grid_to_demand": 36150.70975,
    "govt_battery_charge": 43.28843657
  },
  {
    "timestamp": "28/07/24 8:00",
    "demand": 35374.53997,
    "solar_generated": 17.50850624,
    "wind_generated": 79.33466229,
    "marketplace_to_demand": 148.5572413,
    "grid_to_demand": 32608.34754,
    "govt_battery_charge": 10.16981398
  },
  {
    "timestamp": "28/07/24 9:00",
    "demand": 36489.30443,
    "solar_generated": 22.84265474,
    "wind_generated": 79.90560219,
    "marketplace_to_demand": 190.8818779,
    "grid_to_demand": 34913.93586,
    "govt_battery_charge": 66.69944962
  },
  {
    "timestamp": "28/07/24 10:00",
    "demand": 34816.92412,
    "solar_generated": 19.759683,
    "wind_generated": 53.94965707,
    "marketplace_to_demand": 107.5287298,
    "grid_to_demand": 32827.96282,
    "govt_battery_charge": 70.17490037
  },
  {
    "timestamp": "28/07/24 11:00",
    "demand": 32005.21892,
    "solar_generated": 12.58518982,
    "wind_generated": 60.50590279,
    "marketplace_to_demand": 192.6197089,
    "grid_to_demand": 33670.09967,
    "govt_battery_charge": 46.6399781
  },
  {
    "timestamp": "28/07/24 12:00",
    "demand": 33023.08095,
    "solar_generated": 8.711694157,
    "wind_generated": 80.13326709,
    "marketplace_to_demand": 114.502037,
    "grid_to_demand": 31213.17109,
    "govt_battery_charge": 59.67842926
  },
  {
    "timestamp": "28/07/24 13:00",
    "demand": 35671.09385,
    "solar_generated": 15.45947332,
    "wind_generated": 80.00224171,
    "marketplace_to_demand": 30.50542042,
    "grid_to_demand": 34098.38989,
    "govt_battery_charge": 77.98388496
  },
  {
    "timestamp": "28/07/24 14:00",
    "demand": 32164.90243,
    "solar_generated": 9.198918032,
    "wind_generated": 79.10513312,
    "marketplace_to_demand": 146.3010399,
    "grid_to_demand": 32236.66766,
    "govt_battery_charge": 15.45115957
  },
  {
    "timestamp": "28/07/24 15:00",
    "demand": 32045.40761,
    "solar_generated": 18.339396,
    "wind_generated": 67.00565706,
    "marketplace_to_demand": 53.33326056,
    "grid_to_demand": 32920.15304,
    "govt_battery_charge": 25.43531774
  },
  {
    "timestamp": "28/07/24 16:00",
    "demand": 36505.0463,
    "solar_generated": 20.20571946,
    "wind_generated": 56.06920903,
    "marketplace_to_demand": 68.70349642,
    "grid_to_demand": 32001.33184,
    "govt_battery_charge": 3.834513945
  },
  {
    "timestamp": "28/07/24 17:00",
    "demand": 31108.33098,
    "solar_generated": 12.61634384,
    "wind_generated": 59.00747631,
    "marketplace_to_demand": 63.3736732,
    "grid_to_demand": 36455.08372,
    "govt_battery_charge": 54.4409789
  },
  {
    "timestamp": "28/07/24 18:00",
    "demand": 36188.32264,
    "solar_generated": 0,
    "wind_generated": 54.40547217,
    "marketplace_to_demand": 135.5799262,
    "grid_to_demand": 36214.55453,
    "govt_battery_charge": 91.4203112
  },
  {
    "timestamp": "28/07/24 19:00",
    "demand": 30590.21692,
    "solar_generated": 0,
    "wind_generated": 65.21906214,
    "marketplace_to_demand": 184.3566741,
    "grid_to_demand": 35773.95967,
    "govt_battery_charge": 8.123975033
  },
  {
    "timestamp": "28/07/24 20:00",
    "demand": 33958.19618,
    "solar_generated": 0,
    "wind_generated": 68.086235,
    "marketplace_to_demand": 0.328996109,
    "grid_to_demand": 30512.36787,
    "govt_battery_charge": 43.67335631
  },
  {
    "timestamp": "28/07/24 21:00",
    "demand": 36121.15306,
    "solar_generated": 0,
    "wind_generated": 57.73187801,
    "marketplace_to_demand": 128.2617304,
    "grid_to_demand": 30653.38245,
    "govt_battery_charge": 40.27092819
  },
  {
    "timestamp": "28/07/24 22:00",
    "demand": 35469.06818,
    "solar_generated": 0,
    "wind_generated": 60.01633223,
    "marketplace_to_demand": 144.4367434,
    "grid_to_demand": 31138.3656,
    "govt_battery_charge": 84.41270738
  },
  {
    "timestamp": "28/07/24 23:00",
    "demand": 30785.01101,
    "solar_generated": 0,
    "wind_generated": 56.97143872,
    "marketplace_to_demand": 67.4057813,
    "grid_to_demand": 36366.92905,
    "govt_battery_charge": 70.22362956
  },
  {
    "timestamp": "29/07/24 0:00",
    "demand": 33709.49697,
    "solar_generated": 0,
    "wind_generated": 68.29230176,
    "marketplace_to_demand": 35.24683763,
    "grid_to_demand": 33991.97795,
    "govt_battery_charge": 30.63202955
  },
  {
    "timestamp": "29/07/24 1:00",
    "demand": 33579.63943,
    "solar_generated": 0,
    "wind_generated": 71.15187893,
    "marketplace_to_demand": 51.6626383,
    "grid_to_demand": 34481.44683,
    "govt_battery_charge": 52.78072201
  },
  {
    "timestamp": "29/07/24 2:00",
    "demand": 31483.92558,
    "solar_generated": 0,
    "wind_generated": 62.50044842,
    "marketplace_to_demand": 13.61910707,
    "grid_to_demand": 33816.44248,
    "govt_battery_charge": 4.161183668
  },
  {
    "timestamp": "29/07/24 3:00",
    "demand": 31024.75671,
    "solar_generated": 0,
    "wind_generated": 76.62248888,
    "marketplace_to_demand": 175.5869719,
    "grid_to_demand": 36063.70254,
    "govt_battery_charge": 72.68293387
  },
  {
    "timestamp": "29/07/24 4:00",
    "demand": 33717.14531,
    "solar_generated": 0,
    "wind_generated": 71.28178862,
    "marketplace_to_demand": 72.22721544,
    "grid_to_demand": 35285.68767,
    "govt_battery_charge": 42.65039413
  },
  {
    "timestamp": "29/07/24 5:00",
    "demand": 30154.45684,
    "solar_generated": 0,
    "wind_generated": 68.73351627,
    "marketplace_to_demand": 101.8608746,
    "grid_to_demand": 31215.3266,
    "govt_battery_charge": 47.11379317
  },
  {
    "timestamp": "29/07/24 6:00",
    "demand": 31325.55803,
    "solar_generated": 11.70240314,
    "wind_generated": 62.26291637,
    "marketplace_to_demand": 34.00847266,
    "grid_to_demand": 34810.95712,
    "govt_battery_charge": 22.59126614
  },
  {
    "timestamp": "29/07/24 7:00",
    "demand": 35892.24086,
    "solar_generated": 15.30966402,
    "wind_generated": 79.41476975,
    "marketplace_to_demand": 69.68991733,
    "grid_to_demand": 32667.83512,
    "govt_battery_charge": 28.87714588
  },
  {
    "timestamp": "29/07/24 8:00",
    "demand": 34667.64395,
    "solar_generated": 18.19458851,
    "wind_generated": 75.09935383,
    "marketplace_to_demand": 174.0636271,
    "grid_to_demand": 30901.66333,
    "govt_battery_charge": 67.84873634
  },
  {
    "timestamp": "29/07/24 9:00",
    "demand": 33148.64179,
    "solar_generated": 17.65710902,
    "wind_generated": 66.07953052,
    "marketplace_to_demand": 147.8316871,
    "grid_to_demand": 29928.95739,
    "govt_battery_charge": 42.06803956
  },
  {
    "timestamp": "29/07/24 10:00",
    "demand": 30799.30683,
    "solar_generated": 6.223196342,
    "wind_generated": 64.82046908,
    "marketplace_to_demand": 156.879081,
    "grid_to_demand": 31178.37157,
    "govt_battery_charge": 15.62725495
  },
  {
    "timestamp": "29/07/24 11:00",
    "demand": 34641.32485,
    "solar_generated": 11.13863895,
    "wind_generated": 65.3767074,
    "marketplace_to_demand": 178.3901052,
    "grid_to_demand": 33449.4037,
    "govt_battery_charge": 47.74456101
  },
  {
    "timestamp": "29/07/24 12:00",
    "demand": 35094.12016,
    "solar_generated": 21.01267143,
    "wind_generated": 66.05996688,
    "marketplace_to_demand": 170.6898506,
    "grid_to_demand": 36028.85195,
    "govt_battery_charge": 66.18105583
  },
  {
    "timestamp": "29/07/24 13:00",
    "demand": 31809.57931,
    "solar_generated": 9.517300607,
    "wind_generated": 69.26055691,
    "marketplace_to_demand": 86.68960253,
    "grid_to_demand": 35605.45305,
    "govt_battery_charge": 34.02301111
  },
  {
    "timestamp": "29/07/24 14:00",
    "demand": 30929.92967,
    "solar_generated": 17.78837769,
    "wind_generated": 56.75919009,
    "marketplace_to_demand": 31.29294577,
    "grid_to_demand": 31388.09194,
    "govt_battery_charge": 90.91801519
  },
  {
    "timestamp": "29/07/24 15:00",
    "demand": 36274.04826,
    "solar_generated": 14.40882058,
    "wind_generated": 57.70431168,
    "marketplace_to_demand": 67.04676163,
    "grid_to_demand": 35020.35961,
    "govt_battery_charge": 6.776803026
  },
  {
    "timestamp": "29/07/24 16:00",
    "demand": 31845.30634,
    "solar_generated": 12.19052547,
    "wind_generated": 70.83477327,
    "marketplace_to_demand": 61.5709936,
    "grid_to_demand": 34484.72353,
    "govt_battery_charge": 92.94788271
  },
  {
    "timestamp": "29/07/24 17:00",
    "demand": 32234.84309,
    "solar_generated": 7.684608465,
    "wind_generated": 73.30640082,
    "marketplace_to_demand": 132.4109502,
    "grid_to_demand": 31499.64675,
    "govt_battery_charge": 44.84285534
  },
  {
    "timestamp": "29/07/24 18:00",
    "demand": 34784.2823,
    "solar_generated": 0,
    "wind_generated": 76.16381521,
    "marketplace_to_demand": 27.77197643,
    "grid_to_demand": 35947.39896,
    "govt_battery_charge": 31.70236707
  },
  {
    "timestamp": "29/07/24 19:00",
    "demand": 32496.61986,
    "solar_generated": 0,
    "wind_generated": 78.91036056,
    "marketplace_to_demand": 82.80657713,
    "grid_to_demand": 30104.76636,
    "govt_battery_charge": 9.151759455
  },
  {
    "timestamp": "29/07/24 20:00",
    "demand": 31701.55868,
    "solar_generated": 0,
    "wind_generated": 68.25007331,
    "marketplace_to_demand": 156.1663682,
    "grid_to_demand": 34807.75419,
    "govt_battery_charge": 4.239400243
  },
  {
    "timestamp": "29/07/24 21:00",
    "demand": 32035.20491,
    "solar_generated": 0,
    "wind_generated": 60.98201733,
    "marketplace_to_demand": 90.61790694,
    "grid_to_demand": 33568.386,
    "govt_battery_charge": 87.04279086
  },
  {
    "timestamp": "29/07/24 22:00",
    "demand": 35804.46456,
    "solar_generated": 0,
    "wind_generated": 79.44474205,
    "marketplace_to_demand": 174.0201606,
    "grid_to_demand": 32628.27875,
    "govt_battery_charge": 93.35789594
  },
  {
    "timestamp": "29/07/24 23:00",
    "demand": 33538.19036,
    "solar_generated": 0,
    "wind_generated": 62.04187283,
    "marketplace_to_demand": 159.1724457,
    "grid_to_demand": 34368.95687,
    "govt_battery_charge": 61.73552208
  },
  {
    "timestamp": "30/07/24 0:00",
    "demand": 31636.01511,
    "solar_generated": 0,
    "wind_generated": 76.07960481,
    "marketplace_to_demand": 103.73738,
    "grid_to_demand": 31915.75191,
    "govt_battery_charge": 13.71829594
  },
  {
    "timestamp": "30/07/24 1:00",
    "demand": 32703.12396,
    "solar_generated": 0,
    "wind_generated": 57.36195559,
    "marketplace_to_demand": 34.38473159,
    "grid_to_demand": 33630.13966,
    "govt_battery_charge": 16.6276435
  },
  {
    "timestamp": "30/07/24 2:00",
    "demand": 32740.39611,
    "solar_generated": 0,
    "wind_generated": 74.08536585,
    "marketplace_to_demand": 108.1200917,
    "grid_to_demand": 32673.27512,
    "govt_battery_charge": 53.73656241
  },
  {
    "timestamp": "30/07/24 3:00",
    "demand": 36053.00775,
    "solar_generated": 0,
    "wind_generated": 62.28894254,
    "marketplace_to_demand": 72.11196687,
    "grid_to_demand": 29946.55466,
    "govt_battery_charge": 39.15918259
  },
  {
    "timestamp": "30/07/24 4:00",
    "demand": 31561.63386,
    "solar_generated": 0,
    "wind_generated": 54.15790102,
    "marketplace_to_demand": 100.5456874,
    "grid_to_demand": 34626.22665,
    "govt_battery_charge": 75.57298568
  },
  {
    "timestamp": "30/07/24 5:00",
    "demand": 36200.84012,
    "solar_generated": 0,
    "wind_generated": 67.94257943,
    "marketplace_to_demand": 121.1148071,
    "grid_to_demand": 33177.32504,
    "govt_battery_charge": 54.77102409
  },
  {
    "timestamp": "30/07/24 6:00",
    "demand": 33939.66179,
    "solar_generated": 18.99970117,
    "wind_generated": 65.42605782,
    "marketplace_to_demand": 33.68185656,
    "grid_to_demand": 34770.06251,
    "govt_battery_charge": 93.10059465
  },
  {
    "timestamp": "30/07/24 7:00",
    "demand": 31254.68563,
    "solar_generated": 14.23539497,
    "wind_generated": 62.08755353,
    "marketplace_to_demand": 165.2889424,
    "grid_to_demand": 35397.50796,
    "govt_battery_charge": 94.28886245
  },
  {
    "timestamp": "30/07/24 8:00",
    "demand": 33543.36372,
    "solar_generated": 9.985904801,
    "wind_generated": 63.56422461,
    "marketplace_to_demand": 6.148279537,
    "grid_to_demand": 35334.13807,
    "govt_battery_charge": 10.23316075
  },
  {
    "timestamp": "30/07/24 9:00",
    "demand": 35774.38097,
    "solar_generated": 7.172574892,
    "wind_generated": 62.61585117,
    "marketplace_to_demand": 41.21662807,
    "grid_to_demand": 32927.12932,
    "govt_battery_charge": 18.48319819
  },
  {
    "timestamp": "30/07/24 10:00",
    "demand": 36490.45131,
    "solar_generated": 11.86186922,
    "wind_generated": 63.00764937,
    "marketplace_to_demand": 180.5191986,
    "grid_to_demand": 33967.93553,
    "govt_battery_charge": 24.72758994
  },
  {
    "timestamp": "30/07/24 11:00",
    "demand": 30802.41385,
    "solar_generated": 15.07351871,
    "wind_generated": 72.51434713,
    "marketplace_to_demand": 175.4527247,
    "grid_to_demand": 30440.87759,
    "govt_battery_charge": 33.30984512
  },
  {
    "timestamp": "30/07/24 12:00",
    "demand": 30480.1595,
    "solar_generated": 22.37319643,
    "wind_generated": 67.61846128,
    "marketplace_to_demand": 128.0439779,
    "grid_to_demand": 33146.97363,
    "govt_battery_charge": 48.11495885
  },
  {
    "timestamp": "30/07/24 13:00",
    "demand": 34545.59144,
    "solar_generated": 9.943623388,
    "wind_generated": 61.49781058,
    "marketplace_to_demand": 91.63054033,
    "grid_to_demand": 36415.86827,
    "govt_battery_charge": 72.49058297
  },
  {
    "timestamp": "30/07/24 14:00",
    "demand": 33498.59299,
    "solar_generated": 13.90544126,
    "wind_generated": 54.78854454,
    "marketplace_to_demand": 38.14092736,
    "grid_to_demand": 35655.77374,
    "govt_battery_charge": 54.55595033
  },
  {
    "timestamp": "30/07/24 15:00",
    "demand": 35178.65311,
    "solar_generated": 12.72433392,
    "wind_generated": 59.71522103,
    "marketplace_to_demand": 192.4206871,
    "grid_to_demand": 33768.81311,
    "govt_battery_charge": 93.77953998
  },
  {
    "timestamp": "30/07/24 16:00",
    "demand": 33887.24303,
    "solar_generated": 21.91517695,
    "wind_generated": 56.39196961,
    "marketplace_to_demand": 87.35809572,
    "grid_to_demand": 34499.19301,
    "govt_battery_charge": 97.87330287
  },
  {
    "timestamp": "30/07/24 17:00",
    "demand": 35328.59803,
    "solar_generated": 15.13108628,
    "wind_generated": 75.84739456,
    "marketplace_to_demand": 89.06432118,
    "grid_to_demand": 32594.28968,
    "govt_battery_charge": 36.97710729
  },
  {
    "timestamp": "30/07/24 18:00",
    "demand": 33878.88646,
    "solar_generated": 0,
    "wind_generated": 56.60263607,
    "marketplace_to_demand": 2.871076687,
    "grid_to_demand": 29936.36701,
    "govt_battery_charge": 36.83490075
  },
  {
    "timestamp": "30/07/24 19:00",
    "demand": 30324.01651,
    "solar_generated": 0,
    "wind_generated": 65.52836091,
    "marketplace_to_demand": 106.6326828,
    "grid_to_demand": 35733.63433,
    "govt_battery_charge": 67.95542414
  },
  {
    "timestamp": "30/07/24 20:00",
    "demand": 35943.69213,
    "solar_generated": 0,
    "wind_generated": 60.20929177,
    "marketplace_to_demand": 165.0252173,
    "grid_to_demand": 30342.74678,
    "govt_battery_charge": 74.21251165
  },
  {
    "timestamp": "30/07/24 21:00",
    "demand": 30747.16583,
    "solar_generated": 0,
    "wind_generated": 64.29323659,
    "marketplace_to_demand": 94.01188942,
    "grid_to_demand": 32244.53558,
    "govt_battery_charge": 96.99093719
  },
  {
    "timestamp": "30/07/24 22:00",
    "demand": 32547.38548,
    "solar_generated": 0,
    "wind_generated": 59.44335602,
    "marketplace_to_demand": 104.9920209,
    "grid_to_demand": 34768.69639,
    "govt_battery_charge": 99.91353701
  },
  {
    "timestamp": "30/07/24 23:00",
    "demand": 31857.99495,
    "solar_generated": 0,
    "wind_generated": 68.44378939,
    "marketplace_to_demand": 152.5658746,
    "grid_to_demand": 35321.19279,
    "govt_battery_charge": 40.36111094
  },
  {
    "timestamp": "31/07/24 0:00",
    "demand": 31131.41832,
    "solar_generated": 0,
    "wind_generated": 61.44655061,
    "marketplace_to_demand": 44.05236287,
    "grid_to_demand": 33334.64411,
    "govt_battery_charge": 4.390040605
  },
  {
    "timestamp": "31/07/24 1:00",
    "demand": 30246.64932,
    "solar_generated": 0,
    "wind_generated": 77.37928226,
    "marketplace_to_demand": 115.898539,
    "grid_to_demand": 31349.86247,
    "govt_battery_charge": 34.52360943
  },
  {
    "timestamp": "31/07/24 2:00",
    "demand": 30047.58468,
    "solar_generated": 0,
    "wind_generated": 70.70621878,
    "marketplace_to_demand": 26.81547494,
    "grid_to_demand": 33674.81871,
    "govt_battery_charge": 53.61460096
  },
  {
    "timestamp": "31/07/24 3:00",
    "demand": 31058.55196,
    "solar_generated": 0,
    "wind_generated": 78.61393866,
    "marketplace_to_demand": 122.6621928,
    "grid_to_demand": 33884.99933,
    "govt_battery_charge": 81.77490993
  },
  {
    "timestamp": "31/07/24 4:00",
    "demand": 32936.91218,
    "solar_generated": 0,
    "wind_generated": 69.63576657,
    "marketplace_to_demand": 137.3995733,
    "grid_to_demand": 34183.82209,
    "govt_battery_charge": 18.59971473
  },
  {
    "timestamp": "31/07/24 5:00",
    "demand": 34062.28088,
    "solar_generated": 0,
    "wind_generated": 73.91748501,
    "marketplace_to_demand": 50.82984206,
    "grid_to_demand": 32090.71799,
    "govt_battery_charge": 85.60962272
  },
  {
    "timestamp": "31/07/24 6:00",
    "demand": 35225.71072,
    "solar_generated": 6.215111654,
    "wind_generated": 55.11243999,
    "marketplace_to_demand": 112.6597125,
    "grid_to_demand": 34181.83616,
    "govt_battery_charge": 43.39225254
  },
  {
    "timestamp": "31/07/24 7:00",
    "demand": 32580.57866,
    "solar_generated": 9.1649726,
    "wind_generated": 65.18251316,
    "marketplace_to_demand": 2.209539235,
    "grid_to_demand": 35322.52361,
    "govt_battery_charge": 71.48960729
  },
  {
    "timestamp": "31/07/24 8:00",
    "demand": 36573.76044,
    "solar_generated": 14.36304957,
    "wind_generated": 74.78980264,
    "marketplace_to_demand": 88.96863824,
    "grid_to_demand": 31091.44019,
    "govt_battery_charge": 1.607846203
  },
  {
    "timestamp": "31/07/24 9:00",
    "demand": 33646.70888,
    "solar_generated": 8.802581279,
    "wind_generated": 76.28704886,
    "marketplace_to_demand": 175.0641898,
    "grid_to_demand": 32997.10014,
    "govt_battery_charge": 98.139516
  },
  {
    "timestamp": "31/07/24 10:00",
    "demand": 32807.8825,
    "solar_generated": 17.0481452,
    "wind_generated": 77.77028325,
    "marketplace_to_demand": 187.9938589,
    "grid_to_demand": 35259.04397,
    "govt_battery_charge": 9.717029084
  },
  {
    "timestamp": "31/07/24 11:00",
    "demand": 34379.33735,
    "solar_generated": 14.48893427,
    "wind_generated": 73.38520798,
    "marketplace_to_demand": 190.020966,
    "grid_to_demand": 34483.75712,
    "govt_battery_charge": 26.88693089
  },
  {
    "timestamp": "31/07/24 12:00",
    "demand": 32970.651,
    "solar_generated": 17.37266907,
    "wind_generated": 65.06220181,
    "marketplace_to_demand": 56.7128711,
    "grid_to_demand": 31562.52462,
    "govt_battery_charge": 9.157120674
  },
  {
    "timestamp": "31/07/24 13:00",
    "demand": 32936.93458,
    "solar_generated": 11.14510428,
    "wind_generated": 63.93757564,
    "marketplace_to_demand": 2.568958202,
    "grid_to_demand": 34197.07715,
    "govt_battery_charge": 92.57944015
  },
  {
    "timestamp": "31/07/24 14:00",
    "demand": 32659.13215,
    "solar_generated": 13.75010391,
    "wind_generated": 54.27965525,
    "marketplace_to_demand": 33.76407361,
    "grid_to_demand": 30205.28728,
    "govt_battery_charge": 91.93477028
  },
  {
    "timestamp": "31/07/24 15:00",
    "demand": 35461.24962,
    "solar_generated": 7.964884809,
    "wind_generated": 60.33482141,
    "marketplace_to_demand": 147.8669435,
    "grid_to_demand": 34060.50781,
    "govt_battery_charge": 64.18865438
  },
  {
    "timestamp": "31/07/24 16:00",
    "demand": 32685.81146,
    "solar_generated": 15.69521039,
    "wind_generated": 74.68421562,
    "marketplace_to_demand": 183.1249574,
    "grid_to_demand": 32146.88327,
    "govt_battery_charge": 74.25213021
  },
  {
    "timestamp": "31/07/24 17:00",
    "demand": 30353.96517,
    "solar_generated": 8.395349732,
    "wind_generated": 80.30093879,
    "marketplace_to_demand": 128.6348659,
    "grid_to_demand": 32053.4704,
    "govt_battery_charge": 55.80249623
  },
  {
    "timestamp": "31/07/24 18:00",
    "demand": 32511.98084,
    "solar_generated": 0,
    "wind_generated": 58.19977489,
    "marketplace_to_demand": 197.6526488,
    "grid_to_demand": 31473.87126,
    "govt_battery_charge": 86.69893597
  },
  {
    "timestamp": "31/07/24 19:00",
    "demand": 30488.54333,
    "solar_generated": 0,
    "wind_generated": 60.66671108,
    "marketplace_to_demand": 175.2972631,
    "grid_to_demand": 36317.47513,
    "govt_battery_charge": 64.25485065
  },
  {
    "timestamp": "31/07/24 20:00",
    "demand": 30598.22056,
    "solar_generated": 0,
    "wind_generated": 67.74167419,
    "marketplace_to_demand": 16.07836284,
    "grid_to_demand": 31878.39118,
    "govt_battery_charge": 29.68276523
  },
  {
    "timestamp": "31/07/24 21:00",
    "demand": 33486.08993,
    "solar_generated": 0,
    "wind_generated": 71.06390859,
    "marketplace_to_demand": 94.1465643,
    "grid_to_demand": 34340.91811,
    "govt_battery_charge": 18.66437088
  },
  {
    "timestamp": "31/07/24 22:00",
    "demand": 35741.50624,
    "solar_generated": 0,
    "wind_generated": 56.22719483,
    "marketplace_to_demand": 46.59751064,
    "grid_to_demand": 34854.73184,
    "govt_battery_charge": 15.7448383
  },
  {
    "timestamp": "31/07/24 23:00",
    "demand": 33283.92056,
    "solar_generated": 0,
    "wind_generated": 61.50138669,
    "marketplace_to_demand": 77.32575186,
    "grid_to_demand": 30893.66846,
    "govt_battery_charge": 43.34348551
  },
  {
    "timestamp": "01/08/24 0:00",
    "demand": 32702.87617,
    "solar_generated": 0,
    "wind_generated": 55.11725726,
    "marketplace_to_demand": 21.06791676,
    "grid_to_demand": 31809.17361,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "01/08/24 1:00",
    "demand": 35425.10509,
    "solar_generated": 0,
    "wind_generated": 75.06385115,
    "marketplace_to_demand": 138.7412366,
    "grid_to_demand": 30205.16063,
    "govt_battery_charge": 18.07142049
  },
  {
    "timestamp": "01/08/24 2:00",
    "demand": 32385.49454,
    "solar_generated": 0,
    "wind_generated": 77.3515962,
    "marketplace_to_demand": 195.989433,
    "grid_to_demand": 34726.95152,
    "govt_battery_charge": 34.00328706
  },
  {
    "timestamp": "01/08/24 3:00",
    "demand": 35089.6341,
    "solar_generated": 0,
    "wind_generated": 79.69713823,
    "marketplace_to_demand": 91.1861064,
    "grid_to_demand": 31962.96662,
    "govt_battery_charge": 57.33568783
  },
  {
    "timestamp": "01/08/24 4:00",
    "demand": 35098.89044,
    "solar_generated": 0,
    "wind_generated": 57.69019169,
    "marketplace_to_demand": 143.4740712,
    "grid_to_demand": 32293.63079,
    "govt_battery_charge": 52.55943444
  },
  {
    "timestamp": "01/08/24 5:00",
    "demand": 30170.76239,
    "solar_generated": 0,
    "wind_generated": 73.50679692,
    "marketplace_to_demand": 23.77611077,
    "grid_to_demand": 30913.90865,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "01/08/24 6:00",
    "demand": 31389.6698,
    "solar_generated": 16.49412442,
    "wind_generated": 77.28211046,
    "marketplace_to_demand": 3.974598204,
    "grid_to_demand": 35969.97784,
    "govt_battery_charge": 92.45890393
  },
  {
    "timestamp": "01/08/24 7:00",
    "demand": 30439.09816,
    "solar_generated": 19.13864369,
    "wind_generated": 68.05603206,
    "marketplace_to_demand": 131.8181171,
    "grid_to_demand": 34917.08696,
    "govt_battery_charge": 3.987545887
  },
  {
    "timestamp": "01/08/24 8:00",
    "demand": 35036.47271,
    "solar_generated": 17.15709746,
    "wind_generated": 64.52539095,
    "marketplace_to_demand": 18.22903024,
    "grid_to_demand": 31757.72048,
    "govt_battery_charge": 36.67214564
  },
  {
    "timestamp": "01/08/24 9:00",
    "demand": 34965.65754,
    "solar_generated": 18.93244444,
    "wind_generated": 78.06887911,
    "marketplace_to_demand": 87.61439447,
    "grid_to_demand": 36387.73892,
    "govt_battery_charge": 61.9672571
  },
  {
    "timestamp": "01/08/24 10:00",
    "demand": 36307.85794,
    "solar_generated": 16.31589485,
    "wind_generated": 77.5435383,
    "marketplace_to_demand": 3.241851509,
    "grid_to_demand": 34975.45414,
    "govt_battery_charge": 22.99656091
  },
  {
    "timestamp": "01/08/24 11:00",
    "demand": 35907.37433,
    "solar_generated": 9.64200954,
    "wind_generated": 74.99333945,
    "marketplace_to_demand": 50.5213305,
    "grid_to_demand": 34429.03859,
    "govt_battery_charge": 26.57537371
  },
  {
    "timestamp": "01/08/24 12:00",
    "demand": 30375.99896,
    "solar_generated": 14.14064252,
    "wind_generated": 67.64609112,
    "marketplace_to_demand": 188.8589621,
    "grid_to_demand": 32983.20508,
    "govt_battery_charge": 56.67782308
  },
  {
    "timestamp": "01/08/24 13:00",
    "demand": 32236.31491,
    "solar_generated": 16.82579082,
    "wind_generated": 68.60290132,
    "marketplace_to_demand": 110.3481242,
    "grid_to_demand": 34391.95205,
    "govt_battery_charge": 6.951175208
  },
  {
    "timestamp": "01/08/24 14:00",
    "demand": 34457.3526,
    "solar_generated": 18.1197548,
    "wind_generated": 66.64144772,
    "marketplace_to_demand": 11.96075292,
    "grid_to_demand": 34155.34783,
    "govt_battery_charge": 94.00098651
  },
  {
    "timestamp": "01/08/24 15:00",
    "demand": 31377.28862,
    "solar_generated": 20.72612159,
    "wind_generated": 62.33371575,
    "marketplace_to_demand": 177.0463179,
    "grid_to_demand": 31344.71722,
    "govt_battery_charge": 77.38433891
  },
  {
    "timestamp": "01/08/24 16:00",
    "demand": 36290.87873,
    "solar_generated": 6.009984804,
    "wind_generated": 70.69329164,
    "marketplace_to_demand": 189.4311547,
    "grid_to_demand": 35366.41055,
    "govt_battery_charge": 42.75276789
  },
  {
    "timestamp": "01/08/24 17:00",
    "demand": 30685.78148,
    "solar_generated": 10.46095894,
    "wind_generated": 66.06884852,
    "marketplace_to_demand": 97.56256998,
    "grid_to_demand": 35912.82335,
    "govt_battery_charge": 3.722432712
  },
  {
    "timestamp": "01/08/24 18:00",
    "demand": 30410.66818,
    "solar_generated": 0,
    "wind_generated": 53.6810477,
    "marketplace_to_demand": 85.28949947,
    "grid_to_demand": 35803.11844,
    "govt_battery_charge": 36.34772472
  },
  {
    "timestamp": "01/08/24 19:00",
    "demand": 30559.27786,
    "solar_generated": 0,
    "wind_generated": 71.95091728,
    "marketplace_to_demand": 171.4519151,
    "grid_to_demand": 30369.24787,
    "govt_battery_charge": 94.42003038
  },
  {
    "timestamp": "01/08/24 20:00",
    "demand": 34840.798,
    "solar_generated": 0,
    "wind_generated": 70.09002097,
    "marketplace_to_demand": 91.34263216,
    "grid_to_demand": 32140.27959,
    "govt_battery_charge": 57.09728447
  },
  {
    "timestamp": "01/08/24 21:00",
    "demand": 30667.68628,
    "solar_generated": 0,
    "wind_generated": 78.78166667,
    "marketplace_to_demand": 185.7972707,
    "grid_to_demand": 31283.939,
    "govt_battery_charge": 98.69974781
  },
  {
    "timestamp": "01/08/24 22:00",
    "demand": 30936.50866,
    "solar_generated": 0,
    "wind_generated": 77.02315882,
    "marketplace_to_demand": 146.3670645,
    "grid_to_demand": 31750.37398,
    "govt_battery_charge": 72.14405249
  },
  {
    "timestamp": "01/08/24 23:00",
    "demand": 36301.05205,
    "solar_generated": 0,
    "wind_generated": 60.83803917,
    "marketplace_to_demand": 74.84366106,
    "grid_to_demand": 34441.36584,
    "govt_battery_charge": 50.17344645
  },
  {
    "timestamp": "02/08/24 0:00",
    "demand": 32302.51266,
    "solar_generated": 0,
    "wind_generated": 70.94352328,
    "marketplace_to_demand": 125.3782539,
    "grid_to_demand": 33217.97524,
    "govt_battery_charge": 79.47009519
  },
  {
    "timestamp": "02/08/24 1:00",
    "demand": 36447.06064,
    "solar_generated": 0,
    "wind_generated": 58.66403355,
    "marketplace_to_demand": 63.60233881,
    "grid_to_demand": 31062.5358,
    "govt_battery_charge": 31.06424278
  },
  {
    "timestamp": "02/08/24 2:00",
    "demand": 30933.54787,
    "solar_generated": 0,
    "wind_generated": 57.9094113,
    "marketplace_to_demand": 74.13264358,
    "grid_to_demand": 35916.76102,
    "govt_battery_charge": 91.90915595
  },
  {
    "timestamp": "02/08/24 3:00",
    "demand": 34330.82488,
    "solar_generated": 0,
    "wind_generated": 65.53679546,
    "marketplace_to_demand": 192.2417527,
    "grid_to_demand": 32750.82589,
    "govt_battery_charge": 57.30077943
  },
  {
    "timestamp": "02/08/24 4:00",
    "demand": 36146.6034,
    "solar_generated": 0,
    "wind_generated": 58.41923624,
    "marketplace_to_demand": 64.23417028,
    "grid_to_demand": 31110.30781,
    "govt_battery_charge": 63.53250598
  },
  {
    "timestamp": "02/08/24 5:00",
    "demand": 33046.36525,
    "solar_generated": 0,
    "wind_generated": 74.0349579,
    "marketplace_to_demand": 8.912344284,
    "grid_to_demand": 30090.63711,
    "govt_battery_charge": 17.19199958
  },
  {
    "timestamp": "02/08/24 6:00",
    "demand": 31132.93139,
    "solar_generated": 16.57323285,
    "wind_generated": 79.38429248,
    "marketplace_to_demand": 18.98046363,
    "grid_to_demand": 35706.71296,
    "govt_battery_charge": 99.30161988
  },
  {
    "timestamp": "02/08/24 7:00",
    "demand": 35507.0864,
    "solar_generated": 12.72636889,
    "wind_generated": 68.04331724,
    "marketplace_to_demand": 73.19087481,
    "grid_to_demand": 31945.03734,
    "govt_battery_charge": 93.4279308
  },
  {
    "timestamp": "02/08/24 8:00",
    "demand": 34351.90117,
    "solar_generated": 7.915373032,
    "wind_generated": 73.24532058,
    "marketplace_to_demand": 175.7194285,
    "grid_to_demand": 32552.53487,
    "govt_battery_charge": 24.85448175
  },
  {
    "timestamp": "02/08/24 9:00",
    "demand": 34386.68374,
    "solar_generated": 12.33007333,
    "wind_generated": 68.15906214,
    "marketplace_to_demand": 148.8039075,
    "grid_to_demand": 34501.71077,
    "govt_battery_charge": 94.65519368
  },
  {
    "timestamp": "02/08/24 10:00",
    "demand": 30762.56162,
    "solar_generated": 12.81575939,
    "wind_generated": 68.79691607,
    "marketplace_to_demand": 90.37877509,
    "grid_to_demand": 33049.03275,
    "govt_battery_charge": 94.34164481
  },
  {
    "timestamp": "02/08/24 11:00",
    "demand": 36162.27239,
    "solar_generated": 23.57998985,
    "wind_generated": 64.40361584,
    "marketplace_to_demand": 1.04845465,
    "grid_to_demand": 32147.31666,
    "govt_battery_charge": 74.77470464
  },
  {
    "timestamp": "02/08/24 12:00",
    "demand": 31423.76595,
    "solar_generated": 14.85675756,
    "wind_generated": 74.49149963,
    "marketplace_to_demand": 90.19082397,
    "grid_to_demand": 30725.26019,
    "govt_battery_charge": 75.09152792
  },
  {
    "timestamp": "02/08/24 13:00",
    "demand": 30717.9338,
    "solar_generated": 22.43858082,
    "wind_generated": 73.02939184,
    "marketplace_to_demand": 2.125382068,
    "grid_to_demand": 31396.06646,
    "govt_battery_charge": 30.58794277
  },
  {
    "timestamp": "02/08/24 14:00",
    "demand": 32957.8996,
    "solar_generated": 14.47540555,
    "wind_generated": 77.38126743,
    "marketplace_to_demand": 23.39867872,
    "grid_to_demand": 31033.54651,
    "govt_battery_charge": 29.24290121
  },
  {
    "timestamp": "02/08/24 15:00",
    "demand": 31223.36839,
    "solar_generated": 6.403597327,
    "wind_generated": 75.91413259,
    "marketplace_to_demand": 173.7149244,
    "grid_to_demand": 35817.45978,
    "govt_battery_charge": 82.35862284
  },
  {
    "timestamp": "02/08/24 16:00",
    "demand": 35012.70986,
    "solar_generated": 20.74624155,
    "wind_generated": 55.5975175,
    "marketplace_to_demand": 135.0331773,
    "grid_to_demand": 35129.06648,
    "govt_battery_charge": 31.55767616
  },
  {
    "timestamp": "02/08/24 17:00",
    "demand": 32453.65063,
    "solar_generated": 12.51992963,
    "wind_generated": 56.99850692,
    "marketplace_to_demand": 3.841121398,
    "grid_to_demand": 32150.94514,
    "govt_battery_charge": 67.99623182
  },
  {
    "timestamp": "02/08/24 18:00",
    "demand": 36054.69359,
    "solar_generated": 0,
    "wind_generated": 79.32813336,
    "marketplace_to_demand": 9.456524197,
    "grid_to_demand": 33625.26497,
    "govt_battery_charge": 63.43468205
  },
  {
    "timestamp": "02/08/24 19:00",
    "demand": 31642.52688,
    "solar_generated": 0,
    "wind_generated": 63.53847341,
    "marketplace_to_demand": 156.6553572,
    "grid_to_demand": 30757.31565,
    "govt_battery_charge": 59.67166447
  },
  {
    "timestamp": "02/08/24 20:00",
    "demand": 33057.90404,
    "solar_generated": 0,
    "wind_generated": 56.30487396,
    "marketplace_to_demand": 88.84604167,
    "grid_to_demand": 31986.98298,
    "govt_battery_charge": 71.01536771
  },
  {
    "timestamp": "02/08/24 21:00",
    "demand": 36353.86183,
    "solar_generated": 0,
    "wind_generated": 78.82719055,
    "marketplace_to_demand": 112.6818148,
    "grid_to_demand": 34609.78232,
    "govt_battery_charge": 55.83132409
  },
  {
    "timestamp": "02/08/24 22:00",
    "demand": 35186.20125,
    "solar_generated": 0,
    "wind_generated": 64.48082214,
    "marketplace_to_demand": 2.515520183,
    "grid_to_demand": 33968.48635,
    "govt_battery_charge": 60.49519897
  },
  {
    "timestamp": "02/08/24 23:00",
    "demand": 35444.89289,
    "solar_generated": 0,
    "wind_generated": 64.20173455,
    "marketplace_to_demand": 93.7895465,
    "grid_to_demand": 35136.69743,
    "govt_battery_charge": 38.37615263
  },
  {
    "timestamp": "03/08/24 0:00",
    "demand": 31689.41186,
    "solar_generated": 0,
    "wind_generated": 59.91532842,
    "marketplace_to_demand": 78.06910353,
    "grid_to_demand": 30490.95232,
    "govt_battery_charge": 52.5015486
  },
  {
    "timestamp": "03/08/24 1:00",
    "demand": 30942.8076,
    "solar_generated": 0,
    "wind_generated": 69.42030847,
    "marketplace_to_demand": 5.527011761,
    "grid_to_demand": 31402.77128,
    "govt_battery_charge": 9.301098746
  },
  {
    "timestamp": "03/08/24 2:00",
    "demand": 32264.48261,
    "solar_generated": 0,
    "wind_generated": 69.65618543,
    "marketplace_to_demand": 84.02524914,
    "grid_to_demand": 33873.56214,
    "govt_battery_charge": 91.43850297
  },
  {
    "timestamp": "03/08/24 3:00",
    "demand": 34307.75969,
    "solar_generated": 0,
    "wind_generated": 54.6256571,
    "marketplace_to_demand": 143.4808032,
    "grid_to_demand": 32567.3277,
    "govt_battery_charge": 46.82593703
  },
  {
    "timestamp": "03/08/24 4:00",
    "demand": 29978.24038,
    "solar_generated": 0,
    "wind_generated": 59.06978779,
    "marketplace_to_demand": 178.4818341,
    "grid_to_demand": 32157.51447,
    "govt_battery_charge": 16.22735156
  },
  {
    "timestamp": "03/08/24 5:00",
    "demand": 31688.58896,
    "solar_generated": 0,
    "wind_generated": 73.08693077,
    "marketplace_to_demand": 18.3700706,
    "grid_to_demand": 31255.89458,
    "govt_battery_charge": 11.21139887
  },
  {
    "timestamp": "03/08/24 6:00",
    "demand": 35195.98102,
    "solar_generated": 22.42341587,
    "wind_generated": 66.59673014,
    "marketplace_to_demand": 187.5518245,
    "grid_to_demand": 33492.76597,
    "govt_battery_charge": 31.69816747
  },
  {
    "timestamp": "03/08/24 7:00",
    "demand": 36213.52842,
    "solar_generated": 10.0670014,
    "wind_generated": 77.09967355,
    "marketplace_to_demand": 57.51348707,
    "grid_to_demand": 32125.11001,
    "govt_battery_charge": 80.50095654
  },
  {
    "timestamp": "03/08/24 8:00",
    "demand": 35878.31849,
    "solar_generated": 10.63729551,
    "wind_generated": 62.8969274,
    "marketplace_to_demand": 180.4453104,
    "grid_to_demand": 33773.97588,
    "govt_battery_charge": 40.45156985
  },
  {
    "timestamp": "03/08/24 9:00",
    "demand": 33375.96191,
    "solar_generated": 18.35284352,
    "wind_generated": 62.1638523,
    "marketplace_to_demand": 135.8630704,
    "grid_to_demand": 34237.40669,
    "govt_battery_charge": 64.86409974
  },
  {
    "timestamp": "03/08/24 10:00",
    "demand": 33506.05944,
    "solar_generated": 22.21929342,
    "wind_generated": 77.43612391,
    "marketplace_to_demand": 50.41196314,
    "grid_to_demand": 34172.24285,
    "govt_battery_charge": 74.86612841
  },
  {
    "timestamp": "03/08/24 11:00",
    "demand": 35094.30638,
    "solar_generated": 21.580913,
    "wind_generated": 54.89914742,
    "marketplace_to_demand": 132.7924407,
    "grid_to_demand": 32444.48858,
    "govt_battery_charge": 4.810512216
  },
  {
    "timestamp": "03/08/24 12:00",
    "demand": 35511.60881,
    "solar_generated": 9.447598316,
    "wind_generated": 64.96746538,
    "marketplace_to_demand": 180.8227377,
    "grid_to_demand": 31453.14865,
    "govt_battery_charge": 23.78815957
  },
  {
    "timestamp": "03/08/24 13:00",
    "demand": 31772.52858,
    "solar_generated": 6.59077338,
    "wind_generated": 73.17088379,
    "marketplace_to_demand": 191.9372325,
    "grid_to_demand": 31036.75311,
    "govt_battery_charge": 83.84115949
  },
  {
    "timestamp": "03/08/24 14:00",
    "demand": 34736.70943,
    "solar_generated": 13.39195152,
    "wind_generated": 58.09229342,
    "marketplace_to_demand": 21.3626633,
    "grid_to_demand": 32980.91077,
    "govt_battery_charge": 81.6855532
  },
  {
    "timestamp": "03/08/24 15:00",
    "demand": 30573.75281,
    "solar_generated": 8.155997628,
    "wind_generated": 54.25416608,
    "marketplace_to_demand": 49.62365502,
    "grid_to_demand": 31838.48565,
    "govt_battery_charge": 49.95444462
  },
  {
    "timestamp": "03/08/24 16:00",
    "demand": 30630.21618,
    "solar_generated": 10.36819571,
    "wind_generated": 80.26458444,
    "marketplace_to_demand": 23.79269975,
    "grid_to_demand": 35285.76049,
    "govt_battery_charge": 58.83810517
  },
  {
    "timestamp": "03/08/24 17:00",
    "demand": 30826.11223,
    "solar_generated": 23.51259838,
    "wind_generated": 62.02433139,
    "marketplace_to_demand": 30.68759894,
    "grid_to_demand": 30166.19913,
    "govt_battery_charge": 27.27161771
  },
  {
    "timestamp": "03/08/24 18:00",
    "demand": 34665.43229,
    "solar_generated": 0,
    "wind_generated": 63.68272417,
    "marketplace_to_demand": 24.0213896,
    "grid_to_demand": 35302.11453,
    "govt_battery_charge": 11.79564866
  },
  {
    "timestamp": "03/08/24 19:00",
    "demand": 31262.97333,
    "solar_generated": 0,
    "wind_generated": 59.46327754,
    "marketplace_to_demand": 42.64060138,
    "grid_to_demand": 35218.43771,
    "govt_battery_charge": 88.71475388
  },
  {
    "timestamp": "03/08/24 20:00",
    "demand": 34944.95572,
    "solar_generated": 0,
    "wind_generated": 68.8127776,
    "marketplace_to_demand": 121.4216792,
    "grid_to_demand": 32900.08667,
    "govt_battery_charge": 42.67877021
  },
  {
    "timestamp": "03/08/24 21:00",
    "demand": 36374.87971,
    "solar_generated": 0,
    "wind_generated": 58.2341051,
    "marketplace_to_demand": 188.3630135,
    "grid_to_demand": 36201.46277,
    "govt_battery_charge": 67.45429004
  },
  {
    "timestamp": "03/08/24 22:00",
    "demand": 33470.82477,
    "solar_generated": 0,
    "wind_generated": 79.04332379,
    "marketplace_to_demand": 140.7220849,
    "grid_to_demand": 36279.71042,
    "govt_battery_charge": 3.751844739
  },
  {
    "timestamp": "03/08/24 23:00",
    "demand": 33987.98061,
    "solar_generated": 0,
    "wind_generated": 72.68592975,
    "marketplace_to_demand": 92.89404324,
    "grid_to_demand": 32327.74646,
    "govt_battery_charge": 98.76910863
  },
  {
    "timestamp": "04/08/24 0:00",
    "demand": 35951.8224,
    "solar_generated": 0,
    "wind_generated": 68.49833596,
    "marketplace_to_demand": 191.3087295,
    "grid_to_demand": 30982.88025,
    "govt_battery_charge": 48.80200861
  },
  {
    "timestamp": "04/08/24 1:00",
    "demand": 31111.97329,
    "solar_generated": 0,
    "wind_generated": 64.06257621,
    "marketplace_to_demand": 37.14286474,
    "grid_to_demand": 35668.66073,
    "govt_battery_charge": 77.59188625
  },
  {
    "timestamp": "04/08/24 2:00",
    "demand": 32448.85799,
    "solar_generated": 0,
    "wind_generated": 68.28454556,
    "marketplace_to_demand": 188.6028156,
    "grid_to_demand": 31981.06058,
    "govt_battery_charge": 64.65467647
  },
  {
    "timestamp": "04/08/24 3:00",
    "demand": 32680.6591,
    "solar_generated": 0,
    "wind_generated": 65.25034142,
    "marketplace_to_demand": 20.6368922,
    "grid_to_demand": 30139.74457,
    "govt_battery_charge": 55.45589328
  },
  {
    "timestamp": "04/08/24 4:00",
    "demand": 30196.94701,
    "solar_generated": 0,
    "wind_generated": 80.02251052,
    "marketplace_to_demand": 123.1110869,
    "grid_to_demand": 32261.40923,
    "govt_battery_charge": 78.60944199
  },
  {
    "timestamp": "04/08/24 5:00",
    "demand": 32434.92216,
    "solar_generated": 0,
    "wind_generated": 57.36722407,
    "marketplace_to_demand": 134.0683926,
    "grid_to_demand": 30000.40671,
    "govt_battery_charge": 4.195376905
  },
  {
    "timestamp": "04/08/24 6:00",
    "demand": 32629.45657,
    "solar_generated": 7.536065147,
    "wind_generated": 62.67715062,
    "marketplace_to_demand": 191.8397703,
    "grid_to_demand": 35459.6481,
    "govt_battery_charge": 56.96857263
  },
  {
    "timestamp": "04/08/24 7:00",
    "demand": 31699.48865,
    "solar_generated": 17.3775004,
    "wind_generated": 71.47917536,
    "marketplace_to_demand": 46.48262807,
    "grid_to_demand": 36354.32571,
    "govt_battery_charge": 71.79948099
  },
  {
    "timestamp": "04/08/24 8:00",
    "demand": 30058.64937,
    "solar_generated": 19.92102246,
    "wind_generated": 76.43673176,
    "marketplace_to_demand": 113.1946189,
    "grid_to_demand": 32787.65802,
    "govt_battery_charge": 89.47587653
  },
  {
    "timestamp": "04/08/24 9:00",
    "demand": 30882.59819,
    "solar_generated": 16.47922406,
    "wind_generated": 61.71217106,
    "marketplace_to_demand": 157.4108697,
    "grid_to_demand": 31457.79685,
    "govt_battery_charge": 60.69194912
  },
  {
    "timestamp": "04/08/24 10:00",
    "demand": 30894.31218,
    "solar_generated": 8.021201503,
    "wind_generated": 76.02511123,
    "marketplace_to_demand": 7.598397643,
    "grid_to_demand": 32193.86168,
    "govt_battery_charge": 61.42217199
  },
  {
    "timestamp": "04/08/24 11:00",
    "demand": 35755.01698,
    "solar_generated": 12.83354239,
    "wind_generated": 74.78556344,
    "marketplace_to_demand": 123.0353582,
    "grid_to_demand": 35135.99941,
    "govt_battery_charge": 75.57365022
  },
  {
    "timestamp": "04/08/24 12:00",
    "demand": 32662.76549,
    "solar_generated": 8.062413196,
    "wind_generated": 66.25908944,
    "marketplace_to_demand": 108.6526384,
    "grid_to_demand": 32697.22679,
    "govt_battery_charge": 86.96579902
  },
  {
    "timestamp": "04/08/24 13:00",
    "demand": 31683.20087,
    "solar_generated": 19.46645057,
    "wind_generated": 75.75940815,
    "marketplace_to_demand": 77.37663304,
    "grid_to_demand": 36105.70318,
    "govt_battery_charge": 28.69013725
  },
  {
    "timestamp": "04/08/24 14:00",
    "demand": 30036.84337,
    "solar_generated": 20.54945257,
    "wind_generated": 60.77735404,
    "marketplace_to_demand": 114.0162565,
    "grid_to_demand": 31451.17381,
    "govt_battery_charge": 29.29096737
  },
  {
    "timestamp": "04/08/24 15:00",
    "demand": 30748.96805,
    "solar_generated": 12.57800856,
    "wind_generated": 56.96866157,
    "marketplace_to_demand": 36.75133384,
    "grid_to_demand": 33523.58099,
    "govt_battery_charge": 71.15625994
  },
  {
    "timestamp": "04/08/24 16:00",
    "demand": 31596.10542,
    "solar_generated": 21.46569189,
    "wind_generated": 68.32456302,
    "marketplace_to_demand": 80.65492965,
    "grid_to_demand": 30219.03845,
    "govt_battery_charge": 86.51217259
  },
  {
    "timestamp": "04/08/24 17:00",
    "demand": 33785.2374,
    "solar_generated": 19.63846158,
    "wind_generated": 59.50304779,
    "marketplace_to_demand": 149.0438539,
    "grid_to_demand": 33970.0622,
    "govt_battery_charge": 45.28512091
  },
  {
    "timestamp": "04/08/24 18:00",
    "demand": 32159.34527,
    "solar_generated": 0,
    "wind_generated": 80.0086558,
    "marketplace_to_demand": 121.550565,
    "grid_to_demand": 35521.43509,
    "govt_battery_charge": 54.90500107
  },
  {
    "timestamp": "04/08/24 19:00",
    "demand": 33261.42195,
    "solar_generated": 0,
    "wind_generated": 71.31371092,
    "marketplace_to_demand": 82.69366246,
    "grid_to_demand": 29945.52198,
    "govt_battery_charge": 35.06561076
  },
  {
    "timestamp": "04/08/24 20:00",
    "demand": 33297.47555,
    "solar_generated": 0,
    "wind_generated": 58.83424545,
    "marketplace_to_demand": 60.63608732,
    "grid_to_demand": 35299.69153,
    "govt_battery_charge": 7.020077718
  },
  {
    "timestamp": "04/08/24 21:00",
    "demand": 34595.1437,
    "solar_generated": 0,
    "wind_generated": 78.38690133,
    "marketplace_to_demand": 127.9589502,
    "grid_to_demand": 29915.2438,
    "govt_battery_charge": 68.20029327
  },
  {
    "timestamp": "04/08/24 22:00",
    "demand": 34589.5391,
    "solar_generated": 0,
    "wind_generated": 75.75044687,
    "marketplace_to_demand": 191.7678795,
    "grid_to_demand": 31758.73789,
    "govt_battery_charge": 15.94577792
  },
  {
    "timestamp": "04/08/24 23:00",
    "demand": 30161.52581,
    "solar_generated": 0,
    "wind_generated": 56.08257445,
    "marketplace_to_demand": 199.0139244,
    "grid_to_demand": 34491.60132,
    "govt_battery_charge": 72.40811717
  },
  {
    "timestamp": "05/08/24 0:00",
    "demand": 32668.37312,
    "solar_generated": 0,
    "wind_generated": 77.96475816,
    "marketplace_to_demand": 47.31991079,
    "grid_to_demand": 36210.93385,
    "govt_battery_charge": 80.49215549
  },
  {
    "timestamp": "05/08/24 1:00",
    "demand": 35874.42612,
    "solar_generated": 0,
    "wind_generated": 73.43372784,
    "marketplace_to_demand": 32.76002773,
    "grid_to_demand": 33579.44606,
    "govt_battery_charge": 48.88305665
  },
  {
    "timestamp": "05/08/24 2:00",
    "demand": 31411.10618,
    "solar_generated": 0,
    "wind_generated": 59.82695375,
    "marketplace_to_demand": 23.35979209,
    "grid_to_demand": 33880.86513,
    "govt_battery_charge": 20.50126928
  },
  {
    "timestamp": "05/08/24 3:00",
    "demand": 35666.83861,
    "solar_generated": 0,
    "wind_generated": 71.60590186,
    "marketplace_to_demand": 4.138663496,
    "grid_to_demand": 31718.30052,
    "govt_battery_charge": 43.89205507
  },
  {
    "timestamp": "05/08/24 4:00",
    "demand": 32743.60193,
    "solar_generated": 0,
    "wind_generated": 57.73107495,
    "marketplace_to_demand": 123.6636357,
    "grid_to_demand": 32315.03962,
    "govt_battery_charge": 7.031160424
  },
  {
    "timestamp": "05/08/24 5:00",
    "demand": 33484.46351,
    "solar_generated": 0,
    "wind_generated": 71.71408383,
    "marketplace_to_demand": 107.9571229,
    "grid_to_demand": 32448.78953,
    "govt_battery_charge": 74.13684627
  },
  {
    "timestamp": "05/08/24 6:00",
    "demand": 32902.91753,
    "solar_generated": 9.247295292,
    "wind_generated": 76.84543721,
    "marketplace_to_demand": 162.3373402,
    "grid_to_demand": 30565.12271,
    "govt_battery_charge": 31.49259789
  },
  {
    "timestamp": "05/08/24 7:00",
    "demand": 36518.7029,
    "solar_generated": 10.11850247,
    "wind_generated": 80.0883936,
    "marketplace_to_demand": 68.12189046,
    "grid_to_demand": 33655.25056,
    "govt_battery_charge": 20.59955748
  },
  {
    "timestamp": "05/08/24 8:00",
    "demand": 35035.13273,
    "solar_generated": 7.197416464,
    "wind_generated": 56.28605255,
    "marketplace_to_demand": 22.78267136,
    "grid_to_demand": 34498.01835,
    "govt_battery_charge": 98.67146904
  },
  {
    "timestamp": "05/08/24 9:00",
    "demand": 31738.88993,
    "solar_generated": 10.42617033,
    "wind_generated": 66.62007684,
    "marketplace_to_demand": 28.02077625,
    "grid_to_demand": 35621.10634,
    "govt_battery_charge": 35.07059188
  },
  {
    "timestamp": "05/08/24 10:00",
    "demand": 30718.65332,
    "solar_generated": 14.92383071,
    "wind_generated": 69.84505403,
    "marketplace_to_demand": 93.29512104,
    "grid_to_demand": 31132.32491,
    "govt_battery_charge": 69.30356651
  },
  {
    "timestamp": "05/08/24 11:00",
    "demand": 31717.07284,
    "solar_generated": 16.75767236,
    "wind_generated": 53.80492709,
    "marketplace_to_demand": 24.70387003,
    "grid_to_demand": 30976.58608,
    "govt_battery_charge": 55.97499973
  },
  {
    "timestamp": "05/08/24 12:00",
    "demand": 30222.13547,
    "solar_generated": 15.88854492,
    "wind_generated": 74.78007489,
    "marketplace_to_demand": 100.7182371,
    "grid_to_demand": 32240.08598,
    "govt_battery_charge": 13.26408039
  },
  {
    "timestamp": "05/08/24 13:00",
    "demand": 32839.60814,
    "solar_generated": 20.84580323,
    "wind_generated": 59.44343949,
    "marketplace_to_demand": 71.07977587,
    "grid_to_demand": 34016.89239,
    "govt_battery_charge": 70.67068099
  },
  {
    "timestamp": "05/08/24 14:00",
    "demand": 30489.48636,
    "solar_generated": 12.93606973,
    "wind_generated": 70.25693544,
    "marketplace_to_demand": 58.49896501,
    "grid_to_demand": 31104.07484,
    "govt_battery_charge": 30.31185301
  },
  {
    "timestamp": "05/08/24 15:00",
    "demand": 33491.32492,
    "solar_generated": 10.21719465,
    "wind_generated": 77.76496062,
    "marketplace_to_demand": 25.44397684,
    "grid_to_demand": 33958.8512,
    "govt_battery_charge": 49.38134884
  },
  {
    "timestamp": "05/08/24 16:00",
    "demand": 33389.35371,
    "solar_generated": 11.54651439,
    "wind_generated": 63.08383598,
    "marketplace_to_demand": 178.6754437,
    "grid_to_demand": 30567.29692,
    "govt_battery_charge": 47.64445263
  },
  {
    "timestamp": "05/08/24 17:00",
    "demand": 35034.56483,
    "solar_generated": 21.01843583,
    "wind_generated": 58.66348564,
    "marketplace_to_demand": 6.64862694,
    "grid_to_demand": 36361.62625,
    "govt_battery_charge": 14.84338373
  },
  {
    "timestamp": "05/08/24 18:00",
    "demand": 32982.36279,
    "solar_generated": 0,
    "wind_generated": 59.88610335,
    "marketplace_to_demand": 128.1930368,
    "grid_to_demand": 30127.42982,
    "govt_battery_charge": 67.91455745
  },
  {
    "timestamp": "05/08/24 19:00",
    "demand": 34095.25817,
    "solar_generated": 0,
    "wind_generated": 78.70226381,
    "marketplace_to_demand": 1.39973599,
    "grid_to_demand": 35685.85611,
    "govt_battery_charge": 43.34517017
  },
  {
    "timestamp": "05/08/24 20:00",
    "demand": 31032.19122,
    "solar_generated": 0,
    "wind_generated": 54.77480032,
    "marketplace_to_demand": 178.0894344,
    "grid_to_demand": 33220.882,
    "govt_battery_charge": 33.2914628
  },
  {
    "timestamp": "05/08/24 21:00",
    "demand": 32626.01191,
    "solar_generated": 0,
    "wind_generated": 75.65970776,
    "marketplace_to_demand": 41.38985533,
    "grid_to_demand": 32636.73711,
    "govt_battery_charge": 88.97658094
  },
  {
    "timestamp": "05/08/24 22:00",
    "demand": 34675.56266,
    "solar_generated": 0,
    "wind_generated": 70.25185702,
    "marketplace_to_demand": 142.982954,
    "grid_to_demand": 31454.17657,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "05/08/24 23:00",
    "demand": 34177.25549,
    "solar_generated": 0,
    "wind_generated": 65.5553799,
    "marketplace_to_demand": 59.87019581,
    "grid_to_demand": 30854.44001,
    "govt_battery_charge": 39.99235838
  },
  {
    "timestamp": "06/08/24 0:00",
    "demand": 33995.61417,
    "solar_generated": 0,
    "wind_generated": 67.55520333,
    "marketplace_to_demand": 98.85380452,
    "grid_to_demand": 36313.60214,
    "govt_battery_charge": 37.25459594
  },
  {
    "timestamp": "06/08/24 1:00",
    "demand": 35007.2648,
    "solar_generated": 0,
    "wind_generated": 62.06450314,
    "marketplace_to_demand": 156.1439886,
    "grid_to_demand": 30291.50471,
    "govt_battery_charge": 97.13339179
  },
  {
    "timestamp": "06/08/24 2:00",
    "demand": 35352.68352,
    "solar_generated": 0,
    "wind_generated": 58.13288411,
    "marketplace_to_demand": 156.8049183,
    "grid_to_demand": 34490.4398,
    "govt_battery_charge": 41.97814187
  },
  {
    "timestamp": "06/08/24 3:00",
    "demand": 33275.26693,
    "solar_generated": 0,
    "wind_generated": 67.14867973,
    "marketplace_to_demand": 12.14857074,
    "grid_to_demand": 34459.87482,
    "govt_battery_charge": 93.51601594
  },
  {
    "timestamp": "06/08/24 4:00",
    "demand": 30977.54825,
    "solar_generated": 0,
    "wind_generated": 76.97595876,
    "marketplace_to_demand": 117.4331309,
    "grid_to_demand": 36427.26219,
    "govt_battery_charge": 28.56416613
  },
  {
    "timestamp": "06/08/24 5:00",
    "demand": 33280.10212,
    "solar_generated": 0,
    "wind_generated": 78.64138013,
    "marketplace_to_demand": 189.5509283,
    "grid_to_demand": 34698.41637,
    "govt_battery_charge": 52.32709023
  },
  {
    "timestamp": "06/08/24 6:00",
    "demand": 30800.08857,
    "solar_generated": 9.55259684,
    "wind_generated": 59.90052988,
    "marketplace_to_demand": 42.43892419,
    "grid_to_demand": 35330.79476,
    "govt_battery_charge": 35.20103803
  },
  {
    "timestamp": "06/08/24 7:00",
    "demand": 31745.89454,
    "solar_generated": 16.57439985,
    "wind_generated": 67.57365028,
    "marketplace_to_demand": 108.4727273,
    "grid_to_demand": 30755.12235,
    "govt_battery_charge": 66.60280089
  },
  {
    "timestamp": "06/08/24 8:00",
    "demand": 30246.14866,
    "solar_generated": 13.73429697,
    "wind_generated": 54.77697139,
    "marketplace_to_demand": 90.70423502,
    "grid_to_demand": 32687.31844,
    "govt_battery_charge": 9.28021079
  },
  {
    "timestamp": "06/08/24 9:00",
    "demand": 34770.12877,
    "solar_generated": 16.07384651,
    "wind_generated": 74.57757922,
    "marketplace_to_demand": 166.3579481,
    "grid_to_demand": 30595.48599,
    "govt_battery_charge": 35.95738082
  },
  {
    "timestamp": "06/08/24 10:00",
    "demand": 36251.81114,
    "solar_generated": 11.83159436,
    "wind_generated": 68.54964786,
    "marketplace_to_demand": 198.0822544,
    "grid_to_demand": 35488.57171,
    "govt_battery_charge": 88.02161868
  },
  {
    "timestamp": "06/08/24 11:00",
    "demand": 33216.23255,
    "solar_generated": 7.756694811,
    "wind_generated": 72.22327415,
    "marketplace_to_demand": 182.5816367,
    "grid_to_demand": 30671.89074,
    "govt_battery_charge": 41.63152258
  },
  {
    "timestamp": "06/08/24 12:00",
    "demand": 33096.70368,
    "solar_generated": 13.60517681,
    "wind_generated": 77.25919923,
    "marketplace_to_demand": 123.0641592,
    "grid_to_demand": 34322.53999,
    "govt_battery_charge": 30.23113972
  },
  {
    "timestamp": "06/08/24 13:00",
    "demand": 34786.76746,
    "solar_generated": 9.92733516,
    "wind_generated": 73.71522877,
    "marketplace_to_demand": 43.50469107,
    "grid_to_demand": 36112.98468,
    "govt_battery_charge": 48.88068465
  },
  {
    "timestamp": "06/08/24 14:00",
    "demand": 32390.25939,
    "solar_generated": 6.127759213,
    "wind_generated": 74.96880545,
    "marketplace_to_demand": 136.8466468,
    "grid_to_demand": 33708.5019,
    "govt_battery_charge": 3.722782296
  },
  {
    "timestamp": "06/08/24 15:00",
    "demand": 34548.26667,
    "solar_generated": 21.40596135,
    "wind_generated": 78.15498432,
    "marketplace_to_demand": 122.4719726,
    "grid_to_demand": 34434.82256,
    "govt_battery_charge": 76.99909549
  },
  {
    "timestamp": "06/08/24 16:00",
    "demand": 30718.6596,
    "solar_generated": 22.95693655,
    "wind_generated": 70.54414736,
    "marketplace_to_demand": 113.798127,
    "grid_to_demand": 35116.65527,
    "govt_battery_charge": 94.1349358
  },
  {
    "timestamp": "06/08/24 17:00",
    "demand": 35343.59053,
    "solar_generated": 13.85091716,
    "wind_generated": 70.27942947,
    "marketplace_to_demand": 135.4176345,
    "grid_to_demand": 34999.3751,
    "govt_battery_charge": 58.75111344
  },
  {
    "timestamp": "06/08/24 18:00",
    "demand": 32812.46098,
    "solar_generated": 0,
    "wind_generated": 60.97106122,
    "marketplace_to_demand": 87.14760828,
    "grid_to_demand": 30784.03928,
    "govt_battery_charge": 87.97357688
  },
  {
    "timestamp": "06/08/24 19:00",
    "demand": 34510.25882,
    "solar_generated": 0,
    "wind_generated": 66.28926694,
    "marketplace_to_demand": 111.4305445,
    "grid_to_demand": 35187.23839,
    "govt_battery_charge": 63.06473106
  },
  {
    "timestamp": "06/08/24 20:00",
    "demand": 36262.72745,
    "solar_generated": 0,
    "wind_generated": 62.47861499,
    "marketplace_to_demand": 179.87407,
    "grid_to_demand": 31673.87484,
    "govt_battery_charge": 46.23226892
  },
  {
    "timestamp": "06/08/24 21:00",
    "demand": 31018.13495,
    "solar_generated": 0,
    "wind_generated": 54.2234434,
    "marketplace_to_demand": 89.92968331,
    "grid_to_demand": 35179.70623,
    "govt_battery_charge": 47.05193591
  },
  {
    "timestamp": "06/08/24 22:00",
    "demand": 32656.29764,
    "solar_generated": 0,
    "wind_generated": 66.97342573,
    "marketplace_to_demand": 20.28086564,
    "grid_to_demand": 30783.56861,
    "govt_battery_charge": 52.85350779
  },
  {
    "timestamp": "06/08/24 23:00",
    "demand": 36346.10809,
    "solar_generated": 0,
    "wind_generated": 77.8976224,
    "marketplace_to_demand": 121.9479082,
    "grid_to_demand": 32174.14829,
    "govt_battery_charge": 80.69374897
  },
  {
    "timestamp": "07/08/24 0:00",
    "demand": 31713.34364,
    "solar_generated": 0,
    "wind_generated": 76.13564237,
    "marketplace_to_demand": 139.5827479,
    "grid_to_demand": 34807.05379,
    "govt_battery_charge": 31.98015091
  },
  {
    "timestamp": "07/08/24 1:00",
    "demand": 35681.20405,
    "solar_generated": 0,
    "wind_generated": 64.8737415,
    "marketplace_to_demand": 16.1939801,
    "grid_to_demand": 30207.88188,
    "govt_battery_charge": 19.3730699
  },
  {
    "timestamp": "07/08/24 2:00",
    "demand": 30570.82994,
    "solar_generated": 0,
    "wind_generated": 67.72236915,
    "marketplace_to_demand": 126.6679112,
    "grid_to_demand": 34457.29709,
    "govt_battery_charge": 75.22525483
  },
  {
    "timestamp": "07/08/24 3:00",
    "demand": 36208.37736,
    "solar_generated": 0,
    "wind_generated": 77.77412684,
    "marketplace_to_demand": 31.69086627,
    "grid_to_demand": 31766.27088,
    "govt_battery_charge": 34.2647561
  },
  {
    "timestamp": "07/08/24 4:00",
    "demand": 34280.20318,
    "solar_generated": 0,
    "wind_generated": 67.49276899,
    "marketplace_to_demand": 49.74020634,
    "grid_to_demand": 35762.82027,
    "govt_battery_charge": 4.029090426
  },
  {
    "timestamp": "07/08/24 5:00",
    "demand": 33213.80193,
    "solar_generated": 0,
    "wind_generated": 68.23072592,
    "marketplace_to_demand": 52.78848551,
    "grid_to_demand": 34712.39514,
    "govt_battery_charge": 48.20084127
  },
  {
    "timestamp": "07/08/24 6:00",
    "demand": 33658.42626,
    "solar_generated": 11.37174439,
    "wind_generated": 60.39007684,
    "marketplace_to_demand": 31.40054381,
    "grid_to_demand": 33091.74546,
    "govt_battery_charge": 1.20787889
  },
  {
    "timestamp": "07/08/24 7:00",
    "demand": 32716.33037,
    "solar_generated": 17.82992688,
    "wind_generated": 60.48142422,
    "marketplace_to_demand": 38.47382921,
    "grid_to_demand": 31715.54893,
    "govt_battery_charge": 64.50963597
  },
  {
    "timestamp": "07/08/24 8:00",
    "demand": 35396.41574,
    "solar_generated": 15.86360551,
    "wind_generated": 54.7759786,
    "marketplace_to_demand": 51.27021656,
    "grid_to_demand": 35417.45361,
    "govt_battery_charge": 19.49897837
  },
  {
    "timestamp": "07/08/24 9:00",
    "demand": 35428.56583,
    "solar_generated": 23.18827568,
    "wind_generated": 66.12019897,
    "marketplace_to_demand": 175.4399726,
    "grid_to_demand": 32541.07727,
    "govt_battery_charge": 93.00407632
  },
  {
    "timestamp": "07/08/24 10:00",
    "demand": 30602.18294,
    "solar_generated": 21.32290057,
    "wind_generated": 80.1425318,
    "marketplace_to_demand": 20.16973574,
    "grid_to_demand": 34962.99456,
    "govt_battery_charge": 85.03561756
  },
  {
    "timestamp": "07/08/24 11:00",
    "demand": 35237.02096,
    "solar_generated": 12.47243536,
    "wind_generated": 72.91756308,
    "marketplace_to_demand": 92.29887763,
    "grid_to_demand": 30208.50109,
    "govt_battery_charge": 38.80514709
  },
  {
    "timestamp": "07/08/24 12:00",
    "demand": 31871.45197,
    "solar_generated": 18.61535799,
    "wind_generated": 65.47324793,
    "marketplace_to_demand": 147.7322419,
    "grid_to_demand": 31115.03343,
    "govt_battery_charge": 85.15358295
  },
  {
    "timestamp": "07/08/24 13:00",
    "demand": 33366.25256,
    "solar_generated": 14.89373159,
    "wind_generated": 62.45749497,
    "marketplace_to_demand": 123.1720446,
    "grid_to_demand": 33968.70184,
    "govt_battery_charge": 50.85776718
  },
  {
    "timestamp": "07/08/24 14:00",
    "demand": 33662.97122,
    "solar_generated": 10.79234003,
    "wind_generated": 77.19149388,
    "marketplace_to_demand": 166.3760171,
    "grid_to_demand": 36175.49445,
    "govt_battery_charge": 58.88901512
  },
  {
    "timestamp": "07/08/24 15:00",
    "demand": 32210.91666,
    "solar_generated": 21.9116296,
    "wind_generated": 71.87057377,
    "marketplace_to_demand": 67.44084233,
    "grid_to_demand": 30896.34705,
    "govt_battery_charge": 46.62937475
  },
  {
    "timestamp": "07/08/24 16:00",
    "demand": 35089.56664,
    "solar_generated": 9.280756352,
    "wind_generated": 76.37183566,
    "marketplace_to_demand": 65.89052181,
    "grid_to_demand": 34928.38727,
    "govt_battery_charge": 19.7448469
  },
  {
    "timestamp": "07/08/24 17:00",
    "demand": 32563.55749,
    "solar_generated": 22.34293841,
    "wind_generated": 62.45418085,
    "marketplace_to_demand": 97.61295655,
    "grid_to_demand": 31499.69515,
    "govt_battery_charge": 30.50561506
  },
  {
    "timestamp": "07/08/24 18:00",
    "demand": 32671.75935,
    "solar_generated": 0,
    "wind_generated": 62.04463711,
    "marketplace_to_demand": 84.36775348,
    "grid_to_demand": 31539.56496,
    "govt_battery_charge": 6.279720361
  },
  {
    "timestamp": "07/08/24 19:00",
    "demand": 36497.85428,
    "solar_generated": 0,
    "wind_generated": 75.74785748,
    "marketplace_to_demand": 93.23541863,
    "grid_to_demand": 35836.35378,
    "govt_battery_charge": 54.25605093
  },
  {
    "timestamp": "07/08/24 20:00",
    "demand": 32180.63764,
    "solar_generated": 0,
    "wind_generated": 67.03634624,
    "marketplace_to_demand": 186.535577,
    "grid_to_demand": 35158.86874,
    "govt_battery_charge": 69.266729
  },
  {
    "timestamp": "07/08/24 21:00",
    "demand": 36512.73363,
    "solar_generated": 0,
    "wind_generated": 75.90997145,
    "marketplace_to_demand": 118.3770417,
    "grid_to_demand": 30455.49261,
    "govt_battery_charge": 24.02854297
  },
  {
    "timestamp": "07/08/24 22:00",
    "demand": 30744.63901,
    "solar_generated": 0,
    "wind_generated": 64.92279101,
    "marketplace_to_demand": 103.4203833,
    "grid_to_demand": 33114.64083,
    "govt_battery_charge": 77.31952724
  },
  {
    "timestamp": "07/08/24 23:00",
    "demand": 35619.78104,
    "solar_generated": 0,
    "wind_generated": 73.55416981,
    "marketplace_to_demand": 22.00862204,
    "grid_to_demand": 33998.6813,
    "govt_battery_charge": 32.83165122
  },
  {
    "timestamp": "08/08/24 0:00",
    "demand": 32568.43943,
    "solar_generated": 0,
    "wind_generated": 73.46633491,
    "marketplace_to_demand": 145.7194542,
    "grid_to_demand": 31161.32189,
    "govt_battery_charge": 9.859097543
  },
  {
    "timestamp": "08/08/24 1:00",
    "demand": 30733.02319,
    "solar_generated": 0,
    "wind_generated": 77.40254558,
    "marketplace_to_demand": 126.9522078,
    "grid_to_demand": 34576.71572,
    "govt_battery_charge": 89.25836255
  },
  {
    "timestamp": "08/08/24 2:00",
    "demand": 35461.87987,
    "solar_generated": 0,
    "wind_generated": 70.20113374,
    "marketplace_to_demand": 98.2820572,
    "grid_to_demand": 34125.13153,
    "govt_battery_charge": 41.04334566
  },
  {
    "timestamp": "08/08/24 3:00",
    "demand": 31108.00454,
    "solar_generated": 0,
    "wind_generated": 66.79100735,
    "marketplace_to_demand": 140.3859133,
    "grid_to_demand": 34304.99724,
    "govt_battery_charge": 98.27837073
  },
  {
    "timestamp": "08/08/24 4:00",
    "demand": 30120.11174,
    "solar_generated": 0,
    "wind_generated": 68.12169867,
    "marketplace_to_demand": 144.6289466,
    "grid_to_demand": 34847.83911,
    "govt_battery_charge": 76.99843744
  },
  {
    "timestamp": "08/08/24 5:00",
    "demand": 34201.6935,
    "solar_generated": 0,
    "wind_generated": 77.08675023,
    "marketplace_to_demand": 171.1073738,
    "grid_to_demand": 31746.18164,
    "govt_battery_charge": 68.45148215
  },
  {
    "timestamp": "08/08/24 6:00",
    "demand": 31160.47008,
    "solar_generated": 19.67731409,
    "wind_generated": 65.83414473,
    "marketplace_to_demand": 68.84938683,
    "grid_to_demand": 35697.94726,
    "govt_battery_charge": 47.74640771
  },
  {
    "timestamp": "08/08/24 7:00",
    "demand": 35063.22065,
    "solar_generated": 7.284136828,
    "wind_generated": 69.34654965,
    "marketplace_to_demand": 100.9663082,
    "grid_to_demand": 35344.72758,
    "govt_battery_charge": 46.72277815
  },
  {
    "timestamp": "08/08/24 8:00",
    "demand": 34808.0249,
    "solar_generated": 23.25103562,
    "wind_generated": 62.80334089,
    "marketplace_to_demand": 49.92410812,
    "grid_to_demand": 31224.4011,
    "govt_battery_charge": 9.161448532
  },
  {
    "timestamp": "08/08/24 9:00",
    "demand": 36088.69566,
    "solar_generated": 18.2131027,
    "wind_generated": 69.58606652,
    "marketplace_to_demand": 74.53993752,
    "grid_to_demand": 35658.12961,
    "govt_battery_charge": 59.35829473
  },
  {
    "timestamp": "08/08/24 10:00",
    "demand": 36507.33465,
    "solar_generated": 14.82160938,
    "wind_generated": 72.63555429,
    "marketplace_to_demand": 135.6379989,
    "grid_to_demand": 36548.13049,
    "govt_battery_charge": 57.56471239
  },
  {
    "timestamp": "08/08/24 11:00",
    "demand": 35454.92444,
    "solar_generated": 15.97737837,
    "wind_generated": 77.82787227,
    "marketplace_to_demand": 78.71076989,
    "grid_to_demand": 32673.35843,
    "govt_battery_charge": 70.82424368
  },
  {
    "timestamp": "08/08/24 12:00",
    "demand": 32822.8279,
    "solar_generated": 18.82049618,
    "wind_generated": 71.42459162,
    "marketplace_to_demand": 10.08718369,
    "grid_to_demand": 33375.54219,
    "govt_battery_charge": 64.03898907
  },
  {
    "timestamp": "08/08/24 13:00",
    "demand": 36283.20162,
    "solar_generated": 23.4697118,
    "wind_generated": 54.75465546,
    "marketplace_to_demand": 157.3379267,
    "grid_to_demand": 33633.49398,
    "govt_battery_charge": 85.84909657
  },
  {
    "timestamp": "08/08/24 14:00",
    "demand": 34849.17614,
    "solar_generated": 19.17025405,
    "wind_generated": 66.55010754,
    "marketplace_to_demand": 88.73315242,
    "grid_to_demand": 33038.75035,
    "govt_battery_charge": 68.50576209
  },
  {
    "timestamp": "08/08/24 15:00",
    "demand": 33575.79006,
    "solar_generated": 21.11262643,
    "wind_generated": 78.42538468,
    "marketplace_to_demand": 111.9982702,
    "grid_to_demand": 34981.97948,
    "govt_battery_charge": 15.60019366
  },
  {
    "timestamp": "08/08/24 16:00",
    "demand": 30575.72308,
    "solar_generated": 11.27056963,
    "wind_generated": 70.3915966,
    "marketplace_to_demand": 162.7330415,
    "grid_to_demand": 31884.17215,
    "govt_battery_charge": 98.79233414
  },
  {
    "timestamp": "08/08/24 17:00",
    "demand": 32654.97306,
    "solar_generated": 19.55227714,
    "wind_generated": 62.09064654,
    "marketplace_to_demand": 132.5728903,
    "grid_to_demand": 33670.30265,
    "govt_battery_charge": 80.43947116
  },
  {
    "timestamp": "08/08/24 18:00",
    "demand": 34682.49644,
    "solar_generated": 0,
    "wind_generated": 55.3405499,
    "marketplace_to_demand": 149.1722779,
    "grid_to_demand": 36028.69577,
    "govt_battery_charge": 73.64328275
  },
  {
    "timestamp": "08/08/24 19:00",
    "demand": 33794.96826,
    "solar_generated": 0,
    "wind_generated": 76.29829307,
    "marketplace_to_demand": 146.132172,
    "grid_to_demand": 33865.58062,
    "govt_battery_charge": 59.5269486
  },
  {
    "timestamp": "08/08/24 20:00",
    "demand": 34496.73792,
    "solar_generated": 0,
    "wind_generated": 74.64697324,
    "marketplace_to_demand": 59.06898054,
    "grid_to_demand": 31670.90922,
    "govt_battery_charge": 95.27266172
  },
  {
    "timestamp": "08/08/24 21:00",
    "demand": 31454.52215,
    "solar_generated": 0,
    "wind_generated": 58.54543339,
    "marketplace_to_demand": 140.5180708,
    "grid_to_demand": 34303.41993,
    "govt_battery_charge": 12.28144522
  },
  {
    "timestamp": "08/08/24 22:00",
    "demand": 30485.86922,
    "solar_generated": 0,
    "wind_generated": 64.42744881,
    "marketplace_to_demand": 187.3820065,
    "grid_to_demand": 30974.09442,
    "govt_battery_charge": 69.47567739
  },
  {
    "timestamp": "08/08/24 23:00",
    "demand": 36129.79518,
    "solar_generated": 0,
    "wind_generated": 60.11980905,
    "marketplace_to_demand": 64.75260526,
    "grid_to_demand": 31914.6562,
    "govt_battery_charge": 79.52819349
  },
  {
    "timestamp": "09/08/24 0:00",
    "demand": 33353.89527,
    "solar_generated": 0,
    "wind_generated": 77.66068552,
    "marketplace_to_demand": 152.5801362,
    "grid_to_demand": 33587.99522,
    "govt_battery_charge": 53.07190237
  },
  {
    "timestamp": "09/08/24 1:00",
    "demand": 30625.60236,
    "solar_generated": 0,
    "wind_generated": 55.98147476,
    "marketplace_to_demand": 135.402095,
    "grid_to_demand": 29948.7927,
    "govt_battery_charge": 91.03316734
  },
  {
    "timestamp": "09/08/24 2:00",
    "demand": 33045.40585,
    "solar_generated": 0,
    "wind_generated": 56.58561007,
    "marketplace_to_demand": 90.59487047,
    "grid_to_demand": 31279.68802,
    "govt_battery_charge": 96.95818748
  },
  {
    "timestamp": "09/08/24 3:00",
    "demand": 32552.32386,
    "solar_generated": 0,
    "wind_generated": 54.3596032,
    "marketplace_to_demand": 60.43099987,
    "grid_to_demand": 35302.50109,
    "govt_battery_charge": 78.20129873
  },
  {
    "timestamp": "09/08/24 4:00",
    "demand": 31031.17884,
    "solar_generated": 0,
    "wind_generated": 78.91318868,
    "marketplace_to_demand": 121.9133385,
    "grid_to_demand": 34213.27902,
    "govt_battery_charge": 22.34655419
  },
  {
    "timestamp": "09/08/24 5:00",
    "demand": 33463.25277,
    "solar_generated": 0,
    "wind_generated": 67.2231738,
    "marketplace_to_demand": 18.00155714,
    "grid_to_demand": 36135.28001,
    "govt_battery_charge": 91.011886
  },
  {
    "timestamp": "09/08/24 6:00",
    "demand": 35583.5689,
    "solar_generated": 22.47142143,
    "wind_generated": 76.13011713,
    "marketplace_to_demand": 11.12781289,
    "grid_to_demand": 33110.93569,
    "govt_battery_charge": 1.290737421
  },
  {
    "timestamp": "09/08/24 7:00",
    "demand": 34856.01721,
    "solar_generated": 17.35729406,
    "wind_generated": 57.13756033,
    "marketplace_to_demand": 95.92520323,
    "grid_to_demand": 34964.9771,
    "govt_battery_charge": 2.080716071
  },
  {
    "timestamp": "09/08/24 8:00",
    "demand": 34130.22251,
    "solar_generated": 9.373467615,
    "wind_generated": 75.52188904,
    "marketplace_to_demand": 151.0125687,
    "grid_to_demand": 30758.26489,
    "govt_battery_charge": 62.69319454
  },
  {
    "timestamp": "09/08/24 9:00",
    "demand": 30389.98291,
    "solar_generated": 19.66993097,
    "wind_generated": 75.70611054,
    "marketplace_to_demand": 149.5280442,
    "grid_to_demand": 34768.68069,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/08/24 10:00",
    "demand": 33709.82324,
    "solar_generated": 9.93310149,
    "wind_generated": 68.73203436,
    "marketplace_to_demand": 45.34197169,
    "grid_to_demand": 36460.69185,
    "govt_battery_charge": 50.31370158
  },
  {
    "timestamp": "09/08/24 11:00",
    "demand": 36505.25373,
    "solar_generated": 11.83782545,
    "wind_generated": 71.57024533,
    "marketplace_to_demand": 198.7350741,
    "grid_to_demand": 36231.55662,
    "govt_battery_charge": 32.91672891
  },
  {
    "timestamp": "09/08/24 12:00",
    "demand": 35813.79891,
    "solar_generated": 7.242630066,
    "wind_generated": 70.05213933,
    "marketplace_to_demand": 35.72993881,
    "grid_to_demand": 36305.44525,
    "govt_battery_charge": 81.56934883
  },
  {
    "timestamp": "09/08/24 13:00",
    "demand": 34731.96565,
    "solar_generated": 8.175997672,
    "wind_generated": 62.80373768,
    "marketplace_to_demand": 125.6823159,
    "grid_to_demand": 32116.00552,
    "govt_battery_charge": 21.77035054
  },
  {
    "timestamp": "09/08/24 14:00",
    "demand": 36462.3878,
    "solar_generated": 18.37751424,
    "wind_generated": 69.71162872,
    "marketplace_to_demand": 44.58393744,
    "grid_to_demand": 35360.61729,
    "govt_battery_charge": 10.1529099
  },
  {
    "timestamp": "09/08/24 15:00",
    "demand": 34853.13323,
    "solar_generated": 20.7426844,
    "wind_generated": 65.00618916,
    "marketplace_to_demand": 108.3599928,
    "grid_to_demand": 31566.38861,
    "govt_battery_charge": 8.090327952
  },
  {
    "timestamp": "09/08/24 16:00",
    "demand": 30707.59962,
    "solar_generated": 16.18698039,
    "wind_generated": 77.50757506,
    "marketplace_to_demand": 195.4386065,
    "grid_to_demand": 35104.23586,
    "govt_battery_charge": 99.87330076
  },
  {
    "timestamp": "09/08/24 17:00",
    "demand": 32261.39001,
    "solar_generated": 9.06258125,
    "wind_generated": 59.92850464,
    "marketplace_to_demand": 166.560722,
    "grid_to_demand": 36431.5543,
    "govt_battery_charge": 51.6865163
  },
  {
    "timestamp": "09/08/24 18:00",
    "demand": 34639.02374,
    "solar_generated": 0,
    "wind_generated": 61.98170869,
    "marketplace_to_demand": 170.9716547,
    "grid_to_demand": 33670.23689,
    "govt_battery_charge": 69.86536452
  },
  {
    "timestamp": "09/08/24 19:00",
    "demand": 35083.23739,
    "solar_generated": 0,
    "wind_generated": 65.9423912,
    "marketplace_to_demand": 19.10240143,
    "grid_to_demand": 30050.13374,
    "govt_battery_charge": 47.19327863
  },
  {
    "timestamp": "09/08/24 20:00",
    "demand": 32278.13645,
    "solar_generated": 0,
    "wind_generated": 66.21126654,
    "marketplace_to_demand": 32.09145343,
    "grid_to_demand": 30212.40131,
    "govt_battery_charge": 18.19032884
  },
  {
    "timestamp": "09/08/24 21:00",
    "demand": 30843.74152,
    "solar_generated": 0,
    "wind_generated": 71.50713091,
    "marketplace_to_demand": 60.74278719,
    "grid_to_demand": 31560.03051,
    "govt_battery_charge": 36.08504713
  },
  {
    "timestamp": "09/08/24 22:00",
    "demand": 35097.34477,
    "solar_generated": 0,
    "wind_generated": 67.13053886,
    "marketplace_to_demand": 150.5560502,
    "grid_to_demand": 34020.61796,
    "govt_battery_charge": 76.93074821
  },
  {
    "timestamp": "09/08/24 23:00",
    "demand": 32295.70156,
    "solar_generated": 0,
    "wind_generated": 58.57483888,
    "marketplace_to_demand": 48.072684,
    "grid_to_demand": 33727.0085,
    "govt_battery_charge": 31.91970521
  },
  {
    "timestamp": "10/08/24 0:00",
    "demand": 30872.73237,
    "solar_generated": 0,
    "wind_generated": 60.57221291,
    "marketplace_to_demand": 38.87799582,
    "grid_to_demand": 32242.38305,
    "govt_battery_charge": 84.65624863
  },
  {
    "timestamp": "10/08/24 1:00",
    "demand": 35499.11176,
    "solar_generated": 0,
    "wind_generated": 66.3220156,
    "marketplace_to_demand": 36.9894973,
    "grid_to_demand": 32176.20885,
    "govt_battery_charge": 67.15336446
  },
  {
    "timestamp": "10/08/24 2:00",
    "demand": 35983.43432,
    "solar_generated": 0,
    "wind_generated": 73.3892456,
    "marketplace_to_demand": 67.86074129,
    "grid_to_demand": 34973.55815,
    "govt_battery_charge": 77.86529283
  },
  {
    "timestamp": "10/08/24 3:00",
    "demand": 30688.28973,
    "solar_generated": 0,
    "wind_generated": 77.78217467,
    "marketplace_to_demand": 167.0598772,
    "grid_to_demand": 30907.24894,
    "govt_battery_charge": 95.56417214
  },
  {
    "timestamp": "10/08/24 4:00",
    "demand": 34149.77454,
    "solar_generated": 0,
    "wind_generated": 73.00533729,
    "marketplace_to_demand": 43.45989373,
    "grid_to_demand": 35233.19408,
    "govt_battery_charge": 69.06318597
  },
  {
    "timestamp": "10/08/24 5:00",
    "demand": 34286.24005,
    "solar_generated": 0,
    "wind_generated": 66.65538148,
    "marketplace_to_demand": 90.88671731,
    "grid_to_demand": 36457.7984,
    "govt_battery_charge": 9.007949319
  },
  {
    "timestamp": "10/08/24 6:00",
    "demand": 33165.99501,
    "solar_generated": 8.734195297,
    "wind_generated": 61.31746265,
    "marketplace_to_demand": 190.713917,
    "grid_to_demand": 32009.21856,
    "govt_battery_charge": 45.11684033
  },
  {
    "timestamp": "10/08/24 7:00",
    "demand": 30706.25439,
    "solar_generated": 18.33222148,
    "wind_generated": 55.88333915,
    "marketplace_to_demand": 94.14618767,
    "grid_to_demand": 34299.04543,
    "govt_battery_charge": 72.87883274
  },
  {
    "timestamp": "10/08/24 8:00",
    "demand": 34189.3767,
    "solar_generated": 12.959361,
    "wind_generated": 72.6697675,
    "marketplace_to_demand": 78.7224112,
    "grid_to_demand": 36521.27086,
    "govt_battery_charge": 2.706237329
  },
  {
    "timestamp": "10/08/24 9:00",
    "demand": 32759.35016,
    "solar_generated": 18.14227131,
    "wind_generated": 68.7275841,
    "marketplace_to_demand": 168.2568768,
    "grid_to_demand": 30715.2641,
    "govt_battery_charge": 72.0651196
  },
  {
    "timestamp": "10/08/24 10:00",
    "demand": 34792.19126,
    "solar_generated": 6.027445811,
    "wind_generated": 55.40480234,
    "marketplace_to_demand": 136.6922951,
    "grid_to_demand": 35262.82625,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "10/08/24 11:00",
    "demand": 31390.79046,
    "solar_generated": 14.81816625,
    "wind_generated": 62.91100706,
    "marketplace_to_demand": 98.57887802,
    "grid_to_demand": 32802.887,
    "govt_battery_charge": 43.39248785
  },
  {
    "timestamp": "10/08/24 12:00",
    "demand": 31646.11631,
    "solar_generated": 11.3013273,
    "wind_generated": 73.71102659,
    "marketplace_to_demand": 19.12982291,
    "grid_to_demand": 31425.79604,
    "govt_battery_charge": 88.36824961
  },
  {
    "timestamp": "10/08/24 13:00",
    "demand": 30021.24704,
    "solar_generated": 14.52906318,
    "wind_generated": 68.28493873,
    "marketplace_to_demand": 185.0572453,
    "grid_to_demand": 36204.20316,
    "govt_battery_charge": 43.99148297
  },
  {
    "timestamp": "10/08/24 14:00",
    "demand": 33165.75424,
    "solar_generated": 17.21126338,
    "wind_generated": 77.64101947,
    "marketplace_to_demand": 46.29629986,
    "grid_to_demand": 34740.85404,
    "govt_battery_charge": 90.95274404
  },
  {
    "timestamp": "10/08/24 15:00",
    "demand": 34566.81878,
    "solar_generated": 20.00556243,
    "wind_generated": 78.82087253,
    "marketplace_to_demand": 109.0279299,
    "grid_to_demand": 34503.34692,
    "govt_battery_charge": 31.52795757
  },
  {
    "timestamp": "10/08/24 16:00",
    "demand": 31855.71134,
    "solar_generated": 20.34463952,
    "wind_generated": 67.38810012,
    "marketplace_to_demand": 105.6955388,
    "grid_to_demand": 35157.55921,
    "govt_battery_charge": 69.43819137
  },
  {
    "timestamp": "10/08/24 17:00",
    "demand": 33954.05585,
    "solar_generated": 7.273961186,
    "wind_generated": 56.95604287,
    "marketplace_to_demand": 142.1022047,
    "grid_to_demand": 32108.63561,
    "govt_battery_charge": 19.13772889
  },
  {
    "timestamp": "10/08/24 18:00",
    "demand": 35802.81139,
    "solar_generated": 0,
    "wind_generated": 55.76341859,
    "marketplace_to_demand": 89.1533408,
    "grid_to_demand": 34577.26604,
    "govt_battery_charge": 20.29533877
  },
  {
    "timestamp": "10/08/24 19:00",
    "demand": 30571.94632,
    "solar_generated": 0,
    "wind_generated": 65.86555058,
    "marketplace_to_demand": 123.7919159,
    "grid_to_demand": 30339.07673,
    "govt_battery_charge": 68.00545394
  },
  {
    "timestamp": "10/08/24 20:00",
    "demand": 30790.75814,
    "solar_generated": 0,
    "wind_generated": 61.95382932,
    "marketplace_to_demand": 146.2393174,
    "grid_to_demand": 34813.77922,
    "govt_battery_charge": 7.655467721
  },
  {
    "timestamp": "10/08/24 21:00",
    "demand": 33011.15766,
    "solar_generated": 0,
    "wind_generated": 72.72398484,
    "marketplace_to_demand": 157.2520581,
    "grid_to_demand": 34124.63438,
    "govt_battery_charge": 44.86104018
  },
  {
    "timestamp": "10/08/24 22:00",
    "demand": 31540.84147,
    "solar_generated": 0,
    "wind_generated": 55.59917138,
    "marketplace_to_demand": 35.22984929,
    "grid_to_demand": 33818.1957,
    "govt_battery_charge": 96.90431621
  },
  {
    "timestamp": "10/08/24 23:00",
    "demand": 31777.4192,
    "solar_generated": 0,
    "wind_generated": 76.37324635,
    "marketplace_to_demand": 112.3338012,
    "grid_to_demand": 30671.86964,
    "govt_battery_charge": 6.154978922
  },
  {
    "timestamp": "11/08/24 0:00",
    "demand": 30953.73698,
    "solar_generated": 0,
    "wind_generated": 67.14584596,
    "marketplace_to_demand": 148.5913039,
    "grid_to_demand": 34600.14898,
    "govt_battery_charge": 80.60904776
  },
  {
    "timestamp": "11/08/24 1:00",
    "demand": 32673.77463,
    "solar_generated": 0,
    "wind_generated": 60.25226959,
    "marketplace_to_demand": 190.6965939,
    "grid_to_demand": 34906.73943,
    "govt_battery_charge": 48.84007321
  },
  {
    "timestamp": "11/08/24 2:00",
    "demand": 35725.36798,
    "solar_generated": 0,
    "wind_generated": 79.90919172,
    "marketplace_to_demand": 84.76675419,
    "grid_to_demand": 31961.59174,
    "govt_battery_charge": 63.17567722
  },
  {
    "timestamp": "11/08/24 3:00",
    "demand": 31984.34902,
    "solar_generated": 0,
    "wind_generated": 78.40718125,
    "marketplace_to_demand": 189.768099,
    "grid_to_demand": 30824.40443,
    "govt_battery_charge": 95.89174429
  },
  {
    "timestamp": "11/08/24 4:00",
    "demand": 35058.22851,
    "solar_generated": 0,
    "wind_generated": 70.09009207,
    "marketplace_to_demand": 158.5983275,
    "grid_to_demand": 31719.20645,
    "govt_battery_charge": 47.75702985
  },
  {
    "timestamp": "11/08/24 5:00",
    "demand": 32560.17875,
    "solar_generated": 0,
    "wind_generated": 71.33673651,
    "marketplace_to_demand": 178.3942362,
    "grid_to_demand": 30984.70037,
    "govt_battery_charge": 95.2445631
  },
  {
    "timestamp": "11/08/24 6:00",
    "demand": 31826.11,
    "solar_generated": 20.14247902,
    "wind_generated": 65.29632487,
    "marketplace_to_demand": 86.42887705,
    "grid_to_demand": 30172.71913,
    "govt_battery_charge": 86.84542049
  },
  {
    "timestamp": "11/08/24 7:00",
    "demand": 32085.46444,
    "solar_generated": 21.60429844,
    "wind_generated": 64.41882476,
    "marketplace_to_demand": 75.02333714,
    "grid_to_demand": 31838.08181,
    "govt_battery_charge": 41.19265622
  },
  {
    "timestamp": "11/08/24 8:00",
    "demand": 33724.11935,
    "solar_generated": 11.19000912,
    "wind_generated": 62.04250603,
    "marketplace_to_demand": 24.68509574,
    "grid_to_demand": 36048.69896,
    "govt_battery_charge": 68.53657563
  },
  {
    "timestamp": "11/08/24 9:00",
    "demand": 36301.85931,
    "solar_generated": 10.27491518,
    "wind_generated": 53.67592588,
    "marketplace_to_demand": 54.34663221,
    "grid_to_demand": 30300.98766,
    "govt_battery_charge": 40.56477346
  },
  {
    "timestamp": "11/08/24 10:00",
    "demand": 30514.12789,
    "solar_generated": 18.87765318,
    "wind_generated": 72.34009624,
    "marketplace_to_demand": 22.18675711,
    "grid_to_demand": 32836.20172,
    "govt_battery_charge": 16.4373868
  },
  {
    "timestamp": "11/08/24 11:00",
    "demand": 31550.42019,
    "solar_generated": 23.78120557,
    "wind_generated": 75.31323875,
    "marketplace_to_demand": 172.9482162,
    "grid_to_demand": 33344.97008,
    "govt_battery_charge": 32.13634702
  },
  {
    "timestamp": "11/08/24 12:00",
    "demand": 31001.45022,
    "solar_generated": 9.334389343,
    "wind_generated": 64.95844255,
    "marketplace_to_demand": 48.99166652,
    "grid_to_demand": 34554.2605,
    "govt_battery_charge": 52.41032735
  },
  {
    "timestamp": "11/08/24 13:00",
    "demand": 30753.19195,
    "solar_generated": 13.14575503,
    "wind_generated": 73.11062298,
    "marketplace_to_demand": 45.04466713,
    "grid_to_demand": 30223.16003,
    "govt_battery_charge": 38.59471595
  },
  {
    "timestamp": "11/08/24 14:00",
    "demand": 33832.34277,
    "solar_generated": 11.90285573,
    "wind_generated": 71.89022143,
    "marketplace_to_demand": 112.920336,
    "grid_to_demand": 36514.05796,
    "govt_battery_charge": 52.5086716
  },
  {
    "timestamp": "11/08/24 15:00",
    "demand": 32536.68505,
    "solar_generated": 23.13483846,
    "wind_generated": 65.9545491,
    "marketplace_to_demand": 135.2173538,
    "grid_to_demand": 33469.82907,
    "govt_battery_charge": 77.0721149
  },
  {
    "timestamp": "11/08/24 16:00",
    "demand": 30655.09572,
    "solar_generated": 11.56361084,
    "wind_generated": 61.06736746,
    "marketplace_to_demand": 180.0980977,
    "grid_to_demand": 33113.21151,
    "govt_battery_charge": 80.72990408
  },
  {
    "timestamp": "11/08/24 17:00",
    "demand": 34043.99719,
    "solar_generated": 15.21107088,
    "wind_generated": 76.22739729,
    "marketplace_to_demand": 37.35291225,
    "grid_to_demand": 34366.93124,
    "govt_battery_charge": 50.61598513
  },
  {
    "timestamp": "11/08/24 18:00",
    "demand": 31464.88742,
    "solar_generated": 0,
    "wind_generated": 54.7782987,
    "marketplace_to_demand": 120.1436451,
    "grid_to_demand": 32007.05847,
    "govt_battery_charge": 69.53201914
  },
  {
    "timestamp": "11/08/24 19:00",
    "demand": 30461.3343,
    "solar_generated": 0,
    "wind_generated": 77.56152212,
    "marketplace_to_demand": 97.62377615,
    "grid_to_demand": 36398.66855,
    "govt_battery_charge": 64.98046165
  },
  {
    "timestamp": "11/08/24 20:00",
    "demand": 36248.97379,
    "solar_generated": 0,
    "wind_generated": 61.67459691,
    "marketplace_to_demand": 94.07679506,
    "grid_to_demand": 32763.83391,
    "govt_battery_charge": 51.7775432
  },
  {
    "timestamp": "11/08/24 21:00",
    "demand": 30645.04216,
    "solar_generated": 0,
    "wind_generated": 60.22135222,
    "marketplace_to_demand": 41.95549609,
    "grid_to_demand": 33661.01014,
    "govt_battery_charge": 99.82238735
  },
  {
    "timestamp": "11/08/24 22:00",
    "demand": 33323.13185,
    "solar_generated": 0,
    "wind_generated": 58.41025548,
    "marketplace_to_demand": 50.92755197,
    "grid_to_demand": 30266.13487,
    "govt_battery_charge": 13.21875709
  },
  {
    "timestamp": "11/08/24 23:00",
    "demand": 36299.87352,
    "solar_generated": 0,
    "wind_generated": 57.42025659,
    "marketplace_to_demand": 38.04803435,
    "grid_to_demand": 33596.56989,
    "govt_battery_charge": 91.85368599
  },
  {
    "timestamp": "12/08/24 0:00",
    "demand": 34430.49768,
    "solar_generated": 0,
    "wind_generated": 55.49976476,
    "marketplace_to_demand": 13.30728419,
    "grid_to_demand": 33727.42152,
    "govt_battery_charge": 30.12129837
  },
  {
    "timestamp": "12/08/24 1:00",
    "demand": 35580.99036,
    "solar_generated": 0,
    "wind_generated": 80.13723724,
    "marketplace_to_demand": 45.02765358,
    "grid_to_demand": 35517.32151,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "12/08/24 2:00",
    "demand": 32014.96292,
    "solar_generated": 0,
    "wind_generated": 69.36601261,
    "marketplace_to_demand": 36.47962319,
    "grid_to_demand": 34790.90426,
    "govt_battery_charge": 23.69970921
  },
  {
    "timestamp": "12/08/24 3:00",
    "demand": 32611.53337,
    "solar_generated": 0,
    "wind_generated": 73.02846479,
    "marketplace_to_demand": 9.403760812,
    "grid_to_demand": 35973.81169,
    "govt_battery_charge": 39.9448128
  },
  {
    "timestamp": "12/08/24 4:00",
    "demand": 30560.8651,
    "solar_generated": 0,
    "wind_generated": 73.22195369,
    "marketplace_to_demand": 41.2531972,
    "grid_to_demand": 31297.5567,
    "govt_battery_charge": 67.79095612
  },
  {
    "timestamp": "12/08/24 5:00",
    "demand": 33996.65682,
    "solar_generated": 0,
    "wind_generated": 76.47331714,
    "marketplace_to_demand": 114.5856556,
    "grid_to_demand": 34850.90882,
    "govt_battery_charge": 22.1219885
  },
  {
    "timestamp": "12/08/24 6:00",
    "demand": 32524.65974,
    "solar_generated": 12.4215828,
    "wind_generated": 65.41345903,
    "marketplace_to_demand": 2.60918018,
    "grid_to_demand": 36239.45962,
    "govt_battery_charge": 27.03058597
  },
  {
    "timestamp": "12/08/24 7:00",
    "demand": 35517.57846,
    "solar_generated": 8.245484425,
    "wind_generated": 60.13230529,
    "marketplace_to_demand": 159.9483899,
    "grid_to_demand": 35409.03262,
    "govt_battery_charge": 93.13332995
  },
  {
    "timestamp": "12/08/24 8:00",
    "demand": 30525.60991,
    "solar_generated": 11.50787217,
    "wind_generated": 71.32597182,
    "marketplace_to_demand": 65.17690416,
    "grid_to_demand": 32131.34463,
    "govt_battery_charge": 40.59593529
  },
  {
    "timestamp": "12/08/24 9:00",
    "demand": 36334.03758,
    "solar_generated": 6.957339204,
    "wind_generated": 62.91910714,
    "marketplace_to_demand": 186.2827538,
    "grid_to_demand": 33409.49495,
    "govt_battery_charge": 92.16388854
  },
  {
    "timestamp": "12/08/24 10:00",
    "demand": 31357.55987,
    "solar_generated": 21.06548607,
    "wind_generated": 63.65748078,
    "marketplace_to_demand": 38.55626395,
    "grid_to_demand": 36456.33932,
    "govt_battery_charge": 14.6030775
  },
  {
    "timestamp": "12/08/24 11:00",
    "demand": 30180.67462,
    "solar_generated": 12.25200666,
    "wind_generated": 68.81323259,
    "marketplace_to_demand": 36.3426979,
    "grid_to_demand": 33288.66345,
    "govt_battery_charge": 74.59901061
  },
  {
    "timestamp": "12/08/24 12:00",
    "demand": 35984.02651,
    "solar_generated": 11.56033997,
    "wind_generated": 76.89100958,
    "marketplace_to_demand": 127.2355559,
    "grid_to_demand": 36180.90986,
    "govt_battery_charge": 25.57294238
  },
  {
    "timestamp": "12/08/24 13:00",
    "demand": 32122.17927,
    "solar_generated": 16.42300657,
    "wind_generated": 55.81581591,
    "marketplace_to_demand": 18.7989965,
    "grid_to_demand": 32900.2355,
    "govt_battery_charge": 52.08382553
  },
  {
    "timestamp": "12/08/24 14:00",
    "demand": 35124.33931,
    "solar_generated": 14.40883284,
    "wind_generated": 55.15340447,
    "marketplace_to_demand": 26.77474918,
    "grid_to_demand": 35923.66857,
    "govt_battery_charge": 61.73133655
  },
  {
    "timestamp": "12/08/24 15:00",
    "demand": 34062.14481,
    "solar_generated": 13.31958572,
    "wind_generated": 66.04313192,
    "marketplace_to_demand": 125.9565806,
    "grid_to_demand": 30723.69282,
    "govt_battery_charge": 4.915874989
  },
  {
    "timestamp": "12/08/24 16:00",
    "demand": 30357.84347,
    "solar_generated": 12.29981522,
    "wind_generated": 65.31743239,
    "marketplace_to_demand": 55.7445721,
    "grid_to_demand": 35498.99533,
    "govt_battery_charge": 78.23158501
  },
  {
    "timestamp": "12/08/24 17:00",
    "demand": 30308.91554,
    "solar_generated": 13.67243432,
    "wind_generated": 60.00403334,
    "marketplace_to_demand": 108.5695071,
    "grid_to_demand": 30705.74074,
    "govt_battery_charge": 17.4959119
  },
  {
    "timestamp": "12/08/24 18:00",
    "demand": 34850.34848,
    "solar_generated": 0,
    "wind_generated": 80.27132307,
    "marketplace_to_demand": 54.50931698,
    "grid_to_demand": 30281.02175,
    "govt_battery_charge": 36.61944243
  },
  {
    "timestamp": "12/08/24 19:00",
    "demand": 32682.15305,
    "solar_generated": 0,
    "wind_generated": 61.51723915,
    "marketplace_to_demand": 131.3782886,
    "grid_to_demand": 30314.46231,
    "govt_battery_charge": 98.70215882
  },
  {
    "timestamp": "12/08/24 20:00",
    "demand": 36347.5622,
    "solar_generated": 0,
    "wind_generated": 70.56022085,
    "marketplace_to_demand": 112.1444164,
    "grid_to_demand": 32441.98097,
    "govt_battery_charge": 20.82903374
  },
  {
    "timestamp": "12/08/24 21:00",
    "demand": 32578.94861,
    "solar_generated": 0,
    "wind_generated": 76.23476312,
    "marketplace_to_demand": 76.53243116,
    "grid_to_demand": 32521.12322,
    "govt_battery_charge": 39.2903075
  },
  {
    "timestamp": "12/08/24 22:00",
    "demand": 33674.58852,
    "solar_generated": 0,
    "wind_generated": 72.2507191,
    "marketplace_to_demand": 65.78963989,
    "grid_to_demand": 34392.37027,
    "govt_battery_charge": 95.07418566
  },
  {
    "timestamp": "12/08/24 23:00",
    "demand": 33866.20766,
    "solar_generated": 0,
    "wind_generated": 76.24933159,
    "marketplace_to_demand": 0.864790621,
    "grid_to_demand": 35058.24805,
    "govt_battery_charge": 35.42208004
  },
  {
    "timestamp": "13/08/24 0:00",
    "demand": 34275.18916,
    "solar_generated": 0,
    "wind_generated": 78.89032003,
    "marketplace_to_demand": 66.04158137,
    "grid_to_demand": 35667.09018,
    "govt_battery_charge": 37.60952165
  },
  {
    "timestamp": "13/08/24 1:00",
    "demand": 31576.23588,
    "solar_generated": 0,
    "wind_generated": 60.17884095,
    "marketplace_to_demand": 125.5785619,
    "grid_to_demand": 30649.6952,
    "govt_battery_charge": 30.9652606
  },
  {
    "timestamp": "13/08/24 2:00",
    "demand": 30586.69976,
    "solar_generated": 0,
    "wind_generated": 78.46527738,
    "marketplace_to_demand": 10.6882874,
    "grid_to_demand": 35833.38086,
    "govt_battery_charge": 77.06801461
  },
  {
    "timestamp": "13/08/24 3:00",
    "demand": 30446.54524,
    "solar_generated": 0,
    "wind_generated": 78.5247696,
    "marketplace_to_demand": 80.68583174,
    "grid_to_demand": 31327.98724,
    "govt_battery_charge": 80.06253209
  },
  {
    "timestamp": "13/08/24 4:00",
    "demand": 32938.66996,
    "solar_generated": 0,
    "wind_generated": 62.1459006,
    "marketplace_to_demand": 69.55518895,
    "grid_to_demand": 35249.17602,
    "govt_battery_charge": 64.74685437
  },
  {
    "timestamp": "13/08/24 5:00",
    "demand": 31098.8205,
    "solar_generated": 0,
    "wind_generated": 53.74411113,
    "marketplace_to_demand": 130.100165,
    "grid_to_demand": 33503.56102,
    "govt_battery_charge": 63.02090915
  },
  {
    "timestamp": "13/08/24 6:00",
    "demand": 30990.62522,
    "solar_generated": 16.23350735,
    "wind_generated": 54.45346484,
    "marketplace_to_demand": 108.9039728,
    "grid_to_demand": 35495.45726,
    "govt_battery_charge": 50.76851708
  },
  {
    "timestamp": "13/08/24 7:00",
    "demand": 34552.93879,
    "solar_generated": 10.34134818,
    "wind_generated": 71.87191681,
    "marketplace_to_demand": 198.0927921,
    "grid_to_demand": 31678.28018,
    "govt_battery_charge": 18.77000613
  },
  {
    "timestamp": "13/08/24 8:00",
    "demand": 34099.23462,
    "solar_generated": 16.51091525,
    "wind_generated": 63.9938452,
    "marketplace_to_demand": 119.7494825,
    "grid_to_demand": 30300.46255,
    "govt_battery_charge": 62.40784856
  },
  {
    "timestamp": "13/08/24 9:00",
    "demand": 35639.33708,
    "solar_generated": 19.5301657,
    "wind_generated": 72.67423083,
    "marketplace_to_demand": 142.0461089,
    "grid_to_demand": 36012.70737,
    "govt_battery_charge": 85.01587152
  },
  {
    "timestamp": "13/08/24 10:00",
    "demand": 33343.17928,
    "solar_generated": 20.89948806,
    "wind_generated": 68.33932605,
    "marketplace_to_demand": 142.7481841,
    "grid_to_demand": 30301.15189,
    "govt_battery_charge": 61.1837815
  },
  {
    "timestamp": "13/08/24 11:00",
    "demand": 32901.04073,
    "solar_generated": 6.842014518,
    "wind_generated": 54.27287623,
    "marketplace_to_demand": 85.7958173,
    "grid_to_demand": 30654.27952,
    "govt_battery_charge": 91.75923125
  },
  {
    "timestamp": "13/08/24 12:00",
    "demand": 35572.61434,
    "solar_generated": 19.67215525,
    "wind_generated": 79.53351461,
    "marketplace_to_demand": 117.8043296,
    "grid_to_demand": 30984.86669,
    "govt_battery_charge": 17.71470274
  },
  {
    "timestamp": "13/08/24 13:00",
    "demand": 34990.82038,
    "solar_generated": 15.09125739,
    "wind_generated": 63.64232323,
    "marketplace_to_demand": 10.83111067,
    "grid_to_demand": 30288.13494,
    "govt_battery_charge": 21.02736063
  },
  {
    "timestamp": "13/08/24 14:00",
    "demand": 30790.77285,
    "solar_generated": 10.92108624,
    "wind_generated": 60.58291426,
    "marketplace_to_demand": 173.0488094,
    "grid_to_demand": 35544.10214,
    "govt_battery_charge": 91.54864613
  },
  {
    "timestamp": "13/08/24 15:00",
    "demand": 30428.05191,
    "solar_generated": 22.01746796,
    "wind_generated": 66.22817972,
    "marketplace_to_demand": 179.933581,
    "grid_to_demand": 34947.09734,
    "govt_battery_charge": 34.82272177
  },
  {
    "timestamp": "13/08/24 16:00",
    "demand": 33606.37504,
    "solar_generated": 19.50599518,
    "wind_generated": 73.31882827,
    "marketplace_to_demand": 92.57839102,
    "grid_to_demand": 36393.57378,
    "govt_battery_charge": 21.46207077
  },
  {
    "timestamp": "13/08/24 17:00",
    "demand": 32062.36585,
    "solar_generated": 6.521611707,
    "wind_generated": 64.42938707,
    "marketplace_to_demand": 75.0152671,
    "grid_to_demand": 30772.26655,
    "govt_battery_charge": 63.14489085
  },
  {
    "timestamp": "13/08/24 18:00",
    "demand": 33302.36886,
    "solar_generated": 0,
    "wind_generated": 79.09989251,
    "marketplace_to_demand": 132.5672669,
    "grid_to_demand": 31728.3861,
    "govt_battery_charge": 37.5273685
  },
  {
    "timestamp": "13/08/24 19:00",
    "demand": 30667.37374,
    "solar_generated": 0,
    "wind_generated": 57.7279758,
    "marketplace_to_demand": 113.6179961,
    "grid_to_demand": 30224.88551,
    "govt_battery_charge": 34.48185492
  },
  {
    "timestamp": "13/08/24 20:00",
    "demand": 33918.34531,
    "solar_generated": 0,
    "wind_generated": 55.44555085,
    "marketplace_to_demand": 174.8846566,
    "grid_to_demand": 32836.49746,
    "govt_battery_charge": 40.63669971
  },
  {
    "timestamp": "13/08/24 21:00",
    "demand": 36394.74059,
    "solar_generated": 0,
    "wind_generated": 65.14311029,
    "marketplace_to_demand": 84.84174689,
    "grid_to_demand": 34349.82439,
    "govt_battery_charge": 11.12584773
  },
  {
    "timestamp": "13/08/24 22:00",
    "demand": 34530.61213,
    "solar_generated": 0,
    "wind_generated": 53.68138764,
    "marketplace_to_demand": 145.7496615,
    "grid_to_demand": 34295.51673,
    "govt_battery_charge": 50.74185097
  },
  {
    "timestamp": "13/08/24 23:00",
    "demand": 33590.22433,
    "solar_generated": 0,
    "wind_generated": 72.11346774,
    "marketplace_to_demand": 192.8856479,
    "grid_to_demand": 32287.08641,
    "govt_battery_charge": 77.81462892
  },
  {
    "timestamp": "14/08/24 0:00",
    "demand": 30050.8242,
    "solar_generated": 0,
    "wind_generated": 58.91184779,
    "marketplace_to_demand": 131.6740093,
    "grid_to_demand": 31191.94677,
    "govt_battery_charge": 4.068888746
  },
  {
    "timestamp": "14/08/24 1:00",
    "demand": 31495.96151,
    "solar_generated": 0,
    "wind_generated": 70.62326283,
    "marketplace_to_demand": 106.2963608,
    "grid_to_demand": 30566.33117,
    "govt_battery_charge": 29.57664484
  },
  {
    "timestamp": "14/08/24 2:00",
    "demand": 35462.11827,
    "solar_generated": 0,
    "wind_generated": 63.3507082,
    "marketplace_to_demand": 128.4715279,
    "grid_to_demand": 33863.80893,
    "govt_battery_charge": 67.29806235
  },
  {
    "timestamp": "14/08/24 3:00",
    "demand": 35536.4711,
    "solar_generated": 0,
    "wind_generated": 57.3068906,
    "marketplace_to_demand": 88.02545775,
    "grid_to_demand": 32289.96105,
    "govt_battery_charge": 6.609485608
  },
  {
    "timestamp": "14/08/24 4:00",
    "demand": 35510.77774,
    "solar_generated": 0,
    "wind_generated": 64.89190836,
    "marketplace_to_demand": 24.73136536,
    "grid_to_demand": 36202.53795,
    "govt_battery_charge": 69.69996154
  },
  {
    "timestamp": "14/08/24 5:00",
    "demand": 31796.3576,
    "solar_generated": 0,
    "wind_generated": 72.8142721,
    "marketplace_to_demand": 41.01418188,
    "grid_to_demand": 31386.30548,
    "govt_battery_charge": 34.27923423
  },
  {
    "timestamp": "14/08/24 6:00",
    "demand": 36316.6768,
    "solar_generated": 18.64803678,
    "wind_generated": 69.86827719,
    "marketplace_to_demand": 183.4571733,
    "grid_to_demand": 34806.86008,
    "govt_battery_charge": 94.80489052
  },
  {
    "timestamp": "14/08/24 7:00",
    "demand": 31687.20576,
    "solar_generated": 15.32555861,
    "wind_generated": 64.33861606,
    "marketplace_to_demand": 160.407488,
    "grid_to_demand": 33830.71083,
    "govt_battery_charge": 24.31657374
  },
  {
    "timestamp": "14/08/24 8:00",
    "demand": 34703.46303,
    "solar_generated": 20.09378839,
    "wind_generated": 74.40070649,
    "marketplace_to_demand": 78.02530963,
    "grid_to_demand": 34889.28166,
    "govt_battery_charge": 66.24890575
  },
  {
    "timestamp": "14/08/24 9:00",
    "demand": 34853.75918,
    "solar_generated": 19.0328305,
    "wind_generated": 65.21957783,
    "marketplace_to_demand": 161.58291,
    "grid_to_demand": 32002.19361,
    "govt_battery_charge": 27.8743843
  },
  {
    "timestamp": "14/08/24 10:00",
    "demand": 35270.3262,
    "solar_generated": 8.017773027,
    "wind_generated": 69.29294843,
    "marketplace_to_demand": 165.5508148,
    "grid_to_demand": 34200.40558,
    "govt_battery_charge": 59.68645356
  },
  {
    "timestamp": "14/08/24 11:00",
    "demand": 34035.89769,
    "solar_generated": 13.22128297,
    "wind_generated": 78.77090533,
    "marketplace_to_demand": 5.13943145,
    "grid_to_demand": 35580.45169,
    "govt_battery_charge": 7.578917971
  },
  {
    "timestamp": "14/08/24 12:00",
    "demand": 30985.06746,
    "solar_generated": 13.61782925,
    "wind_generated": 59.74087938,
    "marketplace_to_demand": 20.60141548,
    "grid_to_demand": 32807.11778,
    "govt_battery_charge": 73.16978507
  },
  {
    "timestamp": "14/08/24 13:00",
    "demand": 33497.0331,
    "solar_generated": 12.32639469,
    "wind_generated": 70.51585217,
    "marketplace_to_demand": 134.2779692,
    "grid_to_demand": 33406.5356,
    "govt_battery_charge": 38.0149906
  },
  {
    "timestamp": "14/08/24 14:00",
    "demand": 33588.20902,
    "solar_generated": 11.40298468,
    "wind_generated": 76.77552237,
    "marketplace_to_demand": 47.26027202,
    "grid_to_demand": 32842.72425,
    "govt_battery_charge": 61.75137538
  },
  {
    "timestamp": "14/08/24 15:00",
    "demand": 34572.92551,
    "solar_generated": 20.46767021,
    "wind_generated": 55.37981793,
    "marketplace_to_demand": 155.7400383,
    "grid_to_demand": 32068.59046,
    "govt_battery_charge": 59.0873604
  },
  {
    "timestamp": "14/08/24 16:00",
    "demand": 33272.55334,
    "solar_generated": 21.28320636,
    "wind_generated": 74.91852752,
    "marketplace_to_demand": 48.27476899,
    "grid_to_demand": 29959.50761,
    "govt_battery_charge": 86.2373793
  },
  {
    "timestamp": "14/08/24 17:00",
    "demand": 36042.19981,
    "solar_generated": 20.17019894,
    "wind_generated": 61.3370611,
    "marketplace_to_demand": 145.9684824,
    "grid_to_demand": 33141.12143,
    "govt_battery_charge": 25.43877418
  },
  {
    "timestamp": "14/08/24 18:00",
    "demand": 31835.47978,
    "solar_generated": 0,
    "wind_generated": 61.41190309,
    "marketplace_to_demand": 86.37286714,
    "grid_to_demand": 34738.71652,
    "govt_battery_charge": 84.93975317
  },
  {
    "timestamp": "14/08/24 19:00",
    "demand": 32492.33531,
    "solar_generated": 0,
    "wind_generated": 70.71799753,
    "marketplace_to_demand": 182.5903342,
    "grid_to_demand": 34016.0007,
    "govt_battery_charge": 74.93578228
  },
  {
    "timestamp": "14/08/24 20:00",
    "demand": 31413.45571,
    "solar_generated": 0,
    "wind_generated": 63.11899832,
    "marketplace_to_demand": 143.1655452,
    "grid_to_demand": 30242.27984,
    "govt_battery_charge": 77.64611918
  },
  {
    "timestamp": "14/08/24 21:00",
    "demand": 31802.7362,
    "solar_generated": 0,
    "wind_generated": 65.78417528,
    "marketplace_to_demand": 85.82631017,
    "grid_to_demand": 31048.4188,
    "govt_battery_charge": 34.81281083
  },
  {
    "timestamp": "14/08/24 22:00",
    "demand": 32409.37304,
    "solar_generated": 0,
    "wind_generated": 64.86356602,
    "marketplace_to_demand": 185.3345198,
    "grid_to_demand": 35614.67662,
    "govt_battery_charge": 31.52476462
  },
  {
    "timestamp": "14/08/24 23:00",
    "demand": 34060.35518,
    "solar_generated": 0,
    "wind_generated": 69.63588617,
    "marketplace_to_demand": 97.68704682,
    "grid_to_demand": 30032.9311,
    "govt_battery_charge": 90.83472256
  },
  {
    "timestamp": "15/08/24 0:00",
    "demand": 35410.62528,
    "solar_generated": 0,
    "wind_generated": 62.16353645,
    "marketplace_to_demand": 197.1208429,
    "grid_to_demand": 32153.43095,
    "govt_battery_charge": 81.48852115
  },
  {
    "timestamp": "15/08/24 1:00",
    "demand": 36085.35178,
    "solar_generated": 0,
    "wind_generated": 54.69399336,
    "marketplace_to_demand": 62.99699003,
    "grid_to_demand": 33543.40261,
    "govt_battery_charge": 96.20151592
  },
  {
    "timestamp": "15/08/24 2:00",
    "demand": 35511.69703,
    "solar_generated": 0,
    "wind_generated": 57.5803365,
    "marketplace_to_demand": 10.72350829,
    "grid_to_demand": 33706.33822,
    "govt_battery_charge": 38.50617663
  },
  {
    "timestamp": "15/08/24 3:00",
    "demand": 30359.73692,
    "solar_generated": 0,
    "wind_generated": 62.4936947,
    "marketplace_to_demand": 184.1509272,
    "grid_to_demand": 30364.9475,
    "govt_battery_charge": 50.52314774
  },
  {
    "timestamp": "15/08/24 4:00",
    "demand": 29977.99329,
    "solar_generated": 0,
    "wind_generated": 58.55298305,
    "marketplace_to_demand": 5.928496867,
    "grid_to_demand": 32786.06387,
    "govt_battery_charge": 90.75168081
  },
  {
    "timestamp": "15/08/24 5:00",
    "demand": 35472.97071,
    "solar_generated": 0,
    "wind_generated": 72.31003351,
    "marketplace_to_demand": 53.25331476,
    "grid_to_demand": 33448.07694,
    "govt_battery_charge": 22.43720526
  },
  {
    "timestamp": "15/08/24 6:00",
    "demand": 34677.62124,
    "solar_generated": 21.34199249,
    "wind_generated": 58.82410936,
    "marketplace_to_demand": 9.19334155,
    "grid_to_demand": 31170.50682,
    "govt_battery_charge": 93.70692768
  },
  {
    "timestamp": "15/08/24 7:00",
    "demand": 33339.00014,
    "solar_generated": 16.03946499,
    "wind_generated": 57.44951384,
    "marketplace_to_demand": 180.9522214,
    "grid_to_demand": 36258.90859,
    "govt_battery_charge": 74.8438379
  },
  {
    "timestamp": "15/08/24 8:00",
    "demand": 32861.73868,
    "solar_generated": 8.814526856,
    "wind_generated": 73.48921627,
    "marketplace_to_demand": 189.0421391,
    "grid_to_demand": 35911.24784,
    "govt_battery_charge": 74.16767804
  },
  {
    "timestamp": "15/08/24 9:00",
    "demand": 36235.38716,
    "solar_generated": 10.3541312,
    "wind_generated": 57.64284106,
    "marketplace_to_demand": 160.2462492,
    "grid_to_demand": 32026.37095,
    "govt_battery_charge": 69.54978615
  },
  {
    "timestamp": "15/08/24 10:00",
    "demand": 30272.22133,
    "solar_generated": 18.93373862,
    "wind_generated": 76.2543047,
    "marketplace_to_demand": 74.57455839,
    "grid_to_demand": 32874.76939,
    "govt_battery_charge": 93.66570493
  },
  {
    "timestamp": "15/08/24 11:00",
    "demand": 32804.75572,
    "solar_generated": 12.93690498,
    "wind_generated": 76.60533229,
    "marketplace_to_demand": 139.9704537,
    "grid_to_demand": 33719.47042,
    "govt_battery_charge": 20.47060354
  },
  {
    "timestamp": "15/08/24 12:00",
    "demand": 31195.95821,
    "solar_generated": 19.34272242,
    "wind_generated": 65.05827493,
    "marketplace_to_demand": 72.75841246,
    "grid_to_demand": 29927.30739,
    "govt_battery_charge": 55.63206882
  },
  {
    "timestamp": "15/08/24 13:00",
    "demand": 31025.68291,
    "solar_generated": 17.71747507,
    "wind_generated": 67.72295887,
    "marketplace_to_demand": 139.0497438,
    "grid_to_demand": 35143.05812,
    "govt_battery_charge": 79.03267904
  },
  {
    "timestamp": "15/08/24 14:00",
    "demand": 31201.39303,
    "solar_generated": 10.21199071,
    "wind_generated": 67.83848005,
    "marketplace_to_demand": 85.41639943,
    "grid_to_demand": 32746.34576,
    "govt_battery_charge": 29.19705747
  },
  {
    "timestamp": "15/08/24 15:00",
    "demand": 34144.04282,
    "solar_generated": 10.96558485,
    "wind_generated": 75.40480226,
    "marketplace_to_demand": 104.188886,
    "grid_to_demand": 30379.14618,
    "govt_battery_charge": 23.98323332
  },
  {
    "timestamp": "15/08/24 16:00",
    "demand": 35026.34878,
    "solar_generated": 13.1205357,
    "wind_generated": 66.20102348,
    "marketplace_to_demand": 149.2673727,
    "grid_to_demand": 35685.32377,
    "govt_battery_charge": 28.25387942
  },
  {
    "timestamp": "15/08/24 17:00",
    "demand": 30856.63139,
    "solar_generated": 20.21859125,
    "wind_generated": 60.9575656,
    "marketplace_to_demand": 91.94633441,
    "grid_to_demand": 32799.66622,
    "govt_battery_charge": 52.77602124
  },
  {
    "timestamp": "15/08/24 18:00",
    "demand": 35728.54217,
    "solar_generated": 0,
    "wind_generated": 56.03432486,
    "marketplace_to_demand": 124.0732067,
    "grid_to_demand": 33668.43062,
    "govt_battery_charge": 12.9436379
  },
  {
    "timestamp": "15/08/24 19:00",
    "demand": 35804.97918,
    "solar_generated": 0,
    "wind_generated": 66.78590608,
    "marketplace_to_demand": 157.1722256,
    "grid_to_demand": 35944.81446,
    "govt_battery_charge": 50.66648682
  },
  {
    "timestamp": "15/08/24 20:00",
    "demand": 33485.74766,
    "solar_generated": 0,
    "wind_generated": 67.60077658,
    "marketplace_to_demand": 152.8618917,
    "grid_to_demand": 36430.95627,
    "govt_battery_charge": 57.80411575
  },
  {
    "timestamp": "15/08/24 21:00",
    "demand": 34930.1425,
    "solar_generated": 0,
    "wind_generated": 74.00006525,
    "marketplace_to_demand": 34.42653544,
    "grid_to_demand": 33649.94986,
    "govt_battery_charge": 12.61396877
  },
  {
    "timestamp": "15/08/24 22:00",
    "demand": 33207.1666,
    "solar_generated": 0,
    "wind_generated": 63.58281604,
    "marketplace_to_demand": 33.23366022,
    "grid_to_demand": 35873.43778,
    "govt_battery_charge": 50.45001256
  },
  {
    "timestamp": "15/08/24 23:00",
    "demand": 33187.2921,
    "solar_generated": 0,
    "wind_generated": 77.12530082,
    "marketplace_to_demand": 158.6433594,
    "grid_to_demand": 34669.87705,
    "govt_battery_charge": 34.20830698
  },
  {
    "timestamp": "16/08/24 0:00",
    "demand": 34701.0087,
    "solar_generated": 0,
    "wind_generated": 59.61750838,
    "marketplace_to_demand": 101.4066002,
    "grid_to_demand": 32497.6662,
    "govt_battery_charge": 75.52851001
  },
  {
    "timestamp": "16/08/24 1:00",
    "demand": 31714.53362,
    "solar_generated": 0,
    "wind_generated": 75.32903473,
    "marketplace_to_demand": 116.784739,
    "grid_to_demand": 35069.00509,
    "govt_battery_charge": 81.98760325
  },
  {
    "timestamp": "16/08/24 2:00",
    "demand": 31612.53415,
    "solar_generated": 0,
    "wind_generated": 73.91526882,
    "marketplace_to_demand": 28.8985193,
    "grid_to_demand": 33845.10475,
    "govt_battery_charge": 63.42873279
  },
  {
    "timestamp": "16/08/24 3:00",
    "demand": 35623.36869,
    "solar_generated": 0,
    "wind_generated": 57.55141001,
    "marketplace_to_demand": 147.9246481,
    "grid_to_demand": 34745.508,
    "govt_battery_charge": 73.12530679
  },
  {
    "timestamp": "16/08/24 4:00",
    "demand": 32772.65788,
    "solar_generated": 0,
    "wind_generated": 61.52102794,
    "marketplace_to_demand": 35.76137655,
    "grid_to_demand": 31887.26912,
    "govt_battery_charge": 74.02720494
  },
  {
    "timestamp": "16/08/24 5:00",
    "demand": 33899.72281,
    "solar_generated": 0,
    "wind_generated": 74.26157149,
    "marketplace_to_demand": 96.01520969,
    "grid_to_demand": 33120.19631,
    "govt_battery_charge": 11.46992692
  },
  {
    "timestamp": "16/08/24 6:00",
    "demand": 32438.51903,
    "solar_generated": 22.76181087,
    "wind_generated": 63.79696586,
    "marketplace_to_demand": 34.21325392,
    "grid_to_demand": 35380.2657,
    "govt_battery_charge": 82.18140235
  },
  {
    "timestamp": "16/08/24 7:00",
    "demand": 32272.67507,
    "solar_generated": 11.08976446,
    "wind_generated": 59.87292706,
    "marketplace_to_demand": 14.51434968,
    "grid_to_demand": 31617.3726,
    "govt_battery_charge": 24.38089492
  },
  {
    "timestamp": "16/08/24 8:00",
    "demand": 31119.06846,
    "solar_generated": 17.47000056,
    "wind_generated": 73.26432787,
    "marketplace_to_demand": 74.51154998,
    "grid_to_demand": 35386.01935,
    "govt_battery_charge": 75.56142488
  },
  {
    "timestamp": "16/08/24 9:00",
    "demand": 35276.85148,
    "solar_generated": 7.857890655,
    "wind_generated": 66.70067773,
    "marketplace_to_demand": 28.72301394,
    "grid_to_demand": 30170.46361,
    "govt_battery_charge": 2.071257839
  },
  {
    "timestamp": "16/08/24 10:00",
    "demand": 35080.90516,
    "solar_generated": 22.40963724,
    "wind_generated": 71.39273313,
    "marketplace_to_demand": 75.35723767,
    "grid_to_demand": 35143.25053,
    "govt_battery_charge": 80.19973081
  },
  {
    "timestamp": "16/08/24 11:00",
    "demand": 29985.51334,
    "solar_generated": 14.01286288,
    "wind_generated": 65.70335901,
    "marketplace_to_demand": 54.54146788,
    "grid_to_demand": 30205.11621,
    "govt_battery_charge": 34.20263758
  },
  {
    "timestamp": "16/08/24 12:00",
    "demand": 36347.29104,
    "solar_generated": 6.132790057,
    "wind_generated": 78.66182606,
    "marketplace_to_demand": 27.96033134,
    "grid_to_demand": 36405.42247,
    "govt_battery_charge": 82.04154841
  },
  {
    "timestamp": "16/08/24 13:00",
    "demand": 32909.67312,
    "solar_generated": 17.76862732,
    "wind_generated": 66.35874966,
    "marketplace_to_demand": 22.84589782,
    "grid_to_demand": 30626.05883,
    "govt_battery_charge": 81.36651516
  },
  {
    "timestamp": "16/08/24 14:00",
    "demand": 34114.65567,
    "solar_generated": 6.751390458,
    "wind_generated": 70.66062613,
    "marketplace_to_demand": 93.4753781,
    "grid_to_demand": 30614.32136,
    "govt_battery_charge": 49.09074569
  },
  {
    "timestamp": "16/08/24 15:00",
    "demand": 32969.89481,
    "solar_generated": 21.15469971,
    "wind_generated": 74.96207853,
    "marketplace_to_demand": 158.5569806,
    "grid_to_demand": 35552.06274,
    "govt_battery_charge": 8.09901292
  },
  {
    "timestamp": "16/08/24 16:00",
    "demand": 34365.19122,
    "solar_generated": 8.688991152,
    "wind_generated": 77.60732213,
    "marketplace_to_demand": 49.724041,
    "grid_to_demand": 31333.22421,
    "govt_battery_charge": 10.34755148
  },
  {
    "timestamp": "16/08/24 17:00",
    "demand": 33366.87747,
    "solar_generated": 8.199652386,
    "wind_generated": 58.26832759,
    "marketplace_to_demand": 146.3107619,
    "grid_to_demand": 32820.42701,
    "govt_battery_charge": 4.124875912
  },
  {
    "timestamp": "16/08/24 18:00",
    "demand": 33443.44828,
    "solar_generated": 0,
    "wind_generated": 60.217582,
    "marketplace_to_demand": 189.2896035,
    "grid_to_demand": 35341.52514,
    "govt_battery_charge": 63.02797284
  },
  {
    "timestamp": "16/08/24 19:00",
    "demand": 33018.15375,
    "solar_generated": 0,
    "wind_generated": 78.04050106,
    "marketplace_to_demand": 196.4041279,
    "grid_to_demand": 31344.55418,
    "govt_battery_charge": 22.91800155
  },
  {
    "timestamp": "16/08/24 20:00",
    "demand": 33758.97833,
    "solar_generated": 0,
    "wind_generated": 73.8771962,
    "marketplace_to_demand": 1.680182337,
    "grid_to_demand": 34191.19746,
    "govt_battery_charge": 60.64438152
  },
  {
    "timestamp": "16/08/24 21:00",
    "demand": 32172.04547,
    "solar_generated": 0,
    "wind_generated": 77.38735595,
    "marketplace_to_demand": 157.0634539,
    "grid_to_demand": 36121.83196,
    "govt_battery_charge": 17.70235239
  },
  {
    "timestamp": "16/08/24 22:00",
    "demand": 30607.16867,
    "solar_generated": 0,
    "wind_generated": 74.06143714,
    "marketplace_to_demand": 98.04236167,
    "grid_to_demand": 36247.13481,
    "govt_battery_charge": 15.30266013
  },
  {
    "timestamp": "16/08/24 23:00",
    "demand": 34440.52501,
    "solar_generated": 0,
    "wind_generated": 75.73689582,
    "marketplace_to_demand": 1.157652118,
    "grid_to_demand": 31032.51359,
    "govt_battery_charge": 65.22800802
  },
  {
    "timestamp": "17/08/24 0:00",
    "demand": 32718.41476,
    "solar_generated": 0,
    "wind_generated": 56.83998237,
    "marketplace_to_demand": 165.0848658,
    "grid_to_demand": 30948.83015,
    "govt_battery_charge": 43.03508939
  },
  {
    "timestamp": "17/08/24 1:00",
    "demand": 35034.76069,
    "solar_generated": 0,
    "wind_generated": 73.23258468,
    "marketplace_to_demand": 168.5441676,
    "grid_to_demand": 31913.39129,
    "govt_battery_charge": 6.666377749
  },
  {
    "timestamp": "17/08/24 2:00",
    "demand": 36555.45478,
    "solar_generated": 0,
    "wind_generated": 69.70628589,
    "marketplace_to_demand": 39.98912206,
    "grid_to_demand": 32016.78545,
    "govt_battery_charge": 53.56331729
  },
  {
    "timestamp": "17/08/24 3:00",
    "demand": 29995.28618,
    "solar_generated": 0,
    "wind_generated": 68.8692318,
    "marketplace_to_demand": 76.86502388,
    "grid_to_demand": 35257.20345,
    "govt_battery_charge": 51.03716858
  },
  {
    "timestamp": "17/08/24 4:00",
    "demand": 35258.53051,
    "solar_generated": 0,
    "wind_generated": 74.93881817,
    "marketplace_to_demand": 120.110412,
    "grid_to_demand": 31814.46425,
    "govt_battery_charge": 59.22447901
  },
  {
    "timestamp": "17/08/24 5:00",
    "demand": 32760.39437,
    "solar_generated": 0,
    "wind_generated": 62.34455605,
    "marketplace_to_demand": 127.973172,
    "grid_to_demand": 34250.83851,
    "govt_battery_charge": 32.29645272
  },
  {
    "timestamp": "17/08/24 6:00",
    "demand": 34422.19352,
    "solar_generated": 14.90319654,
    "wind_generated": 60.82146282,
    "marketplace_to_demand": 130.9275218,
    "grid_to_demand": 32337.16601,
    "govt_battery_charge": 61.84229626
  },
  {
    "timestamp": "17/08/24 7:00",
    "demand": 33733.73299,
    "solar_generated": 10.56210876,
    "wind_generated": 63.12861057,
    "marketplace_to_demand": 71.92591328,
    "grid_to_demand": 36064.65055,
    "govt_battery_charge": 59.51846127
  },
  {
    "timestamp": "17/08/24 8:00",
    "demand": 31851.21829,
    "solar_generated": 15.76614353,
    "wind_generated": 62.75834467,
    "marketplace_to_demand": 81.73029732,
    "grid_to_demand": 31693.98618,
    "govt_battery_charge": 83.54181542
  },
  {
    "timestamp": "17/08/24 9:00",
    "demand": 35379.24438,
    "solar_generated": 20.92781057,
    "wind_generated": 66.74617376,
    "marketplace_to_demand": 138.8045432,
    "grid_to_demand": 35112.62547,
    "govt_battery_charge": 25.00441234
  },
  {
    "timestamp": "17/08/24 10:00",
    "demand": 31125.29346,
    "solar_generated": 6.943534135,
    "wind_generated": 57.87622012,
    "marketplace_to_demand": 140.0183323,
    "grid_to_demand": 33962.16655,
    "govt_battery_charge": 69.74370654
  },
  {
    "timestamp": "17/08/24 11:00",
    "demand": 34763.55165,
    "solar_generated": 19.39785954,
    "wind_generated": 66.82363039,
    "marketplace_to_demand": 191.7573564,
    "grid_to_demand": 32825.73665,
    "govt_battery_charge": 54.69703381
  },
  {
    "timestamp": "17/08/24 12:00",
    "demand": 35447.70174,
    "solar_generated": 12.24071822,
    "wind_generated": 57.97940531,
    "marketplace_to_demand": 109.4618043,
    "grid_to_demand": 33929.4043,
    "govt_battery_charge": 76.36954777
  },
  {
    "timestamp": "17/08/24 13:00",
    "demand": 34253.07408,
    "solar_generated": 21.84323345,
    "wind_generated": 60.32939096,
    "marketplace_to_demand": 92.05197451,
    "grid_to_demand": 35520.77874,
    "govt_battery_charge": 34.17091666
  },
  {
    "timestamp": "17/08/24 14:00",
    "demand": 33161.66074,
    "solar_generated": 14.80132708,
    "wind_generated": 66.24630502,
    "marketplace_to_demand": 130.1598678,
    "grid_to_demand": 29938.62609,
    "govt_battery_charge": 72.41895883
  },
  {
    "timestamp": "17/08/24 15:00",
    "demand": 34606.13889,
    "solar_generated": 21.38559581,
    "wind_generated": 55.61111432,
    "marketplace_to_demand": 128.6688717,
    "grid_to_demand": 31495.04167,
    "govt_battery_charge": 6.409405423
  },
  {
    "timestamp": "17/08/24 16:00",
    "demand": 36559.98775,
    "solar_generated": 18.81010078,
    "wind_generated": 78.11311728,
    "marketplace_to_demand": 189.1465145,
    "grid_to_demand": 30241.51004,
    "govt_battery_charge": 92.89504235
  },
  {
    "timestamp": "17/08/24 17:00",
    "demand": 32996.09264,
    "solar_generated": 12.87546383,
    "wind_generated": 73.94544858,
    "marketplace_to_demand": 125.1940294,
    "grid_to_demand": 34495.60538,
    "govt_battery_charge": 84.85367493
  },
  {
    "timestamp": "17/08/24 18:00",
    "demand": 31829.53014,
    "solar_generated": 0,
    "wind_generated": 77.52887523,
    "marketplace_to_demand": 119.671098,
    "grid_to_demand": 32784.35969,
    "govt_battery_charge": 84.74128148
  },
  {
    "timestamp": "17/08/24 19:00",
    "demand": 32972.28169,
    "solar_generated": 0,
    "wind_generated": 63.12349354,
    "marketplace_to_demand": 89.64367955,
    "grid_to_demand": 33293.48343,
    "govt_battery_charge": 62.92209034
  },
  {
    "timestamp": "17/08/24 20:00",
    "demand": 32352.57495,
    "solar_generated": 0,
    "wind_generated": 54.73532998,
    "marketplace_to_demand": 103.2232603,
    "grid_to_demand": 33036.71419,
    "govt_battery_charge": 80.31866289
  },
  {
    "timestamp": "17/08/24 21:00",
    "demand": 33911.41643,
    "solar_generated": 0,
    "wind_generated": 75.39546851,
    "marketplace_to_demand": 17.79593457,
    "grid_to_demand": 35141.97418,
    "govt_battery_charge": 81.86587925
  },
  {
    "timestamp": "17/08/24 22:00",
    "demand": 34742.47637,
    "solar_generated": 0,
    "wind_generated": 62.80491151,
    "marketplace_to_demand": 182.7866327,
    "grid_to_demand": 32936.2789,
    "govt_battery_charge": 55.89897256
  },
  {
    "timestamp": "17/08/24 23:00",
    "demand": 32256.74421,
    "solar_generated": 0,
    "wind_generated": 56.74641346,
    "marketplace_to_demand": 126.9554669,
    "grid_to_demand": 32941.64519,
    "govt_battery_charge": 21.61024877
  },
  {
    "timestamp": "18/08/24 0:00",
    "demand": 36051.62278,
    "solar_generated": 0,
    "wind_generated": 64.943243,
    "marketplace_to_demand": 86.95913283,
    "grid_to_demand": 31791.41533,
    "govt_battery_charge": 53.37205371
  },
  {
    "timestamp": "18/08/24 1:00",
    "demand": 31388.11399,
    "solar_generated": 0,
    "wind_generated": 62.21340145,
    "marketplace_to_demand": 113.6600901,
    "grid_to_demand": 31956.89498,
    "govt_battery_charge": 63.51941413
  },
  {
    "timestamp": "18/08/24 2:00",
    "demand": 36492.0021,
    "solar_generated": 0,
    "wind_generated": 60.26184941,
    "marketplace_to_demand": 50.22867701,
    "grid_to_demand": 35274.20863,
    "govt_battery_charge": 74.98344469
  },
  {
    "timestamp": "18/08/24 3:00",
    "demand": 30022.41912,
    "solar_generated": 0,
    "wind_generated": 57.08378331,
    "marketplace_to_demand": 123.0226064,
    "grid_to_demand": 35793.34451,
    "govt_battery_charge": 89.86185841
  },
  {
    "timestamp": "18/08/24 4:00",
    "demand": 34971.93267,
    "solar_generated": 0,
    "wind_generated": 67.07343078,
    "marketplace_to_demand": 175.125731,
    "grid_to_demand": 33581.5399,
    "govt_battery_charge": 34.40881244
  },
  {
    "timestamp": "18/08/24 5:00",
    "demand": 35293.55765,
    "solar_generated": 0,
    "wind_generated": 63.42160688,
    "marketplace_to_demand": 76.97341812,
    "grid_to_demand": 33109.87421,
    "govt_battery_charge": 51.30589978
  },
  {
    "timestamp": "18/08/24 6:00",
    "demand": 31166.09349,
    "solar_generated": 7.285697114,
    "wind_generated": 70.64836986,
    "marketplace_to_demand": 165.4333482,
    "grid_to_demand": 31333.9574,
    "govt_battery_charge": 14.98325005
  },
  {
    "timestamp": "18/08/24 7:00",
    "demand": 33430.51146,
    "solar_generated": 22.15702931,
    "wind_generated": 64.10390963,
    "marketplace_to_demand": 13.8334025,
    "grid_to_demand": 34807.15054,
    "govt_battery_charge": 85.35439502
  },
  {
    "timestamp": "18/08/24 8:00",
    "demand": 35288.38474,
    "solar_generated": 21.06199473,
    "wind_generated": 73.03887948,
    "marketplace_to_demand": 169.9479823,
    "grid_to_demand": 33973.11472,
    "govt_battery_charge": 96.73277968
  },
  {
    "timestamp": "18/08/24 9:00",
    "demand": 31912.58854,
    "solar_generated": 22.39034218,
    "wind_generated": 70.27184348,
    "marketplace_to_demand": 174.600489,
    "grid_to_demand": 34373.5295,
    "govt_battery_charge": 68.21194325
  },
  {
    "timestamp": "18/08/24 10:00",
    "demand": 33698.89295,
    "solar_generated": 12.77999026,
    "wind_generated": 63.78695643,
    "marketplace_to_demand": 8.18374331,
    "grid_to_demand": 35253.60665,
    "govt_battery_charge": 32.03526345
  },
  {
    "timestamp": "18/08/24 11:00",
    "demand": 31570.30038,
    "solar_generated": 13.45508617,
    "wind_generated": 78.60244706,
    "marketplace_to_demand": 76.90712861,
    "grid_to_demand": 35382.60334,
    "govt_battery_charge": 62.71674351
  },
  {
    "timestamp": "18/08/24 12:00",
    "demand": 33720.01727,
    "solar_generated": 22.35741624,
    "wind_generated": 70.24022391,
    "marketplace_to_demand": 149.3572419,
    "grid_to_demand": 35701.05211,
    "govt_battery_charge": 31.72638373
  },
  {
    "timestamp": "18/08/24 13:00",
    "demand": 36557.60855,
    "solar_generated": 16.51218263,
    "wind_generated": 79.5068239,
    "marketplace_to_demand": 162.7523072,
    "grid_to_demand": 31973.04308,
    "govt_battery_charge": 4.997546452
  },
  {
    "timestamp": "18/08/24 14:00",
    "demand": 32451.96472,
    "solar_generated": 12.89584256,
    "wind_generated": 62.69732552,
    "marketplace_to_demand": 98.60690763,
    "grid_to_demand": 33701.88443,
    "govt_battery_charge": 14.00264766
  },
  {
    "timestamp": "18/08/24 15:00",
    "demand": 35218.80133,
    "solar_generated": 13.89365045,
    "wind_generated": 79.38348429,
    "marketplace_to_demand": 187.830655,
    "grid_to_demand": 32804.34163,
    "govt_battery_charge": 50.52455133
  },
  {
    "timestamp": "18/08/24 16:00",
    "demand": 31160.94158,
    "solar_generated": 6.248202825,
    "wind_generated": 75.04707489,
    "marketplace_to_demand": 81.18473909,
    "grid_to_demand": 33960.32861,
    "govt_battery_charge": 91.71255207
  },
  {
    "timestamp": "18/08/24 17:00",
    "demand": 30951.77056,
    "solar_generated": 6.144709046,
    "wind_generated": 54.41142987,
    "marketplace_to_demand": 31.52398627,
    "grid_to_demand": 34730.2083,
    "govt_battery_charge": 83.35679642
  },
  {
    "timestamp": "18/08/24 18:00",
    "demand": 31319.28533,
    "solar_generated": 0,
    "wind_generated": 63.47716212,
    "marketplace_to_demand": 71.10909233,
    "grid_to_demand": 33232.72943,
    "govt_battery_charge": 43.80755495
  },
  {
    "timestamp": "18/08/24 19:00",
    "demand": 36163.44787,
    "solar_generated": 0,
    "wind_generated": 79.22559617,
    "marketplace_to_demand": 46.81194285,
    "grid_to_demand": 35605.7075,
    "govt_battery_charge": 19.09293635
  },
  {
    "timestamp": "18/08/24 20:00",
    "demand": 33958.81421,
    "solar_generated": 0,
    "wind_generated": 67.25413778,
    "marketplace_to_demand": 108.7401677,
    "grid_to_demand": 33455.37353,
    "govt_battery_charge": 32.9777775
  },
  {
    "timestamp": "18/08/24 21:00",
    "demand": 32721.89045,
    "solar_generated": 0,
    "wind_generated": 53.84043807,
    "marketplace_to_demand": 106.7177416,
    "grid_to_demand": 32708.21778,
    "govt_battery_charge": 74.41449543
  },
  {
    "timestamp": "18/08/24 22:00",
    "demand": 33208.0431,
    "solar_generated": 0,
    "wind_generated": 65.81613921,
    "marketplace_to_demand": 184.4347504,
    "grid_to_demand": 31909.86416,
    "govt_battery_charge": 22.62179131
  },
  {
    "timestamp": "18/08/24 23:00",
    "demand": 33833.74472,
    "solar_generated": 0,
    "wind_generated": 64.12045865,
    "marketplace_to_demand": 154.5653011,
    "grid_to_demand": 30916.18355,
    "govt_battery_charge": 86.22201122
  },
  {
    "timestamp": "19/08/24 0:00",
    "demand": 31021.00715,
    "solar_generated": 0,
    "wind_generated": 66.72982429,
    "marketplace_to_demand": 158.4541965,
    "grid_to_demand": 33918.08332,
    "govt_battery_charge": 16.16838632
  },
  {
    "timestamp": "19/08/24 1:00",
    "demand": 32068.0346,
    "solar_generated": 0,
    "wind_generated": 69.39869275,
    "marketplace_to_demand": 65.34937115,
    "grid_to_demand": 32035.64271,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/08/24 2:00",
    "demand": 32460.5813,
    "solar_generated": 0,
    "wind_generated": 67.34938041,
    "marketplace_to_demand": 174.9528059,
    "grid_to_demand": 36440.64137,
    "govt_battery_charge": 22.98384577
  },
  {
    "timestamp": "19/08/24 3:00",
    "demand": 30390.48567,
    "solar_generated": 0,
    "wind_generated": 72.49212098,
    "marketplace_to_demand": 9.830719165,
    "grid_to_demand": 33732.65743,
    "govt_battery_charge": 55.24779829
  },
  {
    "timestamp": "19/08/24 4:00",
    "demand": 30098.3417,
    "solar_generated": 0,
    "wind_generated": 66.82275991,
    "marketplace_to_demand": 23.88024765,
    "grid_to_demand": 34964.56814,
    "govt_battery_charge": 97.12243413
  },
  {
    "timestamp": "19/08/24 5:00",
    "demand": 36067.40093,
    "solar_generated": 0,
    "wind_generated": 64.29244443,
    "marketplace_to_demand": 164.1556034,
    "grid_to_demand": 34000.91473,
    "govt_battery_charge": 74.02361395
  },
  {
    "timestamp": "19/08/24 6:00",
    "demand": 32926.63066,
    "solar_generated": 21.36168917,
    "wind_generated": 64.06314565,
    "marketplace_to_demand": 194.8781203,
    "grid_to_demand": 35970.77712,
    "govt_battery_charge": 49.24657365
  },
  {
    "timestamp": "19/08/24 7:00",
    "demand": 31741.52374,
    "solar_generated": 16.74540845,
    "wind_generated": 57.9758577,
    "marketplace_to_demand": 132.8695861,
    "grid_to_demand": 30348.05475,
    "govt_battery_charge": 99.34212992
  },
  {
    "timestamp": "19/08/24 8:00",
    "demand": 32518.55411,
    "solar_generated": 14.1215098,
    "wind_generated": 64.10532496,
    "marketplace_to_demand": 123.5491095,
    "grid_to_demand": 32007.94826,
    "govt_battery_charge": 17.71368722
  },
  {
    "timestamp": "19/08/24 9:00",
    "demand": 30230.27603,
    "solar_generated": 18.76159598,
    "wind_generated": 72.93854749,
    "marketplace_to_demand": 155.3718762,
    "grid_to_demand": 36270.56013,
    "govt_battery_charge": 45.85592042
  },
  {
    "timestamp": "19/08/24 10:00",
    "demand": 32522.79284,
    "solar_generated": 7.103046089,
    "wind_generated": 61.77316885,
    "marketplace_to_demand": 104.3041469,
    "grid_to_demand": 35859.65617,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/08/24 11:00",
    "demand": 36156.97187,
    "solar_generated": 13.30983319,
    "wind_generated": 54.70226322,
    "marketplace_to_demand": 156.9945417,
    "grid_to_demand": 36184.10502,
    "govt_battery_charge": 52.63589688
  },
  {
    "timestamp": "19/08/24 12:00",
    "demand": 34196.89378,
    "solar_generated": 17.21958721,
    "wind_generated": 78.81460484,
    "marketplace_to_demand": 166.634437,
    "grid_to_demand": 35258.75319,
    "govt_battery_charge": 37.04530704
  },
  {
    "timestamp": "19/08/24 13:00",
    "demand": 31087.86342,
    "solar_generated": 8.343485532,
    "wind_generated": 63.6204773,
    "marketplace_to_demand": 19.23543973,
    "grid_to_demand": 29989.52858,
    "govt_battery_charge": 68.11639929
  },
  {
    "timestamp": "19/08/24 14:00",
    "demand": 30223.83394,
    "solar_generated": 19.54214065,
    "wind_generated": 64.59144385,
    "marketplace_to_demand": 139.6579922,
    "grid_to_demand": 34387.09366,
    "govt_battery_charge": 42.40334387
  },
  {
    "timestamp": "19/08/24 15:00",
    "demand": 31619.61838,
    "solar_generated": 10.04030087,
    "wind_generated": 60.56681793,
    "marketplace_to_demand": 190.5467549,
    "grid_to_demand": 33077.252,
    "govt_battery_charge": 2.844133464
  },
  {
    "timestamp": "19/08/24 16:00",
    "demand": 34726.28106,
    "solar_generated": 18.93888768,
    "wind_generated": 76.86623455,
    "marketplace_to_demand": 53.52279436,
    "grid_to_demand": 31988.55887,
    "govt_battery_charge": 11.43717608
  },
  {
    "timestamp": "19/08/24 17:00",
    "demand": 31902.35105,
    "solar_generated": 12.82979319,
    "wind_generated": 68.32745987,
    "marketplace_to_demand": 38.47049947,
    "grid_to_demand": 33508.7224,
    "govt_battery_charge": 42.15370796
  },
  {
    "timestamp": "19/08/24 18:00",
    "demand": 30839.5238,
    "solar_generated": 0,
    "wind_generated": 57.53213175,
    "marketplace_to_demand": 171.1381125,
    "grid_to_demand": 30468.96898,
    "govt_battery_charge": 53.58656325
  },
  {
    "timestamp": "19/08/24 19:00",
    "demand": 35136.47799,
    "solar_generated": 0,
    "wind_generated": 70.97797258,
    "marketplace_to_demand": 166.5753739,
    "grid_to_demand": 34161.50467,
    "govt_battery_charge": 21.44884347
  },
  {
    "timestamp": "19/08/24 20:00",
    "demand": 32540.02525,
    "solar_generated": 0,
    "wind_generated": 60.23727201,
    "marketplace_to_demand": 29.91676195,
    "grid_to_demand": 35418.81277,
    "govt_battery_charge": 54.09421971
  },
  {
    "timestamp": "19/08/24 21:00",
    "demand": 36575.6658,
    "solar_generated": 0,
    "wind_generated": 73.81925789,
    "marketplace_to_demand": 143.6673836,
    "grid_to_demand": 30869.35964,
    "govt_battery_charge": 96.08480732
  },
  {
    "timestamp": "19/08/24 22:00",
    "demand": 32105.0277,
    "solar_generated": 0,
    "wind_generated": 65.65258019,
    "marketplace_to_demand": 183.5420895,
    "grid_to_demand": 31196.68257,
    "govt_battery_charge": 95.41376891
  },
  {
    "timestamp": "19/08/24 23:00",
    "demand": 36268.0182,
    "solar_generated": 0,
    "wind_generated": 71.45416736,
    "marketplace_to_demand": 98.70409499,
    "grid_to_demand": 32247.76536,
    "govt_battery_charge": 97.80606758
  },
  {
    "timestamp": "20/08/24 0:00",
    "demand": 36563.47356,
    "solar_generated": 0,
    "wind_generated": 58.46602043,
    "marketplace_to_demand": 171.0542557,
    "grid_to_demand": 30570.03409,
    "govt_battery_charge": 67.39518011
  },
  {
    "timestamp": "20/08/24 1:00",
    "demand": 32108.06529,
    "solar_generated": 0,
    "wind_generated": 76.46145279,
    "marketplace_to_demand": 178.5759958,
    "grid_to_demand": 33127.63764,
    "govt_battery_charge": 75.07084087
  },
  {
    "timestamp": "20/08/24 2:00",
    "demand": 34047.58373,
    "solar_generated": 0,
    "wind_generated": 60.81325634,
    "marketplace_to_demand": 198.56365,
    "grid_to_demand": 34583.0513,
    "govt_battery_charge": 33.29382926
  },
  {
    "timestamp": "20/08/24 3:00",
    "demand": 35693.69982,
    "solar_generated": 0,
    "wind_generated": 63.6695829,
    "marketplace_to_demand": 191.673746,
    "grid_to_demand": 35283.36973,
    "govt_battery_charge": 66.60466264
  },
  {
    "timestamp": "20/08/24 4:00",
    "demand": 31048.00988,
    "solar_generated": 0,
    "wind_generated": 55.75153534,
    "marketplace_to_demand": 126.9295304,
    "grid_to_demand": 34090.69709,
    "govt_battery_charge": 96.16370943
  },
  {
    "timestamp": "20/08/24 5:00",
    "demand": 35177.32447,
    "solar_generated": 0,
    "wind_generated": 62.29890963,
    "marketplace_to_demand": 152.7273063,
    "grid_to_demand": 35118.59873,
    "govt_battery_charge": 40.05423013
  },
  {
    "timestamp": "20/08/24 6:00",
    "demand": 33463.9131,
    "solar_generated": 9.619836104,
    "wind_generated": 67.53756092,
    "marketplace_to_demand": 12.9224439,
    "grid_to_demand": 31221.463,
    "govt_battery_charge": 70.34250321
  },
  {
    "timestamp": "20/08/24 7:00",
    "demand": 30629.11486,
    "solar_generated": 12.09321153,
    "wind_generated": 78.43016781,
    "marketplace_to_demand": 193.8873692,
    "grid_to_demand": 36395.00416,
    "govt_battery_charge": 55.62162728
  },
  {
    "timestamp": "20/08/24 8:00",
    "demand": 31433.87788,
    "solar_generated": 23.40211604,
    "wind_generated": 62.3249999,
    "marketplace_to_demand": 68.64424315,
    "grid_to_demand": 32543.38882,
    "govt_battery_charge": 68.04289006
  },
  {
    "timestamp": "20/08/24 9:00",
    "demand": 33260.21689,
    "solar_generated": 15.01790345,
    "wind_generated": 79.06297817,
    "marketplace_to_demand": 6.97251016,
    "grid_to_demand": 32821.04937,
    "govt_battery_charge": 98.67958399
  },
  {
    "timestamp": "20/08/24 10:00",
    "demand": 32431.27099,
    "solar_generated": 12.0983639,
    "wind_generated": 74.83039096,
    "marketplace_to_demand": 158.1637979,
    "grid_to_demand": 33015.22138,
    "govt_battery_charge": 21.10483181
  },
  {
    "timestamp": "20/08/24 11:00",
    "demand": 30443.51171,
    "solar_generated": 19.38923084,
    "wind_generated": 77.63845945,
    "marketplace_to_demand": 95.19520688,
    "grid_to_demand": 35018.95863,
    "govt_battery_charge": 38.0028273
  },
  {
    "timestamp": "20/08/24 12:00",
    "demand": 35190.97526,
    "solar_generated": 14.74062496,
    "wind_generated": 73.54560462,
    "marketplace_to_demand": 36.87610721,
    "grid_to_demand": 30956.72509,
    "govt_battery_charge": 72.29637021
  },
  {
    "timestamp": "20/08/24 13:00",
    "demand": 32275.63967,
    "solar_generated": 12.35942342,
    "wind_generated": 58.21569162,
    "marketplace_to_demand": 188.408688,
    "grid_to_demand": 30733.11817,
    "govt_battery_charge": 3.763300472
  },
  {
    "timestamp": "20/08/24 14:00",
    "demand": 30398.55972,
    "solar_generated": 7.615711068,
    "wind_generated": 65.49526971,
    "marketplace_to_demand": 120.6147777,
    "grid_to_demand": 33590.76811,
    "govt_battery_charge": 15.03187907
  },
  {
    "timestamp": "20/08/24 15:00",
    "demand": 30059.48485,
    "solar_generated": 20.6344656,
    "wind_generated": 57.82446061,
    "marketplace_to_demand": 128.2382008,
    "grid_to_demand": 35408.43777,
    "govt_battery_charge": 89.23907794
  },
  {
    "timestamp": "20/08/24 16:00",
    "demand": 31415.16896,
    "solar_generated": 11.38031467,
    "wind_generated": 66.10226481,
    "marketplace_to_demand": 108.4863619,
    "grid_to_demand": 35929.80485,
    "govt_battery_charge": 18.99247293
  },
  {
    "timestamp": "20/08/24 17:00",
    "demand": 31660.2873,
    "solar_generated": 8.268051626,
    "wind_generated": 76.96187708,
    "marketplace_to_demand": 88.96933539,
    "grid_to_demand": 31375.04856,
    "govt_battery_charge": 39.30629087
  },
  {
    "timestamp": "20/08/24 18:00",
    "demand": 33501.74433,
    "solar_generated": 0,
    "wind_generated": 59.24549853,
    "marketplace_to_demand": 111.5503328,
    "grid_to_demand": 31773.41999,
    "govt_battery_charge": 15.16269356
  },
  {
    "timestamp": "20/08/24 19:00",
    "demand": 34509.73121,
    "solar_generated": 0,
    "wind_generated": 69.64175057,
    "marketplace_to_demand": 5.065902076,
    "grid_to_demand": 35252.46258,
    "govt_battery_charge": 42.20627716
  },
  {
    "timestamp": "20/08/24 20:00",
    "demand": 32242.65129,
    "solar_generated": 0,
    "wind_generated": 67.51443422,
    "marketplace_to_demand": 182.5059598,
    "grid_to_demand": 31837.42019,
    "govt_battery_charge": 77.05738826
  },
  {
    "timestamp": "20/08/24 21:00",
    "demand": 33933.59051,
    "solar_generated": 0,
    "wind_generated": 74.20982499,
    "marketplace_to_demand": 175.2309692,
    "grid_to_demand": 35318.71342,
    "govt_battery_charge": 85.11264316
  },
  {
    "timestamp": "20/08/24 22:00",
    "demand": 32177.1994,
    "solar_generated": 0,
    "wind_generated": 69.7711415,
    "marketplace_to_demand": 152.4367897,
    "grid_to_demand": 33026.72385,
    "govt_battery_charge": 38.02051174
  },
  {
    "timestamp": "20/08/24 23:00",
    "demand": 35189.51009,
    "solar_generated": 0,
    "wind_generated": 65.9227555,
    "marketplace_to_demand": 162.9809849,
    "grid_to_demand": 34400.3625,
    "govt_battery_charge": 41.69006665
  },
  {
    "timestamp": "21/08/24 0:00",
    "demand": 31306.33614,
    "solar_generated": 0,
    "wind_generated": 56.45589401,
    "marketplace_to_demand": 77.15318486,
    "grid_to_demand": 31738.87797,
    "govt_battery_charge": 15.30844738
  },
  {
    "timestamp": "21/08/24 1:00",
    "demand": 35288.02398,
    "solar_generated": 0,
    "wind_generated": 80.08887563,
    "marketplace_to_demand": 103.5260134,
    "grid_to_demand": 30677.21518,
    "govt_battery_charge": 92.31314337
  },
  {
    "timestamp": "21/08/24 2:00",
    "demand": 30681.46647,
    "solar_generated": 0,
    "wind_generated": 72.49362102,
    "marketplace_to_demand": 68.77872438,
    "grid_to_demand": 30628.8214,
    "govt_battery_charge": 5.062226737
  },
  {
    "timestamp": "21/08/24 3:00",
    "demand": 35215.35737,
    "solar_generated": 0,
    "wind_generated": 59.90824115,
    "marketplace_to_demand": 75.59929016,
    "grid_to_demand": 32950.02114,
    "govt_battery_charge": 98.88476299
  },
  {
    "timestamp": "21/08/24 4:00",
    "demand": 32884.50832,
    "solar_generated": 0,
    "wind_generated": 55.54835274,
    "marketplace_to_demand": 129.2686671,
    "grid_to_demand": 31161.80862,
    "govt_battery_charge": 80.37052771
  },
  {
    "timestamp": "21/08/24 5:00",
    "demand": 35496.3242,
    "solar_generated": 0,
    "wind_generated": 70.77598164,
    "marketplace_to_demand": 171.5689736,
    "grid_to_demand": 31751.41107,
    "govt_battery_charge": 17.68308395
  },
  {
    "timestamp": "21/08/24 6:00",
    "demand": 31005.98256,
    "solar_generated": 19.92646255,
    "wind_generated": 62.04115945,
    "marketplace_to_demand": 110.1986359,
    "grid_to_demand": 36117.85523,
    "govt_battery_charge": 37.57896159
  },
  {
    "timestamp": "21/08/24 7:00",
    "demand": 30376.0475,
    "solar_generated": 20.64389568,
    "wind_generated": 76.9852351,
    "marketplace_to_demand": 109.0199191,
    "grid_to_demand": 33895.20813,
    "govt_battery_charge": 43.25175015
  },
  {
    "timestamp": "21/08/24 8:00",
    "demand": 31984.23575,
    "solar_generated": 19.30832855,
    "wind_generated": 72.18539914,
    "marketplace_to_demand": 81.53151475,
    "grid_to_demand": 33801.90878,
    "govt_battery_charge": 63.53564926
  },
  {
    "timestamp": "21/08/24 9:00",
    "demand": 31739.3361,
    "solar_generated": 6.493903829,
    "wind_generated": 78.73486412,
    "marketplace_to_demand": 128.3190208,
    "grid_to_demand": 33395.35259,
    "govt_battery_charge": 15.65303386
  },
  {
    "timestamp": "21/08/24 10:00",
    "demand": 35785.95945,
    "solar_generated": 15.71153765,
    "wind_generated": 60.83180481,
    "marketplace_to_demand": 13.3159961,
    "grid_to_demand": 32375.5579,
    "govt_battery_charge": 7.765596206
  },
  {
    "timestamp": "21/08/24 11:00",
    "demand": 32152.88891,
    "solar_generated": 17.28904331,
    "wind_generated": 56.26582769,
    "marketplace_to_demand": 189.5565202,
    "grid_to_demand": 30143.71964,
    "govt_battery_charge": 96.16125803
  },
  {
    "timestamp": "21/08/24 12:00",
    "demand": 33829.38099,
    "solar_generated": 7.466853289,
    "wind_generated": 69.87363351,
    "marketplace_to_demand": 32.41955537,
    "grid_to_demand": 33420.93581,
    "govt_battery_charge": 40.22125219
  },
  {
    "timestamp": "21/08/24 13:00",
    "demand": 36564.8808,
    "solar_generated": 17.82620396,
    "wind_generated": 58.19665459,
    "marketplace_to_demand": 121.577809,
    "grid_to_demand": 35013.73468,
    "govt_battery_charge": 98.84916359
  },
  {
    "timestamp": "21/08/24 14:00",
    "demand": 35388.43934,
    "solar_generated": 22.82877781,
    "wind_generated": 72.00600164,
    "marketplace_to_demand": 106.4564059,
    "grid_to_demand": 33525.67574,
    "govt_battery_charge": 75.49716598
  },
  {
    "timestamp": "21/08/24 15:00",
    "demand": 35617.20662,
    "solar_generated": 18.91354637,
    "wind_generated": 60.44216605,
    "marketplace_to_demand": 96.4088567,
    "grid_to_demand": 32512.63342,
    "govt_battery_charge": 92.48191693
  },
  {
    "timestamp": "21/08/24 16:00",
    "demand": 30487.55426,
    "solar_generated": 12.25773748,
    "wind_generated": 62.07378021,
    "marketplace_to_demand": 55.35728368,
    "grid_to_demand": 31782.36632,
    "govt_battery_charge": 35.40594455
  },
  {
    "timestamp": "21/08/24 17:00",
    "demand": 32957.174,
    "solar_generated": 12.73796147,
    "wind_generated": 78.24247873,
    "marketplace_to_demand": 80.28060012,
    "grid_to_demand": 31186.79224,
    "govt_battery_charge": 70.04066958
  },
  {
    "timestamp": "21/08/24 18:00",
    "demand": 31564.28692,
    "solar_generated": 0,
    "wind_generated": 73.7085376,
    "marketplace_to_demand": 139.5305541,
    "grid_to_demand": 30414.40548,
    "govt_battery_charge": 43.61683235
  },
  {
    "timestamp": "21/08/24 19:00",
    "demand": 33450.57001,
    "solar_generated": 0,
    "wind_generated": 58.74005582,
    "marketplace_to_demand": 168.7228976,
    "grid_to_demand": 32320.66307,
    "govt_battery_charge": 71.38588619
  },
  {
    "timestamp": "21/08/24 20:00",
    "demand": 31168.41369,
    "solar_generated": 0,
    "wind_generated": 59.66184477,
    "marketplace_to_demand": 115.933392,
    "grid_to_demand": 31873.66084,
    "govt_battery_charge": 48.59230973
  },
  {
    "timestamp": "21/08/24 21:00",
    "demand": 36595.63306,
    "solar_generated": 0,
    "wind_generated": 60.03628554,
    "marketplace_to_demand": 106.5063162,
    "grid_to_demand": 31227.32297,
    "govt_battery_charge": 65.4093864
  },
  {
    "timestamp": "21/08/24 22:00",
    "demand": 34687.79536,
    "solar_generated": 0,
    "wind_generated": 78.25600834,
    "marketplace_to_demand": 115.6436745,
    "grid_to_demand": 35129.0862,
    "govt_battery_charge": 84.490769
  },
  {
    "timestamp": "21/08/24 23:00",
    "demand": 31453.45359,
    "solar_generated": 0,
    "wind_generated": 75.93141037,
    "marketplace_to_demand": 35.61823751,
    "grid_to_demand": 34715.10065,
    "govt_battery_charge": 67.05463398
  },
  {
    "timestamp": "22/08/24 0:00",
    "demand": 34782.12001,
    "solar_generated": 0,
    "wind_generated": 75.59876365,
    "marketplace_to_demand": 100.7335355,
    "grid_to_demand": 30216.94677,
    "govt_battery_charge": 89.43855247
  },
  {
    "timestamp": "22/08/24 1:00",
    "demand": 33031.28803,
    "solar_generated": 0,
    "wind_generated": 62.76154053,
    "marketplace_to_demand": 14.41686491,
    "grid_to_demand": 31838.24933,
    "govt_battery_charge": 78.48633848
  },
  {
    "timestamp": "22/08/24 2:00",
    "demand": 34057.13895,
    "solar_generated": 0,
    "wind_generated": 77.66781945,
    "marketplace_to_demand": 193.2904882,
    "grid_to_demand": 30966.49317,
    "govt_battery_charge": 91.5299973
  },
  {
    "timestamp": "22/08/24 3:00",
    "demand": 30191.57718,
    "solar_generated": 0,
    "wind_generated": 75.64146208,
    "marketplace_to_demand": 61.0010988,
    "grid_to_demand": 31486.68634,
    "govt_battery_charge": 33.6529923
  },
  {
    "timestamp": "22/08/24 4:00",
    "demand": 32050.29092,
    "solar_generated": 0,
    "wind_generated": 68.95194209,
    "marketplace_to_demand": 37.08949695,
    "grid_to_demand": 34679.42462,
    "govt_battery_charge": 85.15357323
  },
  {
    "timestamp": "22/08/24 5:00",
    "demand": 31743.44879,
    "solar_generated": 0,
    "wind_generated": 56.19967469,
    "marketplace_to_demand": 186.8353176,
    "grid_to_demand": 33891.71575,
    "govt_battery_charge": 41.82611214
  },
  {
    "timestamp": "22/08/24 6:00",
    "demand": 31983.14658,
    "solar_generated": 8.127755767,
    "wind_generated": 67.27247471,
    "marketplace_to_demand": 110.1256457,
    "grid_to_demand": 34961.86172,
    "govt_battery_charge": 9.889171774
  },
  {
    "timestamp": "22/08/24 7:00",
    "demand": 31967.98582,
    "solar_generated": 22.52143181,
    "wind_generated": 72.93707759,
    "marketplace_to_demand": 185.7661444,
    "grid_to_demand": 34308.4309,
    "govt_battery_charge": 89.33244562
  },
  {
    "timestamp": "22/08/24 8:00",
    "demand": 34847.53465,
    "solar_generated": 21.41763646,
    "wind_generated": 72.70986248,
    "marketplace_to_demand": 148.2768936,
    "grid_to_demand": 30951.61198,
    "govt_battery_charge": 4.509427012
  },
  {
    "timestamp": "22/08/24 9:00",
    "demand": 36083.81079,
    "solar_generated": 17.87834624,
    "wind_generated": 57.97116497,
    "marketplace_to_demand": 53.41010318,
    "grid_to_demand": 31806.55135,
    "govt_battery_charge": 57.819701
  },
  {
    "timestamp": "22/08/24 10:00",
    "demand": 34697.21768,
    "solar_generated": 12.17734456,
    "wind_generated": 60.04772489,
    "marketplace_to_demand": 148.9770453,
    "grid_to_demand": 34938.92817,
    "govt_battery_charge": 2.178043127
  },
  {
    "timestamp": "22/08/24 11:00",
    "demand": 31386.76568,
    "solar_generated": 17.40443263,
    "wind_generated": 72.07230077,
    "marketplace_to_demand": 198.0823116,
    "grid_to_demand": 31405.2321,
    "govt_battery_charge": 66.83453472
  },
  {
    "timestamp": "22/08/24 12:00",
    "demand": 30226.51629,
    "solar_generated": 16.81229716,
    "wind_generated": 60.46943014,
    "marketplace_to_demand": 54.5815552,
    "grid_to_demand": 34172.68512,
    "govt_battery_charge": 53.57602913
  },
  {
    "timestamp": "22/08/24 13:00",
    "demand": 33155.78437,
    "solar_generated": 17.96031668,
    "wind_generated": 74.77358176,
    "marketplace_to_demand": 90.45983412,
    "grid_to_demand": 30651.86225,
    "govt_battery_charge": 67.53770744
  },
  {
    "timestamp": "22/08/24 14:00",
    "demand": 30094.09098,
    "solar_generated": 19.18215011,
    "wind_generated": 74.72424899,
    "marketplace_to_demand": 194.927616,
    "grid_to_demand": 31651.26403,
    "govt_battery_charge": 77.4880452
  },
  {
    "timestamp": "22/08/24 15:00",
    "demand": 35709.53051,
    "solar_generated": 13.97571149,
    "wind_generated": 53.99787664,
    "marketplace_to_demand": 123.4482485,
    "grid_to_demand": 33193.05067,
    "govt_battery_charge": 47.60058873
  },
  {
    "timestamp": "22/08/24 16:00",
    "demand": 35525.59132,
    "solar_generated": 9.69060633,
    "wind_generated": 59.08704984,
    "marketplace_to_demand": 194.9463902,
    "grid_to_demand": 33362.516,
    "govt_battery_charge": 28.94760645
  },
  {
    "timestamp": "22/08/24 17:00",
    "demand": 30307.53268,
    "solar_generated": 13.06618443,
    "wind_generated": 77.82451003,
    "marketplace_to_demand": 9.449253132,
    "grid_to_demand": 36430.71783,
    "govt_battery_charge": 66.8051346
  },
  {
    "timestamp": "22/08/24 18:00",
    "demand": 35050.66909,
    "solar_generated": 0,
    "wind_generated": 66.06518833,
    "marketplace_to_demand": 46.54856377,
    "grid_to_demand": 32543.27032,
    "govt_battery_charge": 4.881094565
  },
  {
    "timestamp": "22/08/24 19:00",
    "demand": 35316.35294,
    "solar_generated": 0,
    "wind_generated": 55.05894669,
    "marketplace_to_demand": 78.24525782,
    "grid_to_demand": 30059.67643,
    "govt_battery_charge": 56.9378573
  },
  {
    "timestamp": "22/08/24 20:00",
    "demand": 31515.54889,
    "solar_generated": 0,
    "wind_generated": 79.01594768,
    "marketplace_to_demand": 57.99405055,
    "grid_to_demand": 35811.69167,
    "govt_battery_charge": 24.2543796
  },
  {
    "timestamp": "22/08/24 21:00",
    "demand": 31540.48155,
    "solar_generated": 0,
    "wind_generated": 58.43377453,
    "marketplace_to_demand": 89.67912107,
    "grid_to_demand": 30968.18089,
    "govt_battery_charge": 94.38415921
  },
  {
    "timestamp": "22/08/24 22:00",
    "demand": 35609.57022,
    "solar_generated": 0,
    "wind_generated": 73.04203692,
    "marketplace_to_demand": 134.98753,
    "grid_to_demand": 31990.91499,
    "govt_battery_charge": 47.73447269
  },
  {
    "timestamp": "22/08/24 23:00",
    "demand": 35144.35174,
    "solar_generated": 0,
    "wind_generated": 76.70018802,
    "marketplace_to_demand": 109.272188,
    "grid_to_demand": 30756.39234,
    "govt_battery_charge": 38.13730805
  },
  {
    "timestamp": "23/08/24 0:00",
    "demand": 32346.31869,
    "solar_generated": 0,
    "wind_generated": 75.5570766,
    "marketplace_to_demand": 65.13269,
    "grid_to_demand": 32337.31268,
    "govt_battery_charge": 47.24812722
  },
  {
    "timestamp": "23/08/24 1:00",
    "demand": 32852.99044,
    "solar_generated": 0,
    "wind_generated": 68.19507802,
    "marketplace_to_demand": 35.45702267,
    "grid_to_demand": 34160.43489,
    "govt_battery_charge": 94.566483
  },
  {
    "timestamp": "23/08/24 2:00",
    "demand": 31907.6163,
    "solar_generated": 0,
    "wind_generated": 71.18773203,
    "marketplace_to_demand": 149.0515753,
    "grid_to_demand": 31680.808,
    "govt_battery_charge": 12.77601546
  },
  {
    "timestamp": "23/08/24 3:00",
    "demand": 30178.01956,
    "solar_generated": 0,
    "wind_generated": 75.78198591,
    "marketplace_to_demand": 178.2729014,
    "grid_to_demand": 30061.72773,
    "govt_battery_charge": 53.26520177
  },
  {
    "timestamp": "23/08/24 4:00",
    "demand": 34765.74692,
    "solar_generated": 0,
    "wind_generated": 59.50724645,
    "marketplace_to_demand": 12.05301724,
    "grid_to_demand": 31340.94968,
    "govt_battery_charge": 64.95258515
  },
  {
    "timestamp": "23/08/24 5:00",
    "demand": 34257.31632,
    "solar_generated": 0,
    "wind_generated": 60.82923016,
    "marketplace_to_demand": 197.9549236,
    "grid_to_demand": 35410.58686,
    "govt_battery_charge": 6.149137976
  },
  {
    "timestamp": "23/08/24 6:00",
    "demand": 33601.09907,
    "solar_generated": 13.5699671,
    "wind_generated": 55.46536514,
    "marketplace_to_demand": 27.91079902,
    "grid_to_demand": 31201.8443,
    "govt_battery_charge": 88.59214859
  },
  {
    "timestamp": "23/08/24 7:00",
    "demand": 35471.82632,
    "solar_generated": 22.53558447,
    "wind_generated": 62.70583061,
    "marketplace_to_demand": 153.6893675,
    "grid_to_demand": 30876.10516,
    "govt_battery_charge": 18.6241169
  },
  {
    "timestamp": "23/08/24 8:00",
    "demand": 30036.70502,
    "solar_generated": 23.70122841,
    "wind_generated": 69.83507901,
    "marketplace_to_demand": 113.5330918,
    "grid_to_demand": 34446.91991,
    "govt_battery_charge": 70.87979496
  },
  {
    "timestamp": "23/08/24 9:00",
    "demand": 33250.50078,
    "solar_generated": 18.17068503,
    "wind_generated": 62.55195158,
    "marketplace_to_demand": 159.2895224,
    "grid_to_demand": 35166.84199,
    "govt_battery_charge": 70.19316895
  },
  {
    "timestamp": "23/08/24 10:00",
    "demand": 32944.93157,
    "solar_generated": 23.75809856,
    "wind_generated": 75.81609958,
    "marketplace_to_demand": 118.737448,
    "grid_to_demand": 35459.14039,
    "govt_battery_charge": 48.56278299
  },
  {
    "timestamp": "23/08/24 11:00",
    "demand": 32934.31669,
    "solar_generated": 6.348874855,
    "wind_generated": 62.70813818,
    "marketplace_to_demand": 197.3972464,
    "grid_to_demand": 33444.62035,
    "govt_battery_charge": 53.48749858
  },
  {
    "timestamp": "23/08/24 12:00",
    "demand": 35319.45393,
    "solar_generated": 10.61460172,
    "wind_generated": 76.91811425,
    "marketplace_to_demand": 170.0187662,
    "grid_to_demand": 30369.83649,
    "govt_battery_charge": 79.32371452
  },
  {
    "timestamp": "23/08/24 13:00",
    "demand": 34027.16166,
    "solar_generated": 9.241600978,
    "wind_generated": 73.30694788,
    "marketplace_to_demand": 167.3300767,
    "grid_to_demand": 36349.34118,
    "govt_battery_charge": 90.84835377
  },
  {
    "timestamp": "23/08/24 14:00",
    "demand": 32188.11171,
    "solar_generated": 13.91364667,
    "wind_generated": 59.48860604,
    "marketplace_to_demand": 144.9900519,
    "grid_to_demand": 32349.77082,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "23/08/24 15:00",
    "demand": 35296.17649,
    "solar_generated": 11.28320208,
    "wind_generated": 68.54170048,
    "marketplace_to_demand": 15.78767721,
    "grid_to_demand": 35326.56675,
    "govt_battery_charge": 7.530241376
  },
  {
    "timestamp": "23/08/24 16:00",
    "demand": 31965.174,
    "solar_generated": 6.239307291,
    "wind_generated": 76.77666443,
    "marketplace_to_demand": 57.73468583,
    "grid_to_demand": 33015.05808,
    "govt_battery_charge": 12.75288375
  },
  {
    "timestamp": "23/08/24 17:00",
    "demand": 35172.66422,
    "solar_generated": 6.190601291,
    "wind_generated": 69.91049576,
    "marketplace_to_demand": 22.85761946,
    "grid_to_demand": 35896.34025,
    "govt_battery_charge": 67.13456458
  },
  {
    "timestamp": "23/08/24 18:00",
    "demand": 35559.1985,
    "solar_generated": 0,
    "wind_generated": 70.3183738,
    "marketplace_to_demand": 168.9693949,
    "grid_to_demand": 36193.80653,
    "govt_battery_charge": 99.41848715
  },
  {
    "timestamp": "23/08/24 19:00",
    "demand": 33530.20363,
    "solar_generated": 0,
    "wind_generated": 66.65201529,
    "marketplace_to_demand": 95.91211523,
    "grid_to_demand": 31871.29249,
    "govt_battery_charge": 3.278258151
  },
  {
    "timestamp": "23/08/24 20:00",
    "demand": 31620.64675,
    "solar_generated": 0,
    "wind_generated": 57.24946365,
    "marketplace_to_demand": 28.89691478,
    "grid_to_demand": 30705.26158,
    "govt_battery_charge": 47.55505718
  },
  {
    "timestamp": "23/08/24 21:00",
    "demand": 31624.7842,
    "solar_generated": 0,
    "wind_generated": 77.08643946,
    "marketplace_to_demand": 120.8243998,
    "grid_to_demand": 32736.50752,
    "govt_battery_charge": 77.95446332
  },
  {
    "timestamp": "23/08/24 22:00",
    "demand": 36235.48331,
    "solar_generated": 0,
    "wind_generated": 59.31157892,
    "marketplace_to_demand": 175.2910354,
    "grid_to_demand": 31297.04287,
    "govt_battery_charge": 23.29231959
  },
  {
    "timestamp": "23/08/24 23:00",
    "demand": 32594.16236,
    "solar_generated": 0,
    "wind_generated": 69.19582265,
    "marketplace_to_demand": 121.8793497,
    "grid_to_demand": 32177.28326,
    "govt_battery_charge": 39.75622789
  },
  {
    "timestamp": "24/08/24 0:00",
    "demand": 31580.39415,
    "solar_generated": 0,
    "wind_generated": 72.90120688,
    "marketplace_to_demand": 173.2714349,
    "grid_to_demand": 32264.11069,
    "govt_battery_charge": 38.80670783
  },
  {
    "timestamp": "24/08/24 1:00",
    "demand": 36377.96228,
    "solar_generated": 0,
    "wind_generated": 66.51861472,
    "marketplace_to_demand": 155.020542,
    "grid_to_demand": 36035.20597,
    "govt_battery_charge": 17.41986896
  },
  {
    "timestamp": "24/08/24 2:00",
    "demand": 36300.84235,
    "solar_generated": 0,
    "wind_generated": 59.37403186,
    "marketplace_to_demand": 138.6747349,
    "grid_to_demand": 34132.74913,
    "govt_battery_charge": 39.43710447
  },
  {
    "timestamp": "24/08/24 3:00",
    "demand": 33817.20803,
    "solar_generated": 0,
    "wind_generated": 79.03003871,
    "marketplace_to_demand": 81.47810797,
    "grid_to_demand": 33972.90374,
    "govt_battery_charge": 62.10230533
  },
  {
    "timestamp": "24/08/24 4:00",
    "demand": 34258.86088,
    "solar_generated": 0,
    "wind_generated": 59.5199697,
    "marketplace_to_demand": 103.8300716,
    "grid_to_demand": 31480.00797,
    "govt_battery_charge": 51.56400546
  },
  {
    "timestamp": "24/08/24 5:00",
    "demand": 36195.70776,
    "solar_generated": 0,
    "wind_generated": 75.17604861,
    "marketplace_to_demand": 101.6753881,
    "grid_to_demand": 33376.07985,
    "govt_battery_charge": 42.01506292
  },
  {
    "timestamp": "24/08/24 6:00",
    "demand": 33456.35617,
    "solar_generated": 19.11185673,
    "wind_generated": 56.03251207,
    "marketplace_to_demand": 112.5482229,
    "grid_to_demand": 35553.23264,
    "govt_battery_charge": 29.66903046
  },
  {
    "timestamp": "24/08/24 7:00",
    "demand": 36203.21642,
    "solar_generated": 8.843932127,
    "wind_generated": 65.70151837,
    "marketplace_to_demand": 111.0019741,
    "grid_to_demand": 36190.12126,
    "govt_battery_charge": 35.36386917
  },
  {
    "timestamp": "24/08/24 8:00",
    "demand": 30739.8563,
    "solar_generated": 9.815913788,
    "wind_generated": 75.3840557,
    "marketplace_to_demand": 80.43149947,
    "grid_to_demand": 35273.32423,
    "govt_battery_charge": 6.78184847
  },
  {
    "timestamp": "24/08/24 9:00",
    "demand": 32080.83964,
    "solar_generated": 23.9191443,
    "wind_generated": 60.87449817,
    "marketplace_to_demand": 135.017214,
    "grid_to_demand": 30567.25528,
    "govt_battery_charge": 98.43290317
  },
  {
    "timestamp": "24/08/24 10:00",
    "demand": 30008.87067,
    "solar_generated": 7.425549731,
    "wind_generated": 54.55489428,
    "marketplace_to_demand": 144.8884229,
    "grid_to_demand": 33822.96244,
    "govt_battery_charge": 57.33811937
  },
  {
    "timestamp": "24/08/24 11:00",
    "demand": 35171.14104,
    "solar_generated": 20.45235466,
    "wind_generated": 70.73968812,
    "marketplace_to_demand": 179.717647,
    "grid_to_demand": 30393.539,
    "govt_battery_charge": 38.25361021
  },
  {
    "timestamp": "24/08/24 12:00",
    "demand": 30451.68287,
    "solar_generated": 14.25630008,
    "wind_generated": 66.94612494,
    "marketplace_to_demand": 124.1444117,
    "grid_to_demand": 34523.95423,
    "govt_battery_charge": 34.84155403
  },
  {
    "timestamp": "24/08/24 13:00",
    "demand": 34376.2321,
    "solar_generated": 10.76652319,
    "wind_generated": 57.57972153,
    "marketplace_to_demand": 8.900160158,
    "grid_to_demand": 36116.93505,
    "govt_battery_charge": 48.71555227
  },
  {
    "timestamp": "24/08/24 14:00",
    "demand": 34822.92541,
    "solar_generated": 21.22357962,
    "wind_generated": 70.89967355,
    "marketplace_to_demand": 28.7555378,
    "grid_to_demand": 31308.61919,
    "govt_battery_charge": 48.43964567
  },
  {
    "timestamp": "24/08/24 15:00",
    "demand": 30996.90335,
    "solar_generated": 20.389308,
    "wind_generated": 73.64297453,
    "marketplace_to_demand": 82.91417402,
    "grid_to_demand": 32204.63302,
    "govt_battery_charge": 3.718943584
  },
  {
    "timestamp": "24/08/24 16:00",
    "demand": 31244.00782,
    "solar_generated": 9.060912153,
    "wind_generated": 64.59179182,
    "marketplace_to_demand": 29.77017718,
    "grid_to_demand": 32811.77618,
    "govt_battery_charge": 77.52004682
  },
  {
    "timestamp": "24/08/24 17:00",
    "demand": 32665.55478,
    "solar_generated": 23.36658557,
    "wind_generated": 65.82354012,
    "marketplace_to_demand": 45.77476174,
    "grid_to_demand": 33677.14127,
    "govt_battery_charge": 69.99594622
  },
  {
    "timestamp": "24/08/24 18:00",
    "demand": 35741.58658,
    "solar_generated": 0,
    "wind_generated": 75.65790974,
    "marketplace_to_demand": 64.59235511,
    "grid_to_demand": 34964.61612,
    "govt_battery_charge": 75.29496658
  },
  {
    "timestamp": "24/08/24 19:00",
    "demand": 32208.97914,
    "solar_generated": 0,
    "wind_generated": 72.31603862,
    "marketplace_to_demand": 108.696636,
    "grid_to_demand": 36071.3528,
    "govt_battery_charge": 86.22904396
  },
  {
    "timestamp": "24/08/24 20:00",
    "demand": 35806.15427,
    "solar_generated": 0,
    "wind_generated": 60.09530747,
    "marketplace_to_demand": 23.93415779,
    "grid_to_demand": 33039.77994,
    "govt_battery_charge": 59.08803208
  },
  {
    "timestamp": "24/08/24 21:00",
    "demand": 30689.90848,
    "solar_generated": 0,
    "wind_generated": 59.47386758,
    "marketplace_to_demand": 22.9473848,
    "grid_to_demand": 34366.34685,
    "govt_battery_charge": 31.40933992
  },
  {
    "timestamp": "24/08/24 22:00",
    "demand": 33629.1912,
    "solar_generated": 0,
    "wind_generated": 67.15589426,
    "marketplace_to_demand": 79.91275391,
    "grid_to_demand": 33808.89453,
    "govt_battery_charge": 24.10086311
  },
  {
    "timestamp": "24/08/24 23:00",
    "demand": 32108.90301,
    "solar_generated": 0,
    "wind_generated": 75.39257132,
    "marketplace_to_demand": 50.28846661,
    "grid_to_demand": 34577.46181,
    "govt_battery_charge": 29.91671693
  },
  {
    "timestamp": "25/08/24 0:00",
    "demand": 33032.30732,
    "solar_generated": 0,
    "wind_generated": 69.06624228,
    "marketplace_to_demand": 137.3863622,
    "grid_to_demand": 31272.20993,
    "govt_battery_charge": 1.45175622
  },
  {
    "timestamp": "25/08/24 1:00",
    "demand": 35179.58554,
    "solar_generated": 0,
    "wind_generated": 78.24558814,
    "marketplace_to_demand": 10.05796019,
    "grid_to_demand": 34376.99982,
    "govt_battery_charge": 23.36717
  },
  {
    "timestamp": "25/08/24 2:00",
    "demand": 35897.71704,
    "solar_generated": 0,
    "wind_generated": 62.12483376,
    "marketplace_to_demand": 104.5597815,
    "grid_to_demand": 31490.1283,
    "govt_battery_charge": 47.82813979
  },
  {
    "timestamp": "25/08/24 3:00",
    "demand": 35225.53855,
    "solar_generated": 0,
    "wind_generated": 61.52860892,
    "marketplace_to_demand": 149.1260027,
    "grid_to_demand": 36414.23171,
    "govt_battery_charge": 41.20996799
  },
  {
    "timestamp": "25/08/24 4:00",
    "demand": 30740.05741,
    "solar_generated": 0,
    "wind_generated": 73.52823432,
    "marketplace_to_demand": 191.8484332,
    "grid_to_demand": 30758.66925,
    "govt_battery_charge": 16.16368672
  },
  {
    "timestamp": "25/08/24 5:00",
    "demand": 33476.56809,
    "solar_generated": 0,
    "wind_generated": 54.85830877,
    "marketplace_to_demand": 177.5184546,
    "grid_to_demand": 30176.2853,
    "govt_battery_charge": 5.572022946
  },
  {
    "timestamp": "25/08/24 6:00",
    "demand": 34924.00338,
    "solar_generated": 10.74339639,
    "wind_generated": 62.17260749,
    "marketplace_to_demand": 168.8752935,
    "grid_to_demand": 32946.81008,
    "govt_battery_charge": 30.74905406
  },
  {
    "timestamp": "25/08/24 7:00",
    "demand": 33417.11989,
    "solar_generated": 22.37550184,
    "wind_generated": 70.97752762,
    "marketplace_to_demand": 153.459862,
    "grid_to_demand": 32652.93678,
    "govt_battery_charge": 30.97065791
  },
  {
    "timestamp": "25/08/24 8:00",
    "demand": 31369.00772,
    "solar_generated": 6.255525651,
    "wind_generated": 60.87578024,
    "marketplace_to_demand": 143.331866,
    "grid_to_demand": 31400.66349,
    "govt_battery_charge": 61.54149118
  },
  {
    "timestamp": "25/08/24 9:00",
    "demand": 34012.74057,
    "solar_generated": 11.21198408,
    "wind_generated": 70.47280826,
    "marketplace_to_demand": 25.40492198,
    "grid_to_demand": 35296.03151,
    "govt_battery_charge": 33.13459776
  },
  {
    "timestamp": "25/08/24 10:00",
    "demand": 30114.47048,
    "solar_generated": 21.25115446,
    "wind_generated": 65.91648345,
    "marketplace_to_demand": 183.0300313,
    "grid_to_demand": 34830.58748,
    "govt_battery_charge": 51.11931397
  },
  {
    "timestamp": "25/08/24 11:00",
    "demand": 31798.25464,
    "solar_generated": 6.08280948,
    "wind_generated": 58.43955403,
    "marketplace_to_demand": 93.41218349,
    "grid_to_demand": 31443.21968,
    "govt_battery_charge": 18.48225499
  },
  {
    "timestamp": "25/08/24 12:00",
    "demand": 36436.57791,
    "solar_generated": 17.10962684,
    "wind_generated": 60.81003168,
    "marketplace_to_demand": 122.0645826,
    "grid_to_demand": 34214.53074,
    "govt_battery_charge": 94.48240758
  },
  {
    "timestamp": "25/08/24 13:00",
    "demand": 31489.58292,
    "solar_generated": 20.7233945,
    "wind_generated": 58.38336926,
    "marketplace_to_demand": 25.60692858,
    "grid_to_demand": 35836.79296,
    "govt_battery_charge": 93.75997172
  },
  {
    "timestamp": "25/08/24 14:00",
    "demand": 33907.62172,
    "solar_generated": 18.63157396,
    "wind_generated": 78.27839604,
    "marketplace_to_demand": 4.474855118,
    "grid_to_demand": 31791.8407,
    "govt_battery_charge": 97.56578847
  },
  {
    "timestamp": "25/08/24 15:00",
    "demand": 30260.51001,
    "solar_generated": 15.14621123,
    "wind_generated": 65.88445519,
    "marketplace_to_demand": 24.39486407,
    "grid_to_demand": 33289.86112,
    "govt_battery_charge": 44.10441848
  },
  {
    "timestamp": "25/08/24 16:00",
    "demand": 31466.29075,
    "solar_generated": 8.737761435,
    "wind_generated": 71.46518025,
    "marketplace_to_demand": 193.5702683,
    "grid_to_demand": 35409.42746,
    "govt_battery_charge": 17.80227193
  },
  {
    "timestamp": "25/08/24 17:00",
    "demand": 30733.7335,
    "solar_generated": 7.146256472,
    "wind_generated": 80.33384741,
    "marketplace_to_demand": 181.4778747,
    "grid_to_demand": 32808.44857,
    "govt_battery_charge": 61.01672938
  },
  {
    "timestamp": "25/08/24 18:00",
    "demand": 36440.80651,
    "solar_generated": 0,
    "wind_generated": 65.09902131,
    "marketplace_to_demand": 154.8536775,
    "grid_to_demand": 30894.79053,
    "govt_battery_charge": 75.55910882
  },
  {
    "timestamp": "25/08/24 19:00",
    "demand": 35263.19503,
    "solar_generated": 0,
    "wind_generated": 70.06297488,
    "marketplace_to_demand": 60.53587029,
    "grid_to_demand": 33057.20552,
    "govt_battery_charge": 25.7295855
  },
  {
    "timestamp": "25/08/24 20:00",
    "demand": 34857.6897,
    "solar_generated": 0,
    "wind_generated": 72.65796736,
    "marketplace_to_demand": 56.65669772,
    "grid_to_demand": 33123.83885,
    "govt_battery_charge": 17.83464365
  },
  {
    "timestamp": "25/08/24 21:00",
    "demand": 30736.3572,
    "solar_generated": 0,
    "wind_generated": 59.83081336,
    "marketplace_to_demand": 133.4253514,
    "grid_to_demand": 34423.37921,
    "govt_battery_charge": 83.45852628
  },
  {
    "timestamp": "25/08/24 22:00",
    "demand": 32654.72489,
    "solar_generated": 0,
    "wind_generated": 70.24836793,
    "marketplace_to_demand": 137.7616532,
    "grid_to_demand": 31628.68481,
    "govt_battery_charge": 81.15348129
  },
  {
    "timestamp": "25/08/24 23:00",
    "demand": 36206.98213,
    "solar_generated": 0,
    "wind_generated": 79.85486583,
    "marketplace_to_demand": 149.0658374,
    "grid_to_demand": 33062.39775,
    "govt_battery_charge": 55.02685791
  },
  {
    "timestamp": "26/08/24 0:00",
    "demand": 31641.09711,
    "solar_generated": 0,
    "wind_generated": 75.31019106,
    "marketplace_to_demand": 6.318830327,
    "grid_to_demand": 32166.63369,
    "govt_battery_charge": 61.25770199
  },
  {
    "timestamp": "26/08/24 1:00",
    "demand": 36530.35577,
    "solar_generated": 0,
    "wind_generated": 71.89191145,
    "marketplace_to_demand": 152.0462316,
    "grid_to_demand": 33025.08276,
    "govt_battery_charge": 3.71615268
  },
  {
    "timestamp": "26/08/24 2:00",
    "demand": 35432.33519,
    "solar_generated": 0,
    "wind_generated": 62.31830035,
    "marketplace_to_demand": 28.33909621,
    "grid_to_demand": 30366.84505,
    "govt_battery_charge": 38.28026846
  },
  {
    "timestamp": "26/08/24 3:00",
    "demand": 30959.77847,
    "solar_generated": 0,
    "wind_generated": 62.5900116,
    "marketplace_to_demand": 37.19486169,
    "grid_to_demand": 35181.5589,
    "govt_battery_charge": 68.51277918
  },
  {
    "timestamp": "26/08/24 4:00",
    "demand": 33382.4612,
    "solar_generated": 0,
    "wind_generated": 75.51138712,
    "marketplace_to_demand": 74.03107627,
    "grid_to_demand": 30516.75773,
    "govt_battery_charge": 25.22257951
  },
  {
    "timestamp": "26/08/24 5:00",
    "demand": 33787.19166,
    "solar_generated": 0,
    "wind_generated": 69.69061297,
    "marketplace_to_demand": 114.4901533,
    "grid_to_demand": 30292.67424,
    "govt_battery_charge": 46.11338655
  },
  {
    "timestamp": "26/08/24 6:00",
    "demand": 33458.58609,
    "solar_generated": 20.94804224,
    "wind_generated": 77.25511287,
    "marketplace_to_demand": 88.64620404,
    "grid_to_demand": 33372.36273,
    "govt_battery_charge": 71.26142078
  },
  {
    "timestamp": "26/08/24 7:00",
    "demand": 33827.59233,
    "solar_generated": 18.0736589,
    "wind_generated": 65.04038431,
    "marketplace_to_demand": 72.49484617,
    "grid_to_demand": 30452.68623,
    "govt_battery_charge": 60.73992782
  },
  {
    "timestamp": "26/08/24 8:00",
    "demand": 31097.02898,
    "solar_generated": 12.77269929,
    "wind_generated": 65.94719794,
    "marketplace_to_demand": 76.01822534,
    "grid_to_demand": 31111.14495,
    "govt_battery_charge": 85.30612349
  },
  {
    "timestamp": "26/08/24 9:00",
    "demand": 32998.00263,
    "solar_generated": 10.54626539,
    "wind_generated": 70.49970585,
    "marketplace_to_demand": 97.0878227,
    "grid_to_demand": 33633.48795,
    "govt_battery_charge": 18.86300855
  },
  {
    "timestamp": "26/08/24 10:00",
    "demand": 36074.71095,
    "solar_generated": 9.400589823,
    "wind_generated": 66.62708938,
    "marketplace_to_demand": 66.79950848,
    "grid_to_demand": 33707.26995,
    "govt_battery_charge": 95.25933743
  },
  {
    "timestamp": "26/08/24 11:00",
    "demand": 33823.45154,
    "solar_generated": 16.5897555,
    "wind_generated": 60.31001815,
    "marketplace_to_demand": 27.94285859,
    "grid_to_demand": 33410.74531,
    "govt_battery_charge": 27.08472087
  },
  {
    "timestamp": "26/08/24 12:00",
    "demand": 35337.76635,
    "solar_generated": 10.53874389,
    "wind_generated": 68.14704594,
    "marketplace_to_demand": 174.9351069,
    "grid_to_demand": 35679.48534,
    "govt_battery_charge": 99.12064764
  },
  {
    "timestamp": "26/08/24 13:00",
    "demand": 34626.73805,
    "solar_generated": 16.73065811,
    "wind_generated": 69.94052287,
    "marketplace_to_demand": 78.37839925,
    "grid_to_demand": 36277.50612,
    "govt_battery_charge": 38.46843311
  },
  {
    "timestamp": "26/08/24 14:00",
    "demand": 35628.31014,
    "solar_generated": 19.4293213,
    "wind_generated": 62.89726544,
    "marketplace_to_demand": 41.49824302,
    "grid_to_demand": 35472.64837,
    "govt_battery_charge": 93.21453853
  },
  {
    "timestamp": "26/08/24 15:00",
    "demand": 35880.63552,
    "solar_generated": 22.34308567,
    "wind_generated": 76.52591321,
    "marketplace_to_demand": 61.36778248,
    "grid_to_demand": 34278.11713,
    "govt_battery_charge": 92.27901901
  },
  {
    "timestamp": "26/08/24 16:00",
    "demand": 31295.77356,
    "solar_generated": 23.21986703,
    "wind_generated": 70.37020654,
    "marketplace_to_demand": 119.5470988,
    "grid_to_demand": 34512.76538,
    "govt_battery_charge": 3.645191046
  },
  {
    "timestamp": "26/08/24 17:00",
    "demand": 33358.71992,
    "solar_generated": 8.26476626,
    "wind_generated": 60.62058685,
    "marketplace_to_demand": 145.5747692,
    "grid_to_demand": 33368.6684,
    "govt_battery_charge": 99.99614973
  },
  {
    "timestamp": "26/08/24 18:00",
    "demand": 34254.73956,
    "solar_generated": 0,
    "wind_generated": 71.49352429,
    "marketplace_to_demand": 131.7559109,
    "grid_to_demand": 30040.86802,
    "govt_battery_charge": 88.21224168
  },
  {
    "timestamp": "26/08/24 19:00",
    "demand": 33348.39453,
    "solar_generated": 0,
    "wind_generated": 76.57190133,
    "marketplace_to_demand": 184.9355482,
    "grid_to_demand": 33281.63597,
    "govt_battery_charge": 48.55711016
  },
  {
    "timestamp": "26/08/24 20:00",
    "demand": 30807.32063,
    "solar_generated": 0,
    "wind_generated": 60.85786425,
    "marketplace_to_demand": 58.03725838,
    "grid_to_demand": 33735.01809,
    "govt_battery_charge": 63.6209756
  },
  {
    "timestamp": "26/08/24 21:00",
    "demand": 34687.05429,
    "solar_generated": 0,
    "wind_generated": 63.06971347,
    "marketplace_to_demand": 82.79195558,
    "grid_to_demand": 30768.64495,
    "govt_battery_charge": 51.05403048
  },
  {
    "timestamp": "26/08/24 22:00",
    "demand": 35383.77059,
    "solar_generated": 0,
    "wind_generated": 72.8032899,
    "marketplace_to_demand": 76.30430357,
    "grid_to_demand": 34029.87755,
    "govt_battery_charge": 12.13677249
  },
  {
    "timestamp": "26/08/24 23:00",
    "demand": 30060.94014,
    "solar_generated": 0,
    "wind_generated": 55.47501227,
    "marketplace_to_demand": 99.757782,
    "grid_to_demand": 35129.88347,
    "govt_battery_charge": 87.28351898
  },
  {
    "timestamp": "27/08/24 0:00",
    "demand": 34855.26842,
    "solar_generated": 0,
    "wind_generated": 79.92777347,
    "marketplace_to_demand": 195.3729141,
    "grid_to_demand": 36497.59074,
    "govt_battery_charge": 73.87681825
  },
  {
    "timestamp": "27/08/24 1:00",
    "demand": 32122.13826,
    "solar_generated": 0,
    "wind_generated": 66.77287862,
    "marketplace_to_demand": 191.2160254,
    "grid_to_demand": 30961.8483,
    "govt_battery_charge": 39.0064332
  },
  {
    "timestamp": "27/08/24 2:00",
    "demand": 36218.58653,
    "solar_generated": 0,
    "wind_generated": 70.5608317,
    "marketplace_to_demand": 199.2806612,
    "grid_to_demand": 30351.19225,
    "govt_battery_charge": 29.37678358
  },
  {
    "timestamp": "27/08/24 3:00",
    "demand": 31857.35603,
    "solar_generated": 0,
    "wind_generated": 59.38645828,
    "marketplace_to_demand": 89.67222758,
    "grid_to_demand": 30973.94792,
    "govt_battery_charge": 65.61348324
  },
  {
    "timestamp": "27/08/24 4:00",
    "demand": 35539.27729,
    "solar_generated": 0,
    "wind_generated": 66.7512294,
    "marketplace_to_demand": 33.21085057,
    "grid_to_demand": 32333.59882,
    "govt_battery_charge": 73.32528122
  },
  {
    "timestamp": "27/08/24 5:00",
    "demand": 35693.5982,
    "solar_generated": 0,
    "wind_generated": 62.54345464,
    "marketplace_to_demand": 149.8469492,
    "grid_to_demand": 33991.29313,
    "govt_battery_charge": 43.46548443
  },
  {
    "timestamp": "27/08/24 6:00",
    "demand": 36414.9248,
    "solar_generated": 21.34031089,
    "wind_generated": 77.98752071,
    "marketplace_to_demand": 128.9300376,
    "grid_to_demand": 35261.2214,
    "govt_battery_charge": 99.03541499
  },
  {
    "timestamp": "27/08/24 7:00",
    "demand": 33617.20716,
    "solar_generated": 7.151428772,
    "wind_generated": 72.64883558,
    "marketplace_to_demand": 111.8152902,
    "grid_to_demand": 36254.41146,
    "govt_battery_charge": 34.84316185
  },
  {
    "timestamp": "27/08/24 8:00",
    "demand": 29986.9454,
    "solar_generated": 19.07634967,
    "wind_generated": 79.37956137,
    "marketplace_to_demand": 83.9023025,
    "grid_to_demand": 30127.19533,
    "govt_battery_charge": 36.44192801
  },
  {
    "timestamp": "27/08/24 9:00",
    "demand": 34177.43019,
    "solar_generated": 17.99626706,
    "wind_generated": 61.80839183,
    "marketplace_to_demand": 172.7122642,
    "grid_to_demand": 32545.9065,
    "govt_battery_charge": 22.72419221
  },
  {
    "timestamp": "27/08/24 10:00",
    "demand": 32538.60469,
    "solar_generated": 18.52288737,
    "wind_generated": 59.06526235,
    "marketplace_to_demand": 7.193861356,
    "grid_to_demand": 30646.23679,
    "govt_battery_charge": 20.5386401
  },
  {
    "timestamp": "27/08/24 11:00",
    "demand": 32571.87542,
    "solar_generated": 11.12724376,
    "wind_generated": 78.74253552,
    "marketplace_to_demand": 48.80833139,
    "grid_to_demand": 32437.07991,
    "govt_battery_charge": 85.95676483
  },
  {
    "timestamp": "27/08/24 12:00",
    "demand": 36329.96497,
    "solar_generated": 15.58488371,
    "wind_generated": 63.64408419,
    "marketplace_to_demand": 143.0568024,
    "grid_to_demand": 30465.09693,
    "govt_battery_charge": 78.68037586
  },
  {
    "timestamp": "27/08/24 13:00",
    "demand": 30204.38022,
    "solar_generated": 22.45160153,
    "wind_generated": 58.43796853,
    "marketplace_to_demand": 26.36929966,
    "grid_to_demand": 31240.87066,
    "govt_battery_charge": 87.77836703
  },
  {
    "timestamp": "27/08/24 14:00",
    "demand": 33995.62694,
    "solar_generated": 10.99004014,
    "wind_generated": 61.67053003,
    "marketplace_to_demand": 184.9974099,
    "grid_to_demand": 35988.29932,
    "govt_battery_charge": 27.29800398
  },
  {
    "timestamp": "27/08/24 15:00",
    "demand": 31262.29468,
    "solar_generated": 9.040681002,
    "wind_generated": 54.80163169,
    "marketplace_to_demand": 72.47750296,
    "grid_to_demand": 33781.24179,
    "govt_battery_charge": 34.38058383
  },
  {
    "timestamp": "27/08/24 16:00",
    "demand": 30174.81728,
    "solar_generated": 17.1248099,
    "wind_generated": 60.71824294,
    "marketplace_to_demand": 159.804099,
    "grid_to_demand": 34057.94559,
    "govt_battery_charge": 92.26068906
  },
  {
    "timestamp": "27/08/24 17:00",
    "demand": 34248.09227,
    "solar_generated": 13.51538977,
    "wind_generated": 78.63969535,
    "marketplace_to_demand": 91.19098594,
    "grid_to_demand": 36045.78412,
    "govt_battery_charge": 57.9401523
  },
  {
    "timestamp": "27/08/24 18:00",
    "demand": 31359.29042,
    "solar_generated": 0,
    "wind_generated": 57.08720014,
    "marketplace_to_demand": 112.887064,
    "grid_to_demand": 30739.00323,
    "govt_battery_charge": 8.112911264
  },
  {
    "timestamp": "27/08/24 19:00",
    "demand": 31579.46847,
    "solar_generated": 0,
    "wind_generated": 78.66254579,
    "marketplace_to_demand": 51.69805149,
    "grid_to_demand": 34699.10428,
    "govt_battery_charge": 46.74670213
  },
  {
    "timestamp": "27/08/24 20:00",
    "demand": 32751.43015,
    "solar_generated": 0,
    "wind_generated": 72.92242826,
    "marketplace_to_demand": 130.6305822,
    "grid_to_demand": 30336.77248,
    "govt_battery_charge": 51.10629087
  },
  {
    "timestamp": "27/08/24 21:00",
    "demand": 30243.71076,
    "solar_generated": 0,
    "wind_generated": 78.35821063,
    "marketplace_to_demand": 127.1481096,
    "grid_to_demand": 34824.67778,
    "govt_battery_charge": 30.2428627
  },
  {
    "timestamp": "27/08/24 22:00",
    "demand": 35471.46809,
    "solar_generated": 0,
    "wind_generated": 75.007419,
    "marketplace_to_demand": 186.3629859,
    "grid_to_demand": 32770.88006,
    "govt_battery_charge": 70.46261382
  },
  {
    "timestamp": "27/08/24 23:00",
    "demand": 31076.82192,
    "solar_generated": 0,
    "wind_generated": 63.19309763,
    "marketplace_to_demand": 139.6698378,
    "grid_to_demand": 31850.57893,
    "govt_battery_charge": 44.56687163
  },
  {
    "timestamp": "28/08/24 0:00",
    "demand": 30558.95692,
    "solar_generated": 0,
    "wind_generated": 75.16175544,
    "marketplace_to_demand": 170.4237389,
    "grid_to_demand": 31156.32007,
    "govt_battery_charge": 48.92609606
  },
  {
    "timestamp": "28/08/24 1:00",
    "demand": 30787.30623,
    "solar_generated": 0,
    "wind_generated": 62.99755598,
    "marketplace_to_demand": 158.3567563,
    "grid_to_demand": 36175.89648,
    "govt_battery_charge": 43.73275189
  },
  {
    "timestamp": "28/08/24 2:00",
    "demand": 34311.76273,
    "solar_generated": 0,
    "wind_generated": 60.58449823,
    "marketplace_to_demand": 31.35413526,
    "grid_to_demand": 32424.88083,
    "govt_battery_charge": 29.93445506
  },
  {
    "timestamp": "28/08/24 3:00",
    "demand": 33381.64621,
    "solar_generated": 0,
    "wind_generated": 67.25660631,
    "marketplace_to_demand": 62.2790313,
    "grid_to_demand": 30706.82019,
    "govt_battery_charge": 65.71312909
  },
  {
    "timestamp": "28/08/24 4:00",
    "demand": 30246.32084,
    "solar_generated": 0,
    "wind_generated": 68.67418672,
    "marketplace_to_demand": 39.68812413,
    "grid_to_demand": 31347.46263,
    "govt_battery_charge": 40.74583475
  },
  {
    "timestamp": "28/08/24 5:00",
    "demand": 32127.1577,
    "solar_generated": 0,
    "wind_generated": 74.10535288,
    "marketplace_to_demand": 64.42093534,
    "grid_to_demand": 30190.60159,
    "govt_battery_charge": 37.98809851
  },
  {
    "timestamp": "28/08/24 6:00",
    "demand": 34236.16035,
    "solar_generated": 7.568610864,
    "wind_generated": 54.14188075,
    "marketplace_to_demand": 16.79249909,
    "grid_to_demand": 31500.62434,
    "govt_battery_charge": 39.14953338
  },
  {
    "timestamp": "28/08/24 7:00",
    "demand": 31966.23016,
    "solar_generated": 7.973689034,
    "wind_generated": 55.6353846,
    "marketplace_to_demand": 49.1094168,
    "grid_to_demand": 31316.07682,
    "govt_battery_charge": 51.26084881
  },
  {
    "timestamp": "28/08/24 8:00",
    "demand": 31621.03559,
    "solar_generated": 21.08267363,
    "wind_generated": 70.98405615,
    "marketplace_to_demand": 37.32127547,
    "grid_to_demand": 36380.54145,
    "govt_battery_charge": 73.08468773
  },
  {
    "timestamp": "28/08/24 9:00",
    "demand": 30959.43924,
    "solar_generated": 12.804211,
    "wind_generated": 67.51817294,
    "marketplace_to_demand": 119.1803417,
    "grid_to_demand": 33824.89134,
    "govt_battery_charge": 2.790508484
  },
  {
    "timestamp": "28/08/24 10:00",
    "demand": 30927.92462,
    "solar_generated": 19.99345832,
    "wind_generated": 76.74733844,
    "marketplace_to_demand": 134.7125485,
    "grid_to_demand": 34470.54104,
    "govt_battery_charge": 50.71212898
  },
  {
    "timestamp": "28/08/24 11:00",
    "demand": 33778.39899,
    "solar_generated": 8.777800269,
    "wind_generated": 54.14434493,
    "marketplace_to_demand": 18.04452096,
    "grid_to_demand": 30476.87837,
    "govt_battery_charge": 71.16934181
  },
  {
    "timestamp": "28/08/24 12:00",
    "demand": 33142.25027,
    "solar_generated": 7.355656132,
    "wind_generated": 70.64656051,
    "marketplace_to_demand": 166.060756,
    "grid_to_demand": 34587.80086,
    "govt_battery_charge": 45.59752635
  },
  {
    "timestamp": "28/08/24 13:00",
    "demand": 35629.66344,
    "solar_generated": 9.676550117,
    "wind_generated": 63.09680385,
    "marketplace_to_demand": 19.50298215,
    "grid_to_demand": 34619.76395,
    "govt_battery_charge": 59.45110999
  },
  {
    "timestamp": "28/08/24 14:00",
    "demand": 33640.53411,
    "solar_generated": 9.838381479,
    "wind_generated": 53.6128483,
    "marketplace_to_demand": 48.43042947,
    "grid_to_demand": 32385.84175,
    "govt_battery_charge": 77.02638491
  },
  {
    "timestamp": "28/08/24 15:00",
    "demand": 34776.77001,
    "solar_generated": 13.03890984,
    "wind_generated": 77.92880821,
    "marketplace_to_demand": 60.88386968,
    "grid_to_demand": 32649.14071,
    "govt_battery_charge": 54.65444449
  },
  {
    "timestamp": "28/08/24 16:00",
    "demand": 32605.08262,
    "solar_generated": 20.69531905,
    "wind_generated": 65.87221851,
    "marketplace_to_demand": 105.3230101,
    "grid_to_demand": 34203.29594,
    "govt_battery_charge": 35.87971266
  },
  {
    "timestamp": "28/08/24 17:00",
    "demand": 34554.17705,
    "solar_generated": 23.87001899,
    "wind_generated": 68.83522497,
    "marketplace_to_demand": 157.8792136,
    "grid_to_demand": 34766.3189,
    "govt_battery_charge": 38.16798195
  },
  {
    "timestamp": "28/08/24 18:00",
    "demand": 32933.93316,
    "solar_generated": 0,
    "wind_generated": 75.07878014,
    "marketplace_to_demand": 150.1275978,
    "grid_to_demand": 32510.19187,
    "govt_battery_charge": 12.55988257
  },
  {
    "timestamp": "28/08/24 19:00",
    "demand": 30837.68054,
    "solar_generated": 0,
    "wind_generated": 71.79460915,
    "marketplace_to_demand": 188.0196978,
    "grid_to_demand": 30309.68178,
    "govt_battery_charge": 23.29582257
  },
  {
    "timestamp": "28/08/24 20:00",
    "demand": 35381.42872,
    "solar_generated": 0,
    "wind_generated": 73.92073339,
    "marketplace_to_demand": 85.13802272,
    "grid_to_demand": 32527.36642,
    "govt_battery_charge": 12.23953389
  },
  {
    "timestamp": "28/08/24 21:00",
    "demand": 30684.35866,
    "solar_generated": 0,
    "wind_generated": 76.62200198,
    "marketplace_to_demand": 45.75430741,
    "grid_to_demand": 35650.86967,
    "govt_battery_charge": 6.171888806
  },
  {
    "timestamp": "28/08/24 22:00",
    "demand": 35604.09318,
    "solar_generated": 0,
    "wind_generated": 71.46212764,
    "marketplace_to_demand": 190.2003097,
    "grid_to_demand": 33806.36625,
    "govt_battery_charge": 91.93902175
  },
  {
    "timestamp": "28/08/24 23:00",
    "demand": 33685.3876,
    "solar_generated": 0,
    "wind_generated": 62.92617487,
    "marketplace_to_demand": 152.0427963,
    "grid_to_demand": 35913.39507,
    "govt_battery_charge": 19.06374189
  },
  {
    "timestamp": "29/08/24 0:00",
    "demand": 33903.12125,
    "solar_generated": 0,
    "wind_generated": 56.65778969,
    "marketplace_to_demand": 185.9336403,
    "grid_to_demand": 31095.50784,
    "govt_battery_charge": 66.65633558
  },
  {
    "timestamp": "29/08/24 1:00",
    "demand": 30273.1597,
    "solar_generated": 0,
    "wind_generated": 61.99442369,
    "marketplace_to_demand": 2.978577347,
    "grid_to_demand": 31466.76834,
    "govt_battery_charge": 46.72962859
  },
  {
    "timestamp": "29/08/24 2:00",
    "demand": 32601.05979,
    "solar_generated": 0,
    "wind_generated": 68.39572099,
    "marketplace_to_demand": 147.0145688,
    "grid_to_demand": 33265.18602,
    "govt_battery_charge": 27.19204704
  },
  {
    "timestamp": "29/08/24 3:00",
    "demand": 31954.55993,
    "solar_generated": 0,
    "wind_generated": 55.8447581,
    "marketplace_to_demand": 27.79376031,
    "grid_to_demand": 32920.23224,
    "govt_battery_charge": 81.45085223
  },
  {
    "timestamp": "29/08/24 4:00",
    "demand": 30181.74066,
    "solar_generated": 0,
    "wind_generated": 55.08613072,
    "marketplace_to_demand": 5.035339298,
    "grid_to_demand": 29909.66349,
    "govt_battery_charge": 57.60631264
  },
  {
    "timestamp": "29/08/24 5:00",
    "demand": 29991.65772,
    "solar_generated": 0,
    "wind_generated": 76.0144909,
    "marketplace_to_demand": 170.6998663,
    "grid_to_demand": 35831.32509,
    "govt_battery_charge": 90.87042421
  },
  {
    "timestamp": "29/08/24 6:00",
    "demand": 35695.82389,
    "solar_generated": 18.13352926,
    "wind_generated": 58.83768206,
    "marketplace_to_demand": 105.1618229,
    "grid_to_demand": 35744.39424,
    "govt_battery_charge": 41.83985947
  },
  {
    "timestamp": "29/08/24 7:00",
    "demand": 35466.17161,
    "solar_generated": 20.68057513,
    "wind_generated": 59.97319959,
    "marketplace_to_demand": 21.02313678,
    "grid_to_demand": 32101.99134,
    "govt_battery_charge": 58.34074765
  },
  {
    "timestamp": "29/08/24 8:00",
    "demand": 32253.30876,
    "solar_generated": 15.58420408,
    "wind_generated": 57.34396515,
    "marketplace_to_demand": 103.4731437,
    "grid_to_demand": 35773.25373,
    "govt_battery_charge": 85.72827412
  },
  {
    "timestamp": "29/08/24 9:00",
    "demand": 31419.52337,
    "solar_generated": 15.19946564,
    "wind_generated": 76.17167207,
    "marketplace_to_demand": 197.0851916,
    "grid_to_demand": 35757.21622,
    "govt_battery_charge": 79.69797637
  },
  {
    "timestamp": "29/08/24 10:00",
    "demand": 33719.10849,
    "solar_generated": 20.16145626,
    "wind_generated": 67.15454475,
    "marketplace_to_demand": 195.9196513,
    "grid_to_demand": 31854.03188,
    "govt_battery_charge": 84.07227366
  },
  {
    "timestamp": "29/08/24 11:00",
    "demand": 36533.30365,
    "solar_generated": 15.07825916,
    "wind_generated": 77.17725798,
    "marketplace_to_demand": 143.1509038,
    "grid_to_demand": 35327.94243,
    "govt_battery_charge": 8.762402277
  },
  {
    "timestamp": "29/08/24 12:00",
    "demand": 31469.60093,
    "solar_generated": 9.222850693,
    "wind_generated": 74.63813057,
    "marketplace_to_demand": 6.423958402,
    "grid_to_demand": 32414.04162,
    "govt_battery_charge": 90.44597818
  },
  {
    "timestamp": "29/08/24 13:00",
    "demand": 32706.68561,
    "solar_generated": 7.841257379,
    "wind_generated": 58.42005795,
    "marketplace_to_demand": 74.34000339,
    "grid_to_demand": 36223.16772,
    "govt_battery_charge": 36.91668979
  },
  {
    "timestamp": "29/08/24 14:00",
    "demand": 33946.18731,
    "solar_generated": 6.288548273,
    "wind_generated": 78.57612299,
    "marketplace_to_demand": 124.8544546,
    "grid_to_demand": 31870.31857,
    "govt_battery_charge": 85.82162617
  },
  {
    "timestamp": "29/08/24 15:00",
    "demand": 30687.78588,
    "solar_generated": 12.20075959,
    "wind_generated": 61.63721172,
    "marketplace_to_demand": 56.28721174,
    "grid_to_demand": 32467.98547,
    "govt_battery_charge": 52.36592107
  },
  {
    "timestamp": "29/08/24 16:00",
    "demand": 31708.04689,
    "solar_generated": 12.36954303,
    "wind_generated": 66.78241234,
    "marketplace_to_demand": 8.761426912,
    "grid_to_demand": 35206.1889,
    "govt_battery_charge": 65.86673251
  },
  {
    "timestamp": "29/08/24 17:00",
    "demand": 34859.7588,
    "solar_generated": 22.02493491,
    "wind_generated": 58.65841325,
    "marketplace_to_demand": 55.7898564,
    "grid_to_demand": 31479.73955,
    "govt_battery_charge": 84.63126298
  },
  {
    "timestamp": "29/08/24 18:00",
    "demand": 36375.05607,
    "solar_generated": 0,
    "wind_generated": 76.4565125,
    "marketplace_to_demand": 67.87404633,
    "grid_to_demand": 33181.88797,
    "govt_battery_charge": 37.85034799
  },
  {
    "timestamp": "29/08/24 19:00",
    "demand": 34551.33218,
    "solar_generated": 0,
    "wind_generated": 59.72478092,
    "marketplace_to_demand": 197.887417,
    "grid_to_demand": 31809.19065,
    "govt_battery_charge": 92.74885778
  },
  {
    "timestamp": "29/08/24 20:00",
    "demand": 30981.0349,
    "solar_generated": 0,
    "wind_generated": 80.01708968,
    "marketplace_to_demand": 59.26044016,
    "grid_to_demand": 35225.39518,
    "govt_battery_charge": 10.26226722
  },
  {
    "timestamp": "29/08/24 21:00",
    "demand": 33960.14244,
    "solar_generated": 0,
    "wind_generated": 60.65528815,
    "marketplace_to_demand": 75.64795146,
    "grid_to_demand": 31823.76746,
    "govt_battery_charge": 24.02151291
  },
  {
    "timestamp": "29/08/24 22:00",
    "demand": 34660.60866,
    "solar_generated": 0,
    "wind_generated": 67.13140106,
    "marketplace_to_demand": 1.442476057,
    "grid_to_demand": 34861.20604,
    "govt_battery_charge": 38.40998643
  },
  {
    "timestamp": "29/08/24 23:00",
    "demand": 34585.58755,
    "solar_generated": 0,
    "wind_generated": 67.54390245,
    "marketplace_to_demand": 166.2810095,
    "grid_to_demand": 36336.46807,
    "govt_battery_charge": 48.41300888
  },
  {
    "timestamp": "30/08/24 0:00",
    "demand": 32726.06085,
    "solar_generated": 0,
    "wind_generated": 59.5560983,
    "marketplace_to_demand": 115.4964971,
    "grid_to_demand": 32035.5896,
    "govt_battery_charge": 77.33542303
  },
  {
    "timestamp": "30/08/24 1:00",
    "demand": 34022.13692,
    "solar_generated": 0,
    "wind_generated": 66.89275947,
    "marketplace_to_demand": 97.35403857,
    "grid_to_demand": 33629.85749,
    "govt_battery_charge": 50.84713818
  },
  {
    "timestamp": "30/08/24 2:00",
    "demand": 31071.46418,
    "solar_generated": 0,
    "wind_generated": 72.11416855,
    "marketplace_to_demand": 154.3229404,
    "grid_to_demand": 35061.88498,
    "govt_battery_charge": 20.7346022
  },
  {
    "timestamp": "30/08/24 3:00",
    "demand": 34118.80194,
    "solar_generated": 0,
    "wind_generated": 72.91706476,
    "marketplace_to_demand": 188.9726437,
    "grid_to_demand": 35195.66572,
    "govt_battery_charge": 51.15983072
  },
  {
    "timestamp": "30/08/24 4:00",
    "demand": 30133.8587,
    "solar_generated": 0,
    "wind_generated": 62.19909582,
    "marketplace_to_demand": 107.0630731,
    "grid_to_demand": 36329.09574,
    "govt_battery_charge": 82.46341557
  },
  {
    "timestamp": "30/08/24 5:00",
    "demand": 33438.2756,
    "solar_generated": 0,
    "wind_generated": 60.25809356,
    "marketplace_to_demand": 42.60846299,
    "grid_to_demand": 30006.57427,
    "govt_battery_charge": 7.514464845
  },
  {
    "timestamp": "30/08/24 6:00",
    "demand": 35306.3301,
    "solar_generated": 9.415291938,
    "wind_generated": 54.97510256,
    "marketplace_to_demand": 176.1781722,
    "grid_to_demand": 36193.59183,
    "govt_battery_charge": 17.13047158
  },
  {
    "timestamp": "30/08/24 7:00",
    "demand": 36382.64422,
    "solar_generated": 8.751950699,
    "wind_generated": 78.47553581,
    "marketplace_to_demand": 35.33871644,
    "grid_to_demand": 30004.90044,
    "govt_battery_charge": 41.3311499
  },
  {
    "timestamp": "30/08/24 8:00",
    "demand": 32410.78624,
    "solar_generated": 22.0163989,
    "wind_generated": 74.49667931,
    "marketplace_to_demand": 184.0795398,
    "grid_to_demand": 31264.24211,
    "govt_battery_charge": 30.3861335
  },
  {
    "timestamp": "30/08/24 9:00",
    "demand": 36572.92534,
    "solar_generated": 21.65956902,
    "wind_generated": 74.08404156,
    "marketplace_to_demand": 178.5806483,
    "grid_to_demand": 30503.53593,
    "govt_battery_charge": 77.19013704
  },
  {
    "timestamp": "30/08/24 10:00",
    "demand": 33557.36606,
    "solar_generated": 22.12561286,
    "wind_generated": 61.58614386,
    "marketplace_to_demand": 161.2742111,
    "grid_to_demand": 35016.02973,
    "govt_battery_charge": 71.78278693
  },
  {
    "timestamp": "30/08/24 11:00",
    "demand": 30873.05203,
    "solar_generated": 15.44363744,
    "wind_generated": 80.18593862,
    "marketplace_to_demand": 159.5587064,
    "grid_to_demand": 34389.7864,
    "govt_battery_charge": 93.89544908
  },
  {
    "timestamp": "30/08/24 12:00",
    "demand": 34230.15139,
    "solar_generated": 19.79241595,
    "wind_generated": 53.79224481,
    "marketplace_to_demand": 198.8627797,
    "grid_to_demand": 31026.6278,
    "govt_battery_charge": 44.4896332
  },
  {
    "timestamp": "30/08/24 13:00",
    "demand": 33246.952,
    "solar_generated": 22.23608702,
    "wind_generated": 75.8708225,
    "marketplace_to_demand": 3.389884036,
    "grid_to_demand": 33428.99833,
    "govt_battery_charge": 73.03081163
  },
  {
    "timestamp": "30/08/24 14:00",
    "demand": 34783.06302,
    "solar_generated": 19.31482242,
    "wind_generated": 67.13591804,
    "marketplace_to_demand": 110.7830952,
    "grid_to_demand": 33552.20675,
    "govt_battery_charge": 80.05774351
  },
  {
    "timestamp": "30/08/24 15:00",
    "demand": 35660.58091,
    "solar_generated": 15.23675704,
    "wind_generated": 63.77901362,
    "marketplace_to_demand": 53.8297949,
    "grid_to_demand": 31681.05857,
    "govt_battery_charge": 55.75994271
  },
  {
    "timestamp": "30/08/24 16:00",
    "demand": 31182.54604,
    "solar_generated": 22.65099909,
    "wind_generated": 59.77047024,
    "marketplace_to_demand": 59.40468932,
    "grid_to_demand": 34976.37871,
    "govt_battery_charge": 78.65970348
  },
  {
    "timestamp": "30/08/24 17:00",
    "demand": 32734.08636,
    "solar_generated": 7.73813402,
    "wind_generated": 62.20544422,
    "marketplace_to_demand": 103.6632494,
    "grid_to_demand": 30533.33413,
    "govt_battery_charge": 85.516693
  },
  {
    "timestamp": "30/08/24 18:00",
    "demand": 34959.44842,
    "solar_generated": 0,
    "wind_generated": 70.19022322,
    "marketplace_to_demand": 53.44853897,
    "grid_to_demand": 34870.98293,
    "govt_battery_charge": 12.45045422
  },
  {
    "timestamp": "30/08/24 19:00",
    "demand": 34374.71746,
    "solar_generated": 0,
    "wind_generated": 57.38929741,
    "marketplace_to_demand": 170.600316,
    "grid_to_demand": 30343.0567,
    "govt_battery_charge": 5.506831322
  },
  {
    "timestamp": "30/08/24 20:00",
    "demand": 30429.95049,
    "solar_generated": 0,
    "wind_generated": 75.37565249,
    "marketplace_to_demand": 199.8844781,
    "grid_to_demand": 35744.48478,
    "govt_battery_charge": 44.11877538
  },
  {
    "timestamp": "30/08/24 21:00",
    "demand": 32479.57379,
    "solar_generated": 0,
    "wind_generated": 65.59681767,
    "marketplace_to_demand": 161.8373677,
    "grid_to_demand": 32395.74404,
    "govt_battery_charge": 50.02463014
  },
  {
    "timestamp": "30/08/24 22:00",
    "demand": 32951.28616,
    "solar_generated": 0,
    "wind_generated": 73.07104782,
    "marketplace_to_demand": 38.06200958,
    "grid_to_demand": 33412.10961,
    "govt_battery_charge": 19.96859097
  },
  {
    "timestamp": "30/08/24 23:00",
    "demand": 36011.28634,
    "solar_generated": 0,
    "wind_generated": 64.87099583,
    "marketplace_to_demand": 167.7311698,
    "grid_to_demand": 34197.71046,
    "govt_battery_charge": 38.20976022
  },
  {
    "timestamp": "31/08/24 0:00",
    "demand": 32968.42869,
    "solar_generated": 0,
    "wind_generated": 76.95617845,
    "marketplace_to_demand": 27.34094731,
    "grid_to_demand": 34517.29048,
    "govt_battery_charge": 55.06024023
  },
  {
    "timestamp": "31/08/24 1:00",
    "demand": 33368.74259,
    "solar_generated": 0,
    "wind_generated": 76.58007445,
    "marketplace_to_demand": 130.6626654,
    "grid_to_demand": 32462.70843,
    "govt_battery_charge": 1.411914569
  },
  {
    "timestamp": "31/08/24 2:00",
    "demand": 32036.82674,
    "solar_generated": 0,
    "wind_generated": 77.77126535,
    "marketplace_to_demand": 19.92345674,
    "grid_to_demand": 36073.25585,
    "govt_battery_charge": 7.234320166
  },
  {
    "timestamp": "31/08/24 3:00",
    "demand": 33369.40738,
    "solar_generated": 0,
    "wind_generated": 74.30794525,
    "marketplace_to_demand": 139.8369495,
    "grid_to_demand": 36448.06982,
    "govt_battery_charge": 35.94212287
  },
  {
    "timestamp": "31/08/24 4:00",
    "demand": 31802.66661,
    "solar_generated": 0,
    "wind_generated": 62.88492741,
    "marketplace_to_demand": 192.1210125,
    "grid_to_demand": 35970.08584,
    "govt_battery_charge": 90.2744674
  },
  {
    "timestamp": "31/08/24 5:00",
    "demand": 35885.68418,
    "solar_generated": 0,
    "wind_generated": 72.2700609,
    "marketplace_to_demand": 174.1606156,
    "grid_to_demand": 33038.21478,
    "govt_battery_charge": 3.714752442
  },
  {
    "timestamp": "31/08/24 6:00",
    "demand": 36159.52107,
    "solar_generated": 9.500263615,
    "wind_generated": 79.58260622,
    "marketplace_to_demand": 119.6482774,
    "grid_to_demand": 30994.03376,
    "govt_battery_charge": 38.33406763
  },
  {
    "timestamp": "31/08/24 7:00",
    "demand": 30073.83988,
    "solar_generated": 14.38609281,
    "wind_generated": 73.41551597,
    "marketplace_to_demand": 90.83367191,
    "grid_to_demand": 32560.42089,
    "govt_battery_charge": 69.45041333
  },
  {
    "timestamp": "31/08/24 8:00",
    "demand": 33797.88425,
    "solar_generated": 12.91940674,
    "wind_generated": 78.479963,
    "marketplace_to_demand": 14.22632584,
    "grid_to_demand": 31478.36686,
    "govt_battery_charge": 6.675272063
  },
  {
    "timestamp": "31/08/24 9:00",
    "demand": 33855.83659,
    "solar_generated": 19.31240209,
    "wind_generated": 63.32819444,
    "marketplace_to_demand": 138.4367468,
    "grid_to_demand": 30306.51609,
    "govt_battery_charge": 22.39840118
  },
  {
    "timestamp": "31/08/24 10:00",
    "demand": 33970.27506,
    "solar_generated": 16.83717434,
    "wind_generated": 65.97706779,
    "marketplace_to_demand": 127.4427673,
    "grid_to_demand": 31501.37729,
    "govt_battery_charge": 85.32122125
  },
  {
    "timestamp": "31/08/24 11:00",
    "demand": 32195.25417,
    "solar_generated": 22.30020733,
    "wind_generated": 54.03409786,
    "marketplace_to_demand": 119.5331489,
    "grid_to_demand": 34389.73852,
    "govt_battery_charge": 65.94734375
  },
  {
    "timestamp": "31/08/24 12:00",
    "demand": 31732.63809,
    "solar_generated": 10.16895308,
    "wind_generated": 73.54924017,
    "marketplace_to_demand": 83.62079085,
    "grid_to_demand": 31680.00575,
    "govt_battery_charge": 30.65698156
  },
  {
    "timestamp": "31/08/24 13:00",
    "demand": 35795.72596,
    "solar_generated": 8.943008287,
    "wind_generated": 80.12370374,
    "marketplace_to_demand": 43.56061837,
    "grid_to_demand": 31027.0599,
    "govt_battery_charge": 31.94149313
  },
  {
    "timestamp": "31/08/24 14:00",
    "demand": 36493.32361,
    "solar_generated": 13.06111967,
    "wind_generated": 79.36063676,
    "marketplace_to_demand": 121.432219,
    "grid_to_demand": 32072.50351,
    "govt_battery_charge": 93.98921386
  },
  {
    "timestamp": "31/08/24 15:00",
    "demand": 29978.87752,
    "solar_generated": 16.85874843,
    "wind_generated": 64.30633263,
    "marketplace_to_demand": 30.91862081,
    "grid_to_demand": 32231.85291,
    "govt_battery_charge": 83.08897544
  },
  {
    "timestamp": "31/08/24 16:00",
    "demand": 31156.78713,
    "solar_generated": 8.198332114,
    "wind_generated": 58.96358695,
    "marketplace_to_demand": 44.17794028,
    "grid_to_demand": 33271.87602,
    "govt_battery_charge": 18.52357763
  },
  {
    "timestamp": "31/08/24 17:00",
    "demand": 36339.80946,
    "solar_generated": 23.95054555,
    "wind_generated": 61.64643411,
    "marketplace_to_demand": 22.67924277,
    "grid_to_demand": 36411.17602,
    "govt_battery_charge": 37.1660488
  },
  {
    "timestamp": "31/08/24 18:00",
    "demand": 32357.81944,
    "solar_generated": 0,
    "wind_generated": 55.76754643,
    "marketplace_to_demand": 146.8168616,
    "grid_to_demand": 32617.40203,
    "govt_battery_charge": 88.10364081
  },
  {
    "timestamp": "31/08/24 19:00",
    "demand": 30061.9552,
    "solar_generated": 0,
    "wind_generated": 67.60564721,
    "marketplace_to_demand": 53.61841247,
    "grid_to_demand": 32054.29918,
    "govt_battery_charge": 8.247307954
  },
  {
    "timestamp": "31/08/24 20:00",
    "demand": 36620.63439,
    "solar_generated": 0,
    "wind_generated": 71.64706552,
    "marketplace_to_demand": 49.38507611,
    "grid_to_demand": 35404.82308,
    "govt_battery_charge": 2.67669709
  },
  {
    "timestamp": "31/08/24 21:00",
    "demand": 36408.82116,
    "solar_generated": 0,
    "wind_generated": 72.28761544,
    "marketplace_to_demand": 176.7165636,
    "grid_to_demand": 32283.71915,
    "govt_battery_charge": 73.45650363
  },
  {
    "timestamp": "31/08/24 22:00",
    "demand": 31955.22516,
    "solar_generated": 0,
    "wind_generated": 56.10414348,
    "marketplace_to_demand": 108.7304839,
    "grid_to_demand": 36251.02505,
    "govt_battery_charge": 11.34345388
  },
  {
    "timestamp": "31/08/24 23:00",
    "demand": 34792.60927,
    "solar_generated": 0,
    "wind_generated": 61.54434958,
    "marketplace_to_demand": 39.66151729,
    "grid_to_demand": 36015.48786,
    "govt_battery_charge": 3.669714417
  },
  {
    "timestamp": "01/09/24 0:00",
    "demand": 35866.17562,
    "solar_generated": 0,
    "wind_generated": 65.1989871,
    "marketplace_to_demand": 37.91481981,
    "grid_to_demand": 33081.26095,
    "govt_battery_charge": 43.64650235
  },
  {
    "timestamp": "01/09/24 1:00",
    "demand": 32663.07326,
    "solar_generated": 0,
    "wind_generated": 58.77002978,
    "marketplace_to_demand": 190.7211527,
    "grid_to_demand": 36515.16897,
    "govt_battery_charge": 82.73235848
  },
  {
    "timestamp": "01/09/24 2:00",
    "demand": 35477.88538,
    "solar_generated": 0,
    "wind_generated": 67.64443713,
    "marketplace_to_demand": 152.3145886,
    "grid_to_demand": 33269.99572,
    "govt_battery_charge": 89.42952904
  },
  {
    "timestamp": "01/09/24 3:00",
    "demand": 32820.98852,
    "solar_generated": 0,
    "wind_generated": 68.88790323,
    "marketplace_to_demand": 124.553252,
    "grid_to_demand": 34306.65053,
    "govt_battery_charge": 16.28796729
  },
  {
    "timestamp": "01/09/24 4:00",
    "demand": 36203.81084,
    "solar_generated": 0,
    "wind_generated": 76.54438425,
    "marketplace_to_demand": 81.763482,
    "grid_to_demand": 32413.40219,
    "govt_battery_charge": 84.25777401
  },
  {
    "timestamp": "01/09/24 5:00",
    "demand": 31145.44316,
    "solar_generated": 0,
    "wind_generated": 68.79541919,
    "marketplace_to_demand": 90.22854772,
    "grid_to_demand": 31033.08783,
    "govt_battery_charge": 95.99714581
  },
  {
    "timestamp": "01/09/24 6:00",
    "demand": 31633.48195,
    "solar_generated": 9.734256463,
    "wind_generated": 53.73045877,
    "marketplace_to_demand": 81.69899575,
    "grid_to_demand": 36403.63807,
    "govt_battery_charge": 29.02645418
  },
  {
    "timestamp": "01/09/24 7:00",
    "demand": 34503.49903,
    "solar_generated": 22.79387754,
    "wind_generated": 79.91211695,
    "marketplace_to_demand": 197.8605674,
    "grid_to_demand": 36441.1942,
    "govt_battery_charge": 25.35047939
  },
  {
    "timestamp": "01/09/24 8:00",
    "demand": 36500.30671,
    "solar_generated": 11.58881299,
    "wind_generated": 69.3013874,
    "marketplace_to_demand": 185.4994357,
    "grid_to_demand": 34715.77987,
    "govt_battery_charge": 90.2763434
  },
  {
    "timestamp": "01/09/24 9:00",
    "demand": 31275.1171,
    "solar_generated": 15.33505088,
    "wind_generated": 66.21422058,
    "marketplace_to_demand": 123.5186004,
    "grid_to_demand": 35111.68142,
    "govt_battery_charge": 79.84327316
  },
  {
    "timestamp": "01/09/24 10:00",
    "demand": 34013.11978,
    "solar_generated": 22.43308553,
    "wind_generated": 78.60444252,
    "marketplace_to_demand": 78.50458883,
    "grid_to_demand": 31593.95518,
    "govt_battery_charge": 82.88676631
  },
  {
    "timestamp": "01/09/24 11:00",
    "demand": 34402.25359,
    "solar_generated": 7.366786803,
    "wind_generated": 69.81062086,
    "marketplace_to_demand": 22.88928318,
    "grid_to_demand": 35694.5366,
    "govt_battery_charge": 95.67208419
  },
  {
    "timestamp": "01/09/24 12:00",
    "demand": 33121.35674,
    "solar_generated": 10.43464081,
    "wind_generated": 72.0760955,
    "marketplace_to_demand": 195.6559435,
    "grid_to_demand": 35921.89131,
    "govt_battery_charge": 53.09679671
  },
  {
    "timestamp": "01/09/24 13:00",
    "demand": 32705.24905,
    "solar_generated": 13.0231321,
    "wind_generated": 60.27495572,
    "marketplace_to_demand": 182.8729148,
    "grid_to_demand": 34068.17808,
    "govt_battery_charge": 70.29979815
  },
  {
    "timestamp": "01/09/24 14:00",
    "demand": 33491.63018,
    "solar_generated": 23.98168636,
    "wind_generated": 77.67454547,
    "marketplace_to_demand": 197.6976812,
    "grid_to_demand": 34884.46455,
    "govt_battery_charge": 88.39743681
  },
  {
    "timestamp": "01/09/24 15:00",
    "demand": 32373.85243,
    "solar_generated": 23.42265138,
    "wind_generated": 69.16068647,
    "marketplace_to_demand": 182.0316666,
    "grid_to_demand": 34785.90076,
    "govt_battery_charge": 73.94910524
  },
  {
    "timestamp": "01/09/24 16:00",
    "demand": 33802.13706,
    "solar_generated": 23.46317877,
    "wind_generated": 75.0313324,
    "marketplace_to_demand": 183.1777127,
    "grid_to_demand": 33192.21429,
    "govt_battery_charge": 66.44105002
  },
  {
    "timestamp": "01/09/24 17:00",
    "demand": 35973.97991,
    "solar_generated": 23.76004836,
    "wind_generated": 54.60894193,
    "marketplace_to_demand": 57.97042059,
    "grid_to_demand": 33865.37072,
    "govt_battery_charge": 92.63549747
  },
  {
    "timestamp": "01/09/24 18:00",
    "demand": 30495.96906,
    "solar_generated": 0,
    "wind_generated": 56.45154418,
    "marketplace_to_demand": 69.95904932,
    "grid_to_demand": 34267.8058,
    "govt_battery_charge": 80.26231116
  },
  {
    "timestamp": "01/09/24 19:00",
    "demand": 31484.19996,
    "solar_generated": 0,
    "wind_generated": 74.21554829,
    "marketplace_to_demand": 75.01728514,
    "grid_to_demand": 33888.66057,
    "govt_battery_charge": 17.40811575
  },
  {
    "timestamp": "01/09/24 20:00",
    "demand": 33485.67123,
    "solar_generated": 0,
    "wind_generated": 67.41937508,
    "marketplace_to_demand": 167.1362651,
    "grid_to_demand": 30076.95751,
    "govt_battery_charge": 49.43924144
  },
  {
    "timestamp": "01/09/24 21:00",
    "demand": 33844.18158,
    "solar_generated": 0,
    "wind_generated": 74.23876821,
    "marketplace_to_demand": 53.49628404,
    "grid_to_demand": 35657.59681,
    "govt_battery_charge": 2.787042226
  },
  {
    "timestamp": "01/09/24 22:00",
    "demand": 33925.74364,
    "solar_generated": 0,
    "wind_generated": 66.09440785,
    "marketplace_to_demand": 0.777302938,
    "grid_to_demand": 32807.95664,
    "govt_battery_charge": 61.38818452
  },
  {
    "timestamp": "01/09/24 23:00",
    "demand": 33890.61275,
    "solar_generated": 0,
    "wind_generated": 60.05887959,
    "marketplace_to_demand": 88.66378601,
    "grid_to_demand": 35809.2622,
    "govt_battery_charge": 73.71128594
  },
  {
    "timestamp": "02/09/24 0:00",
    "demand": 33171.98234,
    "solar_generated": 0,
    "wind_generated": 76.34783557,
    "marketplace_to_demand": 10.12965852,
    "grid_to_demand": 32899.41207,
    "govt_battery_charge": 6.960480973
  },
  {
    "timestamp": "02/09/24 1:00",
    "demand": 35140.74774,
    "solar_generated": 0,
    "wind_generated": 66.247675,
    "marketplace_to_demand": 103.2118194,
    "grid_to_demand": 34959.32739,
    "govt_battery_charge": 1.777208727
  },
  {
    "timestamp": "02/09/24 2:00",
    "demand": 33279.11852,
    "solar_generated": 0,
    "wind_generated": 74.84047755,
    "marketplace_to_demand": 129.5402296,
    "grid_to_demand": 32839.76293,
    "govt_battery_charge": 64.03205362
  },
  {
    "timestamp": "02/09/24 3:00",
    "demand": 33660.77013,
    "solar_generated": 0,
    "wind_generated": 69.19643215,
    "marketplace_to_demand": 114.0826584,
    "grid_to_demand": 32403.44891,
    "govt_battery_charge": 87.65785603
  },
  {
    "timestamp": "02/09/24 4:00",
    "demand": 33110.34641,
    "solar_generated": 0,
    "wind_generated": 57.04937089,
    "marketplace_to_demand": 89.41972498,
    "grid_to_demand": 34191.74617,
    "govt_battery_charge": 56.71919605
  },
  {
    "timestamp": "02/09/24 5:00",
    "demand": 34639.6893,
    "solar_generated": 0,
    "wind_generated": 63.82482552,
    "marketplace_to_demand": 18.92094149,
    "grid_to_demand": 33944.89698,
    "govt_battery_charge": 35.55702343
  },
  {
    "timestamp": "02/09/24 6:00",
    "demand": 32956.02903,
    "solar_generated": 12.48930175,
    "wind_generated": 65.12011975,
    "marketplace_to_demand": 73.12446611,
    "grid_to_demand": 35103.37983,
    "govt_battery_charge": 76.19251838
  },
  {
    "timestamp": "02/09/24 7:00",
    "demand": 35190.67029,
    "solar_generated": 20.86770786,
    "wind_generated": 64.60727637,
    "marketplace_to_demand": 17.17287023,
    "grid_to_demand": 30417.74456,
    "govt_battery_charge": 62.17493528
  },
  {
    "timestamp": "02/09/24 8:00",
    "demand": 30600.499,
    "solar_generated": 6.799528227,
    "wind_generated": 62.07196381,
    "marketplace_to_demand": 107.5744549,
    "grid_to_demand": 31112.80493,
    "govt_battery_charge": 43.04547567
  },
  {
    "timestamp": "02/09/24 9:00",
    "demand": 35376.63355,
    "solar_generated": 23.46054357,
    "wind_generated": 57.37534451,
    "marketplace_to_demand": 95.05976395,
    "grid_to_demand": 32951.70378,
    "govt_battery_charge": 74.72812497
  },
  {
    "timestamp": "02/09/24 10:00",
    "demand": 35669.40633,
    "solar_generated": 20.34253906,
    "wind_generated": 73.26568647,
    "marketplace_to_demand": 131.8938657,
    "grid_to_demand": 30123.8767,
    "govt_battery_charge": 54.31945489
  },
  {
    "timestamp": "02/09/24 11:00",
    "demand": 33021.78165,
    "solar_generated": 16.84666991,
    "wind_generated": 73.82834999,
    "marketplace_to_demand": 48.21354251,
    "grid_to_demand": 34506.56687,
    "govt_battery_charge": 36.0458424
  },
  {
    "timestamp": "02/09/24 12:00",
    "demand": 32311.18633,
    "solar_generated": 16.40495062,
    "wind_generated": 55.00468043,
    "marketplace_to_demand": 30.31652211,
    "grid_to_demand": 36093.30851,
    "govt_battery_charge": 67.22511032
  },
  {
    "timestamp": "02/09/24 13:00",
    "demand": 33974.76961,
    "solar_generated": 9.079905443,
    "wind_generated": 65.78012773,
    "marketplace_to_demand": 43.26789237,
    "grid_to_demand": 32514.96385,
    "govt_battery_charge": 64.28263067
  },
  {
    "timestamp": "02/09/24 14:00",
    "demand": 36585.1786,
    "solar_generated": 18.55215785,
    "wind_generated": 70.59542528,
    "marketplace_to_demand": 39.42240661,
    "grid_to_demand": 34327.41693,
    "govt_battery_charge": 90.4762858
  },
  {
    "timestamp": "02/09/24 15:00",
    "demand": 35966.00675,
    "solar_generated": 7.09174779,
    "wind_generated": 67.38903986,
    "marketplace_to_demand": 68.5919797,
    "grid_to_demand": 32471.64503,
    "govt_battery_charge": 73.49175377
  },
  {
    "timestamp": "02/09/24 16:00",
    "demand": 33764.00665,
    "solar_generated": 11.14942602,
    "wind_generated": 76.44308018,
    "marketplace_to_demand": 146.8844378,
    "grid_to_demand": 34300.54825,
    "govt_battery_charge": 6.685078816
  },
  {
    "timestamp": "02/09/24 17:00",
    "demand": 33005.95481,
    "solar_generated": 10.94162093,
    "wind_generated": 78.29513064,
    "marketplace_to_demand": 9.682040777,
    "grid_to_demand": 35773.01145,
    "govt_battery_charge": 96.49277077
  },
  {
    "timestamp": "02/09/24 18:00",
    "demand": 31842.23974,
    "solar_generated": 0,
    "wind_generated": 56.11405014,
    "marketplace_to_demand": 172.7750144,
    "grid_to_demand": 32476.17596,
    "govt_battery_charge": 1.796348012
  },
  {
    "timestamp": "02/09/24 19:00",
    "demand": 30816.43087,
    "solar_generated": 0,
    "wind_generated": 70.8423026,
    "marketplace_to_demand": 157.5936354,
    "grid_to_demand": 34713.13266,
    "govt_battery_charge": 33.54598847
  },
  {
    "timestamp": "02/09/24 20:00",
    "demand": 34331.8701,
    "solar_generated": 0,
    "wind_generated": 62.81538737,
    "marketplace_to_demand": 168.287188,
    "grid_to_demand": 32235.38828,
    "govt_battery_charge": 75.92727053
  },
  {
    "timestamp": "02/09/24 21:00",
    "demand": 30791.02237,
    "solar_generated": 0,
    "wind_generated": 68.70950886,
    "marketplace_to_demand": 18.8006796,
    "grid_to_demand": 33260.70962,
    "govt_battery_charge": 65.65869996
  },
  {
    "timestamp": "02/09/24 22:00",
    "demand": 34397.10929,
    "solar_generated": 0,
    "wind_generated": 56.15156382,
    "marketplace_to_demand": 187.611638,
    "grid_to_demand": 33217.77298,
    "govt_battery_charge": 10.87850285
  },
  {
    "timestamp": "02/09/24 23:00",
    "demand": 31859.27527,
    "solar_generated": 0,
    "wind_generated": 71.25470725,
    "marketplace_to_demand": 62.82601014,
    "grid_to_demand": 35090.12065,
    "govt_battery_charge": 39.95100415
  },
  {
    "timestamp": "03/09/24 0:00",
    "demand": 36410.29618,
    "solar_generated": 0,
    "wind_generated": 61.74382158,
    "marketplace_to_demand": 174.984362,
    "grid_to_demand": 31413.87941,
    "govt_battery_charge": 39.78385747
  },
  {
    "timestamp": "03/09/24 1:00",
    "demand": 32455.75052,
    "solar_generated": 0,
    "wind_generated": 62.20131614,
    "marketplace_to_demand": 0.543996728,
    "grid_to_demand": 29963.60446,
    "govt_battery_charge": 59.09390098
  },
  {
    "timestamp": "03/09/24 2:00",
    "demand": 35931.03372,
    "solar_generated": 0,
    "wind_generated": 66.58251711,
    "marketplace_to_demand": 133.8793397,
    "grid_to_demand": 32438.1932,
    "govt_battery_charge": 56.19035839
  },
  {
    "timestamp": "03/09/24 3:00",
    "demand": 30184.71174,
    "solar_generated": 0,
    "wind_generated": 69.3430166,
    "marketplace_to_demand": 53.04510783,
    "grid_to_demand": 31993.10333,
    "govt_battery_charge": 79.39591204
  },
  {
    "timestamp": "03/09/24 4:00",
    "demand": 35089.82178,
    "solar_generated": 0,
    "wind_generated": 74.95799882,
    "marketplace_to_demand": 18.59041801,
    "grid_to_demand": 36169.11422,
    "govt_battery_charge": 9.153727041
  },
  {
    "timestamp": "03/09/24 5:00",
    "demand": 36398.25414,
    "solar_generated": 0,
    "wind_generated": 54.60460666,
    "marketplace_to_demand": 151.3949453,
    "grid_to_demand": 32527.61209,
    "govt_battery_charge": 28.72302843
  },
  {
    "timestamp": "03/09/24 6:00",
    "demand": 32317.3683,
    "solar_generated": 18.9804572,
    "wind_generated": 58.63137842,
    "marketplace_to_demand": 49.6396787,
    "grid_to_demand": 35748.06701,
    "govt_battery_charge": 10.64477585
  },
  {
    "timestamp": "03/09/24 7:00",
    "demand": 30935.09784,
    "solar_generated": 19.07475108,
    "wind_generated": 63.02425722,
    "marketplace_to_demand": 10.44511819,
    "grid_to_demand": 31343.08721,
    "govt_battery_charge": 64.41517117
  },
  {
    "timestamp": "03/09/24 8:00",
    "demand": 32711.21929,
    "solar_generated": 14.30310391,
    "wind_generated": 70.87497113,
    "marketplace_to_demand": 188.692005,
    "grid_to_demand": 35554.59104,
    "govt_battery_charge": 34.81087736
  },
  {
    "timestamp": "03/09/24 9:00",
    "demand": 31698.65164,
    "solar_generated": 21.2709962,
    "wind_generated": 70.07371311,
    "marketplace_to_demand": 145.5455624,
    "grid_to_demand": 34854.40651,
    "govt_battery_charge": 6.693395314
  },
  {
    "timestamp": "03/09/24 10:00",
    "demand": 33044.36885,
    "solar_generated": 13.40874065,
    "wind_generated": 67.78154765,
    "marketplace_to_demand": 60.922856,
    "grid_to_demand": 30487.5636,
    "govt_battery_charge": 85.10794579
  },
  {
    "timestamp": "03/09/24 11:00",
    "demand": 32333.29011,
    "solar_generated": 19.12775886,
    "wind_generated": 60.06308968,
    "marketplace_to_demand": 70.6618812,
    "grid_to_demand": 32277.85197,
    "govt_battery_charge": 94.44489397
  },
  {
    "timestamp": "03/09/24 12:00",
    "demand": 31448.28243,
    "solar_generated": 21.34023597,
    "wind_generated": 74.74813246,
    "marketplace_to_demand": 163.2816421,
    "grid_to_demand": 35861.0522,
    "govt_battery_charge": 74.21723449
  },
  {
    "timestamp": "03/09/24 13:00",
    "demand": 30995.68776,
    "solar_generated": 6.37221662,
    "wind_generated": 60.74126953,
    "marketplace_to_demand": 63.51124913,
    "grid_to_demand": 31694.56216,
    "govt_battery_charge": 29.10705371
  },
  {
    "timestamp": "03/09/24 14:00",
    "demand": 34146.43425,
    "solar_generated": 9.067629815,
    "wind_generated": 57.43078305,
    "marketplace_to_demand": 18.87532271,
    "grid_to_demand": 36077.08328,
    "govt_battery_charge": 56.02917489
  },
  {
    "timestamp": "03/09/24 15:00",
    "demand": 33662.29363,
    "solar_generated": 21.46241829,
    "wind_generated": 62.45750885,
    "marketplace_to_demand": 70.90075677,
    "grid_to_demand": 34715.4376,
    "govt_battery_charge": 22.85075546
  },
  {
    "timestamp": "03/09/24 16:00",
    "demand": 31197.63968,
    "solar_generated": 10.90465566,
    "wind_generated": 61.08279198,
    "marketplace_to_demand": 45.75965859,
    "grid_to_demand": 31919.38502,
    "govt_battery_charge": 28.01870388
  },
  {
    "timestamp": "03/09/24 17:00",
    "demand": 31227.85873,
    "solar_generated": 16.74620986,
    "wind_generated": 54.68529396,
    "marketplace_to_demand": 73.32941943,
    "grid_to_demand": 30951.94686,
    "govt_battery_charge": 59.39617473
  },
  {
    "timestamp": "03/09/24 18:00",
    "demand": 33485.10211,
    "solar_generated": 0,
    "wind_generated": 66.5208528,
    "marketplace_to_demand": 77.31061426,
    "grid_to_demand": 34060.74757,
    "govt_battery_charge": 99.13019485
  },
  {
    "timestamp": "03/09/24 19:00",
    "demand": 33347.72897,
    "solar_generated": 0,
    "wind_generated": 77.5415919,
    "marketplace_to_demand": 125.1834602,
    "grid_to_demand": 35492.05198,
    "govt_battery_charge": 14.32813704
  },
  {
    "timestamp": "03/09/24 20:00",
    "demand": 35101.0285,
    "solar_generated": 0,
    "wind_generated": 68.15414819,
    "marketplace_to_demand": 87.1194931,
    "grid_to_demand": 32411.49889,
    "govt_battery_charge": 26.00544039
  },
  {
    "timestamp": "03/09/24 21:00",
    "demand": 33208.63745,
    "solar_generated": 0,
    "wind_generated": 75.01003302,
    "marketplace_to_demand": 186.3964013,
    "grid_to_demand": 33038.11288,
    "govt_battery_charge": 64.75587182
  },
  {
    "timestamp": "03/09/24 22:00",
    "demand": 30823.45468,
    "solar_generated": 0,
    "wind_generated": 72.24179826,
    "marketplace_to_demand": 142.5588874,
    "grid_to_demand": 31240.12772,
    "govt_battery_charge": 86.81266296
  },
  {
    "timestamp": "03/09/24 23:00",
    "demand": 36578.30098,
    "solar_generated": 0,
    "wind_generated": 57.47987159,
    "marketplace_to_demand": 136.5838889,
    "grid_to_demand": 36079.12909,
    "govt_battery_charge": 79.36504047
  },
  {
    "timestamp": "04/09/24 0:00",
    "demand": 34857.9051,
    "solar_generated": 0,
    "wind_generated": 72.39554117,
    "marketplace_to_demand": 59.980123,
    "grid_to_demand": 33469.79348,
    "govt_battery_charge": 64.77257976
  },
  {
    "timestamp": "04/09/24 1:00",
    "demand": 34353.27021,
    "solar_generated": 0,
    "wind_generated": 67.30172815,
    "marketplace_to_demand": 22.55797421,
    "grid_to_demand": 35135.64451,
    "govt_battery_charge": 90.49808767
  },
  {
    "timestamp": "04/09/24 2:00",
    "demand": 33859.89912,
    "solar_generated": 0,
    "wind_generated": 76.84290826,
    "marketplace_to_demand": 12.62787876,
    "grid_to_demand": 33966.50344,
    "govt_battery_charge": 44.53280687
  },
  {
    "timestamp": "04/09/24 3:00",
    "demand": 32146.03119,
    "solar_generated": 0,
    "wind_generated": 57.12144609,
    "marketplace_to_demand": 38.52269347,
    "grid_to_demand": 30377.72198,
    "govt_battery_charge": 75.8553575
  },
  {
    "timestamp": "04/09/24 4:00",
    "demand": 30801.6491,
    "solar_generated": 0,
    "wind_generated": 67.24141474,
    "marketplace_to_demand": 104.6753138,
    "grid_to_demand": 34781.74733,
    "govt_battery_charge": 77.18132385
  },
  {
    "timestamp": "04/09/24 5:00",
    "demand": 30070.05659,
    "solar_generated": 0,
    "wind_generated": 66.36860668,
    "marketplace_to_demand": 142.8488989,
    "grid_to_demand": 32415.10782,
    "govt_battery_charge": 67.57170555
  },
  {
    "timestamp": "04/09/24 6:00",
    "demand": 32674.692,
    "solar_generated": 22.24154023,
    "wind_generated": 73.781331,
    "marketplace_to_demand": 169.3472976,
    "grid_to_demand": 34565.55561,
    "govt_battery_charge": 91.618974
  },
  {
    "timestamp": "04/09/24 7:00",
    "demand": 32158.07166,
    "solar_generated": 7.47684837,
    "wind_generated": 70.75793991,
    "marketplace_to_demand": 178.7781896,
    "grid_to_demand": 33757.55864,
    "govt_battery_charge": 82.17857718
  },
  {
    "timestamp": "04/09/24 8:00",
    "demand": 33939.63605,
    "solar_generated": 8.711085547,
    "wind_generated": 77.91958158,
    "marketplace_to_demand": 125.1092512,
    "grid_to_demand": 36517.99654,
    "govt_battery_charge": 35.58138339
  },
  {
    "timestamp": "04/09/24 9:00",
    "demand": 31256.96013,
    "solar_generated": 13.87031522,
    "wind_generated": 74.88048601,
    "marketplace_to_demand": 126.76631,
    "grid_to_demand": 35018.13429,
    "govt_battery_charge": 24.18080859
  },
  {
    "timestamp": "04/09/24 10:00",
    "demand": 35107.21494,
    "solar_generated": 17.00957775,
    "wind_generated": 68.64518275,
    "marketplace_to_demand": 70.95763503,
    "grid_to_demand": 34771.65315,
    "govt_battery_charge": 58.85334492
  },
  {
    "timestamp": "04/09/24 11:00",
    "demand": 30317.84071,
    "solar_generated": 19.93621961,
    "wind_generated": 76.60952747,
    "marketplace_to_demand": 22.10291492,
    "grid_to_demand": 36352.29485,
    "govt_battery_charge": 31.40164512
  },
  {
    "timestamp": "04/09/24 12:00",
    "demand": 30324.85357,
    "solar_generated": 21.42480316,
    "wind_generated": 61.01310323,
    "marketplace_to_demand": 92.98505066,
    "grid_to_demand": 34228.14319,
    "govt_battery_charge": 61.22133264
  },
  {
    "timestamp": "04/09/24 13:00",
    "demand": 35721.77337,
    "solar_generated": 22.92393123,
    "wind_generated": 64.19148,
    "marketplace_to_demand": 91.97457294,
    "grid_to_demand": 35167.76426,
    "govt_battery_charge": 91.5101805
  },
  {
    "timestamp": "04/09/24 14:00",
    "demand": 36150.07898,
    "solar_generated": 14.31334166,
    "wind_generated": 58.02099331,
    "marketplace_to_demand": 172.2479914,
    "grid_to_demand": 36145.36232,
    "govt_battery_charge": 44.03096593
  },
  {
    "timestamp": "04/09/24 15:00",
    "demand": 31504.74598,
    "solar_generated": 6.233817974,
    "wind_generated": 54.54375651,
    "marketplace_to_demand": 3.541061241,
    "grid_to_demand": 32045.80331,
    "govt_battery_charge": 71.55619509
  },
  {
    "timestamp": "04/09/24 16:00",
    "demand": 31526.58572,
    "solar_generated": 8.935252141,
    "wind_generated": 60.97660888,
    "marketplace_to_demand": 178.3639878,
    "grid_to_demand": 34823.84653,
    "govt_battery_charge": 55.35222103
  },
  {
    "timestamp": "04/09/24 17:00",
    "demand": 29991.61771,
    "solar_generated": 14.48202212,
    "wind_generated": 55.20178416,
    "marketplace_to_demand": 30.54471732,
    "grid_to_demand": 32049.01754,
    "govt_battery_charge": 90.64843649
  },
  {
    "timestamp": "04/09/24 18:00",
    "demand": 31582.34816,
    "solar_generated": 0,
    "wind_generated": 65.11468933,
    "marketplace_to_demand": 114.816256,
    "grid_to_demand": 32757.33276,
    "govt_battery_charge": 16.4597594
  },
  {
    "timestamp": "04/09/24 19:00",
    "demand": 32803.73726,
    "solar_generated": 0,
    "wind_generated": 72.35242195,
    "marketplace_to_demand": 170.1301102,
    "grid_to_demand": 33572.94009,
    "govt_battery_charge": 67.66380489
  },
  {
    "timestamp": "04/09/24 20:00",
    "demand": 32012.44101,
    "solar_generated": 0,
    "wind_generated": 72.6236474,
    "marketplace_to_demand": 68.79706276,
    "grid_to_demand": 31405.83571,
    "govt_battery_charge": 54.47087318
  },
  {
    "timestamp": "04/09/24 21:00",
    "demand": 31264.68867,
    "solar_generated": 0,
    "wind_generated": 65.17778572,
    "marketplace_to_demand": 198.5257842,
    "grid_to_demand": 35890.05668,
    "govt_battery_charge": 56.12049947
  },
  {
    "timestamp": "04/09/24 22:00",
    "demand": 32598.04436,
    "solar_generated": 0,
    "wind_generated": 70.08656074,
    "marketplace_to_demand": 105.8294308,
    "grid_to_demand": 35011.41701,
    "govt_battery_charge": 93.61601011
  },
  {
    "timestamp": "04/09/24 23:00",
    "demand": 34105.66809,
    "solar_generated": 0,
    "wind_generated": 79.0146568,
    "marketplace_to_demand": 109.9430024,
    "grid_to_demand": 32610.58381,
    "govt_battery_charge": 52.10602192
  },
  {
    "timestamp": "05/09/24 0:00",
    "demand": 32862.74048,
    "solar_generated": 0,
    "wind_generated": 71.14543466,
    "marketplace_to_demand": 16.83420941,
    "grid_to_demand": 30925.53176,
    "govt_battery_charge": 51.28575436
  },
  {
    "timestamp": "05/09/24 1:00",
    "demand": 30432.47581,
    "solar_generated": 0,
    "wind_generated": 55.4606979,
    "marketplace_to_demand": 187.7600985,
    "grid_to_demand": 30029.92136,
    "govt_battery_charge": 97.21745099
  },
  {
    "timestamp": "05/09/24 2:00",
    "demand": 35034.81983,
    "solar_generated": 0,
    "wind_generated": 58.25123993,
    "marketplace_to_demand": 63.67081714,
    "grid_to_demand": 32840.15887,
    "govt_battery_charge": 88.36976755
  },
  {
    "timestamp": "05/09/24 3:00",
    "demand": 34981.72593,
    "solar_generated": 0,
    "wind_generated": 76.98087898,
    "marketplace_to_demand": 189.083881,
    "grid_to_demand": 31322.79885,
    "govt_battery_charge": 13.12942245
  },
  {
    "timestamp": "05/09/24 4:00",
    "demand": 36044.33721,
    "solar_generated": 0,
    "wind_generated": 61.81822437,
    "marketplace_to_demand": 9.969827809,
    "grid_to_demand": 32206.95333,
    "govt_battery_charge": 8.95617619
  },
  {
    "timestamp": "05/09/24 5:00",
    "demand": 33010.86118,
    "solar_generated": 0,
    "wind_generated": 66.57005851,
    "marketplace_to_demand": 136.0863172,
    "grid_to_demand": 31605.65275,
    "govt_battery_charge": 78.84816686
  },
  {
    "timestamp": "05/09/24 6:00",
    "demand": 35326.97332,
    "solar_generated": 9.244393766,
    "wind_generated": 73.69310384,
    "marketplace_to_demand": 23.57140364,
    "grid_to_demand": 35598.9105,
    "govt_battery_charge": 28.15787088
  },
  {
    "timestamp": "05/09/24 7:00",
    "demand": 30054.09934,
    "solar_generated": 11.37291722,
    "wind_generated": 70.29710923,
    "marketplace_to_demand": 109.1770381,
    "grid_to_demand": 32859.93795,
    "govt_battery_charge": 61.384234
  },
  {
    "timestamp": "05/09/24 8:00",
    "demand": 30513.35175,
    "solar_generated": 10.19293437,
    "wind_generated": 63.83782689,
    "marketplace_to_demand": 164.6626183,
    "grid_to_demand": 32388.98376,
    "govt_battery_charge": 10.78770665
  },
  {
    "timestamp": "05/09/24 9:00",
    "demand": 34405.44633,
    "solar_generated": 11.43915751,
    "wind_generated": 74.76885351,
    "marketplace_to_demand": 96.4094403,
    "grid_to_demand": 33977.61012,
    "govt_battery_charge": 29.56724631
  },
  {
    "timestamp": "05/09/24 10:00",
    "demand": 33248.3455,
    "solar_generated": 22.80325873,
    "wind_generated": 60.30038422,
    "marketplace_to_demand": 28.17608473,
    "grid_to_demand": 30332.96251,
    "govt_battery_charge": 50.13928515
  },
  {
    "timestamp": "05/09/24 11:00",
    "demand": 32951.58345,
    "solar_generated": 15.53848669,
    "wind_generated": 61.77847053,
    "marketplace_to_demand": 191.3376079,
    "grid_to_demand": 34098.74804,
    "govt_battery_charge": 16.85106136
  },
  {
    "timestamp": "05/09/24 12:00",
    "demand": 35836.69073,
    "solar_generated": 22.44850594,
    "wind_generated": 60.47077496,
    "marketplace_to_demand": 130.3226078,
    "grid_to_demand": 35458.78933,
    "govt_battery_charge": 40.85664239
  },
  {
    "timestamp": "05/09/24 13:00",
    "demand": 35609.95026,
    "solar_generated": 17.11637665,
    "wind_generated": 58.15490652,
    "marketplace_to_demand": 125.0658042,
    "grid_to_demand": 36091.542,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "05/09/24 14:00",
    "demand": 32034.877,
    "solar_generated": 6.654345981,
    "wind_generated": 59.21316772,
    "marketplace_to_demand": 162.1861751,
    "grid_to_demand": 35181.15987,
    "govt_battery_charge": 65.0297853
  },
  {
    "timestamp": "05/09/24 15:00",
    "demand": 32444.10637,
    "solar_generated": 10.98520425,
    "wind_generated": 78.23745798,
    "marketplace_to_demand": 199.7226737,
    "grid_to_demand": 33824.34392,
    "govt_battery_charge": 89.62805355
  },
  {
    "timestamp": "05/09/24 16:00",
    "demand": 35234.35981,
    "solar_generated": 13.51266414,
    "wind_generated": 76.97334026,
    "marketplace_to_demand": 156.8061957,
    "grid_to_demand": 33349.91307,
    "govt_battery_charge": 86.60794621
  },
  {
    "timestamp": "05/09/24 17:00",
    "demand": 34495.84673,
    "solar_generated": 13.45156377,
    "wind_generated": 72.21140903,
    "marketplace_to_demand": 47.4726288,
    "grid_to_demand": 32380.43192,
    "govt_battery_charge": 87.59976979
  },
  {
    "timestamp": "05/09/24 18:00",
    "demand": 31864.77486,
    "solar_generated": 0,
    "wind_generated": 80.05474382,
    "marketplace_to_demand": 114.5710524,
    "grid_to_demand": 36371.84127,
    "govt_battery_charge": 41.90651787
  },
  {
    "timestamp": "05/09/24 19:00",
    "demand": 32700.56861,
    "solar_generated": 0,
    "wind_generated": 59.25528567,
    "marketplace_to_demand": 92.28797237,
    "grid_to_demand": 32394.63032,
    "govt_battery_charge": 38.37245371
  },
  {
    "timestamp": "05/09/24 20:00",
    "demand": 34453.80364,
    "solar_generated": 0,
    "wind_generated": 53.69335865,
    "marketplace_to_demand": 88.90608241,
    "grid_to_demand": 34910.82525,
    "govt_battery_charge": 82.38167697
  },
  {
    "timestamp": "05/09/24 21:00",
    "demand": 31049.04873,
    "solar_generated": 0,
    "wind_generated": 58.49492536,
    "marketplace_to_demand": 56.260739,
    "grid_to_demand": 35234.86624,
    "govt_battery_charge": 66.29192485
  },
  {
    "timestamp": "05/09/24 22:00",
    "demand": 33544.68373,
    "solar_generated": 0,
    "wind_generated": 68.12270578,
    "marketplace_to_demand": 35.12104404,
    "grid_to_demand": 30981.73136,
    "govt_battery_charge": 79.40761655
  },
  {
    "timestamp": "05/09/24 23:00",
    "demand": 32734.47175,
    "solar_generated": 0,
    "wind_generated": 59.78543422,
    "marketplace_to_demand": 119.7153681,
    "grid_to_demand": 33752.93878,
    "govt_battery_charge": 2.875459024
  },
  {
    "timestamp": "06/09/24 0:00",
    "demand": 30969.34221,
    "solar_generated": 0,
    "wind_generated": 77.93984536,
    "marketplace_to_demand": 18.32787572,
    "grid_to_demand": 30650.85749,
    "govt_battery_charge": 66.3398925
  },
  {
    "timestamp": "06/09/24 1:00",
    "demand": 36441.02322,
    "solar_generated": 0,
    "wind_generated": 60.58565707,
    "marketplace_to_demand": 175.1302658,
    "grid_to_demand": 30778.6317,
    "govt_battery_charge": 83.76748048
  },
  {
    "timestamp": "06/09/24 2:00",
    "demand": 32079.65038,
    "solar_generated": 0,
    "wind_generated": 79.69375839,
    "marketplace_to_demand": 82.79535225,
    "grid_to_demand": 36509.55027,
    "govt_battery_charge": 73.32835811
  },
  {
    "timestamp": "06/09/24 3:00",
    "demand": 34925.34378,
    "solar_generated": 0,
    "wind_generated": 79.65579989,
    "marketplace_to_demand": 138.2701338,
    "grid_to_demand": 35378.40429,
    "govt_battery_charge": 92.37503357
  },
  {
    "timestamp": "06/09/24 4:00",
    "demand": 30822.87361,
    "solar_generated": 0,
    "wind_generated": 53.94090839,
    "marketplace_to_demand": 144.2315449,
    "grid_to_demand": 32306.69597,
    "govt_battery_charge": 10.43321695
  },
  {
    "timestamp": "06/09/24 5:00",
    "demand": 35956.64461,
    "solar_generated": 0,
    "wind_generated": 72.33274832,
    "marketplace_to_demand": 150.5296841,
    "grid_to_demand": 33920.43302,
    "govt_battery_charge": 3.16840065
  },
  {
    "timestamp": "06/09/24 6:00",
    "demand": 31430.30002,
    "solar_generated": 22.45410592,
    "wind_generated": 73.64566011,
    "marketplace_to_demand": 67.75285888,
    "grid_to_demand": 36258.88222,
    "govt_battery_charge": 41.86770051
  },
  {
    "timestamp": "06/09/24 7:00",
    "demand": 30651.11966,
    "solar_generated": 9.055258935,
    "wind_generated": 57.49251968,
    "marketplace_to_demand": 69.66328936,
    "grid_to_demand": 35779.94089,
    "govt_battery_charge": 31.07979343
  },
  {
    "timestamp": "06/09/24 8:00",
    "demand": 30984.65462,
    "solar_generated": 8.933788709,
    "wind_generated": 76.26357182,
    "marketplace_to_demand": 118.4415136,
    "grid_to_demand": 30059.86489,
    "govt_battery_charge": 52.25022772
  },
  {
    "timestamp": "06/09/24 9:00",
    "demand": 33688.43845,
    "solar_generated": 14.90146683,
    "wind_generated": 78.41625559,
    "marketplace_to_demand": 65.68627228,
    "grid_to_demand": 33848.02233,
    "govt_battery_charge": 16.12453343
  },
  {
    "timestamp": "06/09/24 10:00",
    "demand": 33175.59577,
    "solar_generated": 17.28470593,
    "wind_generated": 75.63238853,
    "marketplace_to_demand": 34.84358972,
    "grid_to_demand": 30738.69888,
    "govt_battery_charge": 91.03024528
  },
  {
    "timestamp": "06/09/24 11:00",
    "demand": 31482.08881,
    "solar_generated": 9.673201176,
    "wind_generated": 75.68269416,
    "marketplace_to_demand": 44.71322677,
    "grid_to_demand": 30394.60536,
    "govt_battery_charge": 8.868565893
  },
  {
    "timestamp": "06/09/24 12:00",
    "demand": 35216.35334,
    "solar_generated": 19.0058696,
    "wind_generated": 64.62600194,
    "marketplace_to_demand": 118.889134,
    "grid_to_demand": 36315.82014,
    "govt_battery_charge": 79.59422053
  },
  {
    "timestamp": "06/09/24 13:00",
    "demand": 35045.42997,
    "solar_generated": 19.99258848,
    "wind_generated": 71.82319818,
    "marketplace_to_demand": 189.2199939,
    "grid_to_demand": 31660.08765,
    "govt_battery_charge": 20.05390749
  },
  {
    "timestamp": "06/09/24 14:00",
    "demand": 32534.11329,
    "solar_generated": 19.13365854,
    "wind_generated": 66.43315501,
    "marketplace_to_demand": 33.21338868,
    "grid_to_demand": 30603.5193,
    "govt_battery_charge": 77.05369153
  },
  {
    "timestamp": "06/09/24 15:00",
    "demand": 32881.5202,
    "solar_generated": 6.982167861,
    "wind_generated": 79.20970132,
    "marketplace_to_demand": 22.58283451,
    "grid_to_demand": 34155.80198,
    "govt_battery_charge": 78.43562577
  },
  {
    "timestamp": "06/09/24 16:00",
    "demand": 36282.61078,
    "solar_generated": 14.28572075,
    "wind_generated": 69.32864824,
    "marketplace_to_demand": 79.98991341,
    "grid_to_demand": 36133.4311,
    "govt_battery_charge": 44.87115735
  },
  {
    "timestamp": "06/09/24 17:00",
    "demand": 33640.75783,
    "solar_generated": 22.11699315,
    "wind_generated": 65.00840257,
    "marketplace_to_demand": 12.3174412,
    "grid_to_demand": 36052.93237,
    "govt_battery_charge": 10.83480576
  },
  {
    "timestamp": "06/09/24 18:00",
    "demand": 31302.53311,
    "solar_generated": 0,
    "wind_generated": 56.88187383,
    "marketplace_to_demand": 157.1717762,
    "grid_to_demand": 36074.28936,
    "govt_battery_charge": 28.59355594
  },
  {
    "timestamp": "06/09/24 19:00",
    "demand": 31462.82915,
    "solar_generated": 0,
    "wind_generated": 78.65558665,
    "marketplace_to_demand": 46.34396027,
    "grid_to_demand": 36070.12722,
    "govt_battery_charge": 6.456445117
  },
  {
    "timestamp": "06/09/24 20:00",
    "demand": 32574.31875,
    "solar_generated": 0,
    "wind_generated": 54.7885703,
    "marketplace_to_demand": 2.932756411,
    "grid_to_demand": 36439.39092,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "06/09/24 21:00",
    "demand": 31704.21937,
    "solar_generated": 0,
    "wind_generated": 66.37419094,
    "marketplace_to_demand": 51.02952458,
    "grid_to_demand": 34786.26539,
    "govt_battery_charge": 38.57622487
  },
  {
    "timestamp": "06/09/24 22:00",
    "demand": 36580.39424,
    "solar_generated": 0,
    "wind_generated": 77.18134755,
    "marketplace_to_demand": 69.38518156,
    "grid_to_demand": 32916.88179,
    "govt_battery_charge": 93.89931548
  },
  {
    "timestamp": "06/09/24 23:00",
    "demand": 34877.58348,
    "solar_generated": 0,
    "wind_generated": 77.17681531,
    "marketplace_to_demand": 120.1064142,
    "grid_to_demand": 36141.93568,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "07/09/24 0:00",
    "demand": 32613.68856,
    "solar_generated": 0,
    "wind_generated": 67.44094943,
    "marketplace_to_demand": 131.1100175,
    "grid_to_demand": 36200.72007,
    "govt_battery_charge": 71.24045661
  },
  {
    "timestamp": "07/09/24 1:00",
    "demand": 30275.47157,
    "solar_generated": 0,
    "wind_generated": 61.27052959,
    "marketplace_to_demand": 128.6963887,
    "grid_to_demand": 35361.02869,
    "govt_battery_charge": 8.273068316
  },
  {
    "timestamp": "07/09/24 2:00",
    "demand": 32268.06343,
    "solar_generated": 0,
    "wind_generated": 78.0524619,
    "marketplace_to_demand": 57.24784702,
    "grid_to_demand": 30832.25297,
    "govt_battery_charge": 10.62121788
  },
  {
    "timestamp": "07/09/24 3:00",
    "demand": 31875.86708,
    "solar_generated": 0,
    "wind_generated": 54.90318156,
    "marketplace_to_demand": 102.5582693,
    "grid_to_demand": 36036.10234,
    "govt_battery_charge": 49.7240693
  },
  {
    "timestamp": "07/09/24 4:00",
    "demand": 34815.77323,
    "solar_generated": 0,
    "wind_generated": 65.56366286,
    "marketplace_to_demand": 77.93120775,
    "grid_to_demand": 30106.29759,
    "govt_battery_charge": 29.91512419
  },
  {
    "timestamp": "07/09/24 5:00",
    "demand": 31791.44831,
    "solar_generated": 0,
    "wind_generated": 63.26907786,
    "marketplace_to_demand": 12.30194277,
    "grid_to_demand": 34226.36806,
    "govt_battery_charge": 32.97997345
  },
  {
    "timestamp": "07/09/24 6:00",
    "demand": 32826.53602,
    "solar_generated": 6.565680357,
    "wind_generated": 69.2301226,
    "marketplace_to_demand": 101.0853064,
    "grid_to_demand": 32595.29378,
    "govt_battery_charge": 11.56628631
  },
  {
    "timestamp": "07/09/24 7:00",
    "demand": 31835.96734,
    "solar_generated": 17.60116009,
    "wind_generated": 59.14909115,
    "marketplace_to_demand": 142.1131843,
    "grid_to_demand": 33273.10757,
    "govt_battery_charge": 18.2703145
  },
  {
    "timestamp": "07/09/24 8:00",
    "demand": 31341.39936,
    "solar_generated": 16.6825941,
    "wind_generated": 79.82537746,
    "marketplace_to_demand": 38.83691374,
    "grid_to_demand": 31770.73782,
    "govt_battery_charge": 37.80930843
  },
  {
    "timestamp": "07/09/24 9:00",
    "demand": 35202.0759,
    "solar_generated": 16.17290249,
    "wind_generated": 80.19639524,
    "marketplace_to_demand": 160.3172821,
    "grid_to_demand": 32831.0983,
    "govt_battery_charge": 22.04285922
  },
  {
    "timestamp": "07/09/24 10:00",
    "demand": 34649.26376,
    "solar_generated": 10.06951193,
    "wind_generated": 78.23572071,
    "marketplace_to_demand": 91.99444675,
    "grid_to_demand": 33401.71986,
    "govt_battery_charge": 28.87247295
  },
  {
    "timestamp": "07/09/24 11:00",
    "demand": 31631.57922,
    "solar_generated": 10.63815012,
    "wind_generated": 74.11960656,
    "marketplace_to_demand": 97.79659163,
    "grid_to_demand": 36072.43365,
    "govt_battery_charge": 80.14586774
  },
  {
    "timestamp": "07/09/24 12:00",
    "demand": 30182.16023,
    "solar_generated": 10.92902972,
    "wind_generated": 54.77707573,
    "marketplace_to_demand": 81.0105302,
    "grid_to_demand": 30350.07017,
    "govt_battery_charge": 26.71937456
  },
  {
    "timestamp": "07/09/24 13:00",
    "demand": 32155.32017,
    "solar_generated": 17.4066499,
    "wind_generated": 65.59002273,
    "marketplace_to_demand": 135.3500885,
    "grid_to_demand": 36088.35382,
    "govt_battery_charge": 40.35850149
  },
  {
    "timestamp": "07/09/24 14:00",
    "demand": 33121.32072,
    "solar_generated": 16.06500681,
    "wind_generated": 65.04510441,
    "marketplace_to_demand": 58.35636457,
    "grid_to_demand": 35385.93123,
    "govt_battery_charge": 57.61922776
  },
  {
    "timestamp": "07/09/24 15:00",
    "demand": 32373.35199,
    "solar_generated": 9.087764095,
    "wind_generated": 59.9342889,
    "marketplace_to_demand": 73.19240803,
    "grid_to_demand": 34876.75759,
    "govt_battery_charge": 61.87467784
  },
  {
    "timestamp": "07/09/24 16:00",
    "demand": 34480.32415,
    "solar_generated": 19.49485735,
    "wind_generated": 78.36924627,
    "marketplace_to_demand": 11.00485024,
    "grid_to_demand": 33488.84978,
    "govt_battery_charge": 25.98096983
  },
  {
    "timestamp": "07/09/24 17:00",
    "demand": 32439.47158,
    "solar_generated": 17.12801848,
    "wind_generated": 57.85713507,
    "marketplace_to_demand": 145.0472577,
    "grid_to_demand": 30016.84873,
    "govt_battery_charge": 61.260583
  },
  {
    "timestamp": "07/09/24 18:00",
    "demand": 30573.44836,
    "solar_generated": 0,
    "wind_generated": 58.19332538,
    "marketplace_to_demand": 64.75310162,
    "grid_to_demand": 35302.83513,
    "govt_battery_charge": 66.77463493
  },
  {
    "timestamp": "07/09/24 19:00",
    "demand": 34051.00054,
    "solar_generated": 0,
    "wind_generated": 74.14165925,
    "marketplace_to_demand": 48.92939204,
    "grid_to_demand": 34094.18657,
    "govt_battery_charge": 64.23510248
  },
  {
    "timestamp": "07/09/24 20:00",
    "demand": 30887.65328,
    "solar_generated": 0,
    "wind_generated": 61.75378186,
    "marketplace_to_demand": 197.1579928,
    "grid_to_demand": 35594.74745,
    "govt_battery_charge": 45.52335889
  },
  {
    "timestamp": "07/09/24 21:00",
    "demand": 32491.05278,
    "solar_generated": 0,
    "wind_generated": 62.76665723,
    "marketplace_to_demand": 179.0537533,
    "grid_to_demand": 31211.41416,
    "govt_battery_charge": 6.988932563
  },
  {
    "timestamp": "07/09/24 22:00",
    "demand": 35736.92742,
    "solar_generated": 0,
    "wind_generated": 57.86633822,
    "marketplace_to_demand": 185.575601,
    "grid_to_demand": 36233.52076,
    "govt_battery_charge": 68.10639151
  },
  {
    "timestamp": "07/09/24 23:00",
    "demand": 32496.33234,
    "solar_generated": 0,
    "wind_generated": 72.60763639,
    "marketplace_to_demand": 70.0696111,
    "grid_to_demand": 29962.87351,
    "govt_battery_charge": 20.63389894
  },
  {
    "timestamp": "08/09/24 0:00",
    "demand": 35487.11801,
    "solar_generated": 0,
    "wind_generated": 60.25906709,
    "marketplace_to_demand": 29.00871407,
    "grid_to_demand": 30529.83981,
    "govt_battery_charge": 83.49170214
  },
  {
    "timestamp": "08/09/24 1:00",
    "demand": 35096.41812,
    "solar_generated": 0,
    "wind_generated": 54.25211717,
    "marketplace_to_demand": 116.5413371,
    "grid_to_demand": 33523.13816,
    "govt_battery_charge": 99.01250708
  },
  {
    "timestamp": "08/09/24 2:00",
    "demand": 34013.63643,
    "solar_generated": 0,
    "wind_generated": 73.52592389,
    "marketplace_to_demand": 75.49723045,
    "grid_to_demand": 33616.85622,
    "govt_battery_charge": 42.2196768
  },
  {
    "timestamp": "08/09/24 3:00",
    "demand": 33315.47229,
    "solar_generated": 0,
    "wind_generated": 64.53128706,
    "marketplace_to_demand": 31.34241866,
    "grid_to_demand": 29950.73694,
    "govt_battery_charge": 33.18483419
  },
  {
    "timestamp": "08/09/24 4:00",
    "demand": 33301.78584,
    "solar_generated": 0,
    "wind_generated": 54.14956393,
    "marketplace_to_demand": 166.6681267,
    "grid_to_demand": 30322.29715,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "08/09/24 5:00",
    "demand": 36553.20055,
    "solar_generated": 0,
    "wind_generated": 64.04383267,
    "marketplace_to_demand": 137.6150066,
    "grid_to_demand": 35813.43552,
    "govt_battery_charge": 32.29779031
  },
  {
    "timestamp": "08/09/24 6:00",
    "demand": 34931.54448,
    "solar_generated": 7.121092526,
    "wind_generated": 64.78571559,
    "marketplace_to_demand": 95.66663172,
    "grid_to_demand": 35279.17523,
    "govt_battery_charge": 88.00146084
  },
  {
    "timestamp": "08/09/24 7:00",
    "demand": 36223.02544,
    "solar_generated": 13.54724027,
    "wind_generated": 55.20128414,
    "marketplace_to_demand": 19.43302392,
    "grid_to_demand": 31666.47666,
    "govt_battery_charge": 71.03995293
  },
  {
    "timestamp": "08/09/24 8:00",
    "demand": 36280.50547,
    "solar_generated": 12.39296831,
    "wind_generated": 59.44714114,
    "marketplace_to_demand": 169.004187,
    "grid_to_demand": 34753.44591,
    "govt_battery_charge": 99.92676505
  },
  {
    "timestamp": "08/09/24 9:00",
    "demand": 33877.86407,
    "solar_generated": 9.442501112,
    "wind_generated": 74.02776335,
    "marketplace_to_demand": 138.0094343,
    "grid_to_demand": 30172.73893,
    "govt_battery_charge": 94.16643797
  },
  {
    "timestamp": "08/09/24 10:00",
    "demand": 35752.52302,
    "solar_generated": 9.324084925,
    "wind_generated": 76.30851005,
    "marketplace_to_demand": 26.57517722,
    "grid_to_demand": 34053.15165,
    "govt_battery_charge": 5.711336587
  },
  {
    "timestamp": "08/09/24 11:00",
    "demand": 31369.26339,
    "solar_generated": 18.79528817,
    "wind_generated": 57.28316437,
    "marketplace_to_demand": 147.3875573,
    "grid_to_demand": 32645.37624,
    "govt_battery_charge": 84.89444475
  },
  {
    "timestamp": "08/09/24 12:00",
    "demand": 35544.6051,
    "solar_generated": 12.70901452,
    "wind_generated": 79.07909422,
    "marketplace_to_demand": 129.6016586,
    "grid_to_demand": 30287.58614,
    "govt_battery_charge": 52.97721724
  },
  {
    "timestamp": "08/09/24 13:00",
    "demand": 31984.35598,
    "solar_generated": 9.66649834,
    "wind_generated": 76.23066302,
    "marketplace_to_demand": 176.7872713,
    "grid_to_demand": 33054.12624,
    "govt_battery_charge": 34.18645746
  },
  {
    "timestamp": "08/09/24 14:00",
    "demand": 33792.61597,
    "solar_generated": 15.3390447,
    "wind_generated": 72.06757008,
    "marketplace_to_demand": 30.44592858,
    "grid_to_demand": 32582.51395,
    "govt_battery_charge": 75.1309172
  },
  {
    "timestamp": "08/09/24 15:00",
    "demand": 31383.01499,
    "solar_generated": 16.09654403,
    "wind_generated": 54.17518726,
    "marketplace_to_demand": 173.4363744,
    "grid_to_demand": 36008.0951,
    "govt_battery_charge": 85.24463487
  },
  {
    "timestamp": "08/09/24 16:00",
    "demand": 34908.63796,
    "solar_generated": 6.436699943,
    "wind_generated": 76.86928649,
    "marketplace_to_demand": 72.85426514,
    "grid_to_demand": 32906.53708,
    "govt_battery_charge": 57.56982885
  },
  {
    "timestamp": "08/09/24 17:00",
    "demand": 33982.9887,
    "solar_generated": 23.25911145,
    "wind_generated": 64.63841194,
    "marketplace_to_demand": 87.21553998,
    "grid_to_demand": 33653.91105,
    "govt_battery_charge": 35.11176512
  },
  {
    "timestamp": "08/09/24 18:00",
    "demand": 34433.76599,
    "solar_generated": 0,
    "wind_generated": 67.72643289,
    "marketplace_to_demand": 173.6320484,
    "grid_to_demand": 32800.32427,
    "govt_battery_charge": 86.09079149
  },
  {
    "timestamp": "08/09/24 19:00",
    "demand": 34429.55179,
    "solar_generated": 0,
    "wind_generated": 74.39179908,
    "marketplace_to_demand": 103.6269274,
    "grid_to_demand": 35034.35319,
    "govt_battery_charge": 93.2405324
  },
  {
    "timestamp": "08/09/24 20:00",
    "demand": 34080.24294,
    "solar_generated": 0,
    "wind_generated": 54.94038797,
    "marketplace_to_demand": 61.58544028,
    "grid_to_demand": 32719.74823,
    "govt_battery_charge": 74.52715256
  },
  {
    "timestamp": "08/09/24 21:00",
    "demand": 33306.73241,
    "solar_generated": 0,
    "wind_generated": 62.79515074,
    "marketplace_to_demand": 95.67547515,
    "grid_to_demand": 36499.23321,
    "govt_battery_charge": 96.25907392
  },
  {
    "timestamp": "08/09/24 22:00",
    "demand": 34989.44763,
    "solar_generated": 0,
    "wind_generated": 62.44463713,
    "marketplace_to_demand": 193.0467424,
    "grid_to_demand": 36533.95529,
    "govt_battery_charge": 56.15843607
  },
  {
    "timestamp": "08/09/24 23:00",
    "demand": 34675.03008,
    "solar_generated": 0,
    "wind_generated": 76.2216917,
    "marketplace_to_demand": 188.1917731,
    "grid_to_demand": 32113.00025,
    "govt_battery_charge": 23.62075903
  },
  {
    "timestamp": "09/09/24 0:00",
    "demand": 36431.81405,
    "solar_generated": 0,
    "wind_generated": 71.95870279,
    "marketplace_to_demand": 114.2553874,
    "grid_to_demand": 35778.71913,
    "govt_battery_charge": 29.62773289
  },
  {
    "timestamp": "09/09/24 1:00",
    "demand": 35030.7738,
    "solar_generated": 0,
    "wind_generated": 71.88896226,
    "marketplace_to_demand": 27.26284239,
    "grid_to_demand": 31908.02836,
    "govt_battery_charge": 8.443503464
  },
  {
    "timestamp": "09/09/24 2:00",
    "demand": 33287.16957,
    "solar_generated": 0,
    "wind_generated": 65.58795003,
    "marketplace_to_demand": 103.3083685,
    "grid_to_demand": 36273.6188,
    "govt_battery_charge": 11.85812863
  },
  {
    "timestamp": "09/09/24 3:00",
    "demand": 30896.13126,
    "solar_generated": 0,
    "wind_generated": 74.16876191,
    "marketplace_to_demand": 52.90945865,
    "grid_to_demand": 32568.33376,
    "govt_battery_charge": 75.1724382
  },
  {
    "timestamp": "09/09/24 4:00",
    "demand": 35767.24028,
    "solar_generated": 0,
    "wind_generated": 70.28360122,
    "marketplace_to_demand": 134.1559249,
    "grid_to_demand": 32440.68708,
    "govt_battery_charge": 97.86701553
  },
  {
    "timestamp": "09/09/24 5:00",
    "demand": 35018.54222,
    "solar_generated": 0,
    "wind_generated": 60.66670097,
    "marketplace_to_demand": 43.96089354,
    "grid_to_demand": 32486.28238,
    "govt_battery_charge": 96.137858
  },
  {
    "timestamp": "09/09/24 6:00",
    "demand": 30300.14689,
    "solar_generated": 18.27329976,
    "wind_generated": 60.65639254,
    "marketplace_to_demand": 177.1663051,
    "grid_to_demand": 32781.56875,
    "govt_battery_charge": 5.778501206
  },
  {
    "timestamp": "09/09/24 7:00",
    "demand": 34704.73059,
    "solar_generated": 20.26832823,
    "wind_generated": 69.87614803,
    "marketplace_to_demand": 50.2578997,
    "grid_to_demand": 32217.78578,
    "govt_battery_charge": 50.95203052
  },
  {
    "timestamp": "09/09/24 8:00",
    "demand": 30029.43143,
    "solar_generated": 11.14436159,
    "wind_generated": 74.83820314,
    "marketplace_to_demand": 156.4471368,
    "grid_to_demand": 36100.72777,
    "govt_battery_charge": 67.1479852
  },
  {
    "timestamp": "09/09/24 9:00",
    "demand": 34530.32472,
    "solar_generated": 21.13683234,
    "wind_generated": 57.39831409,
    "marketplace_to_demand": 68.4337461,
    "grid_to_demand": 31621.42049,
    "govt_battery_charge": 40.47405144
  },
  {
    "timestamp": "09/09/24 10:00",
    "demand": 36211.58954,
    "solar_generated": 12.7794798,
    "wind_generated": 62.34715589,
    "marketplace_to_demand": 116.7593406,
    "grid_to_demand": 33830.9358,
    "govt_battery_charge": 50.779562
  },
  {
    "timestamp": "09/09/24 11:00",
    "demand": 32824.75971,
    "solar_generated": 10.80794198,
    "wind_generated": 70.90824984,
    "marketplace_to_demand": 36.44681476,
    "grid_to_demand": 31426.16354,
    "govt_battery_charge": 88.55877532
  },
  {
    "timestamp": "09/09/24 12:00",
    "demand": 30719.98161,
    "solar_generated": 17.80426095,
    "wind_generated": 78.74192904,
    "marketplace_to_demand": 98.10862471,
    "grid_to_demand": 33665.55379,
    "govt_battery_charge": 19.25528109
  },
  {
    "timestamp": "09/09/24 13:00",
    "demand": 31551.48868,
    "solar_generated": 18.22590826,
    "wind_generated": 58.60561949,
    "marketplace_to_demand": 126.3119314,
    "grid_to_demand": 35105.83858,
    "govt_battery_charge": 53.68684943
  },
  {
    "timestamp": "09/09/24 14:00",
    "demand": 31494.00859,
    "solar_generated": 22.74929722,
    "wind_generated": 62.94318745,
    "marketplace_to_demand": 166.084908,
    "grid_to_demand": 35366.69894,
    "govt_battery_charge": 53.18602693
  },
  {
    "timestamp": "09/09/24 15:00",
    "demand": 33522.10549,
    "solar_generated": 14.83731834,
    "wind_generated": 74.65336029,
    "marketplace_to_demand": 167.6516408,
    "grid_to_demand": 32620.89751,
    "govt_battery_charge": 27.76764554
  },
  {
    "timestamp": "09/09/24 16:00",
    "demand": 36145.26857,
    "solar_generated": 23.02680363,
    "wind_generated": 74.3913421,
    "marketplace_to_demand": 166.9359401,
    "grid_to_demand": 31185.59046,
    "govt_battery_charge": 6.395622023
  },
  {
    "timestamp": "09/09/24 17:00",
    "demand": 35823.74189,
    "solar_generated": 21.68740529,
    "wind_generated": 71.04546633,
    "marketplace_to_demand": 162.9080087,
    "grid_to_demand": 36141.1044,
    "govt_battery_charge": 24.02200571
  },
  {
    "timestamp": "09/09/24 18:00",
    "demand": 31161.04713,
    "solar_generated": 0,
    "wind_generated": 56.25013192,
    "marketplace_to_demand": 139.2533194,
    "grid_to_demand": 32457.25923,
    "govt_battery_charge": 7.562372659
  },
  {
    "timestamp": "09/09/24 19:00",
    "demand": 33480.25981,
    "solar_generated": 0,
    "wind_generated": 78.06180064,
    "marketplace_to_demand": 11.96513868,
    "grid_to_demand": 35142.58414,
    "govt_battery_charge": 30.52854708
  },
  {
    "timestamp": "09/09/24 20:00",
    "demand": 34200.9487,
    "solar_generated": 0,
    "wind_generated": 63.07122593,
    "marketplace_to_demand": 127.5795468,
    "grid_to_demand": 35704.77762,
    "govt_battery_charge": 52.64283561
  },
  {
    "timestamp": "09/09/24 21:00",
    "demand": 34635.33025,
    "solar_generated": 0,
    "wind_generated": 67.93937591,
    "marketplace_to_demand": 159.1297926,
    "grid_to_demand": 30071.7743,
    "govt_battery_charge": 73.19919333
  },
  {
    "timestamp": "09/09/24 22:00",
    "demand": 31957.1135,
    "solar_generated": 0,
    "wind_generated": 75.36870953,
    "marketplace_to_demand": 48.02699067,
    "grid_to_demand": 32174.7016,
    "govt_battery_charge": 73.00161217
  },
  {
    "timestamp": "09/09/24 23:00",
    "demand": 31686.41301,
    "solar_generated": 0,
    "wind_generated": 66.53284682,
    "marketplace_to_demand": 182.5898154,
    "grid_to_demand": 31474.1074,
    "govt_battery_charge": 65.43150981
  },
  {
    "timestamp": "10/09/24 0:00",
    "demand": 36239.86863,
    "solar_generated": 0,
    "wind_generated": 71.95325226,
    "marketplace_to_demand": 37.80236866,
    "grid_to_demand": 31592.51395,
    "govt_battery_charge": 5.280731836
  },
  {
    "timestamp": "10/09/24 1:00",
    "demand": 36109.67297,
    "solar_generated": 0,
    "wind_generated": 60.57784751,
    "marketplace_to_demand": 120.9450829,
    "grid_to_demand": 31310.44325,
    "govt_battery_charge": 58.95896104
  },
  {
    "timestamp": "10/09/24 2:00",
    "demand": 34718.62778,
    "solar_generated": 0,
    "wind_generated": 58.10083914,
    "marketplace_to_demand": 22.44343587,
    "grid_to_demand": 35852.31786,
    "govt_battery_charge": 80.13309411
  },
  {
    "timestamp": "10/09/24 3:00",
    "demand": 32478.57605,
    "solar_generated": 0,
    "wind_generated": 64.38479473,
    "marketplace_to_demand": 119.5714926,
    "grid_to_demand": 30780.99839,
    "govt_battery_charge": 63.39518986
  },
  {
    "timestamp": "10/09/24 4:00",
    "demand": 31951.93941,
    "solar_generated": 0,
    "wind_generated": 74.8684834,
    "marketplace_to_demand": 99.13895213,
    "grid_to_demand": 32440.55567,
    "govt_battery_charge": 47.68472808
  },
  {
    "timestamp": "10/09/24 5:00",
    "demand": 35857.19748,
    "solar_generated": 0,
    "wind_generated": 72.67998035,
    "marketplace_to_demand": 155.8125417,
    "grid_to_demand": 36152.13234,
    "govt_battery_charge": 66.11348354
  },
  {
    "timestamp": "10/09/24 6:00",
    "demand": 30809.85784,
    "solar_generated": 8.673372772,
    "wind_generated": 54.74041181,
    "marketplace_to_demand": 70.74401842,
    "grid_to_demand": 36439.61173,
    "govt_battery_charge": 37.90790246
  },
  {
    "timestamp": "10/09/24 7:00",
    "demand": 35047.30851,
    "solar_generated": 15.08116881,
    "wind_generated": 59.56269141,
    "marketplace_to_demand": 4.954096094,
    "grid_to_demand": 31936.64982,
    "govt_battery_charge": 54.07977979
  },
  {
    "timestamp": "10/09/24 8:00",
    "demand": 30591.08831,
    "solar_generated": 18.01436574,
    "wind_generated": 60.27589301,
    "marketplace_to_demand": 100.1899653,
    "grid_to_demand": 34369.73688,
    "govt_battery_charge": 64.40839785
  },
  {
    "timestamp": "10/09/24 9:00",
    "demand": 30107.33135,
    "solar_generated": 20.61587911,
    "wind_generated": 76.14961145,
    "marketplace_to_demand": 113.0614826,
    "grid_to_demand": 32363.14298,
    "govt_battery_charge": 20.37416852
  },
  {
    "timestamp": "10/09/24 10:00",
    "demand": 33224.08683,
    "solar_generated": 7.477630474,
    "wind_generated": 64.55570221,
    "marketplace_to_demand": 91.49700872,
    "grid_to_demand": 34704.96191,
    "govt_battery_charge": 72.34711649
  },
  {
    "timestamp": "10/09/24 11:00",
    "demand": 36584.76941,
    "solar_generated": 16.48220515,
    "wind_generated": 72.93541308,
    "marketplace_to_demand": 115.4620908,
    "grid_to_demand": 33517.13863,
    "govt_battery_charge": 98.81976207
  },
  {
    "timestamp": "10/09/24 12:00",
    "demand": 34515.73221,
    "solar_generated": 16.23903536,
    "wind_generated": 55.76209052,
    "marketplace_to_demand": 38.87635811,
    "grid_to_demand": 33137.13806,
    "govt_battery_charge": 50.87938019
  },
  {
    "timestamp": "10/09/24 13:00",
    "demand": 31621.51455,
    "solar_generated": 11.76183256,
    "wind_generated": 73.30236959,
    "marketplace_to_demand": 66.05704578,
    "grid_to_demand": 31452.52115,
    "govt_battery_charge": 30.39069178
  },
  {
    "timestamp": "10/09/24 14:00",
    "demand": 32884.93569,
    "solar_generated": 7.847619098,
    "wind_generated": 54.47375358,
    "marketplace_to_demand": 199.7512399,
    "grid_to_demand": 35244.83604,
    "govt_battery_charge": 59.8750325
  },
  {
    "timestamp": "10/09/24 15:00",
    "demand": 33290.66105,
    "solar_generated": 19.41769771,
    "wind_generated": 68.7035287,
    "marketplace_to_demand": 45.30239962,
    "grid_to_demand": 35749.13234,
    "govt_battery_charge": 5.879756458
  },
  {
    "timestamp": "10/09/24 16:00",
    "demand": 34240.54331,
    "solar_generated": 19.41922119,
    "wind_generated": 53.64576056,
    "marketplace_to_demand": 45.69837902,
    "grid_to_demand": 33654.42171,
    "govt_battery_charge": 27.29922214
  },
  {
    "timestamp": "10/09/24 17:00",
    "demand": 34392.33709,
    "solar_generated": 20.99734439,
    "wind_generated": 75.32564207,
    "marketplace_to_demand": 78.75359297,
    "grid_to_demand": 32701.03151,
    "govt_battery_charge": 40.95453352
  },
  {
    "timestamp": "10/09/24 18:00",
    "demand": 36275.32894,
    "solar_generated": 0,
    "wind_generated": 59.41741942,
    "marketplace_to_demand": 18.25963173,
    "grid_to_demand": 35921.78391,
    "govt_battery_charge": 89.31193123
  },
  {
    "timestamp": "10/09/24 19:00",
    "demand": 35147.3115,
    "solar_generated": 0,
    "wind_generated": 74.09963506,
    "marketplace_to_demand": 177.8560317,
    "grid_to_demand": 30583.71347,
    "govt_battery_charge": 5.87887229
  },
  {
    "timestamp": "10/09/24 20:00",
    "demand": 35129.9735,
    "solar_generated": 0,
    "wind_generated": 58.2812324,
    "marketplace_to_demand": 76.84731554,
    "grid_to_demand": 36088.51336,
    "govt_battery_charge": 80.55026944
  },
  {
    "timestamp": "10/09/24 21:00",
    "demand": 36568.0485,
    "solar_generated": 0,
    "wind_generated": 53.98210528,
    "marketplace_to_demand": 22.03497344,
    "grid_to_demand": 35794.09439,
    "govt_battery_charge": 78.05983157
  },
  {
    "timestamp": "10/09/24 22:00",
    "demand": 31097.07571,
    "solar_generated": 0,
    "wind_generated": 54.13474437,
    "marketplace_to_demand": 1.634331975,
    "grid_to_demand": 31016.77696,
    "govt_battery_charge": 88.53837484
  },
  {
    "timestamp": "10/09/24 23:00",
    "demand": 30909.27816,
    "solar_generated": 0,
    "wind_generated": 68.91176,
    "marketplace_to_demand": 132.3088905,
    "grid_to_demand": 30576.73254,
    "govt_battery_charge": 91.20996283
  },
  {
    "timestamp": "11/09/24 0:00",
    "demand": 32088.91455,
    "solar_generated": 0,
    "wind_generated": 54.06873661,
    "marketplace_to_demand": 10.80316497,
    "grid_to_demand": 32670.76657,
    "govt_battery_charge": 31.33858988
  },
  {
    "timestamp": "11/09/24 1:00",
    "demand": 32756.29487,
    "solar_generated": 0,
    "wind_generated": 60.85748374,
    "marketplace_to_demand": 72.13649873,
    "grid_to_demand": 33194.40877,
    "govt_battery_charge": 21.89062351
  },
  {
    "timestamp": "11/09/24 2:00",
    "demand": 32634.68723,
    "solar_generated": 0,
    "wind_generated": 64.70071419,
    "marketplace_to_demand": 1.013728174,
    "grid_to_demand": 36439.43226,
    "govt_battery_charge": 31.60191956
  },
  {
    "timestamp": "11/09/24 3:00",
    "demand": 32414.47836,
    "solar_generated": 0,
    "wind_generated": 66.08995978,
    "marketplace_to_demand": 115.7988633,
    "grid_to_demand": 33981.65016,
    "govt_battery_charge": 75.38087254
  },
  {
    "timestamp": "11/09/24 4:00",
    "demand": 36122.84503,
    "solar_generated": 0,
    "wind_generated": 76.70250733,
    "marketplace_to_demand": 52.62037245,
    "grid_to_demand": 34273.0603,
    "govt_battery_charge": 22.95877567
  },
  {
    "timestamp": "11/09/24 5:00",
    "demand": 30662.67025,
    "solar_generated": 0,
    "wind_generated": 76.63674499,
    "marketplace_to_demand": 12.20559498,
    "grid_to_demand": 36543.30729,
    "govt_battery_charge": 78.84006806
  },
  {
    "timestamp": "11/09/24 6:00",
    "demand": 35147.25421,
    "solar_generated": 21.2540372,
    "wind_generated": 72.75386964,
    "marketplace_to_demand": 55.48975285,
    "grid_to_demand": 32654.90579,
    "govt_battery_charge": 35.11748266
  },
  {
    "timestamp": "11/09/24 7:00",
    "demand": 31775.98587,
    "solar_generated": 22.66136878,
    "wind_generated": 71.25707065,
    "marketplace_to_demand": 144.1935299,
    "grid_to_demand": 35562.95253,
    "govt_battery_charge": 89.95943477
  },
  {
    "timestamp": "11/09/24 8:00",
    "demand": 35767.038,
    "solar_generated": 8.597319829,
    "wind_generated": 61.16401466,
    "marketplace_to_demand": 146.151409,
    "grid_to_demand": 33032.45002,
    "govt_battery_charge": 68.20596389
  },
  {
    "timestamp": "11/09/24 9:00",
    "demand": 33810.88865,
    "solar_generated": 16.38227439,
    "wind_generated": 66.22692861,
    "marketplace_to_demand": 95.11023837,
    "grid_to_demand": 33470.2075,
    "govt_battery_charge": 9.765736343
  },
  {
    "timestamp": "11/09/24 10:00",
    "demand": 31688.77938,
    "solar_generated": 7.114408485,
    "wind_generated": 69.45667685,
    "marketplace_to_demand": 57.27324534,
    "grid_to_demand": 31054.53693,
    "govt_battery_charge": 37.48263183
  },
  {
    "timestamp": "11/09/24 11:00",
    "demand": 34090.11249,
    "solar_generated": 17.96720979,
    "wind_generated": 67.38299451,
    "marketplace_to_demand": 102.9861143,
    "grid_to_demand": 31297.05589,
    "govt_battery_charge": 75.5760682
  },
  {
    "timestamp": "11/09/24 12:00",
    "demand": 33674.5202,
    "solar_generated": 13.71933665,
    "wind_generated": 56.20519819,
    "marketplace_to_demand": 130.3557876,
    "grid_to_demand": 33501.2854,
    "govt_battery_charge": 50.29347623
  },
  {
    "timestamp": "11/09/24 13:00",
    "demand": 34672.10003,
    "solar_generated": 11.66013,
    "wind_generated": 54.47088255,
    "marketplace_to_demand": 75.64106194,
    "grid_to_demand": 33607.57608,
    "govt_battery_charge": 86.4816166
  },
  {
    "timestamp": "11/09/24 14:00",
    "demand": 30176.2468,
    "solar_generated": 16.94924754,
    "wind_generated": 73.10420165,
    "marketplace_to_demand": 176.0791892,
    "grid_to_demand": 31490.35841,
    "govt_battery_charge": 24.89765131
  },
  {
    "timestamp": "11/09/24 15:00",
    "demand": 33382.76806,
    "solar_generated": 15.53743306,
    "wind_generated": 59.36088292,
    "marketplace_to_demand": 67.98311785,
    "grid_to_demand": 32093.43896,
    "govt_battery_charge": 74.6596726
  },
  {
    "timestamp": "11/09/24 16:00",
    "demand": 31855.21212,
    "solar_generated": 19.66952883,
    "wind_generated": 53.74207959,
    "marketplace_to_demand": 64.53740025,
    "grid_to_demand": 34504.00007,
    "govt_battery_charge": 93.70354806
  },
  {
    "timestamp": "11/09/24 17:00",
    "demand": 33253.50756,
    "solar_generated": 19.45038122,
    "wind_generated": 72.61819948,
    "marketplace_to_demand": 135.5598978,
    "grid_to_demand": 34251.90139,
    "govt_battery_charge": 54.02231442
  },
  {
    "timestamp": "11/09/24 18:00",
    "demand": 31344.8834,
    "solar_generated": 0,
    "wind_generated": 68.00561597,
    "marketplace_to_demand": 150.623182,
    "grid_to_demand": 33839.26037,
    "govt_battery_charge": 45.33342231
  },
  {
    "timestamp": "11/09/24 19:00",
    "demand": 30790.13816,
    "solar_generated": 0,
    "wind_generated": 68.51211004,
    "marketplace_to_demand": 39.57058116,
    "grid_to_demand": 30678.76187,
    "govt_battery_charge": 47.80042403
  },
  {
    "timestamp": "11/09/24 20:00",
    "demand": 36433.19019,
    "solar_generated": 0,
    "wind_generated": 80.16083999,
    "marketplace_to_demand": 66.66831256,
    "grid_to_demand": 32241.03968,
    "govt_battery_charge": 5.067371912
  },
  {
    "timestamp": "11/09/24 21:00",
    "demand": 36265.98783,
    "solar_generated": 0,
    "wind_generated": 61.30514948,
    "marketplace_to_demand": 135.1863589,
    "grid_to_demand": 36092.87666,
    "govt_battery_charge": 56.8011283
  },
  {
    "timestamp": "11/09/24 22:00",
    "demand": 30877.43127,
    "solar_generated": 0,
    "wind_generated": 60.5193237,
    "marketplace_to_demand": 103.8098503,
    "grid_to_demand": 36551.23247,
    "govt_battery_charge": 49.44871024
  },
  {
    "timestamp": "11/09/24 23:00",
    "demand": 32051.95356,
    "solar_generated": 0,
    "wind_generated": 55.59708013,
    "marketplace_to_demand": 117.221527,
    "grid_to_demand": 31493.64649,
    "govt_battery_charge": 92.19572513
  },
  {
    "timestamp": "12/09/24 0:00",
    "demand": 32597.13837,
    "solar_generated": 0,
    "wind_generated": 53.63998842,
    "marketplace_to_demand": 103.5401241,
    "grid_to_demand": 32227.89872,
    "govt_battery_charge": 88.26429882
  },
  {
    "timestamp": "12/09/24 1:00",
    "demand": 30891.85806,
    "solar_generated": 0,
    "wind_generated": 67.29599354,
    "marketplace_to_demand": 83.19104075,
    "grid_to_demand": 35169.09153,
    "govt_battery_charge": 21.46872711
  },
  {
    "timestamp": "12/09/24 2:00",
    "demand": 32719.21553,
    "solar_generated": 0,
    "wind_generated": 78.02375915,
    "marketplace_to_demand": 131.2839464,
    "grid_to_demand": 33485.84891,
    "govt_battery_charge": 31.86307447
  },
  {
    "timestamp": "12/09/24 3:00",
    "demand": 30741.6427,
    "solar_generated": 0,
    "wind_generated": 62.5946108,
    "marketplace_to_demand": 145.3434454,
    "grid_to_demand": 33551.56536,
    "govt_battery_charge": 95.2114538
  },
  {
    "timestamp": "12/09/24 4:00",
    "demand": 32467.99691,
    "solar_generated": 0,
    "wind_generated": 79.17962034,
    "marketplace_to_demand": 64.94246772,
    "grid_to_demand": 33992.61633,
    "govt_battery_charge": 17.8212033
  },
  {
    "timestamp": "12/09/24 5:00",
    "demand": 33067.07102,
    "solar_generated": 0,
    "wind_generated": 77.1273723,
    "marketplace_to_demand": 192.7078155,
    "grid_to_demand": 32221.98608,
    "govt_battery_charge": 23.93757938
  },
  {
    "timestamp": "12/09/24 6:00",
    "demand": 35630.35812,
    "solar_generated": 17.26459442,
    "wind_generated": 70.43886082,
    "marketplace_to_demand": 86.6069275,
    "grid_to_demand": 32780.84247,
    "govt_battery_charge": 82.91130593
  },
  {
    "timestamp": "12/09/24 7:00",
    "demand": 34550.17954,
    "solar_generated": 16.43029686,
    "wind_generated": 69.38077004,
    "marketplace_to_demand": 76.51975569,
    "grid_to_demand": 30465.26199,
    "govt_battery_charge": 98.15788887
  },
  {
    "timestamp": "12/09/24 8:00",
    "demand": 31974.99605,
    "solar_generated": 16.29908026,
    "wind_generated": 56.99369504,
    "marketplace_to_demand": 132.8519713,
    "grid_to_demand": 33554.79617,
    "govt_battery_charge": 46.21321705
  },
  {
    "timestamp": "12/09/24 9:00",
    "demand": 34731.42634,
    "solar_generated": 14.59197684,
    "wind_generated": 59.46348535,
    "marketplace_to_demand": 26.80891679,
    "grid_to_demand": 36256.74435,
    "govt_battery_charge": 69.17943403
  },
  {
    "timestamp": "12/09/24 10:00",
    "demand": 35820.41634,
    "solar_generated": 15.75809186,
    "wind_generated": 78.42217516,
    "marketplace_to_demand": 50.5708293,
    "grid_to_demand": 33830.60822,
    "govt_battery_charge": 82.08074542
  },
  {
    "timestamp": "12/09/24 11:00",
    "demand": 30650.07572,
    "solar_generated": 7.511291468,
    "wind_generated": 67.5136449,
    "marketplace_to_demand": 134.5298418,
    "grid_to_demand": 32564.81496,
    "govt_battery_charge": 25.6267579
  },
  {
    "timestamp": "12/09/24 12:00",
    "demand": 35322.321,
    "solar_generated": 13.04852989,
    "wind_generated": 58.75884009,
    "marketplace_to_demand": 118.2618102,
    "grid_to_demand": 30173.21234,
    "govt_battery_charge": 31.36631918
  },
  {
    "timestamp": "12/09/24 13:00",
    "demand": 32160.85594,
    "solar_generated": 16.25040156,
    "wind_generated": 53.75863736,
    "marketplace_to_demand": 117.4512769,
    "grid_to_demand": 33569.01939,
    "govt_battery_charge": 98.50255713
  },
  {
    "timestamp": "12/09/24 14:00",
    "demand": 35291.839,
    "solar_generated": 11.48006112,
    "wind_generated": 74.14988578,
    "marketplace_to_demand": 96.8783794,
    "grid_to_demand": 30842.44258,
    "govt_battery_charge": 39.73926139
  },
  {
    "timestamp": "12/09/24 15:00",
    "demand": 33371.05316,
    "solar_generated": 21.22435735,
    "wind_generated": 75.67719672,
    "marketplace_to_demand": 159.5167949,
    "grid_to_demand": 35738.30046,
    "govt_battery_charge": 3.468853016
  },
  {
    "timestamp": "12/09/24 16:00",
    "demand": 30473.17678,
    "solar_generated": 11.54962448,
    "wind_generated": 79.08415643,
    "marketplace_to_demand": 46.81713333,
    "grid_to_demand": 36266.52207,
    "govt_battery_charge": 19.67218153
  },
  {
    "timestamp": "12/09/24 17:00",
    "demand": 35357.79985,
    "solar_generated": 7.593657583,
    "wind_generated": 73.82938754,
    "marketplace_to_demand": 18.88123076,
    "grid_to_demand": 32465.33169,
    "govt_battery_charge": 93.70767883
  },
  {
    "timestamp": "12/09/24 18:00",
    "demand": 32426.2522,
    "solar_generated": 0,
    "wind_generated": 77.90352603,
    "marketplace_to_demand": 155.1402339,
    "grid_to_demand": 35696.54799,
    "govt_battery_charge": 16.46303388
  },
  {
    "timestamp": "12/09/24 19:00",
    "demand": 36080.10383,
    "solar_generated": 0,
    "wind_generated": 71.94608321,
    "marketplace_to_demand": 43.50046532,
    "grid_to_demand": 36088.93247,
    "govt_battery_charge": 13.14893305
  },
  {
    "timestamp": "12/09/24 20:00",
    "demand": 33749.81603,
    "solar_generated": 0,
    "wind_generated": 76.07059867,
    "marketplace_to_demand": 137.0463102,
    "grid_to_demand": 34363.86321,
    "govt_battery_charge": 70.21917186
  },
  {
    "timestamp": "12/09/24 21:00",
    "demand": 30122.71481,
    "solar_generated": 0,
    "wind_generated": 76.33587891,
    "marketplace_to_demand": 105.2367224,
    "grid_to_demand": 32419.64657,
    "govt_battery_charge": 27.09774249
  },
  {
    "timestamp": "12/09/24 22:00",
    "demand": 34548.96177,
    "solar_generated": 0,
    "wind_generated": 54.02176831,
    "marketplace_to_demand": 3.689922934,
    "grid_to_demand": 33618.36637,
    "govt_battery_charge": 17.14867729
  },
  {
    "timestamp": "12/09/24 23:00",
    "demand": 31983.6858,
    "solar_generated": 0,
    "wind_generated": 74.94145294,
    "marketplace_to_demand": 54.86435808,
    "grid_to_demand": 35455.09728,
    "govt_battery_charge": 29.735195
  },
  {
    "timestamp": "13/09/24 0:00",
    "demand": 30140.14891,
    "solar_generated": 0,
    "wind_generated": 54.66188622,
    "marketplace_to_demand": 63.96800559,
    "grid_to_demand": 31132.14871,
    "govt_battery_charge": 78.45530341
  },
  {
    "timestamp": "13/09/24 1:00",
    "demand": 33732.66434,
    "solar_generated": 0,
    "wind_generated": 74.39984943,
    "marketplace_to_demand": 145.1404332,
    "grid_to_demand": 32142.07388,
    "govt_battery_charge": 76.46763796
  },
  {
    "timestamp": "13/09/24 2:00",
    "demand": 34810.69547,
    "solar_generated": 0,
    "wind_generated": 55.16842637,
    "marketplace_to_demand": 68.76228374,
    "grid_to_demand": 35325.98139,
    "govt_battery_charge": 18.71492952
  },
  {
    "timestamp": "13/09/24 3:00",
    "demand": 30522.8917,
    "solar_generated": 0,
    "wind_generated": 75.6758488,
    "marketplace_to_demand": 39.24825745,
    "grid_to_demand": 29924.97497,
    "govt_battery_charge": 12.42193051
  },
  {
    "timestamp": "13/09/24 4:00",
    "demand": 30639.57954,
    "solar_generated": 0,
    "wind_generated": 78.47355238,
    "marketplace_to_demand": 60.550391,
    "grid_to_demand": 33778.78179,
    "govt_battery_charge": 41.37028517
  },
  {
    "timestamp": "13/09/24 5:00",
    "demand": 34389.76996,
    "solar_generated": 0,
    "wind_generated": 64.83617659,
    "marketplace_to_demand": 60.68917382,
    "grid_to_demand": 30761.99756,
    "govt_battery_charge": 13.68271202
  },
  {
    "timestamp": "13/09/24 6:00",
    "demand": 33214.68266,
    "solar_generated": 19.79488353,
    "wind_generated": 64.99354071,
    "marketplace_to_demand": 124.7556129,
    "grid_to_demand": 31225.092,
    "govt_battery_charge": 16.92760576
  },
  {
    "timestamp": "13/09/24 7:00",
    "demand": 32512.32136,
    "solar_generated": 22.31132242,
    "wind_generated": 58.82680242,
    "marketplace_to_demand": 119.477096,
    "grid_to_demand": 35586.78368,
    "govt_battery_charge": 41.19577321
  },
  {
    "timestamp": "13/09/24 8:00",
    "demand": 35404.56469,
    "solar_generated": 15.37246503,
    "wind_generated": 80.03633885,
    "marketplace_to_demand": 1.866053579,
    "grid_to_demand": 31291.98154,
    "govt_battery_charge": 69.23315627
  },
  {
    "timestamp": "13/09/24 9:00",
    "demand": 36054.74369,
    "solar_generated": 20.04500862,
    "wind_generated": 60.3268682,
    "marketplace_to_demand": 93.89233665,
    "grid_to_demand": 34205.71135,
    "govt_battery_charge": 13.91831674
  },
  {
    "timestamp": "13/09/24 10:00",
    "demand": 31656.36626,
    "solar_generated": 15.71370897,
    "wind_generated": 63.4600197,
    "marketplace_to_demand": 93.77858758,
    "grid_to_demand": 30655.68875,
    "govt_battery_charge": 93.59277174
  },
  {
    "timestamp": "13/09/24 11:00",
    "demand": 31914.5028,
    "solar_generated": 19.42880754,
    "wind_generated": 80.05360662,
    "marketplace_to_demand": 81.06013374,
    "grid_to_demand": 31683.00373,
    "govt_battery_charge": 5.926859338
  },
  {
    "timestamp": "13/09/24 12:00",
    "demand": 35469.04011,
    "solar_generated": 7.617771481,
    "wind_generated": 63.06219031,
    "marketplace_to_demand": 27.58788332,
    "grid_to_demand": 36366.75105,
    "govt_battery_charge": 11.60070619
  },
  {
    "timestamp": "13/09/24 13:00",
    "demand": 35759.77733,
    "solar_generated": 8.759478428,
    "wind_generated": 62.81267937,
    "marketplace_to_demand": 109.3955707,
    "grid_to_demand": 33358.03349,
    "govt_battery_charge": 13.31967248
  },
  {
    "timestamp": "13/09/24 14:00",
    "demand": 30904.15523,
    "solar_generated": 8.838749242,
    "wind_generated": 64.02576353,
    "marketplace_to_demand": 187.4264504,
    "grid_to_demand": 32452.8168,
    "govt_battery_charge": 53.48622736
  },
  {
    "timestamp": "13/09/24 15:00",
    "demand": 32365.50959,
    "solar_generated": 15.26224668,
    "wind_generated": 53.87549618,
    "marketplace_to_demand": 116.9044299,
    "grid_to_demand": 29959.46064,
    "govt_battery_charge": 79.92305782
  },
  {
    "timestamp": "13/09/24 16:00",
    "demand": 30342.67154,
    "solar_generated": 22.8517058,
    "wind_generated": 63.56812401,
    "marketplace_to_demand": 4.114375957,
    "grid_to_demand": 32272.29537,
    "govt_battery_charge": 58.89689817
  },
  {
    "timestamp": "13/09/24 17:00",
    "demand": 35929.16376,
    "solar_generated": 6.540247348,
    "wind_generated": 56.06457983,
    "marketplace_to_demand": 40.31912582,
    "grid_to_demand": 34677.83874,
    "govt_battery_charge": 47.16330132
  },
  {
    "timestamp": "13/09/24 18:00",
    "demand": 32934.18053,
    "solar_generated": 0,
    "wind_generated": 59.74075624,
    "marketplace_to_demand": 46.7679424,
    "grid_to_demand": 29921.30349,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "13/09/24 19:00",
    "demand": 33554.64288,
    "solar_generated": 0,
    "wind_generated": 61.57283462,
    "marketplace_to_demand": 128.6490219,
    "grid_to_demand": 32274.10886,
    "govt_battery_charge": 2.105028297
  },
  {
    "timestamp": "13/09/24 20:00",
    "demand": 35508.453,
    "solar_generated": 0,
    "wind_generated": 76.67420858,
    "marketplace_to_demand": 122.2535476,
    "grid_to_demand": 33750.94139,
    "govt_battery_charge": 29.96894502
  },
  {
    "timestamp": "13/09/24 21:00",
    "demand": 31050.53278,
    "solar_generated": 0,
    "wind_generated": 56.61251215,
    "marketplace_to_demand": 147.788945,
    "grid_to_demand": 31702.44689,
    "govt_battery_charge": 21.27917174
  },
  {
    "timestamp": "13/09/24 22:00",
    "demand": 35415.39821,
    "solar_generated": 0,
    "wind_generated": 71.04884623,
    "marketplace_to_demand": 155.3479488,
    "grid_to_demand": 33835.14001,
    "govt_battery_charge": 4.627531733
  },
  {
    "timestamp": "13/09/24 23:00",
    "demand": 35868.13898,
    "solar_generated": 0,
    "wind_generated": 54.39415917,
    "marketplace_to_demand": 77.36299658,
    "grid_to_demand": 35808.19545,
    "govt_battery_charge": 8.344578144
  },
  {
    "timestamp": "14/09/24 0:00",
    "demand": 31311.99283,
    "solar_generated": 0,
    "wind_generated": 57.59270502,
    "marketplace_to_demand": 103.1759671,
    "grid_to_demand": 34579.42722,
    "govt_battery_charge": 89.6758156
  },
  {
    "timestamp": "14/09/24 1:00",
    "demand": 34098.23186,
    "solar_generated": 0,
    "wind_generated": 71.42296004,
    "marketplace_to_demand": 107.1236022,
    "grid_to_demand": 30606.24109,
    "govt_battery_charge": 80.01057099
  },
  {
    "timestamp": "14/09/24 2:00",
    "demand": 36121.78948,
    "solar_generated": 0,
    "wind_generated": 68.30807348,
    "marketplace_to_demand": 49.36709254,
    "grid_to_demand": 32647.29855,
    "govt_battery_charge": 78.50501486
  },
  {
    "timestamp": "14/09/24 3:00",
    "demand": 30584.92187,
    "solar_generated": 0,
    "wind_generated": 74.90268384,
    "marketplace_to_demand": 89.66943428,
    "grid_to_demand": 35957.76521,
    "govt_battery_charge": 19.21391189
  },
  {
    "timestamp": "14/09/24 4:00",
    "demand": 31634.38554,
    "solar_generated": 0,
    "wind_generated": 65.36523143,
    "marketplace_to_demand": 114.8366436,
    "grid_to_demand": 34461.13958,
    "govt_battery_charge": 88.19061279
  },
  {
    "timestamp": "14/09/24 5:00",
    "demand": 35355.93416,
    "solar_generated": 0,
    "wind_generated": 73.57161443,
    "marketplace_to_demand": 88.03163216,
    "grid_to_demand": 36494.53186,
    "govt_battery_charge": 54.52147637
  },
  {
    "timestamp": "14/09/24 6:00",
    "demand": 36098.56318,
    "solar_generated": 23.78310164,
    "wind_generated": 70.98614407,
    "marketplace_to_demand": 98.87739704,
    "grid_to_demand": 34352.05684,
    "govt_battery_charge": 70.30343052
  },
  {
    "timestamp": "14/09/24 7:00",
    "demand": 33728.51036,
    "solar_generated": 13.38463877,
    "wind_generated": 55.55250279,
    "marketplace_to_demand": 115.1968645,
    "grid_to_demand": 30071.34316,
    "govt_battery_charge": 21.23952849
  },
  {
    "timestamp": "14/09/24 8:00",
    "demand": 34705.03526,
    "solar_generated": 10.30404017,
    "wind_generated": 71.43648803,
    "marketplace_to_demand": 93.21073849,
    "grid_to_demand": 31650.21554,
    "govt_battery_charge": 85.97783477
  },
  {
    "timestamp": "14/09/24 9:00",
    "demand": 32471.58744,
    "solar_generated": 13.38093211,
    "wind_generated": 73.91124116,
    "marketplace_to_demand": 69.93896855,
    "grid_to_demand": 35578.42921,
    "govt_battery_charge": 11.72692318
  },
  {
    "timestamp": "14/09/24 10:00",
    "demand": 36602.57991,
    "solar_generated": 18.41100749,
    "wind_generated": 71.53737729,
    "marketplace_to_demand": 19.81451877,
    "grid_to_demand": 34485.94208,
    "govt_battery_charge": 20.65701462
  },
  {
    "timestamp": "14/09/24 11:00",
    "demand": 33720.71449,
    "solar_generated": 6.754442948,
    "wind_generated": 66.38354717,
    "marketplace_to_demand": 116.6302301,
    "grid_to_demand": 32649.7625,
    "govt_battery_charge": 71.97112721
  },
  {
    "timestamp": "14/09/24 12:00",
    "demand": 32897.75034,
    "solar_generated": 17.74785505,
    "wind_generated": 60.96909219,
    "marketplace_to_demand": 194.183034,
    "grid_to_demand": 31581.56911,
    "govt_battery_charge": 84.46546699
  },
  {
    "timestamp": "14/09/24 13:00",
    "demand": 32982.40879,
    "solar_generated": 6.323888533,
    "wind_generated": 72.37002783,
    "marketplace_to_demand": 161.6700702,
    "grid_to_demand": 30195.44046,
    "govt_battery_charge": 42.12826503
  },
  {
    "timestamp": "14/09/24 14:00",
    "demand": 30823.74279,
    "solar_generated": 9.33417211,
    "wind_generated": 55.94721705,
    "marketplace_to_demand": 76.12812792,
    "grid_to_demand": 30666.9556,
    "govt_battery_charge": 64.2555057
  },
  {
    "timestamp": "14/09/24 15:00",
    "demand": 32555.1418,
    "solar_generated": 7.391030085,
    "wind_generated": 58.63482046,
    "marketplace_to_demand": 10.46975325,
    "grid_to_demand": 33897.81193,
    "govt_battery_charge": 58.22406274
  },
  {
    "timestamp": "14/09/24 16:00",
    "demand": 32532.46332,
    "solar_generated": 10.28802504,
    "wind_generated": 60.95142722,
    "marketplace_to_demand": 138.1646022,
    "grid_to_demand": 35396.02333,
    "govt_battery_charge": 64.28577602
  },
  {
    "timestamp": "14/09/24 17:00",
    "demand": 34267.86659,
    "solar_generated": 23.47499009,
    "wind_generated": 60.10134995,
    "marketplace_to_demand": 124.5250887,
    "grid_to_demand": 33321.55128,
    "govt_battery_charge": 87.38477119
  },
  {
    "timestamp": "14/09/24 18:00",
    "demand": 32496.53121,
    "solar_generated": 0,
    "wind_generated": 56.8097978,
    "marketplace_to_demand": 130.4136368,
    "grid_to_demand": 34133.83693,
    "govt_battery_charge": 46.58641441
  },
  {
    "timestamp": "14/09/24 19:00",
    "demand": 33912.23535,
    "solar_generated": 0,
    "wind_generated": 60.38800852,
    "marketplace_to_demand": 185.1581856,
    "grid_to_demand": 33223.13237,
    "govt_battery_charge": 97.98309174
  },
  {
    "timestamp": "14/09/24 20:00",
    "demand": 31074.95244,
    "solar_generated": 0,
    "wind_generated": 60.82833017,
    "marketplace_to_demand": 146.1591859,
    "grid_to_demand": 34603.48791,
    "govt_battery_charge": 88.12084056
  },
  {
    "timestamp": "14/09/24 21:00",
    "demand": 30473.37452,
    "solar_generated": 0,
    "wind_generated": 58.65922408,
    "marketplace_to_demand": 160.9971623,
    "grid_to_demand": 31151.03739,
    "govt_battery_charge": 61.95999314
  },
  {
    "timestamp": "14/09/24 22:00",
    "demand": 35686.23149,
    "solar_generated": 0,
    "wind_generated": 73.77784971,
    "marketplace_to_demand": 28.77092085,
    "grid_to_demand": 32192.58086,
    "govt_battery_charge": 37.75262643
  },
  {
    "timestamp": "14/09/24 23:00",
    "demand": 31863.0853,
    "solar_generated": 0,
    "wind_generated": 63.2887209,
    "marketplace_to_demand": 112.439754,
    "grid_to_demand": 35086.59313,
    "govt_battery_charge": 6.665088367
  },
  {
    "timestamp": "15/09/24 0:00",
    "demand": 31969.07566,
    "solar_generated": 0,
    "wind_generated": 78.42459509,
    "marketplace_to_demand": 108.490708,
    "grid_to_demand": 34377.45899,
    "govt_battery_charge": 49.47583239
  },
  {
    "timestamp": "15/09/24 1:00",
    "demand": 31756.6183,
    "solar_generated": 0,
    "wind_generated": 55.02801054,
    "marketplace_to_demand": 143.6324282,
    "grid_to_demand": 33625.64064,
    "govt_battery_charge": 11.37927531
  },
  {
    "timestamp": "15/09/24 2:00",
    "demand": 30462.29576,
    "solar_generated": 0,
    "wind_generated": 71.62011468,
    "marketplace_to_demand": 96.95023689,
    "grid_to_demand": 30446.61924,
    "govt_battery_charge": 28.46117968
  },
  {
    "timestamp": "15/09/24 3:00",
    "demand": 35461.63392,
    "solar_generated": 0,
    "wind_generated": 59.35684168,
    "marketplace_to_demand": 136.1689858,
    "grid_to_demand": 35785.7911,
    "govt_battery_charge": 98.89569772
  },
  {
    "timestamp": "15/09/24 4:00",
    "demand": 32219.13432,
    "solar_generated": 0,
    "wind_generated": 71.92841655,
    "marketplace_to_demand": 166.7588065,
    "grid_to_demand": 31459.10605,
    "govt_battery_charge": 53.4307067
  },
  {
    "timestamp": "15/09/24 5:00",
    "demand": 32726.31085,
    "solar_generated": 0,
    "wind_generated": 56.05166731,
    "marketplace_to_demand": 60.70899474,
    "grid_to_demand": 32236.53563,
    "govt_battery_charge": 90.5945684
  },
  {
    "timestamp": "15/09/24 6:00",
    "demand": 34891.94318,
    "solar_generated": 16.49155604,
    "wind_generated": 67.6410027,
    "marketplace_to_demand": 144.6367566,
    "grid_to_demand": 35153.29853,
    "govt_battery_charge": 65.11878516
  },
  {
    "timestamp": "15/09/24 7:00",
    "demand": 30794.34741,
    "solar_generated": 19.3010487,
    "wind_generated": 79.01404531,
    "marketplace_to_demand": 72.41578019,
    "grid_to_demand": 36468.34532,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "15/09/24 8:00",
    "demand": 30123.39978,
    "solar_generated": 13.59561813,
    "wind_generated": 56.48809664,
    "marketplace_to_demand": 165.9076562,
    "grid_to_demand": 35452.65159,
    "govt_battery_charge": 53.43916732
  },
  {
    "timestamp": "15/09/24 9:00",
    "demand": 32492.71805,
    "solar_generated": 14.25384761,
    "wind_generated": 54.99510735,
    "marketplace_to_demand": 32.32240489,
    "grid_to_demand": 33824.77507,
    "govt_battery_charge": 78.98407954
  },
  {
    "timestamp": "15/09/24 10:00",
    "demand": 34887.24983,
    "solar_generated": 19.23891423,
    "wind_generated": 58.28758117,
    "marketplace_to_demand": 0.950830118,
    "grid_to_demand": 32385.68327,
    "govt_battery_charge": 34.61842941
  },
  {
    "timestamp": "15/09/24 11:00",
    "demand": 31913.7513,
    "solar_generated": 6.783043926,
    "wind_generated": 58.0620585,
    "marketplace_to_demand": 131.6343518,
    "grid_to_demand": 35729.95091,
    "govt_battery_charge": 49.34026966
  },
  {
    "timestamp": "15/09/24 12:00",
    "demand": 31002.63164,
    "solar_generated": 14.02918127,
    "wind_generated": 61.15768376,
    "marketplace_to_demand": 93.42845073,
    "grid_to_demand": 31232.94652,
    "govt_battery_charge": 94.2678326
  },
  {
    "timestamp": "15/09/24 13:00",
    "demand": 36125.59563,
    "solar_generated": 19.32781228,
    "wind_generated": 73.74017253,
    "marketplace_to_demand": 73.21294027,
    "grid_to_demand": 33996.143,
    "govt_battery_charge": 40.97268709
  },
  {
    "timestamp": "15/09/24 14:00",
    "demand": 32672.06597,
    "solar_generated": 19.11698495,
    "wind_generated": 67.59039029,
    "marketplace_to_demand": 108.2650768,
    "grid_to_demand": 31331.87734,
    "govt_battery_charge": 7.560161496
  },
  {
    "timestamp": "15/09/24 15:00",
    "demand": 31996.75932,
    "solar_generated": 21.8259911,
    "wind_generated": 66.59939212,
    "marketplace_to_demand": 54.52897473,
    "grid_to_demand": 32265.90593,
    "govt_battery_charge": 92.97648749
  },
  {
    "timestamp": "15/09/24 16:00",
    "demand": 35982.54557,
    "solar_generated": 18.19762011,
    "wind_generated": 65.92197315,
    "marketplace_to_demand": 6.748130443,
    "grid_to_demand": 35329.57782,
    "govt_battery_charge": 92.25912612
  },
  {
    "timestamp": "15/09/24 17:00",
    "demand": 30907.79554,
    "solar_generated": 20.61924205,
    "wind_generated": 60.99158333,
    "marketplace_to_demand": 26.55298159,
    "grid_to_demand": 35930.16979,
    "govt_battery_charge": 47.11525868
  },
  {
    "timestamp": "15/09/24 18:00",
    "demand": 33115.16071,
    "solar_generated": 0,
    "wind_generated": 68.89904506,
    "marketplace_to_demand": 106.3594176,
    "grid_to_demand": 34382.7575,
    "govt_battery_charge": 61.88087742
  },
  {
    "timestamp": "15/09/24 19:00",
    "demand": 35540.33031,
    "solar_generated": 0,
    "wind_generated": 59.98304854,
    "marketplace_to_demand": 159.7450991,
    "grid_to_demand": 33116.90479,
    "govt_battery_charge": 66.09900497
  },
  {
    "timestamp": "15/09/24 20:00",
    "demand": 31794.83083,
    "solar_generated": 0,
    "wind_generated": 71.12163634,
    "marketplace_to_demand": 23.82251032,
    "grid_to_demand": 30047.34267,
    "govt_battery_charge": 64.088275
  },
  {
    "timestamp": "15/09/24 21:00",
    "demand": 35425.08766,
    "solar_generated": 0,
    "wind_generated": 78.7880304,
    "marketplace_to_demand": 54.39619733,
    "grid_to_demand": 31298.96186,
    "govt_battery_charge": 18.68842678
  },
  {
    "timestamp": "15/09/24 22:00",
    "demand": 33883.31742,
    "solar_generated": 0,
    "wind_generated": 67.30317229,
    "marketplace_to_demand": 57.38481442,
    "grid_to_demand": 32931.09448,
    "govt_battery_charge": 64.46517985
  },
  {
    "timestamp": "15/09/24 23:00",
    "demand": 35758.10652,
    "solar_generated": 0,
    "wind_generated": 60.51176196,
    "marketplace_to_demand": 26.29888829,
    "grid_to_demand": 30644.89523,
    "govt_battery_charge": 99.20221312
  },
  {
    "timestamp": "16/09/24 0:00",
    "demand": 34452.34042,
    "solar_generated": 0,
    "wind_generated": 55.71805034,
    "marketplace_to_demand": 181.1350792,
    "grid_to_demand": 33982.29216,
    "govt_battery_charge": 86.61599939
  },
  {
    "timestamp": "16/09/24 1:00",
    "demand": 34199.46523,
    "solar_generated": 0,
    "wind_generated": 56.21788652,
    "marketplace_to_demand": 153.9840214,
    "grid_to_demand": 29917.06633,
    "govt_battery_charge": 30.59751329
  },
  {
    "timestamp": "16/09/24 2:00",
    "demand": 30282.47559,
    "solar_generated": 0,
    "wind_generated": 58.77925925,
    "marketplace_to_demand": 120.2807181,
    "grid_to_demand": 31411.41095,
    "govt_battery_charge": 59.76334543
  },
  {
    "timestamp": "16/09/24 3:00",
    "demand": 30162.63835,
    "solar_generated": 0,
    "wind_generated": 76.71375157,
    "marketplace_to_demand": 98.2451967,
    "grid_to_demand": 32519.53071,
    "govt_battery_charge": 48.97905007
  },
  {
    "timestamp": "16/09/24 4:00",
    "demand": 31069.06012,
    "solar_generated": 0,
    "wind_generated": 68.98560061,
    "marketplace_to_demand": 123.6744888,
    "grid_to_demand": 30961.97602,
    "govt_battery_charge": 19.65372924
  },
  {
    "timestamp": "16/09/24 5:00",
    "demand": 31555.14696,
    "solar_generated": 0,
    "wind_generated": 76.38229155,
    "marketplace_to_demand": 84.50652347,
    "grid_to_demand": 35557.01165,
    "govt_battery_charge": 99.4756077
  },
  {
    "timestamp": "16/09/24 6:00",
    "demand": 34473.00571,
    "solar_generated": 7.21974067,
    "wind_generated": 76.77096582,
    "marketplace_to_demand": 31.41054201,
    "grid_to_demand": 32610.69388,
    "govt_battery_charge": 64.92721268
  },
  {
    "timestamp": "16/09/24 7:00",
    "demand": 36392.69275,
    "solar_generated": 9.040949299,
    "wind_generated": 72.38510207,
    "marketplace_to_demand": 147.4873583,
    "grid_to_demand": 32651.69562,
    "govt_battery_charge": 7.442563088
  },
  {
    "timestamp": "16/09/24 8:00",
    "demand": 30786.86056,
    "solar_generated": 12.90607966,
    "wind_generated": 65.21415512,
    "marketplace_to_demand": 9.827941663,
    "grid_to_demand": 33300.84869,
    "govt_battery_charge": 27.03568624
  },
  {
    "timestamp": "16/09/24 9:00",
    "demand": 33289.78865,
    "solar_generated": 22.19134455,
    "wind_generated": 67.33142125,
    "marketplace_to_demand": 63.7771036,
    "grid_to_demand": 34804.0456,
    "govt_battery_charge": 9.289152373
  },
  {
    "timestamp": "16/09/24 10:00",
    "demand": 33076.76393,
    "solar_generated": 12.63807679,
    "wind_generated": 77.10213,
    "marketplace_to_demand": 18.97560711,
    "grid_to_demand": 34172.45829,
    "govt_battery_charge": 26.34128871
  },
  {
    "timestamp": "16/09/24 11:00",
    "demand": 34301.46927,
    "solar_generated": 15.23646412,
    "wind_generated": 75.47614023,
    "marketplace_to_demand": 107.5803105,
    "grid_to_demand": 31496.91662,
    "govt_battery_charge": 44.55774347
  },
  {
    "timestamp": "16/09/24 12:00",
    "demand": 36259.0857,
    "solar_generated": 11.2419481,
    "wind_generated": 54.64337948,
    "marketplace_to_demand": 163.5477526,
    "grid_to_demand": 32957.35749,
    "govt_battery_charge": 7.69872918
  },
  {
    "timestamp": "16/09/24 13:00",
    "demand": 31027.97578,
    "solar_generated": 11.80308925,
    "wind_generated": 66.18045596,
    "marketplace_to_demand": 121.6039289,
    "grid_to_demand": 34985.84716,
    "govt_battery_charge": 75.10586153
  },
  {
    "timestamp": "16/09/24 14:00",
    "demand": 34299.61225,
    "solar_generated": 23.53026867,
    "wind_generated": 66.35618911,
    "marketplace_to_demand": 6.469693773,
    "grid_to_demand": 34052.8731,
    "govt_battery_charge": 31.49955236
  },
  {
    "timestamp": "16/09/24 15:00",
    "demand": 32818.109,
    "solar_generated": 18.91425692,
    "wind_generated": 62.02955536,
    "marketplace_to_demand": 156.3402116,
    "grid_to_demand": 33017.38044,
    "govt_battery_charge": 5.99906127
  },
  {
    "timestamp": "16/09/24 16:00",
    "demand": 31075.46131,
    "solar_generated": 22.91255188,
    "wind_generated": 59.49296838,
    "marketplace_to_demand": 70.42652213,
    "grid_to_demand": 32965.22815,
    "govt_battery_charge": 78.6701278
  },
  {
    "timestamp": "16/09/24 17:00",
    "demand": 34031.03711,
    "solar_generated": 21.2792411,
    "wind_generated": 69.79541699,
    "marketplace_to_demand": 44.84897101,
    "grid_to_demand": 31622.38027,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "16/09/24 18:00",
    "demand": 36527.76936,
    "solar_generated": 0,
    "wind_generated": 74.6462739,
    "marketplace_to_demand": 168.8388836,
    "grid_to_demand": 34145.60728,
    "govt_battery_charge": 42.90616701
  },
  {
    "timestamp": "16/09/24 19:00",
    "demand": 30564.85014,
    "solar_generated": 0,
    "wind_generated": 79.97676323,
    "marketplace_to_demand": 188.5671298,
    "grid_to_demand": 34616.23705,
    "govt_battery_charge": 80.87657364
  },
  {
    "timestamp": "16/09/24 20:00",
    "demand": 34132.80174,
    "solar_generated": 0,
    "wind_generated": 72.99265087,
    "marketplace_to_demand": 178.5305404,
    "grid_to_demand": 32935.77014,
    "govt_battery_charge": 20.12504453
  },
  {
    "timestamp": "16/09/24 21:00",
    "demand": 35386.04245,
    "solar_generated": 0,
    "wind_generated": 65.89757283,
    "marketplace_to_demand": 181.8308377,
    "grid_to_demand": 31033.86218,
    "govt_battery_charge": 92.10634082
  },
  {
    "timestamp": "16/09/24 22:00",
    "demand": 33504.15685,
    "solar_generated": 0,
    "wind_generated": 79.59885102,
    "marketplace_to_demand": 186.9004938,
    "grid_to_demand": 35109.53335,
    "govt_battery_charge": 61.56323343
  },
  {
    "timestamp": "16/09/24 23:00",
    "demand": 32172.11673,
    "solar_generated": 0,
    "wind_generated": 68.6313936,
    "marketplace_to_demand": 35.27692777,
    "grid_to_demand": 33929.95351,
    "govt_battery_charge": 27.92132324
  },
  {
    "timestamp": "17/09/24 0:00",
    "demand": 36086.14642,
    "solar_generated": 0,
    "wind_generated": 70.57685646,
    "marketplace_to_demand": 15.9331783,
    "grid_to_demand": 31599.73405,
    "govt_battery_charge": 5.972197572
  },
  {
    "timestamp": "17/09/24 1:00",
    "demand": 30078.47767,
    "solar_generated": 0,
    "wind_generated": 76.58014996,
    "marketplace_to_demand": 60.07058155,
    "grid_to_demand": 35880.616,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "17/09/24 2:00",
    "demand": 33699.97468,
    "solar_generated": 0,
    "wind_generated": 71.83378543,
    "marketplace_to_demand": 166.8761487,
    "grid_to_demand": 32286.15936,
    "govt_battery_charge": 53.2295869
  },
  {
    "timestamp": "17/09/24 3:00",
    "demand": 31511.40677,
    "solar_generated": 0,
    "wind_generated": 75.53408753,
    "marketplace_to_demand": 99.43562779,
    "grid_to_demand": 32735.51807,
    "govt_battery_charge": 38.87664675
  },
  {
    "timestamp": "17/09/24 4:00",
    "demand": 36346.91228,
    "solar_generated": 0,
    "wind_generated": 70.73714488,
    "marketplace_to_demand": 96.24629925,
    "grid_to_demand": 31368.23836,
    "govt_battery_charge": 36.59149416
  },
  {
    "timestamp": "17/09/24 5:00",
    "demand": 31278.94617,
    "solar_generated": 0,
    "wind_generated": 80.29910656,
    "marketplace_to_demand": 97.75204353,
    "grid_to_demand": 30327.22931,
    "govt_battery_charge": 56.24963267
  },
  {
    "timestamp": "17/09/24 6:00",
    "demand": 34529.49696,
    "solar_generated": 22.86154162,
    "wind_generated": 78.54092559,
    "marketplace_to_demand": 95.77647198,
    "grid_to_demand": 34210.11896,
    "govt_battery_charge": 26.95773806
  },
  {
    "timestamp": "17/09/24 7:00",
    "demand": 32762.91782,
    "solar_generated": 13.09751734,
    "wind_generated": 65.12356685,
    "marketplace_to_demand": 115.8894936,
    "grid_to_demand": 36005.22258,
    "govt_battery_charge": 68.64747972
  },
  {
    "timestamp": "17/09/24 8:00",
    "demand": 31118.04829,
    "solar_generated": 9.48425731,
    "wind_generated": 65.87017315,
    "marketplace_to_demand": 66.65775459,
    "grid_to_demand": 35854.51605,
    "govt_battery_charge": 56.05131393
  },
  {
    "timestamp": "17/09/24 9:00",
    "demand": 35367.28251,
    "solar_generated": 17.73901672,
    "wind_generated": 73.01685301,
    "marketplace_to_demand": 144.7063393,
    "grid_to_demand": 32958.57679,
    "govt_battery_charge": 88.43413249
  },
  {
    "timestamp": "17/09/24 10:00",
    "demand": 36571.2979,
    "solar_generated": 7.114573979,
    "wind_generated": 55.50229416,
    "marketplace_to_demand": 49.84162232,
    "grid_to_demand": 35504.83175,
    "govt_battery_charge": 72.83772756
  },
  {
    "timestamp": "17/09/24 11:00",
    "demand": 32451.29555,
    "solar_generated": 7.678842881,
    "wind_generated": 76.56392176,
    "marketplace_to_demand": 165.4754117,
    "grid_to_demand": 35870.71159,
    "govt_battery_charge": 11.67381803
  },
  {
    "timestamp": "17/09/24 12:00",
    "demand": 36542.7169,
    "solar_generated": 11.71890445,
    "wind_generated": 79.64635062,
    "marketplace_to_demand": 54.0917622,
    "grid_to_demand": 33156.03932,
    "govt_battery_charge": 60.74409053
  },
  {
    "timestamp": "17/09/24 13:00",
    "demand": 30477.09879,
    "solar_generated": 19.03229249,
    "wind_generated": 66.72009195,
    "marketplace_to_demand": 180.8246909,
    "grid_to_demand": 36279.80029,
    "govt_battery_charge": 42.55433283
  },
  {
    "timestamp": "17/09/24 14:00",
    "demand": 32023.11934,
    "solar_generated": 17.29627372,
    "wind_generated": 56.88763268,
    "marketplace_to_demand": 46.66791972,
    "grid_to_demand": 34207.10037,
    "govt_battery_charge": 27.44820379
  },
  {
    "timestamp": "17/09/24 15:00",
    "demand": 34074.54593,
    "solar_generated": 17.07031048,
    "wind_generated": 63.49086463,
    "marketplace_to_demand": 125.8028364,
    "grid_to_demand": 35884.01547,
    "govt_battery_charge": 47.05827853
  },
  {
    "timestamp": "17/09/24 16:00",
    "demand": 35629.32468,
    "solar_generated": 6.629919754,
    "wind_generated": 61.47003328,
    "marketplace_to_demand": 164.6879827,
    "grid_to_demand": 30427.41651,
    "govt_battery_charge": 46.53010509
  },
  {
    "timestamp": "17/09/24 17:00",
    "demand": 35702.46969,
    "solar_generated": 15.32362927,
    "wind_generated": 77.79080856,
    "marketplace_to_demand": 147.0961355,
    "grid_to_demand": 30849.57341,
    "govt_battery_charge": 33.47074902
  },
  {
    "timestamp": "17/09/24 18:00",
    "demand": 35363.21411,
    "solar_generated": 0,
    "wind_generated": 70.96360637,
    "marketplace_to_demand": 56.96289047,
    "grid_to_demand": 32112.23496,
    "govt_battery_charge": 30.44301434
  },
  {
    "timestamp": "17/09/24 19:00",
    "demand": 30697.64023,
    "solar_generated": 0,
    "wind_generated": 61.05945332,
    "marketplace_to_demand": 154.9065101,
    "grid_to_demand": 34319.45743,
    "govt_battery_charge": 34.26931941
  },
  {
    "timestamp": "17/09/24 20:00",
    "demand": 35632.56863,
    "solar_generated": 0,
    "wind_generated": 77.98117288,
    "marketplace_to_demand": 128.9579957,
    "grid_to_demand": 36541.05699,
    "govt_battery_charge": 54.52325683
  },
  {
    "timestamp": "17/09/24 21:00",
    "demand": 31087.80567,
    "solar_generated": 0,
    "wind_generated": 62.25531341,
    "marketplace_to_demand": 48.26897219,
    "grid_to_demand": 30945.48292,
    "govt_battery_charge": 26.54784703
  },
  {
    "timestamp": "17/09/24 22:00",
    "demand": 30408.57905,
    "solar_generated": 0,
    "wind_generated": 73.74177228,
    "marketplace_to_demand": 113.9190522,
    "grid_to_demand": 32747.94721,
    "govt_battery_charge": 59.15850894
  },
  {
    "timestamp": "17/09/24 23:00",
    "demand": 32367.06895,
    "solar_generated": 0,
    "wind_generated": 56.57021205,
    "marketplace_to_demand": 80.77088888,
    "grid_to_demand": 35664.54111,
    "govt_battery_charge": 69.58206883
  },
  {
    "timestamp": "18/09/24 0:00",
    "demand": 31347.07154,
    "solar_generated": 0,
    "wind_generated": 68.84408953,
    "marketplace_to_demand": 181.2671544,
    "grid_to_demand": 33415.00474,
    "govt_battery_charge": 19.42351132
  },
  {
    "timestamp": "18/09/24 1:00",
    "demand": 35201.48887,
    "solar_generated": 0,
    "wind_generated": 63.56405882,
    "marketplace_to_demand": 60.42054396,
    "grid_to_demand": 31699.74467,
    "govt_battery_charge": 78.75463721
  },
  {
    "timestamp": "18/09/24 2:00",
    "demand": 31750.79403,
    "solar_generated": 0,
    "wind_generated": 68.06420787,
    "marketplace_to_demand": 146.4162956,
    "grid_to_demand": 35349.61077,
    "govt_battery_charge": 64.47303395
  },
  {
    "timestamp": "18/09/24 3:00",
    "demand": 32168.91335,
    "solar_generated": 0,
    "wind_generated": 63.75535547,
    "marketplace_to_demand": 175.6621761,
    "grid_to_demand": 33179.51054,
    "govt_battery_charge": 92.20220156
  },
  {
    "timestamp": "18/09/24 4:00",
    "demand": 32427.17337,
    "solar_generated": 0,
    "wind_generated": 69.05478611,
    "marketplace_to_demand": 136.3915966,
    "grid_to_demand": 34098.25342,
    "govt_battery_charge": 62.26482283
  },
  {
    "timestamp": "18/09/24 5:00",
    "demand": 30486.90891,
    "solar_generated": 0,
    "wind_generated": 62.07597508,
    "marketplace_to_demand": 75.8678789,
    "grid_to_demand": 34510.0255,
    "govt_battery_charge": 10.63298334
  },
  {
    "timestamp": "18/09/24 6:00",
    "demand": 31323.50438,
    "solar_generated": 20.79371884,
    "wind_generated": 76.96308154,
    "marketplace_to_demand": 191.3300162,
    "grid_to_demand": 33917.59781,
    "govt_battery_charge": 98.99045384
  },
  {
    "timestamp": "18/09/24 7:00",
    "demand": 31967.00578,
    "solar_generated": 18.96817061,
    "wind_generated": 63.52071716,
    "marketplace_to_demand": 137.6376976,
    "grid_to_demand": 32515.8352,
    "govt_battery_charge": 76.22332467
  },
  {
    "timestamp": "18/09/24 8:00",
    "demand": 35296.51548,
    "solar_generated": 22.64692183,
    "wind_generated": 54.0282079,
    "marketplace_to_demand": 187.4936309,
    "grid_to_demand": 30062.32169,
    "govt_battery_charge": 19.80112633
  },
  {
    "timestamp": "18/09/24 9:00",
    "demand": 33529.27132,
    "solar_generated": 12.01679385,
    "wind_generated": 64.63617322,
    "marketplace_to_demand": 111.1395457,
    "grid_to_demand": 36495.90958,
    "govt_battery_charge": 53.00216331
  },
  {
    "timestamp": "18/09/24 10:00",
    "demand": 30793.62734,
    "solar_generated": 6.238996915,
    "wind_generated": 56.20297642,
    "marketplace_to_demand": 150.9672246,
    "grid_to_demand": 30164.96897,
    "govt_battery_charge": 51.32942901
  },
  {
    "timestamp": "18/09/24 11:00",
    "demand": 35625.53546,
    "solar_generated": 16.65744913,
    "wind_generated": 76.23566085,
    "marketplace_to_demand": 121.5837276,
    "grid_to_demand": 32017.99525,
    "govt_battery_charge": 56.6589743
  },
  {
    "timestamp": "18/09/24 12:00",
    "demand": 36502.88018,
    "solar_generated": 10.48188961,
    "wind_generated": 72.42564016,
    "marketplace_to_demand": 78.2264911,
    "grid_to_demand": 31680.83777,
    "govt_battery_charge": 31.5041376
  },
  {
    "timestamp": "18/09/24 13:00",
    "demand": 31692.00575,
    "solar_generated": 21.35002843,
    "wind_generated": 79.65778542,
    "marketplace_to_demand": 142.7849811,
    "grid_to_demand": 34547.34738,
    "govt_battery_charge": 92.16314545
  },
  {
    "timestamp": "18/09/24 14:00",
    "demand": 33583.1681,
    "solar_generated": 20.36957189,
    "wind_generated": 79.51957412,
    "marketplace_to_demand": 12.97913786,
    "grid_to_demand": 32888.60067,
    "govt_battery_charge": 96.57822327
  },
  {
    "timestamp": "18/09/24 15:00",
    "demand": 34215.21933,
    "solar_generated": 7.401611667,
    "wind_generated": 74.01531793,
    "marketplace_to_demand": 158.2980635,
    "grid_to_demand": 35434.42159,
    "govt_battery_charge": 60.58973922
  },
  {
    "timestamp": "18/09/24 16:00",
    "demand": 34325.10824,
    "solar_generated": 16.86146947,
    "wind_generated": 71.38199448,
    "marketplace_to_demand": 42.47098408,
    "grid_to_demand": 34868.15221,
    "govt_battery_charge": 51.4300795
  },
  {
    "timestamp": "18/09/24 17:00",
    "demand": 33211.26152,
    "solar_generated": 10.13717023,
    "wind_generated": 73.43411836,
    "marketplace_to_demand": 82.50149996,
    "grid_to_demand": 36099.9786,
    "govt_battery_charge": 69.63415569
  },
  {
    "timestamp": "18/09/24 18:00",
    "demand": 33365.52937,
    "solar_generated": 0,
    "wind_generated": 70.17111711,
    "marketplace_to_demand": 49.10741992,
    "grid_to_demand": 30035.43151,
    "govt_battery_charge": 57.23567096
  },
  {
    "timestamp": "18/09/24 19:00",
    "demand": 33982.06566,
    "solar_generated": 0,
    "wind_generated": 67.66527947,
    "marketplace_to_demand": 85.91006658,
    "grid_to_demand": 30227.5672,
    "govt_battery_charge": 62.55285244
  },
  {
    "timestamp": "18/09/24 20:00",
    "demand": 33780.09906,
    "solar_generated": 0,
    "wind_generated": 61.91202783,
    "marketplace_to_demand": 160.4884975,
    "grid_to_demand": 30687.55706,
    "govt_battery_charge": 19.51198116
  },
  {
    "timestamp": "18/09/24 21:00",
    "demand": 31450.94943,
    "solar_generated": 0,
    "wind_generated": 54.63437968,
    "marketplace_to_demand": 1.400800771,
    "grid_to_demand": 35808.81012,
    "govt_battery_charge": 17.1804641
  },
  {
    "timestamp": "18/09/24 22:00",
    "demand": 30431.67204,
    "solar_generated": 0,
    "wind_generated": 68.06102896,
    "marketplace_to_demand": 16.29452451,
    "grid_to_demand": 31007.20031,
    "govt_battery_charge": 11.56919021
  },
  {
    "timestamp": "18/09/24 23:00",
    "demand": 36380.99155,
    "solar_generated": 0,
    "wind_generated": 55.24972143,
    "marketplace_to_demand": 51.99847911,
    "grid_to_demand": 35299.76816,
    "govt_battery_charge": 87.6801872
  },
  {
    "timestamp": "19/09/24 0:00",
    "demand": 33547.29871,
    "solar_generated": 0,
    "wind_generated": 55.34042388,
    "marketplace_to_demand": 80.96046405,
    "grid_to_demand": 32348.70223,
    "govt_battery_charge": 4.069206109
  },
  {
    "timestamp": "19/09/24 1:00",
    "demand": 34480.22772,
    "solar_generated": 0,
    "wind_generated": 66.84486165,
    "marketplace_to_demand": 28.4535379,
    "grid_to_demand": 30544.90643,
    "govt_battery_charge": 6.03199459
  },
  {
    "timestamp": "19/09/24 2:00",
    "demand": 32099.72421,
    "solar_generated": 0,
    "wind_generated": 70.01209393,
    "marketplace_to_demand": 104.0082023,
    "grid_to_demand": 31554.14839,
    "govt_battery_charge": 99.47646228
  },
  {
    "timestamp": "19/09/24 3:00",
    "demand": 33294.33562,
    "solar_generated": 0,
    "wind_generated": 56.8682486,
    "marketplace_to_demand": 50.79007669,
    "grid_to_demand": 35715.72428,
    "govt_battery_charge": 13.80439641
  },
  {
    "timestamp": "19/09/24 4:00",
    "demand": 34864.62378,
    "solar_generated": 0,
    "wind_generated": 56.81292322,
    "marketplace_to_demand": 188.7049636,
    "grid_to_demand": 33131.22887,
    "govt_battery_charge": 53.71183589
  },
  {
    "timestamp": "19/09/24 5:00",
    "demand": 33162.61669,
    "solar_generated": 0,
    "wind_generated": 57.6182417,
    "marketplace_to_demand": 168.4142924,
    "grid_to_demand": 31620.70119,
    "govt_battery_charge": 15.97360837
  },
  {
    "timestamp": "19/09/24 6:00",
    "demand": 34533.44679,
    "solar_generated": 7.584778997,
    "wind_generated": 77.07720591,
    "marketplace_to_demand": 114.5965933,
    "grid_to_demand": 34583.85058,
    "govt_battery_charge": 33.77415748
  },
  {
    "timestamp": "19/09/24 7:00",
    "demand": 34481.87809,
    "solar_generated": 13.99572595,
    "wind_generated": 67.29281277,
    "marketplace_to_demand": 180.3404513,
    "grid_to_demand": 33160.2539,
    "govt_battery_charge": 16.09044397
  },
  {
    "timestamp": "19/09/24 8:00",
    "demand": 31743.28964,
    "solar_generated": 11.10091202,
    "wind_generated": 70.35611146,
    "marketplace_to_demand": 179.9910813,
    "grid_to_demand": 35485.70699,
    "govt_battery_charge": 6.952837527
  },
  {
    "timestamp": "19/09/24 9:00",
    "demand": 35409.17382,
    "solar_generated": 10.23749446,
    "wind_generated": 59.58852116,
    "marketplace_to_demand": 28.57416423,
    "grid_to_demand": 35307.02593,
    "govt_battery_charge": 54.06353526
  },
  {
    "timestamp": "19/09/24 10:00",
    "demand": 36556.00904,
    "solar_generated": 12.83665843,
    "wind_generated": 79.200353,
    "marketplace_to_demand": 92.08922845,
    "grid_to_demand": 34490.37125,
    "govt_battery_charge": 87.73045924
  },
  {
    "timestamp": "19/09/24 11:00",
    "demand": 31490.36574,
    "solar_generated": 9.653359162,
    "wind_generated": 65.68955101,
    "marketplace_to_demand": 94.13424722,
    "grid_to_demand": 34308.57503,
    "govt_battery_charge": 51.83765009
  },
  {
    "timestamp": "19/09/24 12:00",
    "demand": 34865.79565,
    "solar_generated": 20.75272576,
    "wind_generated": 64.92447208,
    "marketplace_to_demand": 149.7423112,
    "grid_to_demand": 32146.21692,
    "govt_battery_charge": 60.41379851
  },
  {
    "timestamp": "19/09/24 13:00",
    "demand": 36012.73886,
    "solar_generated": 17.19993024,
    "wind_generated": 66.55873144,
    "marketplace_to_demand": 51.63848466,
    "grid_to_demand": 36206.78719,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/09/24 14:00",
    "demand": 34931.2638,
    "solar_generated": 9.092441714,
    "wind_generated": 66.48972125,
    "marketplace_to_demand": 153.5308365,
    "grid_to_demand": 33234.67785,
    "govt_battery_charge": 72.79462243
  },
  {
    "timestamp": "19/09/24 15:00",
    "demand": 31681.63364,
    "solar_generated": 23.19127568,
    "wind_generated": 65.38205368,
    "marketplace_to_demand": 29.81801149,
    "grid_to_demand": 35308.69017,
    "govt_battery_charge": 91.74792995
  },
  {
    "timestamp": "19/09/24 16:00",
    "demand": 31964.84974,
    "solar_generated": 11.601195,
    "wind_generated": 54.18419375,
    "marketplace_to_demand": 173.0299069,
    "grid_to_demand": 35173.37112,
    "govt_battery_charge": 93.19154075
  },
  {
    "timestamp": "19/09/24 17:00",
    "demand": 31732.0649,
    "solar_generated": 13.14682708,
    "wind_generated": 57.81716461,
    "marketplace_to_demand": 15.03092039,
    "grid_to_demand": 34051.22311,
    "govt_battery_charge": 47.19821102
  },
  {
    "timestamp": "19/09/24 18:00",
    "demand": 33853.54726,
    "solar_generated": 0,
    "wind_generated": 73.03773787,
    "marketplace_to_demand": 83.34333731,
    "grid_to_demand": 32043.02409,
    "govt_battery_charge": 28.20215043
  },
  {
    "timestamp": "19/09/24 19:00",
    "demand": 33223.72531,
    "solar_generated": 0,
    "wind_generated": 74.34539246,
    "marketplace_to_demand": 32.16149901,
    "grid_to_demand": 31035.44528,
    "govt_battery_charge": 58.89053865
  },
  {
    "timestamp": "19/09/24 20:00",
    "demand": 33419.46634,
    "solar_generated": 0,
    "wind_generated": 74.84138372,
    "marketplace_to_demand": 131.8502562,
    "grid_to_demand": 33780.59002,
    "govt_battery_charge": 13.06106519
  },
  {
    "timestamp": "19/09/24 21:00",
    "demand": 30615.87344,
    "solar_generated": 0,
    "wind_generated": 74.45374583,
    "marketplace_to_demand": 87.26606666,
    "grid_to_demand": 31105.76259,
    "govt_battery_charge": 99.1246621
  },
  {
    "timestamp": "19/09/24 22:00",
    "demand": 33073.15147,
    "solar_generated": 0,
    "wind_generated": 67.09279599,
    "marketplace_to_demand": 152.3459784,
    "grid_to_demand": 34062.24107,
    "govt_battery_charge": 79.95643685
  },
  {
    "timestamp": "19/09/24 23:00",
    "demand": 31080.22485,
    "solar_generated": 0,
    "wind_generated": 79.68981466,
    "marketplace_to_demand": 27.20092599,
    "grid_to_demand": 32198.42267,
    "govt_battery_charge": 13.83544434
  },
  {
    "timestamp": "20/09/24 0:00",
    "demand": 33628.20864,
    "solar_generated": 0,
    "wind_generated": 57.45511837,
    "marketplace_to_demand": 45.7922569,
    "grid_to_demand": 31718.83305,
    "govt_battery_charge": 90.74713448
  },
  {
    "timestamp": "20/09/24 1:00",
    "demand": 35943.91955,
    "solar_generated": 0,
    "wind_generated": 58.60563519,
    "marketplace_to_demand": 33.93134974,
    "grid_to_demand": 35151.80969,
    "govt_battery_charge": 44.34876276
  },
  {
    "timestamp": "20/09/24 2:00",
    "demand": 33270.44055,
    "solar_generated": 0,
    "wind_generated": 54.22984509,
    "marketplace_to_demand": 128.9572386,
    "grid_to_demand": 33751.91001,
    "govt_battery_charge": 41.59588853
  },
  {
    "timestamp": "20/09/24 3:00",
    "demand": 35096.13438,
    "solar_generated": 0,
    "wind_generated": 62.72767733,
    "marketplace_to_demand": 174.5153791,
    "grid_to_demand": 31104.49818,
    "govt_battery_charge": 29.00858629
  },
  {
    "timestamp": "20/09/24 4:00",
    "demand": 36460.15272,
    "solar_generated": 0,
    "wind_generated": 59.18143744,
    "marketplace_to_demand": 155.5353286,
    "grid_to_demand": 33074.67404,
    "govt_battery_charge": 6.984419085
  },
  {
    "timestamp": "20/09/24 5:00",
    "demand": 30226.62126,
    "solar_generated": 0,
    "wind_generated": 72.64667992,
    "marketplace_to_demand": 103.4735692,
    "grid_to_demand": 34836.75727,
    "govt_battery_charge": 61.01114559
  },
  {
    "timestamp": "20/09/24 6:00",
    "demand": 35334.54917,
    "solar_generated": 8.434229053,
    "wind_generated": 68.6685428,
    "marketplace_to_demand": 120.594706,
    "grid_to_demand": 36191.23244,
    "govt_battery_charge": 14.0489885
  },
  {
    "timestamp": "20/09/24 7:00",
    "demand": 33779.95322,
    "solar_generated": 23.69616606,
    "wind_generated": 59.99829275,
    "marketplace_to_demand": 193.1024602,
    "grid_to_demand": 35471.99796,
    "govt_battery_charge": 41.43247132
  },
  {
    "timestamp": "20/09/24 8:00",
    "demand": 34130.83668,
    "solar_generated": 17.65834516,
    "wind_generated": 65.9633044,
    "marketplace_to_demand": 53.99570187,
    "grid_to_demand": 30616.97654,
    "govt_battery_charge": 8.717221309
  },
  {
    "timestamp": "20/09/24 9:00",
    "demand": 30022.46141,
    "solar_generated": 8.213517611,
    "wind_generated": 69.88649916,
    "marketplace_to_demand": 3.618795876,
    "grid_to_demand": 33392.71282,
    "govt_battery_charge": 2.688006385
  },
  {
    "timestamp": "20/09/24 10:00",
    "demand": 34797.16761,
    "solar_generated": 10.99916505,
    "wind_generated": 55.87612441,
    "marketplace_to_demand": 44.75590548,
    "grid_to_demand": 34267.22801,
    "govt_battery_charge": 50.95434713
  },
  {
    "timestamp": "20/09/24 11:00",
    "demand": 32387.25292,
    "solar_generated": 19.61166976,
    "wind_generated": 56.14030339,
    "marketplace_to_demand": 143.2802819,
    "grid_to_demand": 34249.03189,
    "govt_battery_charge": 51.963982
  },
  {
    "timestamp": "20/09/24 12:00",
    "demand": 32355.74055,
    "solar_generated": 12.74161224,
    "wind_generated": 60.92968549,
    "marketplace_to_demand": 137.4210556,
    "grid_to_demand": 32012.60486,
    "govt_battery_charge": 47.20841087
  },
  {
    "timestamp": "20/09/24 13:00",
    "demand": 35124.86231,
    "solar_generated": 11.2839666,
    "wind_generated": 74.68049131,
    "marketplace_to_demand": 1.679617079,
    "grid_to_demand": 33618.86542,
    "govt_battery_charge": 83.6694761
  },
  {
    "timestamp": "20/09/24 14:00",
    "demand": 35775.33669,
    "solar_generated": 9.579880798,
    "wind_generated": 78.95459796,
    "marketplace_to_demand": 78.57105849,
    "grid_to_demand": 35877.92189,
    "govt_battery_charge": 61.40953269
  },
  {
    "timestamp": "20/09/24 15:00",
    "demand": 32019.98116,
    "solar_generated": 12.27581927,
    "wind_generated": 54.68107988,
    "marketplace_to_demand": 11.77594037,
    "grid_to_demand": 33205.1123,
    "govt_battery_charge": 85.63881955
  },
  {
    "timestamp": "20/09/24 16:00",
    "demand": 30790.1719,
    "solar_generated": 14.14777471,
    "wind_generated": 60.73329608,
    "marketplace_to_demand": 13.15154954,
    "grid_to_demand": 34278.67554,
    "govt_battery_charge": 29.88078466
  },
  {
    "timestamp": "20/09/24 17:00",
    "demand": 34306.34886,
    "solar_generated": 7.970629488,
    "wind_generated": 54.04794737,
    "marketplace_to_demand": 111.596196,
    "grid_to_demand": 30367.05139,
    "govt_battery_charge": 3.826980267
  },
  {
    "timestamp": "20/09/24 18:00",
    "demand": 34611.96594,
    "solar_generated": 0,
    "wind_generated": 74.90950318,
    "marketplace_to_demand": 148.3285968,
    "grid_to_demand": 35558.30689,
    "govt_battery_charge": 19.6788595
  },
  {
    "timestamp": "20/09/24 19:00",
    "demand": 33738.90619,
    "solar_generated": 0,
    "wind_generated": 66.75105498,
    "marketplace_to_demand": 18.90108988,
    "grid_to_demand": 32352.356,
    "govt_battery_charge": 78.28437907
  },
  {
    "timestamp": "20/09/24 20:00",
    "demand": 34252.68319,
    "solar_generated": 0,
    "wind_generated": 76.64863004,
    "marketplace_to_demand": 137.3456304,
    "grid_to_demand": 35304.03084,
    "govt_battery_charge": 61.49519344
  },
  {
    "timestamp": "20/09/24 21:00",
    "demand": 31234.3926,
    "solar_generated": 0,
    "wind_generated": 63.56118342,
    "marketplace_to_demand": 40.72409473,
    "grid_to_demand": 34206.38863,
    "govt_battery_charge": 3.932414719
  },
  {
    "timestamp": "20/09/24 22:00",
    "demand": 34790.23032,
    "solar_generated": 0,
    "wind_generated": 57.04907829,
    "marketplace_to_demand": 91.91190296,
    "grid_to_demand": 30506.71542,
    "govt_battery_charge": 49.43806035
  },
  {
    "timestamp": "20/09/24 23:00",
    "demand": 34179.43716,
    "solar_generated": 0,
    "wind_generated": 74.08836851,
    "marketplace_to_demand": 90.79587534,
    "grid_to_demand": 34837.4561,
    "govt_battery_charge": 20.719715
  },
  {
    "timestamp": "21/09/24 0:00",
    "demand": 30466.20846,
    "solar_generated": 0,
    "wind_generated": 54.65203764,
    "marketplace_to_demand": 50.93119854,
    "grid_to_demand": 35389.5356,
    "govt_battery_charge": 15.13471824
  },
  {
    "timestamp": "21/09/24 1:00",
    "demand": 34814.10948,
    "solar_generated": 0,
    "wind_generated": 73.63489878,
    "marketplace_to_demand": 167.945717,
    "grid_to_demand": 33979.87854,
    "govt_battery_charge": 9.170883426
  },
  {
    "timestamp": "21/09/24 2:00",
    "demand": 36365.3131,
    "solar_generated": 0,
    "wind_generated": 78.63074905,
    "marketplace_to_demand": 123.1890153,
    "grid_to_demand": 31115.70231,
    "govt_battery_charge": 1.082451935
  },
  {
    "timestamp": "21/09/24 3:00",
    "demand": 35255.76603,
    "solar_generated": 0,
    "wind_generated": 78.07632412,
    "marketplace_to_demand": 101.6459716,
    "grid_to_demand": 36001.44534,
    "govt_battery_charge": 6.826041498
  },
  {
    "timestamp": "21/09/24 4:00",
    "demand": 34829.89725,
    "solar_generated": 0,
    "wind_generated": 60.34276787,
    "marketplace_to_demand": 165.7142676,
    "grid_to_demand": 30058.6435,
    "govt_battery_charge": 14.60970771
  },
  {
    "timestamp": "21/09/24 5:00",
    "demand": 35395.85927,
    "solar_generated": 0,
    "wind_generated": 56.56257515,
    "marketplace_to_demand": 66.96975044,
    "grid_to_demand": 32937.05273,
    "govt_battery_charge": 10.06068318
  },
  {
    "timestamp": "21/09/24 6:00",
    "demand": 35741.09321,
    "solar_generated": 9.912528136,
    "wind_generated": 74.99556112,
    "marketplace_to_demand": 93.58896289,
    "grid_to_demand": 34893.30147,
    "govt_battery_charge": 78.04218331
  },
  {
    "timestamp": "21/09/24 7:00",
    "demand": 31989.07663,
    "solar_generated": 12.04648808,
    "wind_generated": 67.67692087,
    "marketplace_to_demand": 4.69793908,
    "grid_to_demand": 30600.57486,
    "govt_battery_charge": 19.63752513
  },
  {
    "timestamp": "21/09/24 8:00",
    "demand": 34559.89788,
    "solar_generated": 12.16633258,
    "wind_generated": 66.15983348,
    "marketplace_to_demand": 110.2055908,
    "grid_to_demand": 35960.58915,
    "govt_battery_charge": 83.66919346
  },
  {
    "timestamp": "21/09/24 9:00",
    "demand": 33104.46438,
    "solar_generated": 12.37026232,
    "wind_generated": 53.82574508,
    "marketplace_to_demand": 134.6520897,
    "grid_to_demand": 35242.89371,
    "govt_battery_charge": 51.45339988
  },
  {
    "timestamp": "21/09/24 10:00",
    "demand": 32242.80687,
    "solar_generated": 6.684372976,
    "wind_generated": 66.67575268,
    "marketplace_to_demand": 176.9231377,
    "grid_to_demand": 34351.94327,
    "govt_battery_charge": 38.60789141
  },
  {
    "timestamp": "21/09/24 11:00",
    "demand": 33229.91018,
    "solar_generated": 6.0208301,
    "wind_generated": 75.65257431,
    "marketplace_to_demand": 123.1024612,
    "grid_to_demand": 34859.6971,
    "govt_battery_charge": 71.91128737
  },
  {
    "timestamp": "21/09/24 12:00",
    "demand": 31627.30191,
    "solar_generated": 14.86856813,
    "wind_generated": 65.5307377,
    "marketplace_to_demand": 24.85236878,
    "grid_to_demand": 34187.80155,
    "govt_battery_charge": 27.51328927
  },
  {
    "timestamp": "21/09/24 13:00",
    "demand": 31719.12154,
    "solar_generated": 18.82888776,
    "wind_generated": 66.27181921,
    "marketplace_to_demand": 79.53160086,
    "grid_to_demand": 34829.39661,
    "govt_battery_charge": 63.68200953
  },
  {
    "timestamp": "21/09/24 14:00",
    "demand": 33239.13896,
    "solar_generated": 14.6060833,
    "wind_generated": 71.3661093,
    "marketplace_to_demand": 5.226804969,
    "grid_to_demand": 35658.58933,
    "govt_battery_charge": 67.11651547
  },
  {
    "timestamp": "21/09/24 15:00",
    "demand": 30875.45184,
    "solar_generated": 13.21827348,
    "wind_generated": 73.40872748,
    "marketplace_to_demand": 96.53962248,
    "grid_to_demand": 33645.1462,
    "govt_battery_charge": 44.97102432
  },
  {
    "timestamp": "21/09/24 16:00",
    "demand": 30016.95753,
    "solar_generated": 23.83585661,
    "wind_generated": 71.21453992,
    "marketplace_to_demand": 52.41822944,
    "grid_to_demand": 35721.77159,
    "govt_battery_charge": 75.16339044
  },
  {
    "timestamp": "21/09/24 17:00",
    "demand": 33019.37362,
    "solar_generated": 16.2690882,
    "wind_generated": 76.41728054,
    "marketplace_to_demand": 131.6522726,
    "grid_to_demand": 32731.05029,
    "govt_battery_charge": 34.97363629
  },
  {
    "timestamp": "21/09/24 18:00",
    "demand": 30669.36639,
    "solar_generated": 0,
    "wind_generated": 66.25844579,
    "marketplace_to_demand": 84.54361829,
    "grid_to_demand": 31217.01888,
    "govt_battery_charge": 78.26625594
  },
  {
    "timestamp": "21/09/24 19:00",
    "demand": 36063.11871,
    "solar_generated": 0,
    "wind_generated": 71.39427026,
    "marketplace_to_demand": 84.59829671,
    "grid_to_demand": 35891.67434,
    "govt_battery_charge": 77.52657826
  },
  {
    "timestamp": "21/09/24 20:00",
    "demand": 34317.52646,
    "solar_generated": 0,
    "wind_generated": 67.02350834,
    "marketplace_to_demand": 58.47914563,
    "grid_to_demand": 31943.92105,
    "govt_battery_charge": 90.82269596
  },
  {
    "timestamp": "21/09/24 21:00",
    "demand": 36179.30291,
    "solar_generated": 0,
    "wind_generated": 75.02646648,
    "marketplace_to_demand": 101.114285,
    "grid_to_demand": 32211.67161,
    "govt_battery_charge": 40.76530421
  },
  {
    "timestamp": "21/09/24 22:00",
    "demand": 30743.44193,
    "solar_generated": 0,
    "wind_generated": 61.43579952,
    "marketplace_to_demand": 147.0972746,
    "grid_to_demand": 35503.7825,
    "govt_battery_charge": 66.38181498
  },
  {
    "timestamp": "21/09/24 23:00",
    "demand": 31863.72237,
    "solar_generated": 0,
    "wind_generated": 77.62332257,
    "marketplace_to_demand": 113.8372314,
    "grid_to_demand": 34478.30216,
    "govt_battery_charge": 92.59526743
  },
  {
    "timestamp": "22/09/24 0:00",
    "demand": 31995.97412,
    "solar_generated": 0,
    "wind_generated": 56.94111212,
    "marketplace_to_demand": 48.51373474,
    "grid_to_demand": 31083.99314,
    "govt_battery_charge": 81.32163933
  },
  {
    "timestamp": "22/09/24 1:00",
    "demand": 30351.1089,
    "solar_generated": 0,
    "wind_generated": 61.71518989,
    "marketplace_to_demand": 115.0502258,
    "grid_to_demand": 33743.60558,
    "govt_battery_charge": 76.00241802
  },
  {
    "timestamp": "22/09/24 2:00",
    "demand": 31630.67881,
    "solar_generated": 0,
    "wind_generated": 70.62261409,
    "marketplace_to_demand": 110.7033823,
    "grid_to_demand": 35620.71006,
    "govt_battery_charge": 37.18606396
  },
  {
    "timestamp": "22/09/24 3:00",
    "demand": 30082.47406,
    "solar_generated": 0,
    "wind_generated": 70.61171288,
    "marketplace_to_demand": 172.2207481,
    "grid_to_demand": 32440.3478,
    "govt_battery_charge": 4.443158379
  },
  {
    "timestamp": "22/09/24 4:00",
    "demand": 33108.65687,
    "solar_generated": 0,
    "wind_generated": 77.73046111,
    "marketplace_to_demand": 39.67414724,
    "grid_to_demand": 32565.63673,
    "govt_battery_charge": 93.01706645
  },
  {
    "timestamp": "22/09/24 5:00",
    "demand": 35345.69847,
    "solar_generated": 0,
    "wind_generated": 65.41850153,
    "marketplace_to_demand": 70.95144174,
    "grid_to_demand": 33646.20488,
    "govt_battery_charge": 56.08393396
  },
  {
    "timestamp": "22/09/24 6:00",
    "demand": 30347.01311,
    "solar_generated": 14.48209158,
    "wind_generated": 58.10201023,
    "marketplace_to_demand": 149.1039435,
    "grid_to_demand": 31946.46684,
    "govt_battery_charge": 94.68476063
  },
  {
    "timestamp": "22/09/24 7:00",
    "demand": 36499.61579,
    "solar_generated": 21.61107511,
    "wind_generated": 55.56961615,
    "marketplace_to_demand": 173.8132337,
    "grid_to_demand": 34386.59731,
    "govt_battery_charge": 37.09846624
  },
  {
    "timestamp": "22/09/24 8:00",
    "demand": 30406.8943,
    "solar_generated": 17.09355258,
    "wind_generated": 72.90768766,
    "marketplace_to_demand": 33.22842577,
    "grid_to_demand": 34906.67113,
    "govt_battery_charge": 64.16175297
  },
  {
    "timestamp": "22/09/24 9:00",
    "demand": 36485.96069,
    "solar_generated": 17.298155,
    "wind_generated": 69.84311492,
    "marketplace_to_demand": 10.45636169,
    "grid_to_demand": 33452.97148,
    "govt_battery_charge": 35.78922346
  },
  {
    "timestamp": "22/09/24 10:00",
    "demand": 32830.12916,
    "solar_generated": 13.91835665,
    "wind_generated": 77.6634572,
    "marketplace_to_demand": 135.3062469,
    "grid_to_demand": 30646.79835,
    "govt_battery_charge": 24.69211185
  },
  {
    "timestamp": "22/09/24 11:00",
    "demand": 30595.11547,
    "solar_generated": 10.20005442,
    "wind_generated": 69.58200905,
    "marketplace_to_demand": 92.56864535,
    "grid_to_demand": 32830.18067,
    "govt_battery_charge": 20.51043306
  },
  {
    "timestamp": "22/09/24 12:00",
    "demand": 31880.13465,
    "solar_generated": 20.77630333,
    "wind_generated": 64.94599703,
    "marketplace_to_demand": 90.35049331,
    "grid_to_demand": 31650.54483,
    "govt_battery_charge": 79.93774777
  },
  {
    "timestamp": "22/09/24 13:00",
    "demand": 34950.46556,
    "solar_generated": 13.84741866,
    "wind_generated": 77.47522773,
    "marketplace_to_demand": 22.41415742,
    "grid_to_demand": 34278.54802,
    "govt_battery_charge": 64.0624629
  },
  {
    "timestamp": "22/09/24 14:00",
    "demand": 35807.91959,
    "solar_generated": 23.71213268,
    "wind_generated": 71.18807229,
    "marketplace_to_demand": 184.3014224,
    "grid_to_demand": 34274.64574,
    "govt_battery_charge": 83.49501579
  },
  {
    "timestamp": "22/09/24 15:00",
    "demand": 34005.10944,
    "solar_generated": 23.09815332,
    "wind_generated": 58.38660985,
    "marketplace_to_demand": 129.4673993,
    "grid_to_demand": 31727.46706,
    "govt_battery_charge": 36.01854333
  },
  {
    "timestamp": "22/09/24 16:00",
    "demand": 34217.3219,
    "solar_generated": 21.70761789,
    "wind_generated": 54.79244975,
    "marketplace_to_demand": 32.19658953,
    "grid_to_demand": 35883.83452,
    "govt_battery_charge": 60.33379255
  },
  {
    "timestamp": "22/09/24 17:00",
    "demand": 36281.48161,
    "solar_generated": 13.85185879,
    "wind_generated": 58.85514804,
    "marketplace_to_demand": 111.0812881,
    "grid_to_demand": 32263.95403,
    "govt_battery_charge": 12.66190261
  },
  {
    "timestamp": "22/09/24 18:00",
    "demand": 32199.98977,
    "solar_generated": 0,
    "wind_generated": 66.09213488,
    "marketplace_to_demand": 114.9057739,
    "grid_to_demand": 34780.86368,
    "govt_battery_charge": 2.437953739
  },
  {
    "timestamp": "22/09/24 19:00",
    "demand": 30074.30097,
    "solar_generated": 0,
    "wind_generated": 65.80948157,
    "marketplace_to_demand": 179.6687655,
    "grid_to_demand": 32797.13115,
    "govt_battery_charge": 96.02919628
  },
  {
    "timestamp": "22/09/24 20:00",
    "demand": 31672.48953,
    "solar_generated": 0,
    "wind_generated": 69.80533744,
    "marketplace_to_demand": 103.1467996,
    "grid_to_demand": 30585.51257,
    "govt_battery_charge": 76.14645623
  },
  {
    "timestamp": "22/09/24 21:00",
    "demand": 35864.45429,
    "solar_generated": 0,
    "wind_generated": 77.53245715,
    "marketplace_to_demand": 182.9961332,
    "grid_to_demand": 31936.86714,
    "govt_battery_charge": 3.284517222
  },
  {
    "timestamp": "22/09/24 22:00",
    "demand": 35545.95065,
    "solar_generated": 0,
    "wind_generated": 67.29106999,
    "marketplace_to_demand": 38.2454111,
    "grid_to_demand": 36186.66793,
    "govt_battery_charge": 11.50620376
  },
  {
    "timestamp": "22/09/24 23:00",
    "demand": 35671.47768,
    "solar_generated": 0,
    "wind_generated": 75.95785796,
    "marketplace_to_demand": 67.60479002,
    "grid_to_demand": 35827.71716,
    "govt_battery_charge": 42.20714793
  },
  {
    "timestamp": "23/09/24 0:00",
    "demand": 35387.31758,
    "solar_generated": 0,
    "wind_generated": 70.20891303,
    "marketplace_to_demand": 122.1724518,
    "grid_to_demand": 34463.80353,
    "govt_battery_charge": 29.62817006
  },
  {
    "timestamp": "23/09/24 1:00",
    "demand": 35935.10119,
    "solar_generated": 0,
    "wind_generated": 66.29542702,
    "marketplace_to_demand": 38.49665834,
    "grid_to_demand": 35217.53594,
    "govt_battery_charge": 18.23002882
  },
  {
    "timestamp": "23/09/24 2:00",
    "demand": 30501.01146,
    "solar_generated": 0,
    "wind_generated": 73.9374296,
    "marketplace_to_demand": 145.8954076,
    "grid_to_demand": 33253.15809,
    "govt_battery_charge": 58.67729641
  },
  {
    "timestamp": "23/09/24 3:00",
    "demand": 35884.5909,
    "solar_generated": 0,
    "wind_generated": 62.7618332,
    "marketplace_to_demand": 185.4836776,
    "grid_to_demand": 36358.88399,
    "govt_battery_charge": 28.7598879
  },
  {
    "timestamp": "23/09/24 4:00",
    "demand": 36031.48325,
    "solar_generated": 0,
    "wind_generated": 59.7890682,
    "marketplace_to_demand": 97.79232665,
    "grid_to_demand": 34146.62519,
    "govt_battery_charge": 38.76807326
  },
  {
    "timestamp": "23/09/24 5:00",
    "demand": 33285.27671,
    "solar_generated": 0,
    "wind_generated": 71.62153019,
    "marketplace_to_demand": 102.9417005,
    "grid_to_demand": 31819.32778,
    "govt_battery_charge": 81.39800293
  },
  {
    "timestamp": "23/09/24 6:00",
    "demand": 36382.79295,
    "solar_generated": 19.59958122,
    "wind_generated": 74.87926197,
    "marketplace_to_demand": 156.7623946,
    "grid_to_demand": 35618.59437,
    "govt_battery_charge": 26.9765109
  },
  {
    "timestamp": "23/09/24 7:00",
    "demand": 35489.95934,
    "solar_generated": 13.54842329,
    "wind_generated": 68.83600189,
    "marketplace_to_demand": 66.19786658,
    "grid_to_demand": 35243.65595,
    "govt_battery_charge": 14.15068633
  },
  {
    "timestamp": "23/09/24 8:00",
    "demand": 36455.37616,
    "solar_generated": 20.19676676,
    "wind_generated": 54.49515614,
    "marketplace_to_demand": 107.2698042,
    "grid_to_demand": 31055.37796,
    "govt_battery_charge": 23.50217947
  },
  {
    "timestamp": "23/09/24 9:00",
    "demand": 31936.13543,
    "solar_generated": 19.93893938,
    "wind_generated": 63.38714299,
    "marketplace_to_demand": 122.5557658,
    "grid_to_demand": 35992.89174,
    "govt_battery_charge": 8.354243434
  },
  {
    "timestamp": "23/09/24 10:00",
    "demand": 35913.76993,
    "solar_generated": 8.664113617,
    "wind_generated": 64.81602544,
    "marketplace_to_demand": 142.2211057,
    "grid_to_demand": 31558.30175,
    "govt_battery_charge": 79.73285399
  },
  {
    "timestamp": "23/09/24 11:00",
    "demand": 32912.23558,
    "solar_generated": 16.51642615,
    "wind_generated": 78.43805558,
    "marketplace_to_demand": 19.75844861,
    "grid_to_demand": 32340.79246,
    "govt_battery_charge": 60.69069931
  },
  {
    "timestamp": "23/09/24 12:00",
    "demand": 36077.96918,
    "solar_generated": 20.92711457,
    "wind_generated": 72.5385486,
    "marketplace_to_demand": 139.1019441,
    "grid_to_demand": 33164.44805,
    "govt_battery_charge": 7.625830611
  },
  {
    "timestamp": "23/09/24 13:00",
    "demand": 33396.25732,
    "solar_generated": 22.95706393,
    "wind_generated": 73.86019616,
    "marketplace_to_demand": 94.37321524,
    "grid_to_demand": 34994.98115,
    "govt_battery_charge": 35.09439905
  },
  {
    "timestamp": "23/09/24 14:00",
    "demand": 35588.61374,
    "solar_generated": 15.53383774,
    "wind_generated": 77.51507016,
    "marketplace_to_demand": 143.9020084,
    "grid_to_demand": 33650.59212,
    "govt_battery_charge": 77.44139221
  },
  {
    "timestamp": "23/09/24 15:00",
    "demand": 35279.42821,
    "solar_generated": 23.9096602,
    "wind_generated": 55.4664774,
    "marketplace_to_demand": 162.5866809,
    "grid_to_demand": 31785.06959,
    "govt_battery_charge": 45.14043442
  },
  {
    "timestamp": "23/09/24 16:00",
    "demand": 36591.25352,
    "solar_generated": 12.34874143,
    "wind_generated": 68.14368297,
    "marketplace_to_demand": 53.07404345,
    "grid_to_demand": 36309.29622,
    "govt_battery_charge": 90.36946629
  },
  {
    "timestamp": "23/09/24 17:00",
    "demand": 33809.29485,
    "solar_generated": 19.94253853,
    "wind_generated": 67.90300661,
    "marketplace_to_demand": 147.3571553,
    "grid_to_demand": 35525.82853,
    "govt_battery_charge": 32.33867662
  },
  {
    "timestamp": "23/09/24 18:00",
    "demand": 30899.1172,
    "solar_generated": 0,
    "wind_generated": 59.46678832,
    "marketplace_to_demand": 137.8035322,
    "grid_to_demand": 35840.11588,
    "govt_battery_charge": 89.60140757
  },
  {
    "timestamp": "23/09/24 19:00",
    "demand": 34393.61023,
    "solar_generated": 0,
    "wind_generated": 64.90679197,
    "marketplace_to_demand": 37.12722083,
    "grid_to_demand": 34755.68965,
    "govt_battery_charge": 72.50822274
  },
  {
    "timestamp": "23/09/24 20:00",
    "demand": 32434.39257,
    "solar_generated": 0,
    "wind_generated": 72.66849296,
    "marketplace_to_demand": 160.6982097,
    "grid_to_demand": 30640.50103,
    "govt_battery_charge": 24.12295945
  },
  {
    "timestamp": "23/09/24 21:00",
    "demand": 34904.55556,
    "solar_generated": 0,
    "wind_generated": 73.63743037,
    "marketplace_to_demand": 84.78512678,
    "grid_to_demand": 30245.42638,
    "govt_battery_charge": 70.05150901
  },
  {
    "timestamp": "23/09/24 22:00",
    "demand": 31459.8664,
    "solar_generated": 0,
    "wind_generated": 60.32635534,
    "marketplace_to_demand": 100.1258316,
    "grid_to_demand": 36184.67521,
    "govt_battery_charge": 9.062572319
  },
  {
    "timestamp": "23/09/24 23:00",
    "demand": 36001.87804,
    "solar_generated": 0,
    "wind_generated": 65.86436913,
    "marketplace_to_demand": 180.58337,
    "grid_to_demand": 35176.91308,
    "govt_battery_charge": 16.90334072
  },
  {
    "timestamp": "24/09/24 0:00",
    "demand": 33883.32431,
    "solar_generated": 0,
    "wind_generated": 74.14703317,
    "marketplace_to_demand": 7.505488827,
    "grid_to_demand": 35309.21001,
    "govt_battery_charge": 23.63667107
  },
  {
    "timestamp": "24/09/24 1:00",
    "demand": 32952.09097,
    "solar_generated": 0,
    "wind_generated": 66.41000875,
    "marketplace_to_demand": 109.438708,
    "grid_to_demand": 29958.14953,
    "govt_battery_charge": 1.345374868
  },
  {
    "timestamp": "24/09/24 2:00",
    "demand": 32048.17777,
    "solar_generated": 0,
    "wind_generated": 53.62056453,
    "marketplace_to_demand": 171.9307776,
    "grid_to_demand": 33063.3244,
    "govt_battery_charge": 6.051154659
  },
  {
    "timestamp": "24/09/24 3:00",
    "demand": 31777.50327,
    "solar_generated": 0,
    "wind_generated": 74.55523646,
    "marketplace_to_demand": 171.8070291,
    "grid_to_demand": 34806.68252,
    "govt_battery_charge": 44.08047311
  },
  {
    "timestamp": "24/09/24 4:00",
    "demand": 35528.83713,
    "solar_generated": 0,
    "wind_generated": 64.76143084,
    "marketplace_to_demand": 135.5723224,
    "grid_to_demand": 34932.99206,
    "govt_battery_charge": 95.88400782
  },
  {
    "timestamp": "24/09/24 5:00",
    "demand": 36465.92455,
    "solar_generated": 0,
    "wind_generated": 69.88998905,
    "marketplace_to_demand": 155.8707133,
    "grid_to_demand": 32201.87224,
    "govt_battery_charge": 35.2063852
  },
  {
    "timestamp": "24/09/24 6:00",
    "demand": 33047.64347,
    "solar_generated": 14.02620955,
    "wind_generated": 72.51220884,
    "marketplace_to_demand": 172.8777442,
    "grid_to_demand": 36023.39749,
    "govt_battery_charge": 41.97253707
  },
  {
    "timestamp": "24/09/24 7:00",
    "demand": 31277.27121,
    "solar_generated": 23.34524398,
    "wind_generated": 67.82197501,
    "marketplace_to_demand": 170.7966882,
    "grid_to_demand": 31151.55855,
    "govt_battery_charge": 88.90479938
  },
  {
    "timestamp": "24/09/24 8:00",
    "demand": 31394.67667,
    "solar_generated": 8.084041803,
    "wind_generated": 56.74344719,
    "marketplace_to_demand": 2.328103755,
    "grid_to_demand": 34778.2738,
    "govt_battery_charge": 54.38182147
  },
  {
    "timestamp": "24/09/24 9:00",
    "demand": 33741.25844,
    "solar_generated": 14.92243231,
    "wind_generated": 62.41516414,
    "marketplace_to_demand": 152.0521864,
    "grid_to_demand": 33506.57224,
    "govt_battery_charge": 67.76468443
  },
  {
    "timestamp": "24/09/24 10:00",
    "demand": 34001.21959,
    "solar_generated": 19.3482031,
    "wind_generated": 59.30805224,
    "marketplace_to_demand": 6.695893607,
    "grid_to_demand": 31266.93905,
    "govt_battery_charge": 14.69597691
  },
  {
    "timestamp": "24/09/24 11:00",
    "demand": 33466.71081,
    "solar_generated": 20.4575034,
    "wind_generated": 67.15495896,
    "marketplace_to_demand": 105.1228338,
    "grid_to_demand": 34556.04347,
    "govt_battery_charge": 79.13503824
  },
  {
    "timestamp": "24/09/24 12:00",
    "demand": 35801.0081,
    "solar_generated": 20.12116313,
    "wind_generated": 66.98321714,
    "marketplace_to_demand": 169.9587249,
    "grid_to_demand": 30335.38923,
    "govt_battery_charge": 98.11676274
  },
  {
    "timestamp": "24/09/24 13:00",
    "demand": 34785.40994,
    "solar_generated": 18.55960085,
    "wind_generated": 66.44555888,
    "marketplace_to_demand": 141.4499088,
    "grid_to_demand": 31667.42447,
    "govt_battery_charge": 73.92307132
  },
  {
    "timestamp": "24/09/24 14:00",
    "demand": 35107.60988,
    "solar_generated": 18.80781778,
    "wind_generated": 65.55680932,
    "marketplace_to_demand": 48.97351025,
    "grid_to_demand": 33228.45051,
    "govt_battery_charge": 3.678317454
  },
  {
    "timestamp": "24/09/24 15:00",
    "demand": 35790.24635,
    "solar_generated": 13.72719424,
    "wind_generated": 70.32482256,
    "marketplace_to_demand": 166.3639293,
    "grid_to_demand": 35641.9291,
    "govt_battery_charge": 95.12177598
  },
  {
    "timestamp": "24/09/24 16:00",
    "demand": 31537.44024,
    "solar_generated": 23.50094158,
    "wind_generated": 78.67311815,
    "marketplace_to_demand": 156.9310696,
    "grid_to_demand": 33928.77806,
    "govt_battery_charge": 42.61872815
  },
  {
    "timestamp": "24/09/24 17:00",
    "demand": 31027.01758,
    "solar_generated": 13.93394811,
    "wind_generated": 63.59937091,
    "marketplace_to_demand": 179.0699948,
    "grid_to_demand": 33677.01191,
    "govt_battery_charge": 99.40915537
  },
  {
    "timestamp": "24/09/24 18:00",
    "demand": 35254.65005,
    "solar_generated": 0,
    "wind_generated": 63.37792927,
    "marketplace_to_demand": 159.7690774,
    "grid_to_demand": 36430.04067,
    "govt_battery_charge": 82.13641132
  },
  {
    "timestamp": "24/09/24 19:00",
    "demand": 34573.62046,
    "solar_generated": 0,
    "wind_generated": 68.58559725,
    "marketplace_to_demand": 58.69511672,
    "grid_to_demand": 29913.46066,
    "govt_battery_charge": 51.16686857
  },
  {
    "timestamp": "24/09/24 20:00",
    "demand": 33072.04189,
    "solar_generated": 0,
    "wind_generated": 79.22772994,
    "marketplace_to_demand": 184.1210001,
    "grid_to_demand": 32172.7836,
    "govt_battery_charge": 35.51184024
  },
  {
    "timestamp": "24/09/24 21:00",
    "demand": 34843.48104,
    "solar_generated": 0,
    "wind_generated": 68.85433567,
    "marketplace_to_demand": 159.4010283,
    "grid_to_demand": 31285.62489,
    "govt_battery_charge": 72.17400238
  },
  {
    "timestamp": "24/09/24 22:00",
    "demand": 32541.31896,
    "solar_generated": 0,
    "wind_generated": 62.26315744,
    "marketplace_to_demand": 123.2948407,
    "grid_to_demand": 34953.25965,
    "govt_battery_charge": 6.65413
  },
  {
    "timestamp": "24/09/24 23:00",
    "demand": 35113.91895,
    "solar_generated": 0,
    "wind_generated": 56.06932812,
    "marketplace_to_demand": 111.1548708,
    "grid_to_demand": 30002.96854,
    "govt_battery_charge": 67.88246213
  },
  {
    "timestamp": "25/09/24 0:00",
    "demand": 36515.81729,
    "solar_generated": 0,
    "wind_generated": 59.16016653,
    "marketplace_to_demand": 144.1694717,
    "grid_to_demand": 30549.02979,
    "govt_battery_charge": 72.90940057
  },
  {
    "timestamp": "25/09/24 1:00",
    "demand": 30470.67223,
    "solar_generated": 0,
    "wind_generated": 70.38473298,
    "marketplace_to_demand": 5.682377637,
    "grid_to_demand": 34475.08381,
    "govt_battery_charge": 6.902362958
  },
  {
    "timestamp": "25/09/24 2:00",
    "demand": 35208.83516,
    "solar_generated": 0,
    "wind_generated": 63.91512177,
    "marketplace_to_demand": 84.19922932,
    "grid_to_demand": 30426.93164,
    "govt_battery_charge": 21.77565737
  },
  {
    "timestamp": "25/09/24 3:00",
    "demand": 33309.13461,
    "solar_generated": 0,
    "wind_generated": 55.04320761,
    "marketplace_to_demand": 65.01357448,
    "grid_to_demand": 33682.13413,
    "govt_battery_charge": 65.41577863
  },
  {
    "timestamp": "25/09/24 4:00",
    "demand": 33903.41323,
    "solar_generated": 0,
    "wind_generated": 61.38370229,
    "marketplace_to_demand": 197.1242137,
    "grid_to_demand": 32684.36849,
    "govt_battery_charge": 38.25158941
  },
  {
    "timestamp": "25/09/24 5:00",
    "demand": 32898.72801,
    "solar_generated": 0,
    "wind_generated": 62.33377019,
    "marketplace_to_demand": 98.91882295,
    "grid_to_demand": 30375.06561,
    "govt_battery_charge": 82.19128335
  },
  {
    "timestamp": "25/09/24 6:00",
    "demand": 36613.53243,
    "solar_generated": 13.94285786,
    "wind_generated": 57.88006536,
    "marketplace_to_demand": 50.28017458,
    "grid_to_demand": 35288.21596,
    "govt_battery_charge": 10.84707853
  },
  {
    "timestamp": "25/09/24 7:00",
    "demand": 31464.14757,
    "solar_generated": 11.43806293,
    "wind_generated": 67.44246609,
    "marketplace_to_demand": 161.9471914,
    "grid_to_demand": 34393.25297,
    "govt_battery_charge": 22.72211384
  },
  {
    "timestamp": "25/09/24 8:00",
    "demand": 34020.21591,
    "solar_generated": 20.89225797,
    "wind_generated": 63.48625664,
    "marketplace_to_demand": 142.2749984,
    "grid_to_demand": 33768.8095,
    "govt_battery_charge": 88.98887912
  },
  {
    "timestamp": "25/09/24 9:00",
    "demand": 34487.45925,
    "solar_generated": 7.226159919,
    "wind_generated": 61.59446906,
    "marketplace_to_demand": 48.51464764,
    "grid_to_demand": 34763.18137,
    "govt_battery_charge": 46.14244076
  },
  {
    "timestamp": "25/09/24 10:00",
    "demand": 35553.87796,
    "solar_generated": 23.99087896,
    "wind_generated": 79.65629164,
    "marketplace_to_demand": 49.19076769,
    "grid_to_demand": 34668.55316,
    "govt_battery_charge": 43.78398796
  },
  {
    "timestamp": "25/09/24 11:00",
    "demand": 33276.49204,
    "solar_generated": 12.85753127,
    "wind_generated": 75.9589796,
    "marketplace_to_demand": 48.58461973,
    "grid_to_demand": 34982.07314,
    "govt_battery_charge": 6.827843841
  },
  {
    "timestamp": "25/09/24 12:00",
    "demand": 34642.72427,
    "solar_generated": 20.06676892,
    "wind_generated": 64.3147138,
    "marketplace_to_demand": 116.751229,
    "grid_to_demand": 35719.59649,
    "govt_battery_charge": 74.14210087
  },
  {
    "timestamp": "25/09/24 13:00",
    "demand": 35390.67312,
    "solar_generated": 18.77843319,
    "wind_generated": 72.57349461,
    "marketplace_to_demand": 158.5451131,
    "grid_to_demand": 31575.06223,
    "govt_battery_charge": 38.54148795
  },
  {
    "timestamp": "25/09/24 14:00",
    "demand": 30189.06133,
    "solar_generated": 20.69084388,
    "wind_generated": 71.8282544,
    "marketplace_to_demand": 42.05270489,
    "grid_to_demand": 30710.14041,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "25/09/24 15:00",
    "demand": 35209.9441,
    "solar_generated": 6.261621267,
    "wind_generated": 55.29298213,
    "marketplace_to_demand": 153.3717027,
    "grid_to_demand": 30784.47808,
    "govt_battery_charge": 94.32399441
  },
  {
    "timestamp": "25/09/24 16:00",
    "demand": 36453.15054,
    "solar_generated": 11.83217307,
    "wind_generated": 55.44539672,
    "marketplace_to_demand": 159.9654223,
    "grid_to_demand": 32880.79374,
    "govt_battery_charge": 34.7751498
  },
  {
    "timestamp": "25/09/24 17:00",
    "demand": 33788.44484,
    "solar_generated": 6.65014265,
    "wind_generated": 67.68084051,
    "marketplace_to_demand": 149.8960369,
    "grid_to_demand": 31664.3086,
    "govt_battery_charge": 69.09041847
  },
  {
    "timestamp": "25/09/24 18:00",
    "demand": 33399.46178,
    "solar_generated": 0,
    "wind_generated": 74.34144405,
    "marketplace_to_demand": 8.730480023,
    "grid_to_demand": 30577.78177,
    "govt_battery_charge": 96.26166468
  },
  {
    "timestamp": "25/09/24 19:00",
    "demand": 36538.78552,
    "solar_generated": 0,
    "wind_generated": 71.89054254,
    "marketplace_to_demand": 196.6194811,
    "grid_to_demand": 35427.55235,
    "govt_battery_charge": 57.42852449
  },
  {
    "timestamp": "25/09/24 20:00",
    "demand": 34391.1264,
    "solar_generated": 0,
    "wind_generated": 74.93217251,
    "marketplace_to_demand": 62.52465754,
    "grid_to_demand": 34230.98517,
    "govt_battery_charge": 66.82208963
  },
  {
    "timestamp": "25/09/24 21:00",
    "demand": 34718.06845,
    "solar_generated": 0,
    "wind_generated": 59.67870551,
    "marketplace_to_demand": 163.7103158,
    "grid_to_demand": 34496.80616,
    "govt_battery_charge": 67.73949813
  },
  {
    "timestamp": "25/09/24 22:00",
    "demand": 30614.77117,
    "solar_generated": 0,
    "wind_generated": 67.41177349,
    "marketplace_to_demand": 143.3982259,
    "grid_to_demand": 32460.83361,
    "govt_battery_charge": 41.87027517
  },
  {
    "timestamp": "25/09/24 23:00",
    "demand": 31592.79249,
    "solar_generated": 0,
    "wind_generated": 74.84927307,
    "marketplace_to_demand": 177.5706442,
    "grid_to_demand": 34316.91426,
    "govt_battery_charge": 91.80805956
  },
  {
    "timestamp": "26/09/24 0:00",
    "demand": 36301.87388,
    "solar_generated": 0,
    "wind_generated": 62.33960759,
    "marketplace_to_demand": 88.49531853,
    "grid_to_demand": 33299.39556,
    "govt_battery_charge": 61.16624948
  },
  {
    "timestamp": "26/09/24 1:00",
    "demand": 32044.91018,
    "solar_generated": 0,
    "wind_generated": 74.15349287,
    "marketplace_to_demand": 177.0477275,
    "grid_to_demand": 30633.75188,
    "govt_battery_charge": 6.46952825
  },
  {
    "timestamp": "26/09/24 2:00",
    "demand": 35401.95135,
    "solar_generated": 0,
    "wind_generated": 64.57505206,
    "marketplace_to_demand": 51.26143436,
    "grid_to_demand": 34959.43748,
    "govt_battery_charge": 79.52414039
  },
  {
    "timestamp": "26/09/24 3:00",
    "demand": 36041.1918,
    "solar_generated": 0,
    "wind_generated": 68.84201566,
    "marketplace_to_demand": 176.6683821,
    "grid_to_demand": 33604.18084,
    "govt_battery_charge": 29.48897475
  },
  {
    "timestamp": "26/09/24 4:00",
    "demand": 32748.90442,
    "solar_generated": 0,
    "wind_generated": 74.974895,
    "marketplace_to_demand": 63.15883771,
    "grid_to_demand": 30686.55673,
    "govt_battery_charge": 49.04689077
  },
  {
    "timestamp": "26/09/24 5:00",
    "demand": 30114.30501,
    "solar_generated": 0,
    "wind_generated": 56.56440842,
    "marketplace_to_demand": 182.8025491,
    "grid_to_demand": 35020.22233,
    "govt_battery_charge": 2.378514186
  },
  {
    "timestamp": "26/09/24 6:00",
    "demand": 33122.33297,
    "solar_generated": 15.4698265,
    "wind_generated": 57.53658674,
    "marketplace_to_demand": 196.9395708,
    "grid_to_demand": 30590.37644,
    "govt_battery_charge": 47.49935869
  },
  {
    "timestamp": "26/09/24 7:00",
    "demand": 33649.05187,
    "solar_generated": 7.655212567,
    "wind_generated": 57.77088185,
    "marketplace_to_demand": 160.3213033,
    "grid_to_demand": 30561.12324,
    "govt_battery_charge": 3.83646863
  },
  {
    "timestamp": "26/09/24 8:00",
    "demand": 36313.13873,
    "solar_generated": 21.88704861,
    "wind_generated": 62.89632015,
    "marketplace_to_demand": 9.897033179,
    "grid_to_demand": 31962.88832,
    "govt_battery_charge": 79.28204182
  },
  {
    "timestamp": "26/09/24 9:00",
    "demand": 33024.79494,
    "solar_generated": 6.239739887,
    "wind_generated": 61.13287387,
    "marketplace_to_demand": 13.62669046,
    "grid_to_demand": 34071.80713,
    "govt_battery_charge": 15.99943921
  },
  {
    "timestamp": "26/09/24 10:00",
    "demand": 32020.44805,
    "solar_generated": 7.630906202,
    "wind_generated": 64.8089329,
    "marketplace_to_demand": 38.73204176,
    "grid_to_demand": 35185.421,
    "govt_battery_charge": 69.59706198
  },
  {
    "timestamp": "26/09/24 11:00",
    "demand": 36467.40654,
    "solar_generated": 13.27357197,
    "wind_generated": 60.39092984,
    "marketplace_to_demand": 156.7395348,
    "grid_to_demand": 31205.86966,
    "govt_battery_charge": 21.09090412
  },
  {
    "timestamp": "26/09/24 12:00",
    "demand": 32375.36937,
    "solar_generated": 17.81425273,
    "wind_generated": 73.54176033,
    "marketplace_to_demand": 117.5253209,
    "grid_to_demand": 31366.91135,
    "govt_battery_charge": 97.22422041
  },
  {
    "timestamp": "26/09/24 13:00",
    "demand": 31343.26288,
    "solar_generated": 11.08637773,
    "wind_generated": 71.27284574,
    "marketplace_to_demand": 86.3530129,
    "grid_to_demand": 35438.12408,
    "govt_battery_charge": 40.40348938
  },
  {
    "timestamp": "26/09/24 14:00",
    "demand": 34941.15281,
    "solar_generated": 9.902451789,
    "wind_generated": 59.28913021,
    "marketplace_to_demand": 28.16809145,
    "grid_to_demand": 35554.90613,
    "govt_battery_charge": 40.49017952
  },
  {
    "timestamp": "26/09/24 15:00",
    "demand": 36515.2077,
    "solar_generated": 9.210731198,
    "wind_generated": 72.95392006,
    "marketplace_to_demand": 1.518149944,
    "grid_to_demand": 33163.65873,
    "govt_battery_charge": 37.50723077
  },
  {
    "timestamp": "26/09/24 16:00",
    "demand": 33190.71351,
    "solar_generated": 10.38782706,
    "wind_generated": 57.01492388,
    "marketplace_to_demand": 131.8007675,
    "grid_to_demand": 30426.3218,
    "govt_battery_charge": 88.10357968
  },
  {
    "timestamp": "26/09/24 17:00",
    "demand": 34850.94512,
    "solar_generated": 18.31678975,
    "wind_generated": 65.24502641,
    "marketplace_to_demand": 146.9751174,
    "grid_to_demand": 34709.70267,
    "govt_battery_charge": 34.48090655
  },
  {
    "timestamp": "26/09/24 18:00",
    "demand": 31258.88923,
    "solar_generated": 0,
    "wind_generated": 68.6218795,
    "marketplace_to_demand": 165.8807682,
    "grid_to_demand": 36322.27033,
    "govt_battery_charge": 50.05245533
  },
  {
    "timestamp": "26/09/24 19:00",
    "demand": 33638.88516,
    "solar_generated": 0,
    "wind_generated": 59.90639981,
    "marketplace_to_demand": 191.3227344,
    "grid_to_demand": 34698.19689,
    "govt_battery_charge": 52.60854575
  },
  {
    "timestamp": "26/09/24 20:00",
    "demand": 33758.59504,
    "solar_generated": 0,
    "wind_generated": 57.62628435,
    "marketplace_to_demand": 140.3963587,
    "grid_to_demand": 32791.54895,
    "govt_battery_charge": 38.94507579
  },
  {
    "timestamp": "26/09/24 21:00",
    "demand": 30640.36639,
    "solar_generated": 0,
    "wind_generated": 78.80641995,
    "marketplace_to_demand": 149.9063244,
    "grid_to_demand": 34440.6594,
    "govt_battery_charge": 4.224869015
  },
  {
    "timestamp": "26/09/24 22:00",
    "demand": 30348.35631,
    "solar_generated": 0,
    "wind_generated": 64.69433653,
    "marketplace_to_demand": 104.4577099,
    "grid_to_demand": 35503.15877,
    "govt_battery_charge": 89.34632637
  },
  {
    "timestamp": "26/09/24 23:00",
    "demand": 30131.19622,
    "solar_generated": 0,
    "wind_generated": 59.12660925,
    "marketplace_to_demand": 12.06568098,
    "grid_to_demand": 36490.20344,
    "govt_battery_charge": 3.804019219
  },
  {
    "timestamp": "27/09/24 0:00",
    "demand": 32504.20428,
    "solar_generated": 0,
    "wind_generated": 57.01041364,
    "marketplace_to_demand": 124.7055554,
    "grid_to_demand": 31255.41301,
    "govt_battery_charge": 66.14619138
  },
  {
    "timestamp": "27/09/24 1:00",
    "demand": 36454.76537,
    "solar_generated": 0,
    "wind_generated": 75.46452204,
    "marketplace_to_demand": 49.576972,
    "grid_to_demand": 34354.83669,
    "govt_battery_charge": 18.7188589
  },
  {
    "timestamp": "27/09/24 2:00",
    "demand": 30609.02759,
    "solar_generated": 0,
    "wind_generated": 55.89422977,
    "marketplace_to_demand": 44.783942,
    "grid_to_demand": 34974.44122,
    "govt_battery_charge": 66.8497313
  },
  {
    "timestamp": "27/09/24 3:00",
    "demand": 34322.72396,
    "solar_generated": 0,
    "wind_generated": 74.668519,
    "marketplace_to_demand": 24.52428766,
    "grid_to_demand": 33735.31449,
    "govt_battery_charge": 24.5669871
  },
  {
    "timestamp": "27/09/24 4:00",
    "demand": 34723.69005,
    "solar_generated": 0,
    "wind_generated": 59.97791693,
    "marketplace_to_demand": 65.45053547,
    "grid_to_demand": 31616.90646,
    "govt_battery_charge": 49.03384042
  },
  {
    "timestamp": "27/09/24 5:00",
    "demand": 34451.92872,
    "solar_generated": 0,
    "wind_generated": 78.9468102,
    "marketplace_to_demand": 45.68213013,
    "grid_to_demand": 30564.33793,
    "govt_battery_charge": 38.74605175
  },
  {
    "timestamp": "27/09/24 6:00",
    "demand": 30377.3091,
    "solar_generated": 18.09032376,
    "wind_generated": 72.67609096,
    "marketplace_to_demand": 74.13893187,
    "grid_to_demand": 34239.45346,
    "govt_battery_charge": 60.12870292
  },
  {
    "timestamp": "27/09/24 7:00",
    "demand": 33785.58374,
    "solar_generated": 22.81926287,
    "wind_generated": 58.97622159,
    "marketplace_to_demand": 17.49076728,
    "grid_to_demand": 35296.17698,
    "govt_battery_charge": 96.13208989
  },
  {
    "timestamp": "27/09/24 8:00",
    "demand": 30899.34976,
    "solar_generated": 10.00114309,
    "wind_generated": 73.26929017,
    "marketplace_to_demand": 71.41797509,
    "grid_to_demand": 31303.98637,
    "govt_battery_charge": 28.1810218
  },
  {
    "timestamp": "27/09/24 9:00",
    "demand": 30125.92032,
    "solar_generated": 13.73570376,
    "wind_generated": 72.31010222,
    "marketplace_to_demand": 14.56986273,
    "grid_to_demand": 32310.0842,
    "govt_battery_charge": 90.37768181
  },
  {
    "timestamp": "27/09/24 10:00",
    "demand": 32866.03652,
    "solar_generated": 18.950343,
    "wind_generated": 62.65762939,
    "marketplace_to_demand": 197.1487182,
    "grid_to_demand": 36505.82019,
    "govt_battery_charge": 91.22721645
  },
  {
    "timestamp": "27/09/24 11:00",
    "demand": 33229.8508,
    "solar_generated": 8.908871525,
    "wind_generated": 58.48902764,
    "marketplace_to_demand": 70.36833682,
    "grid_to_demand": 32598.68174,
    "govt_battery_charge": 39.53020533
  },
  {
    "timestamp": "27/09/24 12:00",
    "demand": 35898.16428,
    "solar_generated": 17.92875657,
    "wind_generated": 57.62206995,
    "marketplace_to_demand": 132.958431,
    "grid_to_demand": 32528.02436,
    "govt_battery_charge": 54.27061114
  },
  {
    "timestamp": "27/09/24 13:00",
    "demand": 30604.65843,
    "solar_generated": 19.90202381,
    "wind_generated": 68.27991811,
    "marketplace_to_demand": 169.4153285,
    "grid_to_demand": 34515.38171,
    "govt_battery_charge": 16.5256484
  },
  {
    "timestamp": "27/09/24 14:00",
    "demand": 34381.24508,
    "solar_generated": 19.47023957,
    "wind_generated": 63.07115787,
    "marketplace_to_demand": 10.32801388,
    "grid_to_demand": 30038.80442,
    "govt_battery_charge": 81.46931247
  },
  {
    "timestamp": "27/09/24 15:00",
    "demand": 34597.75425,
    "solar_generated": 16.93891501,
    "wind_generated": 66.82882203,
    "marketplace_to_demand": 105.5602122,
    "grid_to_demand": 32486.55406,
    "govt_battery_charge": 70.9544667
  },
  {
    "timestamp": "27/09/24 16:00",
    "demand": 32731.09645,
    "solar_generated": 15.68479563,
    "wind_generated": 78.98102626,
    "marketplace_to_demand": 77.29805657,
    "grid_to_demand": 33586.36997,
    "govt_battery_charge": 38.84558449
  },
  {
    "timestamp": "27/09/24 17:00",
    "demand": 32023.39968,
    "solar_generated": 13.2570809,
    "wind_generated": 64.18122705,
    "marketplace_to_demand": 131.4104142,
    "grid_to_demand": 33359.80823,
    "govt_battery_charge": 33.4281153
  },
  {
    "timestamp": "27/09/24 18:00",
    "demand": 35976.44882,
    "solar_generated": 0,
    "wind_generated": 61.08433316,
    "marketplace_to_demand": 145.9449352,
    "grid_to_demand": 31275.59995,
    "govt_battery_charge": 43.32792625
  },
  {
    "timestamp": "27/09/24 19:00",
    "demand": 31849.02453,
    "solar_generated": 0,
    "wind_generated": 54.80902777,
    "marketplace_to_demand": 92.52042666,
    "grid_to_demand": 35069.83817,
    "govt_battery_charge": 55.17464817
  },
  {
    "timestamp": "27/09/24 20:00",
    "demand": 30738.69454,
    "solar_generated": 0,
    "wind_generated": 74.52068316,
    "marketplace_to_demand": 42.28281105,
    "grid_to_demand": 32573.66592,
    "govt_battery_charge": 3.955062118
  },
  {
    "timestamp": "27/09/24 21:00",
    "demand": 29988.74545,
    "solar_generated": 0,
    "wind_generated": 61.05384785,
    "marketplace_to_demand": 155.1349575,
    "grid_to_demand": 30613.67216,
    "govt_battery_charge": 54.18515738
  },
  {
    "timestamp": "27/09/24 22:00",
    "demand": 35365.40646,
    "solar_generated": 0,
    "wind_generated": 62.88327841,
    "marketplace_to_demand": 94.39616419,
    "grid_to_demand": 30377.26487,
    "govt_battery_charge": 73.34792547
  },
  {
    "timestamp": "27/09/24 23:00",
    "demand": 35387.06852,
    "solar_generated": 0,
    "wind_generated": 77.87186865,
    "marketplace_to_demand": 95.28452463,
    "grid_to_demand": 34036.46393,
    "govt_battery_charge": 57.7874748
  },
  {
    "timestamp": "28/09/24 0:00",
    "demand": 32672.83985,
    "solar_generated": 0,
    "wind_generated": 68.15147067,
    "marketplace_to_demand": 14.55273349,
    "grid_to_demand": 36517.84499,
    "govt_battery_charge": 39.23122004
  },
  {
    "timestamp": "28/09/24 1:00",
    "demand": 30358.8164,
    "solar_generated": 0,
    "wind_generated": 58.91026462,
    "marketplace_to_demand": 95.4693903,
    "grid_to_demand": 31779.56179,
    "govt_battery_charge": 15.71641776
  },
  {
    "timestamp": "28/09/24 2:00",
    "demand": 36456.77932,
    "solar_generated": 0,
    "wind_generated": 60.4633263,
    "marketplace_to_demand": 47.35873865,
    "grid_to_demand": 32647.37087,
    "govt_battery_charge": 78.43822276
  },
  {
    "timestamp": "28/09/24 3:00",
    "demand": 31743.13246,
    "solar_generated": 0,
    "wind_generated": 61.71800354,
    "marketplace_to_demand": 182.4929811,
    "grid_to_demand": 35535.53272,
    "govt_battery_charge": 25.35406436
  },
  {
    "timestamp": "28/09/24 4:00",
    "demand": 34125.74529,
    "solar_generated": 0,
    "wind_generated": 68.82554863,
    "marketplace_to_demand": 16.7046549,
    "grid_to_demand": 35407.7851,
    "govt_battery_charge": 31.74423799
  },
  {
    "timestamp": "28/09/24 5:00",
    "demand": 34096.56341,
    "solar_generated": 0,
    "wind_generated": 68.14507646,
    "marketplace_to_demand": 180.0153632,
    "grid_to_demand": 31582.6214,
    "govt_battery_charge": 93.04553892
  },
  {
    "timestamp": "28/09/24 6:00",
    "demand": 34428.85935,
    "solar_generated": 6.605087783,
    "wind_generated": 65.92024936,
    "marketplace_to_demand": 8.365807667,
    "grid_to_demand": 31934.31997,
    "govt_battery_charge": 85.48365983
  },
  {
    "timestamp": "28/09/24 7:00",
    "demand": 35208.97266,
    "solar_generated": 6.348345147,
    "wind_generated": 61.81872731,
    "marketplace_to_demand": 80.24919004,
    "grid_to_demand": 36535.75126,
    "govt_battery_charge": 8.060994611
  },
  {
    "timestamp": "28/09/24 8:00",
    "demand": 33710.8442,
    "solar_generated": 10.92016703,
    "wind_generated": 71.71047586,
    "marketplace_to_demand": 79.87380661,
    "grid_to_demand": 32449.78557,
    "govt_battery_charge": 7.310150944
  },
  {
    "timestamp": "28/09/24 9:00",
    "demand": 32011.16197,
    "solar_generated": 14.68249,
    "wind_generated": 71.47350437,
    "marketplace_to_demand": 1.274218923,
    "grid_to_demand": 36085.32792,
    "govt_battery_charge": 59.70183398
  },
  {
    "timestamp": "28/09/24 10:00",
    "demand": 30780.93642,
    "solar_generated": 21.11006353,
    "wind_generated": 57.72137248,
    "marketplace_to_demand": 191.675871,
    "grid_to_demand": 32934.70455,
    "govt_battery_charge": 74.08801153
  },
  {
    "timestamp": "28/09/24 11:00",
    "demand": 36302.97358,
    "solar_generated": 21.18580247,
    "wind_generated": 74.50608447,
    "marketplace_to_demand": 134.031532,
    "grid_to_demand": 35190.40195,
    "govt_battery_charge": 38.13576335
  },
  {
    "timestamp": "28/09/24 12:00",
    "demand": 35773.8506,
    "solar_generated": 11.11429719,
    "wind_generated": 59.54012219,
    "marketplace_to_demand": 171.4227589,
    "grid_to_demand": 35815.05069,
    "govt_battery_charge": 1.359504052
  },
  {
    "timestamp": "28/09/24 13:00",
    "demand": 34828.40751,
    "solar_generated": 19.29744848,
    "wind_generated": 59.28077129,
    "marketplace_to_demand": 159.1011425,
    "grid_to_demand": 33554.90948,
    "govt_battery_charge": 26.7614686
  },
  {
    "timestamp": "28/09/24 14:00",
    "demand": 34451.41529,
    "solar_generated": 19.24410831,
    "wind_generated": 59.03427907,
    "marketplace_to_demand": 136.1847118,
    "grid_to_demand": 32078.37245,
    "govt_battery_charge": 30.40452972
  },
  {
    "timestamp": "28/09/24 15:00",
    "demand": 30011.17164,
    "solar_generated": 18.64659793,
    "wind_generated": 73.15860908,
    "marketplace_to_demand": 189.1433918,
    "grid_to_demand": 33184.24291,
    "govt_battery_charge": 30.58617167
  },
  {
    "timestamp": "28/09/24 16:00",
    "demand": 30487.25236,
    "solar_generated": 21.03743382,
    "wind_generated": 55.49281785,
    "marketplace_to_demand": 29.94053287,
    "grid_to_demand": 35378.86965,
    "govt_battery_charge": 80.55261551
  },
  {
    "timestamp": "28/09/24 17:00",
    "demand": 34797.48716,
    "solar_generated": 9.517025214,
    "wind_generated": 65.64357912,
    "marketplace_to_demand": 167.6252697,
    "grid_to_demand": 32950.60818,
    "govt_battery_charge": 51.78797954
  },
  {
    "timestamp": "28/09/24 18:00",
    "demand": 33077.13264,
    "solar_generated": 0,
    "wind_generated": 79.48373785,
    "marketplace_to_demand": 28.10164022,
    "grid_to_demand": 30434.24946,
    "govt_battery_charge": 30.06053584
  },
  {
    "timestamp": "28/09/24 19:00",
    "demand": 34468.75802,
    "solar_generated": 0,
    "wind_generated": 58.81219745,
    "marketplace_to_demand": 91.42933953,
    "grid_to_demand": 31527.492,
    "govt_battery_charge": 19.88978338
  },
  {
    "timestamp": "28/09/24 20:00",
    "demand": 32874.03884,
    "solar_generated": 0,
    "wind_generated": 76.32519773,
    "marketplace_to_demand": 121.5108099,
    "grid_to_demand": 31027.53273,
    "govt_battery_charge": 89.32540868
  },
  {
    "timestamp": "28/09/24 21:00",
    "demand": 31551.38286,
    "solar_generated": 0,
    "wind_generated": 67.0971597,
    "marketplace_to_demand": 133.567406,
    "grid_to_demand": 30110.88984,
    "govt_battery_charge": 34.36722041
  },
  {
    "timestamp": "28/09/24 22:00",
    "demand": 32316.33145,
    "solar_generated": 0,
    "wind_generated": 63.19911765,
    "marketplace_to_demand": 10.21430796,
    "grid_to_demand": 34727.62381,
    "govt_battery_charge": 66.35520546
  },
  {
    "timestamp": "28/09/24 23:00",
    "demand": 33199.17455,
    "solar_generated": 0,
    "wind_generated": 65.42322182,
    "marketplace_to_demand": 125.6597667,
    "grid_to_demand": 34937.58642,
    "govt_battery_charge": 98.4256089
  },
  {
    "timestamp": "29/09/24 0:00",
    "demand": 35871.47621,
    "solar_generated": 0,
    "wind_generated": 55.05422667,
    "marketplace_to_demand": 190.021009,
    "grid_to_demand": 35698.5924,
    "govt_battery_charge": 43.54101019
  },
  {
    "timestamp": "29/09/24 1:00",
    "demand": 32311.19442,
    "solar_generated": 0,
    "wind_generated": 77.5354371,
    "marketplace_to_demand": 158.0333429,
    "grid_to_demand": 30753.02209,
    "govt_battery_charge": 92.66374731
  },
  {
    "timestamp": "29/09/24 2:00",
    "demand": 35382.90737,
    "solar_generated": 0,
    "wind_generated": 64.47509039,
    "marketplace_to_demand": 56.59249341,
    "grid_to_demand": 32622.06025,
    "govt_battery_charge": 31.46772007
  },
  {
    "timestamp": "29/09/24 3:00",
    "demand": 34868.84659,
    "solar_generated": 0,
    "wind_generated": 68.1154432,
    "marketplace_to_demand": 68.999924,
    "grid_to_demand": 36421.46803,
    "govt_battery_charge": 73.52065222
  },
  {
    "timestamp": "29/09/24 4:00",
    "demand": 34303.7379,
    "solar_generated": 0,
    "wind_generated": 53.84811538,
    "marketplace_to_demand": 171.2965637,
    "grid_to_demand": 34688.24001,
    "govt_battery_charge": 23.62205903
  },
  {
    "timestamp": "29/09/24 5:00",
    "demand": 33238.68873,
    "solar_generated": 0,
    "wind_generated": 69.08471456,
    "marketplace_to_demand": 154.8276561,
    "grid_to_demand": 32921.54305,
    "govt_battery_charge": 98.22494038
  },
  {
    "timestamp": "29/09/24 6:00",
    "demand": 34346.90331,
    "solar_generated": 23.70826636,
    "wind_generated": 70.04248844,
    "marketplace_to_demand": 198.5442699,
    "grid_to_demand": 32274.14138,
    "govt_battery_charge": 90.98860775
  },
  {
    "timestamp": "29/09/24 7:00",
    "demand": 36454.37119,
    "solar_generated": 16.87219357,
    "wind_generated": 66.65766073,
    "marketplace_to_demand": 122.5564591,
    "grid_to_demand": 35649.19461,
    "govt_battery_charge": 35.48367681
  },
  {
    "timestamp": "29/09/24 8:00",
    "demand": 31336.40347,
    "solar_generated": 10.50853779,
    "wind_generated": 56.96075532,
    "marketplace_to_demand": 82.36243361,
    "grid_to_demand": 33119.12784,
    "govt_battery_charge": 23.88765682
  },
  {
    "timestamp": "29/09/24 9:00",
    "demand": 31916.73367,
    "solar_generated": 13.70234995,
    "wind_generated": 60.32804935,
    "marketplace_to_demand": 22.71903843,
    "grid_to_demand": 30299.98187,
    "govt_battery_charge": 47.68360287
  },
  {
    "timestamp": "29/09/24 10:00",
    "demand": 33658.48885,
    "solar_generated": 12.59510059,
    "wind_generated": 59.78979444,
    "marketplace_to_demand": 131.4479763,
    "grid_to_demand": 34204.13819,
    "govt_battery_charge": 89.62569676
  },
  {
    "timestamp": "29/09/24 11:00",
    "demand": 31880.94342,
    "solar_generated": 19.6712999,
    "wind_generated": 78.83995006,
    "marketplace_to_demand": 70.30533157,
    "grid_to_demand": 35053.97622,
    "govt_battery_charge": 70.54036507
  },
  {
    "timestamp": "29/09/24 12:00",
    "demand": 31959.87214,
    "solar_generated": 21.2224653,
    "wind_generated": 58.67784743,
    "marketplace_to_demand": 38.55522573,
    "grid_to_demand": 33549.24284,
    "govt_battery_charge": 37.09228948
  },
  {
    "timestamp": "29/09/24 13:00",
    "demand": 30599.82397,
    "solar_generated": 20.62494294,
    "wind_generated": 74.23413713,
    "marketplace_to_demand": 84.30559636,
    "grid_to_demand": 33416.37809,
    "govt_battery_charge": 24.99242886
  },
  {
    "timestamp": "29/09/24 14:00",
    "demand": 32385.74585,
    "solar_generated": 14.7527155,
    "wind_generated": 69.17967385,
    "marketplace_to_demand": 75.34927348,
    "grid_to_demand": 33494.34922,
    "govt_battery_charge": 20.47351748
  },
  {
    "timestamp": "29/09/24 15:00",
    "demand": 32858.83069,
    "solar_generated": 19.01428846,
    "wind_generated": 75.33691581,
    "marketplace_to_demand": 19.03622647,
    "grid_to_demand": 30436.58817,
    "govt_battery_charge": 20.86678566
  },
  {
    "timestamp": "29/09/24 16:00",
    "demand": 34768.42656,
    "solar_generated": 7.129073041,
    "wind_generated": 75.76679293,
    "marketplace_to_demand": 165.7144397,
    "grid_to_demand": 34614.22135,
    "govt_battery_charge": 72.30785619
  },
  {
    "timestamp": "29/09/24 17:00",
    "demand": 31513.9077,
    "solar_generated": 18.74255726,
    "wind_generated": 69.14032618,
    "marketplace_to_demand": 88.17153596,
    "grid_to_demand": 36122.03283,
    "govt_battery_charge": 41.25561665
  },
  {
    "timestamp": "29/09/24 18:00",
    "demand": 33880.68825,
    "solar_generated": 0,
    "wind_generated": 56.25857695,
    "marketplace_to_demand": 23.44418956,
    "grid_to_demand": 33262.53092,
    "govt_battery_charge": 34.77163484
  },
  {
    "timestamp": "29/09/24 19:00",
    "demand": 31511.59499,
    "solar_generated": 0,
    "wind_generated": 57.99962596,
    "marketplace_to_demand": 51.30017222,
    "grid_to_demand": 31809.60693,
    "govt_battery_charge": 37.96431385
  },
  {
    "timestamp": "29/09/24 20:00",
    "demand": 33029.1601,
    "solar_generated": 0,
    "wind_generated": 62.34226332,
    "marketplace_to_demand": 178.6122866,
    "grid_to_demand": 33856.49792,
    "govt_battery_charge": 75.87889977
  },
  {
    "timestamp": "29/09/24 21:00",
    "demand": 35328.6154,
    "solar_generated": 0,
    "wind_generated": 57.60683545,
    "marketplace_to_demand": 84.95142869,
    "grid_to_demand": 31171.56623,
    "govt_battery_charge": 26.06461188
  },
  {
    "timestamp": "29/09/24 22:00",
    "demand": 31979.52894,
    "solar_generated": 0,
    "wind_generated": 57.83672532,
    "marketplace_to_demand": 75.95537696,
    "grid_to_demand": 34903.1235,
    "govt_battery_charge": 57.9695756
  },
  {
    "timestamp": "29/09/24 23:00",
    "demand": 34054.69758,
    "solar_generated": 0,
    "wind_generated": 73.64897463,
    "marketplace_to_demand": 170.6288198,
    "grid_to_demand": 31105.45169,
    "govt_battery_charge": 13.75705925
  },
  {
    "timestamp": "30/09/24 0:00",
    "demand": 30400.21183,
    "solar_generated": 0,
    "wind_generated": 76.48138433,
    "marketplace_to_demand": 100.6942343,
    "grid_to_demand": 31091.23273,
    "govt_battery_charge": 18.9997446
  },
  {
    "timestamp": "30/09/24 1:00",
    "demand": 36014.0886,
    "solar_generated": 0,
    "wind_generated": 77.44046081,
    "marketplace_to_demand": 74.72710144,
    "grid_to_demand": 36050.41403,
    "govt_battery_charge": 77.56428078
  },
  {
    "timestamp": "30/09/24 2:00",
    "demand": 30716.72565,
    "solar_generated": 0,
    "wind_generated": 59.20749109,
    "marketplace_to_demand": 65.00615918,
    "grid_to_demand": 33493.77021,
    "govt_battery_charge": 22.8798376
  },
  {
    "timestamp": "30/09/24 3:00",
    "demand": 34242.60908,
    "solar_generated": 0,
    "wind_generated": 78.17269807,
    "marketplace_to_demand": 22.67446795,
    "grid_to_demand": 34355.1249,
    "govt_battery_charge": 26.9253826
  },
  {
    "timestamp": "30/09/24 4:00",
    "demand": 31091.89124,
    "solar_generated": 0,
    "wind_generated": 76.31046266,
    "marketplace_to_demand": 83.62311872,
    "grid_to_demand": 33345.51759,
    "govt_battery_charge": 8.115257628
  },
  {
    "timestamp": "30/09/24 5:00",
    "demand": 31893.19127,
    "solar_generated": 0,
    "wind_generated": 69.36399247,
    "marketplace_to_demand": 83.68157908,
    "grid_to_demand": 32495.20778,
    "govt_battery_charge": 37.07045759
  },
  {
    "timestamp": "30/09/24 6:00",
    "demand": 36100.45529,
    "solar_generated": 20.68453752,
    "wind_generated": 73.3640112,
    "marketplace_to_demand": 59.70636547,
    "grid_to_demand": 33178.57912,
    "govt_battery_charge": 40.30795288
  },
  {
    "timestamp": "30/09/24 7:00",
    "demand": 34681.36639,
    "solar_generated": 18.78902282,
    "wind_generated": 75.06748118,
    "marketplace_to_demand": 0.71923836,
    "grid_to_demand": 31938.16277,
    "govt_battery_charge": 15.89227408
  },
  {
    "timestamp": "30/09/24 8:00",
    "demand": 32402.3625,
    "solar_generated": 23.79410301,
    "wind_generated": 71.87404935,
    "marketplace_to_demand": 29.68376759,
    "grid_to_demand": 34218.82008,
    "govt_battery_charge": 28.148876
  },
  {
    "timestamp": "30/09/24 9:00",
    "demand": 35165.77372,
    "solar_generated": 13.42884921,
    "wind_generated": 54.7287661,
    "marketplace_to_demand": 165.4382832,
    "grid_to_demand": 35170.7328,
    "govt_battery_charge": 98.1010664
  },
  {
    "timestamp": "30/09/24 10:00",
    "demand": 32855.05491,
    "solar_generated": 15.34605553,
    "wind_generated": 77.21769085,
    "marketplace_to_demand": 57.73267447,
    "grid_to_demand": 33659.80289,
    "govt_battery_charge": 55.56089559
  },
  {
    "timestamp": "30/09/24 11:00",
    "demand": 32202.45757,
    "solar_generated": 6.615428222,
    "wind_generated": 59.00312244,
    "marketplace_to_demand": 167.0938351,
    "grid_to_demand": 32119.92499,
    "govt_battery_charge": 15.97398088
  },
  {
    "timestamp": "30/09/24 12:00",
    "demand": 33440.21075,
    "solar_generated": 9.54804936,
    "wind_generated": 60.16626022,
    "marketplace_to_demand": 34.73367342,
    "grid_to_demand": 35765.49355,
    "govt_battery_charge": 37.05739174
  },
  {
    "timestamp": "30/09/24 13:00",
    "demand": 36479.96537,
    "solar_generated": 21.52193374,
    "wind_generated": 70.5040257,
    "marketplace_to_demand": 69.73383909,
    "grid_to_demand": 30363.463,
    "govt_battery_charge": 71.33223059
  },
  {
    "timestamp": "30/09/24 14:00",
    "demand": 36203.14602,
    "solar_generated": 14.63040216,
    "wind_generated": 57.08861228,
    "marketplace_to_demand": 28.51391751,
    "grid_to_demand": 33769.79973,
    "govt_battery_charge": 95.96242002
  },
  {
    "timestamp": "30/09/24 15:00",
    "demand": 33011.59406,
    "solar_generated": 9.841364245,
    "wind_generated": 78.13112753,
    "marketplace_to_demand": 106.9260808,
    "grid_to_demand": 31339.04718,
    "govt_battery_charge": 21.29740004
  },
  {
    "timestamp": "30/09/24 16:00",
    "demand": 30530.90116,
    "solar_generated": 9.438057318,
    "wind_generated": 76.7013187,
    "marketplace_to_demand": 29.23294435,
    "grid_to_demand": 32470.86086,
    "govt_battery_charge": 12.55649436
  },
  {
    "timestamp": "30/09/24 17:00",
    "demand": 34540.06278,
    "solar_generated": 22.83740579,
    "wind_generated": 54.63122439,
    "marketplace_to_demand": 64.3853609,
    "grid_to_demand": 31550.88504,
    "govt_battery_charge": 70.39561515
  },
  {
    "timestamp": "30/09/24 18:00",
    "demand": 31978.91949,
    "solar_generated": 0,
    "wind_generated": 57.21707655,
    "marketplace_to_demand": 114.6926947,
    "grid_to_demand": 32432.33248,
    "govt_battery_charge": 28.00555863
  },
  {
    "timestamp": "30/09/24 19:00",
    "demand": 32308.51947,
    "solar_generated": 0,
    "wind_generated": 70.21289903,
    "marketplace_to_demand": 20.2800486,
    "grid_to_demand": 32488.55448,
    "govt_battery_charge": 95.30401629
  },
  {
    "timestamp": "30/09/24 20:00",
    "demand": 33075.73173,
    "solar_generated": 0,
    "wind_generated": 68.6826461,
    "marketplace_to_demand": 21.39928498,
    "grid_to_demand": 36410.06404,
    "govt_battery_charge": 36.69121307
  },
  {
    "timestamp": "30/09/24 21:00",
    "demand": 30446.15845,
    "solar_generated": 0,
    "wind_generated": 77.75288942,
    "marketplace_to_demand": 36.36948429,
    "grid_to_demand": 33780.11029,
    "govt_battery_charge": 16.58486161
  },
  {
    "timestamp": "30/09/24 22:00",
    "demand": 31503.16842,
    "solar_generated": 0,
    "wind_generated": 70.64580465,
    "marketplace_to_demand": 68.49247231,
    "grid_to_demand": 34767.36652,
    "govt_battery_charge": 26.22528008
  },
  {
    "timestamp": "30/09/24 23:00",
    "demand": 34732.73228,
    "solar_generated": 0,
    "wind_generated": 69.46634541,
    "marketplace_to_demand": 172.4721576,
    "grid_to_demand": 35226.18056,
    "govt_battery_charge": 54.17643471
  },
  {
    "timestamp": "01/10/24 0:00",
    "demand": 32604.35276,
    "solar_generated": 0,
    "wind_generated": 71.09148811,
    "marketplace_to_demand": 123.7452325,
    "grid_to_demand": 32572.08101,
    "govt_battery_charge": 9.531055142
  },
  {
    "timestamp": "01/10/24 1:00",
    "demand": 31945.02004,
    "solar_generated": 0,
    "wind_generated": 57.52482659,
    "marketplace_to_demand": 102.0377189,
    "grid_to_demand": 33616.18326,
    "govt_battery_charge": 80.86925207
  },
  {
    "timestamp": "01/10/24 2:00",
    "demand": 36119.59421,
    "solar_generated": 0,
    "wind_generated": 57.25567107,
    "marketplace_to_demand": 85.36993185,
    "grid_to_demand": 34002.01311,
    "govt_battery_charge": 31.34404975
  },
  {
    "timestamp": "01/10/24 3:00",
    "demand": 33934.8778,
    "solar_generated": 0,
    "wind_generated": 69.51553018,
    "marketplace_to_demand": 76.03089813,
    "grid_to_demand": 35573.60474,
    "govt_battery_charge": 63.19917308
  },
  {
    "timestamp": "01/10/24 4:00",
    "demand": 32961.40246,
    "solar_generated": 0,
    "wind_generated": 60.26220629,
    "marketplace_to_demand": 138.5756087,
    "grid_to_demand": 36175.88516,
    "govt_battery_charge": 41.9509661
  },
  {
    "timestamp": "01/10/24 5:00",
    "demand": 31512.1761,
    "solar_generated": 0,
    "wind_generated": 63.83629541,
    "marketplace_to_demand": 12.0078255,
    "grid_to_demand": 34614.92555,
    "govt_battery_charge": 62.57046992
  },
  {
    "timestamp": "01/10/24 6:00",
    "demand": 36261.08004,
    "solar_generated": 16.35496684,
    "wind_generated": 75.37440603,
    "marketplace_to_demand": 107.0710202,
    "grid_to_demand": 34778.88725,
    "govt_battery_charge": 77.75900486
  },
  {
    "timestamp": "01/10/24 7:00",
    "demand": 35446.59713,
    "solar_generated": 9.763971675,
    "wind_generated": 71.66625295,
    "marketplace_to_demand": 155.7897391,
    "grid_to_demand": 33407.02928,
    "govt_battery_charge": 32.64695932
  },
  {
    "timestamp": "01/10/24 8:00",
    "demand": 36145.49406,
    "solar_generated": 23.02226651,
    "wind_generated": 55.66284814,
    "marketplace_to_demand": 125.1853611,
    "grid_to_demand": 33095.58784,
    "govt_battery_charge": 91.34994626
  },
  {
    "timestamp": "01/10/24 9:00",
    "demand": 30125.72687,
    "solar_generated": 18.98346509,
    "wind_generated": 63.91234194,
    "marketplace_to_demand": 72.55582512,
    "grid_to_demand": 30726.5488,
    "govt_battery_charge": 51.45028322
  },
  {
    "timestamp": "01/10/24 10:00",
    "demand": 31421.6727,
    "solar_generated": 20.69633109,
    "wind_generated": 65.22546211,
    "marketplace_to_demand": 42.80133683,
    "grid_to_demand": 30257.60938,
    "govt_battery_charge": 4.968547823
  },
  {
    "timestamp": "01/10/24 11:00",
    "demand": 35797.21107,
    "solar_generated": 7.630735372,
    "wind_generated": 78.04762253,
    "marketplace_to_demand": 81.04378634,
    "grid_to_demand": 32214.31552,
    "govt_battery_charge": 86.43650427
  },
  {
    "timestamp": "01/10/24 12:00",
    "demand": 34344.41988,
    "solar_generated": 22.71728779,
    "wind_generated": 76.56072232,
    "marketplace_to_demand": 190.0980452,
    "grid_to_demand": 36057.98327,
    "govt_battery_charge": 86.81653344
  },
  {
    "timestamp": "01/10/24 13:00",
    "demand": 31342.45297,
    "solar_generated": 12.33277589,
    "wind_generated": 65.56477369,
    "marketplace_to_demand": 187.1917902,
    "grid_to_demand": 35373.7641,
    "govt_battery_charge": 66.02676154
  },
  {
    "timestamp": "01/10/24 14:00",
    "demand": 35432.00595,
    "solar_generated": 15.55017294,
    "wind_generated": 77.61546446,
    "marketplace_to_demand": 188.344032,
    "grid_to_demand": 31763.66311,
    "govt_battery_charge": 65.36692103
  },
  {
    "timestamp": "01/10/24 15:00",
    "demand": 30262.62991,
    "solar_generated": 7.776978194,
    "wind_generated": 59.35197054,
    "marketplace_to_demand": 80.58776697,
    "grid_to_demand": 33508.18964,
    "govt_battery_charge": 37.14606131
  },
  {
    "timestamp": "01/10/24 16:00",
    "demand": 34317.28734,
    "solar_generated": 6.012187435,
    "wind_generated": 64.48373081,
    "marketplace_to_demand": 175.865539,
    "grid_to_demand": 30118.01875,
    "govt_battery_charge": 94.62146957
  },
  {
    "timestamp": "01/10/24 17:00",
    "demand": 31023.41733,
    "solar_generated": 20.50572194,
    "wind_generated": 77.32574228,
    "marketplace_to_demand": 6.535737921,
    "grid_to_demand": 33364.16172,
    "govt_battery_charge": 69.72455914
  },
  {
    "timestamp": "01/10/24 18:00",
    "demand": 30035.89732,
    "solar_generated": 0,
    "wind_generated": 79.30653121,
    "marketplace_to_demand": 36.44263133,
    "grid_to_demand": 30494.718,
    "govt_battery_charge": 82.45774685
  },
  {
    "timestamp": "01/10/24 19:00",
    "demand": 30421.72069,
    "solar_generated": 0,
    "wind_generated": 60.53267181,
    "marketplace_to_demand": 57.49346706,
    "grid_to_demand": 31550.44752,
    "govt_battery_charge": 20.08036103
  },
  {
    "timestamp": "01/10/24 20:00",
    "demand": 33455.79851,
    "solar_generated": 0,
    "wind_generated": 71.60547087,
    "marketplace_to_demand": 169.7474002,
    "grid_to_demand": 32210.09137,
    "govt_battery_charge": 40.86994954
  },
  {
    "timestamp": "01/10/24 21:00",
    "demand": 35235.94502,
    "solar_generated": 0,
    "wind_generated": 59.26581747,
    "marketplace_to_demand": 100.580449,
    "grid_to_demand": 34860.32139,
    "govt_battery_charge": 91.28530621
  },
  {
    "timestamp": "01/10/24 22:00",
    "demand": 31851.83256,
    "solar_generated": 0,
    "wind_generated": 58.09509555,
    "marketplace_to_demand": 120.7484686,
    "grid_to_demand": 34818.59448,
    "govt_battery_charge": 51.57251899
  },
  {
    "timestamp": "01/10/24 23:00",
    "demand": 35923.05394,
    "solar_generated": 0,
    "wind_generated": 61.27857381,
    "marketplace_to_demand": 143.2022305,
    "grid_to_demand": 36455.66377,
    "govt_battery_charge": 99.06729039
  },
  {
    "timestamp": "02/10/24 0:00",
    "demand": 31763.83526,
    "solar_generated": 0,
    "wind_generated": 64.3438744,
    "marketplace_to_demand": 121.822742,
    "grid_to_demand": 35419.86333,
    "govt_battery_charge": 98.1149428
  },
  {
    "timestamp": "02/10/24 1:00",
    "demand": 35812.40611,
    "solar_generated": 0,
    "wind_generated": 68.99540538,
    "marketplace_to_demand": 168.0104609,
    "grid_to_demand": 36288.90647,
    "govt_battery_charge": 4.344778366
  },
  {
    "timestamp": "02/10/24 2:00",
    "demand": 34097.70993,
    "solar_generated": 0,
    "wind_generated": 77.19249793,
    "marketplace_to_demand": 109.2290786,
    "grid_to_demand": 31237.8307,
    "govt_battery_charge": 86.92567591
  },
  {
    "timestamp": "02/10/24 3:00",
    "demand": 35796.18293,
    "solar_generated": 0,
    "wind_generated": 70.00216013,
    "marketplace_to_demand": 117.8351147,
    "grid_to_demand": 32675.98439,
    "govt_battery_charge": 53.09662429
  },
  {
    "timestamp": "02/10/24 4:00",
    "demand": 31406.60787,
    "solar_generated": 0,
    "wind_generated": 77.92636581,
    "marketplace_to_demand": 58.01549702,
    "grid_to_demand": 30835.15589,
    "govt_battery_charge": 43.85577487
  },
  {
    "timestamp": "02/10/24 5:00",
    "demand": 33531.61956,
    "solar_generated": 0,
    "wind_generated": 78.23510518,
    "marketplace_to_demand": 92.18337033,
    "grid_to_demand": 35589.72671,
    "govt_battery_charge": 90.19477749
  },
  {
    "timestamp": "02/10/24 6:00",
    "demand": 36294.73279,
    "solar_generated": 23.03857048,
    "wind_generated": 73.62574404,
    "marketplace_to_demand": 142.6624087,
    "grid_to_demand": 35872.31495,
    "govt_battery_charge": 83.76720558
  },
  {
    "timestamp": "02/10/24 7:00",
    "demand": 34567.5989,
    "solar_generated": 17.49631392,
    "wind_generated": 59.23739693,
    "marketplace_to_demand": 199.6072925,
    "grid_to_demand": 31307.59238,
    "govt_battery_charge": 76.24270307
  },
  {
    "timestamp": "02/10/24 8:00",
    "demand": 33570.01903,
    "solar_generated": 23.8514109,
    "wind_generated": 69.83811518,
    "marketplace_to_demand": 61.09488631,
    "grid_to_demand": 33143.23298,
    "govt_battery_charge": 67.52468514
  },
  {
    "timestamp": "02/10/24 9:00",
    "demand": 34779.73719,
    "solar_generated": 11.76794269,
    "wind_generated": 66.79951042,
    "marketplace_to_demand": 139.2931894,
    "grid_to_demand": 32786.03042,
    "govt_battery_charge": 64.43023666
  },
  {
    "timestamp": "02/10/24 10:00",
    "demand": 33489.42046,
    "solar_generated": 10.13344534,
    "wind_generated": 62.98587587,
    "marketplace_to_demand": 178.8985032,
    "grid_to_demand": 30060.4358,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "02/10/24 11:00",
    "demand": 31702.83229,
    "solar_generated": 16.40202391,
    "wind_generated": 79.84509808,
    "marketplace_to_demand": 149.4393272,
    "grid_to_demand": 30858.7534,
    "govt_battery_charge": 40.43256362
  },
  {
    "timestamp": "02/10/24 12:00",
    "demand": 31982.33397,
    "solar_generated": 15.8313843,
    "wind_generated": 61.86907951,
    "marketplace_to_demand": 84.12613145,
    "grid_to_demand": 33579.17782,
    "govt_battery_charge": 98.26695664
  },
  {
    "timestamp": "02/10/24 13:00",
    "demand": 30008.06858,
    "solar_generated": 14.63619224,
    "wind_generated": 75.09048167,
    "marketplace_to_demand": 75.37120491,
    "grid_to_demand": 33258.39789,
    "govt_battery_charge": 41.71573785
  },
  {
    "timestamp": "02/10/24 14:00",
    "demand": 35172.40485,
    "solar_generated": 11.54246474,
    "wind_generated": 66.20752383,
    "marketplace_to_demand": 80.81693285,
    "grid_to_demand": 33888.47755,
    "govt_battery_charge": 44.87100682
  },
  {
    "timestamp": "02/10/24 15:00",
    "demand": 30352.59015,
    "solar_generated": 15.78453208,
    "wind_generated": 76.34076159,
    "marketplace_to_demand": 161.4203443,
    "grid_to_demand": 33830.18831,
    "govt_battery_charge": 93.58794432
  },
  {
    "timestamp": "02/10/24 16:00",
    "demand": 36412.35319,
    "solar_generated": 18.38649222,
    "wind_generated": 62.87141227,
    "marketplace_to_demand": 123.1169873,
    "grid_to_demand": 32066.12583,
    "govt_battery_charge": 30.65295679
  },
  {
    "timestamp": "02/10/24 17:00",
    "demand": 36267.37498,
    "solar_generated": 15.0600465,
    "wind_generated": 56.68965863,
    "marketplace_to_demand": 75.19176018,
    "grid_to_demand": 32103.08671,
    "govt_battery_charge": 1.475230632
  },
  {
    "timestamp": "02/10/24 18:00",
    "demand": 32361.22594,
    "solar_generated": 0,
    "wind_generated": 77.80484402,
    "marketplace_to_demand": 4.693059484,
    "grid_to_demand": 36272.0885,
    "govt_battery_charge": 92.63907229
  },
  {
    "timestamp": "02/10/24 19:00",
    "demand": 34709.34514,
    "solar_generated": 0,
    "wind_generated": 67.67447063,
    "marketplace_to_demand": 115.2858736,
    "grid_to_demand": 32876.26134,
    "govt_battery_charge": 25.18054428
  },
  {
    "timestamp": "02/10/24 20:00",
    "demand": 34315.0627,
    "solar_generated": 0,
    "wind_generated": 66.77949229,
    "marketplace_to_demand": 109.2426092,
    "grid_to_demand": 32223.12376,
    "govt_battery_charge": 45.73037453
  },
  {
    "timestamp": "02/10/24 21:00",
    "demand": 36170.63069,
    "solar_generated": 0,
    "wind_generated": 56.57984916,
    "marketplace_to_demand": 167.9102785,
    "grid_to_demand": 32519.30227,
    "govt_battery_charge": 50.62375761
  },
  {
    "timestamp": "02/10/24 22:00",
    "demand": 34412.54431,
    "solar_generated": 0,
    "wind_generated": 74.7144109,
    "marketplace_to_demand": 107.6464841,
    "grid_to_demand": 35073.36821,
    "govt_battery_charge": 31.23598977
  },
  {
    "timestamp": "02/10/24 23:00",
    "demand": 35515.54042,
    "solar_generated": 0,
    "wind_generated": 69.03343792,
    "marketplace_to_demand": 185.4636195,
    "grid_to_demand": 31069.99164,
    "govt_battery_charge": 37.79177865
  },
  {
    "timestamp": "03/10/24 0:00",
    "demand": 33150.55165,
    "solar_generated": 0,
    "wind_generated": 62.72419385,
    "marketplace_to_demand": 115.9294862,
    "grid_to_demand": 35304.47032,
    "govt_battery_charge": 80.88942241
  },
  {
    "timestamp": "03/10/24 1:00",
    "demand": 35218.31346,
    "solar_generated": 0,
    "wind_generated": 77.535438,
    "marketplace_to_demand": 144.5919473,
    "grid_to_demand": 30167.97557,
    "govt_battery_charge": 42.34837196
  },
  {
    "timestamp": "03/10/24 2:00",
    "demand": 31753.2621,
    "solar_generated": 0,
    "wind_generated": 67.15425341,
    "marketplace_to_demand": 79.19018364,
    "grid_to_demand": 34393.17624,
    "govt_battery_charge": 93.88896068
  },
  {
    "timestamp": "03/10/24 3:00",
    "demand": 33259.87575,
    "solar_generated": 0,
    "wind_generated": 72.47033086,
    "marketplace_to_demand": 32.46615557,
    "grid_to_demand": 30520.82353,
    "govt_battery_charge": 63.71815308
  },
  {
    "timestamp": "03/10/24 4:00",
    "demand": 30634.30761,
    "solar_generated": 0,
    "wind_generated": 77.43757282,
    "marketplace_to_demand": 82.32285733,
    "grid_to_demand": 34990.26343,
    "govt_battery_charge": 76.92718087
  },
  {
    "timestamp": "03/10/24 5:00",
    "demand": 33489.13869,
    "solar_generated": 0,
    "wind_generated": 75.96377881,
    "marketplace_to_demand": 110.5026485,
    "grid_to_demand": 35125.8297,
    "govt_battery_charge": 15.75739381
  },
  {
    "timestamp": "03/10/24 6:00",
    "demand": 30623.02596,
    "solar_generated": 22.98835267,
    "wind_generated": 61.83930002,
    "marketplace_to_demand": 2.978349333,
    "grid_to_demand": 32515.02397,
    "govt_battery_charge": 59.49222219
  },
  {
    "timestamp": "03/10/24 7:00",
    "demand": 31935.51462,
    "solar_generated": 21.34165564,
    "wind_generated": 55.46806277,
    "marketplace_to_demand": 92.33518583,
    "grid_to_demand": 33796.61082,
    "govt_battery_charge": 86.12547468
  },
  {
    "timestamp": "03/10/24 8:00",
    "demand": 34881.89798,
    "solar_generated": 20.55314854,
    "wind_generated": 77.28644897,
    "marketplace_to_demand": 119.951566,
    "grid_to_demand": 36342.71379,
    "govt_battery_charge": 81.35479203
  },
  {
    "timestamp": "03/10/24 9:00",
    "demand": 32619.65,
    "solar_generated": 17.81892093,
    "wind_generated": 55.28738402,
    "marketplace_to_demand": 122.7769519,
    "grid_to_demand": 33679.10454,
    "govt_battery_charge": 11.30269
  },
  {
    "timestamp": "03/10/24 10:00",
    "demand": 35123.16627,
    "solar_generated": 9.998793602,
    "wind_generated": 68.76665061,
    "marketplace_to_demand": 41.9776968,
    "grid_to_demand": 31257.00436,
    "govt_battery_charge": 61.6264273
  },
  {
    "timestamp": "03/10/24 11:00",
    "demand": 30862.25277,
    "solar_generated": 19.38650189,
    "wind_generated": 58.81427213,
    "marketplace_to_demand": 56.6811162,
    "grid_to_demand": 33788.20574,
    "govt_battery_charge": 20.59465069
  },
  {
    "timestamp": "03/10/24 12:00",
    "demand": 34120.35746,
    "solar_generated": 11.13499486,
    "wind_generated": 56.81121008,
    "marketplace_to_demand": 146.79273,
    "grid_to_demand": 30257.49036,
    "govt_battery_charge": 62.55310641
  },
  {
    "timestamp": "03/10/24 13:00",
    "demand": 33679.95525,
    "solar_generated": 23.38650747,
    "wind_generated": 74.32046651,
    "marketplace_to_demand": 44.9034408,
    "grid_to_demand": 34781.75318,
    "govt_battery_charge": 96.59436356
  },
  {
    "timestamp": "03/10/24 14:00",
    "demand": 35210.77029,
    "solar_generated": 12.66400948,
    "wind_generated": 76.32648072,
    "marketplace_to_demand": 121.923101,
    "grid_to_demand": 33791.8191,
    "govt_battery_charge": 73.89177666
  },
  {
    "timestamp": "03/10/24 15:00",
    "demand": 33761.73045,
    "solar_generated": 12.20133577,
    "wind_generated": 57.06699158,
    "marketplace_to_demand": 117.2471109,
    "grid_to_demand": 34674.00533,
    "govt_battery_charge": 27.61314794
  },
  {
    "timestamp": "03/10/24 16:00",
    "demand": 34126.65967,
    "solar_generated": 10.91729353,
    "wind_generated": 79.08896205,
    "marketplace_to_demand": 154.1805462,
    "grid_to_demand": 31485.52387,
    "govt_battery_charge": 64.97688606
  },
  {
    "timestamp": "03/10/24 17:00",
    "demand": 32259.0312,
    "solar_generated": 10.6318253,
    "wind_generated": 59.54337583,
    "marketplace_to_demand": 173.832955,
    "grid_to_demand": 35509.19252,
    "govt_battery_charge": 87.62609241
  },
  {
    "timestamp": "03/10/24 18:00",
    "demand": 30730.36597,
    "solar_generated": 0,
    "wind_generated": 73.40293455,
    "marketplace_to_demand": 16.48725041,
    "grid_to_demand": 33328.76192,
    "govt_battery_charge": 59.15203925
  },
  {
    "timestamp": "03/10/24 19:00",
    "demand": 35795.21606,
    "solar_generated": 0,
    "wind_generated": 73.63666119,
    "marketplace_to_demand": 127.4477859,
    "grid_to_demand": 30546.13654,
    "govt_battery_charge": 55.37671997
  },
  {
    "timestamp": "03/10/24 20:00",
    "demand": 31637.61925,
    "solar_generated": 0,
    "wind_generated": 69.866854,
    "marketplace_to_demand": 152.9219976,
    "grid_to_demand": 35562.99488,
    "govt_battery_charge": 45.91124343
  },
  {
    "timestamp": "03/10/24 21:00",
    "demand": 30332.7162,
    "solar_generated": 0,
    "wind_generated": 61.31105111,
    "marketplace_to_demand": 16.55658763,
    "grid_to_demand": 35530.25439,
    "govt_battery_charge": 90.53553362
  },
  {
    "timestamp": "03/10/24 22:00",
    "demand": 33027.17118,
    "solar_generated": 0,
    "wind_generated": 75.95501028,
    "marketplace_to_demand": 89.29068704,
    "grid_to_demand": 31980.58888,
    "govt_battery_charge": 61.97257056
  },
  {
    "timestamp": "03/10/24 23:00",
    "demand": 34255.11727,
    "solar_generated": 0,
    "wind_generated": 76.57133691,
    "marketplace_to_demand": 98.49949935,
    "grid_to_demand": 29913.6655,
    "govt_battery_charge": 11.05294001
  },
  {
    "timestamp": "04/10/24 0:00",
    "demand": 32422.64219,
    "solar_generated": 0,
    "wind_generated": 56.39744289,
    "marketplace_to_demand": 126.6765543,
    "grid_to_demand": 32449.76921,
    "govt_battery_charge": 7.506412501
  },
  {
    "timestamp": "04/10/24 1:00",
    "demand": 32849.83638,
    "solar_generated": 0,
    "wind_generated": 75.69221122,
    "marketplace_to_demand": 108.355457,
    "grid_to_demand": 32618.1574,
    "govt_battery_charge": 85.43394529
  },
  {
    "timestamp": "04/10/24 2:00",
    "demand": 32701.49194,
    "solar_generated": 0,
    "wind_generated": 66.30249075,
    "marketplace_to_demand": 88.7995987,
    "grid_to_demand": 31655.31694,
    "govt_battery_charge": 84.75121642
  },
  {
    "timestamp": "04/10/24 3:00",
    "demand": 33684.38858,
    "solar_generated": 0,
    "wind_generated": 64.89426136,
    "marketplace_to_demand": 186.1892901,
    "grid_to_demand": 33467.3991,
    "govt_battery_charge": 84.99046114
  },
  {
    "timestamp": "04/10/24 4:00",
    "demand": 31349.22714,
    "solar_generated": 0,
    "wind_generated": 54.39942458,
    "marketplace_to_demand": 107.4046629,
    "grid_to_demand": 32179.23092,
    "govt_battery_charge": 9.504763893
  },
  {
    "timestamp": "04/10/24 5:00",
    "demand": 35836.26453,
    "solar_generated": 0,
    "wind_generated": 65.73572488,
    "marketplace_to_demand": 105.8895257,
    "grid_to_demand": 30643.19798,
    "govt_battery_charge": 76.39286099
  },
  {
    "timestamp": "04/10/24 6:00",
    "demand": 30006.52287,
    "solar_generated": 13.31696558,
    "wind_generated": 57.48396757,
    "marketplace_to_demand": 6.205949988,
    "grid_to_demand": 35319.48285,
    "govt_battery_charge": 17.12910612
  },
  {
    "timestamp": "04/10/24 7:00",
    "demand": 30852.35576,
    "solar_generated": 14.520397,
    "wind_generated": 58.49810218,
    "marketplace_to_demand": 23.93109178,
    "grid_to_demand": 35126.72817,
    "govt_battery_charge": 91.29317967
  },
  {
    "timestamp": "04/10/24 8:00",
    "demand": 29969.79439,
    "solar_generated": 8.026213444,
    "wind_generated": 68.49433887,
    "marketplace_to_demand": 0.296842852,
    "grid_to_demand": 35309.15285,
    "govt_battery_charge": 60.49695827
  },
  {
    "timestamp": "04/10/24 9:00",
    "demand": 30436.37484,
    "solar_generated": 20.83386144,
    "wind_generated": 69.17345876,
    "marketplace_to_demand": 34.90262601,
    "grid_to_demand": 32500.33172,
    "govt_battery_charge": 47.34763457
  },
  {
    "timestamp": "04/10/24 10:00",
    "demand": 35173.09448,
    "solar_generated": 11.65914704,
    "wind_generated": 57.75144784,
    "marketplace_to_demand": 137.2873508,
    "grid_to_demand": 35658.67135,
    "govt_battery_charge": 43.87637197
  },
  {
    "timestamp": "04/10/24 11:00",
    "demand": 34835.27987,
    "solar_generated": 18.71627583,
    "wind_generated": 73.91455675,
    "marketplace_to_demand": 5.303855613,
    "grid_to_demand": 33610.02161,
    "govt_battery_charge": 93.66058326
  },
  {
    "timestamp": "04/10/24 12:00",
    "demand": 36589.28187,
    "solar_generated": 21.03543355,
    "wind_generated": 62.6278081,
    "marketplace_to_demand": 178.6905391,
    "grid_to_demand": 30264.046,
    "govt_battery_charge": 58.06646021
  },
  {
    "timestamp": "04/10/24 13:00",
    "demand": 35778.2784,
    "solar_generated": 13.70986784,
    "wind_generated": 60.36496032,
    "marketplace_to_demand": 35.80340273,
    "grid_to_demand": 30334.95418,
    "govt_battery_charge": 2.68020672
  },
  {
    "timestamp": "04/10/24 14:00",
    "demand": 31872.33445,
    "solar_generated": 22.00511826,
    "wind_generated": 67.75343291,
    "marketplace_to_demand": 97.48314877,
    "grid_to_demand": 36041.66587,
    "govt_battery_charge": 13.47150399
  },
  {
    "timestamp": "04/10/24 15:00",
    "demand": 30841.42558,
    "solar_generated": 7.400820795,
    "wind_generated": 67.55213501,
    "marketplace_to_demand": 188.984714,
    "grid_to_demand": 30288.64661,
    "govt_battery_charge": 13.92401878
  },
  {
    "timestamp": "04/10/24 16:00",
    "demand": 35220.92297,
    "solar_generated": 7.255773523,
    "wind_generated": 61.59602985,
    "marketplace_to_demand": 29.44444462,
    "grid_to_demand": 34970.21918,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "04/10/24 17:00",
    "demand": 32427.62082,
    "solar_generated": 10.09553156,
    "wind_generated": 72.52113296,
    "marketplace_to_demand": 125.2824576,
    "grid_to_demand": 31818.05391,
    "govt_battery_charge": 8.587147164
  },
  {
    "timestamp": "04/10/24 18:00",
    "demand": 30911.36297,
    "solar_generated": 0,
    "wind_generated": 59.50615147,
    "marketplace_to_demand": 122.4108339,
    "grid_to_demand": 32094.42021,
    "govt_battery_charge": 57.58143082
  },
  {
    "timestamp": "04/10/24 19:00",
    "demand": 31926.06597,
    "solar_generated": 0,
    "wind_generated": 76.29449556,
    "marketplace_to_demand": 160.8640899,
    "grid_to_demand": 35956.14292,
    "govt_battery_charge": 27.96732951
  },
  {
    "timestamp": "04/10/24 20:00",
    "demand": 34293.7587,
    "solar_generated": 0,
    "wind_generated": 77.10506807,
    "marketplace_to_demand": 195.7437186,
    "grid_to_demand": 35005.81515,
    "govt_battery_charge": 92.42606599
  },
  {
    "timestamp": "04/10/24 21:00",
    "demand": 30113.30202,
    "solar_generated": 0,
    "wind_generated": 65.97438173,
    "marketplace_to_demand": 157.561549,
    "grid_to_demand": 29958.09799,
    "govt_battery_charge": 85.07360518
  },
  {
    "timestamp": "04/10/24 22:00",
    "demand": 30251.41015,
    "solar_generated": 0,
    "wind_generated": 60.30878095,
    "marketplace_to_demand": 141.7800504,
    "grid_to_demand": 31372.56218,
    "govt_battery_charge": 28.00286598
  },
  {
    "timestamp": "04/10/24 23:00",
    "demand": 36351.80441,
    "solar_generated": 0,
    "wind_generated": 60.70805913,
    "marketplace_to_demand": 107.347172,
    "grid_to_demand": 32723.22837,
    "govt_battery_charge": 87.77562156
  },
  {
    "timestamp": "05/10/24 0:00",
    "demand": 33872.7141,
    "solar_generated": 0,
    "wind_generated": 71.42133772,
    "marketplace_to_demand": 29.45062747,
    "grid_to_demand": 35811.98596,
    "govt_battery_charge": 38.54770812
  },
  {
    "timestamp": "05/10/24 1:00",
    "demand": 35718.93031,
    "solar_generated": 0,
    "wind_generated": 77.46759547,
    "marketplace_to_demand": 118.9491109,
    "grid_to_demand": 30264.03888,
    "govt_battery_charge": 30.19035124
  },
  {
    "timestamp": "05/10/24 2:00",
    "demand": 31960.64834,
    "solar_generated": 0,
    "wind_generated": 67.58205331,
    "marketplace_to_demand": 149.0516953,
    "grid_to_demand": 33577.90088,
    "govt_battery_charge": 79.16520113
  },
  {
    "timestamp": "05/10/24 3:00",
    "demand": 35188.40861,
    "solar_generated": 0,
    "wind_generated": 53.77505257,
    "marketplace_to_demand": 158.1982968,
    "grid_to_demand": 33681.33267,
    "govt_battery_charge": 44.65128356
  },
  {
    "timestamp": "05/10/24 4:00",
    "demand": 36202.47987,
    "solar_generated": 0,
    "wind_generated": 75.65229425,
    "marketplace_to_demand": 134.8761147,
    "grid_to_demand": 34711.10127,
    "govt_battery_charge": 84.90101138
  },
  {
    "timestamp": "05/10/24 5:00",
    "demand": 30449.6371,
    "solar_generated": 0,
    "wind_generated": 71.2780331,
    "marketplace_to_demand": 128.9194355,
    "grid_to_demand": 33608.2043,
    "govt_battery_charge": 97.06447848
  },
  {
    "timestamp": "05/10/24 6:00",
    "demand": 34548.72666,
    "solar_generated": 6.197227788,
    "wind_generated": 63.79959344,
    "marketplace_to_demand": 87.98628111,
    "grid_to_demand": 35914.4924,
    "govt_battery_charge": 36.6127933
  },
  {
    "timestamp": "05/10/24 7:00",
    "demand": 33398.14852,
    "solar_generated": 12.56582723,
    "wind_generated": 63.27415761,
    "marketplace_to_demand": 158.4495634,
    "grid_to_demand": 29962.31508,
    "govt_battery_charge": 73.60656643
  },
  {
    "timestamp": "05/10/24 8:00",
    "demand": 30583.77341,
    "solar_generated": 21.31073312,
    "wind_generated": 78.47692592,
    "marketplace_to_demand": 81.17901162,
    "grid_to_demand": 34489.6184,
    "govt_battery_charge": 31.04587085
  },
  {
    "timestamp": "05/10/24 9:00",
    "demand": 33000.16989,
    "solar_generated": 15.66014036,
    "wind_generated": 66.55090164,
    "marketplace_to_demand": 18.47191906,
    "grid_to_demand": 34099.95372,
    "govt_battery_charge": 8.949246118
  },
  {
    "timestamp": "05/10/24 10:00",
    "demand": 34210.6697,
    "solar_generated": 12.58936173,
    "wind_generated": 72.72947512,
    "marketplace_to_demand": 183.3013211,
    "grid_to_demand": 31691.77174,
    "govt_battery_charge": 44.78122142
  },
  {
    "timestamp": "05/10/24 11:00",
    "demand": 31709.89233,
    "solar_generated": 22.48488107,
    "wind_generated": 75.16449811,
    "marketplace_to_demand": 66.69233535,
    "grid_to_demand": 34709.22786,
    "govt_battery_charge": 75.95387222
  },
  {
    "timestamp": "05/10/24 12:00",
    "demand": 36144.14533,
    "solar_generated": 12.69666289,
    "wind_generated": 73.54208762,
    "marketplace_to_demand": 172.8265475,
    "grid_to_demand": 32878.21134,
    "govt_battery_charge": 43.28775594
  },
  {
    "timestamp": "05/10/24 13:00",
    "demand": 35173.9924,
    "solar_generated": 22.2367612,
    "wind_generated": 55.06547676,
    "marketplace_to_demand": 1.247869149,
    "grid_to_demand": 35760.95517,
    "govt_battery_charge": 5.904354604
  },
  {
    "timestamp": "05/10/24 14:00",
    "demand": 34609.07329,
    "solar_generated": 18.89460833,
    "wind_generated": 60.17030067,
    "marketplace_to_demand": 11.8746375,
    "grid_to_demand": 32375.796,
    "govt_battery_charge": 59.20325962
  },
  {
    "timestamp": "05/10/24 15:00",
    "demand": 34363.09375,
    "solar_generated": 6.01835642,
    "wind_generated": 62.10106047,
    "marketplace_to_demand": 39.70494932,
    "grid_to_demand": 31187.34129,
    "govt_battery_charge": 40.51720667
  },
  {
    "timestamp": "05/10/24 16:00",
    "demand": 33620.74691,
    "solar_generated": 6.932592633,
    "wind_generated": 63.21118864,
    "marketplace_to_demand": 93.81144549,
    "grid_to_demand": 35398.80516,
    "govt_battery_charge": 66.55230845
  },
  {
    "timestamp": "05/10/24 17:00",
    "demand": 35105.89215,
    "solar_generated": 23.75518438,
    "wind_generated": 59.81852444,
    "marketplace_to_demand": 149.9445033,
    "grid_to_demand": 32131.16814,
    "govt_battery_charge": 72.45263792
  },
  {
    "timestamp": "05/10/24 18:00",
    "demand": 34724.00745,
    "solar_generated": 0,
    "wind_generated": 74.21685316,
    "marketplace_to_demand": 78.26167289,
    "grid_to_demand": 30249.3433,
    "govt_battery_charge": 65.15831145
  },
  {
    "timestamp": "05/10/24 19:00",
    "demand": 30070.79382,
    "solar_generated": 0,
    "wind_generated": 63.39158623,
    "marketplace_to_demand": 196.5347151,
    "grid_to_demand": 30363.62346,
    "govt_battery_charge": 4.636234062
  },
  {
    "timestamp": "05/10/24 20:00",
    "demand": 31909.41814,
    "solar_generated": 0,
    "wind_generated": 56.16561232,
    "marketplace_to_demand": 94.35502705,
    "grid_to_demand": 35411.84278,
    "govt_battery_charge": 50.46843633
  },
  {
    "timestamp": "05/10/24 21:00",
    "demand": 33039.79824,
    "solar_generated": 0,
    "wind_generated": 69.09457449,
    "marketplace_to_demand": 101.8740141,
    "grid_to_demand": 34431.60488,
    "govt_battery_charge": 83.67297036
  },
  {
    "timestamp": "05/10/24 22:00",
    "demand": 31642.92505,
    "solar_generated": 0,
    "wind_generated": 64.15305472,
    "marketplace_to_demand": 102.568483,
    "grid_to_demand": 30167.53031,
    "govt_battery_charge": 92.57290298
  },
  {
    "timestamp": "05/10/24 23:00",
    "demand": 35173.48384,
    "solar_generated": 0,
    "wind_generated": 70.28495844,
    "marketplace_to_demand": 20.61098389,
    "grid_to_demand": 32822.71814,
    "govt_battery_charge": 9.270563403
  },
  {
    "timestamp": "06/10/24 0:00",
    "demand": 30299.07566,
    "solar_generated": 0,
    "wind_generated": 78.96603097,
    "marketplace_to_demand": 111.5274608,
    "grid_to_demand": 32133.45874,
    "govt_battery_charge": 36.6669835
  },
  {
    "timestamp": "06/10/24 1:00",
    "demand": 30435.29765,
    "solar_generated": 0,
    "wind_generated": 60.42107835,
    "marketplace_to_demand": 101.6080124,
    "grid_to_demand": 32278.84563,
    "govt_battery_charge": 12.30045012
  },
  {
    "timestamp": "06/10/24 2:00",
    "demand": 31187.88115,
    "solar_generated": 0,
    "wind_generated": 76.90991543,
    "marketplace_to_demand": 175.1674043,
    "grid_to_demand": 35505.83535,
    "govt_battery_charge": 35.37310846
  },
  {
    "timestamp": "06/10/24 3:00",
    "demand": 32086.41221,
    "solar_generated": 0,
    "wind_generated": 75.71121087,
    "marketplace_to_demand": 130.2499961,
    "grid_to_demand": 31113.32339,
    "govt_battery_charge": 86.6705662
  },
  {
    "timestamp": "06/10/24 4:00",
    "demand": 35152.9576,
    "solar_generated": 0,
    "wind_generated": 56.98405727,
    "marketplace_to_demand": 21.20136128,
    "grid_to_demand": 34648.45538,
    "govt_battery_charge": 51.27034144
  },
  {
    "timestamp": "06/10/24 5:00",
    "demand": 36166.08873,
    "solar_generated": 0,
    "wind_generated": 63.28154937,
    "marketplace_to_demand": 74.81903562,
    "grid_to_demand": 36384.06707,
    "govt_battery_charge": 80.96918859
  },
  {
    "timestamp": "06/10/24 6:00",
    "demand": 34320.20837,
    "solar_generated": 18.41847731,
    "wind_generated": 57.33932361,
    "marketplace_to_demand": 145.589889,
    "grid_to_demand": 31498.95733,
    "govt_battery_charge": 96.95799635
  },
  {
    "timestamp": "06/10/24 7:00",
    "demand": 30764.59712,
    "solar_generated": 18.04169196,
    "wind_generated": 66.86274742,
    "marketplace_to_demand": 128.8743716,
    "grid_to_demand": 34425.5902,
    "govt_battery_charge": 5.120802233
  },
  {
    "timestamp": "06/10/24 8:00",
    "demand": 31695.51493,
    "solar_generated": 11.73140582,
    "wind_generated": 77.79622341,
    "marketplace_to_demand": 34.19136138,
    "grid_to_demand": 33053.7062,
    "govt_battery_charge": 57.56821652
  },
  {
    "timestamp": "06/10/24 9:00",
    "demand": 34772.4464,
    "solar_generated": 15.35710976,
    "wind_generated": 71.86001919,
    "marketplace_to_demand": 159.3737071,
    "grid_to_demand": 36175.62772,
    "govt_battery_charge": 7.28971748
  },
  {
    "timestamp": "06/10/24 10:00",
    "demand": 32404.79539,
    "solar_generated": 19.74367348,
    "wind_generated": 53.78168395,
    "marketplace_to_demand": 186.3010721,
    "grid_to_demand": 32327.04831,
    "govt_battery_charge": 14.59312279
  },
  {
    "timestamp": "06/10/24 11:00",
    "demand": 30751.91797,
    "solar_generated": 13.12899657,
    "wind_generated": 57.49837829,
    "marketplace_to_demand": 49.62223383,
    "grid_to_demand": 30663.71251,
    "govt_battery_charge": 7.964938664
  },
  {
    "timestamp": "06/10/24 12:00",
    "demand": 31364.2096,
    "solar_generated": 7.726984841,
    "wind_generated": 55.90679224,
    "marketplace_to_demand": 89.47623292,
    "grid_to_demand": 35097.49813,
    "govt_battery_charge": 51.60917939
  },
  {
    "timestamp": "06/10/24 13:00",
    "demand": 30986.81998,
    "solar_generated": 20.05247039,
    "wind_generated": 60.62850176,
    "marketplace_to_demand": 87.05543027,
    "grid_to_demand": 29992.48293,
    "govt_battery_charge": 33.87555247
  },
  {
    "timestamp": "06/10/24 14:00",
    "demand": 34872.51575,
    "solar_generated": 13.79561156,
    "wind_generated": 53.77949856,
    "marketplace_to_demand": 32.71304531,
    "grid_to_demand": 32350.79744,
    "govt_battery_charge": 82.9354698
  },
  {
    "timestamp": "06/10/24 15:00",
    "demand": 31691.50916,
    "solar_generated": 15.87309414,
    "wind_generated": 74.64255361,
    "marketplace_to_demand": 173.0946076,
    "grid_to_demand": 33053.36226,
    "govt_battery_charge": 10.71080921
  },
  {
    "timestamp": "06/10/24 16:00",
    "demand": 36609.43529,
    "solar_generated": 12.46393024,
    "wind_generated": 70.61076923,
    "marketplace_to_demand": 125.9652781,
    "grid_to_demand": 31879.62921,
    "govt_battery_charge": 89.66223543
  },
  {
    "timestamp": "06/10/24 17:00",
    "demand": 35701.95205,
    "solar_generated": 9.528827762,
    "wind_generated": 72.31608153,
    "marketplace_to_demand": 53.80040855,
    "grid_to_demand": 36301.44379,
    "govt_battery_charge": 23.87248278
  },
  {
    "timestamp": "06/10/24 18:00",
    "demand": 30047.19668,
    "solar_generated": 0,
    "wind_generated": 66.15780877,
    "marketplace_to_demand": 60.68420117,
    "grid_to_demand": 36042.64339,
    "govt_battery_charge": 46.85781437
  },
  {
    "timestamp": "06/10/24 19:00",
    "demand": 33740.76089,
    "solar_generated": 0,
    "wind_generated": 79.37951304,
    "marketplace_to_demand": 79.29879824,
    "grid_to_demand": 32358.88571,
    "govt_battery_charge": 7.792113287
  },
  {
    "timestamp": "06/10/24 20:00",
    "demand": 35767.97971,
    "solar_generated": 0,
    "wind_generated": 59.45639942,
    "marketplace_to_demand": 74.67671649,
    "grid_to_demand": 35144.48875,
    "govt_battery_charge": 72.18242947
  },
  {
    "timestamp": "06/10/24 21:00",
    "demand": 35335.86312,
    "solar_generated": 0,
    "wind_generated": 62.17434537,
    "marketplace_to_demand": 25.33007675,
    "grid_to_demand": 32834.75289,
    "govt_battery_charge": 38.96586606
  },
  {
    "timestamp": "06/10/24 22:00",
    "demand": 32586.54228,
    "solar_generated": 0,
    "wind_generated": 65.85152288,
    "marketplace_to_demand": 13.08405263,
    "grid_to_demand": 35740.15888,
    "govt_battery_charge": 70.46159389
  },
  {
    "timestamp": "06/10/24 23:00",
    "demand": 35622.65525,
    "solar_generated": 0,
    "wind_generated": 77.42095134,
    "marketplace_to_demand": 118.7764706,
    "grid_to_demand": 33040.69102,
    "govt_battery_charge": 95.73713671
  },
  {
    "timestamp": "07/10/24 0:00",
    "demand": 35527.94545,
    "solar_generated": 0,
    "wind_generated": 72.85458095,
    "marketplace_to_demand": 23.81159174,
    "grid_to_demand": 33121.79651,
    "govt_battery_charge": 93.76313764
  },
  {
    "timestamp": "07/10/24 1:00",
    "demand": 30665.85897,
    "solar_generated": 0,
    "wind_generated": 79.72175245,
    "marketplace_to_demand": 30.20333912,
    "grid_to_demand": 36146.59593,
    "govt_battery_charge": 54.72999096
  },
  {
    "timestamp": "07/10/24 2:00",
    "demand": 30309.60933,
    "solar_generated": 0,
    "wind_generated": 59.22480808,
    "marketplace_to_demand": 39.54294834,
    "grid_to_demand": 31128.09809,
    "govt_battery_charge": 17.3288576
  },
  {
    "timestamp": "07/10/24 3:00",
    "demand": 34458.85839,
    "solar_generated": 0,
    "wind_generated": 63.11468541,
    "marketplace_to_demand": 189.8021044,
    "grid_to_demand": 30136.03224,
    "govt_battery_charge": 92.95296328
  },
  {
    "timestamp": "07/10/24 4:00",
    "demand": 33026.61743,
    "solar_generated": 0,
    "wind_generated": 74.25829579,
    "marketplace_to_demand": 92.4936458,
    "grid_to_demand": 31393.2614,
    "govt_battery_charge": 23.15292684
  },
  {
    "timestamp": "07/10/24 5:00",
    "demand": 32377.72501,
    "solar_generated": 0,
    "wind_generated": 79.23640613,
    "marketplace_to_demand": 75.86252946,
    "grid_to_demand": 36442.26909,
    "govt_battery_charge": 80.05206127
  },
  {
    "timestamp": "07/10/24 6:00",
    "demand": 31150.19784,
    "solar_generated": 15.53563752,
    "wind_generated": 75.81120707,
    "marketplace_to_demand": 131.5695167,
    "grid_to_demand": 34212.62457,
    "govt_battery_charge": 1.402041317
  },
  {
    "timestamp": "07/10/24 7:00",
    "demand": 36497.20883,
    "solar_generated": 11.11971868,
    "wind_generated": 67.10329989,
    "marketplace_to_demand": 162.0649027,
    "grid_to_demand": 35147.4757,
    "govt_battery_charge": 29.75343438
  },
  {
    "timestamp": "07/10/24 8:00",
    "demand": 33653.59556,
    "solar_generated": 16.0641232,
    "wind_generated": 79.2244878,
    "marketplace_to_demand": 8.555670214,
    "grid_to_demand": 34613.96612,
    "govt_battery_charge": 85.92233635
  },
  {
    "timestamp": "07/10/24 9:00",
    "demand": 36603.51924,
    "solar_generated": 15.05087111,
    "wind_generated": 67.87991724,
    "marketplace_to_demand": 44.11905732,
    "grid_to_demand": 31840.75806,
    "govt_battery_charge": 89.42901456
  },
  {
    "timestamp": "07/10/24 10:00",
    "demand": 35478.30225,
    "solar_generated": 19.96607764,
    "wind_generated": 64.32762038,
    "marketplace_to_demand": 175.1386905,
    "grid_to_demand": 31286.77245,
    "govt_battery_charge": 34.99873852
  },
  {
    "timestamp": "07/10/24 11:00",
    "demand": 33612.17538,
    "solar_generated": 6.962763581,
    "wind_generated": 55.67099739,
    "marketplace_to_demand": 117.9317583,
    "grid_to_demand": 30190.94162,
    "govt_battery_charge": 19.73852369
  },
  {
    "timestamp": "07/10/24 12:00",
    "demand": 36544.80134,
    "solar_generated": 17.17706003,
    "wind_generated": 54.57285227,
    "marketplace_to_demand": 197.4969186,
    "grid_to_demand": 31671.0426,
    "govt_battery_charge": 43.15921635
  },
  {
    "timestamp": "07/10/24 13:00",
    "demand": 32418.47393,
    "solar_generated": 7.163468201,
    "wind_generated": 76.332264,
    "marketplace_to_demand": 136.1581427,
    "grid_to_demand": 33645.87938,
    "govt_battery_charge": 31.64382411
  },
  {
    "timestamp": "07/10/24 14:00",
    "demand": 33658.61415,
    "solar_generated": 21.25397912,
    "wind_generated": 68.40981208,
    "marketplace_to_demand": 1.274919832,
    "grid_to_demand": 32847.22884,
    "govt_battery_charge": 16.81999366
  },
  {
    "timestamp": "07/10/24 15:00",
    "demand": 33546.55751,
    "solar_generated": 11.0597044,
    "wind_generated": 60.80753336,
    "marketplace_to_demand": 115.0567406,
    "grid_to_demand": 33296.36309,
    "govt_battery_charge": 3.392459314
  },
  {
    "timestamp": "07/10/24 16:00",
    "demand": 35430.38347,
    "solar_generated": 11.94410838,
    "wind_generated": 65.01501984,
    "marketplace_to_demand": 115.1893358,
    "grid_to_demand": 35551.69061,
    "govt_battery_charge": 21.49147657
  },
  {
    "timestamp": "07/10/24 17:00",
    "demand": 33935.17868,
    "solar_generated": 15.35000682,
    "wind_generated": 71.86599648,
    "marketplace_to_demand": 148.2924035,
    "grid_to_demand": 35341.23386,
    "govt_battery_charge": 15.02970023
  },
  {
    "timestamp": "07/10/24 18:00",
    "demand": 34863.21723,
    "solar_generated": 0,
    "wind_generated": 62.18261516,
    "marketplace_to_demand": 98.59330708,
    "grid_to_demand": 33897.59515,
    "govt_battery_charge": 19.85554607
  },
  {
    "timestamp": "07/10/24 19:00",
    "demand": 32247.68446,
    "solar_generated": 0,
    "wind_generated": 72.09759655,
    "marketplace_to_demand": 185.1680371,
    "grid_to_demand": 30990.37579,
    "govt_battery_charge": 59.22676746
  },
  {
    "timestamp": "07/10/24 20:00",
    "demand": 30590.60836,
    "solar_generated": 0,
    "wind_generated": 78.24994597,
    "marketplace_to_demand": 39.54916531,
    "grid_to_demand": 33235.93962,
    "govt_battery_charge": 95.1416014
  },
  {
    "timestamp": "07/10/24 21:00",
    "demand": 33480.23628,
    "solar_generated": 0,
    "wind_generated": 56.66756239,
    "marketplace_to_demand": 110.6524268,
    "grid_to_demand": 32207.97514,
    "govt_battery_charge": 56.35210245
  },
  {
    "timestamp": "07/10/24 22:00",
    "demand": 33920.45562,
    "solar_generated": 0,
    "wind_generated": 79.47920969,
    "marketplace_to_demand": 159.9739024,
    "grid_to_demand": 31563.25701,
    "govt_battery_charge": 57.21028743
  },
  {
    "timestamp": "07/10/24 23:00",
    "demand": 30307.5618,
    "solar_generated": 0,
    "wind_generated": 71.51343538,
    "marketplace_to_demand": 113.5536433,
    "grid_to_demand": 31979.94965,
    "govt_battery_charge": 81.790809
  },
  {
    "timestamp": "08/10/24 0:00",
    "demand": 35053.12163,
    "solar_generated": 0,
    "wind_generated": 62.87281258,
    "marketplace_to_demand": 15.07540817,
    "grid_to_demand": 30759.15063,
    "govt_battery_charge": 53.53388457
  },
  {
    "timestamp": "08/10/24 1:00",
    "demand": 35468.72402,
    "solar_generated": 0,
    "wind_generated": 60.38998226,
    "marketplace_to_demand": 177.9123434,
    "grid_to_demand": 31630.08086,
    "govt_battery_charge": 90.66982607
  },
  {
    "timestamp": "08/10/24 2:00",
    "demand": 34909.14084,
    "solar_generated": 0,
    "wind_generated": 56.49086555,
    "marketplace_to_demand": 185.6372708,
    "grid_to_demand": 34754.07389,
    "govt_battery_charge": 33.85024406
  },
  {
    "timestamp": "08/10/24 3:00",
    "demand": 35151.56698,
    "solar_generated": 0,
    "wind_generated": 62.48743058,
    "marketplace_to_demand": 38.74341221,
    "grid_to_demand": 34564.20506,
    "govt_battery_charge": 14.34713679
  },
  {
    "timestamp": "08/10/24 4:00",
    "demand": 35754.59727,
    "solar_generated": 0,
    "wind_generated": 73.92070101,
    "marketplace_to_demand": 89.50826605,
    "grid_to_demand": 31067.20784,
    "govt_battery_charge": 55.02074703
  },
  {
    "timestamp": "08/10/24 5:00",
    "demand": 32517.13605,
    "solar_generated": 0,
    "wind_generated": 73.70385067,
    "marketplace_to_demand": 119.4998238,
    "grid_to_demand": 30180.62893,
    "govt_battery_charge": 65.05799728
  },
  {
    "timestamp": "08/10/24 6:00",
    "demand": 34048.19484,
    "solar_generated": 14.70379811,
    "wind_generated": 66.53551903,
    "marketplace_to_demand": 129.9549055,
    "grid_to_demand": 35680.90987,
    "govt_battery_charge": 9.124130224
  },
  {
    "timestamp": "08/10/24 7:00",
    "demand": 30001.30578,
    "solar_generated": 19.29084402,
    "wind_generated": 68.07620607,
    "marketplace_to_demand": 20.01610442,
    "grid_to_demand": 32077.65371,
    "govt_battery_charge": 11.38214493
  },
  {
    "timestamp": "08/10/24 8:00",
    "demand": 30396.52846,
    "solar_generated": 9.610925045,
    "wind_generated": 57.30127046,
    "marketplace_to_demand": 176.8117441,
    "grid_to_demand": 30353.51819,
    "govt_battery_charge": 55.35393093
  },
  {
    "timestamp": "08/10/24 9:00",
    "demand": 34581.96873,
    "solar_generated": 8.767116492,
    "wind_generated": 69.78957403,
    "marketplace_to_demand": 4.711934898,
    "grid_to_demand": 34312.49277,
    "govt_battery_charge": 40.96174412
  },
  {
    "timestamp": "08/10/24 10:00",
    "demand": 30640.27073,
    "solar_generated": 16.24887901,
    "wind_generated": 73.72149068,
    "marketplace_to_demand": 80.779002,
    "grid_to_demand": 31990.698,
    "govt_battery_charge": 23.41344672
  },
  {
    "timestamp": "08/10/24 11:00",
    "demand": 35897.81296,
    "solar_generated": 19.30119424,
    "wind_generated": 65.90710499,
    "marketplace_to_demand": 150.7767393,
    "grid_to_demand": 35096.43575,
    "govt_battery_charge": 13.44457891
  },
  {
    "timestamp": "08/10/24 12:00",
    "demand": 34526.32444,
    "solar_generated": 11.61284655,
    "wind_generated": 58.02195977,
    "marketplace_to_demand": 90.41801497,
    "grid_to_demand": 33836.94706,
    "govt_battery_charge": 62.32665683
  },
  {
    "timestamp": "08/10/24 13:00",
    "demand": 31734.13935,
    "solar_generated": 14.67811965,
    "wind_generated": 63.65068807,
    "marketplace_to_demand": 192.8263648,
    "grid_to_demand": 33660.98907,
    "govt_battery_charge": 26.29784088
  },
  {
    "timestamp": "08/10/24 14:00",
    "demand": 34478.59239,
    "solar_generated": 15.49000378,
    "wind_generated": 74.53558201,
    "marketplace_to_demand": 10.8190253,
    "grid_to_demand": 33253.26772,
    "govt_battery_charge": 56.55851071
  },
  {
    "timestamp": "08/10/24 15:00",
    "demand": 33479.6912,
    "solar_generated": 15.65553718,
    "wind_generated": 76.70486119,
    "marketplace_to_demand": 44.20499794,
    "grid_to_demand": 33058.06956,
    "govt_battery_charge": 17.39758454
  },
  {
    "timestamp": "08/10/24 16:00",
    "demand": 32123.9206,
    "solar_generated": 13.2677795,
    "wind_generated": 79.08312429,
    "marketplace_to_demand": 128.1546135,
    "grid_to_demand": 32364.04091,
    "govt_battery_charge": 63.36855091
  },
  {
    "timestamp": "08/10/24 17:00",
    "demand": 34228.86333,
    "solar_generated": 18.76808585,
    "wind_generated": 58.76817991,
    "marketplace_to_demand": 24.72622823,
    "grid_to_demand": 33868.08878,
    "govt_battery_charge": 15.04206048
  },
  {
    "timestamp": "08/10/24 18:00",
    "demand": 36153.52439,
    "solar_generated": 0,
    "wind_generated": 64.23766464,
    "marketplace_to_demand": 3.96803322,
    "grid_to_demand": 30404.95302,
    "govt_battery_charge": 81.47308688
  },
  {
    "timestamp": "08/10/24 19:00",
    "demand": 30454.52684,
    "solar_generated": 0,
    "wind_generated": 71.99015422,
    "marketplace_to_demand": 88.97575747,
    "grid_to_demand": 33591.10363,
    "govt_battery_charge": 8.302630913
  },
  {
    "timestamp": "08/10/24 20:00",
    "demand": 36020.41864,
    "solar_generated": 0,
    "wind_generated": 61.56499236,
    "marketplace_to_demand": 51.67450508,
    "grid_to_demand": 32917.81792,
    "govt_battery_charge": 82.38700393
  },
  {
    "timestamp": "08/10/24 21:00",
    "demand": 32055.57843,
    "solar_generated": 0,
    "wind_generated": 54.96649752,
    "marketplace_to_demand": 79.29428249,
    "grid_to_demand": 34655.52662,
    "govt_battery_charge": 36.21344819
  },
  {
    "timestamp": "08/10/24 22:00",
    "demand": 34581.29722,
    "solar_generated": 0,
    "wind_generated": 61.7051408,
    "marketplace_to_demand": 123.4907131,
    "grid_to_demand": 34611.82647,
    "govt_battery_charge": 9.223393913
  },
  {
    "timestamp": "08/10/24 23:00",
    "demand": 33828.88946,
    "solar_generated": 0,
    "wind_generated": 63.59461389,
    "marketplace_to_demand": 14.80596365,
    "grid_to_demand": 33335.29551,
    "govt_battery_charge": 70.91470764
  },
  {
    "timestamp": "09/10/24 0:00",
    "demand": 29995.71235,
    "solar_generated": 0,
    "wind_generated": 71.96292284,
    "marketplace_to_demand": 137.72539,
    "grid_to_demand": 35549.84367,
    "govt_battery_charge": 48.71935499
  },
  {
    "timestamp": "09/10/24 1:00",
    "demand": 30608.65632,
    "solar_generated": 0,
    "wind_generated": 60.76472081,
    "marketplace_to_demand": 60.87240041,
    "grid_to_demand": 32947.4155,
    "govt_battery_charge": 14.35250167
  },
  {
    "timestamp": "09/10/24 2:00",
    "demand": 30720.58762,
    "solar_generated": 0,
    "wind_generated": 56.2447203,
    "marketplace_to_demand": 59.31684717,
    "grid_to_demand": 32944.95846,
    "govt_battery_charge": 27.40082809
  },
  {
    "timestamp": "09/10/24 3:00",
    "demand": 36359.27137,
    "solar_generated": 0,
    "wind_generated": 72.70899169,
    "marketplace_to_demand": 116.790103,
    "grid_to_demand": 33007.76045,
    "govt_battery_charge": 75.31757919
  },
  {
    "timestamp": "09/10/24 4:00",
    "demand": 35411.38423,
    "solar_generated": 0,
    "wind_generated": 64.90528401,
    "marketplace_to_demand": 153.2130498,
    "grid_to_demand": 32839.11103,
    "govt_battery_charge": 5.040127971
  },
  {
    "timestamp": "09/10/24 5:00",
    "demand": 35913.38879,
    "solar_generated": 0,
    "wind_generated": 56.17576575,
    "marketplace_to_demand": 190.8851093,
    "grid_to_demand": 34263.21005,
    "govt_battery_charge": 40.86655576
  },
  {
    "timestamp": "09/10/24 6:00",
    "demand": 32673.27502,
    "solar_generated": 13.64500455,
    "wind_generated": 65.48489339,
    "marketplace_to_demand": 12.29553469,
    "grid_to_demand": 31086.88282,
    "govt_battery_charge": 78.89784267
  },
  {
    "timestamp": "09/10/24 7:00",
    "demand": 32867.41538,
    "solar_generated": 19.9661499,
    "wind_generated": 71.12179003,
    "marketplace_to_demand": 169.6461421,
    "grid_to_demand": 33873.59857,
    "govt_battery_charge": 25.78993659
  },
  {
    "timestamp": "09/10/24 8:00",
    "demand": 33404.49964,
    "solar_generated": 10.76049623,
    "wind_generated": 62.7908308,
    "marketplace_to_demand": 69.82983104,
    "grid_to_demand": 33676.21221,
    "govt_battery_charge": 40.4989054
  },
  {
    "timestamp": "09/10/24 9:00",
    "demand": 36302.04394,
    "solar_generated": 10.90495099,
    "wind_generated": 74.70441816,
    "marketplace_to_demand": 120.3777553,
    "grid_to_demand": 35222.70214,
    "govt_battery_charge": 61.97670966
  },
  {
    "timestamp": "09/10/24 10:00",
    "demand": 33567.23474,
    "solar_generated": 18.36381533,
    "wind_generated": 54.99751381,
    "marketplace_to_demand": 124.3126265,
    "grid_to_demand": 32777.02027,
    "govt_battery_charge": 60.81183707
  },
  {
    "timestamp": "09/10/24 11:00",
    "demand": 35718.17548,
    "solar_generated": 9.195381229,
    "wind_generated": 53.88068219,
    "marketplace_to_demand": 65.28848594,
    "grid_to_demand": 35274.93199,
    "govt_battery_charge": 63.77899948
  },
  {
    "timestamp": "09/10/24 12:00",
    "demand": 34079.36109,
    "solar_generated": 8.14560089,
    "wind_generated": 55.81958661,
    "marketplace_to_demand": 153.5790819,
    "grid_to_demand": 31014.3677,
    "govt_battery_charge": 51.97565751
  },
  {
    "timestamp": "09/10/24 13:00",
    "demand": 30453.3491,
    "solar_generated": 6.084419173,
    "wind_generated": 63.53020706,
    "marketplace_to_demand": 19.70377873,
    "grid_to_demand": 33946.90514,
    "govt_battery_charge": 55.44447615
  },
  {
    "timestamp": "09/10/24 14:00",
    "demand": 32455.72153,
    "solar_generated": 23.34125391,
    "wind_generated": 55.73676106,
    "marketplace_to_demand": 5.272453234,
    "grid_to_demand": 32513.32196,
    "govt_battery_charge": 54.05040049
  },
  {
    "timestamp": "09/10/24 15:00",
    "demand": 31573.14225,
    "solar_generated": 21.51240734,
    "wind_generated": 70.53193577,
    "marketplace_to_demand": 51.26828477,
    "grid_to_demand": 29914.4471,
    "govt_battery_charge": 27.6878959
  },
  {
    "timestamp": "09/10/24 16:00",
    "demand": 30744.06575,
    "solar_generated": 14.02144289,
    "wind_generated": 80.37786706,
    "marketplace_to_demand": 77.41922569,
    "grid_to_demand": 32450.99779,
    "govt_battery_charge": 5.756430866
  },
  {
    "timestamp": "09/10/24 17:00",
    "demand": 31515.56613,
    "solar_generated": 14.77006145,
    "wind_generated": 57.6722216,
    "marketplace_to_demand": 88.48333503,
    "grid_to_demand": 35619.21462,
    "govt_battery_charge": 67.73009887
  },
  {
    "timestamp": "09/10/24 18:00",
    "demand": 32226.3473,
    "solar_generated": 0,
    "wind_generated": 77.46941575,
    "marketplace_to_demand": 180.3410308,
    "grid_to_demand": 33486.72661,
    "govt_battery_charge": 82.95778085
  },
  {
    "timestamp": "09/10/24 19:00",
    "demand": 30820.86507,
    "solar_generated": 0,
    "wind_generated": 72.43868082,
    "marketplace_to_demand": 186.4584316,
    "grid_to_demand": 30837.42174,
    "govt_battery_charge": 79.59981154
  },
  {
    "timestamp": "09/10/24 20:00",
    "demand": 35647.89886,
    "solar_generated": 0,
    "wind_generated": 67.53185585,
    "marketplace_to_demand": 144.5358811,
    "grid_to_demand": 30074.15375,
    "govt_battery_charge": 63.93452861
  },
  {
    "timestamp": "09/10/24 21:00",
    "demand": 30888.3827,
    "solar_generated": 0,
    "wind_generated": 57.6961434,
    "marketplace_to_demand": 1.263021673,
    "grid_to_demand": 30539.15333,
    "govt_battery_charge": 63.59293705
  },
  {
    "timestamp": "09/10/24 22:00",
    "demand": 34227.15961,
    "solar_generated": 0,
    "wind_generated": 63.76579488,
    "marketplace_to_demand": 59.86783528,
    "grid_to_demand": 36275.38028,
    "govt_battery_charge": 76.83595261
  },
  {
    "timestamp": "09/10/24 23:00",
    "demand": 32954.0134,
    "solar_generated": 0,
    "wind_generated": 54.80577717,
    "marketplace_to_demand": 26.27855642,
    "grid_to_demand": 33873.58777,
    "govt_battery_charge": 48.40216809
  },
  {
    "timestamp": "10/10/24 0:00",
    "demand": 31930.3975,
    "solar_generated": 0,
    "wind_generated": 79.08082416,
    "marketplace_to_demand": 159.7847647,
    "grid_to_demand": 32278.97132,
    "govt_battery_charge": 75.2380026
  },
  {
    "timestamp": "10/10/24 1:00",
    "demand": 34817.45153,
    "solar_generated": 0,
    "wind_generated": 66.00396504,
    "marketplace_to_demand": 70.26837669,
    "grid_to_demand": 35564.13588,
    "govt_battery_charge": 26.72590388
  },
  {
    "timestamp": "10/10/24 2:00",
    "demand": 33867.87156,
    "solar_generated": 0,
    "wind_generated": 77.97859617,
    "marketplace_to_demand": 149.7458197,
    "grid_to_demand": 33626.89817,
    "govt_battery_charge": 1.783189042
  },
  {
    "timestamp": "10/10/24 3:00",
    "demand": 34325.10692,
    "solar_generated": 0,
    "wind_generated": 68.05496368,
    "marketplace_to_demand": 1.415868497,
    "grid_to_demand": 33246.96499,
    "govt_battery_charge": 92.83121927
  },
  {
    "timestamp": "10/10/24 4:00",
    "demand": 32683.69604,
    "solar_generated": 0,
    "wind_generated": 72.6112264,
    "marketplace_to_demand": 68.45173638,
    "grid_to_demand": 33660.34077,
    "govt_battery_charge": 20.43932455
  },
  {
    "timestamp": "10/10/24 5:00",
    "demand": 33216.02467,
    "solar_generated": 0,
    "wind_generated": 76.5825297,
    "marketplace_to_demand": 14.84047467,
    "grid_to_demand": 29906.45444,
    "govt_battery_charge": 36.66795574
  },
  {
    "timestamp": "10/10/24 6:00",
    "demand": 34470.16036,
    "solar_generated": 21.00177348,
    "wind_generated": 59.93314522,
    "marketplace_to_demand": 176.3140075,
    "grid_to_demand": 35654.26708,
    "govt_battery_charge": 87.04614357
  },
  {
    "timestamp": "10/10/24 7:00",
    "demand": 31122.17741,
    "solar_generated": 14.9382055,
    "wind_generated": 75.32061583,
    "marketplace_to_demand": 155.3829805,
    "grid_to_demand": 31998.91054,
    "govt_battery_charge": 91.37962581
  },
  {
    "timestamp": "10/10/24 8:00",
    "demand": 35921.04836,
    "solar_generated": 19.1160852,
    "wind_generated": 75.38787436,
    "marketplace_to_demand": 96.09667533,
    "grid_to_demand": 30598.48759,
    "govt_battery_charge": 98.22217203
  },
  {
    "timestamp": "10/10/24 9:00",
    "demand": 34125.53382,
    "solar_generated": 22.87178387,
    "wind_generated": 62.25309024,
    "marketplace_to_demand": 91.44873629,
    "grid_to_demand": 35352.31782,
    "govt_battery_charge": 93.89293309
  },
  {
    "timestamp": "10/10/24 10:00",
    "demand": 31262.39927,
    "solar_generated": 14.71168269,
    "wind_generated": 56.82237185,
    "marketplace_to_demand": 115.5150751,
    "grid_to_demand": 35578.91611,
    "govt_battery_charge": 14.9400166
  },
  {
    "timestamp": "10/10/24 11:00",
    "demand": 31111.88635,
    "solar_generated": 10.64912853,
    "wind_generated": 73.41627677,
    "marketplace_to_demand": 147.053358,
    "grid_to_demand": 32443.53454,
    "govt_battery_charge": 31.29816953
  },
  {
    "timestamp": "10/10/24 12:00",
    "demand": 33186.39901,
    "solar_generated": 15.16747604,
    "wind_generated": 55.77523215,
    "marketplace_to_demand": 81.64399087,
    "grid_to_demand": 34990.8434,
    "govt_battery_charge": 1.035478808
  },
  {
    "timestamp": "10/10/24 13:00",
    "demand": 34594.83104,
    "solar_generated": 15.41188495,
    "wind_generated": 67.65490679,
    "marketplace_to_demand": 91.73653135,
    "grid_to_demand": 34561.774,
    "govt_battery_charge": 46.04751131
  },
  {
    "timestamp": "10/10/24 14:00",
    "demand": 30036.61724,
    "solar_generated": 6.565505721,
    "wind_generated": 71.59305727,
    "marketplace_to_demand": 56.03231097,
    "grid_to_demand": 33519.86449,
    "govt_battery_charge": 80.02706757
  },
  {
    "timestamp": "10/10/24 15:00",
    "demand": 33015.33558,
    "solar_generated": 19.28612878,
    "wind_generated": 74.51011322,
    "marketplace_to_demand": 20.60974587,
    "grid_to_demand": 33313.71276,
    "govt_battery_charge": 4.423790391
  },
  {
    "timestamp": "10/10/24 16:00",
    "demand": 31853.78544,
    "solar_generated": 23.39352843,
    "wind_generated": 66.06663283,
    "marketplace_to_demand": 71.08669493,
    "grid_to_demand": 33497.48154,
    "govt_battery_charge": 99.31242974
  },
  {
    "timestamp": "10/10/24 17:00",
    "demand": 31932.3154,
    "solar_generated": 18.44544803,
    "wind_generated": 58.09794628,
    "marketplace_to_demand": 180.5746392,
    "grid_to_demand": 30707.7828,
    "govt_battery_charge": 56.04760845
  },
  {
    "timestamp": "10/10/24 18:00",
    "demand": 34361.61917,
    "solar_generated": 0,
    "wind_generated": 62.55205196,
    "marketplace_to_demand": 118.8546056,
    "grid_to_demand": 31224.38926,
    "govt_battery_charge": 46.68359856
  },
  {
    "timestamp": "10/10/24 19:00",
    "demand": 30915.90017,
    "solar_generated": 0,
    "wind_generated": 58.54781787,
    "marketplace_to_demand": 113.389272,
    "grid_to_demand": 30267.75798,
    "govt_battery_charge": 43.03490629
  },
  {
    "timestamp": "10/10/24 20:00",
    "demand": 30720.54724,
    "solar_generated": 0,
    "wind_generated": 63.36588576,
    "marketplace_to_demand": 140.244563,
    "grid_to_demand": 34114.61802,
    "govt_battery_charge": 41.32589907
  },
  {
    "timestamp": "10/10/24 21:00",
    "demand": 32918.03134,
    "solar_generated": 0,
    "wind_generated": 79.63036329,
    "marketplace_to_demand": 126.8180579,
    "grid_to_demand": 32899.67845,
    "govt_battery_charge": 16.32975698
  },
  {
    "timestamp": "10/10/24 22:00",
    "demand": 35833.24187,
    "solar_generated": 0,
    "wind_generated": 62.72775489,
    "marketplace_to_demand": 49.12327415,
    "grid_to_demand": 34996.78206,
    "govt_battery_charge": 51.98482542
  },
  {
    "timestamp": "10/10/24 23:00",
    "demand": 35644.11127,
    "solar_generated": 0,
    "wind_generated": 78.95656159,
    "marketplace_to_demand": 73.14886724,
    "grid_to_demand": 32118.90929,
    "govt_battery_charge": 61.02886064
  },
  {
    "timestamp": "11/10/24 0:00",
    "demand": 32656.48391,
    "solar_generated": 0,
    "wind_generated": 57.73631897,
    "marketplace_to_demand": 84.18161897,
    "grid_to_demand": 35078.37949,
    "govt_battery_charge": 67.53039832
  },
  {
    "timestamp": "11/10/24 1:00",
    "demand": 32501.8542,
    "solar_generated": 0,
    "wind_generated": 54.48207466,
    "marketplace_to_demand": 155.3802236,
    "grid_to_demand": 32686.06746,
    "govt_battery_charge": 83.86237526
  },
  {
    "timestamp": "11/10/24 2:00",
    "demand": 33192.00252,
    "solar_generated": 0,
    "wind_generated": 62.33293589,
    "marketplace_to_demand": 32.63640246,
    "grid_to_demand": 30084.78823,
    "govt_battery_charge": 73.63428521
  },
  {
    "timestamp": "11/10/24 3:00",
    "demand": 31323.76964,
    "solar_generated": 0,
    "wind_generated": 61.16334075,
    "marketplace_to_demand": 57.74029354,
    "grid_to_demand": 30709.78245,
    "govt_battery_charge": 85.11309299
  },
  {
    "timestamp": "11/10/24 4:00",
    "demand": 32783.93405,
    "solar_generated": 0,
    "wind_generated": 78.29246079,
    "marketplace_to_demand": 28.74806432,
    "grid_to_demand": 32929.48396,
    "govt_battery_charge": 33.36915144
  },
  {
    "timestamp": "11/10/24 5:00",
    "demand": 31940.20021,
    "solar_generated": 0,
    "wind_generated": 68.35565287,
    "marketplace_to_demand": 131.1710152,
    "grid_to_demand": 34343.80042,
    "govt_battery_charge": 24.5028076
  },
  {
    "timestamp": "11/10/24 6:00",
    "demand": 33695.16951,
    "solar_generated": 12.81879435,
    "wind_generated": 74.59688089,
    "marketplace_to_demand": 74.60469803,
    "grid_to_demand": 35308.51875,
    "govt_battery_charge": 88.13780294
  },
  {
    "timestamp": "11/10/24 7:00",
    "demand": 34393.26611,
    "solar_generated": 18.25736581,
    "wind_generated": 56.12175978,
    "marketplace_to_demand": 42.05032029,
    "grid_to_demand": 35074.06703,
    "govt_battery_charge": 83.19023467
  },
  {
    "timestamp": "11/10/24 8:00",
    "demand": 30169.58517,
    "solar_generated": 12.57921936,
    "wind_generated": 79.48174067,
    "marketplace_to_demand": 177.2793301,
    "grid_to_demand": 30769.86384,
    "govt_battery_charge": 96.49425815
  },
  {
    "timestamp": "11/10/24 9:00",
    "demand": 33468.16358,
    "solar_generated": 17.5714548,
    "wind_generated": 62.51951557,
    "marketplace_to_demand": 77.91612399,
    "grid_to_demand": 34038.66136,
    "govt_battery_charge": 51.04595648
  },
  {
    "timestamp": "11/10/24 10:00",
    "demand": 35256.63145,
    "solar_generated": 11.88538048,
    "wind_generated": 63.1971828,
    "marketplace_to_demand": 24.41593432,
    "grid_to_demand": 29943.07795,
    "govt_battery_charge": 74.33488023
  },
  {
    "timestamp": "11/10/24 11:00",
    "demand": 33765.101,
    "solar_generated": 7.631035463,
    "wind_generated": 56.17589822,
    "marketplace_to_demand": 127.738371,
    "grid_to_demand": 34355.05282,
    "govt_battery_charge": 93.6001431
  },
  {
    "timestamp": "11/10/24 12:00",
    "demand": 33358.87365,
    "solar_generated": 13.77583819,
    "wind_generated": 80.09250361,
    "marketplace_to_demand": 110.989807,
    "grid_to_demand": 32553.92606,
    "govt_battery_charge": 27.4223904
  },
  {
    "timestamp": "11/10/24 13:00",
    "demand": 36060.19998,
    "solar_generated": 22.88827544,
    "wind_generated": 76.98746452,
    "marketplace_to_demand": 25.88589762,
    "grid_to_demand": 33483.37697,
    "govt_battery_charge": 60.45866661
  },
  {
    "timestamp": "11/10/24 14:00",
    "demand": 34857.426,
    "solar_generated": 16.0158793,
    "wind_generated": 57.71450573,
    "marketplace_to_demand": 68.31593185,
    "grid_to_demand": 31214.00378,
    "govt_battery_charge": 1.641546394
  },
  {
    "timestamp": "11/10/24 15:00",
    "demand": 33207.51514,
    "solar_generated": 21.4543086,
    "wind_generated": 77.46700328,
    "marketplace_to_demand": 154.9422976,
    "grid_to_demand": 34348.35756,
    "govt_battery_charge": 72.27344008
  },
  {
    "timestamp": "11/10/24 16:00",
    "demand": 31970.62652,
    "solar_generated": 20.61219774,
    "wind_generated": 55.95920372,
    "marketplace_to_demand": 108.4479787,
    "grid_to_demand": 33959.15937,
    "govt_battery_charge": 59.67180779
  },
  {
    "timestamp": "11/10/24 17:00",
    "demand": 34499.13074,
    "solar_generated": 14.80493332,
    "wind_generated": 63.56114065,
    "marketplace_to_demand": 32.53943712,
    "grid_to_demand": 34910.90257,
    "govt_battery_charge": 12.31014013
  },
  {
    "timestamp": "11/10/24 18:00",
    "demand": 30458.08506,
    "solar_generated": 0,
    "wind_generated": 72.58793003,
    "marketplace_to_demand": 31.83036548,
    "grid_to_demand": 34995.50052,
    "govt_battery_charge": 46.52555897
  },
  {
    "timestamp": "11/10/24 19:00",
    "demand": 33654.46894,
    "solar_generated": 0,
    "wind_generated": 75.54460443,
    "marketplace_to_demand": 111.7169754,
    "grid_to_demand": 32862.49055,
    "govt_battery_charge": 63.70288153
  },
  {
    "timestamp": "11/10/24 20:00",
    "demand": 32504.26671,
    "solar_generated": 0,
    "wind_generated": 69.11768445,
    "marketplace_to_demand": 142.0956343,
    "grid_to_demand": 34465.60803,
    "govt_battery_charge": 26.78217112
  },
  {
    "timestamp": "11/10/24 21:00",
    "demand": 33615.24865,
    "solar_generated": 0,
    "wind_generated": 54.10724296,
    "marketplace_to_demand": 44.95898437,
    "grid_to_demand": 36131.58549,
    "govt_battery_charge": 19.39781045
  },
  {
    "timestamp": "11/10/24 22:00",
    "demand": 32735.0242,
    "solar_generated": 0,
    "wind_generated": 61.59061125,
    "marketplace_to_demand": 49.67427182,
    "grid_to_demand": 36448.35363,
    "govt_battery_charge": 19.36171642
  },
  {
    "timestamp": "11/10/24 23:00",
    "demand": 33266.39465,
    "solar_generated": 0,
    "wind_generated": 77.4310662,
    "marketplace_to_demand": 67.40631865,
    "grid_to_demand": 35452.99594,
    "govt_battery_charge": 70.36339395
  },
  {
    "timestamp": "12/10/24 0:00",
    "demand": 36314.06513,
    "solar_generated": 0,
    "wind_generated": 76.68512871,
    "marketplace_to_demand": 146.3143181,
    "grid_to_demand": 35792.70295,
    "govt_battery_charge": 65.52465761
  },
  {
    "timestamp": "12/10/24 1:00",
    "demand": 32163.53603,
    "solar_generated": 0,
    "wind_generated": 79.48999924,
    "marketplace_to_demand": 167.6699184,
    "grid_to_demand": 33591.99591,
    "govt_battery_charge": 74.17485758
  },
  {
    "timestamp": "12/10/24 2:00",
    "demand": 35868.72,
    "solar_generated": 0,
    "wind_generated": 63.25177769,
    "marketplace_to_demand": 56.75341229,
    "grid_to_demand": 30640.0631,
    "govt_battery_charge": 67.44918369
  },
  {
    "timestamp": "12/10/24 3:00",
    "demand": 30240.32962,
    "solar_generated": 0,
    "wind_generated": 72.82376276,
    "marketplace_to_demand": 46.16376231,
    "grid_to_demand": 30171.7206,
    "govt_battery_charge": 84.02825606
  },
  {
    "timestamp": "12/10/24 4:00",
    "demand": 33938.66387,
    "solar_generated": 0,
    "wind_generated": 63.31387514,
    "marketplace_to_demand": 152.3526176,
    "grid_to_demand": 30467.91991,
    "govt_battery_charge": 5.322769807
  },
  {
    "timestamp": "12/10/24 5:00",
    "demand": 34495.98799,
    "solar_generated": 0,
    "wind_generated": 67.07720491,
    "marketplace_to_demand": 91.38183475,
    "grid_to_demand": 36025.9244,
    "govt_battery_charge": 13.291773
  },
  {
    "timestamp": "12/10/24 6:00",
    "demand": 33059.11324,
    "solar_generated": 14.53035876,
    "wind_generated": 61.26470828,
    "marketplace_to_demand": 124.2960634,
    "grid_to_demand": 35401.55795,
    "govt_battery_charge": 69.42152594
  },
  {
    "timestamp": "12/10/24 7:00",
    "demand": 33725.57603,
    "solar_generated": 9.683699743,
    "wind_generated": 69.8469237,
    "marketplace_to_demand": 189.4030942,
    "grid_to_demand": 30629.87982,
    "govt_battery_charge": 11.58226512
  },
  {
    "timestamp": "12/10/24 8:00",
    "demand": 35110.51968,
    "solar_generated": 12.92386353,
    "wind_generated": 63.42438417,
    "marketplace_to_demand": 153.0610522,
    "grid_to_demand": 31106.82583,
    "govt_battery_charge": 37.27778822
  },
  {
    "timestamp": "12/10/24 9:00",
    "demand": 32024.41252,
    "solar_generated": 15.64989271,
    "wind_generated": 73.59728202,
    "marketplace_to_demand": 155.0473506,
    "grid_to_demand": 30410.99732,
    "govt_battery_charge": 16.11176107
  },
  {
    "timestamp": "12/10/24 10:00",
    "demand": 36539.97322,
    "solar_generated": 14.54986463,
    "wind_generated": 72.77319676,
    "marketplace_to_demand": 74.33303496,
    "grid_to_demand": 32868.01047,
    "govt_battery_charge": 88.24771991
  },
  {
    "timestamp": "12/10/24 11:00",
    "demand": 32316.69086,
    "solar_generated": 18.4041238,
    "wind_generated": 71.63977316,
    "marketplace_to_demand": 134.9403944,
    "grid_to_demand": 34466.54159,
    "govt_battery_charge": 56.08964256
  },
  {
    "timestamp": "12/10/24 12:00",
    "demand": 30326.46445,
    "solar_generated": 21.26878255,
    "wind_generated": 67.32160993,
    "marketplace_to_demand": 37.49958763,
    "grid_to_demand": 34823.80885,
    "govt_battery_charge": 15.26831063
  },
  {
    "timestamp": "12/10/24 13:00",
    "demand": 30906.70658,
    "solar_generated": 19.74110318,
    "wind_generated": 72.48416561,
    "marketplace_to_demand": 41.28636022,
    "grid_to_demand": 34102.05254,
    "govt_battery_charge": 97.09155995
  },
  {
    "timestamp": "12/10/24 14:00",
    "demand": 29984.91604,
    "solar_generated": 9.813772759,
    "wind_generated": 62.86371587,
    "marketplace_to_demand": 52.91284216,
    "grid_to_demand": 29999.88681,
    "govt_battery_charge": 34.30338422
  },
  {
    "timestamp": "12/10/24 15:00",
    "demand": 34446.80536,
    "solar_generated": 10.96441565,
    "wind_generated": 75.12265287,
    "marketplace_to_demand": 146.7771773,
    "grid_to_demand": 30288.69589,
    "govt_battery_charge": 45.85700359
  },
  {
    "timestamp": "12/10/24 16:00",
    "demand": 35485.10235,
    "solar_generated": 16.73912413,
    "wind_generated": 72.91931161,
    "marketplace_to_demand": 8.248109995,
    "grid_to_demand": 35503.75246,
    "govt_battery_charge": 84.34760513
  },
  {
    "timestamp": "12/10/24 17:00",
    "demand": 32886.11392,
    "solar_generated": 18.42917845,
    "wind_generated": 61.2003148,
    "marketplace_to_demand": 143.58677,
    "grid_to_demand": 32661.78347,
    "govt_battery_charge": 46.95668647
  },
  {
    "timestamp": "12/10/24 18:00",
    "demand": 31155.6499,
    "solar_generated": 0,
    "wind_generated": 71.69699475,
    "marketplace_to_demand": 151.0839395,
    "grid_to_demand": 35652.74845,
    "govt_battery_charge": 38.73306762
  },
  {
    "timestamp": "12/10/24 19:00",
    "demand": 35853.48173,
    "solar_generated": 0,
    "wind_generated": 78.89020636,
    "marketplace_to_demand": 49.20026725,
    "grid_to_demand": 33248.61246,
    "govt_battery_charge": 87.9698816
  },
  {
    "timestamp": "12/10/24 20:00",
    "demand": 33548.97848,
    "solar_generated": 0,
    "wind_generated": 60.97363235,
    "marketplace_to_demand": 41.2185661,
    "grid_to_demand": 36444.5746,
    "govt_battery_charge": 1.680579334
  },
  {
    "timestamp": "12/10/24 21:00",
    "demand": 31633.58709,
    "solar_generated": 0,
    "wind_generated": 79.31056173,
    "marketplace_to_demand": 119.1020877,
    "grid_to_demand": 36280.51385,
    "govt_battery_charge": 45.68453764
  },
  {
    "timestamp": "12/10/24 22:00",
    "demand": 36519.42311,
    "solar_generated": 0,
    "wind_generated": 60.80097908,
    "marketplace_to_demand": 160.5958043,
    "grid_to_demand": 31575.0952,
    "govt_battery_charge": 6.672009035
  },
  {
    "timestamp": "12/10/24 23:00",
    "demand": 30804.52203,
    "solar_generated": 0,
    "wind_generated": 75.09799115,
    "marketplace_to_demand": 13.93985981,
    "grid_to_demand": 32102.62355,
    "govt_battery_charge": 77.54280176
  },
  {
    "timestamp": "13/10/24 0:00",
    "demand": 33635.42083,
    "solar_generated": 0,
    "wind_generated": 60.58950329,
    "marketplace_to_demand": 8.279777276,
    "grid_to_demand": 30814.2302,
    "govt_battery_charge": 64.17440892
  },
  {
    "timestamp": "13/10/24 1:00",
    "demand": 34682.47595,
    "solar_generated": 0,
    "wind_generated": 76.72857082,
    "marketplace_to_demand": 151.7402226,
    "grid_to_demand": 32164.70104,
    "govt_battery_charge": 56.20776041
  },
  {
    "timestamp": "13/10/24 2:00",
    "demand": 36138.59594,
    "solar_generated": 0,
    "wind_generated": 75.38552667,
    "marketplace_to_demand": 89.88264647,
    "grid_to_demand": 30803.30849,
    "govt_battery_charge": 19.01220577
  },
  {
    "timestamp": "13/10/24 3:00",
    "demand": 35902.73094,
    "solar_generated": 0,
    "wind_generated": 63.33576257,
    "marketplace_to_demand": 6.44199144,
    "grid_to_demand": 35229.29338,
    "govt_battery_charge": 62.59342259
  },
  {
    "timestamp": "13/10/24 4:00",
    "demand": 31077.30741,
    "solar_generated": 0,
    "wind_generated": 75.24154198,
    "marketplace_to_demand": 187.6055594,
    "grid_to_demand": 29908.44872,
    "govt_battery_charge": 18.1194215
  },
  {
    "timestamp": "13/10/24 5:00",
    "demand": 34719.00351,
    "solar_generated": 0,
    "wind_generated": 54.20537293,
    "marketplace_to_demand": 130.3121109,
    "grid_to_demand": 36128.60734,
    "govt_battery_charge": 6.801667565
  },
  {
    "timestamp": "13/10/24 6:00",
    "demand": 36337.15696,
    "solar_generated": 8.497758219,
    "wind_generated": 56.20246159,
    "marketplace_to_demand": 97.63110007,
    "grid_to_demand": 32792.39941,
    "govt_battery_charge": 48.17641229
  },
  {
    "timestamp": "13/10/24 7:00",
    "demand": 31383.48159,
    "solar_generated": 21.08117154,
    "wind_generated": 74.3866317,
    "marketplace_to_demand": 158.7941325,
    "grid_to_demand": 30322.30997,
    "govt_battery_charge": 6.45717627
  },
  {
    "timestamp": "13/10/24 8:00",
    "demand": 34240.23762,
    "solar_generated": 12.13508909,
    "wind_generated": 76.39400188,
    "marketplace_to_demand": 199.7747482,
    "grid_to_demand": 31694.91469,
    "govt_battery_charge": 79.30268423
  },
  {
    "timestamp": "13/10/24 9:00",
    "demand": 34166.15779,
    "solar_generated": 20.68984714,
    "wind_generated": 79.34574321,
    "marketplace_to_demand": 22.11332697,
    "grid_to_demand": 30715.37068,
    "govt_battery_charge": 93.8529854
  },
  {
    "timestamp": "13/10/24 10:00",
    "demand": 36262.54829,
    "solar_generated": 9.104080309,
    "wind_generated": 54.14941273,
    "marketplace_to_demand": 117.4296405,
    "grid_to_demand": 32494.91006,
    "govt_battery_charge": 89.53313249
  },
  {
    "timestamp": "13/10/24 11:00",
    "demand": 32483.31975,
    "solar_generated": 21.60704314,
    "wind_generated": 56.49486763,
    "marketplace_to_demand": 42.99623247,
    "grid_to_demand": 36306.73117,
    "govt_battery_charge": 52.53225951
  },
  {
    "timestamp": "13/10/24 12:00",
    "demand": 34669.41939,
    "solar_generated": 14.82469258,
    "wind_generated": 65.05435557,
    "marketplace_to_demand": 141.4711444,
    "grid_to_demand": 34665.25684,
    "govt_battery_charge": 79.00733681
  },
  {
    "timestamp": "13/10/24 13:00",
    "demand": 33363.41454,
    "solar_generated": 9.777517918,
    "wind_generated": 76.940768,
    "marketplace_to_demand": 160.2940371,
    "grid_to_demand": 36278.42782,
    "govt_battery_charge": 52.55827374
  },
  {
    "timestamp": "13/10/24 14:00",
    "demand": 35115.40907,
    "solar_generated": 18.32819064,
    "wind_generated": 79.11200874,
    "marketplace_to_demand": 112.8868676,
    "grid_to_demand": 35120.7805,
    "govt_battery_charge": 13.95866291
  },
  {
    "timestamp": "13/10/24 15:00",
    "demand": 32522.43854,
    "solar_generated": 16.07037945,
    "wind_generated": 58.53123309,
    "marketplace_to_demand": 42.8942428,
    "grid_to_demand": 30192.21964,
    "govt_battery_charge": 4.111416453
  },
  {
    "timestamp": "13/10/24 16:00",
    "demand": 35877.62948,
    "solar_generated": 13.006618,
    "wind_generated": 80.09183596,
    "marketplace_to_demand": 32.9255255,
    "grid_to_demand": 33854.25491,
    "govt_battery_charge": 79.06925908
  },
  {
    "timestamp": "13/10/24 17:00",
    "demand": 31346.18789,
    "solar_generated": 21.72157023,
    "wind_generated": 56.00123068,
    "marketplace_to_demand": 176.1286197,
    "grid_to_demand": 35616.91505,
    "govt_battery_charge": 93.74170344
  },
  {
    "timestamp": "13/10/24 18:00",
    "demand": 34251.56938,
    "solar_generated": 0,
    "wind_generated": 78.46670372,
    "marketplace_to_demand": 31.38508728,
    "grid_to_demand": 35759.40599,
    "govt_battery_charge": 64.22739762
  },
  {
    "timestamp": "13/10/24 19:00",
    "demand": 34872.54946,
    "solar_generated": 0,
    "wind_generated": 56.98029697,
    "marketplace_to_demand": 94.37707542,
    "grid_to_demand": 33269.65939,
    "govt_battery_charge": 54.2767759
  },
  {
    "timestamp": "13/10/24 20:00",
    "demand": 35233.5392,
    "solar_generated": 0,
    "wind_generated": 55.84129019,
    "marketplace_to_demand": 89.20291082,
    "grid_to_demand": 30195.44162,
    "govt_battery_charge": 52.40649793
  },
  {
    "timestamp": "13/10/24 21:00",
    "demand": 33083.61739,
    "solar_generated": 0,
    "wind_generated": 66.88156043,
    "marketplace_to_demand": 134.4448668,
    "grid_to_demand": 34686.45619,
    "govt_battery_charge": 4.216539989
  },
  {
    "timestamp": "13/10/24 22:00",
    "demand": 35857.1837,
    "solar_generated": 0,
    "wind_generated": 62.71420042,
    "marketplace_to_demand": 71.62446929,
    "grid_to_demand": 32868.07815,
    "govt_battery_charge": 67.8472643
  },
  {
    "timestamp": "13/10/24 23:00",
    "demand": 32920.5663,
    "solar_generated": 0,
    "wind_generated": 68.28258396,
    "marketplace_to_demand": 1.477319068,
    "grid_to_demand": 34446.37658,
    "govt_battery_charge": 66.41332044
  },
  {
    "timestamp": "14/10/24 0:00",
    "demand": 31227.70218,
    "solar_generated": 0,
    "wind_generated": 61.91270413,
    "marketplace_to_demand": 122.824888,
    "grid_to_demand": 33461.93767,
    "govt_battery_charge": 33.18785032
  },
  {
    "timestamp": "14/10/24 1:00",
    "demand": 33382.99174,
    "solar_generated": 0,
    "wind_generated": 70.29958447,
    "marketplace_to_demand": 64.96640229,
    "grid_to_demand": 32433.93056,
    "govt_battery_charge": 13.01219064
  },
  {
    "timestamp": "14/10/24 2:00",
    "demand": 34666.32344,
    "solar_generated": 0,
    "wind_generated": 58.40863557,
    "marketplace_to_demand": 56.49207553,
    "grid_to_demand": 35862.96717,
    "govt_battery_charge": 6.331379203
  },
  {
    "timestamp": "14/10/24 3:00",
    "demand": 31203.15235,
    "solar_generated": 0,
    "wind_generated": 70.10180883,
    "marketplace_to_demand": 187.2704396,
    "grid_to_demand": 33018.11636,
    "govt_battery_charge": 86.90898845
  },
  {
    "timestamp": "14/10/24 4:00",
    "demand": 36536.82334,
    "solar_generated": 0,
    "wind_generated": 57.91935113,
    "marketplace_to_demand": 117.3172554,
    "grid_to_demand": 33129.93942,
    "govt_battery_charge": 81.68467836
  },
  {
    "timestamp": "14/10/24 5:00",
    "demand": 31742.33095,
    "solar_generated": 0,
    "wind_generated": 78.78482443,
    "marketplace_to_demand": 145.1997228,
    "grid_to_demand": 35014.17285,
    "govt_battery_charge": 58.43318078
  },
  {
    "timestamp": "14/10/24 6:00",
    "demand": 35782.88615,
    "solar_generated": 10.05475723,
    "wind_generated": 54.85861994,
    "marketplace_to_demand": 68.27191452,
    "grid_to_demand": 31770.00532,
    "govt_battery_charge": 78.71663765
  },
  {
    "timestamp": "14/10/24 7:00",
    "demand": 32460.6207,
    "solar_generated": 18.25198322,
    "wind_generated": 70.41675653,
    "marketplace_to_demand": 113.4221899,
    "grid_to_demand": 31497.02912,
    "govt_battery_charge": 20.04485757
  },
  {
    "timestamp": "14/10/24 8:00",
    "demand": 32329.22257,
    "solar_generated": 21.74097792,
    "wind_generated": 55.96970972,
    "marketplace_to_demand": 107.2502999,
    "grid_to_demand": 31042.43278,
    "govt_battery_charge": 1.801630352
  },
  {
    "timestamp": "14/10/24 9:00",
    "demand": 35293.98177,
    "solar_generated": 16.7055731,
    "wind_generated": 73.82721256,
    "marketplace_to_demand": 26.12760007,
    "grid_to_demand": 35913.85447,
    "govt_battery_charge": 82.71723345
  },
  {
    "timestamp": "14/10/24 10:00",
    "demand": 32867.60256,
    "solar_generated": 14.73276227,
    "wind_generated": 62.79204539,
    "marketplace_to_demand": 147.0999391,
    "grid_to_demand": 30589.02734,
    "govt_battery_charge": 59.36147324
  },
  {
    "timestamp": "14/10/24 11:00",
    "demand": 34631.29694,
    "solar_generated": 14.90352612,
    "wind_generated": 66.0261583,
    "marketplace_to_demand": 65.95062286,
    "grid_to_demand": 31636.96427,
    "govt_battery_charge": 84.89631582
  },
  {
    "timestamp": "14/10/24 12:00",
    "demand": 33107.54856,
    "solar_generated": 18.0494654,
    "wind_generated": 57.50494363,
    "marketplace_to_demand": 54.2303272,
    "grid_to_demand": 32634.55586,
    "govt_battery_charge": 86.31820231
  },
  {
    "timestamp": "14/10/24 13:00",
    "demand": 36015.52759,
    "solar_generated": 10.97395851,
    "wind_generated": 72.10284005,
    "marketplace_to_demand": 24.35118656,
    "grid_to_demand": 32864.10359,
    "govt_battery_charge": 40.79842906
  },
  {
    "timestamp": "14/10/24 14:00",
    "demand": 31055.48837,
    "solar_generated": 22.2999632,
    "wind_generated": 66.47648627,
    "marketplace_to_demand": 5.208807326,
    "grid_to_demand": 30252.93328,
    "govt_battery_charge": 56.35806365
  },
  {
    "timestamp": "14/10/24 15:00",
    "demand": 32015.29351,
    "solar_generated": 22.06543838,
    "wind_generated": 67.15282771,
    "marketplace_to_demand": 127.19057,
    "grid_to_demand": 35602.2212,
    "govt_battery_charge": 64.897867
  },
  {
    "timestamp": "14/10/24 16:00",
    "demand": 35557.08784,
    "solar_generated": 16.65296409,
    "wind_generated": 58.56055448,
    "marketplace_to_demand": 127.00815,
    "grid_to_demand": 36304.8181,
    "govt_battery_charge": 76.07341418
  },
  {
    "timestamp": "14/10/24 17:00",
    "demand": 30693.22841,
    "solar_generated": 23.91325877,
    "wind_generated": 65.85209308,
    "marketplace_to_demand": 198.9923265,
    "grid_to_demand": 34597.71915,
    "govt_battery_charge": 30.65935937
  },
  {
    "timestamp": "14/10/24 18:00",
    "demand": 32900.46678,
    "solar_generated": 0,
    "wind_generated": 68.64954279,
    "marketplace_to_demand": 193.3198092,
    "grid_to_demand": 30368.19447,
    "govt_battery_charge": 58.55521489
  },
  {
    "timestamp": "14/10/24 19:00",
    "demand": 36155.57054,
    "solar_generated": 0,
    "wind_generated": 74.85271597,
    "marketplace_to_demand": 41.14011835,
    "grid_to_demand": 32870.31105,
    "govt_battery_charge": 96.61001227
  },
  {
    "timestamp": "14/10/24 20:00",
    "demand": 34818.72275,
    "solar_generated": 0,
    "wind_generated": 70.00709413,
    "marketplace_to_demand": 168.3786404,
    "grid_to_demand": 34859.15118,
    "govt_battery_charge": 70.19436113
  },
  {
    "timestamp": "14/10/24 21:00",
    "demand": 34669.18271,
    "solar_generated": 0,
    "wind_generated": 74.19420892,
    "marketplace_to_demand": 69.15897935,
    "grid_to_demand": 34493.70323,
    "govt_battery_charge": 13.33371531
  },
  {
    "timestamp": "14/10/24 22:00",
    "demand": 30972.96126,
    "solar_generated": 0,
    "wind_generated": 70.20007446,
    "marketplace_to_demand": 4.08964965,
    "grid_to_demand": 34223.90123,
    "govt_battery_charge": 94.19234086
  },
  {
    "timestamp": "14/10/24 23:00",
    "demand": 30424.92118,
    "solar_generated": 0,
    "wind_generated": 61.0993914,
    "marketplace_to_demand": 87.32737531,
    "grid_to_demand": 35023.23382,
    "govt_battery_charge": 42.71579481
  },
  {
    "timestamp": "15/10/24 0:00",
    "demand": 33370.4007,
    "solar_generated": 0,
    "wind_generated": 57.68149479,
    "marketplace_to_demand": 180.019021,
    "grid_to_demand": 33254.89146,
    "govt_battery_charge": 66.35368656
  },
  {
    "timestamp": "15/10/24 1:00",
    "demand": 36164.22125,
    "solar_generated": 0,
    "wind_generated": 77.27034591,
    "marketplace_to_demand": 20.4727641,
    "grid_to_demand": 33480.432,
    "govt_battery_charge": 98.65588892
  },
  {
    "timestamp": "15/10/24 2:00",
    "demand": 31758.08202,
    "solar_generated": 0,
    "wind_generated": 69.07928611,
    "marketplace_to_demand": 89.63222929,
    "grid_to_demand": 34384.34798,
    "govt_battery_charge": 42.95876912
  },
  {
    "timestamp": "15/10/24 3:00",
    "demand": 33934.65909,
    "solar_generated": 0,
    "wind_generated": 54.14869504,
    "marketplace_to_demand": 104.9375934,
    "grid_to_demand": 33101.63784,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "15/10/24 4:00",
    "demand": 30929.93908,
    "solar_generated": 0,
    "wind_generated": 74.30620987,
    "marketplace_to_demand": 80.26533907,
    "grid_to_demand": 33079.58223,
    "govt_battery_charge": 25.97192274
  },
  {
    "timestamp": "15/10/24 5:00",
    "demand": 33795.38988,
    "solar_generated": 0,
    "wind_generated": 59.23634524,
    "marketplace_to_demand": 38.70181858,
    "grid_to_demand": 31974.28518,
    "govt_battery_charge": 66.48312533
  },
  {
    "timestamp": "15/10/24 6:00",
    "demand": 35208.48284,
    "solar_generated": 22.14208284,
    "wind_generated": 57.24487795,
    "marketplace_to_demand": 117.7294976,
    "grid_to_demand": 34004.72492,
    "govt_battery_charge": 16.65104495
  },
  {
    "timestamp": "15/10/24 7:00",
    "demand": 32446.04144,
    "solar_generated": 16.2833117,
    "wind_generated": 76.4167203,
    "marketplace_to_demand": 162.3029817,
    "grid_to_demand": 33757.2581,
    "govt_battery_charge": 24.92543742
  },
  {
    "timestamp": "15/10/24 8:00",
    "demand": 32397.30813,
    "solar_generated": 15.06646923,
    "wind_generated": 62.77985359,
    "marketplace_to_demand": 149.8458899,
    "grid_to_demand": 30068.77543,
    "govt_battery_charge": 54.5969393
  },
  {
    "timestamp": "15/10/24 9:00",
    "demand": 30900.36904,
    "solar_generated": 16.76065289,
    "wind_generated": 73.957468,
    "marketplace_to_demand": 49.61307302,
    "grid_to_demand": 33586.66425,
    "govt_battery_charge": 56.52096662
  },
  {
    "timestamp": "15/10/24 10:00",
    "demand": 31067.19709,
    "solar_generated": 8.091951072,
    "wind_generated": 61.19705051,
    "marketplace_to_demand": 39.38790396,
    "grid_to_demand": 35770.03881,
    "govt_battery_charge": 1.455700243
  },
  {
    "timestamp": "15/10/24 11:00",
    "demand": 35562.80087,
    "solar_generated": 17.72615793,
    "wind_generated": 64.45770817,
    "marketplace_to_demand": 121.8694761,
    "grid_to_demand": 34727.91343,
    "govt_battery_charge": 59.89922052
  },
  {
    "timestamp": "15/10/24 12:00",
    "demand": 33916.86234,
    "solar_generated": 17.99332719,
    "wind_generated": 55.29756097,
    "marketplace_to_demand": 56.42087515,
    "grid_to_demand": 30004.3333,
    "govt_battery_charge": 2.163867915
  },
  {
    "timestamp": "15/10/24 13:00",
    "demand": 32176.13606,
    "solar_generated": 12.29987551,
    "wind_generated": 64.70547118,
    "marketplace_to_demand": 125.754484,
    "grid_to_demand": 36304.82545,
    "govt_battery_charge": 69.93121518
  },
  {
    "timestamp": "15/10/24 14:00",
    "demand": 34404.28532,
    "solar_generated": 15.31384678,
    "wind_generated": 65.52300619,
    "marketplace_to_demand": 30.97641983,
    "grid_to_demand": 35813.84852,
    "govt_battery_charge": 89.79505743
  },
  {
    "timestamp": "15/10/24 15:00",
    "demand": 30428.4925,
    "solar_generated": 10.49739771,
    "wind_generated": 74.62051691,
    "marketplace_to_demand": 45.42774538,
    "grid_to_demand": 34963.00536,
    "govt_battery_charge": 92.25253376
  },
  {
    "timestamp": "15/10/24 16:00",
    "demand": 30826.25005,
    "solar_generated": 15.95557694,
    "wind_generated": 72.9082198,
    "marketplace_to_demand": 4.77140473,
    "grid_to_demand": 34169.17301,
    "govt_battery_charge": 2.845666697
  },
  {
    "timestamp": "15/10/24 17:00",
    "demand": 35377.45406,
    "solar_generated": 17.38357358,
    "wind_generated": 59.10140747,
    "marketplace_to_demand": 38.06835822,
    "grid_to_demand": 32615.49831,
    "govt_battery_charge": 26.91453028
  },
  {
    "timestamp": "15/10/24 18:00",
    "demand": 34450.8966,
    "solar_generated": 0,
    "wind_generated": 54.71966854,
    "marketplace_to_demand": 73.99857455,
    "grid_to_demand": 35930.30052,
    "govt_battery_charge": 20.52859683
  },
  {
    "timestamp": "15/10/24 19:00",
    "demand": 34408.52868,
    "solar_generated": 0,
    "wind_generated": 67.75053354,
    "marketplace_to_demand": 19.49427743,
    "grid_to_demand": 31355.74879,
    "govt_battery_charge": 46.59347457
  },
  {
    "timestamp": "15/10/24 20:00",
    "demand": 33674.00421,
    "solar_generated": 0,
    "wind_generated": 61.75273037,
    "marketplace_to_demand": 49.35805421,
    "grid_to_demand": 33913.54724,
    "govt_battery_charge": 67.7803987
  },
  {
    "timestamp": "15/10/24 21:00",
    "demand": 31380.33172,
    "solar_generated": 0,
    "wind_generated": 78.51595612,
    "marketplace_to_demand": 158.9829724,
    "grid_to_demand": 34083.09016,
    "govt_battery_charge": 56.38398072
  },
  {
    "timestamp": "15/10/24 22:00",
    "demand": 35447.8039,
    "solar_generated": 0,
    "wind_generated": 75.57609736,
    "marketplace_to_demand": 36.28285253,
    "grid_to_demand": 33185.08847,
    "govt_battery_charge": 25.9581653
  },
  {
    "timestamp": "15/10/24 23:00",
    "demand": 31337.04124,
    "solar_generated": 0,
    "wind_generated": 72.8595828,
    "marketplace_to_demand": 66.36001806,
    "grid_to_demand": 31206.46905,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "16/10/24 0:00",
    "demand": 30522.59469,
    "solar_generated": 0,
    "wind_generated": 78.83832537,
    "marketplace_to_demand": 114.9612926,
    "grid_to_demand": 34596.96084,
    "govt_battery_charge": 35.00020583
  },
  {
    "timestamp": "16/10/24 1:00",
    "demand": 35781.4721,
    "solar_generated": 0,
    "wind_generated": 78.8426768,
    "marketplace_to_demand": 64.44025203,
    "grid_to_demand": 31591.97609,
    "govt_battery_charge": 16.33910675
  },
  {
    "timestamp": "16/10/24 2:00",
    "demand": 34553.99657,
    "solar_generated": 0,
    "wind_generated": 61.36292034,
    "marketplace_to_demand": 65.78925973,
    "grid_to_demand": 33109.90291,
    "govt_battery_charge": 88.95114069
  },
  {
    "timestamp": "16/10/24 3:00",
    "demand": 35154.08914,
    "solar_generated": 0,
    "wind_generated": 67.49850665,
    "marketplace_to_demand": 188.2802547,
    "grid_to_demand": 33700.25336,
    "govt_battery_charge": 19.7424079
  },
  {
    "timestamp": "16/10/24 4:00",
    "demand": 32600.22562,
    "solar_generated": 0,
    "wind_generated": 77.0716193,
    "marketplace_to_demand": 148.553211,
    "grid_to_demand": 35162.77726,
    "govt_battery_charge": 16.97884275
  },
  {
    "timestamp": "16/10/24 5:00",
    "demand": 31809.19819,
    "solar_generated": 0,
    "wind_generated": 62.50747953,
    "marketplace_to_demand": 2.688481062,
    "grid_to_demand": 30164.24511,
    "govt_battery_charge": 13.97661963
  },
  {
    "timestamp": "16/10/24 6:00",
    "demand": 35188.42203,
    "solar_generated": 12.0288694,
    "wind_generated": 63.94912168,
    "marketplace_to_demand": 158.7219006,
    "grid_to_demand": 30719.77564,
    "govt_battery_charge": 3.159568177
  },
  {
    "timestamp": "16/10/24 7:00",
    "demand": 30277.01127,
    "solar_generated": 22.90941843,
    "wind_generated": 70.42180588,
    "marketplace_to_demand": 145.6601452,
    "grid_to_demand": 32612.47448,
    "govt_battery_charge": 45.4481422
  },
  {
    "timestamp": "16/10/24 8:00",
    "demand": 30305.74161,
    "solar_generated": 10.86736757,
    "wind_generated": 55.91930867,
    "marketplace_to_demand": 120.1849495,
    "grid_to_demand": 33219.70337,
    "govt_battery_charge": 48.23270385
  },
  {
    "timestamp": "16/10/24 9:00",
    "demand": 30781.04169,
    "solar_generated": 12.9233192,
    "wind_generated": 68.04616881,
    "marketplace_to_demand": 67.2265587,
    "grid_to_demand": 33498.04351,
    "govt_battery_charge": 77.19985274
  },
  {
    "timestamp": "16/10/24 10:00",
    "demand": 31328.94886,
    "solar_generated": 17.72486444,
    "wind_generated": 62.80514408,
    "marketplace_to_demand": 192.1161677,
    "grid_to_demand": 33651.14237,
    "govt_battery_charge": 51.45463895
  },
  {
    "timestamp": "16/10/24 11:00",
    "demand": 30742.96958,
    "solar_generated": 9.089303722,
    "wind_generated": 59.96845009,
    "marketplace_to_demand": 131.4742213,
    "grid_to_demand": 31729.07174,
    "govt_battery_charge": 71.08793426
  },
  {
    "timestamp": "16/10/24 12:00",
    "demand": 31315.12728,
    "solar_generated": 11.04315281,
    "wind_generated": 74.45492439,
    "marketplace_to_demand": 12.52707858,
    "grid_to_demand": 32335.67387,
    "govt_battery_charge": 87.15615485
  },
  {
    "timestamp": "16/10/24 13:00",
    "demand": 32984.40169,
    "solar_generated": 20.71039693,
    "wind_generated": 73.74333744,
    "marketplace_to_demand": 140.4764911,
    "grid_to_demand": 30781.85087,
    "govt_battery_charge": 11.81091511
  },
  {
    "timestamp": "16/10/24 14:00",
    "demand": 33658.41731,
    "solar_generated": 12.20299395,
    "wind_generated": 80.169111,
    "marketplace_to_demand": 11.92573966,
    "grid_to_demand": 33501.55326,
    "govt_battery_charge": 17.51867499
  },
  {
    "timestamp": "16/10/24 15:00",
    "demand": 36213.50766,
    "solar_generated": 14.77279473,
    "wind_generated": 58.02946609,
    "marketplace_to_demand": 134.251973,
    "grid_to_demand": 35845.63024,
    "govt_battery_charge": 24.3315687
  },
  {
    "timestamp": "16/10/24 16:00",
    "demand": 35447.1878,
    "solar_generated": 7.165283723,
    "wind_generated": 78.474454,
    "marketplace_to_demand": 128.885551,
    "grid_to_demand": 32023.33298,
    "govt_battery_charge": 36.73332393
  },
  {
    "timestamp": "16/10/24 17:00",
    "demand": 36411.05019,
    "solar_generated": 11.99450374,
    "wind_generated": 68.97786696,
    "marketplace_to_demand": 46.15561367,
    "grid_to_demand": 36387.87055,
    "govt_battery_charge": 28.88740113
  },
  {
    "timestamp": "16/10/24 18:00",
    "demand": 32890.38791,
    "solar_generated": 0,
    "wind_generated": 65.47404285,
    "marketplace_to_demand": 29.62258304,
    "grid_to_demand": 35853.90515,
    "govt_battery_charge": 73.93960712
  },
  {
    "timestamp": "16/10/24 19:00",
    "demand": 35534.40452,
    "solar_generated": 0,
    "wind_generated": 59.99421235,
    "marketplace_to_demand": 17.54948426,
    "grid_to_demand": 30653.41453,
    "govt_battery_charge": 54.384636
  },
  {
    "timestamp": "16/10/24 20:00",
    "demand": 33637.21824,
    "solar_generated": 0,
    "wind_generated": 57.29943217,
    "marketplace_to_demand": 143.0260186,
    "grid_to_demand": 33349.39061,
    "govt_battery_charge": 29.45980174
  },
  {
    "timestamp": "16/10/24 21:00",
    "demand": 35079.66866,
    "solar_generated": 0,
    "wind_generated": 66.33123088,
    "marketplace_to_demand": 126.6518357,
    "grid_to_demand": 30939.93547,
    "govt_battery_charge": 35.36789834
  },
  {
    "timestamp": "16/10/24 22:00",
    "demand": 30556.30451,
    "solar_generated": 0,
    "wind_generated": 74.13810358,
    "marketplace_to_demand": 117.7920307,
    "grid_to_demand": 33589.29054,
    "govt_battery_charge": 16.81845904
  },
  {
    "timestamp": "16/10/24 23:00",
    "demand": 32441.84162,
    "solar_generated": 0,
    "wind_generated": 58.07969846,
    "marketplace_to_demand": 45.25720917,
    "grid_to_demand": 30790.98904,
    "govt_battery_charge": 22.27660165
  },
  {
    "timestamp": "17/10/24 0:00",
    "demand": 31158.75154,
    "solar_generated": 0,
    "wind_generated": 68.21542333,
    "marketplace_to_demand": 146.3366116,
    "grid_to_demand": 30990.98058,
    "govt_battery_charge": 21.01060057
  },
  {
    "timestamp": "17/10/24 1:00",
    "demand": 35179.82996,
    "solar_generated": 0,
    "wind_generated": 78.4824636,
    "marketplace_to_demand": 158.3923584,
    "grid_to_demand": 30577.87003,
    "govt_battery_charge": 6.116431219
  },
  {
    "timestamp": "17/10/24 2:00",
    "demand": 31649.12492,
    "solar_generated": 0,
    "wind_generated": 57.40930857,
    "marketplace_to_demand": 148.2933776,
    "grid_to_demand": 35422.62542,
    "govt_battery_charge": 17.01351903
  },
  {
    "timestamp": "17/10/24 3:00",
    "demand": 34093.66238,
    "solar_generated": 0,
    "wind_generated": 68.5114073,
    "marketplace_to_demand": 91.38594174,
    "grid_to_demand": 36129.85307,
    "govt_battery_charge": 7.063401195
  },
  {
    "timestamp": "17/10/24 4:00",
    "demand": 30336.51645,
    "solar_generated": 0,
    "wind_generated": 57.52697276,
    "marketplace_to_demand": 93.92619557,
    "grid_to_demand": 32370.87087,
    "govt_battery_charge": 82.0304258
  },
  {
    "timestamp": "17/10/24 5:00",
    "demand": 34674.45786,
    "solar_generated": 0,
    "wind_generated": 71.95080949,
    "marketplace_to_demand": 110.2857372,
    "grid_to_demand": 36164.93304,
    "govt_battery_charge": 20.88266456
  },
  {
    "timestamp": "17/10/24 6:00",
    "demand": 35844.23404,
    "solar_generated": 6.51284974,
    "wind_generated": 58.72883957,
    "marketplace_to_demand": 190.3199951,
    "grid_to_demand": 35657.28559,
    "govt_battery_charge": 54.2258128
  },
  {
    "timestamp": "17/10/24 7:00",
    "demand": 31910.09281,
    "solar_generated": 11.43106767,
    "wind_generated": 66.1793513,
    "marketplace_to_demand": 165.4262049,
    "grid_to_demand": 36069.27486,
    "govt_battery_charge": 45.07994974
  },
  {
    "timestamp": "17/10/24 8:00",
    "demand": 34183.6273,
    "solar_generated": 8.578760368,
    "wind_generated": 65.62168284,
    "marketplace_to_demand": 141.727106,
    "grid_to_demand": 34241.08888,
    "govt_battery_charge": 48.12312233
  },
  {
    "timestamp": "17/10/24 9:00",
    "demand": 34892.36861,
    "solar_generated": 21.83700763,
    "wind_generated": 70.15365521,
    "marketplace_to_demand": 160.9037933,
    "grid_to_demand": 33331.10194,
    "govt_battery_charge": 53.49589314
  },
  {
    "timestamp": "17/10/24 10:00",
    "demand": 35774.03395,
    "solar_generated": 8.618376849,
    "wind_generated": 62.5518534,
    "marketplace_to_demand": 102.6217051,
    "grid_to_demand": 33742.78958,
    "govt_battery_charge": 82.41364565
  },
  {
    "timestamp": "17/10/24 11:00",
    "demand": 31137.55631,
    "solar_generated": 18.34657079,
    "wind_generated": 58.88160598,
    "marketplace_to_demand": 97.9311758,
    "grid_to_demand": 30294.21601,
    "govt_battery_charge": 93.61492467
  },
  {
    "timestamp": "17/10/24 12:00",
    "demand": 35403.60619,
    "solar_generated": 7.577546669,
    "wind_generated": 60.03267251,
    "marketplace_to_demand": 122.4278261,
    "grid_to_demand": 30710.91382,
    "govt_battery_charge": 86.38699186
  },
  {
    "timestamp": "17/10/24 13:00",
    "demand": 30132.44335,
    "solar_generated": 6.88271385,
    "wind_generated": 55.61381298,
    "marketplace_to_demand": 114.265244,
    "grid_to_demand": 34046.84022,
    "govt_battery_charge": 47.33324651
  },
  {
    "timestamp": "17/10/24 14:00",
    "demand": 30906.95747,
    "solar_generated": 10.27804414,
    "wind_generated": 59.11824373,
    "marketplace_to_demand": 112.9288123,
    "grid_to_demand": 31815.1726,
    "govt_battery_charge": 71.21496638
  },
  {
    "timestamp": "17/10/24 15:00",
    "demand": 32375.89863,
    "solar_generated": 8.575861647,
    "wind_generated": 61.82464048,
    "marketplace_to_demand": 116.1734204,
    "grid_to_demand": 31333.69249,
    "govt_battery_charge": 18.62841988
  },
  {
    "timestamp": "17/10/24 16:00",
    "demand": 32077.8932,
    "solar_generated": 8.467522784,
    "wind_generated": 57.50387242,
    "marketplace_to_demand": 172.4077432,
    "grid_to_demand": 34469.39906,
    "govt_battery_charge": 84.33944992
  },
  {
    "timestamp": "17/10/24 17:00",
    "demand": 32278.63301,
    "solar_generated": 6.408399771,
    "wind_generated": 65.52816513,
    "marketplace_to_demand": 97.32332872,
    "grid_to_demand": 31136.89446,
    "govt_battery_charge": 41.01975537
  },
  {
    "timestamp": "17/10/24 18:00",
    "demand": 35426.01898,
    "solar_generated": 0,
    "wind_generated": 60.72289918,
    "marketplace_to_demand": 62.98279123,
    "grid_to_demand": 34178.91657,
    "govt_battery_charge": 63.11134289
  },
  {
    "timestamp": "17/10/24 19:00",
    "demand": 31576.99658,
    "solar_generated": 0,
    "wind_generated": 66.10419273,
    "marketplace_to_demand": 130.9188677,
    "grid_to_demand": 34574.61376,
    "govt_battery_charge": 68.08074857
  },
  {
    "timestamp": "17/10/24 20:00",
    "demand": 34230.11602,
    "solar_generated": 0,
    "wind_generated": 71.84709874,
    "marketplace_to_demand": 196.9579996,
    "grid_to_demand": 31394.17767,
    "govt_battery_charge": 26.40623197
  },
  {
    "timestamp": "17/10/24 21:00",
    "demand": 35211.07133,
    "solar_generated": 0,
    "wind_generated": 73.84274203,
    "marketplace_to_demand": 22.76625347,
    "grid_to_demand": 33147.27193,
    "govt_battery_charge": 85.12221409
  },
  {
    "timestamp": "17/10/24 22:00",
    "demand": 30291.07331,
    "solar_generated": 0,
    "wind_generated": 55.46711168,
    "marketplace_to_demand": 71.90910337,
    "grid_to_demand": 35747.97964,
    "govt_battery_charge": 94.85503093
  },
  {
    "timestamp": "17/10/24 23:00",
    "demand": 30289.44449,
    "solar_generated": 0,
    "wind_generated": 62.62124566,
    "marketplace_to_demand": 127.5840545,
    "grid_to_demand": 33672.35351,
    "govt_battery_charge": 30.92709324
  },
  {
    "timestamp": "18/10/24 0:00",
    "demand": 36207.21481,
    "solar_generated": 0,
    "wind_generated": 61.46190423,
    "marketplace_to_demand": 181.2307775,
    "grid_to_demand": 32669.95738,
    "govt_battery_charge": 36.77666206
  },
  {
    "timestamp": "18/10/24 1:00",
    "demand": 32648.61337,
    "solar_generated": 0,
    "wind_generated": 79.53794657,
    "marketplace_to_demand": 99.77643091,
    "grid_to_demand": 34227.79101,
    "govt_battery_charge": 1.131111648
  },
  {
    "timestamp": "18/10/24 2:00",
    "demand": 34710.07969,
    "solar_generated": 0,
    "wind_generated": 67.37381877,
    "marketplace_to_demand": 74.40038169,
    "grid_to_demand": 35207.37113,
    "govt_battery_charge": 71.88285406
  },
  {
    "timestamp": "18/10/24 3:00",
    "demand": 31207.70995,
    "solar_generated": 0,
    "wind_generated": 70.74036151,
    "marketplace_to_demand": 111.823864,
    "grid_to_demand": 31260.60885,
    "govt_battery_charge": 3.791691599
  },
  {
    "timestamp": "18/10/24 4:00",
    "demand": 31354.59905,
    "solar_generated": 0,
    "wind_generated": 58.76908487,
    "marketplace_to_demand": 20.72224346,
    "grid_to_demand": 33202.58578,
    "govt_battery_charge": 76.33789762
  },
  {
    "timestamp": "18/10/24 5:00",
    "demand": 34058.36914,
    "solar_generated": 0,
    "wind_generated": 76.32921452,
    "marketplace_to_demand": 195.2753113,
    "grid_to_demand": 35379.90608,
    "govt_battery_charge": 45.8094663
  },
  {
    "timestamp": "18/10/24 6:00",
    "demand": 33391.84778,
    "solar_generated": 10.24024337,
    "wind_generated": 57.65305056,
    "marketplace_to_demand": 94.80819976,
    "grid_to_demand": 31670.20753,
    "govt_battery_charge": 58.85382545
  },
  {
    "timestamp": "18/10/24 7:00",
    "demand": 33033.14412,
    "solar_generated": 11.23661071,
    "wind_generated": 64.24574197,
    "marketplace_to_demand": 192.1633435,
    "grid_to_demand": 33006.25569,
    "govt_battery_charge": 25.09502824
  },
  {
    "timestamp": "18/10/24 8:00",
    "demand": 30393.04068,
    "solar_generated": 7.559475689,
    "wind_generated": 59.93383292,
    "marketplace_to_demand": 137.0163343,
    "grid_to_demand": 30741.96034,
    "govt_battery_charge": 81.40493666
  },
  {
    "timestamp": "18/10/24 9:00",
    "demand": 36538.88409,
    "solar_generated": 11.91758399,
    "wind_generated": 56.22733858,
    "marketplace_to_demand": 34.00803603,
    "grid_to_demand": 30749.97681,
    "govt_battery_charge": 95.96637912
  },
  {
    "timestamp": "18/10/24 10:00",
    "demand": 32933.42393,
    "solar_generated": 21.82362454,
    "wind_generated": 79.96582059,
    "marketplace_to_demand": 170.7596452,
    "grid_to_demand": 31249.66229,
    "govt_battery_charge": 20.74988032
  },
  {
    "timestamp": "18/10/24 11:00",
    "demand": 30568.56884,
    "solar_generated": 19.79082688,
    "wind_generated": 75.15017894,
    "marketplace_to_demand": 55.07116132,
    "grid_to_demand": 36026.95109,
    "govt_battery_charge": 72.06553074
  },
  {
    "timestamp": "18/10/24 12:00",
    "demand": 34306.75147,
    "solar_generated": 20.59932,
    "wind_generated": 61.17105951,
    "marketplace_to_demand": 46.00544489,
    "grid_to_demand": 35038.85199,
    "govt_battery_charge": 16.04184758
  },
  {
    "timestamp": "18/10/24 13:00",
    "demand": 32518.02628,
    "solar_generated": 15.52126075,
    "wind_generated": 65.65331514,
    "marketplace_to_demand": 189.8750055,
    "grid_to_demand": 33836.40188,
    "govt_battery_charge": 87.43074708
  },
  {
    "timestamp": "18/10/24 14:00",
    "demand": 32078.07899,
    "solar_generated": 16.59875204,
    "wind_generated": 63.49388477,
    "marketplace_to_demand": 52.37352865,
    "grid_to_demand": 33308.97472,
    "govt_battery_charge": 9.019486442
  },
  {
    "timestamp": "18/10/24 15:00",
    "demand": 30149.65308,
    "solar_generated": 15.89345042,
    "wind_generated": 59.96673361,
    "marketplace_to_demand": 8.376944958,
    "grid_to_demand": 36269.76099,
    "govt_battery_charge": 61.82507879
  },
  {
    "timestamp": "18/10/24 16:00",
    "demand": 32940.11706,
    "solar_generated": 18.98021259,
    "wind_generated": 64.76619123,
    "marketplace_to_demand": 115.4226035,
    "grid_to_demand": 31424.81913,
    "govt_battery_charge": 60.27433346
  },
  {
    "timestamp": "18/10/24 17:00",
    "demand": 35926.83207,
    "solar_generated": 15.80882454,
    "wind_generated": 65.76616429,
    "marketplace_to_demand": 109.7488317,
    "grid_to_demand": 30108.85433,
    "govt_battery_charge": 56.3977253
  },
  {
    "timestamp": "18/10/24 18:00",
    "demand": 34641.03402,
    "solar_generated": 0,
    "wind_generated": 64.2927721,
    "marketplace_to_demand": 49.78179669,
    "grid_to_demand": 36523.35903,
    "govt_battery_charge": 22.38433672
  },
  {
    "timestamp": "18/10/24 19:00",
    "demand": 30549.63457,
    "solar_generated": 0,
    "wind_generated": 59.26672697,
    "marketplace_to_demand": 151.0396281,
    "grid_to_demand": 36535.85463,
    "govt_battery_charge": 40.66591572
  },
  {
    "timestamp": "18/10/24 20:00",
    "demand": 32116.20674,
    "solar_generated": 0,
    "wind_generated": 57.57338046,
    "marketplace_to_demand": 158.0503775,
    "grid_to_demand": 31303.89209,
    "govt_battery_charge": 78.14236316
  },
  {
    "timestamp": "18/10/24 21:00",
    "demand": 32018.48749,
    "solar_generated": 0,
    "wind_generated": 59.01955109,
    "marketplace_to_demand": 59.22834598,
    "grid_to_demand": 35071.75501,
    "govt_battery_charge": 11.73883558
  },
  {
    "timestamp": "18/10/24 22:00",
    "demand": 34432.40074,
    "solar_generated": 0,
    "wind_generated": 61.96172016,
    "marketplace_to_demand": 34.88466809,
    "grid_to_demand": 31670.49948,
    "govt_battery_charge": 72.13982076
  },
  {
    "timestamp": "18/10/24 23:00",
    "demand": 33532.81684,
    "solar_generated": 0,
    "wind_generated": 54.10935663,
    "marketplace_to_demand": 148.8456771,
    "grid_to_demand": 33934.097,
    "govt_battery_charge": 35.73033997
  },
  {
    "timestamp": "19/10/24 0:00",
    "demand": 30196.30132,
    "solar_generated": 0,
    "wind_generated": 71.28511807,
    "marketplace_to_demand": 58.0388551,
    "grid_to_demand": 32623.10732,
    "govt_battery_charge": 25.62164122
  },
  {
    "timestamp": "19/10/24 1:00",
    "demand": 31284.72422,
    "solar_generated": 0,
    "wind_generated": 54.24543897,
    "marketplace_to_demand": 163.3891701,
    "grid_to_demand": 34420.1963,
    "govt_battery_charge": 69.84176999
  },
  {
    "timestamp": "19/10/24 2:00",
    "demand": 30448.56745,
    "solar_generated": 0,
    "wind_generated": 73.57127353,
    "marketplace_to_demand": 109.3685425,
    "grid_to_demand": 31067.28198,
    "govt_battery_charge": 78.49646709
  },
  {
    "timestamp": "19/10/24 3:00",
    "demand": 33800.46926,
    "solar_generated": 0,
    "wind_generated": 70.00662289,
    "marketplace_to_demand": 184.1359647,
    "grid_to_demand": 34705.96997,
    "govt_battery_charge": 91.37109997
  },
  {
    "timestamp": "19/10/24 4:00",
    "demand": 30884.42168,
    "solar_generated": 0,
    "wind_generated": 77.51472661,
    "marketplace_to_demand": 67.78275581,
    "grid_to_demand": 35763.97273,
    "govt_battery_charge": 40.88912063
  },
  {
    "timestamp": "19/10/24 5:00",
    "demand": 30580.01957,
    "solar_generated": 0,
    "wind_generated": 72.95015597,
    "marketplace_to_demand": 193.7586497,
    "grid_to_demand": 33754.05998,
    "govt_battery_charge": 67.88616276
  },
  {
    "timestamp": "19/10/24 6:00",
    "demand": 32837.86255,
    "solar_generated": 12.05105897,
    "wind_generated": 58.67184984,
    "marketplace_to_demand": 66.96458691,
    "grid_to_demand": 32515.73629,
    "govt_battery_charge": 31.95308196
  },
  {
    "timestamp": "19/10/24 7:00",
    "demand": 35609.89614,
    "solar_generated": 21.05834693,
    "wind_generated": 77.63524518,
    "marketplace_to_demand": 19.36760857,
    "grid_to_demand": 34501.32929,
    "govt_battery_charge": 33.87386652
  },
  {
    "timestamp": "19/10/24 8:00",
    "demand": 30344.38999,
    "solar_generated": 12.04969255,
    "wind_generated": 56.43601346,
    "marketplace_to_demand": 129.5543943,
    "grid_to_demand": 34870.05569,
    "govt_battery_charge": 33.36858663
  },
  {
    "timestamp": "19/10/24 9:00",
    "demand": 31094.91643,
    "solar_generated": 14.54843739,
    "wind_generated": 78.43058903,
    "marketplace_to_demand": 164.7685604,
    "grid_to_demand": 31890.85284,
    "govt_battery_charge": 12.2748219
  },
  {
    "timestamp": "19/10/24 10:00",
    "demand": 34482.36425,
    "solar_generated": 17.64651976,
    "wind_generated": 65.56140807,
    "marketplace_to_demand": 77.75308341,
    "grid_to_demand": 34736.69645,
    "govt_battery_charge": 52.7679356
  },
  {
    "timestamp": "19/10/24 11:00",
    "demand": 32436.56301,
    "solar_generated": 15.88133985,
    "wind_generated": 65.01432467,
    "marketplace_to_demand": 13.08750561,
    "grid_to_demand": 32698.61936,
    "govt_battery_charge": 71.66941162
  },
  {
    "timestamp": "19/10/24 12:00",
    "demand": 33905.36712,
    "solar_generated": 21.17358326,
    "wind_generated": 62.73076775,
    "marketplace_to_demand": 40.8815982,
    "grid_to_demand": 31882.16068,
    "govt_battery_charge": 22.54225821
  },
  {
    "timestamp": "19/10/24 13:00",
    "demand": 32435.27629,
    "solar_generated": 16.46692544,
    "wind_generated": 63.0911673,
    "marketplace_to_demand": 15.46172254,
    "grid_to_demand": 33774.77996,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/10/24 14:00",
    "demand": 29981.29902,
    "solar_generated": 14.56990064,
    "wind_generated": 68.31934237,
    "marketplace_to_demand": 1.676614434,
    "grid_to_demand": 34672.63264,
    "govt_battery_charge": 46.45256776
  },
  {
    "timestamp": "19/10/24 15:00",
    "demand": 31163.97045,
    "solar_generated": 19.12898815,
    "wind_generated": 76.21551869,
    "marketplace_to_demand": 155.8302009,
    "grid_to_demand": 36408.64997,
    "govt_battery_charge": 38.82277608
  },
  {
    "timestamp": "19/10/24 16:00",
    "demand": 30108.96573,
    "solar_generated": 9.045073339,
    "wind_generated": 55.57552456,
    "marketplace_to_demand": 134.146677,
    "grid_to_demand": 32914.87198,
    "govt_battery_charge": 37.70518833
  },
  {
    "timestamp": "19/10/24 17:00",
    "demand": 32351.38818,
    "solar_generated": 10.70966283,
    "wind_generated": 67.51730483,
    "marketplace_to_demand": 149.0593661,
    "grid_to_demand": 35221.06674,
    "govt_battery_charge": 86.37866445
  },
  {
    "timestamp": "19/10/24 18:00",
    "demand": 36085.13664,
    "solar_generated": 0,
    "wind_generated": 78.59641171,
    "marketplace_to_demand": 199.353165,
    "grid_to_demand": 33530.214,
    "govt_battery_charge": 93.85398252
  },
  {
    "timestamp": "19/10/24 19:00",
    "demand": 31519.42508,
    "solar_generated": 0,
    "wind_generated": 56.30466057,
    "marketplace_to_demand": 17.26721116,
    "grid_to_demand": 36120.69634,
    "govt_battery_charge": 44.96804397
  },
  {
    "timestamp": "19/10/24 20:00",
    "demand": 30196.12814,
    "solar_generated": 0,
    "wind_generated": 56.54595386,
    "marketplace_to_demand": 6.838629637,
    "grid_to_demand": 30275.27182,
    "govt_battery_charge": 88.77924642
  },
  {
    "timestamp": "19/10/24 21:00",
    "demand": 35553.87659,
    "solar_generated": 0,
    "wind_generated": 74.74781157,
    "marketplace_to_demand": 11.07561111,
    "grid_to_demand": 35281.57469,
    "govt_battery_charge": 42.82962879
  },
  {
    "timestamp": "19/10/24 22:00",
    "demand": 31851.21403,
    "solar_generated": 0,
    "wind_generated": 69.26677147,
    "marketplace_to_demand": 155.7208032,
    "grid_to_demand": 31779.51003,
    "govt_battery_charge": 66.09012195
  },
  {
    "timestamp": "19/10/24 23:00",
    "demand": 32224.76793,
    "solar_generated": 0,
    "wind_generated": 67.01835283,
    "marketplace_to_demand": 72.46415933,
    "grid_to_demand": 34771.08702,
    "govt_battery_charge": 21.77046825
  },
  {
    "timestamp": "20/10/24 0:00",
    "demand": 32101.40681,
    "solar_generated": 0,
    "wind_generated": 77.50268904,
    "marketplace_to_demand": 8.584137405,
    "grid_to_demand": 35794.25649,
    "govt_battery_charge": 36.08057134
  },
  {
    "timestamp": "20/10/24 1:00",
    "demand": 34900.03384,
    "solar_generated": 0,
    "wind_generated": 78.72638573,
    "marketplace_to_demand": 188.1677524,
    "grid_to_demand": 34623.35963,
    "govt_battery_charge": 39.99766119
  },
  {
    "timestamp": "20/10/24 2:00",
    "demand": 34215.9011,
    "solar_generated": 0,
    "wind_generated": 74.15535965,
    "marketplace_to_demand": 93.23520693,
    "grid_to_demand": 32658.73479,
    "govt_battery_charge": 47.18518837
  },
  {
    "timestamp": "20/10/24 3:00",
    "demand": 35655.73688,
    "solar_generated": 0,
    "wind_generated": 68.794852,
    "marketplace_to_demand": 1.737763088,
    "grid_to_demand": 32148.85727,
    "govt_battery_charge": 23.4874485
  },
  {
    "timestamp": "20/10/24 4:00",
    "demand": 35738.8926,
    "solar_generated": 0,
    "wind_generated": 54.6182377,
    "marketplace_to_demand": 173.6923898,
    "grid_to_demand": 32298.18225,
    "govt_battery_charge": 33.36640298
  },
  {
    "timestamp": "20/10/24 5:00",
    "demand": 33573.58005,
    "solar_generated": 0,
    "wind_generated": 63.27287405,
    "marketplace_to_demand": 121.8878907,
    "grid_to_demand": 36376.87524,
    "govt_battery_charge": 37.08459063
  },
  {
    "timestamp": "20/10/24 6:00",
    "demand": 36425.16952,
    "solar_generated": 12.86436924,
    "wind_generated": 74.49445552,
    "marketplace_to_demand": 128.9132851,
    "grid_to_demand": 32627.10912,
    "govt_battery_charge": 51.28755925
  },
  {
    "timestamp": "20/10/24 7:00",
    "demand": 35805.63475,
    "solar_generated": 19.63813761,
    "wind_generated": 56.98673966,
    "marketplace_to_demand": 100.8252832,
    "grid_to_demand": 32780.68535,
    "govt_battery_charge": 9.063571853
  },
  {
    "timestamp": "20/10/24 8:00",
    "demand": 35680.42398,
    "solar_generated": 12.65672438,
    "wind_generated": 63.35152405,
    "marketplace_to_demand": 143.0249534,
    "grid_to_demand": 33609.46133,
    "govt_battery_charge": 8.998658844
  },
  {
    "timestamp": "20/10/24 9:00",
    "demand": 30920.42982,
    "solar_generated": 8.917421536,
    "wind_generated": 73.00552482,
    "marketplace_to_demand": 162.8479774,
    "grid_to_demand": 36362.52783,
    "govt_battery_charge": 30.01009819
  },
  {
    "timestamp": "20/10/24 10:00",
    "demand": 34896.09808,
    "solar_generated": 7.57315632,
    "wind_generated": 67.12990798,
    "marketplace_to_demand": 103.475142,
    "grid_to_demand": 31949.45117,
    "govt_battery_charge": 11.80283202
  },
  {
    "timestamp": "20/10/24 11:00",
    "demand": 34786.30336,
    "solar_generated": 12.66105441,
    "wind_generated": 77.97952954,
    "marketplace_to_demand": 197.1078601,
    "grid_to_demand": 32130.84936,
    "govt_battery_charge": 25.21393064
  },
  {
    "timestamp": "20/10/24 12:00",
    "demand": 30901.00923,
    "solar_generated": 21.80305629,
    "wind_generated": 63.865653,
    "marketplace_to_demand": 174.3186435,
    "grid_to_demand": 30242.70972,
    "govt_battery_charge": 47.84873984
  },
  {
    "timestamp": "20/10/24 13:00",
    "demand": 30597.47852,
    "solar_generated": 6.007831749,
    "wind_generated": 54.72728285,
    "marketplace_to_demand": 26.78949922,
    "grid_to_demand": 32594.99248,
    "govt_battery_charge": 9.177742352
  },
  {
    "timestamp": "20/10/24 14:00",
    "demand": 36012.8339,
    "solar_generated": 6.340807693,
    "wind_generated": 59.66969425,
    "marketplace_to_demand": 91.28657312,
    "grid_to_demand": 35983.5861,
    "govt_battery_charge": 10.88503523
  },
  {
    "timestamp": "20/10/24 15:00",
    "demand": 35637.6239,
    "solar_generated": 19.71521048,
    "wind_generated": 56.08344084,
    "marketplace_to_demand": 59.94546588,
    "grid_to_demand": 36472.10808,
    "govt_battery_charge": 53.71377154
  },
  {
    "timestamp": "20/10/24 16:00",
    "demand": 33103.56924,
    "solar_generated": 8.603007502,
    "wind_generated": 54.2940187,
    "marketplace_to_demand": 98.74652672,
    "grid_to_demand": 34698.02585,
    "govt_battery_charge": 80.62736369
  },
  {
    "timestamp": "20/10/24 17:00",
    "demand": 30193.76648,
    "solar_generated": 16.01635371,
    "wind_generated": 76.69393215,
    "marketplace_to_demand": 158.7837266,
    "grid_to_demand": 30995.65736,
    "govt_battery_charge": 91.77364166
  },
  {
    "timestamp": "20/10/24 18:00",
    "demand": 30630.87696,
    "solar_generated": 0,
    "wind_generated": 57.68464443,
    "marketplace_to_demand": 49.52352859,
    "grid_to_demand": 31758.73858,
    "govt_battery_charge": 34.36709227
  },
  {
    "timestamp": "20/10/24 19:00",
    "demand": 34187.16988,
    "solar_generated": 0,
    "wind_generated": 68.83697358,
    "marketplace_to_demand": 145.0617746,
    "grid_to_demand": 32218.41741,
    "govt_battery_charge": 68.26117567
  },
  {
    "timestamp": "20/10/24 20:00",
    "demand": 33497.90442,
    "solar_generated": 0,
    "wind_generated": 74.15445931,
    "marketplace_to_demand": 21.61077839,
    "grid_to_demand": 36007.58895,
    "govt_battery_charge": 88.11998365
  },
  {
    "timestamp": "20/10/24 21:00",
    "demand": 36383.01942,
    "solar_generated": 0,
    "wind_generated": 67.483114,
    "marketplace_to_demand": 70.45466587,
    "grid_to_demand": 34592.64476,
    "govt_battery_charge": 78.62166358
  },
  {
    "timestamp": "20/10/24 22:00",
    "demand": 32496.36417,
    "solar_generated": 0,
    "wind_generated": 55.19141037,
    "marketplace_to_demand": 42.13550245,
    "grid_to_demand": 32369.39747,
    "govt_battery_charge": 15.26637846
  },
  {
    "timestamp": "20/10/24 23:00",
    "demand": 31803.15047,
    "solar_generated": 0,
    "wind_generated": 66.49517726,
    "marketplace_to_demand": 182.6353974,
    "grid_to_demand": 32120.05461,
    "govt_battery_charge": 72.4584507
  },
  {
    "timestamp": "21/10/24 0:00",
    "demand": 35614.35287,
    "solar_generated": 0,
    "wind_generated": 56.20218594,
    "marketplace_to_demand": 15.74897196,
    "grid_to_demand": 32827.96397,
    "govt_battery_charge": 49.33916403
  },
  {
    "timestamp": "21/10/24 1:00",
    "demand": 31846.97043,
    "solar_generated": 0,
    "wind_generated": 60.71416862,
    "marketplace_to_demand": 35.34092259,
    "grid_to_demand": 34520.40447,
    "govt_battery_charge": 13.47882918
  },
  {
    "timestamp": "21/10/24 2:00",
    "demand": 31784.01359,
    "solar_generated": 0,
    "wind_generated": 69.00019751,
    "marketplace_to_demand": 116.6916664,
    "grid_to_demand": 33104.79244,
    "govt_battery_charge": 3.450847379
  },
  {
    "timestamp": "21/10/24 3:00",
    "demand": 32114.5422,
    "solar_generated": 0,
    "wind_generated": 73.28804183,
    "marketplace_to_demand": 194.4544398,
    "grid_to_demand": 33790.53498,
    "govt_battery_charge": 30.89601597
  },
  {
    "timestamp": "21/10/24 4:00",
    "demand": 36487.96763,
    "solar_generated": 0,
    "wind_generated": 79.02345573,
    "marketplace_to_demand": 110.0533039,
    "grid_to_demand": 34962.61097,
    "govt_battery_charge": 31.9625418
  },
  {
    "timestamp": "21/10/24 5:00",
    "demand": 31262.16855,
    "solar_generated": 0,
    "wind_generated": 58.89468937,
    "marketplace_to_demand": 187.2655994,
    "grid_to_demand": 33516.10652,
    "govt_battery_charge": 54.8956855
  },
  {
    "timestamp": "21/10/24 6:00",
    "demand": 34414.19851,
    "solar_generated": 10.54875207,
    "wind_generated": 60.85645032,
    "marketplace_to_demand": 139.9499182,
    "grid_to_demand": 35401.99111,
    "govt_battery_charge": 14.75660446
  },
  {
    "timestamp": "21/10/24 7:00",
    "demand": 36170.39005,
    "solar_generated": 12.98051379,
    "wind_generated": 57.66770802,
    "marketplace_to_demand": 58.30529889,
    "grid_to_demand": 32701.25657,
    "govt_battery_charge": 84.88453511
  },
  {
    "timestamp": "21/10/24 8:00",
    "demand": 33512.32265,
    "solar_generated": 21.37679531,
    "wind_generated": 71.9375963,
    "marketplace_to_demand": 90.85674832,
    "grid_to_demand": 36449.2523,
    "govt_battery_charge": 4.511866428
  },
  {
    "timestamp": "21/10/24 9:00",
    "demand": 30047.87074,
    "solar_generated": 22.13148218,
    "wind_generated": 53.64972492,
    "marketplace_to_demand": 141.6969516,
    "grid_to_demand": 31205.01897,
    "govt_battery_charge": 93.68289592
  },
  {
    "timestamp": "21/10/24 10:00",
    "demand": 35589.01318,
    "solar_generated": 10.06418572,
    "wind_generated": 59.07594419,
    "marketplace_to_demand": 57.4674464,
    "grid_to_demand": 30465.40982,
    "govt_battery_charge": 29.27900321
  },
  {
    "timestamp": "21/10/24 11:00",
    "demand": 31481.71472,
    "solar_generated": 16.45317106,
    "wind_generated": 67.69868771,
    "marketplace_to_demand": 31.66018417,
    "grid_to_demand": 35335.62023,
    "govt_battery_charge": 6.894980822
  },
  {
    "timestamp": "21/10/24 12:00",
    "demand": 30658.8571,
    "solar_generated": 9.280682167,
    "wind_generated": 72.25408589,
    "marketplace_to_demand": 115.5024415,
    "grid_to_demand": 34995.52958,
    "govt_battery_charge": 52.06808583
  },
  {
    "timestamp": "21/10/24 13:00",
    "demand": 29989.94634,
    "solar_generated": 23.38130802,
    "wind_generated": 60.59538113,
    "marketplace_to_demand": 160.0500624,
    "grid_to_demand": 32855.06004,
    "govt_battery_charge": 57.6445981
  },
  {
    "timestamp": "21/10/24 14:00",
    "demand": 31370.06932,
    "solar_generated": 11.55579562,
    "wind_generated": 58.97897634,
    "marketplace_to_demand": 181.7318533,
    "grid_to_demand": 31719.0789,
    "govt_battery_charge": 23.2908743
  },
  {
    "timestamp": "21/10/24 15:00",
    "demand": 32907.49,
    "solar_generated": 19.45950816,
    "wind_generated": 72.03727733,
    "marketplace_to_demand": 128.0608463,
    "grid_to_demand": 34028.38321,
    "govt_battery_charge": 47.22717237
  },
  {
    "timestamp": "21/10/24 16:00",
    "demand": 32704.21478,
    "solar_generated": 9.715023153,
    "wind_generated": 55.10889066,
    "marketplace_to_demand": 153.3642022,
    "grid_to_demand": 35341.71769,
    "govt_battery_charge": 88.8180635
  },
  {
    "timestamp": "21/10/24 17:00",
    "demand": 35533.71508,
    "solar_generated": 19.90804669,
    "wind_generated": 68.99254223,
    "marketplace_to_demand": 69.41924472,
    "grid_to_demand": 35447.98053,
    "govt_battery_charge": 67.52093725
  },
  {
    "timestamp": "21/10/24 18:00",
    "demand": 33543.55966,
    "solar_generated": 0,
    "wind_generated": 77.73927826,
    "marketplace_to_demand": 9.500067492,
    "grid_to_demand": 32298.67261,
    "govt_battery_charge": 92.78134853
  },
  {
    "timestamp": "21/10/24 19:00",
    "demand": 34493.97573,
    "solar_generated": 0,
    "wind_generated": 71.60624878,
    "marketplace_to_demand": 92.50533365,
    "grid_to_demand": 34209.05562,
    "govt_battery_charge": 91.49223831
  },
  {
    "timestamp": "21/10/24 20:00",
    "demand": 35814.49614,
    "solar_generated": 0,
    "wind_generated": 65.49716252,
    "marketplace_to_demand": 121.1237444,
    "grid_to_demand": 36247.74278,
    "govt_battery_charge": 90.7300856
  },
  {
    "timestamp": "21/10/24 21:00",
    "demand": 30100.92098,
    "solar_generated": 0,
    "wind_generated": 77.52391841,
    "marketplace_to_demand": 125.526107,
    "grid_to_demand": 31194.24729,
    "govt_battery_charge": 22.21977617
  },
  {
    "timestamp": "21/10/24 22:00",
    "demand": 31572.92552,
    "solar_generated": 0,
    "wind_generated": 74.12454838,
    "marketplace_to_demand": 23.5763307,
    "grid_to_demand": 32731.6862,
    "govt_battery_charge": 86.43950637
  },
  {
    "timestamp": "21/10/24 23:00",
    "demand": 34516.25857,
    "solar_generated": 0,
    "wind_generated": 77.18754134,
    "marketplace_to_demand": 41.0683118,
    "grid_to_demand": 33481.17589,
    "govt_battery_charge": 79.40516358
  },
  {
    "timestamp": "22/10/24 0:00",
    "demand": 33678.46686,
    "solar_generated": 0,
    "wind_generated": 58.2955214,
    "marketplace_to_demand": 21.64943461,
    "grid_to_demand": 34727.35833,
    "govt_battery_charge": 8.198886196
  },
  {
    "timestamp": "22/10/24 1:00",
    "demand": 32212.90595,
    "solar_generated": 0,
    "wind_generated": 64.89387844,
    "marketplace_to_demand": 17.57787466,
    "grid_to_demand": 32127.85813,
    "govt_battery_charge": 74.1902323
  },
  {
    "timestamp": "22/10/24 2:00",
    "demand": 31707.66809,
    "solar_generated": 0,
    "wind_generated": 57.00798851,
    "marketplace_to_demand": 4.351550163,
    "grid_to_demand": 33434.48645,
    "govt_battery_charge": 22.65539047
  },
  {
    "timestamp": "22/10/24 3:00",
    "demand": 34598.5314,
    "solar_generated": 0,
    "wind_generated": 71.83018279,
    "marketplace_to_demand": 74.35915863,
    "grid_to_demand": 34138.85333,
    "govt_battery_charge": 60.49998646
  },
  {
    "timestamp": "22/10/24 4:00",
    "demand": 33293.38174,
    "solar_generated": 0,
    "wind_generated": 69.48792216,
    "marketplace_to_demand": 160.4879453,
    "grid_to_demand": 30270.70543,
    "govt_battery_charge": 58.81006346
  },
  {
    "timestamp": "22/10/24 5:00",
    "demand": 34696.57242,
    "solar_generated": 0,
    "wind_generated": 77.73933477,
    "marketplace_to_demand": 21.38913143,
    "grid_to_demand": 30394.15251,
    "govt_battery_charge": 8.202534093
  },
  {
    "timestamp": "22/10/24 6:00",
    "demand": 32901.47817,
    "solar_generated": 6.917916509,
    "wind_generated": 74.97790439,
    "marketplace_to_demand": 126.9547087,
    "grid_to_demand": 34890.95557,
    "govt_battery_charge": 42.10829331
  },
  {
    "timestamp": "22/10/24 7:00",
    "demand": 30334.57404,
    "solar_generated": 13.85890211,
    "wind_generated": 65.26844886,
    "marketplace_to_demand": 194.5182833,
    "grid_to_demand": 31145.28783,
    "govt_battery_charge": 70.33053641
  },
  {
    "timestamp": "22/10/24 8:00",
    "demand": 35803.5304,
    "solar_generated": 20.18914041,
    "wind_generated": 78.19195875,
    "marketplace_to_demand": 190.7649694,
    "grid_to_demand": 35546.29036,
    "govt_battery_charge": 24.79562579
  },
  {
    "timestamp": "22/10/24 9:00",
    "demand": 35999.80654,
    "solar_generated": 22.81015828,
    "wind_generated": 71.70353889,
    "marketplace_to_demand": 167.5304827,
    "grid_to_demand": 32638.00363,
    "govt_battery_charge": 49.98743595
  },
  {
    "timestamp": "22/10/24 10:00",
    "demand": 36139.46586,
    "solar_generated": 8.375898659,
    "wind_generated": 68.91161267,
    "marketplace_to_demand": 169.9298927,
    "grid_to_demand": 33813.41566,
    "govt_battery_charge": 40.67655773
  },
  {
    "timestamp": "22/10/24 11:00",
    "demand": 33723.97156,
    "solar_generated": 20.68986856,
    "wind_generated": 63.9493063,
    "marketplace_to_demand": 133.9685862,
    "grid_to_demand": 32698.57746,
    "govt_battery_charge": 13.31910054
  },
  {
    "timestamp": "22/10/24 12:00",
    "demand": 36467.13219,
    "solar_generated": 7.102814182,
    "wind_generated": 63.65222029,
    "marketplace_to_demand": 43.55805482,
    "grid_to_demand": 31317.8055,
    "govt_battery_charge": 50.50082158
  },
  {
    "timestamp": "22/10/24 13:00",
    "demand": 33055.68025,
    "solar_generated": 13.22695312,
    "wind_generated": 72.85411086,
    "marketplace_to_demand": 94.63329078,
    "grid_to_demand": 31927.78765,
    "govt_battery_charge": 13.9021407
  },
  {
    "timestamp": "22/10/24 14:00",
    "demand": 33168.34191,
    "solar_generated": 16.12300709,
    "wind_generated": 67.7547351,
    "marketplace_to_demand": 163.4768074,
    "grid_to_demand": 30919.25865,
    "govt_battery_charge": 27.83851316
  },
  {
    "timestamp": "22/10/24 15:00",
    "demand": 32451.31703,
    "solar_generated": 16.69459209,
    "wind_generated": 68.61143766,
    "marketplace_to_demand": 165.8853884,
    "grid_to_demand": 33014.61011,
    "govt_battery_charge": 93.40649776
  },
  {
    "timestamp": "22/10/24 16:00",
    "demand": 32086.60074,
    "solar_generated": 18.78964782,
    "wind_generated": 78.56906514,
    "marketplace_to_demand": 90.18998487,
    "grid_to_demand": 30514.57641,
    "govt_battery_charge": 31.88901764
  },
  {
    "timestamp": "22/10/24 17:00",
    "demand": 34840.64921,
    "solar_generated": 15.24565011,
    "wind_generated": 61.40583859,
    "marketplace_to_demand": 190.3626301,
    "grid_to_demand": 31716.50444,
    "govt_battery_charge": 29.7251662
  },
  {
    "timestamp": "22/10/24 18:00",
    "demand": 36197.5429,
    "solar_generated": 0,
    "wind_generated": 65.45281882,
    "marketplace_to_demand": 166.2234811,
    "grid_to_demand": 36007.68918,
    "govt_battery_charge": 47.35097879
  },
  {
    "timestamp": "22/10/24 19:00",
    "demand": 31638.85679,
    "solar_generated": 0,
    "wind_generated": 73.61238238,
    "marketplace_to_demand": 193.2340749,
    "grid_to_demand": 30309.89466,
    "govt_battery_charge": 63.72454896
  },
  {
    "timestamp": "22/10/24 20:00",
    "demand": 35538.54113,
    "solar_generated": 0,
    "wind_generated": 69.22353984,
    "marketplace_to_demand": 162.3625289,
    "grid_to_demand": 31668.99574,
    "govt_battery_charge": 36.79377029
  },
  {
    "timestamp": "22/10/24 21:00",
    "demand": 36614.32068,
    "solar_generated": 0,
    "wind_generated": 70.12367687,
    "marketplace_to_demand": 144.9065138,
    "grid_to_demand": 33306.72426,
    "govt_battery_charge": 39.23238501
  },
  {
    "timestamp": "22/10/24 22:00",
    "demand": 36347.23613,
    "solar_generated": 0,
    "wind_generated": 59.81455454,
    "marketplace_to_demand": 75.51329494,
    "grid_to_demand": 31866.03003,
    "govt_battery_charge": 47.72141713
  },
  {
    "timestamp": "22/10/24 23:00",
    "demand": 31530.96948,
    "solar_generated": 0,
    "wind_generated": 63.4490312,
    "marketplace_to_demand": 156.492387,
    "grid_to_demand": 35050.45569,
    "govt_battery_charge": 67.07966262
  },
  {
    "timestamp": "23/10/24 0:00",
    "demand": 32057.34198,
    "solar_generated": 0,
    "wind_generated": 70.34398627,
    "marketplace_to_demand": 43.10395596,
    "grid_to_demand": 35776.3045,
    "govt_battery_charge": 59.323699
  },
  {
    "timestamp": "23/10/24 1:00",
    "demand": 30243.29103,
    "solar_generated": 0,
    "wind_generated": 75.80374856,
    "marketplace_to_demand": 37.17971564,
    "grid_to_demand": 34963.81295,
    "govt_battery_charge": 30.21628161
  },
  {
    "timestamp": "23/10/24 2:00",
    "demand": 30760.70177,
    "solar_generated": 0,
    "wind_generated": 79.42162363,
    "marketplace_to_demand": 78.62202988,
    "grid_to_demand": 33528.20552,
    "govt_battery_charge": 12.09785542
  },
  {
    "timestamp": "23/10/24 3:00",
    "demand": 34954.8006,
    "solar_generated": 0,
    "wind_generated": 61.67261391,
    "marketplace_to_demand": 35.77478952,
    "grid_to_demand": 33823.04746,
    "govt_battery_charge": 20.47464612
  },
  {
    "timestamp": "23/10/24 4:00",
    "demand": 33422.1852,
    "solar_generated": 0,
    "wind_generated": 61.70291466,
    "marketplace_to_demand": 153.844522,
    "grid_to_demand": 30037.09474,
    "govt_battery_charge": 14.38155781
  },
  {
    "timestamp": "23/10/24 5:00",
    "demand": 31832.37542,
    "solar_generated": 0,
    "wind_generated": 78.10438662,
    "marketplace_to_demand": 188.0456158,
    "grid_to_demand": 32836.77416,
    "govt_battery_charge": 91.53431811
  },
  {
    "timestamp": "23/10/24 6:00",
    "demand": 36078.72677,
    "solar_generated": 20.55319404,
    "wind_generated": 67.49339074,
    "marketplace_to_demand": 140.6937196,
    "grid_to_demand": 32333.64915,
    "govt_battery_charge": 28.63157827
  },
  {
    "timestamp": "23/10/24 7:00",
    "demand": 35118.99191,
    "solar_generated": 17.89024726,
    "wind_generated": 72.77928435,
    "marketplace_to_demand": 80.88106487,
    "grid_to_demand": 34540.21968,
    "govt_battery_charge": 82.53543733
  },
  {
    "timestamp": "23/10/24 8:00",
    "demand": 31593.23092,
    "solar_generated": 18.42398251,
    "wind_generated": 76.12753657,
    "marketplace_to_demand": 127.4486698,
    "grid_to_demand": 34233.99067,
    "govt_battery_charge": 85.9654985
  },
  {
    "timestamp": "23/10/24 9:00",
    "demand": 32223.43397,
    "solar_generated": 19.60097128,
    "wind_generated": 59.94560463,
    "marketplace_to_demand": 4.106958032,
    "grid_to_demand": 31047.17125,
    "govt_battery_charge": 2.858663615
  },
  {
    "timestamp": "23/10/24 10:00",
    "demand": 35416.1049,
    "solar_generated": 22.5421372,
    "wind_generated": 57.30408568,
    "marketplace_to_demand": 23.71082625,
    "grid_to_demand": 32345.87361,
    "govt_battery_charge": 46.21184949
  },
  {
    "timestamp": "23/10/24 11:00",
    "demand": 33800.00723,
    "solar_generated": 20.4128451,
    "wind_generated": 62.37194057,
    "marketplace_to_demand": 165.2189027,
    "grid_to_demand": 35704.94856,
    "govt_battery_charge": 29.45825105
  },
  {
    "timestamp": "23/10/24 12:00",
    "demand": 35604.90238,
    "solar_generated": 8.427088732,
    "wind_generated": 72.32037377,
    "marketplace_to_demand": 98.31918003,
    "grid_to_demand": 35108.90641,
    "govt_battery_charge": 91.17890237
  },
  {
    "timestamp": "23/10/24 13:00",
    "demand": 31819.12113,
    "solar_generated": 15.90886895,
    "wind_generated": 75.43601079,
    "marketplace_to_demand": 36.82496174,
    "grid_to_demand": 32017.2939,
    "govt_battery_charge": 9.266472739
  },
  {
    "timestamp": "23/10/24 14:00",
    "demand": 33547.9505,
    "solar_generated": 19.51646411,
    "wind_generated": 61.25424687,
    "marketplace_to_demand": 154.4979233,
    "grid_to_demand": 34849.05198,
    "govt_battery_charge": 90.56720307
  },
  {
    "timestamp": "23/10/24 15:00",
    "demand": 34379.06535,
    "solar_generated": 15.31100704,
    "wind_generated": 79.5384006,
    "marketplace_to_demand": 191.6565076,
    "grid_to_demand": 30821.95044,
    "govt_battery_charge": 65.54251054
  },
  {
    "timestamp": "23/10/24 16:00",
    "demand": 34265.59979,
    "solar_generated": 22.48239203,
    "wind_generated": 60.38452844,
    "marketplace_to_demand": 165.5882137,
    "grid_to_demand": 35950.7358,
    "govt_battery_charge": 4.851100128
  },
  {
    "timestamp": "23/10/24 17:00",
    "demand": 34778.60082,
    "solar_generated": 13.07045652,
    "wind_generated": 79.4000831,
    "marketplace_to_demand": 104.2881783,
    "grid_to_demand": 34424.15107,
    "govt_battery_charge": 45.5129165
  },
  {
    "timestamp": "23/10/24 18:00",
    "demand": 32169.98588,
    "solar_generated": 0,
    "wind_generated": 68.48875198,
    "marketplace_to_demand": 154.6406097,
    "grid_to_demand": 36043.49114,
    "govt_battery_charge": 64.33439982
  },
  {
    "timestamp": "23/10/24 19:00",
    "demand": 34507.56579,
    "solar_generated": 0,
    "wind_generated": 74.89869924,
    "marketplace_to_demand": 162.9175343,
    "grid_to_demand": 32479.93991,
    "govt_battery_charge": 30.2793137
  },
  {
    "timestamp": "23/10/24 20:00",
    "demand": 36472.30514,
    "solar_generated": 0,
    "wind_generated": 62.102687,
    "marketplace_to_demand": 74.24176715,
    "grid_to_demand": 34834.84562,
    "govt_battery_charge": 71.35992076
  },
  {
    "timestamp": "23/10/24 21:00",
    "demand": 36451.08963,
    "solar_generated": 0,
    "wind_generated": 65.90495225,
    "marketplace_to_demand": 167.9742981,
    "grid_to_demand": 31679.76407,
    "govt_battery_charge": 70.95820712
  },
  {
    "timestamp": "23/10/24 22:00",
    "demand": 32360.17204,
    "solar_generated": 0,
    "wind_generated": 68.22797581,
    "marketplace_to_demand": 88.35090859,
    "grid_to_demand": 30615.59117,
    "govt_battery_charge": 94.53132907
  },
  {
    "timestamp": "23/10/24 23:00",
    "demand": 31497.41924,
    "solar_generated": 0,
    "wind_generated": 66.08560041,
    "marketplace_to_demand": 186.708509,
    "grid_to_demand": 31432.29436,
    "govt_battery_charge": 74.62206105
  },
  {
    "timestamp": "24/10/24 0:00",
    "demand": 35899.33415,
    "solar_generated": 0,
    "wind_generated": 79.43356789,
    "marketplace_to_demand": 28.07520619,
    "grid_to_demand": 32468.28581,
    "govt_battery_charge": 73.16805721
  },
  {
    "timestamp": "24/10/24 1:00",
    "demand": 35002.14169,
    "solar_generated": 0,
    "wind_generated": 78.31581601,
    "marketplace_to_demand": 114.5349484,
    "grid_to_demand": 34686.72134,
    "govt_battery_charge": 99.25129797
  },
  {
    "timestamp": "24/10/24 2:00",
    "demand": 32131.7903,
    "solar_generated": 0,
    "wind_generated": 77.84651346,
    "marketplace_to_demand": 197.8685787,
    "grid_to_demand": 30994.15393,
    "govt_battery_charge": 93.10743713
  },
  {
    "timestamp": "24/10/24 3:00",
    "demand": 33042.58289,
    "solar_generated": 0,
    "wind_generated": 77.45129055,
    "marketplace_to_demand": 187.7890524,
    "grid_to_demand": 33145.09271,
    "govt_battery_charge": 99.46867799
  },
  {
    "timestamp": "24/10/24 4:00",
    "demand": 32177.70668,
    "solar_generated": 0,
    "wind_generated": 56.19047992,
    "marketplace_to_demand": 98.14519633,
    "grid_to_demand": 34960.25522,
    "govt_battery_charge": 62.08423949
  },
  {
    "timestamp": "24/10/24 5:00",
    "demand": 33804.90771,
    "solar_generated": 0,
    "wind_generated": 75.12477006,
    "marketplace_to_demand": 103.0922765,
    "grid_to_demand": 34549.96935,
    "govt_battery_charge": 14.36610565
  },
  {
    "timestamp": "24/10/24 6:00",
    "demand": 32441.8803,
    "solar_generated": 10.8150633,
    "wind_generated": 59.36438403,
    "marketplace_to_demand": 48.85196477,
    "grid_to_demand": 32856.34741,
    "govt_battery_charge": 2.709991506
  },
  {
    "timestamp": "24/10/24 7:00",
    "demand": 32052.98669,
    "solar_generated": 15.6793996,
    "wind_generated": 77.29659174,
    "marketplace_to_demand": 102.5255201,
    "grid_to_demand": 30625.54708,
    "govt_battery_charge": 23.4109168
  },
  {
    "timestamp": "24/10/24 8:00",
    "demand": 35465.85463,
    "solar_generated": 18.88821896,
    "wind_generated": 59.67903132,
    "marketplace_to_demand": 64.70316412,
    "grid_to_demand": 32969.99547,
    "govt_battery_charge": 76.03816358
  },
  {
    "timestamp": "24/10/24 9:00",
    "demand": 33651.01666,
    "solar_generated": 12.97901436,
    "wind_generated": 68.16555591,
    "marketplace_to_demand": 59.82474635,
    "grid_to_demand": 32216.23952,
    "govt_battery_charge": 2.915367652
  },
  {
    "timestamp": "24/10/24 10:00",
    "demand": 35433.86245,
    "solar_generated": 18.85982894,
    "wind_generated": 75.75816308,
    "marketplace_to_demand": 149.9949469,
    "grid_to_demand": 35576.34866,
    "govt_battery_charge": 23.40402563
  },
  {
    "timestamp": "24/10/24 11:00",
    "demand": 36470.51934,
    "solar_generated": 12.83297307,
    "wind_generated": 54.24661895,
    "marketplace_to_demand": 38.32684414,
    "grid_to_demand": 36081.20513,
    "govt_battery_charge": 95.13344461
  },
  {
    "timestamp": "24/10/24 12:00",
    "demand": 32328.69146,
    "solar_generated": 13.54332894,
    "wind_generated": 60.7323608,
    "marketplace_to_demand": 96.07262362,
    "grid_to_demand": 32764.15709,
    "govt_battery_charge": 39.98438557
  },
  {
    "timestamp": "24/10/24 13:00",
    "demand": 33301.47635,
    "solar_generated": 9.114671663,
    "wind_generated": 64.70747514,
    "marketplace_to_demand": 167.6225765,
    "grid_to_demand": 29979.58844,
    "govt_battery_charge": 15.44470122
  },
  {
    "timestamp": "24/10/24 14:00",
    "demand": 33120.88057,
    "solar_generated": 7.583311954,
    "wind_generated": 72.15887874,
    "marketplace_to_demand": 55.90481594,
    "grid_to_demand": 31104.82353,
    "govt_battery_charge": 28.25281421
  },
  {
    "timestamp": "24/10/24 15:00",
    "demand": 30006.50025,
    "solar_generated": 23.17088567,
    "wind_generated": 65.435055,
    "marketplace_to_demand": 11.89554519,
    "grid_to_demand": 32459.55764,
    "govt_battery_charge": 10.35662237
  },
  {
    "timestamp": "24/10/24 16:00",
    "demand": 30138.98711,
    "solar_generated": 17.14517281,
    "wind_generated": 65.50876911,
    "marketplace_to_demand": 129.4425014,
    "grid_to_demand": 36025.11329,
    "govt_battery_charge": 70.26564863
  },
  {
    "timestamp": "24/10/24 17:00",
    "demand": 31225.31097,
    "solar_generated": 10.68811583,
    "wind_generated": 60.57922514,
    "marketplace_to_demand": 110.4356155,
    "grid_to_demand": 33173.94634,
    "govt_battery_charge": 40.42928762
  },
  {
    "timestamp": "24/10/24 18:00",
    "demand": 34818.46465,
    "solar_generated": 0,
    "wind_generated": 65.37652332,
    "marketplace_to_demand": 96.03727849,
    "grid_to_demand": 30641.44886,
    "govt_battery_charge": 68.68778258
  },
  {
    "timestamp": "24/10/24 19:00",
    "demand": 36493.51936,
    "solar_generated": 0,
    "wind_generated": 73.10263885,
    "marketplace_to_demand": 6.688850438,
    "grid_to_demand": 34810.6563,
    "govt_battery_charge": 37.3917935
  },
  {
    "timestamp": "24/10/24 20:00",
    "demand": 33746.47984,
    "solar_generated": 0,
    "wind_generated": 64.10240222,
    "marketplace_to_demand": 101.5930947,
    "grid_to_demand": 34672.40563,
    "govt_battery_charge": 11.92026753
  },
  {
    "timestamp": "24/10/24 21:00",
    "demand": 35163.34804,
    "solar_generated": 0,
    "wind_generated": 55.53897265,
    "marketplace_to_demand": 172.7297758,
    "grid_to_demand": 36503.06751,
    "govt_battery_charge": 49.05264153
  },
  {
    "timestamp": "24/10/24 22:00",
    "demand": 35435.21115,
    "solar_generated": 0,
    "wind_generated": 63.51369517,
    "marketplace_to_demand": 123.1688426,
    "grid_to_demand": 34301.92791,
    "govt_battery_charge": 73.40170418
  },
  {
    "timestamp": "24/10/24 23:00",
    "demand": 34661.52914,
    "solar_generated": 0,
    "wind_generated": 54.05429224,
    "marketplace_to_demand": 43.77361761,
    "grid_to_demand": 35152.00263,
    "govt_battery_charge": 11.67963427
  },
  {
    "timestamp": "25/10/24 0:00",
    "demand": 35854.15212,
    "solar_generated": 0,
    "wind_generated": 76.25673675,
    "marketplace_to_demand": 136.2372758,
    "grid_to_demand": 34077.14763,
    "govt_battery_charge": 18.28222818
  },
  {
    "timestamp": "25/10/24 1:00",
    "demand": 34573.88598,
    "solar_generated": 0,
    "wind_generated": 72.73997299,
    "marketplace_to_demand": 179.4302547,
    "grid_to_demand": 30245.3614,
    "govt_battery_charge": 76.90459551
  },
  {
    "timestamp": "25/10/24 2:00",
    "demand": 34196.32439,
    "solar_generated": 0,
    "wind_generated": 65.29016615,
    "marketplace_to_demand": 127.0034964,
    "grid_to_demand": 36506.33664,
    "govt_battery_charge": 64.89767749
  },
  {
    "timestamp": "25/10/24 3:00",
    "demand": 31268.55998,
    "solar_generated": 0,
    "wind_generated": 58.60286063,
    "marketplace_to_demand": 45.62593414,
    "grid_to_demand": 33146.73214,
    "govt_battery_charge": 39.74079196
  },
  {
    "timestamp": "25/10/24 4:00",
    "demand": 30175.72452,
    "solar_generated": 0,
    "wind_generated": 76.14793952,
    "marketplace_to_demand": 80.13163445,
    "grid_to_demand": 30868.65704,
    "govt_battery_charge": 66.32570323
  },
  {
    "timestamp": "25/10/24 5:00",
    "demand": 36014.0927,
    "solar_generated": 0,
    "wind_generated": 59.32299861,
    "marketplace_to_demand": 170.3342192,
    "grid_to_demand": 32313.83075,
    "govt_battery_charge": 52.87044351
  },
  {
    "timestamp": "25/10/24 6:00",
    "demand": 30009.89463,
    "solar_generated": 13.08568088,
    "wind_generated": 65.4670396,
    "marketplace_to_demand": 94.69061984,
    "grid_to_demand": 34124.39843,
    "govt_battery_charge": 5.011889538
  },
  {
    "timestamp": "25/10/24 7:00",
    "demand": 34007.37155,
    "solar_generated": 21.63049637,
    "wind_generated": 61.95721063,
    "marketplace_to_demand": 29.12527233,
    "grid_to_demand": 35747.98836,
    "govt_battery_charge": 81.44968792
  },
  {
    "timestamp": "25/10/24 8:00",
    "demand": 31256.0053,
    "solar_generated": 13.905789,
    "wind_generated": 62.71756814,
    "marketplace_to_demand": 0.14126064,
    "grid_to_demand": 35770.445,
    "govt_battery_charge": 35.83612158
  },
  {
    "timestamp": "25/10/24 9:00",
    "demand": 35592.64072,
    "solar_generated": 19.89900517,
    "wind_generated": 60.59778259,
    "marketplace_to_demand": 89.93251484,
    "grid_to_demand": 34503.78474,
    "govt_battery_charge": 90.99474328
  },
  {
    "timestamp": "25/10/24 10:00",
    "demand": 36200.42001,
    "solar_generated": 19.19396044,
    "wind_generated": 66.43503282,
    "marketplace_to_demand": 4.291945988,
    "grid_to_demand": 31931.00955,
    "govt_battery_charge": 52.47196625
  },
  {
    "timestamp": "25/10/24 11:00",
    "demand": 35366.44951,
    "solar_generated": 14.57342137,
    "wind_generated": 76.68190049,
    "marketplace_to_demand": 92.76963804,
    "grid_to_demand": 33659.32232,
    "govt_battery_charge": 26.56490867
  },
  {
    "timestamp": "25/10/24 12:00",
    "demand": 30511.30459,
    "solar_generated": 9.441225421,
    "wind_generated": 61.73208754,
    "marketplace_to_demand": 76.70678494,
    "grid_to_demand": 35597.99646,
    "govt_battery_charge": 24.08581531
  },
  {
    "timestamp": "25/10/24 13:00",
    "demand": 30259.70574,
    "solar_generated": 18.13293164,
    "wind_generated": 64.31101864,
    "marketplace_to_demand": 90.36134427,
    "grid_to_demand": 33476.92492,
    "govt_battery_charge": 51.0257695
  },
  {
    "timestamp": "25/10/24 14:00",
    "demand": 36018.43879,
    "solar_generated": 8.302254994,
    "wind_generated": 66.71078099,
    "marketplace_to_demand": 106.4537132,
    "grid_to_demand": 35462.1434,
    "govt_battery_charge": 60.36644357
  },
  {
    "timestamp": "25/10/24 15:00",
    "demand": 31153.76258,
    "solar_generated": 22.27482196,
    "wind_generated": 58.55997773,
    "marketplace_to_demand": 109.2801603,
    "grid_to_demand": 34018.13998,
    "govt_battery_charge": 59.67752759
  },
  {
    "timestamp": "25/10/24 16:00",
    "demand": 30064.59205,
    "solar_generated": 17.03145691,
    "wind_generated": 59.05641662,
    "marketplace_to_demand": 182.7111449,
    "grid_to_demand": 30182.36143,
    "govt_battery_charge": 92.39404568
  },
  {
    "timestamp": "25/10/24 17:00",
    "demand": 34963.44011,
    "solar_generated": 19.10897057,
    "wind_generated": 69.37543583,
    "marketplace_to_demand": 3.581544737,
    "grid_to_demand": 33509.60952,
    "govt_battery_charge": 68.64808156
  },
  {
    "timestamp": "25/10/24 18:00",
    "demand": 30076.44922,
    "solar_generated": 0,
    "wind_generated": 64.59638944,
    "marketplace_to_demand": 73.49497232,
    "grid_to_demand": 32265.46161,
    "govt_battery_charge": 65.1447645
  },
  {
    "timestamp": "25/10/24 19:00",
    "demand": 36353.19209,
    "solar_generated": 0,
    "wind_generated": 59.36399015,
    "marketplace_to_demand": 199.5423751,
    "grid_to_demand": 30147.31244,
    "govt_battery_charge": 2.448197336
  },
  {
    "timestamp": "25/10/24 20:00",
    "demand": 33531.99319,
    "solar_generated": 0,
    "wind_generated": 63.20985899,
    "marketplace_to_demand": 195.3273189,
    "grid_to_demand": 32861.67432,
    "govt_battery_charge": 52.16107119
  },
  {
    "timestamp": "25/10/24 21:00",
    "demand": 33691.9058,
    "solar_generated": 0,
    "wind_generated": 62.15180468,
    "marketplace_to_demand": 4.954079873,
    "grid_to_demand": 31233.95243,
    "govt_battery_charge": 2.769617381
  },
  {
    "timestamp": "25/10/24 22:00",
    "demand": 30952.28723,
    "solar_generated": 0,
    "wind_generated": 78.77612595,
    "marketplace_to_demand": 97.00161716,
    "grid_to_demand": 34112.33545,
    "govt_battery_charge": 99.73572352
  },
  {
    "timestamp": "25/10/24 23:00",
    "demand": 33703.84369,
    "solar_generated": 0,
    "wind_generated": 54.27620727,
    "marketplace_to_demand": 150.6969976,
    "grid_to_demand": 34758.19221,
    "govt_battery_charge": 91.16325581
  },
  {
    "timestamp": "26/10/24 0:00",
    "demand": 35292.84816,
    "solar_generated": 0,
    "wind_generated": 53.60783066,
    "marketplace_to_demand": 70.47988295,
    "grid_to_demand": 35239.20986,
    "govt_battery_charge": 61.11131271
  },
  {
    "timestamp": "26/10/24 1:00",
    "demand": 31013.1559,
    "solar_generated": 0,
    "wind_generated": 76.11126774,
    "marketplace_to_demand": 15.12996035,
    "grid_to_demand": 34535.63297,
    "govt_battery_charge": 68.38129264
  },
  {
    "timestamp": "26/10/24 2:00",
    "demand": 34573.21638,
    "solar_generated": 0,
    "wind_generated": 64.86257849,
    "marketplace_to_demand": 31.30705025,
    "grid_to_demand": 36304.33558,
    "govt_battery_charge": 43.02683004
  },
  {
    "timestamp": "26/10/24 3:00",
    "demand": 36219.71163,
    "solar_generated": 0,
    "wind_generated": 76.00330661,
    "marketplace_to_demand": 150.0480929,
    "grid_to_demand": 31000.28658,
    "govt_battery_charge": 84.08192003
  },
  {
    "timestamp": "26/10/24 4:00",
    "demand": 31833.37764,
    "solar_generated": 0,
    "wind_generated": 75.98143096,
    "marketplace_to_demand": 44.36947801,
    "grid_to_demand": 31360.23804,
    "govt_battery_charge": 58.89063461
  },
  {
    "timestamp": "26/10/24 5:00",
    "demand": 33537.48595,
    "solar_generated": 0,
    "wind_generated": 61.96771881,
    "marketplace_to_demand": 159.5479638,
    "grid_to_demand": 30538.47159,
    "govt_battery_charge": 64.55735773
  },
  {
    "timestamp": "26/10/24 6:00",
    "demand": 32130.48361,
    "solar_generated": 14.75977633,
    "wind_generated": 66.22219394,
    "marketplace_to_demand": 98.75465489,
    "grid_to_demand": 30923.79899,
    "govt_battery_charge": 87.98604179
  },
  {
    "timestamp": "26/10/24 7:00",
    "demand": 30441.07006,
    "solar_generated": 9.902924873,
    "wind_generated": 76.41999299,
    "marketplace_to_demand": 132.4926786,
    "grid_to_demand": 35212.90942,
    "govt_battery_charge": 4.073222309
  },
  {
    "timestamp": "26/10/24 8:00",
    "demand": 33095.59393,
    "solar_generated": 8.141632005,
    "wind_generated": 59.70872752,
    "marketplace_to_demand": 97.58764815,
    "grid_to_demand": 33361.13263,
    "govt_battery_charge": 12.47560979
  },
  {
    "timestamp": "26/10/24 9:00",
    "demand": 34008.68708,
    "solar_generated": 8.917661021,
    "wind_generated": 66.94834303,
    "marketplace_to_demand": 8.474877543,
    "grid_to_demand": 33190.2802,
    "govt_battery_charge": 26.49830017
  },
  {
    "timestamp": "26/10/24 10:00",
    "demand": 33143.45657,
    "solar_generated": 15.23015062,
    "wind_generated": 57.46163094,
    "marketplace_to_demand": 81.55960726,
    "grid_to_demand": 29976.58729,
    "govt_battery_charge": 66.97980831
  },
  {
    "timestamp": "26/10/24 11:00",
    "demand": 33630.38141,
    "solar_generated": 20.16687815,
    "wind_generated": 68.89227468,
    "marketplace_to_demand": 147.3349904,
    "grid_to_demand": 35207.2279,
    "govt_battery_charge": 38.78387007
  },
  {
    "timestamp": "26/10/24 12:00",
    "demand": 34056.54356,
    "solar_generated": 20.60796885,
    "wind_generated": 77.03195366,
    "marketplace_to_demand": 188.8917233,
    "grid_to_demand": 30285.70984,
    "govt_battery_charge": 45.18265393
  },
  {
    "timestamp": "26/10/24 13:00",
    "demand": 33440.53222,
    "solar_generated": 20.2947024,
    "wind_generated": 76.80295489,
    "marketplace_to_demand": 97.89198678,
    "grid_to_demand": 32728.51749,
    "govt_battery_charge": 26.28564171
  },
  {
    "timestamp": "26/10/24 14:00",
    "demand": 35167.01833,
    "solar_generated": 21.84514385,
    "wind_generated": 75.21367928,
    "marketplace_to_demand": 196.7830533,
    "grid_to_demand": 34532.59913,
    "govt_battery_charge": 75.55874263
  },
  {
    "timestamp": "26/10/24 15:00",
    "demand": 36246.30482,
    "solar_generated": 16.68428999,
    "wind_generated": 75.63992331,
    "marketplace_to_demand": 169.3499367,
    "grid_to_demand": 30508.59177,
    "govt_battery_charge": 65.53916787
  },
  {
    "timestamp": "26/10/24 16:00",
    "demand": 30332.07061,
    "solar_generated": 22.39077987,
    "wind_generated": 74.34846322,
    "marketplace_to_demand": 60.94636593,
    "grid_to_demand": 33916.10127,
    "govt_battery_charge": 18.85706141
  },
  {
    "timestamp": "26/10/24 17:00",
    "demand": 30260.52014,
    "solar_generated": 16.65616108,
    "wind_generated": 56.36939988,
    "marketplace_to_demand": 3.99121361,
    "grid_to_demand": 29916.83075,
    "govt_battery_charge": 43.9532877
  },
  {
    "timestamp": "26/10/24 18:00",
    "demand": 36219.47282,
    "solar_generated": 0,
    "wind_generated": 55.05522063,
    "marketplace_to_demand": 156.3584022,
    "grid_to_demand": 30740.61743,
    "govt_battery_charge": 8.339042122
  },
  {
    "timestamp": "26/10/24 19:00",
    "demand": 33713.79295,
    "solar_generated": 0,
    "wind_generated": 54.58671304,
    "marketplace_to_demand": 56.92545958,
    "grid_to_demand": 32718.19695,
    "govt_battery_charge": 68.35857121
  },
  {
    "timestamp": "26/10/24 20:00",
    "demand": 36354.56269,
    "solar_generated": 0,
    "wind_generated": 76.62384213,
    "marketplace_to_demand": 11.83213313,
    "grid_to_demand": 31625.67113,
    "govt_battery_charge": 23.14859661
  },
  {
    "timestamp": "26/10/24 21:00",
    "demand": 35068.9092,
    "solar_generated": 0,
    "wind_generated": 68.62918022,
    "marketplace_to_demand": 174.5292121,
    "grid_to_demand": 31539.43083,
    "govt_battery_charge": 48.89681842
  },
  {
    "timestamp": "26/10/24 22:00",
    "demand": 36615.26759,
    "solar_generated": 0,
    "wind_generated": 65.10597185,
    "marketplace_to_demand": 4.99639412,
    "grid_to_demand": 32576.76951,
    "govt_battery_charge": 85.02039772
  },
  {
    "timestamp": "26/10/24 23:00",
    "demand": 32015.10968,
    "solar_generated": 0,
    "wind_generated": 70.43771082,
    "marketplace_to_demand": 153.4187763,
    "grid_to_demand": 32920.74595,
    "govt_battery_charge": 70.39957678
  },
  {
    "timestamp": "27/10/24 0:00",
    "demand": 33622.66802,
    "solar_generated": 0,
    "wind_generated": 74.89871564,
    "marketplace_to_demand": 172.1446489,
    "grid_to_demand": 30211.88521,
    "govt_battery_charge": 97.02715065
  },
  {
    "timestamp": "27/10/24 1:00",
    "demand": 32743.9447,
    "solar_generated": 0,
    "wind_generated": 63.51902772,
    "marketplace_to_demand": 99.52924667,
    "grid_to_demand": 33457.00832,
    "govt_battery_charge": 47.20643982
  },
  {
    "timestamp": "27/10/24 2:00",
    "demand": 35954.97999,
    "solar_generated": 0,
    "wind_generated": 77.16825227,
    "marketplace_to_demand": 90.47768995,
    "grid_to_demand": 30688.36505,
    "govt_battery_charge": 85.06720897
  },
  {
    "timestamp": "27/10/24 3:00",
    "demand": 30491.72982,
    "solar_generated": 0,
    "wind_generated": 56.32284185,
    "marketplace_to_demand": 183.0872365,
    "grid_to_demand": 32986.09893,
    "govt_battery_charge": 23.69530105
  },
  {
    "timestamp": "27/10/24 4:00",
    "demand": 31122.71122,
    "solar_generated": 0,
    "wind_generated": 69.99485902,
    "marketplace_to_demand": 6.69889588,
    "grid_to_demand": 31105.25953,
    "govt_battery_charge": 4.908269998
  },
  {
    "timestamp": "27/10/24 5:00",
    "demand": 35319.55413,
    "solar_generated": 0,
    "wind_generated": 60.39336562,
    "marketplace_to_demand": 18.05938451,
    "grid_to_demand": 35719.93634,
    "govt_battery_charge": 67.09591963
  },
  {
    "timestamp": "27/10/24 6:00",
    "demand": 31298.21431,
    "solar_generated": 14.00390082,
    "wind_generated": 71.09752296,
    "marketplace_to_demand": 83.01480099,
    "grid_to_demand": 31554.77734,
    "govt_battery_charge": 48.69298197
  },
  {
    "timestamp": "27/10/24 7:00",
    "demand": 33298.78282,
    "solar_generated": 8.53314493,
    "wind_generated": 66.67299382,
    "marketplace_to_demand": 134.7109738,
    "grid_to_demand": 35248.73564,
    "govt_battery_charge": 38.10975214
  },
  {
    "timestamp": "27/10/24 8:00",
    "demand": 31023.7866,
    "solar_generated": 11.73873655,
    "wind_generated": 59.22780329,
    "marketplace_to_demand": 122.5858036,
    "grid_to_demand": 35387.01948,
    "govt_battery_charge": 4.887957579
  },
  {
    "timestamp": "27/10/24 9:00",
    "demand": 35970.57325,
    "solar_generated": 10.71052787,
    "wind_generated": 67.60393639,
    "marketplace_to_demand": 191.9381737,
    "grid_to_demand": 32950.29715,
    "govt_battery_charge": 46.62378885
  },
  {
    "timestamp": "27/10/24 10:00",
    "demand": 36485.57603,
    "solar_generated": 14.85344887,
    "wind_generated": 57.62799437,
    "marketplace_to_demand": 142.3780814,
    "grid_to_demand": 31589.09727,
    "govt_battery_charge": 4.661480858
  },
  {
    "timestamp": "27/10/24 11:00",
    "demand": 35134.3956,
    "solar_generated": 14.66872507,
    "wind_generated": 67.72139571,
    "marketplace_to_demand": 174.274969,
    "grid_to_demand": 33900.58861,
    "govt_battery_charge": 53.78042036
  },
  {
    "timestamp": "27/10/24 12:00",
    "demand": 33482.21007,
    "solar_generated": 8.039080376,
    "wind_generated": 61.76973315,
    "marketplace_to_demand": 146.1944605,
    "grid_to_demand": 35958.70629,
    "govt_battery_charge": 65.79534363
  },
  {
    "timestamp": "27/10/24 13:00",
    "demand": 34853.4888,
    "solar_generated": 15.38303493,
    "wind_generated": 68.05407649,
    "marketplace_to_demand": 122.6906622,
    "grid_to_demand": 34457.31277,
    "govt_battery_charge": 5.841344493
  },
  {
    "timestamp": "27/10/24 14:00",
    "demand": 32477.17372,
    "solar_generated": 17.71843384,
    "wind_generated": 74.95417661,
    "marketplace_to_demand": 27.66227755,
    "grid_to_demand": 32747.56026,
    "govt_battery_charge": 94.87968349
  },
  {
    "timestamp": "27/10/24 15:00",
    "demand": 33722.6038,
    "solar_generated": 7.558347512,
    "wind_generated": 76.62219528,
    "marketplace_to_demand": 47.61395278,
    "grid_to_demand": 35890.20081,
    "govt_battery_charge": 93.33084934
  },
  {
    "timestamp": "27/10/24 16:00",
    "demand": 34552.63292,
    "solar_generated": 19.70516061,
    "wind_generated": 75.17012132,
    "marketplace_to_demand": 157.8727121,
    "grid_to_demand": 32428.99768,
    "govt_battery_charge": 3.127671651
  },
  {
    "timestamp": "27/10/24 17:00",
    "demand": 30593.95663,
    "solar_generated": 11.28494389,
    "wind_generated": 74.0975066,
    "marketplace_to_demand": 4.658355258,
    "grid_to_demand": 31641.49647,
    "govt_battery_charge": 26.14539507
  },
  {
    "timestamp": "27/10/24 18:00",
    "demand": 35707.15441,
    "solar_generated": 0,
    "wind_generated": 78.70059145,
    "marketplace_to_demand": 64.11956463,
    "grid_to_demand": 30614.92545,
    "govt_battery_charge": 64.06375602
  },
  {
    "timestamp": "27/10/24 19:00",
    "demand": 35701.51688,
    "solar_generated": 0,
    "wind_generated": 77.11969651,
    "marketplace_to_demand": 121.9747222,
    "grid_to_demand": 35376.73612,
    "govt_battery_charge": 34.01503202
  },
  {
    "timestamp": "27/10/24 20:00",
    "demand": 31423.00304,
    "solar_generated": 0,
    "wind_generated": 56.08625474,
    "marketplace_to_demand": 28.87682945,
    "grid_to_demand": 31774.67899,
    "govt_battery_charge": 74.86987221
  },
  {
    "timestamp": "27/10/24 21:00",
    "demand": 31757.28565,
    "solar_generated": 0,
    "wind_generated": 53.73848775,
    "marketplace_to_demand": 182.476687,
    "grid_to_demand": 31232.73346,
    "govt_battery_charge": 76.09973602
  },
  {
    "timestamp": "27/10/24 22:00",
    "demand": 31020.64053,
    "solar_generated": 0,
    "wind_generated": 56.99442258,
    "marketplace_to_demand": 160.5993715,
    "grid_to_demand": 35896.98231,
    "govt_battery_charge": 37.06175384
  },
  {
    "timestamp": "27/10/24 23:00",
    "demand": 33022.61885,
    "solar_generated": 0,
    "wind_generated": 59.00047651,
    "marketplace_to_demand": 177.5225667,
    "grid_to_demand": 35231.1187,
    "govt_battery_charge": 90.86933163
  },
  {
    "timestamp": "28/10/24 0:00",
    "demand": 35762.55118,
    "solar_generated": 0,
    "wind_generated": 79.66682763,
    "marketplace_to_demand": 44.52711833,
    "grid_to_demand": 35798.33859,
    "govt_battery_charge": 1.758555291
  },
  {
    "timestamp": "28/10/24 1:00",
    "demand": 33593.40497,
    "solar_generated": 0,
    "wind_generated": 55.79911166,
    "marketplace_to_demand": 148.0329792,
    "grid_to_demand": 32074.43426,
    "govt_battery_charge": 10.12216779
  },
  {
    "timestamp": "28/10/24 2:00",
    "demand": 34118.51244,
    "solar_generated": 0,
    "wind_generated": 55.67249854,
    "marketplace_to_demand": 16.54566582,
    "grid_to_demand": 33712.1222,
    "govt_battery_charge": 81.87633214
  },
  {
    "timestamp": "28/10/24 3:00",
    "demand": 35372.7157,
    "solar_generated": 0,
    "wind_generated": 64.62882962,
    "marketplace_to_demand": 102.0080889,
    "grid_to_demand": 33346.43383,
    "govt_battery_charge": 79.67286037
  },
  {
    "timestamp": "28/10/24 4:00",
    "demand": 34282.56602,
    "solar_generated": 0,
    "wind_generated": 66.12634438,
    "marketplace_to_demand": 85.48093559,
    "grid_to_demand": 31544.72231,
    "govt_battery_charge": 60.22725434
  },
  {
    "timestamp": "28/10/24 5:00",
    "demand": 31739.35553,
    "solar_generated": 0,
    "wind_generated": 58.16025596,
    "marketplace_to_demand": 134.4000813,
    "grid_to_demand": 35717.60662,
    "govt_battery_charge": 26.79591456
  },
  {
    "timestamp": "28/10/24 6:00",
    "demand": 31524.20661,
    "solar_generated": 7.743183,
    "wind_generated": 70.76581415,
    "marketplace_to_demand": 169.6096077,
    "grid_to_demand": 31181.87885,
    "govt_battery_charge": 72.34406907
  },
  {
    "timestamp": "28/10/24 7:00",
    "demand": 32234.05902,
    "solar_generated": 21.8134327,
    "wind_generated": 68.36310953,
    "marketplace_to_demand": 25.46994916,
    "grid_to_demand": 33527.38517,
    "govt_battery_charge": 88.92185101
  },
  {
    "timestamp": "28/10/24 8:00",
    "demand": 34856.45079,
    "solar_generated": 20.98474392,
    "wind_generated": 59.66296527,
    "marketplace_to_demand": 191.4387343,
    "grid_to_demand": 33952.22908,
    "govt_battery_charge": 54.23312079
  },
  {
    "timestamp": "28/10/24 9:00",
    "demand": 30342.20216,
    "solar_generated": 16.56317736,
    "wind_generated": 70.24310936,
    "marketplace_to_demand": 49.40278514,
    "grid_to_demand": 34674.90526,
    "govt_battery_charge": 45.63740404
  },
  {
    "timestamp": "28/10/24 10:00",
    "demand": 36032.23888,
    "solar_generated": 8.599270236,
    "wind_generated": 55.4858267,
    "marketplace_to_demand": 186.7945942,
    "grid_to_demand": 30655.27638,
    "govt_battery_charge": 78.82871804
  },
  {
    "timestamp": "28/10/24 11:00",
    "demand": 35507.6408,
    "solar_generated": 20.90967025,
    "wind_generated": 68.95709599,
    "marketplace_to_demand": 144.6145262,
    "grid_to_demand": 30083.42889,
    "govt_battery_charge": 2.606535459
  },
  {
    "timestamp": "28/10/24 12:00",
    "demand": 32522.99445,
    "solar_generated": 18.31358842,
    "wind_generated": 66.59426006,
    "marketplace_to_demand": 196.7830662,
    "grid_to_demand": 33844.54408,
    "govt_battery_charge": 74.08358912
  },
  {
    "timestamp": "28/10/24 13:00",
    "demand": 33426.69105,
    "solar_generated": 12.90721024,
    "wind_generated": 71.5877393,
    "marketplace_to_demand": 73.18677107,
    "grid_to_demand": 34264.44609,
    "govt_battery_charge": 70.68151783
  },
  {
    "timestamp": "28/10/24 14:00",
    "demand": 35652.17155,
    "solar_generated": 21.08299679,
    "wind_generated": 59.76217359,
    "marketplace_to_demand": 192.1131654,
    "grid_to_demand": 35464.03404,
    "govt_battery_charge": 33.41521637
  },
  {
    "timestamp": "28/10/24 15:00",
    "demand": 31642.94701,
    "solar_generated": 20.91772065,
    "wind_generated": 70.72876221,
    "marketplace_to_demand": 9.425761821,
    "grid_to_demand": 30022.9431,
    "govt_battery_charge": 58.13226013
  },
  {
    "timestamp": "28/10/24 16:00",
    "demand": 34767.72663,
    "solar_generated": 12.18441801,
    "wind_generated": 53.78531866,
    "marketplace_to_demand": 164.1989404,
    "grid_to_demand": 31882.98136,
    "govt_battery_charge": 50.32693261
  },
  {
    "timestamp": "28/10/24 17:00",
    "demand": 35987.06244,
    "solar_generated": 17.41312321,
    "wind_generated": 66.69880782,
    "marketplace_to_demand": 26.61838585,
    "grid_to_demand": 32393.03842,
    "govt_battery_charge": 26.93275352
  },
  {
    "timestamp": "28/10/24 18:00",
    "demand": 32434.57967,
    "solar_generated": 0,
    "wind_generated": 70.99833101,
    "marketplace_to_demand": 34.88628305,
    "grid_to_demand": 34620.15054,
    "govt_battery_charge": 8.724783778
  },
  {
    "timestamp": "28/10/24 19:00",
    "demand": 30099.82573,
    "solar_generated": 0,
    "wind_generated": 74.94912006,
    "marketplace_to_demand": 21.23630845,
    "grid_to_demand": 32980.05,
    "govt_battery_charge": 20.1123954
  },
  {
    "timestamp": "28/10/24 20:00",
    "demand": 30365.534,
    "solar_generated": 0,
    "wind_generated": 54.35227336,
    "marketplace_to_demand": 196.9672439,
    "grid_to_demand": 33354.28016,
    "govt_battery_charge": 21.86322509
  },
  {
    "timestamp": "28/10/24 21:00",
    "demand": 35906.78578,
    "solar_generated": 0,
    "wind_generated": 79.31489927,
    "marketplace_to_demand": 116.1253035,
    "grid_to_demand": 34840.87087,
    "govt_battery_charge": 41.18215401
  },
  {
    "timestamp": "28/10/24 22:00",
    "demand": 31421.04796,
    "solar_generated": 0,
    "wind_generated": 67.09129678,
    "marketplace_to_demand": 152.7102295,
    "grid_to_demand": 30672.0479,
    "govt_battery_charge": 23.86618079
  },
  {
    "timestamp": "28/10/24 23:00",
    "demand": 31796.01384,
    "solar_generated": 0,
    "wind_generated": 72.79558739,
    "marketplace_to_demand": 105.4315543,
    "grid_to_demand": 32624.48355,
    "govt_battery_charge": 54.43392538
  },
  {
    "timestamp": "29/10/24 0:00",
    "demand": 34500.12533,
    "solar_generated": 0,
    "wind_generated": 73.7802819,
    "marketplace_to_demand": 89.11847178,
    "grid_to_demand": 32675.88923,
    "govt_battery_charge": 61.4721989
  },
  {
    "timestamp": "29/10/24 1:00",
    "demand": 33105.22142,
    "solar_generated": 0,
    "wind_generated": 72.83633034,
    "marketplace_to_demand": 70.34981224,
    "grid_to_demand": 31796.85681,
    "govt_battery_charge": 26.4075173
  },
  {
    "timestamp": "29/10/24 2:00",
    "demand": 31765.47463,
    "solar_generated": 0,
    "wind_generated": 54.59065821,
    "marketplace_to_demand": 96.44908986,
    "grid_to_demand": 32470.51797,
    "govt_battery_charge": 38.26977379
  },
  {
    "timestamp": "29/10/24 3:00",
    "demand": 31563.28303,
    "solar_generated": 0,
    "wind_generated": 66.08871998,
    "marketplace_to_demand": 134.4768255,
    "grid_to_demand": 31845.67694,
    "govt_battery_charge": 34.66229136
  },
  {
    "timestamp": "29/10/24 4:00",
    "demand": 34236.06038,
    "solar_generated": 0,
    "wind_generated": 61.80757897,
    "marketplace_to_demand": 10.75922344,
    "grid_to_demand": 31305.13504,
    "govt_battery_charge": 4.185236861
  },
  {
    "timestamp": "29/10/24 5:00",
    "demand": 32033.32526,
    "solar_generated": 0,
    "wind_generated": 72.09973912,
    "marketplace_to_demand": 104.6684,
    "grid_to_demand": 33243.7898,
    "govt_battery_charge": 21.92267301
  },
  {
    "timestamp": "29/10/24 6:00",
    "demand": 36350.66044,
    "solar_generated": 19.50446847,
    "wind_generated": 57.59363484,
    "marketplace_to_demand": 119.1249891,
    "grid_to_demand": 36164.2676,
    "govt_battery_charge": 91.1227279
  },
  {
    "timestamp": "29/10/24 7:00",
    "demand": 33444.15288,
    "solar_generated": 18.76004014,
    "wind_generated": 61.59110389,
    "marketplace_to_demand": 77.14163827,
    "grid_to_demand": 34378.17742,
    "govt_battery_charge": 24.64421617
  },
  {
    "timestamp": "29/10/24 8:00",
    "demand": 34212.02435,
    "solar_generated": 7.871768003,
    "wind_generated": 73.74928019,
    "marketplace_to_demand": 154.7700818,
    "grid_to_demand": 33098.99105,
    "govt_battery_charge": 6.578902461
  },
  {
    "timestamp": "29/10/24 9:00",
    "demand": 36398.90698,
    "solar_generated": 10.04094973,
    "wind_generated": 67.52888409,
    "marketplace_to_demand": 131.521686,
    "grid_to_demand": 36156.55693,
    "govt_battery_charge": 4.393718164
  },
  {
    "timestamp": "29/10/24 10:00",
    "demand": 32525.73545,
    "solar_generated": 20.44179287,
    "wind_generated": 65.18349731,
    "marketplace_to_demand": 107.7167637,
    "grid_to_demand": 32226.51611,
    "govt_battery_charge": 78.04477918
  },
  {
    "timestamp": "29/10/24 11:00",
    "demand": 35961.78563,
    "solar_generated": 8.604701045,
    "wind_generated": 65.41187927,
    "marketplace_to_demand": 154.7460341,
    "grid_to_demand": 33705.29144,
    "govt_battery_charge": 98.65118765
  },
  {
    "timestamp": "29/10/24 12:00",
    "demand": 31177.05025,
    "solar_generated": 19.72067593,
    "wind_generated": 55.47266759,
    "marketplace_to_demand": 197.144172,
    "grid_to_demand": 34145.18488,
    "govt_battery_charge": 75.18145362
  },
  {
    "timestamp": "29/10/24 13:00",
    "demand": 34890.61401,
    "solar_generated": 7.688751563,
    "wind_generated": 59.94774507,
    "marketplace_to_demand": 74.07207458,
    "grid_to_demand": 32220.41771,
    "govt_battery_charge": 61.84666289
  },
  {
    "timestamp": "29/10/24 14:00",
    "demand": 34310.35309,
    "solar_generated": 14.26878591,
    "wind_generated": 61.24685364,
    "marketplace_to_demand": 199.6550516,
    "grid_to_demand": 35664.1734,
    "govt_battery_charge": 51.29767665
  },
  {
    "timestamp": "29/10/24 15:00",
    "demand": 35751.01252,
    "solar_generated": 9.780145238,
    "wind_generated": 71.93913425,
    "marketplace_to_demand": 77.94974327,
    "grid_to_demand": 33201.87477,
    "govt_battery_charge": 39.00955525
  },
  {
    "timestamp": "29/10/24 16:00",
    "demand": 32757.12184,
    "solar_generated": 10.11320159,
    "wind_generated": 54.25917386,
    "marketplace_to_demand": 119.0593524,
    "grid_to_demand": 35228.69869,
    "govt_battery_charge": 80.83815044
  },
  {
    "timestamp": "29/10/24 17:00",
    "demand": 33317.85425,
    "solar_generated": 11.87927462,
    "wind_generated": 57.93919587,
    "marketplace_to_demand": 87.49499913,
    "grid_to_demand": 33169.70689,
    "govt_battery_charge": 57.3950157
  },
  {
    "timestamp": "29/10/24 18:00",
    "demand": 36107.72391,
    "solar_generated": 0,
    "wind_generated": 79.46187589,
    "marketplace_to_demand": 184.2946624,
    "grid_to_demand": 36183.14798,
    "govt_battery_charge": 80.88186695
  },
  {
    "timestamp": "29/10/24 19:00",
    "demand": 32189.68907,
    "solar_generated": 0,
    "wind_generated": 70.14615645,
    "marketplace_to_demand": 189.0264565,
    "grid_to_demand": 32407.06312,
    "govt_battery_charge": 8.184519183
  },
  {
    "timestamp": "29/10/24 20:00",
    "demand": 30622.60587,
    "solar_generated": 0,
    "wind_generated": 70.69560886,
    "marketplace_to_demand": 42.95564314,
    "grid_to_demand": 33684.00847,
    "govt_battery_charge": 18.22164507
  },
  {
    "timestamp": "29/10/24 21:00",
    "demand": 31544.9151,
    "solar_generated": 0,
    "wind_generated": 64.25872417,
    "marketplace_to_demand": 133.1979436,
    "grid_to_demand": 31517.54081,
    "govt_battery_charge": 31.18493877
  },
  {
    "timestamp": "29/10/24 22:00",
    "demand": 32893.34143,
    "solar_generated": 0,
    "wind_generated": 58.65116438,
    "marketplace_to_demand": 132.9806868,
    "grid_to_demand": 30103.06512,
    "govt_battery_charge": 65.75230988
  },
  {
    "timestamp": "29/10/24 23:00",
    "demand": 34456.64195,
    "solar_generated": 0,
    "wind_generated": 67.3721485,
    "marketplace_to_demand": 105.1809558,
    "grid_to_demand": 33618.71751,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "30/10/24 0:00",
    "demand": 31470.35111,
    "solar_generated": 0,
    "wind_generated": 65.66258414,
    "marketplace_to_demand": 21.93006187,
    "grid_to_demand": 34099.3899,
    "govt_battery_charge": 96.28893
  },
  {
    "timestamp": "30/10/24 1:00",
    "demand": 31144.04672,
    "solar_generated": 0,
    "wind_generated": 62.7957054,
    "marketplace_to_demand": 156.6981115,
    "grid_to_demand": 34986.01065,
    "govt_battery_charge": 31.20078468
  },
  {
    "timestamp": "30/10/24 2:00",
    "demand": 33986.30671,
    "solar_generated": 0,
    "wind_generated": 56.80259251,
    "marketplace_to_demand": 18.44590851,
    "grid_to_demand": 33688.88749,
    "govt_battery_charge": 13.46662787
  },
  {
    "timestamp": "30/10/24 3:00",
    "demand": 35732.78468,
    "solar_generated": 0,
    "wind_generated": 73.49877618,
    "marketplace_to_demand": 114.4368123,
    "grid_to_demand": 32355.06791,
    "govt_battery_charge": 95.98227484
  },
  {
    "timestamp": "30/10/24 4:00",
    "demand": 31240.05214,
    "solar_generated": 0,
    "wind_generated": 59.05272898,
    "marketplace_to_demand": 155.092368,
    "grid_to_demand": 34870.34892,
    "govt_battery_charge": 2.100033407
  },
  {
    "timestamp": "30/10/24 5:00",
    "demand": 33828.46212,
    "solar_generated": 0,
    "wind_generated": 55.06565151,
    "marketplace_to_demand": 178.9034601,
    "grid_to_demand": 32311.78486,
    "govt_battery_charge": 91.68973388
  },
  {
    "timestamp": "30/10/24 6:00",
    "demand": 32511.7774,
    "solar_generated": 10.59611033,
    "wind_generated": 55.5709113,
    "marketplace_to_demand": 168.1545338,
    "grid_to_demand": 33925.23754,
    "govt_battery_charge": 16.81281021
  },
  {
    "timestamp": "30/10/24 7:00",
    "demand": 33351.67871,
    "solar_generated": 16.93353093,
    "wind_generated": 69.65953215,
    "marketplace_to_demand": 74.26198086,
    "grid_to_demand": 31114.45381,
    "govt_battery_charge": 64.69019623
  },
  {
    "timestamp": "30/10/24 8:00",
    "demand": 30971.85674,
    "solar_generated": 23.63768722,
    "wind_generated": 58.61608061,
    "marketplace_to_demand": 28.83056335,
    "grid_to_demand": 32578.35022,
    "govt_battery_charge": 45.11978891
  },
  {
    "timestamp": "30/10/24 9:00",
    "demand": 35826.28202,
    "solar_generated": 21.3882235,
    "wind_generated": 72.70325623,
    "marketplace_to_demand": 60.58642384,
    "grid_to_demand": 35991.69518,
    "govt_battery_charge": 13.54119917
  },
  {
    "timestamp": "30/10/24 10:00",
    "demand": 33429.36259,
    "solar_generated": 19.04187851,
    "wind_generated": 76.00292996,
    "marketplace_to_demand": 50.87626013,
    "grid_to_demand": 35626.83735,
    "govt_battery_charge": 23.59831534
  },
  {
    "timestamp": "30/10/24 11:00",
    "demand": 32449.31727,
    "solar_generated": 22.7428697,
    "wind_generated": 54.49803549,
    "marketplace_to_demand": 179.7851026,
    "grid_to_demand": 33790.73201,
    "govt_battery_charge": 69.43033984
  },
  {
    "timestamp": "30/10/24 12:00",
    "demand": 30957.76232,
    "solar_generated": 15.86861326,
    "wind_generated": 65.42342295,
    "marketplace_to_demand": 155.6395453,
    "grid_to_demand": 33732.93332,
    "govt_battery_charge": 81.94218816
  },
  {
    "timestamp": "30/10/24 13:00",
    "demand": 35159.01818,
    "solar_generated": 11.82715598,
    "wind_generated": 76.93495964,
    "marketplace_to_demand": 160.919295,
    "grid_to_demand": 35868.99085,
    "govt_battery_charge": 88.60401903
  },
  {
    "timestamp": "30/10/24 14:00",
    "demand": 36277.9136,
    "solar_generated": 15.27930941,
    "wind_generated": 72.04391346,
    "marketplace_to_demand": 76.29555121,
    "grid_to_demand": 33314.28281,
    "govt_battery_charge": 7.302108211
  },
  {
    "timestamp": "30/10/24 15:00",
    "demand": 30450.80864,
    "solar_generated": 6.220610868,
    "wind_generated": 65.35797544,
    "marketplace_to_demand": 117.2402947,
    "grid_to_demand": 33228.6732,
    "govt_battery_charge": 49.82355923
  },
  {
    "timestamp": "30/10/24 16:00",
    "demand": 31128.22878,
    "solar_generated": 7.595897564,
    "wind_generated": 61.9570751,
    "marketplace_to_demand": 47.69655219,
    "grid_to_demand": 35842.21038,
    "govt_battery_charge": 83.86469745
  },
  {
    "timestamp": "30/10/24 17:00",
    "demand": 32564.09944,
    "solar_generated": 6.155162256,
    "wind_generated": 68.9255636,
    "marketplace_to_demand": 186.506327,
    "grid_to_demand": 34601.21561,
    "govt_battery_charge": 64.66631184
  },
  {
    "timestamp": "30/10/24 18:00",
    "demand": 34645.09759,
    "solar_generated": 0,
    "wind_generated": 74.44192982,
    "marketplace_to_demand": 152.70993,
    "grid_to_demand": 31684.72704,
    "govt_battery_charge": 74.12541996
  },
  {
    "timestamp": "30/10/24 19:00",
    "demand": 36302.29211,
    "solar_generated": 0,
    "wind_generated": 73.39398639,
    "marketplace_to_demand": 55.8529304,
    "grid_to_demand": 31019.46371,
    "govt_battery_charge": 49.75034891
  },
  {
    "timestamp": "30/10/24 20:00",
    "demand": 31097.11205,
    "solar_generated": 0,
    "wind_generated": 74.51697809,
    "marketplace_to_demand": 137.4386092,
    "grid_to_demand": 35399.98165,
    "govt_battery_charge": 98.66711597
  },
  {
    "timestamp": "30/10/24 21:00",
    "demand": 33773.35971,
    "solar_generated": 0,
    "wind_generated": 75.09775509,
    "marketplace_to_demand": 109.5569434,
    "grid_to_demand": 31487.60072,
    "govt_battery_charge": 55.06032874
  },
  {
    "timestamp": "30/10/24 22:00",
    "demand": 30697.37908,
    "solar_generated": 0,
    "wind_generated": 53.8809489,
    "marketplace_to_demand": 105.8546121,
    "grid_to_demand": 35242.01322,
    "govt_battery_charge": 30.0919298
  },
  {
    "timestamp": "30/10/24 23:00",
    "demand": 33044.93835,
    "solar_generated": 0,
    "wind_generated": 73.0691148,
    "marketplace_to_demand": 173.6994126,
    "grid_to_demand": 35042.35483,
    "govt_battery_charge": 45.45039861
  },
  {
    "timestamp": "31/10/24 0:00",
    "demand": 33970.56985,
    "solar_generated": 0,
    "wind_generated": 60.04495401,
    "marketplace_to_demand": 109.1755099,
    "grid_to_demand": 31957.53493,
    "govt_battery_charge": 8.621056755
  },
  {
    "timestamp": "31/10/24 1:00",
    "demand": 36267.38266,
    "solar_generated": 0,
    "wind_generated": 59.37196823,
    "marketplace_to_demand": 56.2113447,
    "grid_to_demand": 33094.14078,
    "govt_battery_charge": 32.58303643
  },
  {
    "timestamp": "31/10/24 2:00",
    "demand": 34305.51122,
    "solar_generated": 0,
    "wind_generated": 72.99622488,
    "marketplace_to_demand": 46.83088637,
    "grid_to_demand": 31664.57752,
    "govt_battery_charge": 91.59709169
  },
  {
    "timestamp": "31/10/24 3:00",
    "demand": 33707.41949,
    "solar_generated": 0,
    "wind_generated": 58.49692064,
    "marketplace_to_demand": 31.01661743,
    "grid_to_demand": 31276.99896,
    "govt_battery_charge": 83.04018926
  },
  {
    "timestamp": "31/10/24 4:00",
    "demand": 29996.69765,
    "solar_generated": 0,
    "wind_generated": 58.40823057,
    "marketplace_to_demand": 74.23896573,
    "grid_to_demand": 31532.06861,
    "govt_battery_charge": 92.94407279
  },
  {
    "timestamp": "31/10/24 5:00",
    "demand": 31684.40927,
    "solar_generated": 0,
    "wind_generated": 69.77011814,
    "marketplace_to_demand": 79.80939073,
    "grid_to_demand": 33993.51759,
    "govt_battery_charge": 38.6516508
  },
  {
    "timestamp": "31/10/24 6:00",
    "demand": 30901.48693,
    "solar_generated": 16.49554393,
    "wind_generated": 66.23536238,
    "marketplace_to_demand": 112.6195487,
    "grid_to_demand": 30288.71669,
    "govt_battery_charge": 39.44528079
  },
  {
    "timestamp": "31/10/24 7:00",
    "demand": 32977.0199,
    "solar_generated": 10.7187706,
    "wind_generated": 72.78272457,
    "marketplace_to_demand": 130.6721798,
    "grid_to_demand": 33662.27358,
    "govt_battery_charge": 89.35216401
  },
  {
    "timestamp": "31/10/24 8:00",
    "demand": 34490.07187,
    "solar_generated": 12.52730331,
    "wind_generated": 80.19699973,
    "marketplace_to_demand": 133.8861851,
    "grid_to_demand": 32085.46143,
    "govt_battery_charge": 33.78640344
  },
  {
    "timestamp": "31/10/24 9:00",
    "demand": 33158.58527,
    "solar_generated": 23.80936153,
    "wind_generated": 55.62371873,
    "marketplace_to_demand": 39.75571792,
    "grid_to_demand": 31629.10931,
    "govt_battery_charge": 17.32589227
  },
  {
    "timestamp": "31/10/24 10:00",
    "demand": 30121.58937,
    "solar_generated": 9.377674981,
    "wind_generated": 65.40073171,
    "marketplace_to_demand": 156.0174514,
    "grid_to_demand": 34410.86249,
    "govt_battery_charge": 84.21726721
  },
  {
    "timestamp": "31/10/24 11:00",
    "demand": 35592.88844,
    "solar_generated": 13.30433265,
    "wind_generated": 74.24544826,
    "marketplace_to_demand": 164.479384,
    "grid_to_demand": 31275.32066,
    "govt_battery_charge": 40.79665492
  },
  {
    "timestamp": "31/10/24 12:00",
    "demand": 32688.05451,
    "solar_generated": 21.3671336,
    "wind_generated": 60.95413939,
    "marketplace_to_demand": 29.96076518,
    "grid_to_demand": 31733.85742,
    "govt_battery_charge": 23.24332544
  },
  {
    "timestamp": "31/10/24 13:00",
    "demand": 31051.54912,
    "solar_generated": 22.1120407,
    "wind_generated": 79.93035697,
    "marketplace_to_demand": 41.00334697,
    "grid_to_demand": 31217.99969,
    "govt_battery_charge": 98.82137487
  },
  {
    "timestamp": "31/10/24 14:00",
    "demand": 36120.28005,
    "solar_generated": 20.96968545,
    "wind_generated": 63.53855875,
    "marketplace_to_demand": 105.2058421,
    "grid_to_demand": 32435.90275,
    "govt_battery_charge": 13.28423176
  },
  {
    "timestamp": "31/10/24 15:00",
    "demand": 33243.94137,
    "solar_generated": 23.02990925,
    "wind_generated": 75.87269978,
    "marketplace_to_demand": 191.2595159,
    "grid_to_demand": 34919.09029,
    "govt_battery_charge": 46.89646929
  },
  {
    "timestamp": "31/10/24 16:00",
    "demand": 35964.17763,
    "solar_generated": 15.34513973,
    "wind_generated": 64.78417747,
    "marketplace_to_demand": 184.9321556,
    "grid_to_demand": 33517.30708,
    "govt_battery_charge": 38.72742526
  },
  {
    "timestamp": "31/10/24 17:00",
    "demand": 31204.26352,
    "solar_generated": 20.37400365,
    "wind_generated": 63.55245374,
    "marketplace_to_demand": 18.61271879,
    "grid_to_demand": 32009.34739,
    "govt_battery_charge": 16.69862306
  },
  {
    "timestamp": "31/10/24 18:00",
    "demand": 33395.03491,
    "solar_generated": 0,
    "wind_generated": 75.54710181,
    "marketplace_to_demand": 142.0715336,
    "grid_to_demand": 35616.9729,
    "govt_battery_charge": 93.55327615
  },
  {
    "timestamp": "31/10/24 19:00",
    "demand": 30130.14544,
    "solar_generated": 0,
    "wind_generated": 57.27290415,
    "marketplace_to_demand": 152.9137167,
    "grid_to_demand": 35238.46813,
    "govt_battery_charge": 77.05936055
  },
  {
    "timestamp": "31/10/24 20:00",
    "demand": 31752.94077,
    "solar_generated": 0,
    "wind_generated": 79.76202955,
    "marketplace_to_demand": 165.8024265,
    "grid_to_demand": 34884.46786,
    "govt_battery_charge": 19.73559964
  },
  {
    "timestamp": "31/10/24 21:00",
    "demand": 33898.88334,
    "solar_generated": 0,
    "wind_generated": 56.23342991,
    "marketplace_to_demand": 88.92721494,
    "grid_to_demand": 34965.55214,
    "govt_battery_charge": 57.92407343
  },
  {
    "timestamp": "31/10/24 22:00",
    "demand": 36622.82534,
    "solar_generated": 0,
    "wind_generated": 76.01436697,
    "marketplace_to_demand": 137.0070192,
    "grid_to_demand": 29976.37027,
    "govt_battery_charge": 39.04633841
  },
  {
    "timestamp": "31/10/24 23:00",
    "demand": 33579.14723,
    "solar_generated": 0,
    "wind_generated": 72.24330972,
    "marketplace_to_demand": 196.564132,
    "grid_to_demand": 32083.49913,
    "govt_battery_charge": 57.14607012
  },
  {
    "timestamp": "01/11/24 0:00",
    "demand": 30632.36749,
    "solar_generated": 0,
    "wind_generated": 55.1793278,
    "marketplace_to_demand": 151.7539732,
    "grid_to_demand": 36120.17976,
    "govt_battery_charge": 88.56672578
  },
  {
    "timestamp": "01/11/24 1:00",
    "demand": 31825.52137,
    "solar_generated": 0,
    "wind_generated": 69.2840274,
    "marketplace_to_demand": 59.98187294,
    "grid_to_demand": 34978.06057,
    "govt_battery_charge": 25.54548898
  },
  {
    "timestamp": "01/11/24 2:00",
    "demand": 30957.67879,
    "solar_generated": 0,
    "wind_generated": 56.4797864,
    "marketplace_to_demand": 90.75760308,
    "grid_to_demand": 33624.32916,
    "govt_battery_charge": 71.05862698
  },
  {
    "timestamp": "01/11/24 3:00",
    "demand": 33519.75008,
    "solar_generated": 0,
    "wind_generated": 56.10292585,
    "marketplace_to_demand": 118.3297309,
    "grid_to_demand": 35224.13289,
    "govt_battery_charge": 25.97915625
  },
  {
    "timestamp": "01/11/24 4:00",
    "demand": 30127.1896,
    "solar_generated": 0,
    "wind_generated": 70.57290355,
    "marketplace_to_demand": 64.26949208,
    "grid_to_demand": 31244.49169,
    "govt_battery_charge": 99.2025752
  },
  {
    "timestamp": "01/11/24 5:00",
    "demand": 32542.50627,
    "solar_generated": 0,
    "wind_generated": 75.51043011,
    "marketplace_to_demand": 51.93442175,
    "grid_to_demand": 36485.90341,
    "govt_battery_charge": 47.9716733
  },
  {
    "timestamp": "01/11/24 6:00",
    "demand": 35797.13154,
    "solar_generated": 11.93028677,
    "wind_generated": 77.97662104,
    "marketplace_to_demand": 4.917719254,
    "grid_to_demand": 34070.2534,
    "govt_battery_charge": 63.87997388
  },
  {
    "timestamp": "01/11/24 7:00",
    "demand": 31291.60779,
    "solar_generated": 23.09463216,
    "wind_generated": 62.49074219,
    "marketplace_to_demand": 16.27328833,
    "grid_to_demand": 36327.37161,
    "govt_battery_charge": 53.15388346
  },
  {
    "timestamp": "01/11/24 8:00",
    "demand": 32894.9892,
    "solar_generated": 10.69584595,
    "wind_generated": 59.6482995,
    "marketplace_to_demand": 41.11833257,
    "grid_to_demand": 31145.43294,
    "govt_battery_charge": 89.75132908
  },
  {
    "timestamp": "01/11/24 9:00",
    "demand": 30904.04761,
    "solar_generated": 18.98483872,
    "wind_generated": 60.82781437,
    "marketplace_to_demand": 196.6179795,
    "grid_to_demand": 35058.6657,
    "govt_battery_charge": 26.67668514
  },
  {
    "timestamp": "01/11/24 10:00",
    "demand": 36438.35208,
    "solar_generated": 13.74831911,
    "wind_generated": 60.33750536,
    "marketplace_to_demand": 69.20644019,
    "grid_to_demand": 32853.16121,
    "govt_battery_charge": 43.40248653
  },
  {
    "timestamp": "01/11/24 11:00",
    "demand": 30701.36355,
    "solar_generated": 10.23673542,
    "wind_generated": 61.54003325,
    "marketplace_to_demand": 5.828515209,
    "grid_to_demand": 35803.28612,
    "govt_battery_charge": 21.1794402
  },
  {
    "timestamp": "01/11/24 12:00",
    "demand": 30515.85376,
    "solar_generated": 14.00335131,
    "wind_generated": 72.95794532,
    "marketplace_to_demand": 68.50690293,
    "grid_to_demand": 35198.97378,
    "govt_battery_charge": 29.1953807
  },
  {
    "timestamp": "01/11/24 13:00",
    "demand": 31845.4321,
    "solar_generated": 13.11210129,
    "wind_generated": 64.40145707,
    "marketplace_to_demand": 53.70203071,
    "grid_to_demand": 32536.13544,
    "govt_battery_charge": 83.98626194
  },
  {
    "timestamp": "01/11/24 14:00",
    "demand": 36365.00024,
    "solar_generated": 15.79263762,
    "wind_generated": 66.82934499,
    "marketplace_to_demand": 88.52715063,
    "grid_to_demand": 30019.41359,
    "govt_battery_charge": 93.34363581
  },
  {
    "timestamp": "01/11/24 15:00",
    "demand": 32629.74391,
    "solar_generated": 23.08507817,
    "wind_generated": 71.54777178,
    "marketplace_to_demand": 29.45074305,
    "grid_to_demand": 33710.43818,
    "govt_battery_charge": 7.92619692
  },
  {
    "timestamp": "01/11/24 16:00",
    "demand": 30921.29066,
    "solar_generated": 23.73478123,
    "wind_generated": 74.3105958,
    "marketplace_to_demand": 124.3423861,
    "grid_to_demand": 33129.03826,
    "govt_battery_charge": 94.66770423
  },
  {
    "timestamp": "01/11/24 17:00",
    "demand": 30553.08223,
    "solar_generated": 21.46404065,
    "wind_generated": 70.42902442,
    "marketplace_to_demand": 166.7860464,
    "grid_to_demand": 31153.24605,
    "govt_battery_charge": 68.86290798
  },
  {
    "timestamp": "01/11/24 18:00",
    "demand": 34670.54375,
    "solar_generated": 0,
    "wind_generated": 67.07920459,
    "marketplace_to_demand": 71.40266214,
    "grid_to_demand": 35799.68238,
    "govt_battery_charge": 94.79791275
  },
  {
    "timestamp": "01/11/24 19:00",
    "demand": 29975.48359,
    "solar_generated": 0,
    "wind_generated": 69.29053531,
    "marketplace_to_demand": 116.6404022,
    "grid_to_demand": 32563.17098,
    "govt_battery_charge": 43.79722565
  },
  {
    "timestamp": "01/11/24 20:00",
    "demand": 34797.37123,
    "solar_generated": 0,
    "wind_generated": 63.85173455,
    "marketplace_to_demand": 107.7628052,
    "grid_to_demand": 33196.92714,
    "govt_battery_charge": 38.6285448
  },
  {
    "timestamp": "01/11/24 21:00",
    "demand": 31478.76627,
    "solar_generated": 0,
    "wind_generated": 61.98254332,
    "marketplace_to_demand": 11.31698216,
    "grid_to_demand": 35227.86233,
    "govt_battery_charge": 66.99779329
  },
  {
    "timestamp": "01/11/24 22:00",
    "demand": 35262.18261,
    "solar_generated": 0,
    "wind_generated": 62.85777531,
    "marketplace_to_demand": 15.13425371,
    "grid_to_demand": 31865.25451,
    "govt_battery_charge": 2.005910288
  },
  {
    "timestamp": "01/11/24 23:00",
    "demand": 31209.33529,
    "solar_generated": 0,
    "wind_generated": 66.24084205,
    "marketplace_to_demand": 117.5038491,
    "grid_to_demand": 35822.52832,
    "govt_battery_charge": 13.15742928
  },
  {
    "timestamp": "02/11/24 0:00",
    "demand": 31508.36462,
    "solar_generated": 0,
    "wind_generated": 55.5662981,
    "marketplace_to_demand": 168.3050516,
    "grid_to_demand": 31826.6714,
    "govt_battery_charge": 89.35464815
  },
  {
    "timestamp": "02/11/24 1:00",
    "demand": 35614.15863,
    "solar_generated": 0,
    "wind_generated": 63.36641101,
    "marketplace_to_demand": 153.4987252,
    "grid_to_demand": 33644.81246,
    "govt_battery_charge": 10.39549118
  },
  {
    "timestamp": "02/11/24 2:00",
    "demand": 34533.14449,
    "solar_generated": 0,
    "wind_generated": 56.02295216,
    "marketplace_to_demand": 39.66811454,
    "grid_to_demand": 29955.38816,
    "govt_battery_charge": 69.72357148
  },
  {
    "timestamp": "02/11/24 3:00",
    "demand": 32312.17351,
    "solar_generated": 0,
    "wind_generated": 72.77408674,
    "marketplace_to_demand": 38.22036588,
    "grid_to_demand": 31778.62033,
    "govt_battery_charge": 62.48423604
  },
  {
    "timestamp": "02/11/24 4:00",
    "demand": 31223.24652,
    "solar_generated": 0,
    "wind_generated": 54.86435165,
    "marketplace_to_demand": 8.771269,
    "grid_to_demand": 35021.27727,
    "govt_battery_charge": 43.64495605
  },
  {
    "timestamp": "02/11/24 5:00",
    "demand": 32987.61306,
    "solar_generated": 0,
    "wind_generated": 79.87141792,
    "marketplace_to_demand": 123.2173839,
    "grid_to_demand": 35665.69847,
    "govt_battery_charge": 19.62262002
  },
  {
    "timestamp": "02/11/24 6:00",
    "demand": 33063.93102,
    "solar_generated": 16.53083852,
    "wind_generated": 69.0985143,
    "marketplace_to_demand": 154.3223772,
    "grid_to_demand": 36430.66437,
    "govt_battery_charge": 57.42096642
  },
  {
    "timestamp": "02/11/24 7:00",
    "demand": 34236.83223,
    "solar_generated": 18.44342013,
    "wind_generated": 55.73709062,
    "marketplace_to_demand": 154.7747569,
    "grid_to_demand": 35334.50025,
    "govt_battery_charge": 57.98169768
  },
  {
    "timestamp": "02/11/24 8:00",
    "demand": 31372.07785,
    "solar_generated": 17.27256275,
    "wind_generated": 64.67817671,
    "marketplace_to_demand": 33.61766645,
    "grid_to_demand": 33121.88597,
    "govt_battery_charge": 43.33286063
  },
  {
    "timestamp": "02/11/24 9:00",
    "demand": 34896.42265,
    "solar_generated": 17.14060097,
    "wind_generated": 54.59612516,
    "marketplace_to_demand": 132.4164374,
    "grid_to_demand": 34286.93614,
    "govt_battery_charge": 64.12678666
  },
  {
    "timestamp": "02/11/24 10:00",
    "demand": 30516.71932,
    "solar_generated": 22.26999749,
    "wind_generated": 65.15303171,
    "marketplace_to_demand": 141.2460327,
    "grid_to_demand": 33007.87945,
    "govt_battery_charge": 79.7816555
  },
  {
    "timestamp": "02/11/24 11:00",
    "demand": 34397.91632,
    "solar_generated": 13.35806196,
    "wind_generated": 77.04582786,
    "marketplace_to_demand": 154.1585117,
    "grid_to_demand": 35571.74953,
    "govt_battery_charge": 30.60644132
  },
  {
    "timestamp": "02/11/24 12:00",
    "demand": 35557.97565,
    "solar_generated": 16.23780269,
    "wind_generated": 72.61469572,
    "marketplace_to_demand": 196.2840094,
    "grid_to_demand": 35843.6216,
    "govt_battery_charge": 9.46714587
  },
  {
    "timestamp": "02/11/24 13:00",
    "demand": 30814.29791,
    "solar_generated": 11.31137377,
    "wind_generated": 54.7009942,
    "marketplace_to_demand": 2.518658691,
    "grid_to_demand": 33173.03404,
    "govt_battery_charge": 76.69598309
  },
  {
    "timestamp": "02/11/24 14:00",
    "demand": 33777.91066,
    "solar_generated": 10.63045209,
    "wind_generated": 70.91243674,
    "marketplace_to_demand": 39.33087541,
    "grid_to_demand": 30739.157,
    "govt_battery_charge": 53.32314973
  },
  {
    "timestamp": "02/11/24 15:00",
    "demand": 33171.50388,
    "solar_generated": 17.52268108,
    "wind_generated": 69.72480628,
    "marketplace_to_demand": 2.816662307,
    "grid_to_demand": 32459.15089,
    "govt_battery_charge": 18.45359949
  },
  {
    "timestamp": "02/11/24 16:00",
    "demand": 36545.44532,
    "solar_generated": 17.0011381,
    "wind_generated": 76.94973833,
    "marketplace_to_demand": 122.3407083,
    "grid_to_demand": 33764.92804,
    "govt_battery_charge": 10.75302783
  },
  {
    "timestamp": "02/11/24 17:00",
    "demand": 32700.37116,
    "solar_generated": 21.5174643,
    "wind_generated": 61.16465201,
    "marketplace_to_demand": 20.49438284,
    "grid_to_demand": 31787.76777,
    "govt_battery_charge": 32.97493059
  },
  {
    "timestamp": "02/11/24 18:00",
    "demand": 30137.6542,
    "solar_generated": 0,
    "wind_generated": 57.51689159,
    "marketplace_to_demand": 91.44688228,
    "grid_to_demand": 33376.96084,
    "govt_battery_charge": 79.89472689
  },
  {
    "timestamp": "02/11/24 19:00",
    "demand": 30463.89631,
    "solar_generated": 0,
    "wind_generated": 78.190535,
    "marketplace_to_demand": 3.75336835,
    "grid_to_demand": 31849.28922,
    "govt_battery_charge": 7.029082812
  },
  {
    "timestamp": "02/11/24 20:00",
    "demand": 30705.56958,
    "solar_generated": 0,
    "wind_generated": 62.23164452,
    "marketplace_to_demand": 78.25458736,
    "grid_to_demand": 35329.37185,
    "govt_battery_charge": 49.17982203
  },
  {
    "timestamp": "02/11/24 21:00",
    "demand": 35211.45889,
    "solar_generated": 0,
    "wind_generated": 59.91175743,
    "marketplace_to_demand": 197.0636745,
    "grid_to_demand": 35989.29352,
    "govt_battery_charge": 92.74200319
  },
  {
    "timestamp": "02/11/24 22:00",
    "demand": 34037.70721,
    "solar_generated": 0,
    "wind_generated": 68.81557733,
    "marketplace_to_demand": 96.67123216,
    "grid_to_demand": 33838.85966,
    "govt_battery_charge": 9.506397683
  },
  {
    "timestamp": "02/11/24 23:00",
    "demand": 31858.47043,
    "solar_generated": 0,
    "wind_generated": 65.08499845,
    "marketplace_to_demand": 172.6541262,
    "grid_to_demand": 33273.14325,
    "govt_battery_charge": 2.33400925
  },
  {
    "timestamp": "03/11/24 0:00",
    "demand": 31334.93584,
    "solar_generated": 0,
    "wind_generated": 80.39780098,
    "marketplace_to_demand": 124.4355981,
    "grid_to_demand": 31804.9273,
    "govt_battery_charge": 53.43878337
  },
  {
    "timestamp": "03/11/24 1:00",
    "demand": 33217.48886,
    "solar_generated": 0,
    "wind_generated": 73.09584935,
    "marketplace_to_demand": 18.77081363,
    "grid_to_demand": 35554.62647,
    "govt_battery_charge": 60.14202496
  },
  {
    "timestamp": "03/11/24 2:00",
    "demand": 33036.71718,
    "solar_generated": 0,
    "wind_generated": 66.70811081,
    "marketplace_to_demand": 77.62879215,
    "grid_to_demand": 33944.61396,
    "govt_battery_charge": 72.6876218
  },
  {
    "timestamp": "03/11/24 3:00",
    "demand": 32539.51077,
    "solar_generated": 0,
    "wind_generated": 57.1120828,
    "marketplace_to_demand": 154.3928142,
    "grid_to_demand": 29938.12324,
    "govt_battery_charge": 24.09852743
  },
  {
    "timestamp": "03/11/24 4:00",
    "demand": 35860.1331,
    "solar_generated": 0,
    "wind_generated": 57.42480645,
    "marketplace_to_demand": 172.6500603,
    "grid_to_demand": 29918.49385,
    "govt_battery_charge": 95.99613862
  },
  {
    "timestamp": "03/11/24 5:00",
    "demand": 36128.59055,
    "solar_generated": 0,
    "wind_generated": 71.78363589,
    "marketplace_to_demand": 106.5931802,
    "grid_to_demand": 33144.40363,
    "govt_battery_charge": 89.02946292
  },
  {
    "timestamp": "03/11/24 6:00",
    "demand": 31173.25126,
    "solar_generated": 7.321073252,
    "wind_generated": 72.18040265,
    "marketplace_to_demand": 178.7798672,
    "grid_to_demand": 30054.01234,
    "govt_battery_charge": 49.00264478
  },
  {
    "timestamp": "03/11/24 7:00",
    "demand": 31432.96449,
    "solar_generated": 23.92103152,
    "wind_generated": 58.37103156,
    "marketplace_to_demand": 35.07243262,
    "grid_to_demand": 33767.37191,
    "govt_battery_charge": 99.19107317
  },
  {
    "timestamp": "03/11/24 8:00",
    "demand": 33421.45456,
    "solar_generated": 14.71723752,
    "wind_generated": 65.406969,
    "marketplace_to_demand": 95.17513455,
    "grid_to_demand": 31772.78354,
    "govt_battery_charge": 65.97556097
  },
  {
    "timestamp": "03/11/24 9:00",
    "demand": 35513.12908,
    "solar_generated": 10.74412899,
    "wind_generated": 67.15015175,
    "marketplace_to_demand": 4.732249987,
    "grid_to_demand": 30537.53457,
    "govt_battery_charge": 81.41567505
  },
  {
    "timestamp": "03/11/24 10:00",
    "demand": 31403.33265,
    "solar_generated": 8.648399667,
    "wind_generated": 69.26697004,
    "marketplace_to_demand": 126.4461695,
    "grid_to_demand": 30707.07625,
    "govt_battery_charge": 42.18681371
  },
  {
    "timestamp": "03/11/24 11:00",
    "demand": 30629.33655,
    "solar_generated": 9.734833222,
    "wind_generated": 68.95491225,
    "marketplace_to_demand": 95.49120081,
    "grid_to_demand": 32986.32912,
    "govt_battery_charge": 34.87684935
  },
  {
    "timestamp": "03/11/24 12:00",
    "demand": 36607.73766,
    "solar_generated": 17.77147601,
    "wind_generated": 71.29162278,
    "marketplace_to_demand": 95.24419854,
    "grid_to_demand": 36093.36558,
    "govt_battery_charge": 89.27083054
  },
  {
    "timestamp": "03/11/24 13:00",
    "demand": 32394.00731,
    "solar_generated": 16.55275729,
    "wind_generated": 54.88806992,
    "marketplace_to_demand": 6.65805631,
    "grid_to_demand": 33436.11071,
    "govt_battery_charge": 97.45839163
  },
  {
    "timestamp": "03/11/24 14:00",
    "demand": 35902.11064,
    "solar_generated": 21.75059494,
    "wind_generated": 64.28757416,
    "marketplace_to_demand": 21.28411707,
    "grid_to_demand": 31390.30974,
    "govt_battery_charge": 12.65822789
  },
  {
    "timestamp": "03/11/24 15:00",
    "demand": 32809.24282,
    "solar_generated": 18.99772673,
    "wind_generated": 59.10630389,
    "marketplace_to_demand": 186.7791737,
    "grid_to_demand": 30407.8973,
    "govt_battery_charge": 26.17274914
  },
  {
    "timestamp": "03/11/24 16:00",
    "demand": 34780.00406,
    "solar_generated": 22.03469984,
    "wind_generated": 79.69499488,
    "marketplace_to_demand": 158.7219993,
    "grid_to_demand": 33872.62692,
    "govt_battery_charge": 1.97676048
  },
  {
    "timestamp": "03/11/24 17:00",
    "demand": 35538.31222,
    "solar_generated": 8.926969782,
    "wind_generated": 77.55110466,
    "marketplace_to_demand": 30.08921041,
    "grid_to_demand": 35647.52973,
    "govt_battery_charge": 70.21596169
  },
  {
    "timestamp": "03/11/24 18:00",
    "demand": 32967.45043,
    "solar_generated": 0,
    "wind_generated": 76.47664495,
    "marketplace_to_demand": 131.3797766,
    "grid_to_demand": 32038.55635,
    "govt_battery_charge": 17.38237797
  },
  {
    "timestamp": "03/11/24 19:00",
    "demand": 31264.34184,
    "solar_generated": 0,
    "wind_generated": 53.79537707,
    "marketplace_to_demand": 166.7192294,
    "grid_to_demand": 30147.08696,
    "govt_battery_charge": 48.72177089
  },
  {
    "timestamp": "03/11/24 20:00",
    "demand": 32331.20832,
    "solar_generated": 0,
    "wind_generated": 65.05327082,
    "marketplace_to_demand": 44.82204177,
    "grid_to_demand": 32183.98139,
    "govt_battery_charge": 53.88523982
  },
  {
    "timestamp": "03/11/24 21:00",
    "demand": 30434.74123,
    "solar_generated": 0,
    "wind_generated": 66.12375052,
    "marketplace_to_demand": 128.5761932,
    "grid_to_demand": 35226.99438,
    "govt_battery_charge": 43.67218899
  },
  {
    "timestamp": "03/11/24 22:00",
    "demand": 33784.66938,
    "solar_generated": 0,
    "wind_generated": 70.11319694,
    "marketplace_to_demand": 86.13868243,
    "grid_to_demand": 35267.54181,
    "govt_battery_charge": 88.21185033
  },
  {
    "timestamp": "03/11/24 23:00",
    "demand": 34409.2067,
    "solar_generated": 0,
    "wind_generated": 67.5806343,
    "marketplace_to_demand": 197.5960784,
    "grid_to_demand": 35434.55768,
    "govt_battery_charge": 12.80982623
  },
  {
    "timestamp": "04/11/24 0:00",
    "demand": 34955.74635,
    "solar_generated": 0,
    "wind_generated": 54.93576459,
    "marketplace_to_demand": 85.79389444,
    "grid_to_demand": 36263.76853,
    "govt_battery_charge": 46.38646277
  },
  {
    "timestamp": "04/11/24 1:00",
    "demand": 36389.9377,
    "solar_generated": 0,
    "wind_generated": 69.28324637,
    "marketplace_to_demand": 172.8720943,
    "grid_to_demand": 30820.65554,
    "govt_battery_charge": 65.92181368
  },
  {
    "timestamp": "04/11/24 2:00",
    "demand": 33250.21333,
    "solar_generated": 0,
    "wind_generated": 61.89293459,
    "marketplace_to_demand": 2.503876285,
    "grid_to_demand": 32165.69663,
    "govt_battery_charge": 11.93389811
  },
  {
    "timestamp": "04/11/24 3:00",
    "demand": 32833.24959,
    "solar_generated": 0,
    "wind_generated": 60.56612741,
    "marketplace_to_demand": 21.34348383,
    "grid_to_demand": 33156.49215,
    "govt_battery_charge": 82.16375537
  },
  {
    "timestamp": "04/11/24 4:00",
    "demand": 35295.15161,
    "solar_generated": 0,
    "wind_generated": 67.63615596,
    "marketplace_to_demand": 1.214848112,
    "grid_to_demand": 30733.49835,
    "govt_battery_charge": 88.50164847
  },
  {
    "timestamp": "04/11/24 5:00",
    "demand": 33485.49092,
    "solar_generated": 0,
    "wind_generated": 75.71585495,
    "marketplace_to_demand": 39.27789007,
    "grid_to_demand": 30754.0511,
    "govt_battery_charge": 89.61034099
  },
  {
    "timestamp": "04/11/24 6:00",
    "demand": 32408.60965,
    "solar_generated": 12.83283877,
    "wind_generated": 57.03955842,
    "marketplace_to_demand": 71.62727259,
    "grid_to_demand": 35933.41939,
    "govt_battery_charge": 49.80220514
  },
  {
    "timestamp": "04/11/24 7:00",
    "demand": 35701.74684,
    "solar_generated": 6.705255916,
    "wind_generated": 73.30967323,
    "marketplace_to_demand": 23.19128244,
    "grid_to_demand": 34600.81359,
    "govt_battery_charge": 22.48327186
  },
  {
    "timestamp": "04/11/24 8:00",
    "demand": 35850.22175,
    "solar_generated": 21.35750945,
    "wind_generated": 64.08042089,
    "marketplace_to_demand": 167.1597706,
    "grid_to_demand": 36247.20704,
    "govt_battery_charge": 95.00995055
  },
  {
    "timestamp": "04/11/24 9:00",
    "demand": 36543.77787,
    "solar_generated": 13.99184136,
    "wind_generated": 64.01454096,
    "marketplace_to_demand": 19.45274912,
    "grid_to_demand": 33832.12039,
    "govt_battery_charge": 39.75726593
  },
  {
    "timestamp": "04/11/24 10:00",
    "demand": 35466.89928,
    "solar_generated": 7.000414938,
    "wind_generated": 79.21885872,
    "marketplace_to_demand": 33.55996363,
    "grid_to_demand": 30153.92005,
    "govt_battery_charge": 96.39710912
  },
  {
    "timestamp": "04/11/24 11:00",
    "demand": 32969.97893,
    "solar_generated": 12.80122704,
    "wind_generated": 80.23048187,
    "marketplace_to_demand": 14.5545371,
    "grid_to_demand": 30993.74388,
    "govt_battery_charge": 99.12081386
  },
  {
    "timestamp": "04/11/24 12:00",
    "demand": 36242.83288,
    "solar_generated": 17.87192804,
    "wind_generated": 70.44207028,
    "marketplace_to_demand": 166.7872271,
    "grid_to_demand": 31120.65992,
    "govt_battery_charge": 89.77630264
  },
  {
    "timestamp": "04/11/24 13:00",
    "demand": 31052.39514,
    "solar_generated": 18.09497136,
    "wind_generated": 58.7168004,
    "marketplace_to_demand": 27.55538602,
    "grid_to_demand": 31731.03588,
    "govt_battery_charge": 70.8457881
  },
  {
    "timestamp": "04/11/24 14:00",
    "demand": 33193.17425,
    "solar_generated": 9.673657538,
    "wind_generated": 55.30788468,
    "marketplace_to_demand": 197.8415793,
    "grid_to_demand": 30430.33578,
    "govt_battery_charge": 82.17017514
  },
  {
    "timestamp": "04/11/24 15:00",
    "demand": 36104.89251,
    "solar_generated": 17.77744329,
    "wind_generated": 66.81985087,
    "marketplace_to_demand": 136.8131095,
    "grid_to_demand": 30368.24809,
    "govt_battery_charge": 47.60301435
  },
  {
    "timestamp": "04/11/24 16:00",
    "demand": 31065.22555,
    "solar_generated": 8.418198405,
    "wind_generated": 67.14467663,
    "marketplace_to_demand": 172.9946396,
    "grid_to_demand": 34098.99216,
    "govt_battery_charge": 20.83750958
  },
  {
    "timestamp": "04/11/24 17:00",
    "demand": 36381.1002,
    "solar_generated": 8.95510929,
    "wind_generated": 66.1579274,
    "marketplace_to_demand": 98.70726742,
    "grid_to_demand": 31006.79275,
    "govt_battery_charge": 68.66799518
  },
  {
    "timestamp": "04/11/24 18:00",
    "demand": 31249.79915,
    "solar_generated": 0,
    "wind_generated": 54.07873842,
    "marketplace_to_demand": 144.6437208,
    "grid_to_demand": 30233.20665,
    "govt_battery_charge": 26.20929931
  },
  {
    "timestamp": "04/11/24 19:00",
    "demand": 33396.27747,
    "solar_generated": 0,
    "wind_generated": 76.22193217,
    "marketplace_to_demand": 152.9426841,
    "grid_to_demand": 34303.43761,
    "govt_battery_charge": 66.3776319
  },
  {
    "timestamp": "04/11/24 20:00",
    "demand": 32513.19683,
    "solar_generated": 0,
    "wind_generated": 68.64563208,
    "marketplace_to_demand": 87.83155181,
    "grid_to_demand": 33174.02803,
    "govt_battery_charge": 47.74357361
  },
  {
    "timestamp": "04/11/24 21:00",
    "demand": 30818.23868,
    "solar_generated": 0,
    "wind_generated": 64.73817431,
    "marketplace_to_demand": 104.3488381,
    "grid_to_demand": 36440.50297,
    "govt_battery_charge": 14.06760277
  },
  {
    "timestamp": "04/11/24 22:00",
    "demand": 34412.26112,
    "solar_generated": 0,
    "wind_generated": 69.37764333,
    "marketplace_to_demand": 101.5428683,
    "grid_to_demand": 34502.11921,
    "govt_battery_charge": 76.35217462
  },
  {
    "timestamp": "04/11/24 23:00",
    "demand": 35931.07952,
    "solar_generated": 0,
    "wind_generated": 58.39094567,
    "marketplace_to_demand": 50.04414674,
    "grid_to_demand": 36100.93921,
    "govt_battery_charge": 99.55745373
  },
  {
    "timestamp": "05/11/24 0:00",
    "demand": 34729.68234,
    "solar_generated": 0,
    "wind_generated": 74.16208723,
    "marketplace_to_demand": 57.52885319,
    "grid_to_demand": 35079.78293,
    "govt_battery_charge": 76.13177123
  },
  {
    "timestamp": "05/11/24 1:00",
    "demand": 30497.05971,
    "solar_generated": 0,
    "wind_generated": 76.34804723,
    "marketplace_to_demand": 116.3398183,
    "grid_to_demand": 32736.29169,
    "govt_battery_charge": 90.20283423
  },
  {
    "timestamp": "05/11/24 2:00",
    "demand": 32308.88859,
    "solar_generated": 0,
    "wind_generated": 56.03525624,
    "marketplace_to_demand": 6.768401278,
    "grid_to_demand": 32632.56217,
    "govt_battery_charge": 32.60486693
  },
  {
    "timestamp": "05/11/24 3:00",
    "demand": 34411.82066,
    "solar_generated": 0,
    "wind_generated": 58.43929268,
    "marketplace_to_demand": 189.7379223,
    "grid_to_demand": 35545.5477,
    "govt_battery_charge": 36.92272152
  },
  {
    "timestamp": "05/11/24 4:00",
    "demand": 33538.87998,
    "solar_generated": 0,
    "wind_generated": 60.29388373,
    "marketplace_to_demand": 140.2102512,
    "grid_to_demand": 33050.34604,
    "govt_battery_charge": 77.54274455
  },
  {
    "timestamp": "05/11/24 5:00",
    "demand": 32698.09934,
    "solar_generated": 0,
    "wind_generated": 71.0178094,
    "marketplace_to_demand": 104.6986587,
    "grid_to_demand": 31649.66516,
    "govt_battery_charge": 3.377881341
  },
  {
    "timestamp": "05/11/24 6:00",
    "demand": 34288.58917,
    "solar_generated": 17.40696614,
    "wind_generated": 70.38736547,
    "marketplace_to_demand": 94.09550962,
    "grid_to_demand": 32509.32341,
    "govt_battery_charge": 55.23929141
  },
  {
    "timestamp": "05/11/24 7:00",
    "demand": 30064.04169,
    "solar_generated": 7.290346283,
    "wind_generated": 79.23448488,
    "marketplace_to_demand": 47.13538207,
    "grid_to_demand": 36397.12861,
    "govt_battery_charge": 6.609352957
  },
  {
    "timestamp": "05/11/24 8:00",
    "demand": 35213.0156,
    "solar_generated": 16.24331259,
    "wind_generated": 55.7398632,
    "marketplace_to_demand": 30.37481615,
    "grid_to_demand": 32877.76377,
    "govt_battery_charge": 61.94664906
  },
  {
    "timestamp": "05/11/24 9:00",
    "demand": 32653.68653,
    "solar_generated": 11.87466185,
    "wind_generated": 69.03536322,
    "marketplace_to_demand": 31.32041962,
    "grid_to_demand": 36246.87227,
    "govt_battery_charge": 28.03505354
  },
  {
    "timestamp": "05/11/24 10:00",
    "demand": 35116.90912,
    "solar_generated": 19.83701293,
    "wind_generated": 74.26779366,
    "marketplace_to_demand": 50.29047563,
    "grid_to_demand": 30934.55353,
    "govt_battery_charge": 82.85621302
  },
  {
    "timestamp": "05/11/24 11:00",
    "demand": 30401.00188,
    "solar_generated": 19.02707741,
    "wind_generated": 76.20078704,
    "marketplace_to_demand": 189.6103944,
    "grid_to_demand": 31912.8384,
    "govt_battery_charge": 23.78498009
  },
  {
    "timestamp": "05/11/24 12:00",
    "demand": 32696.47829,
    "solar_generated": 8.813888002,
    "wind_generated": 61.29234315,
    "marketplace_to_demand": 104.1103295,
    "grid_to_demand": 34883.07621,
    "govt_battery_charge": 60.11489493
  },
  {
    "timestamp": "05/11/24 13:00",
    "demand": 31632.94591,
    "solar_generated": 16.88251359,
    "wind_generated": 69.61946417,
    "marketplace_to_demand": 13.81099296,
    "grid_to_demand": 32733.82896,
    "govt_battery_charge": 55.33350039
  },
  {
    "timestamp": "05/11/24 14:00",
    "demand": 36422.64271,
    "solar_generated": 16.86291593,
    "wind_generated": 61.81932932,
    "marketplace_to_demand": 179.3358637,
    "grid_to_demand": 30895.7702,
    "govt_battery_charge": 32.94316796
  },
  {
    "timestamp": "05/11/24 15:00",
    "demand": 35178.42141,
    "solar_generated": 9.232624752,
    "wind_generated": 66.10436393,
    "marketplace_to_demand": 155.114262,
    "grid_to_demand": 31784.68599,
    "govt_battery_charge": 74.97535765
  },
  {
    "timestamp": "05/11/24 16:00",
    "demand": 36106.4256,
    "solar_generated": 12.74304687,
    "wind_generated": 66.77030184,
    "marketplace_to_demand": 59.36438115,
    "grid_to_demand": 35352.10986,
    "govt_battery_charge": 2.029422995
  },
  {
    "timestamp": "05/11/24 17:00",
    "demand": 36142.61439,
    "solar_generated": 11.85226924,
    "wind_generated": 70.75636916,
    "marketplace_to_demand": 125.07592,
    "grid_to_demand": 35858.83114,
    "govt_battery_charge": 84.49782937
  },
  {
    "timestamp": "05/11/24 18:00",
    "demand": 31970.90866,
    "solar_generated": 0,
    "wind_generated": 62.45133302,
    "marketplace_to_demand": 141.7747928,
    "grid_to_demand": 34395.09242,
    "govt_battery_charge": 34.36320974
  },
  {
    "timestamp": "05/11/24 19:00",
    "demand": 35151.33513,
    "solar_generated": 0,
    "wind_generated": 76.44661217,
    "marketplace_to_demand": 26.09054264,
    "grid_to_demand": 35592.2043,
    "govt_battery_charge": 12.69537765
  },
  {
    "timestamp": "05/11/24 20:00",
    "demand": 33171.01899,
    "solar_generated": 0,
    "wind_generated": 65.20991368,
    "marketplace_to_demand": 192.3718869,
    "grid_to_demand": 35669.71535,
    "govt_battery_charge": 21.95187138
  },
  {
    "timestamp": "05/11/24 21:00",
    "demand": 30756.76504,
    "solar_generated": 0,
    "wind_generated": 57.61280792,
    "marketplace_to_demand": 101.4092596,
    "grid_to_demand": 31602.64054,
    "govt_battery_charge": 70.30932274
  },
  {
    "timestamp": "05/11/24 22:00",
    "demand": 34954.01234,
    "solar_generated": 0,
    "wind_generated": 64.09366146,
    "marketplace_to_demand": 92.03526371,
    "grid_to_demand": 35932.81369,
    "govt_battery_charge": 91.5708726
  },
  {
    "timestamp": "05/11/24 23:00",
    "demand": 32062.72864,
    "solar_generated": 0,
    "wind_generated": 63.42251208,
    "marketplace_to_demand": 98.52609903,
    "grid_to_demand": 30176.53969,
    "govt_battery_charge": 95.17527818
  },
  {
    "timestamp": "06/11/24 0:00",
    "demand": 35517.79212,
    "solar_generated": 0,
    "wind_generated": 56.96664818,
    "marketplace_to_demand": 178.8602335,
    "grid_to_demand": 31046.05121,
    "govt_battery_charge": 5.075425247
  },
  {
    "timestamp": "06/11/24 1:00",
    "demand": 35657.48273,
    "solar_generated": 0,
    "wind_generated": 55.73867043,
    "marketplace_to_demand": 39.13492737,
    "grid_to_demand": 30206.39547,
    "govt_battery_charge": 63.85340495
  },
  {
    "timestamp": "06/11/24 2:00",
    "demand": 36584.70964,
    "solar_generated": 0,
    "wind_generated": 70.98765744,
    "marketplace_to_demand": 151.1989113,
    "grid_to_demand": 34112.10144,
    "govt_battery_charge": 37.26203847
  },
  {
    "timestamp": "06/11/24 3:00",
    "demand": 31644.57672,
    "solar_generated": 0,
    "wind_generated": 69.73473986,
    "marketplace_to_demand": 141.2902229,
    "grid_to_demand": 31307.90617,
    "govt_battery_charge": 25.92341857
  },
  {
    "timestamp": "06/11/24 4:00",
    "demand": 35302.48861,
    "solar_generated": 0,
    "wind_generated": 57.8210052,
    "marketplace_to_demand": 114.4556954,
    "grid_to_demand": 31152.9334,
    "govt_battery_charge": 44.28433059
  },
  {
    "timestamp": "06/11/24 5:00",
    "demand": 36572.17859,
    "solar_generated": 0,
    "wind_generated": 60.36140785,
    "marketplace_to_demand": 116.0105442,
    "grid_to_demand": 32700.31712,
    "govt_battery_charge": 62.01344594
  },
  {
    "timestamp": "06/11/24 6:00",
    "demand": 31583.10832,
    "solar_generated": 6.251328291,
    "wind_generated": 57.64913005,
    "marketplace_to_demand": 164.9479772,
    "grid_to_demand": 30162.35929,
    "govt_battery_charge": 84.2553606
  },
  {
    "timestamp": "06/11/24 7:00",
    "demand": 31180.39713,
    "solar_generated": 21.53443251,
    "wind_generated": 54.65616439,
    "marketplace_to_demand": 73.23817668,
    "grid_to_demand": 35925.62048,
    "govt_battery_charge": 74.64206114
  },
  {
    "timestamp": "06/11/24 8:00",
    "demand": 30924.82897,
    "solar_generated": 16.6486829,
    "wind_generated": 59.55936021,
    "marketplace_to_demand": 178.2150896,
    "grid_to_demand": 32464.65076,
    "govt_battery_charge": 14.24882117
  },
  {
    "timestamp": "06/11/24 9:00",
    "demand": 36603.11327,
    "solar_generated": 18.55663015,
    "wind_generated": 58.50694949,
    "marketplace_to_demand": 78.51994231,
    "grid_to_demand": 31378.47593,
    "govt_battery_charge": 81.05044057
  },
  {
    "timestamp": "06/11/24 10:00",
    "demand": 30364.12905,
    "solar_generated": 19.93189238,
    "wind_generated": 54.2892142,
    "marketplace_to_demand": 127.260713,
    "grid_to_demand": 31568.34616,
    "govt_battery_charge": 66.20038694
  },
  {
    "timestamp": "06/11/24 11:00",
    "demand": 32026.39452,
    "solar_generated": 7.595060744,
    "wind_generated": 58.72712406,
    "marketplace_to_demand": 105.4958196,
    "grid_to_demand": 33912.20145,
    "govt_battery_charge": 72.01114911
  },
  {
    "timestamp": "06/11/24 12:00",
    "demand": 35179.92767,
    "solar_generated": 12.30364625,
    "wind_generated": 61.93334337,
    "marketplace_to_demand": 63.45627066,
    "grid_to_demand": 33278.61181,
    "govt_battery_charge": 49.86794344
  },
  {
    "timestamp": "06/11/24 13:00",
    "demand": 34780.49014,
    "solar_generated": 23.60943077,
    "wind_generated": 53.62244579,
    "marketplace_to_demand": 104.3019395,
    "grid_to_demand": 30825.11236,
    "govt_battery_charge": 55.87162558
  },
  {
    "timestamp": "06/11/24 14:00",
    "demand": 32654.42301,
    "solar_generated": 14.23497586,
    "wind_generated": 79.89149901,
    "marketplace_to_demand": 6.207725019,
    "grid_to_demand": 33162.0015,
    "govt_battery_charge": 68.40958481
  },
  {
    "timestamp": "06/11/24 15:00",
    "demand": 32277.25913,
    "solar_generated": 11.74451187,
    "wind_generated": 54.16833893,
    "marketplace_to_demand": 101.1302085,
    "grid_to_demand": 33731.11935,
    "govt_battery_charge": 71.02571038
  },
  {
    "timestamp": "06/11/24 16:00",
    "demand": 32177.28949,
    "solar_generated": 7.56978616,
    "wind_generated": 76.22571878,
    "marketplace_to_demand": 145.4065513,
    "grid_to_demand": 33298.00408,
    "govt_battery_charge": 54.8040421
  },
  {
    "timestamp": "06/11/24 17:00",
    "demand": 31910.80893,
    "solar_generated": 17.34760728,
    "wind_generated": 62.46206398,
    "marketplace_to_demand": 131.6890983,
    "grid_to_demand": 35874.12049,
    "govt_battery_charge": 55.28155578
  },
  {
    "timestamp": "06/11/24 18:00",
    "demand": 32729.50657,
    "solar_generated": 0,
    "wind_generated": 74.80034276,
    "marketplace_to_demand": 163.6330784,
    "grid_to_demand": 30518.00461,
    "govt_battery_charge": 98.10123334
  },
  {
    "timestamp": "06/11/24 19:00",
    "demand": 33523.9975,
    "solar_generated": 0,
    "wind_generated": 62.14360177,
    "marketplace_to_demand": 97.78269812,
    "grid_to_demand": 35321.42785,
    "govt_battery_charge": 64.99370479
  },
  {
    "timestamp": "06/11/24 20:00",
    "demand": 31912.8578,
    "solar_generated": 0,
    "wind_generated": 69.08149971,
    "marketplace_to_demand": 18.21854373,
    "grid_to_demand": 33362.6436,
    "govt_battery_charge": 29.56555092
  },
  {
    "timestamp": "06/11/24 21:00",
    "demand": 34428.38033,
    "solar_generated": 0,
    "wind_generated": 65.97135243,
    "marketplace_to_demand": 132.4235751,
    "grid_to_demand": 31549.36451,
    "govt_battery_charge": 66.74581468
  },
  {
    "timestamp": "06/11/24 22:00",
    "demand": 31972.60539,
    "solar_generated": 0,
    "wind_generated": 76.17831496,
    "marketplace_to_demand": 63.51777079,
    "grid_to_demand": 31287.07666,
    "govt_battery_charge": 17.83597076
  },
  {
    "timestamp": "06/11/24 23:00",
    "demand": 32654.70352,
    "solar_generated": 0,
    "wind_generated": 64.62976725,
    "marketplace_to_demand": 19.78258402,
    "grid_to_demand": 31923.28388,
    "govt_battery_charge": 31.75775845
  },
  {
    "timestamp": "07/11/24 0:00",
    "demand": 34791.24646,
    "solar_generated": 0,
    "wind_generated": 56.2064502,
    "marketplace_to_demand": 72.28111929,
    "grid_to_demand": 35664.36477,
    "govt_battery_charge": 42.47004725
  },
  {
    "timestamp": "07/11/24 1:00",
    "demand": 30082.29581,
    "solar_generated": 0,
    "wind_generated": 66.15536002,
    "marketplace_to_demand": 139.496893,
    "grid_to_demand": 33357.97628,
    "govt_battery_charge": 19.17593729
  },
  {
    "timestamp": "07/11/24 2:00",
    "demand": 35987.25726,
    "solar_generated": 0,
    "wind_generated": 63.13538184,
    "marketplace_to_demand": 134.6914867,
    "grid_to_demand": 33993.35233,
    "govt_battery_charge": 91.47786287
  },
  {
    "timestamp": "07/11/24 3:00",
    "demand": 36163.68151,
    "solar_generated": 0,
    "wind_generated": 55.5428245,
    "marketplace_to_demand": 80.0977418,
    "grid_to_demand": 35800.54287,
    "govt_battery_charge": 29.26749398
  },
  {
    "timestamp": "07/11/24 4:00",
    "demand": 30748.73268,
    "solar_generated": 0,
    "wind_generated": 54.68280415,
    "marketplace_to_demand": 126.9741029,
    "grid_to_demand": 34096.3588,
    "govt_battery_charge": 26.58098623
  },
  {
    "timestamp": "07/11/24 5:00",
    "demand": 34764.83616,
    "solar_generated": 0,
    "wind_generated": 55.60986672,
    "marketplace_to_demand": 106.2789703,
    "grid_to_demand": 30914.16867,
    "govt_battery_charge": 15.44081021
  },
  {
    "timestamp": "07/11/24 6:00",
    "demand": 32416.39101,
    "solar_generated": 21.26680316,
    "wind_generated": 69.08736905,
    "marketplace_to_demand": 72.31828178,
    "grid_to_demand": 32548.34545,
    "govt_battery_charge": 61.13560383
  },
  {
    "timestamp": "07/11/24 7:00",
    "demand": 35216.5032,
    "solar_generated": 21.06362227,
    "wind_generated": 59.03467539,
    "marketplace_to_demand": 53.25498822,
    "grid_to_demand": 33647.75284,
    "govt_battery_charge": 50.03422652
  },
  {
    "timestamp": "07/11/24 8:00",
    "demand": 35679.80914,
    "solar_generated": 6.91669859,
    "wind_generated": 66.97458282,
    "marketplace_to_demand": 55.31130932,
    "grid_to_demand": 30233.02819,
    "govt_battery_charge": 3.88317555
  },
  {
    "timestamp": "07/11/24 9:00",
    "demand": 32295.61427,
    "solar_generated": 20.1209387,
    "wind_generated": 59.28107013,
    "marketplace_to_demand": 123.4522353,
    "grid_to_demand": 32187.23367,
    "govt_battery_charge": 6.568423404
  },
  {
    "timestamp": "07/11/24 10:00",
    "demand": 31977.09442,
    "solar_generated": 6.872114635,
    "wind_generated": 66.11581901,
    "marketplace_to_demand": 156.0692361,
    "grid_to_demand": 32645.16503,
    "govt_battery_charge": 55.07154248
  },
  {
    "timestamp": "07/11/24 11:00",
    "demand": 30633.14586,
    "solar_generated": 15.38913276,
    "wind_generated": 63.26568966,
    "marketplace_to_demand": 3.747285661,
    "grid_to_demand": 35418.0633,
    "govt_battery_charge": 14.59674719
  },
  {
    "timestamp": "07/11/24 12:00",
    "demand": 31991.7946,
    "solar_generated": 16.3427041,
    "wind_generated": 70.41429141,
    "marketplace_to_demand": 50.53114076,
    "grid_to_demand": 31064.71659,
    "govt_battery_charge": 81.55172128
  },
  {
    "timestamp": "07/11/24 13:00",
    "demand": 32181.59233,
    "solar_generated": 18.47356338,
    "wind_generated": 61.30558097,
    "marketplace_to_demand": 2.370094906,
    "grid_to_demand": 33166.67833,
    "govt_battery_charge": 89.73324554
  },
  {
    "timestamp": "07/11/24 14:00",
    "demand": 30019.41021,
    "solar_generated": 12.96584358,
    "wind_generated": 56.88180201,
    "marketplace_to_demand": 95.49649234,
    "grid_to_demand": 32538.00851,
    "govt_battery_charge": 37.02641604
  },
  {
    "timestamp": "07/11/24 15:00",
    "demand": 33649.50126,
    "solar_generated": 22.04024787,
    "wind_generated": 69.3448825,
    "marketplace_to_demand": 178.8759008,
    "grid_to_demand": 34122.43325,
    "govt_battery_charge": 34.84573866
  },
  {
    "timestamp": "07/11/24 16:00",
    "demand": 30456.74789,
    "solar_generated": 13.78987513,
    "wind_generated": 58.12038926,
    "marketplace_to_demand": 64.10665468,
    "grid_to_demand": 34264.36362,
    "govt_battery_charge": 10.07314358
  },
  {
    "timestamp": "07/11/24 17:00",
    "demand": 31792.83335,
    "solar_generated": 9.635539843,
    "wind_generated": 64.70171616,
    "marketplace_to_demand": 190.6735099,
    "grid_to_demand": 33519.21844,
    "govt_battery_charge": 51.51390631
  },
  {
    "timestamp": "07/11/24 18:00",
    "demand": 30274.66131,
    "solar_generated": 0,
    "wind_generated": 61.91602628,
    "marketplace_to_demand": 152.7573899,
    "grid_to_demand": 32081.88113,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "07/11/24 19:00",
    "demand": 36151.24591,
    "solar_generated": 0,
    "wind_generated": 79.95920428,
    "marketplace_to_demand": 196.3547385,
    "grid_to_demand": 34436.52561,
    "govt_battery_charge": 7.69782735
  },
  {
    "timestamp": "07/11/24 20:00",
    "demand": 32403.61486,
    "solar_generated": 0,
    "wind_generated": 67.41758512,
    "marketplace_to_demand": 167.0319144,
    "grid_to_demand": 30904.83774,
    "govt_battery_charge": 81.92663889
  },
  {
    "timestamp": "07/11/24 21:00",
    "demand": 33190.12429,
    "solar_generated": 0,
    "wind_generated": 63.85526998,
    "marketplace_to_demand": 93.79438489,
    "grid_to_demand": 35682.43813,
    "govt_battery_charge": 35.18353438
  },
  {
    "timestamp": "07/11/24 22:00",
    "demand": 31021.89724,
    "solar_generated": 0,
    "wind_generated": 74.73776075,
    "marketplace_to_demand": 135.4808106,
    "grid_to_demand": 33862.87325,
    "govt_battery_charge": 69.16857314
  },
  {
    "timestamp": "07/11/24 23:00",
    "demand": 35916.12564,
    "solar_generated": 0,
    "wind_generated": 60.60301614,
    "marketplace_to_demand": 50.41384498,
    "grid_to_demand": 35673.16548,
    "govt_battery_charge": 63.00953035
  },
  {
    "timestamp": "08/11/24 0:00",
    "demand": 31073.43592,
    "solar_generated": 0,
    "wind_generated": 75.72804357,
    "marketplace_to_demand": 195.8062326,
    "grid_to_demand": 31363.20585,
    "govt_battery_charge": 92.41456923
  },
  {
    "timestamp": "08/11/24 1:00",
    "demand": 34483.73826,
    "solar_generated": 0,
    "wind_generated": 69.45714858,
    "marketplace_to_demand": 40.44395883,
    "grid_to_demand": 31445.80851,
    "govt_battery_charge": 15.35213993
  },
  {
    "timestamp": "08/11/24 2:00",
    "demand": 35022.70268,
    "solar_generated": 0,
    "wind_generated": 53.93505724,
    "marketplace_to_demand": 144.5260055,
    "grid_to_demand": 32065.4604,
    "govt_battery_charge": 18.72648913
  },
  {
    "timestamp": "08/11/24 3:00",
    "demand": 31216.40204,
    "solar_generated": 0,
    "wind_generated": 56.33170953,
    "marketplace_to_demand": 176.9566394,
    "grid_to_demand": 33625.59963,
    "govt_battery_charge": 10.55352284
  },
  {
    "timestamp": "08/11/24 4:00",
    "demand": 31215.52415,
    "solar_generated": 0,
    "wind_generated": 74.72981209,
    "marketplace_to_demand": 164.9163478,
    "grid_to_demand": 31114.20261,
    "govt_battery_charge": 79.14934324
  },
  {
    "timestamp": "08/11/24 5:00",
    "demand": 31468.76468,
    "solar_generated": 0,
    "wind_generated": 72.08186442,
    "marketplace_to_demand": 138.8957417,
    "grid_to_demand": 33332.00265,
    "govt_battery_charge": 31.31357606
  },
  {
    "timestamp": "08/11/24 6:00",
    "demand": 35397.87879,
    "solar_generated": 18.36398667,
    "wind_generated": 57.27458228,
    "marketplace_to_demand": 22.75993603,
    "grid_to_demand": 31954.11965,
    "govt_battery_charge": 94.36052121
  },
  {
    "timestamp": "08/11/24 7:00",
    "demand": 36200.98006,
    "solar_generated": 19.32391344,
    "wind_generated": 73.79744632,
    "marketplace_to_demand": 140.9347721,
    "grid_to_demand": 30811.72117,
    "govt_battery_charge": 42.74106527
  },
  {
    "timestamp": "08/11/24 8:00",
    "demand": 36524.77207,
    "solar_generated": 13.99642343,
    "wind_generated": 58.97985367,
    "marketplace_to_demand": 140.8456696,
    "grid_to_demand": 33764.35202,
    "govt_battery_charge": 48.39020508
  },
  {
    "timestamp": "08/11/24 9:00",
    "demand": 31514.73193,
    "solar_generated": 21.15049532,
    "wind_generated": 63.77957206,
    "marketplace_to_demand": 123.7139236,
    "grid_to_demand": 32394.82388,
    "govt_battery_charge": 16.07451017
  },
  {
    "timestamp": "08/11/24 10:00",
    "demand": 32751.05253,
    "solar_generated": 23.42047755,
    "wind_generated": 56.176161,
    "marketplace_to_demand": 131.0466975,
    "grid_to_demand": 34154.46939,
    "govt_battery_charge": 45.31646027
  },
  {
    "timestamp": "08/11/24 11:00",
    "demand": 34109.81422,
    "solar_generated": 23.68917772,
    "wind_generated": 59.05806453,
    "marketplace_to_demand": 46.61209282,
    "grid_to_demand": 30263.03463,
    "govt_battery_charge": 65.46426249
  },
  {
    "timestamp": "08/11/24 12:00",
    "demand": 35903.50313,
    "solar_generated": 6.815895583,
    "wind_generated": 66.29637421,
    "marketplace_to_demand": 17.03929971,
    "grid_to_demand": 33429.71703,
    "govt_battery_charge": 24.16572903
  },
  {
    "timestamp": "08/11/24 13:00",
    "demand": 30934.96811,
    "solar_generated": 15.51338471,
    "wind_generated": 78.33278523,
    "marketplace_to_demand": 120.5263958,
    "grid_to_demand": 34999.34213,
    "govt_battery_charge": 31.6545251
  },
  {
    "timestamp": "08/11/24 14:00",
    "demand": 30005.81363,
    "solar_generated": 10.19850594,
    "wind_generated": 67.2069639,
    "marketplace_to_demand": 114.8837222,
    "grid_to_demand": 31755.95288,
    "govt_battery_charge": 37.41511138
  },
  {
    "timestamp": "08/11/24 15:00",
    "demand": 31261.4231,
    "solar_generated": 19.09156415,
    "wind_generated": 68.04650779,
    "marketplace_to_demand": 8.634367076,
    "grid_to_demand": 30992.43485,
    "govt_battery_charge": 97.30079781
  },
  {
    "timestamp": "08/11/24 16:00",
    "demand": 32178.1163,
    "solar_generated": 14.38683295,
    "wind_generated": 66.18171235,
    "marketplace_to_demand": 114.2751022,
    "grid_to_demand": 32259.2654,
    "govt_battery_charge": 9.479244481
  },
  {
    "timestamp": "08/11/24 17:00",
    "demand": 31087.17227,
    "solar_generated": 10.16707117,
    "wind_generated": 66.82928242,
    "marketplace_to_demand": 98.62164321,
    "grid_to_demand": 35617.71368,
    "govt_battery_charge": 18.63882868
  },
  {
    "timestamp": "08/11/24 18:00",
    "demand": 30385.68498,
    "solar_generated": 0,
    "wind_generated": 53.99631263,
    "marketplace_to_demand": 163.093802,
    "grid_to_demand": 31588.93632,
    "govt_battery_charge": 85.06622047
  },
  {
    "timestamp": "08/11/24 19:00",
    "demand": 31887.20237,
    "solar_generated": 0,
    "wind_generated": 67.73278706,
    "marketplace_to_demand": 126.8115217,
    "grid_to_demand": 32513.72365,
    "govt_battery_charge": 89.11482338
  },
  {
    "timestamp": "08/11/24 20:00",
    "demand": 30951.08277,
    "solar_generated": 0,
    "wind_generated": 57.25583545,
    "marketplace_to_demand": 156.5953027,
    "grid_to_demand": 30867.27308,
    "govt_battery_charge": 18.95765338
  },
  {
    "timestamp": "08/11/24 21:00",
    "demand": 32989.58455,
    "solar_generated": 0,
    "wind_generated": 74.16583414,
    "marketplace_to_demand": 55.66290369,
    "grid_to_demand": 32275.67023,
    "govt_battery_charge": 94.40195741
  },
  {
    "timestamp": "08/11/24 22:00",
    "demand": 31862.73566,
    "solar_generated": 0,
    "wind_generated": 60.70845397,
    "marketplace_to_demand": 35.29847106,
    "grid_to_demand": 35189.4307,
    "govt_battery_charge": 2.637166687
  },
  {
    "timestamp": "08/11/24 23:00",
    "demand": 34940.43089,
    "solar_generated": 0,
    "wind_generated": 54.20922581,
    "marketplace_to_demand": 142.6556902,
    "grid_to_demand": 31263.32948,
    "govt_battery_charge": 29.29022851
  },
  {
    "timestamp": "09/11/24 0:00",
    "demand": 35874.95678,
    "solar_generated": 0,
    "wind_generated": 64.76809603,
    "marketplace_to_demand": 51.85609415,
    "grid_to_demand": 36067.44689,
    "govt_battery_charge": 2.885895659
  },
  {
    "timestamp": "09/11/24 1:00",
    "demand": 30137.37154,
    "solar_generated": 0,
    "wind_generated": 65.4258531,
    "marketplace_to_demand": 34.86122637,
    "grid_to_demand": 31119.77547,
    "govt_battery_charge": 50.71168685
  },
  {
    "timestamp": "09/11/24 2:00",
    "demand": 31030.10727,
    "solar_generated": 0,
    "wind_generated": 54.47784355,
    "marketplace_to_demand": 197.6371701,
    "grid_to_demand": 32638.37092,
    "govt_battery_charge": 72.03386593
  },
  {
    "timestamp": "09/11/24 3:00",
    "demand": 35783.16504,
    "solar_generated": 0,
    "wind_generated": 67.53366197,
    "marketplace_to_demand": 26.82523529,
    "grid_to_demand": 35464.13117,
    "govt_battery_charge": 2.974797022
  },
  {
    "timestamp": "09/11/24 4:00",
    "demand": 32715.01995,
    "solar_generated": 0,
    "wind_generated": 54.6352212,
    "marketplace_to_demand": 47.76334418,
    "grid_to_demand": 32847.99486,
    "govt_battery_charge": 51.90474586
  },
  {
    "timestamp": "09/11/24 5:00",
    "demand": 30698.16475,
    "solar_generated": 0,
    "wind_generated": 70.07720999,
    "marketplace_to_demand": 160.1760031,
    "grid_to_demand": 34338.02279,
    "govt_battery_charge": 33.54430309
  },
  {
    "timestamp": "09/11/24 6:00",
    "demand": 32695.79923,
    "solar_generated": 12.40131858,
    "wind_generated": 57.82812155,
    "marketplace_to_demand": 187.0393261,
    "grid_to_demand": 34202.53751,
    "govt_battery_charge": 20.39389526
  },
  {
    "timestamp": "09/11/24 7:00",
    "demand": 32693.80031,
    "solar_generated": 15.07719744,
    "wind_generated": 68.10757473,
    "marketplace_to_demand": 79.07077037,
    "grid_to_demand": 35047.80567,
    "govt_battery_charge": 23.17924221
  },
  {
    "timestamp": "09/11/24 8:00",
    "demand": 33693.37446,
    "solar_generated": 18.52547946,
    "wind_generated": 61.92254202,
    "marketplace_to_demand": 140.2032632,
    "grid_to_demand": 36201.32817,
    "govt_battery_charge": 44.01398582
  },
  {
    "timestamp": "09/11/24 9:00",
    "demand": 31633.40133,
    "solar_generated": 18.09458615,
    "wind_generated": 58.3229943,
    "marketplace_to_demand": 15.6362791,
    "grid_to_demand": 36258.63978,
    "govt_battery_charge": 61.63171713
  },
  {
    "timestamp": "09/11/24 10:00",
    "demand": 33325.81157,
    "solar_generated": 21.51818419,
    "wind_generated": 64.40358964,
    "marketplace_to_demand": 39.28149272,
    "grid_to_demand": 32530.97818,
    "govt_battery_charge": 98.78669121
  },
  {
    "timestamp": "09/11/24 11:00",
    "demand": 31714.43451,
    "solar_generated": 12.11076483,
    "wind_generated": 71.17440275,
    "marketplace_to_demand": 150.6902602,
    "grid_to_demand": 31253.58428,
    "govt_battery_charge": 37.11341031
  },
  {
    "timestamp": "09/11/24 12:00",
    "demand": 30037.79772,
    "solar_generated": 19.81435152,
    "wind_generated": 76.36979262,
    "marketplace_to_demand": 149.8051235,
    "grid_to_demand": 31517.75958,
    "govt_battery_charge": 24.81446816
  },
  {
    "timestamp": "09/11/24 13:00",
    "demand": 34272.47274,
    "solar_generated": 14.62373684,
    "wind_generated": 60.38068521,
    "marketplace_to_demand": 77.77771348,
    "grid_to_demand": 33920.89795,
    "govt_battery_charge": 44.88989968
  },
  {
    "timestamp": "09/11/24 14:00",
    "demand": 31444.42599,
    "solar_generated": 19.77858488,
    "wind_generated": 76.66276067,
    "marketplace_to_demand": 183.9547747,
    "grid_to_demand": 33486.3628,
    "govt_battery_charge": 68.09416706
  },
  {
    "timestamp": "09/11/24 15:00",
    "demand": 30565.90773,
    "solar_generated": 17.61614009,
    "wind_generated": 68.88677429,
    "marketplace_to_demand": 61.18325171,
    "grid_to_demand": 32254.4942,
    "govt_battery_charge": 3.664332696
  },
  {
    "timestamp": "09/11/24 16:00",
    "demand": 31009.71157,
    "solar_generated": 23.39035643,
    "wind_generated": 60.66003582,
    "marketplace_to_demand": 69.87247357,
    "grid_to_demand": 36011.13268,
    "govt_battery_charge": 47.91124111
  },
  {
    "timestamp": "09/11/24 17:00",
    "demand": 31191.44833,
    "solar_generated": 13.9089454,
    "wind_generated": 67.05734582,
    "marketplace_to_demand": 14.73801637,
    "grid_to_demand": 33755.33424,
    "govt_battery_charge": 6.813248237
  },
  {
    "timestamp": "09/11/24 18:00",
    "demand": 35275.99076,
    "solar_generated": 0,
    "wind_generated": 73.34926706,
    "marketplace_to_demand": 158.3343868,
    "grid_to_demand": 35809.60484,
    "govt_battery_charge": 17.12645024
  },
  {
    "timestamp": "09/11/24 19:00",
    "demand": 35923.77616,
    "solar_generated": 0,
    "wind_generated": 57.65446555,
    "marketplace_to_demand": 131.309127,
    "grid_to_demand": 36440.38189,
    "govt_battery_charge": 96.15566287
  },
  {
    "timestamp": "09/11/24 20:00",
    "demand": 35119.5713,
    "solar_generated": 0,
    "wind_generated": 58.17254183,
    "marketplace_to_demand": 114.9455683,
    "grid_to_demand": 32910.78489,
    "govt_battery_charge": 67.40308303
  },
  {
    "timestamp": "09/11/24 21:00",
    "demand": 30013.12794,
    "solar_generated": 0,
    "wind_generated": 60.03160139,
    "marketplace_to_demand": 45.12929424,
    "grid_to_demand": 34298.66703,
    "govt_battery_charge": 77.16007227
  },
  {
    "timestamp": "09/11/24 22:00",
    "demand": 34664.45665,
    "solar_generated": 0,
    "wind_generated": 56.14187129,
    "marketplace_to_demand": 2.628801247,
    "grid_to_demand": 32269.27492,
    "govt_battery_charge": 77.77528836
  },
  {
    "timestamp": "09/11/24 23:00",
    "demand": 33359.95493,
    "solar_generated": 0,
    "wind_generated": 74.57514512,
    "marketplace_to_demand": 71.96851921,
    "grid_to_demand": 34659.91301,
    "govt_battery_charge": 83.02290022
  },
  {
    "timestamp": "10/11/24 0:00",
    "demand": 30694.9474,
    "solar_generated": 0,
    "wind_generated": 79.92425892,
    "marketplace_to_demand": 161.9299263,
    "grid_to_demand": 32634.60486,
    "govt_battery_charge": 39.62617327
  },
  {
    "timestamp": "10/11/24 1:00",
    "demand": 34152.25112,
    "solar_generated": 0,
    "wind_generated": 78.38718282,
    "marketplace_to_demand": 188.4733756,
    "grid_to_demand": 33251.57046,
    "govt_battery_charge": 22.28544544
  },
  {
    "timestamp": "10/11/24 2:00",
    "demand": 33487.00008,
    "solar_generated": 0,
    "wind_generated": 68.67975536,
    "marketplace_to_demand": 193.9385744,
    "grid_to_demand": 31492.44647,
    "govt_battery_charge": 72.02630547
  },
  {
    "timestamp": "10/11/24 3:00",
    "demand": 31955.59171,
    "solar_generated": 0,
    "wind_generated": 54.08530256,
    "marketplace_to_demand": 107.5632587,
    "grid_to_demand": 31735.01645,
    "govt_battery_charge": 59.842258
  },
  {
    "timestamp": "10/11/24 4:00",
    "demand": 31352.28438,
    "solar_generated": 0,
    "wind_generated": 75.25022494,
    "marketplace_to_demand": 171.9315618,
    "grid_to_demand": 29964.41472,
    "govt_battery_charge": 82.35999321
  },
  {
    "timestamp": "10/11/24 5:00",
    "demand": 35993.07023,
    "solar_generated": 0,
    "wind_generated": 75.36604726,
    "marketplace_to_demand": 66.59853009,
    "grid_to_demand": 33921.91459,
    "govt_battery_charge": 62.21138497
  },
  {
    "timestamp": "10/11/24 6:00",
    "demand": 33116.7284,
    "solar_generated": 19.71251013,
    "wind_generated": 73.57352118,
    "marketplace_to_demand": 10.35596674,
    "grid_to_demand": 34440.59848,
    "govt_battery_charge": 81.76707953
  },
  {
    "timestamp": "10/11/24 7:00",
    "demand": 36242.142,
    "solar_generated": 15.97088465,
    "wind_generated": 62.44307187,
    "marketplace_to_demand": 139.0033322,
    "grid_to_demand": 33783.68923,
    "govt_battery_charge": 20.52815153
  },
  {
    "timestamp": "10/11/24 8:00",
    "demand": 32366.61757,
    "solar_generated": 11.42356,
    "wind_generated": 64.99856791,
    "marketplace_to_demand": 14.36841344,
    "grid_to_demand": 34539.88519,
    "govt_battery_charge": 64.4179485
  },
  {
    "timestamp": "10/11/24 9:00",
    "demand": 30278.23981,
    "solar_generated": 13.70954677,
    "wind_generated": 54.05244859,
    "marketplace_to_demand": 24.90667317,
    "grid_to_demand": 30766.73785,
    "govt_battery_charge": 11.93652802
  },
  {
    "timestamp": "10/11/24 10:00",
    "demand": 33957.34004,
    "solar_generated": 16.66068895,
    "wind_generated": 66.59194454,
    "marketplace_to_demand": 46.9584616,
    "grid_to_demand": 34163.19298,
    "govt_battery_charge": 57.24842837
  },
  {
    "timestamp": "10/11/24 11:00",
    "demand": 36455.70819,
    "solar_generated": 20.92213966,
    "wind_generated": 61.95589059,
    "marketplace_to_demand": 68.94745874,
    "grid_to_demand": 32598.32434,
    "govt_battery_charge": 33.24461519
  },
  {
    "timestamp": "10/11/24 12:00",
    "demand": 36401.02156,
    "solar_generated": 8.523535676,
    "wind_generated": 75.28228919,
    "marketplace_to_demand": 57.49988057,
    "grid_to_demand": 34449.62624,
    "govt_battery_charge": 43.65300503
  },
  {
    "timestamp": "10/11/24 13:00",
    "demand": 30086.65915,
    "solar_generated": 21.81921306,
    "wind_generated": 54.88058145,
    "marketplace_to_demand": 33.11051837,
    "grid_to_demand": 30809.71593,
    "govt_battery_charge": 33.81808885
  },
  {
    "timestamp": "10/11/24 14:00",
    "demand": 32997.8654,
    "solar_generated": 7.472392469,
    "wind_generated": 66.49846163,
    "marketplace_to_demand": 33.17187043,
    "grid_to_demand": 33811.01554,
    "govt_battery_charge": 80.52700787
  },
  {
    "timestamp": "10/11/24 15:00",
    "demand": 36060.34787,
    "solar_generated": 13.92114673,
    "wind_generated": 54.11833434,
    "marketplace_to_demand": 189.1782121,
    "grid_to_demand": 35723.91305,
    "govt_battery_charge": 47.69698202
  },
  {
    "timestamp": "10/11/24 16:00",
    "demand": 31584.24126,
    "solar_generated": 11.85451289,
    "wind_generated": 66.12137543,
    "marketplace_to_demand": 157.3782393,
    "grid_to_demand": 35166.82173,
    "govt_battery_charge": 1.848687911
  },
  {
    "timestamp": "10/11/24 17:00",
    "demand": 30859.28873,
    "solar_generated": 12.03685161,
    "wind_generated": 72.79767029,
    "marketplace_to_demand": 38.50939332,
    "grid_to_demand": 30672.81323,
    "govt_battery_charge": 57.88691561
  },
  {
    "timestamp": "10/11/24 18:00",
    "demand": 34237.16415,
    "solar_generated": 0,
    "wind_generated": 61.79962323,
    "marketplace_to_demand": 112.423684,
    "grid_to_demand": 34648.3459,
    "govt_battery_charge": 34.43032738
  },
  {
    "timestamp": "10/11/24 19:00",
    "demand": 32838.40295,
    "solar_generated": 0,
    "wind_generated": 66.43743357,
    "marketplace_to_demand": 189.0569782,
    "grid_to_demand": 32924.32659,
    "govt_battery_charge": 52.62813292
  },
  {
    "timestamp": "10/11/24 20:00",
    "demand": 34257.74308,
    "solar_generated": 0,
    "wind_generated": 56.20525818,
    "marketplace_to_demand": 196.5275828,
    "grid_to_demand": 30834.89121,
    "govt_battery_charge": 13.04989196
  },
  {
    "timestamp": "10/11/24 21:00",
    "demand": 36532.09695,
    "solar_generated": 0,
    "wind_generated": 60.58718692,
    "marketplace_to_demand": 46.61346505,
    "grid_to_demand": 30708.40629,
    "govt_battery_charge": 52.59216685
  },
  {
    "timestamp": "10/11/24 22:00",
    "demand": 35801.01455,
    "solar_generated": 0,
    "wind_generated": 66.1046314,
    "marketplace_to_demand": 66.66496718,
    "grid_to_demand": 34513.5266,
    "govt_battery_charge": 12.20800491
  },
  {
    "timestamp": "10/11/24 23:00",
    "demand": 30145.83772,
    "solar_generated": 0,
    "wind_generated": 54.44478127,
    "marketplace_to_demand": 36.06533964,
    "grid_to_demand": 32895.74919,
    "govt_battery_charge": 72.94718438
  },
  {
    "timestamp": "11/11/24 0:00",
    "demand": 31042.15187,
    "solar_generated": 0,
    "wind_generated": 65.39786533,
    "marketplace_to_demand": 56.23085226,
    "grid_to_demand": 30868.40138,
    "govt_battery_charge": 34.49047644
  },
  {
    "timestamp": "11/11/24 1:00",
    "demand": 34950.83101,
    "solar_generated": 0,
    "wind_generated": 75.73367637,
    "marketplace_to_demand": 167.8491957,
    "grid_to_demand": 32116.24674,
    "govt_battery_charge": 26.24667269
  },
  {
    "timestamp": "11/11/24 2:00",
    "demand": 36337.54342,
    "solar_generated": 0,
    "wind_generated": 58.74506887,
    "marketplace_to_demand": 24.87135081,
    "grid_to_demand": 33749.24451,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "11/11/24 3:00",
    "demand": 31219.365,
    "solar_generated": 0,
    "wind_generated": 78.23972349,
    "marketplace_to_demand": 191.8325841,
    "grid_to_demand": 32819.44782,
    "govt_battery_charge": 78.98011884
  },
  {
    "timestamp": "11/11/24 4:00",
    "demand": 30794.63391,
    "solar_generated": 0,
    "wind_generated": 69.54776484,
    "marketplace_to_demand": 102.217105,
    "grid_to_demand": 32620.41027,
    "govt_battery_charge": 14.54577069
  },
  {
    "timestamp": "11/11/24 5:00",
    "demand": 30364.06855,
    "solar_generated": 0,
    "wind_generated": 64.4577879,
    "marketplace_to_demand": 141.9499425,
    "grid_to_demand": 30712.67205,
    "govt_battery_charge": 50.01889582
  },
  {
    "timestamp": "11/11/24 6:00",
    "demand": 31262.92779,
    "solar_generated": 19.51599386,
    "wind_generated": 79.94406138,
    "marketplace_to_demand": 101.4300321,
    "grid_to_demand": 30468.90777,
    "govt_battery_charge": 31.25807575
  },
  {
    "timestamp": "11/11/24 7:00",
    "demand": 32561.52017,
    "solar_generated": 7.096171258,
    "wind_generated": 60.97175501,
    "marketplace_to_demand": 58.23466139,
    "grid_to_demand": 35835.06038,
    "govt_battery_charge": 63.75696891
  },
  {
    "timestamp": "11/11/24 8:00",
    "demand": 32782.57562,
    "solar_generated": 18.54583239,
    "wind_generated": 65.43771541,
    "marketplace_to_demand": 132.0031291,
    "grid_to_demand": 35563.53199,
    "govt_battery_charge": 43.46756509
  },
  {
    "timestamp": "11/11/24 9:00",
    "demand": 33095.76836,
    "solar_generated": 21.27968076,
    "wind_generated": 59.48169504,
    "marketplace_to_demand": 57.59141851,
    "grid_to_demand": 35681.87634,
    "govt_battery_charge": 86.71145269
  },
  {
    "timestamp": "11/11/24 10:00",
    "demand": 35876.42874,
    "solar_generated": 15.20445944,
    "wind_generated": 79.17707752,
    "marketplace_to_demand": 125.1935974,
    "grid_to_demand": 35613.57574,
    "govt_battery_charge": 27.76392942
  },
  {
    "timestamp": "11/11/24 11:00",
    "demand": 33564.96364,
    "solar_generated": 18.50131581,
    "wind_generated": 70.70552756,
    "marketplace_to_demand": 153.9469378,
    "grid_to_demand": 35239.56189,
    "govt_battery_charge": 73.93689027
  },
  {
    "timestamp": "11/11/24 12:00",
    "demand": 30308.06341,
    "solar_generated": 13.83178181,
    "wind_generated": 55.63543944,
    "marketplace_to_demand": 6.061836459,
    "grid_to_demand": 33096.57034,
    "govt_battery_charge": 69.40640086
  },
  {
    "timestamp": "11/11/24 13:00",
    "demand": 36426.48196,
    "solar_generated": 17.56930612,
    "wind_generated": 57.36784393,
    "marketplace_to_demand": 143.6750711,
    "grid_to_demand": 32045.75148,
    "govt_battery_charge": 70.76739701
  },
  {
    "timestamp": "11/11/24 14:00",
    "demand": 31842.06083,
    "solar_generated": 18.23387394,
    "wind_generated": 66.85905801,
    "marketplace_to_demand": 103.471675,
    "grid_to_demand": 33134.06629,
    "govt_battery_charge": 79.74146722
  },
  {
    "timestamp": "11/11/24 15:00",
    "demand": 30343.84777,
    "solar_generated": 12.60662352,
    "wind_generated": 68.46674115,
    "marketplace_to_demand": 91.10846389,
    "grid_to_demand": 32348.57344,
    "govt_battery_charge": 77.0582592
  },
  {
    "timestamp": "11/11/24 16:00",
    "demand": 35993.16915,
    "solar_generated": 8.124360282,
    "wind_generated": 68.60599702,
    "marketplace_to_demand": 199.8054559,
    "grid_to_demand": 35067.93718,
    "govt_battery_charge": 17.54460107
  },
  {
    "timestamp": "11/11/24 17:00",
    "demand": 35674.97473,
    "solar_generated": 17.84463064,
    "wind_generated": 68.66771254,
    "marketplace_to_demand": 58.92289358,
    "grid_to_demand": 32763.16095,
    "govt_battery_charge": 40.30550903
  },
  {
    "timestamp": "11/11/24 18:00",
    "demand": 32364.98401,
    "solar_generated": 0,
    "wind_generated": 74.604506,
    "marketplace_to_demand": 10.94080155,
    "grid_to_demand": 31867.32247,
    "govt_battery_charge": 68.8011342
  },
  {
    "timestamp": "11/11/24 19:00",
    "demand": 33230.6011,
    "solar_generated": 0,
    "wind_generated": 67.0080479,
    "marketplace_to_demand": 134.9435539,
    "grid_to_demand": 30901.19727,
    "govt_battery_charge": 49.63131797
  },
  {
    "timestamp": "11/11/24 20:00",
    "demand": 33179.41609,
    "solar_generated": 0,
    "wind_generated": 72.59001375,
    "marketplace_to_demand": 188.1619968,
    "grid_to_demand": 30264.5698,
    "govt_battery_charge": 20.21931969
  },
  {
    "timestamp": "11/11/24 21:00",
    "demand": 36398.747,
    "solar_generated": 0,
    "wind_generated": 65.0176545,
    "marketplace_to_demand": 18.17066517,
    "grid_to_demand": 32183.2623,
    "govt_battery_charge": 60.17520337
  },
  {
    "timestamp": "11/11/24 22:00",
    "demand": 33183.90378,
    "solar_generated": 0,
    "wind_generated": 62.87059173,
    "marketplace_to_demand": 21.26051675,
    "grid_to_demand": 35713.64825,
    "govt_battery_charge": 27.37830569
  },
  {
    "timestamp": "11/11/24 23:00",
    "demand": 32872.09516,
    "solar_generated": 0,
    "wind_generated": 58.55668132,
    "marketplace_to_demand": 157.7526557,
    "grid_to_demand": 31279.57842,
    "govt_battery_charge": 33.44199141
  },
  {
    "timestamp": "12/11/24 0:00",
    "demand": 30351.48102,
    "solar_generated": 0,
    "wind_generated": 74.24973924,
    "marketplace_to_demand": 112.8645676,
    "grid_to_demand": 34059.71628,
    "govt_battery_charge": 49.13538594
  },
  {
    "timestamp": "12/11/24 1:00",
    "demand": 31218.11935,
    "solar_generated": 0,
    "wind_generated": 71.50017068,
    "marketplace_to_demand": 48.40552653,
    "grid_to_demand": 33756.55513,
    "govt_battery_charge": 63.2971444
  },
  {
    "timestamp": "12/11/24 2:00",
    "demand": 31702.62919,
    "solar_generated": 0,
    "wind_generated": 53.88174278,
    "marketplace_to_demand": 41.11531244,
    "grid_to_demand": 34512.22471,
    "govt_battery_charge": 49.45378015
  },
  {
    "timestamp": "12/11/24 3:00",
    "demand": 30885.54504,
    "solar_generated": 0,
    "wind_generated": 57.50374302,
    "marketplace_to_demand": 187.0597038,
    "grid_to_demand": 33160.68195,
    "govt_battery_charge": 32.81066751
  },
  {
    "timestamp": "12/11/24 4:00",
    "demand": 31979.76581,
    "solar_generated": 0,
    "wind_generated": 63.78252802,
    "marketplace_to_demand": 42.60204429,
    "grid_to_demand": 34751.18928,
    "govt_battery_charge": 21.09116648
  },
  {
    "timestamp": "12/11/24 5:00",
    "demand": 35317.52241,
    "solar_generated": 0,
    "wind_generated": 55.61428682,
    "marketplace_to_demand": 32.7419224,
    "grid_to_demand": 35686.06137,
    "govt_battery_charge": 52.13020642
  },
  {
    "timestamp": "12/11/24 6:00",
    "demand": 32371.13433,
    "solar_generated": 17.85836274,
    "wind_generated": 74.27683296,
    "marketplace_to_demand": 52.77956026,
    "grid_to_demand": 33852.9931,
    "govt_battery_charge": 41.62977244
  },
  {
    "timestamp": "12/11/24 7:00",
    "demand": 31263.72852,
    "solar_generated": 6.771187289,
    "wind_generated": 58.87536622,
    "marketplace_to_demand": 126.3209898,
    "grid_to_demand": 34581.27247,
    "govt_battery_charge": 89.90008888
  },
  {
    "timestamp": "12/11/24 8:00",
    "demand": 31954.66965,
    "solar_generated": 10.92442164,
    "wind_generated": 70.16976595,
    "marketplace_to_demand": 141.5052477,
    "grid_to_demand": 31325.37695,
    "govt_battery_charge": 67.74808787
  },
  {
    "timestamp": "12/11/24 9:00",
    "demand": 32331.69969,
    "solar_generated": 18.67638409,
    "wind_generated": 77.2175228,
    "marketplace_to_demand": 86.284607,
    "grid_to_demand": 34477.16651,
    "govt_battery_charge": 10.53990414
  },
  {
    "timestamp": "12/11/24 10:00",
    "demand": 33377.10828,
    "solar_generated": 17.87231301,
    "wind_generated": 66.0284753,
    "marketplace_to_demand": 6.044636369,
    "grid_to_demand": 31529.09719,
    "govt_battery_charge": 84.08276528
  },
  {
    "timestamp": "12/11/24 11:00",
    "demand": 31402.78446,
    "solar_generated": 15.4071253,
    "wind_generated": 67.24344596,
    "marketplace_to_demand": 50.55366066,
    "grid_to_demand": 33550.48428,
    "govt_battery_charge": 89.53439523
  },
  {
    "timestamp": "12/11/24 12:00",
    "demand": 31356.27523,
    "solar_generated": 22.25117318,
    "wind_generated": 74.23656833,
    "marketplace_to_demand": 85.46787979,
    "grid_to_demand": 34527.44728,
    "govt_battery_charge": 42.73614426
  },
  {
    "timestamp": "12/11/24 13:00",
    "demand": 33805.90715,
    "solar_generated": 11.79789784,
    "wind_generated": 57.89695975,
    "marketplace_to_demand": 39.15053126,
    "grid_to_demand": 35014.86121,
    "govt_battery_charge": 54.46632224
  },
  {
    "timestamp": "12/11/24 14:00",
    "demand": 35246.41105,
    "solar_generated": 17.01741234,
    "wind_generated": 78.69871238,
    "marketplace_to_demand": 121.4008222,
    "grid_to_demand": 33235.64837,
    "govt_battery_charge": 74.18367523
  },
  {
    "timestamp": "12/11/24 15:00",
    "demand": 34663.26159,
    "solar_generated": 11.26429594,
    "wind_generated": 80.17814455,
    "marketplace_to_demand": 3.060142267,
    "grid_to_demand": 32277.45188,
    "govt_battery_charge": 96.20387179
  },
  {
    "timestamp": "12/11/24 16:00",
    "demand": 30691.50351,
    "solar_generated": 8.420844535,
    "wind_generated": 76.0366205,
    "marketplace_to_demand": 160.8683791,
    "grid_to_demand": 36357.05519,
    "govt_battery_charge": 88.06259206
  },
  {
    "timestamp": "12/11/24 17:00",
    "demand": 30807.88377,
    "solar_generated": 7.139132285,
    "wind_generated": 60.27541957,
    "marketplace_to_demand": 134.8010263,
    "grid_to_demand": 31508.18585,
    "govt_battery_charge": 71.22161978
  },
  {
    "timestamp": "12/11/24 18:00",
    "demand": 36446.40499,
    "solar_generated": 0,
    "wind_generated": 69.14131073,
    "marketplace_to_demand": 95.07833713,
    "grid_to_demand": 31443.02037,
    "govt_battery_charge": 40.00955372
  },
  {
    "timestamp": "12/11/24 19:00",
    "demand": 31692.85712,
    "solar_generated": 0,
    "wind_generated": 79.2211048,
    "marketplace_to_demand": 52.11101497,
    "grid_to_demand": 32784.57429,
    "govt_battery_charge": 47.94952607
  },
  {
    "timestamp": "12/11/24 20:00",
    "demand": 30283.21691,
    "solar_generated": 0,
    "wind_generated": 61.74682151,
    "marketplace_to_demand": 7.283509747,
    "grid_to_demand": 34519.81663,
    "govt_battery_charge": 79.92763631
  },
  {
    "timestamp": "12/11/24 21:00",
    "demand": 35841.29093,
    "solar_generated": 0,
    "wind_generated": 67.9929835,
    "marketplace_to_demand": 67.17563958,
    "grid_to_demand": 31431.61252,
    "govt_battery_charge": 6.104300922
  },
  {
    "timestamp": "12/11/24 22:00",
    "demand": 31784.08979,
    "solar_generated": 0,
    "wind_generated": 69.95619216,
    "marketplace_to_demand": 193.9515021,
    "grid_to_demand": 35456.0491,
    "govt_battery_charge": 48.49889912
  },
  {
    "timestamp": "12/11/24 23:00",
    "demand": 32008.36102,
    "solar_generated": 0,
    "wind_generated": 68.67981526,
    "marketplace_to_demand": 37.29146006,
    "grid_to_demand": 30653.74746,
    "govt_battery_charge": 39.27654961
  },
  {
    "timestamp": "13/11/24 0:00",
    "demand": 33986.79066,
    "solar_generated": 0,
    "wind_generated": 62.51221879,
    "marketplace_to_demand": 38.5033738,
    "grid_to_demand": 34209.32528,
    "govt_battery_charge": 47.43880218
  },
  {
    "timestamp": "13/11/24 1:00",
    "demand": 32725.42772,
    "solar_generated": 0,
    "wind_generated": 69.60957891,
    "marketplace_to_demand": 94.01113683,
    "grid_to_demand": 33952.58108,
    "govt_battery_charge": 59.35458057
  },
  {
    "timestamp": "13/11/24 2:00",
    "demand": 32239.72386,
    "solar_generated": 0,
    "wind_generated": 68.37074153,
    "marketplace_to_demand": 160.0397207,
    "grid_to_demand": 32338.8747,
    "govt_battery_charge": 74.05640009
  },
  {
    "timestamp": "13/11/24 3:00",
    "demand": 33755.56789,
    "solar_generated": 0,
    "wind_generated": 62.31965296,
    "marketplace_to_demand": 182.7320066,
    "grid_to_demand": 35950.6728,
    "govt_battery_charge": 65.23765467
  },
  {
    "timestamp": "13/11/24 4:00",
    "demand": 34934.90114,
    "solar_generated": 0,
    "wind_generated": 59.75993581,
    "marketplace_to_demand": 132.0798733,
    "grid_to_demand": 31878.9784,
    "govt_battery_charge": 3.196973924
  },
  {
    "timestamp": "13/11/24 5:00",
    "demand": 32988.19195,
    "solar_generated": 0,
    "wind_generated": 55.15297271,
    "marketplace_to_demand": 65.07874329,
    "grid_to_demand": 33352.40074,
    "govt_battery_charge": 92.08621041
  },
  {
    "timestamp": "13/11/24 6:00",
    "demand": 34834.48949,
    "solar_generated": 10.74337006,
    "wind_generated": 64.57660857,
    "marketplace_to_demand": 96.30562215,
    "grid_to_demand": 30350.69665,
    "govt_battery_charge": 60.44803361
  },
  {
    "timestamp": "13/11/24 7:00",
    "demand": 35686.40738,
    "solar_generated": 10.08231438,
    "wind_generated": 64.8501135,
    "marketplace_to_demand": 69.24723766,
    "grid_to_demand": 31582.19279,
    "govt_battery_charge": 87.44328787
  },
  {
    "timestamp": "13/11/24 8:00",
    "demand": 36234.98237,
    "solar_generated": 10.61882181,
    "wind_generated": 62.00548528,
    "marketplace_to_demand": 161.0875257,
    "grid_to_demand": 30885.21464,
    "govt_battery_charge": 81.79330015
  },
  {
    "timestamp": "13/11/24 9:00",
    "demand": 34979.88096,
    "solar_generated": 15.80524535,
    "wind_generated": 63.39155595,
    "marketplace_to_demand": 3.111267267,
    "grid_to_demand": 31226.80088,
    "govt_battery_charge": 30.60816494
  },
  {
    "timestamp": "13/11/24 10:00",
    "demand": 34454.89024,
    "solar_generated": 19.30577249,
    "wind_generated": 75.17446165,
    "marketplace_to_demand": 43.35645302,
    "grid_to_demand": 34154.71645,
    "govt_battery_charge": 28.51858378
  },
  {
    "timestamp": "13/11/24 11:00",
    "demand": 34874.58775,
    "solar_generated": 20.65585145,
    "wind_generated": 79.98433748,
    "marketplace_to_demand": 92.73732791,
    "grid_to_demand": 35266.58464,
    "govt_battery_charge": 6.254252088
  },
  {
    "timestamp": "13/11/24 12:00",
    "demand": 32007.68046,
    "solar_generated": 12.05674228,
    "wind_generated": 79.1670874,
    "marketplace_to_demand": 91.63599933,
    "grid_to_demand": 30771.94712,
    "govt_battery_charge": 36.59145746
  },
  {
    "timestamp": "13/11/24 13:00",
    "demand": 33355.97712,
    "solar_generated": 8.320719571,
    "wind_generated": 80.3585459,
    "marketplace_to_demand": 63.84913822,
    "grid_to_demand": 31891.18708,
    "govt_battery_charge": 3.486450465
  },
  {
    "timestamp": "13/11/24 14:00",
    "demand": 33358.89083,
    "solar_generated": 11.73190336,
    "wind_generated": 63.28361705,
    "marketplace_to_demand": 96.93191532,
    "grid_to_demand": 36235.45576,
    "govt_battery_charge": 48.05575848
  },
  {
    "timestamp": "13/11/24 15:00",
    "demand": 31564.26236,
    "solar_generated": 21.78110293,
    "wind_generated": 60.80224319,
    "marketplace_to_demand": 32.57267472,
    "grid_to_demand": 32558.51563,
    "govt_battery_charge": 43.6257131
  },
  {
    "timestamp": "13/11/24 16:00",
    "demand": 34999.05769,
    "solar_generated": 7.449730617,
    "wind_generated": 58.08003565,
    "marketplace_to_demand": 96.56391467,
    "grid_to_demand": 32158.52485,
    "govt_battery_charge": 36.66727342
  },
  {
    "timestamp": "13/11/24 17:00",
    "demand": 30942.90835,
    "solar_generated": 7.243574968,
    "wind_generated": 56.42489324,
    "marketplace_to_demand": 69.91043251,
    "grid_to_demand": 31675.84256,
    "govt_battery_charge": 39.19017294
  },
  {
    "timestamp": "13/11/24 18:00",
    "demand": 33600.93894,
    "solar_generated": 0,
    "wind_generated": 63.43435466,
    "marketplace_to_demand": 42.96645559,
    "grid_to_demand": 35089.18565,
    "govt_battery_charge": 97.65200895
  },
  {
    "timestamp": "13/11/24 19:00",
    "demand": 34728.62576,
    "solar_generated": 0,
    "wind_generated": 70.17700176,
    "marketplace_to_demand": 75.55229777,
    "grid_to_demand": 35366.57378,
    "govt_battery_charge": 20.92364555
  },
  {
    "timestamp": "13/11/24 20:00",
    "demand": 34571.90436,
    "solar_generated": 0,
    "wind_generated": 77.1331815,
    "marketplace_to_demand": 39.73071221,
    "grid_to_demand": 36027.27614,
    "govt_battery_charge": 36.3258532
  },
  {
    "timestamp": "13/11/24 21:00",
    "demand": 34043.24826,
    "solar_generated": 0,
    "wind_generated": 67.69090543,
    "marketplace_to_demand": 71.09955042,
    "grid_to_demand": 31847.82063,
    "govt_battery_charge": 12.82279301
  },
  {
    "timestamp": "13/11/24 22:00",
    "demand": 35461.15597,
    "solar_generated": 0,
    "wind_generated": 55.36473946,
    "marketplace_to_demand": 126.2334488,
    "grid_to_demand": 30296.86517,
    "govt_battery_charge": 57.77486129
  },
  {
    "timestamp": "13/11/24 23:00",
    "demand": 30152.17169,
    "solar_generated": 0,
    "wind_generated": 59.64639717,
    "marketplace_to_demand": 30.03815287,
    "grid_to_demand": 32748.4718,
    "govt_battery_charge": 15.52577613
  },
  {
    "timestamp": "14/11/24 0:00",
    "demand": 35475.76939,
    "solar_generated": 0,
    "wind_generated": 57.43743581,
    "marketplace_to_demand": 76.37061313,
    "grid_to_demand": 36021.10704,
    "govt_battery_charge": 79.67953582
  },
  {
    "timestamp": "14/11/24 1:00",
    "demand": 31856.71883,
    "solar_generated": 0,
    "wind_generated": 78.30391685,
    "marketplace_to_demand": 169.7021805,
    "grid_to_demand": 36237.01217,
    "govt_battery_charge": 21.89815125
  },
  {
    "timestamp": "14/11/24 2:00",
    "demand": 33862.67614,
    "solar_generated": 0,
    "wind_generated": 77.94479077,
    "marketplace_to_demand": 145.8383598,
    "grid_to_demand": 32432.33305,
    "govt_battery_charge": 26.01086238
  },
  {
    "timestamp": "14/11/24 3:00",
    "demand": 31508.85778,
    "solar_generated": 0,
    "wind_generated": 63.92414954,
    "marketplace_to_demand": 58.79000658,
    "grid_to_demand": 30483.42788,
    "govt_battery_charge": 74.40095199
  },
  {
    "timestamp": "14/11/24 4:00",
    "demand": 31572.63314,
    "solar_generated": 0,
    "wind_generated": 57.44064289,
    "marketplace_to_demand": 118.5072231,
    "grid_to_demand": 32684.3567,
    "govt_battery_charge": 65.04514736
  },
  {
    "timestamp": "14/11/24 5:00",
    "demand": 31397.55825,
    "solar_generated": 0,
    "wind_generated": 59.83948026,
    "marketplace_to_demand": 17.72546845,
    "grid_to_demand": 31651.77035,
    "govt_battery_charge": 36.04032473
  },
  {
    "timestamp": "14/11/24 6:00",
    "demand": 31032.92027,
    "solar_generated": 21.66461171,
    "wind_generated": 59.24861703,
    "marketplace_to_demand": 90.7705952,
    "grid_to_demand": 35285.10521,
    "govt_battery_charge": 12.52470528
  },
  {
    "timestamp": "14/11/24 7:00",
    "demand": 32316.60467,
    "solar_generated": 6.255942054,
    "wind_generated": 70.00696026,
    "marketplace_to_demand": 68.61390372,
    "grid_to_demand": 33890.1926,
    "govt_battery_charge": 8.312908565
  },
  {
    "timestamp": "14/11/24 8:00",
    "demand": 35889.63674,
    "solar_generated": 9.543265688,
    "wind_generated": 79.71732382,
    "marketplace_to_demand": 189.625737,
    "grid_to_demand": 35806.14938,
    "govt_battery_charge": 50.78263327
  },
  {
    "timestamp": "14/11/24 9:00",
    "demand": 34371.92268,
    "solar_generated": 12.00017995,
    "wind_generated": 69.7424795,
    "marketplace_to_demand": 187.5048755,
    "grid_to_demand": 36481.7331,
    "govt_battery_charge": 23.40730931
  },
  {
    "timestamp": "14/11/24 10:00",
    "demand": 34108.41412,
    "solar_generated": 21.05951057,
    "wind_generated": 70.69444508,
    "marketplace_to_demand": 166.5356665,
    "grid_to_demand": 34206.89326,
    "govt_battery_charge": 9.446484799
  },
  {
    "timestamp": "14/11/24 11:00",
    "demand": 35398.06624,
    "solar_generated": 11.04476464,
    "wind_generated": 59.12619915,
    "marketplace_to_demand": 91.53084236,
    "grid_to_demand": 32529.03238,
    "govt_battery_charge": 16.85413308
  },
  {
    "timestamp": "14/11/24 12:00",
    "demand": 32377.75324,
    "solar_generated": 8.908463096,
    "wind_generated": 55.596775,
    "marketplace_to_demand": 88.30346085,
    "grid_to_demand": 34391.63077,
    "govt_battery_charge": 60.49121484
  },
  {
    "timestamp": "14/11/24 13:00",
    "demand": 33058.13976,
    "solar_generated": 23.03218697,
    "wind_generated": 60.81264967,
    "marketplace_to_demand": 139.4131244,
    "grid_to_demand": 35528.71887,
    "govt_battery_charge": 53.40042622
  },
  {
    "timestamp": "14/11/24 14:00",
    "demand": 35448.70737,
    "solar_generated": 16.33772316,
    "wind_generated": 56.49912256,
    "marketplace_to_demand": 147.2939934,
    "grid_to_demand": 31595.75291,
    "govt_battery_charge": 85.97525834
  },
  {
    "timestamp": "14/11/24 15:00",
    "demand": 35226.40055,
    "solar_generated": 10.27756908,
    "wind_generated": 69.45302066,
    "marketplace_to_demand": 63.21248872,
    "grid_to_demand": 32393.17287,
    "govt_battery_charge": 65.32618277
  },
  {
    "timestamp": "14/11/24 16:00",
    "demand": 30950.29239,
    "solar_generated": 13.49370901,
    "wind_generated": 79.88361727,
    "marketplace_to_demand": 20.62295815,
    "grid_to_demand": 31223.51013,
    "govt_battery_charge": 12.62996929
  },
  {
    "timestamp": "14/11/24 17:00",
    "demand": 34612.61175,
    "solar_generated": 6.931342537,
    "wind_generated": 68.99141248,
    "marketplace_to_demand": 69.24100043,
    "grid_to_demand": 34072.83228,
    "govt_battery_charge": 59.00504382
  },
  {
    "timestamp": "14/11/24 18:00",
    "demand": 36440.52301,
    "solar_generated": 0,
    "wind_generated": 80.25781796,
    "marketplace_to_demand": 91.99461193,
    "grid_to_demand": 32960.88141,
    "govt_battery_charge": 39.82318565
  },
  {
    "timestamp": "14/11/24 19:00",
    "demand": 33557.80339,
    "solar_generated": 0,
    "wind_generated": 79.95829286,
    "marketplace_to_demand": 163.7870722,
    "grid_to_demand": 30411.44614,
    "govt_battery_charge": 24.43801593
  },
  {
    "timestamp": "14/11/24 20:00",
    "demand": 31305.60716,
    "solar_generated": 0,
    "wind_generated": 72.79457094,
    "marketplace_to_demand": 166.8295069,
    "grid_to_demand": 33173.29866,
    "govt_battery_charge": 61.30338654
  },
  {
    "timestamp": "14/11/24 21:00",
    "demand": 34469.48103,
    "solar_generated": 0,
    "wind_generated": 73.08126102,
    "marketplace_to_demand": 113.8480966,
    "grid_to_demand": 31402.63867,
    "govt_battery_charge": 64.42088539
  },
  {
    "timestamp": "14/11/24 22:00",
    "demand": 33799.2775,
    "solar_generated": 0,
    "wind_generated": 67.87498701,
    "marketplace_to_demand": 15.283619,
    "grid_to_demand": 33981.02488,
    "govt_battery_charge": 50.50944398
  },
  {
    "timestamp": "14/11/24 23:00",
    "demand": 31048.65977,
    "solar_generated": 0,
    "wind_generated": 67.0084628,
    "marketplace_to_demand": 105.7774271,
    "grid_to_demand": 31123.70698,
    "govt_battery_charge": 69.22607429
  },
  {
    "timestamp": "15/11/24 0:00",
    "demand": 32693.29676,
    "solar_generated": 0,
    "wind_generated": 75.48329896,
    "marketplace_to_demand": 14.64739139,
    "grid_to_demand": 33817.3556,
    "govt_battery_charge": 41.68944528
  },
  {
    "timestamp": "15/11/24 1:00",
    "demand": 35092.47476,
    "solar_generated": 0,
    "wind_generated": 71.44105585,
    "marketplace_to_demand": 17.08143465,
    "grid_to_demand": 35292.98349,
    "govt_battery_charge": 38.05764376
  },
  {
    "timestamp": "15/11/24 2:00",
    "demand": 31816.70637,
    "solar_generated": 0,
    "wind_generated": 71.11888259,
    "marketplace_to_demand": 181.3068238,
    "grid_to_demand": 30229.91465,
    "govt_battery_charge": 71.60410945
  },
  {
    "timestamp": "15/11/24 3:00",
    "demand": 34173.2031,
    "solar_generated": 0,
    "wind_generated": 59.17306494,
    "marketplace_to_demand": 151.7846577,
    "grid_to_demand": 33674.66958,
    "govt_battery_charge": 11.33843401
  },
  {
    "timestamp": "15/11/24 4:00",
    "demand": 31187.52943,
    "solar_generated": 0,
    "wind_generated": 74.68995393,
    "marketplace_to_demand": 97.86534351,
    "grid_to_demand": 32121.70272,
    "govt_battery_charge": 40.15678981
  },
  {
    "timestamp": "15/11/24 5:00",
    "demand": 36457.70202,
    "solar_generated": 0,
    "wind_generated": 78.6184746,
    "marketplace_to_demand": 135.4129593,
    "grid_to_demand": 32902.4192,
    "govt_battery_charge": 6.421134978
  },
  {
    "timestamp": "15/11/24 6:00",
    "demand": 31864.94239,
    "solar_generated": 17.78471507,
    "wind_generated": 65.38888412,
    "marketplace_to_demand": 131.0554356,
    "grid_to_demand": 30956.8886,
    "govt_battery_charge": 35.400332
  },
  {
    "timestamp": "15/11/24 7:00",
    "demand": 31706.59854,
    "solar_generated": 19.21223803,
    "wind_generated": 79.7231807,
    "marketplace_to_demand": 32.11532124,
    "grid_to_demand": 31834.43973,
    "govt_battery_charge": 26.76955728
  },
  {
    "timestamp": "15/11/24 8:00",
    "demand": 32555.83817,
    "solar_generated": 9.250491708,
    "wind_generated": 71.40406882,
    "marketplace_to_demand": 121.0558999,
    "grid_to_demand": 34708.47555,
    "govt_battery_charge": 9.396046587
  },
  {
    "timestamp": "15/11/24 9:00",
    "demand": 33918.73818,
    "solar_generated": 18.82360777,
    "wind_generated": 54.51735687,
    "marketplace_to_demand": 195.48522,
    "grid_to_demand": 32278.41995,
    "govt_battery_charge": 24.41908469
  },
  {
    "timestamp": "15/11/24 10:00",
    "demand": 32220.35211,
    "solar_generated": 14.05915981,
    "wind_generated": 73.48370697,
    "marketplace_to_demand": 46.5135653,
    "grid_to_demand": 30090.88655,
    "govt_battery_charge": 69.14790293
  },
  {
    "timestamp": "15/11/24 11:00",
    "demand": 35938.18311,
    "solar_generated": 16.93594278,
    "wind_generated": 55.29982089,
    "marketplace_to_demand": 80.33583938,
    "grid_to_demand": 31101.18416,
    "govt_battery_charge": 93.23020485
  },
  {
    "timestamp": "15/11/24 12:00",
    "demand": 32657.53681,
    "solar_generated": 11.27128111,
    "wind_generated": 67.04134374,
    "marketplace_to_demand": 123.9771343,
    "grid_to_demand": 32504.2122,
    "govt_battery_charge": 63.31036376
  },
  {
    "timestamp": "15/11/24 13:00",
    "demand": 34226.81058,
    "solar_generated": 11.09877372,
    "wind_generated": 65.51254359,
    "marketplace_to_demand": 12.57648076,
    "grid_to_demand": 33827.58602,
    "govt_battery_charge": 73.4979404
  },
  {
    "timestamp": "15/11/24 14:00",
    "demand": 35884.51999,
    "solar_generated": 14.97740307,
    "wind_generated": 68.32149998,
    "marketplace_to_demand": 41.20557621,
    "grid_to_demand": 33734.38275,
    "govt_battery_charge": 95.79540714
  },
  {
    "timestamp": "15/11/24 15:00",
    "demand": 32584.08996,
    "solar_generated": 15.61902558,
    "wind_generated": 60.24365397,
    "marketplace_to_demand": 193.621317,
    "grid_to_demand": 30739.20294,
    "govt_battery_charge": 81.97920222
  },
  {
    "timestamp": "15/11/24 16:00",
    "demand": 36539.57239,
    "solar_generated": 8.551098694,
    "wind_generated": 61.40482985,
    "marketplace_to_demand": 65.12515601,
    "grid_to_demand": 36444.17151,
    "govt_battery_charge": 72.44377962
  },
  {
    "timestamp": "15/11/24 17:00",
    "demand": 30482.22177,
    "solar_generated": 8.65660388,
    "wind_generated": 70.24615751,
    "marketplace_to_demand": 166.7879324,
    "grid_to_demand": 31428.07416,
    "govt_battery_charge": 62.02718008
  },
  {
    "timestamp": "15/11/24 18:00",
    "demand": 30264.50103,
    "solar_generated": 0,
    "wind_generated": 73.92371455,
    "marketplace_to_demand": 82.95067349,
    "grid_to_demand": 31436.02766,
    "govt_battery_charge": 24.07836216
  },
  {
    "timestamp": "15/11/24 19:00",
    "demand": 36393.90072,
    "solar_generated": 0,
    "wind_generated": 66.84911765,
    "marketplace_to_demand": 157.9439722,
    "grid_to_demand": 31568.70468,
    "govt_battery_charge": 94.89280563
  },
  {
    "timestamp": "15/11/24 20:00",
    "demand": 31175.62693,
    "solar_generated": 0,
    "wind_generated": 54.20780736,
    "marketplace_to_demand": 85.81855532,
    "grid_to_demand": 32723.11791,
    "govt_battery_charge": 83.07833833
  },
  {
    "timestamp": "15/11/24 21:00",
    "demand": 32342.6546,
    "solar_generated": 0,
    "wind_generated": 76.06565542,
    "marketplace_to_demand": 132.4294541,
    "grid_to_demand": 34788.15166,
    "govt_battery_charge": 2.225998151
  },
  {
    "timestamp": "15/11/24 22:00",
    "demand": 35958.95492,
    "solar_generated": 0,
    "wind_generated": 56.26605836,
    "marketplace_to_demand": 168.0326949,
    "grid_to_demand": 30122.11614,
    "govt_battery_charge": 41.52569136
  },
  {
    "timestamp": "15/11/24 23:00",
    "demand": 32466.56427,
    "solar_generated": 0,
    "wind_generated": 56.97677773,
    "marketplace_to_demand": 15.28817754,
    "grid_to_demand": 33714.9509,
    "govt_battery_charge": 97.99078629
  },
  {
    "timestamp": "16/11/24 0:00",
    "demand": 32142.49043,
    "solar_generated": 0,
    "wind_generated": 55.77165991,
    "marketplace_to_demand": 22.20750179,
    "grid_to_demand": 30952.07332,
    "govt_battery_charge": 35.29613048
  },
  {
    "timestamp": "16/11/24 1:00",
    "demand": 30993.64088,
    "solar_generated": 0,
    "wind_generated": 70.59290892,
    "marketplace_to_demand": 82.45025996,
    "grid_to_demand": 32405.98183,
    "govt_battery_charge": 98.5513633
  },
  {
    "timestamp": "16/11/24 2:00",
    "demand": 34845.43235,
    "solar_generated": 0,
    "wind_generated": 54.65083606,
    "marketplace_to_demand": 21.38143506,
    "grid_to_demand": 35762.14848,
    "govt_battery_charge": 17.24102189
  },
  {
    "timestamp": "16/11/24 3:00",
    "demand": 34587.46969,
    "solar_generated": 0,
    "wind_generated": 63.09683814,
    "marketplace_to_demand": 131.5303162,
    "grid_to_demand": 34612.6354,
    "govt_battery_charge": 87.92962618
  },
  {
    "timestamp": "16/11/24 4:00",
    "demand": 33240.01457,
    "solar_generated": 0,
    "wind_generated": 64.25055221,
    "marketplace_to_demand": 7.682916552,
    "grid_to_demand": 30579.31389,
    "govt_battery_charge": 78.36992885
  },
  {
    "timestamp": "16/11/24 5:00",
    "demand": 33118.50382,
    "solar_generated": 0,
    "wind_generated": 69.94597784,
    "marketplace_to_demand": 25.23449109,
    "grid_to_demand": 35558.95327,
    "govt_battery_charge": 14.71994215
  },
  {
    "timestamp": "16/11/24 6:00",
    "demand": 31195.1241,
    "solar_generated": 14.33567682,
    "wind_generated": 73.6559057,
    "marketplace_to_demand": 48.96898238,
    "grid_to_demand": 30831.54397,
    "govt_battery_charge": 68.28802973
  },
  {
    "timestamp": "16/11/24 7:00",
    "demand": 30316.06094,
    "solar_generated": 16.21751609,
    "wind_generated": 62.93247407,
    "marketplace_to_demand": 105.7819204,
    "grid_to_demand": 35633.67105,
    "govt_battery_charge": 89.95633982
  },
  {
    "timestamp": "16/11/24 8:00",
    "demand": 32638.16469,
    "solar_generated": 21.4371772,
    "wind_generated": 80.3574731,
    "marketplace_to_demand": 179.9312438,
    "grid_to_demand": 30903.69914,
    "govt_battery_charge": 16.70148503
  },
  {
    "timestamp": "16/11/24 9:00",
    "demand": 30944.64086,
    "solar_generated": 12.7488516,
    "wind_generated": 55.49274886,
    "marketplace_to_demand": 12.64412394,
    "grid_to_demand": 31267.92312,
    "govt_battery_charge": 31.54250274
  },
  {
    "timestamp": "16/11/24 10:00",
    "demand": 33485.4367,
    "solar_generated": 17.90926751,
    "wind_generated": 67.14761239,
    "marketplace_to_demand": 66.19348311,
    "grid_to_demand": 35642.97434,
    "govt_battery_charge": 83.69995056
  },
  {
    "timestamp": "16/11/24 11:00",
    "demand": 34661.69181,
    "solar_generated": 12.80759949,
    "wind_generated": 79.14409305,
    "marketplace_to_demand": 187.018607,
    "grid_to_demand": 33504.47221,
    "govt_battery_charge": 27.15413819
  },
  {
    "timestamp": "16/11/24 12:00",
    "demand": 35977.40824,
    "solar_generated": 21.02296655,
    "wind_generated": 79.95422908,
    "marketplace_to_demand": 11.29372434,
    "grid_to_demand": 34621.52104,
    "govt_battery_charge": 17.69220017
  },
  {
    "timestamp": "16/11/24 13:00",
    "demand": 36333.3835,
    "solar_generated": 11.01920572,
    "wind_generated": 61.41535056,
    "marketplace_to_demand": 94.41202338,
    "grid_to_demand": 34611.96677,
    "govt_battery_charge": 66.88273196
  },
  {
    "timestamp": "16/11/24 14:00",
    "demand": 31217.3255,
    "solar_generated": 22.38939708,
    "wind_generated": 70.18625061,
    "marketplace_to_demand": 186.6561195,
    "grid_to_demand": 32046.36057,
    "govt_battery_charge": 44.40478724
  },
  {
    "timestamp": "16/11/24 15:00",
    "demand": 31459.01584,
    "solar_generated": 16.94319705,
    "wind_generated": 73.87431645,
    "marketplace_to_demand": 32.60236553,
    "grid_to_demand": 33801.02147,
    "govt_battery_charge": 33.23999674
  },
  {
    "timestamp": "16/11/24 16:00",
    "demand": 36331.1366,
    "solar_generated": 9.262197518,
    "wind_generated": 54.68928141,
    "marketplace_to_demand": 50.94071905,
    "grid_to_demand": 30663.21272,
    "govt_battery_charge": 52.52919779
  },
  {
    "timestamp": "16/11/24 17:00",
    "demand": 30700.1964,
    "solar_generated": 10.044985,
    "wind_generated": 70.62481975,
    "marketplace_to_demand": 136.0023585,
    "grid_to_demand": 35615.88488,
    "govt_battery_charge": 82.78328996
  },
  {
    "timestamp": "16/11/24 18:00",
    "demand": 33676.742,
    "solar_generated": 0,
    "wind_generated": 65.51965252,
    "marketplace_to_demand": 36.26284569,
    "grid_to_demand": 35583.0858,
    "govt_battery_charge": 58.07825774
  },
  {
    "timestamp": "16/11/24 19:00",
    "demand": 36045.62603,
    "solar_generated": 0,
    "wind_generated": 68.26706909,
    "marketplace_to_demand": 119.19321,
    "grid_to_demand": 34742.45509,
    "govt_battery_charge": 8.540838965
  },
  {
    "timestamp": "16/11/24 20:00",
    "demand": 36332.78788,
    "solar_generated": 0,
    "wind_generated": 70.870384,
    "marketplace_to_demand": 18.11140717,
    "grid_to_demand": 36188.03074,
    "govt_battery_charge": 70.7716941
  },
  {
    "timestamp": "16/11/24 21:00",
    "demand": 32761.41144,
    "solar_generated": 0,
    "wind_generated": 78.57930641,
    "marketplace_to_demand": 171.5807363,
    "grid_to_demand": 35278.27224,
    "govt_battery_charge": 37.79707346
  },
  {
    "timestamp": "16/11/24 22:00",
    "demand": 35572.15102,
    "solar_generated": 0,
    "wind_generated": 57.49373739,
    "marketplace_to_demand": 31.45493,
    "grid_to_demand": 34839.41766,
    "govt_battery_charge": 26.6940618
  },
  {
    "timestamp": "16/11/24 23:00",
    "demand": 35914.95503,
    "solar_generated": 0,
    "wind_generated": 58.78585754,
    "marketplace_to_demand": 195.01536,
    "grid_to_demand": 34360.6342,
    "govt_battery_charge": 82.53580959
  },
  {
    "timestamp": "17/11/24 0:00",
    "demand": 31994.642,
    "solar_generated": 0,
    "wind_generated": 76.71417439,
    "marketplace_to_demand": 81.89698161,
    "grid_to_demand": 33769.08208,
    "govt_battery_charge": 51.80778675
  },
  {
    "timestamp": "17/11/24 1:00",
    "demand": 31803.97339,
    "solar_generated": 0,
    "wind_generated": 68.11299136,
    "marketplace_to_demand": 64.58802487,
    "grid_to_demand": 32580.10589,
    "govt_battery_charge": 71.8296163
  },
  {
    "timestamp": "17/11/24 2:00",
    "demand": 32282.53217,
    "solar_generated": 0,
    "wind_generated": 73.09954844,
    "marketplace_to_demand": 14.01437092,
    "grid_to_demand": 35380.01586,
    "govt_battery_charge": 38.13798131
  },
  {
    "timestamp": "17/11/24 3:00",
    "demand": 32577.44856,
    "solar_generated": 0,
    "wind_generated": 57.62851951,
    "marketplace_to_demand": 121.2865961,
    "grid_to_demand": 35010.39276,
    "govt_battery_charge": 8.425048025
  },
  {
    "timestamp": "17/11/24 4:00",
    "demand": 36366.2286,
    "solar_generated": 0,
    "wind_generated": 59.42822657,
    "marketplace_to_demand": 22.61469348,
    "grid_to_demand": 35143.65043,
    "govt_battery_charge": 91.54328195
  },
  {
    "timestamp": "17/11/24 5:00",
    "demand": 30813.55712,
    "solar_generated": 0,
    "wind_generated": 69.93554514,
    "marketplace_to_demand": 38.44692061,
    "grid_to_demand": 34702.17323,
    "govt_battery_charge": 49.35945913
  },
  {
    "timestamp": "17/11/24 6:00",
    "demand": 36332.32446,
    "solar_generated": 21.82653569,
    "wind_generated": 74.34209742,
    "marketplace_to_demand": 162.1313915,
    "grid_to_demand": 34241.87591,
    "govt_battery_charge": 49.65652895
  },
  {
    "timestamp": "17/11/24 7:00",
    "demand": 36391.12683,
    "solar_generated": 22.16272817,
    "wind_generated": 67.5513744,
    "marketplace_to_demand": 2.593322699,
    "grid_to_demand": 31046.12254,
    "govt_battery_charge": 69.45259424
  },
  {
    "timestamp": "17/11/24 8:00",
    "demand": 31523.72345,
    "solar_generated": 15.7894207,
    "wind_generated": 69.4356081,
    "marketplace_to_demand": 83.13370584,
    "grid_to_demand": 32492.78306,
    "govt_battery_charge": 57.20619141
  },
  {
    "timestamp": "17/11/24 9:00",
    "demand": 33457.54928,
    "solar_generated": 22.93557253,
    "wind_generated": 65.62163171,
    "marketplace_to_demand": 67.74019782,
    "grid_to_demand": 33215.99664,
    "govt_battery_charge": 52.83609443
  },
  {
    "timestamp": "17/11/24 10:00",
    "demand": 35624.85529,
    "solar_generated": 15.82090582,
    "wind_generated": 62.92132374,
    "marketplace_to_demand": 21.41768017,
    "grid_to_demand": 32976.69477,
    "govt_battery_charge": 33.91973627
  },
  {
    "timestamp": "17/11/24 11:00",
    "demand": 31473.16714,
    "solar_generated": 9.340539423,
    "wind_generated": 61.7086385,
    "marketplace_to_demand": 40.57204895,
    "grid_to_demand": 33166.08819,
    "govt_battery_charge": 99.0013927
  },
  {
    "timestamp": "17/11/24 12:00",
    "demand": 35995.12337,
    "solar_generated": 17.9119602,
    "wind_generated": 69.89890847,
    "marketplace_to_demand": 38.47552595,
    "grid_to_demand": 35299.00046,
    "govt_battery_charge": 39.53440288
  },
  {
    "timestamp": "17/11/24 13:00",
    "demand": 32495.47946,
    "solar_generated": 14.54829177,
    "wind_generated": 71.50576198,
    "marketplace_to_demand": 184.9192285,
    "grid_to_demand": 31853.48118,
    "govt_battery_charge": 85.28209137
  },
  {
    "timestamp": "17/11/24 14:00",
    "demand": 31280.17606,
    "solar_generated": 12.01995146,
    "wind_generated": 65.79931416,
    "marketplace_to_demand": 171.2821707,
    "grid_to_demand": 36159.62059,
    "govt_battery_charge": 57.32774472
  },
  {
    "timestamp": "17/11/24 15:00",
    "demand": 35117.8209,
    "solar_generated": 19.07994997,
    "wind_generated": 62.40031886,
    "marketplace_to_demand": 185.6140804,
    "grid_to_demand": 31242.71287,
    "govt_battery_charge": 79.14261308
  },
  {
    "timestamp": "17/11/24 16:00",
    "demand": 31113.14895,
    "solar_generated": 7.920713039,
    "wind_generated": 73.06800265,
    "marketplace_to_demand": 102.9099958,
    "grid_to_demand": 33760.98214,
    "govt_battery_charge": 33.02592339
  },
  {
    "timestamp": "17/11/24 17:00",
    "demand": 31202.48885,
    "solar_generated": 19.82649669,
    "wind_generated": 60.180301,
    "marketplace_to_demand": 123.9563728,
    "grid_to_demand": 34956.62723,
    "govt_battery_charge": 49.90128379
  },
  {
    "timestamp": "17/11/24 18:00",
    "demand": 30440.13302,
    "solar_generated": 0,
    "wind_generated": 54.26268771,
    "marketplace_to_demand": 196.7930008,
    "grid_to_demand": 33617.39161,
    "govt_battery_charge": 40.70887656
  },
  {
    "timestamp": "17/11/24 19:00",
    "demand": 32685.3532,
    "solar_generated": 0,
    "wind_generated": 63.03341778,
    "marketplace_to_demand": 38.59795781,
    "grid_to_demand": 32898.8983,
    "govt_battery_charge": 16.06796174
  },
  {
    "timestamp": "17/11/24 20:00",
    "demand": 36478.62091,
    "solar_generated": 0,
    "wind_generated": 57.18011061,
    "marketplace_to_demand": 71.31129255,
    "grid_to_demand": 35704.1686,
    "govt_battery_charge": 20.46829928
  },
  {
    "timestamp": "17/11/24 21:00",
    "demand": 30000.37339,
    "solar_generated": 0,
    "wind_generated": 73.45238438,
    "marketplace_to_demand": 163.2094545,
    "grid_to_demand": 35959.74383,
    "govt_battery_charge": 23.73951187
  },
  {
    "timestamp": "17/11/24 22:00",
    "demand": 34020.14688,
    "solar_generated": 0,
    "wind_generated": 68.99432103,
    "marketplace_to_demand": 57.74854871,
    "grid_to_demand": 32263.44792,
    "govt_battery_charge": 31.49850571
  },
  {
    "timestamp": "17/11/24 23:00",
    "demand": 30823.80932,
    "solar_generated": 0,
    "wind_generated": 54.63972445,
    "marketplace_to_demand": 55.63653995,
    "grid_to_demand": 30860.60008,
    "govt_battery_charge": 95.57489589
  },
  {
    "timestamp": "18/11/24 0:00",
    "demand": 34489.84507,
    "solar_generated": 0,
    "wind_generated": 76.46313383,
    "marketplace_to_demand": 139.1673825,
    "grid_to_demand": 30980.87715,
    "govt_battery_charge": 2.306249646
  },
  {
    "timestamp": "18/11/24 1:00",
    "demand": 34488.19571,
    "solar_generated": 0,
    "wind_generated": 75.44283359,
    "marketplace_to_demand": 96.89901181,
    "grid_to_demand": 31772.37071,
    "govt_battery_charge": 94.36515667
  },
  {
    "timestamp": "18/11/24 2:00",
    "demand": 33219.01459,
    "solar_generated": 0,
    "wind_generated": 69.90167804,
    "marketplace_to_demand": 86.48209191,
    "grid_to_demand": 36084.27548,
    "govt_battery_charge": 53.97729795
  },
  {
    "timestamp": "18/11/24 3:00",
    "demand": 34436.25356,
    "solar_generated": 0,
    "wind_generated": 63.4202971,
    "marketplace_to_demand": 5.693653002,
    "grid_to_demand": 34398.31941,
    "govt_battery_charge": 68.90974641
  },
  {
    "timestamp": "18/11/24 4:00",
    "demand": 33154.96048,
    "solar_generated": 0,
    "wind_generated": 65.46282044,
    "marketplace_to_demand": 94.85752134,
    "grid_to_demand": 34089.18079,
    "govt_battery_charge": 90.02032323
  },
  {
    "timestamp": "18/11/24 5:00",
    "demand": 36281.83718,
    "solar_generated": 0,
    "wind_generated": 58.62981452,
    "marketplace_to_demand": 64.31126221,
    "grid_to_demand": 36168.30326,
    "govt_battery_charge": 45.79184021
  },
  {
    "timestamp": "18/11/24 6:00",
    "demand": 35452.9218,
    "solar_generated": 18.74312227,
    "wind_generated": 76.28889378,
    "marketplace_to_demand": 18.83888452,
    "grid_to_demand": 34157.46631,
    "govt_battery_charge": 72.10628127
  },
  {
    "timestamp": "18/11/24 7:00",
    "demand": 34091.52028,
    "solar_generated": 6.386116578,
    "wind_generated": 58.08517732,
    "marketplace_to_demand": 122.2789914,
    "grid_to_demand": 36539.32356,
    "govt_battery_charge": 12.30208111
  },
  {
    "timestamp": "18/11/24 8:00",
    "demand": 31086.1186,
    "solar_generated": 13.6728701,
    "wind_generated": 56.93265306,
    "marketplace_to_demand": 80.46539851,
    "grid_to_demand": 35986.0256,
    "govt_battery_charge": 73.65764936
  },
  {
    "timestamp": "18/11/24 9:00",
    "demand": 32967.26889,
    "solar_generated": 22.46209663,
    "wind_generated": 55.41434202,
    "marketplace_to_demand": 192.750133,
    "grid_to_demand": 33322.85215,
    "govt_battery_charge": 17.2982222
  },
  {
    "timestamp": "18/11/24 10:00",
    "demand": 33732.55407,
    "solar_generated": 6.598750397,
    "wind_generated": 54.35824908,
    "marketplace_to_demand": 87.30999516,
    "grid_to_demand": 34489.2895,
    "govt_battery_charge": 71.49234997
  },
  {
    "timestamp": "18/11/24 11:00",
    "demand": 33168.39648,
    "solar_generated": 15.40966145,
    "wind_generated": 67.63410285,
    "marketplace_to_demand": 101.0569763,
    "grid_to_demand": 36047.78819,
    "govt_battery_charge": 49.87150056
  },
  {
    "timestamp": "18/11/24 12:00",
    "demand": 34225.37653,
    "solar_generated": 13.12900446,
    "wind_generated": 62.42974056,
    "marketplace_to_demand": 114.6449002,
    "grid_to_demand": 32375.55802,
    "govt_battery_charge": 12.02043679
  },
  {
    "timestamp": "18/11/24 13:00",
    "demand": 36542.43316,
    "solar_generated": 17.68382799,
    "wind_generated": 68.02684826,
    "marketplace_to_demand": 150.6989276,
    "grid_to_demand": 36032.03155,
    "govt_battery_charge": 94.53585969
  },
  {
    "timestamp": "18/11/24 14:00",
    "demand": 31747.1752,
    "solar_generated": 10.07720389,
    "wind_generated": 58.94251524,
    "marketplace_to_demand": 86.49527282,
    "grid_to_demand": 34242.82657,
    "govt_battery_charge": 96.31234892
  },
  {
    "timestamp": "18/11/24 15:00",
    "demand": 31710.0744,
    "solar_generated": 17.21642769,
    "wind_generated": 72.64765462,
    "marketplace_to_demand": 87.94931072,
    "grid_to_demand": 31243.82516,
    "govt_battery_charge": 48.82349963
  },
  {
    "timestamp": "18/11/24 16:00",
    "demand": 32800.68204,
    "solar_generated": 10.32385336,
    "wind_generated": 64.90131895,
    "marketplace_to_demand": 18.44650889,
    "grid_to_demand": 33570.78509,
    "govt_battery_charge": 94.63222034
  },
  {
    "timestamp": "18/11/24 17:00",
    "demand": 32540.45012,
    "solar_generated": 16.68244134,
    "wind_generated": 77.4031164,
    "marketplace_to_demand": 110.8603074,
    "grid_to_demand": 36465.55037,
    "govt_battery_charge": 70.94991869
  },
  {
    "timestamp": "18/11/24 18:00",
    "demand": 32141.00652,
    "solar_generated": 0,
    "wind_generated": 73.69887069,
    "marketplace_to_demand": 126.2473148,
    "grid_to_demand": 34219.85348,
    "govt_battery_charge": 39.07753803
  },
  {
    "timestamp": "18/11/24 19:00",
    "demand": 30965.85536,
    "solar_generated": 0,
    "wind_generated": 54.41610192,
    "marketplace_to_demand": 21.69052357,
    "grid_to_demand": 30450.13006,
    "govt_battery_charge": 42.0900124
  },
  {
    "timestamp": "18/11/24 20:00",
    "demand": 34790.61221,
    "solar_generated": 0,
    "wind_generated": 77.49349439,
    "marketplace_to_demand": 6.186241498,
    "grid_to_demand": 34686.18234,
    "govt_battery_charge": 55.01014952
  },
  {
    "timestamp": "18/11/24 21:00",
    "demand": 36607.98313,
    "solar_generated": 0,
    "wind_generated": 72.70077863,
    "marketplace_to_demand": 132.7578123,
    "grid_to_demand": 32147.3202,
    "govt_battery_charge": 26.22483196
  },
  {
    "timestamp": "18/11/24 22:00",
    "demand": 30107.1853,
    "solar_generated": 0,
    "wind_generated": 66.24076309,
    "marketplace_to_demand": 100.8628996,
    "grid_to_demand": 33815.50724,
    "govt_battery_charge": 67.79993527
  },
  {
    "timestamp": "18/11/24 23:00",
    "demand": 34399.50595,
    "solar_generated": 0,
    "wind_generated": 78.63016952,
    "marketplace_to_demand": 150.9804582,
    "grid_to_demand": 33285.73067,
    "govt_battery_charge": 37.29236219
  },
  {
    "timestamp": "19/11/24 0:00",
    "demand": 36172.82139,
    "solar_generated": 0,
    "wind_generated": 66.13115511,
    "marketplace_to_demand": 36.89450195,
    "grid_to_demand": 32902.37299,
    "govt_battery_charge": 24.51529722
  },
  {
    "timestamp": "19/11/24 1:00",
    "demand": 32902.83555,
    "solar_generated": 0,
    "wind_generated": 77.42059608,
    "marketplace_to_demand": 107.7259723,
    "grid_to_demand": 35089.67699,
    "govt_battery_charge": 6.157583245
  },
  {
    "timestamp": "19/11/24 2:00",
    "demand": 30685.67144,
    "solar_generated": 0,
    "wind_generated": 72.86783511,
    "marketplace_to_demand": 94.45087153,
    "grid_to_demand": 36139.12829,
    "govt_battery_charge": 59.55334077
  },
  {
    "timestamp": "19/11/24 3:00",
    "demand": 34859.00635,
    "solar_generated": 0,
    "wind_generated": 55.1467252,
    "marketplace_to_demand": 178.8319374,
    "grid_to_demand": 30244.38362,
    "govt_battery_charge": 60.30924118
  },
  {
    "timestamp": "19/11/24 4:00",
    "demand": 35557.13366,
    "solar_generated": 0,
    "wind_generated": 55.79028317,
    "marketplace_to_demand": 85.06398823,
    "grid_to_demand": 33931.24626,
    "govt_battery_charge": 28.70832218
  },
  {
    "timestamp": "19/11/24 5:00",
    "demand": 30431.66465,
    "solar_generated": 0,
    "wind_generated": 78.06756678,
    "marketplace_to_demand": 48.79179465,
    "grid_to_demand": 34510.30211,
    "govt_battery_charge": 59.52077844
  },
  {
    "timestamp": "19/11/24 6:00",
    "demand": 32513.19041,
    "solar_generated": 21.94320035,
    "wind_generated": 73.98985323,
    "marketplace_to_demand": 32.87302969,
    "grid_to_demand": 35498.08933,
    "govt_battery_charge": 77.61376148
  },
  {
    "timestamp": "19/11/24 7:00",
    "demand": 34274.47282,
    "solar_generated": 20.41899031,
    "wind_generated": 75.66878664,
    "marketplace_to_demand": 110.0555375,
    "grid_to_demand": 31633.19715,
    "govt_battery_charge": 83.86512234
  },
  {
    "timestamp": "19/11/24 8:00",
    "demand": 31188.60396,
    "solar_generated": 23.97336418,
    "wind_generated": 75.15847599,
    "marketplace_to_demand": 5.541948753,
    "grid_to_demand": 30942.53114,
    "govt_battery_charge": 49.36193282
  },
  {
    "timestamp": "19/11/24 9:00",
    "demand": 36441.01871,
    "solar_generated": 6.440260754,
    "wind_generated": 59.86117086,
    "marketplace_to_demand": 112.4901151,
    "grid_to_demand": 31576.69935,
    "govt_battery_charge": 76.57978358
  },
  {
    "timestamp": "19/11/24 10:00",
    "demand": 34334.63632,
    "solar_generated": 17.39406539,
    "wind_generated": 55.61686057,
    "marketplace_to_demand": 143.4141929,
    "grid_to_demand": 36155.09837,
    "govt_battery_charge": 94.32768236
  },
  {
    "timestamp": "19/11/24 11:00",
    "demand": 32917.41716,
    "solar_generated": 16.4556546,
    "wind_generated": 62.37106074,
    "marketplace_to_demand": 151.4851377,
    "grid_to_demand": 36314.77288,
    "govt_battery_charge": 85.09958491
  },
  {
    "timestamp": "19/11/24 12:00",
    "demand": 36497.35709,
    "solar_generated": 6.592554915,
    "wind_generated": 75.52048166,
    "marketplace_to_demand": 66.65094143,
    "grid_to_demand": 31398.01867,
    "govt_battery_charge": 51.76618148
  },
  {
    "timestamp": "19/11/24 13:00",
    "demand": 33533.12188,
    "solar_generated": 13.23128079,
    "wind_generated": 54.70355302,
    "marketplace_to_demand": 42.59895872,
    "grid_to_demand": 30080.15699,
    "govt_battery_charge": 30.04474968
  },
  {
    "timestamp": "19/11/24 14:00",
    "demand": 31231.69298,
    "solar_generated": 23.63798724,
    "wind_generated": 76.34980925,
    "marketplace_to_demand": 14.22459886,
    "grid_to_demand": 33996.92101,
    "govt_battery_charge": 4.682218051
  },
  {
    "timestamp": "19/11/24 15:00",
    "demand": 36032.53608,
    "solar_generated": 16.05232036,
    "wind_generated": 62.49927896,
    "marketplace_to_demand": 74.42323797,
    "grid_to_demand": 32566.11305,
    "govt_battery_charge": 46.7847563
  },
  {
    "timestamp": "19/11/24 16:00",
    "demand": 32071.33238,
    "solar_generated": 10.73856611,
    "wind_generated": 73.0484596,
    "marketplace_to_demand": 136.5710467,
    "grid_to_demand": 35898.79141,
    "govt_battery_charge": 7.431073684
  },
  {
    "timestamp": "19/11/24 17:00",
    "demand": 36571.51798,
    "solar_generated": 10.88692367,
    "wind_generated": 64.45465563,
    "marketplace_to_demand": 65.62853187,
    "grid_to_demand": 34400.478,
    "govt_battery_charge": 12.33138332
  },
  {
    "timestamp": "19/11/24 18:00",
    "demand": 31763.95934,
    "solar_generated": 0,
    "wind_generated": 58.25575028,
    "marketplace_to_demand": 97.25135263,
    "grid_to_demand": 30747.92587,
    "govt_battery_charge": 93.34552794
  },
  {
    "timestamp": "19/11/24 19:00",
    "demand": 32533.88349,
    "solar_generated": 0,
    "wind_generated": 67.62983286,
    "marketplace_to_demand": 173.6016093,
    "grid_to_demand": 32227.15088,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/11/24 20:00",
    "demand": 35129.74821,
    "solar_generated": 0,
    "wind_generated": 60.21086167,
    "marketplace_to_demand": 179.9523059,
    "grid_to_demand": 32108.62552,
    "govt_battery_charge": 86.95261614
  },
  {
    "timestamp": "19/11/24 21:00",
    "demand": 35795.69165,
    "solar_generated": 0,
    "wind_generated": 73.58027114,
    "marketplace_to_demand": 175.134538,
    "grid_to_demand": 35249.05063,
    "govt_battery_charge": 97.52142732
  },
  {
    "timestamp": "19/11/24 22:00",
    "demand": 32985.76253,
    "solar_generated": 0,
    "wind_generated": 80.1634443,
    "marketplace_to_demand": 114.8655902,
    "grid_to_demand": 34780.55984,
    "govt_battery_charge": 56.7276072
  },
  {
    "timestamp": "19/11/24 23:00",
    "demand": 31572.29807,
    "solar_generated": 0,
    "wind_generated": 63.33458481,
    "marketplace_to_demand": 177.1201341,
    "grid_to_demand": 33003.12901,
    "govt_battery_charge": 3.150845159
  },
  {
    "timestamp": "20/11/24 0:00",
    "demand": 36217.52632,
    "solar_generated": 0,
    "wind_generated": 57.32918341,
    "marketplace_to_demand": 113.6764451,
    "grid_to_demand": 35058.00729,
    "govt_battery_charge": 6.877902947
  },
  {
    "timestamp": "20/11/24 1:00",
    "demand": 33996.30992,
    "solar_generated": 0,
    "wind_generated": 68.89155783,
    "marketplace_to_demand": 72.27912154,
    "grid_to_demand": 34844.97564,
    "govt_battery_charge": 26.23297069
  },
  {
    "timestamp": "20/11/24 2:00",
    "demand": 34023.19432,
    "solar_generated": 0,
    "wind_generated": 70.1626273,
    "marketplace_to_demand": 45.52224476,
    "grid_to_demand": 31194.39181,
    "govt_battery_charge": 35.06145293
  },
  {
    "timestamp": "20/11/24 3:00",
    "demand": 36075.7359,
    "solar_generated": 0,
    "wind_generated": 58.39055545,
    "marketplace_to_demand": 26.71649254,
    "grid_to_demand": 32399.04093,
    "govt_battery_charge": 55.21110099
  },
  {
    "timestamp": "20/11/24 4:00",
    "demand": 31428.82585,
    "solar_generated": 0,
    "wind_generated": 63.19675149,
    "marketplace_to_demand": 67.79350161,
    "grid_to_demand": 36034.14505,
    "govt_battery_charge": 16.41875964
  },
  {
    "timestamp": "20/11/24 5:00",
    "demand": 30345.3497,
    "solar_generated": 0,
    "wind_generated": 68.23459888,
    "marketplace_to_demand": 46.337918,
    "grid_to_demand": 33602.67943,
    "govt_battery_charge": 88.95686353
  },
  {
    "timestamp": "20/11/24 6:00",
    "demand": 34854.8492,
    "solar_generated": 15.08696432,
    "wind_generated": 78.33680559,
    "marketplace_to_demand": 119.8227336,
    "grid_to_demand": 34895.91109,
    "govt_battery_charge": 29.98545124
  },
  {
    "timestamp": "20/11/24 7:00",
    "demand": 31784.6054,
    "solar_generated": 20.08754471,
    "wind_generated": 58.93874297,
    "marketplace_to_demand": 72.18404256,
    "grid_to_demand": 32648.04316,
    "govt_battery_charge": 37.71374572
  },
  {
    "timestamp": "20/11/24 8:00",
    "demand": 34191.78279,
    "solar_generated": 15.21218466,
    "wind_generated": 58.34225724,
    "marketplace_to_demand": 106.3994559,
    "grid_to_demand": 33288.51931,
    "govt_battery_charge": 20.50280427
  },
  {
    "timestamp": "20/11/24 9:00",
    "demand": 30586.31037,
    "solar_generated": 14.89915271,
    "wind_generated": 65.82713741,
    "marketplace_to_demand": 151.757764,
    "grid_to_demand": 33572.92384,
    "govt_battery_charge": 34.62735917
  },
  {
    "timestamp": "20/11/24 10:00",
    "demand": 31984.448,
    "solar_generated": 7.64046668,
    "wind_generated": 64.96665564,
    "marketplace_to_demand": 2.369056499,
    "grid_to_demand": 36401.11783,
    "govt_battery_charge": 31.98378927
  },
  {
    "timestamp": "20/11/24 11:00",
    "demand": 33738.29788,
    "solar_generated": 22.37660937,
    "wind_generated": 77.38693827,
    "marketplace_to_demand": 22.73470298,
    "grid_to_demand": 35176.16736,
    "govt_battery_charge": 71.10704404
  },
  {
    "timestamp": "20/11/24 12:00",
    "demand": 32687.69527,
    "solar_generated": 15.68410539,
    "wind_generated": 54.08168999,
    "marketplace_to_demand": 138.2130565,
    "grid_to_demand": 35265.82,
    "govt_battery_charge": 26.0654532
  },
  {
    "timestamp": "20/11/24 13:00",
    "demand": 34073.27686,
    "solar_generated": 17.54266221,
    "wind_generated": 76.11753236,
    "marketplace_to_demand": 164.5984227,
    "grid_to_demand": 36371.81765,
    "govt_battery_charge": 39.89126844
  },
  {
    "timestamp": "20/11/24 14:00",
    "demand": 33280.8428,
    "solar_generated": 16.44483571,
    "wind_generated": 68.54797653,
    "marketplace_to_demand": 118.4603857,
    "grid_to_demand": 32419.90535,
    "govt_battery_charge": 58.84848964
  },
  {
    "timestamp": "20/11/24 15:00",
    "demand": 33365.48776,
    "solar_generated": 22.66157247,
    "wind_generated": 74.86620158,
    "marketplace_to_demand": 136.880272,
    "grid_to_demand": 32695.11488,
    "govt_battery_charge": 53.20019917
  },
  {
    "timestamp": "20/11/24 16:00",
    "demand": 30859.2134,
    "solar_generated": 10.59973808,
    "wind_generated": 60.33512328,
    "marketplace_to_demand": 75.46070547,
    "grid_to_demand": 33742.55897,
    "govt_battery_charge": 53.85803999
  },
  {
    "timestamp": "20/11/24 17:00",
    "demand": 31010.01213,
    "solar_generated": 12.21833565,
    "wind_generated": 56.93238522,
    "marketplace_to_demand": 182.265465,
    "grid_to_demand": 34474.3937,
    "govt_battery_charge": 49.28982825
  },
  {
    "timestamp": "20/11/24 18:00",
    "demand": 35961.98009,
    "solar_generated": 0,
    "wind_generated": 80.02647027,
    "marketplace_to_demand": 148.4176322,
    "grid_to_demand": 31218.11062,
    "govt_battery_charge": 65.31311786
  },
  {
    "timestamp": "20/11/24 19:00",
    "demand": 30140.56498,
    "solar_generated": 0,
    "wind_generated": 71.90068013,
    "marketplace_to_demand": 199.8440576,
    "grid_to_demand": 32690.09105,
    "govt_battery_charge": 92.34091194
  },
  {
    "timestamp": "20/11/24 20:00",
    "demand": 36394.09987,
    "solar_generated": 0,
    "wind_generated": 59.6911886,
    "marketplace_to_demand": 29.29779405,
    "grid_to_demand": 30527.9648,
    "govt_battery_charge": 41.78293351
  },
  {
    "timestamp": "20/11/24 21:00",
    "demand": 34871.38433,
    "solar_generated": 0,
    "wind_generated": 70.16667309,
    "marketplace_to_demand": 28.34281881,
    "grid_to_demand": 35084.66135,
    "govt_battery_charge": 77.49649446
  },
  {
    "timestamp": "20/11/24 22:00",
    "demand": 29986.59849,
    "solar_generated": 0,
    "wind_generated": 69.80796118,
    "marketplace_to_demand": 90.54404423,
    "grid_to_demand": 30506.05673,
    "govt_battery_charge": 2.0721619
  },
  {
    "timestamp": "20/11/24 23:00",
    "demand": 33479.8363,
    "solar_generated": 0,
    "wind_generated": 65.46617499,
    "marketplace_to_demand": 198.792555,
    "grid_to_demand": 34888.57049,
    "govt_battery_charge": 57.00657377
  },
  {
    "timestamp": "21/11/24 0:00",
    "demand": 36002.13974,
    "solar_generated": 0,
    "wind_generated": 63.14027051,
    "marketplace_to_demand": 133.788523,
    "grid_to_demand": 35873.44168,
    "govt_battery_charge": 28.76155365
  },
  {
    "timestamp": "21/11/24 1:00",
    "demand": 36619.99673,
    "solar_generated": 0,
    "wind_generated": 68.29137147,
    "marketplace_to_demand": 45.99396147,
    "grid_to_demand": 36519.63166,
    "govt_battery_charge": 16.63727395
  },
  {
    "timestamp": "21/11/24 2:00",
    "demand": 33488.29453,
    "solar_generated": 0,
    "wind_generated": 63.06236664,
    "marketplace_to_demand": 163.0836302,
    "grid_to_demand": 34384.70018,
    "govt_battery_charge": 70.57204618
  },
  {
    "timestamp": "21/11/24 3:00",
    "demand": 34460.34745,
    "solar_generated": 0,
    "wind_generated": 54.94217567,
    "marketplace_to_demand": 24.78038189,
    "grid_to_demand": 33281.66462,
    "govt_battery_charge": 56.52393639
  },
  {
    "timestamp": "21/11/24 4:00",
    "demand": 31270.70026,
    "solar_generated": 0,
    "wind_generated": 73.85280968,
    "marketplace_to_demand": 59.28266071,
    "grid_to_demand": 32912.57259,
    "govt_battery_charge": 51.02294864
  },
  {
    "timestamp": "21/11/24 5:00",
    "demand": 33292.25517,
    "solar_generated": 0,
    "wind_generated": 57.54357565,
    "marketplace_to_demand": 155.7833531,
    "grid_to_demand": 32966.55779,
    "govt_battery_charge": 26.63998445
  },
  {
    "timestamp": "21/11/24 6:00",
    "demand": 34172.07589,
    "solar_generated": 23.89344322,
    "wind_generated": 72.54556022,
    "marketplace_to_demand": 25.9397759,
    "grid_to_demand": 30379.15055,
    "govt_battery_charge": 90.67843941
  },
  {
    "timestamp": "21/11/24 7:00",
    "demand": 32811.23702,
    "solar_generated": 23.07251944,
    "wind_generated": 73.28471282,
    "marketplace_to_demand": 68.90466739,
    "grid_to_demand": 35616.21618,
    "govt_battery_charge": 8.656303323
  },
  {
    "timestamp": "21/11/24 8:00",
    "demand": 35832.3863,
    "solar_generated": 11.31276867,
    "wind_generated": 65.71465821,
    "marketplace_to_demand": 130.6824605,
    "grid_to_demand": 31563.07446,
    "govt_battery_charge": 22.51433403
  },
  {
    "timestamp": "21/11/24 9:00",
    "demand": 30286.41306,
    "solar_generated": 14.24568501,
    "wind_generated": 65.74388726,
    "marketplace_to_demand": 36.82296867,
    "grid_to_demand": 36217.59066,
    "govt_battery_charge": 18.05890774
  },
  {
    "timestamp": "21/11/24 10:00",
    "demand": 35016.50832,
    "solar_generated": 16.54687462,
    "wind_generated": 75.86422604,
    "marketplace_to_demand": 79.89084491,
    "grid_to_demand": 30719.76074,
    "govt_battery_charge": 33.00795058
  },
  {
    "timestamp": "21/11/24 11:00",
    "demand": 33500.34397,
    "solar_generated": 23.45102679,
    "wind_generated": 59.44004716,
    "marketplace_to_demand": 112.071082,
    "grid_to_demand": 35354.85801,
    "govt_battery_charge": 86.59832437
  },
  {
    "timestamp": "21/11/24 12:00",
    "demand": 35527.7477,
    "solar_generated": 12.06051396,
    "wind_generated": 65.34211233,
    "marketplace_to_demand": 108.4293384,
    "grid_to_demand": 30655.00543,
    "govt_battery_charge": 66.98926504
  },
  {
    "timestamp": "21/11/24 13:00",
    "demand": 33597.0261,
    "solar_generated": 16.72170513,
    "wind_generated": 60.21395144,
    "marketplace_to_demand": 145.0619501,
    "grid_to_demand": 31535.33705,
    "govt_battery_charge": 20.23015202
  },
  {
    "timestamp": "21/11/24 14:00",
    "demand": 32939.28525,
    "solar_generated": 16.85741823,
    "wind_generated": 73.95387943,
    "marketplace_to_demand": 113.5393013,
    "grid_to_demand": 30338.09586,
    "govt_battery_charge": 17.27506256
  },
  {
    "timestamp": "21/11/24 15:00",
    "demand": 34356.94166,
    "solar_generated": 22.13281364,
    "wind_generated": 77.24867921,
    "marketplace_to_demand": 37.4538629,
    "grid_to_demand": 35997.04805,
    "govt_battery_charge": 58.74730114
  },
  {
    "timestamp": "21/11/24 16:00",
    "demand": 35940.39429,
    "solar_generated": 23.34062503,
    "wind_generated": 79.71046554,
    "marketplace_to_demand": 128.8043633,
    "grid_to_demand": 31831.69642,
    "govt_battery_charge": 82.57716363
  },
  {
    "timestamp": "21/11/24 17:00",
    "demand": 30137.51078,
    "solar_generated": 21.72817623,
    "wind_generated": 77.9516745,
    "marketplace_to_demand": 11.61697781,
    "grid_to_demand": 35796.53766,
    "govt_battery_charge": 12.20312925
  },
  {
    "timestamp": "21/11/24 18:00",
    "demand": 34956.12587,
    "solar_generated": 0,
    "wind_generated": 74.87689738,
    "marketplace_to_demand": 11.91696692,
    "grid_to_demand": 35896.64279,
    "govt_battery_charge": 86.98685519
  },
  {
    "timestamp": "21/11/24 19:00",
    "demand": 36183.49045,
    "solar_generated": 0,
    "wind_generated": 60.45523348,
    "marketplace_to_demand": 128.6481911,
    "grid_to_demand": 36456.18218,
    "govt_battery_charge": 94.77710628
  },
  {
    "timestamp": "21/11/24 20:00",
    "demand": 34606.13725,
    "solar_generated": 0,
    "wind_generated": 64.19752219,
    "marketplace_to_demand": 59.6194016,
    "grid_to_demand": 35378.00334,
    "govt_battery_charge": 65.27220883
  },
  {
    "timestamp": "21/11/24 21:00",
    "demand": 30768.97685,
    "solar_generated": 0,
    "wind_generated": 74.62559039,
    "marketplace_to_demand": 191.846689,
    "grid_to_demand": 35461.41352,
    "govt_battery_charge": 90.3519824
  },
  {
    "timestamp": "21/11/24 22:00",
    "demand": 35954.90848,
    "solar_generated": 0,
    "wind_generated": 68.75488578,
    "marketplace_to_demand": 41.68917442,
    "grid_to_demand": 31440.1985,
    "govt_battery_charge": 30.5117071
  },
  {
    "timestamp": "21/11/24 23:00",
    "demand": 32662.59053,
    "solar_generated": 0,
    "wind_generated": 77.68225998,
    "marketplace_to_demand": 148.0549884,
    "grid_to_demand": 36325.22302,
    "govt_battery_charge": 84.55209725
  },
  {
    "timestamp": "22/11/24 0:00",
    "demand": 34742.99912,
    "solar_generated": 0,
    "wind_generated": 61.83323407,
    "marketplace_to_demand": 115.1674772,
    "grid_to_demand": 32512.38237,
    "govt_battery_charge": 18.63965213
  },
  {
    "timestamp": "22/11/24 1:00",
    "demand": 31180.25485,
    "solar_generated": 0,
    "wind_generated": 62.14013539,
    "marketplace_to_demand": 191.9104726,
    "grid_to_demand": 30548.27997,
    "govt_battery_charge": 33.62597469
  },
  {
    "timestamp": "22/11/24 2:00",
    "demand": 35825.44542,
    "solar_generated": 0,
    "wind_generated": 67.71704318,
    "marketplace_to_demand": 68.14925038,
    "grid_to_demand": 36013.6065,
    "govt_battery_charge": 55.92822095
  },
  {
    "timestamp": "22/11/24 3:00",
    "demand": 30493.61537,
    "solar_generated": 0,
    "wind_generated": 76.31751339,
    "marketplace_to_demand": 148.1712712,
    "grid_to_demand": 36199.05276,
    "govt_battery_charge": 75.42926329
  },
  {
    "timestamp": "22/11/24 4:00",
    "demand": 33788.4693,
    "solar_generated": 0,
    "wind_generated": 64.97370985,
    "marketplace_to_demand": 32.81964964,
    "grid_to_demand": 31479.73899,
    "govt_battery_charge": 3.317392008
  },
  {
    "timestamp": "22/11/24 5:00",
    "demand": 32179.28083,
    "solar_generated": 0,
    "wind_generated": 73.01368455,
    "marketplace_to_demand": 122.2859269,
    "grid_to_demand": 31078.89954,
    "govt_battery_charge": 56.07696993
  },
  {
    "timestamp": "22/11/24 6:00",
    "demand": 36481.82134,
    "solar_generated": 13.59198105,
    "wind_generated": 74.70665235,
    "marketplace_to_demand": 192.2913759,
    "grid_to_demand": 34496.74197,
    "govt_battery_charge": 82.04060585
  },
  {
    "timestamp": "22/11/24 7:00",
    "demand": 34974.00667,
    "solar_generated": 6.590004615,
    "wind_generated": 62.80638498,
    "marketplace_to_demand": 25.52753342,
    "grid_to_demand": 34284.94174,
    "govt_battery_charge": 22.68873269
  },
  {
    "timestamp": "22/11/24 8:00",
    "demand": 36386.30746,
    "solar_generated": 17.97012985,
    "wind_generated": 62.78843975,
    "marketplace_to_demand": 49.97956202,
    "grid_to_demand": 30989.22575,
    "govt_battery_charge": 72.19809991
  },
  {
    "timestamp": "22/11/24 9:00",
    "demand": 32554.37838,
    "solar_generated": 22.91291322,
    "wind_generated": 71.02815077,
    "marketplace_to_demand": 171.1604271,
    "grid_to_demand": 32751.16832,
    "govt_battery_charge": 15.64675221
  },
  {
    "timestamp": "22/11/24 10:00",
    "demand": 31811.59441,
    "solar_generated": 11.10919453,
    "wind_generated": 63.31721938,
    "marketplace_to_demand": 171.2646779,
    "grid_to_demand": 29943.80808,
    "govt_battery_charge": 40.81990196
  },
  {
    "timestamp": "22/11/24 11:00",
    "demand": 36342.4131,
    "solar_generated": 8.474783203,
    "wind_generated": 63.88938136,
    "marketplace_to_demand": 193.4438093,
    "grid_to_demand": 35183.38946,
    "govt_battery_charge": 84.47514937
  },
  {
    "timestamp": "22/11/24 12:00",
    "demand": 35844.55776,
    "solar_generated": 20.40803688,
    "wind_generated": 63.74862615,
    "marketplace_to_demand": 129.8214636,
    "grid_to_demand": 30014.47433,
    "govt_battery_charge": 87.58028036
  },
  {
    "timestamp": "22/11/24 13:00",
    "demand": 36583.60298,
    "solar_generated": 6.276681338,
    "wind_generated": 79.70227238,
    "marketplace_to_demand": 3.207312699,
    "grid_to_demand": 31699.064,
    "govt_battery_charge": 35.16910604
  },
  {
    "timestamp": "22/11/24 14:00",
    "demand": 33996.79772,
    "solar_generated": 21.67878551,
    "wind_generated": 76.3248792,
    "marketplace_to_demand": 109.0419106,
    "grid_to_demand": 35105.5265,
    "govt_battery_charge": 97.23461056
  },
  {
    "timestamp": "22/11/24 15:00",
    "demand": 35856.01512,
    "solar_generated": 10.99452193,
    "wind_generated": 71.37943141,
    "marketplace_to_demand": 7.546673095,
    "grid_to_demand": 33232.28613,
    "govt_battery_charge": 2.282807374
  },
  {
    "timestamp": "22/11/24 16:00",
    "demand": 34224.09446,
    "solar_generated": 12.15938406,
    "wind_generated": 64.08224725,
    "marketplace_to_demand": 60.70891185,
    "grid_to_demand": 35652.02897,
    "govt_battery_charge": 81.93922658
  },
  {
    "timestamp": "22/11/24 17:00",
    "demand": 33025.2652,
    "solar_generated": 17.05177123,
    "wind_generated": 58.54993852,
    "marketplace_to_demand": 47.20477472,
    "grid_to_demand": 33095.05348,
    "govt_battery_charge": 74.63273801
  },
  {
    "timestamp": "22/11/24 18:00",
    "demand": 30426.63461,
    "solar_generated": 0,
    "wind_generated": 71.18783744,
    "marketplace_to_demand": 63.66151339,
    "grid_to_demand": 32622.36291,
    "govt_battery_charge": 62.39630793
  },
  {
    "timestamp": "22/11/24 19:00",
    "demand": 32316.34331,
    "solar_generated": 0,
    "wind_generated": 68.66888906,
    "marketplace_to_demand": 134.7004767,
    "grid_to_demand": 32933.24523,
    "govt_battery_charge": 9.974043077
  },
  {
    "timestamp": "22/11/24 20:00",
    "demand": 33844.84329,
    "solar_generated": 0,
    "wind_generated": 71.35762153,
    "marketplace_to_demand": 197.0550844,
    "grid_to_demand": 32179.37323,
    "govt_battery_charge": 16.19146296
  },
  {
    "timestamp": "22/11/24 21:00",
    "demand": 31629.77007,
    "solar_generated": 0,
    "wind_generated": 69.26229831,
    "marketplace_to_demand": 150.0914891,
    "grid_to_demand": 36261.61072,
    "govt_battery_charge": 80.92305893
  },
  {
    "timestamp": "22/11/24 22:00",
    "demand": 32547.7408,
    "solar_generated": 0,
    "wind_generated": 55.45909877,
    "marketplace_to_demand": 90.73641742,
    "grid_to_demand": 35639.50327,
    "govt_battery_charge": 98.40768725
  },
  {
    "timestamp": "22/11/24 23:00",
    "demand": 36030.089,
    "solar_generated": 0,
    "wind_generated": 63.93847937,
    "marketplace_to_demand": 129.458033,
    "grid_to_demand": 34697.29571,
    "govt_battery_charge": 5.539399586
  },
  {
    "timestamp": "23/11/24 0:00",
    "demand": 35462.09517,
    "solar_generated": 0,
    "wind_generated": 74.25260003,
    "marketplace_to_demand": 50.13055554,
    "grid_to_demand": 33417.45737,
    "govt_battery_charge": 2.085678114
  },
  {
    "timestamp": "23/11/24 1:00",
    "demand": 33236.93982,
    "solar_generated": 0,
    "wind_generated": 65.08281247,
    "marketplace_to_demand": 14.68841933,
    "grid_to_demand": 35385.27854,
    "govt_battery_charge": 67.38810507
  },
  {
    "timestamp": "23/11/24 2:00",
    "demand": 36593.30558,
    "solar_generated": 0,
    "wind_generated": 59.40495448,
    "marketplace_to_demand": 121.766817,
    "grid_to_demand": 34413.21097,
    "govt_battery_charge": 5.145010762
  },
  {
    "timestamp": "23/11/24 3:00",
    "demand": 32834.08527,
    "solar_generated": 0,
    "wind_generated": 55.36665413,
    "marketplace_to_demand": 138.1817404,
    "grid_to_demand": 33779.30509,
    "govt_battery_charge": 20.33140786
  },
  {
    "timestamp": "23/11/24 4:00",
    "demand": 32948.16717,
    "solar_generated": 0,
    "wind_generated": 71.7508061,
    "marketplace_to_demand": 100.550511,
    "grid_to_demand": 35298.80213,
    "govt_battery_charge": 51.01229626
  },
  {
    "timestamp": "23/11/24 5:00",
    "demand": 34732.19897,
    "solar_generated": 0,
    "wind_generated": 71.71471434,
    "marketplace_to_demand": 179.7468854,
    "grid_to_demand": 35154.31625,
    "govt_battery_charge": 40.5178303
  },
  {
    "timestamp": "23/11/24 6:00",
    "demand": 31059.22052,
    "solar_generated": 12.3972601,
    "wind_generated": 72.18856845,
    "marketplace_to_demand": 161.7789107,
    "grid_to_demand": 35099.10037,
    "govt_battery_charge": 33.10659701
  },
  {
    "timestamp": "23/11/24 7:00",
    "demand": 35352.19356,
    "solar_generated": 8.304466207,
    "wind_generated": 65.71185331,
    "marketplace_to_demand": 98.3995947,
    "grid_to_demand": 31322.83255,
    "govt_battery_charge": 29.14513781
  },
  {
    "timestamp": "23/11/24 8:00",
    "demand": 32703.61708,
    "solar_generated": 23.42269858,
    "wind_generated": 66.42814095,
    "marketplace_to_demand": 184.6292327,
    "grid_to_demand": 35871.25912,
    "govt_battery_charge": 39.75245519
  },
  {
    "timestamp": "23/11/24 9:00",
    "demand": 32148.21738,
    "solar_generated": 23.1913386,
    "wind_generated": 54.17428456,
    "marketplace_to_demand": 186.8111766,
    "grid_to_demand": 31164.28718,
    "govt_battery_charge": 34.74219571
  },
  {
    "timestamp": "23/11/24 10:00",
    "demand": 34613.6425,
    "solar_generated": 18.46119862,
    "wind_generated": 56.08075181,
    "marketplace_to_demand": 109.8544244,
    "grid_to_demand": 32136.01346,
    "govt_battery_charge": 93.94354345
  },
  {
    "timestamp": "23/11/24 11:00",
    "demand": 33780.03814,
    "solar_generated": 13.38194771,
    "wind_generated": 55.38656312,
    "marketplace_to_demand": 71.29180992,
    "grid_to_demand": 35104.81033,
    "govt_battery_charge": 26.49190036
  },
  {
    "timestamp": "23/11/24 12:00",
    "demand": 34319.51476,
    "solar_generated": 14.39715001,
    "wind_generated": 79.68227973,
    "marketplace_to_demand": 36.39172918,
    "grid_to_demand": 32553.18433,
    "govt_battery_charge": 65.20379934
  },
  {
    "timestamp": "23/11/24 13:00",
    "demand": 34727.64988,
    "solar_generated": 12.94888713,
    "wind_generated": 70.81403365,
    "marketplace_to_demand": 50.20027158,
    "grid_to_demand": 35289.42457,
    "govt_battery_charge": 29.74598921
  },
  {
    "timestamp": "23/11/24 14:00",
    "demand": 30016.69915,
    "solar_generated": 6.04497348,
    "wind_generated": 73.23596552,
    "marketplace_to_demand": 141.1905028,
    "grid_to_demand": 35875.43545,
    "govt_battery_charge": 53.60235398
  },
  {
    "timestamp": "23/11/24 15:00",
    "demand": 30726.4925,
    "solar_generated": 10.2317377,
    "wind_generated": 69.66898405,
    "marketplace_to_demand": 112.4047871,
    "grid_to_demand": 31519.75673,
    "govt_battery_charge": 59.79872551
  },
  {
    "timestamp": "23/11/24 16:00",
    "demand": 35358.90625,
    "solar_generated": 11.28291478,
    "wind_generated": 71.14179851,
    "marketplace_to_demand": 62.9221848,
    "grid_to_demand": 36357.5859,
    "govt_battery_charge": 79.90187186
  },
  {
    "timestamp": "23/11/24 17:00",
    "demand": 32854.24798,
    "solar_generated": 10.32233181,
    "wind_generated": 62.38226572,
    "marketplace_to_demand": 65.6039835,
    "grid_to_demand": 35496.68837,
    "govt_battery_charge": 25.02985489
  },
  {
    "timestamp": "23/11/24 18:00",
    "demand": 33027.19485,
    "solar_generated": 0,
    "wind_generated": 59.88869876,
    "marketplace_to_demand": 195.9387715,
    "grid_to_demand": 31390.17542,
    "govt_battery_charge": 69.36070876
  },
  {
    "timestamp": "23/11/24 19:00",
    "demand": 31108.62227,
    "solar_generated": 0,
    "wind_generated": 61.06169159,
    "marketplace_to_demand": 120.1149073,
    "grid_to_demand": 31655.83358,
    "govt_battery_charge": 4.848481436
  },
  {
    "timestamp": "23/11/24 20:00",
    "demand": 32434.38933,
    "solar_generated": 0,
    "wind_generated": 64.59269393,
    "marketplace_to_demand": 20.89310037,
    "grid_to_demand": 36366.20713,
    "govt_battery_charge": 85.19225093
  },
  {
    "timestamp": "23/11/24 21:00",
    "demand": 35313.20846,
    "solar_generated": 0,
    "wind_generated": 71.29946019,
    "marketplace_to_demand": 59.22965935,
    "grid_to_demand": 30376.79821,
    "govt_battery_charge": 34.47139912
  },
  {
    "timestamp": "23/11/24 22:00",
    "demand": 32654.43783,
    "solar_generated": 0,
    "wind_generated": 69.31331085,
    "marketplace_to_demand": 173.7124689,
    "grid_to_demand": 35180.63981,
    "govt_battery_charge": 19.16647368
  },
  {
    "timestamp": "23/11/24 23:00",
    "demand": 33422.54515,
    "solar_generated": 0,
    "wind_generated": 76.67530014,
    "marketplace_to_demand": 143.2372634,
    "grid_to_demand": 30550.77072,
    "govt_battery_charge": 73.17523769
  },
  {
    "timestamp": "24/11/24 0:00",
    "demand": 35223.19793,
    "solar_generated": 0,
    "wind_generated": 60.06661743,
    "marketplace_to_demand": 162.0338287,
    "grid_to_demand": 36382.70971,
    "govt_battery_charge": 84.5083183
  },
  {
    "timestamp": "24/11/24 1:00",
    "demand": 35606.92047,
    "solar_generated": 0,
    "wind_generated": 78.34546379,
    "marketplace_to_demand": 125.5266178,
    "grid_to_demand": 34223.38849,
    "govt_battery_charge": 46.38222339
  },
  {
    "timestamp": "24/11/24 2:00",
    "demand": 31579.12112,
    "solar_generated": 0,
    "wind_generated": 59.46333084,
    "marketplace_to_demand": 192.8322074,
    "grid_to_demand": 32765.82008,
    "govt_battery_charge": 19.99680718
  },
  {
    "timestamp": "24/11/24 3:00",
    "demand": 34701.5761,
    "solar_generated": 0,
    "wind_generated": 62.5261585,
    "marketplace_to_demand": 158.5380293,
    "grid_to_demand": 36354.37306,
    "govt_battery_charge": 9.219517505
  },
  {
    "timestamp": "24/11/24 4:00",
    "demand": 32514.27131,
    "solar_generated": 0,
    "wind_generated": 53.67564498,
    "marketplace_to_demand": 178.2192554,
    "grid_to_demand": 35156.03164,
    "govt_battery_charge": 85.60574467
  },
  {
    "timestamp": "24/11/24 5:00",
    "demand": 35566.23805,
    "solar_generated": 0,
    "wind_generated": 65.341196,
    "marketplace_to_demand": 182.7113837,
    "grid_to_demand": 33579.50726,
    "govt_battery_charge": 97.84452738
  },
  {
    "timestamp": "24/11/24 6:00",
    "demand": 33522.00365,
    "solar_generated": 8.147449068,
    "wind_generated": 71.03991408,
    "marketplace_to_demand": 126.4366306,
    "grid_to_demand": 35545.06887,
    "govt_battery_charge": 66.74290958
  },
  {
    "timestamp": "24/11/24 7:00",
    "demand": 33552.28446,
    "solar_generated": 17.40225458,
    "wind_generated": 67.80675215,
    "marketplace_to_demand": 13.20451474,
    "grid_to_demand": 31016.22362,
    "govt_battery_charge": 64.49381941
  },
  {
    "timestamp": "24/11/24 8:00",
    "demand": 36623.49365,
    "solar_generated": 18.872628,
    "wind_generated": 79.52507873,
    "marketplace_to_demand": 194.2105986,
    "grid_to_demand": 34796.03167,
    "govt_battery_charge": 18.5363228
  },
  {
    "timestamp": "24/11/24 9:00",
    "demand": 34198.09415,
    "solar_generated": 18.17658341,
    "wind_generated": 77.08491074,
    "marketplace_to_demand": 167.96566,
    "grid_to_demand": 33376.14756,
    "govt_battery_charge": 36.95398656
  },
  {
    "timestamp": "24/11/24 10:00",
    "demand": 31783.37362,
    "solar_generated": 17.84348481,
    "wind_generated": 77.18694869,
    "marketplace_to_demand": 68.39630552,
    "grid_to_demand": 30745.69101,
    "govt_battery_charge": 99.11924126
  },
  {
    "timestamp": "24/11/24 11:00",
    "demand": 30544.21417,
    "solar_generated": 20.5564982,
    "wind_generated": 73.87425554,
    "marketplace_to_demand": 115.6403072,
    "grid_to_demand": 32540.4513,
    "govt_battery_charge": 3.496493574
  },
  {
    "timestamp": "24/11/24 12:00",
    "demand": 31687.84255,
    "solar_generated": 13.78531613,
    "wind_generated": 53.87779796,
    "marketplace_to_demand": 88.55533589,
    "grid_to_demand": 30559.60766,
    "govt_battery_charge": 25.16479874
  },
  {
    "timestamp": "24/11/24 13:00",
    "demand": 36237.29995,
    "solar_generated": 8.84700691,
    "wind_generated": 70.90846861,
    "marketplace_to_demand": 189.9336171,
    "grid_to_demand": 35092.59341,
    "govt_battery_charge": 93.5412774
  },
  {
    "timestamp": "24/11/24 14:00",
    "demand": 32465.13228,
    "solar_generated": 21.41588245,
    "wind_generated": 64.76925092,
    "marketplace_to_demand": 0.642636976,
    "grid_to_demand": 35997.64385,
    "govt_battery_charge": 52.83342981
  },
  {
    "timestamp": "24/11/24 15:00",
    "demand": 35232.06091,
    "solar_generated": 10.48605767,
    "wind_generated": 66.61513059,
    "marketplace_to_demand": 15.19395809,
    "grid_to_demand": 31359.40357,
    "govt_battery_charge": 86.16646808
  },
  {
    "timestamp": "24/11/24 16:00",
    "demand": 36038.23926,
    "solar_generated": 14.36384203,
    "wind_generated": 74.52123117,
    "marketplace_to_demand": 112.8619217,
    "grid_to_demand": 33129.56769,
    "govt_battery_charge": 47.02213329
  },
  {
    "timestamp": "24/11/24 17:00",
    "demand": 32460.6099,
    "solar_generated": 17.26311355,
    "wind_generated": 70.00892999,
    "marketplace_to_demand": 33.93389953,
    "grid_to_demand": 30665.90808,
    "govt_battery_charge": 84.56372591
  },
  {
    "timestamp": "24/11/24 18:00",
    "demand": 33804.39989,
    "solar_generated": 0,
    "wind_generated": 76.92171571,
    "marketplace_to_demand": 91.10973521,
    "grid_to_demand": 33778.53978,
    "govt_battery_charge": 35.20144134
  },
  {
    "timestamp": "24/11/24 19:00",
    "demand": 33835.96576,
    "solar_generated": 0,
    "wind_generated": 78.78779615,
    "marketplace_to_demand": 14.7011277,
    "grid_to_demand": 33310.47128,
    "govt_battery_charge": 27.69500878
  },
  {
    "timestamp": "24/11/24 20:00",
    "demand": 31896.92529,
    "solar_generated": 0,
    "wind_generated": 66.30324317,
    "marketplace_to_demand": 19.6478781,
    "grid_to_demand": 31350.016,
    "govt_battery_charge": 28.00372876
  },
  {
    "timestamp": "24/11/24 21:00",
    "demand": 33087.66274,
    "solar_generated": 0,
    "wind_generated": 59.33166938,
    "marketplace_to_demand": 17.29989364,
    "grid_to_demand": 32536.40837,
    "govt_battery_charge": 27.24257419
  },
  {
    "timestamp": "24/11/24 22:00",
    "demand": 31464.83286,
    "solar_generated": 0,
    "wind_generated": 60.49166063,
    "marketplace_to_demand": 100.2712292,
    "grid_to_demand": 31947.78383,
    "govt_battery_charge": 1.495038883
  },
  {
    "timestamp": "24/11/24 23:00",
    "demand": 33013.95829,
    "solar_generated": 0,
    "wind_generated": 78.66529381,
    "marketplace_to_demand": 169.4323474,
    "grid_to_demand": 35570.87275,
    "govt_battery_charge": 12.99987611
  },
  {
    "timestamp": "25/11/24 0:00",
    "demand": 35794.76836,
    "solar_generated": 0,
    "wind_generated": 72.31915604,
    "marketplace_to_demand": 21.82124923,
    "grid_to_demand": 29938.04241,
    "govt_battery_charge": 70.30239432
  },
  {
    "timestamp": "25/11/24 1:00",
    "demand": 32534.99887,
    "solar_generated": 0,
    "wind_generated": 62.91559425,
    "marketplace_to_demand": 143.5288486,
    "grid_to_demand": 32265.99463,
    "govt_battery_charge": 57.82424159
  },
  {
    "timestamp": "25/11/24 2:00",
    "demand": 35455.27175,
    "solar_generated": 0,
    "wind_generated": 54.58793081,
    "marketplace_to_demand": 51.82904335,
    "grid_to_demand": 33501.64343,
    "govt_battery_charge": 2.690727987
  },
  {
    "timestamp": "25/11/24 3:00",
    "demand": 31380.46688,
    "solar_generated": 0,
    "wind_generated": 57.87873787,
    "marketplace_to_demand": 17.93241879,
    "grid_to_demand": 35492.84909,
    "govt_battery_charge": 18.96959801
  },
  {
    "timestamp": "25/11/24 4:00",
    "demand": 34283.7919,
    "solar_generated": 0,
    "wind_generated": 75.74826952,
    "marketplace_to_demand": 176.4976588,
    "grid_to_demand": 32884.87,
    "govt_battery_charge": 15.1056987
  },
  {
    "timestamp": "25/11/24 5:00",
    "demand": 29994.65946,
    "solar_generated": 0,
    "wind_generated": 78.18199908,
    "marketplace_to_demand": 72.95883322,
    "grid_to_demand": 32882.02837,
    "govt_battery_charge": 81.2695331
  },
  {
    "timestamp": "25/11/24 6:00",
    "demand": 30792.72448,
    "solar_generated": 22.50286701,
    "wind_generated": 53.80916751,
    "marketplace_to_demand": 3.770189745,
    "grid_to_demand": 31744.90005,
    "govt_battery_charge": 74.36816428
  },
  {
    "timestamp": "25/11/24 7:00",
    "demand": 33530.05384,
    "solar_generated": 22.22110167,
    "wind_generated": 62.13081184,
    "marketplace_to_demand": 95.5345346,
    "grid_to_demand": 33342.58772,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "25/11/24 8:00",
    "demand": 30721.83065,
    "solar_generated": 6.235992885,
    "wind_generated": 78.75498911,
    "marketplace_to_demand": 157.6281285,
    "grid_to_demand": 29911.35698,
    "govt_battery_charge": 70.52258707
  },
  {
    "timestamp": "25/11/24 9:00",
    "demand": 30297.4444,
    "solar_generated": 11.17457119,
    "wind_generated": 62.10706111,
    "marketplace_to_demand": 99.23056506,
    "grid_to_demand": 32770.01852,
    "govt_battery_charge": 63.53779158
  },
  {
    "timestamp": "25/11/24 10:00",
    "demand": 31212.01664,
    "solar_generated": 21.75508044,
    "wind_generated": 62.14630333,
    "marketplace_to_demand": 88.59266507,
    "grid_to_demand": 35961.68168,
    "govt_battery_charge": 30.62038895
  },
  {
    "timestamp": "25/11/24 11:00",
    "demand": 29979.03416,
    "solar_generated": 10.60449054,
    "wind_generated": 76.78647379,
    "marketplace_to_demand": 40.50691294,
    "grid_to_demand": 31941.40592,
    "govt_battery_charge": 75.74763797
  },
  {
    "timestamp": "25/11/24 12:00",
    "demand": 32622.23087,
    "solar_generated": 9.165154038,
    "wind_generated": 71.20221974,
    "marketplace_to_demand": 94.17275793,
    "grid_to_demand": 35977.39839,
    "govt_battery_charge": 50.44703951
  },
  {
    "timestamp": "25/11/24 13:00",
    "demand": 34789.53075,
    "solar_generated": 22.47816354,
    "wind_generated": 64.28300602,
    "marketplace_to_demand": 141.2789117,
    "grid_to_demand": 33254.04589,
    "govt_battery_charge": 73.94766931
  },
  {
    "timestamp": "25/11/24 14:00",
    "demand": 31827.56538,
    "solar_generated": 23.49301358,
    "wind_generated": 54.63407149,
    "marketplace_to_demand": 125.6837542,
    "grid_to_demand": 31232.49487,
    "govt_battery_charge": 41.30698867
  },
  {
    "timestamp": "25/11/24 15:00",
    "demand": 34073.61299,
    "solar_generated": 22.88333668,
    "wind_generated": 73.42546601,
    "marketplace_to_demand": 153.2904729,
    "grid_to_demand": 33872.71187,
    "govt_battery_charge": 44.95941401
  },
  {
    "timestamp": "25/11/24 16:00",
    "demand": 30129.32466,
    "solar_generated": 13.13351182,
    "wind_generated": 66.55521018,
    "marketplace_to_demand": 105.2418626,
    "grid_to_demand": 35818.66131,
    "govt_battery_charge": 42.1104333
  },
  {
    "timestamp": "25/11/24 17:00",
    "demand": 34935.89508,
    "solar_generated": 22.7892347,
    "wind_generated": 58.96964251,
    "marketplace_to_demand": 68.15214852,
    "grid_to_demand": 31832.71614,
    "govt_battery_charge": 35.40179454
  },
  {
    "timestamp": "25/11/24 18:00",
    "demand": 34825.5215,
    "solar_generated": 0,
    "wind_generated": 62.92775938,
    "marketplace_to_demand": 146.2406577,
    "grid_to_demand": 32041.93186,
    "govt_battery_charge": 25.39014989
  },
  {
    "timestamp": "25/11/24 19:00",
    "demand": 35746.04733,
    "solar_generated": 0,
    "wind_generated": 61.56651271,
    "marketplace_to_demand": 18.79741714,
    "grid_to_demand": 32244.80715,
    "govt_battery_charge": 27.93073478
  },
  {
    "timestamp": "25/11/24 20:00",
    "demand": 32331.06374,
    "solar_generated": 0,
    "wind_generated": 63.56152886,
    "marketplace_to_demand": 2.170904237,
    "grid_to_demand": 34606.30512,
    "govt_battery_charge": 84.54178342
  },
  {
    "timestamp": "25/11/24 21:00",
    "demand": 30345.31611,
    "solar_generated": 0,
    "wind_generated": 61.11257095,
    "marketplace_to_demand": 130.6204625,
    "grid_to_demand": 36366.58038,
    "govt_battery_charge": 56.4629331
  },
  {
    "timestamp": "25/11/24 22:00",
    "demand": 31013.33047,
    "solar_generated": 0,
    "wind_generated": 56.40453699,
    "marketplace_to_demand": 85.39608394,
    "grid_to_demand": 31640.29139,
    "govt_battery_charge": 89.24374688
  },
  {
    "timestamp": "25/11/24 23:00",
    "demand": 32448.60792,
    "solar_generated": 0,
    "wind_generated": 60.67554302,
    "marketplace_to_demand": 146.8489399,
    "grid_to_demand": 31887.05084,
    "govt_battery_charge": 19.94805328
  },
  {
    "timestamp": "26/11/24 0:00",
    "demand": 33721.01284,
    "solar_generated": 0,
    "wind_generated": 66.14215749,
    "marketplace_to_demand": 151.2116037,
    "grid_to_demand": 35216.11645,
    "govt_battery_charge": 66.69309938
  },
  {
    "timestamp": "26/11/24 1:00",
    "demand": 35012.79033,
    "solar_generated": 0,
    "wind_generated": 56.97757722,
    "marketplace_to_demand": 64.57038662,
    "grid_to_demand": 32174.1411,
    "govt_battery_charge": 80.87725603
  },
  {
    "timestamp": "26/11/24 2:00",
    "demand": 31987.09356,
    "solar_generated": 0,
    "wind_generated": 59.82217414,
    "marketplace_to_demand": 53.86295992,
    "grid_to_demand": 35801.99951,
    "govt_battery_charge": 12.68230881
  },
  {
    "timestamp": "26/11/24 3:00",
    "demand": 32518.92038,
    "solar_generated": 0,
    "wind_generated": 71.55173174,
    "marketplace_to_demand": 169.644383,
    "grid_to_demand": 34235.98212,
    "govt_battery_charge": 34.70172657
  },
  {
    "timestamp": "26/11/24 4:00",
    "demand": 33564.74962,
    "solar_generated": 0,
    "wind_generated": 60.40829173,
    "marketplace_to_demand": 185.2810688,
    "grid_to_demand": 35841.24813,
    "govt_battery_charge": 62.64483969
  },
  {
    "timestamp": "26/11/24 5:00",
    "demand": 31355.77749,
    "solar_generated": 0,
    "wind_generated": 62.10612133,
    "marketplace_to_demand": 33.02184191,
    "grid_to_demand": 35502.46622,
    "govt_battery_charge": 18.41037361
  },
  {
    "timestamp": "26/11/24 6:00",
    "demand": 31447.26998,
    "solar_generated": 16.02336817,
    "wind_generated": 67.15643497,
    "marketplace_to_demand": 95.03977393,
    "grid_to_demand": 33463.90309,
    "govt_battery_charge": 64.90571517
  },
  {
    "timestamp": "26/11/24 7:00",
    "demand": 35822.88425,
    "solar_generated": 9.733949913,
    "wind_generated": 73.73116318,
    "marketplace_to_demand": 123.4595327,
    "grid_to_demand": 32111.16844,
    "govt_battery_charge": 45.90304754
  },
  {
    "timestamp": "26/11/24 8:00",
    "demand": 30547.21542,
    "solar_generated": 6.921810593,
    "wind_generated": 74.99285959,
    "marketplace_to_demand": 135.5691018,
    "grid_to_demand": 32744.20513,
    "govt_battery_charge": 25.08557887
  },
  {
    "timestamp": "26/11/24 9:00",
    "demand": 34671.35262,
    "solar_generated": 19.7620153,
    "wind_generated": 59.77061115,
    "marketplace_to_demand": 28.09099286,
    "grid_to_demand": 35162.13728,
    "govt_battery_charge": 90.37163803
  },
  {
    "timestamp": "26/11/24 10:00",
    "demand": 30632.6588,
    "solar_generated": 21.77372523,
    "wind_generated": 59.34488329,
    "marketplace_to_demand": 82.60169913,
    "grid_to_demand": 32971.11056,
    "govt_battery_charge": 18.02076499
  },
  {
    "timestamp": "26/11/24 11:00",
    "demand": 33731.28669,
    "solar_generated": 22.47394307,
    "wind_generated": 62.70362938,
    "marketplace_to_demand": 165.1857198,
    "grid_to_demand": 34314.55124,
    "govt_battery_charge": 41.52655593
  },
  {
    "timestamp": "26/11/24 12:00",
    "demand": 32350.30224,
    "solar_generated": 13.69171531,
    "wind_generated": 59.89411412,
    "marketplace_to_demand": 162.9461594,
    "grid_to_demand": 33877.96248,
    "govt_battery_charge": 56.33615416
  },
  {
    "timestamp": "26/11/24 13:00",
    "demand": 33852.9777,
    "solar_generated": 18.75597778,
    "wind_generated": 76.10586317,
    "marketplace_to_demand": 17.17003049,
    "grid_to_demand": 34328.84609,
    "govt_battery_charge": 8.570679933
  },
  {
    "timestamp": "26/11/24 14:00",
    "demand": 30210.4759,
    "solar_generated": 8.925530009,
    "wind_generated": 56.7283291,
    "marketplace_to_demand": 192.1547924,
    "grid_to_demand": 29910.55549,
    "govt_battery_charge": 29.76180508
  },
  {
    "timestamp": "26/11/24 15:00",
    "demand": 32175.752,
    "solar_generated": 8.31111651,
    "wind_generated": 79.47952723,
    "marketplace_to_demand": 89.21239913,
    "grid_to_demand": 30057.43399,
    "govt_battery_charge": 23.54875075
  },
  {
    "timestamp": "26/11/24 16:00",
    "demand": 33691.60988,
    "solar_generated": 9.579057394,
    "wind_generated": 73.94826263,
    "marketplace_to_demand": 164.4322523,
    "grid_to_demand": 36367.49239,
    "govt_battery_charge": 50.52295935
  },
  {
    "timestamp": "26/11/24 17:00",
    "demand": 30042.98152,
    "solar_generated": 16.58189379,
    "wind_generated": 68.3302919,
    "marketplace_to_demand": 135.3088352,
    "grid_to_demand": 32666.03347,
    "govt_battery_charge": 97.43516314
  },
  {
    "timestamp": "26/11/24 18:00",
    "demand": 30567.44846,
    "solar_generated": 0,
    "wind_generated": 77.32211907,
    "marketplace_to_demand": 94.55257635,
    "grid_to_demand": 30587.44108,
    "govt_battery_charge": 36.08090839
  },
  {
    "timestamp": "26/11/24 19:00",
    "demand": 30113.37026,
    "solar_generated": 0,
    "wind_generated": 78.47955354,
    "marketplace_to_demand": 37.25053128,
    "grid_to_demand": 34359.03467,
    "govt_battery_charge": 94.58317343
  },
  {
    "timestamp": "26/11/24 20:00",
    "demand": 33015.03197,
    "solar_generated": 0,
    "wind_generated": 79.8908665,
    "marketplace_to_demand": 33.94441272,
    "grid_to_demand": 33962.63592,
    "govt_battery_charge": 79.78510972
  },
  {
    "timestamp": "26/11/24 21:00",
    "demand": 31339.05783,
    "solar_generated": 0,
    "wind_generated": 55.10932302,
    "marketplace_to_demand": 150.1992338,
    "grid_to_demand": 31369.17251,
    "govt_battery_charge": 44.35418602
  },
  {
    "timestamp": "26/11/24 22:00",
    "demand": 30437.19639,
    "solar_generated": 0,
    "wind_generated": 78.74744159,
    "marketplace_to_demand": 101.0737644,
    "grid_to_demand": 36311.65757,
    "govt_battery_charge": 60.8379743
  },
  {
    "timestamp": "26/11/24 23:00",
    "demand": 35572.08664,
    "solar_generated": 0,
    "wind_generated": 57.08660158,
    "marketplace_to_demand": 5.743490604,
    "grid_to_demand": 36516.55826,
    "govt_battery_charge": 67.13660908
  },
  {
    "timestamp": "27/11/24 0:00",
    "demand": 35705.28384,
    "solar_generated": 0,
    "wind_generated": 72.76980933,
    "marketplace_to_demand": 127.088826,
    "grid_to_demand": 30054.2588,
    "govt_battery_charge": 36.7584406
  },
  {
    "timestamp": "27/11/24 1:00",
    "demand": 32244.28285,
    "solar_generated": 0,
    "wind_generated": 77.43288958,
    "marketplace_to_demand": 63.07764188,
    "grid_to_demand": 30631.75593,
    "govt_battery_charge": 70.24028098
  },
  {
    "timestamp": "27/11/24 2:00",
    "demand": 30942.76282,
    "solar_generated": 0,
    "wind_generated": 69.77598425,
    "marketplace_to_demand": 62.93149707,
    "grid_to_demand": 36069.19864,
    "govt_battery_charge": 52.51176864
  },
  {
    "timestamp": "27/11/24 3:00",
    "demand": 30251.83736,
    "solar_generated": 0,
    "wind_generated": 66.06750965,
    "marketplace_to_demand": 154.1736835,
    "grid_to_demand": 35152.84226,
    "govt_battery_charge": 86.77175771
  },
  {
    "timestamp": "27/11/24 4:00",
    "demand": 30758.02429,
    "solar_generated": 0,
    "wind_generated": 76.12137829,
    "marketplace_to_demand": 32.26170263,
    "grid_to_demand": 35392.05485,
    "govt_battery_charge": 42.97742563
  },
  {
    "timestamp": "27/11/24 5:00",
    "demand": 34055.53521,
    "solar_generated": 0,
    "wind_generated": 57.16280216,
    "marketplace_to_demand": 183.721867,
    "grid_to_demand": 34669.58088,
    "govt_battery_charge": 20.01483356
  },
  {
    "timestamp": "27/11/24 6:00",
    "demand": 29970.8706,
    "solar_generated": 6.922934425,
    "wind_generated": 58.0957991,
    "marketplace_to_demand": 126.1030185,
    "grid_to_demand": 33132.30498,
    "govt_battery_charge": 10.56160639
  },
  {
    "timestamp": "27/11/24 7:00",
    "demand": 33753.65846,
    "solar_generated": 19.41593333,
    "wind_generated": 76.57370709,
    "marketplace_to_demand": 178.2592801,
    "grid_to_demand": 35785.71129,
    "govt_battery_charge": 12.36844825
  },
  {
    "timestamp": "27/11/24 8:00",
    "demand": 30753.26068,
    "solar_generated": 21.42708508,
    "wind_generated": 65.31302352,
    "marketplace_to_demand": 50.97633317,
    "grid_to_demand": 35066.19026,
    "govt_battery_charge": 91.55601991
  },
  {
    "timestamp": "27/11/24 9:00",
    "demand": 36383.42247,
    "solar_generated": 10.82798697,
    "wind_generated": 58.5936272,
    "marketplace_to_demand": 8.929241621,
    "grid_to_demand": 35988.72522,
    "govt_battery_charge": 88.8098841
  },
  {
    "timestamp": "27/11/24 10:00",
    "demand": 30751.23094,
    "solar_generated": 15.72076224,
    "wind_generated": 79.12615778,
    "marketplace_to_demand": 36.66177274,
    "grid_to_demand": 31905.69383,
    "govt_battery_charge": 98.70369079
  },
  {
    "timestamp": "27/11/24 11:00",
    "demand": 31343.63498,
    "solar_generated": 10.1129789,
    "wind_generated": 72.2650982,
    "marketplace_to_demand": 66.53346656,
    "grid_to_demand": 32881.23211,
    "govt_battery_charge": 3.848529331
  },
  {
    "timestamp": "27/11/24 12:00",
    "demand": 30952.5138,
    "solar_generated": 11.36999319,
    "wind_generated": 73.37469592,
    "marketplace_to_demand": 145.0606723,
    "grid_to_demand": 33196.51989,
    "govt_battery_charge": 83.52879507
  },
  {
    "timestamp": "27/11/24 13:00",
    "demand": 32607.07649,
    "solar_generated": 8.188609394,
    "wind_generated": 78.02431518,
    "marketplace_to_demand": 15.89374292,
    "grid_to_demand": 35040.26622,
    "govt_battery_charge": 20.16871347
  },
  {
    "timestamp": "27/11/24 14:00",
    "demand": 34744.70654,
    "solar_generated": 21.5736901,
    "wind_generated": 71.17822999,
    "marketplace_to_demand": 52.32752096,
    "grid_to_demand": 33463.38418,
    "govt_battery_charge": 32.14744497
  },
  {
    "timestamp": "27/11/24 15:00",
    "demand": 32475.32259,
    "solar_generated": 9.23226453,
    "wind_generated": 59.22359091,
    "marketplace_to_demand": 121.9823433,
    "grid_to_demand": 30724.8468,
    "govt_battery_charge": 32.77754386
  },
  {
    "timestamp": "27/11/24 16:00",
    "demand": 35596.5452,
    "solar_generated": 10.58832721,
    "wind_generated": 62.62820484,
    "marketplace_to_demand": 25.84475081,
    "grid_to_demand": 33379.45943,
    "govt_battery_charge": 95.12617419
  },
  {
    "timestamp": "27/11/24 17:00",
    "demand": 31943.32519,
    "solar_generated": 22.57658812,
    "wind_generated": 54.07185318,
    "marketplace_to_demand": 54.50414195,
    "grid_to_demand": 31731.67716,
    "govt_battery_charge": 27.17709984
  },
  {
    "timestamp": "27/11/24 18:00",
    "demand": 34484.76627,
    "solar_generated": 0,
    "wind_generated": 69.09387024,
    "marketplace_to_demand": 33.13594363,
    "grid_to_demand": 33836.76366,
    "govt_battery_charge": 61.82636816
  },
  {
    "timestamp": "27/11/24 19:00",
    "demand": 33288.37841,
    "solar_generated": 0,
    "wind_generated": 59.33065507,
    "marketplace_to_demand": 31.14554441,
    "grid_to_demand": 33087.53892,
    "govt_battery_charge": 27.33690057
  },
  {
    "timestamp": "27/11/24 20:00",
    "demand": 35726.90197,
    "solar_generated": 0,
    "wind_generated": 64.48581748,
    "marketplace_to_demand": 65.50819931,
    "grid_to_demand": 31380.6408,
    "govt_battery_charge": 18.60191463
  },
  {
    "timestamp": "27/11/24 21:00",
    "demand": 36306.97521,
    "solar_generated": 0,
    "wind_generated": 64.42016543,
    "marketplace_to_demand": 47.96825831,
    "grid_to_demand": 31990.83285,
    "govt_battery_charge": 6.710019028
  },
  {
    "timestamp": "27/11/24 22:00",
    "demand": 36473.51037,
    "solar_generated": 0,
    "wind_generated": 55.84528594,
    "marketplace_to_demand": 163.2642627,
    "grid_to_demand": 30240.5079,
    "govt_battery_charge": 67.28138754
  },
  {
    "timestamp": "27/11/24 23:00",
    "demand": 34380.34161,
    "solar_generated": 0,
    "wind_generated": 68.06136317,
    "marketplace_to_demand": 120.0279406,
    "grid_to_demand": 35891.44683,
    "govt_battery_charge": 37.84785754
  },
  {
    "timestamp": "28/11/24 0:00",
    "demand": 32450.83825,
    "solar_generated": 0,
    "wind_generated": 69.80110468,
    "marketplace_to_demand": 138.6875122,
    "grid_to_demand": 34691.80534,
    "govt_battery_charge": 62.8947026
  },
  {
    "timestamp": "28/11/24 1:00",
    "demand": 34357.99229,
    "solar_generated": 0,
    "wind_generated": 72.30463688,
    "marketplace_to_demand": 171.8122177,
    "grid_to_demand": 30811.95401,
    "govt_battery_charge": 13.35594544
  },
  {
    "timestamp": "28/11/24 2:00",
    "demand": 32270.17488,
    "solar_generated": 0,
    "wind_generated": 58.97088452,
    "marketplace_to_demand": 135.5340796,
    "grid_to_demand": 31076.03586,
    "govt_battery_charge": 77.69420826
  },
  {
    "timestamp": "28/11/24 3:00",
    "demand": 32611.79323,
    "solar_generated": 0,
    "wind_generated": 75.13562602,
    "marketplace_to_demand": 14.05053059,
    "grid_to_demand": 32363.22782,
    "govt_battery_charge": 94.13522592
  },
  {
    "timestamp": "28/11/24 4:00",
    "demand": 31109.68503,
    "solar_generated": 0,
    "wind_generated": 73.10902692,
    "marketplace_to_demand": 65.03727766,
    "grid_to_demand": 30286.17559,
    "govt_battery_charge": 53.87208583
  },
  {
    "timestamp": "28/11/24 5:00",
    "demand": 32427.65973,
    "solar_generated": 0,
    "wind_generated": 62.73408212,
    "marketplace_to_demand": 154.0961363,
    "grid_to_demand": 30286.06344,
    "govt_battery_charge": 54.22997717
  },
  {
    "timestamp": "28/11/24 6:00",
    "demand": 35204.67591,
    "solar_generated": 7.465274947,
    "wind_generated": 65.73661436,
    "marketplace_to_demand": 152.2003942,
    "grid_to_demand": 35447.65925,
    "govt_battery_charge": 55.48179171
  },
  {
    "timestamp": "28/11/24 7:00",
    "demand": 32692.53436,
    "solar_generated": 6.407200735,
    "wind_generated": 58.9318748,
    "marketplace_to_demand": 16.58155719,
    "grid_to_demand": 33842.734,
    "govt_battery_charge": 38.02893362
  },
  {
    "timestamp": "28/11/24 8:00",
    "demand": 30878.8172,
    "solar_generated": 9.732509842,
    "wind_generated": 62.72837745,
    "marketplace_to_demand": 62.25861578,
    "grid_to_demand": 30589.4783,
    "govt_battery_charge": 61.53611985
  },
  {
    "timestamp": "28/11/24 9:00",
    "demand": 35023.72192,
    "solar_generated": 15.61132527,
    "wind_generated": 70.16805996,
    "marketplace_to_demand": 191.1345303,
    "grid_to_demand": 30942.72472,
    "govt_battery_charge": 18.0539145
  },
  {
    "timestamp": "28/11/24 10:00",
    "demand": 32849.23729,
    "solar_generated": 15.07070073,
    "wind_generated": 58.48875435,
    "marketplace_to_demand": 141.8027238,
    "grid_to_demand": 32226.3701,
    "govt_battery_charge": 83.60751049
  },
  {
    "timestamp": "28/11/24 11:00",
    "demand": 35262.22174,
    "solar_generated": 22.54459509,
    "wind_generated": 65.0452295,
    "marketplace_to_demand": 174.3269405,
    "grid_to_demand": 36226.96584,
    "govt_battery_charge": 34.6921271
  },
  {
    "timestamp": "28/11/24 12:00",
    "demand": 34631.76353,
    "solar_generated": 7.793137617,
    "wind_generated": 64.99245148,
    "marketplace_to_demand": 1.659601706,
    "grid_to_demand": 31310.30802,
    "govt_battery_charge": 59.31671578
  },
  {
    "timestamp": "28/11/24 13:00",
    "demand": 34827.59199,
    "solar_generated": 12.77379527,
    "wind_generated": 72.02460877,
    "marketplace_to_demand": 186.9114926,
    "grid_to_demand": 30386.43209,
    "govt_battery_charge": 75.53882139
  },
  {
    "timestamp": "28/11/24 14:00",
    "demand": 33318.15776,
    "solar_generated": 16.6999631,
    "wind_generated": 55.75900891,
    "marketplace_to_demand": 76.66592326,
    "grid_to_demand": 34245.37665,
    "govt_battery_charge": 48.23842105
  },
  {
    "timestamp": "28/11/24 15:00",
    "demand": 34676.31594,
    "solar_generated": 17.23658809,
    "wind_generated": 54.40198975,
    "marketplace_to_demand": 123.0478096,
    "grid_to_demand": 34436.17481,
    "govt_battery_charge": 37.8052473
  },
  {
    "timestamp": "28/11/24 16:00",
    "demand": 32504.94879,
    "solar_generated": 6.288348286,
    "wind_generated": 59.22712284,
    "marketplace_to_demand": 185.9241153,
    "grid_to_demand": 34411.24598,
    "govt_battery_charge": 50.49680727
  },
  {
    "timestamp": "28/11/24 17:00",
    "demand": 33223.59944,
    "solar_generated": 19.44090553,
    "wind_generated": 66.87217347,
    "marketplace_to_demand": 51.94634401,
    "grid_to_demand": 30799.74629,
    "govt_battery_charge": 17.79013784
  },
  {
    "timestamp": "28/11/24 18:00",
    "demand": 33464.89773,
    "solar_generated": 0,
    "wind_generated": 62.42780662,
    "marketplace_to_demand": 129.4047087,
    "grid_to_demand": 32208.84563,
    "govt_battery_charge": 49.07539842
  },
  {
    "timestamp": "28/11/24 19:00",
    "demand": 36307.09741,
    "solar_generated": 0,
    "wind_generated": 69.78861237,
    "marketplace_to_demand": 94.00241259,
    "grid_to_demand": 33751.35475,
    "govt_battery_charge": 48.19718715
  },
  {
    "timestamp": "28/11/24 20:00",
    "demand": 36541.34523,
    "solar_generated": 0,
    "wind_generated": 59.48346224,
    "marketplace_to_demand": 102.0400375,
    "grid_to_demand": 34196.55715,
    "govt_battery_charge": 27.71491147
  },
  {
    "timestamp": "28/11/24 21:00",
    "demand": 32925.38319,
    "solar_generated": 0,
    "wind_generated": 78.53615346,
    "marketplace_to_demand": 45.8649503,
    "grid_to_demand": 34726.164,
    "govt_battery_charge": 54.07157567
  },
  {
    "timestamp": "28/11/24 22:00",
    "demand": 35179.49181,
    "solar_generated": 0,
    "wind_generated": 63.02464704,
    "marketplace_to_demand": 149.5038243,
    "grid_to_demand": 34971.38299,
    "govt_battery_charge": 24.30550494
  },
  {
    "timestamp": "28/11/24 23:00",
    "demand": 35685.80606,
    "solar_generated": 0,
    "wind_generated": 59.27688916,
    "marketplace_to_demand": 91.20676001,
    "grid_to_demand": 30252.12741,
    "govt_battery_charge": 17.13059188
  },
  {
    "timestamp": "29/11/24 0:00",
    "demand": 31044.03649,
    "solar_generated": 0,
    "wind_generated": 77.16820728,
    "marketplace_to_demand": 195.2792125,
    "grid_to_demand": 34382.16124,
    "govt_battery_charge": 93.6621016
  },
  {
    "timestamp": "29/11/24 1:00",
    "demand": 36191.33574,
    "solar_generated": 0,
    "wind_generated": 64.46283181,
    "marketplace_to_demand": 22.90758001,
    "grid_to_demand": 31729.81119,
    "govt_battery_charge": 66.57687558
  },
  {
    "timestamp": "29/11/24 2:00",
    "demand": 36469.95269,
    "solar_generated": 0,
    "wind_generated": 68.0372337,
    "marketplace_to_demand": 184.1992909,
    "grid_to_demand": 31309.86749,
    "govt_battery_charge": 49.63195994
  },
  {
    "timestamp": "29/11/24 3:00",
    "demand": 31180.35509,
    "solar_generated": 0,
    "wind_generated": 55.12516783,
    "marketplace_to_demand": 8.022613017,
    "grid_to_demand": 35813.51824,
    "govt_battery_charge": 49.96386704
  },
  {
    "timestamp": "29/11/24 4:00",
    "demand": 32680.87368,
    "solar_generated": 0,
    "wind_generated": 60.39079655,
    "marketplace_to_demand": 80.8796679,
    "grid_to_demand": 31246.2692,
    "govt_battery_charge": 15.26170082
  },
  {
    "timestamp": "29/11/24 5:00",
    "demand": 30452.24775,
    "solar_generated": 0,
    "wind_generated": 62.59986761,
    "marketplace_to_demand": 100.5563864,
    "grid_to_demand": 33002.20625,
    "govt_battery_charge": 21.70841358
  },
  {
    "timestamp": "29/11/24 6:00",
    "demand": 33449.54941,
    "solar_generated": 7.94115608,
    "wind_generated": 57.2082301,
    "marketplace_to_demand": 146.2232057,
    "grid_to_demand": 35882.69806,
    "govt_battery_charge": 55.57065068
  },
  {
    "timestamp": "29/11/24 7:00",
    "demand": 32329.14408,
    "solar_generated": 18.54572282,
    "wind_generated": 64.30657286,
    "marketplace_to_demand": 28.75214912,
    "grid_to_demand": 34272.01561,
    "govt_battery_charge": 61.03811688
  },
  {
    "timestamp": "29/11/24 8:00",
    "demand": 32914.95732,
    "solar_generated": 12.64196067,
    "wind_generated": 79.74147794,
    "marketplace_to_demand": 38.38284885,
    "grid_to_demand": 30564.67326,
    "govt_battery_charge": 30.03613931
  },
  {
    "timestamp": "29/11/24 9:00",
    "demand": 31128.73309,
    "solar_generated": 9.156597555,
    "wind_generated": 62.35551553,
    "marketplace_to_demand": 154.5687579,
    "grid_to_demand": 35174.09157,
    "govt_battery_charge": 35.24666491
  },
  {
    "timestamp": "29/11/24 10:00",
    "demand": 32443.505,
    "solar_generated": 22.70515162,
    "wind_generated": 58.62067547,
    "marketplace_to_demand": 112.5253267,
    "grid_to_demand": 32514.73348,
    "govt_battery_charge": 46.97092264
  },
  {
    "timestamp": "29/11/24 11:00",
    "demand": 35803.93577,
    "solar_generated": 12.4740618,
    "wind_generated": 63.87788086,
    "marketplace_to_demand": 73.16095731,
    "grid_to_demand": 30049.625,
    "govt_battery_charge": 40.19163088
  },
  {
    "timestamp": "29/11/24 12:00",
    "demand": 30943.76656,
    "solar_generated": 6.187004422,
    "wind_generated": 54.05529512,
    "marketplace_to_demand": 175.9723763,
    "grid_to_demand": 32314.85757,
    "govt_battery_charge": 51.94085863
  },
  {
    "timestamp": "29/11/24 13:00",
    "demand": 32379.55313,
    "solar_generated": 21.86891244,
    "wind_generated": 61.24780007,
    "marketplace_to_demand": 51.94240159,
    "grid_to_demand": 34768.07314,
    "govt_battery_charge": 92.91042066
  },
  {
    "timestamp": "29/11/24 14:00",
    "demand": 33991.70088,
    "solar_generated": 10.6261924,
    "wind_generated": 65.76043906,
    "marketplace_to_demand": 29.13061802,
    "grid_to_demand": 34945.83733,
    "govt_battery_charge": 19.87479482
  },
  {
    "timestamp": "29/11/24 15:00",
    "demand": 30505.56865,
    "solar_generated": 22.4481812,
    "wind_generated": 76.87589077,
    "marketplace_to_demand": 35.20740211,
    "grid_to_demand": 34626.0997,
    "govt_battery_charge": 93.37542948
  },
  {
    "timestamp": "29/11/24 16:00",
    "demand": 34419.88407,
    "solar_generated": 18.64152468,
    "wind_generated": 74.91486224,
    "marketplace_to_demand": 140.3191599,
    "grid_to_demand": 34155.8946,
    "govt_battery_charge": 21.30899042
  },
  {
    "timestamp": "29/11/24 17:00",
    "demand": 36032.0708,
    "solar_generated": 14.62144658,
    "wind_generated": 67.56229181,
    "marketplace_to_demand": 80.13025123,
    "grid_to_demand": 32978.88019,
    "govt_battery_charge": 74.002561
  },
  {
    "timestamp": "29/11/24 18:00",
    "demand": 35043.81562,
    "solar_generated": 0,
    "wind_generated": 69.05069774,
    "marketplace_to_demand": 43.70189775,
    "grid_to_demand": 34088.7567,
    "govt_battery_charge": 83.03556065
  },
  {
    "timestamp": "29/11/24 19:00",
    "demand": 33222.64582,
    "solar_generated": 0,
    "wind_generated": 71.53930516,
    "marketplace_to_demand": 102.010805,
    "grid_to_demand": 34399.07363,
    "govt_battery_charge": 25.86550888
  },
  {
    "timestamp": "29/11/24 20:00",
    "demand": 30485.32062,
    "solar_generated": 0,
    "wind_generated": 66.96410076,
    "marketplace_to_demand": 186.3955948,
    "grid_to_demand": 30952.11755,
    "govt_battery_charge": 21.65145673
  },
  {
    "timestamp": "29/11/24 21:00",
    "demand": 31906.93786,
    "solar_generated": 0,
    "wind_generated": 73.85005077,
    "marketplace_to_demand": 2.61650612,
    "grid_to_demand": 35194.8131,
    "govt_battery_charge": 44.31275647
  },
  {
    "timestamp": "29/11/24 22:00",
    "demand": 33130.6102,
    "solar_generated": 0,
    "wind_generated": 59.41811981,
    "marketplace_to_demand": 109.2134281,
    "grid_to_demand": 36259.2907,
    "govt_battery_charge": 65.47498753
  },
  {
    "timestamp": "29/11/24 23:00",
    "demand": 36332.68747,
    "solar_generated": 0,
    "wind_generated": 73.24602085,
    "marketplace_to_demand": 1.416603885,
    "grid_to_demand": 30067.48071,
    "govt_battery_charge": 8.311459262
  },
  {
    "timestamp": "30/11/24 0:00",
    "demand": 30025.95462,
    "solar_generated": 0,
    "wind_generated": 67.8225776,
    "marketplace_to_demand": 114.7808154,
    "grid_to_demand": 34964.29179,
    "govt_battery_charge": 70.31895532
  },
  {
    "timestamp": "30/11/24 1:00",
    "demand": 35625.89376,
    "solar_generated": 0,
    "wind_generated": 56.41995527,
    "marketplace_to_demand": 30.10985367,
    "grid_to_demand": 36455.63072,
    "govt_battery_charge": 81.9053948
  },
  {
    "timestamp": "30/11/24 2:00",
    "demand": 31675.60921,
    "solar_generated": 0,
    "wind_generated": 68.23312026,
    "marketplace_to_demand": 141.9030595,
    "grid_to_demand": 36464.46281,
    "govt_battery_charge": 14.95935752
  },
  {
    "timestamp": "30/11/24 3:00",
    "demand": 35316.35594,
    "solar_generated": 0,
    "wind_generated": 55.95325424,
    "marketplace_to_demand": 6.217912308,
    "grid_to_demand": 31770.18707,
    "govt_battery_charge": 10.14841209
  },
  {
    "timestamp": "30/11/24 4:00",
    "demand": 36083.75538,
    "solar_generated": 0,
    "wind_generated": 54.14237989,
    "marketplace_to_demand": 112.8040066,
    "grid_to_demand": 35634.91337,
    "govt_battery_charge": 10.63119084
  },
  {
    "timestamp": "30/11/24 5:00",
    "demand": 33638.34797,
    "solar_generated": 0,
    "wind_generated": 77.8803746,
    "marketplace_to_demand": 117.9070208,
    "grid_to_demand": 31931.26478,
    "govt_battery_charge": 30.06918599
  },
  {
    "timestamp": "30/11/24 6:00",
    "demand": 30236.81044,
    "solar_generated": 20.02119909,
    "wind_generated": 71.00444217,
    "marketplace_to_demand": 0.754035122,
    "grid_to_demand": 35026.2784,
    "govt_battery_charge": 8.74042294
  },
  {
    "timestamp": "30/11/24 7:00",
    "demand": 35778.70848,
    "solar_generated": 6.15871169,
    "wind_generated": 56.8294666,
    "marketplace_to_demand": 59.75939321,
    "grid_to_demand": 35368.21273,
    "govt_battery_charge": 24.90500263
  },
  {
    "timestamp": "30/11/24 8:00",
    "demand": 33222.07372,
    "solar_generated": 13.03042116,
    "wind_generated": 54.18817144,
    "marketplace_to_demand": 157.571839,
    "grid_to_demand": 35633.97732,
    "govt_battery_charge": 54.80392159
  },
  {
    "timestamp": "30/11/24 9:00",
    "demand": 32964.65851,
    "solar_generated": 15.03416914,
    "wind_generated": 73.83942342,
    "marketplace_to_demand": 51.72366401,
    "grid_to_demand": 34636.4505,
    "govt_battery_charge": 60.01564581
  },
  {
    "timestamp": "30/11/24 10:00",
    "demand": 30338.90978,
    "solar_generated": 23.19819173,
    "wind_generated": 54.80205038,
    "marketplace_to_demand": 93.20820093,
    "grid_to_demand": 34723.18736,
    "govt_battery_charge": 66.26226734
  },
  {
    "timestamp": "30/11/24 11:00",
    "demand": 30251.80002,
    "solar_generated": 13.19633075,
    "wind_generated": 53.64114827,
    "marketplace_to_demand": 144.0023254,
    "grid_to_demand": 33652.19254,
    "govt_battery_charge": 6.551864768
  },
  {
    "timestamp": "30/11/24 12:00",
    "demand": 33346.04343,
    "solar_generated": 10.69609924,
    "wind_generated": 67.71336345,
    "marketplace_to_demand": 195.1209881,
    "grid_to_demand": 33028.53098,
    "govt_battery_charge": 81.97067591
  },
  {
    "timestamp": "30/11/24 13:00",
    "demand": 35945.12122,
    "solar_generated": 14.38455926,
    "wind_generated": 58.56226177,
    "marketplace_to_demand": 99.89891827,
    "grid_to_demand": 35314.08293,
    "govt_battery_charge": 10.8717341
  },
  {
    "timestamp": "30/11/24 14:00",
    "demand": 36388.4178,
    "solar_generated": 10.19032896,
    "wind_generated": 73.58434034,
    "marketplace_to_demand": 62.7926759,
    "grid_to_demand": 33169.50006,
    "govt_battery_charge": 38.62370612
  },
  {
    "timestamp": "30/11/24 15:00",
    "demand": 32848.17829,
    "solar_generated": 12.75840857,
    "wind_generated": 74.14280728,
    "marketplace_to_demand": 37.03495112,
    "grid_to_demand": 34282.34442,
    "govt_battery_charge": 79.92506505
  },
  {
    "timestamp": "30/11/24 16:00",
    "demand": 31504.62334,
    "solar_generated": 8.094788223,
    "wind_generated": 69.816249,
    "marketplace_to_demand": 181.6591051,
    "grid_to_demand": 31036.48867,
    "govt_battery_charge": 15.7143156
  },
  {
    "timestamp": "30/11/24 17:00",
    "demand": 33847.79817,
    "solar_generated": 7.660912824,
    "wind_generated": 77.11158554,
    "marketplace_to_demand": 92.87674369,
    "grid_to_demand": 33139.59123,
    "govt_battery_charge": 20.4841761
  },
  {
    "timestamp": "30/11/24 18:00",
    "demand": 35369.88707,
    "solar_generated": 0,
    "wind_generated": 60.35984787,
    "marketplace_to_demand": 176.5710009,
    "grid_to_demand": 33815.42519,
    "govt_battery_charge": 71.23888792
  },
  {
    "timestamp": "30/11/24 19:00",
    "demand": 35276.40637,
    "solar_generated": 0,
    "wind_generated": 69.29756637,
    "marketplace_to_demand": 166.1849397,
    "grid_to_demand": 30051.69112,
    "govt_battery_charge": 78.50505108
  },
  {
    "timestamp": "30/11/24 20:00",
    "demand": 30712.51231,
    "solar_generated": 0,
    "wind_generated": 60.09246405,
    "marketplace_to_demand": 145.5348814,
    "grid_to_demand": 33099.25281,
    "govt_battery_charge": 70.24649422
  },
  {
    "timestamp": "30/11/24 21:00",
    "demand": 32395.42015,
    "solar_generated": 0,
    "wind_generated": 76.27619886,
    "marketplace_to_demand": 77.35065706,
    "grid_to_demand": 31474.86922,
    "govt_battery_charge": 21.11635269
  },
  {
    "timestamp": "30/11/24 22:00",
    "demand": 30703.90809,
    "solar_generated": 0,
    "wind_generated": 66.55607471,
    "marketplace_to_demand": 111.7940191,
    "grid_to_demand": 31187.48426,
    "govt_battery_charge": 47.86915206
  },
  {
    "timestamp": "30/11/24 23:00",
    "demand": 32389.43186,
    "solar_generated": 0,
    "wind_generated": 78.27885285,
    "marketplace_to_demand": 133.7302322,
    "grid_to_demand": 34772.91349,
    "govt_battery_charge": 86.45780456
  },
  {
    "timestamp": "01/12/24 0:00",
    "demand": 33266.55589,
    "solar_generated": 0,
    "wind_generated": 61.68776254,
    "marketplace_to_demand": 153.8769634,
    "grid_to_demand": 35194.41055,
    "govt_battery_charge": 79.0274903
  },
  {
    "timestamp": "01/12/24 1:00",
    "demand": 33113.53413,
    "solar_generated": 0,
    "wind_generated": 78.96311052,
    "marketplace_to_demand": 145.5442556,
    "grid_to_demand": 30868.23146,
    "govt_battery_charge": 97.25325193
  },
  {
    "timestamp": "01/12/24 2:00",
    "demand": 31942.79306,
    "solar_generated": 0,
    "wind_generated": 74.16901681,
    "marketplace_to_demand": 35.07072778,
    "grid_to_demand": 34526.53255,
    "govt_battery_charge": 93.91753808
  },
  {
    "timestamp": "01/12/24 3:00",
    "demand": 35130.64416,
    "solar_generated": 0,
    "wind_generated": 54.16286392,
    "marketplace_to_demand": 187.0730521,
    "grid_to_demand": 34053.02907,
    "govt_battery_charge": 71.79036158
  },
  {
    "timestamp": "01/12/24 4:00",
    "demand": 35813.40277,
    "solar_generated": 0,
    "wind_generated": 63.68859247,
    "marketplace_to_demand": 169.9134425,
    "grid_to_demand": 30962.52905,
    "govt_battery_charge": 74.2171692
  },
  {
    "timestamp": "01/12/24 5:00",
    "demand": 34825.14562,
    "solar_generated": 0,
    "wind_generated": 64.05354171,
    "marketplace_to_demand": 112.0105016,
    "grid_to_demand": 30748.18814,
    "govt_battery_charge": 35.66770381
  },
  {
    "timestamp": "01/12/24 6:00",
    "demand": 35360.66993,
    "solar_generated": 12.70695295,
    "wind_generated": 71.68017105,
    "marketplace_to_demand": 62.88100628,
    "grid_to_demand": 35563.32634,
    "govt_battery_charge": 94.25988833
  },
  {
    "timestamp": "01/12/24 7:00",
    "demand": 36496.69125,
    "solar_generated": 8.22606468,
    "wind_generated": 64.65070599,
    "marketplace_to_demand": 0.956867079,
    "grid_to_demand": 32847.61137,
    "govt_battery_charge": 62.88275828
  },
  {
    "timestamp": "01/12/24 8:00",
    "demand": 32718.23793,
    "solar_generated": 6.934315795,
    "wind_generated": 66.75947704,
    "marketplace_to_demand": 109.6299376,
    "grid_to_demand": 35251.14038,
    "govt_battery_charge": 86.01812413
  },
  {
    "timestamp": "01/12/24 9:00",
    "demand": 35060.5906,
    "solar_generated": 13.82431855,
    "wind_generated": 67.61772999,
    "marketplace_to_demand": 98.3741468,
    "grid_to_demand": 35997.43374,
    "govt_battery_charge": 87.10390152
  },
  {
    "timestamp": "01/12/24 10:00",
    "demand": 33698.09092,
    "solar_generated": 23.39387402,
    "wind_generated": 74.92755693,
    "marketplace_to_demand": 19.03298774,
    "grid_to_demand": 35446.17646,
    "govt_battery_charge": 37.82312239
  },
  {
    "timestamp": "01/12/24 11:00",
    "demand": 35345.92754,
    "solar_generated": 23.59510498,
    "wind_generated": 56.66645914,
    "marketplace_to_demand": 63.32879912,
    "grid_to_demand": 32636.51305,
    "govt_battery_charge": 36.26495185
  },
  {
    "timestamp": "01/12/24 12:00",
    "demand": 32152.93231,
    "solar_generated": 23.88732631,
    "wind_generated": 72.84891095,
    "marketplace_to_demand": 159.3285335,
    "grid_to_demand": 33722.05893,
    "govt_battery_charge": 1.01028794
  },
  {
    "timestamp": "01/12/24 13:00",
    "demand": 30821.75393,
    "solar_generated": 11.85430603,
    "wind_generated": 56.95799275,
    "marketplace_to_demand": 139.9891091,
    "grid_to_demand": 36182.46825,
    "govt_battery_charge": 54.06822285
  },
  {
    "timestamp": "01/12/24 14:00",
    "demand": 34010.41039,
    "solar_generated": 14.33873238,
    "wind_generated": 67.48652667,
    "marketplace_to_demand": 99.65520626,
    "grid_to_demand": 34083.14837,
    "govt_battery_charge": 90.50100865
  },
  {
    "timestamp": "01/12/24 15:00",
    "demand": 35295.05476,
    "solar_generated": 22.06581766,
    "wind_generated": 65.79852516,
    "marketplace_to_demand": 41.58472324,
    "grid_to_demand": 35609.12752,
    "govt_battery_charge": 34.00307642
  },
  {
    "timestamp": "01/12/24 16:00",
    "demand": 34459.28256,
    "solar_generated": 14.76845727,
    "wind_generated": 57.02592471,
    "marketplace_to_demand": 86.2981948,
    "grid_to_demand": 34193.3588,
    "govt_battery_charge": 27.76929699
  },
  {
    "timestamp": "01/12/24 17:00",
    "demand": 32027.73712,
    "solar_generated": 11.48247602,
    "wind_generated": 74.79625549,
    "marketplace_to_demand": 160.4903813,
    "grid_to_demand": 31044.56524,
    "govt_battery_charge": 73.52588791
  },
  {
    "timestamp": "01/12/24 18:00",
    "demand": 33206.71059,
    "solar_generated": 0,
    "wind_generated": 61.28219169,
    "marketplace_to_demand": 38.92163366,
    "grid_to_demand": 33884.62612,
    "govt_battery_charge": 8.375672977
  },
  {
    "timestamp": "01/12/24 19:00",
    "demand": 31697.97848,
    "solar_generated": 0,
    "wind_generated": 66.16474965,
    "marketplace_to_demand": 167.4337379,
    "grid_to_demand": 31198.40925,
    "govt_battery_charge": 34.98286499
  },
  {
    "timestamp": "01/12/24 20:00",
    "demand": 32275.01177,
    "solar_generated": 0,
    "wind_generated": 62.38508947,
    "marketplace_to_demand": 102.2909939,
    "grid_to_demand": 35201.6113,
    "govt_battery_charge": 31.99139369
  },
  {
    "timestamp": "01/12/24 21:00",
    "demand": 32509.85608,
    "solar_generated": 0,
    "wind_generated": 68.65094298,
    "marketplace_to_demand": 45.53063002,
    "grid_to_demand": 34167.79016,
    "govt_battery_charge": 3.775808048
  },
  {
    "timestamp": "01/12/24 22:00",
    "demand": 35922.4799,
    "solar_generated": 0,
    "wind_generated": 76.48409379,
    "marketplace_to_demand": 81.30603593,
    "grid_to_demand": 30292.89265,
    "govt_battery_charge": 89.6095079
  },
  {
    "timestamp": "01/12/24 23:00",
    "demand": 32511.47743,
    "solar_generated": 0,
    "wind_generated": 73.30648269,
    "marketplace_to_demand": 68.7795223,
    "grid_to_demand": 33492.89378,
    "govt_battery_charge": 27.8083237
  },
  {
    "timestamp": "02/12/24 0:00",
    "demand": 31705.61823,
    "solar_generated": 0,
    "wind_generated": 66.27429108,
    "marketplace_to_demand": 15.62581518,
    "grid_to_demand": 36500.45012,
    "govt_battery_charge": 90.47909997
  },
  {
    "timestamp": "02/12/24 1:00",
    "demand": 34633.93275,
    "solar_generated": 0,
    "wind_generated": 70.2871327,
    "marketplace_to_demand": 26.98756202,
    "grid_to_demand": 30563.85136,
    "govt_battery_charge": 80.09826742
  },
  {
    "timestamp": "02/12/24 2:00",
    "demand": 35975.83679,
    "solar_generated": 0,
    "wind_generated": 67.36929933,
    "marketplace_to_demand": 84.73974176,
    "grid_to_demand": 33540.73816,
    "govt_battery_charge": 45.83860116
  },
  {
    "timestamp": "02/12/24 3:00",
    "demand": 35032.63932,
    "solar_generated": 0,
    "wind_generated": 73.04674501,
    "marketplace_to_demand": 5.009089297,
    "grid_to_demand": 35621.3476,
    "govt_battery_charge": 87.98391891
  },
  {
    "timestamp": "02/12/24 4:00",
    "demand": 34320.36796,
    "solar_generated": 0,
    "wind_generated": 76.15957899,
    "marketplace_to_demand": 95.6520097,
    "grid_to_demand": 31052.93341,
    "govt_battery_charge": 47.81219842
  },
  {
    "timestamp": "02/12/24 5:00",
    "demand": 33259.11611,
    "solar_generated": 0,
    "wind_generated": 60.62032354,
    "marketplace_to_demand": 23.35230023,
    "grid_to_demand": 31985.53428,
    "govt_battery_charge": 46.30940584
  },
  {
    "timestamp": "02/12/24 6:00",
    "demand": 29975.75331,
    "solar_generated": 19.12986046,
    "wind_generated": 74.01679734,
    "marketplace_to_demand": 57.81296495,
    "grid_to_demand": 33388.75834,
    "govt_battery_charge": 93.6293426
  },
  {
    "timestamp": "02/12/24 7:00",
    "demand": 34330.34869,
    "solar_generated": 7.68780426,
    "wind_generated": 75.59998186,
    "marketplace_to_demand": 160.477214,
    "grid_to_demand": 35526.1504,
    "govt_battery_charge": 59.92394901
  },
  {
    "timestamp": "02/12/24 8:00",
    "demand": 31760.8765,
    "solar_generated": 12.23177266,
    "wind_generated": 58.75772095,
    "marketplace_to_demand": 70.26978737,
    "grid_to_demand": 32676.69041,
    "govt_battery_charge": 89.45080174
  },
  {
    "timestamp": "02/12/24 9:00",
    "demand": 35768.59923,
    "solar_generated": 9.809074587,
    "wind_generated": 58.29567226,
    "marketplace_to_demand": 20.15728377,
    "grid_to_demand": 35909.90887,
    "govt_battery_charge": 27.54677201
  },
  {
    "timestamp": "02/12/24 10:00",
    "demand": 36067.97952,
    "solar_generated": 19.40368262,
    "wind_generated": 71.96313789,
    "marketplace_to_demand": 103.6025078,
    "grid_to_demand": 30128.70587,
    "govt_battery_charge": 45.76812613
  },
  {
    "timestamp": "02/12/24 11:00",
    "demand": 34697.59545,
    "solar_generated": 11.46840716,
    "wind_generated": 54.3332146,
    "marketplace_to_demand": 59.06387003,
    "grid_to_demand": 31060.94576,
    "govt_battery_charge": 63.25680702
  },
  {
    "timestamp": "02/12/24 12:00",
    "demand": 34531.57586,
    "solar_generated": 17.73985717,
    "wind_generated": 68.83020646,
    "marketplace_to_demand": 132.9996904,
    "grid_to_demand": 30255.88586,
    "govt_battery_charge": 73.83946648
  },
  {
    "timestamp": "02/12/24 13:00",
    "demand": 35904.51193,
    "solar_generated": 9.700200162,
    "wind_generated": 71.60353256,
    "marketplace_to_demand": 101.5106238,
    "grid_to_demand": 35094.01783,
    "govt_battery_charge": 42.23281397
  },
  {
    "timestamp": "02/12/24 14:00",
    "demand": 34630.309,
    "solar_generated": 17.29145327,
    "wind_generated": 69.13451826,
    "marketplace_to_demand": 118.1378569,
    "grid_to_demand": 32295.64752,
    "govt_battery_charge": 98.26029774
  },
  {
    "timestamp": "02/12/24 15:00",
    "demand": 30464.8332,
    "solar_generated": 14.23446165,
    "wind_generated": 55.84496124,
    "marketplace_to_demand": 82.38213531,
    "grid_to_demand": 29922.28352,
    "govt_battery_charge": 16.6698907
  },
  {
    "timestamp": "02/12/24 16:00",
    "demand": 30534.58774,
    "solar_generated": 10.82109125,
    "wind_generated": 77.59774953,
    "marketplace_to_demand": 67.72126059,
    "grid_to_demand": 34150.85979,
    "govt_battery_charge": 36.9362217
  },
  {
    "timestamp": "02/12/24 17:00",
    "demand": 34285.1476,
    "solar_generated": 17.04664349,
    "wind_generated": 68.39713544,
    "marketplace_to_demand": 42.28773343,
    "grid_to_demand": 33360.78373,
    "govt_battery_charge": 95.59203107
  },
  {
    "timestamp": "02/12/24 18:00",
    "demand": 35015.09676,
    "solar_generated": 0,
    "wind_generated": 57.4304382,
    "marketplace_to_demand": 23.2476001,
    "grid_to_demand": 32539.74566,
    "govt_battery_charge": 28.28294975
  },
  {
    "timestamp": "02/12/24 19:00",
    "demand": 31248.80513,
    "solar_generated": 0,
    "wind_generated": 64.19340654,
    "marketplace_to_demand": 111.8022985,
    "grid_to_demand": 35406.40933,
    "govt_battery_charge": 82.15032069
  },
  {
    "timestamp": "02/12/24 20:00",
    "demand": 34958.87439,
    "solar_generated": 0,
    "wind_generated": 80.12480229,
    "marketplace_to_demand": 142.8649026,
    "grid_to_demand": 34525.01271,
    "govt_battery_charge": 89.39052863
  },
  {
    "timestamp": "02/12/24 21:00",
    "demand": 32280.87149,
    "solar_generated": 0,
    "wind_generated": 55.5348036,
    "marketplace_to_demand": 60.79740116,
    "grid_to_demand": 30009.35285,
    "govt_battery_charge": 36.99890588
  },
  {
    "timestamp": "02/12/24 22:00",
    "demand": 32355.79406,
    "solar_generated": 0,
    "wind_generated": 68.00446204,
    "marketplace_to_demand": 106.1038824,
    "grid_to_demand": 30791.52616,
    "govt_battery_charge": 20.21304804
  },
  {
    "timestamp": "02/12/24 23:00",
    "demand": 31913.09907,
    "solar_generated": 0,
    "wind_generated": 79.54494549,
    "marketplace_to_demand": 24.57531019,
    "grid_to_demand": 31886.00777,
    "govt_battery_charge": 46.89447553
  },
  {
    "timestamp": "03/12/24 0:00",
    "demand": 33036.00671,
    "solar_generated": 0,
    "wind_generated": 66.13120531,
    "marketplace_to_demand": 165.2165808,
    "grid_to_demand": 34509.4373,
    "govt_battery_charge": 78.03267847
  },
  {
    "timestamp": "03/12/24 1:00",
    "demand": 30961.70445,
    "solar_generated": 0,
    "wind_generated": 60.93083354,
    "marketplace_to_demand": 70.88951612,
    "grid_to_demand": 31316.2132,
    "govt_battery_charge": 40.32378919
  },
  {
    "timestamp": "03/12/24 2:00",
    "demand": 34939.23052,
    "solar_generated": 0,
    "wind_generated": 55.74627695,
    "marketplace_to_demand": 152.365885,
    "grid_to_demand": 30232.86104,
    "govt_battery_charge": 13.43455081
  },
  {
    "timestamp": "03/12/24 3:00",
    "demand": 36347.86997,
    "solar_generated": 0,
    "wind_generated": 65.65057225,
    "marketplace_to_demand": 65.60712996,
    "grid_to_demand": 34853.60609,
    "govt_battery_charge": 51.40796376
  },
  {
    "timestamp": "03/12/24 4:00",
    "demand": 30803.52104,
    "solar_generated": 0,
    "wind_generated": 72.57794153,
    "marketplace_to_demand": 37.05398444,
    "grid_to_demand": 32145.0224,
    "govt_battery_charge": 11.84898059
  },
  {
    "timestamp": "03/12/24 5:00",
    "demand": 33861.79574,
    "solar_generated": 0,
    "wind_generated": 76.5500833,
    "marketplace_to_demand": 199.9373724,
    "grid_to_demand": 35198.42412,
    "govt_battery_charge": 38.60575079
  },
  {
    "timestamp": "03/12/24 6:00",
    "demand": 30246.53066,
    "solar_generated": 23.11205952,
    "wind_generated": 80.27163822,
    "marketplace_to_demand": 93.36794676,
    "grid_to_demand": 30014.38744,
    "govt_battery_charge": 91.94893996
  },
  {
    "timestamp": "03/12/24 7:00",
    "demand": 31992.06569,
    "solar_generated": 6.13181347,
    "wind_generated": 76.83815788,
    "marketplace_to_demand": 69.70399263,
    "grid_to_demand": 32960.16526,
    "govt_battery_charge": 37.4890157
  },
  {
    "timestamp": "03/12/24 8:00",
    "demand": 34893.88423,
    "solar_generated": 6.668227958,
    "wind_generated": 54.72783704,
    "marketplace_to_demand": 95.98584097,
    "grid_to_demand": 31655.73635,
    "govt_battery_charge": 99.74162769
  },
  {
    "timestamp": "03/12/24 9:00",
    "demand": 33667.72379,
    "solar_generated": 18.28748634,
    "wind_generated": 70.78613446,
    "marketplace_to_demand": 76.2802731,
    "grid_to_demand": 35005.39248,
    "govt_battery_charge": 20.83292453
  },
  {
    "timestamp": "03/12/24 10:00",
    "demand": 33091.53485,
    "solar_generated": 23.40372575,
    "wind_generated": 69.03832592,
    "marketplace_to_demand": 73.77516115,
    "grid_to_demand": 34791.9974,
    "govt_battery_charge": 66.42423883
  },
  {
    "timestamp": "03/12/24 11:00",
    "demand": 30818.81245,
    "solar_generated": 10.26977595,
    "wind_generated": 68.95456621,
    "marketplace_to_demand": 48.95867347,
    "grid_to_demand": 31167.47688,
    "govt_battery_charge": 98.32532067
  },
  {
    "timestamp": "03/12/24 12:00",
    "demand": 36466.09876,
    "solar_generated": 15.92515669,
    "wind_generated": 56.67316061,
    "marketplace_to_demand": 196.3635333,
    "grid_to_demand": 32768.89511,
    "govt_battery_charge": 23.82116263
  },
  {
    "timestamp": "03/12/24 13:00",
    "demand": 33899.36579,
    "solar_generated": 16.63070169,
    "wind_generated": 59.87298525,
    "marketplace_to_demand": 172.8395654,
    "grid_to_demand": 33627.25022,
    "govt_battery_charge": 45.30515826
  },
  {
    "timestamp": "03/12/24 14:00",
    "demand": 31706.4846,
    "solar_generated": 19.53768373,
    "wind_generated": 77.96004917,
    "marketplace_to_demand": 27.92978225,
    "grid_to_demand": 35903.93993,
    "govt_battery_charge": 10.50285913
  },
  {
    "timestamp": "03/12/24 15:00",
    "demand": 33590.01736,
    "solar_generated": 19.86098181,
    "wind_generated": 63.77418512,
    "marketplace_to_demand": 175.3485975,
    "grid_to_demand": 32881.53635,
    "govt_battery_charge": 99.7213971
  },
  {
    "timestamp": "03/12/24 16:00",
    "demand": 36566.26933,
    "solar_generated": 17.11984005,
    "wind_generated": 64.15573927,
    "marketplace_to_demand": 53.50267408,
    "grid_to_demand": 34819.34463,
    "govt_battery_charge": 74.70426633
  },
  {
    "timestamp": "03/12/24 17:00",
    "demand": 35944.01606,
    "solar_generated": 22.02318036,
    "wind_generated": 58.89899624,
    "marketplace_to_demand": 86.66327766,
    "grid_to_demand": 33085.98407,
    "govt_battery_charge": 10.0464449
  },
  {
    "timestamp": "03/12/24 18:00",
    "demand": 33169.12106,
    "solar_generated": 0,
    "wind_generated": 57.33224177,
    "marketplace_to_demand": 32.13796996,
    "grid_to_demand": 35964.26401,
    "govt_battery_charge": 49.97398506
  },
  {
    "timestamp": "03/12/24 19:00",
    "demand": 31488.88279,
    "solar_generated": 0,
    "wind_generated": 64.60108572,
    "marketplace_to_demand": 85.73385421,
    "grid_to_demand": 34371.47733,
    "govt_battery_charge": 52.9045116
  },
  {
    "timestamp": "03/12/24 20:00",
    "demand": 36312.42153,
    "solar_generated": 0,
    "wind_generated": 76.16605355,
    "marketplace_to_demand": 185.3151042,
    "grid_to_demand": 32265.01025,
    "govt_battery_charge": 58.3502449
  },
  {
    "timestamp": "03/12/24 21:00",
    "demand": 34104.07969,
    "solar_generated": 0,
    "wind_generated": 80.33222326,
    "marketplace_to_demand": 101.3387929,
    "grid_to_demand": 34274.76652,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "03/12/24 22:00",
    "demand": 34607.80444,
    "solar_generated": 0,
    "wind_generated": 71.12797677,
    "marketplace_to_demand": 45.19299643,
    "grid_to_demand": 34005.15914,
    "govt_battery_charge": 79.75038942
  },
  {
    "timestamp": "03/12/24 23:00",
    "demand": 30023.7698,
    "solar_generated": 0,
    "wind_generated": 59.615591,
    "marketplace_to_demand": 109.6199505,
    "grid_to_demand": 33693.55648,
    "govt_battery_charge": 56.76733206
  },
  {
    "timestamp": "04/12/24 0:00",
    "demand": 30051.02276,
    "solar_generated": 0,
    "wind_generated": 58.30435268,
    "marketplace_to_demand": 112.5040702,
    "grid_to_demand": 32586.03699,
    "govt_battery_charge": 58.4822546
  },
  {
    "timestamp": "04/12/24 1:00",
    "demand": 34257.71954,
    "solar_generated": 0,
    "wind_generated": 58.61159211,
    "marketplace_to_demand": 162.3998846,
    "grid_to_demand": 36384.59063,
    "govt_battery_charge": 47.08785931
  },
  {
    "timestamp": "04/12/24 2:00",
    "demand": 35734.42387,
    "solar_generated": 0,
    "wind_generated": 80.16409656,
    "marketplace_to_demand": 171.1499797,
    "grid_to_demand": 34764.16553,
    "govt_battery_charge": 86.40145736
  },
  {
    "timestamp": "04/12/24 3:00",
    "demand": 33105.7243,
    "solar_generated": 0,
    "wind_generated": 74.76422001,
    "marketplace_to_demand": 147.960607,
    "grid_to_demand": 34350.77925,
    "govt_battery_charge": 16.56335354
  },
  {
    "timestamp": "04/12/24 4:00",
    "demand": 31221.54916,
    "solar_generated": 0,
    "wind_generated": 67.58881908,
    "marketplace_to_demand": 112.60311,
    "grid_to_demand": 35631.43303,
    "govt_battery_charge": 13.49131278
  },
  {
    "timestamp": "04/12/24 5:00",
    "demand": 34529.92497,
    "solar_generated": 0,
    "wind_generated": 65.20276823,
    "marketplace_to_demand": 138.6415126,
    "grid_to_demand": 35816.59,
    "govt_battery_charge": 93.61677135
  },
  {
    "timestamp": "04/12/24 6:00",
    "demand": 32275.22323,
    "solar_generated": 10.85083263,
    "wind_generated": 65.29121213,
    "marketplace_to_demand": 62.45498259,
    "grid_to_demand": 34119.73766,
    "govt_battery_charge": 76.50786965
  },
  {
    "timestamp": "04/12/24 7:00",
    "demand": 31828.82039,
    "solar_generated": 17.36798038,
    "wind_generated": 70.99856993,
    "marketplace_to_demand": 71.96091745,
    "grid_to_demand": 30396.35447,
    "govt_battery_charge": 49.05136977
  },
  {
    "timestamp": "04/12/24 8:00",
    "demand": 36091.79864,
    "solar_generated": 8.736276613,
    "wind_generated": 73.28544615,
    "marketplace_to_demand": 165.3703704,
    "grid_to_demand": 34201.2658,
    "govt_battery_charge": 95.47493473
  },
  {
    "timestamp": "04/12/24 9:00",
    "demand": 36492.22408,
    "solar_generated": 6.727481379,
    "wind_generated": 75.63525922,
    "marketplace_to_demand": 74.01387779,
    "grid_to_demand": 36489.3716,
    "govt_battery_charge": 60.1930027
  },
  {
    "timestamp": "04/12/24 10:00",
    "demand": 35924.56917,
    "solar_generated": 21.43446477,
    "wind_generated": 70.6122039,
    "marketplace_to_demand": 78.01540771,
    "grid_to_demand": 32688.96698,
    "govt_battery_charge": 36.97928919
  },
  {
    "timestamp": "04/12/24 11:00",
    "demand": 30764.86305,
    "solar_generated": 14.73841479,
    "wind_generated": 58.46988725,
    "marketplace_to_demand": 118.6267779,
    "grid_to_demand": 30598.42955,
    "govt_battery_charge": 75.21321695
  },
  {
    "timestamp": "04/12/24 12:00",
    "demand": 33953.66584,
    "solar_generated": 20.2884875,
    "wind_generated": 63.06693323,
    "marketplace_to_demand": 169.9570537,
    "grid_to_demand": 33054.15327,
    "govt_battery_charge": 11.7471506
  },
  {
    "timestamp": "04/12/24 13:00",
    "demand": 32027.56518,
    "solar_generated": 8.295274553,
    "wind_generated": 74.27523832,
    "marketplace_to_demand": 20.61337171,
    "grid_to_demand": 33746.64631,
    "govt_battery_charge": 31.13050725
  },
  {
    "timestamp": "04/12/24 14:00",
    "demand": 32107.13329,
    "solar_generated": 13.67558563,
    "wind_generated": 78.72996702,
    "marketplace_to_demand": 115.1005479,
    "grid_to_demand": 34069.5572,
    "govt_battery_charge": 50.91456952
  },
  {
    "timestamp": "04/12/24 15:00",
    "demand": 36364.51258,
    "solar_generated": 18.47982678,
    "wind_generated": 59.03214258,
    "marketplace_to_demand": 162.8942068,
    "grid_to_demand": 34552.66994,
    "govt_battery_charge": 59.86258646
  },
  {
    "timestamp": "04/12/24 16:00",
    "demand": 30299.77124,
    "solar_generated": 21.62691698,
    "wind_generated": 68.41669387,
    "marketplace_to_demand": 115.2504981,
    "grid_to_demand": 35796.12884,
    "govt_battery_charge": 90.03808622
  },
  {
    "timestamp": "04/12/24 17:00",
    "demand": 34102.81464,
    "solar_generated": 21.36465872,
    "wind_generated": 62.74102502,
    "marketplace_to_demand": 49.93823554,
    "grid_to_demand": 34145.72651,
    "govt_battery_charge": 40.11875782
  },
  {
    "timestamp": "04/12/24 18:00",
    "demand": 34985.98982,
    "solar_generated": 0,
    "wind_generated": 76.29624059,
    "marketplace_to_demand": 58.39515075,
    "grid_to_demand": 30637.37502,
    "govt_battery_charge": 5.272108904
  },
  {
    "timestamp": "04/12/24 19:00",
    "demand": 32230.17568,
    "solar_generated": 0,
    "wind_generated": 70.89377188,
    "marketplace_to_demand": 37.35166733,
    "grid_to_demand": 30579.63828,
    "govt_battery_charge": 55.42929264
  },
  {
    "timestamp": "04/12/24 20:00",
    "demand": 30473.46736,
    "solar_generated": 0,
    "wind_generated": 61.4498038,
    "marketplace_to_demand": 9.497748498,
    "grid_to_demand": 34115.23547,
    "govt_battery_charge": 58.27963402
  },
  {
    "timestamp": "04/12/24 21:00",
    "demand": 34589.11824,
    "solar_generated": 0,
    "wind_generated": 61.28055081,
    "marketplace_to_demand": 1.132925496,
    "grid_to_demand": 33470.1066,
    "govt_battery_charge": 61.91523346
  },
  {
    "timestamp": "04/12/24 22:00",
    "demand": 32141.58872,
    "solar_generated": 0,
    "wind_generated": 78.52581335,
    "marketplace_to_demand": 194.4898157,
    "grid_to_demand": 30773.76852,
    "govt_battery_charge": 51.3295265
  },
  {
    "timestamp": "04/12/24 23:00",
    "demand": 34936.48624,
    "solar_generated": 0,
    "wind_generated": 59.87474044,
    "marketplace_to_demand": 15.39643958,
    "grid_to_demand": 33465.1565,
    "govt_battery_charge": 88.48532971
  },
  {
    "timestamp": "05/12/24 0:00",
    "demand": 30780.36775,
    "solar_generated": 0,
    "wind_generated": 77.95526199,
    "marketplace_to_demand": 21.64981842,
    "grid_to_demand": 30246.1557,
    "govt_battery_charge": 59.02557906
  },
  {
    "timestamp": "05/12/24 1:00",
    "demand": 32498.81223,
    "solar_generated": 0,
    "wind_generated": 54.66211353,
    "marketplace_to_demand": 163.2460039,
    "grid_to_demand": 36318.13275,
    "govt_battery_charge": 60.30440608
  },
  {
    "timestamp": "05/12/24 2:00",
    "demand": 32328.14845,
    "solar_generated": 0,
    "wind_generated": 57.28645915,
    "marketplace_to_demand": 142.8733158,
    "grid_to_demand": 34826.92147,
    "govt_battery_charge": 5.22596155
  },
  {
    "timestamp": "05/12/24 3:00",
    "demand": 32628.43547,
    "solar_generated": 0,
    "wind_generated": 77.42489224,
    "marketplace_to_demand": 75.43443425,
    "grid_to_demand": 30887.80546,
    "govt_battery_charge": 83.72699898
  },
  {
    "timestamp": "05/12/24 4:00",
    "demand": 30879.14895,
    "solar_generated": 0,
    "wind_generated": 72.76282565,
    "marketplace_to_demand": 146.8197625,
    "grid_to_demand": 32330.60742,
    "govt_battery_charge": 66.70247645
  },
  {
    "timestamp": "05/12/24 5:00",
    "demand": 30988.14797,
    "solar_generated": 0,
    "wind_generated": 59.59929167,
    "marketplace_to_demand": 167.2466482,
    "grid_to_demand": 31396.86537,
    "govt_battery_charge": 75.993755
  },
  {
    "timestamp": "05/12/24 6:00",
    "demand": 33224.69913,
    "solar_generated": 15.82407596,
    "wind_generated": 67.38355287,
    "marketplace_to_demand": 40.85131558,
    "grid_to_demand": 31849.11761,
    "govt_battery_charge": 78.10498926
  },
  {
    "timestamp": "05/12/24 7:00",
    "demand": 32071.91475,
    "solar_generated": 21.23394939,
    "wind_generated": 61.9361692,
    "marketplace_to_demand": 148.2660417,
    "grid_to_demand": 34465.61692,
    "govt_battery_charge": 88.10238575
  },
  {
    "timestamp": "05/12/24 8:00",
    "demand": 31238.50235,
    "solar_generated": 11.62066882,
    "wind_generated": 67.70353263,
    "marketplace_to_demand": 68.8573933,
    "grid_to_demand": 35118.53538,
    "govt_battery_charge": 18.82266358
  },
  {
    "timestamp": "05/12/24 9:00",
    "demand": 33490.21488,
    "solar_generated": 16.94898835,
    "wind_generated": 60.22897716,
    "marketplace_to_demand": 91.90715573,
    "grid_to_demand": 33682.96021,
    "govt_battery_charge": 91.67853232
  },
  {
    "timestamp": "05/12/24 10:00",
    "demand": 35672.80189,
    "solar_generated": 7.836120104,
    "wind_generated": 79.31736411,
    "marketplace_to_demand": 70.07559414,
    "grid_to_demand": 34606.55773,
    "govt_battery_charge": 28.10608035
  },
  {
    "timestamp": "05/12/24 11:00",
    "demand": 35850.07187,
    "solar_generated": 20.23114187,
    "wind_generated": 54.28647197,
    "marketplace_to_demand": 94.27839088,
    "grid_to_demand": 34889.07881,
    "govt_battery_charge": 76.68555348
  },
  {
    "timestamp": "05/12/24 12:00",
    "demand": 30843.91236,
    "solar_generated": 21.94434454,
    "wind_generated": 65.65588484,
    "marketplace_to_demand": 194.2607123,
    "grid_to_demand": 32121.18892,
    "govt_battery_charge": 94.77843919
  },
  {
    "timestamp": "05/12/24 13:00",
    "demand": 31375.11455,
    "solar_generated": 21.87021024,
    "wind_generated": 75.84199818,
    "marketplace_to_demand": 158.6437385,
    "grid_to_demand": 31641.96486,
    "govt_battery_charge": 78.34604498
  },
  {
    "timestamp": "05/12/24 14:00",
    "demand": 31270.17226,
    "solar_generated": 8.134792906,
    "wind_generated": 78.10887848,
    "marketplace_to_demand": 101.9476521,
    "grid_to_demand": 30953.42712,
    "govt_battery_charge": 23.56423175
  },
  {
    "timestamp": "05/12/24 15:00",
    "demand": 36413.79376,
    "solar_generated": 19.75301925,
    "wind_generated": 56.02345758,
    "marketplace_to_demand": 173.5599286,
    "grid_to_demand": 33396.74187,
    "govt_battery_charge": 89.84754111
  },
  {
    "timestamp": "05/12/24 16:00",
    "demand": 36397.46218,
    "solar_generated": 6.593342576,
    "wind_generated": 75.17414331,
    "marketplace_to_demand": 47.07503142,
    "grid_to_demand": 36487.65046,
    "govt_battery_charge": 20.50648699
  },
  {
    "timestamp": "05/12/24 17:00",
    "demand": 32966.93017,
    "solar_generated": 8.870213518,
    "wind_generated": 69.359177,
    "marketplace_to_demand": 56.03629451,
    "grid_to_demand": 35335.06232,
    "govt_battery_charge": 92.35610916
  },
  {
    "timestamp": "05/12/24 18:00",
    "demand": 33877.00374,
    "solar_generated": 0,
    "wind_generated": 65.09390396,
    "marketplace_to_demand": 58.55265293,
    "grid_to_demand": 35322.80684,
    "govt_battery_charge": 33.2495033
  },
  {
    "timestamp": "05/12/24 19:00",
    "demand": 32927.62939,
    "solar_generated": 0,
    "wind_generated": 55.90214409,
    "marketplace_to_demand": 183.9249952,
    "grid_to_demand": 34851.60147,
    "govt_battery_charge": 53.09546115
  },
  {
    "timestamp": "05/12/24 20:00",
    "demand": 34452.6637,
    "solar_generated": 0,
    "wind_generated": 76.78919364,
    "marketplace_to_demand": 48.42981443,
    "grid_to_demand": 33067.26136,
    "govt_battery_charge": 87.2666034
  },
  {
    "timestamp": "05/12/24 21:00",
    "demand": 30417.92125,
    "solar_generated": 0,
    "wind_generated": 60.8684476,
    "marketplace_to_demand": 67.51700237,
    "grid_to_demand": 32592.61554,
    "govt_battery_charge": 19.5132301
  },
  {
    "timestamp": "05/12/24 22:00",
    "demand": 34476.46863,
    "solar_generated": 0,
    "wind_generated": 65.8032746,
    "marketplace_to_demand": 12.67508299,
    "grid_to_demand": 30492.34302,
    "govt_battery_charge": 87.0782229
  },
  {
    "timestamp": "05/12/24 23:00",
    "demand": 32622.05947,
    "solar_generated": 0,
    "wind_generated": 64.43139021,
    "marketplace_to_demand": 20.70469242,
    "grid_to_demand": 31283.21877,
    "govt_battery_charge": 39.08133951
  },
  {
    "timestamp": "06/12/24 0:00",
    "demand": 36264.13629,
    "solar_generated": 0,
    "wind_generated": 73.71627066,
    "marketplace_to_demand": 110.0291907,
    "grid_to_demand": 35377.99959,
    "govt_battery_charge": 73.43320774
  },
  {
    "timestamp": "06/12/24 1:00",
    "demand": 33877.24046,
    "solar_generated": 0,
    "wind_generated": 74.15759073,
    "marketplace_to_demand": 163.2995295,
    "grid_to_demand": 29990.24742,
    "govt_battery_charge": 90.61167133
  },
  {
    "timestamp": "06/12/24 2:00",
    "demand": 33805.41374,
    "solar_generated": 0,
    "wind_generated": 68.47959334,
    "marketplace_to_demand": 71.22275988,
    "grid_to_demand": 32771.75799,
    "govt_battery_charge": 80.71651048
  },
  {
    "timestamp": "06/12/24 3:00",
    "demand": 33677.70633,
    "solar_generated": 0,
    "wind_generated": 68.00066249,
    "marketplace_to_demand": 66.59673109,
    "grid_to_demand": 32079.20779,
    "govt_battery_charge": 75.17459634
  },
  {
    "timestamp": "06/12/24 4:00",
    "demand": 31830.4095,
    "solar_generated": 0,
    "wind_generated": 75.37756227,
    "marketplace_to_demand": 142.2004233,
    "grid_to_demand": 31357.27034,
    "govt_battery_charge": 24.41762387
  },
  {
    "timestamp": "06/12/24 5:00",
    "demand": 30716.18327,
    "solar_generated": 0,
    "wind_generated": 63.39938366,
    "marketplace_to_demand": 139.3388189,
    "grid_to_demand": 36456.25455,
    "govt_battery_charge": 6.701684925
  },
  {
    "timestamp": "06/12/24 6:00",
    "demand": 36189.82677,
    "solar_generated": 18.72428556,
    "wind_generated": 78.40220473,
    "marketplace_to_demand": 119.3086561,
    "grid_to_demand": 34391.51085,
    "govt_battery_charge": 14.59722942
  },
  {
    "timestamp": "06/12/24 7:00",
    "demand": 34188.68113,
    "solar_generated": 15.89414538,
    "wind_generated": 54.2862553,
    "marketplace_to_demand": 57.19525672,
    "grid_to_demand": 30947.26473,
    "govt_battery_charge": 22.13697939
  },
  {
    "timestamp": "06/12/24 8:00",
    "demand": 35249.99146,
    "solar_generated": 12.52497282,
    "wind_generated": 72.78110919,
    "marketplace_to_demand": 85.83427453,
    "grid_to_demand": 34957.59609,
    "govt_battery_charge": 14.71572167
  },
  {
    "timestamp": "06/12/24 9:00",
    "demand": 33605.97068,
    "solar_generated": 18.59387769,
    "wind_generated": 75.49358998,
    "marketplace_to_demand": 159.8572706,
    "grid_to_demand": 34382.263,
    "govt_battery_charge": 88.59295317
  },
  {
    "timestamp": "06/12/24 10:00",
    "demand": 30607.50786,
    "solar_generated": 10.26145449,
    "wind_generated": 54.21608374,
    "marketplace_to_demand": 184.8932077,
    "grid_to_demand": 34810.90253,
    "govt_battery_charge": 37.85710164
  },
  {
    "timestamp": "06/12/24 11:00",
    "demand": 30798.25478,
    "solar_generated": 21.12797655,
    "wind_generated": 73.97226827,
    "marketplace_to_demand": 83.38541767,
    "grid_to_demand": 35985.97166,
    "govt_battery_charge": 42.82919543
  },
  {
    "timestamp": "06/12/24 12:00",
    "demand": 30600.73931,
    "solar_generated": 14.67339879,
    "wind_generated": 65.28224209,
    "marketplace_to_demand": 114.4814862,
    "grid_to_demand": 36323.8133,
    "govt_battery_charge": 66.61336864
  },
  {
    "timestamp": "06/12/24 13:00",
    "demand": 35211.13851,
    "solar_generated": 8.626427772,
    "wind_generated": 78.92655672,
    "marketplace_to_demand": 17.25324074,
    "grid_to_demand": 34087.27243,
    "govt_battery_charge": 71.64895193
  },
  {
    "timestamp": "06/12/24 14:00",
    "demand": 30659.78657,
    "solar_generated": 20.0400468,
    "wind_generated": 74.0004392,
    "marketplace_to_demand": 35.89685383,
    "grid_to_demand": 30492.58681,
    "govt_battery_charge": 26.33082447
  },
  {
    "timestamp": "06/12/24 15:00",
    "demand": 35391.51277,
    "solar_generated": 14.90204434,
    "wind_generated": 61.96579989,
    "marketplace_to_demand": 145.0397402,
    "grid_to_demand": 32804.79177,
    "govt_battery_charge": 61.44472007
  },
  {
    "timestamp": "06/12/24 16:00",
    "demand": 32235.759,
    "solar_generated": 20.15368178,
    "wind_generated": 67.66248221,
    "marketplace_to_demand": 0.239537867,
    "grid_to_demand": 34366.19725,
    "govt_battery_charge": 9.627543369
  },
  {
    "timestamp": "06/12/24 17:00",
    "demand": 36299.9707,
    "solar_generated": 16.84062393,
    "wind_generated": 67.10789915,
    "marketplace_to_demand": 85.71774702,
    "grid_to_demand": 30696.94964,
    "govt_battery_charge": 94.94989387
  },
  {
    "timestamp": "06/12/24 18:00",
    "demand": 32903.32002,
    "solar_generated": 0,
    "wind_generated": 75.43423564,
    "marketplace_to_demand": 85.96384088,
    "grid_to_demand": 34147.52124,
    "govt_battery_charge": 42.25246793
  },
  {
    "timestamp": "06/12/24 19:00",
    "demand": 33887.90812,
    "solar_generated": 0,
    "wind_generated": 74.14050354,
    "marketplace_to_demand": 84.03363201,
    "grid_to_demand": 32330.0641,
    "govt_battery_charge": 40.25157486
  },
  {
    "timestamp": "06/12/24 20:00",
    "demand": 32729.02597,
    "solar_generated": 0,
    "wind_generated": 75.3898751,
    "marketplace_to_demand": 16.89951386,
    "grid_to_demand": 34055.69464,
    "govt_battery_charge": 46.09371919
  },
  {
    "timestamp": "06/12/24 21:00",
    "demand": 32391.8075,
    "solar_generated": 0,
    "wind_generated": 59.37585108,
    "marketplace_to_demand": 30.07636259,
    "grid_to_demand": 35185.54639,
    "govt_battery_charge": 31.67760809
  },
  {
    "timestamp": "06/12/24 22:00",
    "demand": 36132.19712,
    "solar_generated": 0,
    "wind_generated": 60.63314876,
    "marketplace_to_demand": 8.010903857,
    "grid_to_demand": 35184.40751,
    "govt_battery_charge": 14.62596794
  },
  {
    "timestamp": "06/12/24 23:00",
    "demand": 32409.18763,
    "solar_generated": 0,
    "wind_generated": 56.79051273,
    "marketplace_to_demand": 127.1418451,
    "grid_to_demand": 31888.51165,
    "govt_battery_charge": 11.1976461
  },
  {
    "timestamp": "07/12/24 0:00",
    "demand": 31705.53734,
    "solar_generated": 0,
    "wind_generated": 61.84526057,
    "marketplace_to_demand": 77.70207687,
    "grid_to_demand": 34841.02865,
    "govt_battery_charge": 10.72238784
  },
  {
    "timestamp": "07/12/24 1:00",
    "demand": 33482.76017,
    "solar_generated": 0,
    "wind_generated": 76.01587815,
    "marketplace_to_demand": 60.16939747,
    "grid_to_demand": 32723.39472,
    "govt_battery_charge": 36.7590523
  },
  {
    "timestamp": "07/12/24 2:00",
    "demand": 33236.41419,
    "solar_generated": 0,
    "wind_generated": 59.51151567,
    "marketplace_to_demand": 50.41469145,
    "grid_to_demand": 34491.4617,
    "govt_battery_charge": 25.51050949
  },
  {
    "timestamp": "07/12/24 3:00",
    "demand": 31247.4453,
    "solar_generated": 0,
    "wind_generated": 70.19387629,
    "marketplace_to_demand": 122.3918886,
    "grid_to_demand": 34874.55592,
    "govt_battery_charge": 67.79075611
  },
  {
    "timestamp": "07/12/24 4:00",
    "demand": 34762.9861,
    "solar_generated": 0,
    "wind_generated": 78.76400804,
    "marketplace_to_demand": 86.15227269,
    "grid_to_demand": 31738.03221,
    "govt_battery_charge": 99.82511385
  },
  {
    "timestamp": "07/12/24 5:00",
    "demand": 29988.85389,
    "solar_generated": 0,
    "wind_generated": 66.64960845,
    "marketplace_to_demand": 179.9528013,
    "grid_to_demand": 32773.04621,
    "govt_battery_charge": 26.19664961
  },
  {
    "timestamp": "07/12/24 6:00",
    "demand": 32966.4045,
    "solar_generated": 11.79469982,
    "wind_generated": 77.9719551,
    "marketplace_to_demand": 69.35053681,
    "grid_to_demand": 31335.17972,
    "govt_battery_charge": 57.71530514
  },
  {
    "timestamp": "07/12/24 7:00",
    "demand": 33477.42705,
    "solar_generated": 20.24776426,
    "wind_generated": 68.65812226,
    "marketplace_to_demand": 151.7005484,
    "grid_to_demand": 30941.10325,
    "govt_battery_charge": 49.96504171
  },
  {
    "timestamp": "07/12/24 8:00",
    "demand": 31010.05792,
    "solar_generated": 14.92958363,
    "wind_generated": 63.26535289,
    "marketplace_to_demand": 132.3455696,
    "grid_to_demand": 35574.3929,
    "govt_battery_charge": 29.08587923
  },
  {
    "timestamp": "07/12/24 9:00",
    "demand": 34354.53244,
    "solar_generated": 22.67166101,
    "wind_generated": 55.85222543,
    "marketplace_to_demand": 154.3951284,
    "grid_to_demand": 36251.83192,
    "govt_battery_charge": 38.38766289
  },
  {
    "timestamp": "07/12/24 10:00",
    "demand": 34235.86952,
    "solar_generated": 9.604079042,
    "wind_generated": 60.91988753,
    "marketplace_to_demand": 164.7014498,
    "grid_to_demand": 36030.56534,
    "govt_battery_charge": 93.52165421
  },
  {
    "timestamp": "07/12/24 11:00",
    "demand": 32508.06761,
    "solar_generated": 14.91122588,
    "wind_generated": 58.74137598,
    "marketplace_to_demand": 132.3884749,
    "grid_to_demand": 36333.03276,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "07/12/24 12:00",
    "demand": 34798.27399,
    "solar_generated": 6.502028808,
    "wind_generated": 62.0862337,
    "marketplace_to_demand": 178.9547355,
    "grid_to_demand": 35528.18055,
    "govt_battery_charge": 66.5443815
  },
  {
    "timestamp": "07/12/24 13:00",
    "demand": 33739.45092,
    "solar_generated": 12.19302954,
    "wind_generated": 56.88921647,
    "marketplace_to_demand": 160.9106784,
    "grid_to_demand": 36379.8315,
    "govt_battery_charge": 12.30061876
  },
  {
    "timestamp": "07/12/24 14:00",
    "demand": 33269.92489,
    "solar_generated": 15.6025817,
    "wind_generated": 78.80549696,
    "marketplace_to_demand": 14.67607866,
    "grid_to_demand": 35225.20775,
    "govt_battery_charge": 14.73302463
  },
  {
    "timestamp": "07/12/24 15:00",
    "demand": 35883.03347,
    "solar_generated": 7.138077925,
    "wind_generated": 54.03274538,
    "marketplace_to_demand": 35.81068379,
    "grid_to_demand": 32060.03885,
    "govt_battery_charge": 5.299236902
  },
  {
    "timestamp": "07/12/24 16:00",
    "demand": 36589.00095,
    "solar_generated": 17.58666688,
    "wind_generated": 54.89538106,
    "marketplace_to_demand": 16.10461384,
    "grid_to_demand": 33030.08817,
    "govt_battery_charge": 14.20200832
  },
  {
    "timestamp": "07/12/24 17:00",
    "demand": 32438.54166,
    "solar_generated": 21.67451309,
    "wind_generated": 54.5915305,
    "marketplace_to_demand": 161.3406181,
    "grid_to_demand": 32043.37081,
    "govt_battery_charge": 97.89258465
  },
  {
    "timestamp": "07/12/24 18:00",
    "demand": 34729.8877,
    "solar_generated": 0,
    "wind_generated": 66.98519329,
    "marketplace_to_demand": 75.58808591,
    "grid_to_demand": 35679.99278,
    "govt_battery_charge": 57.78520406
  },
  {
    "timestamp": "07/12/24 19:00",
    "demand": 30335.40533,
    "solar_generated": 0,
    "wind_generated": 64.34395869,
    "marketplace_to_demand": 72.11023477,
    "grid_to_demand": 30338.42167,
    "govt_battery_charge": 76.34080052
  },
  {
    "timestamp": "07/12/24 20:00",
    "demand": 34409.10064,
    "solar_generated": 0,
    "wind_generated": 60.23456217,
    "marketplace_to_demand": 169.2266065,
    "grid_to_demand": 34511.47515,
    "govt_battery_charge": 27.98666176
  },
  {
    "timestamp": "07/12/24 21:00",
    "demand": 30016.96394,
    "solar_generated": 0,
    "wind_generated": 62.37273328,
    "marketplace_to_demand": 144.599445,
    "grid_to_demand": 36256.04663,
    "govt_battery_charge": 52.99520696
  },
  {
    "timestamp": "07/12/24 22:00",
    "demand": 31154.32726,
    "solar_generated": 0,
    "wind_generated": 68.87998944,
    "marketplace_to_demand": 89.92921469,
    "grid_to_demand": 35448.43511,
    "govt_battery_charge": 4.504392645
  },
  {
    "timestamp": "07/12/24 23:00",
    "demand": 30731.3168,
    "solar_generated": 0,
    "wind_generated": 61.47500946,
    "marketplace_to_demand": 90.39095571,
    "grid_to_demand": 35139.85506,
    "govt_battery_charge": 1.030671202
  },
  {
    "timestamp": "08/12/24 0:00",
    "demand": 31497.11124,
    "solar_generated": 0,
    "wind_generated": 55.4175592,
    "marketplace_to_demand": 93.98082083,
    "grid_to_demand": 30770.2168,
    "govt_battery_charge": 7.123066449
  },
  {
    "timestamp": "08/12/24 1:00",
    "demand": 34725.21158,
    "solar_generated": 0,
    "wind_generated": 55.83278649,
    "marketplace_to_demand": 78.79873759,
    "grid_to_demand": 35602.5071,
    "govt_battery_charge": 32.26015728
  },
  {
    "timestamp": "08/12/24 2:00",
    "demand": 36455.03107,
    "solar_generated": 0,
    "wind_generated": 62.18434542,
    "marketplace_to_demand": 42.51408768,
    "grid_to_demand": 35931.82569,
    "govt_battery_charge": 87.38604317
  },
  {
    "timestamp": "08/12/24 3:00",
    "demand": 33024.37395,
    "solar_generated": 0,
    "wind_generated": 65.54324306,
    "marketplace_to_demand": 47.97215683,
    "grid_to_demand": 35773.84732,
    "govt_battery_charge": 55.881542
  },
  {
    "timestamp": "08/12/24 4:00",
    "demand": 33694.59466,
    "solar_generated": 0,
    "wind_generated": 76.16870067,
    "marketplace_to_demand": 5.878992833,
    "grid_to_demand": 30545.074,
    "govt_battery_charge": 76.25329285
  },
  {
    "timestamp": "08/12/24 5:00",
    "demand": 34958.16921,
    "solar_generated": 0,
    "wind_generated": 58.09430077,
    "marketplace_to_demand": 27.4446273,
    "grid_to_demand": 30043.77377,
    "govt_battery_charge": 43.2877035
  },
  {
    "timestamp": "08/12/24 6:00",
    "demand": 35614.87096,
    "solar_generated": 17.65554922,
    "wind_generated": 57.62005395,
    "marketplace_to_demand": 51.55508254,
    "grid_to_demand": 30436.69067,
    "govt_battery_charge": 91.74434569
  },
  {
    "timestamp": "08/12/24 7:00",
    "demand": 32027.83952,
    "solar_generated": 13.56034284,
    "wind_generated": 63.88711796,
    "marketplace_to_demand": 32.1787172,
    "grid_to_demand": 30121.60532,
    "govt_battery_charge": 89.32399601
  },
  {
    "timestamp": "08/12/24 8:00",
    "demand": 30771.42569,
    "solar_generated": 17.5973017,
    "wind_generated": 78.39797401,
    "marketplace_to_demand": 31.47961748,
    "grid_to_demand": 34397.00656,
    "govt_battery_charge": 49.58418725
  },
  {
    "timestamp": "08/12/24 9:00",
    "demand": 30429.78685,
    "solar_generated": 19.66397596,
    "wind_generated": 55.60544294,
    "marketplace_to_demand": 10.91599322,
    "grid_to_demand": 30559.88739,
    "govt_battery_charge": 5.690189634
  },
  {
    "timestamp": "08/12/24 10:00",
    "demand": 36392.03259,
    "solar_generated": 13.78624783,
    "wind_generated": 76.3474365,
    "marketplace_to_demand": 34.88231759,
    "grid_to_demand": 33500.68153,
    "govt_battery_charge": 78.5793834
  },
  {
    "timestamp": "08/12/24 11:00",
    "demand": 32558.17915,
    "solar_generated": 22.34817619,
    "wind_generated": 73.17640695,
    "marketplace_to_demand": 63.05208266,
    "grid_to_demand": 33941.71869,
    "govt_battery_charge": 67.52286645
  },
  {
    "timestamp": "08/12/24 12:00",
    "demand": 31121.79934,
    "solar_generated": 20.02501015,
    "wind_generated": 67.1903541,
    "marketplace_to_demand": 148.1477218,
    "grid_to_demand": 33185.72941,
    "govt_battery_charge": 73.39755501
  },
  {
    "timestamp": "08/12/24 13:00",
    "demand": 36113.18347,
    "solar_generated": 11.64708938,
    "wind_generated": 64.89039314,
    "marketplace_to_demand": 64.51991728,
    "grid_to_demand": 35408.26279,
    "govt_battery_charge": 70.2154304
  },
  {
    "timestamp": "08/12/24 14:00",
    "demand": 36382.9684,
    "solar_generated": 7.368972672,
    "wind_generated": 62.79820254,
    "marketplace_to_demand": 99.53749015,
    "grid_to_demand": 32797.47607,
    "govt_battery_charge": 88.44634992
  },
  {
    "timestamp": "08/12/24 15:00",
    "demand": 36287.77596,
    "solar_generated": 12.06238664,
    "wind_generated": 70.50612815,
    "marketplace_to_demand": 120.479765,
    "grid_to_demand": 31896.20734,
    "govt_battery_charge": 18.53997733
  },
  {
    "timestamp": "08/12/24 16:00",
    "demand": 32775.76462,
    "solar_generated": 10.70635331,
    "wind_generated": 58.45475009,
    "marketplace_to_demand": 180.7858155,
    "grid_to_demand": 31139.29306,
    "govt_battery_charge": 56.60606678
  },
  {
    "timestamp": "08/12/24 17:00",
    "demand": 36357.47778,
    "solar_generated": 15.27285653,
    "wind_generated": 70.43836919,
    "marketplace_to_demand": 151.3753781,
    "grid_to_demand": 31927.16947,
    "govt_battery_charge": 84.44938167
  },
  {
    "timestamp": "08/12/24 18:00",
    "demand": 34804.43761,
    "solar_generated": 0,
    "wind_generated": 78.98119692,
    "marketplace_to_demand": 32.71329655,
    "grid_to_demand": 35079.17902,
    "govt_battery_charge": 31.59220993
  },
  {
    "timestamp": "08/12/24 19:00",
    "demand": 35398.90105,
    "solar_generated": 0,
    "wind_generated": 69.38377682,
    "marketplace_to_demand": 155.2156812,
    "grid_to_demand": 30120.52964,
    "govt_battery_charge": 22.18542225
  },
  {
    "timestamp": "08/12/24 20:00",
    "demand": 30299.41987,
    "solar_generated": 0,
    "wind_generated": 68.56618695,
    "marketplace_to_demand": 141.1739014,
    "grid_to_demand": 31501.47979,
    "govt_battery_charge": 89.89868532
  },
  {
    "timestamp": "08/12/24 21:00",
    "demand": 34977.68933,
    "solar_generated": 0,
    "wind_generated": 58.42323475,
    "marketplace_to_demand": 10.08969218,
    "grid_to_demand": 32039.90078,
    "govt_battery_charge": 38.7459958
  },
  {
    "timestamp": "08/12/24 22:00",
    "demand": 30767.90825,
    "solar_generated": 0,
    "wind_generated": 55.63312525,
    "marketplace_to_demand": 193.4880934,
    "grid_to_demand": 30824.81686,
    "govt_battery_charge": 61.14733118
  },
  {
    "timestamp": "08/12/24 23:00",
    "demand": 35644.12885,
    "solar_generated": 0,
    "wind_generated": 76.43560878,
    "marketplace_to_demand": 128.5500028,
    "grid_to_demand": 32743.8507,
    "govt_battery_charge": 58.07487892
  },
  {
    "timestamp": "09/12/24 0:00",
    "demand": 33564.90795,
    "solar_generated": 0,
    "wind_generated": 77.24203093,
    "marketplace_to_demand": 171.5511688,
    "grid_to_demand": 30456.24976,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/12/24 1:00",
    "demand": 35690.40069,
    "solar_generated": 0,
    "wind_generated": 67.09318427,
    "marketplace_to_demand": 78.81065419,
    "grid_to_demand": 31672.64303,
    "govt_battery_charge": 6.685767585
  },
  {
    "timestamp": "09/12/24 2:00",
    "demand": 30547.91903,
    "solar_generated": 0,
    "wind_generated": 67.59196458,
    "marketplace_to_demand": 126.8549394,
    "grid_to_demand": 35481.12214,
    "govt_battery_charge": 37.51665247
  },
  {
    "timestamp": "09/12/24 3:00",
    "demand": 30364.26397,
    "solar_generated": 0,
    "wind_generated": 67.66506417,
    "marketplace_to_demand": 176.2098804,
    "grid_to_demand": 31412.99906,
    "govt_battery_charge": 85.88433381
  },
  {
    "timestamp": "09/12/24 4:00",
    "demand": 30040.03198,
    "solar_generated": 0,
    "wind_generated": 70.51436467,
    "marketplace_to_demand": 96.28921992,
    "grid_to_demand": 34729.21962,
    "govt_battery_charge": 70.14726813
  },
  {
    "timestamp": "09/12/24 5:00",
    "demand": 34142.30506,
    "solar_generated": 0,
    "wind_generated": 56.07401979,
    "marketplace_to_demand": 26.7682428,
    "grid_to_demand": 30296.02969,
    "govt_battery_charge": 72.18615697
  },
  {
    "timestamp": "09/12/24 6:00",
    "demand": 34221.55156,
    "solar_generated": 17.5404632,
    "wind_generated": 76.47959264,
    "marketplace_to_demand": 120.719274,
    "grid_to_demand": 32686.20965,
    "govt_battery_charge": 53.39277893
  },
  {
    "timestamp": "09/12/24 7:00",
    "demand": 30674.92438,
    "solar_generated": 12.98127094,
    "wind_generated": 69.02543766,
    "marketplace_to_demand": 134.7323841,
    "grid_to_demand": 36438.0672,
    "govt_battery_charge": 88.69227124
  },
  {
    "timestamp": "09/12/24 8:00",
    "demand": 32433.17879,
    "solar_generated": 19.53639008,
    "wind_generated": 71.21127786,
    "marketplace_to_demand": 49.82713751,
    "grid_to_demand": 30312.74002,
    "govt_battery_charge": 3.567657027
  },
  {
    "timestamp": "09/12/24 9:00",
    "demand": 36093.95399,
    "solar_generated": 19.45529921,
    "wind_generated": 75.72492347,
    "marketplace_to_demand": 3.036917966,
    "grid_to_demand": 33242.22387,
    "govt_battery_charge": 85.16670106
  },
  {
    "timestamp": "09/12/24 10:00",
    "demand": 35582.48415,
    "solar_generated": 18.95927376,
    "wind_generated": 65.08475239,
    "marketplace_to_demand": 150.1007251,
    "grid_to_demand": 36351.90963,
    "govt_battery_charge": 6.089438475
  },
  {
    "timestamp": "09/12/24 11:00",
    "demand": 31096.3797,
    "solar_generated": 14.15861707,
    "wind_generated": 55.84093931,
    "marketplace_to_demand": 79.65503103,
    "grid_to_demand": 35738.54081,
    "govt_battery_charge": 29.57900119
  },
  {
    "timestamp": "09/12/24 12:00",
    "demand": 35369.58725,
    "solar_generated": 17.37384789,
    "wind_generated": 58.53216691,
    "marketplace_to_demand": 72.24545087,
    "grid_to_demand": 34014.41226,
    "govt_battery_charge": 41.73932613
  },
  {
    "timestamp": "09/12/24 13:00",
    "demand": 35571.30757,
    "solar_generated": 12.29220042,
    "wind_generated": 78.53408459,
    "marketplace_to_demand": 57.5615143,
    "grid_to_demand": 32914.1845,
    "govt_battery_charge": 99.23406851
  },
  {
    "timestamp": "09/12/24 14:00",
    "demand": 31785.0279,
    "solar_generated": 23.45243127,
    "wind_generated": 65.3992538,
    "marketplace_to_demand": 151.79013,
    "grid_to_demand": 30388.35115,
    "govt_battery_charge": 50.04779812
  },
  {
    "timestamp": "09/12/24 15:00",
    "demand": 36554.32348,
    "solar_generated": 21.50553453,
    "wind_generated": 71.05427238,
    "marketplace_to_demand": 192.0540473,
    "grid_to_demand": 33976.74156,
    "govt_battery_charge": 26.57484699
  },
  {
    "timestamp": "09/12/24 16:00",
    "demand": 31855.02607,
    "solar_generated": 21.28008494,
    "wind_generated": 75.69372233,
    "marketplace_to_demand": 142.091548,
    "grid_to_demand": 31317.4105,
    "govt_battery_charge": 72.08006199
  },
  {
    "timestamp": "09/12/24 17:00",
    "demand": 35628.45433,
    "solar_generated": 22.91244766,
    "wind_generated": 57.34527114,
    "marketplace_to_demand": 168.1769078,
    "grid_to_demand": 35665.97203,
    "govt_battery_charge": 82.75865106
  },
  {
    "timestamp": "09/12/24 18:00",
    "demand": 36529.2729,
    "solar_generated": 0,
    "wind_generated": 73.85879416,
    "marketplace_to_demand": 142.8701651,
    "grid_to_demand": 33368.46006,
    "govt_battery_charge": 80.69790596
  },
  {
    "timestamp": "09/12/24 19:00",
    "demand": 35419.8459,
    "solar_generated": 0,
    "wind_generated": 64.65199966,
    "marketplace_to_demand": 125.3671825,
    "grid_to_demand": 34495.03989,
    "govt_battery_charge": 80.04209911
  },
  {
    "timestamp": "09/12/24 20:00",
    "demand": 32206.89143,
    "solar_generated": 0,
    "wind_generated": 73.29915993,
    "marketplace_to_demand": 101.2336911,
    "grid_to_demand": 35235.79037,
    "govt_battery_charge": 90.50290729
  },
  {
    "timestamp": "09/12/24 21:00",
    "demand": 32213.62022,
    "solar_generated": 0,
    "wind_generated": 75.48357998,
    "marketplace_to_demand": 155.5345479,
    "grid_to_demand": 30079.23169,
    "govt_battery_charge": 79.98603346
  },
  {
    "timestamp": "09/12/24 22:00",
    "demand": 34472.46553,
    "solar_generated": 0,
    "wind_generated": 77.68939666,
    "marketplace_to_demand": 20.33127677,
    "grid_to_demand": 31396.64058,
    "govt_battery_charge": 33.38463447
  },
  {
    "timestamp": "09/12/24 23:00",
    "demand": 32958.67902,
    "solar_generated": 0,
    "wind_generated": 63.82180874,
    "marketplace_to_demand": 157.0996734,
    "grid_to_demand": 34137.50578,
    "govt_battery_charge": 88.99713717
  },
  {
    "timestamp": "10/12/24 0:00",
    "demand": 29995.17436,
    "solar_generated": 0,
    "wind_generated": 66.11119039,
    "marketplace_to_demand": 29.99078076,
    "grid_to_demand": 35106.58403,
    "govt_battery_charge": 68.56659819
  },
  {
    "timestamp": "10/12/24 1:00",
    "demand": 31261.7247,
    "solar_generated": 0,
    "wind_generated": 76.28565396,
    "marketplace_to_demand": 73.19848504,
    "grid_to_demand": 35885.45875,
    "govt_battery_charge": 26.67005976
  },
  {
    "timestamp": "10/12/24 2:00",
    "demand": 31800.21386,
    "solar_generated": 0,
    "wind_generated": 77.10482285,
    "marketplace_to_demand": 118.0463882,
    "grid_to_demand": 31610.62111,
    "govt_battery_charge": 16.52344936
  },
  {
    "timestamp": "10/12/24 3:00",
    "demand": 30506.79964,
    "solar_generated": 0,
    "wind_generated": 63.28279092,
    "marketplace_to_demand": 127.6857472,
    "grid_to_demand": 30680.80947,
    "govt_battery_charge": 92.14592335
  },
  {
    "timestamp": "10/12/24 4:00",
    "demand": 34813.28809,
    "solar_generated": 0,
    "wind_generated": 71.43141075,
    "marketplace_to_demand": 155.1259047,
    "grid_to_demand": 31267.99523,
    "govt_battery_charge": 31.56939291
  },
  {
    "timestamp": "10/12/24 5:00",
    "demand": 32888.66105,
    "solar_generated": 0,
    "wind_generated": 78.3035126,
    "marketplace_to_demand": 136.7390832,
    "grid_to_demand": 30061.29482,
    "govt_battery_charge": 69.50575758
  },
  {
    "timestamp": "10/12/24 6:00",
    "demand": 35185.78814,
    "solar_generated": 10.99540221,
    "wind_generated": 78.34682144,
    "marketplace_to_demand": 10.6873096,
    "grid_to_demand": 35952.34228,
    "govt_battery_charge": 70.27343848
  },
  {
    "timestamp": "10/12/24 7:00",
    "demand": 36273.06145,
    "solar_generated": 7.761586541,
    "wind_generated": 59.83619684,
    "marketplace_to_demand": 36.00924192,
    "grid_to_demand": 36162.94512,
    "govt_battery_charge": 4.209335955
  },
  {
    "timestamp": "10/12/24 8:00",
    "demand": 35190.97333,
    "solar_generated": 10.35228712,
    "wind_generated": 73.38106334,
    "marketplace_to_demand": 55.35805026,
    "grid_to_demand": 35175.63285,
    "govt_battery_charge": 74.38226561
  },
  {
    "timestamp": "10/12/24 9:00",
    "demand": 33329.40862,
    "solar_generated": 13.30802496,
    "wind_generated": 62.11911463,
    "marketplace_to_demand": 137.8331059,
    "grid_to_demand": 32283.14935,
    "govt_battery_charge": 52.92449506
  },
  {
    "timestamp": "10/12/24 10:00",
    "demand": 34719.27052,
    "solar_generated": 11.52425995,
    "wind_generated": 57.46990143,
    "marketplace_to_demand": 66.05444145,
    "grid_to_demand": 34783.72999,
    "govt_battery_charge": 8.573495021
  },
  {
    "timestamp": "10/12/24 11:00",
    "demand": 31978.50639,
    "solar_generated": 22.4993415,
    "wind_generated": 71.2323185,
    "marketplace_to_demand": 60.91558795,
    "grid_to_demand": 31615.31824,
    "govt_battery_charge": 46.5651513
  },
  {
    "timestamp": "10/12/24 12:00",
    "demand": 32870.14359,
    "solar_generated": 8.248286999,
    "wind_generated": 64.98449702,
    "marketplace_to_demand": 64.35915926,
    "grid_to_demand": 32880.58041,
    "govt_battery_charge": 58.06893392
  },
  {
    "timestamp": "10/12/24 13:00",
    "demand": 30671.7918,
    "solar_generated": 22.27149407,
    "wind_generated": 64.19337828,
    "marketplace_to_demand": 195.5896329,
    "grid_to_demand": 34443.01978,
    "govt_battery_charge": 58.59814587
  },
  {
    "timestamp": "10/12/24 14:00",
    "demand": 34675.20055,
    "solar_generated": 22.41654793,
    "wind_generated": 75.87663333,
    "marketplace_to_demand": 174.2106354,
    "grid_to_demand": 33029.44514,
    "govt_battery_charge": 17.24842919
  },
  {
    "timestamp": "10/12/24 15:00",
    "demand": 31288.90984,
    "solar_generated": 20.36825073,
    "wind_generated": 75.20331066,
    "marketplace_to_demand": 99.41019306,
    "grid_to_demand": 31570.45076,
    "govt_battery_charge": 58.44721932
  },
  {
    "timestamp": "10/12/24 16:00",
    "demand": 35521.13573,
    "solar_generated": 13.04379547,
    "wind_generated": 58.80715797,
    "marketplace_to_demand": 6.092211933,
    "grid_to_demand": 36224.69316,
    "govt_battery_charge": 4.2229273
  },
  {
    "timestamp": "10/12/24 17:00",
    "demand": 32847.01396,
    "solar_generated": 8.591825592,
    "wind_generated": 64.03632371,
    "marketplace_to_demand": 48.70187611,
    "grid_to_demand": 36392.712,
    "govt_battery_charge": 31.7317026
  },
  {
    "timestamp": "10/12/24 18:00",
    "demand": 31183.21721,
    "solar_generated": 0,
    "wind_generated": 79.47370941,
    "marketplace_to_demand": 103.3799461,
    "grid_to_demand": 35052.11251,
    "govt_battery_charge": 39.80909911
  },
  {
    "timestamp": "10/12/24 19:00",
    "demand": 32240.0967,
    "solar_generated": 0,
    "wind_generated": 73.99516468,
    "marketplace_to_demand": 198.1404782,
    "grid_to_demand": 30145.72301,
    "govt_battery_charge": 72.73363123
  },
  {
    "timestamp": "10/12/24 20:00",
    "demand": 34420.87672,
    "solar_generated": 0,
    "wind_generated": 72.76721007,
    "marketplace_to_demand": 132.7460574,
    "grid_to_demand": 33565.50325,
    "govt_battery_charge": 49.23362224
  },
  {
    "timestamp": "10/12/24 21:00",
    "demand": 33167.55548,
    "solar_generated": 0,
    "wind_generated": 72.96268505,
    "marketplace_to_demand": 12.36091887,
    "grid_to_demand": 34589.60354,
    "govt_battery_charge": 56.53649218
  },
  {
    "timestamp": "10/12/24 22:00",
    "demand": 33830.24649,
    "solar_generated": 0,
    "wind_generated": 68.34803764,
    "marketplace_to_demand": 69.09740902,
    "grid_to_demand": 32827.59285,
    "govt_battery_charge": 70.14720268
  },
  {
    "timestamp": "10/12/24 23:00",
    "demand": 36160.04257,
    "solar_generated": 0,
    "wind_generated": 54.07928464,
    "marketplace_to_demand": 100.0378139,
    "grid_to_demand": 34214.63375,
    "govt_battery_charge": 32.1516907
  },
  {
    "timestamp": "11/12/24 0:00",
    "demand": 33780.36808,
    "solar_generated": 0,
    "wind_generated": 69.08073683,
    "marketplace_to_demand": 112.2831223,
    "grid_to_demand": 36355.7889,
    "govt_battery_charge": 20.96789731
  },
  {
    "timestamp": "11/12/24 1:00",
    "demand": 34832.84226,
    "solar_generated": 0,
    "wind_generated": 73.85218564,
    "marketplace_to_demand": 25.98399511,
    "grid_to_demand": 34009.74476,
    "govt_battery_charge": 37.31082547
  },
  {
    "timestamp": "11/12/24 2:00",
    "demand": 32904.35085,
    "solar_generated": 0,
    "wind_generated": 60.85662235,
    "marketplace_to_demand": 132.4619593,
    "grid_to_demand": 33228.11607,
    "govt_battery_charge": 79.91423579
  },
  {
    "timestamp": "11/12/24 3:00",
    "demand": 33428.20721,
    "solar_generated": 0,
    "wind_generated": 64.12891109,
    "marketplace_to_demand": 119.0641624,
    "grid_to_demand": 32989.64652,
    "govt_battery_charge": 81.24745224
  },
  {
    "timestamp": "11/12/24 4:00",
    "demand": 32250.91943,
    "solar_generated": 0,
    "wind_generated": 71.73000888,
    "marketplace_to_demand": 120.2076875,
    "grid_to_demand": 34715.26858,
    "govt_battery_charge": 19.29898942
  },
  {
    "timestamp": "11/12/24 5:00",
    "demand": 34548.1782,
    "solar_generated": 0,
    "wind_generated": 66.31804197,
    "marketplace_to_demand": 58.47960925,
    "grid_to_demand": 31632.99946,
    "govt_battery_charge": 40.93079592
  },
  {
    "timestamp": "11/12/24 6:00",
    "demand": 31383.13599,
    "solar_generated": 22.84985024,
    "wind_generated": 65.21497158,
    "marketplace_to_demand": 86.49255383,
    "grid_to_demand": 33942.6826,
    "govt_battery_charge": 86.3953843
  },
  {
    "timestamp": "11/12/24 7:00",
    "demand": 34514.93446,
    "solar_generated": 11.03622966,
    "wind_generated": 74.60724387,
    "marketplace_to_demand": 61.32176886,
    "grid_to_demand": 32059.57632,
    "govt_battery_charge": 71.14320233
  },
  {
    "timestamp": "11/12/24 8:00",
    "demand": 36101.55799,
    "solar_generated": 16.34455176,
    "wind_generated": 62.71854731,
    "marketplace_to_demand": 107.7810399,
    "grid_to_demand": 32441.14383,
    "govt_battery_charge": 47.30887594
  },
  {
    "timestamp": "11/12/24 9:00",
    "demand": 32678.12152,
    "solar_generated": 22.72045856,
    "wind_generated": 65.6781482,
    "marketplace_to_demand": 122.7845293,
    "grid_to_demand": 35212.30237,
    "govt_battery_charge": 92.433348
  },
  {
    "timestamp": "11/12/24 10:00",
    "demand": 35775.38739,
    "solar_generated": 11.3912416,
    "wind_generated": 59.15353043,
    "marketplace_to_demand": 63.90343217,
    "grid_to_demand": 34463.88458,
    "govt_battery_charge": 86.25607881
  },
  {
    "timestamp": "11/12/24 11:00",
    "demand": 35295.05666,
    "solar_generated": 20.41249452,
    "wind_generated": 61.17261561,
    "marketplace_to_demand": 192.1280037,
    "grid_to_demand": 31694.86179,
    "govt_battery_charge": 64.15885713
  },
  {
    "timestamp": "11/12/24 12:00",
    "demand": 32588.58723,
    "solar_generated": 12.14730211,
    "wind_generated": 67.60499182,
    "marketplace_to_demand": 52.87740169,
    "grid_to_demand": 35364.56811,
    "govt_battery_charge": 27.54743325
  },
  {
    "timestamp": "11/12/24 13:00",
    "demand": 30377.42542,
    "solar_generated": 21.53812873,
    "wind_generated": 74.01985637,
    "marketplace_to_demand": 55.4550371,
    "grid_to_demand": 31921.29001,
    "govt_battery_charge": 76.71146952
  },
  {
    "timestamp": "11/12/24 14:00",
    "demand": 35450.89086,
    "solar_generated": 17.40213724,
    "wind_generated": 56.03915727,
    "marketplace_to_demand": 119.451897,
    "grid_to_demand": 31024.41187,
    "govt_battery_charge": 77.47818329
  },
  {
    "timestamp": "11/12/24 15:00",
    "demand": 32317.39786,
    "solar_generated": 19.30245728,
    "wind_generated": 76.61915365,
    "marketplace_to_demand": 35.65100939,
    "grid_to_demand": 30696.77031,
    "govt_battery_charge": 47.74180521
  },
  {
    "timestamp": "11/12/24 16:00",
    "demand": 35524.20194,
    "solar_generated": 7.368685369,
    "wind_generated": 55.83783085,
    "marketplace_to_demand": 33.88913467,
    "grid_to_demand": 31221.31054,
    "govt_battery_charge": 33.75746495
  },
  {
    "timestamp": "11/12/24 17:00",
    "demand": 30173.05335,
    "solar_generated": 20.12119079,
    "wind_generated": 55.48512673,
    "marketplace_to_demand": 138.9124525,
    "grid_to_demand": 35579.52075,
    "govt_battery_charge": 90.40735943
  },
  {
    "timestamp": "11/12/24 18:00",
    "demand": 31970.75287,
    "solar_generated": 0,
    "wind_generated": 65.18497599,
    "marketplace_to_demand": 96.39912522,
    "grid_to_demand": 31975.88775,
    "govt_battery_charge": 3.152981839
  },
  {
    "timestamp": "11/12/24 19:00",
    "demand": 30828.04773,
    "solar_generated": 0,
    "wind_generated": 68.78212047,
    "marketplace_to_demand": 164.5315261,
    "grid_to_demand": 30813.15952,
    "govt_battery_charge": 68.65333364
  },
  {
    "timestamp": "11/12/24 20:00",
    "demand": 34312.79446,
    "solar_generated": 0,
    "wind_generated": 72.43586885,
    "marketplace_to_demand": 199.2685252,
    "grid_to_demand": 32314.90272,
    "govt_battery_charge": 26.19388148
  },
  {
    "timestamp": "11/12/24 21:00",
    "demand": 32285.35479,
    "solar_generated": 0,
    "wind_generated": 61.42722286,
    "marketplace_to_demand": 146.3290948,
    "grid_to_demand": 31083.22596,
    "govt_battery_charge": 61.04389811
  },
  {
    "timestamp": "11/12/24 22:00",
    "demand": 35430.65382,
    "solar_generated": 0,
    "wind_generated": 65.63308336,
    "marketplace_to_demand": 66.89952982,
    "grid_to_demand": 34801.62129,
    "govt_battery_charge": 4.369908303
  },
  {
    "timestamp": "11/12/24 23:00",
    "demand": 32367.41213,
    "solar_generated": 0,
    "wind_generated": 74.89613245,
    "marketplace_to_demand": 98.88239091,
    "grid_to_demand": 31441.3724,
    "govt_battery_charge": 47.28303888
  },
  {
    "timestamp": "12/12/24 0:00",
    "demand": 33750.77485,
    "solar_generated": 0,
    "wind_generated": 72.25727693,
    "marketplace_to_demand": 70.94690022,
    "grid_to_demand": 35524.87454,
    "govt_battery_charge": 66.60295076
  },
  {
    "timestamp": "12/12/24 1:00",
    "demand": 31189.7745,
    "solar_generated": 0,
    "wind_generated": 75.14692101,
    "marketplace_to_demand": 124.7336771,
    "grid_to_demand": 34857.32131,
    "govt_battery_charge": 82.93349391
  },
  {
    "timestamp": "12/12/24 2:00",
    "demand": 32815.93101,
    "solar_generated": 0,
    "wind_generated": 76.21791294,
    "marketplace_to_demand": 62.43671643,
    "grid_to_demand": 32997.72181,
    "govt_battery_charge": 42.13326883
  },
  {
    "timestamp": "12/12/24 3:00",
    "demand": 30325.74247,
    "solar_generated": 0,
    "wind_generated": 56.52816455,
    "marketplace_to_demand": 172.1288936,
    "grid_to_demand": 31646.69926,
    "govt_battery_charge": 95.15426751
  },
  {
    "timestamp": "12/12/24 4:00",
    "demand": 31901.61262,
    "solar_generated": 0,
    "wind_generated": 59.98519241,
    "marketplace_to_demand": 16.98436872,
    "grid_to_demand": 31542.53866,
    "govt_battery_charge": 41.05221881
  },
  {
    "timestamp": "12/12/24 5:00",
    "demand": 34046.87692,
    "solar_generated": 0,
    "wind_generated": 80.34403087,
    "marketplace_to_demand": 48.30945284,
    "grid_to_demand": 35596.627,
    "govt_battery_charge": 51.87067879
  },
  {
    "timestamp": "12/12/24 6:00",
    "demand": 32023.03451,
    "solar_generated": 8.104386011,
    "wind_generated": 75.94554404,
    "marketplace_to_demand": 4.733155211,
    "grid_to_demand": 31543.43802,
    "govt_battery_charge": 97.91067028
  },
  {
    "timestamp": "12/12/24 7:00",
    "demand": 33843.3419,
    "solar_generated": 17.66515082,
    "wind_generated": 62.40569357,
    "marketplace_to_demand": 47.45461083,
    "grid_to_demand": 35752.59542,
    "govt_battery_charge": 35.33412139
  },
  {
    "timestamp": "12/12/24 8:00",
    "demand": 33863.56857,
    "solar_generated": 20.69078001,
    "wind_generated": 59.99934045,
    "marketplace_to_demand": 133.1838565,
    "grid_to_demand": 31806.66539,
    "govt_battery_charge": 31.35901538
  },
  {
    "timestamp": "12/12/24 9:00",
    "demand": 30648.2418,
    "solar_generated": 19.46960751,
    "wind_generated": 59.02110224,
    "marketplace_to_demand": 159.980026,
    "grid_to_demand": 35434.80765,
    "govt_battery_charge": 40.92365585
  },
  {
    "timestamp": "12/12/24 10:00",
    "demand": 30394.05158,
    "solar_generated": 21.43167709,
    "wind_generated": 76.32421171,
    "marketplace_to_demand": 110.3651842,
    "grid_to_demand": 32616.02382,
    "govt_battery_charge": 49.13435996
  },
  {
    "timestamp": "12/12/24 11:00",
    "demand": 30466.55919,
    "solar_generated": 18.70619782,
    "wind_generated": 66.76337066,
    "marketplace_to_demand": 110.1837855,
    "grid_to_demand": 30873.12523,
    "govt_battery_charge": 55.18420429
  },
  {
    "timestamp": "12/12/24 12:00",
    "demand": 34418.69292,
    "solar_generated": 23.27464697,
    "wind_generated": 63.53042136,
    "marketplace_to_demand": 119.3391257,
    "grid_to_demand": 32863.351,
    "govt_battery_charge": 26.51946346
  },
  {
    "timestamp": "12/12/24 13:00",
    "demand": 32374.75791,
    "solar_generated": 8.0892428,
    "wind_generated": 68.3302535,
    "marketplace_to_demand": 105.1751656,
    "grid_to_demand": 30490.94553,
    "govt_battery_charge": 86.88021658
  },
  {
    "timestamp": "12/12/24 14:00",
    "demand": 31022.2658,
    "solar_generated": 23.93479432,
    "wind_generated": 66.86960377,
    "marketplace_to_demand": 120.0791541,
    "grid_to_demand": 34492.24261,
    "govt_battery_charge": 61.78725256
  },
  {
    "timestamp": "12/12/24 15:00",
    "demand": 31904.32662,
    "solar_generated": 18.7641238,
    "wind_generated": 59.26976933,
    "marketplace_to_demand": 182.0586724,
    "grid_to_demand": 35822.83952,
    "govt_battery_charge": 45.41498922
  },
  {
    "timestamp": "12/12/24 16:00",
    "demand": 31794.76727,
    "solar_generated": 16.93200938,
    "wind_generated": 54.16063647,
    "marketplace_to_demand": 29.98455705,
    "grid_to_demand": 30355.37918,
    "govt_battery_charge": 27.71186974
  },
  {
    "timestamp": "12/12/24 17:00",
    "demand": 35547.77694,
    "solar_generated": 18.59307281,
    "wind_generated": 69.29197465,
    "marketplace_to_demand": 195.9338804,
    "grid_to_demand": 33738.93535,
    "govt_battery_charge": 36.09515519
  },
  {
    "timestamp": "12/12/24 18:00",
    "demand": 30313.46579,
    "solar_generated": 0,
    "wind_generated": 67.18400717,
    "marketplace_to_demand": 126.6089123,
    "grid_to_demand": 33086.10914,
    "govt_battery_charge": 37.25370292
  },
  {
    "timestamp": "12/12/24 19:00",
    "demand": 32990.4552,
    "solar_generated": 0,
    "wind_generated": 70.66934697,
    "marketplace_to_demand": 120.6557181,
    "grid_to_demand": 31487.14095,
    "govt_battery_charge": 31.47603498
  },
  {
    "timestamp": "12/12/24 20:00",
    "demand": 36426.74002,
    "solar_generated": 0,
    "wind_generated": 56.19278972,
    "marketplace_to_demand": 10.14992946,
    "grid_to_demand": 35255.66277,
    "govt_battery_charge": 84.33165114
  },
  {
    "timestamp": "12/12/24 21:00",
    "demand": 30527.54145,
    "solar_generated": 0,
    "wind_generated": 67.40732487,
    "marketplace_to_demand": 138.0189591,
    "grid_to_demand": 30395.58504,
    "govt_battery_charge": 99.95498949
  },
  {
    "timestamp": "12/12/24 22:00",
    "demand": 32405.14542,
    "solar_generated": 0,
    "wind_generated": 67.56933169,
    "marketplace_to_demand": 154.8877621,
    "grid_to_demand": 34216.56114,
    "govt_battery_charge": 4.68478476
  },
  {
    "timestamp": "12/12/24 23:00",
    "demand": 34964.56884,
    "solar_generated": 0,
    "wind_generated": 69.122308,
    "marketplace_to_demand": 31.25108213,
    "grid_to_demand": 31641.2577,
    "govt_battery_charge": 46.44405502
  },
  {
    "timestamp": "13/12/24 0:00",
    "demand": 35473.36396,
    "solar_generated": 0,
    "wind_generated": 58.38023386,
    "marketplace_to_demand": 88.52219,
    "grid_to_demand": 30509.18056,
    "govt_battery_charge": 26.03645182
  },
  {
    "timestamp": "13/12/24 1:00",
    "demand": 34544.06628,
    "solar_generated": 0,
    "wind_generated": 69.00177245,
    "marketplace_to_demand": 83.8503691,
    "grid_to_demand": 34238.61726,
    "govt_battery_charge": 18.09188356
  },
  {
    "timestamp": "13/12/24 2:00",
    "demand": 34929.99185,
    "solar_generated": 0,
    "wind_generated": 60.083152,
    "marketplace_to_demand": 118.768995,
    "grid_to_demand": 34038.09178,
    "govt_battery_charge": 29.46326876
  },
  {
    "timestamp": "13/12/24 3:00",
    "demand": 35267.27969,
    "solar_generated": 0,
    "wind_generated": 76.849748,
    "marketplace_to_demand": 58.32086835,
    "grid_to_demand": 33567.92072,
    "govt_battery_charge": 42.79583566
  },
  {
    "timestamp": "13/12/24 4:00",
    "demand": 30337.74314,
    "solar_generated": 0,
    "wind_generated": 77.39813973,
    "marketplace_to_demand": 13.18976446,
    "grid_to_demand": 30176.16525,
    "govt_battery_charge": 14.77903166
  },
  {
    "timestamp": "13/12/24 5:00",
    "demand": 32470.18484,
    "solar_generated": 0,
    "wind_generated": 69.53421952,
    "marketplace_to_demand": 21.78247504,
    "grid_to_demand": 36336.56102,
    "govt_battery_charge": 50.20941526
  },
  {
    "timestamp": "13/12/24 6:00",
    "demand": 33352.68634,
    "solar_generated": 19.59373736,
    "wind_generated": 66.27956073,
    "marketplace_to_demand": 52.75543111,
    "grid_to_demand": 32038.98055,
    "govt_battery_charge": 43.61103049
  },
  {
    "timestamp": "13/12/24 7:00",
    "demand": 34616.52487,
    "solar_generated": 16.55140773,
    "wind_generated": 72.40812047,
    "marketplace_to_demand": 24.95038946,
    "grid_to_demand": 32616.25288,
    "govt_battery_charge": 59.74826274
  },
  {
    "timestamp": "13/12/24 8:00",
    "demand": 33305.77524,
    "solar_generated": 9.920285271,
    "wind_generated": 68.18175662,
    "marketplace_to_demand": 119.0693332,
    "grid_to_demand": 29916.60895,
    "govt_battery_charge": 88.41005648
  },
  {
    "timestamp": "13/12/24 9:00",
    "demand": 31323.1525,
    "solar_generated": 20.02665837,
    "wind_generated": 66.41973922,
    "marketplace_to_demand": 61.80605624,
    "grid_to_demand": 35123.84472,
    "govt_battery_charge": 37.32056336
  },
  {
    "timestamp": "13/12/24 10:00",
    "demand": 34578.05968,
    "solar_generated": 10.5748167,
    "wind_generated": 56.71372923,
    "marketplace_to_demand": 195.8271088,
    "grid_to_demand": 31677.68575,
    "govt_battery_charge": 29.08482422
  },
  {
    "timestamp": "13/12/24 11:00",
    "demand": 33399.4555,
    "solar_generated": 9.361999843,
    "wind_generated": 76.41100058,
    "marketplace_to_demand": 175.1592367,
    "grid_to_demand": 33472.79603,
    "govt_battery_charge": 12.96312313
  },
  {
    "timestamp": "13/12/24 12:00",
    "demand": 34171.98765,
    "solar_generated": 20.85040555,
    "wind_generated": 63.69933728,
    "marketplace_to_demand": 104.9785691,
    "grid_to_demand": 32291.9484,
    "govt_battery_charge": 75.17831153
  },
  {
    "timestamp": "13/12/24 13:00",
    "demand": 35752.29439,
    "solar_generated": 16.0881128,
    "wind_generated": 78.29779921,
    "marketplace_to_demand": 188.0128324,
    "grid_to_demand": 36051.24825,
    "govt_battery_charge": 42.12258971
  },
  {
    "timestamp": "13/12/24 14:00",
    "demand": 36140.26202,
    "solar_generated": 8.998038676,
    "wind_generated": 61.32133349,
    "marketplace_to_demand": 126.6883691,
    "grid_to_demand": 34829.18086,
    "govt_battery_charge": 63.45770284
  },
  {
    "timestamp": "13/12/24 15:00",
    "demand": 35641.81818,
    "solar_generated": 21.86590675,
    "wind_generated": 66.60177202,
    "marketplace_to_demand": 153.2276402,
    "grid_to_demand": 32945.93416,
    "govt_battery_charge": 18.91143164
  },
  {
    "timestamp": "13/12/24 16:00",
    "demand": 33967.18414,
    "solar_generated": 20.96843368,
    "wind_generated": 61.83709189,
    "marketplace_to_demand": 70.45210066,
    "grid_to_demand": 30922.04277,
    "govt_battery_charge": 36.99960246
  },
  {
    "timestamp": "13/12/24 17:00",
    "demand": 31606.60654,
    "solar_generated": 23.07047178,
    "wind_generated": 66.79167452,
    "marketplace_to_demand": 136.3734174,
    "grid_to_demand": 34377.95622,
    "govt_battery_charge": 93.28767858
  },
  {
    "timestamp": "13/12/24 18:00",
    "demand": 35190.44101,
    "solar_generated": 0,
    "wind_generated": 64.48386843,
    "marketplace_to_demand": 145.0377254,
    "grid_to_demand": 33132.88246,
    "govt_battery_charge": 14.09510908
  },
  {
    "timestamp": "13/12/24 19:00",
    "demand": 30433.73691,
    "solar_generated": 0,
    "wind_generated": 64.04578588,
    "marketplace_to_demand": 67.79539034,
    "grid_to_demand": 35379.48747,
    "govt_battery_charge": 78.85765039
  },
  {
    "timestamp": "13/12/24 20:00",
    "demand": 34305.85338,
    "solar_generated": 0,
    "wind_generated": 65.45945598,
    "marketplace_to_demand": 2.164501246,
    "grid_to_demand": 32366.85831,
    "govt_battery_charge": 73.54109987
  },
  {
    "timestamp": "13/12/24 21:00",
    "demand": 30080.63877,
    "solar_generated": 0,
    "wind_generated": 77.26094955,
    "marketplace_to_demand": 119.0518987,
    "grid_to_demand": 33606.94586,
    "govt_battery_charge": 20.73364688
  },
  {
    "timestamp": "13/12/24 22:00",
    "demand": 33441.39605,
    "solar_generated": 0,
    "wind_generated": 79.70838513,
    "marketplace_to_demand": 147.5714658,
    "grid_to_demand": 33186.26442,
    "govt_battery_charge": 10.55213988
  },
  {
    "timestamp": "13/12/24 23:00",
    "demand": 30635.2815,
    "solar_generated": 0,
    "wind_generated": 71.88491873,
    "marketplace_to_demand": 94.99114273,
    "grid_to_demand": 36428.23602,
    "govt_battery_charge": 95.13418818
  },
  {
    "timestamp": "14/12/24 0:00",
    "demand": 34107.07771,
    "solar_generated": 0,
    "wind_generated": 74.05141145,
    "marketplace_to_demand": 154.4137999,
    "grid_to_demand": 32093.07891,
    "govt_battery_charge": 39.50102711
  },
  {
    "timestamp": "14/12/24 1:00",
    "demand": 30317.55375,
    "solar_generated": 0,
    "wind_generated": 62.68599067,
    "marketplace_to_demand": 74.00375121,
    "grid_to_demand": 33605.70884,
    "govt_battery_charge": 1.745728417
  },
  {
    "timestamp": "14/12/24 2:00",
    "demand": 34764.89724,
    "solar_generated": 0,
    "wind_generated": 61.65729072,
    "marketplace_to_demand": 183.6459427,
    "grid_to_demand": 35358.82026,
    "govt_battery_charge": 62.8345062
  },
  {
    "timestamp": "14/12/24 3:00",
    "demand": 31707.5539,
    "solar_generated": 0,
    "wind_generated": 64.40904459,
    "marketplace_to_demand": 89.12357822,
    "grid_to_demand": 31785.69561,
    "govt_battery_charge": 47.55189242
  },
  {
    "timestamp": "14/12/24 4:00",
    "demand": 30774.35225,
    "solar_generated": 0,
    "wind_generated": 69.05517601,
    "marketplace_to_demand": 149.7339532,
    "grid_to_demand": 31035.00834,
    "govt_battery_charge": 68.04514166
  },
  {
    "timestamp": "14/12/24 5:00",
    "demand": 30950.75398,
    "solar_generated": 0,
    "wind_generated": 54.75671761,
    "marketplace_to_demand": 40.76069568,
    "grid_to_demand": 30609.7885,
    "govt_battery_charge": 53.12760261
  },
  {
    "timestamp": "14/12/24 6:00",
    "demand": 36523.40545,
    "solar_generated": 23.04238733,
    "wind_generated": 59.80005448,
    "marketplace_to_demand": 97.08701742,
    "grid_to_demand": 35346.99696,
    "govt_battery_charge": 81.8313333
  },
  {
    "timestamp": "14/12/24 7:00",
    "demand": 32040.15871,
    "solar_generated": 20.11236718,
    "wind_generated": 57.91509205,
    "marketplace_to_demand": 13.40891416,
    "grid_to_demand": 34201.62531,
    "govt_battery_charge": 91.6762638
  },
  {
    "timestamp": "14/12/24 8:00",
    "demand": 31850.84614,
    "solar_generated": 6.071590551,
    "wind_generated": 67.92362191,
    "marketplace_to_demand": 127.2928904,
    "grid_to_demand": 35247.96372,
    "govt_battery_charge": 48.76557544
  },
  {
    "timestamp": "14/12/24 9:00",
    "demand": 32284.25993,
    "solar_generated": 14.17701787,
    "wind_generated": 75.62788681,
    "marketplace_to_demand": 122.6052732,
    "grid_to_demand": 35586.1547,
    "govt_battery_charge": 28.9232719
  },
  {
    "timestamp": "14/12/24 10:00",
    "demand": 32450.52891,
    "solar_generated": 15.43787063,
    "wind_generated": 59.34610772,
    "marketplace_to_demand": 150.2790844,
    "grid_to_demand": 34603.56742,
    "govt_battery_charge": 43.26514703
  },
  {
    "timestamp": "14/12/24 11:00",
    "demand": 30959.47706,
    "solar_generated": 23.98954896,
    "wind_generated": 75.85719997,
    "marketplace_to_demand": 25.04691629,
    "grid_to_demand": 36453.83996,
    "govt_battery_charge": 16.36124373
  },
  {
    "timestamp": "14/12/24 12:00",
    "demand": 36252.43512,
    "solar_generated": 6.725246766,
    "wind_generated": 54.37156941,
    "marketplace_to_demand": 81.12140494,
    "grid_to_demand": 32169.91745,
    "govt_battery_charge": 98.27664933
  },
  {
    "timestamp": "14/12/24 13:00",
    "demand": 34336.61004,
    "solar_generated": 8.058769304,
    "wind_generated": 69.08298704,
    "marketplace_to_demand": 180.942828,
    "grid_to_demand": 31217.76064,
    "govt_battery_charge": 95.51736888
  },
  {
    "timestamp": "14/12/24 14:00",
    "demand": 36092.95257,
    "solar_generated": 7.03990648,
    "wind_generated": 77.0101998,
    "marketplace_to_demand": 56.21492191,
    "grid_to_demand": 33726.85024,
    "govt_battery_charge": 70.90185322
  },
  {
    "timestamp": "14/12/24 15:00",
    "demand": 35733.37906,
    "solar_generated": 21.98981025,
    "wind_generated": 70.88016023,
    "marketplace_to_demand": 63.7420402,
    "grid_to_demand": 33207.25111,
    "govt_battery_charge": 46.53745961
  },
  {
    "timestamp": "14/12/24 16:00",
    "demand": 33963.76995,
    "solar_generated": 17.90027234,
    "wind_generated": 68.97772632,
    "marketplace_to_demand": 123.4443666,
    "grid_to_demand": 35175.2895,
    "govt_battery_charge": 4.662012803
  },
  {
    "timestamp": "14/12/24 17:00",
    "demand": 32704.33367,
    "solar_generated": 10.88433388,
    "wind_generated": 69.89574883,
    "marketplace_to_demand": 3.686021108,
    "grid_to_demand": 33041.61824,
    "govt_battery_charge": 16.33666787
  },
  {
    "timestamp": "14/12/24 18:00",
    "demand": 32395.13044,
    "solar_generated": 0,
    "wind_generated": 66.51601037,
    "marketplace_to_demand": 142.566427,
    "grid_to_demand": 33859.15651,
    "govt_battery_charge": 58.54353695
  },
  {
    "timestamp": "14/12/24 19:00",
    "demand": 35905.53623,
    "solar_generated": 0,
    "wind_generated": 66.59237605,
    "marketplace_to_demand": 120.3278581,
    "grid_to_demand": 34059.34068,
    "govt_battery_charge": 99.31554209
  },
  {
    "timestamp": "14/12/24 20:00",
    "demand": 33278.98799,
    "solar_generated": 0,
    "wind_generated": 68.44521441,
    "marketplace_to_demand": 97.01347889,
    "grid_to_demand": 31313.47233,
    "govt_battery_charge": 45.80183033
  },
  {
    "timestamp": "14/12/24 21:00",
    "demand": 30478.85399,
    "solar_generated": 0,
    "wind_generated": 76.85752992,
    "marketplace_to_demand": 70.40983802,
    "grid_to_demand": 32426.28304,
    "govt_battery_charge": 44.92313648
  },
  {
    "timestamp": "14/12/24 22:00",
    "demand": 32728.94323,
    "solar_generated": 0,
    "wind_generated": 68.58432531,
    "marketplace_to_demand": 15.13353266,
    "grid_to_demand": 32683.06131,
    "govt_battery_charge": 67.05672919
  },
  {
    "timestamp": "14/12/24 23:00",
    "demand": 34222.83539,
    "solar_generated": 0,
    "wind_generated": 53.89674006,
    "marketplace_to_demand": 60.71027591,
    "grid_to_demand": 33526.27675,
    "govt_battery_charge": 36.33666442
  },
  {
    "timestamp": "15/12/24 0:00",
    "demand": 30492.79829,
    "solar_generated": 0,
    "wind_generated": 67.32813049,
    "marketplace_to_demand": 52.63966188,
    "grid_to_demand": 32526.70859,
    "govt_battery_charge": 89.1832071
  },
  {
    "timestamp": "15/12/24 1:00",
    "demand": 33474.844,
    "solar_generated": 0,
    "wind_generated": 66.32493193,
    "marketplace_to_demand": 151.1865011,
    "grid_to_demand": 31150.13993,
    "govt_battery_charge": 5.306534811
  },
  {
    "timestamp": "15/12/24 2:00",
    "demand": 36223.6625,
    "solar_generated": 0,
    "wind_generated": 62.28929395,
    "marketplace_to_demand": 150.5963472,
    "grid_to_demand": 34397.54976,
    "govt_battery_charge": 2.881302887
  },
  {
    "timestamp": "15/12/24 3:00",
    "demand": 35155.39225,
    "solar_generated": 0,
    "wind_generated": 68.38478765,
    "marketplace_to_demand": 90.54468721,
    "grid_to_demand": 34510.31137,
    "govt_battery_charge": 97.48234254
  },
  {
    "timestamp": "15/12/24 4:00",
    "demand": 36060.98867,
    "solar_generated": 0,
    "wind_generated": 58.88074267,
    "marketplace_to_demand": 124.7919107,
    "grid_to_demand": 32101.63799,
    "govt_battery_charge": 41.27191495
  },
  {
    "timestamp": "15/12/24 5:00",
    "demand": 34371.91193,
    "solar_generated": 0,
    "wind_generated": 65.18751271,
    "marketplace_to_demand": 101.6153875,
    "grid_to_demand": 34708.06893,
    "govt_battery_charge": 52.34097273
  },
  {
    "timestamp": "15/12/24 6:00",
    "demand": 33305.9412,
    "solar_generated": 14.12558489,
    "wind_generated": 54.41082727,
    "marketplace_to_demand": 65.70060564,
    "grid_to_demand": 35752.46103,
    "govt_battery_charge": 64.36285148
  },
  {
    "timestamp": "15/12/24 7:00",
    "demand": 33250.84186,
    "solar_generated": 20.46148434,
    "wind_generated": 70.28717208,
    "marketplace_to_demand": 15.25904432,
    "grid_to_demand": 30732.42082,
    "govt_battery_charge": 2.65059137
  },
  {
    "timestamp": "15/12/24 8:00",
    "demand": 31985.16862,
    "solar_generated": 15.22303539,
    "wind_generated": 59.24912936,
    "marketplace_to_demand": 11.41525666,
    "grid_to_demand": 35895.62119,
    "govt_battery_charge": 46.19019715
  },
  {
    "timestamp": "15/12/24 9:00",
    "demand": 35262.49902,
    "solar_generated": 12.50207193,
    "wind_generated": 54.19589514,
    "marketplace_to_demand": 44.31815558,
    "grid_to_demand": 34392.31418,
    "govt_battery_charge": 34.24693677
  },
  {
    "timestamp": "15/12/24 10:00",
    "demand": 31964.62101,
    "solar_generated": 12.86677237,
    "wind_generated": 75.6987784,
    "marketplace_to_demand": 194.5398415,
    "grid_to_demand": 30407.82302,
    "govt_battery_charge": 79.83289012
  },
  {
    "timestamp": "15/12/24 11:00",
    "demand": 32952.73247,
    "solar_generated": 23.36062043,
    "wind_generated": 61.79169961,
    "marketplace_to_demand": 129.7854665,
    "grid_to_demand": 31758.42005,
    "govt_battery_charge": 49.74104584
  },
  {
    "timestamp": "15/12/24 12:00",
    "demand": 36597.08765,
    "solar_generated": 12.93337302,
    "wind_generated": 73.82945978,
    "marketplace_to_demand": 102.3996448,
    "grid_to_demand": 35520.11367,
    "govt_battery_charge": 43.93374795
  },
  {
    "timestamp": "15/12/24 13:00",
    "demand": 30561.74592,
    "solar_generated": 20.88945797,
    "wind_generated": 77.80626334,
    "marketplace_to_demand": 81.96657453,
    "grid_to_demand": 32566.28242,
    "govt_battery_charge": 44.94710673
  },
  {
    "timestamp": "15/12/24 14:00",
    "demand": 34937.52765,
    "solar_generated": 23.58374457,
    "wind_generated": 66.41524938,
    "marketplace_to_demand": 33.73310872,
    "grid_to_demand": 30354.88404,
    "govt_battery_charge": 54.32564192
  },
  {
    "timestamp": "15/12/24 15:00",
    "demand": 33575.04544,
    "solar_generated": 14.8813934,
    "wind_generated": 55.70572044,
    "marketplace_to_demand": 32.93754127,
    "grid_to_demand": 34420.05651,
    "govt_battery_charge": 52.44099825
  },
  {
    "timestamp": "15/12/24 16:00",
    "demand": 30498.91348,
    "solar_generated": 8.794362337,
    "wind_generated": 71.89495191,
    "marketplace_to_demand": 117.3441767,
    "grid_to_demand": 35248.46573,
    "govt_battery_charge": 4.032193695
  },
  {
    "timestamp": "15/12/24 17:00",
    "demand": 33563.73637,
    "solar_generated": 18.85022271,
    "wind_generated": 80.2279259,
    "marketplace_to_demand": 67.07371088,
    "grid_to_demand": 30782.86366,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "15/12/24 18:00",
    "demand": 30877.6031,
    "solar_generated": 0,
    "wind_generated": 78.55278289,
    "marketplace_to_demand": 136.1180919,
    "grid_to_demand": 31227.33854,
    "govt_battery_charge": 89.04496443
  },
  {
    "timestamp": "15/12/24 19:00",
    "demand": 34848.75899,
    "solar_generated": 0,
    "wind_generated": 60.31518075,
    "marketplace_to_demand": 108.7248051,
    "grid_to_demand": 33960.37638,
    "govt_battery_charge": 72.44067368
  },
  {
    "timestamp": "15/12/24 20:00",
    "demand": 31623.75734,
    "solar_generated": 0,
    "wind_generated": 76.45120242,
    "marketplace_to_demand": 184.9872277,
    "grid_to_demand": 34867.83034,
    "govt_battery_charge": 83.23239842
  },
  {
    "timestamp": "15/12/24 21:00",
    "demand": 36461.44813,
    "solar_generated": 0,
    "wind_generated": 79.49320427,
    "marketplace_to_demand": 95.52989809,
    "grid_to_demand": 33853.28175,
    "govt_battery_charge": 3.45474124
  },
  {
    "timestamp": "15/12/24 22:00",
    "demand": 34902.31559,
    "solar_generated": 0,
    "wind_generated": 76.42268233,
    "marketplace_to_demand": 175.0690569,
    "grid_to_demand": 32765.93402,
    "govt_battery_charge": 23.90119446
  },
  {
    "timestamp": "15/12/24 23:00",
    "demand": 30009.1771,
    "solar_generated": 0,
    "wind_generated": 71.51966081,
    "marketplace_to_demand": 14.17766568,
    "grid_to_demand": 34773.63137,
    "govt_battery_charge": 39.28440951
  },
  {
    "timestamp": "16/12/24 0:00",
    "demand": 31179.09782,
    "solar_generated": 0,
    "wind_generated": 66.20917188,
    "marketplace_to_demand": 50.56337344,
    "grid_to_demand": 34374.19821,
    "govt_battery_charge": 69.24410358
  },
  {
    "timestamp": "16/12/24 1:00",
    "demand": 32412.1969,
    "solar_generated": 0,
    "wind_generated": 71.63487727,
    "marketplace_to_demand": 174.2405664,
    "grid_to_demand": 30370.81569,
    "govt_battery_charge": 73.33209347
  },
  {
    "timestamp": "16/12/24 2:00",
    "demand": 34620.76395,
    "solar_generated": 0,
    "wind_generated": 66.62915157,
    "marketplace_to_demand": 76.57457357,
    "grid_to_demand": 33268.86732,
    "govt_battery_charge": 25.8202705
  },
  {
    "timestamp": "16/12/24 3:00",
    "demand": 34537.3414,
    "solar_generated": 0,
    "wind_generated": 78.99928367,
    "marketplace_to_demand": 15.96176953,
    "grid_to_demand": 32937.35791,
    "govt_battery_charge": 96.78830262
  },
  {
    "timestamp": "16/12/24 4:00",
    "demand": 33257.2903,
    "solar_generated": 0,
    "wind_generated": 64.93227542,
    "marketplace_to_demand": 78.30885313,
    "grid_to_demand": 30072.64367,
    "govt_battery_charge": 68.58692663
  },
  {
    "timestamp": "16/12/24 5:00",
    "demand": 35857.29509,
    "solar_generated": 0,
    "wind_generated": 63.86164479,
    "marketplace_to_demand": 95.04627851,
    "grid_to_demand": 33225.18841,
    "govt_battery_charge": 4.298066501
  },
  {
    "timestamp": "16/12/24 6:00",
    "demand": 32263.88203,
    "solar_generated": 6.401335279,
    "wind_generated": 53.87524676,
    "marketplace_to_demand": 171.7744511,
    "grid_to_demand": 32460.40323,
    "govt_battery_charge": 22.14130271
  },
  {
    "timestamp": "16/12/24 7:00",
    "demand": 32638.0554,
    "solar_generated": 11.02099927,
    "wind_generated": 70.53037849,
    "marketplace_to_demand": 138.8862373,
    "grid_to_demand": 31874.4528,
    "govt_battery_charge": 6.544498069
  },
  {
    "timestamp": "16/12/24 8:00",
    "demand": 36170.06401,
    "solar_generated": 15.42611424,
    "wind_generated": 75.5581259,
    "marketplace_to_demand": 36.17115439,
    "grid_to_demand": 32404.92924,
    "govt_battery_charge": 97.66736909
  },
  {
    "timestamp": "16/12/24 9:00",
    "demand": 31424.48615,
    "solar_generated": 18.57200872,
    "wind_generated": 76.8030766,
    "marketplace_to_demand": 163.9600907,
    "grid_to_demand": 35602.39578,
    "govt_battery_charge": 78.13278905
  },
  {
    "timestamp": "16/12/24 10:00",
    "demand": 32053.03507,
    "solar_generated": 9.733100938,
    "wind_generated": 78.6006392,
    "marketplace_to_demand": 81.64633115,
    "grid_to_demand": 33192.62934,
    "govt_battery_charge": 38.62654416
  },
  {
    "timestamp": "16/12/24 11:00",
    "demand": 34285.51472,
    "solar_generated": 23.34802864,
    "wind_generated": 65.6247983,
    "marketplace_to_demand": 21.33892177,
    "grid_to_demand": 35362.38321,
    "govt_battery_charge": 24.51065349
  },
  {
    "timestamp": "16/12/24 12:00",
    "demand": 32164.75328,
    "solar_generated": 20.02140579,
    "wind_generated": 57.55060939,
    "marketplace_to_demand": 183.1512873,
    "grid_to_demand": 30451.88151,
    "govt_battery_charge": 3.02274834
  },
  {
    "timestamp": "16/12/24 13:00",
    "demand": 35025.27537,
    "solar_generated": 23.3282654,
    "wind_generated": 61.97933322,
    "marketplace_to_demand": 47.23788629,
    "grid_to_demand": 35131.57527,
    "govt_battery_charge": 49.00351378
  },
  {
    "timestamp": "16/12/24 14:00",
    "demand": 35638.83905,
    "solar_generated": 11.72830969,
    "wind_generated": 65.4557547,
    "marketplace_to_demand": 6.324967248,
    "grid_to_demand": 30413.27313,
    "govt_battery_charge": 16.02665696
  },
  {
    "timestamp": "16/12/24 15:00",
    "demand": 30117.15794,
    "solar_generated": 19.51898708,
    "wind_generated": 67.79722248,
    "marketplace_to_demand": 97.06000517,
    "grid_to_demand": 31025.51942,
    "govt_battery_charge": 48.93237469
  },
  {
    "timestamp": "16/12/24 16:00",
    "demand": 34581.39758,
    "solar_generated": 17.80448345,
    "wind_generated": 63.14181786,
    "marketplace_to_demand": 108.2652313,
    "grid_to_demand": 34268.75638,
    "govt_battery_charge": 72.34975713
  },
  {
    "timestamp": "16/12/24 17:00",
    "demand": 34089.13123,
    "solar_generated": 21.5301438,
    "wind_generated": 66.1266338,
    "marketplace_to_demand": 118.1823095,
    "grid_to_demand": 31738.03015,
    "govt_battery_charge": 47.46260788
  },
  {
    "timestamp": "16/12/24 18:00",
    "demand": 35128.17648,
    "solar_generated": 0,
    "wind_generated": 74.63258851,
    "marketplace_to_demand": 146.4256416,
    "grid_to_demand": 30335.44601,
    "govt_battery_charge": 33.65094918
  },
  {
    "timestamp": "16/12/24 19:00",
    "demand": 31326.30965,
    "solar_generated": 0,
    "wind_generated": 68.30861973,
    "marketplace_to_demand": 88.8888733,
    "grid_to_demand": 34252.06675,
    "govt_battery_charge": 26.55111152
  },
  {
    "timestamp": "16/12/24 20:00",
    "demand": 32540.98776,
    "solar_generated": 0,
    "wind_generated": 75.95307877,
    "marketplace_to_demand": 43.2504217,
    "grid_to_demand": 33861.81994,
    "govt_battery_charge": 28.09142279
  },
  {
    "timestamp": "16/12/24 21:00",
    "demand": 34930.38083,
    "solar_generated": 0,
    "wind_generated": 68.48753971,
    "marketplace_to_demand": 114.7752656,
    "grid_to_demand": 33115.39596,
    "govt_battery_charge": 84.68947537
  },
  {
    "timestamp": "16/12/24 22:00",
    "demand": 35386.26103,
    "solar_generated": 0,
    "wind_generated": 76.58919338,
    "marketplace_to_demand": 1.44195432,
    "grid_to_demand": 29951.81439,
    "govt_battery_charge": 95.20154197
  },
  {
    "timestamp": "16/12/24 23:00",
    "demand": 36349.42278,
    "solar_generated": 0,
    "wind_generated": 79.29731055,
    "marketplace_to_demand": 195.2327054,
    "grid_to_demand": 31702.58989,
    "govt_battery_charge": 96.04814086
  },
  {
    "timestamp": "17/12/24 0:00",
    "demand": 36579.96368,
    "solar_generated": 0,
    "wind_generated": 63.04902525,
    "marketplace_to_demand": 98.92888892,
    "grid_to_demand": 34510.73012,
    "govt_battery_charge": 75.61558427
  },
  {
    "timestamp": "17/12/24 1:00",
    "demand": 31510.93164,
    "solar_generated": 0,
    "wind_generated": 62.980151,
    "marketplace_to_demand": 190.7315903,
    "grid_to_demand": 35214.38644,
    "govt_battery_charge": 2.214289099
  },
  {
    "timestamp": "17/12/24 2:00",
    "demand": 30618.2052,
    "solar_generated": 0,
    "wind_generated": 59.75785237,
    "marketplace_to_demand": 16.32992145,
    "grid_to_demand": 34703.37004,
    "govt_battery_charge": 11.55138451
  },
  {
    "timestamp": "17/12/24 3:00",
    "demand": 33721.39709,
    "solar_generated": 0,
    "wind_generated": 65.69500204,
    "marketplace_to_demand": 98.36377136,
    "grid_to_demand": 30662.57848,
    "govt_battery_charge": 10.55589348
  },
  {
    "timestamp": "17/12/24 4:00",
    "demand": 35261.75631,
    "solar_generated": 0,
    "wind_generated": 64.96208639,
    "marketplace_to_demand": 172.0125818,
    "grid_to_demand": 34874.00483,
    "govt_battery_charge": 6.986169874
  },
  {
    "timestamp": "17/12/24 5:00",
    "demand": 34725.9528,
    "solar_generated": 0,
    "wind_generated": 66.46927963,
    "marketplace_to_demand": 196.6841652,
    "grid_to_demand": 36001.00103,
    "govt_battery_charge": 45.38675304
  },
  {
    "timestamp": "17/12/24 6:00",
    "demand": 31053.53202,
    "solar_generated": 10.92909763,
    "wind_generated": 67.58622801,
    "marketplace_to_demand": 103.0937628,
    "grid_to_demand": 31447.16829,
    "govt_battery_charge": 84.7644894
  },
  {
    "timestamp": "17/12/24 7:00",
    "demand": 31807.72486,
    "solar_generated": 17.68596346,
    "wind_generated": 54.59764704,
    "marketplace_to_demand": 13.15639226,
    "grid_to_demand": 34671.75177,
    "govt_battery_charge": 66.36853794
  },
  {
    "timestamp": "17/12/24 8:00",
    "demand": 32311.04244,
    "solar_generated": 15.86489714,
    "wind_generated": 54.01293351,
    "marketplace_to_demand": 37.5930445,
    "grid_to_demand": 34653.46361,
    "govt_battery_charge": 54.02443736
  },
  {
    "timestamp": "17/12/24 9:00",
    "demand": 33163.87316,
    "solar_generated": 11.41357068,
    "wind_generated": 71.97757601,
    "marketplace_to_demand": 156.5195657,
    "grid_to_demand": 33664.59521,
    "govt_battery_charge": 30.96344953
  },
  {
    "timestamp": "17/12/24 10:00",
    "demand": 32134.81179,
    "solar_generated": 13.22052955,
    "wind_generated": 66.60856534,
    "marketplace_to_demand": 129.6657782,
    "grid_to_demand": 32725.35256,
    "govt_battery_charge": 43.80831195
  },
  {
    "timestamp": "17/12/24 11:00",
    "demand": 34661.74759,
    "solar_generated": 15.53511401,
    "wind_generated": 58.50535466,
    "marketplace_to_demand": 8.636904267,
    "grid_to_demand": 35436.70677,
    "govt_battery_charge": 24.1622824
  },
  {
    "timestamp": "17/12/24 12:00",
    "demand": 33012.01836,
    "solar_generated": 14.03092084,
    "wind_generated": 72.25182031,
    "marketplace_to_demand": 108.4603853,
    "grid_to_demand": 35724.94298,
    "govt_battery_charge": 81.84077258
  },
  {
    "timestamp": "17/12/24 13:00",
    "demand": 31272.7759,
    "solar_generated": 18.70353647,
    "wind_generated": 78.84211806,
    "marketplace_to_demand": 28.16529245,
    "grid_to_demand": 32287.0899,
    "govt_battery_charge": 57.01607771
  },
  {
    "timestamp": "17/12/24 14:00",
    "demand": 35520.79663,
    "solar_generated": 20.02581587,
    "wind_generated": 59.99526991,
    "marketplace_to_demand": 5.809768152,
    "grid_to_demand": 34381.10862,
    "govt_battery_charge": 83.83131223
  },
  {
    "timestamp": "17/12/24 15:00",
    "demand": 33088.86127,
    "solar_generated": 21.30072305,
    "wind_generated": 76.28738578,
    "marketplace_to_demand": 196.7206964,
    "grid_to_demand": 35780.08684,
    "govt_battery_charge": 95.30970993
  },
  {
    "timestamp": "17/12/24 16:00",
    "demand": 33352.93838,
    "solar_generated": 6.506010521,
    "wind_generated": 54.97160453,
    "marketplace_to_demand": 71.17046739,
    "grid_to_demand": 30482.50218,
    "govt_battery_charge": 84.70683762
  },
  {
    "timestamp": "17/12/24 17:00",
    "demand": 35117.29281,
    "solar_generated": 23.53438134,
    "wind_generated": 75.20335356,
    "marketplace_to_demand": 151.1523673,
    "grid_to_demand": 33320.07959,
    "govt_battery_charge": 34.34911848
  },
  {
    "timestamp": "17/12/24 18:00",
    "demand": 33300.71989,
    "solar_generated": 0,
    "wind_generated": 73.70358515,
    "marketplace_to_demand": 78.56548307,
    "grid_to_demand": 33558.42472,
    "govt_battery_charge": 97.58083399
  },
  {
    "timestamp": "17/12/24 19:00",
    "demand": 33966.2118,
    "solar_generated": 0,
    "wind_generated": 73.34653921,
    "marketplace_to_demand": 5.661705996,
    "grid_to_demand": 32712.18211,
    "govt_battery_charge": 57.57623656
  },
  {
    "timestamp": "17/12/24 20:00",
    "demand": 33321.00369,
    "solar_generated": 0,
    "wind_generated": 61.04838476,
    "marketplace_to_demand": 179.6555084,
    "grid_to_demand": 36208.96757,
    "govt_battery_charge": 96.8691509
  },
  {
    "timestamp": "17/12/24 21:00",
    "demand": 31685.51151,
    "solar_generated": 0,
    "wind_generated": 77.85887123,
    "marketplace_to_demand": 98.50955703,
    "grid_to_demand": 32769.39098,
    "govt_battery_charge": 29.07095214
  },
  {
    "timestamp": "17/12/24 22:00",
    "demand": 30535.14889,
    "solar_generated": 0,
    "wind_generated": 68.29768985,
    "marketplace_to_demand": 47.44497081,
    "grid_to_demand": 35807.50944,
    "govt_battery_charge": 67.75019223
  },
  {
    "timestamp": "17/12/24 23:00",
    "demand": 32613.70196,
    "solar_generated": 0,
    "wind_generated": 60.30820528,
    "marketplace_to_demand": 178.0177659,
    "grid_to_demand": 33855.69661,
    "govt_battery_charge": 97.30871902
  },
  {
    "timestamp": "18/12/24 0:00",
    "demand": 31342.27581,
    "solar_generated": 0,
    "wind_generated": 61.17209688,
    "marketplace_to_demand": 75.04797111,
    "grid_to_demand": 31977.46934,
    "govt_battery_charge": 51.37860677
  },
  {
    "timestamp": "18/12/24 1:00",
    "demand": 35211.86687,
    "solar_generated": 0,
    "wind_generated": 71.93399517,
    "marketplace_to_demand": 176.1650159,
    "grid_to_demand": 34111.6454,
    "govt_battery_charge": 50.83572314
  },
  {
    "timestamp": "18/12/24 2:00",
    "demand": 36510.07701,
    "solar_generated": 0,
    "wind_generated": 74.01301445,
    "marketplace_to_demand": 163.8625885,
    "grid_to_demand": 34119.11772,
    "govt_battery_charge": 72.14543663
  },
  {
    "timestamp": "18/12/24 3:00",
    "demand": 35421.03832,
    "solar_generated": 0,
    "wind_generated": 66.10404185,
    "marketplace_to_demand": 5.263589982,
    "grid_to_demand": 34685.63205,
    "govt_battery_charge": 23.67657352
  },
  {
    "timestamp": "18/12/24 4:00",
    "demand": 31396.89859,
    "solar_generated": 0,
    "wind_generated": 69.42539082,
    "marketplace_to_demand": 73.42908775,
    "grid_to_demand": 31719.08067,
    "govt_battery_charge": 88.34398295
  },
  {
    "timestamp": "18/12/24 5:00",
    "demand": 31765.99258,
    "solar_generated": 0,
    "wind_generated": 73.66578148,
    "marketplace_to_demand": 137.8640723,
    "grid_to_demand": 35199.49634,
    "govt_battery_charge": 91.48466067
  },
  {
    "timestamp": "18/12/24 6:00",
    "demand": 32809.17213,
    "solar_generated": 19.42336095,
    "wind_generated": 69.53720009,
    "marketplace_to_demand": 144.6341441,
    "grid_to_demand": 35983.6777,
    "govt_battery_charge": 24.39411768
  },
  {
    "timestamp": "18/12/24 7:00",
    "demand": 35600.81654,
    "solar_generated": 19.2708748,
    "wind_generated": 59.90552518,
    "marketplace_to_demand": 29.025769,
    "grid_to_demand": 32546.4812,
    "govt_battery_charge": 91.27756107
  },
  {
    "timestamp": "18/12/24 8:00",
    "demand": 30777.21815,
    "solar_generated": 21.6915854,
    "wind_generated": 77.68976704,
    "marketplace_to_demand": 16.19582546,
    "grid_to_demand": 30702.7086,
    "govt_battery_charge": 36.27823242
  },
  {
    "timestamp": "18/12/24 9:00",
    "demand": 30464.96753,
    "solar_generated": 21.70125945,
    "wind_generated": 64.87728206,
    "marketplace_to_demand": 62.50084485,
    "grid_to_demand": 31144.61136,
    "govt_battery_charge": 84.18950538
  },
  {
    "timestamp": "18/12/24 10:00",
    "demand": 34308.09141,
    "solar_generated": 8.030818066,
    "wind_generated": 56.17146254,
    "marketplace_to_demand": 162.0154976,
    "grid_to_demand": 36119.91061,
    "govt_battery_charge": 35.93634844
  },
  {
    "timestamp": "18/12/24 11:00",
    "demand": 30597.06661,
    "solar_generated": 11.00201183,
    "wind_generated": 60.74675691,
    "marketplace_to_demand": 79.94507055,
    "grid_to_demand": 32871.18607,
    "govt_battery_charge": 60.07707253
  },
  {
    "timestamp": "18/12/24 12:00",
    "demand": 35143.09786,
    "solar_generated": 9.014842167,
    "wind_generated": 72.9809617,
    "marketplace_to_demand": 107.8740361,
    "grid_to_demand": 31622.98583,
    "govt_battery_charge": 61.253346
  },
  {
    "timestamp": "18/12/24 13:00",
    "demand": 34344.46479,
    "solar_generated": 18.57486633,
    "wind_generated": 54.70610821,
    "marketplace_to_demand": 133.8971721,
    "grid_to_demand": 34138.66798,
    "govt_battery_charge": 73.25702055
  },
  {
    "timestamp": "18/12/24 14:00",
    "demand": 32961.57576,
    "solar_generated": 21.03519777,
    "wind_generated": 65.96750973,
    "marketplace_to_demand": 88.38878804,
    "grid_to_demand": 31400.2715,
    "govt_battery_charge": 7.764148261
  },
  {
    "timestamp": "18/12/24 15:00",
    "demand": 33622.99928,
    "solar_generated": 13.09494672,
    "wind_generated": 74.05399604,
    "marketplace_to_demand": 197.4956828,
    "grid_to_demand": 30819.74782,
    "govt_battery_charge": 49.41901767
  },
  {
    "timestamp": "18/12/24 16:00",
    "demand": 36296.1492,
    "solar_generated": 19.72185417,
    "wind_generated": 74.65622698,
    "marketplace_to_demand": 43.71303526,
    "grid_to_demand": 36165.57868,
    "govt_battery_charge": 28.83531852
  },
  {
    "timestamp": "18/12/24 17:00",
    "demand": 32550.61659,
    "solar_generated": 16.40105719,
    "wind_generated": 58.11369102,
    "marketplace_to_demand": 2.435589662,
    "grid_to_demand": 35020.01223,
    "govt_battery_charge": 44.02591074
  },
  {
    "timestamp": "18/12/24 18:00",
    "demand": 35171.55449,
    "solar_generated": 0,
    "wind_generated": 79.40071564,
    "marketplace_to_demand": 94.85702621,
    "grid_to_demand": 31718.59302,
    "govt_battery_charge": 16.1976543
  },
  {
    "timestamp": "18/12/24 19:00",
    "demand": 32077.24503,
    "solar_generated": 0,
    "wind_generated": 76.95907797,
    "marketplace_to_demand": 195.9667813,
    "grid_to_demand": 30407.47613,
    "govt_battery_charge": 77.17231567
  },
  {
    "timestamp": "18/12/24 20:00",
    "demand": 34308.88368,
    "solar_generated": 0,
    "wind_generated": 78.98086378,
    "marketplace_to_demand": 42.19374217,
    "grid_to_demand": 34394.41004,
    "govt_battery_charge": 10.35745727
  },
  {
    "timestamp": "18/12/24 21:00",
    "demand": 35367.61967,
    "solar_generated": 0,
    "wind_generated": 56.64537069,
    "marketplace_to_demand": 55.52238261,
    "grid_to_demand": 32144.47904,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "18/12/24 22:00",
    "demand": 33245.38872,
    "solar_generated": 0,
    "wind_generated": 61.80711487,
    "marketplace_to_demand": 82.58343708,
    "grid_to_demand": 35994.20625,
    "govt_battery_charge": 25.25627919
  },
  {
    "timestamp": "18/12/24 23:00",
    "demand": 31054.74703,
    "solar_generated": 0,
    "wind_generated": 76.32692324,
    "marketplace_to_demand": 98.81538633,
    "grid_to_demand": 35363.40087,
    "govt_battery_charge": 53.26822568
  },
  {
    "timestamp": "19/12/24 0:00",
    "demand": 34162.04985,
    "solar_generated": 0,
    "wind_generated": 72.31405015,
    "marketplace_to_demand": 103.4163467,
    "grid_to_demand": 36483.14678,
    "govt_battery_charge": 96.1449661
  },
  {
    "timestamp": "19/12/24 1:00",
    "demand": 32185.61797,
    "solar_generated": 0,
    "wind_generated": 68.37031368,
    "marketplace_to_demand": 15.65939674,
    "grid_to_demand": 34399.32238,
    "govt_battery_charge": 85.40794298
  },
  {
    "timestamp": "19/12/24 2:00",
    "demand": 35698.15695,
    "solar_generated": 0,
    "wind_generated": 61.15121767,
    "marketplace_to_demand": 183.1757794,
    "grid_to_demand": 35299.85873,
    "govt_battery_charge": 23.99417936
  },
  {
    "timestamp": "19/12/24 3:00",
    "demand": 33408.78612,
    "solar_generated": 0,
    "wind_generated": 63.11781916,
    "marketplace_to_demand": 122.2293259,
    "grid_to_demand": 33482.62037,
    "govt_battery_charge": 78.83207554
  },
  {
    "timestamp": "19/12/24 4:00",
    "demand": 33655.53845,
    "solar_generated": 0,
    "wind_generated": 66.29551543,
    "marketplace_to_demand": 134.4878366,
    "grid_to_demand": 34338.75289,
    "govt_battery_charge": 41.04566374
  },
  {
    "timestamp": "19/12/24 5:00",
    "demand": 36003.86745,
    "solar_generated": 0,
    "wind_generated": 79.0482163,
    "marketplace_to_demand": 72.57355756,
    "grid_to_demand": 31292.51202,
    "govt_battery_charge": 92.86477257
  },
  {
    "timestamp": "19/12/24 6:00",
    "demand": 31325.78518,
    "solar_generated": 9.235237337,
    "wind_generated": 58.71521608,
    "marketplace_to_demand": 96.63249479,
    "grid_to_demand": 30539.63848,
    "govt_battery_charge": 36.44773123
  },
  {
    "timestamp": "19/12/24 7:00",
    "demand": 36364.36868,
    "solar_generated": 20.39825207,
    "wind_generated": 79.86285062,
    "marketplace_to_demand": 31.62820734,
    "grid_to_demand": 34834.40232,
    "govt_battery_charge": 25.92712025
  },
  {
    "timestamp": "19/12/24 8:00",
    "demand": 31175.41765,
    "solar_generated": 23.43726602,
    "wind_generated": 70.79741453,
    "marketplace_to_demand": 164.650231,
    "grid_to_demand": 35199.75123,
    "govt_battery_charge": 60.6568087
  },
  {
    "timestamp": "19/12/24 9:00",
    "demand": 36512.15757,
    "solar_generated": 6.919337058,
    "wind_generated": 76.68781642,
    "marketplace_to_demand": 37.21156218,
    "grid_to_demand": 31135.85134,
    "govt_battery_charge": 61.40242489
  },
  {
    "timestamp": "19/12/24 10:00",
    "demand": 30268.38567,
    "solar_generated": 7.901875793,
    "wind_generated": 77.89464839,
    "marketplace_to_demand": 40.94207498,
    "grid_to_demand": 36368.82487,
    "govt_battery_charge": 38.08424241
  },
  {
    "timestamp": "19/12/24 11:00",
    "demand": 33204.73112,
    "solar_generated": 9.512454268,
    "wind_generated": 70.34033789,
    "marketplace_to_demand": 137.6533178,
    "grid_to_demand": 35276.76109,
    "govt_battery_charge": 55.10901623
  },
  {
    "timestamp": "19/12/24 12:00",
    "demand": 34195.36951,
    "solar_generated": 15.96552619,
    "wind_generated": 80.16744297,
    "marketplace_to_demand": 43.1941033,
    "grid_to_demand": 30591.35034,
    "govt_battery_charge": 81.19215017
  },
  {
    "timestamp": "19/12/24 13:00",
    "demand": 35913.96411,
    "solar_generated": 13.48784087,
    "wind_generated": 79.90922295,
    "marketplace_to_demand": 28.14030673,
    "grid_to_demand": 32469.18456,
    "govt_battery_charge": 92.74071231
  },
  {
    "timestamp": "19/12/24 14:00",
    "demand": 32916.93894,
    "solar_generated": 12.75521322,
    "wind_generated": 71.55323035,
    "marketplace_to_demand": 19.43824203,
    "grid_to_demand": 33551.58575,
    "govt_battery_charge": 71.0353812
  },
  {
    "timestamp": "19/12/24 15:00",
    "demand": 30722.32175,
    "solar_generated": 16.20224358,
    "wind_generated": 62.71675185,
    "marketplace_to_demand": 90.38026761,
    "grid_to_demand": 33497.19708,
    "govt_battery_charge": 24.37759737
  },
  {
    "timestamp": "19/12/24 16:00",
    "demand": 33942.05153,
    "solar_generated": 11.28154205,
    "wind_generated": 65.04543805,
    "marketplace_to_demand": 152.800718,
    "grid_to_demand": 35010.27929,
    "govt_battery_charge": 75.20046849
  },
  {
    "timestamp": "19/12/24 17:00",
    "demand": 33744.64625,
    "solar_generated": 21.38387182,
    "wind_generated": 59.8647921,
    "marketplace_to_demand": 135.0508906,
    "grid_to_demand": 32596.31659,
    "govt_battery_charge": 8.090260656
  },
  {
    "timestamp": "19/12/24 18:00",
    "demand": 34887.91121,
    "solar_generated": 0,
    "wind_generated": 62.82543683,
    "marketplace_to_demand": 31.42880967,
    "grid_to_demand": 34337.35205,
    "govt_battery_charge": 25.03862484
  },
  {
    "timestamp": "19/12/24 19:00",
    "demand": 32788.42422,
    "solar_generated": 0,
    "wind_generated": 58.94862832,
    "marketplace_to_demand": 53.58243337,
    "grid_to_demand": 35173.90131,
    "govt_battery_charge": 56.87297212
  },
  {
    "timestamp": "19/12/24 20:00",
    "demand": 34702.07808,
    "solar_generated": 0,
    "wind_generated": 76.51398296,
    "marketplace_to_demand": 118.6772594,
    "grid_to_demand": 30431.89977,
    "govt_battery_charge": 11.49008816
  },
  {
    "timestamp": "19/12/24 21:00",
    "demand": 35190.37809,
    "solar_generated": 0,
    "wind_generated": 78.83961707,
    "marketplace_to_demand": 137.8923505,
    "grid_to_demand": 34945.34834,
    "govt_battery_charge": 73.87104234
  },
  {
    "timestamp": "19/12/24 22:00",
    "demand": 36264.70499,
    "solar_generated": 0,
    "wind_generated": 57.95999409,
    "marketplace_to_demand": 37.12358212,
    "grid_to_demand": 35025.28811,
    "govt_battery_charge": 60.09380529
  },
  {
    "timestamp": "19/12/24 23:00",
    "demand": 33780.24271,
    "solar_generated": 0,
    "wind_generated": 53.87352543,
    "marketplace_to_demand": 172.5145186,
    "grid_to_demand": 35776.47865,
    "govt_battery_charge": 50.9445478
  },
  {
    "timestamp": "20/12/24 0:00",
    "demand": 32945.32711,
    "solar_generated": 0,
    "wind_generated": 69.13557755,
    "marketplace_to_demand": 112.8637464,
    "grid_to_demand": 31269.29394,
    "govt_battery_charge": 1.051421305
  },
  {
    "timestamp": "20/12/24 1:00",
    "demand": 33622.05111,
    "solar_generated": 0,
    "wind_generated": 70.52788297,
    "marketplace_to_demand": 172.1071626,
    "grid_to_demand": 32272.08422,
    "govt_battery_charge": 42.76760193
  },
  {
    "timestamp": "20/12/24 2:00",
    "demand": 34338.94768,
    "solar_generated": 0,
    "wind_generated": 58.59316962,
    "marketplace_to_demand": 189.0079834,
    "grid_to_demand": 30824.66773,
    "govt_battery_charge": 23.35791213
  },
  {
    "timestamp": "20/12/24 3:00",
    "demand": 32415.54491,
    "solar_generated": 0,
    "wind_generated": 80.23301086,
    "marketplace_to_demand": 109.125619,
    "grid_to_demand": 33202.67808,
    "govt_battery_charge": 92.45262387
  },
  {
    "timestamp": "20/12/24 4:00",
    "demand": 36210.34102,
    "solar_generated": 0,
    "wind_generated": 73.41904821,
    "marketplace_to_demand": 67.65904127,
    "grid_to_demand": 33079.34006,
    "govt_battery_charge": 93.60049459
  },
  {
    "timestamp": "20/12/24 5:00",
    "demand": 35534.57488,
    "solar_generated": 0,
    "wind_generated": 67.25947311,
    "marketplace_to_demand": 192.3269913,
    "grid_to_demand": 29983.32846,
    "govt_battery_charge": 67.2112996
  },
  {
    "timestamp": "20/12/24 6:00",
    "demand": 34540.93136,
    "solar_generated": 6.930120712,
    "wind_generated": 55.61413895,
    "marketplace_to_demand": 21.97257528,
    "grid_to_demand": 33875.42472,
    "govt_battery_charge": 87.4038877
  },
  {
    "timestamp": "20/12/24 7:00",
    "demand": 34353.71776,
    "solar_generated": 15.37786073,
    "wind_generated": 53.74479166,
    "marketplace_to_demand": 64.69650206,
    "grid_to_demand": 35392.25383,
    "govt_battery_charge": 14.56186752
  },
  {
    "timestamp": "20/12/24 8:00",
    "demand": 35032.07757,
    "solar_generated": 16.9476598,
    "wind_generated": 66.20353483,
    "marketplace_to_demand": 3.230338404,
    "grid_to_demand": 35145.57348,
    "govt_battery_charge": 30.08861982
  },
  {
    "timestamp": "20/12/24 9:00",
    "demand": 33326.45735,
    "solar_generated": 10.19073134,
    "wind_generated": 71.32963201,
    "marketplace_to_demand": 22.91876894,
    "grid_to_demand": 30551.84468,
    "govt_battery_charge": 66.79468392
  },
  {
    "timestamp": "20/12/24 10:00",
    "demand": 34045.46207,
    "solar_generated": 17.90287532,
    "wind_generated": 64.60888398,
    "marketplace_to_demand": 137.0953801,
    "grid_to_demand": 31533.85385,
    "govt_battery_charge": 56.94439424
  },
  {
    "timestamp": "20/12/24 11:00",
    "demand": 31884.56811,
    "solar_generated": 23.232605,
    "wind_generated": 79.09667255,
    "marketplace_to_demand": 16.17506453,
    "grid_to_demand": 31688.18225,
    "govt_battery_charge": 39.7492207
  },
  {
    "timestamp": "20/12/24 12:00",
    "demand": 32501.97227,
    "solar_generated": 18.61353107,
    "wind_generated": 73.39476623,
    "marketplace_to_demand": 54.57201462,
    "grid_to_demand": 33987.33152,
    "govt_battery_charge": 46.84652305
  },
  {
    "timestamp": "20/12/24 13:00",
    "demand": 31229.10304,
    "solar_generated": 18.14507797,
    "wind_generated": 58.51932059,
    "marketplace_to_demand": 71.9145336,
    "grid_to_demand": 34194.36404,
    "govt_battery_charge": 77.10556547
  },
  {
    "timestamp": "20/12/24 14:00",
    "demand": 34004.93547,
    "solar_generated": 15.58674358,
    "wind_generated": 74.71717435,
    "marketplace_to_demand": 40.10440699,
    "grid_to_demand": 31585.35925,
    "govt_battery_charge": 87.90492896
  },
  {
    "timestamp": "20/12/24 15:00",
    "demand": 31210.50254,
    "solar_generated": 12.91613221,
    "wind_generated": 59.19476838,
    "marketplace_to_demand": 126.7784746,
    "grid_to_demand": 31999.39502,
    "govt_battery_charge": 16.76416661
  },
  {
    "timestamp": "20/12/24 16:00",
    "demand": 31013.01962,
    "solar_generated": 11.02074558,
    "wind_generated": 60.42267221,
    "marketplace_to_demand": 2.017231981,
    "grid_to_demand": 35900.41134,
    "govt_battery_charge": 90.83857438
  },
  {
    "timestamp": "20/12/24 17:00",
    "demand": 35525.69476,
    "solar_generated": 19.58992436,
    "wind_generated": 74.51676007,
    "marketplace_to_demand": 134.4077327,
    "grid_to_demand": 30854.4912,
    "govt_battery_charge": 53.43127543
  },
  {
    "timestamp": "20/12/24 18:00",
    "demand": 35658.68305,
    "solar_generated": 0,
    "wind_generated": 73.41096495,
    "marketplace_to_demand": 46.64814196,
    "grid_to_demand": 33212.70762,
    "govt_battery_charge": 71.77982587
  },
  {
    "timestamp": "20/12/24 19:00",
    "demand": 32777.4374,
    "solar_generated": 0,
    "wind_generated": 71.62767733,
    "marketplace_to_demand": 123.7036986,
    "grid_to_demand": 32230.46282,
    "govt_battery_charge": 42.21676299
  },
  {
    "timestamp": "20/12/24 20:00",
    "demand": 32686.32709,
    "solar_generated": 0,
    "wind_generated": 61.12875327,
    "marketplace_to_demand": 162.7469879,
    "grid_to_demand": 33924.96114,
    "govt_battery_charge": 55.00012624
  },
  {
    "timestamp": "20/12/24 21:00",
    "demand": 33730.2921,
    "solar_generated": 0,
    "wind_generated": 70.04676834,
    "marketplace_to_demand": 108.3846724,
    "grid_to_demand": 36009.35979,
    "govt_battery_charge": 59.4593025
  },
  {
    "timestamp": "20/12/24 22:00",
    "demand": 36335.51574,
    "solar_generated": 0,
    "wind_generated": 73.49371922,
    "marketplace_to_demand": 194.2059544,
    "grid_to_demand": 32058.83445,
    "govt_battery_charge": 51.67286174
  },
  {
    "timestamp": "20/12/24 23:00",
    "demand": 35315.94418,
    "solar_generated": 0,
    "wind_generated": 80.1541132,
    "marketplace_to_demand": 73.99469029,
    "grid_to_demand": 30896.89142,
    "govt_battery_charge": 69.37838602
  },
  {
    "timestamp": "21/12/24 0:00",
    "demand": 31434.60123,
    "solar_generated": 0,
    "wind_generated": 77.74401855,
    "marketplace_to_demand": 86.0182352,
    "grid_to_demand": 32854.17969,
    "govt_battery_charge": 73.62480247
  },
  {
    "timestamp": "21/12/24 1:00",
    "demand": 33392.23902,
    "solar_generated": 0,
    "wind_generated": 71.71774655,
    "marketplace_to_demand": 75.95375465,
    "grid_to_demand": 31839.79765,
    "govt_battery_charge": 3.726414649
  },
  {
    "timestamp": "21/12/24 2:00",
    "demand": 35458.6061,
    "solar_generated": 0,
    "wind_generated": 61.88199782,
    "marketplace_to_demand": 12.66101608,
    "grid_to_demand": 33988.11177,
    "govt_battery_charge": 44.6219108
  },
  {
    "timestamp": "21/12/24 3:00",
    "demand": 30380.4285,
    "solar_generated": 0,
    "wind_generated": 59.66663113,
    "marketplace_to_demand": 81.32765516,
    "grid_to_demand": 34077.82416,
    "govt_battery_charge": 12.41670787
  },
  {
    "timestamp": "21/12/24 4:00",
    "demand": 32776.42459,
    "solar_generated": 0,
    "wind_generated": 78.07720018,
    "marketplace_to_demand": 106.8309148,
    "grid_to_demand": 33402.40752,
    "govt_battery_charge": 5.10147411
  },
  {
    "timestamp": "21/12/24 5:00",
    "demand": 30657.19525,
    "solar_generated": 0,
    "wind_generated": 68.16910748,
    "marketplace_to_demand": 13.95973987,
    "grid_to_demand": 33575.33273,
    "govt_battery_charge": 14.1418216
  },
  {
    "timestamp": "21/12/24 6:00",
    "demand": 33384.37544,
    "solar_generated": 19.33488091,
    "wind_generated": 77.30054001,
    "marketplace_to_demand": 28.75178304,
    "grid_to_demand": 32219.75078,
    "govt_battery_charge": 54.99326918
  },
  {
    "timestamp": "21/12/24 7:00",
    "demand": 31212.2669,
    "solar_generated": 23.63056513,
    "wind_generated": 73.55733471,
    "marketplace_to_demand": 94.79483187,
    "grid_to_demand": 36124.2729,
    "govt_battery_charge": 20.14318829
  },
  {
    "timestamp": "21/12/24 8:00",
    "demand": 30773.61013,
    "solar_generated": 15.31797449,
    "wind_generated": 66.66226378,
    "marketplace_to_demand": 27.97021287,
    "grid_to_demand": 35039.53892,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "21/12/24 9:00",
    "demand": 34968.43586,
    "solar_generated": 19.76512102,
    "wind_generated": 75.38425472,
    "marketplace_to_demand": 112.9832016,
    "grid_to_demand": 30934.04696,
    "govt_battery_charge": 97.70875604
  },
  {
    "timestamp": "21/12/24 10:00",
    "demand": 33231.08721,
    "solar_generated": 12.67896779,
    "wind_generated": 60.81756415,
    "marketplace_to_demand": 198.5403674,
    "grid_to_demand": 33003.44395,
    "govt_battery_charge": 59.3531414
  },
  {
    "timestamp": "21/12/24 11:00",
    "demand": 33174.02721,
    "solar_generated": 14.29284506,
    "wind_generated": 77.52564524,
    "marketplace_to_demand": 19.79852682,
    "grid_to_demand": 36124.76459,
    "govt_battery_charge": 42.69290263
  },
  {
    "timestamp": "21/12/24 12:00",
    "demand": 33348.30201,
    "solar_generated": 14.87654819,
    "wind_generated": 77.63791875,
    "marketplace_to_demand": 194.6830186,
    "grid_to_demand": 34063.37177,
    "govt_battery_charge": 34.92794934
  },
  {
    "timestamp": "21/12/24 13:00",
    "demand": 34363.58478,
    "solar_generated": 22.71909177,
    "wind_generated": 67.04726153,
    "marketplace_to_demand": 161.1104179,
    "grid_to_demand": 32959.51225,
    "govt_battery_charge": 14.45013385
  },
  {
    "timestamp": "21/12/24 14:00",
    "demand": 31326.81666,
    "solar_generated": 10.97071921,
    "wind_generated": 62.69321074,
    "marketplace_to_demand": 31.91761888,
    "grid_to_demand": 34550.22213,
    "govt_battery_charge": 96.06239886
  },
  {
    "timestamp": "21/12/24 15:00",
    "demand": 32927.61877,
    "solar_generated": 20.64927693,
    "wind_generated": 56.78302636,
    "marketplace_to_demand": 74.90692272,
    "grid_to_demand": 35919.79409,
    "govt_battery_charge": 2.818495179
  },
  {
    "timestamp": "21/12/24 16:00",
    "demand": 29975.33045,
    "solar_generated": 21.72306194,
    "wind_generated": 60.90938156,
    "marketplace_to_demand": 128.4614077,
    "grid_to_demand": 30638.98614,
    "govt_battery_charge": 2.688127582
  },
  {
    "timestamp": "21/12/24 17:00",
    "demand": 30827.60833,
    "solar_generated": 22.62369568,
    "wind_generated": 74.86987562,
    "marketplace_to_demand": 74.93251326,
    "grid_to_demand": 33877.01296,
    "govt_battery_charge": 25.75907895
  },
  {
    "timestamp": "21/12/24 18:00",
    "demand": 33552.3622,
    "solar_generated": 0,
    "wind_generated": 66.70491094,
    "marketplace_to_demand": 90.1805676,
    "grid_to_demand": 31754.9802,
    "govt_battery_charge": 83.27074765
  },
  {
    "timestamp": "21/12/24 19:00",
    "demand": 32488.92998,
    "solar_generated": 0,
    "wind_generated": 56.93032125,
    "marketplace_to_demand": 110.6323094,
    "grid_to_demand": 32359.39597,
    "govt_battery_charge": 67.3734544
  },
  {
    "timestamp": "21/12/24 20:00",
    "demand": 32600.90161,
    "solar_generated": 0,
    "wind_generated": 57.76389539,
    "marketplace_to_demand": 72.31624005,
    "grid_to_demand": 31242.96471,
    "govt_battery_charge": 80.28943121
  },
  {
    "timestamp": "21/12/24 21:00",
    "demand": 36219.35001,
    "solar_generated": 0,
    "wind_generated": 65.70739195,
    "marketplace_to_demand": 107.3730795,
    "grid_to_demand": 32092.72797,
    "govt_battery_charge": 18.30673716
  },
  {
    "timestamp": "21/12/24 22:00",
    "demand": 34693.82291,
    "solar_generated": 0,
    "wind_generated": 78.2766773,
    "marketplace_to_demand": 67.39172091,
    "grid_to_demand": 35227.63674,
    "govt_battery_charge": 96.63549386
  },
  {
    "timestamp": "21/12/24 23:00",
    "demand": 31776.4847,
    "solar_generated": 0,
    "wind_generated": 68.36726883,
    "marketplace_to_demand": 111.8585243,
    "grid_to_demand": 31635.89041,
    "govt_battery_charge": 42.45144853
  },
  {
    "timestamp": "22/12/24 0:00",
    "demand": 30513.452,
    "solar_generated": 0,
    "wind_generated": 62.02298691,
    "marketplace_to_demand": 152.0350892,
    "grid_to_demand": 30184.62367,
    "govt_battery_charge": 48.7053151
  },
  {
    "timestamp": "22/12/24 1:00",
    "demand": 31693.69774,
    "solar_generated": 0,
    "wind_generated": 66.62821599,
    "marketplace_to_demand": 154.4231591,
    "grid_to_demand": 34365.47173,
    "govt_battery_charge": 2.686803304
  },
  {
    "timestamp": "22/12/24 2:00",
    "demand": 34465.40016,
    "solar_generated": 0,
    "wind_generated": 72.9695388,
    "marketplace_to_demand": 48.68120343,
    "grid_to_demand": 36144.64602,
    "govt_battery_charge": 98.38349906
  },
  {
    "timestamp": "22/12/24 3:00",
    "demand": 30162.54125,
    "solar_generated": 0,
    "wind_generated": 70.79275686,
    "marketplace_to_demand": 58.24487067,
    "grid_to_demand": 31189.73419,
    "govt_battery_charge": 33.1637715
  },
  {
    "timestamp": "22/12/24 4:00",
    "demand": 34258.73307,
    "solar_generated": 0,
    "wind_generated": 70.9277657,
    "marketplace_to_demand": 14.57384956,
    "grid_to_demand": 35136.60983,
    "govt_battery_charge": 6.080667837
  },
  {
    "timestamp": "22/12/24 5:00",
    "demand": 35545.35678,
    "solar_generated": 0,
    "wind_generated": 58.26723377,
    "marketplace_to_demand": 69.6339445,
    "grid_to_demand": 30643.93002,
    "govt_battery_charge": 10.55479497
  },
  {
    "timestamp": "22/12/24 6:00",
    "demand": 30391.42443,
    "solar_generated": 13.18685688,
    "wind_generated": 62.7085537,
    "marketplace_to_demand": 76.10186374,
    "grid_to_demand": 32308.87288,
    "govt_battery_charge": 38.91814414
  },
  {
    "timestamp": "22/12/24 7:00",
    "demand": 32552.15267,
    "solar_generated": 17.91922782,
    "wind_generated": 60.4032406,
    "marketplace_to_demand": 158.4236927,
    "grid_to_demand": 36304.03577,
    "govt_battery_charge": 48.77280506
  },
  {
    "timestamp": "22/12/24 8:00",
    "demand": 32686.25898,
    "solar_generated": 17.00362822,
    "wind_generated": 53.69841156,
    "marketplace_to_demand": 116.950698,
    "grid_to_demand": 31807.72716,
    "govt_battery_charge": 95.21903211
  },
  {
    "timestamp": "22/12/24 9:00",
    "demand": 35431.37989,
    "solar_generated": 8.564241419,
    "wind_generated": 72.4108217,
    "marketplace_to_demand": 165.0257824,
    "grid_to_demand": 30012.66609,
    "govt_battery_charge": 70.7913127
  },
  {
    "timestamp": "22/12/24 10:00",
    "demand": 29993.40314,
    "solar_generated": 21.01808137,
    "wind_generated": 60.61951464,
    "marketplace_to_demand": 14.34969651,
    "grid_to_demand": 36412.00908,
    "govt_battery_charge": 14.68234049
  },
  {
    "timestamp": "22/12/24 11:00",
    "demand": 35896.10516,
    "solar_generated": 23.24912292,
    "wind_generated": 59.70795496,
    "marketplace_to_demand": 71.63459892,
    "grid_to_demand": 33656.44423,
    "govt_battery_charge": 73.13615326
  },
  {
    "timestamp": "22/12/24 12:00",
    "demand": 34927.14122,
    "solar_generated": 21.35929158,
    "wind_generated": 69.70003469,
    "marketplace_to_demand": 60.62942181,
    "grid_to_demand": 32916.97513,
    "govt_battery_charge": 14.28195971
  },
  {
    "timestamp": "22/12/24 13:00",
    "demand": 36065.27258,
    "solar_generated": 20.82722594,
    "wind_generated": 72.16402525,
    "marketplace_to_demand": 88.00746699,
    "grid_to_demand": 33871.49832,
    "govt_battery_charge": 2.240713455
  },
  {
    "timestamp": "22/12/24 14:00",
    "demand": 30715.51917,
    "solar_generated": 20.56653035,
    "wind_generated": 69.81991941,
    "marketplace_to_demand": 126.5944954,
    "grid_to_demand": 34658.37115,
    "govt_battery_charge": 88.77476016
  },
  {
    "timestamp": "22/12/24 15:00",
    "demand": 30957.83532,
    "solar_generated": 10.49245282,
    "wind_generated": 64.81563502,
    "marketplace_to_demand": 159.9132615,
    "grid_to_demand": 36293.33508,
    "govt_battery_charge": 35.76001537
  },
  {
    "timestamp": "22/12/24 16:00",
    "demand": 34610.86623,
    "solar_generated": 22.67013434,
    "wind_generated": 69.31682786,
    "marketplace_to_demand": 37.9924317,
    "grid_to_demand": 32188.2815,
    "govt_battery_charge": 31.73111384
  },
  {
    "timestamp": "22/12/24 17:00",
    "demand": 36310.90066,
    "solar_generated": 19.6656368,
    "wind_generated": 69.48150747,
    "marketplace_to_demand": 171.430832,
    "grid_to_demand": 29985.15017,
    "govt_battery_charge": 6.479432313
  },
  {
    "timestamp": "22/12/24 18:00",
    "demand": 30510.70568,
    "solar_generated": 0,
    "wind_generated": 62.46869231,
    "marketplace_to_demand": 115.4376107,
    "grid_to_demand": 33555.27148,
    "govt_battery_charge": 88.55707643
  },
  {
    "timestamp": "22/12/24 19:00",
    "demand": 35011.53195,
    "solar_generated": 0,
    "wind_generated": 59.82349492,
    "marketplace_to_demand": 126.7601371,
    "grid_to_demand": 31642.14698,
    "govt_battery_charge": 69.96581916
  },
  {
    "timestamp": "22/12/24 20:00",
    "demand": 32512.02444,
    "solar_generated": 0,
    "wind_generated": 70.91567827,
    "marketplace_to_demand": 48.60796465,
    "grid_to_demand": 34567.93291,
    "govt_battery_charge": 72.78479553
  },
  {
    "timestamp": "22/12/24 21:00",
    "demand": 32352.49334,
    "solar_generated": 0,
    "wind_generated": 66.96578597,
    "marketplace_to_demand": 51.65401038,
    "grid_to_demand": 30815.49339,
    "govt_battery_charge": 60.13796726
  },
  {
    "timestamp": "22/12/24 22:00",
    "demand": 32510.79538,
    "solar_generated": 0,
    "wind_generated": 58.20553438,
    "marketplace_to_demand": 196.3048405,
    "grid_to_demand": 36528.73206,
    "govt_battery_charge": 54.44697825
  },
  {
    "timestamp": "22/12/24 23:00",
    "demand": 35539.62462,
    "solar_generated": 0,
    "wind_generated": 72.42333875,
    "marketplace_to_demand": 176.899607,
    "grid_to_demand": 33869.61396,
    "govt_battery_charge": 52.22486423
  },
  {
    "timestamp": "23/12/24 0:00",
    "demand": 33034.90664,
    "solar_generated": 0,
    "wind_generated": 57.26783561,
    "marketplace_to_demand": 184.9382295,
    "grid_to_demand": 33454.90983,
    "govt_battery_charge": 73.73487303
  },
  {
    "timestamp": "23/12/24 1:00",
    "demand": 35332.58062,
    "solar_generated": 0,
    "wind_generated": 66.59702864,
    "marketplace_to_demand": 88.08930603,
    "grid_to_demand": 35540.91993,
    "govt_battery_charge": 52.27232667
  },
  {
    "timestamp": "23/12/24 2:00",
    "demand": 32262.02888,
    "solar_generated": 0,
    "wind_generated": 56.31064328,
    "marketplace_to_demand": 2.196366146,
    "grid_to_demand": 36516.39583,
    "govt_battery_charge": 51.72588407
  },
  {
    "timestamp": "23/12/24 3:00",
    "demand": 36135.17591,
    "solar_generated": 0,
    "wind_generated": 66.35061721,
    "marketplace_to_demand": 41.31935224,
    "grid_to_demand": 32917.4395,
    "govt_battery_charge": 95.87984706
  },
  {
    "timestamp": "23/12/24 4:00",
    "demand": 30071.97679,
    "solar_generated": 0,
    "wind_generated": 65.30908472,
    "marketplace_to_demand": 20.01125698,
    "grid_to_demand": 34844.8384,
    "govt_battery_charge": 26.84832264
  },
  {
    "timestamp": "23/12/24 5:00",
    "demand": 34849.22346,
    "solar_generated": 0,
    "wind_generated": 78.75180499,
    "marketplace_to_demand": 153.8329433,
    "grid_to_demand": 30935.77729,
    "govt_battery_charge": 9.818216628
  },
  {
    "timestamp": "23/12/24 6:00",
    "demand": 32792.79707,
    "solar_generated": 12.46888903,
    "wind_generated": 69.9948128,
    "marketplace_to_demand": 125.5526081,
    "grid_to_demand": 32895.14752,
    "govt_battery_charge": 1.249916931
  },
  {
    "timestamp": "23/12/24 7:00",
    "demand": 30740.31613,
    "solar_generated": 6.309188797,
    "wind_generated": 64.67221212,
    "marketplace_to_demand": 117.6988352,
    "grid_to_demand": 34207.41381,
    "govt_battery_charge": 58.90341418
  },
  {
    "timestamp": "23/12/24 8:00",
    "demand": 35856.52455,
    "solar_generated": 16.97123546,
    "wind_generated": 76.23950484,
    "marketplace_to_demand": 148.9643087,
    "grid_to_demand": 31120.95398,
    "govt_battery_charge": 95.96776722
  },
  {
    "timestamp": "23/12/24 9:00",
    "demand": 32943.82458,
    "solar_generated": 10.68710597,
    "wind_generated": 54.71228092,
    "marketplace_to_demand": 106.3239501,
    "grid_to_demand": 34386.00187,
    "govt_battery_charge": 8.06905388
  },
  {
    "timestamp": "23/12/24 10:00",
    "demand": 31379.94253,
    "solar_generated": 13.32618849,
    "wind_generated": 75.08414319,
    "marketplace_to_demand": 36.60588779,
    "grid_to_demand": 34433.49309,
    "govt_battery_charge": 69.11454913
  },
  {
    "timestamp": "23/12/24 11:00",
    "demand": 35001.17078,
    "solar_generated": 20.18008068,
    "wind_generated": 67.8132581,
    "marketplace_to_demand": 191.5779794,
    "grid_to_demand": 34762.20518,
    "govt_battery_charge": 24.37651568
  },
  {
    "timestamp": "23/12/24 12:00",
    "demand": 35693.95938,
    "solar_generated": 21.83691173,
    "wind_generated": 75.9982987,
    "marketplace_to_demand": 168.9389781,
    "grid_to_demand": 33437.27448,
    "govt_battery_charge": 71.83740241
  },
  {
    "timestamp": "23/12/24 13:00",
    "demand": 35167.42134,
    "solar_generated": 21.52262471,
    "wind_generated": 78.99395596,
    "marketplace_to_demand": 115.8620919,
    "grid_to_demand": 35262.21269,
    "govt_battery_charge": 4.447656898
  },
  {
    "timestamp": "23/12/24 14:00",
    "demand": 35277.91087,
    "solar_generated": 23.885631,
    "wind_generated": 66.10856611,
    "marketplace_to_demand": 15.31539277,
    "grid_to_demand": 32865.19852,
    "govt_battery_charge": 92.55507499
  },
  {
    "timestamp": "23/12/24 15:00",
    "demand": 31712.8104,
    "solar_generated": 11.4253743,
    "wind_generated": 59.61302173,
    "marketplace_to_demand": 159.565688,
    "grid_to_demand": 34415.43077,
    "govt_battery_charge": 27.48582665
  },
  {
    "timestamp": "23/12/24 16:00",
    "demand": 34067.44898,
    "solar_generated": 8.266898314,
    "wind_generated": 78.92900406,
    "marketplace_to_demand": 9.3339363,
    "grid_to_demand": 32107.5034,
    "govt_battery_charge": 1.156203137
  },
  {
    "timestamp": "23/12/24 17:00",
    "demand": 33070.49602,
    "solar_generated": 14.62064166,
    "wind_generated": 59.85310192,
    "marketplace_to_demand": 122.6368974,
    "grid_to_demand": 31219.35809,
    "govt_battery_charge": 23.94431941
  },
  {
    "timestamp": "23/12/24 18:00",
    "demand": 34685.98235,
    "solar_generated": 0,
    "wind_generated": 72.11018604,
    "marketplace_to_demand": 89.92963397,
    "grid_to_demand": 31384.58902,
    "govt_battery_charge": 3.612041553
  },
  {
    "timestamp": "23/12/24 19:00",
    "demand": 32774.99642,
    "solar_generated": 0,
    "wind_generated": 61.99913846,
    "marketplace_to_demand": 191.4190313,
    "grid_to_demand": 33233.63537,
    "govt_battery_charge": 15.31637582
  },
  {
    "timestamp": "23/12/24 20:00",
    "demand": 35370.4716,
    "solar_generated": 0,
    "wind_generated": 63.5772136,
    "marketplace_to_demand": 151.2833177,
    "grid_to_demand": 32314.33629,
    "govt_battery_charge": 71.02132474
  },
  {
    "timestamp": "23/12/24 21:00",
    "demand": 30995.00892,
    "solar_generated": 0,
    "wind_generated": 63.68181614,
    "marketplace_to_demand": 23.09298681,
    "grid_to_demand": 32089.0255,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "23/12/24 22:00",
    "demand": 34188.45702,
    "solar_generated": 0,
    "wind_generated": 77.40197323,
    "marketplace_to_demand": 139.7647169,
    "grid_to_demand": 35296.78539,
    "govt_battery_charge": 54.58148584
  },
  {
    "timestamp": "23/12/24 23:00",
    "demand": 32512.93091,
    "solar_generated": 0,
    "wind_generated": 74.74968318,
    "marketplace_to_demand": 47.64042974,
    "grid_to_demand": 32171.8306,
    "govt_battery_charge": 88.64846938
  },
  {
    "timestamp": "24/12/24 0:00",
    "demand": 33019.49066,
    "solar_generated": 0,
    "wind_generated": 61.06824361,
    "marketplace_to_demand": 112.0140093,
    "grid_to_demand": 33856.32205,
    "govt_battery_charge": 13.02074951
  },
  {
    "timestamp": "24/12/24 1:00",
    "demand": 33415.24597,
    "solar_generated": 0,
    "wind_generated": 78.07275403,
    "marketplace_to_demand": 184.0661144,
    "grid_to_demand": 34358.65583,
    "govt_battery_charge": 64.7201828
  },
  {
    "timestamp": "24/12/24 2:00",
    "demand": 30779.74722,
    "solar_generated": 0,
    "wind_generated": 53.60970602,
    "marketplace_to_demand": 31.47717915,
    "grid_to_demand": 34835.41082,
    "govt_battery_charge": 93.98762256
  },
  {
    "timestamp": "24/12/24 3:00",
    "demand": 32204.58103,
    "solar_generated": 0,
    "wind_generated": 62.64004989,
    "marketplace_to_demand": 151.1262355,
    "grid_to_demand": 32096.54183,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "24/12/24 4:00",
    "demand": 35085.89079,
    "solar_generated": 0,
    "wind_generated": 55.75807252,
    "marketplace_to_demand": 65.78613627,
    "grid_to_demand": 30984.30635,
    "govt_battery_charge": 11.62095384
  },
  {
    "timestamp": "24/12/24 5:00",
    "demand": 32355.37152,
    "solar_generated": 0,
    "wind_generated": 64.84759012,
    "marketplace_to_demand": 162.6953066,
    "grid_to_demand": 35068.07238,
    "govt_battery_charge": 25.66736972
  },
  {
    "timestamp": "24/12/24 6:00",
    "demand": 33368.50201,
    "solar_generated": 16.45227946,
    "wind_generated": 70.81520612,
    "marketplace_to_demand": 83.71426258,
    "grid_to_demand": 30895.96906,
    "govt_battery_charge": 36.13844179
  },
  {
    "timestamp": "24/12/24 7:00",
    "demand": 31202.93803,
    "solar_generated": 22.68171886,
    "wind_generated": 74.28498992,
    "marketplace_to_demand": 49.13256489,
    "grid_to_demand": 31176.68254,
    "govt_battery_charge": 20.47985912
  },
  {
    "timestamp": "24/12/24 8:00",
    "demand": 30282.29877,
    "solar_generated": 14.01790222,
    "wind_generated": 54.10870251,
    "marketplace_to_demand": 64.34659551,
    "grid_to_demand": 33658.65555,
    "govt_battery_charge": 32.26421601
  },
  {
    "timestamp": "24/12/24 9:00",
    "demand": 34486.88606,
    "solar_generated": 8.206634274,
    "wind_generated": 60.79933066,
    "marketplace_to_demand": 194.6119358,
    "grid_to_demand": 33516.17779,
    "govt_battery_charge": 33.09923722
  },
  {
    "timestamp": "24/12/24 10:00",
    "demand": 34304.51014,
    "solar_generated": 10.03864439,
    "wind_generated": 73.98920698,
    "marketplace_to_demand": 196.8534157,
    "grid_to_demand": 32256.33095,
    "govt_battery_charge": 57.21145596
  },
  {
    "timestamp": "24/12/24 11:00",
    "demand": 34914.00082,
    "solar_generated": 14.73270243,
    "wind_generated": 64.58539614,
    "marketplace_to_demand": 156.5085739,
    "grid_to_demand": 32158.24518,
    "govt_battery_charge": 94.30108407
  },
  {
    "timestamp": "24/12/24 12:00",
    "demand": 32908.13361,
    "solar_generated": 21.6471345,
    "wind_generated": 77.66800553,
    "marketplace_to_demand": 24.54593249,
    "grid_to_demand": 34372.23339,
    "govt_battery_charge": 88.15017799
  },
  {
    "timestamp": "24/12/24 13:00",
    "demand": 34465.20283,
    "solar_generated": 10.11800961,
    "wind_generated": 62.07239829,
    "marketplace_to_demand": 93.8503291,
    "grid_to_demand": 30013.28476,
    "govt_battery_charge": 58.53785979
  },
  {
    "timestamp": "24/12/24 14:00",
    "demand": 31271.57657,
    "solar_generated": 10.54514719,
    "wind_generated": 57.13379305,
    "marketplace_to_demand": 112.2691842,
    "grid_to_demand": 32016.35208,
    "govt_battery_charge": 75.60165984
  },
  {
    "timestamp": "24/12/24 15:00",
    "demand": 30422.1327,
    "solar_generated": 9.986301946,
    "wind_generated": 57.96862129,
    "marketplace_to_demand": 112.254328,
    "grid_to_demand": 36417.50575,
    "govt_battery_charge": 81.53141989
  },
  {
    "timestamp": "24/12/24 16:00",
    "demand": 35902.81186,
    "solar_generated": 6.623800642,
    "wind_generated": 63.8899474,
    "marketplace_to_demand": 40.99068849,
    "grid_to_demand": 32265.34254,
    "govt_battery_charge": 39.89324869
  },
  {
    "timestamp": "24/12/24 17:00",
    "demand": 30920.29636,
    "solar_generated": 15.56552551,
    "wind_generated": 68.5609023,
    "marketplace_to_demand": 129.6348315,
    "grid_to_demand": 35093.95466,
    "govt_battery_charge": 84.48107039
  },
  {
    "timestamp": "24/12/24 18:00",
    "demand": 30809.35406,
    "solar_generated": 0,
    "wind_generated": 79.04685501,
    "marketplace_to_demand": 164.0170503,
    "grid_to_demand": 36359.45984,
    "govt_battery_charge": 32.22375424
  },
  {
    "timestamp": "24/12/24 19:00",
    "demand": 31401.92683,
    "solar_generated": 0,
    "wind_generated": 58.02998789,
    "marketplace_to_demand": 197.2416781,
    "grid_to_demand": 31890.42214,
    "govt_battery_charge": 42.8905015
  },
  {
    "timestamp": "24/12/24 20:00",
    "demand": 32908.69548,
    "solar_generated": 0,
    "wind_generated": 59.0627173,
    "marketplace_to_demand": 101.1490117,
    "grid_to_demand": 30381.61115,
    "govt_battery_charge": 26.87918879
  },
  {
    "timestamp": "24/12/24 21:00",
    "demand": 33335.04891,
    "solar_generated": 0,
    "wind_generated": 54.44234657,
    "marketplace_to_demand": 3.030895894,
    "grid_to_demand": 30694.63972,
    "govt_battery_charge": 40.46213517
  },
  {
    "timestamp": "24/12/24 22:00",
    "demand": 30021.60944,
    "solar_generated": 0,
    "wind_generated": 75.39584624,
    "marketplace_to_demand": 82.94307766,
    "grid_to_demand": 36471.50704,
    "govt_battery_charge": 30.37283473
  },
  {
    "timestamp": "24/12/24 23:00",
    "demand": 33674.84143,
    "solar_generated": 0,
    "wind_generated": 60.20713979,
    "marketplace_to_demand": 54.1279614,
    "grid_to_demand": 33726.77444,
    "govt_battery_charge": 97.74630082
  },
  {
    "timestamp": "25/12/24 0:00",
    "demand": 31835.59734,
    "solar_generated": 0,
    "wind_generated": 56.94643716,
    "marketplace_to_demand": 124.5199334,
    "grid_to_demand": 30553.13169,
    "govt_battery_charge": 41.33629982
  },
  {
    "timestamp": "25/12/24 1:00",
    "demand": 32682.22102,
    "solar_generated": 0,
    "wind_generated": 73.1027095,
    "marketplace_to_demand": 78.81154835,
    "grid_to_demand": 30961.97442,
    "govt_battery_charge": 7.105279602
  },
  {
    "timestamp": "25/12/24 2:00",
    "demand": 35886.43227,
    "solar_generated": 0,
    "wind_generated": 60.47756529,
    "marketplace_to_demand": 73.96277525,
    "grid_to_demand": 29970.94923,
    "govt_battery_charge": 50.73347182
  },
  {
    "timestamp": "25/12/24 3:00",
    "demand": 29992.45075,
    "solar_generated": 0,
    "wind_generated": 80.36718257,
    "marketplace_to_demand": 17.21424883,
    "grid_to_demand": 29923.21401,
    "govt_battery_charge": 36.88913592
  },
  {
    "timestamp": "25/12/24 4:00",
    "demand": 34143.01074,
    "solar_generated": 0,
    "wind_generated": 75.7065309,
    "marketplace_to_demand": 65.20599742,
    "grid_to_demand": 31506.09456,
    "govt_battery_charge": 65.56669827
  },
  {
    "timestamp": "25/12/24 5:00",
    "demand": 36226.39758,
    "solar_generated": 0,
    "wind_generated": 63.05229088,
    "marketplace_to_demand": 116.4877133,
    "grid_to_demand": 30636.72233,
    "govt_battery_charge": 74.43043513
  },
  {
    "timestamp": "25/12/24 6:00",
    "demand": 35081.26557,
    "solar_generated": 11.45322686,
    "wind_generated": 78.54183542,
    "marketplace_to_demand": 87.16411954,
    "grid_to_demand": 34517.92805,
    "govt_battery_charge": 26.58233303
  },
  {
    "timestamp": "25/12/24 7:00",
    "demand": 35584.72906,
    "solar_generated": 13.96686881,
    "wind_generated": 75.41859874,
    "marketplace_to_demand": 68.57583139,
    "grid_to_demand": 32745.45165,
    "govt_battery_charge": 7.370927891
  },
  {
    "timestamp": "25/12/24 8:00",
    "demand": 34602.32327,
    "solar_generated": 21.53455957,
    "wind_generated": 77.72685465,
    "marketplace_to_demand": 188.9551986,
    "grid_to_demand": 31765.32627,
    "govt_battery_charge": 49.31352439
  },
  {
    "timestamp": "25/12/24 9:00",
    "demand": 33815.49951,
    "solar_generated": 13.7806142,
    "wind_generated": 53.69569168,
    "marketplace_to_demand": 118.4095434,
    "grid_to_demand": 33971.65161,
    "govt_battery_charge": 39.32132392
  },
  {
    "timestamp": "25/12/24 10:00",
    "demand": 36139.28661,
    "solar_generated": 17.25626738,
    "wind_generated": 62.22596421,
    "marketplace_to_demand": 154.9258686,
    "grid_to_demand": 33658.87722,
    "govt_battery_charge": 14.26104058
  },
  {
    "timestamp": "25/12/24 11:00",
    "demand": 32414.50408,
    "solar_generated": 21.70013302,
    "wind_generated": 54.09838736,
    "marketplace_to_demand": 1.663740006,
    "grid_to_demand": 33049.98454,
    "govt_battery_charge": 93.53132641
  },
  {
    "timestamp": "25/12/24 12:00",
    "demand": 35696.95469,
    "solar_generated": 11.78131376,
    "wind_generated": 78.32208102,
    "marketplace_to_demand": 106.5695059,
    "grid_to_demand": 33966.51069,
    "govt_battery_charge": 52.48796657
  },
  {
    "timestamp": "25/12/24 13:00",
    "demand": 31637.97468,
    "solar_generated": 7.616830042,
    "wind_generated": 66.63637525,
    "marketplace_to_demand": 132.567411,
    "grid_to_demand": 34340.34124,
    "govt_battery_charge": 70.90822888
  },
  {
    "timestamp": "25/12/24 14:00",
    "demand": 34746.47023,
    "solar_generated": 19.56989534,
    "wind_generated": 77.24626874,
    "marketplace_to_demand": 162.4086651,
    "grid_to_demand": 31811.55622,
    "govt_battery_charge": 94.21746342
  },
  {
    "timestamp": "25/12/24 15:00",
    "demand": 34887.54768,
    "solar_generated": 21.45058415,
    "wind_generated": 57.26646324,
    "marketplace_to_demand": 77.06534903,
    "grid_to_demand": 31915.13404,
    "govt_battery_charge": 89.13993365
  },
  {
    "timestamp": "25/12/24 16:00",
    "demand": 35566.53465,
    "solar_generated": 11.37857115,
    "wind_generated": 79.45012933,
    "marketplace_to_demand": 27.28289212,
    "grid_to_demand": 34139.66135,
    "govt_battery_charge": 39.99573533
  },
  {
    "timestamp": "25/12/24 17:00",
    "demand": 35661.36032,
    "solar_generated": 23.22265668,
    "wind_generated": 66.97061145,
    "marketplace_to_demand": 2.228190765,
    "grid_to_demand": 36294.76083,
    "govt_battery_charge": 97.86025322
  },
  {
    "timestamp": "25/12/24 18:00",
    "demand": 35387.82688,
    "solar_generated": 0,
    "wind_generated": 77.4620343,
    "marketplace_to_demand": 117.6135613,
    "grid_to_demand": 35102.26876,
    "govt_battery_charge": 55.65975334
  },
  {
    "timestamp": "25/12/24 19:00",
    "demand": 34323.87773,
    "solar_generated": 0,
    "wind_generated": 62.40722075,
    "marketplace_to_demand": 27.66983617,
    "grid_to_demand": 32818.58582,
    "govt_battery_charge": 75.15494873
  },
  {
    "timestamp": "25/12/24 20:00",
    "demand": 35755.12127,
    "solar_generated": 0,
    "wind_generated": 68.81294296,
    "marketplace_to_demand": 81.87038479,
    "grid_to_demand": 33834.62942,
    "govt_battery_charge": 26.18035485
  },
  {
    "timestamp": "25/12/24 21:00",
    "demand": 34287.68166,
    "solar_generated": 0,
    "wind_generated": 67.26752929,
    "marketplace_to_demand": 50.05736106,
    "grid_to_demand": 31129.97099,
    "govt_battery_charge": 93.46024973
  },
  {
    "timestamp": "25/12/24 22:00",
    "demand": 36586.96841,
    "solar_generated": 0,
    "wind_generated": 58.82329766,
    "marketplace_to_demand": 138.2321618,
    "grid_to_demand": 34507.74063,
    "govt_battery_charge": 59.46396099
  },
  {
    "timestamp": "25/12/24 23:00",
    "demand": 30529.60729,
    "solar_generated": 0,
    "wind_generated": 77.89035145,
    "marketplace_to_demand": 60.77180904,
    "grid_to_demand": 30029.57858,
    "govt_battery_charge": 56.88979212
  },
  {
    "timestamp": "26/12/24 0:00",
    "demand": 32460.48492,
    "solar_generated": 0,
    "wind_generated": 55.34182377,
    "marketplace_to_demand": 146.0299982,
    "grid_to_demand": 36450.44397,
    "govt_battery_charge": 76.53654925
  },
  {
    "timestamp": "26/12/24 1:00",
    "demand": 30434.64679,
    "solar_generated": 0,
    "wind_generated": 59.96322587,
    "marketplace_to_demand": 75.55980794,
    "grid_to_demand": 35058.58765,
    "govt_battery_charge": 25.89281804
  },
  {
    "timestamp": "26/12/24 2:00",
    "demand": 34734.50434,
    "solar_generated": 0,
    "wind_generated": 60.06937324,
    "marketplace_to_demand": 123.8148008,
    "grid_to_demand": 34613.80724,
    "govt_battery_charge": 37.41527268
  },
  {
    "timestamp": "26/12/24 3:00",
    "demand": 36118.71188,
    "solar_generated": 0,
    "wind_generated": 75.2837627,
    "marketplace_to_demand": 86.08425197,
    "grid_to_demand": 31558.0693,
    "govt_battery_charge": 53.55209131
  },
  {
    "timestamp": "26/12/24 4:00",
    "demand": 30209.59471,
    "solar_generated": 0,
    "wind_generated": 65.18596067,
    "marketplace_to_demand": 54.31480915,
    "grid_to_demand": 35469.99962,
    "govt_battery_charge": 75.56858801
  },
  {
    "timestamp": "26/12/24 5:00",
    "demand": 36027.90465,
    "solar_generated": 0,
    "wind_generated": 60.9692073,
    "marketplace_to_demand": 188.7209864,
    "grid_to_demand": 36518.02665,
    "govt_battery_charge": 62.37798078
  },
  {
    "timestamp": "26/12/24 6:00",
    "demand": 32603.41499,
    "solar_generated": 18.74412659,
    "wind_generated": 58.39423441,
    "marketplace_to_demand": 197.1537729,
    "grid_to_demand": 34617.8293,
    "govt_battery_charge": 52.36511255
  },
  {
    "timestamp": "26/12/24 7:00",
    "demand": 35430.14094,
    "solar_generated": 10.70529838,
    "wind_generated": 67.88343955,
    "marketplace_to_demand": 162.0329626,
    "grid_to_demand": 34067.71174,
    "govt_battery_charge": 63.48500518
  },
  {
    "timestamp": "26/12/24 8:00",
    "demand": 35154.36195,
    "solar_generated": 21.80230411,
    "wind_generated": 74.88688687,
    "marketplace_to_demand": 7.383797358,
    "grid_to_demand": 33986.77014,
    "govt_battery_charge": 92.88213326
  },
  {
    "timestamp": "26/12/24 9:00",
    "demand": 34841.78402,
    "solar_generated": 9.483793976,
    "wind_generated": 60.41983119,
    "marketplace_to_demand": 26.29831044,
    "grid_to_demand": 32098.54221,
    "govt_battery_charge": 26.95888586
  },
  {
    "timestamp": "26/12/24 10:00",
    "demand": 31309.93895,
    "solar_generated": 13.80754198,
    "wind_generated": 60.53535327,
    "marketplace_to_demand": 49.30047227,
    "grid_to_demand": 30208.54469,
    "govt_battery_charge": 93.43945851
  },
  {
    "timestamp": "26/12/24 11:00",
    "demand": 36096.90201,
    "solar_generated": 17.30120499,
    "wind_generated": 58.10543639,
    "marketplace_to_demand": 109.06742,
    "grid_to_demand": 34436.99868,
    "govt_battery_charge": 88.03052215
  },
  {
    "timestamp": "26/12/24 12:00",
    "demand": 33094.14566,
    "solar_generated": 23.54614823,
    "wind_generated": 66.91292911,
    "marketplace_to_demand": 57.83600484,
    "grid_to_demand": 31910.88038,
    "govt_battery_charge": 25.48828792
  },
  {
    "timestamp": "26/12/24 13:00",
    "demand": 34631.66025,
    "solar_generated": 21.20691413,
    "wind_generated": 70.47503385,
    "marketplace_to_demand": 63.20577421,
    "grid_to_demand": 36227.85394,
    "govt_battery_charge": 77.9689255
  },
  {
    "timestamp": "26/12/24 14:00",
    "demand": 29985.83991,
    "solar_generated": 17.80851042,
    "wind_generated": 77.02068293,
    "marketplace_to_demand": 120.3638428,
    "grid_to_demand": 36350.07601,
    "govt_battery_charge": 44.8979536
  },
  {
    "timestamp": "26/12/24 15:00",
    "demand": 35083.25885,
    "solar_generated": 11.36404617,
    "wind_generated": 61.57484333,
    "marketplace_to_demand": 93.67036863,
    "grid_to_demand": 34228.25412,
    "govt_battery_charge": 13.22327569
  },
  {
    "timestamp": "26/12/24 16:00",
    "demand": 30347.80168,
    "solar_generated": 22.45296523,
    "wind_generated": 70.06155091,
    "marketplace_to_demand": 15.74536322,
    "grid_to_demand": 32120.27235,
    "govt_battery_charge": 84.33605722
  },
  {
    "timestamp": "26/12/24 17:00",
    "demand": 33264.76914,
    "solar_generated": 15.56601014,
    "wind_generated": 67.20715978,
    "marketplace_to_demand": 82.38841107,
    "grid_to_demand": 34200.23167,
    "govt_battery_charge": 97.41335922
  },
  {
    "timestamp": "26/12/24 18:00",
    "demand": 32800.6804,
    "solar_generated": 0,
    "wind_generated": 74.39283602,
    "marketplace_to_demand": 35.85747807,
    "grid_to_demand": 32919.46592,
    "govt_battery_charge": 61.10548802
  },
  {
    "timestamp": "26/12/24 19:00",
    "demand": 32418.39015,
    "solar_generated": 0,
    "wind_generated": 54.09035545,
    "marketplace_to_demand": 1.700082959,
    "grid_to_demand": 32891.33742,
    "govt_battery_charge": 74.29776444
  },
  {
    "timestamp": "26/12/24 20:00",
    "demand": 31521.74024,
    "solar_generated": 0,
    "wind_generated": 64.10366114,
    "marketplace_to_demand": 165.3978648,
    "grid_to_demand": 32039.95613,
    "govt_battery_charge": 95.37272561
  },
  {
    "timestamp": "26/12/24 21:00",
    "demand": 33873.85252,
    "solar_generated": 0,
    "wind_generated": 64.85351113,
    "marketplace_to_demand": 35.58619883,
    "grid_to_demand": 31133.13945,
    "govt_battery_charge": 32.21512555
  },
  {
    "timestamp": "26/12/24 22:00",
    "demand": 30994.35663,
    "solar_generated": 0,
    "wind_generated": 57.23225698,
    "marketplace_to_demand": 157.984142,
    "grid_to_demand": 32620.09538,
    "govt_battery_charge": 71.65485283
  },
  {
    "timestamp": "26/12/24 23:00",
    "demand": 34311.08648,
    "solar_generated": 0,
    "wind_generated": 77.49646426,
    "marketplace_to_demand": 49.29877368,
    "grid_to_demand": 32856.09932,
    "govt_battery_charge": 18.05462479
  },
  {
    "timestamp": "27/12/24 0:00",
    "demand": 33007.72377,
    "solar_generated": 0,
    "wind_generated": 54.17660958,
    "marketplace_to_demand": 190.8202726,
    "grid_to_demand": 34639.80503,
    "govt_battery_charge": 49.64456084
  },
  {
    "timestamp": "27/12/24 1:00",
    "demand": 34420.47242,
    "solar_generated": 0,
    "wind_generated": 60.15396732,
    "marketplace_to_demand": 43.02217448,
    "grid_to_demand": 31739.56725,
    "govt_battery_charge": 66.2359008
  },
  {
    "timestamp": "27/12/24 2:00",
    "demand": 33353.96765,
    "solar_generated": 0,
    "wind_generated": 73.915633,
    "marketplace_to_demand": 126.5996791,
    "grid_to_demand": 31128.4965,
    "govt_battery_charge": 56.08105284
  },
  {
    "timestamp": "27/12/24 3:00",
    "demand": 30509.66088,
    "solar_generated": 0,
    "wind_generated": 75.49185142,
    "marketplace_to_demand": 34.36351097,
    "grid_to_demand": 36489.85094,
    "govt_battery_charge": 75.14158746
  },
  {
    "timestamp": "27/12/24 4:00",
    "demand": 36397.3437,
    "solar_generated": 0,
    "wind_generated": 70.81944063,
    "marketplace_to_demand": 169.8254796,
    "grid_to_demand": 31722.46448,
    "govt_battery_charge": 97.58408568
  },
  {
    "timestamp": "27/12/24 5:00",
    "demand": 31661.93762,
    "solar_generated": 0,
    "wind_generated": 70.47795559,
    "marketplace_to_demand": 150.9387374,
    "grid_to_demand": 30941.94731,
    "govt_battery_charge": 88.8317455
  },
  {
    "timestamp": "27/12/24 6:00",
    "demand": 30169.33855,
    "solar_generated": 14.2403218,
    "wind_generated": 73.37540196,
    "marketplace_to_demand": 165.2068369,
    "grid_to_demand": 34030.37315,
    "govt_battery_charge": 43.21310694
  },
  {
    "timestamp": "27/12/24 7:00",
    "demand": 31290.98239,
    "solar_generated": 18.3060019,
    "wind_generated": 56.17802863,
    "marketplace_to_demand": 41.00192171,
    "grid_to_demand": 32158.5792,
    "govt_battery_charge": 35.01505232
  },
  {
    "timestamp": "27/12/24 8:00",
    "demand": 32221.97181,
    "solar_generated": 11.02638821,
    "wind_generated": 69.39179538,
    "marketplace_to_demand": 166.7836086,
    "grid_to_demand": 34621.6583,
    "govt_battery_charge": 36.24794491
  },
  {
    "timestamp": "27/12/24 9:00",
    "demand": 35696.0193,
    "solar_generated": 15.08837093,
    "wind_generated": 75.06277688,
    "marketplace_to_demand": 181.3156891,
    "grid_to_demand": 31493.66656,
    "govt_battery_charge": 3.13694638
  },
  {
    "timestamp": "27/12/24 10:00",
    "demand": 35706.93995,
    "solar_generated": 7.418495521,
    "wind_generated": 63.15375563,
    "marketplace_to_demand": 84.18358874,
    "grid_to_demand": 32623.70833,
    "govt_battery_charge": 10.19999515
  },
  {
    "timestamp": "27/12/24 11:00",
    "demand": 36058.30791,
    "solar_generated": 11.61488862,
    "wind_generated": 77.28055292,
    "marketplace_to_demand": 113.5158975,
    "grid_to_demand": 30945.09346,
    "govt_battery_charge": 34.7511811
  },
  {
    "timestamp": "27/12/24 12:00",
    "demand": 30920.21135,
    "solar_generated": 19.08877485,
    "wind_generated": 71.71801085,
    "marketplace_to_demand": 56.62854556,
    "grid_to_demand": 34247.40973,
    "govt_battery_charge": 92.6127623
  },
  {
    "timestamp": "27/12/24 13:00",
    "demand": 33574.66681,
    "solar_generated": 6.351200007,
    "wind_generated": 77.70297253,
    "marketplace_to_demand": 186.6125824,
    "grid_to_demand": 31206.55329,
    "govt_battery_charge": 37.54376533
  },
  {
    "timestamp": "27/12/24 14:00",
    "demand": 30776.09843,
    "solar_generated": 7.207688636,
    "wind_generated": 57.43762973,
    "marketplace_to_demand": 10.79868634,
    "grid_to_demand": 32860.28516,
    "govt_battery_charge": 53.5138084
  },
  {
    "timestamp": "27/12/24 15:00",
    "demand": 36550.51809,
    "solar_generated": 9.49941692,
    "wind_generated": 69.96245338,
    "marketplace_to_demand": 178.6026271,
    "grid_to_demand": 31176.21791,
    "govt_battery_charge": 69.10161642
  },
  {
    "timestamp": "27/12/24 16:00",
    "demand": 33840.81457,
    "solar_generated": 7.991218075,
    "wind_generated": 65.01281482,
    "marketplace_to_demand": 107.1457543,
    "grid_to_demand": 34889.95584,
    "govt_battery_charge": 96.13546519
  },
  {
    "timestamp": "27/12/24 17:00",
    "demand": 36255.8852,
    "solar_generated": 18.62503209,
    "wind_generated": 79.74369985,
    "marketplace_to_demand": 161.0901803,
    "grid_to_demand": 35661.27167,
    "govt_battery_charge": 8.904916633
  },
  {
    "timestamp": "27/12/24 18:00",
    "demand": 32487.35386,
    "solar_generated": 0,
    "wind_generated": 68.35362083,
    "marketplace_to_demand": 166.0308664,
    "grid_to_demand": 32358.90001,
    "govt_battery_charge": 86.03840842
  },
  {
    "timestamp": "27/12/24 19:00",
    "demand": 36505.22851,
    "solar_generated": 0,
    "wind_generated": 56.04673685,
    "marketplace_to_demand": 115.6800479,
    "grid_to_demand": 33235.63939,
    "govt_battery_charge": 56.05022707
  },
  {
    "timestamp": "27/12/24 20:00",
    "demand": 31256.35811,
    "solar_generated": 0,
    "wind_generated": 61.88224378,
    "marketplace_to_demand": 149.256073,
    "grid_to_demand": 35676.93181,
    "govt_battery_charge": 61.75085477
  },
  {
    "timestamp": "27/12/24 21:00",
    "demand": 32179.66701,
    "solar_generated": 0,
    "wind_generated": 69.06813761,
    "marketplace_to_demand": 74.58202231,
    "grid_to_demand": 34700.96713,
    "govt_battery_charge": 3.682265136
  },
  {
    "timestamp": "27/12/24 22:00",
    "demand": 33624.2078,
    "solar_generated": 0,
    "wind_generated": 58.24637026,
    "marketplace_to_demand": 167.9647605,
    "grid_to_demand": 34446.57089,
    "govt_battery_charge": 73.01871855
  },
  {
    "timestamp": "27/12/24 23:00",
    "demand": 34994.29835,
    "solar_generated": 0,
    "wind_generated": 65.02036193,
    "marketplace_to_demand": 150.106794,
    "grid_to_demand": 31173.79543,
    "govt_battery_charge": 78.75173399
  },
  {
    "timestamp": "28/12/24 0:00",
    "demand": 32555.70878,
    "solar_generated": 0,
    "wind_generated": 73.34278093,
    "marketplace_to_demand": 57.1168061,
    "grid_to_demand": 35397.48556,
    "govt_battery_charge": 30.37814356
  },
  {
    "timestamp": "28/12/24 1:00",
    "demand": 31334.47778,
    "solar_generated": 0,
    "wind_generated": 76.61173712,
    "marketplace_to_demand": 92.85304957,
    "grid_to_demand": 35479.54571,
    "govt_battery_charge": 55.8835231
  },
  {
    "timestamp": "28/12/24 2:00",
    "demand": 33509.39286,
    "solar_generated": 0,
    "wind_generated": 63.72483129,
    "marketplace_to_demand": 78.60984628,
    "grid_to_demand": 34661.87856,
    "govt_battery_charge": 27.06325226
  },
  {
    "timestamp": "28/12/24 3:00",
    "demand": 36061.95735,
    "solar_generated": 0,
    "wind_generated": 64.52873993,
    "marketplace_to_demand": 192.895633,
    "grid_to_demand": 31451.19854,
    "govt_battery_charge": 70.8971233
  },
  {
    "timestamp": "28/12/24 4:00",
    "demand": 30428.8141,
    "solar_generated": 0,
    "wind_generated": 59.16753652,
    "marketplace_to_demand": 173.472594,
    "grid_to_demand": 31500.77681,
    "govt_battery_charge": 33.25887564
  },
  {
    "timestamp": "28/12/24 5:00",
    "demand": 35989.01147,
    "solar_generated": 0,
    "wind_generated": 73.19376364,
    "marketplace_to_demand": 14.41513718,
    "grid_to_demand": 31804.50183,
    "govt_battery_charge": 11.03965443
  },
  {
    "timestamp": "28/12/24 6:00",
    "demand": 33601.95066,
    "solar_generated": 9.356401222,
    "wind_generated": 54.00443764,
    "marketplace_to_demand": 141.8980694,
    "grid_to_demand": 31824.90371,
    "govt_battery_charge": 74.24485125
  },
  {
    "timestamp": "28/12/24 7:00",
    "demand": 32753.21851,
    "solar_generated": 23.928049,
    "wind_generated": 79.35417269,
    "marketplace_to_demand": 113.8914224,
    "grid_to_demand": 36241.44451,
    "govt_battery_charge": 67.94505123
  },
  {
    "timestamp": "28/12/24 8:00",
    "demand": 32066.43189,
    "solar_generated": 14.16436755,
    "wind_generated": 75.57886921,
    "marketplace_to_demand": 103.2902795,
    "grid_to_demand": 35172.15997,
    "govt_battery_charge": 40.69106524
  },
  {
    "timestamp": "28/12/24 9:00",
    "demand": 36153.70023,
    "solar_generated": 12.59432489,
    "wind_generated": 58.112406,
    "marketplace_to_demand": 12.73713438,
    "grid_to_demand": 34413.74526,
    "govt_battery_charge": 9.058506241
  },
  {
    "timestamp": "28/12/24 10:00",
    "demand": 30312.52675,
    "solar_generated": 8.392298797,
    "wind_generated": 59.41613361,
    "marketplace_to_demand": 23.81997153,
    "grid_to_demand": 35636.1694,
    "govt_battery_charge": 13.3271136
  },
  {
    "timestamp": "28/12/24 11:00",
    "demand": 35683.82302,
    "solar_generated": 15.91235674,
    "wind_generated": 74.95284989,
    "marketplace_to_demand": 17.20658329,
    "grid_to_demand": 36346.16351,
    "govt_battery_charge": 27.23606303
  },
  {
    "timestamp": "28/12/24 12:00",
    "demand": 34783.76691,
    "solar_generated": 14.68442586,
    "wind_generated": 62.88206878,
    "marketplace_to_demand": 56.71266888,
    "grid_to_demand": 32891.62567,
    "govt_battery_charge": 74.16809303
  },
  {
    "timestamp": "28/12/24 13:00",
    "demand": 33079.88926,
    "solar_generated": 14.703176,
    "wind_generated": 71.06584602,
    "marketplace_to_demand": 17.88761161,
    "grid_to_demand": 31683.28898,
    "govt_battery_charge": 93.30485092
  },
  {
    "timestamp": "28/12/24 14:00",
    "demand": 36606.52801,
    "solar_generated": 18.74055054,
    "wind_generated": 71.08231369,
    "marketplace_to_demand": 44.56808215,
    "grid_to_demand": 33125.92273,
    "govt_battery_charge": 42.27174557
  },
  {
    "timestamp": "28/12/24 15:00",
    "demand": 30428.26443,
    "solar_generated": 14.79550301,
    "wind_generated": 71.551813,
    "marketplace_to_demand": 58.34793888,
    "grid_to_demand": 30603.51402,
    "govt_battery_charge": 60.58643718
  },
  {
    "timestamp": "28/12/24 16:00",
    "demand": 34398.82263,
    "solar_generated": 10.00971474,
    "wind_generated": 73.16801475,
    "marketplace_to_demand": 52.1515227,
    "grid_to_demand": 31012.04029,
    "govt_battery_charge": 44.3747568
  },
  {
    "timestamp": "28/12/24 17:00",
    "demand": 34126.71009,
    "solar_generated": 12.9546987,
    "wind_generated": 55.68633871,
    "marketplace_to_demand": 70.76689446,
    "grid_to_demand": 32515.25719,
    "govt_battery_charge": 83.14316186
  },
  {
    "timestamp": "28/12/24 18:00",
    "demand": 31606.88373,
    "solar_generated": 0,
    "wind_generated": 69.37735646,
    "marketplace_to_demand": 22.53160934,
    "grid_to_demand": 32028.33314,
    "govt_battery_charge": 14.39682315
  },
  {
    "timestamp": "28/12/24 19:00",
    "demand": 36200.22938,
    "solar_generated": 0,
    "wind_generated": 80.06256623,
    "marketplace_to_demand": 99.15808783,
    "grid_to_demand": 33806.97449,
    "govt_battery_charge": 75.97689673
  },
  {
    "timestamp": "28/12/24 20:00",
    "demand": 30403.80936,
    "solar_generated": 0,
    "wind_generated": 58.5781669,
    "marketplace_to_demand": 188.2553989,
    "grid_to_demand": 34150.33629,
    "govt_battery_charge": 6.199079739
  },
  {
    "timestamp": "28/12/24 21:00",
    "demand": 30572.1028,
    "solar_generated": 0,
    "wind_generated": 75.37856293,
    "marketplace_to_demand": 124.9946959,
    "grid_to_demand": 33393.7699,
    "govt_battery_charge": 83.02945556
  },
  {
    "timestamp": "28/12/24 22:00",
    "demand": 32093.93318,
    "solar_generated": 0,
    "wind_generated": 72.81160315,
    "marketplace_to_demand": 113.8293376,
    "grid_to_demand": 31476.91975,
    "govt_battery_charge": 82.59714995
  },
  {
    "timestamp": "28/12/24 23:00",
    "demand": 31793.87609,
    "solar_generated": 0,
    "wind_generated": 63.73276585,
    "marketplace_to_demand": 61.48710204,
    "grid_to_demand": 30622.61207,
    "govt_battery_charge": 69.16129599
  },
  {
    "timestamp": "29/12/24 0:00",
    "demand": 35970.77887,
    "solar_generated": 0,
    "wind_generated": 77.14874057,
    "marketplace_to_demand": 12.08907079,
    "grid_to_demand": 34093.3662,
    "govt_battery_charge": 68.14458533
  },
  {
    "timestamp": "29/12/24 1:00",
    "demand": 31040.52846,
    "solar_generated": 0,
    "wind_generated": 73.01333314,
    "marketplace_to_demand": 92.4700513,
    "grid_to_demand": 35308.11765,
    "govt_battery_charge": 35.44127944
  },
  {
    "timestamp": "29/12/24 2:00",
    "demand": 35142.11118,
    "solar_generated": 0,
    "wind_generated": 75.43200895,
    "marketplace_to_demand": 194.3989242,
    "grid_to_demand": 34341.90855,
    "govt_battery_charge": 24.61997631
  },
  {
    "timestamp": "29/12/24 3:00",
    "demand": 34523.26943,
    "solar_generated": 0,
    "wind_generated": 66.97394956,
    "marketplace_to_demand": 91.03161014,
    "grid_to_demand": 33337.86561,
    "govt_battery_charge": 26.5732552
  },
  {
    "timestamp": "29/12/24 4:00",
    "demand": 30618.961,
    "solar_generated": 0,
    "wind_generated": 72.34298325,
    "marketplace_to_demand": 192.3629294,
    "grid_to_demand": 33483.8799,
    "govt_battery_charge": 94.49474795
  },
  {
    "timestamp": "29/12/24 5:00",
    "demand": 30900.46259,
    "solar_generated": 0,
    "wind_generated": 80.38691803,
    "marketplace_to_demand": 47.99803181,
    "grid_to_demand": 33655.1685,
    "govt_battery_charge": 69.00804603
  },
  {
    "timestamp": "29/12/24 6:00",
    "demand": 33403.85256,
    "solar_generated": 6.032070933,
    "wind_generated": 69.95820764,
    "marketplace_to_demand": 43.73879622,
    "grid_to_demand": 30615.14298,
    "govt_battery_charge": 85.89013897
  },
  {
    "timestamp": "29/12/24 7:00",
    "demand": 34391.84361,
    "solar_generated": 13.46475721,
    "wind_generated": 55.57133144,
    "marketplace_to_demand": 159.8663899,
    "grid_to_demand": 32208.8207,
    "govt_battery_charge": 47.78755076
  },
  {
    "timestamp": "29/12/24 8:00",
    "demand": 34160.76571,
    "solar_generated": 11.08532016,
    "wind_generated": 68.56966134,
    "marketplace_to_demand": 2.588149115,
    "grid_to_demand": 35630.99774,
    "govt_battery_charge": 86.44510109
  },
  {
    "timestamp": "29/12/24 9:00",
    "demand": 32773.54787,
    "solar_generated": 14.34102473,
    "wind_generated": 65.62129739,
    "marketplace_to_demand": 67.44233814,
    "grid_to_demand": 33637.50332,
    "govt_battery_charge": 95.99326725
  },
  {
    "timestamp": "29/12/24 10:00",
    "demand": 32067.16947,
    "solar_generated": 12.86297179,
    "wind_generated": 56.65248546,
    "marketplace_to_demand": 71.72705646,
    "grid_to_demand": 30470.32959,
    "govt_battery_charge": 28.76387328
  },
  {
    "timestamp": "29/12/24 11:00",
    "demand": 36264.29513,
    "solar_generated": 12.05824638,
    "wind_generated": 68.01714838,
    "marketplace_to_demand": 30.51716814,
    "grid_to_demand": 33240.24633,
    "govt_battery_charge": 72.69346652
  },
  {
    "timestamp": "29/12/24 12:00",
    "demand": 34267.92746,
    "solar_generated": 20.51967625,
    "wind_generated": 68.10877171,
    "marketplace_to_demand": 25.9620213,
    "grid_to_demand": 30748.84917,
    "govt_battery_charge": 34.67620043
  },
  {
    "timestamp": "29/12/24 13:00",
    "demand": 31145.7855,
    "solar_generated": 15.20118103,
    "wind_generated": 79.24194195,
    "marketplace_to_demand": 162.6874377,
    "grid_to_demand": 31147.00812,
    "govt_battery_charge": 40.56132215
  },
  {
    "timestamp": "29/12/24 14:00",
    "demand": 31427.42533,
    "solar_generated": 19.00968169,
    "wind_generated": 59.06028258,
    "marketplace_to_demand": 80.08112938,
    "grid_to_demand": 35186.09472,
    "govt_battery_charge": 76.34219505
  },
  {
    "timestamp": "29/12/24 15:00",
    "demand": 32999.7696,
    "solar_generated": 15.18973499,
    "wind_generated": 57.87018097,
    "marketplace_to_demand": 117.0474837,
    "grid_to_demand": 34522.54528,
    "govt_battery_charge": 39.29050305
  },
  {
    "timestamp": "29/12/24 16:00",
    "demand": 34716.95505,
    "solar_generated": 9.269371536,
    "wind_generated": 78.38317457,
    "marketplace_to_demand": 190.7855684,
    "grid_to_demand": 36440.09262,
    "govt_battery_charge": 97.7180662
  },
  {
    "timestamp": "29/12/24 17:00",
    "demand": 35231.83859,
    "solar_generated": 12.64182792,
    "wind_generated": 80.05652573,
    "marketplace_to_demand": 101.8123884,
    "grid_to_demand": 31717.76955,
    "govt_battery_charge": 50.59235889
  },
  {
    "timestamp": "29/12/24 18:00",
    "demand": 32519.1668,
    "solar_generated": 0,
    "wind_generated": 74.68422512,
    "marketplace_to_demand": 56.1499191,
    "grid_to_demand": 32306.27907,
    "govt_battery_charge": 50.41085246
  },
  {
    "timestamp": "29/12/24 19:00",
    "demand": 33684.98617,
    "solar_generated": 0,
    "wind_generated": 67.82130307,
    "marketplace_to_demand": 158.2235911,
    "grid_to_demand": 30663.72632,
    "govt_battery_charge": 11.24114897
  },
  {
    "timestamp": "29/12/24 20:00",
    "demand": 31048.18157,
    "solar_generated": 0,
    "wind_generated": 76.08082937,
    "marketplace_to_demand": 51.19851237,
    "grid_to_demand": 35691.3135,
    "govt_battery_charge": 47.44562921
  },
  {
    "timestamp": "29/12/24 21:00",
    "demand": 34428.31214,
    "solar_generated": 0,
    "wind_generated": 79.0706182,
    "marketplace_to_demand": 51.13052849,
    "grid_to_demand": 34546.70457,
    "govt_battery_charge": 13.79110464
  },
  {
    "timestamp": "29/12/24 22:00",
    "demand": 30268.22274,
    "solar_generated": 0,
    "wind_generated": 77.32211411,
    "marketplace_to_demand": 133.7214986,
    "grid_to_demand": 36293.15014,
    "govt_battery_charge": 78.84402606
  },
  {
    "timestamp": "29/12/24 23:00",
    "demand": 36187.03135,
    "solar_generated": 0,
    "wind_generated": 61.71819813,
    "marketplace_to_demand": 59.02218774,
    "grid_to_demand": 35559.89216,
    "govt_battery_charge": 50.09880997
  },
  {
    "timestamp": "30/12/24 0:00",
    "demand": 31092.20939,
    "solar_generated": 0,
    "wind_generated": 62.85249659,
    "marketplace_to_demand": 66.69530528,
    "grid_to_demand": 30584.04034,
    "govt_battery_charge": 34.36052467
  },
  {
    "timestamp": "30/12/24 1:00",
    "demand": 32019.46371,
    "solar_generated": 0,
    "wind_generated": 73.93323297,
    "marketplace_to_demand": 197.8861931,
    "grid_to_demand": 34684.58263,
    "govt_battery_charge": 4.382544084
  },
  {
    "timestamp": "30/12/24 2:00",
    "demand": 34283.28674,
    "solar_generated": 0,
    "wind_generated": 68.21819714,
    "marketplace_to_demand": 108.1450967,
    "grid_to_demand": 32389.24248,
    "govt_battery_charge": 19.53543336
  },
  {
    "timestamp": "30/12/24 3:00",
    "demand": 35632.01895,
    "solar_generated": 0,
    "wind_generated": 75.90196481,
    "marketplace_to_demand": 30.82858794,
    "grid_to_demand": 34505.20283,
    "govt_battery_charge": 37.6056518
  },
  {
    "timestamp": "30/12/24 4:00",
    "demand": 33086.01925,
    "solar_generated": 0,
    "wind_generated": 54.675698,
    "marketplace_to_demand": 24.46754808,
    "grid_to_demand": 31557.83425,
    "govt_battery_charge": 9.143796726
  },
  {
    "timestamp": "30/12/24 5:00",
    "demand": 32033.46726,
    "solar_generated": 0,
    "wind_generated": 71.83308368,
    "marketplace_to_demand": 82.01641193,
    "grid_to_demand": 29972.15736,
    "govt_battery_charge": 74.0995606
  },
  {
    "timestamp": "30/12/24 6:00",
    "demand": 34177.08653,
    "solar_generated": 20.16079085,
    "wind_generated": 73.34934163,
    "marketplace_to_demand": 186.8734814,
    "grid_to_demand": 32053.77794,
    "govt_battery_charge": 81.38020523
  },
  {
    "timestamp": "30/12/24 7:00",
    "demand": 30460.48539,
    "solar_generated": 15.78780338,
    "wind_generated": 62.03593587,
    "marketplace_to_demand": 92.9686801,
    "grid_to_demand": 35098.05236,
    "govt_battery_charge": 7.150427217
  },
  {
    "timestamp": "30/12/24 8:00",
    "demand": 35606.3568,
    "solar_generated": 21.77232267,
    "wind_generated": 75.50961088,
    "marketplace_to_demand": 130.4904642,
    "grid_to_demand": 30279.43354,
    "govt_battery_charge": 71.0662542
  },
  {
    "timestamp": "30/12/24 9:00",
    "demand": 30738.59164,
    "solar_generated": 15.95755099,
    "wind_generated": 68.96902823,
    "marketplace_to_demand": 49.84207919,
    "grid_to_demand": 33956.31849,
    "govt_battery_charge": 89.71742183
  },
  {
    "timestamp": "30/12/24 10:00",
    "demand": 33490.49418,
    "solar_generated": 12.77388998,
    "wind_generated": 67.1352822,
    "marketplace_to_demand": 51.95831703,
    "grid_to_demand": 35785.95455,
    "govt_battery_charge": 14.9899974
  },
  {
    "timestamp": "30/12/24 11:00",
    "demand": 33452.50273,
    "solar_generated": 22.02340094,
    "wind_generated": 77.47505754,
    "marketplace_to_demand": 10.6318818,
    "grid_to_demand": 33706.82113,
    "govt_battery_charge": 38.28243051
  },
  {
    "timestamp": "30/12/24 12:00",
    "demand": 31881.50838,
    "solar_generated": 16.76242571,
    "wind_generated": 55.04820591,
    "marketplace_to_demand": 153.2143274,
    "grid_to_demand": 30267.87904,
    "govt_battery_charge": 25.77684748
  },
  {
    "timestamp": "30/12/24 13:00",
    "demand": 30510.24068,
    "solar_generated": 22.2485572,
    "wind_generated": 67.4185062,
    "marketplace_to_demand": 62.84904423,
    "grid_to_demand": 32987.86373,
    "govt_battery_charge": 37.81991533
  },
  {
    "timestamp": "30/12/24 14:00",
    "demand": 35815.96111,
    "solar_generated": 11.4499239,
    "wind_generated": 61.5864446,
    "marketplace_to_demand": 65.56230098,
    "grid_to_demand": 32953.4587,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "30/12/24 15:00",
    "demand": 30688.7283,
    "solar_generated": 21.19213886,
    "wind_generated": 55.21411791,
    "marketplace_to_demand": 2.1718927,
    "grid_to_demand": 34345.41868,
    "govt_battery_charge": 13.5345821
  },
  {
    "timestamp": "30/12/24 16:00",
    "demand": 30459.41757,
    "solar_generated": 7.003712822,
    "wind_generated": 78.74929615,
    "marketplace_to_demand": 76.99628481,
    "grid_to_demand": 30649.40665,
    "govt_battery_charge": 62.94140344
  },
  {
    "timestamp": "30/12/24 17:00",
    "demand": 34929.84309,
    "solar_generated": 23.32220358,
    "wind_generated": 72.00713769,
    "marketplace_to_demand": 93.91950116,
    "grid_to_demand": 36082.12245,
    "govt_battery_charge": 72.67861402
  },
  {
    "timestamp": "30/12/24 18:00",
    "demand": 35100.42513,
    "solar_generated": 0,
    "wind_generated": 78.96484255,
    "marketplace_to_demand": 178.5970674,
    "grid_to_demand": 29938.26792,
    "govt_battery_charge": 24.0337988
  },
  {
    "timestamp": "30/12/24 19:00",
    "demand": 34942.35323,
    "solar_generated": 0,
    "wind_generated": 77.8609224,
    "marketplace_to_demand": 169.8041568,
    "grid_to_demand": 31388.37138,
    "govt_battery_charge": 53.56317022
  },
  {
    "timestamp": "30/12/24 20:00",
    "demand": 33231.97063,
    "solar_generated": 0,
    "wind_generated": 54.31570114,
    "marketplace_to_demand": 157.7258463,
    "grid_to_demand": 33641.25263,
    "govt_battery_charge": 62.62453516
  },
  {
    "timestamp": "30/12/24 21:00",
    "demand": 32163.6613,
    "solar_generated": 0,
    "wind_generated": 64.77081956,
    "marketplace_to_demand": 74.4668454,
    "grid_to_demand": 31695.28332,
    "govt_battery_charge": 41.71918206
  },
  {
    "timestamp": "30/12/24 22:00",
    "demand": 33139.89651,
    "solar_generated": 0,
    "wind_generated": 55.2834906,
    "marketplace_to_demand": 33.55412854,
    "grid_to_demand": 34898.63139,
    "govt_battery_charge": 43.10273207
  },
  {
    "timestamp": "30/12/24 23:00",
    "demand": 34865.13141,
    "solar_generated": 0,
    "wind_generated": 56.8790278,
    "marketplace_to_demand": 179.4018954,
    "grid_to_demand": 31939.05827,
    "govt_battery_charge": 31.00842402
  },
  {
    "timestamp": "31/12/24 0:00",
    "demand": 33525.25412,
    "solar_generated": 0,
    "wind_generated": 79.49278487,
    "marketplace_to_demand": 136.0876656,
    "grid_to_demand": 36075.72459,
    "govt_battery_charge": 74.1862142
  },
  {
    "timestamp": "31/12/24 1:00",
    "demand": 35397.40291,
    "solar_generated": 0,
    "wind_generated": 75.78366718,
    "marketplace_to_demand": 58.00906349,
    "grid_to_demand": 33444.57021,
    "govt_battery_charge": 40.85959998
  },
  {
    "timestamp": "31/12/24 2:00",
    "demand": 31414.20463,
    "solar_generated": 0,
    "wind_generated": 59.39986424,
    "marketplace_to_demand": 128.9359499,
    "grid_to_demand": 32658.57838,
    "govt_battery_charge": 45.44318545
  },
  {
    "timestamp": "31/12/24 3:00",
    "demand": 32424.28907,
    "solar_generated": 0,
    "wind_generated": 65.179406,
    "marketplace_to_demand": 87.23288787,
    "grid_to_demand": 34779.58969,
    "govt_battery_charge": 45.53708721
  },
  {
    "timestamp": "31/12/24 4:00",
    "demand": 31712.93154,
    "solar_generated": 0,
    "wind_generated": 60.7493345,
    "marketplace_to_demand": 156.5721035,
    "grid_to_demand": 32075.44334,
    "govt_battery_charge": 44.55741722
  },
  {
    "timestamp": "31/12/24 5:00",
    "demand": 30164.55831,
    "solar_generated": 0,
    "wind_generated": 71.54111225,
    "marketplace_to_demand": 161.0553944,
    "grid_to_demand": 30057.65147,
    "govt_battery_charge": 26.9204491
  },
  {
    "timestamp": "31/12/24 6:00",
    "demand": 32775.89939,
    "solar_generated": 7.372669375,
    "wind_generated": 74.85412731,
    "marketplace_to_demand": 136.6571458,
    "grid_to_demand": 30493.44127,
    "govt_battery_charge": 80.27592111
  },
  {
    "timestamp": "31/12/24 7:00",
    "demand": 31662.76523,
    "solar_generated": 11.62053317,
    "wind_generated": 62.28150868,
    "marketplace_to_demand": 6.182964494,
    "grid_to_demand": 30577.28969,
    "govt_battery_charge": 84.64827904
  },
  {
    "timestamp": "31/12/24 8:00",
    "demand": 32715.5771,
    "solar_generated": 18.69046619,
    "wind_generated": 70.09377116,
    "marketplace_to_demand": 178.1748571,
    "grid_to_demand": 33433.00822,
    "govt_battery_charge": 99.46479147
  },
  {
    "timestamp": "31/12/24 9:00",
    "demand": 31747.08592,
    "solar_generated": 7.888350312,
    "wind_generated": 76.01224522,
    "marketplace_to_demand": 178.8345404,
    "grid_to_demand": 30365.95189,
    "govt_battery_charge": 67.91176173
  },
  {
    "timestamp": "31/12/24 10:00",
    "demand": 32862.4312,
    "solar_generated": 6.896782264,
    "wind_generated": 55.84461633,
    "marketplace_to_demand": 57.10959294,
    "grid_to_demand": 31429.35504,
    "govt_battery_charge": 23.04960911
  },
  {
    "timestamp": "31/12/24 11:00",
    "demand": 31866.50192,
    "solar_generated": 7.757068207,
    "wind_generated": 75.4858949,
    "marketplace_to_demand": 191.7245459,
    "grid_to_demand": 33467.97279,
    "govt_battery_charge": 76.04379768
  },
  {
    "timestamp": "31/12/24 12:00",
    "demand": 35060.41122,
    "solar_generated": 18.72479176,
    "wind_generated": 80.05797056,
    "marketplace_to_demand": 181.7890665,
    "grid_to_demand": 33521.04163,
    "govt_battery_charge": 13.62552748
  },
  {
    "timestamp": "31/12/24 13:00",
    "demand": 34686.81071,
    "solar_generated": 12.42136011,
    "wind_generated": 57.9295463,
    "marketplace_to_demand": 36.56709275,
    "grid_to_demand": 35541.93318,
    "govt_battery_charge": 19.08947688
  },
  {
    "timestamp": "31/12/24 14:00",
    "demand": 32972.4153,
    "solar_generated": 19.87555828,
    "wind_generated": 56.14052724,
    "marketplace_to_demand": 178.88583,
    "grid_to_demand": 34068.13274,
    "govt_battery_charge": 56.39090519
  },
  {
    "timestamp": "31/12/24 15:00",
    "demand": 36112.66901,
    "solar_generated": 15.41793467,
    "wind_generated": 71.96681533,
    "marketplace_to_demand": 57.07695333,
    "grid_to_demand": 31573.04984,
    "govt_battery_charge": 87.72497045
  },
  {
    "timestamp": "31/12/24 16:00",
    "demand": 34391.59921,
    "solar_generated": 8.151505445,
    "wind_generated": 75.2021275,
    "marketplace_to_demand": 130.1650373,
    "grid_to_demand": 32392.67954,
    "govt_battery_charge": 89.31431006
  },
  {
    "timestamp": "31/12/24 17:00",
    "demand": 34753.76621,
    "solar_generated": 21.00208851,
    "wind_generated": 58.73805441,
    "marketplace_to_demand": 55.96444193,
    "grid_to_demand": 34205.58975,
    "govt_battery_charge": 41.19473209
  },
  {
    "timestamp": "31/12/24 18:00",
    "demand": 33227.71053,
    "solar_generated": 0,
    "wind_generated": 61.00258113,
    "marketplace_to_demand": 84.42767932,
    "grid_to_demand": 33053.6127,
    "govt_battery_charge": 78.31750669
  },
  {
    "timestamp": "31/12/24 19:00",
    "demand": 35870.31568,
    "solar_generated": 0,
    "wind_generated": 77.7644608,
    "marketplace_to_demand": 98.99621236,
    "grid_to_demand": 35937.40217,
    "govt_battery_charge": 38.28469374
  },
  {
    "timestamp": "31/12/24 20:00",
    "demand": 36001.87062,
    "solar_generated": 0,
    "wind_generated": 61.41916107,
    "marketplace_to_demand": 87.96457971,
    "grid_to_demand": 33611.03109,
    "govt_battery_charge": 81.99206478
  },
  {
    "timestamp": "31/12/24 21:00",
    "demand": 35385.46284,
    "solar_generated": 0,
    "wind_generated": 61.00295425,
    "marketplace_to_demand": 0.181775004,
    "grid_to_demand": 31417.51893,
    "govt_battery_charge": 74.24083756
  },
  {
    "timestamp": "31/12/24 22:00",
    "demand": 36155.61717,
    "solar_generated": 0,
    "wind_generated": 77.18765872,
    "marketplace_to_demand": 78.14727743,
    "grid_to_demand": 35332.10154,
    "govt_battery_charge": 61.82152852
  },
  {
    "timestamp": "31/12/24 23:00",
    "demand": 33578.85749,
    "solar_generated": 0,
    "wind_generated": 70.48055113,
    "marketplace_to_demand": 133.5670299,
    "grid_to_demand": 33695.53405,
    "govt_battery_charge": 63.21397312
  },
  {
    "timestamp": "01/01/25 0:00",
    "demand": 34141.53063,
    "solar_generated": 0,
    "wind_generated": 77.81943052,
    "marketplace_to_demand": 46.73049765,
    "grid_to_demand": 36118.28855,
    "govt_battery_charge": 64.01832893
  },
  {
    "timestamp": "01/01/25 1:00",
    "demand": 31576.76408,
    "solar_generated": 0,
    "wind_generated": 67.04687241,
    "marketplace_to_demand": 147.922268,
    "grid_to_demand": 33620.55193,
    "govt_battery_charge": 21.14728968
  },
  {
    "timestamp": "01/01/25 2:00",
    "demand": 33123.929,
    "solar_generated": 0,
    "wind_generated": 57.78864443,
    "marketplace_to_demand": 22.43047768,
    "grid_to_demand": 34209.9179,
    "govt_battery_charge": 49.52225158
  },
  {
    "timestamp": "01/01/25 3:00",
    "demand": 30119.94555,
    "solar_generated": 0,
    "wind_generated": 74.03237752,
    "marketplace_to_demand": 3.665348319,
    "grid_to_demand": 31559.24104,
    "govt_battery_charge": 59.42342403
  },
  {
    "timestamp": "01/01/25 4:00",
    "demand": 36084.46376,
    "solar_generated": 0,
    "wind_generated": 62.83943362,
    "marketplace_to_demand": 141.7026375,
    "grid_to_demand": 36546.15434,
    "govt_battery_charge": 5.817927461
  },
  {
    "timestamp": "01/01/25 5:00",
    "demand": 34502.35722,
    "solar_generated": 0,
    "wind_generated": 73.3802396,
    "marketplace_to_demand": 85.92673546,
    "grid_to_demand": 33306.54113,
    "govt_battery_charge": 59.91136018
  },
  {
    "timestamp": "01/01/25 6:00",
    "demand": 32030.15372,
    "solar_generated": 18.83133513,
    "wind_generated": 68.00339778,
    "marketplace_to_demand": 178.4201264,
    "grid_to_demand": 33200.39699,
    "govt_battery_charge": 92.1767802
  },
  {
    "timestamp": "01/01/25 7:00",
    "demand": 30721.04691,
    "solar_generated": 23.99597366,
    "wind_generated": 70.06799965,
    "marketplace_to_demand": 101.8268904,
    "grid_to_demand": 35836.67335,
    "govt_battery_charge": 19.81546225
  },
  {
    "timestamp": "01/01/25 8:00",
    "demand": 30165.62407,
    "solar_generated": 9.223140661,
    "wind_generated": 59.18155126,
    "marketplace_to_demand": 9.093118962,
    "grid_to_demand": 30817.18849,
    "govt_battery_charge": 39.68503922
  },
  {
    "timestamp": "01/01/25 9:00",
    "demand": 30501.40134,
    "solar_generated": 13.85838138,
    "wind_generated": 62.01771052,
    "marketplace_to_demand": 63.29316604,
    "grid_to_demand": 32658.23848,
    "govt_battery_charge": 92.76364562
  },
  {
    "timestamp": "01/01/25 10:00",
    "demand": 33106.12934,
    "solar_generated": 13.17258542,
    "wind_generated": 74.98157389,
    "marketplace_to_demand": 41.5989592,
    "grid_to_demand": 35436.93625,
    "govt_battery_charge": 77.80591088
  },
  {
    "timestamp": "01/01/25 11:00",
    "demand": 35152.2608,
    "solar_generated": 23.77705573,
    "wind_generated": 67.57112673,
    "marketplace_to_demand": 120.3960075,
    "grid_to_demand": 32130.55195,
    "govt_battery_charge": 21.3877018
  },
  {
    "timestamp": "01/01/25 12:00",
    "demand": 35808.31429,
    "solar_generated": 14.34870358,
    "wind_generated": 55.7738503,
    "marketplace_to_demand": 143.0772932,
    "grid_to_demand": 31208.89779,
    "govt_battery_charge": 51.29243529
  },
  {
    "timestamp": "01/01/25 13:00",
    "demand": 34760.49091,
    "solar_generated": 19.10802102,
    "wind_generated": 68.98479231,
    "marketplace_to_demand": 193.3241883,
    "grid_to_demand": 34992.57284,
    "govt_battery_charge": 39.4735743
  },
  {
    "timestamp": "01/01/25 14:00",
    "demand": 33565.92539,
    "solar_generated": 7.175392631,
    "wind_generated": 65.66037833,
    "marketplace_to_demand": 141.9620054,
    "grid_to_demand": 33469.96611,
    "govt_battery_charge": 74.2913267
  },
  {
    "timestamp": "01/01/25 15:00",
    "demand": 31340.238,
    "solar_generated": 13.59821098,
    "wind_generated": 67.32968006,
    "marketplace_to_demand": 109.4494942,
    "grid_to_demand": 30937.32411,
    "govt_battery_charge": 88.13257138
  },
  {
    "timestamp": "01/01/25 16:00",
    "demand": 33395.04739,
    "solar_generated": 19.19000083,
    "wind_generated": 78.43137106,
    "marketplace_to_demand": 66.26659438,
    "grid_to_demand": 33334.0959,
    "govt_battery_charge": 64.90747776
  },
  {
    "timestamp": "01/01/25 17:00",
    "demand": 32647.05061,
    "solar_generated": 22.84846391,
    "wind_generated": 58.03107474,
    "marketplace_to_demand": 133.1931381,
    "grid_to_demand": 32285.10756,
    "govt_battery_charge": 12.29909772
  },
  {
    "timestamp": "01/01/25 18:00",
    "demand": 30617.50561,
    "solar_generated": 0,
    "wind_generated": 74.79257401,
    "marketplace_to_demand": 133.4162275,
    "grid_to_demand": 33776.49427,
    "govt_battery_charge": 70.78129389
  },
  {
    "timestamp": "01/01/25 19:00",
    "demand": 30250.95806,
    "solar_generated": 0,
    "wind_generated": 75.00092727,
    "marketplace_to_demand": 57.72132199,
    "grid_to_demand": 35608.05951,
    "govt_battery_charge": 11.72779566
  },
  {
    "timestamp": "01/01/25 20:00",
    "demand": 32675.04852,
    "solar_generated": 0,
    "wind_generated": 63.84113124,
    "marketplace_to_demand": 59.59477827,
    "grid_to_demand": 34654.3796,
    "govt_battery_charge": 43.25860853
  },
  {
    "timestamp": "01/01/25 21:00",
    "demand": 31035.89673,
    "solar_generated": 0,
    "wind_generated": 53.66793652,
    "marketplace_to_demand": 144.197471,
    "grid_to_demand": 33779.96283,
    "govt_battery_charge": 90.46892485
  },
  {
    "timestamp": "01/01/25 22:00",
    "demand": 31761.61177,
    "solar_generated": 0,
    "wind_generated": 79.6005531,
    "marketplace_to_demand": 54.49862059,
    "grid_to_demand": 32276.45397,
    "govt_battery_charge": 53.38104642
  },
  {
    "timestamp": "01/01/25 23:00",
    "demand": 34882.28454,
    "solar_generated": 0,
    "wind_generated": 56.72862166,
    "marketplace_to_demand": 191.6783817,
    "grid_to_demand": 30200.27168,
    "govt_battery_charge": 78.88497715
  },
  {
    "timestamp": "02/01/25 0:00",
    "demand": 34860.74631,
    "solar_generated": 0,
    "wind_generated": 73.67737429,
    "marketplace_to_demand": 198.8143837,
    "grid_to_demand": 35474.19661,
    "govt_battery_charge": 14.08773602
  },
  {
    "timestamp": "02/01/25 1:00",
    "demand": 30717.81183,
    "solar_generated": 0,
    "wind_generated": 65.23326775,
    "marketplace_to_demand": 13.56474351,
    "grid_to_demand": 34298.10268,
    "govt_battery_charge": 92.16887318
  },
  {
    "timestamp": "02/01/25 2:00",
    "demand": 33963.61064,
    "solar_generated": 0,
    "wind_generated": 57.95244269,
    "marketplace_to_demand": 157.2858678,
    "grid_to_demand": 30083.76282,
    "govt_battery_charge": 80.85048615
  },
  {
    "timestamp": "02/01/25 3:00",
    "demand": 30529.92077,
    "solar_generated": 0,
    "wind_generated": 54.02446482,
    "marketplace_to_demand": 196.7869647,
    "grid_to_demand": 31134.62036,
    "govt_battery_charge": 13.43027577
  },
  {
    "timestamp": "02/01/25 4:00",
    "demand": 30304.8969,
    "solar_generated": 0,
    "wind_generated": 75.85755248,
    "marketplace_to_demand": 109.9825821,
    "grid_to_demand": 33133.27195,
    "govt_battery_charge": 99.09296418
  },
  {
    "timestamp": "02/01/25 5:00",
    "demand": 33174.64751,
    "solar_generated": 0,
    "wind_generated": 72.87208186,
    "marketplace_to_demand": 101.2305206,
    "grid_to_demand": 31442.34623,
    "govt_battery_charge": 90.71932484
  },
  {
    "timestamp": "02/01/25 6:00",
    "demand": 31645.15934,
    "solar_generated": 13.0263605,
    "wind_generated": 66.84752679,
    "marketplace_to_demand": 34.64927197,
    "grid_to_demand": 33511.97507,
    "govt_battery_charge": 18.64425248
  },
  {
    "timestamp": "02/01/25 7:00",
    "demand": 32387.13848,
    "solar_generated": 14.32010964,
    "wind_generated": 57.44115092,
    "marketplace_to_demand": 22.87620892,
    "grid_to_demand": 30315.55479,
    "govt_battery_charge": 52.63323847
  },
  {
    "timestamp": "02/01/25 8:00",
    "demand": 35132.59449,
    "solar_generated": 23.37097694,
    "wind_generated": 78.57584589,
    "marketplace_to_demand": 42.86959759,
    "grid_to_demand": 32348.99685,
    "govt_battery_charge": 3.019560834
  },
  {
    "timestamp": "02/01/25 9:00",
    "demand": 31129.90404,
    "solar_generated": 9.097200774,
    "wind_generated": 70.88284604,
    "marketplace_to_demand": 2.285694448,
    "grid_to_demand": 32521.6167,
    "govt_battery_charge": 72.13640618
  },
  {
    "timestamp": "02/01/25 10:00",
    "demand": 33225.195,
    "solar_generated": 20.4708059,
    "wind_generated": 67.537409,
    "marketplace_to_demand": 48.38564821,
    "grid_to_demand": 33834.3512,
    "govt_battery_charge": 72.14112502
  },
  {
    "timestamp": "02/01/25 11:00",
    "demand": 31298.99835,
    "solar_generated": 23.05882907,
    "wind_generated": 65.4893622,
    "marketplace_to_demand": 81.06479346,
    "grid_to_demand": 32125.0649,
    "govt_battery_charge": 41.54039498
  },
  {
    "timestamp": "02/01/25 12:00",
    "demand": 35181.93321,
    "solar_generated": 9.14497098,
    "wind_generated": 75.7887428,
    "marketplace_to_demand": 35.12931116,
    "grid_to_demand": 30504.47704,
    "govt_battery_charge": 74.4430774
  },
  {
    "timestamp": "02/01/25 13:00",
    "demand": 30582.35834,
    "solar_generated": 6.230966659,
    "wind_generated": 78.59063247,
    "marketplace_to_demand": 13.44880024,
    "grid_to_demand": 29996.47263,
    "govt_battery_charge": 68.90486074
  },
  {
    "timestamp": "02/01/25 14:00",
    "demand": 34828.11546,
    "solar_generated": 14.99089523,
    "wind_generated": 68.94447512,
    "marketplace_to_demand": 195.2052213,
    "grid_to_demand": 32823.48343,
    "govt_battery_charge": 18.01759435
  },
  {
    "timestamp": "02/01/25 15:00",
    "demand": 32429.69709,
    "solar_generated": 16.52105588,
    "wind_generated": 65.23736717,
    "marketplace_to_demand": 198.4395859,
    "grid_to_demand": 32584.6357,
    "govt_battery_charge": 70.01724713
  },
  {
    "timestamp": "02/01/25 16:00",
    "demand": 36487.80907,
    "solar_generated": 20.07592155,
    "wind_generated": 72.93789549,
    "marketplace_to_demand": 106.6840045,
    "grid_to_demand": 31029.74113,
    "govt_battery_charge": 70.45984032
  },
  {
    "timestamp": "02/01/25 17:00",
    "demand": 34546.95412,
    "solar_generated": 18.54262803,
    "wind_generated": 76.52656162,
    "marketplace_to_demand": 25.88130569,
    "grid_to_demand": 30341.52077,
    "govt_battery_charge": 64.73695741
  },
  {
    "timestamp": "02/01/25 18:00",
    "demand": 33038.75257,
    "solar_generated": 0,
    "wind_generated": 61.16153642,
    "marketplace_to_demand": 23.07839034,
    "grid_to_demand": 31980.94027,
    "govt_battery_charge": 63.97193319
  },
  {
    "timestamp": "02/01/25 19:00",
    "demand": 33713.2255,
    "solar_generated": 0,
    "wind_generated": 76.88834899,
    "marketplace_to_demand": 124.3640941,
    "grid_to_demand": 33518.19832,
    "govt_battery_charge": 47.03578998
  },
  {
    "timestamp": "02/01/25 20:00",
    "demand": 35483.24456,
    "solar_generated": 0,
    "wind_generated": 74.31865234,
    "marketplace_to_demand": 70.14242167,
    "grid_to_demand": 33831.67397,
    "govt_battery_charge": 4.741352219
  },
  {
    "timestamp": "02/01/25 21:00",
    "demand": 36293.68969,
    "solar_generated": 0,
    "wind_generated": 69.1478145,
    "marketplace_to_demand": 144.9279266,
    "grid_to_demand": 30958.14302,
    "govt_battery_charge": 2.03148066
  },
  {
    "timestamp": "02/01/25 22:00",
    "demand": 30578.79058,
    "solar_generated": 0,
    "wind_generated": 64.71393637,
    "marketplace_to_demand": 55.15992188,
    "grid_to_demand": 30726.47916,
    "govt_battery_charge": 7.005811635
  },
  {
    "timestamp": "02/01/25 23:00",
    "demand": 30474.71942,
    "solar_generated": 0,
    "wind_generated": 57.54112169,
    "marketplace_to_demand": 16.35514683,
    "grid_to_demand": 33804.07083,
    "govt_battery_charge": 66.78124791
  },
  {
    "timestamp": "03/01/25 0:00",
    "demand": 36218.98485,
    "solar_generated": 0,
    "wind_generated": 72.43707095,
    "marketplace_to_demand": 40.73576534,
    "grid_to_demand": 35169.23073,
    "govt_battery_charge": 85.3722702
  },
  {
    "timestamp": "03/01/25 1:00",
    "demand": 33798.94748,
    "solar_generated": 0,
    "wind_generated": 62.51746489,
    "marketplace_to_demand": 67.25216176,
    "grid_to_demand": 32157.92763,
    "govt_battery_charge": 57.43917625
  },
  {
    "timestamp": "03/01/25 2:00",
    "demand": 35008.5578,
    "solar_generated": 0,
    "wind_generated": 58.24952572,
    "marketplace_to_demand": 43.37612132,
    "grid_to_demand": 30967.87132,
    "govt_battery_charge": 56.63261548
  },
  {
    "timestamp": "03/01/25 3:00",
    "demand": 31246.71856,
    "solar_generated": 0,
    "wind_generated": 53.71806497,
    "marketplace_to_demand": 54.39323658,
    "grid_to_demand": 33105.69537,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "03/01/25 4:00",
    "demand": 33467.64684,
    "solar_generated": 0,
    "wind_generated": 65.21377699,
    "marketplace_to_demand": 128.5880928,
    "grid_to_demand": 33560.58761,
    "govt_battery_charge": 51.24453833
  },
  {
    "timestamp": "03/01/25 5:00",
    "demand": 32887.51728,
    "solar_generated": 0,
    "wind_generated": 76.87579006,
    "marketplace_to_demand": 35.67745212,
    "grid_to_demand": 32133.45172,
    "govt_battery_charge": 36.91268716
  },
  {
    "timestamp": "03/01/25 6:00",
    "demand": 31292.65289,
    "solar_generated": 6.086972766,
    "wind_generated": 66.88785902,
    "marketplace_to_demand": 89.05889281,
    "grid_to_demand": 30125.58291,
    "govt_battery_charge": 69.79768192
  },
  {
    "timestamp": "03/01/25 7:00",
    "demand": 33653.51702,
    "solar_generated": 18.8514166,
    "wind_generated": 75.85326704,
    "marketplace_to_demand": 149.1015764,
    "grid_to_demand": 31165.92373,
    "govt_battery_charge": 23.40324646
  },
  {
    "timestamp": "03/01/25 8:00",
    "demand": 35559.57589,
    "solar_generated": 18.91673437,
    "wind_generated": 56.92803738,
    "marketplace_to_demand": 169.3349667,
    "grid_to_demand": 35395.88882,
    "govt_battery_charge": 62.07219587
  },
  {
    "timestamp": "03/01/25 9:00",
    "demand": 32435.67922,
    "solar_generated": 13.02082228,
    "wind_generated": 59.71672177,
    "marketplace_to_demand": 132.109612,
    "grid_to_demand": 34672.32412,
    "govt_battery_charge": 36.44000345
  },
  {
    "timestamp": "03/01/25 10:00",
    "demand": 32553.55715,
    "solar_generated": 17.27755021,
    "wind_generated": 67.44425805,
    "marketplace_to_demand": 132.2820569,
    "grid_to_demand": 30174.42442,
    "govt_battery_charge": 12.08271157
  },
  {
    "timestamp": "03/01/25 11:00",
    "demand": 30129.30409,
    "solar_generated": 23.79371886,
    "wind_generated": 65.58775759,
    "marketplace_to_demand": 64.19714711,
    "grid_to_demand": 33697.31224,
    "govt_battery_charge": 98.59961056
  },
  {
    "timestamp": "03/01/25 12:00",
    "demand": 30533.45039,
    "solar_generated": 16.44365774,
    "wind_generated": 60.62891755,
    "marketplace_to_demand": 59.65974285,
    "grid_to_demand": 33550.92618,
    "govt_battery_charge": 29.63221072
  },
  {
    "timestamp": "03/01/25 13:00",
    "demand": 32936.28571,
    "solar_generated": 8.855017427,
    "wind_generated": 62.60216013,
    "marketplace_to_demand": 107.0204058,
    "grid_to_demand": 34751.83302,
    "govt_battery_charge": 78.72487246
  },
  {
    "timestamp": "03/01/25 14:00",
    "demand": 35644.19216,
    "solar_generated": 8.178316611,
    "wind_generated": 66.46120252,
    "marketplace_to_demand": 124.3823167,
    "grid_to_demand": 35332.00004,
    "govt_battery_charge": 76.47757888
  },
  {
    "timestamp": "03/01/25 15:00",
    "demand": 32184.7323,
    "solar_generated": 12.53713603,
    "wind_generated": 54.88268679,
    "marketplace_to_demand": 46.75403456,
    "grid_to_demand": 30243.22627,
    "govt_battery_charge": 98.16858265
  },
  {
    "timestamp": "03/01/25 16:00",
    "demand": 34153.33941,
    "solar_generated": 18.48950294,
    "wind_generated": 65.0470179,
    "marketplace_to_demand": 130.8260857,
    "grid_to_demand": 33234.60847,
    "govt_battery_charge": 66.80721684
  },
  {
    "timestamp": "03/01/25 17:00",
    "demand": 34299.25501,
    "solar_generated": 6.190044096,
    "wind_generated": 59.68146155,
    "marketplace_to_demand": 53.19195766,
    "grid_to_demand": 31690.84709,
    "govt_battery_charge": 76.3810975
  },
  {
    "timestamp": "03/01/25 18:00",
    "demand": 34683.44203,
    "solar_generated": 0,
    "wind_generated": 80.39510203,
    "marketplace_to_demand": 103.8341222,
    "grid_to_demand": 31451.38951,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "03/01/25 19:00",
    "demand": 30685.57957,
    "solar_generated": 0,
    "wind_generated": 60.05268614,
    "marketplace_to_demand": 24.49286429,
    "grid_to_demand": 30743.51559,
    "govt_battery_charge": 17.19637644
  },
  {
    "timestamp": "03/01/25 20:00",
    "demand": 32967.04851,
    "solar_generated": 0,
    "wind_generated": 66.18160549,
    "marketplace_to_demand": 8.309439746,
    "grid_to_demand": 32637.02755,
    "govt_battery_charge": 9.255192209
  },
  {
    "timestamp": "03/01/25 21:00",
    "demand": 34553.27178,
    "solar_generated": 0,
    "wind_generated": 80.27667933,
    "marketplace_to_demand": 92.15211252,
    "grid_to_demand": 32862.34938,
    "govt_battery_charge": 81.49837256
  },
  {
    "timestamp": "03/01/25 22:00",
    "demand": 30288.13105,
    "solar_generated": 0,
    "wind_generated": 79.51564037,
    "marketplace_to_demand": 7.627015074,
    "grid_to_demand": 34172.05727,
    "govt_battery_charge": 50.99681981
  },
  {
    "timestamp": "03/01/25 23:00",
    "demand": 30725.05784,
    "solar_generated": 0,
    "wind_generated": 76.56205667,
    "marketplace_to_demand": 124.3173136,
    "grid_to_demand": 35392.11574,
    "govt_battery_charge": 98.58518015
  },
  {
    "timestamp": "04/01/25 0:00",
    "demand": 36569.59973,
    "solar_generated": 0,
    "wind_generated": 59.66419515,
    "marketplace_to_demand": 126.1119391,
    "grid_to_demand": 30726.34687,
    "govt_battery_charge": 47.68523051
  },
  {
    "timestamp": "04/01/25 1:00",
    "demand": 32270.58052,
    "solar_generated": 0,
    "wind_generated": 66.39873132,
    "marketplace_to_demand": 65.16303882,
    "grid_to_demand": 32478.41209,
    "govt_battery_charge": 1.864394398
  },
  {
    "timestamp": "04/01/25 2:00",
    "demand": 31658.37517,
    "solar_generated": 0,
    "wind_generated": 62.0468185,
    "marketplace_to_demand": 86.8508879,
    "grid_to_demand": 32009.34447,
    "govt_battery_charge": 85.71071699
  },
  {
    "timestamp": "04/01/25 3:00",
    "demand": 30555.15115,
    "solar_generated": 0,
    "wind_generated": 68.49193277,
    "marketplace_to_demand": 10.28473451,
    "grid_to_demand": 30364.35374,
    "govt_battery_charge": 71.31694535
  },
  {
    "timestamp": "04/01/25 4:00",
    "demand": 34529.5227,
    "solar_generated": 0,
    "wind_generated": 55.17716965,
    "marketplace_to_demand": 129.9073507,
    "grid_to_demand": 35736.25157,
    "govt_battery_charge": 90.7686177
  },
  {
    "timestamp": "04/01/25 5:00",
    "demand": 31679.15209,
    "solar_generated": 0,
    "wind_generated": 76.20158499,
    "marketplace_to_demand": 174.8619397,
    "grid_to_demand": 35056.07177,
    "govt_battery_charge": 89.07413757
  },
  {
    "timestamp": "04/01/25 6:00",
    "demand": 34872.44999,
    "solar_generated": 18.19148478,
    "wind_generated": 68.00542591,
    "marketplace_to_demand": 183.0494456,
    "grid_to_demand": 30516.86061,
    "govt_battery_charge": 65.68879094
  },
  {
    "timestamp": "04/01/25 7:00",
    "demand": 30973.17509,
    "solar_generated": 20.41972401,
    "wind_generated": 65.98375649,
    "marketplace_to_demand": 89.65941714,
    "grid_to_demand": 34268.10935,
    "govt_battery_charge": 61.06703497
  },
  {
    "timestamp": "04/01/25 8:00",
    "demand": 34716.42336,
    "solar_generated": 16.29553256,
    "wind_generated": 69.5741783,
    "marketplace_to_demand": 171.1423329,
    "grid_to_demand": 30578.30486,
    "govt_battery_charge": 16.50638498
  },
  {
    "timestamp": "04/01/25 9:00",
    "demand": 34738.32379,
    "solar_generated": 9.266547219,
    "wind_generated": 72.79450353,
    "marketplace_to_demand": 86.01338019,
    "grid_to_demand": 35517.55398,
    "govt_battery_charge": 72.97932722
  },
  {
    "timestamp": "04/01/25 10:00",
    "demand": 32648.22559,
    "solar_generated": 20.91213679,
    "wind_generated": 55.55959989,
    "marketplace_to_demand": 178.6380257,
    "grid_to_demand": 32581.71085,
    "govt_battery_charge": 27.2195522
  },
  {
    "timestamp": "04/01/25 11:00",
    "demand": 30682.08119,
    "solar_generated": 16.35682953,
    "wind_generated": 56.87663977,
    "marketplace_to_demand": 109.183662,
    "grid_to_demand": 36135.00409,
    "govt_battery_charge": 24.13482832
  },
  {
    "timestamp": "04/01/25 12:00",
    "demand": 31616.66537,
    "solar_generated": 20.57906409,
    "wind_generated": 64.33117856,
    "marketplace_to_demand": 89.02834671,
    "grid_to_demand": 36349.95242,
    "govt_battery_charge": 25.19274247
  },
  {
    "timestamp": "04/01/25 13:00",
    "demand": 33319.1734,
    "solar_generated": 6.321760527,
    "wind_generated": 70.98367099,
    "marketplace_to_demand": 9.548118178,
    "grid_to_demand": 32536.9948,
    "govt_battery_charge": 9.371532785
  },
  {
    "timestamp": "04/01/25 14:00",
    "demand": 34655.27034,
    "solar_generated": 8.918059361,
    "wind_generated": 76.65619371,
    "marketplace_to_demand": 56.68261965,
    "grid_to_demand": 32878.25356,
    "govt_battery_charge": 73.77688881
  },
  {
    "timestamp": "04/01/25 15:00",
    "demand": 33853.38058,
    "solar_generated": 23.13393589,
    "wind_generated": 80.03871096,
    "marketplace_to_demand": 55.81062605,
    "grid_to_demand": 31066.17027,
    "govt_battery_charge": 36.47208705
  },
  {
    "timestamp": "04/01/25 16:00",
    "demand": 31574.00995,
    "solar_generated": 19.86088211,
    "wind_generated": 74.6170368,
    "marketplace_to_demand": 14.71856217,
    "grid_to_demand": 34630.96447,
    "govt_battery_charge": 54.05217418
  },
  {
    "timestamp": "04/01/25 17:00",
    "demand": 31546.67738,
    "solar_generated": 6.776972532,
    "wind_generated": 72.19339492,
    "marketplace_to_demand": 2.824696972,
    "grid_to_demand": 31804.08011,
    "govt_battery_charge": 88.67992276
  },
  {
    "timestamp": "04/01/25 18:00",
    "demand": 31001.25691,
    "solar_generated": 0,
    "wind_generated": 59.14718276,
    "marketplace_to_demand": 33.36982332,
    "grid_to_demand": 34576.51368,
    "govt_battery_charge": 49.31409463
  },
  {
    "timestamp": "04/01/25 19:00",
    "demand": 32647.79375,
    "solar_generated": 0,
    "wind_generated": 66.19283408,
    "marketplace_to_demand": 2.575764669,
    "grid_to_demand": 33398.56719,
    "govt_battery_charge": 42.2248184
  },
  {
    "timestamp": "04/01/25 20:00",
    "demand": 35160.77058,
    "solar_generated": 0,
    "wind_generated": 75.6664042,
    "marketplace_to_demand": 44.21485109,
    "grid_to_demand": 30417.84315,
    "govt_battery_charge": 53.59973686
  },
  {
    "timestamp": "04/01/25 21:00",
    "demand": 30692.54216,
    "solar_generated": 0,
    "wind_generated": 78.92384532,
    "marketplace_to_demand": 75.00231274,
    "grid_to_demand": 34125.56749,
    "govt_battery_charge": 56.13759197
  },
  {
    "timestamp": "04/01/25 22:00",
    "demand": 33958.77013,
    "solar_generated": 0,
    "wind_generated": 71.47245913,
    "marketplace_to_demand": 30.58025946,
    "grid_to_demand": 34039.0832,
    "govt_battery_charge": 82.6795937
  },
  {
    "timestamp": "04/01/25 23:00",
    "demand": 32246.13231,
    "solar_generated": 0,
    "wind_generated": 58.16490559,
    "marketplace_to_demand": 47.22789999,
    "grid_to_demand": 31553.14016,
    "govt_battery_charge": 46.50945099
  },
  {
    "timestamp": "05/01/25 0:00",
    "demand": 31208.07699,
    "solar_generated": 0,
    "wind_generated": 65.10592281,
    "marketplace_to_demand": 32.70546833,
    "grid_to_demand": 32185.90166,
    "govt_battery_charge": 28.51577366
  },
  {
    "timestamp": "05/01/25 1:00",
    "demand": 30900.48173,
    "solar_generated": 0,
    "wind_generated": 61.47053788,
    "marketplace_to_demand": 31.81820955,
    "grid_to_demand": 31395.32416,
    "govt_battery_charge": 97.98706243
  },
  {
    "timestamp": "05/01/25 2:00",
    "demand": 31369.41073,
    "solar_generated": 0,
    "wind_generated": 71.53305762,
    "marketplace_to_demand": 87.44494168,
    "grid_to_demand": 32581.77559,
    "govt_battery_charge": 91.08978188
  },
  {
    "timestamp": "05/01/25 3:00",
    "demand": 35184.83695,
    "solar_generated": 0,
    "wind_generated": 66.15790443,
    "marketplace_to_demand": 128.0493597,
    "grid_to_demand": 36141.66744,
    "govt_battery_charge": 7.048112939
  },
  {
    "timestamp": "05/01/25 4:00",
    "demand": 30638.24947,
    "solar_generated": 0,
    "wind_generated": 74.70659924,
    "marketplace_to_demand": 92.57791766,
    "grid_to_demand": 31224.84681,
    "govt_battery_charge": 59.70813758
  },
  {
    "timestamp": "05/01/25 5:00",
    "demand": 30448.41859,
    "solar_generated": 0,
    "wind_generated": 67.44693931,
    "marketplace_to_demand": 155.8570772,
    "grid_to_demand": 32489.93327,
    "govt_battery_charge": 66.23846468
  },
  {
    "timestamp": "05/01/25 6:00",
    "demand": 30076.90092,
    "solar_generated": 17.6605917,
    "wind_generated": 57.37804901,
    "marketplace_to_demand": 172.3994115,
    "grid_to_demand": 33926.04357,
    "govt_battery_charge": 83.65049281
  },
  {
    "timestamp": "05/01/25 7:00",
    "demand": 31691.23584,
    "solar_generated": 22.02301177,
    "wind_generated": 61.64625591,
    "marketplace_to_demand": 56.58071604,
    "grid_to_demand": 30885.17567,
    "govt_battery_charge": 47.72682683
  },
  {
    "timestamp": "05/01/25 8:00",
    "demand": 35431.36462,
    "solar_generated": 8.736916258,
    "wind_generated": 65.25746014,
    "marketplace_to_demand": 175.605114,
    "grid_to_demand": 36217.18066,
    "govt_battery_charge": 89.88850829
  },
  {
    "timestamp": "05/01/25 9:00",
    "demand": 35723.03961,
    "solar_generated": 6.418963043,
    "wind_generated": 63.60814588,
    "marketplace_to_demand": 187.364251,
    "grid_to_demand": 36315.61281,
    "govt_battery_charge": 81.85699499
  },
  {
    "timestamp": "05/01/25 10:00",
    "demand": 33688.86582,
    "solar_generated": 20.13460302,
    "wind_generated": 72.92862429,
    "marketplace_to_demand": 36.28020513,
    "grid_to_demand": 32090.57089,
    "govt_battery_charge": 48.69224679
  },
  {
    "timestamp": "05/01/25 11:00",
    "demand": 35409.20612,
    "solar_generated": 7.557084069,
    "wind_generated": 70.38434704,
    "marketplace_to_demand": 173.162039,
    "grid_to_demand": 30294.06741,
    "govt_battery_charge": 51.86070435
  },
  {
    "timestamp": "05/01/25 12:00",
    "demand": 30278.26542,
    "solar_generated": 7.028897156,
    "wind_generated": 79.32336374,
    "marketplace_to_demand": 69.20597094,
    "grid_to_demand": 31608.33013,
    "govt_battery_charge": 1.275741007
  },
  {
    "timestamp": "05/01/25 13:00",
    "demand": 33583.70358,
    "solar_generated": 17.12929898,
    "wind_generated": 79.31465026,
    "marketplace_to_demand": 173.2052388,
    "grid_to_demand": 31804.71458,
    "govt_battery_charge": 48.39179844
  },
  {
    "timestamp": "05/01/25 14:00",
    "demand": 35708.50155,
    "solar_generated": 7.323868446,
    "wind_generated": 71.76436557,
    "marketplace_to_demand": 33.60308171,
    "grid_to_demand": 36479.35104,
    "govt_battery_charge": 57.45344225
  },
  {
    "timestamp": "05/01/25 15:00",
    "demand": 33752.80128,
    "solar_generated": 13.22509,
    "wind_generated": 74.0831363,
    "marketplace_to_demand": 196.6208984,
    "grid_to_demand": 32817.15824,
    "govt_battery_charge": 63.2878974
  },
  {
    "timestamp": "05/01/25 16:00",
    "demand": 34051.0886,
    "solar_generated": 13.28316468,
    "wind_generated": 56.77451834,
    "marketplace_to_demand": 179.9040526,
    "grid_to_demand": 33234.09008,
    "govt_battery_charge": 36.89963851
  },
  {
    "timestamp": "05/01/25 17:00",
    "demand": 30834.4164,
    "solar_generated": 19.6008244,
    "wind_generated": 58.65270043,
    "marketplace_to_demand": 19.4599247,
    "grid_to_demand": 31161.70447,
    "govt_battery_charge": 96.25442203
  },
  {
    "timestamp": "05/01/25 18:00",
    "demand": 32997.16942,
    "solar_generated": 0,
    "wind_generated": 64.5532671,
    "marketplace_to_demand": 47.63001823,
    "grid_to_demand": 34991.61384,
    "govt_battery_charge": 80.36377878
  },
  {
    "timestamp": "05/01/25 19:00",
    "demand": 33666.43032,
    "solar_generated": 0,
    "wind_generated": 67.06900763,
    "marketplace_to_demand": 6.073268564,
    "grid_to_demand": 36337.91422,
    "govt_battery_charge": 10.86781642
  },
  {
    "timestamp": "05/01/25 20:00",
    "demand": 30975.05792,
    "solar_generated": 0,
    "wind_generated": 66.77218244,
    "marketplace_to_demand": 127.9297186,
    "grid_to_demand": 36214.66625,
    "govt_battery_charge": 99.66403741
  },
  {
    "timestamp": "05/01/25 21:00",
    "demand": 32683.74254,
    "solar_generated": 0,
    "wind_generated": 58.64817154,
    "marketplace_to_demand": 188.4705128,
    "grid_to_demand": 32256.86521,
    "govt_battery_charge": 37.31167327
  },
  {
    "timestamp": "05/01/25 22:00",
    "demand": 31014.2473,
    "solar_generated": 0,
    "wind_generated": 69.71085629,
    "marketplace_to_demand": 34.24083336,
    "grid_to_demand": 33079.14123,
    "govt_battery_charge": 44.4364944
  },
  {
    "timestamp": "05/01/25 23:00",
    "demand": 35369.17692,
    "solar_generated": 0,
    "wind_generated": 70.39461201,
    "marketplace_to_demand": 151.5948029,
    "grid_to_demand": 33633.06023,
    "govt_battery_charge": 63.12714303
  },
  {
    "timestamp": "06/01/25 0:00",
    "demand": 35651.24132,
    "solar_generated": 0,
    "wind_generated": 68.71416052,
    "marketplace_to_demand": 179.1943027,
    "grid_to_demand": 32536.23346,
    "govt_battery_charge": 97.33710949
  },
  {
    "timestamp": "06/01/25 1:00",
    "demand": 36110.24726,
    "solar_generated": 0,
    "wind_generated": 61.91835264,
    "marketplace_to_demand": 45.63396528,
    "grid_to_demand": 35673.48034,
    "govt_battery_charge": 56.44704228
  },
  {
    "timestamp": "06/01/25 2:00",
    "demand": 31235.58511,
    "solar_generated": 0,
    "wind_generated": 56.38144935,
    "marketplace_to_demand": 180.4671194,
    "grid_to_demand": 35237.8366,
    "govt_battery_charge": 90.70645973
  },
  {
    "timestamp": "06/01/25 3:00",
    "demand": 31622.02332,
    "solar_generated": 0,
    "wind_generated": 69.25140422,
    "marketplace_to_demand": 19.95544574,
    "grid_to_demand": 33924.662,
    "govt_battery_charge": 82.52323039
  },
  {
    "timestamp": "06/01/25 4:00",
    "demand": 35427.26842,
    "solar_generated": 0,
    "wind_generated": 65.84370928,
    "marketplace_to_demand": 63.19301789,
    "grid_to_demand": 35867.39478,
    "govt_battery_charge": 68.23661257
  },
  {
    "timestamp": "06/01/25 5:00",
    "demand": 33972.26418,
    "solar_generated": 0,
    "wind_generated": 63.47481321,
    "marketplace_to_demand": 0.668981567,
    "grid_to_demand": 35819.94054,
    "govt_battery_charge": 64.19559014
  },
  {
    "timestamp": "06/01/25 6:00",
    "demand": 30451.5363,
    "solar_generated": 6.14919231,
    "wind_generated": 71.7697075,
    "marketplace_to_demand": 193.5451852,
    "grid_to_demand": 32258.17852,
    "govt_battery_charge": 3.636099397
  },
  {
    "timestamp": "06/01/25 7:00",
    "demand": 30801.97822,
    "solar_generated": 17.08023447,
    "wind_generated": 59.74443763,
    "marketplace_to_demand": 76.51564417,
    "grid_to_demand": 32698.91985,
    "govt_battery_charge": 52.77805092
  },
  {
    "timestamp": "06/01/25 8:00",
    "demand": 31919.03058,
    "solar_generated": 22.91119842,
    "wind_generated": 57.24530088,
    "marketplace_to_demand": 71.3747723,
    "grid_to_demand": 30360.83173,
    "govt_battery_charge": 9.287216989
  },
  {
    "timestamp": "06/01/25 9:00",
    "demand": 33036.5068,
    "solar_generated": 19.36738909,
    "wind_generated": 68.87770774,
    "marketplace_to_demand": 187.1485943,
    "grid_to_demand": 31989.68274,
    "govt_battery_charge": 50.71478286
  },
  {
    "timestamp": "06/01/25 10:00",
    "demand": 31119.16073,
    "solar_generated": 23.54958672,
    "wind_generated": 62.31655503,
    "marketplace_to_demand": 32.92432532,
    "grid_to_demand": 30764.48028,
    "govt_battery_charge": 42.53327656
  },
  {
    "timestamp": "06/01/25 11:00",
    "demand": 31963.57584,
    "solar_generated": 11.94626792,
    "wind_generated": 68.15949511,
    "marketplace_to_demand": 131.3034158,
    "grid_to_demand": 34105.36659,
    "govt_battery_charge": 32.89797196
  },
  {
    "timestamp": "06/01/25 12:00",
    "demand": 35983.49832,
    "solar_generated": 14.09303474,
    "wind_generated": 71.39494466,
    "marketplace_to_demand": 65.29901573,
    "grid_to_demand": 31477.89373,
    "govt_battery_charge": 49.32143272
  },
  {
    "timestamp": "06/01/25 13:00",
    "demand": 36310.34849,
    "solar_generated": 13.60719053,
    "wind_generated": 73.07748122,
    "marketplace_to_demand": 96.59018939,
    "grid_to_demand": 36259.78831,
    "govt_battery_charge": 9.790414657
  },
  {
    "timestamp": "06/01/25 14:00",
    "demand": 33190.58751,
    "solar_generated": 8.865110881,
    "wind_generated": 79.96874146,
    "marketplace_to_demand": 6.291053613,
    "grid_to_demand": 30126.98225,
    "govt_battery_charge": 47.48622986
  },
  {
    "timestamp": "06/01/25 15:00",
    "demand": 33500.82958,
    "solar_generated": 9.581827211,
    "wind_generated": 70.83790573,
    "marketplace_to_demand": 10.70519607,
    "grid_to_demand": 35999.8536,
    "govt_battery_charge": 86.25701126
  },
  {
    "timestamp": "06/01/25 16:00",
    "demand": 30381.14229,
    "solar_generated": 9.83978682,
    "wind_generated": 54.74116505,
    "marketplace_to_demand": 5.80811893,
    "grid_to_demand": 35573.34751,
    "govt_battery_charge": 18.28954757
  },
  {
    "timestamp": "06/01/25 17:00",
    "demand": 34653.64718,
    "solar_generated": 8.505757529,
    "wind_generated": 79.39505753,
    "marketplace_to_demand": 28.00929572,
    "grid_to_demand": 36464.86864,
    "govt_battery_charge": 66.33661214
  },
  {
    "timestamp": "06/01/25 18:00",
    "demand": 33935.15338,
    "solar_generated": 0,
    "wind_generated": 55.53846027,
    "marketplace_to_demand": 2.766279886,
    "grid_to_demand": 36317.35374,
    "govt_battery_charge": 33.96399361
  },
  {
    "timestamp": "06/01/25 19:00",
    "demand": 31545.37366,
    "solar_generated": 0,
    "wind_generated": 64.33050458,
    "marketplace_to_demand": 165.7284217,
    "grid_to_demand": 32813.71538,
    "govt_battery_charge": 94.3689912
  },
  {
    "timestamp": "06/01/25 20:00",
    "demand": 34455.83035,
    "solar_generated": 0,
    "wind_generated": 61.12718852,
    "marketplace_to_demand": 49.80593061,
    "grid_to_demand": 33274.85507,
    "govt_battery_charge": 16.42828434
  },
  {
    "timestamp": "06/01/25 21:00",
    "demand": 30181.29067,
    "solar_generated": 0,
    "wind_generated": 68.72547924,
    "marketplace_to_demand": 63.68073143,
    "grid_to_demand": 34650.11514,
    "govt_battery_charge": 6.967438635
  },
  {
    "timestamp": "06/01/25 22:00",
    "demand": 36054.41456,
    "solar_generated": 0,
    "wind_generated": 73.34208306,
    "marketplace_to_demand": 110.4592155,
    "grid_to_demand": 31072.45751,
    "govt_battery_charge": 35.0572798
  },
  {
    "timestamp": "06/01/25 23:00",
    "demand": 35048.53433,
    "solar_generated": 0,
    "wind_generated": 58.98069231,
    "marketplace_to_demand": 58.74906083,
    "grid_to_demand": 33005.69102,
    "govt_battery_charge": 78.63186021
  },
  {
    "timestamp": "07/01/25 0:00",
    "demand": 32585.31906,
    "solar_generated": 0,
    "wind_generated": 68.60110438,
    "marketplace_to_demand": 6.101604956,
    "grid_to_demand": 32263.47304,
    "govt_battery_charge": 5.298511025
  },
  {
    "timestamp": "07/01/25 1:00",
    "demand": 30700.69142,
    "solar_generated": 0,
    "wind_generated": 67.70602804,
    "marketplace_to_demand": 146.7971675,
    "grid_to_demand": 33781.89338,
    "govt_battery_charge": 48.5086967
  },
  {
    "timestamp": "07/01/25 2:00",
    "demand": 30561.59044,
    "solar_generated": 0,
    "wind_generated": 76.91627101,
    "marketplace_to_demand": 122.9685069,
    "grid_to_demand": 35320.05583,
    "govt_battery_charge": 83.13556479
  },
  {
    "timestamp": "07/01/25 3:00",
    "demand": 31589.00609,
    "solar_generated": 0,
    "wind_generated": 64.42677295,
    "marketplace_to_demand": 0.322106262,
    "grid_to_demand": 32372.90112,
    "govt_battery_charge": 41.24535661
  },
  {
    "timestamp": "07/01/25 4:00",
    "demand": 34586.87882,
    "solar_generated": 0,
    "wind_generated": 66.39068172,
    "marketplace_to_demand": 134.4133232,
    "grid_to_demand": 33110.98885,
    "govt_battery_charge": 33.34297584
  },
  {
    "timestamp": "07/01/25 5:00",
    "demand": 31168.78353,
    "solar_generated": 0,
    "wind_generated": 79.10021473,
    "marketplace_to_demand": 192.7847941,
    "grid_to_demand": 32285.44795,
    "govt_battery_charge": 50.11691
  },
  {
    "timestamp": "07/01/25 6:00",
    "demand": 29998.57943,
    "solar_generated": 23.82821923,
    "wind_generated": 67.98226404,
    "marketplace_to_demand": 168.177288,
    "grid_to_demand": 31744.37497,
    "govt_battery_charge": 14.5525906
  },
  {
    "timestamp": "07/01/25 7:00",
    "demand": 31727.89667,
    "solar_generated": 21.91627298,
    "wind_generated": 69.87714562,
    "marketplace_to_demand": 136.9979667,
    "grid_to_demand": 34718.53927,
    "govt_battery_charge": 95.71315326
  },
  {
    "timestamp": "07/01/25 8:00",
    "demand": 32915.87178,
    "solar_generated": 16.25062253,
    "wind_generated": 70.0941509,
    "marketplace_to_demand": 11.12126474,
    "grid_to_demand": 30079.98983,
    "govt_battery_charge": 14.87309092
  },
  {
    "timestamp": "07/01/25 9:00",
    "demand": 32848.88304,
    "solar_generated": 15.37902615,
    "wind_generated": 55.02093263,
    "marketplace_to_demand": 194.9539401,
    "grid_to_demand": 34582.26219,
    "govt_battery_charge": 92.33013413
  },
  {
    "timestamp": "07/01/25 10:00",
    "demand": 30733.075,
    "solar_generated": 18.30069948,
    "wind_generated": 71.01668447,
    "marketplace_to_demand": 196.3803092,
    "grid_to_demand": 30368.44351,
    "govt_battery_charge": 33.20743578
  },
  {
    "timestamp": "07/01/25 11:00",
    "demand": 36150.30125,
    "solar_generated": 11.70479232,
    "wind_generated": 73.40056172,
    "marketplace_to_demand": 173.8805856,
    "grid_to_demand": 31358.71792,
    "govt_battery_charge": 47.54782264
  },
  {
    "timestamp": "07/01/25 12:00",
    "demand": 35984.46188,
    "solar_generated": 13.26380397,
    "wind_generated": 76.22281709,
    "marketplace_to_demand": 134.5817705,
    "grid_to_demand": 35138.06906,
    "govt_battery_charge": 91.69516082
  },
  {
    "timestamp": "07/01/25 13:00",
    "demand": 30365.35107,
    "solar_generated": 17.69857676,
    "wind_generated": 55.97798041,
    "marketplace_to_demand": 21.73994867,
    "grid_to_demand": 30011.25535,
    "govt_battery_charge": 11.04136925
  },
  {
    "timestamp": "07/01/25 14:00",
    "demand": 36603.80829,
    "solar_generated": 9.863174703,
    "wind_generated": 57.64873865,
    "marketplace_to_demand": 20.53937643,
    "grid_to_demand": 30509.62184,
    "govt_battery_charge": 94.84936877
  },
  {
    "timestamp": "07/01/25 15:00",
    "demand": 30274.10227,
    "solar_generated": 13.83320853,
    "wind_generated": 77.92337931,
    "marketplace_to_demand": 69.48344901,
    "grid_to_demand": 35720.89027,
    "govt_battery_charge": 1.418887561
  },
  {
    "timestamp": "07/01/25 16:00",
    "demand": 35514.19589,
    "solar_generated": 14.20315883,
    "wind_generated": 69.85835701,
    "marketplace_to_demand": 157.0225315,
    "grid_to_demand": 32425.03773,
    "govt_battery_charge": 97.6948142
  },
  {
    "timestamp": "07/01/25 17:00",
    "demand": 35594.5872,
    "solar_generated": 8.101892925,
    "wind_generated": 63.64222014,
    "marketplace_to_demand": 55.9861937,
    "grid_to_demand": 30054.62,
    "govt_battery_charge": 80.47115346
  },
  {
    "timestamp": "07/01/25 18:00",
    "demand": 33089.74042,
    "solar_generated": 0,
    "wind_generated": 69.62756691,
    "marketplace_to_demand": 177.3980738,
    "grid_to_demand": 30018.02559,
    "govt_battery_charge": 86.56455358
  },
  {
    "timestamp": "07/01/25 19:00",
    "demand": 30776.64441,
    "solar_generated": 0,
    "wind_generated": 66.78617052,
    "marketplace_to_demand": 22.96340732,
    "grid_to_demand": 33157.9374,
    "govt_battery_charge": 83.87497978
  },
  {
    "timestamp": "07/01/25 20:00",
    "demand": 31518.60925,
    "solar_generated": 0,
    "wind_generated": 66.96549058,
    "marketplace_to_demand": 25.71516327,
    "grid_to_demand": 33771.07798,
    "govt_battery_charge": 62.60921315
  },
  {
    "timestamp": "07/01/25 21:00",
    "demand": 33342.42981,
    "solar_generated": 0,
    "wind_generated": 76.35708285,
    "marketplace_to_demand": 104.3513182,
    "grid_to_demand": 33774.82408,
    "govt_battery_charge": 77.85687648
  },
  {
    "timestamp": "07/01/25 22:00",
    "demand": 30040.14935,
    "solar_generated": 0,
    "wind_generated": 77.3940162,
    "marketplace_to_demand": 195.45118,
    "grid_to_demand": 31480.33091,
    "govt_battery_charge": 85.02019092
  },
  {
    "timestamp": "07/01/25 23:00",
    "demand": 34753.66152,
    "solar_generated": 0,
    "wind_generated": 62.32667384,
    "marketplace_to_demand": 54.91266787,
    "grid_to_demand": 34500.67946,
    "govt_battery_charge": 55.2864157
  },
  {
    "timestamp": "08/01/25 0:00",
    "demand": 35913.15511,
    "solar_generated": 0,
    "wind_generated": 74.67123553,
    "marketplace_to_demand": 79.30868615,
    "grid_to_demand": 33530.30737,
    "govt_battery_charge": 83.21386492
  },
  {
    "timestamp": "08/01/25 1:00",
    "demand": 33181.79602,
    "solar_generated": 0,
    "wind_generated": 53.65971043,
    "marketplace_to_demand": 158.2835838,
    "grid_to_demand": 33576.95782,
    "govt_battery_charge": 79.58845395
  },
  {
    "timestamp": "08/01/25 2:00",
    "demand": 33340.39965,
    "solar_generated": 0,
    "wind_generated": 58.43453748,
    "marketplace_to_demand": 121.0998168,
    "grid_to_demand": 31496.10117,
    "govt_battery_charge": 42.61026382
  },
  {
    "timestamp": "08/01/25 3:00",
    "demand": 33261.73259,
    "solar_generated": 0,
    "wind_generated": 70.18203856,
    "marketplace_to_demand": 41.16797604,
    "grid_to_demand": 35593.41282,
    "govt_battery_charge": 17.95316899
  },
  {
    "timestamp": "08/01/25 4:00",
    "demand": 34466.08322,
    "solar_generated": 0,
    "wind_generated": 58.63120959,
    "marketplace_to_demand": 51.15702315,
    "grid_to_demand": 33596.4323,
    "govt_battery_charge": 68.7503667
  },
  {
    "timestamp": "08/01/25 5:00",
    "demand": 34761.09033,
    "solar_generated": 0,
    "wind_generated": 64.91481308,
    "marketplace_to_demand": 79.66638576,
    "grid_to_demand": 31717.91946,
    "govt_battery_charge": 33.2414512
  },
  {
    "timestamp": "08/01/25 6:00",
    "demand": 32053.20228,
    "solar_generated": 9.510954582,
    "wind_generated": 64.50985848,
    "marketplace_to_demand": 147.4727371,
    "grid_to_demand": 36229.12693,
    "govt_battery_charge": 62.01804471
  },
  {
    "timestamp": "08/01/25 7:00",
    "demand": 36172.66756,
    "solar_generated": 19.97629817,
    "wind_generated": 57.17984834,
    "marketplace_to_demand": 5.685646499,
    "grid_to_demand": 31842.57471,
    "govt_battery_charge": 21.93853906
  },
  {
    "timestamp": "08/01/25 8:00",
    "demand": 36045.41803,
    "solar_generated": 23.01465788,
    "wind_generated": 65.70308505,
    "marketplace_to_demand": 123.8370389,
    "grid_to_demand": 35358.58715,
    "govt_battery_charge": 60.62244254
  },
  {
    "timestamp": "08/01/25 9:00",
    "demand": 36555.14563,
    "solar_generated": 8.214871725,
    "wind_generated": 71.3989037,
    "marketplace_to_demand": 133.9415487,
    "grid_to_demand": 36063.6005,
    "govt_battery_charge": 25.69789269
  },
  {
    "timestamp": "08/01/25 10:00",
    "demand": 32028.48883,
    "solar_generated": 8.019380678,
    "wind_generated": 64.04998069,
    "marketplace_to_demand": 57.08082715,
    "grid_to_demand": 31779.61135,
    "govt_battery_charge": 53.7841545
  },
  {
    "timestamp": "08/01/25 11:00",
    "demand": 34236.81037,
    "solar_generated": 14.13854181,
    "wind_generated": 72.89850775,
    "marketplace_to_demand": 91.26429418,
    "grid_to_demand": 33444.91924,
    "govt_battery_charge": 35.45558855
  },
  {
    "timestamp": "08/01/25 12:00",
    "demand": 32259.96023,
    "solar_generated": 7.174721725,
    "wind_generated": 71.44537576,
    "marketplace_to_demand": 4.317998738,
    "grid_to_demand": 29917.92648,
    "govt_battery_charge": 88.03377679
  },
  {
    "timestamp": "08/01/25 13:00",
    "demand": 35403.96537,
    "solar_generated": 23.63796635,
    "wind_generated": 56.25754736,
    "marketplace_to_demand": 154.931399,
    "grid_to_demand": 34910.4693,
    "govt_battery_charge": 45.98019845
  },
  {
    "timestamp": "08/01/25 14:00",
    "demand": 32854.74834,
    "solar_generated": 10.98462631,
    "wind_generated": 62.7860247,
    "marketplace_to_demand": 35.45867768,
    "grid_to_demand": 33006.73135,
    "govt_battery_charge": 91.26389108
  },
  {
    "timestamp": "08/01/25 15:00",
    "demand": 32643.52814,
    "solar_generated": 22.29721053,
    "wind_generated": 65.38942465,
    "marketplace_to_demand": 97.3432502,
    "grid_to_demand": 36119.22247,
    "govt_battery_charge": 50.36595326
  },
  {
    "timestamp": "08/01/25 16:00",
    "demand": 30685.8283,
    "solar_generated": 9.560725339,
    "wind_generated": 74.69219596,
    "marketplace_to_demand": 188.0804655,
    "grid_to_demand": 30623.55303,
    "govt_battery_charge": 95.94100418
  },
  {
    "timestamp": "08/01/25 17:00",
    "demand": 30435.28528,
    "solar_generated": 11.75062756,
    "wind_generated": 75.24179855,
    "marketplace_to_demand": 149.4456819,
    "grid_to_demand": 33827.00109,
    "govt_battery_charge": 47.60092609
  },
  {
    "timestamp": "08/01/25 18:00",
    "demand": 33796.42865,
    "solar_generated": 0,
    "wind_generated": 75.77239183,
    "marketplace_to_demand": 29.45540558,
    "grid_to_demand": 32997.61225,
    "govt_battery_charge": 60.03607681
  },
  {
    "timestamp": "08/01/25 19:00",
    "demand": 33556.69732,
    "solar_generated": 0,
    "wind_generated": 75.74112139,
    "marketplace_to_demand": 162.4659452,
    "grid_to_demand": 35202.43647,
    "govt_battery_charge": 52.96062821
  },
  {
    "timestamp": "08/01/25 20:00",
    "demand": 34684.47169,
    "solar_generated": 0,
    "wind_generated": 59.09103045,
    "marketplace_to_demand": 86.54148132,
    "grid_to_demand": 30901.24961,
    "govt_battery_charge": 84.01637933
  },
  {
    "timestamp": "08/01/25 21:00",
    "demand": 32766.78435,
    "solar_generated": 0,
    "wind_generated": 76.72721107,
    "marketplace_to_demand": 130.0433633,
    "grid_to_demand": 33269.50588,
    "govt_battery_charge": 78.10927999
  },
  {
    "timestamp": "08/01/25 22:00",
    "demand": 36353.01033,
    "solar_generated": 0,
    "wind_generated": 78.87596575,
    "marketplace_to_demand": 147.9755039,
    "grid_to_demand": 35511.90287,
    "govt_battery_charge": 97.27316928
  },
  {
    "timestamp": "08/01/25 23:00",
    "demand": 35612.04781,
    "solar_generated": 0,
    "wind_generated": 58.17606434,
    "marketplace_to_demand": 166.09452,
    "grid_to_demand": 30357.32344,
    "govt_battery_charge": 84.75523055
  },
  {
    "timestamp": "09/01/25 0:00",
    "demand": 32925.65579,
    "solar_generated": 0,
    "wind_generated": 78.38824853,
    "marketplace_to_demand": 14.01730382,
    "grid_to_demand": 35225.75899,
    "govt_battery_charge": 8.920168045
  },
  {
    "timestamp": "09/01/25 1:00",
    "demand": 35074.42496,
    "solar_generated": 0,
    "wind_generated": 77.06001723,
    "marketplace_to_demand": 23.3593393,
    "grid_to_demand": 33300.70594,
    "govt_battery_charge": 2.201998135
  },
  {
    "timestamp": "09/01/25 2:00",
    "demand": 34612.64427,
    "solar_generated": 0,
    "wind_generated": 66.91537391,
    "marketplace_to_demand": 107.4533507,
    "grid_to_demand": 33750.53976,
    "govt_battery_charge": 99.0234782
  },
  {
    "timestamp": "09/01/25 3:00",
    "demand": 31079.48584,
    "solar_generated": 0,
    "wind_generated": 62.97391817,
    "marketplace_to_demand": 91.97548482,
    "grid_to_demand": 34891.4623,
    "govt_battery_charge": 31.20154142
  },
  {
    "timestamp": "09/01/25 4:00",
    "demand": 34644.1016,
    "solar_generated": 0,
    "wind_generated": 60.25987172,
    "marketplace_to_demand": 18.43903707,
    "grid_to_demand": 33057.03763,
    "govt_battery_charge": 35.50053193
  },
  {
    "timestamp": "09/01/25 5:00",
    "demand": 32903.72491,
    "solar_generated": 0,
    "wind_generated": 67.9199216,
    "marketplace_to_demand": 34.63986967,
    "grid_to_demand": 30346.27992,
    "govt_battery_charge": 68.87371764
  },
  {
    "timestamp": "09/01/25 6:00",
    "demand": 32438.36535,
    "solar_generated": 16.79911366,
    "wind_generated": 64.47610124,
    "marketplace_to_demand": 173.2002345,
    "grid_to_demand": 34947.81897,
    "govt_battery_charge": 33.04781806
  },
  {
    "timestamp": "09/01/25 7:00",
    "demand": 33531.38102,
    "solar_generated": 19.79489025,
    "wind_generated": 62.65395152,
    "marketplace_to_demand": 159.6543171,
    "grid_to_demand": 32455.42491,
    "govt_battery_charge": 7.091862173
  },
  {
    "timestamp": "09/01/25 8:00",
    "demand": 33734.7449,
    "solar_generated": 10.11336243,
    "wind_generated": 61.39281542,
    "marketplace_to_demand": 160.035576,
    "grid_to_demand": 31626.96298,
    "govt_battery_charge": 65.92432036
  },
  {
    "timestamp": "09/01/25 9:00",
    "demand": 31596.33048,
    "solar_generated": 6.10836394,
    "wind_generated": 58.16263944,
    "marketplace_to_demand": 121.8557195,
    "grid_to_demand": 31430.88591,
    "govt_battery_charge": 11.86873969
  },
  {
    "timestamp": "09/01/25 10:00",
    "demand": 34347.02365,
    "solar_generated": 9.409920661,
    "wind_generated": 54.09019052,
    "marketplace_to_demand": 33.22601534,
    "grid_to_demand": 36323.11233,
    "govt_battery_charge": 88.98913379
  },
  {
    "timestamp": "09/01/25 11:00",
    "demand": 35738.91744,
    "solar_generated": 17.92115528,
    "wind_generated": 59.09170575,
    "marketplace_to_demand": 31.28556167,
    "grid_to_demand": 31293.82691,
    "govt_battery_charge": 80.84037358
  },
  {
    "timestamp": "09/01/25 12:00",
    "demand": 36024.48513,
    "solar_generated": 10.52774385,
    "wind_generated": 78.09378023,
    "marketplace_to_demand": 63.60387182,
    "grid_to_demand": 34086.2975,
    "govt_battery_charge": 75.43460336
  },
  {
    "timestamp": "09/01/25 13:00",
    "demand": 32038.18853,
    "solar_generated": 14.05164814,
    "wind_generated": 63.83919948,
    "marketplace_to_demand": 185.3381398,
    "grid_to_demand": 35337.22421,
    "govt_battery_charge": 17.81720976
  },
  {
    "timestamp": "09/01/25 14:00",
    "demand": 34229.37419,
    "solar_generated": 11.54637778,
    "wind_generated": 77.71300789,
    "marketplace_to_demand": 104.3842331,
    "grid_to_demand": 33988.60327,
    "govt_battery_charge": 48.22357316
  },
  {
    "timestamp": "09/01/25 15:00",
    "demand": 35704.06561,
    "solar_generated": 9.39901162,
    "wind_generated": 70.78191659,
    "marketplace_to_demand": 67.74652385,
    "grid_to_demand": 33100.35792,
    "govt_battery_charge": 48.63842731
  },
  {
    "timestamp": "09/01/25 16:00",
    "demand": 33786.93546,
    "solar_generated": 7.142319399,
    "wind_generated": 71.21376045,
    "marketplace_to_demand": 24.80750431,
    "grid_to_demand": 34779.55246,
    "govt_battery_charge": 94.03339728
  },
  {
    "timestamp": "09/01/25 17:00",
    "demand": 32495.491,
    "solar_generated": 22.1735719,
    "wind_generated": 76.63885577,
    "marketplace_to_demand": 119.8815049,
    "grid_to_demand": 36528.1639,
    "govt_battery_charge": 50.76361029
  },
  {
    "timestamp": "09/01/25 18:00",
    "demand": 35456.4206,
    "solar_generated": 0,
    "wind_generated": 75.42218673,
    "marketplace_to_demand": 35.14808486,
    "grid_to_demand": 34200.08803,
    "govt_battery_charge": 90.72381662
  },
  {
    "timestamp": "09/01/25 19:00",
    "demand": 30004.59926,
    "solar_generated": 0,
    "wind_generated": 56.3533651,
    "marketplace_to_demand": 16.61493469,
    "grid_to_demand": 31737.26673,
    "govt_battery_charge": 31.18320678
  },
  {
    "timestamp": "09/01/25 20:00",
    "demand": 34499.95832,
    "solar_generated": 0,
    "wind_generated": 72.60061066,
    "marketplace_to_demand": 123.1276614,
    "grid_to_demand": 30942.25715,
    "govt_battery_charge": 71.02095966
  },
  {
    "timestamp": "09/01/25 21:00",
    "demand": 30627.56432,
    "solar_generated": 0,
    "wind_generated": 69.14511246,
    "marketplace_to_demand": 118.285281,
    "grid_to_demand": 34664.1748,
    "govt_battery_charge": 33.84074043
  },
  {
    "timestamp": "09/01/25 22:00",
    "demand": 34492.22625,
    "solar_generated": 0,
    "wind_generated": 68.16108825,
    "marketplace_to_demand": 99.02302117,
    "grid_to_demand": 30534.57529,
    "govt_battery_charge": 88.73471555
  },
  {
    "timestamp": "09/01/25 23:00",
    "demand": 30718.06845,
    "solar_generated": 0,
    "wind_generated": 80.01716631,
    "marketplace_to_demand": 175.4128381,
    "grid_to_demand": 32064.60776,
    "govt_battery_charge": 28.2274569
  },
  {
    "timestamp": "10/01/25 0:00",
    "demand": 33513.81152,
    "solar_generated": 0,
    "wind_generated": 78.91574755,
    "marketplace_to_demand": 68.52470582,
    "grid_to_demand": 31163.87437,
    "govt_battery_charge": 42.50160937
  },
  {
    "timestamp": "10/01/25 1:00",
    "demand": 34203.64597,
    "solar_generated": 0,
    "wind_generated": 70.76334614,
    "marketplace_to_demand": 55.43513726,
    "grid_to_demand": 31976.01656,
    "govt_battery_charge": 49.27202407
  },
  {
    "timestamp": "10/01/25 2:00",
    "demand": 30302.76069,
    "solar_generated": 0,
    "wind_generated": 61.56730355,
    "marketplace_to_demand": 197.0531815,
    "grid_to_demand": 30259.13567,
    "govt_battery_charge": 51.6676776
  },
  {
    "timestamp": "10/01/25 3:00",
    "demand": 36557.98874,
    "solar_generated": 0,
    "wind_generated": 67.97370704,
    "marketplace_to_demand": 0.062194645,
    "grid_to_demand": 35244.76808,
    "govt_battery_charge": 38.07189309
  },
  {
    "timestamp": "10/01/25 4:00",
    "demand": 34946.67075,
    "solar_generated": 0,
    "wind_generated": 73.25400655,
    "marketplace_to_demand": 45.97540762,
    "grid_to_demand": 30454.72169,
    "govt_battery_charge": 27.4504716
  },
  {
    "timestamp": "10/01/25 5:00",
    "demand": 35412.01844,
    "solar_generated": 0,
    "wind_generated": 74.03822524,
    "marketplace_to_demand": 171.2435961,
    "grid_to_demand": 33370.41329,
    "govt_battery_charge": 33.58679358
  },
  {
    "timestamp": "10/01/25 6:00",
    "demand": 30284.21793,
    "solar_generated": 22.38368785,
    "wind_generated": 57.66584136,
    "marketplace_to_demand": 81.62157049,
    "grid_to_demand": 30233.46881,
    "govt_battery_charge": 4.772671669
  },
  {
    "timestamp": "10/01/25 7:00",
    "demand": 35486.63043,
    "solar_generated": 18.73367031,
    "wind_generated": 67.60998448,
    "marketplace_to_demand": 19.46260318,
    "grid_to_demand": 35094.31133,
    "govt_battery_charge": 97.36714069
  },
  {
    "timestamp": "10/01/25 8:00",
    "demand": 35132.38728,
    "solar_generated": 18.53857676,
    "wind_generated": 74.33982014,
    "marketplace_to_demand": 122.638405,
    "grid_to_demand": 34551.7254,
    "govt_battery_charge": 69.09168676
  },
  {
    "timestamp": "10/01/25 9:00",
    "demand": 30276.97172,
    "solar_generated": 9.868746655,
    "wind_generated": 74.91856288,
    "marketplace_to_demand": 94.15098443,
    "grid_to_demand": 36132.26914,
    "govt_battery_charge": 79.98775408
  },
  {
    "timestamp": "10/01/25 10:00",
    "demand": 34560.93189,
    "solar_generated": 21.24066941,
    "wind_generated": 65.58567611,
    "marketplace_to_demand": 104.2601133,
    "grid_to_demand": 34149.16309,
    "govt_battery_charge": 51.96065529
  },
  {
    "timestamp": "10/01/25 11:00",
    "demand": 31392.29176,
    "solar_generated": 10.18292053,
    "wind_generated": 79.66830875,
    "marketplace_to_demand": 5.210824218,
    "grid_to_demand": 32237.28756,
    "govt_battery_charge": 57.79696193
  },
  {
    "timestamp": "10/01/25 12:00",
    "demand": 32678.95951,
    "solar_generated": 23.15508463,
    "wind_generated": 79.2373162,
    "marketplace_to_demand": 81.03840017,
    "grid_to_demand": 30202.14614,
    "govt_battery_charge": 27.33376092
  },
  {
    "timestamp": "10/01/25 13:00",
    "demand": 34386.79571,
    "solar_generated": 11.61196786,
    "wind_generated": 61.07619714,
    "marketplace_to_demand": 36.04118449,
    "grid_to_demand": 33730.05411,
    "govt_battery_charge": 47.52210609
  },
  {
    "timestamp": "10/01/25 14:00",
    "demand": 35397.78541,
    "solar_generated": 10.01045078,
    "wind_generated": 55.98439027,
    "marketplace_to_demand": 118.803406,
    "grid_to_demand": 35133.13826,
    "govt_battery_charge": 94.06671017
  },
  {
    "timestamp": "10/01/25 15:00",
    "demand": 34530.03603,
    "solar_generated": 7.212922154,
    "wind_generated": 70.51337416,
    "marketplace_to_demand": 73.09941847,
    "grid_to_demand": 34120.03793,
    "govt_battery_charge": 38.81214802
  },
  {
    "timestamp": "10/01/25 16:00",
    "demand": 31340.51388,
    "solar_generated": 22.332342,
    "wind_generated": 59.89840204,
    "marketplace_to_demand": 8.356573138,
    "grid_to_demand": 33568.25015,
    "govt_battery_charge": 22.01926188
  },
  {
    "timestamp": "10/01/25 17:00",
    "demand": 34373.09422,
    "solar_generated": 14.73673772,
    "wind_generated": 60.42922166,
    "marketplace_to_demand": 153.5145716,
    "grid_to_demand": 35548.18139,
    "govt_battery_charge": 30.48157833
  },
  {
    "timestamp": "10/01/25 18:00",
    "demand": 32335.44745,
    "solar_generated": 0,
    "wind_generated": 59.9757496,
    "marketplace_to_demand": 20.82028459,
    "grid_to_demand": 35594.49871,
    "govt_battery_charge": 63.53838005
  },
  {
    "timestamp": "10/01/25 19:00",
    "demand": 35830.8067,
    "solar_generated": 0,
    "wind_generated": 76.76219655,
    "marketplace_to_demand": 2.935408325,
    "grid_to_demand": 30370.18374,
    "govt_battery_charge": 40.98880948
  },
  {
    "timestamp": "10/01/25 20:00",
    "demand": 32174.74955,
    "solar_generated": 0,
    "wind_generated": 68.41896502,
    "marketplace_to_demand": 55.46389667,
    "grid_to_demand": 30774.4945,
    "govt_battery_charge": 74.29934368
  },
  {
    "timestamp": "10/01/25 21:00",
    "demand": 36187.49791,
    "solar_generated": 0,
    "wind_generated": 57.88603378,
    "marketplace_to_demand": 31.92761355,
    "grid_to_demand": 31386.09863,
    "govt_battery_charge": 60.07660769
  },
  {
    "timestamp": "10/01/25 22:00",
    "demand": 31598.55351,
    "solar_generated": 0,
    "wind_generated": 63.16385601,
    "marketplace_to_demand": 108.3655252,
    "grid_to_demand": 31678.69803,
    "govt_battery_charge": 21.36640178
  },
  {
    "timestamp": "10/01/25 23:00",
    "demand": 32972.04711,
    "solar_generated": 0,
    "wind_generated": 65.87264881,
    "marketplace_to_demand": 86.29402692,
    "grid_to_demand": 33767.99021,
    "govt_battery_charge": 2.097044163
  },
  {
    "timestamp": "11/01/25 0:00",
    "demand": 36075.93708,
    "solar_generated": 0,
    "wind_generated": 75.96441273,
    "marketplace_to_demand": 44.34764648,
    "grid_to_demand": 35365.82315,
    "govt_battery_charge": 34.52187819
  },
  {
    "timestamp": "11/01/25 1:00",
    "demand": 33711.39707,
    "solar_generated": 0,
    "wind_generated": 61.07492296,
    "marketplace_to_demand": 105.6678768,
    "grid_to_demand": 32985.17476,
    "govt_battery_charge": 59.52778795
  },
  {
    "timestamp": "11/01/25 2:00",
    "demand": 30221.647,
    "solar_generated": 0,
    "wind_generated": 66.14660456,
    "marketplace_to_demand": 162.7165089,
    "grid_to_demand": 35867.9566,
    "govt_battery_charge": 80.64129691
  },
  {
    "timestamp": "11/01/25 3:00",
    "demand": 30461.11591,
    "solar_generated": 0,
    "wind_generated": 71.92564036,
    "marketplace_to_demand": 57.78090962,
    "grid_to_demand": 36292.16067,
    "govt_battery_charge": 42.09586493
  },
  {
    "timestamp": "11/01/25 4:00",
    "demand": 35069.75084,
    "solar_generated": 0,
    "wind_generated": 71.05588622,
    "marketplace_to_demand": 167.4800851,
    "grid_to_demand": 33365.145,
    "govt_battery_charge": 11.37604109
  },
  {
    "timestamp": "11/01/25 5:00",
    "demand": 32222.282,
    "solar_generated": 0,
    "wind_generated": 70.27753349,
    "marketplace_to_demand": 121.0992482,
    "grid_to_demand": 30171.59039,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "11/01/25 6:00",
    "demand": 34880.86289,
    "solar_generated": 13.34673421,
    "wind_generated": 65.20463068,
    "marketplace_to_demand": 120.1033358,
    "grid_to_demand": 31103.76548,
    "govt_battery_charge": 19.38238177
  },
  {
    "timestamp": "11/01/25 7:00",
    "demand": 32565.16808,
    "solar_generated": 23.98672258,
    "wind_generated": 61.91073217,
    "marketplace_to_demand": 28.04099797,
    "grid_to_demand": 30034.65628,
    "govt_battery_charge": 7.268097637
  },
  {
    "timestamp": "11/01/25 8:00",
    "demand": 33674.24379,
    "solar_generated": 19.18557547,
    "wind_generated": 55.65567417,
    "marketplace_to_demand": 177.6756031,
    "grid_to_demand": 32668.96597,
    "govt_battery_charge": 60.99682565
  },
  {
    "timestamp": "11/01/25 9:00",
    "demand": 33839.32365,
    "solar_generated": 13.4230953,
    "wind_generated": 78.07395532,
    "marketplace_to_demand": 106.5546903,
    "grid_to_demand": 35897.90338,
    "govt_battery_charge": 9.097149906
  },
  {
    "timestamp": "11/01/25 10:00",
    "demand": 35208.67001,
    "solar_generated": 12.03932006,
    "wind_generated": 65.04496322,
    "marketplace_to_demand": 139.0554526,
    "grid_to_demand": 35977.0517,
    "govt_battery_charge": 51.31799388
  },
  {
    "timestamp": "11/01/25 11:00",
    "demand": 30698.82513,
    "solar_generated": 19.21045569,
    "wind_generated": 54.51220753,
    "marketplace_to_demand": 143.8678925,
    "grid_to_demand": 35205.74168,
    "govt_battery_charge": 95.64522145
  },
  {
    "timestamp": "11/01/25 12:00",
    "demand": 33866.78992,
    "solar_generated": 17.00034152,
    "wind_generated": 67.41033958,
    "marketplace_to_demand": 151.2769986,
    "grid_to_demand": 34572.51526,
    "govt_battery_charge": 68.92387171
  },
  {
    "timestamp": "11/01/25 13:00",
    "demand": 30508.08742,
    "solar_generated": 7.871096169,
    "wind_generated": 72.9437183,
    "marketplace_to_demand": 137.9114999,
    "grid_to_demand": 32838.24264,
    "govt_battery_charge": 28.53700121
  },
  {
    "timestamp": "11/01/25 14:00",
    "demand": 34364.4644,
    "solar_generated": 7.990875204,
    "wind_generated": 63.87617133,
    "marketplace_to_demand": 107.4029561,
    "grid_to_demand": 35307.47375,
    "govt_battery_charge": 75.04503556
  },
  {
    "timestamp": "11/01/25 15:00",
    "demand": 36029.83186,
    "solar_generated": 11.53068688,
    "wind_generated": 54.70084832,
    "marketplace_to_demand": 160.0047146,
    "grid_to_demand": 35605.43341,
    "govt_battery_charge": 37.80507643
  },
  {
    "timestamp": "11/01/25 16:00",
    "demand": 31958.76807,
    "solar_generated": 9.481117073,
    "wind_generated": 76.64960401,
    "marketplace_to_demand": 185.1618944,
    "grid_to_demand": 31193.82056,
    "govt_battery_charge": 1.548632663
  },
  {
    "timestamp": "11/01/25 17:00",
    "demand": 34001.27941,
    "solar_generated": 15.39482937,
    "wind_generated": 75.10547271,
    "marketplace_to_demand": 44.35402141,
    "grid_to_demand": 30705.76857,
    "govt_battery_charge": 73.9137295
  },
  {
    "timestamp": "11/01/25 18:00",
    "demand": 31947.22477,
    "solar_generated": 0,
    "wind_generated": 61.04262839,
    "marketplace_to_demand": 93.40444481,
    "grid_to_demand": 30583.90333,
    "govt_battery_charge": 12.27177635
  },
  {
    "timestamp": "11/01/25 19:00",
    "demand": 32581.0532,
    "solar_generated": 0,
    "wind_generated": 62.14811118,
    "marketplace_to_demand": 107.1072123,
    "grid_to_demand": 30040.01109,
    "govt_battery_charge": 16.95488124
  },
  {
    "timestamp": "11/01/25 20:00",
    "demand": 30341.7094,
    "solar_generated": 0,
    "wind_generated": 56.8890889,
    "marketplace_to_demand": 118.4888444,
    "grid_to_demand": 35061.9356,
    "govt_battery_charge": 33.66732814
  },
  {
    "timestamp": "11/01/25 21:00",
    "demand": 30053.19211,
    "solar_generated": 0,
    "wind_generated": 68.63102819,
    "marketplace_to_demand": 173.4535177,
    "grid_to_demand": 35369.69413,
    "govt_battery_charge": 64.70702479
  },
  {
    "timestamp": "11/01/25 22:00",
    "demand": 35155.7921,
    "solar_generated": 0,
    "wind_generated": 75.0321998,
    "marketplace_to_demand": 162.8647049,
    "grid_to_demand": 35881.75706,
    "govt_battery_charge": 62.81817128
  },
  {
    "timestamp": "11/01/25 23:00",
    "demand": 34009.89966,
    "solar_generated": 0,
    "wind_generated": 67.57569258,
    "marketplace_to_demand": 93.22581479,
    "grid_to_demand": 31471.24243,
    "govt_battery_charge": 12.70853798
  },
  {
    "timestamp": "12/01/25 0:00",
    "demand": 30086.13588,
    "solar_generated": 0,
    "wind_generated": 73.81994079,
    "marketplace_to_demand": 144.8867221,
    "grid_to_demand": 31764.2632,
    "govt_battery_charge": 69.10389531
  },
  {
    "timestamp": "12/01/25 1:00",
    "demand": 31686.22161,
    "solar_generated": 0,
    "wind_generated": 60.24522078,
    "marketplace_to_demand": 113.0019726,
    "grid_to_demand": 30971.2227,
    "govt_battery_charge": 34.4392578
  },
  {
    "timestamp": "12/01/25 2:00",
    "demand": 35953.41543,
    "solar_generated": 0,
    "wind_generated": 78.69356562,
    "marketplace_to_demand": 182.3016305,
    "grid_to_demand": 33146.53495,
    "govt_battery_charge": 73.49469636
  },
  {
    "timestamp": "12/01/25 3:00",
    "demand": 33672.39284,
    "solar_generated": 0,
    "wind_generated": 76.11649243,
    "marketplace_to_demand": 3.473020254,
    "grid_to_demand": 29952.30321,
    "govt_battery_charge": 64.07599281
  },
  {
    "timestamp": "12/01/25 4:00",
    "demand": 35839.83648,
    "solar_generated": 0,
    "wind_generated": 56.66610769,
    "marketplace_to_demand": 57.22756815,
    "grid_to_demand": 35771.96974,
    "govt_battery_charge": 25.80155752
  },
  {
    "timestamp": "12/01/25 5:00",
    "demand": 34607.27484,
    "solar_generated": 0,
    "wind_generated": 57.75242002,
    "marketplace_to_demand": 44.38148233,
    "grid_to_demand": 33902.91384,
    "govt_battery_charge": 37.70375134
  },
  {
    "timestamp": "12/01/25 6:00",
    "demand": 33007.38849,
    "solar_generated": 9.371337554,
    "wind_generated": 62.00451766,
    "marketplace_to_demand": 96.00156307,
    "grid_to_demand": 34850.99926,
    "govt_battery_charge": 61.51747498
  },
  {
    "timestamp": "12/01/25 7:00",
    "demand": 31433.32317,
    "solar_generated": 15.62104267,
    "wind_generated": 74.85039405,
    "marketplace_to_demand": 26.99467683,
    "grid_to_demand": 36070.81718,
    "govt_battery_charge": 84.57754991
  },
  {
    "timestamp": "12/01/25 8:00",
    "demand": 32578.3474,
    "solar_generated": 12.81548881,
    "wind_generated": 54.72836078,
    "marketplace_to_demand": 40.59387509,
    "grid_to_demand": 32388.73148,
    "govt_battery_charge": 66.95156886
  },
  {
    "timestamp": "12/01/25 9:00",
    "demand": 30533.37697,
    "solar_generated": 10.5826718,
    "wind_generated": 76.5113027,
    "marketplace_to_demand": 140.5428712,
    "grid_to_demand": 33963.37611,
    "govt_battery_charge": 27.47590812
  },
  {
    "timestamp": "12/01/25 10:00",
    "demand": 33887.61064,
    "solar_generated": 8.004399927,
    "wind_generated": 72.29660065,
    "marketplace_to_demand": 153.6488766,
    "grid_to_demand": 32842.71556,
    "govt_battery_charge": 39.08998632
  },
  {
    "timestamp": "12/01/25 11:00",
    "demand": 32642.81267,
    "solar_generated": 12.77498611,
    "wind_generated": 64.52898601,
    "marketplace_to_demand": 181.5182463,
    "grid_to_demand": 32681.57158,
    "govt_battery_charge": 33.52428411
  },
  {
    "timestamp": "12/01/25 12:00",
    "demand": 34820.61882,
    "solar_generated": 20.83089814,
    "wind_generated": 69.3897729,
    "marketplace_to_demand": 132.3037715,
    "grid_to_demand": 32707.13873,
    "govt_battery_charge": 47.94378923
  },
  {
    "timestamp": "12/01/25 13:00",
    "demand": 34950.92886,
    "solar_generated": 10.76525499,
    "wind_generated": 60.83893828,
    "marketplace_to_demand": 96.57214978,
    "grid_to_demand": 33990.51584,
    "govt_battery_charge": 35.25515685
  },
  {
    "timestamp": "12/01/25 14:00",
    "demand": 31549.22898,
    "solar_generated": 15.81772402,
    "wind_generated": 60.07322416,
    "marketplace_to_demand": 17.01738504,
    "grid_to_demand": 31381.13257,
    "govt_battery_charge": 28.86115985
  },
  {
    "timestamp": "12/01/25 15:00",
    "demand": 31495.04755,
    "solar_generated": 8.485699293,
    "wind_generated": 80.00576507,
    "marketplace_to_demand": 128.4779992,
    "grid_to_demand": 33096.33926,
    "govt_battery_charge": 12.81692141
  },
  {
    "timestamp": "12/01/25 16:00",
    "demand": 35207.38675,
    "solar_generated": 15.58150239,
    "wind_generated": 78.96123774,
    "marketplace_to_demand": 1.166500671,
    "grid_to_demand": 30413.01995,
    "govt_battery_charge": 4.762063004
  },
  {
    "timestamp": "12/01/25 17:00",
    "demand": 35908.50919,
    "solar_generated": 13.11986299,
    "wind_generated": 74.23350027,
    "marketplace_to_demand": 184.2590309,
    "grid_to_demand": 31057.31451,
    "govt_battery_charge": 93.47444418
  },
  {
    "timestamp": "12/01/25 18:00",
    "demand": 33341.12313,
    "solar_generated": 0,
    "wind_generated": 61.71043817,
    "marketplace_to_demand": 175.2044865,
    "grid_to_demand": 34626.35405,
    "govt_battery_charge": 14.05034948
  },
  {
    "timestamp": "12/01/25 19:00",
    "demand": 33787.16182,
    "solar_generated": 0,
    "wind_generated": 72.76577976,
    "marketplace_to_demand": 195.8414386,
    "grid_to_demand": 33415.25744,
    "govt_battery_charge": 96.55176183
  },
  {
    "timestamp": "12/01/25 20:00",
    "demand": 30239.44681,
    "solar_generated": 0,
    "wind_generated": 63.74203513,
    "marketplace_to_demand": 36.72251009,
    "grid_to_demand": 30544.71919,
    "govt_battery_charge": 74.71968478
  },
  {
    "timestamp": "12/01/25 21:00",
    "demand": 33238.38274,
    "solar_generated": 0,
    "wind_generated": 53.88135825,
    "marketplace_to_demand": 123.365142,
    "grid_to_demand": 35846.70902,
    "govt_battery_charge": 49.30534139
  },
  {
    "timestamp": "12/01/25 22:00",
    "demand": 30659.44713,
    "solar_generated": 0,
    "wind_generated": 54.63024694,
    "marketplace_to_demand": 175.5541237,
    "grid_to_demand": 35852.31463,
    "govt_battery_charge": 88.52685906
  },
  {
    "timestamp": "12/01/25 23:00",
    "demand": 36447.23952,
    "solar_generated": 0,
    "wind_generated": 55.80044605,
    "marketplace_to_demand": 23.67244813,
    "grid_to_demand": 30715.40839,
    "govt_battery_charge": 20.96614733
  },
  {
    "timestamp": "13/01/25 0:00",
    "demand": 34338.76241,
    "solar_generated": 0,
    "wind_generated": 77.53789303,
    "marketplace_to_demand": 119.7421959,
    "grid_to_demand": 32721.39766,
    "govt_battery_charge": 97.92294173
  },
  {
    "timestamp": "13/01/25 1:00",
    "demand": 35726.88767,
    "solar_generated": 0,
    "wind_generated": 66.56649219,
    "marketplace_to_demand": 94.31201784,
    "grid_to_demand": 34200.85162,
    "govt_battery_charge": 11.58484291
  },
  {
    "timestamp": "13/01/25 2:00",
    "demand": 30338.92064,
    "solar_generated": 0,
    "wind_generated": 57.76385092,
    "marketplace_to_demand": 119.7788095,
    "grid_to_demand": 35549.49894,
    "govt_battery_charge": 1.724625572
  },
  {
    "timestamp": "13/01/25 3:00",
    "demand": 34836.23769,
    "solar_generated": 0,
    "wind_generated": 74.21429863,
    "marketplace_to_demand": 103.2832201,
    "grid_to_demand": 35196.0963,
    "govt_battery_charge": 71.5538506
  },
  {
    "timestamp": "13/01/25 4:00",
    "demand": 32097.99556,
    "solar_generated": 0,
    "wind_generated": 68.58833112,
    "marketplace_to_demand": 91.0260235,
    "grid_to_demand": 31014.74449,
    "govt_battery_charge": 73.88702571
  },
  {
    "timestamp": "13/01/25 5:00",
    "demand": 34711.60217,
    "solar_generated": 0,
    "wind_generated": 77.92046834,
    "marketplace_to_demand": 141.2703821,
    "grid_to_demand": 34903.68358,
    "govt_battery_charge": 91.47879444
  },
  {
    "timestamp": "13/01/25 6:00",
    "demand": 31717.52033,
    "solar_generated": 16.88801297,
    "wind_generated": 67.95286217,
    "marketplace_to_demand": 78.67758204,
    "grid_to_demand": 33936.34897,
    "govt_battery_charge": 18.96513907
  },
  {
    "timestamp": "13/01/25 7:00",
    "demand": 30895.15355,
    "solar_generated": 11.51743137,
    "wind_generated": 53.73193767,
    "marketplace_to_demand": 39.33960598,
    "grid_to_demand": 32247.3676,
    "govt_battery_charge": 75.13783275
  },
  {
    "timestamp": "13/01/25 8:00",
    "demand": 36456.72903,
    "solar_generated": 8.585709906,
    "wind_generated": 53.84731238,
    "marketplace_to_demand": 7.140897039,
    "grid_to_demand": 30113.71226,
    "govt_battery_charge": 15.25111828
  },
  {
    "timestamp": "13/01/25 9:00",
    "demand": 35656.79681,
    "solar_generated": 17.39798591,
    "wind_generated": 62.31586599,
    "marketplace_to_demand": 52.63341715,
    "grid_to_demand": 31119.13593,
    "govt_battery_charge": 18.01400001
  },
  {
    "timestamp": "13/01/25 10:00",
    "demand": 31589.22929,
    "solar_generated": 18.17104537,
    "wind_generated": 67.03928695,
    "marketplace_to_demand": 172.8312679,
    "grid_to_demand": 30353.83205,
    "govt_battery_charge": 13.71872293
  },
  {
    "timestamp": "13/01/25 11:00",
    "demand": 34946.58553,
    "solar_generated": 13.57707613,
    "wind_generated": 68.20101201,
    "marketplace_to_demand": 115.1474924,
    "grid_to_demand": 31926.89405,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "13/01/25 12:00",
    "demand": 33153.54981,
    "solar_generated": 10.20376158,
    "wind_generated": 67.18113675,
    "marketplace_to_demand": 188.0515744,
    "grid_to_demand": 36470.43004,
    "govt_battery_charge": 42.09616263
  },
  {
    "timestamp": "13/01/25 13:00",
    "demand": 30167.64543,
    "solar_generated": 16.14952738,
    "wind_generated": 60.09118509,
    "marketplace_to_demand": 153.4603341,
    "grid_to_demand": 33884.92723,
    "govt_battery_charge": 92.39912394
  },
  {
    "timestamp": "13/01/25 14:00",
    "demand": 33427.0531,
    "solar_generated": 22.46693932,
    "wind_generated": 69.74956272,
    "marketplace_to_demand": 156.5962023,
    "grid_to_demand": 33000.66204,
    "govt_battery_charge": 79.32514463
  },
  {
    "timestamp": "13/01/25 15:00",
    "demand": 33037.20637,
    "solar_generated": 19.24120455,
    "wind_generated": 58.82600824,
    "marketplace_to_demand": 65.40452332,
    "grid_to_demand": 30582.68927,
    "govt_battery_charge": 69.8844466
  },
  {
    "timestamp": "13/01/25 16:00",
    "demand": 33512.40749,
    "solar_generated": 15.89663734,
    "wind_generated": 68.53725743,
    "marketplace_to_demand": 66.52526727,
    "grid_to_demand": 35539.17238,
    "govt_battery_charge": 35.56771201
  },
  {
    "timestamp": "13/01/25 17:00",
    "demand": 34213.81725,
    "solar_generated": 16.33257879,
    "wind_generated": 57.3660327,
    "marketplace_to_demand": 192.3281842,
    "grid_to_demand": 33581.71564,
    "govt_battery_charge": 90.33547897
  },
  {
    "timestamp": "13/01/25 18:00",
    "demand": 30243.35399,
    "solar_generated": 0,
    "wind_generated": 72.96276877,
    "marketplace_to_demand": 84.0096778,
    "grid_to_demand": 34806.64781,
    "govt_battery_charge": 15.24831545
  },
  {
    "timestamp": "13/01/25 19:00",
    "demand": 36219.43141,
    "solar_generated": 0,
    "wind_generated": 60.16254601,
    "marketplace_to_demand": 168.5049299,
    "grid_to_demand": 30978.91853,
    "govt_battery_charge": 58.92344763
  },
  {
    "timestamp": "13/01/25 20:00",
    "demand": 31330.8839,
    "solar_generated": 0,
    "wind_generated": 54.99145065,
    "marketplace_to_demand": 170.7904745,
    "grid_to_demand": 33670.0493,
    "govt_battery_charge": 36.70190516
  },
  {
    "timestamp": "13/01/25 21:00",
    "demand": 35717.84834,
    "solar_generated": 0,
    "wind_generated": 60.76798224,
    "marketplace_to_demand": 32.84082367,
    "grid_to_demand": 33555.80557,
    "govt_battery_charge": 26.38424653
  },
  {
    "timestamp": "13/01/25 22:00",
    "demand": 32170.83057,
    "solar_generated": 0,
    "wind_generated": 71.60625989,
    "marketplace_to_demand": 19.71526766,
    "grid_to_demand": 34618.66091,
    "govt_battery_charge": 80.77672158
  },
  {
    "timestamp": "13/01/25 23:00",
    "demand": 33690.00653,
    "solar_generated": 0,
    "wind_generated": 72.24703785,
    "marketplace_to_demand": 110.0621279,
    "grid_to_demand": 34984.09216,
    "govt_battery_charge": 13.49704578
  },
  {
    "timestamp": "14/01/25 0:00",
    "demand": 35772.90552,
    "solar_generated": 0,
    "wind_generated": 60.23277192,
    "marketplace_to_demand": 67.70384316,
    "grid_to_demand": 35110.12005,
    "govt_battery_charge": 88.95513605
  },
  {
    "timestamp": "14/01/25 1:00",
    "demand": 36313.93285,
    "solar_generated": 0,
    "wind_generated": 75.81146058,
    "marketplace_to_demand": 159.3505077,
    "grid_to_demand": 30759.02477,
    "govt_battery_charge": 95.58525964
  },
  {
    "timestamp": "14/01/25 2:00",
    "demand": 31444.10227,
    "solar_generated": 0,
    "wind_generated": 66.33756188,
    "marketplace_to_demand": 116.4747558,
    "grid_to_demand": 34621.07327,
    "govt_battery_charge": 41.92176411
  },
  {
    "timestamp": "14/01/25 3:00",
    "demand": 32860.84112,
    "solar_generated": 0,
    "wind_generated": 59.98434188,
    "marketplace_to_demand": 17.72385107,
    "grid_to_demand": 34440.59325,
    "govt_battery_charge": 48.29851361
  },
  {
    "timestamp": "14/01/25 4:00",
    "demand": 31438.92341,
    "solar_generated": 0,
    "wind_generated": 70.03035893,
    "marketplace_to_demand": 23.28558841,
    "grid_to_demand": 35492.7227,
    "govt_battery_charge": 49.9634019
  },
  {
    "timestamp": "14/01/25 5:00",
    "demand": 33340.62505,
    "solar_generated": 0,
    "wind_generated": 63.75500789,
    "marketplace_to_demand": 83.51415959,
    "grid_to_demand": 30648.39116,
    "govt_battery_charge": 36.07966762
  },
  {
    "timestamp": "14/01/25 6:00",
    "demand": 31323.66212,
    "solar_generated": 8.812887987,
    "wind_generated": 68.80682527,
    "marketplace_to_demand": 14.63003867,
    "grid_to_demand": 35566.39251,
    "govt_battery_charge": 47.45677071
  },
  {
    "timestamp": "14/01/25 7:00",
    "demand": 32689.56134,
    "solar_generated": 10.0239668,
    "wind_generated": 56.14210926,
    "marketplace_to_demand": 142.9048174,
    "grid_to_demand": 34107.81175,
    "govt_battery_charge": 71.50551373
  },
  {
    "timestamp": "14/01/25 8:00",
    "demand": 35516.89257,
    "solar_generated": 18.48783555,
    "wind_generated": 78.37917394,
    "marketplace_to_demand": 2.690960244,
    "grid_to_demand": 36357.05745,
    "govt_battery_charge": 97.7476892
  },
  {
    "timestamp": "14/01/25 9:00",
    "demand": 32684.30591,
    "solar_generated": 22.19328969,
    "wind_generated": 56.66779611,
    "marketplace_to_demand": 121.9849867,
    "grid_to_demand": 33147.86836,
    "govt_battery_charge": 42.53948233
  },
  {
    "timestamp": "14/01/25 10:00",
    "demand": 31124.90935,
    "solar_generated": 6.637875274,
    "wind_generated": 56.26624286,
    "marketplace_to_demand": 198.2727054,
    "grid_to_demand": 30547.41466,
    "govt_battery_charge": 54.21914973
  },
  {
    "timestamp": "14/01/25 11:00",
    "demand": 32977.08732,
    "solar_generated": 17.15188343,
    "wind_generated": 66.58812932,
    "marketplace_to_demand": 28.59376272,
    "grid_to_demand": 33285.8745,
    "govt_battery_charge": 84.83571577
  },
  {
    "timestamp": "14/01/25 12:00",
    "demand": 32104.73729,
    "solar_generated": 11.13879869,
    "wind_generated": 57.17494496,
    "marketplace_to_demand": 91.16333365,
    "grid_to_demand": 35153.42333,
    "govt_battery_charge": 6.540691433
  },
  {
    "timestamp": "14/01/25 13:00",
    "demand": 35121.06904,
    "solar_generated": 21.22273193,
    "wind_generated": 75.25099621,
    "marketplace_to_demand": 30.43604362,
    "grid_to_demand": 32131.98132,
    "govt_battery_charge": 19.11830262
  },
  {
    "timestamp": "14/01/25 14:00",
    "demand": 33427.48761,
    "solar_generated": 21.24713439,
    "wind_generated": 78.95738453,
    "marketplace_to_demand": 193.3367666,
    "grid_to_demand": 35490.00253,
    "govt_battery_charge": 53.63267818
  },
  {
    "timestamp": "14/01/25 15:00",
    "demand": 35136.38122,
    "solar_generated": 20.08001824,
    "wind_generated": 65.53300548,
    "marketplace_to_demand": 105.0384215,
    "grid_to_demand": 31971.67375,
    "govt_battery_charge": 80.06335762
  },
  {
    "timestamp": "14/01/25 16:00",
    "demand": 30980.92948,
    "solar_generated": 23.06197975,
    "wind_generated": 60.66629719,
    "marketplace_to_demand": 26.01506144,
    "grid_to_demand": 35605.59975,
    "govt_battery_charge": 59.90191921
  },
  {
    "timestamp": "14/01/25 17:00",
    "demand": 30407.3794,
    "solar_generated": 7.333189363,
    "wind_generated": 57.93770884,
    "marketplace_to_demand": 44.2154965,
    "grid_to_demand": 32346.27901,
    "govt_battery_charge": 75.56134385
  },
  {
    "timestamp": "14/01/25 18:00",
    "demand": 35084.2933,
    "solar_generated": 0,
    "wind_generated": 68.70071237,
    "marketplace_to_demand": 113.1614958,
    "grid_to_demand": 33560.17555,
    "govt_battery_charge": 47.18781083
  },
  {
    "timestamp": "14/01/25 19:00",
    "demand": 30660.51982,
    "solar_generated": 0,
    "wind_generated": 80.04480595,
    "marketplace_to_demand": 45.49467914,
    "grid_to_demand": 30885.88143,
    "govt_battery_charge": 58.08488362
  },
  {
    "timestamp": "14/01/25 20:00",
    "demand": 35194.92938,
    "solar_generated": 0,
    "wind_generated": 53.80363379,
    "marketplace_to_demand": 162.6638773,
    "grid_to_demand": 33732.37239,
    "govt_battery_charge": 44.82071229
  },
  {
    "timestamp": "14/01/25 21:00",
    "demand": 34902.19387,
    "solar_generated": 0,
    "wind_generated": 65.30821504,
    "marketplace_to_demand": 54.08674539,
    "grid_to_demand": 31714.753,
    "govt_battery_charge": 76.39736884
  },
  {
    "timestamp": "14/01/25 22:00",
    "demand": 32327.30995,
    "solar_generated": 0,
    "wind_generated": 63.64097477,
    "marketplace_to_demand": 120.0457589,
    "grid_to_demand": 36509.55295,
    "govt_battery_charge": 5.114549209
  },
  {
    "timestamp": "14/01/25 23:00",
    "demand": 35194.84589,
    "solar_generated": 0,
    "wind_generated": 70.47684077,
    "marketplace_to_demand": 197.0391423,
    "grid_to_demand": 33306.9493,
    "govt_battery_charge": 45.17685342
  },
  {
    "timestamp": "15/01/25 0:00",
    "demand": 30753.06097,
    "solar_generated": 0,
    "wind_generated": 65.49264425,
    "marketplace_to_demand": 180.184596,
    "grid_to_demand": 33998.22369,
    "govt_battery_charge": 66.25006011
  },
  {
    "timestamp": "15/01/25 1:00",
    "demand": 34652.52429,
    "solar_generated": 0,
    "wind_generated": 74.92665421,
    "marketplace_to_demand": 47.91108729,
    "grid_to_demand": 34198.03643,
    "govt_battery_charge": 71.26212333
  },
  {
    "timestamp": "15/01/25 2:00",
    "demand": 34644.39398,
    "solar_generated": 0,
    "wind_generated": 60.71464832,
    "marketplace_to_demand": 103.962629,
    "grid_to_demand": 33914.06587,
    "govt_battery_charge": 65.78162525
  },
  {
    "timestamp": "15/01/25 3:00",
    "demand": 30813.28425,
    "solar_generated": 0,
    "wind_generated": 76.30927858,
    "marketplace_to_demand": 34.071906,
    "grid_to_demand": 31972.46158,
    "govt_battery_charge": 12.32082748
  },
  {
    "timestamp": "15/01/25 4:00",
    "demand": 35755.56758,
    "solar_generated": 0,
    "wind_generated": 63.30394299,
    "marketplace_to_demand": 72.70531173,
    "grid_to_demand": 32517.49681,
    "govt_battery_charge": 68.27960118
  },
  {
    "timestamp": "15/01/25 5:00",
    "demand": 36460.427,
    "solar_generated": 0,
    "wind_generated": 69.60756186,
    "marketplace_to_demand": 92.32258885,
    "grid_to_demand": 33243.11294,
    "govt_battery_charge": 96.00673576
  },
  {
    "timestamp": "15/01/25 6:00",
    "demand": 29967.93161,
    "solar_generated": 16.30504096,
    "wind_generated": 78.12209731,
    "marketplace_to_demand": 136.6800922,
    "grid_to_demand": 34897.89354,
    "govt_battery_charge": 32.16179111
  },
  {
    "timestamp": "15/01/25 7:00",
    "demand": 35393.77594,
    "solar_generated": 7.609769272,
    "wind_generated": 58.79171848,
    "marketplace_to_demand": 127.95477,
    "grid_to_demand": 33548.8603,
    "govt_battery_charge": 99.26855459
  },
  {
    "timestamp": "15/01/25 8:00",
    "demand": 34045.6314,
    "solar_generated": 7.456367803,
    "wind_generated": 55.93056664,
    "marketplace_to_demand": 163.7286037,
    "grid_to_demand": 36364.92667,
    "govt_battery_charge": 92.71676369
  },
  {
    "timestamp": "15/01/25 9:00",
    "demand": 32241.67574,
    "solar_generated": 14.76597212,
    "wind_generated": 54.49754706,
    "marketplace_to_demand": 160.6801765,
    "grid_to_demand": 31367.62542,
    "govt_battery_charge": 21.10164314
  },
  {
    "timestamp": "15/01/25 10:00",
    "demand": 33245.468,
    "solar_generated": 11.88147827,
    "wind_generated": 66.71572066,
    "marketplace_to_demand": 192.488404,
    "grid_to_demand": 31982.65994,
    "govt_battery_charge": 2.601814528
  },
  {
    "timestamp": "15/01/25 11:00",
    "demand": 33911.76152,
    "solar_generated": 17.04483272,
    "wind_generated": 55.78538757,
    "marketplace_to_demand": 174.1294381,
    "grid_to_demand": 30646.08354,
    "govt_battery_charge": 75.25995001
  },
  {
    "timestamp": "15/01/25 12:00",
    "demand": 31740.08079,
    "solar_generated": 12.92874454,
    "wind_generated": 53.84467813,
    "marketplace_to_demand": 89.52383734,
    "grid_to_demand": 35953.44193,
    "govt_battery_charge": 49.00273038
  },
  {
    "timestamp": "15/01/25 13:00",
    "demand": 33732.61542,
    "solar_generated": 15.36468408,
    "wind_generated": 55.0084733,
    "marketplace_to_demand": 53.05500417,
    "grid_to_demand": 33147.9929,
    "govt_battery_charge": 46.2791963
  },
  {
    "timestamp": "15/01/25 14:00",
    "demand": 35766.92347,
    "solar_generated": 9.625670964,
    "wind_generated": 70.24614981,
    "marketplace_to_demand": 191.7343188,
    "grid_to_demand": 31823.76369,
    "govt_battery_charge": 1.251860305
  },
  {
    "timestamp": "15/01/25 15:00",
    "demand": 35698.65266,
    "solar_generated": 7.458627532,
    "wind_generated": 56.59720589,
    "marketplace_to_demand": 68.49915746,
    "grid_to_demand": 31425.78402,
    "govt_battery_charge": 55.31722485
  },
  {
    "timestamp": "15/01/25 16:00",
    "demand": 32769.95128,
    "solar_generated": 22.21458203,
    "wind_generated": 55.24875207,
    "marketplace_to_demand": 132.7810931,
    "grid_to_demand": 34153.49128,
    "govt_battery_charge": 18.26651241
  },
  {
    "timestamp": "15/01/25 17:00",
    "demand": 34268.44445,
    "solar_generated": 15.22554111,
    "wind_generated": 58.32087205,
    "marketplace_to_demand": 191.6416142,
    "grid_to_demand": 32785.29642,
    "govt_battery_charge": 6.744454301
  },
  {
    "timestamp": "15/01/25 18:00",
    "demand": 30535.01699,
    "solar_generated": 0,
    "wind_generated": 59.72891064,
    "marketplace_to_demand": 37.25479837,
    "grid_to_demand": 33968.41946,
    "govt_battery_charge": 45.23538344
  },
  {
    "timestamp": "15/01/25 19:00",
    "demand": 35388.74156,
    "solar_generated": 0,
    "wind_generated": 76.80742286,
    "marketplace_to_demand": 98.32282003,
    "grid_to_demand": 34431.82194,
    "govt_battery_charge": 66.68579496
  },
  {
    "timestamp": "15/01/25 20:00",
    "demand": 30704.18982,
    "solar_generated": 0,
    "wind_generated": 76.87564397,
    "marketplace_to_demand": 127.1381177,
    "grid_to_demand": 31824.95351,
    "govt_battery_charge": 11.04938178
  },
  {
    "timestamp": "15/01/25 21:00",
    "demand": 32373.42581,
    "solar_generated": 0,
    "wind_generated": 79.97211457,
    "marketplace_to_demand": 130.098855,
    "grid_to_demand": 31025.67727,
    "govt_battery_charge": 3.372359743
  },
  {
    "timestamp": "15/01/25 22:00",
    "demand": 32802.66591,
    "solar_generated": 0,
    "wind_generated": 55.28224713,
    "marketplace_to_demand": 43.37258014,
    "grid_to_demand": 36223.21025,
    "govt_battery_charge": 59.76335411
  },
  {
    "timestamp": "15/01/25 23:00",
    "demand": 34108.30976,
    "solar_generated": 0,
    "wind_generated": 70.98726018,
    "marketplace_to_demand": 78.26209295,
    "grid_to_demand": 29929.46332,
    "govt_battery_charge": 47.93349895
  },
  {
    "timestamp": "16/01/25 0:00",
    "demand": 36569.21458,
    "solar_generated": 0,
    "wind_generated": 79.95232981,
    "marketplace_to_demand": 29.92041255,
    "grid_to_demand": 32212.97412,
    "govt_battery_charge": 90.28745347
  },
  {
    "timestamp": "16/01/25 1:00",
    "demand": 32964.4814,
    "solar_generated": 0,
    "wind_generated": 57.97610137,
    "marketplace_to_demand": 88.73771356,
    "grid_to_demand": 30604.35749,
    "govt_battery_charge": 45.84732287
  },
  {
    "timestamp": "16/01/25 2:00",
    "demand": 33820.18479,
    "solar_generated": 0,
    "wind_generated": 60.29345259,
    "marketplace_to_demand": 87.20760058,
    "grid_to_demand": 31948.53038,
    "govt_battery_charge": 63.74240358
  },
  {
    "timestamp": "16/01/25 3:00",
    "demand": 34067.77131,
    "solar_generated": 0,
    "wind_generated": 65.07421791,
    "marketplace_to_demand": 25.71693717,
    "grid_to_demand": 34742.53133,
    "govt_battery_charge": 17.21680849
  },
  {
    "timestamp": "16/01/25 4:00",
    "demand": 30865.60073,
    "solar_generated": 0,
    "wind_generated": 63.90418323,
    "marketplace_to_demand": 174.432869,
    "grid_to_demand": 34721.70031,
    "govt_battery_charge": 62.53880741
  },
  {
    "timestamp": "16/01/25 5:00",
    "demand": 31061.70318,
    "solar_generated": 0,
    "wind_generated": 54.31522119,
    "marketplace_to_demand": 72.55989554,
    "grid_to_demand": 33425.58975,
    "govt_battery_charge": 87.41494849
  },
  {
    "timestamp": "16/01/25 6:00",
    "demand": 33192.37577,
    "solar_generated": 23.57822737,
    "wind_generated": 68.24321851,
    "marketplace_to_demand": 159.8058401,
    "grid_to_demand": 34683.42833,
    "govt_battery_charge": 38.93444929
  },
  {
    "timestamp": "16/01/25 7:00",
    "demand": 33082.12998,
    "solar_generated": 18.94848613,
    "wind_generated": 64.5823155,
    "marketplace_to_demand": 150.8019642,
    "grid_to_demand": 30219.86013,
    "govt_battery_charge": 35.85308839
  },
  {
    "timestamp": "16/01/25 8:00",
    "demand": 30053.52872,
    "solar_generated": 7.936537084,
    "wind_generated": 68.79114329,
    "marketplace_to_demand": 199.1129332,
    "grid_to_demand": 36305.87082,
    "govt_battery_charge": 21.79171285
  },
  {
    "timestamp": "16/01/25 9:00",
    "demand": 34944.14673,
    "solar_generated": 7.28232936,
    "wind_generated": 79.66337972,
    "marketplace_to_demand": 141.834978,
    "grid_to_demand": 35678.59784,
    "govt_battery_charge": 47.54490459
  },
  {
    "timestamp": "16/01/25 10:00",
    "demand": 34926.29385,
    "solar_generated": 6.980225262,
    "wind_generated": 73.65152076,
    "marketplace_to_demand": 179.620838,
    "grid_to_demand": 30826.74082,
    "govt_battery_charge": 43.05432981
  },
  {
    "timestamp": "16/01/25 11:00",
    "demand": 31648.47332,
    "solar_generated": 14.59142686,
    "wind_generated": 63.47418353,
    "marketplace_to_demand": 71.00127293,
    "grid_to_demand": 32619.60556,
    "govt_battery_charge": 70.85499883
  },
  {
    "timestamp": "16/01/25 12:00",
    "demand": 35206.05352,
    "solar_generated": 22.14561521,
    "wind_generated": 66.60069095,
    "marketplace_to_demand": 65.42626919,
    "grid_to_demand": 32987.62249,
    "govt_battery_charge": 74.40086977
  },
  {
    "timestamp": "16/01/25 13:00",
    "demand": 33444.59699,
    "solar_generated": 13.38893084,
    "wind_generated": 73.97609144,
    "marketplace_to_demand": 174.7024111,
    "grid_to_demand": 35998.24265,
    "govt_battery_charge": 97.92551254
  },
  {
    "timestamp": "16/01/25 14:00",
    "demand": 30364.79459,
    "solar_generated": 7.988634203,
    "wind_generated": 63.38255881,
    "marketplace_to_demand": 26.89890371,
    "grid_to_demand": 35106.78371,
    "govt_battery_charge": 86.64878079
  },
  {
    "timestamp": "16/01/25 15:00",
    "demand": 33767.41167,
    "solar_generated": 12.17211778,
    "wind_generated": 53.70138765,
    "marketplace_to_demand": 126.3985635,
    "grid_to_demand": 33212.18134,
    "govt_battery_charge": 40.96949131
  },
  {
    "timestamp": "16/01/25 16:00",
    "demand": 34967.04372,
    "solar_generated": 17.37126263,
    "wind_generated": 56.65447853,
    "marketplace_to_demand": 176.7991946,
    "grid_to_demand": 31732.42995,
    "govt_battery_charge": 5.941368547
  },
  {
    "timestamp": "16/01/25 17:00",
    "demand": 32638.0636,
    "solar_generated": 7.856053213,
    "wind_generated": 77.13149456,
    "marketplace_to_demand": 63.44271672,
    "grid_to_demand": 29973.38311,
    "govt_battery_charge": 41.83494646
  },
  {
    "timestamp": "16/01/25 18:00",
    "demand": 34977.32705,
    "solar_generated": 0,
    "wind_generated": 63.17388182,
    "marketplace_to_demand": 39.76344404,
    "grid_to_demand": 31332.7916,
    "govt_battery_charge": 82.1082252
  },
  {
    "timestamp": "16/01/25 19:00",
    "demand": 35802.00552,
    "solar_generated": 0,
    "wind_generated": 73.08171115,
    "marketplace_to_demand": 114.8695054,
    "grid_to_demand": 34635.44049,
    "govt_battery_charge": 99.67085507
  },
  {
    "timestamp": "16/01/25 20:00",
    "demand": 33471.31941,
    "solar_generated": 0,
    "wind_generated": 62.81841958,
    "marketplace_to_demand": 129.8761438,
    "grid_to_demand": 35923.50745,
    "govt_battery_charge": 98.75340002
  },
  {
    "timestamp": "16/01/25 21:00",
    "demand": 31243.53899,
    "solar_generated": 0,
    "wind_generated": 53.90732581,
    "marketplace_to_demand": 199.768868,
    "grid_to_demand": 32981.45041,
    "govt_battery_charge": 87.2092367
  },
  {
    "timestamp": "16/01/25 22:00",
    "demand": 32923.07924,
    "solar_generated": 0,
    "wind_generated": 79.36254023,
    "marketplace_to_demand": 104.6084971,
    "grid_to_demand": 35101.22621,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "16/01/25 23:00",
    "demand": 30011.51037,
    "solar_generated": 0,
    "wind_generated": 58.62658167,
    "marketplace_to_demand": 157.8281802,
    "grid_to_demand": 31470.75426,
    "govt_battery_charge": 26.35240057
  },
  {
    "timestamp": "17/01/25 0:00",
    "demand": 34308.69829,
    "solar_generated": 0,
    "wind_generated": 72.78584654,
    "marketplace_to_demand": 71.46112804,
    "grid_to_demand": 31454.40389,
    "govt_battery_charge": 98.36203525
  },
  {
    "timestamp": "17/01/25 1:00",
    "demand": 31549.3897,
    "solar_generated": 0,
    "wind_generated": 69.90729167,
    "marketplace_to_demand": 145.3918986,
    "grid_to_demand": 35843.04258,
    "govt_battery_charge": 54.47664101
  },
  {
    "timestamp": "17/01/25 2:00",
    "demand": 33291.59915,
    "solar_generated": 0,
    "wind_generated": 75.28342988,
    "marketplace_to_demand": 53.75875891,
    "grid_to_demand": 31751.58862,
    "govt_battery_charge": 88.58996429
  },
  {
    "timestamp": "17/01/25 3:00",
    "demand": 31562.99299,
    "solar_generated": 0,
    "wind_generated": 76.23800457,
    "marketplace_to_demand": 41.79909295,
    "grid_to_demand": 33385.42651,
    "govt_battery_charge": 87.3345614
  },
  {
    "timestamp": "17/01/25 4:00",
    "demand": 33007.60418,
    "solar_generated": 0,
    "wind_generated": 56.45006458,
    "marketplace_to_demand": 15.55016992,
    "grid_to_demand": 32913.20962,
    "govt_battery_charge": 15.97999971
  },
  {
    "timestamp": "17/01/25 5:00",
    "demand": 29977.33504,
    "solar_generated": 0,
    "wind_generated": 76.56452824,
    "marketplace_to_demand": 94.39765538,
    "grid_to_demand": 31393.76144,
    "govt_battery_charge": 79.70764024
  },
  {
    "timestamp": "17/01/25 6:00",
    "demand": 31093.7765,
    "solar_generated": 17.80666998,
    "wind_generated": 57.86196471,
    "marketplace_to_demand": 118.5363819,
    "grid_to_demand": 31907.60194,
    "govt_battery_charge": 61.90785186
  },
  {
    "timestamp": "17/01/25 7:00",
    "demand": 34723.34652,
    "solar_generated": 21.6958753,
    "wind_generated": 70.48836401,
    "marketplace_to_demand": 148.5089785,
    "grid_to_demand": 35783.24772,
    "govt_battery_charge": 41.849278
  },
  {
    "timestamp": "17/01/25 8:00",
    "demand": 32768.57765,
    "solar_generated": 6.771545834,
    "wind_generated": 62.33237983,
    "marketplace_to_demand": 157.533799,
    "grid_to_demand": 29929.73098,
    "govt_battery_charge": 90.0934993
  },
  {
    "timestamp": "17/01/25 9:00",
    "demand": 36309.07255,
    "solar_generated": 13.57535393,
    "wind_generated": 55.46076977,
    "marketplace_to_demand": 79.39247354,
    "grid_to_demand": 35075.75275,
    "govt_battery_charge": 78.95068684
  },
  {
    "timestamp": "17/01/25 10:00",
    "demand": 30942.45631,
    "solar_generated": 21.82750248,
    "wind_generated": 62.38543951,
    "marketplace_to_demand": 110.5404628,
    "grid_to_demand": 34443.04076,
    "govt_battery_charge": 74.00779555
  },
  {
    "timestamp": "17/01/25 11:00",
    "demand": 30228.16485,
    "solar_generated": 8.427942895,
    "wind_generated": 54.02590956,
    "marketplace_to_demand": 20.30797221,
    "grid_to_demand": 34284.52606,
    "govt_battery_charge": 13.50798522
  },
  {
    "timestamp": "17/01/25 12:00",
    "demand": 35102.78663,
    "solar_generated": 17.7712316,
    "wind_generated": 68.60479984,
    "marketplace_to_demand": 115.3293187,
    "grid_to_demand": 34220.40633,
    "govt_battery_charge": 86.06574566
  },
  {
    "timestamp": "17/01/25 13:00",
    "demand": 30935.18928,
    "solar_generated": 7.399678196,
    "wind_generated": 59.49460611,
    "marketplace_to_demand": 147.2258411,
    "grid_to_demand": 30799.15246,
    "govt_battery_charge": 74.81876909
  },
  {
    "timestamp": "17/01/25 14:00",
    "demand": 36047.93245,
    "solar_generated": 18.23389306,
    "wind_generated": 71.85334941,
    "marketplace_to_demand": 134.401751,
    "grid_to_demand": 34359.38255,
    "govt_battery_charge": 24.06801459
  },
  {
    "timestamp": "17/01/25 15:00",
    "demand": 32395.9664,
    "solar_generated": 16.68460816,
    "wind_generated": 66.24912825,
    "marketplace_to_demand": 73.39000774,
    "grid_to_demand": 30130.46335,
    "govt_battery_charge": 62.57230278
  },
  {
    "timestamp": "17/01/25 16:00",
    "demand": 35276.68426,
    "solar_generated": 20.33236881,
    "wind_generated": 64.38382144,
    "marketplace_to_demand": 115.2007317,
    "grid_to_demand": 34023.84915,
    "govt_battery_charge": 24.08833076
  },
  {
    "timestamp": "17/01/25 17:00",
    "demand": 35750.96324,
    "solar_generated": 9.270611803,
    "wind_generated": 55.82692801,
    "marketplace_to_demand": 138.1627211,
    "grid_to_demand": 32340.52417,
    "govt_battery_charge": 31.51236109
  },
  {
    "timestamp": "17/01/25 18:00",
    "demand": 35124.69862,
    "solar_generated": 0,
    "wind_generated": 76.62761794,
    "marketplace_to_demand": 110.9796627,
    "grid_to_demand": 35751.37414,
    "govt_battery_charge": 24.11276776
  },
  {
    "timestamp": "17/01/25 19:00",
    "demand": 31959.73206,
    "solar_generated": 0,
    "wind_generated": 79.09525985,
    "marketplace_to_demand": 69.88520025,
    "grid_to_demand": 30549.19664,
    "govt_battery_charge": 91.26631565
  },
  {
    "timestamp": "17/01/25 20:00",
    "demand": 30711.90018,
    "solar_generated": 0,
    "wind_generated": 57.84643175,
    "marketplace_to_demand": 70.9515387,
    "grid_to_demand": 32838.05202,
    "govt_battery_charge": 58.02379153
  },
  {
    "timestamp": "17/01/25 21:00",
    "demand": 31978.43195,
    "solar_generated": 0,
    "wind_generated": 60.8165209,
    "marketplace_to_demand": 41.1115954,
    "grid_to_demand": 31581.04039,
    "govt_battery_charge": 1.945550843
  },
  {
    "timestamp": "17/01/25 22:00",
    "demand": 30874.25723,
    "solar_generated": 0,
    "wind_generated": 79.91623634,
    "marketplace_to_demand": 99.47429639,
    "grid_to_demand": 31102.05327,
    "govt_battery_charge": 85.06585204
  },
  {
    "timestamp": "17/01/25 23:00",
    "demand": 32374.378,
    "solar_generated": 0,
    "wind_generated": 55.80965475,
    "marketplace_to_demand": 19.96643769,
    "grid_to_demand": 34568.48612,
    "govt_battery_charge": 89.75442518
  },
  {
    "timestamp": "18/01/25 0:00",
    "demand": 32086.78739,
    "solar_generated": 0,
    "wind_generated": 79.81958568,
    "marketplace_to_demand": 33.73807176,
    "grid_to_demand": 34856.39494,
    "govt_battery_charge": 69.31490563
  },
  {
    "timestamp": "18/01/25 1:00",
    "demand": 31185.66716,
    "solar_generated": 0,
    "wind_generated": 65.41135292,
    "marketplace_to_demand": 198.7483001,
    "grid_to_demand": 34521.06141,
    "govt_battery_charge": 39.30489869
  },
  {
    "timestamp": "18/01/25 2:00",
    "demand": 36525.26945,
    "solar_generated": 0,
    "wind_generated": 61.21349501,
    "marketplace_to_demand": 64.14041852,
    "grid_to_demand": 31422.95723,
    "govt_battery_charge": 52.92276672
  },
  {
    "timestamp": "18/01/25 3:00",
    "demand": 34785.09885,
    "solar_generated": 0,
    "wind_generated": 66.76512872,
    "marketplace_to_demand": 55.71858443,
    "grid_to_demand": 34197.32396,
    "govt_battery_charge": 92.20604133
  },
  {
    "timestamp": "18/01/25 4:00",
    "demand": 35470.44283,
    "solar_generated": 0,
    "wind_generated": 68.61432947,
    "marketplace_to_demand": 95.54526957,
    "grid_to_demand": 34306.26813,
    "govt_battery_charge": 91.48067001
  },
  {
    "timestamp": "18/01/25 5:00",
    "demand": 30104.20137,
    "solar_generated": 0,
    "wind_generated": 67.35397472,
    "marketplace_to_demand": 71.85480821,
    "grid_to_demand": 34938.99157,
    "govt_battery_charge": 75.932526
  },
  {
    "timestamp": "18/01/25 6:00",
    "demand": 34450.86088,
    "solar_generated": 11.964352,
    "wind_generated": 75.62780073,
    "marketplace_to_demand": 112.8242345,
    "grid_to_demand": 33228.58303,
    "govt_battery_charge": 84.92766697
  },
  {
    "timestamp": "18/01/25 7:00",
    "demand": 33552.81761,
    "solar_generated": 17.67718115,
    "wind_generated": 70.22230068,
    "marketplace_to_demand": 151.1763421,
    "grid_to_demand": 30340.97379,
    "govt_battery_charge": 71.83331868
  },
  {
    "timestamp": "18/01/25 8:00",
    "demand": 30812.80139,
    "solar_generated": 8.464409526,
    "wind_generated": 66.96593625,
    "marketplace_to_demand": 164.7415367,
    "grid_to_demand": 34511.64905,
    "govt_battery_charge": 42.04570217
  },
  {
    "timestamp": "18/01/25 9:00",
    "demand": 32803.18216,
    "solar_generated": 9.529130325,
    "wind_generated": 55.13509843,
    "marketplace_to_demand": 61.09828348,
    "grid_to_demand": 29938.89836,
    "govt_battery_charge": 82.19569657
  },
  {
    "timestamp": "18/01/25 10:00",
    "demand": 31612.36588,
    "solar_generated": 10.78988491,
    "wind_generated": 59.75911494,
    "marketplace_to_demand": 147.444362,
    "grid_to_demand": 35447.19596,
    "govt_battery_charge": 43.42579353
  },
  {
    "timestamp": "18/01/25 11:00",
    "demand": 30530.04585,
    "solar_generated": 17.29503828,
    "wind_generated": 64.11645667,
    "marketplace_to_demand": 194.7333948,
    "grid_to_demand": 30685.8315,
    "govt_battery_charge": 38.10028427
  },
  {
    "timestamp": "18/01/25 12:00",
    "demand": 33991.88808,
    "solar_generated": 8.273379243,
    "wind_generated": 57.02110516,
    "marketplace_to_demand": 127.2327935,
    "grid_to_demand": 30396.03131,
    "govt_battery_charge": 15.54587244
  },
  {
    "timestamp": "18/01/25 13:00",
    "demand": 36037.73132,
    "solar_generated": 9.043237447,
    "wind_generated": 56.67736282,
    "marketplace_to_demand": 119.6567803,
    "grid_to_demand": 31599.86237,
    "govt_battery_charge": 56.29913039
  },
  {
    "timestamp": "18/01/25 14:00",
    "demand": 35189.72613,
    "solar_generated": 7.141235601,
    "wind_generated": 58.82609256,
    "marketplace_to_demand": 111.6543569,
    "grid_to_demand": 30855.67475,
    "govt_battery_charge": 39.38472644
  },
  {
    "timestamp": "18/01/25 15:00",
    "demand": 35221.92876,
    "solar_generated": 9.008563501,
    "wind_generated": 55.44406447,
    "marketplace_to_demand": 102.4219265,
    "grid_to_demand": 36348.83846,
    "govt_battery_charge": 93.97924075
  },
  {
    "timestamp": "18/01/25 16:00",
    "demand": 31608.89691,
    "solar_generated": 18.05484471,
    "wind_generated": 78.25138122,
    "marketplace_to_demand": 72.62256146,
    "grid_to_demand": 34063.26106,
    "govt_battery_charge": 84.90492093
  },
  {
    "timestamp": "18/01/25 17:00",
    "demand": 35829.55161,
    "solar_generated": 22.1738554,
    "wind_generated": 54.85705659,
    "marketplace_to_demand": 140.7107757,
    "grid_to_demand": 36496.36192,
    "govt_battery_charge": 67.01514139
  },
  {
    "timestamp": "18/01/25 18:00",
    "demand": 34026.90997,
    "solar_generated": 0,
    "wind_generated": 61.39221202,
    "marketplace_to_demand": 96.20204163,
    "grid_to_demand": 30521.09531,
    "govt_battery_charge": 1.947846803
  },
  {
    "timestamp": "18/01/25 19:00",
    "demand": 30318.21602,
    "solar_generated": 0,
    "wind_generated": 70.81074777,
    "marketplace_to_demand": 169.2108746,
    "grid_to_demand": 32898.03088,
    "govt_battery_charge": 55.80531943
  },
  {
    "timestamp": "18/01/25 20:00",
    "demand": 36032.27096,
    "solar_generated": 0,
    "wind_generated": 60.89734055,
    "marketplace_to_demand": 172.5379169,
    "grid_to_demand": 30950.21573,
    "govt_battery_charge": 7.903311336
  },
  {
    "timestamp": "18/01/25 21:00",
    "demand": 35816.99429,
    "solar_generated": 0,
    "wind_generated": 57.33658956,
    "marketplace_to_demand": 64.39308797,
    "grid_to_demand": 35172.651,
    "govt_battery_charge": 46.6902195
  },
  {
    "timestamp": "18/01/25 22:00",
    "demand": 36324.03681,
    "solar_generated": 0,
    "wind_generated": 59.54660271,
    "marketplace_to_demand": 166.621205,
    "grid_to_demand": 36435.19274,
    "govt_battery_charge": 50.71240359
  },
  {
    "timestamp": "18/01/25 23:00",
    "demand": 32229.87094,
    "solar_generated": 0,
    "wind_generated": 70.35645026,
    "marketplace_to_demand": 14.48935866,
    "grid_to_demand": 32206.19502,
    "govt_battery_charge": 32.20023275
  },
  {
    "timestamp": "19/01/25 0:00",
    "demand": 32507.90893,
    "solar_generated": 0,
    "wind_generated": 74.23294875,
    "marketplace_to_demand": 9.007399457,
    "grid_to_demand": 33614.44222,
    "govt_battery_charge": 57.61316028
  },
  {
    "timestamp": "19/01/25 1:00",
    "demand": 31352.92624,
    "solar_generated": 0,
    "wind_generated": 74.89725954,
    "marketplace_to_demand": 89.78654425,
    "grid_to_demand": 32564.90437,
    "govt_battery_charge": 76.69043854
  },
  {
    "timestamp": "19/01/25 2:00",
    "demand": 34439.72605,
    "solar_generated": 0,
    "wind_generated": 60.39693453,
    "marketplace_to_demand": 155.5520347,
    "grid_to_demand": 29960.99004,
    "govt_battery_charge": 47.81574629
  },
  {
    "timestamp": "19/01/25 3:00",
    "demand": 33291.2491,
    "solar_generated": 0,
    "wind_generated": 56.23031916,
    "marketplace_to_demand": 68.89442569,
    "grid_to_demand": 32388.49572,
    "govt_battery_charge": 70.16566555
  },
  {
    "timestamp": "19/01/25 4:00",
    "demand": 33264.02359,
    "solar_generated": 0,
    "wind_generated": 71.44233943,
    "marketplace_to_demand": 101.5813165,
    "grid_to_demand": 35422.65434,
    "govt_battery_charge": 26.36043139
  },
  {
    "timestamp": "19/01/25 5:00",
    "demand": 33806.96319,
    "solar_generated": 0,
    "wind_generated": 74.70435474,
    "marketplace_to_demand": 190.7803618,
    "grid_to_demand": 34764.18987,
    "govt_battery_charge": 95.58369549
  },
  {
    "timestamp": "19/01/25 6:00",
    "demand": 35794.33816,
    "solar_generated": 19.59474565,
    "wind_generated": 55.71918757,
    "marketplace_to_demand": 163.4474353,
    "grid_to_demand": 30862.41678,
    "govt_battery_charge": 93.83918329
  },
  {
    "timestamp": "19/01/25 7:00",
    "demand": 31737.4995,
    "solar_generated": 7.522462119,
    "wind_generated": 76.20798129,
    "marketplace_to_demand": 165.5607638,
    "grid_to_demand": 34866.43777,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "19/01/25 8:00",
    "demand": 34619.50104,
    "solar_generated": 8.238323561,
    "wind_generated": 63.80201288,
    "marketplace_to_demand": 88.18316785,
    "grid_to_demand": 32521.95014,
    "govt_battery_charge": 15.14689711
  },
  {
    "timestamp": "19/01/25 9:00",
    "demand": 36424.16898,
    "solar_generated": 13.56870622,
    "wind_generated": 58.50666781,
    "marketplace_to_demand": 56.86694311,
    "grid_to_demand": 31391.7681,
    "govt_battery_charge": 63.97918356
  },
  {
    "timestamp": "19/01/25 10:00",
    "demand": 34046.2974,
    "solar_generated": 6.207050142,
    "wind_generated": 57.8409361,
    "marketplace_to_demand": 176.279436,
    "grid_to_demand": 32731.35481,
    "govt_battery_charge": 68.34152177
  },
  {
    "timestamp": "19/01/25 11:00",
    "demand": 33007.52377,
    "solar_generated": 21.92557511,
    "wind_generated": 68.65635069,
    "marketplace_to_demand": 195.3499613,
    "grid_to_demand": 34862.88789,
    "govt_battery_charge": 61.0305217
  },
  {
    "timestamp": "19/01/25 12:00",
    "demand": 36070.30972,
    "solar_generated": 11.70344222,
    "wind_generated": 56.15222969,
    "marketplace_to_demand": 6.999402641,
    "grid_to_demand": 30543.7027,
    "govt_battery_charge": 49.53342543
  },
  {
    "timestamp": "19/01/25 13:00",
    "demand": 34432.82523,
    "solar_generated": 12.91486158,
    "wind_generated": 67.8963612,
    "marketplace_to_demand": 115.259396,
    "grid_to_demand": 33981.65153,
    "govt_battery_charge": 25.94269899
  },
  {
    "timestamp": "19/01/25 14:00",
    "demand": 33596.48792,
    "solar_generated": 13.41234807,
    "wind_generated": 57.92964808,
    "marketplace_to_demand": 160.8788556,
    "grid_to_demand": 31806.26962,
    "govt_battery_charge": 77.67443615
  },
  {
    "timestamp": "19/01/25 15:00",
    "demand": 33409.42589,
    "solar_generated": 12.57356023,
    "wind_generated": 74.2360212,
    "marketplace_to_demand": 30.36460459,
    "grid_to_demand": 34059.87089,
    "govt_battery_charge": 45.40701689
  },
  {
    "timestamp": "19/01/25 16:00",
    "demand": 34391.34614,
    "solar_generated": 18.71169442,
    "wind_generated": 54.13461189,
    "marketplace_to_demand": 107.432518,
    "grid_to_demand": 33135.59305,
    "govt_battery_charge": 36.43428797
  },
  {
    "timestamp": "19/01/25 17:00",
    "demand": 32657.75015,
    "solar_generated": 18.15043225,
    "wind_generated": 55.707756,
    "marketplace_to_demand": 57.11694109,
    "grid_to_demand": 35214.98525,
    "govt_battery_charge": 57.3289873
  },
  {
    "timestamp": "19/01/25 18:00",
    "demand": 35023.10679,
    "solar_generated": 0,
    "wind_generated": 75.100024,
    "marketplace_to_demand": 10.81194754,
    "grid_to_demand": 36447.98749,
    "govt_battery_charge": 49.49735602
  },
  {
    "timestamp": "19/01/25 19:00",
    "demand": 34039.73039,
    "solar_generated": 0,
    "wind_generated": 63.99439267,
    "marketplace_to_demand": 130.712456,
    "grid_to_demand": 32294.97346,
    "govt_battery_charge": 62.6442685
  },
  {
    "timestamp": "19/01/25 20:00",
    "demand": 33243.81394,
    "solar_generated": 0,
    "wind_generated": 80.06311168,
    "marketplace_to_demand": 66.68853678,
    "grid_to_demand": 34403.369,
    "govt_battery_charge": 49.12023691
  },
  {
    "timestamp": "19/01/25 21:00",
    "demand": 34276.0993,
    "solar_generated": 0,
    "wind_generated": 72.11380387,
    "marketplace_to_demand": 149.5989642,
    "grid_to_demand": 31218.14243,
    "govt_battery_charge": 14.46002253
  },
  {
    "timestamp": "19/01/25 22:00",
    "demand": 34267.34546,
    "solar_generated": 0,
    "wind_generated": 67.22688046,
    "marketplace_to_demand": 18.07416474,
    "grid_to_demand": 31496.36061,
    "govt_battery_charge": 50.95024251
  },
  {
    "timestamp": "19/01/25 23:00",
    "demand": 31115.52536,
    "solar_generated": 0,
    "wind_generated": 62.28915527,
    "marketplace_to_demand": 116.2899981,
    "grid_to_demand": 33721.48158,
    "govt_battery_charge": 38.10034838
  },
  {
    "timestamp": "20/01/25 0:00",
    "demand": 33373.73072,
    "solar_generated": 0,
    "wind_generated": 76.61967174,
    "marketplace_to_demand": 107.5840875,
    "grid_to_demand": 32851.97949,
    "govt_battery_charge": 39.92645495
  },
  {
    "timestamp": "20/01/25 1:00",
    "demand": 33776.8586,
    "solar_generated": 0,
    "wind_generated": 67.22460893,
    "marketplace_to_demand": 185.0153036,
    "grid_to_demand": 34978.12337,
    "govt_battery_charge": 36.3178256
  },
  {
    "timestamp": "20/01/25 2:00",
    "demand": 31728.88356,
    "solar_generated": 0,
    "wind_generated": 67.00684807,
    "marketplace_to_demand": 17.19208378,
    "grid_to_demand": 34642.86552,
    "govt_battery_charge": 95.35240168
  },
  {
    "timestamp": "20/01/25 3:00",
    "demand": 30127.12461,
    "solar_generated": 0,
    "wind_generated": 59.76812433,
    "marketplace_to_demand": 116.5096036,
    "grid_to_demand": 29950.73702,
    "govt_battery_charge": 93.32316146
  },
  {
    "timestamp": "20/01/25 4:00",
    "demand": 32001.21634,
    "solar_generated": 0,
    "wind_generated": 57.43395864,
    "marketplace_to_demand": 21.70540829,
    "grid_to_demand": 31534.85273,
    "govt_battery_charge": 45.60662426
  },
  {
    "timestamp": "20/01/25 5:00",
    "demand": 35030.38289,
    "solar_generated": 0,
    "wind_generated": 68.43546296,
    "marketplace_to_demand": 198.9837315,
    "grid_to_demand": 31012.45866,
    "govt_battery_charge": 72.20565075
  },
  {
    "timestamp": "20/01/25 6:00",
    "demand": 33824.47763,
    "solar_generated": 14.13616073,
    "wind_generated": 67.43012556,
    "marketplace_to_demand": 96.05253509,
    "grid_to_demand": 33435.62818,
    "govt_battery_charge": 17.0976213
  },
  {
    "timestamp": "20/01/25 7:00",
    "demand": 35840.89402,
    "solar_generated": 6.249785705,
    "wind_generated": 77.03095303,
    "marketplace_to_demand": 194.8841976,
    "grid_to_demand": 35550.4067,
    "govt_battery_charge": 53.12811975
  },
  {
    "timestamp": "20/01/25 8:00",
    "demand": 31624.94622,
    "solar_generated": 14.52177651,
    "wind_generated": 74.8060162,
    "marketplace_to_demand": 102.2695453,
    "grid_to_demand": 32295.12682,
    "govt_battery_charge": 25.04921564
  },
  {
    "timestamp": "20/01/25 9:00",
    "demand": 31222.33253,
    "solar_generated": 6.976179016,
    "wind_generated": 68.17189341,
    "marketplace_to_demand": 92.39990041,
    "grid_to_demand": 35636.21589,
    "govt_battery_charge": 65.48247328
  },
  {
    "timestamp": "20/01/25 10:00",
    "demand": 33097.40968,
    "solar_generated": 18.78212392,
    "wind_generated": 78.49094752,
    "marketplace_to_demand": 191.844656,
    "grid_to_demand": 32592.7788,
    "govt_battery_charge": 45.60808401
  },
  {
    "timestamp": "20/01/25 11:00",
    "demand": 34300.30759,
    "solar_generated": 9.854645028,
    "wind_generated": 75.81531191,
    "marketplace_to_demand": 95.04296584,
    "grid_to_demand": 36098.3859,
    "govt_battery_charge": 39.97230517
  },
  {
    "timestamp": "20/01/25 12:00",
    "demand": 34758.70039,
    "solar_generated": 12.8823934,
    "wind_generated": 67.39204613,
    "marketplace_to_demand": 140.5098109,
    "grid_to_demand": 31260.3138,
    "govt_battery_charge": 26.42033036
  },
  {
    "timestamp": "20/01/25 13:00",
    "demand": 35900.15774,
    "solar_generated": 15.3746422,
    "wind_generated": 65.35394628,
    "marketplace_to_demand": 198.1152218,
    "grid_to_demand": 33796.91256,
    "govt_battery_charge": 39.73768622
  },
  {
    "timestamp": "20/01/25 14:00",
    "demand": 31121.33705,
    "solar_generated": 7.129499609,
    "wind_generated": 61.33155105,
    "marketplace_to_demand": 179.8758249,
    "grid_to_demand": 30651.7722,
    "govt_battery_charge": 48.02757748
  },
  {
    "timestamp": "20/01/25 15:00",
    "demand": 34270.69667,
    "solar_generated": 21.05143414,
    "wind_generated": 57.40812645,
    "marketplace_to_demand": 119.8832857,
    "grid_to_demand": 33489.17097,
    "govt_battery_charge": 4.939816453
  },
  {
    "timestamp": "20/01/25 16:00",
    "demand": 32435.13057,
    "solar_generated": 7.607289893,
    "wind_generated": 79.24008436,
    "marketplace_to_demand": 32.74890438,
    "grid_to_demand": 31432.36619,
    "govt_battery_charge": 94.77536791
  },
  {
    "timestamp": "20/01/25 17:00",
    "demand": 30931.90572,
    "solar_generated": 8.576725564,
    "wind_generated": 78.7508648,
    "marketplace_to_demand": 132.1238324,
    "grid_to_demand": 32565.31689,
    "govt_battery_charge": 43.22462737
  },
  {
    "timestamp": "20/01/25 18:00",
    "demand": 34279.56032,
    "solar_generated": 0,
    "wind_generated": 68.32314047,
    "marketplace_to_demand": 196.0440707,
    "grid_to_demand": 30011.04448,
    "govt_battery_charge": 87.92250818
  },
  {
    "timestamp": "20/01/25 19:00",
    "demand": 30385.09679,
    "solar_generated": 0,
    "wind_generated": 59.94349689,
    "marketplace_to_demand": 154.8727028,
    "grid_to_demand": 34317.27429,
    "govt_battery_charge": 86.33588464
  },
  {
    "timestamp": "20/01/25 20:00",
    "demand": 34638.59744,
    "solar_generated": 0,
    "wind_generated": 56.4432388,
    "marketplace_to_demand": 110.6496603,
    "grid_to_demand": 34063.5418,
    "govt_battery_charge": 12.03264226
  },
  {
    "timestamp": "20/01/25 21:00",
    "demand": 33177.6716,
    "solar_generated": 0,
    "wind_generated": 74.44137939,
    "marketplace_to_demand": 141.3231152,
    "grid_to_demand": 31998.37646,
    "govt_battery_charge": 57.59713816
  },
  {
    "timestamp": "20/01/25 22:00",
    "demand": 30524.1597,
    "solar_generated": 0,
    "wind_generated": 78.4646866,
    "marketplace_to_demand": 171.0381761,
    "grid_to_demand": 32017.99879,
    "govt_battery_charge": 33.42765006
  },
  {
    "timestamp": "20/01/25 23:00",
    "demand": 35476.9555,
    "solar_generated": 0,
    "wind_generated": 70.82362967,
    "marketplace_to_demand": 50.05055834,
    "grid_to_demand": 35107.78198,
    "govt_battery_charge": 24.72985903
  },
  {
    "timestamp": "21/01/25 0:00",
    "demand": 30237.35312,
    "solar_generated": 0,
    "wind_generated": 79.88405805,
    "marketplace_to_demand": 179.5556806,
    "grid_to_demand": 32448.67938,
    "govt_battery_charge": 22.03806996
  },
  {
    "timestamp": "21/01/25 1:00",
    "demand": 36190.47329,
    "solar_generated": 0,
    "wind_generated": 70.94935219,
    "marketplace_to_demand": 14.07632546,
    "grid_to_demand": 34150.79905,
    "govt_battery_charge": 15.60803553
  },
  {
    "timestamp": "21/01/25 2:00",
    "demand": 36542.42885,
    "solar_generated": 0,
    "wind_generated": 65.77965581,
    "marketplace_to_demand": 189.8532072,
    "grid_to_demand": 33507.75113,
    "govt_battery_charge": 18.39487067
  },
  {
    "timestamp": "21/01/25 3:00",
    "demand": 30225.09522,
    "solar_generated": 0,
    "wind_generated": 78.70958484,
    "marketplace_to_demand": 107.4375958,
    "grid_to_demand": 30247.43332,
    "govt_battery_charge": 74.12587759
  },
  {
    "timestamp": "21/01/25 4:00",
    "demand": 34926.34851,
    "solar_generated": 0,
    "wind_generated": 71.636778,
    "marketplace_to_demand": 113.7156729,
    "grid_to_demand": 36093.42318,
    "govt_battery_charge": 90.05896185
  },
  {
    "timestamp": "21/01/25 5:00",
    "demand": 32341.84952,
    "solar_generated": 0,
    "wind_generated": 64.13458596,
    "marketplace_to_demand": 94.85943298,
    "grid_to_demand": 32373.65065,
    "govt_battery_charge": 78.11171933
  },
  {
    "timestamp": "21/01/25 6:00",
    "demand": 31681.31137,
    "solar_generated": 23.84592076,
    "wind_generated": 69.2940236,
    "marketplace_to_demand": 154.606059,
    "grid_to_demand": 30511.19838,
    "govt_battery_charge": 32.87952883
  },
  {
    "timestamp": "21/01/25 7:00",
    "demand": 33864.81481,
    "solar_generated": 19.78020751,
    "wind_generated": 71.33777306,
    "marketplace_to_demand": 85.23406341,
    "grid_to_demand": 31673.22119,
    "govt_battery_charge": 74.31495282
  },
  {
    "timestamp": "21/01/25 8:00",
    "demand": 31535.66795,
    "solar_generated": 12.74289147,
    "wind_generated": 64.13420886,
    "marketplace_to_demand": 97.04975115,
    "grid_to_demand": 32461.91982,
    "govt_battery_charge": 11.4553719
  },
  {
    "timestamp": "21/01/25 9:00",
    "demand": 35267.2656,
    "solar_generated": 11.33320278,
    "wind_generated": 58.46326142,
    "marketplace_to_demand": 51.10195667,
    "grid_to_demand": 30153.68745,
    "govt_battery_charge": 65.40503637
  },
  {
    "timestamp": "21/01/25 10:00",
    "demand": 33373.59252,
    "solar_generated": 21.56199866,
    "wind_generated": 71.24774187,
    "marketplace_to_demand": 141.3887777,
    "grid_to_demand": 34949.37339,
    "govt_battery_charge": 19.50701166
  },
  {
    "timestamp": "21/01/25 11:00",
    "demand": 32300.7524,
    "solar_generated": 8.239044419,
    "wind_generated": 74.41904607,
    "marketplace_to_demand": 188.9696725,
    "grid_to_demand": 34999.85237,
    "govt_battery_charge": 63.73350507
  },
  {
    "timestamp": "21/01/25 12:00",
    "demand": 36056.08052,
    "solar_generated": 17.06051295,
    "wind_generated": 55.15739592,
    "marketplace_to_demand": 192.1316612,
    "grid_to_demand": 33029.55135,
    "govt_battery_charge": 84.72106081
  },
  {
    "timestamp": "21/01/25 13:00",
    "demand": 30039.38898,
    "solar_generated": 12.94305767,
    "wind_generated": 70.0557398,
    "marketplace_to_demand": 144.9964536,
    "grid_to_demand": 34104.66083,
    "govt_battery_charge": 1.683158995
  },
  {
    "timestamp": "21/01/25 14:00",
    "demand": 34091.22908,
    "solar_generated": 6.984849637,
    "wind_generated": 61.195211,
    "marketplace_to_demand": 15.56776829,
    "grid_to_demand": 36345.45336,
    "govt_battery_charge": 54.35641953
  },
  {
    "timestamp": "21/01/25 15:00",
    "demand": 31952.88953,
    "solar_generated": 23.03339248,
    "wind_generated": 54.38054073,
    "marketplace_to_demand": 91.85949727,
    "grid_to_demand": 33079.64946,
    "govt_battery_charge": 78.19853317
  },
  {
    "timestamp": "21/01/25 16:00",
    "demand": 34811.53807,
    "solar_generated": 15.81677268,
    "wind_generated": 76.21985959,
    "marketplace_to_demand": 178.9505665,
    "grid_to_demand": 31512.28925,
    "govt_battery_charge": 25.51800539
  },
  {
    "timestamp": "21/01/25 17:00",
    "demand": 36334.83612,
    "solar_generated": 20.46034282,
    "wind_generated": 65.19034687,
    "marketplace_to_demand": 177.0608141,
    "grid_to_demand": 30955.48463,
    "govt_battery_charge": 77.16981858
  },
  {
    "timestamp": "21/01/25 18:00",
    "demand": 31185.63723,
    "solar_generated": 0,
    "wind_generated": 54.72121444,
    "marketplace_to_demand": 179.0173367,
    "grid_to_demand": 36458.58209,
    "govt_battery_charge": 2.992991757
  },
  {
    "timestamp": "21/01/25 19:00",
    "demand": 29995.45048,
    "solar_generated": 0,
    "wind_generated": 73.87215186,
    "marketplace_to_demand": 179.3565009,
    "grid_to_demand": 30568.04719,
    "govt_battery_charge": 60.00853892
  },
  {
    "timestamp": "21/01/25 20:00",
    "demand": 31632.00153,
    "solar_generated": 0,
    "wind_generated": 71.92672325,
    "marketplace_to_demand": 123.4639537,
    "grid_to_demand": 34468.34622,
    "govt_battery_charge": 93.61651615
  },
  {
    "timestamp": "21/01/25 21:00",
    "demand": 34843.44224,
    "solar_generated": 0,
    "wind_generated": 61.40884987,
    "marketplace_to_demand": 43.93489338,
    "grid_to_demand": 32625.72602,
    "govt_battery_charge": 84.76416765
  },
  {
    "timestamp": "21/01/25 22:00",
    "demand": 30630.05207,
    "solar_generated": 0,
    "wind_generated": 56.46112618,
    "marketplace_to_demand": 83.34690417,
    "grid_to_demand": 32013.55372,
    "govt_battery_charge": 43.7361399
  },
  {
    "timestamp": "21/01/25 23:00",
    "demand": 35021.55491,
    "solar_generated": 0,
    "wind_generated": 73.64843799,
    "marketplace_to_demand": 148.7308574,
    "grid_to_demand": 35374.02132,
    "govt_battery_charge": 55.68015023
  },
  {
    "timestamp": "22/01/25 0:00",
    "demand": 31945.05078,
    "solar_generated": 0,
    "wind_generated": 74.29813047,
    "marketplace_to_demand": 26.43714399,
    "grid_to_demand": 35172.60254,
    "govt_battery_charge": 38.597266
  },
  {
    "timestamp": "22/01/25 1:00",
    "demand": 31784.52472,
    "solar_generated": 0,
    "wind_generated": 57.24146428,
    "marketplace_to_demand": 145.0277796,
    "grid_to_demand": 34362.74298,
    "govt_battery_charge": 68.27907253
  },
  {
    "timestamp": "22/01/25 2:00",
    "demand": 35565.35765,
    "solar_generated": 0,
    "wind_generated": 61.61959843,
    "marketplace_to_demand": 2.838903197,
    "grid_to_demand": 32784.1473,
    "govt_battery_charge": 21.00390815
  },
  {
    "timestamp": "22/01/25 3:00",
    "demand": 31540.70044,
    "solar_generated": 0,
    "wind_generated": 56.96233217,
    "marketplace_to_demand": 94.24126247,
    "grid_to_demand": 31708.36431,
    "govt_battery_charge": 8.048201353
  },
  {
    "timestamp": "22/01/25 4:00",
    "demand": 30986.62928,
    "solar_generated": 0,
    "wind_generated": 66.98232532,
    "marketplace_to_demand": 195.2036102,
    "grid_to_demand": 36416.34633,
    "govt_battery_charge": 14.12308005
  },
  {
    "timestamp": "22/01/25 5:00",
    "demand": 32795.28662,
    "solar_generated": 0,
    "wind_generated": 60.95298384,
    "marketplace_to_demand": 145.7679475,
    "grid_to_demand": 30489.67907,
    "govt_battery_charge": 93.91089912
  },
  {
    "timestamp": "22/01/25 6:00",
    "demand": 34175.67556,
    "solar_generated": 14.8668674,
    "wind_generated": 77.42947489,
    "marketplace_to_demand": 171.9463617,
    "grid_to_demand": 30094.22891,
    "govt_battery_charge": 73.85365316
  },
  {
    "timestamp": "22/01/25 7:00",
    "demand": 35570.4847,
    "solar_generated": 20.80882317,
    "wind_generated": 63.34458968,
    "marketplace_to_demand": 75.87232038,
    "grid_to_demand": 32088.9613,
    "govt_battery_charge": 86.91906273
  },
  {
    "timestamp": "22/01/25 8:00",
    "demand": 32274.95876,
    "solar_generated": 17.0032711,
    "wind_generated": 65.15645766,
    "marketplace_to_demand": 182.975655,
    "grid_to_demand": 32270.8371,
    "govt_battery_charge": 42.28453328
  },
  {
    "timestamp": "22/01/25 9:00",
    "demand": 32672.39072,
    "solar_generated": 11.73602191,
    "wind_generated": 65.35704581,
    "marketplace_to_demand": 148.7207855,
    "grid_to_demand": 30336.89782,
    "govt_battery_charge": 70.95531465
  },
  {
    "timestamp": "22/01/25 10:00",
    "demand": 36196.06467,
    "solar_generated": 6.551234779,
    "wind_generated": 55.88789424,
    "marketplace_to_demand": 49.1341132,
    "grid_to_demand": 33169.57023,
    "govt_battery_charge": 14.76857841
  },
  {
    "timestamp": "22/01/25 11:00",
    "demand": 32953.07532,
    "solar_generated": 17.7925447,
    "wind_generated": 57.23182457,
    "marketplace_to_demand": 40.48126429,
    "grid_to_demand": 31450.95032,
    "govt_battery_charge": 7.247951806
  },
  {
    "timestamp": "22/01/25 12:00",
    "demand": 36456.14871,
    "solar_generated": 21.78824961,
    "wind_generated": 73.8174203,
    "marketplace_to_demand": 178.7828227,
    "grid_to_demand": 30010.21799,
    "govt_battery_charge": 76.82144389
  },
  {
    "timestamp": "22/01/25 13:00",
    "demand": 33319.03918,
    "solar_generated": 14.013255,
    "wind_generated": 58.22481409,
    "marketplace_to_demand": 90.75095212,
    "grid_to_demand": 32080.69374,
    "govt_battery_charge": 72.64265449
  },
  {
    "timestamp": "22/01/25 14:00",
    "demand": 34395.25013,
    "solar_generated": 19.8567723,
    "wind_generated": 64.36218547,
    "marketplace_to_demand": 108.2609166,
    "grid_to_demand": 33494.1487,
    "govt_battery_charge": 94.85942932
  },
  {
    "timestamp": "22/01/25 15:00",
    "demand": 33507.90941,
    "solar_generated": 12.02542403,
    "wind_generated": 73.24012561,
    "marketplace_to_demand": 65.06742754,
    "grid_to_demand": 33295.86055,
    "govt_battery_charge": 67.66672295
  },
  {
    "timestamp": "22/01/25 16:00",
    "demand": 32704.7107,
    "solar_generated": 12.75511921,
    "wind_generated": 66.16849766,
    "marketplace_to_demand": 178.7723606,
    "grid_to_demand": 32158.15779,
    "govt_battery_charge": 83.3948754
  },
  {
    "timestamp": "22/01/25 17:00",
    "demand": 34333.36843,
    "solar_generated": 20.97967491,
    "wind_generated": 67.14650243,
    "marketplace_to_demand": 10.18716876,
    "grid_to_demand": 36138.49452,
    "govt_battery_charge": 48.76555588
  },
  {
    "timestamp": "22/01/25 18:00",
    "demand": 32463.06019,
    "solar_generated": 0,
    "wind_generated": 66.26427981,
    "marketplace_to_demand": 122.67892,
    "grid_to_demand": 35474.83902,
    "govt_battery_charge": 30.33024751
  },
  {
    "timestamp": "22/01/25 19:00",
    "demand": 30965.86683,
    "solar_generated": 0,
    "wind_generated": 53.65228799,
    "marketplace_to_demand": 60.34458946,
    "grid_to_demand": 34709.71685,
    "govt_battery_charge": 38.15639185
  },
  {
    "timestamp": "22/01/25 20:00",
    "demand": 36264.19674,
    "solar_generated": 0,
    "wind_generated": 73.96364128,
    "marketplace_to_demand": 140.0194586,
    "grid_to_demand": 30355.75504,
    "govt_battery_charge": 38.88806338
  },
  {
    "timestamp": "22/01/25 21:00",
    "demand": 31104.53592,
    "solar_generated": 0,
    "wind_generated": 58.77822002,
    "marketplace_to_demand": 116.0247631,
    "grid_to_demand": 35810.42156,
    "govt_battery_charge": 44.89403132
  },
  {
    "timestamp": "22/01/25 22:00",
    "demand": 33361.37312,
    "solar_generated": 0,
    "wind_generated": 70.32553741,
    "marketplace_to_demand": 128.9457856,
    "grid_to_demand": 32156.94824,
    "govt_battery_charge": 86.30448138
  },
  {
    "timestamp": "22/01/25 23:00",
    "demand": 32668.60066,
    "solar_generated": 0,
    "wind_generated": 57.69876653,
    "marketplace_to_demand": 143.7079949,
    "grid_to_demand": 35532.53611,
    "govt_battery_charge": 82.65838162
  },
  {
    "timestamp": "23/01/25 0:00",
    "demand": 31309.78336,
    "solar_generated": 0,
    "wind_generated": 75.68833763,
    "marketplace_to_demand": 96.71808678,
    "grid_to_demand": 32606.36503,
    "govt_battery_charge": 79.9447284
  },
  {
    "timestamp": "23/01/25 1:00",
    "demand": 31008.89023,
    "solar_generated": 0,
    "wind_generated": 79.61016162,
    "marketplace_to_demand": 146.095619,
    "grid_to_demand": 32284.41059,
    "govt_battery_charge": 9.94174105
  },
  {
    "timestamp": "23/01/25 2:00",
    "demand": 31202.22653,
    "solar_generated": 0,
    "wind_generated": 63.02411584,
    "marketplace_to_demand": 58.29718633,
    "grid_to_demand": 32278.55903,
    "govt_battery_charge": 10.10975902
  },
  {
    "timestamp": "23/01/25 3:00",
    "demand": 34866.32426,
    "solar_generated": 0,
    "wind_generated": 79.65064898,
    "marketplace_to_demand": 102.9330544,
    "grid_to_demand": 30519.02122,
    "govt_battery_charge": 13.06315648
  },
  {
    "timestamp": "23/01/25 4:00",
    "demand": 34074.29316,
    "solar_generated": 0,
    "wind_generated": 57.57141233,
    "marketplace_to_demand": 161.5767546,
    "grid_to_demand": 31301.9358,
    "govt_battery_charge": 60.47618952
  },
  {
    "timestamp": "23/01/25 5:00",
    "demand": 30989.75339,
    "solar_generated": 0,
    "wind_generated": 65.7984681,
    "marketplace_to_demand": 62.89149054,
    "grid_to_demand": 34463.2132,
    "govt_battery_charge": 88.31485678
  },
  {
    "timestamp": "23/01/25 6:00",
    "demand": 32250.64884,
    "solar_generated": 11.14969441,
    "wind_generated": 72.70978157,
    "marketplace_to_demand": 60.24147335,
    "grid_to_demand": 35493.41801,
    "govt_battery_charge": 54.77570758
  },
  {
    "timestamp": "23/01/25 7:00",
    "demand": 32763.7111,
    "solar_generated": 17.31255169,
    "wind_generated": 56.54902782,
    "marketplace_to_demand": 174.4798773,
    "grid_to_demand": 33210.21562,
    "govt_battery_charge": 62.37498004
  },
  {
    "timestamp": "23/01/25 8:00",
    "demand": 36067.39246,
    "solar_generated": 14.30392863,
    "wind_generated": 79.25424105,
    "marketplace_to_demand": 82.73063154,
    "grid_to_demand": 33757.67099,
    "govt_battery_charge": 86.45810861
  },
  {
    "timestamp": "23/01/25 9:00",
    "demand": 30464.76834,
    "solar_generated": 16.04497787,
    "wind_generated": 63.75500961,
    "marketplace_to_demand": 56.81548225,
    "grid_to_demand": 34998.19673,
    "govt_battery_charge": 10.73674203
  },
  {
    "timestamp": "23/01/25 10:00",
    "demand": 35334.94573,
    "solar_generated": 9.295693482,
    "wind_generated": 75.03568942,
    "marketplace_to_demand": 121.9200018,
    "grid_to_demand": 35832.67876,
    "govt_battery_charge": 49.50930575
  },
  {
    "timestamp": "23/01/25 11:00",
    "demand": 32202.84268,
    "solar_generated": 9.115057433,
    "wind_generated": 58.93142953,
    "marketplace_to_demand": 142.8158812,
    "grid_to_demand": 32484.50027,
    "govt_battery_charge": 63.35308178
  },
  {
    "timestamp": "23/01/25 12:00",
    "demand": 35358.88208,
    "solar_generated": 13.06706606,
    "wind_generated": 67.80619267,
    "marketplace_to_demand": 14.20083056,
    "grid_to_demand": 34842.78136,
    "govt_battery_charge": 30.0188687
  },
  {
    "timestamp": "23/01/25 13:00",
    "demand": 35198.17525,
    "solar_generated": 6.865785965,
    "wind_generated": 67.44820578,
    "marketplace_to_demand": 154.9269953,
    "grid_to_demand": 31850.28258,
    "govt_battery_charge": 59.73265159
  },
  {
    "timestamp": "23/01/25 14:00",
    "demand": 33166.58133,
    "solar_generated": 10.3697538,
    "wind_generated": 59.93611582,
    "marketplace_to_demand": 45.79170208,
    "grid_to_demand": 34806.93756,
    "govt_battery_charge": 9.05914405
  },
  {
    "timestamp": "23/01/25 15:00",
    "demand": 30573.97384,
    "solar_generated": 12.72956051,
    "wind_generated": 62.46784604,
    "marketplace_to_demand": 61.7194758,
    "grid_to_demand": 34036.29558,
    "govt_battery_charge": 11.71493817
  },
  {
    "timestamp": "23/01/25 16:00",
    "demand": 33234.52178,
    "solar_generated": 11.07285744,
    "wind_generated": 70.19215242,
    "marketplace_to_demand": 53.09942854,
    "grid_to_demand": 35019.9738,
    "govt_battery_charge": 18.34462742
  },
  {
    "timestamp": "23/01/25 17:00",
    "demand": 33310.60038,
    "solar_generated": 20.541636,
    "wind_generated": 62.58095744,
    "marketplace_to_demand": 54.39284808,
    "grid_to_demand": 34861.52136,
    "govt_battery_charge": 98.01402169
  },
  {
    "timestamp": "23/01/25 18:00",
    "demand": 32376.70274,
    "solar_generated": 0,
    "wind_generated": 65.26663028,
    "marketplace_to_demand": 52.13469629,
    "grid_to_demand": 33102.45993,
    "govt_battery_charge": 24.45775222
  },
  {
    "timestamp": "23/01/25 19:00",
    "demand": 34931.17996,
    "solar_generated": 0,
    "wind_generated": 77.98425379,
    "marketplace_to_demand": 195.7718133,
    "grid_to_demand": 35870.76189,
    "govt_battery_charge": 47.15721004
  },
  {
    "timestamp": "23/01/25 20:00",
    "demand": 34555.05566,
    "solar_generated": 0,
    "wind_generated": 69.99923213,
    "marketplace_to_demand": 31.35648654,
    "grid_to_demand": 36104.32511,
    "govt_battery_charge": 42.64098889
  },
  {
    "timestamp": "23/01/25 21:00",
    "demand": 34002.51813,
    "solar_generated": 0,
    "wind_generated": 68.06412368,
    "marketplace_to_demand": 31.65831796,
    "grid_to_demand": 31689.74773,
    "govt_battery_charge": 21.85871952
  },
  {
    "timestamp": "23/01/25 22:00",
    "demand": 30385.25262,
    "solar_generated": 0,
    "wind_generated": 74.90888855,
    "marketplace_to_demand": 26.16075051,
    "grid_to_demand": 35647.20903,
    "govt_battery_charge": 60.6214183
  },
  {
    "timestamp": "23/01/25 23:00",
    "demand": 35081.11031,
    "solar_generated": 0,
    "wind_generated": 54.75303891,
    "marketplace_to_demand": 32.61794963,
    "grid_to_demand": 35947.02816,
    "govt_battery_charge": 80.31260715
  },
  {
    "timestamp": "24/01/25 0:00",
    "demand": 30959.76182,
    "solar_generated": 0,
    "wind_generated": 66.58221772,
    "marketplace_to_demand": 80.37934454,
    "grid_to_demand": 32957.95146,
    "govt_battery_charge": 53.56577622
  },
  {
    "timestamp": "24/01/25 1:00",
    "demand": 30984.38527,
    "solar_generated": 0,
    "wind_generated": 64.67406904,
    "marketplace_to_demand": 70.98924533,
    "grid_to_demand": 36041.57504,
    "govt_battery_charge": 52.83403637
  },
  {
    "timestamp": "24/01/25 2:00",
    "demand": 32354.09296,
    "solar_generated": 0,
    "wind_generated": 62.1621723,
    "marketplace_to_demand": 110.4412519,
    "grid_to_demand": 30770.06031,
    "govt_battery_charge": 78.80642582
  },
  {
    "timestamp": "24/01/25 3:00",
    "demand": 32764.74041,
    "solar_generated": 0,
    "wind_generated": 77.94655559,
    "marketplace_to_demand": 45.03942051,
    "grid_to_demand": 30498.67681,
    "govt_battery_charge": 24.31968521
  },
  {
    "timestamp": "24/01/25 4:00",
    "demand": 34472.1993,
    "solar_generated": 0,
    "wind_generated": 65.08315477,
    "marketplace_to_demand": 14.32110528,
    "grid_to_demand": 33769.70091,
    "govt_battery_charge": 18.96979448
  },
  {
    "timestamp": "24/01/25 5:00",
    "demand": 36534.0388,
    "solar_generated": 0,
    "wind_generated": 69.54420386,
    "marketplace_to_demand": 128.2603113,
    "grid_to_demand": 33553.86411,
    "govt_battery_charge": 93.21911517
  },
  {
    "timestamp": "24/01/25 6:00",
    "demand": 34792.76993,
    "solar_generated": 8.436429071,
    "wind_generated": 70.79741482,
    "marketplace_to_demand": 179.2207912,
    "grid_to_demand": 32623.17829,
    "govt_battery_charge": 24.98280621
  },
  {
    "timestamp": "24/01/25 7:00",
    "demand": 35084.75845,
    "solar_generated": 15.15142088,
    "wind_generated": 54.13140817,
    "marketplace_to_demand": 40.88852636,
    "grid_to_demand": 35754.41001,
    "govt_battery_charge": 98.60680412
  },
  {
    "timestamp": "24/01/25 8:00",
    "demand": 30054.77048,
    "solar_generated": 12.43873251,
    "wind_generated": 66.75722871,
    "marketplace_to_demand": 80.43690955,
    "grid_to_demand": 30893.57586,
    "govt_battery_charge": 22.99071828
  },
  {
    "timestamp": "24/01/25 9:00",
    "demand": 33130.34118,
    "solar_generated": 21.25556135,
    "wind_generated": 67.38647488,
    "marketplace_to_demand": 59.88320236,
    "grid_to_demand": 32179.86258,
    "govt_battery_charge": 5.888692385
  },
  {
    "timestamp": "24/01/25 10:00",
    "demand": 36078.48816,
    "solar_generated": 6.63303989,
    "wind_generated": 66.29431894,
    "marketplace_to_demand": 28.29380689,
    "grid_to_demand": 36305.98877,
    "govt_battery_charge": 18.50972064
  },
  {
    "timestamp": "24/01/25 11:00",
    "demand": 32315.50209,
    "solar_generated": 20.98363454,
    "wind_generated": 68.39092302,
    "marketplace_to_demand": 7.452674948,
    "grid_to_demand": 31502.02608,
    "govt_battery_charge": 80.12568355
  },
  {
    "timestamp": "24/01/25 12:00",
    "demand": 30170.6277,
    "solar_generated": 19.78890148,
    "wind_generated": 74.15245247,
    "marketplace_to_demand": 1.083615377,
    "grid_to_demand": 34082.91441,
    "govt_battery_charge": 85.61974205
  },
  {
    "timestamp": "24/01/25 13:00",
    "demand": 30662.0712,
    "solar_generated": 22.52444462,
    "wind_generated": 54.37491129,
    "marketplace_to_demand": 104.5242556,
    "grid_to_demand": 30711.00864,
    "govt_battery_charge": 73.21093426
  },
  {
    "timestamp": "24/01/25 14:00",
    "demand": 35312.82974,
    "solar_generated": 8.915769546,
    "wind_generated": 68.03071286,
    "marketplace_to_demand": 56.42128399,
    "grid_to_demand": 33074.21184,
    "govt_battery_charge": 29.91869204
  },
  {
    "timestamp": "24/01/25 15:00",
    "demand": 34946.61452,
    "solar_generated": 22.28306073,
    "wind_generated": 80.16532638,
    "marketplace_to_demand": 135.4714397,
    "grid_to_demand": 34292.80686,
    "govt_battery_charge": 72.30230254
  },
  {
    "timestamp": "24/01/25 16:00",
    "demand": 35896.34077,
    "solar_generated": 11.75514791,
    "wind_generated": 66.34155718,
    "marketplace_to_demand": 101.281959,
    "grid_to_demand": 33843.42284,
    "govt_battery_charge": 44.10595083
  },
  {
    "timestamp": "24/01/25 17:00",
    "demand": 36189.19799,
    "solar_generated": 14.9048838,
    "wind_generated": 77.10562869,
    "marketplace_to_demand": 37.46701025,
    "grid_to_demand": 33715.67675,
    "govt_battery_charge": 66.62584698
  },
  {
    "timestamp": "24/01/25 18:00",
    "demand": 32462.83364,
    "solar_generated": 0,
    "wind_generated": 64.49225867,
    "marketplace_to_demand": 13.20865569,
    "grid_to_demand": 30055.24116,
    "govt_battery_charge": 59.77900075
  },
  {
    "timestamp": "24/01/25 19:00",
    "demand": 33363.54446,
    "solar_generated": 0,
    "wind_generated": 77.92242921,
    "marketplace_to_demand": 17.37931764,
    "grid_to_demand": 30920.30503,
    "govt_battery_charge": 41.7508039
  },
  {
    "timestamp": "24/01/25 20:00",
    "demand": 35852.4843,
    "solar_generated": 0,
    "wind_generated": 61.8214683,
    "marketplace_to_demand": 183.5140802,
    "grid_to_demand": 35910.58205,
    "govt_battery_charge": 83.1353418
  },
  {
    "timestamp": "24/01/25 21:00",
    "demand": 33636.99571,
    "solar_generated": 0,
    "wind_generated": 56.2155762,
    "marketplace_to_demand": 53.47614754,
    "grid_to_demand": 32242.48312,
    "govt_battery_charge": 50.79171301
  },
  {
    "timestamp": "24/01/25 22:00",
    "demand": 34648.33162,
    "solar_generated": 0,
    "wind_generated": 76.37675958,
    "marketplace_to_demand": 89.93839383,
    "grid_to_demand": 34038.08438,
    "govt_battery_charge": 75.73394513
  },
  {
    "timestamp": "24/01/25 23:00",
    "demand": 31077.07093,
    "solar_generated": 0,
    "wind_generated": 78.08546897,
    "marketplace_to_demand": 33.3323828,
    "grid_to_demand": 36326.19901,
    "govt_battery_charge": 43.14701722
  },
  {
    "timestamp": "25/01/25 0:00",
    "demand": 30073.58478,
    "solar_generated": 0,
    "wind_generated": 74.01808391,
    "marketplace_to_demand": 111.7513371,
    "grid_to_demand": 31430.36536,
    "govt_battery_charge": 42.92269662
  },
  {
    "timestamp": "25/01/25 1:00",
    "demand": 32235.11294,
    "solar_generated": 0,
    "wind_generated": 58.75241333,
    "marketplace_to_demand": 97.14464098,
    "grid_to_demand": 31683.92101,
    "govt_battery_charge": 18.4994523
  },
  {
    "timestamp": "25/01/25 2:00",
    "demand": 33843.41948,
    "solar_generated": 0,
    "wind_generated": 67.98392695,
    "marketplace_to_demand": 89.82296144,
    "grid_to_demand": 35694.78307,
    "govt_battery_charge": 44.65763309
  },
  {
    "timestamp": "25/01/25 3:00",
    "demand": 33852.88606,
    "solar_generated": 0,
    "wind_generated": 54.71966871,
    "marketplace_to_demand": 171.164777,
    "grid_to_demand": 35433.22334,
    "govt_battery_charge": 42.53461458
  },
  {
    "timestamp": "25/01/25 4:00",
    "demand": 30392.766,
    "solar_generated": 0,
    "wind_generated": 67.54747686,
    "marketplace_to_demand": 96.3279672,
    "grid_to_demand": 30603.95703,
    "govt_battery_charge": 77.71607612
  },
  {
    "timestamp": "25/01/25 5:00",
    "demand": 36126.39284,
    "solar_generated": 0,
    "wind_generated": 56.50245771,
    "marketplace_to_demand": 179.8041183,
    "grid_to_demand": 30311.15699,
    "govt_battery_charge": 49.25747063
  },
  {
    "timestamp": "25/01/25 6:00",
    "demand": 32085.02236,
    "solar_generated": 20.8059505,
    "wind_generated": 62.69041113,
    "marketplace_to_demand": 85.0055077,
    "grid_to_demand": 33664.25902,
    "govt_battery_charge": 11.37632265
  },
  {
    "timestamp": "25/01/25 7:00",
    "demand": 31813.77108,
    "solar_generated": 13.97701641,
    "wind_generated": 58.59713342,
    "marketplace_to_demand": 38.4215616,
    "grid_to_demand": 33024.70889,
    "govt_battery_charge": 25.48967496
  },
  {
    "timestamp": "25/01/25 8:00",
    "demand": 31005.93924,
    "solar_generated": 23.03754372,
    "wind_generated": 58.50961462,
    "marketplace_to_demand": 199.814918,
    "grid_to_demand": 34459.00069,
    "govt_battery_charge": 20.03153133
  },
  {
    "timestamp": "25/01/25 9:00",
    "demand": 34492.6136,
    "solar_generated": 18.65659755,
    "wind_generated": 59.77895301,
    "marketplace_to_demand": 168.0977261,
    "grid_to_demand": 34676.62633,
    "govt_battery_charge": 44.57868409
  },
  {
    "timestamp": "25/01/25 10:00",
    "demand": 33845.78339,
    "solar_generated": 20.54375913,
    "wind_generated": 67.73416033,
    "marketplace_to_demand": 30.0807675,
    "grid_to_demand": 32141.84923,
    "govt_battery_charge": 48.25076766
  },
  {
    "timestamp": "25/01/25 11:00",
    "demand": 32967.64618,
    "solar_generated": 10.98824575,
    "wind_generated": 63.3825055,
    "marketplace_to_demand": 113.2267202,
    "grid_to_demand": 33993.10775,
    "govt_battery_charge": 77.49641852
  },
  {
    "timestamp": "25/01/25 12:00",
    "demand": 36166.45288,
    "solar_generated": 15.90945364,
    "wind_generated": 55.38986165,
    "marketplace_to_demand": 140.3890697,
    "grid_to_demand": 36543.75084,
    "govt_battery_charge": 70.47779292
  },
  {
    "timestamp": "25/01/25 13:00",
    "demand": 30943.9937,
    "solar_generated": 7.37771992,
    "wind_generated": 76.45222833,
    "marketplace_to_demand": 108.00883,
    "grid_to_demand": 31169.70305,
    "govt_battery_charge": 43.0017437
  },
  {
    "timestamp": "25/01/25 14:00",
    "demand": 30131.66376,
    "solar_generated": 12.57413448,
    "wind_generated": 65.69487782,
    "marketplace_to_demand": 177.292571,
    "grid_to_demand": 35551.66277,
    "govt_battery_charge": 72.02273958
  },
  {
    "timestamp": "25/01/25 15:00",
    "demand": 32133.5796,
    "solar_generated": 11.31164138,
    "wind_generated": 73.6513336,
    "marketplace_to_demand": 34.63693719,
    "grid_to_demand": 36042.62735,
    "govt_battery_charge": 9.80926442
  },
  {
    "timestamp": "25/01/25 16:00",
    "demand": 34179.84491,
    "solar_generated": 18.41979938,
    "wind_generated": 61.61809041,
    "marketplace_to_demand": 118.9296943,
    "grid_to_demand": 30063.04249,
    "govt_battery_charge": 90.76755559
  },
  {
    "timestamp": "25/01/25 17:00",
    "demand": 34979.12255,
    "solar_generated": 8.290685915,
    "wind_generated": 55.52411995,
    "marketplace_to_demand": 96.74602673,
    "grid_to_demand": 34676.01416,
    "govt_battery_charge": 27.52185982
  },
  {
    "timestamp": "25/01/25 18:00",
    "demand": 36506.7004,
    "solar_generated": 0,
    "wind_generated": 66.85547246,
    "marketplace_to_demand": 140.0546036,
    "grid_to_demand": 36203.84482,
    "govt_battery_charge": 67.64681502
  },
  {
    "timestamp": "25/01/25 19:00",
    "demand": 33419.44496,
    "solar_generated": 0,
    "wind_generated": 71.21748464,
    "marketplace_to_demand": 89.10998251,
    "grid_to_demand": 35322.3263,
    "govt_battery_charge": 26.19638246
  },
  {
    "timestamp": "25/01/25 20:00",
    "demand": 31638.53646,
    "solar_generated": 0,
    "wind_generated": 79.13845657,
    "marketplace_to_demand": 193.310328,
    "grid_to_demand": 35858.75479,
    "govt_battery_charge": 65.32503892
  },
  {
    "timestamp": "25/01/25 21:00",
    "demand": 36501.32019,
    "solar_generated": 0,
    "wind_generated": 60.66197279,
    "marketplace_to_demand": 144.4776554,
    "grid_to_demand": 32007.54143,
    "govt_battery_charge": 28.43231381
  },
  {
    "timestamp": "25/01/25 22:00",
    "demand": 32642.19537,
    "solar_generated": 0,
    "wind_generated": 62.06674318,
    "marketplace_to_demand": 70.89154376,
    "grid_to_demand": 33226.72356,
    "govt_battery_charge": 23.96476201
  },
  {
    "timestamp": "25/01/25 23:00",
    "demand": 31157.04059,
    "solar_generated": 0,
    "wind_generated": 74.55012276,
    "marketplace_to_demand": 37.82932803,
    "grid_to_demand": 32161.50156,
    "govt_battery_charge": 72.28009623
  },
  {
    "timestamp": "26/01/25 0:00",
    "demand": 34490.22179,
    "solar_generated": 0,
    "wind_generated": 73.79239624,
    "marketplace_to_demand": 78.81039376,
    "grid_to_demand": 31136.59015,
    "govt_battery_charge": 19.62502865
  },
  {
    "timestamp": "26/01/25 1:00",
    "demand": 35260.32661,
    "solar_generated": 0,
    "wind_generated": 77.0271389,
    "marketplace_to_demand": 0.063345919,
    "grid_to_demand": 31435.24836,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "26/01/25 2:00",
    "demand": 34216.78161,
    "solar_generated": 0,
    "wind_generated": 53.91512389,
    "marketplace_to_demand": 22.68886747,
    "grid_to_demand": 33609.90544,
    "govt_battery_charge": 61.06297451
  },
  {
    "timestamp": "26/01/25 3:00",
    "demand": 31642.45982,
    "solar_generated": 0,
    "wind_generated": 73.70668262,
    "marketplace_to_demand": 112.3037916,
    "grid_to_demand": 32571.92121,
    "govt_battery_charge": 59.92593638
  },
  {
    "timestamp": "26/01/25 4:00",
    "demand": 31321.01373,
    "solar_generated": 0,
    "wind_generated": 61.58664012,
    "marketplace_to_demand": 160.4766371,
    "grid_to_demand": 31228.72288,
    "govt_battery_charge": 87.49005986
  },
  {
    "timestamp": "26/01/25 5:00",
    "demand": 35547.5921,
    "solar_generated": 0,
    "wind_generated": 70.57454991,
    "marketplace_to_demand": 182.7384914,
    "grid_to_demand": 34632.39603,
    "govt_battery_charge": 65.41561068
  },
  {
    "timestamp": "26/01/25 6:00",
    "demand": 33251.50852,
    "solar_generated": 19.70921036,
    "wind_generated": 67.04179248,
    "marketplace_to_demand": 48.62824991,
    "grid_to_demand": 36335.65529,
    "govt_battery_charge": 79.94820317
  },
  {
    "timestamp": "26/01/25 7:00",
    "demand": 32120.06001,
    "solar_generated": 20.69754557,
    "wind_generated": 71.28544035,
    "marketplace_to_demand": 59.44428297,
    "grid_to_demand": 33536.17914,
    "govt_battery_charge": 77.60702902
  },
  {
    "timestamp": "26/01/25 8:00",
    "demand": 36280.69168,
    "solar_generated": 20.32511766,
    "wind_generated": 73.9760311,
    "marketplace_to_demand": 167.9432777,
    "grid_to_demand": 30588.94076,
    "govt_battery_charge": 77.34693916
  },
  {
    "timestamp": "26/01/25 9:00",
    "demand": 34624.22097,
    "solar_generated": 8.161783802,
    "wind_generated": 74.86880069,
    "marketplace_to_demand": 104.8957451,
    "grid_to_demand": 30061.97185,
    "govt_battery_charge": 2.883053558
  },
  {
    "timestamp": "26/01/25 10:00",
    "demand": 36273.09194,
    "solar_generated": 19.15302594,
    "wind_generated": 70.13680716,
    "marketplace_to_demand": 4.893015824,
    "grid_to_demand": 35990.81146,
    "govt_battery_charge": 29.54996083
  },
  {
    "timestamp": "26/01/25 11:00",
    "demand": 31965.57923,
    "solar_generated": 17.63462833,
    "wind_generated": 64.19338546,
    "marketplace_to_demand": 44.95863608,
    "grid_to_demand": 31323.11148,
    "govt_battery_charge": 7.737506041
  },
  {
    "timestamp": "26/01/25 12:00",
    "demand": 31818.86334,
    "solar_generated": 8.677092806,
    "wind_generated": 59.24049241,
    "marketplace_to_demand": 142.1502972,
    "grid_to_demand": 35028.44444,
    "govt_battery_charge": 66.51923665
  },
  {
    "timestamp": "26/01/25 13:00",
    "demand": 34516.33958,
    "solar_generated": 12.97455635,
    "wind_generated": 73.60875931,
    "marketplace_to_demand": 22.5744207,
    "grid_to_demand": 34849.87256,
    "govt_battery_charge": 31.76037966
  },
  {
    "timestamp": "26/01/25 14:00",
    "demand": 34050.16964,
    "solar_generated": 23.16200444,
    "wind_generated": 63.10685642,
    "marketplace_to_demand": 16.89639946,
    "grid_to_demand": 34700.97492,
    "govt_battery_charge": 58.97792464
  },
  {
    "timestamp": "26/01/25 15:00",
    "demand": 33412.01924,
    "solar_generated": 7.309927007,
    "wind_generated": 58.49391086,
    "marketplace_to_demand": 122.4114991,
    "grid_to_demand": 32336.59134,
    "govt_battery_charge": 65.15253291
  },
  {
    "timestamp": "26/01/25 16:00",
    "demand": 31043.15388,
    "solar_generated": 17.19359702,
    "wind_generated": 59.42221809,
    "marketplace_to_demand": 177.277656,
    "grid_to_demand": 29945.4104,
    "govt_battery_charge": 32.58898499
  },
  {
    "timestamp": "26/01/25 17:00",
    "demand": 36480.23185,
    "solar_generated": 15.41211854,
    "wind_generated": 74.29253297,
    "marketplace_to_demand": 186.6998896,
    "grid_to_demand": 32758.62344,
    "govt_battery_charge": 94.88932576
  },
  {
    "timestamp": "26/01/25 18:00",
    "demand": 31127.1015,
    "solar_generated": 0,
    "wind_generated": 53.93190544,
    "marketplace_to_demand": 137.3281876,
    "grid_to_demand": 36226.10789,
    "govt_battery_charge": 74.05986939
  },
  {
    "timestamp": "26/01/25 19:00",
    "demand": 31408.52113,
    "solar_generated": 0,
    "wind_generated": 78.18263107,
    "marketplace_to_demand": 50.81572145,
    "grid_to_demand": 35406.54019,
    "govt_battery_charge": 58.8503452
  },
  {
    "timestamp": "26/01/25 20:00",
    "demand": 35152.2223,
    "solar_generated": 0,
    "wind_generated": 73.09173165,
    "marketplace_to_demand": 6.362095722,
    "grid_to_demand": 32433.27922,
    "govt_battery_charge": 10.41670691
  },
  {
    "timestamp": "26/01/25 21:00",
    "demand": 34854.13444,
    "solar_generated": 0,
    "wind_generated": 64.85555482,
    "marketplace_to_demand": 65.1193056,
    "grid_to_demand": 31937.54106,
    "govt_battery_charge": 1.919575679
  },
  {
    "timestamp": "26/01/25 22:00",
    "demand": 31434.56666,
    "solar_generated": 0,
    "wind_generated": 59.74602542,
    "marketplace_to_demand": 85.87223292,
    "grid_to_demand": 32984.00656,
    "govt_battery_charge": 44.54597589
  },
  {
    "timestamp": "26/01/25 23:00",
    "demand": 33668.35694,
    "solar_generated": 0,
    "wind_generated": 70.3247268,
    "marketplace_to_demand": 171.5751258,
    "grid_to_demand": 32528.68925,
    "govt_battery_charge": 91.46402887
  },
  {
    "timestamp": "27/01/25 0:00",
    "demand": 30545.60092,
    "solar_generated": 0,
    "wind_generated": 61.83111862,
    "marketplace_to_demand": 144.9370376,
    "grid_to_demand": 31292.50475,
    "govt_battery_charge": 83.06872159
  },
  {
    "timestamp": "27/01/25 1:00",
    "demand": 35129.79667,
    "solar_generated": 0,
    "wind_generated": 78.81308604,
    "marketplace_to_demand": 101.3262681,
    "grid_to_demand": 33227.91775,
    "govt_battery_charge": 72.62038317
  },
  {
    "timestamp": "27/01/25 2:00",
    "demand": 32921.26373,
    "solar_generated": 0,
    "wind_generated": 77.01895659,
    "marketplace_to_demand": 156.1006414,
    "grid_to_demand": 34794.90422,
    "govt_battery_charge": 31.44565798
  },
  {
    "timestamp": "27/01/25 3:00",
    "demand": 30155.75605,
    "solar_generated": 0,
    "wind_generated": 66.94166179,
    "marketplace_to_demand": 13.77871995,
    "grid_to_demand": 32936.32815,
    "govt_battery_charge": 20.35711896
  },
  {
    "timestamp": "27/01/25 4:00",
    "demand": 33914.5588,
    "solar_generated": 0,
    "wind_generated": 56.82043525,
    "marketplace_to_demand": 66.71611974,
    "grid_to_demand": 30689.16855,
    "govt_battery_charge": 46.30548991
  },
  {
    "timestamp": "27/01/25 5:00",
    "demand": 33928.43939,
    "solar_generated": 0,
    "wind_generated": 76.08814204,
    "marketplace_to_demand": 118.0876478,
    "grid_to_demand": 31238.09819,
    "govt_battery_charge": 46.98592121
  },
  {
    "timestamp": "27/01/25 6:00",
    "demand": 34343.05174,
    "solar_generated": 12.43821942,
    "wind_generated": 56.16049625,
    "marketplace_to_demand": 72.31623099,
    "grid_to_demand": 34638.53598,
    "govt_battery_charge": 42.61119511
  },
  {
    "timestamp": "27/01/25 7:00",
    "demand": 32982.23848,
    "solar_generated": 11.75455064,
    "wind_generated": 69.48493377,
    "marketplace_to_demand": 36.58530874,
    "grid_to_demand": 31920.39794,
    "govt_battery_charge": 70.31140482
  },
  {
    "timestamp": "27/01/25 8:00",
    "demand": 32572.25929,
    "solar_generated": 12.33668676,
    "wind_generated": 75.0953616,
    "marketplace_to_demand": 6.264501086,
    "grid_to_demand": 35128.67235,
    "govt_battery_charge": 23.08650296
  },
  {
    "timestamp": "27/01/25 9:00",
    "demand": 36241.14837,
    "solar_generated": 16.88419416,
    "wind_generated": 56.43056537,
    "marketplace_to_demand": 69.4473037,
    "grid_to_demand": 35934.49849,
    "govt_battery_charge": 72.23237257
  },
  {
    "timestamp": "27/01/25 10:00",
    "demand": 35068.07956,
    "solar_generated": 23.94272573,
    "wind_generated": 66.82140217,
    "marketplace_to_demand": 46.0970902,
    "grid_to_demand": 34256.35197,
    "govt_battery_charge": 20.73160249
  },
  {
    "timestamp": "27/01/25 11:00",
    "demand": 36276.24282,
    "solar_generated": 12.63325331,
    "wind_generated": 67.65943396,
    "marketplace_to_demand": 199.606447,
    "grid_to_demand": 33780.49511,
    "govt_battery_charge": 11.13485527
  },
  {
    "timestamp": "27/01/25 12:00",
    "demand": 36077.76275,
    "solar_generated": 17.52836851,
    "wind_generated": 57.06164406,
    "marketplace_to_demand": 186.5992168,
    "grid_to_demand": 32873.05069,
    "govt_battery_charge": 37.00335744
  },
  {
    "timestamp": "27/01/25 13:00",
    "demand": 35705.4406,
    "solar_generated": 19.11269021,
    "wind_generated": 70.97248486,
    "marketplace_to_demand": 80.18655372,
    "grid_to_demand": 34584.60162,
    "govt_battery_charge": 76.76935293
  },
  {
    "timestamp": "27/01/25 14:00",
    "demand": 36436.64092,
    "solar_generated": 16.90193998,
    "wind_generated": 67.71473541,
    "marketplace_to_demand": 80.82538578,
    "grid_to_demand": 35003.56523,
    "govt_battery_charge": 16.64884013
  },
  {
    "timestamp": "27/01/25 15:00",
    "demand": 31443.21208,
    "solar_generated": 14.8903435,
    "wind_generated": 58.48989913,
    "marketplace_to_demand": 9.573601342,
    "grid_to_demand": 32475.65967,
    "govt_battery_charge": 32.7900446
  },
  {
    "timestamp": "27/01/25 16:00",
    "demand": 32622.1025,
    "solar_generated": 11.85414669,
    "wind_generated": 70.63552823,
    "marketplace_to_demand": 178.0865796,
    "grid_to_demand": 32914.35898,
    "govt_battery_charge": 16.25190491
  },
  {
    "timestamp": "27/01/25 17:00",
    "demand": 34251.27548,
    "solar_generated": 14.63054502,
    "wind_generated": 58.58385845,
    "marketplace_to_demand": 49.83045355,
    "grid_to_demand": 34154.55623,
    "govt_battery_charge": 80.8870895
  },
  {
    "timestamp": "27/01/25 18:00",
    "demand": 35139.40148,
    "solar_generated": 0,
    "wind_generated": 63.32399789,
    "marketplace_to_demand": 20.39718672,
    "grid_to_demand": 31917.03087,
    "govt_battery_charge": 64.60386965
  },
  {
    "timestamp": "27/01/25 19:00",
    "demand": 35379.05759,
    "solar_generated": 0,
    "wind_generated": 65.71889761,
    "marketplace_to_demand": 47.46289255,
    "grid_to_demand": 35353.84171,
    "govt_battery_charge": 93.86498634
  },
  {
    "timestamp": "27/01/25 20:00",
    "demand": 30335.75497,
    "solar_generated": 0,
    "wind_generated": 62.17086007,
    "marketplace_to_demand": 133.8903231,
    "grid_to_demand": 29964.7195,
    "govt_battery_charge": 77.68090401
  },
  {
    "timestamp": "27/01/25 21:00",
    "demand": 33025.42946,
    "solar_generated": 0,
    "wind_generated": 58.98609294,
    "marketplace_to_demand": 23.43714873,
    "grid_to_demand": 32141.28427,
    "govt_battery_charge": 34.57175603
  },
  {
    "timestamp": "27/01/25 22:00",
    "demand": 30325.00661,
    "solar_generated": 0,
    "wind_generated": 61.09723305,
    "marketplace_to_demand": 196.7704177,
    "grid_to_demand": 31875.96097,
    "govt_battery_charge": 77.510922
  },
  {
    "timestamp": "27/01/25 23:00",
    "demand": 33424.10059,
    "solar_generated": 0,
    "wind_generated": 60.02736012,
    "marketplace_to_demand": 125.1148534,
    "grid_to_demand": 35079.31791,
    "govt_battery_charge": 29.65478905
  },
  {
    "timestamp": "28/01/25 0:00",
    "demand": 34873.78298,
    "solar_generated": 0,
    "wind_generated": 61.27569207,
    "marketplace_to_demand": 6.44808198,
    "grid_to_demand": 30498.90994,
    "govt_battery_charge": 14.39587172
  },
  {
    "timestamp": "28/01/25 1:00",
    "demand": 33179.18249,
    "solar_generated": 0,
    "wind_generated": 53.71480777,
    "marketplace_to_demand": 75.07361957,
    "grid_to_demand": 31242.76557,
    "govt_battery_charge": 90.02459256
  },
  {
    "timestamp": "28/01/25 2:00",
    "demand": 36099.02326,
    "solar_generated": 0,
    "wind_generated": 65.00349609,
    "marketplace_to_demand": 127.755333,
    "grid_to_demand": 32048.98529,
    "govt_battery_charge": 67.96837368
  },
  {
    "timestamp": "28/01/25 3:00",
    "demand": 34021.79181,
    "solar_generated": 0,
    "wind_generated": 59.29581654,
    "marketplace_to_demand": 136.7119512,
    "grid_to_demand": 31203.4476,
    "govt_battery_charge": 12.638961
  },
  {
    "timestamp": "28/01/25 4:00",
    "demand": 33022.06136,
    "solar_generated": 0,
    "wind_generated": 80.35720643,
    "marketplace_to_demand": 176.0521465,
    "grid_to_demand": 30015.33795,
    "govt_battery_charge": 33.75027393
  },
  {
    "timestamp": "28/01/25 5:00",
    "demand": 31982.04521,
    "solar_generated": 0,
    "wind_generated": 79.52719128,
    "marketplace_to_demand": 94.32532171,
    "grid_to_demand": 34281.08182,
    "govt_battery_charge": 65.58357233
  },
  {
    "timestamp": "28/01/25 6:00",
    "demand": 30980.14907,
    "solar_generated": 6.580870887,
    "wind_generated": 65.80501594,
    "marketplace_to_demand": 57.09897151,
    "grid_to_demand": 35618.97128,
    "govt_battery_charge": 80.75554283
  },
  {
    "timestamp": "28/01/25 7:00",
    "demand": 36198.27609,
    "solar_generated": 8.010143217,
    "wind_generated": 66.48766512,
    "marketplace_to_demand": 94.74485769,
    "grid_to_demand": 34122.81028,
    "govt_battery_charge": 33.18097689
  },
  {
    "timestamp": "28/01/25 8:00",
    "demand": 30904.64756,
    "solar_generated": 12.49618304,
    "wind_generated": 63.45222072,
    "marketplace_to_demand": 111.3792792,
    "grid_to_demand": 33293.83252,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "28/01/25 9:00",
    "demand": 31024.13797,
    "solar_generated": 11.83941748,
    "wind_generated": 69.05369386,
    "marketplace_to_demand": 167.4406754,
    "grid_to_demand": 32923.22067,
    "govt_battery_charge": 48.02520412
  },
  {
    "timestamp": "28/01/25 10:00",
    "demand": 31440.50499,
    "solar_generated": 19.60985345,
    "wind_generated": 78.85247872,
    "marketplace_to_demand": 142.7436008,
    "grid_to_demand": 36178.68037,
    "govt_battery_charge": 38.55751944
  },
  {
    "timestamp": "28/01/25 11:00",
    "demand": 32238.90674,
    "solar_generated": 10.48077162,
    "wind_generated": 80.00946585,
    "marketplace_to_demand": 36.4214195,
    "grid_to_demand": 31913.63289,
    "govt_battery_charge": 55.50199121
  },
  {
    "timestamp": "28/01/25 12:00",
    "demand": 30381.50345,
    "solar_generated": 8.186593091,
    "wind_generated": 70.03120063,
    "marketplace_to_demand": 111.7906781,
    "grid_to_demand": 30045.70289,
    "govt_battery_charge": 58.35992977
  },
  {
    "timestamp": "28/01/25 13:00",
    "demand": 29991.19611,
    "solar_generated": 10.65794546,
    "wind_generated": 64.95363738,
    "marketplace_to_demand": 178.7222592,
    "grid_to_demand": 36359.19841,
    "govt_battery_charge": 60.58450005
  },
  {
    "timestamp": "28/01/25 14:00",
    "demand": 35159.2868,
    "solar_generated": 17.39762315,
    "wind_generated": 59.17489528,
    "marketplace_to_demand": 22.91396982,
    "grid_to_demand": 32521.49493,
    "govt_battery_charge": 21.33904534
  },
  {
    "timestamp": "28/01/25 15:00",
    "demand": 31712.68773,
    "solar_generated": 10.54428378,
    "wind_generated": 59.8616564,
    "marketplace_to_demand": 119.2083481,
    "grid_to_demand": 35759.55985,
    "govt_battery_charge": 38.25400926
  },
  {
    "timestamp": "28/01/25 16:00",
    "demand": 33080.42422,
    "solar_generated": 23.58416152,
    "wind_generated": 78.02925697,
    "marketplace_to_demand": 20.26893295,
    "grid_to_demand": 32363.33413,
    "govt_battery_charge": 93.24367611
  },
  {
    "timestamp": "28/01/25 17:00",
    "demand": 32302.16987,
    "solar_generated": 8.907580673,
    "wind_generated": 70.79009895,
    "marketplace_to_demand": 87.54918223,
    "grid_to_demand": 35150.55174,
    "govt_battery_charge": 82.13780606
  },
  {
    "timestamp": "28/01/25 18:00",
    "demand": 34205.95876,
    "solar_generated": 0,
    "wind_generated": 63.44202062,
    "marketplace_to_demand": 97.81411919,
    "grid_to_demand": 36528.06222,
    "govt_battery_charge": 78.63805761
  },
  {
    "timestamp": "28/01/25 19:00",
    "demand": 31467.69684,
    "solar_generated": 0,
    "wind_generated": 58.00312053,
    "marketplace_to_demand": 139.5199017,
    "grid_to_demand": 33952.43463,
    "govt_battery_charge": 88.39778748
  },
  {
    "timestamp": "28/01/25 20:00",
    "demand": 34981.70487,
    "solar_generated": 0,
    "wind_generated": 54.02401829,
    "marketplace_to_demand": 49.60788883,
    "grid_to_demand": 31065.02763,
    "govt_battery_charge": 34.23552887
  },
  {
    "timestamp": "28/01/25 21:00",
    "demand": 32236.34867,
    "solar_generated": 0,
    "wind_generated": 70.63920854,
    "marketplace_to_demand": 156.3311553,
    "grid_to_demand": 34824.03225,
    "govt_battery_charge": 94.64850054
  },
  {
    "timestamp": "28/01/25 22:00",
    "demand": 33617.54465,
    "solar_generated": 0,
    "wind_generated": 76.32108102,
    "marketplace_to_demand": 47.10522418,
    "grid_to_demand": 32748.42967,
    "govt_battery_charge": 94.32270668
  },
  {
    "timestamp": "28/01/25 23:00",
    "demand": 36578.53293,
    "solar_generated": 0,
    "wind_generated": 66.27659994,
    "marketplace_to_demand": 140.4204775,
    "grid_to_demand": 34492.35949,
    "govt_battery_charge": 89.44729924
  },
  {
    "timestamp": "29/01/25 0:00",
    "demand": 36117.87059,
    "solar_generated": 0,
    "wind_generated": 64.6889855,
    "marketplace_to_demand": 130.2990397,
    "grid_to_demand": 31079.3861,
    "govt_battery_charge": 76.43724422
  },
  {
    "timestamp": "29/01/25 1:00",
    "demand": 30476.01887,
    "solar_generated": 0,
    "wind_generated": 73.21614363,
    "marketplace_to_demand": 49.09283241,
    "grid_to_demand": 30630.65575,
    "govt_battery_charge": 83.77857051
  },
  {
    "timestamp": "29/01/25 2:00",
    "demand": 33272.7345,
    "solar_generated": 0,
    "wind_generated": 57.28813229,
    "marketplace_to_demand": 166.3943346,
    "grid_to_demand": 33905.21794,
    "govt_battery_charge": 7.663788712
  },
  {
    "timestamp": "29/01/25 3:00",
    "demand": 34593.89337,
    "solar_generated": 0,
    "wind_generated": 64.86079653,
    "marketplace_to_demand": 26.58446664,
    "grid_to_demand": 35578.99567,
    "govt_battery_charge": 84.11952608
  },
  {
    "timestamp": "29/01/25 4:00",
    "demand": 32423.64052,
    "solar_generated": 0,
    "wind_generated": 76.35153674,
    "marketplace_to_demand": 183.0210428,
    "grid_to_demand": 32539.34647,
    "govt_battery_charge": 9.806901212
  },
  {
    "timestamp": "29/01/25 5:00",
    "demand": 34512.98424,
    "solar_generated": 0,
    "wind_generated": 63.40210994,
    "marketplace_to_demand": 36.54292178,
    "grid_to_demand": 34977.8638,
    "govt_battery_charge": 27.4318083
  },
  {
    "timestamp": "29/01/25 6:00",
    "demand": 34604.15358,
    "solar_generated": 8.821246335,
    "wind_generated": 68.89957721,
    "marketplace_to_demand": 165.4905334,
    "grid_to_demand": 30523.39366,
    "govt_battery_charge": 87.29162229
  },
  {
    "timestamp": "29/01/25 7:00",
    "demand": 29987.0395,
    "solar_generated": 8.523058666,
    "wind_generated": 62.53569257,
    "marketplace_to_demand": 39.23968688,
    "grid_to_demand": 31769.41315,
    "govt_battery_charge": 31.26305635
  },
  {
    "timestamp": "29/01/25 8:00",
    "demand": 31081.10626,
    "solar_generated": 13.67221466,
    "wind_generated": 70.7050699,
    "marketplace_to_demand": 14.70650176,
    "grid_to_demand": 33582.96249,
    "govt_battery_charge": 43.10726235
  },
  {
    "timestamp": "29/01/25 9:00",
    "demand": 32839.42205,
    "solar_generated": 16.0545498,
    "wind_generated": 61.54018938,
    "marketplace_to_demand": 19.15854281,
    "grid_to_demand": 34741.98112,
    "govt_battery_charge": 24.00292489
  },
  {
    "timestamp": "29/01/25 10:00",
    "demand": 33540.3873,
    "solar_generated": 13.68300471,
    "wind_generated": 80.10551742,
    "marketplace_to_demand": 19.44132895,
    "grid_to_demand": 31552.21973,
    "govt_battery_charge": 97.96996004
  },
  {
    "timestamp": "29/01/25 11:00",
    "demand": 35411.37264,
    "solar_generated": 20.86860872,
    "wind_generated": 78.33769446,
    "marketplace_to_demand": 27.86738713,
    "grid_to_demand": 34590.26437,
    "govt_battery_charge": 95.84343726
  },
  {
    "timestamp": "29/01/25 12:00",
    "demand": 32515.80441,
    "solar_generated": 12.56174603,
    "wind_generated": 68.71851895,
    "marketplace_to_demand": 116.1441164,
    "grid_to_demand": 31255.75478,
    "govt_battery_charge": 59.85257109
  },
  {
    "timestamp": "29/01/25 13:00",
    "demand": 34791.0444,
    "solar_generated": 13.81405956,
    "wind_generated": 59.0091435,
    "marketplace_to_demand": 160.2250691,
    "grid_to_demand": 35166.33965,
    "govt_battery_charge": 27.3870255
  },
  {
    "timestamp": "29/01/25 14:00",
    "demand": 30980.94279,
    "solar_generated": 21.23789297,
    "wind_generated": 56.05398726,
    "marketplace_to_demand": 170.8307891,
    "grid_to_demand": 32383.08658,
    "govt_battery_charge": 54.38644901
  },
  {
    "timestamp": "29/01/25 15:00",
    "demand": 32002.41047,
    "solar_generated": 13.29172518,
    "wind_generated": 76.1903355,
    "marketplace_to_demand": 161.7164852,
    "grid_to_demand": 35449.5463,
    "govt_battery_charge": 83.73420123
  },
  {
    "timestamp": "29/01/25 16:00",
    "demand": 33632.03913,
    "solar_generated": 6.377093539,
    "wind_generated": 66.22098801,
    "marketplace_to_demand": 151.1957578,
    "grid_to_demand": 34849.97316,
    "govt_battery_charge": 84.33069032
  },
  {
    "timestamp": "29/01/25 17:00",
    "demand": 34668.50288,
    "solar_generated": 21.00759311,
    "wind_generated": 80.26733755,
    "marketplace_to_demand": 145.1950698,
    "grid_to_demand": 33800.96178,
    "govt_battery_charge": 67.67733454
  },
  {
    "timestamp": "29/01/25 18:00",
    "demand": 34798.19585,
    "solar_generated": 0,
    "wind_generated": 64.52781364,
    "marketplace_to_demand": 197.7067207,
    "grid_to_demand": 30796.54601,
    "govt_battery_charge": 46.3516322
  },
  {
    "timestamp": "29/01/25 19:00",
    "demand": 32253.08798,
    "solar_generated": 0,
    "wind_generated": 59.71308548,
    "marketplace_to_demand": 164.0803592,
    "grid_to_demand": 32374.3417,
    "govt_battery_charge": 65.90186847
  },
  {
    "timestamp": "29/01/25 20:00",
    "demand": 36086.36626,
    "solar_generated": 0,
    "wind_generated": 66.77008979,
    "marketplace_to_demand": 140.9948999,
    "grid_to_demand": 34236.53922,
    "govt_battery_charge": 91.24820662
  },
  {
    "timestamp": "29/01/25 21:00",
    "demand": 33798.18616,
    "solar_generated": 0,
    "wind_generated": 65.11287797,
    "marketplace_to_demand": 61.49391729,
    "grid_to_demand": 34186.52575,
    "govt_battery_charge": 85.29406007
  },
  {
    "timestamp": "29/01/25 22:00",
    "demand": 30607.92276,
    "solar_generated": 0,
    "wind_generated": 59.32946889,
    "marketplace_to_demand": 19.94619891,
    "grid_to_demand": 34319.70752,
    "govt_battery_charge": 65.05010528
  },
  {
    "timestamp": "29/01/25 23:00",
    "demand": 33621.73902,
    "solar_generated": 0,
    "wind_generated": 65.4313666,
    "marketplace_to_demand": 57.19888373,
    "grid_to_demand": 30628.72515,
    "govt_battery_charge": 74.0251122
  },
  {
    "timestamp": "30/01/25 0:00",
    "demand": 35427.73352,
    "solar_generated": 0,
    "wind_generated": 66.96570936,
    "marketplace_to_demand": 21.63137496,
    "grid_to_demand": 33869.05916,
    "govt_battery_charge": 42.20172064
  },
  {
    "timestamp": "30/01/25 1:00",
    "demand": 36312.64425,
    "solar_generated": 0,
    "wind_generated": 69.30869264,
    "marketplace_to_demand": 10.59955593,
    "grid_to_demand": 30464.75287,
    "govt_battery_charge": 96.09981018
  },
  {
    "timestamp": "30/01/25 2:00",
    "demand": 30982.76809,
    "solar_generated": 0,
    "wind_generated": 56.59272831,
    "marketplace_to_demand": 163.9747635,
    "grid_to_demand": 31742.21302,
    "govt_battery_charge": 57.53921499
  },
  {
    "timestamp": "30/01/25 3:00",
    "demand": 31090.44204,
    "solar_generated": 0,
    "wind_generated": 65.40597091,
    "marketplace_to_demand": 17.01791009,
    "grid_to_demand": 31440.55051,
    "govt_battery_charge": 67.90302426
  },
  {
    "timestamp": "30/01/25 4:00",
    "demand": 30367.63511,
    "solar_generated": 0,
    "wind_generated": 61.88344281,
    "marketplace_to_demand": 171.5458138,
    "grid_to_demand": 30127.38568,
    "govt_battery_charge": 82.19678001
  },
  {
    "timestamp": "30/01/25 5:00",
    "demand": 33364.34481,
    "solar_generated": 0,
    "wind_generated": 74.67270676,
    "marketplace_to_demand": 190.7379799,
    "grid_to_demand": 33925.39399,
    "govt_battery_charge": 26.56590264
  },
  {
    "timestamp": "30/01/25 6:00",
    "demand": 33113.10814,
    "solar_generated": 19.77369202,
    "wind_generated": 70.70136947,
    "marketplace_to_demand": 182.9951927,
    "grid_to_demand": 31897.00612,
    "govt_battery_charge": 8.101405823
  },
  {
    "timestamp": "30/01/25 7:00",
    "demand": 34904.4788,
    "solar_generated": 7.828698046,
    "wind_generated": 79.58940915,
    "marketplace_to_demand": 132.2665884,
    "grid_to_demand": 35747.01889,
    "govt_battery_charge": 97.05154588
  },
  {
    "timestamp": "30/01/25 8:00",
    "demand": 30957.59258,
    "solar_generated": 9.875897505,
    "wind_generated": 73.47460505,
    "marketplace_to_demand": 158.0265473,
    "grid_to_demand": 31097.77422,
    "govt_battery_charge": 16.45062196
  },
  {
    "timestamp": "30/01/25 9:00",
    "demand": 35131.21608,
    "solar_generated": 10.21384701,
    "wind_generated": 73.53592258,
    "marketplace_to_demand": 153.8544965,
    "grid_to_demand": 35243.13214,
    "govt_battery_charge": 73.16085417
  },
  {
    "timestamp": "30/01/25 10:00",
    "demand": 33321.72636,
    "solar_generated": 10.02175549,
    "wind_generated": 59.30953745,
    "marketplace_to_demand": 19.45582096,
    "grid_to_demand": 32364.08618,
    "govt_battery_charge": 84.90193075
  },
  {
    "timestamp": "30/01/25 11:00",
    "demand": 34352.50506,
    "solar_generated": 18.01642182,
    "wind_generated": 77.1360656,
    "marketplace_to_demand": 19.40178626,
    "grid_to_demand": 29978.90916,
    "govt_battery_charge": 44.22842693
  },
  {
    "timestamp": "30/01/25 12:00",
    "demand": 31665.67005,
    "solar_generated": 8.152217836,
    "wind_generated": 59.47340897,
    "marketplace_to_demand": 156.7201802,
    "grid_to_demand": 34271.6244,
    "govt_battery_charge": 54.45963568
  },
  {
    "timestamp": "30/01/25 13:00",
    "demand": 30106.09147,
    "solar_generated": 17.92679334,
    "wind_generated": 68.66694552,
    "marketplace_to_demand": 189.1994787,
    "grid_to_demand": 32787.8177,
    "govt_battery_charge": 59.17815028
  },
  {
    "timestamp": "30/01/25 14:00",
    "demand": 33381.9129,
    "solar_generated": 12.00051496,
    "wind_generated": 65.8848816,
    "marketplace_to_demand": 177.138435,
    "grid_to_demand": 35941.56136,
    "govt_battery_charge": 65.36327249
  },
  {
    "timestamp": "30/01/25 15:00",
    "demand": 35726.87555,
    "solar_generated": 8.046167258,
    "wind_generated": 54.44231951,
    "marketplace_to_demand": 190.1755948,
    "grid_to_demand": 30532.97604,
    "govt_battery_charge": 53.21791927
  },
  {
    "timestamp": "30/01/25 16:00",
    "demand": 30055.85162,
    "solar_generated": 9.091753692,
    "wind_generated": 79.38661099,
    "marketplace_to_demand": 101.0333696,
    "grid_to_demand": 35405.49936,
    "govt_battery_charge": 82.23315622
  },
  {
    "timestamp": "30/01/25 17:00",
    "demand": 30882.50622,
    "solar_generated": 7.637536752,
    "wind_generated": 75.87175852,
    "marketplace_to_demand": 102.6265302,
    "grid_to_demand": 34118.68492,
    "govt_battery_charge": 4.154094588
  },
  {
    "timestamp": "30/01/25 18:00",
    "demand": 36550.13398,
    "solar_generated": 0,
    "wind_generated": 59.65409402,
    "marketplace_to_demand": 76.19321199,
    "grid_to_demand": 31957.73488,
    "govt_battery_charge": 47.76709601
  },
  {
    "timestamp": "30/01/25 19:00",
    "demand": 36617.74515,
    "solar_generated": 0,
    "wind_generated": 59.2850788,
    "marketplace_to_demand": 15.59755079,
    "grid_to_demand": 35904.74341,
    "govt_battery_charge": 85.33185274
  },
  {
    "timestamp": "30/01/25 20:00",
    "demand": 31527.88001,
    "solar_generated": 0,
    "wind_generated": 54.74080841,
    "marketplace_to_demand": 181.4821478,
    "grid_to_demand": 32048.58117,
    "govt_battery_charge": 99.4611012
  },
  {
    "timestamp": "30/01/25 21:00",
    "demand": 33609.61329,
    "solar_generated": 0,
    "wind_generated": 63.66967658,
    "marketplace_to_demand": 41.1265888,
    "grid_to_demand": 34670.40316,
    "govt_battery_charge": 55.1423969
  },
  {
    "timestamp": "30/01/25 22:00",
    "demand": 34594.20235,
    "solar_generated": 0,
    "wind_generated": 73.80621195,
    "marketplace_to_demand": 171.7963586,
    "grid_to_demand": 32542.20077,
    "govt_battery_charge": 44.18573996
  },
  {
    "timestamp": "30/01/25 23:00",
    "demand": 33706.92032,
    "solar_generated": 0,
    "wind_generated": 74.01764497,
    "marketplace_to_demand": 34.88462338,
    "grid_to_demand": 32159.05297,
    "govt_battery_charge": 11.13770874
  },
  {
    "timestamp": "31/01/25 0:00",
    "demand": 35132.81852,
    "solar_generated": 0,
    "wind_generated": 78.17454474,
    "marketplace_to_demand": 7.762368332,
    "grid_to_demand": 36544.09149,
    "govt_battery_charge": 84.21219271
  },
  {
    "timestamp": "31/01/25 1:00",
    "demand": 35502.82916,
    "solar_generated": 0,
    "wind_generated": 64.46552849,
    "marketplace_to_demand": 157.5506339,
    "grid_to_demand": 34811.93641,
    "govt_battery_charge": 21.65086242
  },
  {
    "timestamp": "31/01/25 2:00",
    "demand": 33788.01467,
    "solar_generated": 0,
    "wind_generated": 79.99024471,
    "marketplace_to_demand": 101.076202,
    "grid_to_demand": 30809.1263,
    "govt_battery_charge": 58.15719123
  },
  {
    "timestamp": "31/01/25 3:00",
    "demand": 32491.11687,
    "solar_generated": 0,
    "wind_generated": 79.77864787,
    "marketplace_to_demand": 132.4317465,
    "grid_to_demand": 33431.36614,
    "govt_battery_charge": 9.157941141
  },
  {
    "timestamp": "31/01/25 4:00",
    "demand": 32508.95384,
    "solar_generated": 0,
    "wind_generated": 75.84944527,
    "marketplace_to_demand": 66.11553876,
    "grid_to_demand": 30158.66065,
    "govt_battery_charge": 48.68569073
  },
  {
    "timestamp": "31/01/25 5:00",
    "demand": 33079.98143,
    "solar_generated": 0,
    "wind_generated": 64.71299566,
    "marketplace_to_demand": 13.40613856,
    "grid_to_demand": 33937.87767,
    "govt_battery_charge": 45.26518446
  },
  {
    "timestamp": "31/01/25 6:00",
    "demand": 34722.35866,
    "solar_generated": 15.18548022,
    "wind_generated": 59.26745415,
    "marketplace_to_demand": 125.1892704,
    "grid_to_demand": 30249.15346,
    "govt_battery_charge": 48.5581085
  },
  {
    "timestamp": "31/01/25 7:00",
    "demand": 33904.95319,
    "solar_generated": 12.37212735,
    "wind_generated": 79.91155467,
    "marketplace_to_demand": 93.36118211,
    "grid_to_demand": 30881.65009,
    "govt_battery_charge": 36.31197248
  },
  {
    "timestamp": "31/01/25 8:00",
    "demand": 31094.19691,
    "solar_generated": 15.32139045,
    "wind_generated": 55.25973033,
    "marketplace_to_demand": 173.0743797,
    "grid_to_demand": 31442.71037,
    "govt_battery_charge": 36.86232856
  },
  {
    "timestamp": "31/01/25 9:00",
    "demand": 35611.19349,
    "solar_generated": 7.235008596,
    "wind_generated": 66.68971347,
    "marketplace_to_demand": 6.522393772,
    "grid_to_demand": 35233.40709,
    "govt_battery_charge": 6.058691803
  },
  {
    "timestamp": "31/01/25 10:00",
    "demand": 36165.22589,
    "solar_generated": 11.73263699,
    "wind_generated": 78.67123248,
    "marketplace_to_demand": 158.0201284,
    "grid_to_demand": 34680.86359,
    "govt_battery_charge": 23.64423179
  },
  {
    "timestamp": "31/01/25 11:00",
    "demand": 36003.25659,
    "solar_generated": 22.63668975,
    "wind_generated": 79.51073318,
    "marketplace_to_demand": 122.3030937,
    "grid_to_demand": 32827.37207,
    "govt_battery_charge": 64.27557387
  },
  {
    "timestamp": "31/01/25 12:00",
    "demand": 34708.28573,
    "solar_generated": 13.00376067,
    "wind_generated": 58.72420876,
    "marketplace_to_demand": 132.8547299,
    "grid_to_demand": 32680.85407,
    "govt_battery_charge": 90.75901318
  },
  {
    "timestamp": "31/01/25 13:00",
    "demand": 36450.64272,
    "solar_generated": 12.12870136,
    "wind_generated": 56.79761542,
    "marketplace_to_demand": 31.55537054,
    "grid_to_demand": 36085.04129,
    "govt_battery_charge": 41.57823418
  },
  {
    "timestamp": "31/01/25 14:00",
    "demand": 33193.29711,
    "solar_generated": 20.73625132,
    "wind_generated": 61.69284407,
    "marketplace_to_demand": 58.75565704,
    "grid_to_demand": 33315.02987,
    "govt_battery_charge": 35.8741384
  },
  {
    "timestamp": "31/01/25 15:00",
    "demand": 36292.34421,
    "solar_generated": 8.551079313,
    "wind_generated": 53.99907441,
    "marketplace_to_demand": 151.91233,
    "grid_to_demand": 34708.97429,
    "govt_battery_charge": 71.05279444
  },
  {
    "timestamp": "31/01/25 16:00",
    "demand": 35982.54947,
    "solar_generated": 6.094293017,
    "wind_generated": 60.14148955,
    "marketplace_to_demand": 119.315679,
    "grid_to_demand": 31855.31249,
    "govt_battery_charge": 88.81019525
  },
  {
    "timestamp": "31/01/25 17:00",
    "demand": 32067.6579,
    "solar_generated": 21.45394849,
    "wind_generated": 75.05780543,
    "marketplace_to_demand": 153.608669,
    "grid_to_demand": 34984.9739,
    "govt_battery_charge": 25.14765528
  },
  {
    "timestamp": "31/01/25 18:00",
    "demand": 34657.46269,
    "solar_generated": 0,
    "wind_generated": 64.12488115,
    "marketplace_to_demand": 160.6457062,
    "grid_to_demand": 33053.57885,
    "govt_battery_charge": 36.97954866
  },
  {
    "timestamp": "31/01/25 19:00",
    "demand": 33671.83125,
    "solar_generated": 0,
    "wind_generated": 56.12076444,
    "marketplace_to_demand": 103.3002368,
    "grid_to_demand": 32533.89223,
    "govt_battery_charge": 71.31958849
  },
  {
    "timestamp": "31/01/25 20:00",
    "demand": 32715.47485,
    "solar_generated": 0,
    "wind_generated": 61.16897506,
    "marketplace_to_demand": 56.38725551,
    "grid_to_demand": 32732.71956,
    "govt_battery_charge": 63.25676977
  },
  {
    "timestamp": "31/01/25 21:00",
    "demand": 35991.28261,
    "solar_generated": 0,
    "wind_generated": 55.2228828,
    "marketplace_to_demand": 143.3608567,
    "grid_to_demand": 31222.70574,
    "govt_battery_charge": 72.56682987
  },
  {
    "timestamp": "31/01/25 22:00",
    "demand": 34396.4355,
    "solar_generated": 0,
    "wind_generated": 70.21793037,
    "marketplace_to_demand": 179.7489331,
    "grid_to_demand": 36170.77748,
    "govt_battery_charge": 63.57301868
  },
  {
    "timestamp": "31/01/25 23:00",
    "demand": 30271.35206,
    "solar_generated": 0,
    "wind_generated": 58.08014921,
    "marketplace_to_demand": 150.5967126,
    "grid_to_demand": 32400.90327,
    "govt_battery_charge": 60.51447448
  },
  {
    "timestamp": "01/02/25 0:00",
    "demand": 34805.92318,
    "solar_generated": 0,
    "wind_generated": 78.6607007,
    "marketplace_to_demand": 129.3668928,
    "grid_to_demand": 31614.04685,
    "govt_battery_charge": 53.36533256
  },
  {
    "timestamp": "01/02/25 1:00",
    "demand": 35069.5448,
    "solar_generated": 0,
    "wind_generated": 68.86190849,
    "marketplace_to_demand": 49.54486492,
    "grid_to_demand": 35501.81547,
    "govt_battery_charge": 13.4947342
  },
  {
    "timestamp": "01/02/25 2:00",
    "demand": 32368.63943,
    "solar_generated": 0,
    "wind_generated": 66.15163724,
    "marketplace_to_demand": 173.9888076,
    "grid_to_demand": 31228.89554,
    "govt_battery_charge": 94.20862361
  },
  {
    "timestamp": "01/02/25 3:00",
    "demand": 31675.73634,
    "solar_generated": 0,
    "wind_generated": 55.1441255,
    "marketplace_to_demand": 100.1827458,
    "grid_to_demand": 32578.76841,
    "govt_battery_charge": 98.17783383
  },
  {
    "timestamp": "01/02/25 4:00",
    "demand": 31955.68248,
    "solar_generated": 0,
    "wind_generated": 54.81333531,
    "marketplace_to_demand": 14.2461782,
    "grid_to_demand": 34592.49055,
    "govt_battery_charge": 46.92490967
  },
  {
    "timestamp": "01/02/25 5:00",
    "demand": 32267.93344,
    "solar_generated": 0,
    "wind_generated": 58.81751094,
    "marketplace_to_demand": 189.5983747,
    "grid_to_demand": 35294.39793,
    "govt_battery_charge": 15.83605035
  },
  {
    "timestamp": "01/02/25 6:00",
    "demand": 33156.37779,
    "solar_generated": 9.921998524,
    "wind_generated": 66.99604261,
    "marketplace_to_demand": 86.36792262,
    "grid_to_demand": 29985.00586,
    "govt_battery_charge": 41.06148652
  },
  {
    "timestamp": "01/02/25 7:00",
    "demand": 33520.98687,
    "solar_generated": 21.70294201,
    "wind_generated": 55.1859847,
    "marketplace_to_demand": 154.4571311,
    "grid_to_demand": 30365.49693,
    "govt_battery_charge": 39.02690402
  },
  {
    "timestamp": "01/02/25 8:00",
    "demand": 32184.59887,
    "solar_generated": 9.943656482,
    "wind_generated": 76.66290919,
    "marketplace_to_demand": 66.77057978,
    "grid_to_demand": 33044.92902,
    "govt_battery_charge": 39.79536712
  },
  {
    "timestamp": "01/02/25 9:00",
    "demand": 31675.58875,
    "solar_generated": 10.19848166,
    "wind_generated": 80.13149772,
    "marketplace_to_demand": 50.77182277,
    "grid_to_demand": 31234.75715,
    "govt_battery_charge": 59.93574026
  },
  {
    "timestamp": "01/02/25 10:00",
    "demand": 34578.5726,
    "solar_generated": 10.54230812,
    "wind_generated": 55.60730911,
    "marketplace_to_demand": 51.56527196,
    "grid_to_demand": 34600.29482,
    "govt_battery_charge": 28.87578009
  },
  {
    "timestamp": "01/02/25 11:00",
    "demand": 30725.49671,
    "solar_generated": 21.2532698,
    "wind_generated": 67.60814304,
    "marketplace_to_demand": 87.03865039,
    "grid_to_demand": 30168.35749,
    "govt_battery_charge": 46.78254838
  },
  {
    "timestamp": "01/02/25 12:00",
    "demand": 35064.55141,
    "solar_generated": 19.62056185,
    "wind_generated": 79.38663417,
    "marketplace_to_demand": 67.52124271,
    "grid_to_demand": 35958.30358,
    "govt_battery_charge": 40.94807089
  },
  {
    "timestamp": "01/02/25 13:00",
    "demand": 35875.3429,
    "solar_generated": 12.40209845,
    "wind_generated": 69.91808868,
    "marketplace_to_demand": 40.96080509,
    "grid_to_demand": 36462.00762,
    "govt_battery_charge": 6.810618938
  },
  {
    "timestamp": "01/02/25 14:00",
    "demand": 34341.80972,
    "solar_generated": 6.43622917,
    "wind_generated": 71.05081675,
    "marketplace_to_demand": 123.6095999,
    "grid_to_demand": 36322.73584,
    "govt_battery_charge": 92.63909033
  },
  {
    "timestamp": "01/02/25 15:00",
    "demand": 30790.51818,
    "solar_generated": 10.3705546,
    "wind_generated": 74.68803749,
    "marketplace_to_demand": 179.1333593,
    "grid_to_demand": 36514.789,
    "govt_battery_charge": 1.901732539
  },
  {
    "timestamp": "01/02/25 16:00",
    "demand": 33777.14691,
    "solar_generated": 20.07485104,
    "wind_generated": 55.61285451,
    "marketplace_to_demand": 86.47005073,
    "grid_to_demand": 35207.70555,
    "govt_battery_charge": 62.79835589
  },
  {
    "timestamp": "01/02/25 17:00",
    "demand": 30510.93202,
    "solar_generated": 7.013505156,
    "wind_generated": 62.00349495,
    "marketplace_to_demand": 165.6373566,
    "grid_to_demand": 30121.77815,
    "govt_battery_charge": 61.02362805
  },
  {
    "timestamp": "01/02/25 18:00",
    "demand": 32473.557,
    "solar_generated": 0,
    "wind_generated": 71.3931301,
    "marketplace_to_demand": 114.4903041,
    "grid_to_demand": 34431.66858,
    "govt_battery_charge": 28.93117866
  },
  {
    "timestamp": "01/02/25 19:00",
    "demand": 33967.53383,
    "solar_generated": 0,
    "wind_generated": 79.62568717,
    "marketplace_to_demand": 198.2974782,
    "grid_to_demand": 30920.67972,
    "govt_battery_charge": 83.82865161
  },
  {
    "timestamp": "01/02/25 20:00",
    "demand": 31555.58892,
    "solar_generated": 0,
    "wind_generated": 74.38524775,
    "marketplace_to_demand": 41.69985277,
    "grid_to_demand": 35935.51557,
    "govt_battery_charge": 51.71943287
  },
  {
    "timestamp": "01/02/25 21:00",
    "demand": 35803.18999,
    "solar_generated": 0,
    "wind_generated": 65.2701473,
    "marketplace_to_demand": 158.2197287,
    "grid_to_demand": 30289.89541,
    "govt_battery_charge": 65.23830316
  },
  {
    "timestamp": "01/02/25 22:00",
    "demand": 36224.42139,
    "solar_generated": 0,
    "wind_generated": 73.04859132,
    "marketplace_to_demand": 50.72828481,
    "grid_to_demand": 34132.18811,
    "govt_battery_charge": 67.41727724
  },
  {
    "timestamp": "01/02/25 23:00",
    "demand": 35366.52709,
    "solar_generated": 0,
    "wind_generated": 79.35034783,
    "marketplace_to_demand": 161.2982701,
    "grid_to_demand": 32271.76362,
    "govt_battery_charge": 75.2167702
  },
  {
    "timestamp": "02/02/25 0:00",
    "demand": 30824.08257,
    "solar_generated": 0,
    "wind_generated": 65.99648787,
    "marketplace_to_demand": 108.5301525,
    "grid_to_demand": 35911.6874,
    "govt_battery_charge": 79.52210261
  },
  {
    "timestamp": "02/02/25 1:00",
    "demand": 30483.09167,
    "solar_generated": 0,
    "wind_generated": 54.61337688,
    "marketplace_to_demand": 101.4723522,
    "grid_to_demand": 32804.09388,
    "govt_battery_charge": 66.21908131
  },
  {
    "timestamp": "02/02/25 2:00",
    "demand": 30006.6109,
    "solar_generated": 0,
    "wind_generated": 60.87769446,
    "marketplace_to_demand": 92.36855122,
    "grid_to_demand": 35707.22616,
    "govt_battery_charge": 80.05110584
  },
  {
    "timestamp": "02/02/25 3:00",
    "demand": 30145.96735,
    "solar_generated": 0,
    "wind_generated": 79.12644186,
    "marketplace_to_demand": 1.123021335,
    "grid_to_demand": 36231.92866,
    "govt_battery_charge": 85.42869036
  },
  {
    "timestamp": "02/02/25 4:00",
    "demand": 34501.58968,
    "solar_generated": 0,
    "wind_generated": 63.99929958,
    "marketplace_to_demand": 28.52195751,
    "grid_to_demand": 31729.42998,
    "govt_battery_charge": 65.08270168
  },
  {
    "timestamp": "02/02/25 5:00",
    "demand": 31151.18187,
    "solar_generated": 0,
    "wind_generated": 79.5404713,
    "marketplace_to_demand": 81.95107763,
    "grid_to_demand": 35905.4967,
    "govt_battery_charge": 42.35446297
  },
  {
    "timestamp": "02/02/25 6:00",
    "demand": 33526.0749,
    "solar_generated": 12.89054703,
    "wind_generated": 77.5959254,
    "marketplace_to_demand": 22.47765485,
    "grid_to_demand": 30472.3872,
    "govt_battery_charge": 64.82958553
  },
  {
    "timestamp": "02/02/25 7:00",
    "demand": 34163.66295,
    "solar_generated": 14.40114689,
    "wind_generated": 70.23401598,
    "marketplace_to_demand": 173.7511084,
    "grid_to_demand": 30597.2957,
    "govt_battery_charge": 96.20170622
  },
  {
    "timestamp": "02/02/25 8:00",
    "demand": 34534.14703,
    "solar_generated": 23.82569645,
    "wind_generated": 59.53340284,
    "marketplace_to_demand": 82.87108405,
    "grid_to_demand": 31432.86104,
    "govt_battery_charge": 92.8682575
  },
  {
    "timestamp": "02/02/25 9:00",
    "demand": 34309.98545,
    "solar_generated": 10.14593271,
    "wind_generated": 76.70902896,
    "marketplace_to_demand": 7.293413143,
    "grid_to_demand": 34662.78995,
    "govt_battery_charge": 25.34947635
  },
  {
    "timestamp": "02/02/25 10:00",
    "demand": 33218.32684,
    "solar_generated": 16.28889032,
    "wind_generated": 65.53546956,
    "marketplace_to_demand": 197.6640791,
    "grid_to_demand": 34855.53665,
    "govt_battery_charge": 84.83746122
  },
  {
    "timestamp": "02/02/25 11:00",
    "demand": 35081.68919,
    "solar_generated": 7.687046735,
    "wind_generated": 56.84675922,
    "marketplace_to_demand": 66.11023147,
    "grid_to_demand": 34499.90657,
    "govt_battery_charge": 22.0477109
  },
  {
    "timestamp": "02/02/25 12:00",
    "demand": 31226.15237,
    "solar_generated": 15.14569301,
    "wind_generated": 68.63891772,
    "marketplace_to_demand": 62.50725891,
    "grid_to_demand": 35910.02211,
    "govt_battery_charge": 86.27182968
  },
  {
    "timestamp": "02/02/25 13:00",
    "demand": 32967.52474,
    "solar_generated": 18.67058972,
    "wind_generated": 68.0879721,
    "marketplace_to_demand": 89.98042643,
    "grid_to_demand": 30029.626,
    "govt_battery_charge": 90.78805172
  },
  {
    "timestamp": "02/02/25 14:00",
    "demand": 35826.73588,
    "solar_generated": 16.34114047,
    "wind_generated": 59.54346337,
    "marketplace_to_demand": 188.4399263,
    "grid_to_demand": 32811.66645,
    "govt_battery_charge": 31.3767036
  },
  {
    "timestamp": "02/02/25 15:00",
    "demand": 35356.13026,
    "solar_generated": 8.52546072,
    "wind_generated": 80.10671509,
    "marketplace_to_demand": 17.7372115,
    "grid_to_demand": 33507.72281,
    "govt_battery_charge": 1.438282943
  },
  {
    "timestamp": "02/02/25 16:00",
    "demand": 34172.07808,
    "solar_generated": 17.87296409,
    "wind_generated": 57.77248874,
    "marketplace_to_demand": 159.8195244,
    "grid_to_demand": 33237.1281,
    "govt_battery_charge": 75.03122057
  },
  {
    "timestamp": "02/02/25 17:00",
    "demand": 30729.47797,
    "solar_generated": 16.11320364,
    "wind_generated": 59.64139167,
    "marketplace_to_demand": 139.4248261,
    "grid_to_demand": 34597.72151,
    "govt_battery_charge": 2.249974057
  },
  {
    "timestamp": "02/02/25 18:00",
    "demand": 35846.63797,
    "solar_generated": 0,
    "wind_generated": 56.98198269,
    "marketplace_to_demand": 173.7779,
    "grid_to_demand": 34612.94994,
    "govt_battery_charge": 69.1245024
  },
  {
    "timestamp": "02/02/25 19:00",
    "demand": 33644.26288,
    "solar_generated": 0,
    "wind_generated": 55.64382973,
    "marketplace_to_demand": 164.9610825,
    "grid_to_demand": 30085.74336,
    "govt_battery_charge": 52.68427227
  },
  {
    "timestamp": "02/02/25 20:00",
    "demand": 30783.07945,
    "solar_generated": 0,
    "wind_generated": 79.57952339,
    "marketplace_to_demand": 90.6981734,
    "grid_to_demand": 29953.56088,
    "govt_battery_charge": 41.2455233
  },
  {
    "timestamp": "02/02/25 21:00",
    "demand": 30431.68828,
    "solar_generated": 0,
    "wind_generated": 61.42328558,
    "marketplace_to_demand": 52.19043546,
    "grid_to_demand": 30123.61514,
    "govt_battery_charge": 3.66434171
  },
  {
    "timestamp": "02/02/25 22:00",
    "demand": 33780.81228,
    "solar_generated": 0,
    "wind_generated": 79.89258349,
    "marketplace_to_demand": 127.8832191,
    "grid_to_demand": 30243.79404,
    "govt_battery_charge": 31.08363212
  },
  {
    "timestamp": "02/02/25 23:00",
    "demand": 35046.79335,
    "solar_generated": 0,
    "wind_generated": 67.98477079,
    "marketplace_to_demand": 178.6865752,
    "grid_to_demand": 36299.7616,
    "govt_battery_charge": 65.21078119
  },
  {
    "timestamp": "03/02/25 0:00",
    "demand": 35899.26993,
    "solar_generated": 0,
    "wind_generated": 72.39205014,
    "marketplace_to_demand": 156.0863036,
    "grid_to_demand": 32513.92251,
    "govt_battery_charge": 49.9056187
  },
  {
    "timestamp": "03/02/25 1:00",
    "demand": 35154.85204,
    "solar_generated": 0,
    "wind_generated": 80.10450235,
    "marketplace_to_demand": 74.21048333,
    "grid_to_demand": 36080.80379,
    "govt_battery_charge": 63.80225379
  },
  {
    "timestamp": "03/02/25 2:00",
    "demand": 33212.8379,
    "solar_generated": 0,
    "wind_generated": 69.32924719,
    "marketplace_to_demand": 42.42885788,
    "grid_to_demand": 31018.71895,
    "govt_battery_charge": 83.34827929
  },
  {
    "timestamp": "03/02/25 3:00",
    "demand": 34842.85559,
    "solar_generated": 0,
    "wind_generated": 60.35919632,
    "marketplace_to_demand": 174.3027751,
    "grid_to_demand": 30564.5045,
    "govt_battery_charge": 51.80015515
  },
  {
    "timestamp": "03/02/25 4:00",
    "demand": 34399.64582,
    "solar_generated": 0,
    "wind_generated": 79.21999984,
    "marketplace_to_demand": 13.54903542,
    "grid_to_demand": 32482.6363,
    "govt_battery_charge": 3.517159002
  },
  {
    "timestamp": "03/02/25 5:00",
    "demand": 33497.5854,
    "solar_generated": 0,
    "wind_generated": 72.40662466,
    "marketplace_to_demand": 3.300005304,
    "grid_to_demand": 34224.43487,
    "govt_battery_charge": 43.3341585
  },
  {
    "timestamp": "03/02/25 6:00",
    "demand": 31149.4556,
    "solar_generated": 16.01746845,
    "wind_generated": 64.00129765,
    "marketplace_to_demand": 33.59386529,
    "grid_to_demand": 33074.17142,
    "govt_battery_charge": 6.047748484
  },
  {
    "timestamp": "03/02/25 7:00",
    "demand": 34334.89104,
    "solar_generated": 23.28268679,
    "wind_generated": 78.37918473,
    "marketplace_to_demand": 182.0628054,
    "grid_to_demand": 35387.14462,
    "govt_battery_charge": 92.78974136
  },
  {
    "timestamp": "03/02/25 8:00",
    "demand": 34473.4064,
    "solar_generated": 12.65635904,
    "wind_generated": 67.87954536,
    "marketplace_to_demand": 60.71900451,
    "grid_to_demand": 35462.16921,
    "govt_battery_charge": 64.02374697
  },
  {
    "timestamp": "03/02/25 9:00",
    "demand": 34182.39331,
    "solar_generated": 8.314695243,
    "wind_generated": 54.54620672,
    "marketplace_to_demand": 63.36694656,
    "grid_to_demand": 31551.92629,
    "govt_battery_charge": 77.30792444
  },
  {
    "timestamp": "03/02/25 10:00",
    "demand": 32573.878,
    "solar_generated": 16.29061551,
    "wind_generated": 71.16401903,
    "marketplace_to_demand": 25.02779059,
    "grid_to_demand": 35158.96887,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "03/02/25 11:00",
    "demand": 36438.98459,
    "solar_generated": 8.514617367,
    "wind_generated": 73.73503868,
    "marketplace_to_demand": 19.45008674,
    "grid_to_demand": 30799.10251,
    "govt_battery_charge": 98.76342733
  },
  {
    "timestamp": "03/02/25 12:00",
    "demand": 35244.59913,
    "solar_generated": 20.19431306,
    "wind_generated": 72.44325039,
    "marketplace_to_demand": 62.06650945,
    "grid_to_demand": 35052.37504,
    "govt_battery_charge": 54.16876561
  },
  {
    "timestamp": "03/02/25 13:00",
    "demand": 32504.29629,
    "solar_generated": 10.69514732,
    "wind_generated": 55.98050709,
    "marketplace_to_demand": 137.2796817,
    "grid_to_demand": 33714.43412,
    "govt_battery_charge": 80.44450788
  },
  {
    "timestamp": "03/02/25 14:00",
    "demand": 34467.49635,
    "solar_generated": 20.50830321,
    "wind_generated": 56.58419465,
    "marketplace_to_demand": 199.928401,
    "grid_to_demand": 30365.74159,
    "govt_battery_charge": 78.68563804
  },
  {
    "timestamp": "03/02/25 15:00",
    "demand": 34108.24585,
    "solar_generated": 20.73923339,
    "wind_generated": 61.01570344,
    "marketplace_to_demand": 105.1255736,
    "grid_to_demand": 32005.04552,
    "govt_battery_charge": 32.92318369
  },
  {
    "timestamp": "03/02/25 16:00",
    "demand": 33705.18813,
    "solar_generated": 18.10935838,
    "wind_generated": 59.82608511,
    "marketplace_to_demand": 198.3412433,
    "grid_to_demand": 30818.02323,
    "govt_battery_charge": 93.13233971
  },
  {
    "timestamp": "03/02/25 17:00",
    "demand": 34970.06465,
    "solar_generated": 14.73384842,
    "wind_generated": 65.02794456,
    "marketplace_to_demand": 20.66720165,
    "grid_to_demand": 35575.61388,
    "govt_battery_charge": 21.48560762
  },
  {
    "timestamp": "03/02/25 18:00",
    "demand": 36442.58126,
    "solar_generated": 0,
    "wind_generated": 67.50000879,
    "marketplace_to_demand": 199.6914735,
    "grid_to_demand": 32024.36134,
    "govt_battery_charge": 9.64839443
  },
  {
    "timestamp": "03/02/25 19:00",
    "demand": 32754.55549,
    "solar_generated": 0,
    "wind_generated": 55.57737485,
    "marketplace_to_demand": 39.10600739,
    "grid_to_demand": 32100.12906,
    "govt_battery_charge": 14.86548425
  },
  {
    "timestamp": "03/02/25 20:00",
    "demand": 36365.49682,
    "solar_generated": 0,
    "wind_generated": 66.81705752,
    "marketplace_to_demand": 50.05592079,
    "grid_to_demand": 31983.91078,
    "govt_battery_charge": 26.36098828
  },
  {
    "timestamp": "03/02/25 21:00",
    "demand": 33032.30516,
    "solar_generated": 0,
    "wind_generated": 57.35113359,
    "marketplace_to_demand": 139.147508,
    "grid_to_demand": 32054.3769,
    "govt_battery_charge": 65.80941398
  },
  {
    "timestamp": "03/02/25 22:00",
    "demand": 33881.51593,
    "solar_generated": 0,
    "wind_generated": 67.36213121,
    "marketplace_to_demand": 132.7868646,
    "grid_to_demand": 36237.1606,
    "govt_battery_charge": 18.29442241
  },
  {
    "timestamp": "03/02/25 23:00",
    "demand": 31066.44977,
    "solar_generated": 0,
    "wind_generated": 71.28929316,
    "marketplace_to_demand": 71.59065971,
    "grid_to_demand": 33823.30173,
    "govt_battery_charge": 14.79582902
  },
  {
    "timestamp": "04/02/25 0:00",
    "demand": 31656.80849,
    "solar_generated": 0,
    "wind_generated": 79.3695569,
    "marketplace_to_demand": 9.004599246,
    "grid_to_demand": 29961.484,
    "govt_battery_charge": 16.15655907
  },
  {
    "timestamp": "04/02/25 1:00",
    "demand": 34311.74578,
    "solar_generated": 0,
    "wind_generated": 77.10930928,
    "marketplace_to_demand": 163.1948841,
    "grid_to_demand": 30736.78425,
    "govt_battery_charge": 67.01736966
  },
  {
    "timestamp": "04/02/25 2:00",
    "demand": 31972.41026,
    "solar_generated": 0,
    "wind_generated": 58.34075363,
    "marketplace_to_demand": 84.20756958,
    "grid_to_demand": 31358.32994,
    "govt_battery_charge": 7.443682697
  },
  {
    "timestamp": "04/02/25 3:00",
    "demand": 32565.06494,
    "solar_generated": 0,
    "wind_generated": 54.49132457,
    "marketplace_to_demand": 124.9814008,
    "grid_to_demand": 30257.39986,
    "govt_battery_charge": 46.69440152
  },
  {
    "timestamp": "04/02/25 4:00",
    "demand": 32893.64173,
    "solar_generated": 0,
    "wind_generated": 72.35480166,
    "marketplace_to_demand": 110.762007,
    "grid_to_demand": 36304.59413,
    "govt_battery_charge": 23.17084576
  },
  {
    "timestamp": "04/02/25 5:00",
    "demand": 36241.39552,
    "solar_generated": 0,
    "wind_generated": 71.03003448,
    "marketplace_to_demand": 158.8801204,
    "grid_to_demand": 34271.92752,
    "govt_battery_charge": 90.5744397
  },
  {
    "timestamp": "04/02/25 6:00",
    "demand": 32135.10324,
    "solar_generated": 13.76236511,
    "wind_generated": 69.24950177,
    "marketplace_to_demand": 52.93711451,
    "grid_to_demand": 34731.83286,
    "govt_battery_charge": 6.57063961
  },
  {
    "timestamp": "04/02/25 7:00",
    "demand": 34591.00533,
    "solar_generated": 15.07874591,
    "wind_generated": 69.05317327,
    "marketplace_to_demand": 145.5739898,
    "grid_to_demand": 31708.10308,
    "govt_battery_charge": 21.29135683
  },
  {
    "timestamp": "04/02/25 8:00",
    "demand": 30743.83707,
    "solar_generated": 7.292106404,
    "wind_generated": 66.72167796,
    "marketplace_to_demand": 86.94277202,
    "grid_to_demand": 35481.06812,
    "govt_battery_charge": 35.8119114
  },
  {
    "timestamp": "04/02/25 9:00",
    "demand": 35678.03685,
    "solar_generated": 8.735776755,
    "wind_generated": 66.08436945,
    "marketplace_to_demand": 155.7425489,
    "grid_to_demand": 32544.60407,
    "govt_battery_charge": 56.61939624
  },
  {
    "timestamp": "04/02/25 10:00",
    "demand": 33353.70059,
    "solar_generated": 8.153766191,
    "wind_generated": 62.56106845,
    "marketplace_to_demand": 128.7762737,
    "grid_to_demand": 31797.90089,
    "govt_battery_charge": 23.63956386
  },
  {
    "timestamp": "04/02/25 11:00",
    "demand": 31070.91455,
    "solar_generated": 19.69500871,
    "wind_generated": 68.31103345,
    "marketplace_to_demand": 172.8380619,
    "grid_to_demand": 31391.98336,
    "govt_battery_charge": 68.66400257
  },
  {
    "timestamp": "04/02/25 12:00",
    "demand": 31682.9924,
    "solar_generated": 12.19221989,
    "wind_generated": 78.50148517,
    "marketplace_to_demand": 47.15848663,
    "grid_to_demand": 35362.85834,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "04/02/25 13:00",
    "demand": 34080.92043,
    "solar_generated": 7.055977629,
    "wind_generated": 73.29716579,
    "marketplace_to_demand": 84.28601165,
    "grid_to_demand": 31006.56314,
    "govt_battery_charge": 89.13732215
  },
  {
    "timestamp": "04/02/25 14:00",
    "demand": 32469.39573,
    "solar_generated": 10.47492627,
    "wind_generated": 59.99471536,
    "marketplace_to_demand": 87.48336646,
    "grid_to_demand": 35508.28621,
    "govt_battery_charge": 44.93996561
  },
  {
    "timestamp": "04/02/25 15:00",
    "demand": 33536.70284,
    "solar_generated": 6.108043413,
    "wind_generated": 67.47115966,
    "marketplace_to_demand": 71.30195642,
    "grid_to_demand": 36381.40395,
    "govt_battery_charge": 74.76282499
  },
  {
    "timestamp": "04/02/25 16:00",
    "demand": 35981.92483,
    "solar_generated": 17.20957597,
    "wind_generated": 73.9894032,
    "marketplace_to_demand": 110.6682726,
    "grid_to_demand": 35423.45545,
    "govt_battery_charge": 54.26420875
  },
  {
    "timestamp": "04/02/25 17:00",
    "demand": 32268.67992,
    "solar_generated": 21.88108819,
    "wind_generated": 66.35708884,
    "marketplace_to_demand": 136.8315101,
    "grid_to_demand": 31696.78667,
    "govt_battery_charge": 13.39459303
  },
  {
    "timestamp": "04/02/25 18:00",
    "demand": 32116.65854,
    "solar_generated": 0,
    "wind_generated": 74.23032107,
    "marketplace_to_demand": 9.918596107,
    "grid_to_demand": 36551.08657,
    "govt_battery_charge": 83.21962876
  },
  {
    "timestamp": "04/02/25 19:00",
    "demand": 33783.78668,
    "solar_generated": 0,
    "wind_generated": 65.17325376,
    "marketplace_to_demand": 199.1652585,
    "grid_to_demand": 32744.93695,
    "govt_battery_charge": 1.501389424
  },
  {
    "timestamp": "04/02/25 20:00",
    "demand": 31292.06035,
    "solar_generated": 0,
    "wind_generated": 69.69718538,
    "marketplace_to_demand": 161.8753563,
    "grid_to_demand": 31503.15187,
    "govt_battery_charge": 19.04389443
  },
  {
    "timestamp": "04/02/25 21:00",
    "demand": 30332.08725,
    "solar_generated": 0,
    "wind_generated": 76.52480696,
    "marketplace_to_demand": 43.51409405,
    "grid_to_demand": 31166.72206,
    "govt_battery_charge": 45.23748381
  },
  {
    "timestamp": "04/02/25 22:00",
    "demand": 31782.23303,
    "solar_generated": 0,
    "wind_generated": 74.89838663,
    "marketplace_to_demand": 171.6635768,
    "grid_to_demand": 33544.60731,
    "govt_battery_charge": 23.77413589
  },
  {
    "timestamp": "04/02/25 23:00",
    "demand": 32675.10885,
    "solar_generated": 0,
    "wind_generated": 77.20516856,
    "marketplace_to_demand": 44.853833,
    "grid_to_demand": 31226.92078,
    "govt_battery_charge": 62.66706552
  },
  {
    "timestamp": "05/02/25 0:00",
    "demand": 36385.95121,
    "solar_generated": 0,
    "wind_generated": 76.44460861,
    "marketplace_to_demand": 49.72797804,
    "grid_to_demand": 34740.73119,
    "govt_battery_charge": 99.43645651
  },
  {
    "timestamp": "05/02/25 1:00",
    "demand": 32578.00608,
    "solar_generated": 0,
    "wind_generated": 61.10521642,
    "marketplace_to_demand": 8.206466305,
    "grid_to_demand": 31742.83345,
    "govt_battery_charge": 47.4244624
  },
  {
    "timestamp": "05/02/25 2:00",
    "demand": 35500.60276,
    "solar_generated": 0,
    "wind_generated": 63.32944685,
    "marketplace_to_demand": 9.023896261,
    "grid_to_demand": 33766.34592,
    "govt_battery_charge": 3.026722766
  },
  {
    "timestamp": "05/02/25 3:00",
    "demand": 35785.88768,
    "solar_generated": 0,
    "wind_generated": 62.75339159,
    "marketplace_to_demand": 185.2853741,
    "grid_to_demand": 34501.34985,
    "govt_battery_charge": 45.41240509
  },
  {
    "timestamp": "05/02/25 4:00",
    "demand": 33896.95086,
    "solar_generated": 0,
    "wind_generated": 73.68512628,
    "marketplace_to_demand": 14.49476473,
    "grid_to_demand": 36053.49472,
    "govt_battery_charge": 12.19333261
  },
  {
    "timestamp": "05/02/25 5:00",
    "demand": 35352.10546,
    "solar_generated": 0,
    "wind_generated": 75.39618851,
    "marketplace_to_demand": 157.8005021,
    "grid_to_demand": 35140.5338,
    "govt_battery_charge": 71.28922663
  },
  {
    "timestamp": "05/02/25 6:00",
    "demand": 32157.62617,
    "solar_generated": 16.75713254,
    "wind_generated": 64.50904323,
    "marketplace_to_demand": 20.88164908,
    "grid_to_demand": 31211.33168,
    "govt_battery_charge": 20.2135149
  },
  {
    "timestamp": "05/02/25 7:00",
    "demand": 35784.41844,
    "solar_generated": 8.672773066,
    "wind_generated": 55.2457061,
    "marketplace_to_demand": 158.6368551,
    "grid_to_demand": 34137.98947,
    "govt_battery_charge": 75.58854724
  },
  {
    "timestamp": "05/02/25 8:00",
    "demand": 33892.84879,
    "solar_generated": 10.69769324,
    "wind_generated": 54.18874261,
    "marketplace_to_demand": 31.4263512,
    "grid_to_demand": 32550.65242,
    "govt_battery_charge": 6.554726611
  },
  {
    "timestamp": "05/02/25 9:00",
    "demand": 30872.20836,
    "solar_generated": 23.24510046,
    "wind_generated": 58.56585076,
    "marketplace_to_demand": 127.1441902,
    "grid_to_demand": 36392.60791,
    "govt_battery_charge": 61.71628437
  },
  {
    "timestamp": "05/02/25 10:00",
    "demand": 35158.7799,
    "solar_generated": 13.51719472,
    "wind_generated": 71.19701653,
    "marketplace_to_demand": 138.3001195,
    "grid_to_demand": 35443.16284,
    "govt_battery_charge": 77.97049254
  },
  {
    "timestamp": "05/02/25 11:00",
    "demand": 31469.08398,
    "solar_generated": 15.99743544,
    "wind_generated": 68.00514068,
    "marketplace_to_demand": 120.000809,
    "grid_to_demand": 30956.36502,
    "govt_battery_charge": 91.59073106
  },
  {
    "timestamp": "05/02/25 12:00",
    "demand": 33502.62222,
    "solar_generated": 16.16915872,
    "wind_generated": 66.92529441,
    "marketplace_to_demand": 129.3196397,
    "grid_to_demand": 32019.23511,
    "govt_battery_charge": 55.16508224
  },
  {
    "timestamp": "05/02/25 13:00",
    "demand": 35096.65709,
    "solar_generated": 19.62193105,
    "wind_generated": 63.51300956,
    "marketplace_to_demand": 110.6968124,
    "grid_to_demand": 30320.33166,
    "govt_battery_charge": 43.11876265
  },
  {
    "timestamp": "05/02/25 14:00",
    "demand": 31763.19026,
    "solar_generated": 20.82066569,
    "wind_generated": 53.79401244,
    "marketplace_to_demand": 156.0789205,
    "grid_to_demand": 34560.29327,
    "govt_battery_charge": 1.337192784
  },
  {
    "timestamp": "05/02/25 15:00",
    "demand": 34209.03736,
    "solar_generated": 22.18627656,
    "wind_generated": 80.31789483,
    "marketplace_to_demand": 14.6861649,
    "grid_to_demand": 30528.93548,
    "govt_battery_charge": 57.75303804
  },
  {
    "timestamp": "05/02/25 16:00",
    "demand": 32278.50858,
    "solar_generated": 12.69670661,
    "wind_generated": 53.89398443,
    "marketplace_to_demand": 187.4534697,
    "grid_to_demand": 36066.17237,
    "govt_battery_charge": 91.02863859
  },
  {
    "timestamp": "05/02/25 17:00",
    "demand": 33251.37772,
    "solar_generated": 13.85914893,
    "wind_generated": 73.08738172,
    "marketplace_to_demand": 47.12476827,
    "grid_to_demand": 34059.81469,
    "govt_battery_charge": 11.19156765
  },
  {
    "timestamp": "05/02/25 18:00",
    "demand": 30873.60619,
    "solar_generated": 0,
    "wind_generated": 78.21537494,
    "marketplace_to_demand": 164.1085515,
    "grid_to_demand": 30321.72847,
    "govt_battery_charge": 62.49777629
  },
  {
    "timestamp": "05/02/25 19:00",
    "demand": 32223.30925,
    "solar_generated": 0,
    "wind_generated": 56.45167507,
    "marketplace_to_demand": 131.9670859,
    "grid_to_demand": 34051.79758,
    "govt_battery_charge": 8.529056056
  },
  {
    "timestamp": "05/02/25 20:00",
    "demand": 34383.57118,
    "solar_generated": 0,
    "wind_generated": 67.34435181,
    "marketplace_to_demand": 159.9411368,
    "grid_to_demand": 36454.86395,
    "govt_battery_charge": 67.34942977
  },
  {
    "timestamp": "05/02/25 21:00",
    "demand": 32262.08313,
    "solar_generated": 0,
    "wind_generated": 72.20561921,
    "marketplace_to_demand": 140.3202842,
    "grid_to_demand": 32413.34127,
    "govt_battery_charge": 40.66941125
  },
  {
    "timestamp": "05/02/25 22:00",
    "demand": 35187.14649,
    "solar_generated": 0,
    "wind_generated": 63.03546749,
    "marketplace_to_demand": 154.1770033,
    "grid_to_demand": 30337.03764,
    "govt_battery_charge": 87.35758966
  },
  {
    "timestamp": "05/02/25 23:00",
    "demand": 32550.07816,
    "solar_generated": 0,
    "wind_generated": 64.94676087,
    "marketplace_to_demand": 85.660861,
    "grid_to_demand": 34840.52769,
    "govt_battery_charge": 23.23855252
  },
  {
    "timestamp": "06/02/25 0:00",
    "demand": 31402.59499,
    "solar_generated": 0,
    "wind_generated": 79.48339302,
    "marketplace_to_demand": 110.9877601,
    "grid_to_demand": 35381.83602,
    "govt_battery_charge": 90.65755895
  },
  {
    "timestamp": "06/02/25 1:00",
    "demand": 30310.71349,
    "solar_generated": 0,
    "wind_generated": 62.6485166,
    "marketplace_to_demand": 86.46903068,
    "grid_to_demand": 34982.62576,
    "govt_battery_charge": 25.9978011
  },
  {
    "timestamp": "06/02/25 2:00",
    "demand": 36079.54827,
    "solar_generated": 0,
    "wind_generated": 57.97189677,
    "marketplace_to_demand": 91.17803778,
    "grid_to_demand": 31799.93873,
    "govt_battery_charge": 66.24920864
  },
  {
    "timestamp": "06/02/25 3:00",
    "demand": 35382.03569,
    "solar_generated": 0,
    "wind_generated": 70.12506689,
    "marketplace_to_demand": 17.55260764,
    "grid_to_demand": 32500.27212,
    "govt_battery_charge": 69.30655017
  },
  {
    "timestamp": "06/02/25 4:00",
    "demand": 32169.7287,
    "solar_generated": 0,
    "wind_generated": 70.35067385,
    "marketplace_to_demand": 59.02256679,
    "grid_to_demand": 31543.32943,
    "govt_battery_charge": 87.23690078
  },
  {
    "timestamp": "06/02/25 5:00",
    "demand": 35078.67261,
    "solar_generated": 0,
    "wind_generated": 63.29356451,
    "marketplace_to_demand": 185.9898692,
    "grid_to_demand": 35479.97029,
    "govt_battery_charge": 49.91055892
  },
  {
    "timestamp": "06/02/25 6:00",
    "demand": 31172.12359,
    "solar_generated": 10.84266548,
    "wind_generated": 65.48541878,
    "marketplace_to_demand": 188.8924622,
    "grid_to_demand": 32582.75053,
    "govt_battery_charge": 6.30054575
  },
  {
    "timestamp": "06/02/25 7:00",
    "demand": 31316.20604,
    "solar_generated": 11.48978673,
    "wind_generated": 66.3776353,
    "marketplace_to_demand": 121.575964,
    "grid_to_demand": 34609.99294,
    "govt_battery_charge": 44.12490647
  },
  {
    "timestamp": "06/02/25 8:00",
    "demand": 33442.1305,
    "solar_generated": 21.7701395,
    "wind_generated": 76.25894388,
    "marketplace_to_demand": 64.84475879,
    "grid_to_demand": 31549.59439,
    "govt_battery_charge": 36.59067706
  },
  {
    "timestamp": "06/02/25 9:00",
    "demand": 31405.58314,
    "solar_generated": 18.48188568,
    "wind_generated": 78.53829717,
    "marketplace_to_demand": 188.174787,
    "grid_to_demand": 33375.37826,
    "govt_battery_charge": 9.76004668
  },
  {
    "timestamp": "06/02/25 10:00",
    "demand": 33701.95956,
    "solar_generated": 6.391765248,
    "wind_generated": 76.33386343,
    "marketplace_to_demand": 44.76835047,
    "grid_to_demand": 30776.38957,
    "govt_battery_charge": 17.3700159
  },
  {
    "timestamp": "06/02/25 11:00",
    "demand": 34949.13447,
    "solar_generated": 20.729078,
    "wind_generated": 75.88698943,
    "marketplace_to_demand": 185.656007,
    "grid_to_demand": 30246.26134,
    "govt_battery_charge": 87.49914913
  },
  {
    "timestamp": "06/02/25 12:00",
    "demand": 31763.03166,
    "solar_generated": 7.718570558,
    "wind_generated": 54.16826471,
    "marketplace_to_demand": 30.98676474,
    "grid_to_demand": 35214.44123,
    "govt_battery_charge": 93.23133929
  },
  {
    "timestamp": "06/02/25 13:00",
    "demand": 35095.33371,
    "solar_generated": 18.58212784,
    "wind_generated": 59.11214761,
    "marketplace_to_demand": 110.9046255,
    "grid_to_demand": 31867.74812,
    "govt_battery_charge": 90.85189555
  },
  {
    "timestamp": "06/02/25 14:00",
    "demand": 34252.75682,
    "solar_generated": 10.02575084,
    "wind_generated": 62.9288855,
    "marketplace_to_demand": 91.71419743,
    "grid_to_demand": 36100.95445,
    "govt_battery_charge": 12.0875767
  },
  {
    "timestamp": "06/02/25 15:00",
    "demand": 32169.19042,
    "solar_generated": 20.84918429,
    "wind_generated": 74.7680101,
    "marketplace_to_demand": 132.8696242,
    "grid_to_demand": 35034.63999,
    "govt_battery_charge": 12.6150802
  },
  {
    "timestamp": "06/02/25 16:00",
    "demand": 30368.25021,
    "solar_generated": 12.39669944,
    "wind_generated": 71.4316456,
    "marketplace_to_demand": 192.924098,
    "grid_to_demand": 34309.52489,
    "govt_battery_charge": 49.92390174
  },
  {
    "timestamp": "06/02/25 17:00",
    "demand": 30595.38755,
    "solar_generated": 20.3914516,
    "wind_generated": 65.98647602,
    "marketplace_to_demand": 75.57016824,
    "grid_to_demand": 32750.48011,
    "govt_battery_charge": 23.46853738
  },
  {
    "timestamp": "06/02/25 18:00",
    "demand": 36387.72949,
    "solar_generated": 0,
    "wind_generated": 80.2916517,
    "marketplace_to_demand": 68.32210095,
    "grid_to_demand": 36486.68452,
    "govt_battery_charge": 87.66806546
  },
  {
    "timestamp": "06/02/25 19:00",
    "demand": 34241.56962,
    "solar_generated": 0,
    "wind_generated": 53.7180144,
    "marketplace_to_demand": 186.1863532,
    "grid_to_demand": 34825.00234,
    "govt_battery_charge": 8.199690207
  },
  {
    "timestamp": "06/02/25 20:00",
    "demand": 30447.22457,
    "solar_generated": 0,
    "wind_generated": 55.92311561,
    "marketplace_to_demand": 26.23314727,
    "grid_to_demand": 34408.04464,
    "govt_battery_charge": 63.61300422
  },
  {
    "timestamp": "06/02/25 21:00",
    "demand": 30387.47308,
    "solar_generated": 0,
    "wind_generated": 55.91464765,
    "marketplace_to_demand": 125.3503892,
    "grid_to_demand": 33861.63802,
    "govt_battery_charge": 88.20309415
  },
  {
    "timestamp": "06/02/25 22:00",
    "demand": 34625.12566,
    "solar_generated": 0,
    "wind_generated": 57.86843048,
    "marketplace_to_demand": 170.6342829,
    "grid_to_demand": 32648.34226,
    "govt_battery_charge": 32.02591419
  },
  {
    "timestamp": "06/02/25 23:00",
    "demand": 35933.94845,
    "solar_generated": 0,
    "wind_generated": 59.78629252,
    "marketplace_to_demand": 150.4634122,
    "grid_to_demand": 31968.52067,
    "govt_battery_charge": 84.17271427
  },
  {
    "timestamp": "07/02/25 0:00",
    "demand": 32454.46366,
    "solar_generated": 0,
    "wind_generated": 70.14392541,
    "marketplace_to_demand": 147.9032422,
    "grid_to_demand": 33113.30596,
    "govt_battery_charge": 60.28097181
  },
  {
    "timestamp": "07/02/25 1:00",
    "demand": 30239.93891,
    "solar_generated": 0,
    "wind_generated": 63.27396906,
    "marketplace_to_demand": 9.70112848,
    "grid_to_demand": 31120.00357,
    "govt_battery_charge": 16.6035052
  },
  {
    "timestamp": "07/02/25 2:00",
    "demand": 30854.67437,
    "solar_generated": 0,
    "wind_generated": 76.19006072,
    "marketplace_to_demand": 80.17900382,
    "grid_to_demand": 36126.68618,
    "govt_battery_charge": 43.97244426
  },
  {
    "timestamp": "07/02/25 3:00",
    "demand": 31429.40807,
    "solar_generated": 0,
    "wind_generated": 61.62951668,
    "marketplace_to_demand": 20.01768917,
    "grid_to_demand": 32944.73665,
    "govt_battery_charge": 99.0687448
  },
  {
    "timestamp": "07/02/25 4:00",
    "demand": 35600.63,
    "solar_generated": 0,
    "wind_generated": 74.75514262,
    "marketplace_to_demand": 153.3302977,
    "grid_to_demand": 35456.74355,
    "govt_battery_charge": 8.779548246
  },
  {
    "timestamp": "07/02/25 5:00",
    "demand": 32064.803,
    "solar_generated": 0,
    "wind_generated": 65.11651193,
    "marketplace_to_demand": 191.6889272,
    "grid_to_demand": 32211.0077,
    "govt_battery_charge": 16.2011333
  },
  {
    "timestamp": "07/02/25 6:00",
    "demand": 32934.73234,
    "solar_generated": 11.41667763,
    "wind_generated": 63.94466738,
    "marketplace_to_demand": 91.90486038,
    "grid_to_demand": 30646.64113,
    "govt_battery_charge": 41.8827629
  },
  {
    "timestamp": "07/02/25 7:00",
    "demand": 32327.98347,
    "solar_generated": 16.99962593,
    "wind_generated": 79.58543908,
    "marketplace_to_demand": 29.56606782,
    "grid_to_demand": 33257.59735,
    "govt_battery_charge": 30.05304168
  },
  {
    "timestamp": "07/02/25 8:00",
    "demand": 35916.85198,
    "solar_generated": 22.47186328,
    "wind_generated": 54.30805809,
    "marketplace_to_demand": 118.760065,
    "grid_to_demand": 35346.17362,
    "govt_battery_charge": 25.70610711
  },
  {
    "timestamp": "07/02/25 9:00",
    "demand": 35352.78629,
    "solar_generated": 12.4597006,
    "wind_generated": 71.41848983,
    "marketplace_to_demand": 123.7760738,
    "grid_to_demand": 33554.19056,
    "govt_battery_charge": 81.56408344
  },
  {
    "timestamp": "07/02/25 10:00",
    "demand": 34234.92198,
    "solar_generated": 11.46056436,
    "wind_generated": 77.46413974,
    "marketplace_to_demand": 107.0895149,
    "grid_to_demand": 34838.74698,
    "govt_battery_charge": 2.878644697
  },
  {
    "timestamp": "07/02/25 11:00",
    "demand": 30540.35186,
    "solar_generated": 23.99927247,
    "wind_generated": 74.66044147,
    "marketplace_to_demand": 141.7828765,
    "grid_to_demand": 31267.97783,
    "govt_battery_charge": 69.9072668
  },
  {
    "timestamp": "07/02/25 12:00",
    "demand": 35410.93907,
    "solar_generated": 11.31592856,
    "wind_generated": 65.1043998,
    "marketplace_to_demand": 146.495538,
    "grid_to_demand": 35313.76155,
    "govt_battery_charge": 79.9346044
  },
  {
    "timestamp": "07/02/25 13:00",
    "demand": 32776.04624,
    "solar_generated": 18.81380982,
    "wind_generated": 61.99005727,
    "marketplace_to_demand": 145.9168019,
    "grid_to_demand": 32164.97129,
    "govt_battery_charge": 44.90703263
  },
  {
    "timestamp": "07/02/25 14:00",
    "demand": 31505.68492,
    "solar_generated": 10.9142859,
    "wind_generated": 53.66262545,
    "marketplace_to_demand": 94.76887049,
    "grid_to_demand": 31060.72547,
    "govt_battery_charge": 62.29052441
  },
  {
    "timestamp": "07/02/25 15:00",
    "demand": 31565.95843,
    "solar_generated": 20.88332506,
    "wind_generated": 68.19219716,
    "marketplace_to_demand": 108.6381585,
    "grid_to_demand": 34635.68776,
    "govt_battery_charge": 87.33767074
  },
  {
    "timestamp": "07/02/25 16:00",
    "demand": 30728.75507,
    "solar_generated": 17.82017696,
    "wind_generated": 59.77788059,
    "marketplace_to_demand": 8.187416166,
    "grid_to_demand": 34141.82272,
    "govt_battery_charge": 72.05279085
  },
  {
    "timestamp": "07/02/25 17:00",
    "demand": 34495.5965,
    "solar_generated": 8.428026215,
    "wind_generated": 54.1925919,
    "marketplace_to_demand": 21.08945613,
    "grid_to_demand": 31239.24826,
    "govt_battery_charge": 45.5490194
  },
  {
    "timestamp": "07/02/25 18:00",
    "demand": 30933.48685,
    "solar_generated": 0,
    "wind_generated": 67.19932518,
    "marketplace_to_demand": 72.05598531,
    "grid_to_demand": 35442.29208,
    "govt_battery_charge": 70.28998149
  },
  {
    "timestamp": "07/02/25 19:00",
    "demand": 35038.77295,
    "solar_generated": 0,
    "wind_generated": 69.09174103,
    "marketplace_to_demand": 108.9522466,
    "grid_to_demand": 32455.37938,
    "govt_battery_charge": 63.56541257
  },
  {
    "timestamp": "07/02/25 20:00",
    "demand": 34529.61528,
    "solar_generated": 0,
    "wind_generated": 74.09885087,
    "marketplace_to_demand": 68.7089723,
    "grid_to_demand": 34361.01074,
    "govt_battery_charge": 20.89485841
  },
  {
    "timestamp": "07/02/25 21:00",
    "demand": 32684.82973,
    "solar_generated": 0,
    "wind_generated": 62.31228817,
    "marketplace_to_demand": 167.3127352,
    "grid_to_demand": 33919.4542,
    "govt_battery_charge": 18.16225846
  },
  {
    "timestamp": "07/02/25 22:00",
    "demand": 35796.30025,
    "solar_generated": 0,
    "wind_generated": 79.6414655,
    "marketplace_to_demand": 132.2130493,
    "grid_to_demand": 30420.96457,
    "govt_battery_charge": 73.42779705
  },
  {
    "timestamp": "07/02/25 23:00",
    "demand": 35929.5514,
    "solar_generated": 0,
    "wind_generated": 70.95938511,
    "marketplace_to_demand": 165.6281515,
    "grid_to_demand": 31086.94402,
    "govt_battery_charge": 72.66211095
  },
  {
    "timestamp": "08/02/25 0:00",
    "demand": 30939.59253,
    "solar_generated": 0,
    "wind_generated": 56.99729801,
    "marketplace_to_demand": 61.35013777,
    "grid_to_demand": 32560.42628,
    "govt_battery_charge": 99.53410297
  },
  {
    "timestamp": "08/02/25 1:00",
    "demand": 30012.17001,
    "solar_generated": 0,
    "wind_generated": 54.77149714,
    "marketplace_to_demand": 74.6108102,
    "grid_to_demand": 33158.29422,
    "govt_battery_charge": 95.27187702
  },
  {
    "timestamp": "08/02/25 2:00",
    "demand": 35714.35829,
    "solar_generated": 0,
    "wind_generated": 79.37959387,
    "marketplace_to_demand": 10.9201043,
    "grid_to_demand": 36440.45338,
    "govt_battery_charge": 71.59230605
  },
  {
    "timestamp": "08/02/25 3:00",
    "demand": 35516.61416,
    "solar_generated": 0,
    "wind_generated": 66.49601955,
    "marketplace_to_demand": 197.1141047,
    "grid_to_demand": 35271.26491,
    "govt_battery_charge": 25.13183791
  },
  {
    "timestamp": "08/02/25 4:00",
    "demand": 31759.87726,
    "solar_generated": 0,
    "wind_generated": 72.77853962,
    "marketplace_to_demand": 18.65120643,
    "grid_to_demand": 32361.51072,
    "govt_battery_charge": 88.93277149
  },
  {
    "timestamp": "08/02/25 5:00",
    "demand": 35031.40382,
    "solar_generated": 0,
    "wind_generated": 73.90618599,
    "marketplace_to_demand": 125.9653494,
    "grid_to_demand": 34960.03651,
    "govt_battery_charge": 52.13479699
  },
  {
    "timestamp": "08/02/25 6:00",
    "demand": 32144.70521,
    "solar_generated": 23.72828631,
    "wind_generated": 78.04496117,
    "marketplace_to_demand": 2.903084617,
    "grid_to_demand": 31768.69033,
    "govt_battery_charge": 71.33968141
  },
  {
    "timestamp": "08/02/25 7:00",
    "demand": 33410.09788,
    "solar_generated": 18.29335257,
    "wind_generated": 53.80741264,
    "marketplace_to_demand": 176.0622534,
    "grid_to_demand": 34610.89849,
    "govt_battery_charge": 36.44141893
  },
  {
    "timestamp": "08/02/25 8:00",
    "demand": 35829.39458,
    "solar_generated": 9.811724464,
    "wind_generated": 71.04745563,
    "marketplace_to_demand": 23.42876678,
    "grid_to_demand": 36084.51029,
    "govt_battery_charge": 84.59237003
  },
  {
    "timestamp": "08/02/25 9:00",
    "demand": 33904.73405,
    "solar_generated": 23.3336505,
    "wind_generated": 77.58295588,
    "marketplace_to_demand": 48.86064037,
    "grid_to_demand": 35160.47631,
    "govt_battery_charge": 66.36363575
  },
  {
    "timestamp": "08/02/25 10:00",
    "demand": 35956.82608,
    "solar_generated": 23.44148809,
    "wind_generated": 77.58850016,
    "marketplace_to_demand": 195.6649464,
    "grid_to_demand": 33893.91904,
    "govt_battery_charge": 15.25956178
  },
  {
    "timestamp": "08/02/25 11:00",
    "demand": 33775.19584,
    "solar_generated": 20.05323088,
    "wind_generated": 54.39583213,
    "marketplace_to_demand": 63.42802844,
    "grid_to_demand": 32090.09499,
    "govt_battery_charge": 98.86849298
  },
  {
    "timestamp": "08/02/25 12:00",
    "demand": 31859.89914,
    "solar_generated": 8.684392095,
    "wind_generated": 66.06768473,
    "marketplace_to_demand": 135.3366393,
    "grid_to_demand": 33127.64328,
    "govt_battery_charge": 8.32845338
  },
  {
    "timestamp": "08/02/25 13:00",
    "demand": 35422.01341,
    "solar_generated": 15.77633506,
    "wind_generated": 56.12261852,
    "marketplace_to_demand": 8.74160819,
    "grid_to_demand": 31374.54592,
    "govt_battery_charge": 71.55739464
  },
  {
    "timestamp": "08/02/25 14:00",
    "demand": 30696.1797,
    "solar_generated": 9.240307267,
    "wind_generated": 64.79181791,
    "marketplace_to_demand": 171.4406392,
    "grid_to_demand": 32418.00696,
    "govt_battery_charge": 76.45062945
  },
  {
    "timestamp": "08/02/25 15:00",
    "demand": 30357.23668,
    "solar_generated": 16.14851852,
    "wind_generated": 78.87710722,
    "marketplace_to_demand": 182.3840295,
    "grid_to_demand": 33724.11392,
    "govt_battery_charge": 89.60395977
  },
  {
    "timestamp": "08/02/25 16:00",
    "demand": 36015.60361,
    "solar_generated": 13.64458368,
    "wind_generated": 73.6601935,
    "marketplace_to_demand": 129.6771058,
    "grid_to_demand": 32107.68615,
    "govt_battery_charge": 28.22950965
  },
  {
    "timestamp": "08/02/25 17:00",
    "demand": 34124.77519,
    "solar_generated": 7.338317108,
    "wind_generated": 61.89148523,
    "marketplace_to_demand": 148.7619499,
    "grid_to_demand": 34593.87513,
    "govt_battery_charge": 59.19981542
  },
  {
    "timestamp": "08/02/25 18:00",
    "demand": 35502.7342,
    "solar_generated": 0,
    "wind_generated": 78.49851465,
    "marketplace_to_demand": 124.7334449,
    "grid_to_demand": 36063.23583,
    "govt_battery_charge": 99.89204101
  },
  {
    "timestamp": "08/02/25 19:00",
    "demand": 32643.43548,
    "solar_generated": 0,
    "wind_generated": 55.30476753,
    "marketplace_to_demand": 96.99527018,
    "grid_to_demand": 35811.82277,
    "govt_battery_charge": 36.86243328
  },
  {
    "timestamp": "08/02/25 20:00",
    "demand": 36017.3817,
    "solar_generated": 0,
    "wind_generated": 65.84667714,
    "marketplace_to_demand": 46.69567276,
    "grid_to_demand": 34288.87228,
    "govt_battery_charge": 8.886036337
  },
  {
    "timestamp": "08/02/25 21:00",
    "demand": 34497.25425,
    "solar_generated": 0,
    "wind_generated": 58.28656296,
    "marketplace_to_demand": 138.6320514,
    "grid_to_demand": 36307.84027,
    "govt_battery_charge": 58.20985825
  },
  {
    "timestamp": "08/02/25 22:00",
    "demand": 34019.45124,
    "solar_generated": 0,
    "wind_generated": 80.12224394,
    "marketplace_to_demand": 182.5888759,
    "grid_to_demand": 32855.67583,
    "govt_battery_charge": 66.23647406
  },
  {
    "timestamp": "08/02/25 23:00",
    "demand": 34336.64267,
    "solar_generated": 0,
    "wind_generated": 59.25019053,
    "marketplace_to_demand": 11.09313592,
    "grid_to_demand": 32066.18453,
    "govt_battery_charge": 30.31271665
  },
  {
    "timestamp": "09/02/25 0:00",
    "demand": 33550.08247,
    "solar_generated": 0,
    "wind_generated": 60.38667661,
    "marketplace_to_demand": 84.83471524,
    "grid_to_demand": 34421.52181,
    "govt_battery_charge": 53.21826436
  },
  {
    "timestamp": "09/02/25 1:00",
    "demand": 31189.31568,
    "solar_generated": 0,
    "wind_generated": 68.14531643,
    "marketplace_to_demand": 102.3352942,
    "grid_to_demand": 34884.70665,
    "govt_battery_charge": 59.55706408
  },
  {
    "timestamp": "09/02/25 2:00",
    "demand": 34231.00206,
    "solar_generated": 0,
    "wind_generated": 70.99629939,
    "marketplace_to_demand": 16.15591108,
    "grid_to_demand": 34491.64456,
    "govt_battery_charge": 64.04047245
  },
  {
    "timestamp": "09/02/25 3:00",
    "demand": 34275.14539,
    "solar_generated": 0,
    "wind_generated": 56.19716338,
    "marketplace_to_demand": 53.0921101,
    "grid_to_demand": 30332.9059,
    "govt_battery_charge": 55.52876152
  },
  {
    "timestamp": "09/02/25 4:00",
    "demand": 31189.56378,
    "solar_generated": 0,
    "wind_generated": 72.55475715,
    "marketplace_to_demand": 31.33665549,
    "grid_to_demand": 35198.5548,
    "govt_battery_charge": 64.19543352
  },
  {
    "timestamp": "09/02/25 5:00",
    "demand": 35795.34984,
    "solar_generated": 0,
    "wind_generated": 69.5348516,
    "marketplace_to_demand": 176.5979794,
    "grid_to_demand": 34005.46775,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/02/25 6:00",
    "demand": 35596.14327,
    "solar_generated": 19.90071892,
    "wind_generated": 57.55661408,
    "marketplace_to_demand": 157.4711901,
    "grid_to_demand": 34812.37595,
    "govt_battery_charge": 85.16150891
  },
  {
    "timestamp": "09/02/25 7:00",
    "demand": 31209.38779,
    "solar_generated": 8.479143151,
    "wind_generated": 72.04444009,
    "marketplace_to_demand": 54.25143605,
    "grid_to_demand": 35932.88631,
    "govt_battery_charge": 58.87715977
  },
  {
    "timestamp": "09/02/25 8:00",
    "demand": 30929.21553,
    "solar_generated": 10.38355456,
    "wind_generated": 57.89848122,
    "marketplace_to_demand": 47.42692339,
    "grid_to_demand": 36043.39632,
    "govt_battery_charge": 84.56604474
  },
  {
    "timestamp": "09/02/25 9:00",
    "demand": 32002.00051,
    "solar_generated": 20.70669044,
    "wind_generated": 68.93989615,
    "marketplace_to_demand": 60.43257348,
    "grid_to_demand": 32397.07772,
    "govt_battery_charge": 62.42289672
  },
  {
    "timestamp": "09/02/25 10:00",
    "demand": 36192.33635,
    "solar_generated": 6.069427822,
    "wind_generated": 62.23225482,
    "marketplace_to_demand": 98.78336153,
    "grid_to_demand": 35302.36001,
    "govt_battery_charge": 18.02801748
  },
  {
    "timestamp": "09/02/25 11:00",
    "demand": 36128.98219,
    "solar_generated": 8.206991845,
    "wind_generated": 67.05142315,
    "marketplace_to_demand": 70.86210864,
    "grid_to_demand": 30494.5369,
    "govt_battery_charge": 32.11595895
  },
  {
    "timestamp": "09/02/25 12:00",
    "demand": 36293.75897,
    "solar_generated": 23.46841859,
    "wind_generated": 74.27951171,
    "marketplace_to_demand": 122.8270982,
    "grid_to_demand": 34467.53519,
    "govt_battery_charge": 94.62151698
  },
  {
    "timestamp": "09/02/25 13:00",
    "demand": 33796.61855,
    "solar_generated": 18.48128369,
    "wind_generated": 75.95905711,
    "marketplace_to_demand": 138.4280147,
    "grid_to_demand": 32799.59625,
    "govt_battery_charge": 17.13631538
  },
  {
    "timestamp": "09/02/25 14:00",
    "demand": 30843.7911,
    "solar_generated": 18.48286824,
    "wind_generated": 77.63538536,
    "marketplace_to_demand": 150.7480368,
    "grid_to_demand": 32823.95559,
    "govt_battery_charge": 20.79616276
  },
  {
    "timestamp": "09/02/25 15:00",
    "demand": 31887.31,
    "solar_generated": 15.09679628,
    "wind_generated": 59.07102844,
    "marketplace_to_demand": 120.1649832,
    "grid_to_demand": 30657.3854,
    "govt_battery_charge": 91.80265008
  },
  {
    "timestamp": "09/02/25 16:00",
    "demand": 32202.13979,
    "solar_generated": 15.17526837,
    "wind_generated": 59.64444262,
    "marketplace_to_demand": 16.89316964,
    "grid_to_demand": 33998.52473,
    "govt_battery_charge": 85.03908791
  },
  {
    "timestamp": "09/02/25 17:00",
    "demand": 33520.25803,
    "solar_generated": 12.73202153,
    "wind_generated": 77.13417391,
    "marketplace_to_demand": 183.1511478,
    "grid_to_demand": 33981.29457,
    "govt_battery_charge": 29.96872371
  },
  {
    "timestamp": "09/02/25 18:00",
    "demand": 30969.16734,
    "solar_generated": 0,
    "wind_generated": 72.77950585,
    "marketplace_to_demand": 94.77527825,
    "grid_to_demand": 31839.15886,
    "govt_battery_charge": 68.59421058
  },
  {
    "timestamp": "09/02/25 19:00",
    "demand": 33093.30882,
    "solar_generated": 0,
    "wind_generated": 70.13906771,
    "marketplace_to_demand": 67.04736739,
    "grid_to_demand": 30513.82553,
    "govt_battery_charge": 19.52234746
  },
  {
    "timestamp": "09/02/25 20:00",
    "demand": 36426.65939,
    "solar_generated": 0,
    "wind_generated": 78.30284144,
    "marketplace_to_demand": 100.9861088,
    "grid_to_demand": 35194.66615,
    "govt_battery_charge": 7.605274952
  },
  {
    "timestamp": "09/02/25 21:00",
    "demand": 31689.04956,
    "solar_generated": 0,
    "wind_generated": 71.70580625,
    "marketplace_to_demand": 82.5631786,
    "grid_to_demand": 32900.21018,
    "govt_battery_charge": 44.08777768
  },
  {
    "timestamp": "09/02/25 22:00",
    "demand": 35976.5775,
    "solar_generated": 0,
    "wind_generated": 77.38834564,
    "marketplace_to_demand": 157.9672869,
    "grid_to_demand": 36532.10894,
    "govt_battery_charge": 25.27048319
  },
  {
    "timestamp": "09/02/25 23:00",
    "demand": 35001.2299,
    "solar_generated": 0,
    "wind_generated": 79.12057331,
    "marketplace_to_demand": 145.7664473,
    "grid_to_demand": 32491.09631,
    "govt_battery_charge": 9.47887953
  },
  {
    "timestamp": "10/02/25 0:00",
    "demand": 34138.36728,
    "solar_generated": 0,
    "wind_generated": 62.57670431,
    "marketplace_to_demand": 155.7109396,
    "grid_to_demand": 35043.59207,
    "govt_battery_charge": 71.61386651
  },
  {
    "timestamp": "10/02/25 1:00",
    "demand": 34668.82343,
    "solar_generated": 0,
    "wind_generated": 69.75113109,
    "marketplace_to_demand": 65.45967047,
    "grid_to_demand": 33599.20177,
    "govt_battery_charge": 1.203923129
  },
  {
    "timestamp": "10/02/25 2:00",
    "demand": 33039.37255,
    "solar_generated": 0,
    "wind_generated": 55.38381715,
    "marketplace_to_demand": 158.5848242,
    "grid_to_demand": 30204.65053,
    "govt_battery_charge": 41.39740661
  },
  {
    "timestamp": "10/02/25 3:00",
    "demand": 32604.83882,
    "solar_generated": 0,
    "wind_generated": 72.70954135,
    "marketplace_to_demand": 128.4616678,
    "grid_to_demand": 34626.5051,
    "govt_battery_charge": 59.47130582
  },
  {
    "timestamp": "10/02/25 4:00",
    "demand": 30715.29346,
    "solar_generated": 0,
    "wind_generated": 61.57881782,
    "marketplace_to_demand": 179.1350705,
    "grid_to_demand": 34399.40755,
    "govt_battery_charge": 79.20608895
  },
  {
    "timestamp": "10/02/25 5:00",
    "demand": 34644.53209,
    "solar_generated": 0,
    "wind_generated": 64.44566185,
    "marketplace_to_demand": 187.1067518,
    "grid_to_demand": 35521.7555,
    "govt_battery_charge": 8.827317164
  },
  {
    "timestamp": "10/02/25 6:00",
    "demand": 36582.16824,
    "solar_generated": 8.293266467,
    "wind_generated": 62.70508084,
    "marketplace_to_demand": 6.176003274,
    "grid_to_demand": 30093.38048,
    "govt_battery_charge": 59.86244926
  },
  {
    "timestamp": "10/02/25 7:00",
    "demand": 32233.88191,
    "solar_generated": 12.04744792,
    "wind_generated": 65.7808549,
    "marketplace_to_demand": 107.1492978,
    "grid_to_demand": 31220.33206,
    "govt_battery_charge": 41.2199325
  },
  {
    "timestamp": "10/02/25 8:00",
    "demand": 30392.0268,
    "solar_generated": 21.5115743,
    "wind_generated": 58.53664738,
    "marketplace_to_demand": 136.2024519,
    "grid_to_demand": 33696.15775,
    "govt_battery_charge": 42.71866492
  },
  {
    "timestamp": "10/02/25 9:00",
    "demand": 36498.58843,
    "solar_generated": 21.98998448,
    "wind_generated": 60.81938299,
    "marketplace_to_demand": 180.9999691,
    "grid_to_demand": 31372.81353,
    "govt_battery_charge": 79.11438903
  },
  {
    "timestamp": "10/02/25 10:00",
    "demand": 31079.98732,
    "solar_generated": 23.36940657,
    "wind_generated": 55.21068164,
    "marketplace_to_demand": 28.24339327,
    "grid_to_demand": 33432.56331,
    "govt_battery_charge": 23.2596719
  },
  {
    "timestamp": "10/02/25 11:00",
    "demand": 30747.52092,
    "solar_generated": 18.9663432,
    "wind_generated": 58.88036516,
    "marketplace_to_demand": 120.9721724,
    "grid_to_demand": 31190.5722,
    "govt_battery_charge": 29.5100757
  },
  {
    "timestamp": "10/02/25 12:00",
    "demand": 33933.08054,
    "solar_generated": 12.3276405,
    "wind_generated": 62.18549425,
    "marketplace_to_demand": 175.1571206,
    "grid_to_demand": 29934.58118,
    "govt_battery_charge": 83.57568286
  },
  {
    "timestamp": "10/02/25 13:00",
    "demand": 32700.14734,
    "solar_generated": 8.388169841,
    "wind_generated": 77.97726713,
    "marketplace_to_demand": 162.3408064,
    "grid_to_demand": 35009.43835,
    "govt_battery_charge": 8.469760712
  },
  {
    "timestamp": "10/02/25 14:00",
    "demand": 31340.65894,
    "solar_generated": 17.62323275,
    "wind_generated": 59.93079921,
    "marketplace_to_demand": 41.38098673,
    "grid_to_demand": 30222.44681,
    "govt_battery_charge": 93.69688832
  },
  {
    "timestamp": "10/02/25 15:00",
    "demand": 36202.94551,
    "solar_generated": 16.21835471,
    "wind_generated": 54.97165345,
    "marketplace_to_demand": 93.93387744,
    "grid_to_demand": 36406.55185,
    "govt_battery_charge": 41.19066262
  },
  {
    "timestamp": "10/02/25 16:00",
    "demand": 35718.60965,
    "solar_generated": 6.772811411,
    "wind_generated": 77.447051,
    "marketplace_to_demand": 87.20758354,
    "grid_to_demand": 33097.16277,
    "govt_battery_charge": 40.45457735
  },
  {
    "timestamp": "10/02/25 17:00",
    "demand": 30203.81806,
    "solar_generated": 22.84386286,
    "wind_generated": 64.43373291,
    "marketplace_to_demand": 87.15951227,
    "grid_to_demand": 31449.96571,
    "govt_battery_charge": 73.54619219
  },
  {
    "timestamp": "10/02/25 18:00",
    "demand": 35799.17195,
    "solar_generated": 0,
    "wind_generated": 76.62462881,
    "marketplace_to_demand": 34.81488411,
    "grid_to_demand": 31653.27586,
    "govt_battery_charge": 91.94463723
  },
  {
    "timestamp": "10/02/25 19:00",
    "demand": 34930.18602,
    "solar_generated": 0,
    "wind_generated": 72.75642514,
    "marketplace_to_demand": 14.4984762,
    "grid_to_demand": 35677.0768,
    "govt_battery_charge": 15.91201288
  },
  {
    "timestamp": "10/02/25 20:00",
    "demand": 35441.55439,
    "solar_generated": 0,
    "wind_generated": 62.79190967,
    "marketplace_to_demand": 79.89911843,
    "grid_to_demand": 34861.91696,
    "govt_battery_charge": 58.28279054
  },
  {
    "timestamp": "10/02/25 21:00",
    "demand": 36416.63247,
    "solar_generated": 0,
    "wind_generated": 69.45263735,
    "marketplace_to_demand": 174.8253052,
    "grid_to_demand": 33497.86202,
    "govt_battery_charge": 83.09867241
  },
  {
    "timestamp": "10/02/25 22:00",
    "demand": 32998.32997,
    "solar_generated": 0,
    "wind_generated": 67.49651178,
    "marketplace_to_demand": 6.825960188,
    "grid_to_demand": 36017.92687,
    "govt_battery_charge": 43.32331729
  },
  {
    "timestamp": "10/02/25 23:00",
    "demand": 31955.9768,
    "solar_generated": 0,
    "wind_generated": 55.16798834,
    "marketplace_to_demand": 166.6717419,
    "grid_to_demand": 32282.29149,
    "govt_battery_charge": 7.469254013
  },
  {
    "timestamp": "11/02/25 0:00",
    "demand": 34821.83377,
    "solar_generated": 0,
    "wind_generated": 73.93488181,
    "marketplace_to_demand": 194.8427189,
    "grid_to_demand": 34510.3663,
    "govt_battery_charge": 86.09534411
  },
  {
    "timestamp": "11/02/25 1:00",
    "demand": 36507.33687,
    "solar_generated": 0,
    "wind_generated": 68.90099759,
    "marketplace_to_demand": 91.38804234,
    "grid_to_demand": 30712.36658,
    "govt_battery_charge": 89.4267097
  },
  {
    "timestamp": "11/02/25 2:00",
    "demand": 34328.28405,
    "solar_generated": 0,
    "wind_generated": 54.89735886,
    "marketplace_to_demand": 59.20997683,
    "grid_to_demand": 35140.3113,
    "govt_battery_charge": 90.90866672
  },
  {
    "timestamp": "11/02/25 3:00",
    "demand": 33779.54617,
    "solar_generated": 0,
    "wind_generated": 62.31845097,
    "marketplace_to_demand": 13.85909109,
    "grid_to_demand": 32684.48932,
    "govt_battery_charge": 48.7845724
  },
  {
    "timestamp": "11/02/25 4:00",
    "demand": 30133.40038,
    "solar_generated": 0,
    "wind_generated": 69.87599746,
    "marketplace_to_demand": 123.8083198,
    "grid_to_demand": 33700.0905,
    "govt_battery_charge": 52.85216902
  },
  {
    "timestamp": "11/02/25 5:00",
    "demand": 34792.82219,
    "solar_generated": 0,
    "wind_generated": 70.85125838,
    "marketplace_to_demand": 61.01619015,
    "grid_to_demand": 30324.52233,
    "govt_battery_charge": 76.31405591
  },
  {
    "timestamp": "11/02/25 6:00",
    "demand": 31563.65795,
    "solar_generated": 14.30262842,
    "wind_generated": 75.10129176,
    "marketplace_to_demand": 171.1727779,
    "grid_to_demand": 32613.6555,
    "govt_battery_charge": 23.16042833
  },
  {
    "timestamp": "11/02/25 7:00",
    "demand": 36549.72959,
    "solar_generated": 15.37377975,
    "wind_generated": 53.94405785,
    "marketplace_to_demand": 130.3612622,
    "grid_to_demand": 30033.63301,
    "govt_battery_charge": 27.08311246
  },
  {
    "timestamp": "11/02/25 8:00",
    "demand": 31717.3139,
    "solar_generated": 22.24373269,
    "wind_generated": 63.80434899,
    "marketplace_to_demand": 104.6927052,
    "grid_to_demand": 30153.63976,
    "govt_battery_charge": 97.86544307
  },
  {
    "timestamp": "11/02/25 9:00",
    "demand": 30664.81586,
    "solar_generated": 13.81974748,
    "wind_generated": 71.8774828,
    "marketplace_to_demand": 17.24359461,
    "grid_to_demand": 33502.81,
    "govt_battery_charge": 18.02899077
  },
  {
    "timestamp": "11/02/25 10:00",
    "demand": 36274.3394,
    "solar_generated": 10.31119012,
    "wind_generated": 62.16806634,
    "marketplace_to_demand": 73.02696061,
    "grid_to_demand": 31463.2229,
    "govt_battery_charge": 24.37367598
  },
  {
    "timestamp": "11/02/25 11:00",
    "demand": 30273.12201,
    "solar_generated": 9.966293726,
    "wind_generated": 72.84473766,
    "marketplace_to_demand": 123.6514678,
    "grid_to_demand": 30063.15517,
    "govt_battery_charge": 37.72196782
  },
  {
    "timestamp": "11/02/25 12:00",
    "demand": 31576.64154,
    "solar_generated": 7.280043691,
    "wind_generated": 56.57400656,
    "marketplace_to_demand": 44.03324841,
    "grid_to_demand": 30351.06385,
    "govt_battery_charge": 62.84411691
  },
  {
    "timestamp": "11/02/25 13:00",
    "demand": 35892.17052,
    "solar_generated": 18.24490095,
    "wind_generated": 58.64183937,
    "marketplace_to_demand": 181.1794398,
    "grid_to_demand": 34318.65273,
    "govt_battery_charge": 39.3869778
  },
  {
    "timestamp": "11/02/25 14:00",
    "demand": 34964.85533,
    "solar_generated": 21.22756922,
    "wind_generated": 63.32925288,
    "marketplace_to_demand": 66.78210682,
    "grid_to_demand": 34130.9493,
    "govt_battery_charge": 67.82476686
  },
  {
    "timestamp": "11/02/25 15:00",
    "demand": 34214.08329,
    "solar_generated": 10.07314533,
    "wind_generated": 69.96130904,
    "marketplace_to_demand": 1.744782883,
    "grid_to_demand": 30587.4913,
    "govt_battery_charge": 8.034594917
  },
  {
    "timestamp": "11/02/25 16:00",
    "demand": 31038.63962,
    "solar_generated": 10.70839831,
    "wind_generated": 77.49265977,
    "marketplace_to_demand": 172.1096533,
    "grid_to_demand": 31023.6469,
    "govt_battery_charge": 11.40913526
  },
  {
    "timestamp": "11/02/25 17:00",
    "demand": 36382.86993,
    "solar_generated": 9.687354256,
    "wind_generated": 64.54074566,
    "marketplace_to_demand": 121.2658527,
    "grid_to_demand": 32737.31789,
    "govt_battery_charge": 35.81443167
  },
  {
    "timestamp": "11/02/25 18:00",
    "demand": 36379.66541,
    "solar_generated": 0,
    "wind_generated": 70.13884667,
    "marketplace_to_demand": 24.26067526,
    "grid_to_demand": 34093.35409,
    "govt_battery_charge": 38.47341013
  },
  {
    "timestamp": "11/02/25 19:00",
    "demand": 31471.0286,
    "solar_generated": 0,
    "wind_generated": 58.96283446,
    "marketplace_to_demand": 24.03344495,
    "grid_to_demand": 31932.36936,
    "govt_battery_charge": 20.48497811
  },
  {
    "timestamp": "11/02/25 20:00",
    "demand": 32900.19978,
    "solar_generated": 0,
    "wind_generated": 75.91288301,
    "marketplace_to_demand": 32.15622889,
    "grid_to_demand": 36077.20763,
    "govt_battery_charge": 35.09228091
  },
  {
    "timestamp": "11/02/25 21:00",
    "demand": 35469.31417,
    "solar_generated": 0,
    "wind_generated": 78.59447715,
    "marketplace_to_demand": 98.06285752,
    "grid_to_demand": 30495.90897,
    "govt_battery_charge": 8.482079307
  },
  {
    "timestamp": "11/02/25 22:00",
    "demand": 31432.67455,
    "solar_generated": 0,
    "wind_generated": 57.60978031,
    "marketplace_to_demand": 75.38457813,
    "grid_to_demand": 33287.912,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "11/02/25 23:00",
    "demand": 31030.0267,
    "solar_generated": 0,
    "wind_generated": 77.00722318,
    "marketplace_to_demand": 69.02284435,
    "grid_to_demand": 35104.58951,
    "govt_battery_charge": 91.1746307
  },
  {
    "timestamp": "12/02/25 0:00",
    "demand": 31512.23132,
    "solar_generated": 0,
    "wind_generated": 76.5420868,
    "marketplace_to_demand": 32.62816406,
    "grid_to_demand": 35395.38018,
    "govt_battery_charge": 64.77990392
  },
  {
    "timestamp": "12/02/25 1:00",
    "demand": 34680.05637,
    "solar_generated": 0,
    "wind_generated": 75.89291973,
    "marketplace_to_demand": 45.43293277,
    "grid_to_demand": 30853.15057,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "12/02/25 2:00",
    "demand": 32114.75833,
    "solar_generated": 0,
    "wind_generated": 74.57267427,
    "marketplace_to_demand": 41.64973043,
    "grid_to_demand": 34971.5162,
    "govt_battery_charge": 56.13903326
  },
  {
    "timestamp": "12/02/25 3:00",
    "demand": 34090.44045,
    "solar_generated": 0,
    "wind_generated": 74.48052567,
    "marketplace_to_demand": 186.5910914,
    "grid_to_demand": 36306.6018,
    "govt_battery_charge": 28.90044612
  },
  {
    "timestamp": "12/02/25 4:00",
    "demand": 34665.04824,
    "solar_generated": 0,
    "wind_generated": 71.73422192,
    "marketplace_to_demand": 5.902913537,
    "grid_to_demand": 31787.47124,
    "govt_battery_charge": 31.93821916
  },
  {
    "timestamp": "12/02/25 5:00",
    "demand": 31386.52315,
    "solar_generated": 0,
    "wind_generated": 63.32865186,
    "marketplace_to_demand": 108.3038448,
    "grid_to_demand": 31187.58631,
    "govt_battery_charge": 92.97183415
  },
  {
    "timestamp": "12/02/25 6:00",
    "demand": 31289.99161,
    "solar_generated": 18.78381877,
    "wind_generated": 64.84261441,
    "marketplace_to_demand": 195.2369536,
    "grid_to_demand": 33972.52797,
    "govt_battery_charge": 96.59754426
  },
  {
    "timestamp": "12/02/25 7:00",
    "demand": 32013.5676,
    "solar_generated": 12.73146107,
    "wind_generated": 54.55819021,
    "marketplace_to_demand": 183.5972012,
    "grid_to_demand": 36452.73185,
    "govt_battery_charge": 85.7439862
  },
  {
    "timestamp": "12/02/25 8:00",
    "demand": 33324.57474,
    "solar_generated": 13.97667376,
    "wind_generated": 73.51370341,
    "marketplace_to_demand": 131.9738496,
    "grid_to_demand": 33141.27314,
    "govt_battery_charge": 74.76924203
  },
  {
    "timestamp": "12/02/25 9:00",
    "demand": 34512.20973,
    "solar_generated": 8.671678108,
    "wind_generated": 75.73867463,
    "marketplace_to_demand": 16.0944565,
    "grid_to_demand": 33645.20704,
    "govt_battery_charge": 46.71178991
  },
  {
    "timestamp": "12/02/25 10:00",
    "demand": 34197.87183,
    "solar_generated": 23.23947097,
    "wind_generated": 59.31515402,
    "marketplace_to_demand": 164.7277493,
    "grid_to_demand": 32065.93554,
    "govt_battery_charge": 85.15617078
  },
  {
    "timestamp": "12/02/25 11:00",
    "demand": 34653.84212,
    "solar_generated": 14.65260987,
    "wind_generated": 66.51295553,
    "marketplace_to_demand": 173.2392818,
    "grid_to_demand": 35487.60125,
    "govt_battery_charge": 53.42233169
  },
  {
    "timestamp": "12/02/25 12:00",
    "demand": 34603.41273,
    "solar_generated": 22.87973559,
    "wind_generated": 56.55493149,
    "marketplace_to_demand": 93.04799681,
    "grid_to_demand": 33776.11426,
    "govt_battery_charge": 39.80678967
  },
  {
    "timestamp": "12/02/25 13:00",
    "demand": 31529.93782,
    "solar_generated": 15.22941372,
    "wind_generated": 54.82752963,
    "marketplace_to_demand": 152.8908415,
    "grid_to_demand": 31770.6347,
    "govt_battery_charge": 54.19719242
  },
  {
    "timestamp": "12/02/25 14:00",
    "demand": 35350.18523,
    "solar_generated": 19.88702811,
    "wind_generated": 79.71882752,
    "marketplace_to_demand": 2.175606752,
    "grid_to_demand": 35841.91775,
    "govt_battery_charge": 21.74544236
  },
  {
    "timestamp": "12/02/25 15:00",
    "demand": 36354.3896,
    "solar_generated": 9.161886932,
    "wind_generated": 65.03364677,
    "marketplace_to_demand": 171.0964495,
    "grid_to_demand": 36038.02157,
    "govt_battery_charge": 61.50598665
  },
  {
    "timestamp": "12/02/25 16:00",
    "demand": 36291.23806,
    "solar_generated": 17.58201802,
    "wind_generated": 77.43258902,
    "marketplace_to_demand": 197.3478616,
    "grid_to_demand": 31113.54494,
    "govt_battery_charge": 8.619348444
  },
  {
    "timestamp": "12/02/25 17:00",
    "demand": 31358.58913,
    "solar_generated": 12.2081918,
    "wind_generated": 66.33734066,
    "marketplace_to_demand": 164.9074099,
    "grid_to_demand": 30916.4703,
    "govt_battery_charge": 38.79973753
  },
  {
    "timestamp": "12/02/25 18:00",
    "demand": 33471.08172,
    "solar_generated": 0,
    "wind_generated": 57.95842881,
    "marketplace_to_demand": 61.72887715,
    "grid_to_demand": 36508.01343,
    "govt_battery_charge": 83.05918725
  },
  {
    "timestamp": "12/02/25 19:00",
    "demand": 32743.47081,
    "solar_generated": 0,
    "wind_generated": 58.75860821,
    "marketplace_to_demand": 182.3938651,
    "grid_to_demand": 35058.29517,
    "govt_battery_charge": 5.598059594
  },
  {
    "timestamp": "12/02/25 20:00",
    "demand": 31960.56788,
    "solar_generated": 0,
    "wind_generated": 79.31384174,
    "marketplace_to_demand": 140.1632523,
    "grid_to_demand": 30715.35287,
    "govt_battery_charge": 75.05886163
  },
  {
    "timestamp": "12/02/25 21:00",
    "demand": 35317.07689,
    "solar_generated": 0,
    "wind_generated": 62.9707537,
    "marketplace_to_demand": 41.45726575,
    "grid_to_demand": 35253.93959,
    "govt_battery_charge": 9.599582997
  },
  {
    "timestamp": "12/02/25 22:00",
    "demand": 33211.20279,
    "solar_generated": 0,
    "wind_generated": 64.59792878,
    "marketplace_to_demand": 100.6567355,
    "grid_to_demand": 30862.76785,
    "govt_battery_charge": 33.43487249
  },
  {
    "timestamp": "12/02/25 23:00",
    "demand": 32305.63786,
    "solar_generated": 0,
    "wind_generated": 63.41223799,
    "marketplace_to_demand": 176.0469273,
    "grid_to_demand": 33082.30795,
    "govt_battery_charge": 76.51606682
  },
  {
    "timestamp": "13/02/25 0:00",
    "demand": 34977.01309,
    "solar_generated": 0,
    "wind_generated": 54.73816564,
    "marketplace_to_demand": 185.3924629,
    "grid_to_demand": 33245.16715,
    "govt_battery_charge": 60.91214461
  },
  {
    "timestamp": "13/02/25 1:00",
    "demand": 33907.50085,
    "solar_generated": 0,
    "wind_generated": 55.6778474,
    "marketplace_to_demand": 116.7531672,
    "grid_to_demand": 33471.43755,
    "govt_battery_charge": 44.61069871
  },
  {
    "timestamp": "13/02/25 2:00",
    "demand": 31511.43307,
    "solar_generated": 0,
    "wind_generated": 55.53911745,
    "marketplace_to_demand": 182.3879807,
    "grid_to_demand": 34415.3822,
    "govt_battery_charge": 7.35133684
  },
  {
    "timestamp": "13/02/25 3:00",
    "demand": 32587.27894,
    "solar_generated": 0,
    "wind_generated": 76.01643793,
    "marketplace_to_demand": 22.61825079,
    "grid_to_demand": 33010.93344,
    "govt_battery_charge": 66.06033285
  },
  {
    "timestamp": "13/02/25 4:00",
    "demand": 32933.91966,
    "solar_generated": 0,
    "wind_generated": 70.28746032,
    "marketplace_to_demand": 128.366144,
    "grid_to_demand": 36000.55523,
    "govt_battery_charge": 35.42906407
  },
  {
    "timestamp": "13/02/25 5:00",
    "demand": 35077.51877,
    "solar_generated": 0,
    "wind_generated": 69.87324124,
    "marketplace_to_demand": 98.804794,
    "grid_to_demand": 31590.16132,
    "govt_battery_charge": 11.25778111
  },
  {
    "timestamp": "13/02/25 6:00",
    "demand": 33049.79984,
    "solar_generated": 20.50295113,
    "wind_generated": 53.66985493,
    "marketplace_to_demand": 149.675563,
    "grid_to_demand": 30147.87767,
    "govt_battery_charge": 19.2792391
  },
  {
    "timestamp": "13/02/25 7:00",
    "demand": 32173.23067,
    "solar_generated": 23.30910788,
    "wind_generated": 53.66648663,
    "marketplace_to_demand": 109.2821919,
    "grid_to_demand": 34094.69889,
    "govt_battery_charge": 2.023299402
  },
  {
    "timestamp": "13/02/25 8:00",
    "demand": 34691.76216,
    "solar_generated": 20.34898545,
    "wind_generated": 78.08459978,
    "marketplace_to_demand": 126.5311805,
    "grid_to_demand": 32273.72631,
    "govt_battery_charge": 68.19865599
  },
  {
    "timestamp": "13/02/25 9:00",
    "demand": 35418.19522,
    "solar_generated": 16.52854773,
    "wind_generated": 57.65815886,
    "marketplace_to_demand": 32.36022977,
    "grid_to_demand": 31999.86535,
    "govt_battery_charge": 21.17095948
  },
  {
    "timestamp": "13/02/25 10:00",
    "demand": 31859.49432,
    "solar_generated": 6.766274585,
    "wind_generated": 59.13347411,
    "marketplace_to_demand": 130.214356,
    "grid_to_demand": 32950.89224,
    "govt_battery_charge": 24.90814038
  },
  {
    "timestamp": "13/02/25 11:00",
    "demand": 36358.02143,
    "solar_generated": 20.51620701,
    "wind_generated": 75.70884438,
    "marketplace_to_demand": 54.05208926,
    "grid_to_demand": 31456.73461,
    "govt_battery_charge": 4.949994038
  },
  {
    "timestamp": "13/02/25 12:00",
    "demand": 32801.95053,
    "solar_generated": 8.379616141,
    "wind_generated": 78.33356918,
    "marketplace_to_demand": 135.8031631,
    "grid_to_demand": 30664.74193,
    "govt_battery_charge": 65.10408998
  },
  {
    "timestamp": "13/02/25 13:00",
    "demand": 36265.33996,
    "solar_generated": 10.80693707,
    "wind_generated": 63.33158429,
    "marketplace_to_demand": 196.9168476,
    "grid_to_demand": 35609.98755,
    "govt_battery_charge": 83.96513415
  },
  {
    "timestamp": "13/02/25 14:00",
    "demand": 32329.20819,
    "solar_generated": 12.19946733,
    "wind_generated": 67.98652479,
    "marketplace_to_demand": 52.65097442,
    "grid_to_demand": 35462.40083,
    "govt_battery_charge": 22.38378475
  },
  {
    "timestamp": "13/02/25 15:00",
    "demand": 31860.12066,
    "solar_generated": 11.29397074,
    "wind_generated": 79.27052393,
    "marketplace_to_demand": 125.0627111,
    "grid_to_demand": 30905.45336,
    "govt_battery_charge": 35.87603689
  },
  {
    "timestamp": "13/02/25 16:00",
    "demand": 34097.48532,
    "solar_generated": 18.76375408,
    "wind_generated": 68.98337037,
    "marketplace_to_demand": 199.6444892,
    "grid_to_demand": 35167.85303,
    "govt_battery_charge": 74.89027038
  },
  {
    "timestamp": "13/02/25 17:00",
    "demand": 32443.14965,
    "solar_generated": 10.14985108,
    "wind_generated": 64.79278464,
    "marketplace_to_demand": 13.2686658,
    "grid_to_demand": 34786.95121,
    "govt_battery_charge": 96.086052
  },
  {
    "timestamp": "13/02/25 18:00",
    "demand": 30990.55582,
    "solar_generated": 0,
    "wind_generated": 53.87750954,
    "marketplace_to_demand": 160.5055037,
    "grid_to_demand": 30764.2694,
    "govt_battery_charge": 56.45953615
  },
  {
    "timestamp": "13/02/25 19:00",
    "demand": 33894.79128,
    "solar_generated": 0,
    "wind_generated": 77.6562423,
    "marketplace_to_demand": 42.08271666,
    "grid_to_demand": 35952.15852,
    "govt_battery_charge": 35.45801762
  },
  {
    "timestamp": "13/02/25 20:00",
    "demand": 33972.72124,
    "solar_generated": 0,
    "wind_generated": 71.96275002,
    "marketplace_to_demand": 138.4641513,
    "grid_to_demand": 30847.15829,
    "govt_battery_charge": 25.94610722
  },
  {
    "timestamp": "13/02/25 21:00",
    "demand": 34737.86448,
    "solar_generated": 0,
    "wind_generated": 62.39436481,
    "marketplace_to_demand": 7.937186564,
    "grid_to_demand": 33513.24548,
    "govt_battery_charge": 47.56600778
  },
  {
    "timestamp": "13/02/25 22:00",
    "demand": 35760.41894,
    "solar_generated": 0,
    "wind_generated": 78.96495374,
    "marketplace_to_demand": 3.239549143,
    "grid_to_demand": 34161.09978,
    "govt_battery_charge": 98.8396552
  },
  {
    "timestamp": "13/02/25 23:00",
    "demand": 33029.45531,
    "solar_generated": 0,
    "wind_generated": 58.65406456,
    "marketplace_to_demand": 197.5303097,
    "grid_to_demand": 36301.7561,
    "govt_battery_charge": 48.51354624
  },
  {
    "timestamp": "14/02/25 0:00",
    "demand": 31571.83963,
    "solar_generated": 0,
    "wind_generated": 68.84370535,
    "marketplace_to_demand": 40.44064702,
    "grid_to_demand": 33192.60208,
    "govt_battery_charge": 78.78406932
  },
  {
    "timestamp": "14/02/25 1:00",
    "demand": 34917.16848,
    "solar_generated": 0,
    "wind_generated": 56.5433069,
    "marketplace_to_demand": 153.8214873,
    "grid_to_demand": 35329.69987,
    "govt_battery_charge": 57.11528272
  },
  {
    "timestamp": "14/02/25 2:00",
    "demand": 35297.76726,
    "solar_generated": 0,
    "wind_generated": 78.22484429,
    "marketplace_to_demand": 177.8200584,
    "grid_to_demand": 30873.59398,
    "govt_battery_charge": 45.17529259
  },
  {
    "timestamp": "14/02/25 3:00",
    "demand": 31875.09207,
    "solar_generated": 0,
    "wind_generated": 76.40141362,
    "marketplace_to_demand": 50.5705533,
    "grid_to_demand": 35272.31873,
    "govt_battery_charge": 10.22700429
  },
  {
    "timestamp": "14/02/25 4:00",
    "demand": 32018.56089,
    "solar_generated": 0,
    "wind_generated": 60.39367161,
    "marketplace_to_demand": 72.81152131,
    "grid_to_demand": 31949.35798,
    "govt_battery_charge": 11.08733461
  },
  {
    "timestamp": "14/02/25 5:00",
    "demand": 31062.25423,
    "solar_generated": 0,
    "wind_generated": 64.67437842,
    "marketplace_to_demand": 164.1017831,
    "grid_to_demand": 32114.50317,
    "govt_battery_charge": 29.49660869
  },
  {
    "timestamp": "14/02/25 6:00",
    "demand": 35625.97462,
    "solar_generated": 23.62363915,
    "wind_generated": 66.29407678,
    "marketplace_to_demand": 199.9375632,
    "grid_to_demand": 32118.68787,
    "govt_battery_charge": 95.58800189
  },
  {
    "timestamp": "14/02/25 7:00",
    "demand": 31428.73335,
    "solar_generated": 6.672295607,
    "wind_generated": 77.60069076,
    "marketplace_to_demand": 22.01032555,
    "grid_to_demand": 35748.2903,
    "govt_battery_charge": 82.02597329
  },
  {
    "timestamp": "14/02/25 8:00",
    "demand": 36204.09371,
    "solar_generated": 11.81246694,
    "wind_generated": 73.43931967,
    "marketplace_to_demand": 137.0142815,
    "grid_to_demand": 31865.26522,
    "govt_battery_charge": 29.80922143
  },
  {
    "timestamp": "14/02/25 9:00",
    "demand": 32313.64443,
    "solar_generated": 7.202316703,
    "wind_generated": 79.63249037,
    "marketplace_to_demand": 75.67243887,
    "grid_to_demand": 31720.75376,
    "govt_battery_charge": 38.71424487
  },
  {
    "timestamp": "14/02/25 10:00",
    "demand": 36058.83689,
    "solar_generated": 23.25515326,
    "wind_generated": 62.1570141,
    "marketplace_to_demand": 41.06415362,
    "grid_to_demand": 34522.37681,
    "govt_battery_charge": 64.35743914
  },
  {
    "timestamp": "14/02/25 11:00",
    "demand": 30915.42336,
    "solar_generated": 20.00599037,
    "wind_generated": 76.94481987,
    "marketplace_to_demand": 107.3692487,
    "grid_to_demand": 30364.08151,
    "govt_battery_charge": 77.40400307
  },
  {
    "timestamp": "14/02/25 12:00",
    "demand": 33455.13797,
    "solar_generated": 23.77667973,
    "wind_generated": 57.01225313,
    "marketplace_to_demand": 0.156536576,
    "grid_to_demand": 35490.66357,
    "govt_battery_charge": 45.40514048
  },
  {
    "timestamp": "14/02/25 13:00",
    "demand": 32481.21029,
    "solar_generated": 20.93897673,
    "wind_generated": 76.90959755,
    "marketplace_to_demand": 89.31053534,
    "grid_to_demand": 36084.89076,
    "govt_battery_charge": 51.55731428
  },
  {
    "timestamp": "14/02/25 14:00",
    "demand": 30788.82011,
    "solar_generated": 16.28101154,
    "wind_generated": 61.0762018,
    "marketplace_to_demand": 33.75330625,
    "grid_to_demand": 31805.50367,
    "govt_battery_charge": 87.92940813
  },
  {
    "timestamp": "14/02/25 15:00",
    "demand": 34265.7065,
    "solar_generated": 16.76533575,
    "wind_generated": 60.67101446,
    "marketplace_to_demand": 10.71299688,
    "grid_to_demand": 31891.21685,
    "govt_battery_charge": 46.01362481
  },
  {
    "timestamp": "14/02/25 16:00",
    "demand": 30022.44335,
    "solar_generated": 8.181221389,
    "wind_generated": 67.69325721,
    "marketplace_to_demand": 120.2144368,
    "grid_to_demand": 33301.99736,
    "govt_battery_charge": 73.76259241
  },
  {
    "timestamp": "14/02/25 17:00",
    "demand": 33479.7375,
    "solar_generated": 17.62211365,
    "wind_generated": 71.09993682,
    "marketplace_to_demand": 74.57656898,
    "grid_to_demand": 34503.06426,
    "govt_battery_charge": 96.19433775
  },
  {
    "timestamp": "14/02/25 18:00",
    "demand": 34509.11424,
    "solar_generated": 0,
    "wind_generated": 57.63929957,
    "marketplace_to_demand": 49.39152317,
    "grid_to_demand": 31419.8945,
    "govt_battery_charge": 5.000269261
  },
  {
    "timestamp": "14/02/25 19:00",
    "demand": 35394.17244,
    "solar_generated": 0,
    "wind_generated": 60.8277074,
    "marketplace_to_demand": 54.14740109,
    "grid_to_demand": 31274.28164,
    "govt_battery_charge": 17.28893939
  },
  {
    "timestamp": "14/02/25 20:00",
    "demand": 32745.65479,
    "solar_generated": 0,
    "wind_generated": 71.58691378,
    "marketplace_to_demand": 144.5106266,
    "grid_to_demand": 33915.07589,
    "govt_battery_charge": 24.46707421
  },
  {
    "timestamp": "14/02/25 21:00",
    "demand": 35152.85651,
    "solar_generated": 0,
    "wind_generated": 54.24196771,
    "marketplace_to_demand": 83.58745165,
    "grid_to_demand": 34835.66695,
    "govt_battery_charge": 57.34874927
  },
  {
    "timestamp": "14/02/25 22:00",
    "demand": 31614.04634,
    "solar_generated": 0,
    "wind_generated": 75.49613117,
    "marketplace_to_demand": 30.10511678,
    "grid_to_demand": 30991.76946,
    "govt_battery_charge": 63.17463386
  },
  {
    "timestamp": "14/02/25 23:00",
    "demand": 35811.76398,
    "solar_generated": 0,
    "wind_generated": 55.2254785,
    "marketplace_to_demand": 96.48387486,
    "grid_to_demand": 34416.58109,
    "govt_battery_charge": 96.32673621
  },
  {
    "timestamp": "15/02/25 0:00",
    "demand": 33237.48897,
    "solar_generated": 0,
    "wind_generated": 60.30185658,
    "marketplace_to_demand": 25.84594899,
    "grid_to_demand": 34968.29832,
    "govt_battery_charge": 3.573693629
  },
  {
    "timestamp": "15/02/25 1:00",
    "demand": 32015.70994,
    "solar_generated": 0,
    "wind_generated": 57.43217078,
    "marketplace_to_demand": 30.01690207,
    "grid_to_demand": 35579.60118,
    "govt_battery_charge": 9.167264415
  },
  {
    "timestamp": "15/02/25 2:00",
    "demand": 35506.12489,
    "solar_generated": 0,
    "wind_generated": 69.55622896,
    "marketplace_to_demand": 136.6076248,
    "grid_to_demand": 36121.49445,
    "govt_battery_charge": 17.71005112
  },
  {
    "timestamp": "15/02/25 3:00",
    "demand": 33457.17278,
    "solar_generated": 0,
    "wind_generated": 68.20996353,
    "marketplace_to_demand": 49.20874974,
    "grid_to_demand": 34975.88504,
    "govt_battery_charge": 31.39857109
  },
  {
    "timestamp": "15/02/25 4:00",
    "demand": 33899.26611,
    "solar_generated": 0,
    "wind_generated": 70.14358785,
    "marketplace_to_demand": 132.6586267,
    "grid_to_demand": 35077.26728,
    "govt_battery_charge": 25.30427269
  },
  {
    "timestamp": "15/02/25 5:00",
    "demand": 31434.63326,
    "solar_generated": 0,
    "wind_generated": 77.84504536,
    "marketplace_to_demand": 89.22851303,
    "grid_to_demand": 32454.58339,
    "govt_battery_charge": 16.11820575
  },
  {
    "timestamp": "15/02/25 6:00",
    "demand": 35851.25765,
    "solar_generated": 10.65364519,
    "wind_generated": 71.5614975,
    "marketplace_to_demand": 69.60670247,
    "grid_to_demand": 32587.51776,
    "govt_battery_charge": 27.88026438
  },
  {
    "timestamp": "15/02/25 7:00",
    "demand": 33248.48084,
    "solar_generated": 16.78911457,
    "wind_generated": 55.33103739,
    "marketplace_to_demand": 27.05180892,
    "grid_to_demand": 34751.08342,
    "govt_battery_charge": 28.63493168
  },
  {
    "timestamp": "15/02/25 8:00",
    "demand": 33842.87516,
    "solar_generated": 16.59122574,
    "wind_generated": 67.2475216,
    "marketplace_to_demand": 74.50532133,
    "grid_to_demand": 31732.84352,
    "govt_battery_charge": 37.41563105
  },
  {
    "timestamp": "15/02/25 9:00",
    "demand": 31220.87877,
    "solar_generated": 23.74285575,
    "wind_generated": 64.12745699,
    "marketplace_to_demand": 135.2168677,
    "grid_to_demand": 30700.40362,
    "govt_battery_charge": 28.25118262
  },
  {
    "timestamp": "15/02/25 10:00",
    "demand": 33074.8253,
    "solar_generated": 18.47308206,
    "wind_generated": 53.7134742,
    "marketplace_to_demand": 8.164860859,
    "grid_to_demand": 31261.19483,
    "govt_battery_charge": 21.65987364
  },
  {
    "timestamp": "15/02/25 11:00",
    "demand": 34037.1969,
    "solar_generated": 11.32931296,
    "wind_generated": 69.20597855,
    "marketplace_to_demand": 176.0505147,
    "grid_to_demand": 30182.24972,
    "govt_battery_charge": 42.66184074
  },
  {
    "timestamp": "15/02/25 12:00",
    "demand": 34389.9761,
    "solar_generated": 13.09627677,
    "wind_generated": 59.78271325,
    "marketplace_to_demand": 179.6931696,
    "grid_to_demand": 35014.65283,
    "govt_battery_charge": 59.02831451
  },
  {
    "timestamp": "15/02/25 13:00",
    "demand": 30304.23749,
    "solar_generated": 19.78805597,
    "wind_generated": 76.35785608,
    "marketplace_to_demand": 198.5274757,
    "grid_to_demand": 34252.40281,
    "govt_battery_charge": 25.67810528
  },
  {
    "timestamp": "15/02/25 14:00",
    "demand": 30828.06735,
    "solar_generated": 8.505139742,
    "wind_generated": 62.37456922,
    "marketplace_to_demand": 85.45815042,
    "grid_to_demand": 31291.6045,
    "govt_battery_charge": 54.66598326
  },
  {
    "timestamp": "15/02/25 15:00",
    "demand": 33939.97477,
    "solar_generated": 22.67092978,
    "wind_generated": 64.6217932,
    "marketplace_to_demand": 6.523144458,
    "grid_to_demand": 31906.35768,
    "govt_battery_charge": 92.44721206
  },
  {
    "timestamp": "15/02/25 16:00",
    "demand": 34886.43366,
    "solar_generated": 7.927252246,
    "wind_generated": 59.76496113,
    "marketplace_to_demand": 105.3479795,
    "grid_to_demand": 32827.91837,
    "govt_battery_charge": 7.232707212
  },
  {
    "timestamp": "15/02/25 17:00",
    "demand": 32041.30578,
    "solar_generated": 9.346879641,
    "wind_generated": 79.70277822,
    "marketplace_to_demand": 144.4479617,
    "grid_to_demand": 34600.14784,
    "govt_battery_charge": 56.98070776
  },
  {
    "timestamp": "15/02/25 18:00",
    "demand": 36275.42165,
    "solar_generated": 0,
    "wind_generated": 62.53089416,
    "marketplace_to_demand": 29.90226184,
    "grid_to_demand": 33471.34972,
    "govt_battery_charge": 91.8483995
  },
  {
    "timestamp": "15/02/25 19:00",
    "demand": 32349.28822,
    "solar_generated": 0,
    "wind_generated": 60.28419407,
    "marketplace_to_demand": 53.92353133,
    "grid_to_demand": 31222.58542,
    "govt_battery_charge": 55.82399737
  },
  {
    "timestamp": "15/02/25 20:00",
    "demand": 30641.78684,
    "solar_generated": 0,
    "wind_generated": 57.38262861,
    "marketplace_to_demand": 70.58290354,
    "grid_to_demand": 36371.72721,
    "govt_battery_charge": 33.45166475
  },
  {
    "timestamp": "15/02/25 21:00",
    "demand": 33971.12485,
    "solar_generated": 0,
    "wind_generated": 66.9174876,
    "marketplace_to_demand": 140.6829174,
    "grid_to_demand": 32206.64436,
    "govt_battery_charge": 93.80407555
  },
  {
    "timestamp": "15/02/25 22:00",
    "demand": 33389.68662,
    "solar_generated": 0,
    "wind_generated": 64.11759389,
    "marketplace_to_demand": 71.81804986,
    "grid_to_demand": 35881.51589,
    "govt_battery_charge": 3.372927902
  },
  {
    "timestamp": "15/02/25 23:00",
    "demand": 31662.6752,
    "solar_generated": 0,
    "wind_generated": 65.39967434,
    "marketplace_to_demand": 75.18028252,
    "grid_to_demand": 36084.53786,
    "govt_battery_charge": 92.03022622
  },
  {
    "timestamp": "16/02/25 0:00",
    "demand": 31263.57449,
    "solar_generated": 0,
    "wind_generated": 64.90920834,
    "marketplace_to_demand": 43.07319718,
    "grid_to_demand": 34835.36164,
    "govt_battery_charge": 45.49327879
  },
  {
    "timestamp": "16/02/25 1:00",
    "demand": 35087.24263,
    "solar_generated": 0,
    "wind_generated": 64.68816668,
    "marketplace_to_demand": 137.6947823,
    "grid_to_demand": 35524.25993,
    "govt_battery_charge": 89.20829367
  },
  {
    "timestamp": "16/02/25 2:00",
    "demand": 35185.66878,
    "solar_generated": 0,
    "wind_generated": 58.02898979,
    "marketplace_to_demand": 38.88801469,
    "grid_to_demand": 33913.18616,
    "govt_battery_charge": 92.04308983
  },
  {
    "timestamp": "16/02/25 3:00",
    "demand": 32399.99798,
    "solar_generated": 0,
    "wind_generated": 62.98415361,
    "marketplace_to_demand": 68.94682707,
    "grid_to_demand": 30999.35904,
    "govt_battery_charge": 97.38999691
  },
  {
    "timestamp": "16/02/25 4:00",
    "demand": 36587.11711,
    "solar_generated": 0,
    "wind_generated": 64.07361463,
    "marketplace_to_demand": 174.0746331,
    "grid_to_demand": 30087.70881,
    "govt_battery_charge": 41.29381962
  },
  {
    "timestamp": "16/02/25 5:00",
    "demand": 35341.32367,
    "solar_generated": 0,
    "wind_generated": 60.56500234,
    "marketplace_to_demand": 133.4333319,
    "grid_to_demand": 35197.57433,
    "govt_battery_charge": 58.89101276
  },
  {
    "timestamp": "16/02/25 6:00",
    "demand": 32612.28148,
    "solar_generated": 10.48947177,
    "wind_generated": 66.15631261,
    "marketplace_to_demand": 157.4656391,
    "grid_to_demand": 31195.78837,
    "govt_battery_charge": 12.24741968
  },
  {
    "timestamp": "16/02/25 7:00",
    "demand": 30913.06608,
    "solar_generated": 17.26980939,
    "wind_generated": 69.14857092,
    "marketplace_to_demand": 94.30945581,
    "grid_to_demand": 30118.74387,
    "govt_battery_charge": 73.26539573
  },
  {
    "timestamp": "16/02/25 8:00",
    "demand": 30973.90511,
    "solar_generated": 6.095905659,
    "wind_generated": 70.90218029,
    "marketplace_to_demand": 191.0820638,
    "grid_to_demand": 32232.72652,
    "govt_battery_charge": 4.845197616
  },
  {
    "timestamp": "16/02/25 9:00",
    "demand": 29997.59776,
    "solar_generated": 9.47992295,
    "wind_generated": 78.64393891,
    "marketplace_to_demand": 169.505029,
    "grid_to_demand": 29914.87009,
    "govt_battery_charge": 79.97811877
  },
  {
    "timestamp": "16/02/25 10:00",
    "demand": 33554.99282,
    "solar_generated": 9.918655701,
    "wind_generated": 61.74765325,
    "marketplace_to_demand": 71.06401052,
    "grid_to_demand": 33076.28078,
    "govt_battery_charge": 21.01755193
  },
  {
    "timestamp": "16/02/25 11:00",
    "demand": 31842.13968,
    "solar_generated": 14.27038492,
    "wind_generated": 77.35056943,
    "marketplace_to_demand": 145.7064632,
    "grid_to_demand": 30550.66358,
    "govt_battery_charge": 58.25555073
  },
  {
    "timestamp": "16/02/25 12:00",
    "demand": 33956.32946,
    "solar_generated": 21.95059158,
    "wind_generated": 64.17126639,
    "marketplace_to_demand": 138.4277166,
    "grid_to_demand": 33636.55546,
    "govt_battery_charge": 34.54443627
  },
  {
    "timestamp": "16/02/25 13:00",
    "demand": 35501.4229,
    "solar_generated": 8.682382205,
    "wind_generated": 72.70841454,
    "marketplace_to_demand": 147.3270808,
    "grid_to_demand": 30373.88228,
    "govt_battery_charge": 39.98801459
  },
  {
    "timestamp": "16/02/25 14:00",
    "demand": 31827.18902,
    "solar_generated": 9.293116763,
    "wind_generated": 69.98236109,
    "marketplace_to_demand": 80.55045798,
    "grid_to_demand": 33193.99934,
    "govt_battery_charge": 37.14912068
  },
  {
    "timestamp": "16/02/25 15:00",
    "demand": 30288.59084,
    "solar_generated": 20.55591547,
    "wind_generated": 67.50064437,
    "marketplace_to_demand": 45.94118983,
    "grid_to_demand": 34132.37555,
    "govt_battery_charge": 61.88501616
  },
  {
    "timestamp": "16/02/25 16:00",
    "demand": 30062.27569,
    "solar_generated": 19.73817834,
    "wind_generated": 56.97632319,
    "marketplace_to_demand": 39.89183786,
    "grid_to_demand": 34207.19506,
    "govt_battery_charge": 84.33522301
  },
  {
    "timestamp": "16/02/25 17:00",
    "demand": 32771.05797,
    "solar_generated": 7.476083024,
    "wind_generated": 61.71732888,
    "marketplace_to_demand": 162.5575772,
    "grid_to_demand": 36475.54266,
    "govt_battery_charge": 6.794017077
  },
  {
    "timestamp": "16/02/25 18:00",
    "demand": 35749.03239,
    "solar_generated": 0,
    "wind_generated": 58.12447705,
    "marketplace_to_demand": 39.87769102,
    "grid_to_demand": 31454.18111,
    "govt_battery_charge": 39.98077224
  },
  {
    "timestamp": "16/02/25 19:00",
    "demand": 35376.25247,
    "solar_generated": 0,
    "wind_generated": 71.49140716,
    "marketplace_to_demand": 127.2297354,
    "grid_to_demand": 33238.04587,
    "govt_battery_charge": 9.351302306
  },
  {
    "timestamp": "16/02/25 20:00",
    "demand": 31488.95586,
    "solar_generated": 0,
    "wind_generated": 63.35681808,
    "marketplace_to_demand": 49.00254846,
    "grid_to_demand": 34966.02415,
    "govt_battery_charge": 53.15604068
  },
  {
    "timestamp": "16/02/25 21:00",
    "demand": 36098.93541,
    "solar_generated": 0,
    "wind_generated": 79.9989644,
    "marketplace_to_demand": 73.82879785,
    "grid_to_demand": 36514.34699,
    "govt_battery_charge": 40.25103496
  },
  {
    "timestamp": "16/02/25 22:00",
    "demand": 34183.25477,
    "solar_generated": 0,
    "wind_generated": 62.15028423,
    "marketplace_to_demand": 23.10206951,
    "grid_to_demand": 32223.50359,
    "govt_battery_charge": 36.33421025
  },
  {
    "timestamp": "16/02/25 23:00",
    "demand": 32458.98979,
    "solar_generated": 0,
    "wind_generated": 70.01052081,
    "marketplace_to_demand": 31.17716422,
    "grid_to_demand": 30830.40763,
    "govt_battery_charge": 80.11003848
  },
  {
    "timestamp": "17/02/25 0:00",
    "demand": 33518.05126,
    "solar_generated": 0,
    "wind_generated": 65.73920352,
    "marketplace_to_demand": 17.60135471,
    "grid_to_demand": 32469.47969,
    "govt_battery_charge": 42.50644276
  },
  {
    "timestamp": "17/02/25 1:00",
    "demand": 34505.72631,
    "solar_generated": 0,
    "wind_generated": 72.06003549,
    "marketplace_to_demand": 121.4191533,
    "grid_to_demand": 31705.35034,
    "govt_battery_charge": 18.32636656
  },
  {
    "timestamp": "17/02/25 2:00",
    "demand": 35378.67951,
    "solar_generated": 0,
    "wind_generated": 60.4480977,
    "marketplace_to_demand": 133.2800238,
    "grid_to_demand": 36298.47811,
    "govt_battery_charge": 73.96102815
  },
  {
    "timestamp": "17/02/25 3:00",
    "demand": 36170.62449,
    "solar_generated": 0,
    "wind_generated": 67.26732969,
    "marketplace_to_demand": 157.7198776,
    "grid_to_demand": 35019.73276,
    "govt_battery_charge": 59.11591881
  },
  {
    "timestamp": "17/02/25 4:00",
    "demand": 33479.83428,
    "solar_generated": 0,
    "wind_generated": 73.16041526,
    "marketplace_to_demand": 109.9804377,
    "grid_to_demand": 36207.71981,
    "govt_battery_charge": 35.39517402
  },
  {
    "timestamp": "17/02/25 5:00",
    "demand": 32847.4118,
    "solar_generated": 0,
    "wind_generated": 57.23223877,
    "marketplace_to_demand": 173.3028872,
    "grid_to_demand": 35644.77683,
    "govt_battery_charge": 4.980046136
  },
  {
    "timestamp": "17/02/25 6:00",
    "demand": 33406.86075,
    "solar_generated": 20.53934344,
    "wind_generated": 67.89048749,
    "marketplace_to_demand": 80.23270636,
    "grid_to_demand": 36444.92144,
    "govt_battery_charge": 83.27695727
  },
  {
    "timestamp": "17/02/25 7:00",
    "demand": 30856.90971,
    "solar_generated": 6.762608454,
    "wind_generated": 63.10634038,
    "marketplace_to_demand": 44.80613017,
    "grid_to_demand": 32824.37827,
    "govt_battery_charge": 47.45452645
  },
  {
    "timestamp": "17/02/25 8:00",
    "demand": 32287.20218,
    "solar_generated": 16.02667414,
    "wind_generated": 77.93901729,
    "marketplace_to_demand": 126.7216732,
    "grid_to_demand": 36045.17476,
    "govt_battery_charge": 65.11489899
  },
  {
    "timestamp": "17/02/25 9:00",
    "demand": 31816.19695,
    "solar_generated": 15.70477179,
    "wind_generated": 71.74127767,
    "marketplace_to_demand": 29.74466967,
    "grid_to_demand": 35649.42831,
    "govt_battery_charge": 18.61714376
  },
  {
    "timestamp": "17/02/25 10:00",
    "demand": 31678.0839,
    "solar_generated": 6.005017391,
    "wind_generated": 63.03285543,
    "marketplace_to_demand": 85.88422471,
    "grid_to_demand": 34449.99034,
    "govt_battery_charge": 20.9875281
  },
  {
    "timestamp": "17/02/25 11:00",
    "demand": 33979.02081,
    "solar_generated": 7.022340369,
    "wind_generated": 77.00248866,
    "marketplace_to_demand": 56.41891706,
    "grid_to_demand": 34132.54969,
    "govt_battery_charge": 86.48843488
  },
  {
    "timestamp": "17/02/25 12:00",
    "demand": 31663.93377,
    "solar_generated": 9.768165541,
    "wind_generated": 60.41233635,
    "marketplace_to_demand": 166.742274,
    "grid_to_demand": 31451.89157,
    "govt_battery_charge": 17.45275579
  },
  {
    "timestamp": "17/02/25 13:00",
    "demand": 30535.01763,
    "solar_generated": 7.330888083,
    "wind_generated": 58.76341315,
    "marketplace_to_demand": 47.93522545,
    "grid_to_demand": 33664.17072,
    "govt_battery_charge": 21.62378202
  },
  {
    "timestamp": "17/02/25 14:00",
    "demand": 30462.3254,
    "solar_generated": 7.033231783,
    "wind_generated": 74.30338408,
    "marketplace_to_demand": 152.3943755,
    "grid_to_demand": 30686.19993,
    "govt_battery_charge": 49.06959474
  },
  {
    "timestamp": "17/02/25 15:00",
    "demand": 30811.87562,
    "solar_generated": 7.264183056,
    "wind_generated": 61.51144886,
    "marketplace_to_demand": 90.57369678,
    "grid_to_demand": 35570.71939,
    "govt_battery_charge": 84.81335999
  },
  {
    "timestamp": "17/02/25 16:00",
    "demand": 34700.67189,
    "solar_generated": 17.93407361,
    "wind_generated": 63.20630576,
    "marketplace_to_demand": 138.7605827,
    "grid_to_demand": 30289.45601,
    "govt_battery_charge": 90.81790855
  },
  {
    "timestamp": "17/02/25 17:00",
    "demand": 35329.45364,
    "solar_generated": 18.77789075,
    "wind_generated": 73.56613776,
    "marketplace_to_demand": 189.709315,
    "grid_to_demand": 35700.83877,
    "govt_battery_charge": 7.871329111
  },
  {
    "timestamp": "17/02/25 18:00",
    "demand": 34415.73135,
    "solar_generated": 0,
    "wind_generated": 62.44068636,
    "marketplace_to_demand": 53.29384881,
    "grid_to_demand": 31919.78101,
    "govt_battery_charge": 68.19572992
  },
  {
    "timestamp": "17/02/25 19:00",
    "demand": 33399.4831,
    "solar_generated": 0,
    "wind_generated": 64.01702174,
    "marketplace_to_demand": 140.6063806,
    "grid_to_demand": 31957.09171,
    "govt_battery_charge": 3.306894037
  },
  {
    "timestamp": "17/02/25 20:00",
    "demand": 32534.43932,
    "solar_generated": 0,
    "wind_generated": 62.30331502,
    "marketplace_to_demand": 26.74804414,
    "grid_to_demand": 34592.5631,
    "govt_battery_charge": 38.64059485
  },
  {
    "timestamp": "17/02/25 21:00",
    "demand": 33816.25333,
    "solar_generated": 0,
    "wind_generated": 59.96648233,
    "marketplace_to_demand": 59.5747225,
    "grid_to_demand": 32391.31533,
    "govt_battery_charge": 87.92773525
  },
  {
    "timestamp": "17/02/25 22:00",
    "demand": 34618.53019,
    "solar_generated": 0,
    "wind_generated": 67.21464221,
    "marketplace_to_demand": 149.6917334,
    "grid_to_demand": 32810.18617,
    "govt_battery_charge": 46.3439898
  },
  {
    "timestamp": "17/02/25 23:00",
    "demand": 33581.19643,
    "solar_generated": 0,
    "wind_generated": 64.85908993,
    "marketplace_to_demand": 147.3059298,
    "grid_to_demand": 36124.53142,
    "govt_battery_charge": 44.02012035
  },
  {
    "timestamp": "18/02/25 0:00",
    "demand": 35833.14805,
    "solar_generated": 0,
    "wind_generated": 75.27080034,
    "marketplace_to_demand": 111.4445758,
    "grid_to_demand": 33524.11291,
    "govt_battery_charge": 41.7937609
  },
  {
    "timestamp": "18/02/25 1:00",
    "demand": 30392.89969,
    "solar_generated": 0,
    "wind_generated": 74.45574561,
    "marketplace_to_demand": 77.66015388,
    "grid_to_demand": 34328.17825,
    "govt_battery_charge": 83.72196735
  },
  {
    "timestamp": "18/02/25 2:00",
    "demand": 34653.30079,
    "solar_generated": 0,
    "wind_generated": 62.281355,
    "marketplace_to_demand": 130.0218971,
    "grid_to_demand": 34227.99188,
    "govt_battery_charge": 62.22396347
  },
  {
    "timestamp": "18/02/25 3:00",
    "demand": 34769.92882,
    "solar_generated": 0,
    "wind_generated": 76.11735652,
    "marketplace_to_demand": 27.12678364,
    "grid_to_demand": 31329.7039,
    "govt_battery_charge": 12.37338262
  },
  {
    "timestamp": "18/02/25 4:00",
    "demand": 30427.26041,
    "solar_generated": 0,
    "wind_generated": 66.23424879,
    "marketplace_to_demand": 43.42239503,
    "grid_to_demand": 34138.78559,
    "govt_battery_charge": 37.37259965
  },
  {
    "timestamp": "18/02/25 5:00",
    "demand": 30325.46407,
    "solar_generated": 0,
    "wind_generated": 54.19866909,
    "marketplace_to_demand": 46.72836119,
    "grid_to_demand": 35686.64596,
    "govt_battery_charge": 95.17253074
  },
  {
    "timestamp": "18/02/25 6:00",
    "demand": 30350.25924,
    "solar_generated": 15.72053709,
    "wind_generated": 78.98680696,
    "marketplace_to_demand": 121.0103863,
    "grid_to_demand": 30240.13377,
    "govt_battery_charge": 26.81448011
  },
  {
    "timestamp": "18/02/25 7:00",
    "demand": 30265.78398,
    "solar_generated": 14.03336575,
    "wind_generated": 62.29007149,
    "marketplace_to_demand": 45.28398638,
    "grid_to_demand": 32028.77743,
    "govt_battery_charge": 26.02098323
  },
  {
    "timestamp": "18/02/25 8:00",
    "demand": 35142.61968,
    "solar_generated": 14.86209891,
    "wind_generated": 79.58760682,
    "marketplace_to_demand": 13.56684252,
    "grid_to_demand": 32409.66847,
    "govt_battery_charge": 8.12731872
  },
  {
    "timestamp": "18/02/25 9:00",
    "demand": 34272.09324,
    "solar_generated": 14.56193533,
    "wind_generated": 57.72328639,
    "marketplace_to_demand": 25.0164255,
    "grid_to_demand": 34081.3654,
    "govt_battery_charge": 92.58982919
  },
  {
    "timestamp": "18/02/25 10:00",
    "demand": 32943.5459,
    "solar_generated": 9.928481281,
    "wind_generated": 75.37765778,
    "marketplace_to_demand": 81.56997912,
    "grid_to_demand": 33520.68423,
    "govt_battery_charge": 76.26856058
  },
  {
    "timestamp": "18/02/25 11:00",
    "demand": 30502.0273,
    "solar_generated": 7.855641772,
    "wind_generated": 77.57201808,
    "marketplace_to_demand": 121.3486412,
    "grid_to_demand": 31414.96895,
    "govt_battery_charge": 81.84398472
  },
  {
    "timestamp": "18/02/25 12:00",
    "demand": 32573.93567,
    "solar_generated": 7.87990069,
    "wind_generated": 58.62809302,
    "marketplace_to_demand": 97.10618904,
    "grid_to_demand": 33257.55286,
    "govt_battery_charge": 2.108676272
  },
  {
    "timestamp": "18/02/25 13:00",
    "demand": 33895.21938,
    "solar_generated": 15.24112744,
    "wind_generated": 79.82856623,
    "marketplace_to_demand": 170.997965,
    "grid_to_demand": 33795.55828,
    "govt_battery_charge": 52.81228008
  },
  {
    "timestamp": "18/02/25 14:00",
    "demand": 35697.87219,
    "solar_generated": 7.724309578,
    "wind_generated": 77.23246562,
    "marketplace_to_demand": 125.5831127,
    "grid_to_demand": 30228.2115,
    "govt_battery_charge": 44.57173735
  },
  {
    "timestamp": "18/02/25 15:00",
    "demand": 33249.12989,
    "solar_generated": 7.899084326,
    "wind_generated": 75.1480282,
    "marketplace_to_demand": 34.53153574,
    "grid_to_demand": 35338.8853,
    "govt_battery_charge": 29.05080326
  },
  {
    "timestamp": "18/02/25 16:00",
    "demand": 36495.44813,
    "solar_generated": 11.91424599,
    "wind_generated": 66.26513862,
    "marketplace_to_demand": 152.4685494,
    "grid_to_demand": 34541.1742,
    "govt_battery_charge": 78.77629268
  },
  {
    "timestamp": "18/02/25 17:00",
    "demand": 32203.2166,
    "solar_generated": 21.47511681,
    "wind_generated": 79.19232332,
    "marketplace_to_demand": 32.61297251,
    "grid_to_demand": 34598.06364,
    "govt_battery_charge": 59.0454798
  },
  {
    "timestamp": "18/02/25 18:00",
    "demand": 36074.50181,
    "solar_generated": 0,
    "wind_generated": 62.74227045,
    "marketplace_to_demand": 135.3234375,
    "grid_to_demand": 35429.77817,
    "govt_battery_charge": 49.21714168
  },
  {
    "timestamp": "18/02/25 19:00",
    "demand": 33850.43875,
    "solar_generated": 0,
    "wind_generated": 71.3847382,
    "marketplace_to_demand": 37.55014697,
    "grid_to_demand": 33131.29799,
    "govt_battery_charge": 51.70612991
  },
  {
    "timestamp": "18/02/25 20:00",
    "demand": 35799.1191,
    "solar_generated": 0,
    "wind_generated": 70.91434558,
    "marketplace_to_demand": 129.8739812,
    "grid_to_demand": 34235.74647,
    "govt_battery_charge": 9.595247889
  },
  {
    "timestamp": "18/02/25 21:00",
    "demand": 33355.20243,
    "solar_generated": 0,
    "wind_generated": 59.05358079,
    "marketplace_to_demand": 41.28887812,
    "grid_to_demand": 36009.71837,
    "govt_battery_charge": 16.03493112
  },
  {
    "timestamp": "18/02/25 22:00",
    "demand": 35221.48257,
    "solar_generated": 0,
    "wind_generated": 76.55807257,
    "marketplace_to_demand": 134.187772,
    "grid_to_demand": 35962.16992,
    "govt_battery_charge": 43.93345818
  },
  {
    "timestamp": "18/02/25 23:00",
    "demand": 34761.01929,
    "solar_generated": 0,
    "wind_generated": 56.08280194,
    "marketplace_to_demand": 58.51041357,
    "grid_to_demand": 35035.39457,
    "govt_battery_charge": 34.64339855
  },
  {
    "timestamp": "19/02/25 0:00",
    "demand": 35496.50258,
    "solar_generated": 0,
    "wind_generated": 70.0296651,
    "marketplace_to_demand": 177.8861445,
    "grid_to_demand": 30038.19626,
    "govt_battery_charge": 97.73906144
  },
  {
    "timestamp": "19/02/25 1:00",
    "demand": 36577.56511,
    "solar_generated": 0,
    "wind_generated": 68.24541714,
    "marketplace_to_demand": 123.9405383,
    "grid_to_demand": 30996.46296,
    "govt_battery_charge": 12.87621929
  },
  {
    "timestamp": "19/02/25 2:00",
    "demand": 33976.3354,
    "solar_generated": 0,
    "wind_generated": 73.52092256,
    "marketplace_to_demand": 34.94702378,
    "grid_to_demand": 30522.53319,
    "govt_battery_charge": 95.86376039
  },
  {
    "timestamp": "19/02/25 3:00",
    "demand": 31335.37696,
    "solar_generated": 0,
    "wind_generated": 80.28476678,
    "marketplace_to_demand": 43.4538349,
    "grid_to_demand": 36484.5169,
    "govt_battery_charge": 53.38089875
  },
  {
    "timestamp": "19/02/25 4:00",
    "demand": 31899.48356,
    "solar_generated": 0,
    "wind_generated": 62.00169443,
    "marketplace_to_demand": 24.28080316,
    "grid_to_demand": 30187.9029,
    "govt_battery_charge": 35.80171278
  },
  {
    "timestamp": "19/02/25 5:00",
    "demand": 33045.3384,
    "solar_generated": 0,
    "wind_generated": 68.10874965,
    "marketplace_to_demand": 153.6218994,
    "grid_to_demand": 30144.10016,
    "govt_battery_charge": 52.91559561
  },
  {
    "timestamp": "19/02/25 6:00",
    "demand": 32646.34973,
    "solar_generated": 6.702492945,
    "wind_generated": 79.41995585,
    "marketplace_to_demand": 48.40027683,
    "grid_to_demand": 31383.25332,
    "govt_battery_charge": 56.18622975
  },
  {
    "timestamp": "19/02/25 7:00",
    "demand": 33810.88806,
    "solar_generated": 7.891311942,
    "wind_generated": 63.20896759,
    "marketplace_to_demand": 54.66914716,
    "grid_to_demand": 31812.18999,
    "govt_battery_charge": 6.99450112
  },
  {
    "timestamp": "19/02/25 8:00",
    "demand": 31667.40887,
    "solar_generated": 23.76916284,
    "wind_generated": 74.0441815,
    "marketplace_to_demand": 154.2550779,
    "grid_to_demand": 34220.29132,
    "govt_battery_charge": 62.91716435
  },
  {
    "timestamp": "19/02/25 9:00",
    "demand": 34980.02757,
    "solar_generated": 16.14252846,
    "wind_generated": 71.0321808,
    "marketplace_to_demand": 26.82146503,
    "grid_to_demand": 34745.42504,
    "govt_battery_charge": 32.77762755
  },
  {
    "timestamp": "19/02/25 10:00",
    "demand": 35139.55094,
    "solar_generated": 6.675507107,
    "wind_generated": 64.38798257,
    "marketplace_to_demand": 113.8761793,
    "grid_to_demand": 35720.49523,
    "govt_battery_charge": 69.22360586
  },
  {
    "timestamp": "19/02/25 11:00",
    "demand": 30841.8678,
    "solar_generated": 7.697896236,
    "wind_generated": 76.84443497,
    "marketplace_to_demand": 133.6886847,
    "grid_to_demand": 34692.90343,
    "govt_battery_charge": 46.86258667
  },
  {
    "timestamp": "19/02/25 12:00",
    "demand": 33455.59517,
    "solar_generated": 8.285605545,
    "wind_generated": 67.58405632,
    "marketplace_to_demand": 90.63157396,
    "grid_to_demand": 30143.57153,
    "govt_battery_charge": 16.92188251
  },
  {
    "timestamp": "19/02/25 13:00",
    "demand": 31352.40678,
    "solar_generated": 18.49258822,
    "wind_generated": 76.13876897,
    "marketplace_to_demand": 25.30117415,
    "grid_to_demand": 30419.09352,
    "govt_battery_charge": 69.16006002
  },
  {
    "timestamp": "19/02/25 14:00",
    "demand": 32468.43414,
    "solar_generated": 13.39113504,
    "wind_generated": 65.55719461,
    "marketplace_to_demand": 142.4731022,
    "grid_to_demand": 30367.9564,
    "govt_battery_charge": 14.29249411
  },
  {
    "timestamp": "19/02/25 15:00",
    "demand": 34039.77445,
    "solar_generated": 9.223396889,
    "wind_generated": 69.6743122,
    "marketplace_to_demand": 169.9917047,
    "grid_to_demand": 30874.22495,
    "govt_battery_charge": 97.49992329
  },
  {
    "timestamp": "19/02/25 16:00",
    "demand": 32113.43014,
    "solar_generated": 10.14918407,
    "wind_generated": 54.73816384,
    "marketplace_to_demand": 38.66677507,
    "grid_to_demand": 35162.86176,
    "govt_battery_charge": 31.23394772
  },
  {
    "timestamp": "19/02/25 17:00",
    "demand": 33377.4642,
    "solar_generated": 15.29947585,
    "wind_generated": 54.94138664,
    "marketplace_to_demand": 199.7838479,
    "grid_to_demand": 32865.0722,
    "govt_battery_charge": 18.85629391
  },
  {
    "timestamp": "19/02/25 18:00",
    "demand": 31509.29123,
    "solar_generated": 0,
    "wind_generated": 64.27032876,
    "marketplace_to_demand": 116.1745497,
    "grid_to_demand": 35458.07711,
    "govt_battery_charge": 52.21886481
  },
  {
    "timestamp": "19/02/25 19:00",
    "demand": 33378.15428,
    "solar_generated": 0,
    "wind_generated": 72.28176771,
    "marketplace_to_demand": 80.14145079,
    "grid_to_demand": 35517.69147,
    "govt_battery_charge": 12.15313396
  },
  {
    "timestamp": "19/02/25 20:00",
    "demand": 30494.56635,
    "solar_generated": 0,
    "wind_generated": 65.0115624,
    "marketplace_to_demand": 92.22328032,
    "grid_to_demand": 30118.36552,
    "govt_battery_charge": 15.03392538
  },
  {
    "timestamp": "19/02/25 21:00",
    "demand": 30515.03177,
    "solar_generated": 0,
    "wind_generated": 78.91995314,
    "marketplace_to_demand": 128.0782654,
    "grid_to_demand": 33711.69097,
    "govt_battery_charge": 14.09467482
  },
  {
    "timestamp": "19/02/25 22:00",
    "demand": 34107.26166,
    "solar_generated": 0,
    "wind_generated": 75.60219346,
    "marketplace_to_demand": 25.63794955,
    "grid_to_demand": 30563.38316,
    "govt_battery_charge": 94.77981142
  },
  {
    "timestamp": "19/02/25 23:00",
    "demand": 35303.73562,
    "solar_generated": 0,
    "wind_generated": 61.40985724,
    "marketplace_to_demand": 167.6598773,
    "grid_to_demand": 34824.86776,
    "govt_battery_charge": 43.00906907
  },
  {
    "timestamp": "20/02/25 0:00",
    "demand": 32313.14455,
    "solar_generated": 0,
    "wind_generated": 67.46983236,
    "marketplace_to_demand": 174.2676242,
    "grid_to_demand": 31799.17194,
    "govt_battery_charge": 45.11161791
  },
  {
    "timestamp": "20/02/25 1:00",
    "demand": 32247.72865,
    "solar_generated": 0,
    "wind_generated": 79.19681105,
    "marketplace_to_demand": 29.60549305,
    "grid_to_demand": 32338.28185,
    "govt_battery_charge": 63.75430782
  },
  {
    "timestamp": "20/02/25 2:00",
    "demand": 32889.23627,
    "solar_generated": 0,
    "wind_generated": 65.60252169,
    "marketplace_to_demand": 95.57658453,
    "grid_to_demand": 35708.90206,
    "govt_battery_charge": 89.62747514
  },
  {
    "timestamp": "20/02/25 3:00",
    "demand": 31731.83822,
    "solar_generated": 0,
    "wind_generated": 78.18504559,
    "marketplace_to_demand": 177.0581712,
    "grid_to_demand": 30791.66659,
    "govt_battery_charge": 32.3007343
  },
  {
    "timestamp": "20/02/25 4:00",
    "demand": 34240.1631,
    "solar_generated": 0,
    "wind_generated": 79.45469282,
    "marketplace_to_demand": 94.37607982,
    "grid_to_demand": 34589.81546,
    "govt_battery_charge": 88.57558687
  },
  {
    "timestamp": "20/02/25 5:00",
    "demand": 30380.26278,
    "solar_generated": 0,
    "wind_generated": 72.68915266,
    "marketplace_to_demand": 146.9999192,
    "grid_to_demand": 30805.65453,
    "govt_battery_charge": 20.44911619
  },
  {
    "timestamp": "20/02/25 6:00",
    "demand": 33232.71796,
    "solar_generated": 7.418082485,
    "wind_generated": 65.01075651,
    "marketplace_to_demand": 87.35243505,
    "grid_to_demand": 30052.42003,
    "govt_battery_charge": 74.214697
  },
  {
    "timestamp": "20/02/25 7:00",
    "demand": 30922.25933,
    "solar_generated": 6.723965301,
    "wind_generated": 70.25172264,
    "marketplace_to_demand": 22.17582718,
    "grid_to_demand": 34730.52697,
    "govt_battery_charge": 36.5206886
  },
  {
    "timestamp": "20/02/25 8:00",
    "demand": 31870.71575,
    "solar_generated": 15.30234146,
    "wind_generated": 69.84257337,
    "marketplace_to_demand": 22.1821003,
    "grid_to_demand": 33180.97568,
    "govt_battery_charge": 38.88993112
  },
  {
    "timestamp": "20/02/25 9:00",
    "demand": 36174.60096,
    "solar_generated": 11.34944632,
    "wind_generated": 77.56697181,
    "marketplace_to_demand": 149.0591618,
    "grid_to_demand": 31030.36577,
    "govt_battery_charge": 63.24553381
  },
  {
    "timestamp": "20/02/25 10:00",
    "demand": 30699.34875,
    "solar_generated": 12.00609002,
    "wind_generated": 74.99431106,
    "marketplace_to_demand": 108.7154925,
    "grid_to_demand": 32943.01632,
    "govt_battery_charge": 43.50971132
  },
  {
    "timestamp": "20/02/25 11:00",
    "demand": 32036.14555,
    "solar_generated": 16.68456537,
    "wind_generated": 63.51139217,
    "marketplace_to_demand": 68.38764425,
    "grid_to_demand": 31643.05837,
    "govt_battery_charge": 46.06021186
  },
  {
    "timestamp": "20/02/25 12:00",
    "demand": 31162.94619,
    "solar_generated": 23.27212805,
    "wind_generated": 76.72181249,
    "marketplace_to_demand": 127.5237486,
    "grid_to_demand": 32687.68223,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "20/02/25 13:00",
    "demand": 32006.16965,
    "solar_generated": 20.30169006,
    "wind_generated": 77.72380014,
    "marketplace_to_demand": 8.371435926,
    "grid_to_demand": 33848.55183,
    "govt_battery_charge": 94.99796354
  },
  {
    "timestamp": "20/02/25 14:00",
    "demand": 31118.43591,
    "solar_generated": 17.50478528,
    "wind_generated": 60.18866578,
    "marketplace_to_demand": 30.40607689,
    "grid_to_demand": 35851.71015,
    "govt_battery_charge": 15.12380809
  },
  {
    "timestamp": "20/02/25 15:00",
    "demand": 30410.36251,
    "solar_generated": 19.86513782,
    "wind_generated": 60.82459027,
    "marketplace_to_demand": 131.9104639,
    "grid_to_demand": 32558.38616,
    "govt_battery_charge": 60.75091828
  },
  {
    "timestamp": "20/02/25 16:00",
    "demand": 30240.01687,
    "solar_generated": 17.67494054,
    "wind_generated": 66.0099521,
    "marketplace_to_demand": 71.21427306,
    "grid_to_demand": 33977.63629,
    "govt_battery_charge": 54.89170598
  },
  {
    "timestamp": "20/02/25 17:00",
    "demand": 31349.31342,
    "solar_generated": 10.55186129,
    "wind_generated": 55.7264554,
    "marketplace_to_demand": 116.6814477,
    "grid_to_demand": 31173.00498,
    "govt_battery_charge": 24.89919226
  },
  {
    "timestamp": "20/02/25 18:00",
    "demand": 35537.33585,
    "solar_generated": 0,
    "wind_generated": 75.1304392,
    "marketplace_to_demand": 42.28857094,
    "grid_to_demand": 31350.52817,
    "govt_battery_charge": 73.99805146
  },
  {
    "timestamp": "20/02/25 19:00",
    "demand": 33663.29033,
    "solar_generated": 0,
    "wind_generated": 71.48421679,
    "marketplace_to_demand": 193.8706431,
    "grid_to_demand": 36305.85481,
    "govt_battery_charge": 60.83086018
  },
  {
    "timestamp": "20/02/25 20:00",
    "demand": 32206.1666,
    "solar_generated": 0,
    "wind_generated": 71.44424862,
    "marketplace_to_demand": 75.73140468,
    "grid_to_demand": 32353.99232,
    "govt_battery_charge": 84.2633167
  },
  {
    "timestamp": "20/02/25 21:00",
    "demand": 30646.45784,
    "solar_generated": 0,
    "wind_generated": 65.84476805,
    "marketplace_to_demand": 126.8764824,
    "grid_to_demand": 30699.18373,
    "govt_battery_charge": 59.46855173
  },
  {
    "timestamp": "20/02/25 22:00",
    "demand": 30286.98804,
    "solar_generated": 0,
    "wind_generated": 55.62331639,
    "marketplace_to_demand": 8.663508528,
    "grid_to_demand": 35599.89515,
    "govt_battery_charge": 3.89056553
  },
  {
    "timestamp": "20/02/25 23:00",
    "demand": 35013.87413,
    "solar_generated": 0,
    "wind_generated": 64.8409935,
    "marketplace_to_demand": 9.211479135,
    "grid_to_demand": 36104.15651,
    "govt_battery_charge": 4.94521291
  },
  {
    "timestamp": "21/02/25 0:00",
    "demand": 30499.9034,
    "solar_generated": 0,
    "wind_generated": 61.97712282,
    "marketplace_to_demand": 40.47605918,
    "grid_to_demand": 32668.57018,
    "govt_battery_charge": 70.350921
  },
  {
    "timestamp": "21/02/25 1:00",
    "demand": 35460.31703,
    "solar_generated": 0,
    "wind_generated": 63.44074691,
    "marketplace_to_demand": 117.8496007,
    "grid_to_demand": 33473.65125,
    "govt_battery_charge": 74.70959723
  },
  {
    "timestamp": "21/02/25 2:00",
    "demand": 31955.86571,
    "solar_generated": 0,
    "wind_generated": 67.59982149,
    "marketplace_to_demand": 190.2836248,
    "grid_to_demand": 36362.91204,
    "govt_battery_charge": 88.65148614
  },
  {
    "timestamp": "21/02/25 3:00",
    "demand": 32042.00583,
    "solar_generated": 0,
    "wind_generated": 56.57299741,
    "marketplace_to_demand": 30.42118862,
    "grid_to_demand": 34570.47383,
    "govt_battery_charge": 53.85457681
  },
  {
    "timestamp": "21/02/25 4:00",
    "demand": 31797.88925,
    "solar_generated": 0,
    "wind_generated": 74.78327778,
    "marketplace_to_demand": 187.3124606,
    "grid_to_demand": 36380.5216,
    "govt_battery_charge": 79.5201306
  },
  {
    "timestamp": "21/02/25 5:00",
    "demand": 32914.34884,
    "solar_generated": 0,
    "wind_generated": 79.98825421,
    "marketplace_to_demand": 187.1875935,
    "grid_to_demand": 31718.35072,
    "govt_battery_charge": 17.236739
  },
  {
    "timestamp": "21/02/25 6:00",
    "demand": 33987.85316,
    "solar_generated": 23.43830404,
    "wind_generated": 59.1632782,
    "marketplace_to_demand": 64.89864601,
    "grid_to_demand": 34410.66336,
    "govt_battery_charge": 68.03029873
  },
  {
    "timestamp": "21/02/25 7:00",
    "demand": 34550.17989,
    "solar_generated": 8.470382826,
    "wind_generated": 58.0593664,
    "marketplace_to_demand": 128.6048134,
    "grid_to_demand": 35777.14153,
    "govt_battery_charge": 89.21878931
  },
  {
    "timestamp": "21/02/25 8:00",
    "demand": 35773.62189,
    "solar_generated": 6.781238272,
    "wind_generated": 59.83429298,
    "marketplace_to_demand": 149.5456348,
    "grid_to_demand": 35382.8207,
    "govt_battery_charge": 97.85764184
  },
  {
    "timestamp": "21/02/25 9:00",
    "demand": 32678.59872,
    "solar_generated": 14.92172753,
    "wind_generated": 54.37596068,
    "marketplace_to_demand": 90.10468652,
    "grid_to_demand": 30624.67613,
    "govt_battery_charge": 78.16148061
  },
  {
    "timestamp": "21/02/25 10:00",
    "demand": 33248.52791,
    "solar_generated": 11.9391544,
    "wind_generated": 69.55313091,
    "marketplace_to_demand": 100.5794072,
    "grid_to_demand": 32325.34039,
    "govt_battery_charge": 92.7948119
  },
  {
    "timestamp": "21/02/25 11:00",
    "demand": 33946.52507,
    "solar_generated": 15.60455085,
    "wind_generated": 64.30819032,
    "marketplace_to_demand": 158.5792717,
    "grid_to_demand": 33708.87231,
    "govt_battery_charge": 58.45852697
  },
  {
    "timestamp": "21/02/25 12:00",
    "demand": 30416.25533,
    "solar_generated": 11.95639802,
    "wind_generated": 55.14037062,
    "marketplace_to_demand": 68.15781258,
    "grid_to_demand": 32607.6611,
    "govt_battery_charge": 57.04339052
  },
  {
    "timestamp": "21/02/25 13:00",
    "demand": 31776.85699,
    "solar_generated": 12.41610901,
    "wind_generated": 57.34762089,
    "marketplace_to_demand": 10.44353138,
    "grid_to_demand": 35061.66587,
    "govt_battery_charge": 73.43504827
  },
  {
    "timestamp": "21/02/25 14:00",
    "demand": 36316.52025,
    "solar_generated": 23.11503285,
    "wind_generated": 63.89121369,
    "marketplace_to_demand": 111.3831316,
    "grid_to_demand": 35266.22606,
    "govt_battery_charge": 82.94720665
  },
  {
    "timestamp": "21/02/25 15:00",
    "demand": 31402.56364,
    "solar_generated": 15.35229956,
    "wind_generated": 53.82915679,
    "marketplace_to_demand": 35.85845905,
    "grid_to_demand": 30288.5592,
    "govt_battery_charge": 45.33423543
  },
  {
    "timestamp": "21/02/25 16:00",
    "demand": 34022.59493,
    "solar_generated": 16.57439385,
    "wind_generated": 68.10040205,
    "marketplace_to_demand": 176.2197046,
    "grid_to_demand": 35799.75871,
    "govt_battery_charge": 76.3857481
  },
  {
    "timestamp": "21/02/25 17:00",
    "demand": 30450.35447,
    "solar_generated": 15.36912537,
    "wind_generated": 74.87854539,
    "marketplace_to_demand": 81.72817814,
    "grid_to_demand": 33984.88873,
    "govt_battery_charge": 4.01763823
  },
  {
    "timestamp": "21/02/25 18:00",
    "demand": 30849.12149,
    "solar_generated": 0,
    "wind_generated": 60.07532589,
    "marketplace_to_demand": 170.2452574,
    "grid_to_demand": 30241.47142,
    "govt_battery_charge": 83.08711318
  },
  {
    "timestamp": "21/02/25 19:00",
    "demand": 36554.84993,
    "solar_generated": 0,
    "wind_generated": 64.32625236,
    "marketplace_to_demand": 90.79710405,
    "grid_to_demand": 31169.31892,
    "govt_battery_charge": 48.1980124
  },
  {
    "timestamp": "21/02/25 20:00",
    "demand": 32971.50848,
    "solar_generated": 0,
    "wind_generated": 62.03152178,
    "marketplace_to_demand": 31.54936363,
    "grid_to_demand": 34295.57887,
    "govt_battery_charge": 80.67855134
  },
  {
    "timestamp": "21/02/25 21:00",
    "demand": 31675.74975,
    "solar_generated": 0,
    "wind_generated": 74.68955051,
    "marketplace_to_demand": 100.214612,
    "grid_to_demand": 31838.59161,
    "govt_battery_charge": 83.2798421
  },
  {
    "timestamp": "21/02/25 22:00",
    "demand": 33247.79568,
    "solar_generated": 0,
    "wind_generated": 65.36472102,
    "marketplace_to_demand": 35.12594364,
    "grid_to_demand": 30977.37928,
    "govt_battery_charge": 67.36358743
  },
  {
    "timestamp": "21/02/25 23:00",
    "demand": 31816.5674,
    "solar_generated": 0,
    "wind_generated": 57.7275556,
    "marketplace_to_demand": 124.1155264,
    "grid_to_demand": 33491.46643,
    "govt_battery_charge": 17.51043031
  },
  {
    "timestamp": "22/02/25 0:00",
    "demand": 31564.49932,
    "solar_generated": 0,
    "wind_generated": 53.91401061,
    "marketplace_to_demand": 88.72639956,
    "grid_to_demand": 35031.38392,
    "govt_battery_charge": 6.213515679
  },
  {
    "timestamp": "22/02/25 1:00",
    "demand": 31137.21202,
    "solar_generated": 0,
    "wind_generated": 76.44746338,
    "marketplace_to_demand": 29.0236165,
    "grid_to_demand": 32189.97497,
    "govt_battery_charge": 68.91962308
  },
  {
    "timestamp": "22/02/25 2:00",
    "demand": 33066.79062,
    "solar_generated": 0,
    "wind_generated": 72.00465013,
    "marketplace_to_demand": 2.706292086,
    "grid_to_demand": 31244.95675,
    "govt_battery_charge": 22.86408398
  },
  {
    "timestamp": "22/02/25 3:00",
    "demand": 34071.82759,
    "solar_generated": 0,
    "wind_generated": 67.07984502,
    "marketplace_to_demand": 102.3796905,
    "grid_to_demand": 35566.13059,
    "govt_battery_charge": 9.343203494
  },
  {
    "timestamp": "22/02/25 4:00",
    "demand": 33659.7402,
    "solar_generated": 0,
    "wind_generated": 57.50950726,
    "marketplace_to_demand": 129.7804407,
    "grid_to_demand": 33930.01251,
    "govt_battery_charge": 79.2465502
  },
  {
    "timestamp": "22/02/25 5:00",
    "demand": 30412.92951,
    "solar_generated": 0,
    "wind_generated": 79.94652097,
    "marketplace_to_demand": 46.00502717,
    "grid_to_demand": 32104.71136,
    "govt_battery_charge": 17.30939426
  },
  {
    "timestamp": "22/02/25 6:00",
    "demand": 35029.46421,
    "solar_generated": 15.48175491,
    "wind_generated": 54.15475903,
    "marketplace_to_demand": 154.4800778,
    "grid_to_demand": 32697.73541,
    "govt_battery_charge": 43.07047724
  },
  {
    "timestamp": "22/02/25 7:00",
    "demand": 34304.83673,
    "solar_generated": 12.17276177,
    "wind_generated": 54.40718386,
    "marketplace_to_demand": 101.1102423,
    "grid_to_demand": 30910.37038,
    "govt_battery_charge": 91.90895129
  },
  {
    "timestamp": "22/02/25 8:00",
    "demand": 32172.95394,
    "solar_generated": 14.95855566,
    "wind_generated": 59.75482086,
    "marketplace_to_demand": 154.558911,
    "grid_to_demand": 32248.08673,
    "govt_battery_charge": 74.67858321
  },
  {
    "timestamp": "22/02/25 9:00",
    "demand": 31641.77256,
    "solar_generated": 6.161123467,
    "wind_generated": 72.13499767,
    "marketplace_to_demand": 176.6942961,
    "grid_to_demand": 31536.83349,
    "govt_battery_charge": 75.84260035
  },
  {
    "timestamp": "22/02/25 10:00",
    "demand": 34555.03773,
    "solar_generated": 10.37192669,
    "wind_generated": 67.47418689,
    "marketplace_to_demand": 89.47433699,
    "grid_to_demand": 34714.74503,
    "govt_battery_charge": 7.17848211
  },
  {
    "timestamp": "22/02/25 11:00",
    "demand": 33747.28999,
    "solar_generated": 15.60173945,
    "wind_generated": 65.97928025,
    "marketplace_to_demand": 157.3092359,
    "grid_to_demand": 31170.38384,
    "govt_battery_charge": 73.74359391
  },
  {
    "timestamp": "22/02/25 12:00",
    "demand": 34809.45312,
    "solar_generated": 6.680103377,
    "wind_generated": 74.22720234,
    "marketplace_to_demand": 67.30327294,
    "grid_to_demand": 33350.85936,
    "govt_battery_charge": 96.31669066
  },
  {
    "timestamp": "22/02/25 13:00",
    "demand": 34262.96577,
    "solar_generated": 6.58650851,
    "wind_generated": 65.92756647,
    "marketplace_to_demand": 0.253234244,
    "grid_to_demand": 32531.5892,
    "govt_battery_charge": 4.38254097
  },
  {
    "timestamp": "22/02/25 14:00",
    "demand": 33366.49267,
    "solar_generated": 17.24615127,
    "wind_generated": 78.55536873,
    "marketplace_to_demand": 126.1778275,
    "grid_to_demand": 32528.34511,
    "govt_battery_charge": 76.53251213
  },
  {
    "timestamp": "22/02/25 15:00",
    "demand": 36259.6022,
    "solar_generated": 17.33440908,
    "wind_generated": 57.21548476,
    "marketplace_to_demand": 48.44492166,
    "grid_to_demand": 32457.25961,
    "govt_battery_charge": 73.53713214
  },
  {
    "timestamp": "22/02/25 16:00",
    "demand": 33901.57117,
    "solar_generated": 17.64997256,
    "wind_generated": 72.32503742,
    "marketplace_to_demand": 198.8340759,
    "grid_to_demand": 30632.8445,
    "govt_battery_charge": 57.18467714
  },
  {
    "timestamp": "22/02/25 17:00",
    "demand": 31909.16936,
    "solar_generated": 11.3255373,
    "wind_generated": 58.97102533,
    "marketplace_to_demand": 105.3956581,
    "grid_to_demand": 33993.55946,
    "govt_battery_charge": 22.64584492
  },
  {
    "timestamp": "22/02/25 18:00",
    "demand": 32618.83226,
    "solar_generated": 0,
    "wind_generated": 57.97379013,
    "marketplace_to_demand": 76.603824,
    "grid_to_demand": 30888.89451,
    "govt_battery_charge": 55.14454035
  },
  {
    "timestamp": "22/02/25 19:00",
    "demand": 35468.08435,
    "solar_generated": 0,
    "wind_generated": 76.37454702,
    "marketplace_to_demand": 140.8628309,
    "grid_to_demand": 30496.911,
    "govt_battery_charge": 52.41588708
  },
  {
    "timestamp": "22/02/25 20:00",
    "demand": 33777.576,
    "solar_generated": 0,
    "wind_generated": 69.29699608,
    "marketplace_to_demand": 26.74707122,
    "grid_to_demand": 35191.9242,
    "govt_battery_charge": 39.63041014
  },
  {
    "timestamp": "22/02/25 21:00",
    "demand": 30849.05626,
    "solar_generated": 0,
    "wind_generated": 73.81603257,
    "marketplace_to_demand": 193.3161341,
    "grid_to_demand": 34015.94016,
    "govt_battery_charge": 84.57765458
  },
  {
    "timestamp": "22/02/25 22:00",
    "demand": 33792.09,
    "solar_generated": 0,
    "wind_generated": 74.82222778,
    "marketplace_to_demand": 186.0818631,
    "grid_to_demand": 36296.62035,
    "govt_battery_charge": 52.33389891
  },
  {
    "timestamp": "22/02/25 23:00",
    "demand": 34272.19627,
    "solar_generated": 0,
    "wind_generated": 80.23055051,
    "marketplace_to_demand": 164.5508475,
    "grid_to_demand": 30735.19819,
    "govt_battery_charge": 25.32980109
  },
  {
    "timestamp": "23/02/25 0:00",
    "demand": 33419.08522,
    "solar_generated": 0,
    "wind_generated": 70.82145071,
    "marketplace_to_demand": 71.73543709,
    "grid_to_demand": 33475.93079,
    "govt_battery_charge": 78.84527688
  },
  {
    "timestamp": "23/02/25 1:00",
    "demand": 32215.3021,
    "solar_generated": 0,
    "wind_generated": 69.37288772,
    "marketplace_to_demand": 144.1573968,
    "grid_to_demand": 34560.75115,
    "govt_battery_charge": 74.84852133
  },
  {
    "timestamp": "23/02/25 2:00",
    "demand": 34332.0678,
    "solar_generated": 0,
    "wind_generated": 71.46046769,
    "marketplace_to_demand": 175.7923884,
    "grid_to_demand": 30501.71635,
    "govt_battery_charge": 64.64210191
  },
  {
    "timestamp": "23/02/25 3:00",
    "demand": 33960.21898,
    "solar_generated": 0,
    "wind_generated": 65.36977014,
    "marketplace_to_demand": 197.0432847,
    "grid_to_demand": 32371.69692,
    "govt_battery_charge": 31.14328869
  },
  {
    "timestamp": "23/02/25 4:00",
    "demand": 31323.31244,
    "solar_generated": 0,
    "wind_generated": 64.90439256,
    "marketplace_to_demand": 30.79333417,
    "grid_to_demand": 32621.12027,
    "govt_battery_charge": 31.89198697
  },
  {
    "timestamp": "23/02/25 5:00",
    "demand": 33277.14984,
    "solar_generated": 0,
    "wind_generated": 53.90873392,
    "marketplace_to_demand": 62.90899191,
    "grid_to_demand": 36050.30167,
    "govt_battery_charge": 48.24078761
  },
  {
    "timestamp": "23/02/25 6:00",
    "demand": 32891.04746,
    "solar_generated": 14.65418996,
    "wind_generated": 55.1054444,
    "marketplace_to_demand": 196.2520674,
    "grid_to_demand": 32277.11558,
    "govt_battery_charge": 74.91785698
  },
  {
    "timestamp": "23/02/25 7:00",
    "demand": 32771.5588,
    "solar_generated": 8.093814258,
    "wind_generated": 74.55665795,
    "marketplace_to_demand": 145.1266169,
    "grid_to_demand": 33235.46154,
    "govt_battery_charge": 69.74575382
  },
  {
    "timestamp": "23/02/25 8:00",
    "demand": 32566.01626,
    "solar_generated": 7.379564335,
    "wind_generated": 54.75150726,
    "marketplace_to_demand": 54.67062863,
    "grid_to_demand": 31255.00857,
    "govt_battery_charge": 10.88430112
  },
  {
    "timestamp": "23/02/25 9:00",
    "demand": 35037.69684,
    "solar_generated": 8.41649969,
    "wind_generated": 70.28864012,
    "marketplace_to_demand": 143.31503,
    "grid_to_demand": 33973.32963,
    "govt_battery_charge": 52.10243146
  },
  {
    "timestamp": "23/02/25 10:00",
    "demand": 35785.43273,
    "solar_generated": 12.02894909,
    "wind_generated": 66.41457629,
    "marketplace_to_demand": 157.5600705,
    "grid_to_demand": 35856.09812,
    "govt_battery_charge": 51.14711171
  },
  {
    "timestamp": "23/02/25 11:00",
    "demand": 32081.34085,
    "solar_generated": 21.78182469,
    "wind_generated": 71.88519071,
    "marketplace_to_demand": 19.39618098,
    "grid_to_demand": 30287.57974,
    "govt_battery_charge": 41.72125761
  },
  {
    "timestamp": "23/02/25 12:00",
    "demand": 32922.81688,
    "solar_generated": 20.80668221,
    "wind_generated": 74.4421127,
    "marketplace_to_demand": 94.17627287,
    "grid_to_demand": 31709.78052,
    "govt_battery_charge": 95.54465891
  },
  {
    "timestamp": "23/02/25 13:00",
    "demand": 30870.49322,
    "solar_generated": 13.98335371,
    "wind_generated": 53.81979528,
    "marketplace_to_demand": 161.0553405,
    "grid_to_demand": 30530.27696,
    "govt_battery_charge": 75.52965663
  },
  {
    "timestamp": "23/02/25 14:00",
    "demand": 34402.41466,
    "solar_generated": 21.95284121,
    "wind_generated": 63.2163698,
    "marketplace_to_demand": 198.941176,
    "grid_to_demand": 33793.35098,
    "govt_battery_charge": 76.53677836
  },
  {
    "timestamp": "23/02/25 15:00",
    "demand": 33233.94229,
    "solar_generated": 19.02963519,
    "wind_generated": 76.42832027,
    "marketplace_to_demand": 146.0890973,
    "grid_to_demand": 33530.43342,
    "govt_battery_charge": 98.04320471
  },
  {
    "timestamp": "23/02/25 16:00",
    "demand": 35595.85707,
    "solar_generated": 12.81355175,
    "wind_generated": 56.8836452,
    "marketplace_to_demand": 36.23338177,
    "grid_to_demand": 36438.9139,
    "govt_battery_charge": 37.58712316
  },
  {
    "timestamp": "23/02/25 17:00",
    "demand": 30940.16986,
    "solar_generated": 13.86265118,
    "wind_generated": 55.74090643,
    "marketplace_to_demand": 109.756315,
    "grid_to_demand": 35528.71317,
    "govt_battery_charge": 62.83189519
  },
  {
    "timestamp": "23/02/25 18:00",
    "demand": 35083.00892,
    "solar_generated": 0,
    "wind_generated": 56.98478681,
    "marketplace_to_demand": 180.1765913,
    "grid_to_demand": 30415.76326,
    "govt_battery_charge": 43.09870551
  },
  {
    "timestamp": "23/02/25 19:00",
    "demand": 31669.00777,
    "solar_generated": 0,
    "wind_generated": 57.66629148,
    "marketplace_to_demand": 81.89945545,
    "grid_to_demand": 32719.3927,
    "govt_battery_charge": 16.31965275
  },
  {
    "timestamp": "23/02/25 20:00",
    "demand": 35572.27911,
    "solar_generated": 0,
    "wind_generated": 62.04464949,
    "marketplace_to_demand": 181.1237255,
    "grid_to_demand": 35431.58325,
    "govt_battery_charge": 74.84102439
  },
  {
    "timestamp": "23/02/25 21:00",
    "demand": 31029.38538,
    "solar_generated": 0,
    "wind_generated": 76.80670283,
    "marketplace_to_demand": 108.4962544,
    "grid_to_demand": 33198.30441,
    "govt_battery_charge": 83.93529557
  },
  {
    "timestamp": "23/02/25 22:00",
    "demand": 35136.70462,
    "solar_generated": 0,
    "wind_generated": 60.96141823,
    "marketplace_to_demand": 179.2380689,
    "grid_to_demand": 33695.26271,
    "govt_battery_charge": 25.96444174
  },
  {
    "timestamp": "23/02/25 23:00",
    "demand": 32887.44559,
    "solar_generated": 0,
    "wind_generated": 69.68923906,
    "marketplace_to_demand": 141.63196,
    "grid_to_demand": 31647.6891,
    "govt_battery_charge": 81.68512768
  },
  {
    "timestamp": "24/02/25 0:00",
    "demand": 31624.46428,
    "solar_generated": 0,
    "wind_generated": 74.30268928,
    "marketplace_to_demand": 50.62533898,
    "grid_to_demand": 35019.69053,
    "govt_battery_charge": 48.54991796
  },
  {
    "timestamp": "24/02/25 1:00",
    "demand": 31834.61471,
    "solar_generated": 0,
    "wind_generated": 72.03523693,
    "marketplace_to_demand": 158.9551641,
    "grid_to_demand": 32046.07344,
    "govt_battery_charge": 93.99263201
  },
  {
    "timestamp": "24/02/25 2:00",
    "demand": 34877.23981,
    "solar_generated": 0,
    "wind_generated": 70.02716663,
    "marketplace_to_demand": 75.74959311,
    "grid_to_demand": 34216.9146,
    "govt_battery_charge": 19.20444769
  },
  {
    "timestamp": "24/02/25 3:00",
    "demand": 33951.87444,
    "solar_generated": 0,
    "wind_generated": 60.46370215,
    "marketplace_to_demand": 120.7156664,
    "grid_to_demand": 36042.24748,
    "govt_battery_charge": 8.367387612
  },
  {
    "timestamp": "24/02/25 4:00",
    "demand": 32048.36608,
    "solar_generated": 0,
    "wind_generated": 61.71151863,
    "marketplace_to_demand": 112.5631493,
    "grid_to_demand": 30237.93432,
    "govt_battery_charge": 66.88004054
  },
  {
    "timestamp": "24/02/25 5:00",
    "demand": 31001.44712,
    "solar_generated": 0,
    "wind_generated": 66.24203974,
    "marketplace_to_demand": 107.7957373,
    "grid_to_demand": 36502.68433,
    "govt_battery_charge": 80.29266223
  },
  {
    "timestamp": "24/02/25 6:00",
    "demand": 32496.6946,
    "solar_generated": 19.92060271,
    "wind_generated": 75.85182514,
    "marketplace_to_demand": 198.9621294,
    "grid_to_demand": 34902.76586,
    "govt_battery_charge": 33.96385995
  },
  {
    "timestamp": "24/02/25 7:00",
    "demand": 36509.16142,
    "solar_generated": 16.21230875,
    "wind_generated": 64.61101139,
    "marketplace_to_demand": 172.4134571,
    "grid_to_demand": 31431.73002,
    "govt_battery_charge": 20.95988366
  },
  {
    "timestamp": "24/02/25 8:00",
    "demand": 30156.96104,
    "solar_generated": 17.92839928,
    "wind_generated": 72.55081651,
    "marketplace_to_demand": 29.79362524,
    "grid_to_demand": 34174.01786,
    "govt_battery_charge": 74.64771426
  },
  {
    "timestamp": "24/02/25 9:00",
    "demand": 30998.43143,
    "solar_generated": 10.07561711,
    "wind_generated": 66.4281702,
    "marketplace_to_demand": 125.8998072,
    "grid_to_demand": 30968.47423,
    "govt_battery_charge": 90.09552954
  },
  {
    "timestamp": "24/02/25 10:00",
    "demand": 31043.89738,
    "solar_generated": 15.96506706,
    "wind_generated": 72.22557735,
    "marketplace_to_demand": 145.2470336,
    "grid_to_demand": 31996.50526,
    "govt_battery_charge": 74.35955843
  },
  {
    "timestamp": "24/02/25 11:00",
    "demand": 36458.91748,
    "solar_generated": 8.419113045,
    "wind_generated": 72.57839106,
    "marketplace_to_demand": 156.4843046,
    "grid_to_demand": 32703.84851,
    "govt_battery_charge": 32.81042012
  },
  {
    "timestamp": "24/02/25 12:00",
    "demand": 35320.60304,
    "solar_generated": 21.23964473,
    "wind_generated": 74.55783424,
    "marketplace_to_demand": 24.58277164,
    "grid_to_demand": 35914.14461,
    "govt_battery_charge": 40.12695132
  },
  {
    "timestamp": "24/02/25 13:00",
    "demand": 30165.49102,
    "solar_generated": 8.941544952,
    "wind_generated": 76.44979662,
    "marketplace_to_demand": 128.7138547,
    "grid_to_demand": 32556.40345,
    "govt_battery_charge": 79.33614733
  },
  {
    "timestamp": "24/02/25 14:00",
    "demand": 34683.57925,
    "solar_generated": 9.418583494,
    "wind_generated": 59.50391911,
    "marketplace_to_demand": 161.2231652,
    "grid_to_demand": 31517.12478,
    "govt_battery_charge": 82.86766923
  },
  {
    "timestamp": "24/02/25 15:00",
    "demand": 31485.93444,
    "solar_generated": 13.40316845,
    "wind_generated": 55.28941935,
    "marketplace_to_demand": 81.66055059,
    "grid_to_demand": 32813.80474,
    "govt_battery_charge": 99.83311894
  },
  {
    "timestamp": "24/02/25 16:00",
    "demand": 31327.08668,
    "solar_generated": 21.24353653,
    "wind_generated": 79.06922746,
    "marketplace_to_demand": 102.3550349,
    "grid_to_demand": 36106.17785,
    "govt_battery_charge": 71.10303778
  },
  {
    "timestamp": "24/02/25 17:00",
    "demand": 33685.78409,
    "solar_generated": 23.06081904,
    "wind_generated": 77.09998535,
    "marketplace_to_demand": 195.2564962,
    "grid_to_demand": 34009.07559,
    "govt_battery_charge": 27.75435503
  },
  {
    "timestamp": "24/02/25 18:00",
    "demand": 36253.38161,
    "solar_generated": 0,
    "wind_generated": 59.17286607,
    "marketplace_to_demand": 83.95047342,
    "grid_to_demand": 33679.24206,
    "govt_battery_charge": 59.79396371
  },
  {
    "timestamp": "24/02/25 19:00",
    "demand": 31237.13646,
    "solar_generated": 0,
    "wind_generated": 61.7500192,
    "marketplace_to_demand": 197.4099245,
    "grid_to_demand": 35322.4598,
    "govt_battery_charge": 98.17095617
  },
  {
    "timestamp": "24/02/25 20:00",
    "demand": 31618.87384,
    "solar_generated": 0,
    "wind_generated": 55.22911749,
    "marketplace_to_demand": 34.55712409,
    "grid_to_demand": 30910.94591,
    "govt_battery_charge": 43.63240115
  },
  {
    "timestamp": "24/02/25 21:00",
    "demand": 35193.56572,
    "solar_generated": 0,
    "wind_generated": 69.50050909,
    "marketplace_to_demand": 9.66055166,
    "grid_to_demand": 36126.03249,
    "govt_battery_charge": 35.69201531
  },
  {
    "timestamp": "24/02/25 22:00",
    "demand": 34233.81349,
    "solar_generated": 0,
    "wind_generated": 76.78547474,
    "marketplace_to_demand": 21.10193244,
    "grid_to_demand": 31609.34267,
    "govt_battery_charge": 16.95953994
  },
  {
    "timestamp": "24/02/25 23:00",
    "demand": 30178.76578,
    "solar_generated": 0,
    "wind_generated": 65.32533031,
    "marketplace_to_demand": 115.7394521,
    "grid_to_demand": 32941.21202,
    "govt_battery_charge": 72.84206403
  },
  {
    "timestamp": "25/02/25 0:00",
    "demand": 33582.20447,
    "solar_generated": 0,
    "wind_generated": 73.17986398,
    "marketplace_to_demand": 87.00275328,
    "grid_to_demand": 36176.76618,
    "govt_battery_charge": 59.98722691
  },
  {
    "timestamp": "25/02/25 1:00",
    "demand": 36393.42586,
    "solar_generated": 0,
    "wind_generated": 62.8084458,
    "marketplace_to_demand": 100.8440356,
    "grid_to_demand": 35047.52543,
    "govt_battery_charge": 37.94322173
  },
  {
    "timestamp": "25/02/25 2:00",
    "demand": 32497.27887,
    "solar_generated": 0,
    "wind_generated": 56.21366746,
    "marketplace_to_demand": 55.90520421,
    "grid_to_demand": 33754.91311,
    "govt_battery_charge": 32.29545269
  },
  {
    "timestamp": "25/02/25 3:00",
    "demand": 35801.99046,
    "solar_generated": 0,
    "wind_generated": 55.54455236,
    "marketplace_to_demand": 68.02709859,
    "grid_to_demand": 34869.91624,
    "govt_battery_charge": 21.11514724
  },
  {
    "timestamp": "25/02/25 4:00",
    "demand": 31809.12439,
    "solar_generated": 0,
    "wind_generated": 61.86898719,
    "marketplace_to_demand": 4.02008048,
    "grid_to_demand": 33034.57687,
    "govt_battery_charge": 51.21860881
  },
  {
    "timestamp": "25/02/25 5:00",
    "demand": 31678.31862,
    "solar_generated": 0,
    "wind_generated": 61.5564833,
    "marketplace_to_demand": 167.8225813,
    "grid_to_demand": 33159.181,
    "govt_battery_charge": 40.72599691
  },
  {
    "timestamp": "25/02/25 6:00",
    "demand": 33586.81281,
    "solar_generated": 13.64229586,
    "wind_generated": 56.76954918,
    "marketplace_to_demand": 78.68007075,
    "grid_to_demand": 30726.26118,
    "govt_battery_charge": 75.06249694
  },
  {
    "timestamp": "25/02/25 7:00",
    "demand": 34473.66769,
    "solar_generated": 14.09666758,
    "wind_generated": 57.50893729,
    "marketplace_to_demand": 91.81307968,
    "grid_to_demand": 30804.3656,
    "govt_battery_charge": 31.43691434
  },
  {
    "timestamp": "25/02/25 8:00",
    "demand": 34188.30516,
    "solar_generated": 8.351586044,
    "wind_generated": 76.35713609,
    "marketplace_to_demand": 175.7671661,
    "grid_to_demand": 33829.73569,
    "govt_battery_charge": 22.37498471
  },
  {
    "timestamp": "25/02/25 9:00",
    "demand": 32914.30313,
    "solar_generated": 9.722177173,
    "wind_generated": 63.8252542,
    "marketplace_to_demand": 68.64485328,
    "grid_to_demand": 35069.80971,
    "govt_battery_charge": 92.05944892
  },
  {
    "timestamp": "25/02/25 10:00",
    "demand": 35926.65602,
    "solar_generated": 19.29866753,
    "wind_generated": 76.15207322,
    "marketplace_to_demand": 58.53634452,
    "grid_to_demand": 32518.01241,
    "govt_battery_charge": 57.54601299
  },
  {
    "timestamp": "25/02/25 11:00",
    "demand": 31324.46772,
    "solar_generated": 12.86731827,
    "wind_generated": 73.13266909,
    "marketplace_to_demand": 191.1372049,
    "grid_to_demand": 33837.01068,
    "govt_battery_charge": 93.89108512
  },
  {
    "timestamp": "25/02/25 12:00",
    "demand": 33199.54611,
    "solar_generated": 6.492149164,
    "wind_generated": 75.11944348,
    "marketplace_to_demand": 168.9414878,
    "grid_to_demand": 31265.77241,
    "govt_battery_charge": 48.54556524
  },
  {
    "timestamp": "25/02/25 13:00",
    "demand": 34952.50626,
    "solar_generated": 15.2116062,
    "wind_generated": 73.85772662,
    "marketplace_to_demand": 87.02970387,
    "grid_to_demand": 31077.48991,
    "govt_battery_charge": 87.13997244
  },
  {
    "timestamp": "25/02/25 14:00",
    "demand": 36309.76253,
    "solar_generated": 15.3015391,
    "wind_generated": 56.3510006,
    "marketplace_to_demand": 170.9936411,
    "grid_to_demand": 32779.46566,
    "govt_battery_charge": 38.57934188
  },
  {
    "timestamp": "25/02/25 15:00",
    "demand": 31802.73898,
    "solar_generated": 14.77776496,
    "wind_generated": 71.78144921,
    "marketplace_to_demand": 34.36130074,
    "grid_to_demand": 33984.58862,
    "govt_battery_charge": 62.13665738
  },
  {
    "timestamp": "25/02/25 16:00",
    "demand": 33713.56877,
    "solar_generated": 14.98487252,
    "wind_generated": 69.47549538,
    "marketplace_to_demand": 7.453148668,
    "grid_to_demand": 30806.73327,
    "govt_battery_charge": 63.43581166
  },
  {
    "timestamp": "25/02/25 17:00",
    "demand": 33507.13596,
    "solar_generated": 22.57380239,
    "wind_generated": 59.86425218,
    "marketplace_to_demand": 57.57932281,
    "grid_to_demand": 31391.40188,
    "govt_battery_charge": 11.29959624
  },
  {
    "timestamp": "25/02/25 18:00",
    "demand": 35319.7391,
    "solar_generated": 0,
    "wind_generated": 58.6751837,
    "marketplace_to_demand": 53.7278374,
    "grid_to_demand": 35759.11358,
    "govt_battery_charge": 37.36894502
  },
  {
    "timestamp": "25/02/25 19:00",
    "demand": 33268.56326,
    "solar_generated": 0,
    "wind_generated": 60.4705396,
    "marketplace_to_demand": 78.82003613,
    "grid_to_demand": 34686.81965,
    "govt_battery_charge": 30.75483657
  },
  {
    "timestamp": "25/02/25 20:00",
    "demand": 30351.01345,
    "solar_generated": 0,
    "wind_generated": 72.36170355,
    "marketplace_to_demand": 141.1040897,
    "grid_to_demand": 33915.45134,
    "govt_battery_charge": 20.61087512
  },
  {
    "timestamp": "25/02/25 21:00",
    "demand": 30518.9726,
    "solar_generated": 0,
    "wind_generated": 56.09091077,
    "marketplace_to_demand": 14.98038933,
    "grid_to_demand": 30914.34483,
    "govt_battery_charge": 18.97681017
  },
  {
    "timestamp": "25/02/25 22:00",
    "demand": 31683.64107,
    "solar_generated": 0,
    "wind_generated": 57.18108107,
    "marketplace_to_demand": 74.56990167,
    "grid_to_demand": 31815.67838,
    "govt_battery_charge": 90.34204673
  },
  {
    "timestamp": "25/02/25 23:00",
    "demand": 35204.76246,
    "solar_generated": 0,
    "wind_generated": 55.76167297,
    "marketplace_to_demand": 14.35833436,
    "grid_to_demand": 34411.54317,
    "govt_battery_charge": 78.72312925
  },
  {
    "timestamp": "26/02/25 0:00",
    "demand": 31297.21979,
    "solar_generated": 0,
    "wind_generated": 77.53386185,
    "marketplace_to_demand": 137.4953812,
    "grid_to_demand": 31738.30798,
    "govt_battery_charge": 99.20614075
  },
  {
    "timestamp": "26/02/25 1:00",
    "demand": 31757.88257,
    "solar_generated": 0,
    "wind_generated": 62.79867676,
    "marketplace_to_demand": 103.7103879,
    "grid_to_demand": 30674.8951,
    "govt_battery_charge": 37.29691363
  },
  {
    "timestamp": "26/02/25 2:00",
    "demand": 33217.11589,
    "solar_generated": 0,
    "wind_generated": 60.07063187,
    "marketplace_to_demand": 100.8099817,
    "grid_to_demand": 33249.42372,
    "govt_battery_charge": 24.67725586
  },
  {
    "timestamp": "26/02/25 3:00",
    "demand": 34852.3357,
    "solar_generated": 0,
    "wind_generated": 61.94853429,
    "marketplace_to_demand": 69.80207976,
    "grid_to_demand": 33227.55814,
    "govt_battery_charge": 67.39863404
  },
  {
    "timestamp": "26/02/25 4:00",
    "demand": 33556.84407,
    "solar_generated": 0,
    "wind_generated": 63.86139454,
    "marketplace_to_demand": 76.44282264,
    "grid_to_demand": 35393.42461,
    "govt_battery_charge": 5.972227628
  },
  {
    "timestamp": "26/02/25 5:00",
    "demand": 36110.67739,
    "solar_generated": 0,
    "wind_generated": 76.91952038,
    "marketplace_to_demand": 7.747219913,
    "grid_to_demand": 32272.88187,
    "govt_battery_charge": 66.05564502
  },
  {
    "timestamp": "26/02/25 6:00",
    "demand": 31424.65684,
    "solar_generated": 14.97797007,
    "wind_generated": 64.12221566,
    "marketplace_to_demand": 101.0239134,
    "grid_to_demand": 30020.44016,
    "govt_battery_charge": 16.99663652
  },
  {
    "timestamp": "26/02/25 7:00",
    "demand": 34554.82905,
    "solar_generated": 10.59858405,
    "wind_generated": 56.50186202,
    "marketplace_to_demand": 97.34461171,
    "grid_to_demand": 34249.80932,
    "govt_battery_charge": 42.7092887
  },
  {
    "timestamp": "26/02/25 8:00",
    "demand": 34831.18827,
    "solar_generated": 20.88987365,
    "wind_generated": 58.82564603,
    "marketplace_to_demand": 168.420623,
    "grid_to_demand": 30336.84814,
    "govt_battery_charge": 12.99557346
  },
  {
    "timestamp": "26/02/25 9:00",
    "demand": 35785.97657,
    "solar_generated": 16.72378236,
    "wind_generated": 76.98881505,
    "marketplace_to_demand": 175.2263088,
    "grid_to_demand": 36315.99664,
    "govt_battery_charge": 6.649999288
  },
  {
    "timestamp": "26/02/25 10:00",
    "demand": 35490.59135,
    "solar_generated": 10.90777684,
    "wind_generated": 63.01620798,
    "marketplace_to_demand": 166.5362892,
    "grid_to_demand": 36102.43022,
    "govt_battery_charge": 58.92722126
  },
  {
    "timestamp": "26/02/25 11:00",
    "demand": 29976.36768,
    "solar_generated": 21.5291911,
    "wind_generated": 55.31488132,
    "marketplace_to_demand": 165.2336676,
    "grid_to_demand": 33022.98593,
    "govt_battery_charge": 79.06586395
  },
  {
    "timestamp": "26/02/25 12:00",
    "demand": 34048.7232,
    "solar_generated": 20.70124759,
    "wind_generated": 55.99085501,
    "marketplace_to_demand": 179.8964335,
    "grid_to_demand": 32558.33533,
    "govt_battery_charge": 86.5015012
  },
  {
    "timestamp": "26/02/25 13:00",
    "demand": 31654.54819,
    "solar_generated": 13.03180239,
    "wind_generated": 72.61992727,
    "marketplace_to_demand": 186.6093311,
    "grid_to_demand": 35418.58721,
    "govt_battery_charge": 75.01239148
  },
  {
    "timestamp": "26/02/25 14:00",
    "demand": 30047.88422,
    "solar_generated": 15.56125528,
    "wind_generated": 74.83431555,
    "marketplace_to_demand": 92.33844348,
    "grid_to_demand": 31276.03507,
    "govt_battery_charge": 49.64460003
  },
  {
    "timestamp": "26/02/25 15:00",
    "demand": 30454.25179,
    "solar_generated": 20.53085576,
    "wind_generated": 67.15472378,
    "marketplace_to_demand": 37.3093116,
    "grid_to_demand": 34970.1308,
    "govt_battery_charge": 41.50912243
  },
  {
    "timestamp": "26/02/25 16:00",
    "demand": 31072.41034,
    "solar_generated": 18.4819355,
    "wind_generated": 75.35488507,
    "marketplace_to_demand": 120.9838021,
    "grid_to_demand": 36457.54282,
    "govt_battery_charge": 10.91861554
  },
  {
    "timestamp": "26/02/25 17:00",
    "demand": 34309.38944,
    "solar_generated": 16.70751753,
    "wind_generated": 61.09921905,
    "marketplace_to_demand": 77.53190109,
    "grid_to_demand": 30661.57781,
    "govt_battery_charge": 92.77894595
  },
  {
    "timestamp": "26/02/25 18:00",
    "demand": 30502.417,
    "solar_generated": 0,
    "wind_generated": 74.40138753,
    "marketplace_to_demand": 9.815237581,
    "grid_to_demand": 32670.73925,
    "govt_battery_charge": 58.38996671
  },
  {
    "timestamp": "26/02/25 19:00",
    "demand": 35496.99512,
    "solar_generated": 0,
    "wind_generated": 63.02849367,
    "marketplace_to_demand": 69.16328878,
    "grid_to_demand": 32356.24295,
    "govt_battery_charge": 83.25281119
  },
  {
    "timestamp": "26/02/25 20:00",
    "demand": 34416.3496,
    "solar_generated": 0,
    "wind_generated": 69.25970661,
    "marketplace_to_demand": 172.5167162,
    "grid_to_demand": 30924.70563,
    "govt_battery_charge": 76.71144351
  },
  {
    "timestamp": "26/02/25 21:00",
    "demand": 33273.1975,
    "solar_generated": 0,
    "wind_generated": 67.99195467,
    "marketplace_to_demand": 101.4136477,
    "grid_to_demand": 35550.96211,
    "govt_battery_charge": 55.9357402
  },
  {
    "timestamp": "26/02/25 22:00",
    "demand": 32137.64689,
    "solar_generated": 0,
    "wind_generated": 67.80289656,
    "marketplace_to_demand": 89.6673175,
    "grid_to_demand": 31373.2182,
    "govt_battery_charge": 63.22019255
  },
  {
    "timestamp": "26/02/25 23:00",
    "demand": 30404.1335,
    "solar_generated": 0,
    "wind_generated": 59.51171375,
    "marketplace_to_demand": 40.39629585,
    "grid_to_demand": 32092.13299,
    "govt_battery_charge": 78.01558449
  },
  {
    "timestamp": "27/02/25 0:00",
    "demand": 32921.06411,
    "solar_generated": 0,
    "wind_generated": 75.47499981,
    "marketplace_to_demand": 45.31869681,
    "grid_to_demand": 35472.45371,
    "govt_battery_charge": 37.50060867
  },
  {
    "timestamp": "27/02/25 1:00",
    "demand": 35758.08729,
    "solar_generated": 0,
    "wind_generated": 76.62191224,
    "marketplace_to_demand": 126.2486209,
    "grid_to_demand": 32168.42537,
    "govt_battery_charge": 18.22557165
  },
  {
    "timestamp": "27/02/25 2:00",
    "demand": 32801.58346,
    "solar_generated": 0,
    "wind_generated": 77.08237605,
    "marketplace_to_demand": 106.1764867,
    "grid_to_demand": 32363.13954,
    "govt_battery_charge": 50.23789209
  },
  {
    "timestamp": "27/02/25 3:00",
    "demand": 34248.97608,
    "solar_generated": 0,
    "wind_generated": 79.28165757,
    "marketplace_to_demand": 70.61382987,
    "grid_to_demand": 36195.88455,
    "govt_battery_charge": 7.106978355
  },
  {
    "timestamp": "27/02/25 4:00",
    "demand": 31317.93803,
    "solar_generated": 0,
    "wind_generated": 65.77796014,
    "marketplace_to_demand": 73.2230969,
    "grid_to_demand": 35700.68498,
    "govt_battery_charge": 4.218089492
  },
  {
    "timestamp": "27/02/25 5:00",
    "demand": 34682.50945,
    "solar_generated": 0,
    "wind_generated": 74.31522088,
    "marketplace_to_demand": 192.2894247,
    "grid_to_demand": 34837.817,
    "govt_battery_charge": 21.80729887
  },
  {
    "timestamp": "27/02/25 6:00",
    "demand": 33215.56682,
    "solar_generated": 9.374298978,
    "wind_generated": 65.07026807,
    "marketplace_to_demand": 45.53143533,
    "grid_to_demand": 34369.17145,
    "govt_battery_charge": 57.17193028
  },
  {
    "timestamp": "27/02/25 7:00",
    "demand": 33655.06185,
    "solar_generated": 8.23001627,
    "wind_generated": 59.45507987,
    "marketplace_to_demand": 31.45685362,
    "grid_to_demand": 34088.72932,
    "govt_battery_charge": 49.97112559
  },
  {
    "timestamp": "27/02/25 8:00",
    "demand": 31738.7491,
    "solar_generated": 21.63963629,
    "wind_generated": 58.01333441,
    "marketplace_to_demand": 70.11906506,
    "grid_to_demand": 34864.46119,
    "govt_battery_charge": 22.42264025
  },
  {
    "timestamp": "27/02/25 9:00",
    "demand": 30956.2949,
    "solar_generated": 18.49340964,
    "wind_generated": 61.6738756,
    "marketplace_to_demand": 71.4641862,
    "grid_to_demand": 34971.3385,
    "govt_battery_charge": 51.77364035
  },
  {
    "timestamp": "27/02/25 10:00",
    "demand": 36258.10629,
    "solar_generated": 20.93245936,
    "wind_generated": 75.96368495,
    "marketplace_to_demand": 35.44606241,
    "grid_to_demand": 36188.10343,
    "govt_battery_charge": 13.6921101
  },
  {
    "timestamp": "27/02/25 11:00",
    "demand": 31601.73922,
    "solar_generated": 22.56047854,
    "wind_generated": 63.20208941,
    "marketplace_to_demand": 11.92859995,
    "grid_to_demand": 30646.18303,
    "govt_battery_charge": 35.20822892
  },
  {
    "timestamp": "27/02/25 12:00",
    "demand": 33480.29383,
    "solar_generated": 17.01197545,
    "wind_generated": 74.80001335,
    "marketplace_to_demand": 144.7519989,
    "grid_to_demand": 34887.76102,
    "govt_battery_charge": 12.78984919
  },
  {
    "timestamp": "27/02/25 13:00",
    "demand": 34700.19852,
    "solar_generated": 11.70250655,
    "wind_generated": 74.15237815,
    "marketplace_to_demand": 56.33219864,
    "grid_to_demand": 33733.34907,
    "govt_battery_charge": 12.3513953
  },
  {
    "timestamp": "27/02/25 14:00",
    "demand": 33251.79339,
    "solar_generated": 15.18497856,
    "wind_generated": 60.59994747,
    "marketplace_to_demand": 93.18159272,
    "grid_to_demand": 35178.09421,
    "govt_battery_charge": 19.72212132
  },
  {
    "timestamp": "27/02/25 15:00",
    "demand": 34251.7622,
    "solar_generated": 19.34272492,
    "wind_generated": 58.94598932,
    "marketplace_to_demand": 85.83083693,
    "grid_to_demand": 34312.04258,
    "govt_battery_charge": 25.84948386
  },
  {
    "timestamp": "27/02/25 16:00",
    "demand": 33305.1611,
    "solar_generated": 18.15914607,
    "wind_generated": 55.68868738,
    "marketplace_to_demand": 32.33698121,
    "grid_to_demand": 36532.43449,
    "govt_battery_charge": 36.76664331
  },
  {
    "timestamp": "27/02/25 17:00",
    "demand": 32466.9331,
    "solar_generated": 15.501802,
    "wind_generated": 71.58186108,
    "marketplace_to_demand": 117.0813664,
    "grid_to_demand": 36351.15316,
    "govt_battery_charge": 60.04947675
  },
  {
    "timestamp": "27/02/25 18:00",
    "demand": 33473.83601,
    "solar_generated": 0,
    "wind_generated": 55.80570411,
    "marketplace_to_demand": 15.40599727,
    "grid_to_demand": 36376.87645,
    "govt_battery_charge": 31.82363952
  },
  {
    "timestamp": "27/02/25 19:00",
    "demand": 34551.21086,
    "solar_generated": 0,
    "wind_generated": 62.25021046,
    "marketplace_to_demand": 66.83207775,
    "grid_to_demand": 31308.21745,
    "govt_battery_charge": 70.32717114
  },
  {
    "timestamp": "27/02/25 20:00",
    "demand": 30447.05714,
    "solar_generated": 0,
    "wind_generated": 58.57706642,
    "marketplace_to_demand": 104.9182153,
    "grid_to_demand": 31545.81331,
    "govt_battery_charge": 2.834182031
  },
  {
    "timestamp": "27/02/25 21:00",
    "demand": 33429.48149,
    "solar_generated": 0,
    "wind_generated": 53.9209227,
    "marketplace_to_demand": 197.960787,
    "grid_to_demand": 32694.84559,
    "govt_battery_charge": 26.96168641
  },
  {
    "timestamp": "27/02/25 22:00",
    "demand": 32714.66065,
    "solar_generated": 0,
    "wind_generated": 73.05618165,
    "marketplace_to_demand": 127.956495,
    "grid_to_demand": 34397.66025,
    "govt_battery_charge": 4.182330438
  },
  {
    "timestamp": "27/02/25 23:00",
    "demand": 35582.99133,
    "solar_generated": 0,
    "wind_generated": 56.84696738,
    "marketplace_to_demand": 50.44176466,
    "grid_to_demand": 33754.99568,
    "govt_battery_charge": 79.04304454
  },
  {
    "timestamp": "28/02/25 0:00",
    "demand": 34626.64098,
    "solar_generated": 0,
    "wind_generated": 72.59788899,
    "marketplace_to_demand": 153.7918509,
    "grid_to_demand": 34641.16021,
    "govt_battery_charge": 47.35059141
  },
  {
    "timestamp": "28/02/25 1:00",
    "demand": 30863.9722,
    "solar_generated": 0,
    "wind_generated": 62.87820267,
    "marketplace_to_demand": 176.2752105,
    "grid_to_demand": 31444.37222,
    "govt_battery_charge": 60.40555416
  },
  {
    "timestamp": "28/02/25 2:00",
    "demand": 36229.96341,
    "solar_generated": 0,
    "wind_generated": 64.22086716,
    "marketplace_to_demand": 132.3152421,
    "grid_to_demand": 30955.16088,
    "govt_battery_charge": 83.06842639
  },
  {
    "timestamp": "28/02/25 3:00",
    "demand": 30595.96794,
    "solar_generated": 0,
    "wind_generated": 55.45584688,
    "marketplace_to_demand": 20.94141513,
    "grid_to_demand": 35007.9316,
    "govt_battery_charge": 45.50782917
  },
  {
    "timestamp": "28/02/25 4:00",
    "demand": 35992.58034,
    "solar_generated": 0,
    "wind_generated": 61.4120895,
    "marketplace_to_demand": 39.33232328,
    "grid_to_demand": 30300.90089,
    "govt_battery_charge": 59.30519632
  },
  {
    "timestamp": "28/02/25 5:00",
    "demand": 33661.738,
    "solar_generated": 0,
    "wind_generated": 79.19940971,
    "marketplace_to_demand": 177.4806935,
    "grid_to_demand": 30116.84549,
    "govt_battery_charge": 5.000100822
  },
  {
    "timestamp": "28/02/25 6:00",
    "demand": 30143.3236,
    "solar_generated": 16.8484236,
    "wind_generated": 72.47872871,
    "marketplace_to_demand": 142.5374126,
    "grid_to_demand": 34447.53666,
    "govt_battery_charge": 84.50176419
  },
  {
    "timestamp": "28/02/25 7:00",
    "demand": 33037.13792,
    "solar_generated": 20.91859121,
    "wind_generated": 64.09020029,
    "marketplace_to_demand": 85.73814003,
    "grid_to_demand": 32487.50177,
    "govt_battery_charge": 24.4328461
  },
  {
    "timestamp": "28/02/25 8:00",
    "demand": 36389.76883,
    "solar_generated": 14.67388505,
    "wind_generated": 76.18264623,
    "marketplace_to_demand": 138.7659087,
    "grid_to_demand": 31000.7552,
    "govt_battery_charge": 2.139972351
  },
  {
    "timestamp": "28/02/25 9:00",
    "demand": 34568.34523,
    "solar_generated": 10.10292571,
    "wind_generated": 72.09264876,
    "marketplace_to_demand": 197.7318128,
    "grid_to_demand": 35197.28554,
    "govt_battery_charge": 14.93415771
  },
  {
    "timestamp": "28/02/25 10:00",
    "demand": 31293.90844,
    "solar_generated": 21.91690842,
    "wind_generated": 70.31132958,
    "marketplace_to_demand": 77.10554516,
    "grid_to_demand": 34150.49818,
    "govt_battery_charge": 34.4569366
  },
  {
    "timestamp": "28/02/25 11:00",
    "demand": 34132.48281,
    "solar_generated": 10.82102857,
    "wind_generated": 54.21938793,
    "marketplace_to_demand": 124.737469,
    "grid_to_demand": 31765.49315,
    "govt_battery_charge": 91.1127027
  },
  {
    "timestamp": "28/02/25 12:00",
    "demand": 31664.52166,
    "solar_generated": 15.71278388,
    "wind_generated": 77.8850507,
    "marketplace_to_demand": 11.77681533,
    "grid_to_demand": 30594.17969,
    "govt_battery_charge": 72.92523497
  },
  {
    "timestamp": "28/02/25 13:00",
    "demand": 35681.76049,
    "solar_generated": 6.560320667,
    "wind_generated": 57.60505128,
    "marketplace_to_demand": 183.3180437,
    "grid_to_demand": 34313.04166,
    "govt_battery_charge": 72.9394051
  },
  {
    "timestamp": "28/02/25 14:00",
    "demand": 32817.21395,
    "solar_generated": 21.951195,
    "wind_generated": 69.49155745,
    "marketplace_to_demand": 134.7855192,
    "grid_to_demand": 32337.87237,
    "govt_battery_charge": 48.78796001
  },
  {
    "timestamp": "28/02/25 15:00",
    "demand": 32644.45372,
    "solar_generated": 22.78430705,
    "wind_generated": 78.34640166,
    "marketplace_to_demand": 15.66664284,
    "grid_to_demand": 32800.3777,
    "govt_battery_charge": 70.4093147
  },
  {
    "timestamp": "28/02/25 16:00",
    "demand": 30410.94868,
    "solar_generated": 8.365740687,
    "wind_generated": 67.49527012,
    "marketplace_to_demand": 46.80799903,
    "grid_to_demand": 32367.89317,
    "govt_battery_charge": 57.85399571
  },
  {
    "timestamp": "28/02/25 17:00",
    "demand": 32284.91429,
    "solar_generated": 11.88281983,
    "wind_generated": 79.33562528,
    "marketplace_to_demand": 1.669857906,
    "grid_to_demand": 30928.61995,
    "govt_battery_charge": 5.07906488
  },
  {
    "timestamp": "28/02/25 18:00",
    "demand": 34466.11206,
    "solar_generated": 0,
    "wind_generated": 60.57261341,
    "marketplace_to_demand": 110.950667,
    "grid_to_demand": 31003.30485,
    "govt_battery_charge": 70.03099275
  },
  {
    "timestamp": "28/02/25 19:00",
    "demand": 35958.97051,
    "solar_generated": 0,
    "wind_generated": 80.39237598,
    "marketplace_to_demand": 149.7769552,
    "grid_to_demand": 35981.5923,
    "govt_battery_charge": 81.56021951
  },
  {
    "timestamp": "28/02/25 20:00",
    "demand": 35941.99537,
    "solar_generated": 0,
    "wind_generated": 70.92857307,
    "marketplace_to_demand": 101.8268544,
    "grid_to_demand": 31004.27167,
    "govt_battery_charge": 5.606796365
  },
  {
    "timestamp": "28/02/25 21:00",
    "demand": 30529.56739,
    "solar_generated": 0,
    "wind_generated": 67.0733056,
    "marketplace_to_demand": 111.7547814,
    "grid_to_demand": 30676.80323,
    "govt_battery_charge": 72.08519363
  },
  {
    "timestamp": "28/02/25 22:00",
    "demand": 33882.65326,
    "solar_generated": 0,
    "wind_generated": 59.26514785,
    "marketplace_to_demand": 13.26182951,
    "grid_to_demand": 32722.3936,
    "govt_battery_charge": 18.16319641
  },
  {
    "timestamp": "28/02/25 23:00",
    "demand": 36576.73985,
    "solar_generated": 0,
    "wind_generated": 65.283451,
    "marketplace_to_demand": 175.2024909,
    "grid_to_demand": 33523.19939,
    "govt_battery_charge": 89.11062066
  },
  {
    "timestamp": "01/03/25 0:00",
    "demand": 32859.424,
    "solar_generated": 0,
    "wind_generated": 64.10517172,
    "marketplace_to_demand": 144.2942968,
    "grid_to_demand": 35873.43081,
    "govt_battery_charge": 38.91009295
  },
  {
    "timestamp": "01/03/25 1:00",
    "demand": 31025.30652,
    "solar_generated": 0,
    "wind_generated": 62.06023649,
    "marketplace_to_demand": 27.56639208,
    "grid_to_demand": 32954.83604,
    "govt_battery_charge": 88.70591582
  },
  {
    "timestamp": "01/03/25 2:00",
    "demand": 33769.26607,
    "solar_generated": 0,
    "wind_generated": 68.00804713,
    "marketplace_to_demand": 60.33078002,
    "grid_to_demand": 35538.66721,
    "govt_battery_charge": 41.24160574
  },
  {
    "timestamp": "01/03/25 3:00",
    "demand": 34447.70313,
    "solar_generated": 0,
    "wind_generated": 78.35892015,
    "marketplace_to_demand": 199.3226222,
    "grid_to_demand": 32323.80428,
    "govt_battery_charge": 51.17610506
  },
  {
    "timestamp": "01/03/25 4:00",
    "demand": 35763.37447,
    "solar_generated": 0,
    "wind_generated": 55.72766668,
    "marketplace_to_demand": 199.3101484,
    "grid_to_demand": 33938.67999,
    "govt_battery_charge": 85.26578018
  },
  {
    "timestamp": "01/03/25 5:00",
    "demand": 35358.27389,
    "solar_generated": 0,
    "wind_generated": 77.44966304,
    "marketplace_to_demand": 38.38564945,
    "grid_to_demand": 30896.77164,
    "govt_battery_charge": 53.50246474
  },
  {
    "timestamp": "01/03/25 6:00",
    "demand": 33606.05187,
    "solar_generated": 8.428269523,
    "wind_generated": 58.78808969,
    "marketplace_to_demand": 6.305219424,
    "grid_to_demand": 35350.36539,
    "govt_battery_charge": 26.99626571
  },
  {
    "timestamp": "01/03/25 7:00",
    "demand": 31759.63976,
    "solar_generated": 8.930759166,
    "wind_generated": 60.97410961,
    "marketplace_to_demand": 131.8131039,
    "grid_to_demand": 35673.75363,
    "govt_battery_charge": 71.04781837
  },
  {
    "timestamp": "01/03/25 8:00",
    "demand": 30013.16016,
    "solar_generated": 23.10613797,
    "wind_generated": 76.37200035,
    "marketplace_to_demand": 177.4114988,
    "grid_to_demand": 34481.76796,
    "govt_battery_charge": 84.44789771
  },
  {
    "timestamp": "01/03/25 9:00",
    "demand": 31976.93819,
    "solar_generated": 13.19295263,
    "wind_generated": 56.24837468,
    "marketplace_to_demand": 147.3630055,
    "grid_to_demand": 36332.49397,
    "govt_battery_charge": 69.01780101
  },
  {
    "timestamp": "01/03/25 10:00",
    "demand": 31343.74594,
    "solar_generated": 14.44212831,
    "wind_generated": 71.33125103,
    "marketplace_to_demand": 188.2731438,
    "grid_to_demand": 36284.12598,
    "govt_battery_charge": 69.56091426
  },
  {
    "timestamp": "01/03/25 11:00",
    "demand": 36258.92338,
    "solar_generated": 12.04211147,
    "wind_generated": 70.30442547,
    "marketplace_to_demand": 183.5889641,
    "grid_to_demand": 36445.24848,
    "govt_battery_charge": 72.95031264
  },
  {
    "timestamp": "01/03/25 12:00",
    "demand": 30716.84403,
    "solar_generated": 17.09710116,
    "wind_generated": 70.80874345,
    "marketplace_to_demand": 66.17237392,
    "grid_to_demand": 32692.51915,
    "govt_battery_charge": 17.48486357
  },
  {
    "timestamp": "01/03/25 13:00",
    "demand": 31874.42648,
    "solar_generated": 11.70557321,
    "wind_generated": 75.31891351,
    "marketplace_to_demand": 35.65961463,
    "grid_to_demand": 34266.0351,
    "govt_battery_charge": 21.02535908
  },
  {
    "timestamp": "01/03/25 14:00",
    "demand": 34428.85871,
    "solar_generated": 12.96129579,
    "wind_generated": 65.64058605,
    "marketplace_to_demand": 25.40177464,
    "grid_to_demand": 33437.85695,
    "govt_battery_charge": 46.71008827
  },
  {
    "timestamp": "01/03/25 15:00",
    "demand": 31889.94774,
    "solar_generated": 15.85469607,
    "wind_generated": 73.96296985,
    "marketplace_to_demand": 124.1777234,
    "grid_to_demand": 35412.20001,
    "govt_battery_charge": 66.84818374
  },
  {
    "timestamp": "01/03/25 16:00",
    "demand": 33660.84755,
    "solar_generated": 14.06454336,
    "wind_generated": 53.73641746,
    "marketplace_to_demand": 62.59615626,
    "grid_to_demand": 34582.01807,
    "govt_battery_charge": 22.30932213
  },
  {
    "timestamp": "01/03/25 17:00",
    "demand": 35703.61312,
    "solar_generated": 6.581287489,
    "wind_generated": 56.51658265,
    "marketplace_to_demand": 144.0092397,
    "grid_to_demand": 30873.93706,
    "govt_battery_charge": 6.005350156
  },
  {
    "timestamp": "01/03/25 18:00",
    "demand": 33447.8268,
    "solar_generated": 0,
    "wind_generated": 57.44565501,
    "marketplace_to_demand": 0.304025684,
    "grid_to_demand": 34971.66615,
    "govt_battery_charge": 71.43527074
  },
  {
    "timestamp": "01/03/25 19:00",
    "demand": 33531.06417,
    "solar_generated": 0,
    "wind_generated": 78.52282267,
    "marketplace_to_demand": 52.33048586,
    "grid_to_demand": 30067.76997,
    "govt_battery_charge": 73.51563141
  },
  {
    "timestamp": "01/03/25 20:00",
    "demand": 32702.72082,
    "solar_generated": 0,
    "wind_generated": 55.6695965,
    "marketplace_to_demand": 48.70420689,
    "grid_to_demand": 35193.96622,
    "govt_battery_charge": 78.87101398
  },
  {
    "timestamp": "01/03/25 21:00",
    "demand": 34079.49116,
    "solar_generated": 0,
    "wind_generated": 57.35713407,
    "marketplace_to_demand": 107.6103764,
    "grid_to_demand": 35181.9123,
    "govt_battery_charge": 93.65141783
  },
  {
    "timestamp": "01/03/25 22:00",
    "demand": 32351.21714,
    "solar_generated": 0,
    "wind_generated": 66.58122191,
    "marketplace_to_demand": 62.26950531,
    "grid_to_demand": 33823.63846,
    "govt_battery_charge": 25.33067339
  },
  {
    "timestamp": "01/03/25 23:00",
    "demand": 33717.86712,
    "solar_generated": 0,
    "wind_generated": 58.78962655,
    "marketplace_to_demand": 126.2239854,
    "grid_to_demand": 34046.632,
    "govt_battery_charge": 90.80522287
  },
  {
    "timestamp": "02/03/25 0:00",
    "demand": 34668.17154,
    "solar_generated": 0,
    "wind_generated": 73.08741152,
    "marketplace_to_demand": 68.83095477,
    "grid_to_demand": 36064.07515,
    "govt_battery_charge": 62.1875107
  },
  {
    "timestamp": "02/03/25 1:00",
    "demand": 34793.10518,
    "solar_generated": 0,
    "wind_generated": 69.43833136,
    "marketplace_to_demand": 0.867971454,
    "grid_to_demand": 35156.45191,
    "govt_battery_charge": 71.98667766
  },
  {
    "timestamp": "02/03/25 2:00",
    "demand": 33666.48661,
    "solar_generated": 0,
    "wind_generated": 54.90693691,
    "marketplace_to_demand": 149.7911791,
    "grid_to_demand": 32290.18305,
    "govt_battery_charge": 53.65351189
  },
  {
    "timestamp": "02/03/25 3:00",
    "demand": 31748.59289,
    "solar_generated": 0,
    "wind_generated": 77.48911292,
    "marketplace_to_demand": 69.84000083,
    "grid_to_demand": 34932.38679,
    "govt_battery_charge": 17.75771587
  },
  {
    "timestamp": "02/03/25 4:00",
    "demand": 32244.74769,
    "solar_generated": 0,
    "wind_generated": 57.51026181,
    "marketplace_to_demand": 111.274027,
    "grid_to_demand": 31174.67689,
    "govt_battery_charge": 24.82224202
  },
  {
    "timestamp": "02/03/25 5:00",
    "demand": 31777.43547,
    "solar_generated": 0,
    "wind_generated": 65.5590729,
    "marketplace_to_demand": 147.7094411,
    "grid_to_demand": 31418.69397,
    "govt_battery_charge": 34.73951056
  },
  {
    "timestamp": "02/03/25 6:00",
    "demand": 31103.77019,
    "solar_generated": 9.263401978,
    "wind_generated": 56.33426521,
    "marketplace_to_demand": 36.61060412,
    "grid_to_demand": 36019.4757,
    "govt_battery_charge": 11.33351503
  },
  {
    "timestamp": "02/03/25 7:00",
    "demand": 31091.41169,
    "solar_generated": 18.91749377,
    "wind_generated": 68.56297238,
    "marketplace_to_demand": 166.4583997,
    "grid_to_demand": 30536.72795,
    "govt_battery_charge": 68.87815347
  },
  {
    "timestamp": "02/03/25 8:00",
    "demand": 31891.45058,
    "solar_generated": 17.71592219,
    "wind_generated": 77.2881409,
    "marketplace_to_demand": 134.7575444,
    "grid_to_demand": 32771.12154,
    "govt_battery_charge": 85.10478094
  },
  {
    "timestamp": "02/03/25 9:00",
    "demand": 32403.34585,
    "solar_generated": 10.18387171,
    "wind_generated": 57.66352829,
    "marketplace_to_demand": 100.8421288,
    "grid_to_demand": 34863.73165,
    "govt_battery_charge": 4.594010365
  },
  {
    "timestamp": "02/03/25 10:00",
    "demand": 36427.28138,
    "solar_generated": 20.44559961,
    "wind_generated": 72.41439618,
    "marketplace_to_demand": 184.1945678,
    "grid_to_demand": 32203.37202,
    "govt_battery_charge": 41.01420198
  },
  {
    "timestamp": "02/03/25 11:00",
    "demand": 31068.38804,
    "solar_generated": 12.88744973,
    "wind_generated": 56.95925664,
    "marketplace_to_demand": 174.0269868,
    "grid_to_demand": 33803.17035,
    "govt_battery_charge": 76.98619501
  },
  {
    "timestamp": "02/03/25 12:00",
    "demand": 35155.208,
    "solar_generated": 15.8340826,
    "wind_generated": 55.51104527,
    "marketplace_to_demand": 194.9970076,
    "grid_to_demand": 34325.43639,
    "govt_battery_charge": 50.11551697
  },
  {
    "timestamp": "02/03/25 13:00",
    "demand": 33956.25465,
    "solar_generated": 13.70503125,
    "wind_generated": 66.00659197,
    "marketplace_to_demand": 59.55320395,
    "grid_to_demand": 36253.88566,
    "govt_battery_charge": 96.29144245
  },
  {
    "timestamp": "02/03/25 14:00",
    "demand": 32209.01249,
    "solar_generated": 21.82193234,
    "wind_generated": 68.45434102,
    "marketplace_to_demand": 110.1746736,
    "grid_to_demand": 30884.53567,
    "govt_battery_charge": 43.61796314
  },
  {
    "timestamp": "02/03/25 15:00",
    "demand": 35592.62547,
    "solar_generated": 13.35646323,
    "wind_generated": 75.12368937,
    "marketplace_to_demand": 46.01991003,
    "grid_to_demand": 31143.29094,
    "govt_battery_charge": 58.17213338
  },
  {
    "timestamp": "02/03/25 16:00",
    "demand": 32179.63021,
    "solar_generated": 20.09635838,
    "wind_generated": 55.19756048,
    "marketplace_to_demand": 52.4159694,
    "grid_to_demand": 32362.6481,
    "govt_battery_charge": 90.55092062
  },
  {
    "timestamp": "02/03/25 17:00",
    "demand": 34914.14449,
    "solar_generated": 21.10143885,
    "wind_generated": 74.60170362,
    "marketplace_to_demand": 64.80843384,
    "grid_to_demand": 35494.76586,
    "govt_battery_charge": 32.35637742
  },
  {
    "timestamp": "02/03/25 18:00",
    "demand": 33353.22188,
    "solar_generated": 0,
    "wind_generated": 79.89336192,
    "marketplace_to_demand": 106.3383714,
    "grid_to_demand": 34685.91151,
    "govt_battery_charge": 84.10167571
  },
  {
    "timestamp": "02/03/25 19:00",
    "demand": 36602.21382,
    "solar_generated": 0,
    "wind_generated": 78.5050821,
    "marketplace_to_demand": 31.24380592,
    "grid_to_demand": 31021.37548,
    "govt_battery_charge": 91.79537161
  },
  {
    "timestamp": "02/03/25 20:00",
    "demand": 34748.04911,
    "solar_generated": 0,
    "wind_generated": 74.46259214,
    "marketplace_to_demand": 93.29186131,
    "grid_to_demand": 34167.98443,
    "govt_battery_charge": 95.44456711
  },
  {
    "timestamp": "02/03/25 21:00",
    "demand": 32160.22957,
    "solar_generated": 0,
    "wind_generated": 78.15490241,
    "marketplace_to_demand": 166.5601872,
    "grid_to_demand": 31446.82972,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "02/03/25 22:00",
    "demand": 30317.40598,
    "solar_generated": 0,
    "wind_generated": 69.04855816,
    "marketplace_to_demand": 119.06094,
    "grid_to_demand": 31508.05956,
    "govt_battery_charge": 77.71672717
  },
  {
    "timestamp": "02/03/25 23:00",
    "demand": 31970.77078,
    "solar_generated": 0,
    "wind_generated": 60.16704904,
    "marketplace_to_demand": 15.96545259,
    "grid_to_demand": 32714.31526,
    "govt_battery_charge": 49.92469944
  },
  {
    "timestamp": "03/03/25 0:00",
    "demand": 31165.10633,
    "solar_generated": 0,
    "wind_generated": 56.95289294,
    "marketplace_to_demand": 107.1046837,
    "grid_to_demand": 36545.51257,
    "govt_battery_charge": 19.66444199
  },
  {
    "timestamp": "03/03/25 1:00",
    "demand": 31354.471,
    "solar_generated": 0,
    "wind_generated": 54.50154248,
    "marketplace_to_demand": 34.25526661,
    "grid_to_demand": 34210.17098,
    "govt_battery_charge": 8.913988934
  },
  {
    "timestamp": "03/03/25 2:00",
    "demand": 30318.28295,
    "solar_generated": 0,
    "wind_generated": 68.93435664,
    "marketplace_to_demand": 104.2403456,
    "grid_to_demand": 33965.6052,
    "govt_battery_charge": 25.37485117
  },
  {
    "timestamp": "03/03/25 3:00",
    "demand": 32473.6702,
    "solar_generated": 0,
    "wind_generated": 73.13406176,
    "marketplace_to_demand": 49.003571,
    "grid_to_demand": 30031.8386,
    "govt_battery_charge": 38.29932696
  },
  {
    "timestamp": "03/03/25 4:00",
    "demand": 33463.57298,
    "solar_generated": 0,
    "wind_generated": 67.84211607,
    "marketplace_to_demand": 145.2020504,
    "grid_to_demand": 35305.33312,
    "govt_battery_charge": 96.39105008
  },
  {
    "timestamp": "03/03/25 5:00",
    "demand": 33080.7505,
    "solar_generated": 0,
    "wind_generated": 71.59851145,
    "marketplace_to_demand": 130.8178283,
    "grid_to_demand": 30118.95313,
    "govt_battery_charge": 54.96316872
  },
  {
    "timestamp": "03/03/25 6:00",
    "demand": 35295.66315,
    "solar_generated": 8.766464346,
    "wind_generated": 65.61948617,
    "marketplace_to_demand": 189.8325706,
    "grid_to_demand": 32275.81174,
    "govt_battery_charge": 57.20794432
  },
  {
    "timestamp": "03/03/25 7:00",
    "demand": 35143.6129,
    "solar_generated": 22.28006075,
    "wind_generated": 76.28996617,
    "marketplace_to_demand": 176.2008674,
    "grid_to_demand": 31412.45477,
    "govt_battery_charge": 68.98894993
  },
  {
    "timestamp": "03/03/25 8:00",
    "demand": 34149.24677,
    "solar_generated": 20.22031477,
    "wind_generated": 59.22386915,
    "marketplace_to_demand": 178.2720046,
    "grid_to_demand": 33939.6596,
    "govt_battery_charge": 88.40575127
  },
  {
    "timestamp": "03/03/25 9:00",
    "demand": 30705.51183,
    "solar_generated": 14.63668682,
    "wind_generated": 54.46826897,
    "marketplace_to_demand": 15.41071785,
    "grid_to_demand": 31360.21289,
    "govt_battery_charge": 49.7344042
  },
  {
    "timestamp": "03/03/25 10:00",
    "demand": 36334.99377,
    "solar_generated": 18.04911437,
    "wind_generated": 63.47968042,
    "marketplace_to_demand": 88.31376407,
    "grid_to_demand": 33740.32385,
    "govt_battery_charge": 15.67695955
  },
  {
    "timestamp": "03/03/25 11:00",
    "demand": 35544.27143,
    "solar_generated": 13.70647389,
    "wind_generated": 62.88672236,
    "marketplace_to_demand": 53.93072089,
    "grid_to_demand": 34873.98627,
    "govt_battery_charge": 76.95202739
  },
  {
    "timestamp": "03/03/25 12:00",
    "demand": 31478.73842,
    "solar_generated": 15.67474905,
    "wind_generated": 66.56577214,
    "marketplace_to_demand": 152.5841223,
    "grid_to_demand": 35398.5695,
    "govt_battery_charge": 48.79089793
  },
  {
    "timestamp": "03/03/25 13:00",
    "demand": 36515.73673,
    "solar_generated": 20.26660227,
    "wind_generated": 56.57246426,
    "marketplace_to_demand": 164.054959,
    "grid_to_demand": 36535.43433,
    "govt_battery_charge": 27.53828801
  },
  {
    "timestamp": "03/03/25 14:00",
    "demand": 35381.82135,
    "solar_generated": 7.460594232,
    "wind_generated": 79.11576617,
    "marketplace_to_demand": 25.32023677,
    "grid_to_demand": 34165.58343,
    "govt_battery_charge": 77.88596292
  },
  {
    "timestamp": "03/03/25 15:00",
    "demand": 31381.42741,
    "solar_generated": 12.1111907,
    "wind_generated": 64.49343598,
    "marketplace_to_demand": 152.3645759,
    "grid_to_demand": 35020.87501,
    "govt_battery_charge": 69.36711431
  },
  {
    "timestamp": "03/03/25 16:00",
    "demand": 34280.93866,
    "solar_generated": 22.25197363,
    "wind_generated": 66.35627487,
    "marketplace_to_demand": 70.97963519,
    "grid_to_demand": 32641.32641,
    "govt_battery_charge": 91.65221059
  },
  {
    "timestamp": "03/03/25 17:00",
    "demand": 34873.75545,
    "solar_generated": 16.72727102,
    "wind_generated": 62.0252655,
    "marketplace_to_demand": 190.1275732,
    "grid_to_demand": 31136.8337,
    "govt_battery_charge": 95.24559266
  },
  {
    "timestamp": "03/03/25 18:00",
    "demand": 32374.5339,
    "solar_generated": 0,
    "wind_generated": 60.95155827,
    "marketplace_to_demand": 100.7695925,
    "grid_to_demand": 32810.42575,
    "govt_battery_charge": 69.36050041
  },
  {
    "timestamp": "03/03/25 19:00",
    "demand": 32185.21257,
    "solar_generated": 0,
    "wind_generated": 54.24072515,
    "marketplace_to_demand": 93.45038415,
    "grid_to_demand": 33302.38098,
    "govt_battery_charge": 9.407652127
  },
  {
    "timestamp": "03/03/25 20:00",
    "demand": 34861.60185,
    "solar_generated": 0,
    "wind_generated": 76.50602203,
    "marketplace_to_demand": 48.17430182,
    "grid_to_demand": 31035.67961,
    "govt_battery_charge": 69.86795542
  },
  {
    "timestamp": "03/03/25 21:00",
    "demand": 35625.93852,
    "solar_generated": 0,
    "wind_generated": 62.03122047,
    "marketplace_to_demand": 190.5674599,
    "grid_to_demand": 33271.03676,
    "govt_battery_charge": 35.58786664
  },
  {
    "timestamp": "03/03/25 22:00",
    "demand": 30364.59861,
    "solar_generated": 0,
    "wind_generated": 65.85045388,
    "marketplace_to_demand": 109.1913711,
    "grid_to_demand": 32278.03991,
    "govt_battery_charge": 52.06515547
  },
  {
    "timestamp": "03/03/25 23:00",
    "demand": 36499.13654,
    "solar_generated": 0,
    "wind_generated": 68.63853081,
    "marketplace_to_demand": 88.86915367,
    "grid_to_demand": 35928.72805,
    "govt_battery_charge": 58.01823758
  },
  {
    "timestamp": "04/03/25 0:00",
    "demand": 33268.84017,
    "solar_generated": 0,
    "wind_generated": 64.46238661,
    "marketplace_to_demand": 85.21415102,
    "grid_to_demand": 30642.40573,
    "govt_battery_charge": 63.62711798
  },
  {
    "timestamp": "04/03/25 1:00",
    "demand": 30664.58984,
    "solar_generated": 0,
    "wind_generated": 62.97699339,
    "marketplace_to_demand": 20.35460723,
    "grid_to_demand": 32130.1487,
    "govt_battery_charge": 46.33351555
  },
  {
    "timestamp": "04/03/25 2:00",
    "demand": 34010.94633,
    "solar_generated": 0,
    "wind_generated": 70.84959063,
    "marketplace_to_demand": 32.32655397,
    "grid_to_demand": 35888.58729,
    "govt_battery_charge": 56.46551202
  },
  {
    "timestamp": "04/03/25 3:00",
    "demand": 30441.9793,
    "solar_generated": 0,
    "wind_generated": 76.08360616,
    "marketplace_to_demand": 179.9561148,
    "grid_to_demand": 35306.23571,
    "govt_battery_charge": 55.25502375
  },
  {
    "timestamp": "04/03/25 4:00",
    "demand": 31335.96461,
    "solar_generated": 0,
    "wind_generated": 59.70793669,
    "marketplace_to_demand": 57.64749727,
    "grid_to_demand": 30000.6378,
    "govt_battery_charge": 47.42001691
  },
  {
    "timestamp": "04/03/25 5:00",
    "demand": 32649.82648,
    "solar_generated": 0,
    "wind_generated": 65.58794694,
    "marketplace_to_demand": 169.3548868,
    "grid_to_demand": 35633.36945,
    "govt_battery_charge": 19.66241734
  },
  {
    "timestamp": "04/03/25 6:00",
    "demand": 35424.57197,
    "solar_generated": 8.999152253,
    "wind_generated": 79.14655654,
    "marketplace_to_demand": 101.8917915,
    "grid_to_demand": 34267.00364,
    "govt_battery_charge": 75.81168171
  },
  {
    "timestamp": "04/03/25 7:00",
    "demand": 33227.34992,
    "solar_generated": 16.13167994,
    "wind_generated": 58.54753542,
    "marketplace_to_demand": 109.6910983,
    "grid_to_demand": 32362.88128,
    "govt_battery_charge": 61.77873934
  },
  {
    "timestamp": "04/03/25 8:00",
    "demand": 30943.22927,
    "solar_generated": 18.91536935,
    "wind_generated": 55.13884242,
    "marketplace_to_demand": 154.2077249,
    "grid_to_demand": 36530.72373,
    "govt_battery_charge": 50.2301667
  },
  {
    "timestamp": "04/03/25 9:00",
    "demand": 33173.26408,
    "solar_generated": 18.62982155,
    "wind_generated": 72.8853942,
    "marketplace_to_demand": 85.13260349,
    "grid_to_demand": 33334.3578,
    "govt_battery_charge": 43.60162123
  },
  {
    "timestamp": "04/03/25 10:00",
    "demand": 31366.49989,
    "solar_generated": 13.48188785,
    "wind_generated": 71.32425905,
    "marketplace_to_demand": 109.2101326,
    "grid_to_demand": 34985.40694,
    "govt_battery_charge": 74.3398383
  },
  {
    "timestamp": "04/03/25 11:00",
    "demand": 34686.53853,
    "solar_generated": 11.81607076,
    "wind_generated": 70.85339118,
    "marketplace_to_demand": 88.27351118,
    "grid_to_demand": 36410.08695,
    "govt_battery_charge": 54.60402429
  },
  {
    "timestamp": "04/03/25 12:00",
    "demand": 31455.86448,
    "solar_generated": 13.30106515,
    "wind_generated": 53.83132584,
    "marketplace_to_demand": 158.3044349,
    "grid_to_demand": 36112.48423,
    "govt_battery_charge": 68.17745997
  },
  {
    "timestamp": "04/03/25 13:00",
    "demand": 30342.50632,
    "solar_generated": 18.42548659,
    "wind_generated": 60.77184678,
    "marketplace_to_demand": 26.62017859,
    "grid_to_demand": 33777.57405,
    "govt_battery_charge": 59.82685505
  },
  {
    "timestamp": "04/03/25 14:00",
    "demand": 32041.2165,
    "solar_generated": 17.44159112,
    "wind_generated": 59.2328038,
    "marketplace_to_demand": 166.9435795,
    "grid_to_demand": 33984.31845,
    "govt_battery_charge": 47.67932617
  },
  {
    "timestamp": "04/03/25 15:00",
    "demand": 33748.03889,
    "solar_generated": 6.918057846,
    "wind_generated": 61.90188844,
    "marketplace_to_demand": 104.9796328,
    "grid_to_demand": 30889.19533,
    "govt_battery_charge": 61.0413231
  },
  {
    "timestamp": "04/03/25 16:00",
    "demand": 33244.44844,
    "solar_generated": 14.20665752,
    "wind_generated": 71.0734621,
    "marketplace_to_demand": 26.02687926,
    "grid_to_demand": 31740.4072,
    "govt_battery_charge": 70.29448885
  },
  {
    "timestamp": "04/03/25 17:00",
    "demand": 32949.1063,
    "solar_generated": 12.53635125,
    "wind_generated": 77.92430056,
    "marketplace_to_demand": 30.86842085,
    "grid_to_demand": 30440.90729,
    "govt_battery_charge": 77.59232662
  },
  {
    "timestamp": "04/03/25 18:00",
    "demand": 34705.84684,
    "solar_generated": 0,
    "wind_generated": 79.3267244,
    "marketplace_to_demand": 58.55278107,
    "grid_to_demand": 33592.88985,
    "govt_battery_charge": 91.79213527
  },
  {
    "timestamp": "04/03/25 19:00",
    "demand": 32498.62586,
    "solar_generated": 0,
    "wind_generated": 63.48693378,
    "marketplace_to_demand": 5.306116109,
    "grid_to_demand": 34778.49639,
    "govt_battery_charge": 70.97907545
  },
  {
    "timestamp": "04/03/25 20:00",
    "demand": 32406.06837,
    "solar_generated": 0,
    "wind_generated": 58.8037309,
    "marketplace_to_demand": 143.3877316,
    "grid_to_demand": 33308.9179,
    "govt_battery_charge": 32.80109371
  },
  {
    "timestamp": "04/03/25 21:00",
    "demand": 31360.11987,
    "solar_generated": 0,
    "wind_generated": 73.81885636,
    "marketplace_to_demand": 138.8323075,
    "grid_to_demand": 34900.50077,
    "govt_battery_charge": 31.04494634
  },
  {
    "timestamp": "04/03/25 22:00",
    "demand": 36550.76609,
    "solar_generated": 0,
    "wind_generated": 55.2450569,
    "marketplace_to_demand": 59.95949192,
    "grid_to_demand": 34749.49249,
    "govt_battery_charge": 32.73961756
  },
  {
    "timestamp": "04/03/25 23:00",
    "demand": 33305.73081,
    "solar_generated": 0,
    "wind_generated": 66.63472539,
    "marketplace_to_demand": 193.1714154,
    "grid_to_demand": 31800.47438,
    "govt_battery_charge": 84.37212014
  },
  {
    "timestamp": "05/03/25 0:00",
    "demand": 34013.26972,
    "solar_generated": 0,
    "wind_generated": 73.8000112,
    "marketplace_to_demand": 45.02132264,
    "grid_to_demand": 32351.15935,
    "govt_battery_charge": 66.64514226
  },
  {
    "timestamp": "05/03/25 1:00",
    "demand": 33037.25933,
    "solar_generated": 0,
    "wind_generated": 60.08687975,
    "marketplace_to_demand": 13.32970946,
    "grid_to_demand": 34618.00707,
    "govt_battery_charge": 37.45532543
  },
  {
    "timestamp": "05/03/25 2:00",
    "demand": 36352.00158,
    "solar_generated": 0,
    "wind_generated": 75.22024865,
    "marketplace_to_demand": 176.481535,
    "grid_to_demand": 35830.02458,
    "govt_battery_charge": 27.77221326
  },
  {
    "timestamp": "05/03/25 3:00",
    "demand": 31636.51795,
    "solar_generated": 0,
    "wind_generated": 54.21521702,
    "marketplace_to_demand": 128.6850615,
    "grid_to_demand": 33244.02717,
    "govt_battery_charge": 74.32383265
  },
  {
    "timestamp": "05/03/25 4:00",
    "demand": 31601.91957,
    "solar_generated": 0,
    "wind_generated": 56.26821927,
    "marketplace_to_demand": 159.9099512,
    "grid_to_demand": 32516.00724,
    "govt_battery_charge": 86.74496688
  },
  {
    "timestamp": "05/03/25 5:00",
    "demand": 30752.46455,
    "solar_generated": 0,
    "wind_generated": 53.63024699,
    "marketplace_to_demand": 70.31536257,
    "grid_to_demand": 29926.83229,
    "govt_battery_charge": 48.64496608
  },
  {
    "timestamp": "05/03/25 6:00",
    "demand": 36040.7381,
    "solar_generated": 16.3767007,
    "wind_generated": 57.12650402,
    "marketplace_to_demand": 82.63419375,
    "grid_to_demand": 30309.18165,
    "govt_battery_charge": 87.52479143
  },
  {
    "timestamp": "05/03/25 7:00",
    "demand": 31869.73066,
    "solar_generated": 18.35884955,
    "wind_generated": 56.84164618,
    "marketplace_to_demand": 75.17136364,
    "grid_to_demand": 34437.21212,
    "govt_battery_charge": 65.89497553
  },
  {
    "timestamp": "05/03/25 8:00",
    "demand": 33294.41797,
    "solar_generated": 15.86339561,
    "wind_generated": 74.42196326,
    "marketplace_to_demand": 108.3440732,
    "grid_to_demand": 35963.3653,
    "govt_battery_charge": 6.83184896
  },
  {
    "timestamp": "05/03/25 9:00",
    "demand": 30624.58411,
    "solar_generated": 23.56521397,
    "wind_generated": 76.52579714,
    "marketplace_to_demand": 22.82268663,
    "grid_to_demand": 34860.07223,
    "govt_battery_charge": 23.0045114
  },
  {
    "timestamp": "05/03/25 10:00",
    "demand": 30448.74602,
    "solar_generated": 7.391213219,
    "wind_generated": 58.20517715,
    "marketplace_to_demand": 23.75810283,
    "grid_to_demand": 35809.99855,
    "govt_battery_charge": 75.09980904
  },
  {
    "timestamp": "05/03/25 11:00",
    "demand": 34862.8091,
    "solar_generated": 13.03321655,
    "wind_generated": 62.50553357,
    "marketplace_to_demand": 44.56864069,
    "grid_to_demand": 35225.18748,
    "govt_battery_charge": 45.64863126
  },
  {
    "timestamp": "05/03/25 12:00",
    "demand": 34098.06217,
    "solar_generated": 23.46702652,
    "wind_generated": 61.91449307,
    "marketplace_to_demand": 173.0854351,
    "grid_to_demand": 31426.08243,
    "govt_battery_charge": 47.39284843
  },
  {
    "timestamp": "05/03/25 13:00",
    "demand": 33291.22256,
    "solar_generated": 17.94524421,
    "wind_generated": 64.31232574,
    "marketplace_to_demand": 81.9840297,
    "grid_to_demand": 34113.91308,
    "govt_battery_charge": 66.29688585
  },
  {
    "timestamp": "05/03/25 14:00",
    "demand": 31882.22458,
    "solar_generated": 7.005541792,
    "wind_generated": 70.45171172,
    "marketplace_to_demand": 59.30124497,
    "grid_to_demand": 31693.39609,
    "govt_battery_charge": 13.24045171
  },
  {
    "timestamp": "05/03/25 15:00",
    "demand": 32449.06301,
    "solar_generated": 22.68305946,
    "wind_generated": 53.66354111,
    "marketplace_to_demand": 21.49414901,
    "grid_to_demand": 32185.46609,
    "govt_battery_charge": 99.85961014
  },
  {
    "timestamp": "05/03/25 16:00",
    "demand": 30102.80994,
    "solar_generated": 8.301846816,
    "wind_generated": 63.76716139,
    "marketplace_to_demand": 63.13029714,
    "grid_to_demand": 35177.29165,
    "govt_battery_charge": 59.69485897
  },
  {
    "timestamp": "05/03/25 17:00",
    "demand": 35693.46756,
    "solar_generated": 13.83651855,
    "wind_generated": 53.78653803,
    "marketplace_to_demand": 124.636065,
    "grid_to_demand": 36084.93135,
    "govt_battery_charge": 33.75338891
  },
  {
    "timestamp": "05/03/25 18:00",
    "demand": 30205.09814,
    "solar_generated": 0,
    "wind_generated": 67.07541114,
    "marketplace_to_demand": 36.87769153,
    "grid_to_demand": 33839.74962,
    "govt_battery_charge": 41.61580047
  },
  {
    "timestamp": "05/03/25 19:00",
    "demand": 33374.3298,
    "solar_generated": 0,
    "wind_generated": 56.69988936,
    "marketplace_to_demand": 196.6362429,
    "grid_to_demand": 30548.89618,
    "govt_battery_charge": 37.17520551
  },
  {
    "timestamp": "05/03/25 20:00",
    "demand": 33221.08125,
    "solar_generated": 0,
    "wind_generated": 71.60888602,
    "marketplace_to_demand": 97.81183878,
    "grid_to_demand": 33557.14394,
    "govt_battery_charge": 89.14471827
  },
  {
    "timestamp": "05/03/25 21:00",
    "demand": 33830.26312,
    "solar_generated": 0,
    "wind_generated": 61.79395913,
    "marketplace_to_demand": 99.88870256,
    "grid_to_demand": 35668.30595,
    "govt_battery_charge": 32.20333411
  },
  {
    "timestamp": "05/03/25 22:00",
    "demand": 36483.67759,
    "solar_generated": 0,
    "wind_generated": 73.4969584,
    "marketplace_to_demand": 110.3747457,
    "grid_to_demand": 34704.07698,
    "govt_battery_charge": 66.88188917
  },
  {
    "timestamp": "05/03/25 23:00",
    "demand": 35608.2067,
    "solar_generated": 0,
    "wind_generated": 68.70089653,
    "marketplace_to_demand": 17.19768074,
    "grid_to_demand": 31367.86621,
    "govt_battery_charge": 47.30374534
  },
  {
    "timestamp": "06/03/25 0:00",
    "demand": 33433.1697,
    "solar_generated": 0,
    "wind_generated": 77.97057956,
    "marketplace_to_demand": 174.9638714,
    "grid_to_demand": 36211.22746,
    "govt_battery_charge": 6.968743494
  },
  {
    "timestamp": "06/03/25 1:00",
    "demand": 31321.83104,
    "solar_generated": 0,
    "wind_generated": 54.32450886,
    "marketplace_to_demand": 62.16938395,
    "grid_to_demand": 30492.45636,
    "govt_battery_charge": 25.76164657
  },
  {
    "timestamp": "06/03/25 2:00",
    "demand": 33086.92727,
    "solar_generated": 0,
    "wind_generated": 57.89636795,
    "marketplace_to_demand": 31.52038623,
    "grid_to_demand": 32437.11807,
    "govt_battery_charge": 83.75088723
  },
  {
    "timestamp": "06/03/25 3:00",
    "demand": 33311.65415,
    "solar_generated": 0,
    "wind_generated": 70.86993456,
    "marketplace_to_demand": 79.88641388,
    "grid_to_demand": 31620.52349,
    "govt_battery_charge": 3.729434991
  },
  {
    "timestamp": "06/03/25 4:00",
    "demand": 32607.15042,
    "solar_generated": 0,
    "wind_generated": 68.42706896,
    "marketplace_to_demand": 153.0744515,
    "grid_to_demand": 34091.50619,
    "govt_battery_charge": 10.24675669
  },
  {
    "timestamp": "06/03/25 5:00",
    "demand": 31660.00036,
    "solar_generated": 0,
    "wind_generated": 61.85177715,
    "marketplace_to_demand": 109.2483966,
    "grid_to_demand": 31078.27719,
    "govt_battery_charge": 1.560800389
  },
  {
    "timestamp": "06/03/25 6:00",
    "demand": 31128.47247,
    "solar_generated": 20.96089686,
    "wind_generated": 71.60109357,
    "marketplace_to_demand": 136.6862203,
    "grid_to_demand": 31412.76294,
    "govt_battery_charge": 21.29820249
  },
  {
    "timestamp": "06/03/25 7:00",
    "demand": 33622.75237,
    "solar_generated": 9.132897985,
    "wind_generated": 57.56789183,
    "marketplace_to_demand": 87.20618416,
    "grid_to_demand": 34992.41812,
    "govt_battery_charge": 27.26624139
  },
  {
    "timestamp": "06/03/25 8:00",
    "demand": 36226.91856,
    "solar_generated": 18.94154412,
    "wind_generated": 61.87165886,
    "marketplace_to_demand": 22.95888543,
    "grid_to_demand": 32821.17967,
    "govt_battery_charge": 72.71117333
  },
  {
    "timestamp": "06/03/25 9:00",
    "demand": 34951.60553,
    "solar_generated": 17.33378451,
    "wind_generated": 62.4312247,
    "marketplace_to_demand": 34.91970257,
    "grid_to_demand": 30755.7868,
    "govt_battery_charge": 4.5185042
  },
  {
    "timestamp": "06/03/25 10:00",
    "demand": 33986.48045,
    "solar_generated": 13.32819914,
    "wind_generated": 72.57479735,
    "marketplace_to_demand": 58.66756169,
    "grid_to_demand": 30275.46059,
    "govt_battery_charge": 48.29671525
  },
  {
    "timestamp": "06/03/25 11:00",
    "demand": 36053.66019,
    "solar_generated": 10.92978972,
    "wind_generated": 64.30783587,
    "marketplace_to_demand": 135.5671619,
    "grid_to_demand": 36272.28949,
    "govt_battery_charge": 56.75163261
  },
  {
    "timestamp": "06/03/25 12:00",
    "demand": 33317.75003,
    "solar_generated": 12.64692851,
    "wind_generated": 79.6673393,
    "marketplace_to_demand": 102.8282602,
    "grid_to_demand": 29963.24997,
    "govt_battery_charge": 69.81070743
  },
  {
    "timestamp": "06/03/25 13:00",
    "demand": 33672.06392,
    "solar_generated": 11.71127918,
    "wind_generated": 61.38755885,
    "marketplace_to_demand": 189.7493914,
    "grid_to_demand": 30928.07092,
    "govt_battery_charge": 9.460166326
  },
  {
    "timestamp": "06/03/25 14:00",
    "demand": 30759.78539,
    "solar_generated": 14.50008689,
    "wind_generated": 63.45006213,
    "marketplace_to_demand": 51.79883367,
    "grid_to_demand": 30351.94536,
    "govt_battery_charge": 99.77735511
  },
  {
    "timestamp": "06/03/25 15:00",
    "demand": 34936.48915,
    "solar_generated": 20.83487881,
    "wind_generated": 57.82918015,
    "marketplace_to_demand": 189.931571,
    "grid_to_demand": 35718.30619,
    "govt_battery_charge": 37.97419053
  },
  {
    "timestamp": "06/03/25 16:00",
    "demand": 32248.82777,
    "solar_generated": 19.67689735,
    "wind_generated": 64.70248763,
    "marketplace_to_demand": 96.17733075,
    "grid_to_demand": 30366.68966,
    "govt_battery_charge": 96.1347355
  },
  {
    "timestamp": "06/03/25 17:00",
    "demand": 34476.56792,
    "solar_generated": 18.20013569,
    "wind_generated": 80.0178766,
    "marketplace_to_demand": 195.2283082,
    "grid_to_demand": 35650.77969,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "06/03/25 18:00",
    "demand": 33497.85677,
    "solar_generated": 0,
    "wind_generated": 55.31004651,
    "marketplace_to_demand": 185.1620188,
    "grid_to_demand": 31085.7601,
    "govt_battery_charge": 93.03484736
  },
  {
    "timestamp": "06/03/25 19:00",
    "demand": 32950.92974,
    "solar_generated": 0,
    "wind_generated": 63.00519458,
    "marketplace_to_demand": 135.2323868,
    "grid_to_demand": 36380.73472,
    "govt_battery_charge": 12.42295547
  },
  {
    "timestamp": "06/03/25 20:00",
    "demand": 33475.01614,
    "solar_generated": 0,
    "wind_generated": 57.84396241,
    "marketplace_to_demand": 12.71123095,
    "grid_to_demand": 31359.6775,
    "govt_battery_charge": 73.48789578
  },
  {
    "timestamp": "06/03/25 21:00",
    "demand": 35986.06889,
    "solar_generated": 0,
    "wind_generated": 57.78663251,
    "marketplace_to_demand": 48.11557438,
    "grid_to_demand": 33755.67346,
    "govt_battery_charge": 76.95870074
  },
  {
    "timestamp": "06/03/25 22:00",
    "demand": 34494.52764,
    "solar_generated": 0,
    "wind_generated": 63.84216628,
    "marketplace_to_demand": 176.220565,
    "grid_to_demand": 33146.75763,
    "govt_battery_charge": 96.56039336
  },
  {
    "timestamp": "06/03/25 23:00",
    "demand": 31786.725,
    "solar_generated": 0,
    "wind_generated": 74.20840372,
    "marketplace_to_demand": 10.37640972,
    "grid_to_demand": 31606.24389,
    "govt_battery_charge": 92.78484781
  },
  {
    "timestamp": "07/03/25 0:00",
    "demand": 33945.92722,
    "solar_generated": 0,
    "wind_generated": 74.46501681,
    "marketplace_to_demand": 38.53966579,
    "grid_to_demand": 32814.58923,
    "govt_battery_charge": 63.67692688
  },
  {
    "timestamp": "07/03/25 1:00",
    "demand": 35022.45283,
    "solar_generated": 0,
    "wind_generated": 60.11137362,
    "marketplace_to_demand": 183.9363142,
    "grid_to_demand": 29962.84656,
    "govt_battery_charge": 83.62854122
  },
  {
    "timestamp": "07/03/25 2:00",
    "demand": 35316.87553,
    "solar_generated": 0,
    "wind_generated": 77.58639618,
    "marketplace_to_demand": 48.92047704,
    "grid_to_demand": 30370.69625,
    "govt_battery_charge": 49.45116543
  },
  {
    "timestamp": "07/03/25 3:00",
    "demand": 30014.11245,
    "solar_generated": 0,
    "wind_generated": 74.85323824,
    "marketplace_to_demand": 109.2652512,
    "grid_to_demand": 35290.70076,
    "govt_battery_charge": 77.58074637
  },
  {
    "timestamp": "07/03/25 4:00",
    "demand": 30808.0251,
    "solar_generated": 0,
    "wind_generated": 58.68239188,
    "marketplace_to_demand": 39.72692558,
    "grid_to_demand": 35998.83477,
    "govt_battery_charge": 62.11897132
  },
  {
    "timestamp": "07/03/25 5:00",
    "demand": 31364.05865,
    "solar_generated": 0,
    "wind_generated": 71.72231473,
    "marketplace_to_demand": 117.4307719,
    "grid_to_demand": 34051.52147,
    "govt_battery_charge": 73.39701707
  },
  {
    "timestamp": "07/03/25 6:00",
    "demand": 33818.60073,
    "solar_generated": 18.44385873,
    "wind_generated": 59.86823428,
    "marketplace_to_demand": 163.9056721,
    "grid_to_demand": 32803.71617,
    "govt_battery_charge": 44.18991241
  },
  {
    "timestamp": "07/03/25 7:00",
    "demand": 32636.25563,
    "solar_generated": 20.29945541,
    "wind_generated": 60.34313164,
    "marketplace_to_demand": 3.751082818,
    "grid_to_demand": 35460.16591,
    "govt_battery_charge": 68.25657732
  },
  {
    "timestamp": "07/03/25 8:00",
    "demand": 36476.84373,
    "solar_generated": 22.46776186,
    "wind_generated": 75.85965737,
    "marketplace_to_demand": 7.29724447,
    "grid_to_demand": 33537.37159,
    "govt_battery_charge": 21.6624396
  },
  {
    "timestamp": "07/03/25 9:00",
    "demand": 32489.40294,
    "solar_generated": 6.324778741,
    "wind_generated": 55.9128719,
    "marketplace_to_demand": 100.0594287,
    "grid_to_demand": 35348.92556,
    "govt_battery_charge": 81.90985332
  },
  {
    "timestamp": "07/03/25 10:00",
    "demand": 30317.24191,
    "solar_generated": 17.24141877,
    "wind_generated": 75.003323,
    "marketplace_to_demand": 40.24803232,
    "grid_to_demand": 33473.15181,
    "govt_battery_charge": 52.08530299
  },
  {
    "timestamp": "07/03/25 11:00",
    "demand": 33773.03895,
    "solar_generated": 12.83392191,
    "wind_generated": 70.27556266,
    "marketplace_to_demand": 117.5105694,
    "grid_to_demand": 35564.42674,
    "govt_battery_charge": 18.97371743
  },
  {
    "timestamp": "07/03/25 12:00",
    "demand": 36023.54884,
    "solar_generated": 12.13870462,
    "wind_generated": 65.05168485,
    "marketplace_to_demand": 135.5258458,
    "grid_to_demand": 33357.69114,
    "govt_battery_charge": 45.5074076
  },
  {
    "timestamp": "07/03/25 13:00",
    "demand": 36554.10249,
    "solar_generated": 14.08058387,
    "wind_generated": 57.58194042,
    "marketplace_to_demand": 117.1920707,
    "grid_to_demand": 32033.92899,
    "govt_battery_charge": 87.67007768
  },
  {
    "timestamp": "07/03/25 14:00",
    "demand": 35613.34215,
    "solar_generated": 23.2606511,
    "wind_generated": 79.64597374,
    "marketplace_to_demand": 79.81205911,
    "grid_to_demand": 32252.00895,
    "govt_battery_charge": 47.49656405
  },
  {
    "timestamp": "07/03/25 15:00",
    "demand": 36047.75163,
    "solar_generated": 9.000066174,
    "wind_generated": 66.69064192,
    "marketplace_to_demand": 190.781443,
    "grid_to_demand": 34202.39865,
    "govt_battery_charge": 35.30751813
  },
  {
    "timestamp": "07/03/25 16:00",
    "demand": 35552.91775,
    "solar_generated": 14.9751846,
    "wind_generated": 74.94992538,
    "marketplace_to_demand": 54.20673015,
    "grid_to_demand": 30447.56789,
    "govt_battery_charge": 87.60055339
  },
  {
    "timestamp": "07/03/25 17:00",
    "demand": 30201.14062,
    "solar_generated": 12.90544926,
    "wind_generated": 56.08323894,
    "marketplace_to_demand": 37.95200551,
    "grid_to_demand": 30193.30399,
    "govt_battery_charge": 71.16011642
  },
  {
    "timestamp": "07/03/25 18:00",
    "demand": 33664.40823,
    "solar_generated": 0,
    "wind_generated": 54.35914286,
    "marketplace_to_demand": 63.20539788,
    "grid_to_demand": 34845.33978,
    "govt_battery_charge": 89.48566759
  },
  {
    "timestamp": "07/03/25 19:00",
    "demand": 31077.20172,
    "solar_generated": 0,
    "wind_generated": 69.05373776,
    "marketplace_to_demand": 59.69810823,
    "grid_to_demand": 36364.33985,
    "govt_battery_charge": 96.34550788
  },
  {
    "timestamp": "07/03/25 20:00",
    "demand": 36123.43999,
    "solar_generated": 0,
    "wind_generated": 66.73809209,
    "marketplace_to_demand": 151.2661321,
    "grid_to_demand": 36542.35913,
    "govt_battery_charge": 5.522829719
  },
  {
    "timestamp": "07/03/25 21:00",
    "demand": 34704.5824,
    "solar_generated": 0,
    "wind_generated": 71.76839886,
    "marketplace_to_demand": 118.9290823,
    "grid_to_demand": 33064.77509,
    "govt_battery_charge": 82.39800155
  },
  {
    "timestamp": "07/03/25 22:00",
    "demand": 32636.28831,
    "solar_generated": 0,
    "wind_generated": 54.49305729,
    "marketplace_to_demand": 58.25306529,
    "grid_to_demand": 32030.95823,
    "govt_battery_charge": 35.75852245
  },
  {
    "timestamp": "07/03/25 23:00",
    "demand": 33679.78476,
    "solar_generated": 0,
    "wind_generated": 70.79195026,
    "marketplace_to_demand": 2.248564111,
    "grid_to_demand": 31540.91601,
    "govt_battery_charge": 63.3785089
  },
  {
    "timestamp": "08/03/25 0:00",
    "demand": 33570.5321,
    "solar_generated": 0,
    "wind_generated": 68.14724252,
    "marketplace_to_demand": 161.6939266,
    "grid_to_demand": 35607.4479,
    "govt_battery_charge": 59.45185212
  },
  {
    "timestamp": "08/03/25 1:00",
    "demand": 35024.91887,
    "solar_generated": 0,
    "wind_generated": 53.77111522,
    "marketplace_to_demand": 127.6299502,
    "grid_to_demand": 33478.50934,
    "govt_battery_charge": 11.8230687
  },
  {
    "timestamp": "08/03/25 2:00",
    "demand": 30433.84868,
    "solar_generated": 0,
    "wind_generated": 65.02343003,
    "marketplace_to_demand": 105.9735795,
    "grid_to_demand": 34221.98527,
    "govt_battery_charge": 62.01187372
  },
  {
    "timestamp": "08/03/25 3:00",
    "demand": 32422.71103,
    "solar_generated": 0,
    "wind_generated": 78.982551,
    "marketplace_to_demand": 122.6009349,
    "grid_to_demand": 36422.00412,
    "govt_battery_charge": 89.70539779
  },
  {
    "timestamp": "08/03/25 4:00",
    "demand": 33555.49659,
    "solar_generated": 0,
    "wind_generated": 54.43989311,
    "marketplace_to_demand": 39.06998637,
    "grid_to_demand": 32637.28714,
    "govt_battery_charge": 40.56721771
  },
  {
    "timestamp": "08/03/25 5:00",
    "demand": 30400.01959,
    "solar_generated": 0,
    "wind_generated": 54.6389081,
    "marketplace_to_demand": 58.97126289,
    "grid_to_demand": 32492.13864,
    "govt_battery_charge": 33.05371543
  },
  {
    "timestamp": "08/03/25 6:00",
    "demand": 36448.03836,
    "solar_generated": 19.22391563,
    "wind_generated": 79.4007569,
    "marketplace_to_demand": 194.3540849,
    "grid_to_demand": 29942.59309,
    "govt_battery_charge": 87.32521384
  },
  {
    "timestamp": "08/03/25 7:00",
    "demand": 33245.8019,
    "solar_generated": 16.78356563,
    "wind_generated": 58.82142264,
    "marketplace_to_demand": 140.3303982,
    "grid_to_demand": 36348.49383,
    "govt_battery_charge": 16.22566347
  },
  {
    "timestamp": "08/03/25 8:00",
    "demand": 33174.34319,
    "solar_generated": 8.262061537,
    "wind_generated": 65.16574858,
    "marketplace_to_demand": 9.488869671,
    "grid_to_demand": 32301.75853,
    "govt_battery_charge": 13.38421981
  },
  {
    "timestamp": "08/03/25 9:00",
    "demand": 32948.16963,
    "solar_generated": 11.35573244,
    "wind_generated": 69.59850986,
    "marketplace_to_demand": 47.49711198,
    "grid_to_demand": 35128.19261,
    "govt_battery_charge": 38.62505787
  },
  {
    "timestamp": "08/03/25 10:00",
    "demand": 34898.9698,
    "solar_generated": 7.908581228,
    "wind_generated": 63.5765019,
    "marketplace_to_demand": 89.91705005,
    "grid_to_demand": 35442.07083,
    "govt_battery_charge": 17.68052162
  },
  {
    "timestamp": "08/03/25 11:00",
    "demand": 36028.14875,
    "solar_generated": 12.2752001,
    "wind_generated": 64.74149003,
    "marketplace_to_demand": 123.7257283,
    "grid_to_demand": 30660.60576,
    "govt_battery_charge": 55.35749594
  },
  {
    "timestamp": "08/03/25 12:00",
    "demand": 33449.66461,
    "solar_generated": 9.136704252,
    "wind_generated": 79.5017088,
    "marketplace_to_demand": 101.343093,
    "grid_to_demand": 30335.70998,
    "govt_battery_charge": 49.66385411
  },
  {
    "timestamp": "08/03/25 13:00",
    "demand": 35067.91504,
    "solar_generated": 13.44784992,
    "wind_generated": 73.41761375,
    "marketplace_to_demand": 131.2080817,
    "grid_to_demand": 30745.55001,
    "govt_battery_charge": 78.60631119
  },
  {
    "timestamp": "08/03/25 14:00",
    "demand": 34336.67028,
    "solar_generated": 20.14560681,
    "wind_generated": 59.80653871,
    "marketplace_to_demand": 12.19908516,
    "grid_to_demand": 35821.44677,
    "govt_battery_charge": 16.30222605
  },
  {
    "timestamp": "08/03/25 15:00",
    "demand": 31730.36228,
    "solar_generated": 21.58725816,
    "wind_generated": 59.45385595,
    "marketplace_to_demand": 88.94107761,
    "grid_to_demand": 32501.32608,
    "govt_battery_charge": 1.910071884
  },
  {
    "timestamp": "08/03/25 16:00",
    "demand": 31451.7116,
    "solar_generated": 14.72276522,
    "wind_generated": 71.8037691,
    "marketplace_to_demand": 199.2461301,
    "grid_to_demand": 34649.89381,
    "govt_battery_charge": 77.64600124
  },
  {
    "timestamp": "08/03/25 17:00",
    "demand": 30580.84309,
    "solar_generated": 6.896927327,
    "wind_generated": 68.77100335,
    "marketplace_to_demand": 109.7483191,
    "grid_to_demand": 32851.011,
    "govt_battery_charge": 59.95569926
  },
  {
    "timestamp": "08/03/25 18:00",
    "demand": 32146.27362,
    "solar_generated": 0,
    "wind_generated": 71.08081826,
    "marketplace_to_demand": 178.1740876,
    "grid_to_demand": 30720.9664,
    "govt_battery_charge": 93.14752549
  },
  {
    "timestamp": "08/03/25 19:00",
    "demand": 34774.27329,
    "solar_generated": 0,
    "wind_generated": 69.21077222,
    "marketplace_to_demand": 118.5583473,
    "grid_to_demand": 33196.36451,
    "govt_battery_charge": 69.90662336
  },
  {
    "timestamp": "08/03/25 20:00",
    "demand": 33514.03631,
    "solar_generated": 0,
    "wind_generated": 77.81487797,
    "marketplace_to_demand": 178.325475,
    "grid_to_demand": 32735.88491,
    "govt_battery_charge": 23.2307908
  },
  {
    "timestamp": "08/03/25 21:00",
    "demand": 31411.90826,
    "solar_generated": 0,
    "wind_generated": 79.0371705,
    "marketplace_to_demand": 93.90363413,
    "grid_to_demand": 34991.10012,
    "govt_battery_charge": 20.49683643
  },
  {
    "timestamp": "08/03/25 22:00",
    "demand": 36319.99261,
    "solar_generated": 0,
    "wind_generated": 65.15259665,
    "marketplace_to_demand": 45.4420225,
    "grid_to_demand": 33698.55948,
    "govt_battery_charge": 74.03093193
  },
  {
    "timestamp": "08/03/25 23:00",
    "demand": 31761.34143,
    "solar_generated": 0,
    "wind_generated": 54.90086742,
    "marketplace_to_demand": 193.4937845,
    "grid_to_demand": 30277.63752,
    "govt_battery_charge": 91.02670078
  },
  {
    "timestamp": "09/03/25 0:00",
    "demand": 34645.49752,
    "solar_generated": 0,
    "wind_generated": 73.893122,
    "marketplace_to_demand": 7.523236753,
    "grid_to_demand": 34710.54465,
    "govt_battery_charge": 22.76796398
  },
  {
    "timestamp": "09/03/25 1:00",
    "demand": 31551.77195,
    "solar_generated": 0,
    "wind_generated": 70.35816719,
    "marketplace_to_demand": 89.09710737,
    "grid_to_demand": 32686.34131,
    "govt_battery_charge": 49.75348017
  },
  {
    "timestamp": "09/03/25 2:00",
    "demand": 35934.87076,
    "solar_generated": 0,
    "wind_generated": 69.23493315,
    "marketplace_to_demand": 92.80280507,
    "grid_to_demand": 32861.36351,
    "govt_battery_charge": 25.4206909
  },
  {
    "timestamp": "09/03/25 3:00",
    "demand": 36308.83807,
    "solar_generated": 0,
    "wind_generated": 76.15894094,
    "marketplace_to_demand": 76.34458384,
    "grid_to_demand": 29979.73834,
    "govt_battery_charge": 97.30431632
  },
  {
    "timestamp": "09/03/25 4:00",
    "demand": 35847.79067,
    "solar_generated": 0,
    "wind_generated": 76.24794814,
    "marketplace_to_demand": 197.4054961,
    "grid_to_demand": 30787.03633,
    "govt_battery_charge": 78.59230831
  },
  {
    "timestamp": "09/03/25 5:00",
    "demand": 34236.44025,
    "solar_generated": 0,
    "wind_generated": 71.50405131,
    "marketplace_to_demand": 36.16443681,
    "grid_to_demand": 30922.28792,
    "govt_battery_charge": 93.35802785
  },
  {
    "timestamp": "09/03/25 6:00",
    "demand": 35064.25303,
    "solar_generated": 9.3145699,
    "wind_generated": 58.73377551,
    "marketplace_to_demand": 54.18616811,
    "grid_to_demand": 32159.16776,
    "govt_battery_charge": 85.19599801
  },
  {
    "timestamp": "09/03/25 7:00",
    "demand": 36128.79664,
    "solar_generated": 21.70608933,
    "wind_generated": 72.22362343,
    "marketplace_to_demand": 44.33475425,
    "grid_to_demand": 34815.8444,
    "govt_battery_charge": 69.99817374
  },
  {
    "timestamp": "09/03/25 8:00",
    "demand": 32054.35748,
    "solar_generated": 6.401877725,
    "wind_generated": 76.55097152,
    "marketplace_to_demand": 149.2312572,
    "grid_to_demand": 30280.24125,
    "govt_battery_charge": 12.30862577
  },
  {
    "timestamp": "09/03/25 9:00",
    "demand": 35639.38147,
    "solar_generated": 18.42608243,
    "wind_generated": 60.40936393,
    "marketplace_to_demand": 29.49749624,
    "grid_to_demand": 35696.8195,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/03/25 10:00",
    "demand": 34384.60675,
    "solar_generated": 11.61250073,
    "wind_generated": 63.07001666,
    "marketplace_to_demand": 23.5682794,
    "grid_to_demand": 34258.27257,
    "govt_battery_charge": 24.22155779
  },
  {
    "timestamp": "09/03/25 11:00",
    "demand": 30239.48122,
    "solar_generated": 17.44991777,
    "wind_generated": 64.27258777,
    "marketplace_to_demand": 5.408586272,
    "grid_to_demand": 31515.08997,
    "govt_battery_charge": 79.89795115
  },
  {
    "timestamp": "09/03/25 12:00",
    "demand": 31796.56887,
    "solar_generated": 7.283094153,
    "wind_generated": 67.97952214,
    "marketplace_to_demand": 75.27031436,
    "grid_to_demand": 31096.46911,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "09/03/25 13:00",
    "demand": 30642.18728,
    "solar_generated": 23.07293115,
    "wind_generated": 66.42637958,
    "marketplace_to_demand": 106.3370255,
    "grid_to_demand": 33761.57677,
    "govt_battery_charge": 69.70482927
  },
  {
    "timestamp": "09/03/25 14:00",
    "demand": 36463.91803,
    "solar_generated": 20.4337297,
    "wind_generated": 58.64527047,
    "marketplace_to_demand": 32.55219767,
    "grid_to_demand": 31868.59654,
    "govt_battery_charge": 53.26886516
  },
  {
    "timestamp": "09/03/25 15:00",
    "demand": 33060.47405,
    "solar_generated": 15.93784508,
    "wind_generated": 67.40415121,
    "marketplace_to_demand": 39.5334259,
    "grid_to_demand": 32474.50901,
    "govt_battery_charge": 17.50397492
  },
  {
    "timestamp": "09/03/25 16:00",
    "demand": 35909.13345,
    "solar_generated": 10.31108944,
    "wind_generated": 54.91253608,
    "marketplace_to_demand": 100.2174495,
    "grid_to_demand": 31332.48411,
    "govt_battery_charge": 58.32632859
  },
  {
    "timestamp": "09/03/25 17:00",
    "demand": 34550.20186,
    "solar_generated": 6.765640465,
    "wind_generated": 77.58320854,
    "marketplace_to_demand": 34.2008216,
    "grid_to_demand": 31448.58222,
    "govt_battery_charge": 84.78275227
  },
  {
    "timestamp": "09/03/25 18:00",
    "demand": 31472.88589,
    "solar_generated": 0,
    "wind_generated": 79.93784321,
    "marketplace_to_demand": 146.3625219,
    "grid_to_demand": 32359.52807,
    "govt_battery_charge": 88.98079797
  },
  {
    "timestamp": "09/03/25 19:00",
    "demand": 30169.27323,
    "solar_generated": 0,
    "wind_generated": 54.30584038,
    "marketplace_to_demand": 132.1868541,
    "grid_to_demand": 33810.43744,
    "govt_battery_charge": 92.14799596
  },
  {
    "timestamp": "09/03/25 20:00",
    "demand": 30274.87555,
    "solar_generated": 0,
    "wind_generated": 67.92963541,
    "marketplace_to_demand": 115.7951887,
    "grid_to_demand": 34363.30487,
    "govt_battery_charge": 47.31844305
  },
  {
    "timestamp": "09/03/25 21:00",
    "demand": 33586.75936,
    "solar_generated": 0,
    "wind_generated": 71.41823333,
    "marketplace_to_demand": 1.778820748,
    "grid_to_demand": 32558.62856,
    "govt_battery_charge": 85.98172045
  },
  {
    "timestamp": "09/03/25 22:00",
    "demand": 33108.53769,
    "solar_generated": 0,
    "wind_generated": 64.52489892,
    "marketplace_to_demand": 99.54943746,
    "grid_to_demand": 31047.60919,
    "govt_battery_charge": 69.94983086
  },
  {
    "timestamp": "09/03/25 23:00",
    "demand": 30366.38784,
    "solar_generated": 0,
    "wind_generated": 65.00390034,
    "marketplace_to_demand": 100.5763446,
    "grid_to_demand": 33688.64199,
    "govt_battery_charge": 15.31101749
  },
  {
    "timestamp": "10/03/25 0:00",
    "demand": 34630.84501,
    "solar_generated": 0,
    "wind_generated": 55.78973941,
    "marketplace_to_demand": 53.43327824,
    "grid_to_demand": 35871.3421,
    "govt_battery_charge": 70.41450367
  },
  {
    "timestamp": "10/03/25 1:00",
    "demand": 33528.91021,
    "solar_generated": 0,
    "wind_generated": 75.70555726,
    "marketplace_to_demand": 1.372133762,
    "grid_to_demand": 33296.67451,
    "govt_battery_charge": 47.53407063
  },
  {
    "timestamp": "10/03/25 2:00",
    "demand": 31734.66154,
    "solar_generated": 0,
    "wind_generated": 78.1756253,
    "marketplace_to_demand": 169.1422643,
    "grid_to_demand": 35146.6662,
    "govt_battery_charge": 9.476196637
  },
  {
    "timestamp": "10/03/25 3:00",
    "demand": 32961.59617,
    "solar_generated": 0,
    "wind_generated": 54.99418157,
    "marketplace_to_demand": 10.38542838,
    "grid_to_demand": 34086.09554,
    "govt_battery_charge": 98.58773402
  },
  {
    "timestamp": "10/03/25 4:00",
    "demand": 35097.77146,
    "solar_generated": 0,
    "wind_generated": 63.19427235,
    "marketplace_to_demand": 165.9509878,
    "grid_to_demand": 31657.9514,
    "govt_battery_charge": 49.65599484
  },
  {
    "timestamp": "10/03/25 5:00",
    "demand": 35831.4883,
    "solar_generated": 0,
    "wind_generated": 67.87627394,
    "marketplace_to_demand": 100.6113294,
    "grid_to_demand": 35782.14118,
    "govt_battery_charge": 53.85990136
  },
  {
    "timestamp": "10/03/25 6:00",
    "demand": 34458.28983,
    "solar_generated": 15.49935525,
    "wind_generated": 77.22914635,
    "marketplace_to_demand": 163.9424809,
    "grid_to_demand": 33304.96068,
    "govt_battery_charge": 74.00249192
  },
  {
    "timestamp": "10/03/25 7:00",
    "demand": 31111.04429,
    "solar_generated": 23.90085668,
    "wind_generated": 63.16856079,
    "marketplace_to_demand": 4.812498523,
    "grid_to_demand": 35596.96202,
    "govt_battery_charge": 67.09785413
  },
  {
    "timestamp": "10/03/25 8:00",
    "demand": 32858.40013,
    "solar_generated": 23.06383125,
    "wind_generated": 72.05837098,
    "marketplace_to_demand": 36.56881053,
    "grid_to_demand": 30256.2598,
    "govt_battery_charge": 57.28315092
  },
  {
    "timestamp": "10/03/25 9:00",
    "demand": 31898.04881,
    "solar_generated": 9.341945873,
    "wind_generated": 65.39135716,
    "marketplace_to_demand": 85.66743701,
    "grid_to_demand": 35440.14733,
    "govt_battery_charge": 74.60591038
  },
  {
    "timestamp": "10/03/25 10:00",
    "demand": 31496.19515,
    "solar_generated": 18.00430213,
    "wind_generated": 55.22654851,
    "marketplace_to_demand": 163.034391,
    "grid_to_demand": 36103.31026,
    "govt_battery_charge": 90.87443737
  },
  {
    "timestamp": "10/03/25 11:00",
    "demand": 34399.06906,
    "solar_generated": 8.005574198,
    "wind_generated": 66.05684343,
    "marketplace_to_demand": 160.7564015,
    "grid_to_demand": 33638.72299,
    "govt_battery_charge": 28.90155819
  },
  {
    "timestamp": "10/03/25 12:00",
    "demand": 33573.04753,
    "solar_generated": 14.40543511,
    "wind_generated": 75.88115805,
    "marketplace_to_demand": 55.31233723,
    "grid_to_demand": 33318.82859,
    "govt_battery_charge": 81.28041563
  },
  {
    "timestamp": "10/03/25 13:00",
    "demand": 32551.00514,
    "solar_generated": 8.233729448,
    "wind_generated": 57.94377018,
    "marketplace_to_demand": 155.0063708,
    "grid_to_demand": 34606.48907,
    "govt_battery_charge": 6.81930851
  },
  {
    "timestamp": "10/03/25 14:00",
    "demand": 34990.01446,
    "solar_generated": 8.771456136,
    "wind_generated": 65.49801494,
    "marketplace_to_demand": 86.83182261,
    "grid_to_demand": 36169.09201,
    "govt_battery_charge": 56.79852118
  },
  {
    "timestamp": "10/03/25 15:00",
    "demand": 34335.09979,
    "solar_generated": 6.898685001,
    "wind_generated": 68.44619825,
    "marketplace_to_demand": 75.06852023,
    "grid_to_demand": 34771.17819,
    "govt_battery_charge": 13.40337004
  },
  {
    "timestamp": "10/03/25 16:00",
    "demand": 34077.88324,
    "solar_generated": 17.68888771,
    "wind_generated": 61.77412953,
    "marketplace_to_demand": 3.613527179,
    "grid_to_demand": 31631.42162,
    "govt_battery_charge": 25.52705434
  },
  {
    "timestamp": "10/03/25 17:00",
    "demand": 32914.79616,
    "solar_generated": 11.43024237,
    "wind_generated": 63.73595191,
    "marketplace_to_demand": 71.15407883,
    "grid_to_demand": 34174.69248,
    "govt_battery_charge": 81.28246802
  },
  {
    "timestamp": "10/03/25 18:00",
    "demand": 33481.27432,
    "solar_generated": 0,
    "wind_generated": 79.86129418,
    "marketplace_to_demand": 40.29722024,
    "grid_to_demand": 35638.14977,
    "govt_battery_charge": 9.557676973
  },
  {
    "timestamp": "10/03/25 19:00",
    "demand": 32486.6659,
    "solar_generated": 0,
    "wind_generated": 62.30617939,
    "marketplace_to_demand": 72.22572469,
    "grid_to_demand": 32116.71554,
    "govt_battery_charge": 96.33359955
  },
  {
    "timestamp": "10/03/25 20:00",
    "demand": 36341.54038,
    "solar_generated": 0,
    "wind_generated": 63.61327269,
    "marketplace_to_demand": 13.37805153,
    "grid_to_demand": 32109.48816,
    "govt_battery_charge": 28.89780954
  },
  {
    "timestamp": "10/03/25 21:00",
    "demand": 34583.83662,
    "solar_generated": 0,
    "wind_generated": 54.94048483,
    "marketplace_to_demand": 71.91614012,
    "grid_to_demand": 36066.71267,
    "govt_battery_charge": 14.01046178
  },
  {
    "timestamp": "10/03/25 22:00",
    "demand": 31893.21871,
    "solar_generated": 0,
    "wind_generated": 62.58025052,
    "marketplace_to_demand": 183.9307531,
    "grid_to_demand": 33783.95374,
    "govt_battery_charge": 78.34062565
  },
  {
    "timestamp": "10/03/25 23:00",
    "demand": 33803.69527,
    "solar_generated": 0,
    "wind_generated": 60.21613479,
    "marketplace_to_demand": 29.03446531,
    "grid_to_demand": 30719.16026,
    "govt_battery_charge": 61.82725906
  },
  {
    "timestamp": "11/03/25 0:00",
    "demand": 32467.7267,
    "solar_generated": 0,
    "wind_generated": 65.78646327,
    "marketplace_to_demand": 103.7108016,
    "grid_to_demand": 36071.75504,
    "govt_battery_charge": 28.39683621
  },
  {
    "timestamp": "11/03/25 1:00",
    "demand": 31942.28787,
    "solar_generated": 0,
    "wind_generated": 64.04639002,
    "marketplace_to_demand": 93.34196713,
    "grid_to_demand": 32128.884,
    "govt_battery_charge": 71.37219322
  },
  {
    "timestamp": "11/03/25 2:00",
    "demand": 31553.02622,
    "solar_generated": 0,
    "wind_generated": 70.25193117,
    "marketplace_to_demand": 102.6693702,
    "grid_to_demand": 32177.67874,
    "govt_battery_charge": 7.971346122
  },
  {
    "timestamp": "11/03/25 3:00",
    "demand": 36120.16033,
    "solar_generated": 0,
    "wind_generated": 59.96900537,
    "marketplace_to_demand": 34.74442644,
    "grid_to_demand": 34948.02908,
    "govt_battery_charge": 66.19902291
  },
  {
    "timestamp": "11/03/25 4:00",
    "demand": 35738.35841,
    "solar_generated": 0,
    "wind_generated": 67.41129632,
    "marketplace_to_demand": 195.1253214,
    "grid_to_demand": 34596.12217,
    "govt_battery_charge": 46.55618068
  },
  {
    "timestamp": "11/03/25 5:00",
    "demand": 31470.37148,
    "solar_generated": 0,
    "wind_generated": 77.02794899,
    "marketplace_to_demand": 58.31038068,
    "grid_to_demand": 35242.66874,
    "govt_battery_charge": 63.91937999
  },
  {
    "timestamp": "11/03/25 6:00",
    "demand": 34119.98867,
    "solar_generated": 6.373438306,
    "wind_generated": 76.17678224,
    "marketplace_to_demand": 65.49225628,
    "grid_to_demand": 35603.78948,
    "govt_battery_charge": 96.25829719
  },
  {
    "timestamp": "11/03/25 7:00",
    "demand": 30327.3791,
    "solar_generated": 22.74741223,
    "wind_generated": 63.33750393,
    "marketplace_to_demand": 87.54904666,
    "grid_to_demand": 33372.13184,
    "govt_battery_charge": 50.25941669
  },
  {
    "timestamp": "11/03/25 8:00",
    "demand": 32169.94855,
    "solar_generated": 9.121730836,
    "wind_generated": 67.50996093,
    "marketplace_to_demand": 60.85521588,
    "grid_to_demand": 30102.13502,
    "govt_battery_charge": 8.487297413
  },
  {
    "timestamp": "11/03/25 9:00",
    "demand": 35083.72171,
    "solar_generated": 9.232538807,
    "wind_generated": 78.12387456,
    "marketplace_to_demand": 55.16082458,
    "grid_to_demand": 30002.50419,
    "govt_battery_charge": 55.06573615
  },
  {
    "timestamp": "11/03/25 10:00",
    "demand": 33622.63744,
    "solar_generated": 9.230494292,
    "wind_generated": 68.43166172,
    "marketplace_to_demand": 65.3902023,
    "grid_to_demand": 30373.63496,
    "govt_battery_charge": 47.80156596
  },
  {
    "timestamp": "11/03/25 11:00",
    "demand": 31621.48803,
    "solar_generated": 7.689515131,
    "wind_generated": 54.87749732,
    "marketplace_to_demand": 118.7873944,
    "grid_to_demand": 34296.4395,
    "govt_battery_charge": 71.46879603
  },
  {
    "timestamp": "11/03/25 12:00",
    "demand": 34121.71064,
    "solar_generated": 13.42282166,
    "wind_generated": 66.72040155,
    "marketplace_to_demand": 154.5080027,
    "grid_to_demand": 35879.57669,
    "govt_battery_charge": 53.32565115
  },
  {
    "timestamp": "11/03/25 13:00",
    "demand": 34049.77641,
    "solar_generated": 21.14354024,
    "wind_generated": 70.5351913,
    "marketplace_to_demand": 168.8414056,
    "grid_to_demand": 29987.31292,
    "govt_battery_charge": 29.76033167
  },
  {
    "timestamp": "11/03/25 14:00",
    "demand": 35924.63564,
    "solar_generated": 15.75456363,
    "wind_generated": 72.05486377,
    "marketplace_to_demand": 133.8757474,
    "grid_to_demand": 34366.43556,
    "govt_battery_charge": 51.1259343
  },
  {
    "timestamp": "11/03/25 15:00",
    "demand": 30156.82029,
    "solar_generated": 21.11695747,
    "wind_generated": 77.25391482,
    "marketplace_to_demand": 15.65654369,
    "grid_to_demand": 35101.29414,
    "govt_battery_charge": 25.30101445
  },
  {
    "timestamp": "11/03/25 16:00",
    "demand": 31264.31836,
    "solar_generated": 8.753797691,
    "wind_generated": 60.26763819,
    "marketplace_to_demand": 65.34490214,
    "grid_to_demand": 31936.62019,
    "govt_battery_charge": 87.64749487
  },
  {
    "timestamp": "11/03/25 17:00",
    "demand": 30764.3287,
    "solar_generated": 6.145151874,
    "wind_generated": 56.12476616,
    "marketplace_to_demand": 129.3410085,
    "grid_to_demand": 30211.03354,
    "govt_battery_charge": 66.12773454
  },
  {
    "timestamp": "11/03/25 18:00",
    "demand": 34660.19659,
    "solar_generated": 0,
    "wind_generated": 58.8246621,
    "marketplace_to_demand": 68.81200706,
    "grid_to_demand": 32675.74879,
    "govt_battery_charge": 63.56252031
  },
  {
    "timestamp": "11/03/25 19:00",
    "demand": 33792.12439,
    "solar_generated": 0,
    "wind_generated": 56.76813384,
    "marketplace_to_demand": 79.07590136,
    "grid_to_demand": 30913.95593,
    "govt_battery_charge": 32.78148419
  },
  {
    "timestamp": "11/03/25 20:00",
    "demand": 33943.01803,
    "solar_generated": 0,
    "wind_generated": 64.20920537,
    "marketplace_to_demand": 132.9461731,
    "grid_to_demand": 31619.59458,
    "govt_battery_charge": 98.08103465
  },
  {
    "timestamp": "11/03/25 21:00",
    "demand": 33640.91487,
    "solar_generated": 0,
    "wind_generated": 62.26724207,
    "marketplace_to_demand": 71.42235192,
    "grid_to_demand": 31991.87923,
    "govt_battery_charge": 71.12016973
  },
  {
    "timestamp": "11/03/25 22:00",
    "demand": 30501.38078,
    "solar_generated": 0,
    "wind_generated": 62.75235905,
    "marketplace_to_demand": 38.70924519,
    "grid_to_demand": 35535.28139,
    "govt_battery_charge": 35.05499207
  },
  {
    "timestamp": "11/03/25 23:00",
    "demand": 31598.22645,
    "solar_generated": 0,
    "wind_generated": 65.36744421,
    "marketplace_to_demand": 0.740874396,
    "grid_to_demand": 31663.71971,
    "govt_battery_charge": 99.09979914
  },
  {
    "timestamp": "12/03/25 0:00",
    "demand": 32649.83251,
    "solar_generated": 0,
    "wind_generated": 73.52386532,
    "marketplace_to_demand": 93.50553357,
    "grid_to_demand": 34562.93408,
    "govt_battery_charge": 51.24212497
  },
  {
    "timestamp": "12/03/25 1:00",
    "demand": 34608.97497,
    "solar_generated": 0,
    "wind_generated": 77.16952124,
    "marketplace_to_demand": 107.710539,
    "grid_to_demand": 34972.57429,
    "govt_battery_charge": 21.04902188
  },
  {
    "timestamp": "12/03/25 2:00",
    "demand": 36409.46271,
    "solar_generated": 0,
    "wind_generated": 70.45593649,
    "marketplace_to_demand": 148.2782192,
    "grid_to_demand": 30765.10053,
    "govt_battery_charge": 70.26508161
  },
  {
    "timestamp": "12/03/25 3:00",
    "demand": 31967.35371,
    "solar_generated": 0,
    "wind_generated": 60.44158176,
    "marketplace_to_demand": 55.53995046,
    "grid_to_demand": 34571.283,
    "govt_battery_charge": 27.89422044
  },
  {
    "timestamp": "12/03/25 4:00",
    "demand": 35008.68892,
    "solar_generated": 0,
    "wind_generated": 71.3763995,
    "marketplace_to_demand": 186.3646689,
    "grid_to_demand": 35521.66765,
    "govt_battery_charge": 45.3386737
  },
  {
    "timestamp": "12/03/25 5:00",
    "demand": 30896.14375,
    "solar_generated": 0,
    "wind_generated": 79.10989147,
    "marketplace_to_demand": 119.7732993,
    "grid_to_demand": 32907.23217,
    "govt_battery_charge": 97.71106747
  },
  {
    "timestamp": "12/03/25 6:00",
    "demand": 34477.4772,
    "solar_generated": 21.39496447,
    "wind_generated": 78.52728777,
    "marketplace_to_demand": 68.33237486,
    "grid_to_demand": 34768.58667,
    "govt_battery_charge": 97.29678461
  },
  {
    "timestamp": "12/03/25 7:00",
    "demand": 32051.40615,
    "solar_generated": 12.92824853,
    "wind_generated": 57.2220827,
    "marketplace_to_demand": 167.0843774,
    "grid_to_demand": 30282.23678,
    "govt_battery_charge": 50.36651438
  },
  {
    "timestamp": "12/03/25 8:00",
    "demand": 34325.64397,
    "solar_generated": 20.53451663,
    "wind_generated": 55.87869811,
    "marketplace_to_demand": 85.24660172,
    "grid_to_demand": 32522.71977,
    "govt_battery_charge": 88.21046815
  },
  {
    "timestamp": "12/03/25 9:00",
    "demand": 33474.58643,
    "solar_generated": 8.354766189,
    "wind_generated": 71.80844691,
    "marketplace_to_demand": 115.4672479,
    "grid_to_demand": 32858.59898,
    "govt_battery_charge": 20.85842872
  },
  {
    "timestamp": "12/03/25 10:00",
    "demand": 31837.38352,
    "solar_generated": 11.32772554,
    "wind_generated": 57.63357529,
    "marketplace_to_demand": 146.2548228,
    "grid_to_demand": 32991.75047,
    "govt_battery_charge": 87.453884
  },
  {
    "timestamp": "12/03/25 11:00",
    "demand": 36249.07966,
    "solar_generated": 16.72500014,
    "wind_generated": 78.90447993,
    "marketplace_to_demand": 48.80968214,
    "grid_to_demand": 31757.74883,
    "govt_battery_charge": 65.95515888
  },
  {
    "timestamp": "12/03/25 12:00",
    "demand": 33891.74742,
    "solar_generated": 10.9142541,
    "wind_generated": 57.04359743,
    "marketplace_to_demand": 178.1073315,
    "grid_to_demand": 31075.17833,
    "govt_battery_charge": 53.58208043
  },
  {
    "timestamp": "12/03/25 13:00",
    "demand": 31802.17849,
    "solar_generated": 10.50494084,
    "wind_generated": 56.11230072,
    "marketplace_to_demand": 18.45245668,
    "grid_to_demand": 36320.3236,
    "govt_battery_charge": 91.3011119
  },
  {
    "timestamp": "12/03/25 14:00",
    "demand": 31625.73424,
    "solar_generated": 17.47908468,
    "wind_generated": 57.37374533,
    "marketplace_to_demand": 191.3318077,
    "grid_to_demand": 35817.77114,
    "govt_battery_charge": 27.76037278
  },
  {
    "timestamp": "12/03/25 15:00",
    "demand": 34762.52533,
    "solar_generated": 8.56440587,
    "wind_generated": 56.35038416,
    "marketplace_to_demand": 193.393494,
    "grid_to_demand": 30310.75337,
    "govt_battery_charge": 90.21836427
  },
  {
    "timestamp": "12/03/25 16:00",
    "demand": 31602.6782,
    "solar_generated": 12.8042459,
    "wind_generated": 55.27236091,
    "marketplace_to_demand": 172.6956266,
    "grid_to_demand": 30376.04602,
    "govt_battery_charge": 42.45299649
  },
  {
    "timestamp": "12/03/25 17:00",
    "demand": 33791.36867,
    "solar_generated": 6.381653456,
    "wind_generated": 73.64341926,
    "marketplace_to_demand": 54.69441823,
    "grid_to_demand": 34451.85648,
    "govt_battery_charge": 66.79940811
  },
  {
    "timestamp": "12/03/25 18:00",
    "demand": 32279.83654,
    "solar_generated": 0,
    "wind_generated": 54.97181685,
    "marketplace_to_demand": 126.6769472,
    "grid_to_demand": 30534.10646,
    "govt_battery_charge": 81.66400008
  },
  {
    "timestamp": "12/03/25 19:00",
    "demand": 36203.56286,
    "solar_generated": 0,
    "wind_generated": 79.35605984,
    "marketplace_to_demand": 107.4178672,
    "grid_to_demand": 33615.21433,
    "govt_battery_charge": 94.82908793
  },
  {
    "timestamp": "12/03/25 20:00",
    "demand": 31843.76446,
    "solar_generated": 0,
    "wind_generated": 53.91104518,
    "marketplace_to_demand": 139.3016778,
    "grid_to_demand": 32941.06184,
    "govt_battery_charge": 31.85647812
  },
  {
    "timestamp": "12/03/25 21:00",
    "demand": 34957.36173,
    "solar_generated": 0,
    "wind_generated": 55.52499062,
    "marketplace_to_demand": 73.67417377,
    "grid_to_demand": 35386.91626,
    "govt_battery_charge": 98.3350832
  },
  {
    "timestamp": "12/03/25 22:00",
    "demand": 36551.46785,
    "solar_generated": 0,
    "wind_generated": 64.75961985,
    "marketplace_to_demand": 131.3531697,
    "grid_to_demand": 35032.95624,
    "govt_battery_charge": 67.08945261
  },
  {
    "timestamp": "12/03/25 23:00",
    "demand": 32042.76862,
    "solar_generated": 0,
    "wind_generated": 77.01848782,
    "marketplace_to_demand": 5.425106994,
    "grid_to_demand": 30283.08116,
    "govt_battery_charge": 97.96047936
  },
  {
    "timestamp": "13/03/25 0:00",
    "demand": 35577.75286,
    "solar_generated": 0,
    "wind_generated": 59.43654022,
    "marketplace_to_demand": 55.39943782,
    "grid_to_demand": 30906.14153,
    "govt_battery_charge": 97.0812091
  },
  {
    "timestamp": "13/03/25 1:00",
    "demand": 32769.39424,
    "solar_generated": 0,
    "wind_generated": 70.56637938,
    "marketplace_to_demand": 30.81757288,
    "grid_to_demand": 30699.95668,
    "govt_battery_charge": 81.16893394
  },
  {
    "timestamp": "13/03/25 2:00",
    "demand": 30758.28612,
    "solar_generated": 0,
    "wind_generated": 63.52279994,
    "marketplace_to_demand": 41.98404202,
    "grid_to_demand": 31701.30355,
    "govt_battery_charge": 5.090403472
  },
  {
    "timestamp": "13/03/25 3:00",
    "demand": 35627.85482,
    "solar_generated": 0,
    "wind_generated": 65.20511902,
    "marketplace_to_demand": 22.11728584,
    "grid_to_demand": 35135.31953,
    "govt_battery_charge": 25.97468137
  },
  {
    "timestamp": "13/03/25 4:00",
    "demand": 30149.58641,
    "solar_generated": 0,
    "wind_generated": 71.48522165,
    "marketplace_to_demand": 21.66568989,
    "grid_to_demand": 35940.63555,
    "govt_battery_charge": 68.96725827
  },
  {
    "timestamp": "13/03/25 5:00",
    "demand": 34896.75361,
    "solar_generated": 0,
    "wind_generated": 53.93283629,
    "marketplace_to_demand": 116.3752696,
    "grid_to_demand": 35429.41478,
    "govt_battery_charge": 13.99400326
  },
  {
    "timestamp": "13/03/25 6:00",
    "demand": 30082.23005,
    "solar_generated": 13.08790784,
    "wind_generated": 78.48218283,
    "marketplace_to_demand": 122.9277004,
    "grid_to_demand": 33510.11033,
    "govt_battery_charge": 72.15124434
  },
  {
    "timestamp": "13/03/25 7:00",
    "demand": 34771.40854,
    "solar_generated": 10.97835598,
    "wind_generated": 72.89188933,
    "marketplace_to_demand": 127.824565,
    "grid_to_demand": 32065.6422,
    "govt_battery_charge": 88.08301442
  },
  {
    "timestamp": "13/03/25 8:00",
    "demand": 35981.29784,
    "solar_generated": 23.99080012,
    "wind_generated": 79.50830658,
    "marketplace_to_demand": 89.71521234,
    "grid_to_demand": 32077.26257,
    "govt_battery_charge": 81.75446218
  },
  {
    "timestamp": "13/03/25 9:00",
    "demand": 34244.1244,
    "solar_generated": 21.16396687,
    "wind_generated": 80.35871518,
    "marketplace_to_demand": 141.6665443,
    "grid_to_demand": 32761.724,
    "govt_battery_charge": 36.57956387
  },
  {
    "timestamp": "13/03/25 10:00",
    "demand": 36187.61344,
    "solar_generated": 19.40245837,
    "wind_generated": 75.24669788,
    "marketplace_to_demand": 174.8362336,
    "grid_to_demand": 35307.55157,
    "govt_battery_charge": 3.111731121
  },
  {
    "timestamp": "13/03/25 11:00",
    "demand": 34058.06779,
    "solar_generated": 15.79830019,
    "wind_generated": 56.52168776,
    "marketplace_to_demand": 96.12820779,
    "grid_to_demand": 34062.98325,
    "govt_battery_charge": 93.63264255
  },
  {
    "timestamp": "13/03/25 12:00",
    "demand": 31962.48324,
    "solar_generated": 9.737723577,
    "wind_generated": 67.93915533,
    "marketplace_to_demand": 72.51209053,
    "grid_to_demand": 35466.69762,
    "govt_battery_charge": 51.94717275
  },
  {
    "timestamp": "13/03/25 13:00",
    "demand": 34399.56333,
    "solar_generated": 17.33197112,
    "wind_generated": 58.73382679,
    "marketplace_to_demand": 133.844817,
    "grid_to_demand": 31888.41841,
    "govt_battery_charge": 18.04380355
  },
  {
    "timestamp": "13/03/25 14:00",
    "demand": 33258.63374,
    "solar_generated": 11.56766657,
    "wind_generated": 56.65500457,
    "marketplace_to_demand": 113.2620432,
    "grid_to_demand": 32296.28697,
    "govt_battery_charge": 82.15429375
  },
  {
    "timestamp": "13/03/25 15:00",
    "demand": 33406.36917,
    "solar_generated": 12.73400121,
    "wind_generated": 56.99836209,
    "marketplace_to_demand": 21.53153138,
    "grid_to_demand": 36180.48394,
    "govt_battery_charge": 40.69815105
  },
  {
    "timestamp": "13/03/25 16:00",
    "demand": 31267.63872,
    "solar_generated": 13.72718279,
    "wind_generated": 79.59516895,
    "marketplace_to_demand": 83.35845594,
    "grid_to_demand": 31264.0534,
    "govt_battery_charge": 90.23079503
  },
  {
    "timestamp": "13/03/25 17:00",
    "demand": 30126.76886,
    "solar_generated": 16.16483114,
    "wind_generated": 80.1096904,
    "marketplace_to_demand": 52.04573543,
    "grid_to_demand": 30309.31829,
    "govt_battery_charge": 60.48634984
  },
  {
    "timestamp": "13/03/25 18:00",
    "demand": 31988.7132,
    "solar_generated": 0,
    "wind_generated": 65.97103319,
    "marketplace_to_demand": 51.42221965,
    "grid_to_demand": 29922.29459,
    "govt_battery_charge": 62.79164471
  },
  {
    "timestamp": "13/03/25 19:00",
    "demand": 34013.62374,
    "solar_generated": 0,
    "wind_generated": 57.18452054,
    "marketplace_to_demand": 88.09744894,
    "grid_to_demand": 30752.58347,
    "govt_battery_charge": 49.96390365
  },
  {
    "timestamp": "13/03/25 20:00",
    "demand": 36480.25385,
    "solar_generated": 0,
    "wind_generated": 54.18406714,
    "marketplace_to_demand": 77.616779,
    "grid_to_demand": 34794.10549,
    "govt_battery_charge": 17.83002529
  },
  {
    "timestamp": "13/03/25 21:00",
    "demand": 35856.61192,
    "solar_generated": 0,
    "wind_generated": 77.57759692,
    "marketplace_to_demand": 170.4290658,
    "grid_to_demand": 34293.88764,
    "govt_battery_charge": 97.47290428
  },
  {
    "timestamp": "13/03/25 22:00",
    "demand": 33186.18989,
    "solar_generated": 0,
    "wind_generated": 55.4173628,
    "marketplace_to_demand": 120.3783789,
    "grid_to_demand": 32260.38808,
    "govt_battery_charge": 77.93300627
  },
  {
    "timestamp": "13/03/25 23:00",
    "demand": 35760.08783,
    "solar_generated": 0,
    "wind_generated": 71.43228923,
    "marketplace_to_demand": 155.0869783,
    "grid_to_demand": 34372.5803,
    "govt_battery_charge": 31.19765854
  },
  {
    "timestamp": "14/03/25 0:00",
    "demand": 32900.34722,
    "solar_generated": 0,
    "wind_generated": 79.41294725,
    "marketplace_to_demand": 21.5553361,
    "grid_to_demand": 36150.75334,
    "govt_battery_charge": 68.45551887
  },
  {
    "timestamp": "14/03/25 1:00",
    "demand": 36486.99529,
    "solar_generated": 0,
    "wind_generated": 55.28703979,
    "marketplace_to_demand": 196.4721126,
    "grid_to_demand": 34537.98629,
    "govt_battery_charge": 37.71338839
  },
  {
    "timestamp": "14/03/25 2:00",
    "demand": 34103.87016,
    "solar_generated": 0,
    "wind_generated": 80.38956516,
    "marketplace_to_demand": 31.66218846,
    "grid_to_demand": 33896.12659,
    "govt_battery_charge": 52.36195731
  },
  {
    "timestamp": "14/03/25 3:00",
    "demand": 32201.17178,
    "solar_generated": 0,
    "wind_generated": 71.83015061,
    "marketplace_to_demand": 60.39839472,
    "grid_to_demand": 33817.40289,
    "govt_battery_charge": 58.1699327
  },
  {
    "timestamp": "14/03/25 4:00",
    "demand": 33632.62058,
    "solar_generated": 0,
    "wind_generated": 74.0412151,
    "marketplace_to_demand": 32.74275353,
    "grid_to_demand": 31634.02818,
    "govt_battery_charge": 59.13735147
  },
  {
    "timestamp": "14/03/25 5:00",
    "demand": 33885.32253,
    "solar_generated": 0,
    "wind_generated": 72.68309403,
    "marketplace_to_demand": 151.8645937,
    "grid_to_demand": 34060.97972,
    "govt_battery_charge": 23.18887387
  },
  {
    "timestamp": "14/03/25 6:00",
    "demand": 30457.63731,
    "solar_generated": 10.40588091,
    "wind_generated": 74.61111808,
    "marketplace_to_demand": 20.25201472,
    "grid_to_demand": 35488.03349,
    "govt_battery_charge": 23.17007501
  },
  {
    "timestamp": "14/03/25 7:00",
    "demand": 31378.99751,
    "solar_generated": 8.598747737,
    "wind_generated": 58.62983178,
    "marketplace_to_demand": 109.9041405,
    "grid_to_demand": 34690.30861,
    "govt_battery_charge": 76.51082613
  },
  {
    "timestamp": "14/03/25 8:00",
    "demand": 30636.10475,
    "solar_generated": 19.99780688,
    "wind_generated": 74.92263807,
    "marketplace_to_demand": 120.1365274,
    "grid_to_demand": 34847.55694,
    "govt_battery_charge": 2.986173917
  },
  {
    "timestamp": "14/03/25 9:00",
    "demand": 33047.15858,
    "solar_generated": 11.87791807,
    "wind_generated": 73.38353805,
    "marketplace_to_demand": 146.9694934,
    "grid_to_demand": 31572.78696,
    "govt_battery_charge": 16.0948469
  },
  {
    "timestamp": "14/03/25 10:00",
    "demand": 33655.47709,
    "solar_generated": 14.08227573,
    "wind_generated": 70.98247851,
    "marketplace_to_demand": 103.6648082,
    "grid_to_demand": 32354.47289,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "14/03/25 11:00",
    "demand": 36454.80047,
    "solar_generated": 23.49988474,
    "wind_generated": 75.82173884,
    "marketplace_to_demand": 118.6623189,
    "grid_to_demand": 31070.53676,
    "govt_battery_charge": 65.10676858
  },
  {
    "timestamp": "14/03/25 12:00",
    "demand": 30447.6334,
    "solar_generated": 18.28795304,
    "wind_generated": 69.98981778,
    "marketplace_to_demand": 143.1308506,
    "grid_to_demand": 33324.20558,
    "govt_battery_charge": 88.48418986
  },
  {
    "timestamp": "14/03/25 13:00",
    "demand": 36477.2854,
    "solar_generated": 13.68701577,
    "wind_generated": 76.7285627,
    "marketplace_to_demand": 64.60947715,
    "grid_to_demand": 34312.12593,
    "govt_battery_charge": 56.66915035
  },
  {
    "timestamp": "14/03/25 14:00",
    "demand": 33324.33462,
    "solar_generated": 16.08650297,
    "wind_generated": 77.38338624,
    "marketplace_to_demand": 199.0189558,
    "grid_to_demand": 32405.06817,
    "govt_battery_charge": 43.83865011
  },
  {
    "timestamp": "14/03/25 15:00",
    "demand": 30380.77574,
    "solar_generated": 15.14198884,
    "wind_generated": 55.0692372,
    "marketplace_to_demand": 146.5520238,
    "grid_to_demand": 35782.24947,
    "govt_battery_charge": 53.40134672
  },
  {
    "timestamp": "14/03/25 16:00",
    "demand": 34198.83627,
    "solar_generated": 16.38958127,
    "wind_generated": 57.42276287,
    "marketplace_to_demand": 125.3523444,
    "grid_to_demand": 34217.2875,
    "govt_battery_charge": 70.0041465
  },
  {
    "timestamp": "14/03/25 17:00",
    "demand": 30387.33785,
    "solar_generated": 11.34097456,
    "wind_generated": 66.14543934,
    "marketplace_to_demand": 101.5845643,
    "grid_to_demand": 34811.53395,
    "govt_battery_charge": 71.11719074
  },
  {
    "timestamp": "14/03/25 18:00",
    "demand": 30570.43473,
    "solar_generated": 0,
    "wind_generated": 64.52842763,
    "marketplace_to_demand": 57.58621656,
    "grid_to_demand": 35705.00857,
    "govt_battery_charge": 66.59843627
  },
  {
    "timestamp": "14/03/25 19:00",
    "demand": 34660.84895,
    "solar_generated": 0,
    "wind_generated": 66.83879464,
    "marketplace_to_demand": 40.33292512,
    "grid_to_demand": 35141.78714,
    "govt_battery_charge": 46.12770547
  },
  {
    "timestamp": "14/03/25 20:00",
    "demand": 30874.00001,
    "solar_generated": 0,
    "wind_generated": 74.24915625,
    "marketplace_to_demand": 156.0590652,
    "grid_to_demand": 34504.11308,
    "govt_battery_charge": 86.90030087
  },
  {
    "timestamp": "14/03/25 21:00",
    "demand": 31173.53342,
    "solar_generated": 0,
    "wind_generated": 68.93759053,
    "marketplace_to_demand": 46.61944176,
    "grid_to_demand": 30613.21696,
    "govt_battery_charge": 62.61919475
  },
  {
    "timestamp": "14/03/25 22:00",
    "demand": 30251.56902,
    "solar_generated": 0,
    "wind_generated": 67.66279601,
    "marketplace_to_demand": 113.7203503,
    "grid_to_demand": 32167.7926,
    "govt_battery_charge": 10.75883719
  },
  {
    "timestamp": "14/03/25 23:00",
    "demand": 34233.21033,
    "solar_generated": 0,
    "wind_generated": 67.90226003,
    "marketplace_to_demand": 143.4541801,
    "grid_to_demand": 32192.98213,
    "govt_battery_charge": 44.32105323
  },
  {
    "timestamp": "15/03/25 0:00",
    "demand": 35967.26884,
    "solar_generated": 0,
    "wind_generated": 79.99208246,
    "marketplace_to_demand": 182.3205065,
    "grid_to_demand": 31173.398,
    "govt_battery_charge": 38.65349901
  },
  {
    "timestamp": "15/03/25 1:00",
    "demand": 36506.14439,
    "solar_generated": 0,
    "wind_generated": 65.71187799,
    "marketplace_to_demand": 193.3933856,
    "grid_to_demand": 30287.53548,
    "govt_battery_charge": 2.286944457
  },
  {
    "timestamp": "15/03/25 2:00",
    "demand": 32570.41341,
    "solar_generated": 0,
    "wind_generated": 77.25882089,
    "marketplace_to_demand": 26.45063724,
    "grid_to_demand": 33391.11091,
    "govt_battery_charge": 89.92409944
  },
  {
    "timestamp": "15/03/25 3:00",
    "demand": 35924.90214,
    "solar_generated": 0,
    "wind_generated": 75.21584546,
    "marketplace_to_demand": 105.2847778,
    "grid_to_demand": 35308.92192,
    "govt_battery_charge": 57.23220105
  },
  {
    "timestamp": "15/03/25 4:00",
    "demand": 35474.76774,
    "solar_generated": 0,
    "wind_generated": 69.14395324,
    "marketplace_to_demand": 186.2359931,
    "grid_to_demand": 32884.5837,
    "govt_battery_charge": 69.53784544
  },
  {
    "timestamp": "15/03/25 5:00",
    "demand": 33539.94733,
    "solar_generated": 0,
    "wind_generated": 79.17864557,
    "marketplace_to_demand": 197.6564023,
    "grid_to_demand": 30538.76623,
    "govt_battery_charge": 76.89123882
  },
  {
    "timestamp": "15/03/25 6:00",
    "demand": 34307.01606,
    "solar_generated": 14.79155504,
    "wind_generated": 66.26280799,
    "marketplace_to_demand": 193.6183726,
    "grid_to_demand": 33389.3995,
    "govt_battery_charge": 73.079927
  },
  {
    "timestamp": "15/03/25 7:00",
    "demand": 35031.7735,
    "solar_generated": 21.52513071,
    "wind_generated": 53.79748144,
    "marketplace_to_demand": 79.46521986,
    "grid_to_demand": 33680.56349,
    "govt_battery_charge": 36.21253474
  },
  {
    "timestamp": "15/03/25 8:00",
    "demand": 36232.99706,
    "solar_generated": 23.49790059,
    "wind_generated": 62.21315123,
    "marketplace_to_demand": 198.4668625,
    "grid_to_demand": 30893.11638,
    "govt_battery_charge": 24.5955635
  },
  {
    "timestamp": "15/03/25 9:00",
    "demand": 34515.86928,
    "solar_generated": 19.51093032,
    "wind_generated": 75.47488387,
    "marketplace_to_demand": 130.4960744,
    "grid_to_demand": 30466.74575,
    "govt_battery_charge": 30.03170542
  },
  {
    "timestamp": "15/03/25 10:00",
    "demand": 34228.26194,
    "solar_generated": 19.88535141,
    "wind_generated": 68.76236767,
    "marketplace_to_demand": 101.1091074,
    "grid_to_demand": 34718.84228,
    "govt_battery_charge": 58.30231376
  },
  {
    "timestamp": "15/03/25 11:00",
    "demand": 34991.35355,
    "solar_generated": 17.67132573,
    "wind_generated": 68.66754017,
    "marketplace_to_demand": 109.1376039,
    "grid_to_demand": 34499.94958,
    "govt_battery_charge": 48.58232541
  },
  {
    "timestamp": "15/03/25 12:00",
    "demand": 32622.67473,
    "solar_generated": 8.146952089,
    "wind_generated": 55.63854186,
    "marketplace_to_demand": 179.5754945,
    "grid_to_demand": 30110.23463,
    "govt_battery_charge": 67.32371778
  },
  {
    "timestamp": "15/03/25 13:00",
    "demand": 30115.11576,
    "solar_generated": 13.09067497,
    "wind_generated": 64.94597107,
    "marketplace_to_demand": 157.9759651,
    "grid_to_demand": 35442.64545,
    "govt_battery_charge": 16.02073191
  },
  {
    "timestamp": "15/03/25 14:00",
    "demand": 36481.1169,
    "solar_generated": 22.56061471,
    "wind_generated": 74.83116811,
    "marketplace_to_demand": 111.6623747,
    "grid_to_demand": 34557.24387,
    "govt_battery_charge": 56.07021402
  },
  {
    "timestamp": "15/03/25 15:00",
    "demand": 34637.30592,
    "solar_generated": 23.48019073,
    "wind_generated": 59.64992572,
    "marketplace_to_demand": 156.7733022,
    "grid_to_demand": 36542.14901,
    "govt_battery_charge": 34.53713639
  },
  {
    "timestamp": "15/03/25 16:00",
    "demand": 31446.91181,
    "solar_generated": 19.60087908,
    "wind_generated": 76.62212695,
    "marketplace_to_demand": 1.726124847,
    "grid_to_demand": 33865.82403,
    "govt_battery_charge": 62.77272936
  },
  {
    "timestamp": "15/03/25 17:00",
    "demand": 32479.96348,
    "solar_generated": 20.42736864,
    "wind_generated": 63.94348962,
    "marketplace_to_demand": 14.18912494,
    "grid_to_demand": 30475.65384,
    "govt_battery_charge": 20.48612026
  },
  {
    "timestamp": "15/03/25 18:00",
    "demand": 31638.97416,
    "solar_generated": 0,
    "wind_generated": 73.76242331,
    "marketplace_to_demand": 151.4270888,
    "grid_to_demand": 36472.58234,
    "govt_battery_charge": 87.18458708
  },
  {
    "timestamp": "15/03/25 19:00",
    "demand": 35134.63795,
    "solar_generated": 0,
    "wind_generated": 74.33532009,
    "marketplace_to_demand": 26.96094341,
    "grid_to_demand": 36367.83653,
    "govt_battery_charge": 38.58713747
  },
  {
    "timestamp": "15/03/25 20:00",
    "demand": 34648.45719,
    "solar_generated": 0,
    "wind_generated": 64.41026845,
    "marketplace_to_demand": 120.480576,
    "grid_to_demand": 30449.60106,
    "govt_battery_charge": 19.23820906
  },
  {
    "timestamp": "15/03/25 21:00",
    "demand": 32519.5853,
    "solar_generated": 0,
    "wind_generated": 63.34366756,
    "marketplace_to_demand": 139.709919,
    "grid_to_demand": 32588.55064,
    "govt_battery_charge": 81.18049744
  },
  {
    "timestamp": "15/03/25 22:00",
    "demand": 30178.73198,
    "solar_generated": 0,
    "wind_generated": 70.79109279,
    "marketplace_to_demand": 6.721096758,
    "grid_to_demand": 36463.4123,
    "govt_battery_charge": 20.01195005
  },
  {
    "timestamp": "15/03/25 23:00",
    "demand": 34387.70386,
    "solar_generated": 0,
    "wind_generated": 80.04843466,
    "marketplace_to_demand": 3.492658851,
    "grid_to_demand": 36219.66897,
    "govt_battery_charge": 55.3567864
  },
  {
    "timestamp": "16/03/25 0:00",
    "demand": 36559.72938,
    "solar_generated": 0,
    "wind_generated": 67.17957184,
    "marketplace_to_demand": 105.3641804,
    "grid_to_demand": 35835.68408,
    "govt_battery_charge": 20.46737905
  },
  {
    "timestamp": "16/03/25 1:00",
    "demand": 31841.96746,
    "solar_generated": 0,
    "wind_generated": 79.52832651,
    "marketplace_to_demand": 145.6371611,
    "grid_to_demand": 30803.77186,
    "govt_battery_charge": 49.42314801
  },
  {
    "timestamp": "16/03/25 2:00",
    "demand": 30730.9786,
    "solar_generated": 0,
    "wind_generated": 62.41480531,
    "marketplace_to_demand": 140.2449778,
    "grid_to_demand": 36397.818,
    "govt_battery_charge": 12.63789974
  },
  {
    "timestamp": "16/03/25 3:00",
    "demand": 36082.60219,
    "solar_generated": 0,
    "wind_generated": 57.73525696,
    "marketplace_to_demand": 20.42958056,
    "grid_to_demand": 31694.38483,
    "govt_battery_charge": 99.2686086
  },
  {
    "timestamp": "16/03/25 4:00",
    "demand": 35296.76758,
    "solar_generated": 0,
    "wind_generated": 79.22344468,
    "marketplace_to_demand": 176.6724956,
    "grid_to_demand": 33882.04847,
    "govt_battery_charge": 56.84800104
  },
  {
    "timestamp": "16/03/25 5:00",
    "demand": 30782.19296,
    "solar_generated": 0,
    "wind_generated": 56.48581658,
    "marketplace_to_demand": 30.24884522,
    "grid_to_demand": 33829.2875,
    "govt_battery_charge": 83.52527438
  },
  {
    "timestamp": "16/03/25 6:00",
    "demand": 33924.92296,
    "solar_generated": 11.46960322,
    "wind_generated": 74.02041083,
    "marketplace_to_demand": 84.7791889,
    "grid_to_demand": 32409.69292,
    "govt_battery_charge": 13.56277989
  },
  {
    "timestamp": "16/03/25 7:00",
    "demand": 32925.77859,
    "solar_generated": 7.319360336,
    "wind_generated": 53.64563205,
    "marketplace_to_demand": 24.36536705,
    "grid_to_demand": 34363.74295,
    "govt_battery_charge": 21.60704545
  },
  {
    "timestamp": "16/03/25 8:00",
    "demand": 30520.83523,
    "solar_generated": 15.52673468,
    "wind_generated": 77.02245096,
    "marketplace_to_demand": 168.4210577,
    "grid_to_demand": 32043.01322,
    "govt_battery_charge": 76.25771163
  },
  {
    "timestamp": "16/03/25 9:00",
    "demand": 32622.3701,
    "solar_generated": 14.7759756,
    "wind_generated": 78.50493345,
    "marketplace_to_demand": 70.71515989,
    "grid_to_demand": 33710.65518,
    "govt_battery_charge": 3.123681695
  },
  {
    "timestamp": "16/03/25 10:00",
    "demand": 30151.46816,
    "solar_generated": 17.30916155,
    "wind_generated": 57.91528792,
    "marketplace_to_demand": 96.51648133,
    "grid_to_demand": 35925.95334,
    "govt_battery_charge": 30.02942402
  },
  {
    "timestamp": "16/03/25 11:00",
    "demand": 31989.14667,
    "solar_generated": 9.832671144,
    "wind_generated": 64.14638528,
    "marketplace_to_demand": 125.1096128,
    "grid_to_demand": 36397.72559,
    "govt_battery_charge": 64.61012613
  },
  {
    "timestamp": "16/03/25 12:00",
    "demand": 30825.12965,
    "solar_generated": 20.93569969,
    "wind_generated": 78.18188843,
    "marketplace_to_demand": 13.41720255,
    "grid_to_demand": 35137.02195,
    "govt_battery_charge": 8.928498214
  },
  {
    "timestamp": "16/03/25 13:00",
    "demand": 36195.30987,
    "solar_generated": 17.18392895,
    "wind_generated": 68.6449069,
    "marketplace_to_demand": 163.5488124,
    "grid_to_demand": 31996.03174,
    "govt_battery_charge": 54.00407699
  },
  {
    "timestamp": "16/03/25 14:00",
    "demand": 33741.51289,
    "solar_generated": 21.3427799,
    "wind_generated": 77.70305946,
    "marketplace_to_demand": 30.65778779,
    "grid_to_demand": 35584.96871,
    "govt_battery_charge": 64.91887004
  },
  {
    "timestamp": "16/03/25 15:00",
    "demand": 32165.40148,
    "solar_generated": 12.6804691,
    "wind_generated": 69.14356528,
    "marketplace_to_demand": 134.6859838,
    "grid_to_demand": 32942.02071,
    "govt_battery_charge": 48.299194
  },
  {
    "timestamp": "16/03/25 16:00",
    "demand": 34378.85688,
    "solar_generated": 18.04613038,
    "wind_generated": 59.54300423,
    "marketplace_to_demand": 71.12038939,
    "grid_to_demand": 34162.76448,
    "govt_battery_charge": 50.6129487
  },
  {
    "timestamp": "16/03/25 17:00",
    "demand": 35658.79456,
    "solar_generated": 15.37286922,
    "wind_generated": 74.59315728,
    "marketplace_to_demand": 88.26690674,
    "grid_to_demand": 33442.44115,
    "govt_battery_charge": 30.73299471
  },
  {
    "timestamp": "16/03/25 18:00",
    "demand": 36420.2801,
    "solar_generated": 0,
    "wind_generated": 78.42995904,
    "marketplace_to_demand": 5.060283603,
    "grid_to_demand": 35295.36333,
    "govt_battery_charge": 73.18215791
  },
  {
    "timestamp": "16/03/25 19:00",
    "demand": 34391.50094,
    "solar_generated": 0,
    "wind_generated": 55.40497007,
    "marketplace_to_demand": 83.79945672,
    "grid_to_demand": 35085.16031,
    "govt_battery_charge": 33.98092645
  },
  {
    "timestamp": "16/03/25 20:00",
    "demand": 33365.86555,
    "solar_generated": 0,
    "wind_generated": 61.23738372,
    "marketplace_to_demand": 64.59483491,
    "grid_to_demand": 33609.19963,
    "govt_battery_charge": 11.79496854
  },
  {
    "timestamp": "16/03/25 21:00",
    "demand": 30504.03014,
    "solar_generated": 0,
    "wind_generated": 71.10921876,
    "marketplace_to_demand": 76.73674336,
    "grid_to_demand": 35354.34259,
    "govt_battery_charge": 1.027230969
  },
  {
    "timestamp": "16/03/25 22:00",
    "demand": 32280.8422,
    "solar_generated": 0,
    "wind_generated": 69.54499972,
    "marketplace_to_demand": 12.26966184,
    "grid_to_demand": 36341.62798,
    "govt_battery_charge": 85.70876537
  },
  {
    "timestamp": "16/03/25 23:00",
    "demand": 32446.74083,
    "solar_generated": 0,
    "wind_generated": 60.40451551,
    "marketplace_to_demand": 38.91916692,
    "grid_to_demand": 35124.06074,
    "govt_battery_charge": 56.56678587
  },
  {
    "timestamp": "17/03/25 0:00",
    "demand": 30571.27831,
    "solar_generated": 0,
    "wind_generated": 54.03579259,
    "marketplace_to_demand": 187.6132177,
    "grid_to_demand": 36006.54822,
    "govt_battery_charge": 69.95063202
  },
  {
    "timestamp": "17/03/25 1:00",
    "demand": 32788.48974,
    "solar_generated": 0,
    "wind_generated": 68.17591555,
    "marketplace_to_demand": 72.09347366,
    "grid_to_demand": 31986.26357,
    "govt_battery_charge": 90.3277323
  },
  {
    "timestamp": "17/03/25 2:00",
    "demand": 35613.90617,
    "solar_generated": 0,
    "wind_generated": 60.57790594,
    "marketplace_to_demand": 173.7859313,
    "grid_to_demand": 34917.95532,
    "govt_battery_charge": 5.631293581
  },
  {
    "timestamp": "17/03/25 3:00",
    "demand": 34107.72071,
    "solar_generated": 0,
    "wind_generated": 60.95752666,
    "marketplace_to_demand": 85.04544787,
    "grid_to_demand": 32372.94831,
    "govt_battery_charge": 40.29784212
  },
  {
    "timestamp": "17/03/25 4:00",
    "demand": 35330.7642,
    "solar_generated": 0,
    "wind_generated": 58.159323,
    "marketplace_to_demand": 127.2219528,
    "grid_to_demand": 33584.39926,
    "govt_battery_charge": 71.73511324
  },
  {
    "timestamp": "17/03/25 5:00",
    "demand": 35817.08691,
    "solar_generated": 0,
    "wind_generated": 74.02449855,
    "marketplace_to_demand": 33.72991097,
    "grid_to_demand": 31648.80184,
    "govt_battery_charge": 70.34889802
  },
  {
    "timestamp": "17/03/25 6:00",
    "demand": 35174.64201,
    "solar_generated": 20.77822396,
    "wind_generated": 56.05644746,
    "marketplace_to_demand": 194.1753558,
    "grid_to_demand": 32043.97264,
    "govt_battery_charge": 3.654808069
  },
  {
    "timestamp": "17/03/25 7:00",
    "demand": 32310.10562,
    "solar_generated": 8.943069014,
    "wind_generated": 74.95616707,
    "marketplace_to_demand": 106.9431547,
    "grid_to_demand": 36418.46997,
    "govt_battery_charge": 96.74579394
  },
  {
    "timestamp": "17/03/25 8:00",
    "demand": 33449.7744,
    "solar_generated": 17.69800663,
    "wind_generated": 65.16383873,
    "marketplace_to_demand": 23.74385772,
    "grid_to_demand": 36159.71043,
    "govt_battery_charge": 88.51792718
  },
  {
    "timestamp": "17/03/25 9:00",
    "demand": 30012.57603,
    "solar_generated": 15.71841914,
    "wind_generated": 55.84094713,
    "marketplace_to_demand": 121.9576091,
    "grid_to_demand": 32091.58942,
    "govt_battery_charge": 5.353083894
  },
  {
    "timestamp": "17/03/25 10:00",
    "demand": 34950.63943,
    "solar_generated": 18.1376246,
    "wind_generated": 56.45571985,
    "marketplace_to_demand": 24.3218186,
    "grid_to_demand": 31738.95246,
    "govt_battery_charge": 86.21264015
  },
  {
    "timestamp": "17/03/25 11:00",
    "demand": 35844.48593,
    "solar_generated": 8.557224045,
    "wind_generated": 54.44652636,
    "marketplace_to_demand": 157.0361899,
    "grid_to_demand": 32429.63677,
    "govt_battery_charge": 30.08662619
  },
  {
    "timestamp": "17/03/25 12:00",
    "demand": 30264.71841,
    "solar_generated": 18.67191364,
    "wind_generated": 67.01744501,
    "marketplace_to_demand": 139.5911282,
    "grid_to_demand": 36376.15226,
    "govt_battery_charge": 93.6464279
  },
  {
    "timestamp": "17/03/25 13:00",
    "demand": 34714.48151,
    "solar_generated": 18.5159732,
    "wind_generated": 64.21963167,
    "marketplace_to_demand": 10.65182008,
    "grid_to_demand": 30838.3322,
    "govt_battery_charge": 82.28975315
  },
  {
    "timestamp": "17/03/25 14:00",
    "demand": 36191.16973,
    "solar_generated": 23.63594352,
    "wind_generated": 63.28557457,
    "marketplace_to_demand": 36.26046107,
    "grid_to_demand": 33107.33883,
    "govt_battery_charge": 16.90487815
  },
  {
    "timestamp": "17/03/25 15:00",
    "demand": 32178.20005,
    "solar_generated": 6.545549602,
    "wind_generated": 53.946982,
    "marketplace_to_demand": 37.64871675,
    "grid_to_demand": 35802.43211,
    "govt_battery_charge": 39.82150648
  },
  {
    "timestamp": "17/03/25 16:00",
    "demand": 31351.69921,
    "solar_generated": 18.39672294,
    "wind_generated": 62.32558603,
    "marketplace_to_demand": 126.6497082,
    "grid_to_demand": 31739.81172,
    "govt_battery_charge": 29.68323267
  },
  {
    "timestamp": "17/03/25 17:00",
    "demand": 31800.9707,
    "solar_generated": 7.170282204,
    "wind_generated": 67.24039162,
    "marketplace_to_demand": 102.1313109,
    "grid_to_demand": 35511.28307,
    "govt_battery_charge": 50.96953121
  },
  {
    "timestamp": "17/03/25 18:00",
    "demand": 32562.29972,
    "solar_generated": 0,
    "wind_generated": 77.75615269,
    "marketplace_to_demand": 66.4290758,
    "grid_to_demand": 36438.44418,
    "govt_battery_charge": 50.95431414
  },
  {
    "timestamp": "17/03/25 19:00",
    "demand": 31726.76721,
    "solar_generated": 0,
    "wind_generated": 56.92586681,
    "marketplace_to_demand": 7.400137333,
    "grid_to_demand": 34020.97819,
    "govt_battery_charge": 71.27516262
  },
  {
    "timestamp": "17/03/25 20:00",
    "demand": 32375.5525,
    "solar_generated": 0,
    "wind_generated": 61.14029077,
    "marketplace_to_demand": 91.34185272,
    "grid_to_demand": 32972.12739,
    "govt_battery_charge": 97.17585911
  },
  {
    "timestamp": "17/03/25 21:00",
    "demand": 32970.06076,
    "solar_generated": 0,
    "wind_generated": 76.88277055,
    "marketplace_to_demand": 140.6263135,
    "grid_to_demand": 31200.91599,
    "govt_battery_charge": 85.82475825
  },
  {
    "timestamp": "17/03/25 22:00",
    "demand": 34621.08652,
    "solar_generated": 0,
    "wind_generated": 73.58362085,
    "marketplace_to_demand": 64.69633385,
    "grid_to_demand": 30430.25502,
    "govt_battery_charge": 21.41447623
  },
  {
    "timestamp": "17/03/25 23:00",
    "demand": 30002.2919,
    "solar_generated": 0,
    "wind_generated": 57.69972982,
    "marketplace_to_demand": 136.7624748,
    "grid_to_demand": 32846.82664,
    "govt_battery_charge": 61.50782824
  },
  {
    "timestamp": "18/03/25 0:00",
    "demand": 33396.20412,
    "solar_generated": 0,
    "wind_generated": 80.26396276,
    "marketplace_to_demand": 62.26320546,
    "grid_to_demand": 32998.02575,
    "govt_battery_charge": 94.77525367
  },
  {
    "timestamp": "18/03/25 1:00",
    "demand": 33745.00896,
    "solar_generated": 0,
    "wind_generated": 80.11890574,
    "marketplace_to_demand": 32.03359502,
    "grid_to_demand": 36108.38424,
    "govt_battery_charge": 22.87035074
  },
  {
    "timestamp": "18/03/25 2:00",
    "demand": 34513.07083,
    "solar_generated": 0,
    "wind_generated": 65.50194425,
    "marketplace_to_demand": 118.2757215,
    "grid_to_demand": 36131.60902,
    "govt_battery_charge": 97.14585115
  },
  {
    "timestamp": "18/03/25 3:00",
    "demand": 34516.53455,
    "solar_generated": 0,
    "wind_generated": 62.86399116,
    "marketplace_to_demand": 90.1809903,
    "grid_to_demand": 30226.04036,
    "govt_battery_charge": 52.76785188
  },
  {
    "timestamp": "18/03/25 4:00",
    "demand": 31055.44649,
    "solar_generated": 0,
    "wind_generated": 65.98611594,
    "marketplace_to_demand": 191.2831953,
    "grid_to_demand": 31495.79293,
    "govt_battery_charge": 12.54055236
  },
  {
    "timestamp": "18/03/25 5:00",
    "demand": 36005.10075,
    "solar_generated": 0,
    "wind_generated": 79.30414616,
    "marketplace_to_demand": 150.3417702,
    "grid_to_demand": 30630.08713,
    "govt_battery_charge": 83.43191008
  },
  {
    "timestamp": "18/03/25 6:00",
    "demand": 34060.4603,
    "solar_generated": 18.12343808,
    "wind_generated": 55.01674951,
    "marketplace_to_demand": 18.38799052,
    "grid_to_demand": 34162.58823,
    "govt_battery_charge": 69.96503612
  },
  {
    "timestamp": "18/03/25 7:00",
    "demand": 31618.61702,
    "solar_generated": 18.76206698,
    "wind_generated": 66.68341379,
    "marketplace_to_demand": 21.35895947,
    "grid_to_demand": 33977.10316,
    "govt_battery_charge": 91.23230719
  },
  {
    "timestamp": "18/03/25 8:00",
    "demand": 33757.83322,
    "solar_generated": 9.45276834,
    "wind_generated": 67.46343161,
    "marketplace_to_demand": 113.3388292,
    "grid_to_demand": 32855.03585,
    "govt_battery_charge": 34.1891714
  },
  {
    "timestamp": "18/03/25 9:00",
    "demand": 35553.96385,
    "solar_generated": 10.53997425,
    "wind_generated": 68.5127603,
    "marketplace_to_demand": 96.51191868,
    "grid_to_demand": 32185.01053,
    "govt_battery_charge": 2.904717164
  },
  {
    "timestamp": "18/03/25 10:00",
    "demand": 30716.99849,
    "solar_generated": 18.93418843,
    "wind_generated": 74.43002216,
    "marketplace_to_demand": 25.87963854,
    "grid_to_demand": 32943.50357,
    "govt_battery_charge": 15.80536632
  },
  {
    "timestamp": "18/03/25 11:00",
    "demand": 31272.33474,
    "solar_generated": 18.58042537,
    "wind_generated": 60.87503061,
    "marketplace_to_demand": 166.6439229,
    "grid_to_demand": 29976.1532,
    "govt_battery_charge": 14.54816854
  },
  {
    "timestamp": "18/03/25 12:00",
    "demand": 31510.12769,
    "solar_generated": 9.425046231,
    "wind_generated": 64.69407749,
    "marketplace_to_demand": 106.1553081,
    "grid_to_demand": 31511.06313,
    "govt_battery_charge": 62.98737398
  },
  {
    "timestamp": "18/03/25 13:00",
    "demand": 34927.15027,
    "solar_generated": 15.83388749,
    "wind_generated": 57.01561191,
    "marketplace_to_demand": 18.34364122,
    "grid_to_demand": 35982.70166,
    "govt_battery_charge": 93.8067728
  },
  {
    "timestamp": "18/03/25 14:00",
    "demand": 32635.328,
    "solar_generated": 12.80042933,
    "wind_generated": 70.48075219,
    "marketplace_to_demand": 109.8294157,
    "grid_to_demand": 31057.05356,
    "govt_battery_charge": 11.95606634
  },
  {
    "timestamp": "18/03/25 15:00",
    "demand": 34306.15652,
    "solar_generated": 18.00256385,
    "wind_generated": 68.66479831,
    "marketplace_to_demand": 30.14593756,
    "grid_to_demand": 34375.47228,
    "govt_battery_charge": 12.74534727
  },
  {
    "timestamp": "18/03/25 16:00",
    "demand": 36001.24256,
    "solar_generated": 13.29135064,
    "wind_generated": 63.28420954,
    "marketplace_to_demand": 29.25269444,
    "grid_to_demand": 31464.57023,
    "govt_battery_charge": 33.48635668
  },
  {
    "timestamp": "18/03/25 17:00",
    "demand": 33559.25773,
    "solar_generated": 9.211698711,
    "wind_generated": 57.29096308,
    "marketplace_to_demand": 116.258354,
    "grid_to_demand": 33197.44179,
    "govt_battery_charge": 22.71904893
  },
  {
    "timestamp": "18/03/25 18:00",
    "demand": 35970.57118,
    "solar_generated": 0,
    "wind_generated": 68.45286709,
    "marketplace_to_demand": 68.45370451,
    "grid_to_demand": 32869.36086,
    "govt_battery_charge": 75.82827328
  },
  {
    "timestamp": "18/03/25 19:00",
    "demand": 30867.4535,
    "solar_generated": 0,
    "wind_generated": 68.49265725,
    "marketplace_to_demand": 94.10719696,
    "grid_to_demand": 30587.92925,
    "govt_battery_charge": 36.54705173
  },
  {
    "timestamp": "18/03/25 20:00",
    "demand": 36376.02644,
    "solar_generated": 0,
    "wind_generated": 67.06076163,
    "marketplace_to_demand": 86.42830278,
    "grid_to_demand": 34114.82441,
    "govt_battery_charge": 31.49238394
  },
  {
    "timestamp": "18/03/25 21:00",
    "demand": 33508.90092,
    "solar_generated": 0,
    "wind_generated": 57.60781604,
    "marketplace_to_demand": 46.49171303,
    "grid_to_demand": 31658.68059,
    "govt_battery_charge": 52.04711408
  },
  {
    "timestamp": "18/03/25 22:00",
    "demand": 35768.43269,
    "solar_generated": 0,
    "wind_generated": 73.26797144,
    "marketplace_to_demand": 31.19609122,
    "grid_to_demand": 34768.12781,
    "govt_battery_charge": 68.42734636
  },
  {
    "timestamp": "18/03/25 23:00",
    "demand": 35178.91422,
    "solar_generated": 0,
    "wind_generated": 55.14136068,
    "marketplace_to_demand": 103.6152589,
    "grid_to_demand": 31761.54581,
    "govt_battery_charge": 85.69454641
  },
  {
    "timestamp": "19/03/25 0:00",
    "demand": 35048.98891,
    "solar_generated": 0,
    "wind_generated": 61.87383745,
    "marketplace_to_demand": 65.83187113,
    "grid_to_demand": 30040.02439,
    "govt_battery_charge": 44.35086561
  },
  {
    "timestamp": "19/03/25 1:00",
    "demand": 35555.45117,
    "solar_generated": 0,
    "wind_generated": 67.11729351,
    "marketplace_to_demand": 53.92347659,
    "grid_to_demand": 31954.5368,
    "govt_battery_charge": 73.68344649
  },
  {
    "timestamp": "19/03/25 2:00",
    "demand": 30163.29003,
    "solar_generated": 0,
    "wind_generated": 78.77656014,
    "marketplace_to_demand": 22.80381784,
    "grid_to_demand": 30242.15591,
    "govt_battery_charge": 50.92049041
  },
  {
    "timestamp": "19/03/25 3:00",
    "demand": 33753.6156,
    "solar_generated": 0,
    "wind_generated": 58.16962593,
    "marketplace_to_demand": 187.6678414,
    "grid_to_demand": 35326.61052,
    "govt_battery_charge": 84.86812962
  },
  {
    "timestamp": "19/03/25 4:00",
    "demand": 32781.1523,
    "solar_generated": 0,
    "wind_generated": 57.08088529,
    "marketplace_to_demand": 74.79416936,
    "grid_to_demand": 31539.25563,
    "govt_battery_charge": 8.455747301
  },
  {
    "timestamp": "19/03/25 5:00",
    "demand": 33412.39388,
    "solar_generated": 0,
    "wind_generated": 69.15544364,
    "marketplace_to_demand": 174.792444,
    "grid_to_demand": 33207.96917,
    "govt_battery_charge": 55.53496426
  },
  {
    "timestamp": "19/03/25 6:00",
    "demand": 32383.75478,
    "solar_generated": 13.93041631,
    "wind_generated": 58.824661,
    "marketplace_to_demand": 71.01522615,
    "grid_to_demand": 35277.20076,
    "govt_battery_charge": 51.0003293
  },
  {
    "timestamp": "19/03/25 7:00",
    "demand": 32368.69914,
    "solar_generated": 10.30320432,
    "wind_generated": 67.122097,
    "marketplace_to_demand": 124.605784,
    "grid_to_demand": 30415.49791,
    "govt_battery_charge": 17.52118524
  },
  {
    "timestamp": "19/03/25 8:00",
    "demand": 33155.71524,
    "solar_generated": 11.97942992,
    "wind_generated": 76.44625261,
    "marketplace_to_demand": 120.9982782,
    "grid_to_demand": 31491.28242,
    "govt_battery_charge": 21.94932306
  },
  {
    "timestamp": "19/03/25 9:00",
    "demand": 32419.84451,
    "solar_generated": 12.35981539,
    "wind_generated": 73.5283982,
    "marketplace_to_demand": 70.13380875,
    "grid_to_demand": 30442.49546,
    "govt_battery_charge": 15.66921816
  },
  {
    "timestamp": "19/03/25 10:00",
    "demand": 34928.20648,
    "solar_generated": 16.22504341,
    "wind_generated": 53.78431481,
    "marketplace_to_demand": 116.4749246,
    "grid_to_demand": 33606.4896,
    "govt_battery_charge": 3.815736547
  },
  {
    "timestamp": "19/03/25 11:00",
    "demand": 34728.19101,
    "solar_generated": 19.32895991,
    "wind_generated": 71.45860352,
    "marketplace_to_demand": 102.4576753,
    "grid_to_demand": 34877.75845,
    "govt_battery_charge": 2.023328262
  },
  {
    "timestamp": "19/03/25 12:00",
    "demand": 35085.29418,
    "solar_generated": 21.49233519,
    "wind_generated": 73.02128858,
    "marketplace_to_demand": 187.5295895,
    "grid_to_demand": 31648.35452,
    "govt_battery_charge": 96.79876566
  },
  {
    "timestamp": "19/03/25 13:00",
    "demand": 31893.9014,
    "solar_generated": 21.37012699,
    "wind_generated": 73.73168759,
    "marketplace_to_demand": 91.07028082,
    "grid_to_demand": 32031.04448,
    "govt_battery_charge": 57.60410071
  },
  {
    "timestamp": "19/03/25 14:00",
    "demand": 36155.04268,
    "solar_generated": 23.02787416,
    "wind_generated": 70.29488496,
    "marketplace_to_demand": 9.560492816,
    "grid_to_demand": 32107.19995,
    "govt_battery_charge": 26.23157225
  },
  {
    "timestamp": "19/03/25 15:00",
    "demand": 35009.85303,
    "solar_generated": 14.9791603,
    "wind_generated": 63.92418472,
    "marketplace_to_demand": 160.6840335,
    "grid_to_demand": 32863.36311,
    "govt_battery_charge": 56.55883753
  },
  {
    "timestamp": "19/03/25 16:00",
    "demand": 33235.01109,
    "solar_generated": 19.69399279,
    "wind_generated": 61.63277198,
    "marketplace_to_demand": 180.2374533,
    "grid_to_demand": 32881.30444,
    "govt_battery_charge": 47.16994604
  },
  {
    "timestamp": "19/03/25 17:00",
    "demand": 32825.57913,
    "solar_generated": 7.5045058,
    "wind_generated": 57.20132851,
    "marketplace_to_demand": 51.14723536,
    "grid_to_demand": 32552.04525,
    "govt_battery_charge": 11.79775944
  },
  {
    "timestamp": "19/03/25 18:00",
    "demand": 30638.98344,
    "solar_generated": 0,
    "wind_generated": 70.44360666,
    "marketplace_to_demand": 52.50604789,
    "grid_to_demand": 36147.82023,
    "govt_battery_charge": 84.45952934
  },
  {
    "timestamp": "19/03/25 19:00",
    "demand": 33423.47411,
    "solar_generated": 0,
    "wind_generated": 67.98299155,
    "marketplace_to_demand": 134.7401018,
    "grid_to_demand": 30441.12316,
    "govt_battery_charge": 49.24418911
  },
  {
    "timestamp": "19/03/25 20:00",
    "demand": 32261.86329,
    "solar_generated": 0,
    "wind_generated": 73.27832496,
    "marketplace_to_demand": 168.6065535,
    "grid_to_demand": 32999.8693,
    "govt_battery_charge": 44.01825794
  },
  {
    "timestamp": "19/03/25 21:00",
    "demand": 34244.678,
    "solar_generated": 0,
    "wind_generated": 74.03108469,
    "marketplace_to_demand": 93.61306397,
    "grid_to_demand": 35529.78754,
    "govt_battery_charge": 68.40509486
  },
  {
    "timestamp": "19/03/25 22:00",
    "demand": 31112.39147,
    "solar_generated": 0,
    "wind_generated": 55.97253491,
    "marketplace_to_demand": 128.8076617,
    "grid_to_demand": 34918.4573,
    "govt_battery_charge": 83.19354095
  },
  {
    "timestamp": "19/03/25 23:00",
    "demand": 29979.41737,
    "solar_generated": 0,
    "wind_generated": 68.48325978,
    "marketplace_to_demand": 125.7758011,
    "grid_to_demand": 30987.34956,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "20/03/25 0:00",
    "demand": 35524.16891,
    "solar_generated": 0,
    "wind_generated": 62.20329311,
    "marketplace_to_demand": 161.9406748,
    "grid_to_demand": 33641.91817,
    "govt_battery_charge": 4.388527554
  },
  {
    "timestamp": "20/03/25 1:00",
    "demand": 32310.98698,
    "solar_generated": 0,
    "wind_generated": 75.67245574,
    "marketplace_to_demand": 3.105751256,
    "grid_to_demand": 35795.49045,
    "govt_battery_charge": 93.83756482
  },
  {
    "timestamp": "20/03/25 2:00",
    "demand": 36390.01181,
    "solar_generated": 0,
    "wind_generated": 64.81272252,
    "marketplace_to_demand": 12.15322141,
    "grid_to_demand": 33811.60583,
    "govt_battery_charge": 28.47188986
  },
  {
    "timestamp": "20/03/25 3:00",
    "demand": 32629.82184,
    "solar_generated": 0,
    "wind_generated": 67.83264955,
    "marketplace_to_demand": 89.54273771,
    "grid_to_demand": 32988.38692,
    "govt_battery_charge": 16.17034953
  },
  {
    "timestamp": "20/03/25 4:00",
    "demand": 35168.90459,
    "solar_generated": 0,
    "wind_generated": 61.60582583,
    "marketplace_to_demand": 50.46988394,
    "grid_to_demand": 32172.09784,
    "govt_battery_charge": 53.15206722
  },
  {
    "timestamp": "20/03/25 5:00",
    "demand": 31377.54039,
    "solar_generated": 0,
    "wind_generated": 68.63076027,
    "marketplace_to_demand": 125.9495715,
    "grid_to_demand": 30523.87531,
    "govt_battery_charge": 19.22084471
  },
  {
    "timestamp": "20/03/25 6:00",
    "demand": 32411.75843,
    "solar_generated": 20.70069047,
    "wind_generated": 71.42080948,
    "marketplace_to_demand": 77.74761731,
    "grid_to_demand": 30930.07612,
    "govt_battery_charge": 77.63935972
  },
  {
    "timestamp": "20/03/25 7:00",
    "demand": 32835.40889,
    "solar_generated": 6.710924956,
    "wind_generated": 56.46310205,
    "marketplace_to_demand": 41.75803398,
    "grid_to_demand": 31298.99944,
    "govt_battery_charge": 93.84694733
  },
  {
    "timestamp": "20/03/25 8:00",
    "demand": 32045.9123,
    "solar_generated": 9.364941216,
    "wind_generated": 68.50242552,
    "marketplace_to_demand": 84.33070961,
    "grid_to_demand": 31098.0227,
    "govt_battery_charge": 71.38421475
  },
  {
    "timestamp": "20/03/25 9:00",
    "demand": 32237.73154,
    "solar_generated": 14.39315445,
    "wind_generated": 67.12218591,
    "marketplace_to_demand": 8.279294391,
    "grid_to_demand": 34377.98086,
    "govt_battery_charge": 47.07206159
  },
  {
    "timestamp": "20/03/25 10:00",
    "demand": 31228.80069,
    "solar_generated": 21.64212643,
    "wind_generated": 55.70432321,
    "marketplace_to_demand": 64.17848616,
    "grid_to_demand": 34095.10137,
    "govt_battery_charge": 33.85055724
  },
  {
    "timestamp": "20/03/25 11:00",
    "demand": 30830.99812,
    "solar_generated": 14.1454353,
    "wind_generated": 66.19548756,
    "marketplace_to_demand": 28.56713774,
    "grid_to_demand": 31486.47583,
    "govt_battery_charge": 66.51128352
  },
  {
    "timestamp": "20/03/25 12:00",
    "demand": 31056.28116,
    "solar_generated": 17.45772419,
    "wind_generated": 61.35188693,
    "marketplace_to_demand": 53.57326401,
    "grid_to_demand": 36281.18586,
    "govt_battery_charge": 50.71669696
  },
  {
    "timestamp": "20/03/25 13:00",
    "demand": 35526.40205,
    "solar_generated": 15.71939928,
    "wind_generated": 64.70209806,
    "marketplace_to_demand": 199.2297522,
    "grid_to_demand": 31785.9852,
    "govt_battery_charge": 13.35374673
  },
  {
    "timestamp": "20/03/25 14:00",
    "demand": 33569.20538,
    "solar_generated": 16.7665092,
    "wind_generated": 79.3007416,
    "marketplace_to_demand": 189.7075685,
    "grid_to_demand": 34834.25691,
    "govt_battery_charge": 29.16836741
  },
  {
    "timestamp": "20/03/25 15:00",
    "demand": 31179.63271,
    "solar_generated": 10.95352944,
    "wind_generated": 58.30298121,
    "marketplace_to_demand": 40.76432654,
    "grid_to_demand": 31314.96394,
    "govt_battery_charge": 41.54773595
  },
  {
    "timestamp": "20/03/25 16:00",
    "demand": 35776.60924,
    "solar_generated": 22.54832884,
    "wind_generated": 73.38198434,
    "marketplace_to_demand": 147.0775153,
    "grid_to_demand": 35308.90661,
    "govt_battery_charge": 46.2666165
  },
  {
    "timestamp": "20/03/25 17:00",
    "demand": 32520.24017,
    "solar_generated": 14.77329894,
    "wind_generated": 66.62413783,
    "marketplace_to_demand": 53.53068661,
    "grid_to_demand": 31130.43037,
    "govt_battery_charge": 88.93460649
  },
  {
    "timestamp": "20/03/25 18:00",
    "demand": 35947.50795,
    "solar_generated": 0,
    "wind_generated": 67.36585608,
    "marketplace_to_demand": 52.56111773,
    "grid_to_demand": 34393.43016,
    "govt_battery_charge": 93.34769202
  },
  {
    "timestamp": "20/03/25 19:00",
    "demand": 35248.22256,
    "solar_generated": 0,
    "wind_generated": 76.88174656,
    "marketplace_to_demand": 5.768677341,
    "grid_to_demand": 34441.16784,
    "govt_battery_charge": 69.49700625
  },
  {
    "timestamp": "20/03/25 20:00",
    "demand": 33722.56897,
    "solar_generated": 0,
    "wind_generated": 72.37800774,
    "marketplace_to_demand": 37.82393228,
    "grid_to_demand": 33422.69113,
    "govt_battery_charge": 98.8747471
  },
  {
    "timestamp": "20/03/25 21:00",
    "demand": 35975.39866,
    "solar_generated": 0,
    "wind_generated": 63.56007059,
    "marketplace_to_demand": 163.1093378,
    "grid_to_demand": 34433.27837,
    "govt_battery_charge": 80.56442735
  },
  {
    "timestamp": "20/03/25 22:00",
    "demand": 36101.50666,
    "solar_generated": 0,
    "wind_generated": 72.19027643,
    "marketplace_to_demand": 115.4075855,
    "grid_to_demand": 31574.89001,
    "govt_battery_charge": 21.51627745
  },
  {
    "timestamp": "20/03/25 23:00",
    "demand": 31790.40367,
    "solar_generated": 0,
    "wind_generated": 58.5491961,
    "marketplace_to_demand": 31.99084177,
    "grid_to_demand": 31892.02347,
    "govt_battery_charge": 25.18807968
  },
  {
    "timestamp": "21/03/25 0:00",
    "demand": 30149.29005,
    "solar_generated": 0,
    "wind_generated": 69.10812565,
    "marketplace_to_demand": 17.15042949,
    "grid_to_demand": 36438.81535,
    "govt_battery_charge": 21.91635168
  },
  {
    "timestamp": "21/03/25 1:00",
    "demand": 32448.04953,
    "solar_generated": 0,
    "wind_generated": 71.46819298,
    "marketplace_to_demand": 30.72427254,
    "grid_to_demand": 32989.79344,
    "govt_battery_charge": 4.614152463
  },
  {
    "timestamp": "21/03/25 2:00",
    "demand": 34575.34069,
    "solar_generated": 0,
    "wind_generated": 73.04455885,
    "marketplace_to_demand": 61.72125823,
    "grid_to_demand": 30124.32748,
    "govt_battery_charge": 18.20150499
  },
  {
    "timestamp": "21/03/25 3:00",
    "demand": 33511.35142,
    "solar_generated": 0,
    "wind_generated": 76.78059445,
    "marketplace_to_demand": 133.7242989,
    "grid_to_demand": 31825.07487,
    "govt_battery_charge": 9.197145939
  },
  {
    "timestamp": "21/03/25 4:00",
    "demand": 33495.63902,
    "solar_generated": 0,
    "wind_generated": 60.30045105,
    "marketplace_to_demand": 31.33024682,
    "grid_to_demand": 33258.84123,
    "govt_battery_charge": 50.55642578
  },
  {
    "timestamp": "21/03/25 5:00",
    "demand": 31099.60837,
    "solar_generated": 0,
    "wind_generated": 57.38229672,
    "marketplace_to_demand": 38.93861227,
    "grid_to_demand": 31501.49668,
    "govt_battery_charge": 10.44186047
  },
  {
    "timestamp": "21/03/25 6:00",
    "demand": 34358.46992,
    "solar_generated": 23.0678925,
    "wind_generated": 62.69181401,
    "marketplace_to_demand": 99.96832332,
    "grid_to_demand": 35615.27143,
    "govt_battery_charge": 45.40078719
  },
  {
    "timestamp": "21/03/25 7:00",
    "demand": 30403.01375,
    "solar_generated": 23.09030134,
    "wind_generated": 58.7649793,
    "marketplace_to_demand": 22.48497557,
    "grid_to_demand": 34796.3411,
    "govt_battery_charge": 61.65757068
  },
  {
    "timestamp": "21/03/25 8:00",
    "demand": 35928.58103,
    "solar_generated": 20.58471301,
    "wind_generated": 62.67306478,
    "marketplace_to_demand": 79.40661834,
    "grid_to_demand": 32294.43887,
    "govt_battery_charge": 83.19989166
  },
  {
    "timestamp": "21/03/25 9:00",
    "demand": 35838.03566,
    "solar_generated": 23.0019488,
    "wind_generated": 61.91508,
    "marketplace_to_demand": 76.08557657,
    "grid_to_demand": 34786.72237,
    "govt_battery_charge": 17.31608955
  },
  {
    "timestamp": "21/03/25 10:00",
    "demand": 33561.57007,
    "solar_generated": 20.80130986,
    "wind_generated": 69.70108494,
    "marketplace_to_demand": 133.8328182,
    "grid_to_demand": 35349.67898,
    "govt_battery_charge": 81.53656481
  },
  {
    "timestamp": "21/03/25 11:00",
    "demand": 34447.38689,
    "solar_generated": 12.11909372,
    "wind_generated": 56.474956,
    "marketplace_to_demand": 68.03679525,
    "grid_to_demand": 33107.65413,
    "govt_battery_charge": 99.71469991
  },
  {
    "timestamp": "21/03/25 12:00",
    "demand": 30647.73208,
    "solar_generated": 22.90494488,
    "wind_generated": 79.86338388,
    "marketplace_to_demand": 194.4921203,
    "grid_to_demand": 33785.03541,
    "govt_battery_charge": 33.8588612
  },
  {
    "timestamp": "21/03/25 13:00",
    "demand": 33881.15101,
    "solar_generated": 11.28246126,
    "wind_generated": 67.38119547,
    "marketplace_to_demand": 149.0768703,
    "grid_to_demand": 31542.60998,
    "govt_battery_charge": 26.97044359
  },
  {
    "timestamp": "21/03/25 14:00",
    "demand": 34521.61888,
    "solar_generated": 23.53600462,
    "wind_generated": 77.73771342,
    "marketplace_to_demand": 27.68479061,
    "grid_to_demand": 35660.28454,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "21/03/25 15:00",
    "demand": 30282.96407,
    "solar_generated": 23.42457281,
    "wind_generated": 70.46603701,
    "marketplace_to_demand": 70.77987012,
    "grid_to_demand": 30306.17157,
    "govt_battery_charge": 97.41065501
  },
  {
    "timestamp": "21/03/25 16:00",
    "demand": 36133.22183,
    "solar_generated": 7.526076412,
    "wind_generated": 60.78624458,
    "marketplace_to_demand": 99.02811173,
    "grid_to_demand": 32739.10734,
    "govt_battery_charge": 56.6597424
  },
  {
    "timestamp": "21/03/25 17:00",
    "demand": 34444.04237,
    "solar_generated": 12.3504621,
    "wind_generated": 60.61309907,
    "marketplace_to_demand": 0.506995002,
    "grid_to_demand": 34368.96629,
    "govt_battery_charge": 13.35848323
  },
  {
    "timestamp": "21/03/25 18:00",
    "demand": 34142.63643,
    "solar_generated": 0,
    "wind_generated": 63.55624027,
    "marketplace_to_demand": 21.38663359,
    "grid_to_demand": 35180.50933,
    "govt_battery_charge": 99.21178395
  },
  {
    "timestamp": "21/03/25 19:00",
    "demand": 35774.63474,
    "solar_generated": 0,
    "wind_generated": 58.22813048,
    "marketplace_to_demand": 57.33466927,
    "grid_to_demand": 34846.64995,
    "govt_battery_charge": 70.72094576
  },
  {
    "timestamp": "21/03/25 20:00",
    "demand": 33316.15841,
    "solar_generated": 0,
    "wind_generated": 70.19560169,
    "marketplace_to_demand": 163.5164935,
    "grid_to_demand": 36492.60394,
    "govt_battery_charge": 6.843145147
  },
  {
    "timestamp": "21/03/25 21:00",
    "demand": 33011.29939,
    "solar_generated": 0,
    "wind_generated": 73.25474218,
    "marketplace_to_demand": 19.3553275,
    "grid_to_demand": 35516.85966,
    "govt_battery_charge": 53.42073201
  },
  {
    "timestamp": "21/03/25 22:00",
    "demand": 31386.57576,
    "solar_generated": 0,
    "wind_generated": 72.07231441,
    "marketplace_to_demand": 131.126089,
    "grid_to_demand": 34539.65535,
    "govt_battery_charge": 25.64805015
  },
  {
    "timestamp": "21/03/25 23:00",
    "demand": 35798.0466,
    "solar_generated": 0,
    "wind_generated": 77.66717117,
    "marketplace_to_demand": 13.94265348,
    "grid_to_demand": 32932.59522,
    "govt_battery_charge": 42.6194618
  },
  {
    "timestamp": "22/03/25 0:00",
    "demand": 33344.41586,
    "solar_generated": 0,
    "wind_generated": 79.70110442,
    "marketplace_to_demand": 70.5531971,
    "grid_to_demand": 34047.86297,
    "govt_battery_charge": 93.50981593
  },
  {
    "timestamp": "22/03/25 1:00",
    "demand": 34565.15128,
    "solar_generated": 0,
    "wind_generated": 59.219919,
    "marketplace_to_demand": 47.8890617,
    "grid_to_demand": 36045.24089,
    "govt_battery_charge": 71.90871147
  },
  {
    "timestamp": "22/03/25 2:00",
    "demand": 32086.23569,
    "solar_generated": 0,
    "wind_generated": 76.73994569,
    "marketplace_to_demand": 143.6657654,
    "grid_to_demand": 30115.07566,
    "govt_battery_charge": 97.43497095
  },
  {
    "timestamp": "22/03/25 3:00",
    "demand": 35692.5212,
    "solar_generated": 0,
    "wind_generated": 56.38229458,
    "marketplace_to_demand": 74.13370052,
    "grid_to_demand": 31348.39089,
    "govt_battery_charge": 66.83603636
  },
  {
    "timestamp": "22/03/25 4:00",
    "demand": 33140.82792,
    "solar_generated": 0,
    "wind_generated": 63.93692654,
    "marketplace_to_demand": 139.5989578,
    "grid_to_demand": 35929.58955,
    "govt_battery_charge": 15.17704532
  },
  {
    "timestamp": "22/03/25 5:00",
    "demand": 32153.01905,
    "solar_generated": 0,
    "wind_generated": 53.6290562,
    "marketplace_to_demand": 163.8425104,
    "grid_to_demand": 33050.35326,
    "govt_battery_charge": 90.9460859
  },
  {
    "timestamp": "22/03/25 6:00",
    "demand": 32277.05938,
    "solar_generated": 14.23140108,
    "wind_generated": 65.05523793,
    "marketplace_to_demand": 10.80279942,
    "grid_to_demand": 31729.72748,
    "govt_battery_charge": 92.78402943
  },
  {
    "timestamp": "22/03/25 7:00",
    "demand": 31395.54809,
    "solar_generated": 16.3589281,
    "wind_generated": 53.71999116,
    "marketplace_to_demand": 192.9672168,
    "grid_to_demand": 33885.15246,
    "govt_battery_charge": 89.4516012
  },
  {
    "timestamp": "22/03/25 8:00",
    "demand": 32379.79208,
    "solar_generated": 15.48512075,
    "wind_generated": 54.01873113,
    "marketplace_to_demand": 160.9123505,
    "grid_to_demand": 31683.37562,
    "govt_battery_charge": 5.004681558
  },
  {
    "timestamp": "22/03/25 9:00",
    "demand": 32326.23064,
    "solar_generated": 11.07325311,
    "wind_generated": 68.32703201,
    "marketplace_to_demand": 59.06407591,
    "grid_to_demand": 33108.8943,
    "govt_battery_charge": 8.536943733
  },
  {
    "timestamp": "22/03/25 10:00",
    "demand": 33683.39798,
    "solar_generated": 20.0888643,
    "wind_generated": 56.42943541,
    "marketplace_to_demand": 153.5148428,
    "grid_to_demand": 35339.49066,
    "govt_battery_charge": 34.59075267
  },
  {
    "timestamp": "22/03/25 11:00",
    "demand": 31102.33317,
    "solar_generated": 10.35796826,
    "wind_generated": 59.13582676,
    "marketplace_to_demand": 106.9226198,
    "grid_to_demand": 30479.962,
    "govt_battery_charge": 96.01489449
  },
  {
    "timestamp": "22/03/25 12:00",
    "demand": 31159.8333,
    "solar_generated": 23.8126232,
    "wind_generated": 54.16169809,
    "marketplace_to_demand": 124.958314,
    "grid_to_demand": 31140.22735,
    "govt_battery_charge": 69.37627721
  },
  {
    "timestamp": "22/03/25 13:00",
    "demand": 35379.43871,
    "solar_generated": 6.848163201,
    "wind_generated": 55.94340128,
    "marketplace_to_demand": 188.5553076,
    "grid_to_demand": 32543.8907,
    "govt_battery_charge": 6.479317024
  },
  {
    "timestamp": "22/03/25 14:00",
    "demand": 30593.57716,
    "solar_generated": 10.52462239,
    "wind_generated": 54.37843479,
    "marketplace_to_demand": 110.8906976,
    "grid_to_demand": 31874.0233,
    "govt_battery_charge": 15.00926658
  },
  {
    "timestamp": "22/03/25 15:00",
    "demand": 36308.62097,
    "solar_generated": 7.845410165,
    "wind_generated": 66.90895297,
    "marketplace_to_demand": 58.67873912,
    "grid_to_demand": 32195.15902,
    "govt_battery_charge": 25.38080253
  },
  {
    "timestamp": "22/03/25 16:00",
    "demand": 31642.92596,
    "solar_generated": 9.78014689,
    "wind_generated": 54.93810211,
    "marketplace_to_demand": 96.96056367,
    "grid_to_demand": 33971.93303,
    "govt_battery_charge": 30.67667553
  },
  {
    "timestamp": "22/03/25 17:00",
    "demand": 34262.21811,
    "solar_generated": 8.223641422,
    "wind_generated": 71.48535357,
    "marketplace_to_demand": 11.52572397,
    "grid_to_demand": 30706.4613,
    "govt_battery_charge": 71.68757527
  },
  {
    "timestamp": "22/03/25 18:00",
    "demand": 33356.6279,
    "solar_generated": 0,
    "wind_generated": 73.48180602,
    "marketplace_to_demand": 65.76514874,
    "grid_to_demand": 30015.85279,
    "govt_battery_charge": 95.12562415
  },
  {
    "timestamp": "22/03/25 19:00",
    "demand": 35796.31294,
    "solar_generated": 0,
    "wind_generated": 73.79962352,
    "marketplace_to_demand": 55.01259314,
    "grid_to_demand": 30611.15463,
    "govt_battery_charge": 29.39733367
  },
  {
    "timestamp": "22/03/25 20:00",
    "demand": 31108.32567,
    "solar_generated": 0,
    "wind_generated": 64.59038806,
    "marketplace_to_demand": 104.2675214,
    "grid_to_demand": 36365.97298,
    "govt_battery_charge": 62.45340406
  },
  {
    "timestamp": "22/03/25 21:00",
    "demand": 30423.0659,
    "solar_generated": 0,
    "wind_generated": 66.05327877,
    "marketplace_to_demand": 173.9279111,
    "grid_to_demand": 33737.11235,
    "govt_battery_charge": 54.43615483
  },
  {
    "timestamp": "22/03/25 22:00",
    "demand": 33854.57634,
    "solar_generated": 0,
    "wind_generated": 70.86283214,
    "marketplace_to_demand": 164.9973159,
    "grid_to_demand": 32207.16769,
    "govt_battery_charge": 92.19166096
  },
  {
    "timestamp": "22/03/25 23:00",
    "demand": 34199.86586,
    "solar_generated": 0,
    "wind_generated": 53.87352779,
    "marketplace_to_demand": 92.75622285,
    "grid_to_demand": 36534.00629,
    "govt_battery_charge": 26.10036346
  },
  {
    "timestamp": "23/03/25 0:00",
    "demand": 33358.44414,
    "solar_generated": 0,
    "wind_generated": 66.19637882,
    "marketplace_to_demand": 141.5083857,
    "grid_to_demand": 33307.53211,
    "govt_battery_charge": 78.76029783
  },
  {
    "timestamp": "23/03/25 1:00",
    "demand": 33234.73976,
    "solar_generated": 0,
    "wind_generated": 60.1618425,
    "marketplace_to_demand": 182.3614858,
    "grid_to_demand": 34232.0927,
    "govt_battery_charge": 53.05424771
  },
  {
    "timestamp": "23/03/25 2:00",
    "demand": 35442.03892,
    "solar_generated": 0,
    "wind_generated": 63.43024078,
    "marketplace_to_demand": 97.78656155,
    "grid_to_demand": 30351.63236,
    "govt_battery_charge": 12.89210339
  },
  {
    "timestamp": "23/03/25 3:00",
    "demand": 36487.5302,
    "solar_generated": 0,
    "wind_generated": 80.09129103,
    "marketplace_to_demand": 31.71055891,
    "grid_to_demand": 35383.74607,
    "govt_battery_charge": 91.71681933
  },
  {
    "timestamp": "23/03/25 4:00",
    "demand": 35544.1178,
    "solar_generated": 0,
    "wind_generated": 55.28025687,
    "marketplace_to_demand": 13.16152757,
    "grid_to_demand": 35593.748,
    "govt_battery_charge": 32.36957356
  },
  {
    "timestamp": "23/03/25 5:00",
    "demand": 35910.15977,
    "solar_generated": 0,
    "wind_generated": 76.24533803,
    "marketplace_to_demand": 169.2210832,
    "grid_to_demand": 32342.68714,
    "govt_battery_charge": 95.55514783
  },
  {
    "timestamp": "23/03/25 6:00",
    "demand": 31872.9859,
    "solar_generated": 17.12236696,
    "wind_generated": 78.74329083,
    "marketplace_to_demand": 129.9681161,
    "grid_to_demand": 31841.88959,
    "govt_battery_charge": 28.95813709
  },
  {
    "timestamp": "23/03/25 7:00",
    "demand": 31290.28981,
    "solar_generated": 15.78527703,
    "wind_generated": 61.3509318,
    "marketplace_to_demand": 10.14824967,
    "grid_to_demand": 35483.99527,
    "govt_battery_charge": 21.95004564
  },
  {
    "timestamp": "23/03/25 8:00",
    "demand": 34994.10735,
    "solar_generated": 14.32528048,
    "wind_generated": 63.53751461,
    "marketplace_to_demand": 53.38565085,
    "grid_to_demand": 32282.32893,
    "govt_battery_charge": 96.79989419
  },
  {
    "timestamp": "23/03/25 9:00",
    "demand": 30952.57244,
    "solar_generated": 6.710218486,
    "wind_generated": 67.98629842,
    "marketplace_to_demand": 94.69600913,
    "grid_to_demand": 35779.0418,
    "govt_battery_charge": 96.25841053
  },
  {
    "timestamp": "23/03/25 10:00",
    "demand": 35722.76587,
    "solar_generated": 8.540581064,
    "wind_generated": 78.56845458,
    "marketplace_to_demand": 97.27856294,
    "grid_to_demand": 35826.79135,
    "govt_battery_charge": 11.03968562
  },
  {
    "timestamp": "23/03/25 11:00",
    "demand": 35922.01468,
    "solar_generated": 19.5767039,
    "wind_generated": 56.73301206,
    "marketplace_to_demand": 167.008552,
    "grid_to_demand": 32360.03306,
    "govt_battery_charge": 40.51941473
  },
  {
    "timestamp": "23/03/25 12:00",
    "demand": 36484.77172,
    "solar_generated": 19.50213142,
    "wind_generated": 71.4384508,
    "marketplace_to_demand": 157.742431,
    "grid_to_demand": 33531.76398,
    "govt_battery_charge": 13.29279472
  },
  {
    "timestamp": "23/03/25 13:00",
    "demand": 34100.18782,
    "solar_generated": 17.51870584,
    "wind_generated": 58.26170309,
    "marketplace_to_demand": 159.579191,
    "grid_to_demand": 30021.75278,
    "govt_battery_charge": 96.28355076
  },
  {
    "timestamp": "23/03/25 14:00",
    "demand": 30176.65881,
    "solar_generated": 17.84203983,
    "wind_generated": 75.33240983,
    "marketplace_to_demand": 139.0733958,
    "grid_to_demand": 34797.80473,
    "govt_battery_charge": 82.57190427
  },
  {
    "timestamp": "23/03/25 15:00",
    "demand": 32869.83008,
    "solar_generated": 12.08503459,
    "wind_generated": 54.52386747,
    "marketplace_to_demand": 41.96387629,
    "grid_to_demand": 31240.21207,
    "govt_battery_charge": 79.51025446
  },
  {
    "timestamp": "23/03/25 16:00",
    "demand": 35363.30426,
    "solar_generated": 8.269493371,
    "wind_generated": 63.48466823,
    "marketplace_to_demand": 108.0995351,
    "grid_to_demand": 34658.77407,
    "govt_battery_charge": 71.26113861
  },
  {
    "timestamp": "23/03/25 17:00",
    "demand": 34019.61635,
    "solar_generated": 14.22244398,
    "wind_generated": 67.79301712,
    "marketplace_to_demand": 6.589667244,
    "grid_to_demand": 31039.23825,
    "govt_battery_charge": 78.74070305
  },
  {
    "timestamp": "23/03/25 18:00",
    "demand": 36544.41924,
    "solar_generated": 0,
    "wind_generated": 62.59487801,
    "marketplace_to_demand": 162.2613745,
    "grid_to_demand": 30285.03622,
    "govt_battery_charge": 91.61788453
  },
  {
    "timestamp": "23/03/25 19:00",
    "demand": 35721.65406,
    "solar_generated": 0,
    "wind_generated": 68.67135545,
    "marketplace_to_demand": 62.7091078,
    "grid_to_demand": 30671.30198,
    "govt_battery_charge": 76.34977893
  },
  {
    "timestamp": "23/03/25 20:00",
    "demand": 33002.95636,
    "solar_generated": 0,
    "wind_generated": 55.75223516,
    "marketplace_to_demand": 23.88223113,
    "grid_to_demand": 35823.10467,
    "govt_battery_charge": 72.32880932
  },
  {
    "timestamp": "23/03/25 21:00",
    "demand": 30130.42437,
    "solar_generated": 0,
    "wind_generated": 77.02738635,
    "marketplace_to_demand": 132.272163,
    "grid_to_demand": 33565.79111,
    "govt_battery_charge": 48.6577311
  },
  {
    "timestamp": "23/03/25 22:00",
    "demand": 32319.26593,
    "solar_generated": 0,
    "wind_generated": 60.70878139,
    "marketplace_to_demand": 30.31024254,
    "grid_to_demand": 35474.35498,
    "govt_battery_charge": 74.3469218
  },
  {
    "timestamp": "23/03/25 23:00",
    "demand": 31666.09264,
    "solar_generated": 0,
    "wind_generated": 61.25439232,
    "marketplace_to_demand": 17.91420505,
    "grid_to_demand": 32178.4304,
    "govt_battery_charge": 19.1337442
  },
  {
    "timestamp": "24/03/25 0:00",
    "demand": 34591.76647,
    "solar_generated": 0,
    "wind_generated": 58.11577832,
    "marketplace_to_demand": 70.12515988,
    "grid_to_demand": 34121.87145,
    "govt_battery_charge": 88.31446447
  },
  {
    "timestamp": "24/03/25 1:00",
    "demand": 34574.25125,
    "solar_generated": 0,
    "wind_generated": 59.94010302,
    "marketplace_to_demand": 175.4197285,
    "grid_to_demand": 33221.37647,
    "govt_battery_charge": 55.65815343
  },
  {
    "timestamp": "24/03/25 2:00",
    "demand": 36454.83588,
    "solar_generated": 0,
    "wind_generated": 65.02956665,
    "marketplace_to_demand": 145.5627884,
    "grid_to_demand": 31861.8956,
    "govt_battery_charge": 88.76398801
  },
  {
    "timestamp": "24/03/25 3:00",
    "demand": 33058.13193,
    "solar_generated": 0,
    "wind_generated": 63.10658228,
    "marketplace_to_demand": 175.2235964,
    "grid_to_demand": 33370.50679,
    "govt_battery_charge": 30.32752915
  },
  {
    "timestamp": "24/03/25 4:00",
    "demand": 32797.70204,
    "solar_generated": 0,
    "wind_generated": 65.9740406,
    "marketplace_to_demand": 138.5019594,
    "grid_to_demand": 32399.02423,
    "govt_battery_charge": 1.74518658
  },
  {
    "timestamp": "24/03/25 5:00",
    "demand": 30631.33294,
    "solar_generated": 0,
    "wind_generated": 60.20528802,
    "marketplace_to_demand": 191.6133747,
    "grid_to_demand": 36452.2873,
    "govt_battery_charge": 33.77528245
  },
  {
    "timestamp": "24/03/25 6:00",
    "demand": 35484.86338,
    "solar_generated": 11.25931451,
    "wind_generated": 61.77822363,
    "marketplace_to_demand": 173.0644375,
    "grid_to_demand": 30202.61398,
    "govt_battery_charge": 62.05613406
  },
  {
    "timestamp": "24/03/25 7:00",
    "demand": 31515.41823,
    "solar_generated": 22.41001549,
    "wind_generated": 60.66830356,
    "marketplace_to_demand": 103.8321493,
    "grid_to_demand": 33334.92423,
    "govt_battery_charge": 86.77492855
  },
  {
    "timestamp": "24/03/25 8:00",
    "demand": 36163.89494,
    "solar_generated": 22.93674982,
    "wind_generated": 70.41609798,
    "marketplace_to_demand": 12.08431987,
    "grid_to_demand": 36059.0795,
    "govt_battery_charge": 67.76092656
  },
  {
    "timestamp": "24/03/25 9:00",
    "demand": 33213.83876,
    "solar_generated": 22.74786014,
    "wind_generated": 55.85790927,
    "marketplace_to_demand": 24.7642254,
    "grid_to_demand": 34182.40241,
    "govt_battery_charge": 35.98911263
  },
  {
    "timestamp": "24/03/25 10:00",
    "demand": 32939.03018,
    "solar_generated": 6.732624507,
    "wind_generated": 78.1691298,
    "marketplace_to_demand": 154.7622573,
    "grid_to_demand": 33360.67645,
    "govt_battery_charge": 12.64122795
  },
  {
    "timestamp": "24/03/25 11:00",
    "demand": 33418.73352,
    "solar_generated": 11.95102278,
    "wind_generated": 59.20690287,
    "marketplace_to_demand": 143.7327702,
    "grid_to_demand": 32833.04858,
    "govt_battery_charge": 77.85868329
  },
  {
    "timestamp": "24/03/25 12:00",
    "demand": 30073.37977,
    "solar_generated": 23.30530631,
    "wind_generated": 71.20755532,
    "marketplace_to_demand": 71.30072623,
    "grid_to_demand": 35659.31318,
    "govt_battery_charge": 24.0283976
  },
  {
    "timestamp": "24/03/25 13:00",
    "demand": 33677.65649,
    "solar_generated": 16.02088975,
    "wind_generated": 54.04389916,
    "marketplace_to_demand": 48.10584695,
    "grid_to_demand": 35807.94313,
    "govt_battery_charge": 74.94047129
  },
  {
    "timestamp": "24/03/25 14:00",
    "demand": 30295.94428,
    "solar_generated": 17.03836928,
    "wind_generated": 74.82308664,
    "marketplace_to_demand": 74.4536336,
    "grid_to_demand": 32303.88102,
    "govt_battery_charge": 75.86243231
  },
  {
    "timestamp": "24/03/25 15:00",
    "demand": 30505.01925,
    "solar_generated": 19.88547556,
    "wind_generated": 72.53479801,
    "marketplace_to_demand": 152.2000552,
    "grid_to_demand": 35435.7137,
    "govt_battery_charge": 93.44578161
  },
  {
    "timestamp": "24/03/25 16:00",
    "demand": 31102.96643,
    "solar_generated": 16.53927179,
    "wind_generated": 58.47366909,
    "marketplace_to_demand": 133.6852033,
    "grid_to_demand": 36539.32311,
    "govt_battery_charge": 70.7039358
  },
  {
    "timestamp": "24/03/25 17:00",
    "demand": 34814.88814,
    "solar_generated": 18.27942564,
    "wind_generated": 57.80837754,
    "marketplace_to_demand": 166.3828168,
    "grid_to_demand": 34204.89322,
    "govt_battery_charge": 42.23185035
  },
  {
    "timestamp": "24/03/25 18:00",
    "demand": 35637.36061,
    "solar_generated": 0,
    "wind_generated": 75.0595278,
    "marketplace_to_demand": 142.4514864,
    "grid_to_demand": 36438.09826,
    "govt_battery_charge": 93.68277057
  },
  {
    "timestamp": "24/03/25 19:00",
    "demand": 34063.27832,
    "solar_generated": 0,
    "wind_generated": 75.20051648,
    "marketplace_to_demand": 85.27588435,
    "grid_to_demand": 33730.61286,
    "govt_battery_charge": 70.91974495
  },
  {
    "timestamp": "24/03/25 20:00",
    "demand": 31442.42953,
    "solar_generated": 0,
    "wind_generated": 77.43807697,
    "marketplace_to_demand": 108.7592293,
    "grid_to_demand": 29937.68724,
    "govt_battery_charge": 9.069994591
  },
  {
    "timestamp": "24/03/25 21:00",
    "demand": 31924.92208,
    "solar_generated": 0,
    "wind_generated": 70.48080847,
    "marketplace_to_demand": 88.7536012,
    "grid_to_demand": 31574.47482,
    "govt_battery_charge": 69.91524144
  },
  {
    "timestamp": "24/03/25 22:00",
    "demand": 29971.13543,
    "solar_generated": 0,
    "wind_generated": 79.64003829,
    "marketplace_to_demand": 186.3476109,
    "grid_to_demand": 31346.2127,
    "govt_battery_charge": 7.722790527
  },
  {
    "timestamp": "24/03/25 23:00",
    "demand": 34545.51565,
    "solar_generated": 0,
    "wind_generated": 74.41621625,
    "marketplace_to_demand": 24.96091934,
    "grid_to_demand": 35875.92593,
    "govt_battery_charge": 43.47989957
  },
  {
    "timestamp": "25/03/25 0:00",
    "demand": 31595.45849,
    "solar_generated": 0,
    "wind_generated": 58.09203667,
    "marketplace_to_demand": 106.7485262,
    "grid_to_demand": 32260.43807,
    "govt_battery_charge": 83.84317262
  },
  {
    "timestamp": "25/03/25 1:00",
    "demand": 30514.43823,
    "solar_generated": 0,
    "wind_generated": 59.48461459,
    "marketplace_to_demand": 37.75583318,
    "grid_to_demand": 33716.08703,
    "govt_battery_charge": 20.54772477
  },
  {
    "timestamp": "25/03/25 2:00",
    "demand": 30380.94555,
    "solar_generated": 0,
    "wind_generated": 54.01948036,
    "marketplace_to_demand": 151.3607005,
    "grid_to_demand": 32425.31087,
    "govt_battery_charge": 83.63583056
  },
  {
    "timestamp": "25/03/25 3:00",
    "demand": 32593.51589,
    "solar_generated": 0,
    "wind_generated": 55.00864724,
    "marketplace_to_demand": 88.82951482,
    "grid_to_demand": 31480.99828,
    "govt_battery_charge": 16.84691504
  },
  {
    "timestamp": "25/03/25 4:00",
    "demand": 35097.13005,
    "solar_generated": 0,
    "wind_generated": 61.02521242,
    "marketplace_to_demand": 56.66181817,
    "grid_to_demand": 32895.55316,
    "govt_battery_charge": 7.152467433
  },
  {
    "timestamp": "25/03/25 5:00",
    "demand": 34011.51306,
    "solar_generated": 0,
    "wind_generated": 80.35818283,
    "marketplace_to_demand": 19.61954173,
    "grid_to_demand": 32897.54603,
    "govt_battery_charge": 86.27408103
  },
  {
    "timestamp": "25/03/25 6:00",
    "demand": 32959.62311,
    "solar_generated": 11.24861642,
    "wind_generated": 62.61919309,
    "marketplace_to_demand": 61.83227675,
    "grid_to_demand": 30561.17715,
    "govt_battery_charge": 50.0859684
  },
  {
    "timestamp": "25/03/25 7:00",
    "demand": 34834.6732,
    "solar_generated": 7.129337006,
    "wind_generated": 73.30916401,
    "marketplace_to_demand": 97.71370816,
    "grid_to_demand": 32450.86602,
    "govt_battery_charge": 45.60865196
  },
  {
    "timestamp": "25/03/25 8:00",
    "demand": 31307.94301,
    "solar_generated": 15.22930495,
    "wind_generated": 76.26072003,
    "marketplace_to_demand": 30.04948417,
    "grid_to_demand": 36330.02298,
    "govt_battery_charge": 13.10286993
  },
  {
    "timestamp": "25/03/25 9:00",
    "demand": 30939.03145,
    "solar_generated": 13.79368266,
    "wind_generated": 70.37069519,
    "marketplace_to_demand": 127.0267205,
    "grid_to_demand": 30436.68362,
    "govt_battery_charge": 73.38137946
  },
  {
    "timestamp": "25/03/25 10:00",
    "demand": 30992.52428,
    "solar_generated": 18.44160171,
    "wind_generated": 78.10277142,
    "marketplace_to_demand": 40.2054492,
    "grid_to_demand": 30378.47727,
    "govt_battery_charge": 52.06423712
  },
  {
    "timestamp": "25/03/25 11:00",
    "demand": 33036.94597,
    "solar_generated": 22.88224413,
    "wind_generated": 75.16940746,
    "marketplace_to_demand": 68.90206821,
    "grid_to_demand": 31876.93564,
    "govt_battery_charge": 45.2120645
  },
  {
    "timestamp": "25/03/25 12:00",
    "demand": 31646.17458,
    "solar_generated": 22.91868429,
    "wind_generated": 56.43754298,
    "marketplace_to_demand": 41.27477515,
    "grid_to_demand": 32604.97039,
    "govt_battery_charge": 49.07931675
  },
  {
    "timestamp": "25/03/25 13:00",
    "demand": 31355.73407,
    "solar_generated": 14.120696,
    "wind_generated": 62.09223708,
    "marketplace_to_demand": 118.9836407,
    "grid_to_demand": 33846.94196,
    "govt_battery_charge": 63.34491198
  },
  {
    "timestamp": "25/03/25 14:00",
    "demand": 34052.63872,
    "solar_generated": 6.825125825,
    "wind_generated": 58.05170456,
    "marketplace_to_demand": 57.87828589,
    "grid_to_demand": 30302.59475,
    "govt_battery_charge": 67.96999084
  },
  {
    "timestamp": "25/03/25 15:00",
    "demand": 32334.05012,
    "solar_generated": 22.09468173,
    "wind_generated": 80.29045442,
    "marketplace_to_demand": 185.7859975,
    "grid_to_demand": 34056.04995,
    "govt_battery_charge": 55.41901134
  },
  {
    "timestamp": "25/03/25 16:00",
    "demand": 30173.00826,
    "solar_generated": 13.5694416,
    "wind_generated": 60.72742137,
    "marketplace_to_demand": 49.23903327,
    "grid_to_demand": 33051.61551,
    "govt_battery_charge": 61.82257992
  },
  {
    "timestamp": "25/03/25 17:00",
    "demand": 35770.39651,
    "solar_generated": 18.18145907,
    "wind_generated": 75.9953306,
    "marketplace_to_demand": 50.09528706,
    "grid_to_demand": 30667.97785,
    "govt_battery_charge": 51.99024196
  },
  {
    "timestamp": "25/03/25 18:00",
    "demand": 30291.59306,
    "solar_generated": 0,
    "wind_generated": 56.64867959,
    "marketplace_to_demand": 65.83770814,
    "grid_to_demand": 30836.50776,
    "govt_battery_charge": 43.85464744
  },
  {
    "timestamp": "25/03/25 19:00",
    "demand": 31911.39339,
    "solar_generated": 0,
    "wind_generated": 67.60001799,
    "marketplace_to_demand": 87.59684787,
    "grid_to_demand": 33491.47232,
    "govt_battery_charge": 23.35128405
  },
  {
    "timestamp": "25/03/25 20:00",
    "demand": 33676.37712,
    "solar_generated": 0,
    "wind_generated": 70.37184975,
    "marketplace_to_demand": 6.3357742,
    "grid_to_demand": 33338.95714,
    "govt_battery_charge": 21.3856572
  },
  {
    "timestamp": "25/03/25 21:00",
    "demand": 31777.92625,
    "solar_generated": 0,
    "wind_generated": 68.14081395,
    "marketplace_to_demand": 175.4267353,
    "grid_to_demand": 31242.61616,
    "govt_battery_charge": 44.65478396
  },
  {
    "timestamp": "25/03/25 22:00",
    "demand": 30947.04621,
    "solar_generated": 0,
    "wind_generated": 76.35690691,
    "marketplace_to_demand": 37.64399182,
    "grid_to_demand": 32209.59547,
    "govt_battery_charge": 25.55523279
  },
  {
    "timestamp": "25/03/25 23:00",
    "demand": 32170.22333,
    "solar_generated": 0,
    "wind_generated": 79.61333256,
    "marketplace_to_demand": 130.224097,
    "grid_to_demand": 30979.8391,
    "govt_battery_charge": 59.23528153
  },
  {
    "timestamp": "26/03/25 0:00",
    "demand": 33470.21454,
    "solar_generated": 0,
    "wind_generated": 58.82371748,
    "marketplace_to_demand": 31.80143218,
    "grid_to_demand": 35111.00412,
    "govt_battery_charge": 21.03290772
  },
  {
    "timestamp": "26/03/25 1:00",
    "demand": 31232.98952,
    "solar_generated": 0,
    "wind_generated": 54.49862516,
    "marketplace_to_demand": 140.6510593,
    "grid_to_demand": 34906.77978,
    "govt_battery_charge": 69.76971497
  },
  {
    "timestamp": "26/03/25 2:00",
    "demand": 33210.13739,
    "solar_generated": 0,
    "wind_generated": 78.64904628,
    "marketplace_to_demand": 13.17826987,
    "grid_to_demand": 34274.83949,
    "govt_battery_charge": 69.3862869
  },
  {
    "timestamp": "26/03/25 3:00",
    "demand": 33684.45998,
    "solar_generated": 0,
    "wind_generated": 70.3615427,
    "marketplace_to_demand": 157.9751233,
    "grid_to_demand": 33348.20294,
    "govt_battery_charge": 16.94886527
  },
  {
    "timestamp": "26/03/25 4:00",
    "demand": 32148.12059,
    "solar_generated": 0,
    "wind_generated": 56.06625448,
    "marketplace_to_demand": 143.1948026,
    "grid_to_demand": 36353.04132,
    "govt_battery_charge": 42.40008583
  },
  {
    "timestamp": "26/03/25 5:00",
    "demand": 30870.53268,
    "solar_generated": 0,
    "wind_generated": 53.60450778,
    "marketplace_to_demand": 171.5710293,
    "grid_to_demand": 34502.65458,
    "govt_battery_charge": 64.07575757
  },
  {
    "timestamp": "26/03/25 6:00",
    "demand": 33838.33583,
    "solar_generated": 9.236151843,
    "wind_generated": 73.80598201,
    "marketplace_to_demand": 43.92341965,
    "grid_to_demand": 30888.16533,
    "govt_battery_charge": 39.4598099
  },
  {
    "timestamp": "26/03/25 7:00",
    "demand": 30785.04988,
    "solar_generated": 19.88379075,
    "wind_generated": 75.62396359,
    "marketplace_to_demand": 52.99303965,
    "grid_to_demand": 32158.29332,
    "govt_battery_charge": 73.52145562
  },
  {
    "timestamp": "26/03/25 8:00",
    "demand": 36324.74224,
    "solar_generated": 6.22970848,
    "wind_generated": 61.46842147,
    "marketplace_to_demand": 43.28130935,
    "grid_to_demand": 35481.20233,
    "govt_battery_charge": 15.78049779
  },
  {
    "timestamp": "26/03/25 9:00",
    "demand": 31010.40385,
    "solar_generated": 21.33134732,
    "wind_generated": 58.57348465,
    "marketplace_to_demand": 151.551306,
    "grid_to_demand": 36490.16947,
    "govt_battery_charge": 57.50423917
  },
  {
    "timestamp": "26/03/25 10:00",
    "demand": 31446.16962,
    "solar_generated": 19.39759982,
    "wind_generated": 67.7699263,
    "marketplace_to_demand": 126.4619427,
    "grid_to_demand": 35349.51082,
    "govt_battery_charge": 46.02060518
  },
  {
    "timestamp": "26/03/25 11:00",
    "demand": 35278.13571,
    "solar_generated": 10.20799225,
    "wind_generated": 78.38480336,
    "marketplace_to_demand": 1.189393463,
    "grid_to_demand": 35577.02578,
    "govt_battery_charge": 38.84193995
  },
  {
    "timestamp": "26/03/25 12:00",
    "demand": 33184.81165,
    "solar_generated": 20.3175868,
    "wind_generated": 76.29249878,
    "marketplace_to_demand": 12.73637067,
    "grid_to_demand": 32952.43336,
    "govt_battery_charge": 97.79933384
  },
  {
    "timestamp": "26/03/25 13:00",
    "demand": 34243.92349,
    "solar_generated": 6.960439096,
    "wind_generated": 68.06774285,
    "marketplace_to_demand": 181.8582543,
    "grid_to_demand": 33717.89724,
    "govt_battery_charge": 75.88666951
  },
  {
    "timestamp": "26/03/25 14:00",
    "demand": 34912.1278,
    "solar_generated": 10.2517362,
    "wind_generated": 63.38092006,
    "marketplace_to_demand": 105.0801772,
    "grid_to_demand": 29922.2424,
    "govt_battery_charge": 29.35954719
  },
  {
    "timestamp": "26/03/25 15:00",
    "demand": 31139.5028,
    "solar_generated": 6.510927514,
    "wind_generated": 67.15604827,
    "marketplace_to_demand": 196.1565936,
    "grid_to_demand": 34010.64664,
    "govt_battery_charge": 26.48389751
  },
  {
    "timestamp": "26/03/25 16:00",
    "demand": 30499.53367,
    "solar_generated": 16.40619717,
    "wind_generated": 69.54747751,
    "marketplace_to_demand": 175.1600375,
    "grid_to_demand": 31592.15455,
    "govt_battery_charge": 41.96598023
  },
  {
    "timestamp": "26/03/25 17:00",
    "demand": 30956.8304,
    "solar_generated": 8.44656174,
    "wind_generated": 79.05120187,
    "marketplace_to_demand": 32.81959133,
    "grid_to_demand": 36208.31214,
    "govt_battery_charge": 47.18606008
  },
  {
    "timestamp": "26/03/25 18:00",
    "demand": 34734.68669,
    "solar_generated": 0,
    "wind_generated": 67.19102002,
    "marketplace_to_demand": 148.5070274,
    "grid_to_demand": 35357.3181,
    "govt_battery_charge": 88.20231131
  },
  {
    "timestamp": "26/03/25 19:00",
    "demand": 35524.9021,
    "solar_generated": 0,
    "wind_generated": 72.46639675,
    "marketplace_to_demand": 8.141391312,
    "grid_to_demand": 31244.82213,
    "govt_battery_charge": 17.68262032
  },
  {
    "timestamp": "26/03/25 20:00",
    "demand": 33568.74962,
    "solar_generated": 0,
    "wind_generated": 74.52712096,
    "marketplace_to_demand": 4.038243928,
    "grid_to_demand": 30277.47054,
    "govt_battery_charge": 45.83794758
  },
  {
    "timestamp": "26/03/25 21:00",
    "demand": 33855.75136,
    "solar_generated": 0,
    "wind_generated": 61.19782265,
    "marketplace_to_demand": 20.22150841,
    "grid_to_demand": 32463.10571,
    "govt_battery_charge": 5.279762393
  },
  {
    "timestamp": "26/03/25 22:00",
    "demand": 31948.58821,
    "solar_generated": 0,
    "wind_generated": 73.80355841,
    "marketplace_to_demand": 195.2096462,
    "grid_to_demand": 32933.39526,
    "govt_battery_charge": 27.45993902
  },
  {
    "timestamp": "26/03/25 23:00",
    "demand": 30738.49353,
    "solar_generated": 0,
    "wind_generated": 74.79235968,
    "marketplace_to_demand": 22.46506534,
    "grid_to_demand": 36340.17118,
    "govt_battery_charge": 9.020165187
  },
  {
    "timestamp": "27/03/25 0:00",
    "demand": 32075.57467,
    "solar_generated": 0,
    "wind_generated": 57.3810041,
    "marketplace_to_demand": 172.6331133,
    "grid_to_demand": 33106.65831,
    "govt_battery_charge": 20.98669684
  },
  {
    "timestamp": "27/03/25 1:00",
    "demand": 30682.74941,
    "solar_generated": 0,
    "wind_generated": 69.83084493,
    "marketplace_to_demand": 80.93435281,
    "grid_to_demand": 31632.32301,
    "govt_battery_charge": 34.00511854
  },
  {
    "timestamp": "27/03/25 2:00",
    "demand": 36560.44127,
    "solar_generated": 0,
    "wind_generated": 65.07111074,
    "marketplace_to_demand": 77.38412409,
    "grid_to_demand": 34882.44859,
    "govt_battery_charge": 61.72484084
  },
  {
    "timestamp": "27/03/25 3:00",
    "demand": 34465.2802,
    "solar_generated": 0,
    "wind_generated": 74.49753696,
    "marketplace_to_demand": 55.9028627,
    "grid_to_demand": 32238.77199,
    "govt_battery_charge": 78.45521178
  },
  {
    "timestamp": "27/03/25 4:00",
    "demand": 35852.04527,
    "solar_generated": 0,
    "wind_generated": 76.2147691,
    "marketplace_to_demand": 189.4773626,
    "grid_to_demand": 32916.06027,
    "govt_battery_charge": 76.73788745
  },
  {
    "timestamp": "27/03/25 5:00",
    "demand": 35162.31983,
    "solar_generated": 0,
    "wind_generated": 56.18479794,
    "marketplace_to_demand": 184.2446156,
    "grid_to_demand": 31408.99034,
    "govt_battery_charge": 9.624266136
  },
  {
    "timestamp": "27/03/25 6:00",
    "demand": 34868.68109,
    "solar_generated": 16.23726854,
    "wind_generated": 61.3138212,
    "marketplace_to_demand": 81.49282329,
    "grid_to_demand": 34182.78803,
    "govt_battery_charge": 11.8919008
  },
  {
    "timestamp": "27/03/25 7:00",
    "demand": 34616.82965,
    "solar_generated": 6.293931918,
    "wind_generated": 79.52127432,
    "marketplace_to_demand": 117.8500959,
    "grid_to_demand": 30785.98795,
    "govt_battery_charge": 62.40285487
  },
  {
    "timestamp": "27/03/25 8:00",
    "demand": 32937.19543,
    "solar_generated": 7.265928915,
    "wind_generated": 75.76740449,
    "marketplace_to_demand": 46.61532967,
    "grid_to_demand": 36307.03778,
    "govt_battery_charge": 20.93635622
  },
  {
    "timestamp": "27/03/25 9:00",
    "demand": 34324.68333,
    "solar_generated": 19.81267743,
    "wind_generated": 72.57556532,
    "marketplace_to_demand": 87.75598187,
    "grid_to_demand": 30374.0187,
    "govt_battery_charge": 50.94584657
  },
  {
    "timestamp": "27/03/25 10:00",
    "demand": 31047.17409,
    "solar_generated": 11.60865978,
    "wind_generated": 56.22911832,
    "marketplace_to_demand": 159.0615055,
    "grid_to_demand": 32559.11984,
    "govt_battery_charge": 17.98604422
  },
  {
    "timestamp": "27/03/25 11:00",
    "demand": 32398.78715,
    "solar_generated": 23.7706599,
    "wind_generated": 75.10518937,
    "marketplace_to_demand": 40.43324737,
    "grid_to_demand": 34743.03284,
    "govt_battery_charge": 33.22393128
  },
  {
    "timestamp": "27/03/25 12:00",
    "demand": 35973.41202,
    "solar_generated": 19.2804112,
    "wind_generated": 77.05490067,
    "marketplace_to_demand": 22.9992065,
    "grid_to_demand": 34219.14023,
    "govt_battery_charge": 12.19958046
  },
  {
    "timestamp": "27/03/25 13:00",
    "demand": 35658.11477,
    "solar_generated": 8.232666833,
    "wind_generated": 62.61642689,
    "marketplace_to_demand": 8.800820985,
    "grid_to_demand": 35178.18789,
    "govt_battery_charge": 86.7876975
  },
  {
    "timestamp": "27/03/25 14:00",
    "demand": 31406.2524,
    "solar_generated": 17.19901589,
    "wind_generated": 80.38296852,
    "marketplace_to_demand": 1.666913533,
    "grid_to_demand": 34036.64511,
    "govt_battery_charge": 68.10201475
  },
  {
    "timestamp": "27/03/25 15:00",
    "demand": 36461.33139,
    "solar_generated": 16.48082198,
    "wind_generated": 67.61448088,
    "marketplace_to_demand": 85.25993621,
    "grid_to_demand": 32317.02606,
    "govt_battery_charge": 53.7056961
  },
  {
    "timestamp": "27/03/25 16:00",
    "demand": 32354.39919,
    "solar_generated": 6.213660931,
    "wind_generated": 66.83715404,
    "marketplace_to_demand": 55.43897417,
    "grid_to_demand": 33452.66029,
    "govt_battery_charge": 88.6600782
  },
  {
    "timestamp": "27/03/25 17:00",
    "demand": 35182.28244,
    "solar_generated": 21.91750931,
    "wind_generated": 63.48926172,
    "marketplace_to_demand": 84.65287718,
    "grid_to_demand": 34729.53628,
    "govt_battery_charge": 33.20815975
  },
  {
    "timestamp": "27/03/25 18:00",
    "demand": 31779.14244,
    "solar_generated": 0,
    "wind_generated": 78.62677269,
    "marketplace_to_demand": 72.06831296,
    "grid_to_demand": 32477.42445,
    "govt_battery_charge": 33.21292344
  },
  {
    "timestamp": "27/03/25 19:00",
    "demand": 31849.86145,
    "solar_generated": 0,
    "wind_generated": 53.95743399,
    "marketplace_to_demand": 128.457848,
    "grid_to_demand": 33724.98321,
    "govt_battery_charge": 30.02489051
  },
  {
    "timestamp": "27/03/25 20:00",
    "demand": 36307.76503,
    "solar_generated": 0,
    "wind_generated": 64.02582443,
    "marketplace_to_demand": 140.1216056,
    "grid_to_demand": 30770.0456,
    "govt_battery_charge": 26.90116124
  },
  {
    "timestamp": "27/03/25 21:00",
    "demand": 30628.16074,
    "solar_generated": 0,
    "wind_generated": 80.37019299,
    "marketplace_to_demand": 138.4018012,
    "grid_to_demand": 30748.03291,
    "govt_battery_charge": 30.93990586
  },
  {
    "timestamp": "27/03/25 22:00",
    "demand": 30643.33609,
    "solar_generated": 0,
    "wind_generated": 76.73528154,
    "marketplace_to_demand": 120.6367285,
    "grid_to_demand": 36127.57983,
    "govt_battery_charge": 29.44196482
  },
  {
    "timestamp": "27/03/25 23:00",
    "demand": 33287.21078,
    "solar_generated": 0,
    "wind_generated": 63.57243172,
    "marketplace_to_demand": 108.9587997,
    "grid_to_demand": 35424.32962,
    "govt_battery_charge": 33.04371628
  },
  {
    "timestamp": "28/03/25 0:00",
    "demand": 33083.22809,
    "solar_generated": 0,
    "wind_generated": 69.18585822,
    "marketplace_to_demand": 176.8278154,
    "grid_to_demand": 30651.87924,
    "govt_battery_charge": 42.20695634
  },
  {
    "timestamp": "28/03/25 1:00",
    "demand": 34673.94951,
    "solar_generated": 0,
    "wind_generated": 56.46897725,
    "marketplace_to_demand": 89.02270856,
    "grid_to_demand": 33343.68946,
    "govt_battery_charge": 70.37605774
  },
  {
    "timestamp": "28/03/25 2:00",
    "demand": 32360.91647,
    "solar_generated": 0,
    "wind_generated": 54.87609804,
    "marketplace_to_demand": 126.2224686,
    "grid_to_demand": 36336.94703,
    "govt_battery_charge": 23.06813212
  },
  {
    "timestamp": "28/03/25 3:00",
    "demand": 35512.1938,
    "solar_generated": 0,
    "wind_generated": 70.97828543,
    "marketplace_to_demand": 111.8391739,
    "grid_to_demand": 33078.35177,
    "govt_battery_charge": 58.94134055
  },
  {
    "timestamp": "28/03/25 4:00",
    "demand": 35848.07961,
    "solar_generated": 0,
    "wind_generated": 70.19857665,
    "marketplace_to_demand": 83.10758982,
    "grid_to_demand": 30167.53598,
    "govt_battery_charge": 28.82006876
  },
  {
    "timestamp": "28/03/25 5:00",
    "demand": 35773.12911,
    "solar_generated": 0,
    "wind_generated": 53.64866095,
    "marketplace_to_demand": 115.8406559,
    "grid_to_demand": 30845.40047,
    "govt_battery_charge": 87.76874916
  },
  {
    "timestamp": "28/03/25 6:00",
    "demand": 34589.92373,
    "solar_generated": 14.6059967,
    "wind_generated": 79.78547668,
    "marketplace_to_demand": 140.5363189,
    "grid_to_demand": 35486.32261,
    "govt_battery_charge": 48.57794686
  },
  {
    "timestamp": "28/03/25 7:00",
    "demand": 31716.02888,
    "solar_generated": 8.225754371,
    "wind_generated": 80.33247152,
    "marketplace_to_demand": 50.89208235,
    "grid_to_demand": 30800.98305,
    "govt_battery_charge": 83.47343603
  },
  {
    "timestamp": "28/03/25 8:00",
    "demand": 31993.87812,
    "solar_generated": 8.279442077,
    "wind_generated": 63.78766682,
    "marketplace_to_demand": 3.59047682,
    "grid_to_demand": 35415.13737,
    "govt_battery_charge": 70.30179008
  },
  {
    "timestamp": "28/03/25 9:00",
    "demand": 35026.69455,
    "solar_generated": 17.89866706,
    "wind_generated": 61.70080835,
    "marketplace_to_demand": 1.929923619,
    "grid_to_demand": 35765.66216,
    "govt_battery_charge": 77.31875988
  },
  {
    "timestamp": "28/03/25 10:00",
    "demand": 30793.97308,
    "solar_generated": 20.12436104,
    "wind_generated": 59.57140172,
    "marketplace_to_demand": 112.5462628,
    "grid_to_demand": 35422.04424,
    "govt_battery_charge": 66.12266941
  },
  {
    "timestamp": "28/03/25 11:00",
    "demand": 30399.18159,
    "solar_generated": 15.87421114,
    "wind_generated": 62.11755663,
    "marketplace_to_demand": 45.31697696,
    "grid_to_demand": 33602.15702,
    "govt_battery_charge": 73.17287669
  },
  {
    "timestamp": "28/03/25 12:00",
    "demand": 32383.69943,
    "solar_generated": 6.251099543,
    "wind_generated": 68.6814017,
    "marketplace_to_demand": 85.12100815,
    "grid_to_demand": 29993.93558,
    "govt_battery_charge": 78.36040047
  },
  {
    "timestamp": "28/03/25 13:00",
    "demand": 31095.71004,
    "solar_generated": 15.54752781,
    "wind_generated": 79.92936233,
    "marketplace_to_demand": 103.2360354,
    "grid_to_demand": 32027.76902,
    "govt_battery_charge": 21.50861964
  },
  {
    "timestamp": "28/03/25 14:00",
    "demand": 30544.8713,
    "solar_generated": 6.091525389,
    "wind_generated": 61.12374962,
    "marketplace_to_demand": 170.195237,
    "grid_to_demand": 31808.13051,
    "govt_battery_charge": 64.74125472
  },
  {
    "timestamp": "28/03/25 15:00",
    "demand": 36013.34586,
    "solar_generated": 10.13679001,
    "wind_generated": 76.87651927,
    "marketplace_to_demand": 59.33659716,
    "grid_to_demand": 32919.32171,
    "govt_battery_charge": 54.49937275
  },
  {
    "timestamp": "28/03/25 16:00",
    "demand": 30920.18306,
    "solar_generated": 8.779916949,
    "wind_generated": 64.89955681,
    "marketplace_to_demand": 114.8543843,
    "grid_to_demand": 34273.2753,
    "govt_battery_charge": 38.85050295
  },
  {
    "timestamp": "28/03/25 17:00",
    "demand": 35960.8679,
    "solar_generated": 6.829625315,
    "wind_generated": 75.47815809,
    "marketplace_to_demand": 125.1900081,
    "grid_to_demand": 31286.47333,
    "govt_battery_charge": 68.58541808
  },
  {
    "timestamp": "28/03/25 18:00",
    "demand": 36221.32455,
    "solar_generated": 0,
    "wind_generated": 68.08880779,
    "marketplace_to_demand": 103.7945786,
    "grid_to_demand": 32858.82849,
    "govt_battery_charge": 10.99918729
  },
  {
    "timestamp": "28/03/25 19:00",
    "demand": 33937.66684,
    "solar_generated": 0,
    "wind_generated": 56.55276255,
    "marketplace_to_demand": 134.1698308,
    "grid_to_demand": 35997.94687,
    "govt_battery_charge": 87.36706718
  },
  {
    "timestamp": "28/03/25 20:00",
    "demand": 34235.87502,
    "solar_generated": 0,
    "wind_generated": 62.71558979,
    "marketplace_to_demand": 62.23598768,
    "grid_to_demand": 32665.434,
    "govt_battery_charge": 27.58961375
  },
  {
    "timestamp": "28/03/25 21:00",
    "demand": 34027.7958,
    "solar_generated": 0,
    "wind_generated": 65.37752798,
    "marketplace_to_demand": 165.5094275,
    "grid_to_demand": 33843.13853,
    "govt_battery_charge": 19.06964237
  },
  {
    "timestamp": "28/03/25 22:00",
    "demand": 30852.43513,
    "solar_generated": 0,
    "wind_generated": 54.93996466,
    "marketplace_to_demand": 21.84613524,
    "grid_to_demand": 34537.84627,
    "govt_battery_charge": 51.24749502
  },
  {
    "timestamp": "28/03/25 23:00",
    "demand": 31488.36478,
    "solar_generated": 0,
    "wind_generated": 56.78946341,
    "marketplace_to_demand": 183.7698922,
    "grid_to_demand": 31620.14881,
    "govt_battery_charge": 49.82023127
  },
  {
    "timestamp": "29/03/25 0:00",
    "demand": 35554.36305,
    "solar_generated": 0,
    "wind_generated": 54.32500393,
    "marketplace_to_demand": 68.82335716,
    "grid_to_demand": 34781.92889,
    "govt_battery_charge": 29.39740698
  },
  {
    "timestamp": "29/03/25 1:00",
    "demand": 33212.70214,
    "solar_generated": 0,
    "wind_generated": 58.86443557,
    "marketplace_to_demand": 10.01469461,
    "grid_to_demand": 36296.95989,
    "govt_battery_charge": 89.47653973
  },
  {
    "timestamp": "29/03/25 2:00",
    "demand": 35853.38022,
    "solar_generated": 0,
    "wind_generated": 69.20247527,
    "marketplace_to_demand": 170.2131625,
    "grid_to_demand": 32915.2529,
    "govt_battery_charge": 52.6356133
  },
  {
    "timestamp": "29/03/25 3:00",
    "demand": 32510.61933,
    "solar_generated": 0,
    "wind_generated": 78.35755929,
    "marketplace_to_demand": 191.2552395,
    "grid_to_demand": 32321.05229,
    "govt_battery_charge": 1.433010056
  },
  {
    "timestamp": "29/03/25 4:00",
    "demand": 31197.59412,
    "solar_generated": 0,
    "wind_generated": 73.72033421,
    "marketplace_to_demand": 32.38424385,
    "grid_to_demand": 31234.76662,
    "govt_battery_charge": 20.21550748
  },
  {
    "timestamp": "29/03/25 5:00",
    "demand": 31055.35561,
    "solar_generated": 0,
    "wind_generated": 67.79502649,
    "marketplace_to_demand": 117.9949326,
    "grid_to_demand": 30545.27182,
    "govt_battery_charge": 80.9869374
  },
  {
    "timestamp": "29/03/25 6:00",
    "demand": 31670.27643,
    "solar_generated": 21.72558734,
    "wind_generated": 67.22144671,
    "marketplace_to_demand": 134.0248541,
    "grid_to_demand": 35940.05501,
    "govt_battery_charge": 61.88346855
  },
  {
    "timestamp": "29/03/25 7:00",
    "demand": 31480.78643,
    "solar_generated": 7.287064105,
    "wind_generated": 59.73043403,
    "marketplace_to_demand": 96.55922727,
    "grid_to_demand": 34755.94572,
    "govt_battery_charge": 26.30073352
  },
  {
    "timestamp": "29/03/25 8:00",
    "demand": 35335.98632,
    "solar_generated": 23.52444277,
    "wind_generated": 78.75381863,
    "marketplace_to_demand": 133.7965099,
    "grid_to_demand": 33255.61334,
    "govt_battery_charge": 29.73895682
  },
  {
    "timestamp": "29/03/25 9:00",
    "demand": 35898.33902,
    "solar_generated": 17.99228751,
    "wind_generated": 61.68476677,
    "marketplace_to_demand": 5.860047241,
    "grid_to_demand": 30352.93939,
    "govt_battery_charge": 70.52567902
  },
  {
    "timestamp": "29/03/25 10:00",
    "demand": 32332.49931,
    "solar_generated": 19.05217528,
    "wind_generated": 56.32280623,
    "marketplace_to_demand": 84.76565662,
    "grid_to_demand": 32133.54994,
    "govt_battery_charge": 86.28070829
  },
  {
    "timestamp": "29/03/25 11:00",
    "demand": 35824.88581,
    "solar_generated": 10.48783148,
    "wind_generated": 62.60999561,
    "marketplace_to_demand": 181.6498159,
    "grid_to_demand": 30051.91397,
    "govt_battery_charge": 95.00045997
  },
  {
    "timestamp": "29/03/25 12:00",
    "demand": 30274.1783,
    "solar_generated": 13.99411449,
    "wind_generated": 58.13126598,
    "marketplace_to_demand": 193.4279073,
    "grid_to_demand": 31755.76858,
    "govt_battery_charge": 3.713290633
  },
  {
    "timestamp": "29/03/25 13:00",
    "demand": 30137.68922,
    "solar_generated": 15.08820095,
    "wind_generated": 63.68566867,
    "marketplace_to_demand": 165.7463829,
    "grid_to_demand": 32996.81797,
    "govt_battery_charge": 20.0111779
  },
  {
    "timestamp": "29/03/25 14:00",
    "demand": 35634.31092,
    "solar_generated": 19.81982648,
    "wind_generated": 65.62799431,
    "marketplace_to_demand": 142.7606119,
    "grid_to_demand": 32324.3914,
    "govt_battery_charge": 36.84221005
  },
  {
    "timestamp": "29/03/25 15:00",
    "demand": 30932.95222,
    "solar_generated": 20.11526508,
    "wind_generated": 57.19336147,
    "marketplace_to_demand": 4.193980979,
    "grid_to_demand": 33357.16288,
    "govt_battery_charge": 26.8437901
  },
  {
    "timestamp": "29/03/25 16:00",
    "demand": 34608.3305,
    "solar_generated": 16.44686316,
    "wind_generated": 54.5365977,
    "marketplace_to_demand": 32.70758058,
    "grid_to_demand": 33842.11244,
    "govt_battery_charge": 46.10447256
  },
  {
    "timestamp": "29/03/25 17:00",
    "demand": 35097.08482,
    "solar_generated": 12.3865777,
    "wind_generated": 66.75132486,
    "marketplace_to_demand": 156.1955455,
    "grid_to_demand": 30953.25359,
    "govt_battery_charge": 43.18400337
  },
  {
    "timestamp": "29/03/25 18:00",
    "demand": 34097.29598,
    "solar_generated": 0,
    "wind_generated": 71.73174,
    "marketplace_to_demand": 107.5120849,
    "grid_to_demand": 31237.19317,
    "govt_battery_charge": 66.70810534
  },
  {
    "timestamp": "29/03/25 19:00",
    "demand": 33048.70255,
    "solar_generated": 0,
    "wind_generated": 77.98680903,
    "marketplace_to_demand": 174.7213885,
    "grid_to_demand": 29939.9504,
    "govt_battery_charge": 85.48000462
  },
  {
    "timestamp": "29/03/25 20:00",
    "demand": 32373.66567,
    "solar_generated": 0,
    "wind_generated": 78.08065028,
    "marketplace_to_demand": 165.1210602,
    "grid_to_demand": 35315.32837,
    "govt_battery_charge": 37.07549186
  },
  {
    "timestamp": "29/03/25 21:00",
    "demand": 32165.92426,
    "solar_generated": 0,
    "wind_generated": 55.05089152,
    "marketplace_to_demand": 108.9465753,
    "grid_to_demand": 32413.2804,
    "govt_battery_charge": 84.14635181
  },
  {
    "timestamp": "29/03/25 22:00",
    "demand": 35739.88164,
    "solar_generated": 0,
    "wind_generated": 73.57946036,
    "marketplace_to_demand": 52.65504781,
    "grid_to_demand": 30144.83666,
    "govt_battery_charge": 56.38604595
  },
  {
    "timestamp": "29/03/25 23:00",
    "demand": 33256.08602,
    "solar_generated": 0,
    "wind_generated": 55.72508885,
    "marketplace_to_demand": 114.5832245,
    "grid_to_demand": 35762.31832,
    "govt_battery_charge": 74.05757406
  },
  {
    "timestamp": "30/03/25 0:00",
    "demand": 31882.5104,
    "solar_generated": 0,
    "wind_generated": 67.61192647,
    "marketplace_to_demand": 124.3176107,
    "grid_to_demand": 30846.78545,
    "govt_battery_charge": 55.17958858
  },
  {
    "timestamp": "30/03/25 1:00",
    "demand": 31999.67646,
    "solar_generated": 0,
    "wind_generated": 68.59272454,
    "marketplace_to_demand": 130.4986231,
    "grid_to_demand": 31651.63652,
    "govt_battery_charge": 91.32676965
  },
  {
    "timestamp": "30/03/25 2:00",
    "demand": 35325.95873,
    "solar_generated": 0,
    "wind_generated": 58.40906391,
    "marketplace_to_demand": 83.81700001,
    "grid_to_demand": 31165.19502,
    "govt_battery_charge": 54.62410075
  },
  {
    "timestamp": "30/03/25 3:00",
    "demand": 30597.32519,
    "solar_generated": 0,
    "wind_generated": 59.84329962,
    "marketplace_to_demand": 117.1399087,
    "grid_to_demand": 34831.22553,
    "govt_battery_charge": 17.9196595
  },
  {
    "timestamp": "30/03/25 4:00",
    "demand": 32203.16021,
    "solar_generated": 0,
    "wind_generated": 72.57565695,
    "marketplace_to_demand": 142.3295493,
    "grid_to_demand": 36232.18925,
    "govt_battery_charge": 34.78431216
  },
  {
    "timestamp": "30/03/25 5:00",
    "demand": 34806.13274,
    "solar_generated": 0,
    "wind_generated": 72.32947086,
    "marketplace_to_demand": 74.66990345,
    "grid_to_demand": 33367.08618,
    "govt_battery_charge": 26.04271114
  },
  {
    "timestamp": "30/03/25 6:00",
    "demand": 32509.63218,
    "solar_generated": 18.88093018,
    "wind_generated": 68.88446073,
    "marketplace_to_demand": 89.96316225,
    "grid_to_demand": 31282.69831,
    "govt_battery_charge": 76.41761668
  },
  {
    "timestamp": "30/03/25 7:00",
    "demand": 33654.85868,
    "solar_generated": 13.42385831,
    "wind_generated": 72.06340795,
    "marketplace_to_demand": 12.7434818,
    "grid_to_demand": 31584.95509,
    "govt_battery_charge": 52.59544866
  },
  {
    "timestamp": "30/03/25 8:00",
    "demand": 34528.91945,
    "solar_generated": 19.70770608,
    "wind_generated": 76.22132698,
    "marketplace_to_demand": 73.86104918,
    "grid_to_demand": 30927.62165,
    "govt_battery_charge": 18.43145389
  },
  {
    "timestamp": "30/03/25 9:00",
    "demand": 31556.87523,
    "solar_generated": 9.833901827,
    "wind_generated": 76.29069054,
    "marketplace_to_demand": 93.74469129,
    "grid_to_demand": 34988.41683,
    "govt_battery_charge": 90.15739268
  },
  {
    "timestamp": "30/03/25 10:00",
    "demand": 30108.13205,
    "solar_generated": 13.94522624,
    "wind_generated": 79.03210231,
    "marketplace_to_demand": 72.19563377,
    "grid_to_demand": 35252.62302,
    "govt_battery_charge": 23.73668942
  },
  {
    "timestamp": "30/03/25 11:00",
    "demand": 33893.09732,
    "solar_generated": 12.98235494,
    "wind_generated": 64.98380464,
    "marketplace_to_demand": 175.6657788,
    "grid_to_demand": 33565.00989,
    "govt_battery_charge": 3.485446529
  },
  {
    "timestamp": "30/03/25 12:00",
    "demand": 31808.29975,
    "solar_generated": 13.18281311,
    "wind_generated": 67.57303539,
    "marketplace_to_demand": 40.75660047,
    "grid_to_demand": 32574.81207,
    "govt_battery_charge": 99.33856658
  },
  {
    "timestamp": "30/03/25 13:00",
    "demand": 30837.42642,
    "solar_generated": 11.25905838,
    "wind_generated": 78.8232229,
    "marketplace_to_demand": 81.11961919,
    "grid_to_demand": 31734.82908,
    "govt_battery_charge": 74.53113106
  },
  {
    "timestamp": "30/03/25 14:00",
    "demand": 30843.34629,
    "solar_generated": 13.05565956,
    "wind_generated": 74.75321035,
    "marketplace_to_demand": 8.996954789,
    "grid_to_demand": 31417.17135,
    "govt_battery_charge": 75.59673853
  },
  {
    "timestamp": "30/03/25 15:00",
    "demand": 36128.81058,
    "solar_generated": 11.37681884,
    "wind_generated": 63.66279564,
    "marketplace_to_demand": 77.68592455,
    "grid_to_demand": 35422.95244,
    "govt_battery_charge": 27.13863132
  },
  {
    "timestamp": "30/03/25 16:00",
    "demand": 34479.72291,
    "solar_generated": 18.68836213,
    "wind_generated": 76.37380621,
    "marketplace_to_demand": 10.59976174,
    "grid_to_demand": 34402.13561,
    "govt_battery_charge": 29.76165354
  },
  {
    "timestamp": "30/03/25 17:00",
    "demand": 33040.6145,
    "solar_generated": 10.22706465,
    "wind_generated": 73.2139868,
    "marketplace_to_demand": 53.82737096,
    "grid_to_demand": 31235.40284,
    "govt_battery_charge": 27.15095166
  },
  {
    "timestamp": "30/03/25 18:00",
    "demand": 36125.44787,
    "solar_generated": 0,
    "wind_generated": 62.54434805,
    "marketplace_to_demand": 103.1082133,
    "grid_to_demand": 34229.03825,
    "govt_battery_charge": 16.95164394
  },
  {
    "timestamp": "30/03/25 19:00",
    "demand": 36613.96593,
    "solar_generated": 0,
    "wind_generated": 54.99203647,
    "marketplace_to_demand": 39.32854645,
    "grid_to_demand": 34201.34211,
    "govt_battery_charge": 25.81119843
  },
  {
    "timestamp": "30/03/25 20:00",
    "demand": 36179.92859,
    "solar_generated": 0,
    "wind_generated": 55.61413958,
    "marketplace_to_demand": 59.11413524,
    "grid_to_demand": 31801.21494,
    "govt_battery_charge": 98.28471108
  },
  {
    "timestamp": "30/03/25 21:00",
    "demand": 30169.85488,
    "solar_generated": 0,
    "wind_generated": 64.89866529,
    "marketplace_to_demand": 5.934940019,
    "grid_to_demand": 31018.40553,
    "govt_battery_charge": 69.77099199
  },
  {
    "timestamp": "30/03/25 22:00",
    "demand": 31785.80589,
    "solar_generated": 0,
    "wind_generated": 55.29856994,
    "marketplace_to_demand": 43.5984912,
    "grid_to_demand": 31804.15928,
    "govt_battery_charge": 24.32619233
  },
  {
    "timestamp": "30/03/25 23:00",
    "demand": 32271.59267,
    "solar_generated": 0,
    "wind_generated": 64.61796681,
    "marketplace_to_demand": 64.42787495,
    "grid_to_demand": 31093.55755,
    "govt_battery_charge": 73.70219715
  },
  {
    "timestamp": "31/03/25 0:00",
    "demand": 31570.8949,
    "solar_generated": 0,
    "wind_generated": 70.07676303,
    "marketplace_to_demand": 21.36632745,
    "grid_to_demand": 34853.5069,
    "govt_battery_charge": 43.95510807
  },
  {
    "timestamp": "31/03/25 1:00",
    "demand": 34319.64405,
    "solar_generated": 0,
    "wind_generated": 62.91838984,
    "marketplace_to_demand": 130.7560976,
    "grid_to_demand": 30615.07382,
    "govt_battery_charge": 85.8912881
  },
  {
    "timestamp": "31/03/25 2:00",
    "demand": 33930.58799,
    "solar_generated": 0,
    "wind_generated": 60.93995722,
    "marketplace_to_demand": 90.77262403,
    "grid_to_demand": 30901.1006,
    "govt_battery_charge": 33.25087461
  },
  {
    "timestamp": "31/03/25 3:00",
    "demand": 32960.80912,
    "solar_generated": 0,
    "wind_generated": 74.93726641,
    "marketplace_to_demand": 99.65777058,
    "grid_to_demand": 36057.7961,
    "govt_battery_charge": 52.39606151
  },
  {
    "timestamp": "31/03/25 4:00",
    "demand": 35713.31534,
    "solar_generated": 0,
    "wind_generated": 62.49323769,
    "marketplace_to_demand": 112.5456085,
    "grid_to_demand": 31262.07582,
    "govt_battery_charge": 47.20332527
  },
  {
    "timestamp": "31/03/25 5:00",
    "demand": 30401.55377,
    "solar_generated": 0,
    "wind_generated": 64.59372844,
    "marketplace_to_demand": 173.9628456,
    "grid_to_demand": 30665.45494,
    "govt_battery_charge": 42.33782532
  },
  {
    "timestamp": "31/03/25 6:00",
    "demand": 32847.6069,
    "solar_generated": 21.83913146,
    "wind_generated": 80.37116239,
    "marketplace_to_demand": 47.0411481,
    "grid_to_demand": 34839.04402,
    "govt_battery_charge": 38.89182744
  },
  {
    "timestamp": "31/03/25 7:00",
    "demand": 29982.48406,
    "solar_generated": 21.46065223,
    "wind_generated": 69.10139076,
    "marketplace_to_demand": 167.3181268,
    "grid_to_demand": 35277.07716,
    "govt_battery_charge": 12.90729173
  },
  {
    "timestamp": "31/03/25 8:00",
    "demand": 31438.27997,
    "solar_generated": 9.280444121,
    "wind_generated": 54.91915353,
    "marketplace_to_demand": 196.6149862,
    "grid_to_demand": 30300.27719,
    "govt_battery_charge": 25.39214366
  },
  {
    "timestamp": "31/03/25 9:00",
    "demand": 36050.57393,
    "solar_generated": 14.21404129,
    "wind_generated": 79.10998671,
    "marketplace_to_demand": 83.99967641,
    "grid_to_demand": 32928.50714,
    "govt_battery_charge": 83.75368218
  },
  {
    "timestamp": "31/03/25 10:00",
    "demand": 30171.84465,
    "solar_generated": 19.27038871,
    "wind_generated": 74.47695026,
    "marketplace_to_demand": 185.5029415,
    "grid_to_demand": 34865.11723,
    "govt_battery_charge": 69.46942629
  },
  {
    "timestamp": "31/03/25 11:00",
    "demand": 33380.20568,
    "solar_generated": 11.20991951,
    "wind_generated": 77.38202101,
    "marketplace_to_demand": 91.49152922,
    "grid_to_demand": 35668.38085,
    "govt_battery_charge": 57.24918947
  },
  {
    "timestamp": "31/03/25 12:00",
    "demand": 35054.67537,
    "solar_generated": 19.01535636,
    "wind_generated": 65.18379833,
    "marketplace_to_demand": 53.23394497,
    "grid_to_demand": 34659.48604,
    "govt_battery_charge": 52.862264
  },
  {
    "timestamp": "31/03/25 13:00",
    "demand": 31564.25345,
    "solar_generated": 19.59725335,
    "wind_generated": 75.46931825,
    "marketplace_to_demand": 56.309324,
    "grid_to_demand": 32942.01372,
    "govt_battery_charge": 85.04596738
  },
  {
    "timestamp": "31/03/25 14:00",
    "demand": 34153.39538,
    "solar_generated": 6.499130289,
    "wind_generated": 61.90833739,
    "marketplace_to_demand": 95.15960312,
    "grid_to_demand": 33824.41036,
    "govt_battery_charge": 53.13726905
  },
  {
    "timestamp": "31/03/25 15:00",
    "demand": 34418.66128,
    "solar_generated": 6.94316663,
    "wind_generated": 56.34427887,
    "marketplace_to_demand": 24.70052852,
    "grid_to_demand": 34696.06571,
    "govt_battery_charge": 38.03193882
  },
  {
    "timestamp": "31/03/25 16:00",
    "demand": 34369.45147,
    "solar_generated": 16.51704445,
    "wind_generated": 70.49883941,
    "marketplace_to_demand": 68.86283996,
    "grid_to_demand": 31811.68211,
    "govt_battery_charge": 8.220918161
  },
  {
    "timestamp": "31/03/25 17:00",
    "demand": 30097.27449,
    "solar_generated": 19.08790703,
    "wind_generated": 64.5935479,
    "marketplace_to_demand": 33.92186658,
    "grid_to_demand": 32957.50637,
    "govt_battery_charge": 74.16822893
  },
  {
    "timestamp": "31/03/25 18:00",
    "demand": 30623.17702,
    "solar_generated": 0,
    "wind_generated": 55.51667505,
    "marketplace_to_demand": 76.37694584,
    "grid_to_demand": 33874.66109,
    "govt_battery_charge": 1
  },
  {
    "timestamp": "31/03/25 19:00",
    "demand": 32359.34632,
    "solar_generated": 0,
    "wind_generated": 53.99399042,
    "marketplace_to_demand": 59.49890875,
    "grid_to_demand": 32906.48856,
    "govt_battery_charge": 31.13322756
  },
  {
    "timestamp": "31/03/25 20:00",
    "demand": 31214.38295,
    "solar_generated": 0,
    "wind_generated": 72.42526956,
    "marketplace_to_demand": 150.8760356,
    "grid_to_demand": 30269.62747,
    "govt_battery_charge": 76.88495421
  },
  {
    "timestamp": "31/03/25 21:00",
    "demand": 36484.94783,
    "solar_generated": 0,
    "wind_generated": 54.07368366,
    "marketplace_to_demand": 169.9412739,
    "grid_to_demand": 35539.80644,
    "govt_battery_charge": 86.22891878
  },
  {
    "timestamp": "31/03/25 22:00",
    "demand": 36286.52592,
    "solar_generated": 0,
    "wind_generated": 59.24275254,
    "marketplace_to_demand": 136.9779359,
    "grid_to_demand": 33232.57824,
    "govt_battery_charge": 10.76181771
  },
  {
    "timestamp": "31/03/25 23:00",
    "demand": 32251.95738,
    "solar_generated": 0,
    "wind_generated": 59.3367993,
    "marketplace_to_demand": 46.57357434,
    "grid_to_demand": 32515.52525,
    "govt_battery_charge": 22.85893944
  },
  {
    "timestamp": "01/04/25 0:00",
    "demand": 34193.44417,
    "solar_generated": 0,
    "wind_generated": 54.19469613,
    "marketplace_to_demand": 76.12645583,
    "grid_to_demand": 33911.48246,
    "govt_battery_charge": 18.67113672
  }
]

export const swData = [
  {
    "solar_panels": 100,
    "wind_turbines": 10,
    "price": 0.15
  },
  {
    "solar_panels": 100,
    "wind_turbines": 20,
    "price": 0.14
  },
  {
    "solar_panels": 200,
    "wind_turbines": 10,
    "price": 0.13
  },
  {
    "solar_panels": 200,
    "wind_turbines": 20,
    "price": 0.12
  },
  {
    "solar_panels": 300,
    "wind_turbines": 15,
    "price": 0.11
  },
  {
    "solar_panels": 400,
    "wind_turbines": 25,
    "price": 0.10
  },
  {
    "solar_panels": 450,
    "wind_turbines": 30,
    "price": 0.095
  },
  {
    "solar_panels": 500,
    "wind_turbines": 35,
    "price": 0.09
  },
  {
    "solar_panels": 600,
    "wind_turbines": 40,
    "price": 0.085
  },
  {
    "solar_panels": 650,
    "wind_turbines": 45,
    "price": 0.082
  },
  {
    "solar_panels": 700,
    "wind_turbines": 50,
    "price": 0.08
  },
  {
    "solar_panels": 750,
    "wind_turbines": 55,
    "price": 0.078
  },
  {
    "solar_panels": 800,
    "wind_turbines": 60,
    "price": 0.075
  },
  {
    "solar_panels": 850,
    "wind_turbines": 65,
    "price": 0.073
  },
  {
    "solar_panels": 900,
    "wind_turbines": 70,
    "price": 0.071
  },
  {
    "solar_panels": 950,
    "wind_turbines": 75,
    "price": 0.069
  },
  {
    "solar_panels": 1000,
    "wind_turbines": 80,
    "price": 0.067
  },
  {
    "solar_panels": 1050,
    "wind_turbines": 85,
    "price": 0.065
  },
  {
    "solar_panels": 1100,
    "wind_turbines": 90,
    "price": 0.063
  },
  {
    "solar_panels": 1150,
    "wind_turbines": 95,
    "price": 0.061
  },
  {
    "solar_panels": 1200,
    "wind_turbines": 100,
    "price": 0.06
  },
  {
    "solar_panels": 1250,
    "wind_turbines": 105,
    "price": 0.058
  },
  {
    "solar_panels": 1300,
    "wind_turbines": 110,
    "price": 0.057
  },
  {
    "solar_panels": 1350,
    "wind_turbines": 115,
    "price": 0.055
  },
  {
    "solar_panels": 1400,
    "wind_turbines": 120,
    "price": 0.054
  },
  {
    "solar_panels": 1450,
    "wind_turbines": 125,
    "price": 0.053
  },
  {
    "solar_panels": 1500,
    "wind_turbines": 130,
    "price": 0.051
  },
  {
    "solar_panels": 1550,
    "wind_turbines": 135,
    "price": 0.05
  },
  {
    "solar_panels": 1600,
    "wind_turbines": 140,
    "price": 0.049
  },
  {
    "solar_panels": 1650,
    "wind_turbines": 145,
    "price": 0.048
  },
  {
    "solar_panels": 1700,
    "wind_turbines": 150,
    "price": 0.047
  },
  {
    "solar_panels": 1750,
    "wind_turbines": 155,
    "price": 0.046
  },
  {
    "solar_panels": 1800,
    "wind_turbines": 160,
    "price": 0.045
  },
  {
    "solar_panels": 1850,
    "wind_turbines": 165,
    "price": 0.044
  },
  {
    "solar_panels": 1900,
    "wind_turbines": 170,
    "price": 0.043
  },
  {
    "solar_panels": 1950,
    "wind_turbines": 175,
    "price": 0.042
  },
  {
    "solar_panels": 2000,
    "wind_turbines": 180,
    "price": 0.041
  },
  {
    "solar_panels": 2050,
    "wind_turbines": 185,
    "price": 0.04
  },
  {
    "solar_panels": 2100,
    "wind_turbines": 190,
    "price": 0.039
  },
  {
    "solar_panels": 2150,
    "wind_turbines": 195,
    "price": 0.038
  },
  {
    "solar_panels": 2200,
    "wind_turbines": 200,
    "price": 0.037
  },
  {
    "solar_panels": 2250,
    "wind_turbines": 205,
    "price": 0.036
  },
  {
    "solar_panels": 2300,
    "wind_turbines": 210,
    "price": 0.035
  },
  {
    "solar_panels": 2350,
    "wind_turbines": 215,
    "price": 0.034
  },
  {
    "solar_panels": 2400,
    "wind_turbines": 220,
    "price": 0.033
  },
  {
    "solar_panels": 2450,
    "wind_turbines": 225,
    "price": 0.032
  },
  {
    "solar_panels": 2500,
    "wind_turbines": 230,
    "price": 0.031
  },
  {
    "solar_panels": 2550,
    "wind_turbines": 235,
    "price": 0.03
  }
]

export const clientReportsData = [
  {
    Id: 1,
    SiteName: "Japan",
    Date: "02-02-2009",
    EvaluationManager: "Arpit",
    DownloadLink: "http://download1.com",
  },
  {
    Id: 2,
    SiteName: "Germany",
    Date: "15-05-2010",
    EvaluationManager: "John",
    DownloadLink: "http://download2.com",
  },
  {
    Id: 3,
    SiteName: "USA",
    Date: "22-08-2011",
    EvaluationManager: "Alice",
    DownloadLink: "http://download3.com",
  },
  {
    Id: 4,
    SiteName: "France",
    Date: "03-11-2012",
    EvaluationManager: "Bob",
    DownloadLink: "http://download4.com",
  },
  {
    Id: 5,
    SiteName: "India",
    Date: "12-01-2013",
    EvaluationManager: "Eve",
    DownloadLink: "http://download5.com",
  },
  {
    Id: 6,
    SiteName: "China",
    Date: "19-04-2014",
    EvaluationManager: "Charlie",
    DownloadLink: "http://download6.com",
  },
  {
    Id: 7,
    SiteName: "Brazil",
    Date: "28-07-2015",
    EvaluationManager: "David",
    DownloadLink: "http://download7.com",
  },
  {
    Id: 8,
    SiteName: "Australia",
    Date: "09-10-2016",
    EvaluationManager: "Fiona",
    DownloadLink: "http://download8.com",
  },
  {
    Id: 9,
    SiteName: "Canada",
    Date: "21-03-2017",
    EvaluationManager: "Grace",
    DownloadLink: "http://download9.com",
  },
  {
    Id: 10,
    SiteName: "Russia",
    Date: "17-06-2018",
    EvaluationManager: "Heidi",
    DownloadLink: "http://download10.com",
  },
  {
    Id: 11,
    SiteName: "UK",
    Date: "25-09-2019",
    EvaluationManager: "Ivy",
    DownloadLink: "http://download11.com",
  },
  {
    Id: 12,
    SiteName: "Italy",
    Date: "13-12-2020",
    EvaluationManager: "Jack",
    DownloadLink: "http://download12.com",
  },
  {
    Id: 13,
    SiteName: "South Korea",
    Date: "04-02-2021",
    EvaluationManager: "Karen",
    DownloadLink: "http://download13.com",
  },
  {
    Id: 14,
    SiteName: "Mexico",
    Date: "31-07-2022",
    EvaluationManager: "Leo",
    DownloadLink: "http://download14.com",
  },
  {
    Id: 15,
    SiteName: "Spain",
    Date: "15-11-2023",
    EvaluationManager: "Mallory",
    DownloadLink: "http://download15.com",
  },
];

export const sampleData = [
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 1,
    "Net Demand": 33296,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 2,
    "Net Demand": 33134,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 3,
    "Net Demand": 32849,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 4,
    "Net Demand": 34630,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 5,
    "Net Demand": 33422,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 6,
    "Net Demand": 33774,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 7,
    "Net Demand": 33103,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 8,
    "Net Demand": 32370,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 9,
    "Net Demand": 30355,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 10,
    "Net Demand": 24378,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 11,
    "Net Demand": 20876,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 12,
    "Net Demand": 25574,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 13,
    "Net Demand": 26196,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 14,
    "Net Demand": 21840,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 15,
    "Net Demand": 22997,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 16,
    "Net Demand": 24490,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 17,
    "Net Demand": 28176,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 18,
    "Net Demand": 29377,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 19,
    "Net Demand": 28777,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 20,
    "Net Demand": 28967,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 21,
    "Net Demand": 28355,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 22,
    "Net Demand": 29344,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 1,
    Hour: 24,
    "Net Demand": 29091,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 1,
    "Net Demand": 26504,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 2,
    "Net Demand": 21193,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 3,
    "Net Demand": 19039,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 4,
    "Net Demand": 19784,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 5,
    "Net Demand": 19706,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 6,
    "Net Demand": 20713,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 7,
    "Net Demand": 17152,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 8,
    "Net Demand": 15839,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 9,
    "Net Demand": 17816,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 10,
    "Net Demand": 17297,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 11,
    "Net Demand": 16805,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 12,
    "Net Demand": 16614,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 13,
    "Net Demand": 17533,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 14,
    "Net Demand": 16587,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 15,
    "Net Demand": 11604,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 16,
    "Net Demand": 11504,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 17,
    "Net Demand": 11950,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 18,
    "Net Demand": 12424,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 19,
    "Net Demand": 12188,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 20,
    "Net Demand": 13055,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 21,
    "Net Demand": 12502,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 22,
    "Net Demand": 11694,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 23,
    "Net Demand": 12200,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 2,
    Hour: 24,
    "Net Demand": 11764,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 1,
    "Net Demand": 10828,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 2,
    "Net Demand": 10882,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 3,
    "Net Demand": 10746,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 4,
    "Net Demand": 10776,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 5,
    "Net Demand": 10453,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 6,
    "Net Demand": 10394,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 7,
    "Net Demand": 9696,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 8,
    "Net Demand": 9153,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 9,
    "Net Demand": 8760,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 10,
    "Net Demand": 8353,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 11,
    "Net Demand": 6900,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 12,
    "Net Demand": 7882,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 13,
    "Net Demand": 6683,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 14,
    "Net Demand": 6189,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 15,
    "Net Demand": 7485,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 16,
    "Net Demand": 7551,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 17,
    "Net Demand": 9580,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 18,
    "Net Demand": 9493,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 19,
    "Net Demand": 9707,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 20,
    "Net Demand": 10823,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 21,
    "Net Demand": 10770,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 22,
    "Net Demand": 10793,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 23,
    "Net Demand": 11201,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 3,
    Hour: 24,
    "Net Demand": 13527,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 1,
    "Net Demand": 18847,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 2,
    "Net Demand": 16229,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 3,
    "Net Demand": 18758,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 4,
    "Net Demand": 19159,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 5,
    "Net Demand": 19056,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 6,
    "Net Demand": 19012,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 7,
    "Net Demand": 19593,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 8,
    "Net Demand": 20099,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 9,
    "Net Demand": 21315,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 10,
    "Net Demand": 18887,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 11,
    "Net Demand": 8309,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 12,
    "Net Demand": 8259,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 13,
    "Net Demand": 9524,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 14,
    "Net Demand": 12521,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 15,
    "Net Demand": 17267,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 16,
    "Net Demand": 21226,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 17,
    "Net Demand": 23597,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 18,
    "Net Demand": 24752,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 19,
    "Net Demand": 26877,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 20,
    "Net Demand": 29569,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 21,
    "Net Demand": 32576,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 22,
    "Net Demand": 31041,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 23,
    "Net Demand": 31379,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 4,
    Hour: 24,
    "Net Demand": 31805,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 1,
    "Net Demand": 33419,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 2,
    "Net Demand": 32531,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 3,
    "Net Demand": 32770,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 4,
    "Net Demand": 33250,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 5,
    "Net Demand": 32523,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 6,
    "Net Demand": 25739,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 7,
    "Net Demand": 29399,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 8,
    "Net Demand": 31095,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 9,
    "Net Demand": 30423,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 10,
    "Net Demand": 30074,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 11,
    "Net Demand": 27649,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 12,
    "Net Demand": 28605,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 13,
    "Net Demand": 29220,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 14,
    "Net Demand": 28798,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 15,
    "Net Demand": 28788,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 16,
    "Net Demand": 29908,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 17,
    "Net Demand": 32885,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 18,
    "Net Demand": 33749,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 19,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 20,
    "Net Demand": 35055,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 21,
    "Net Demand": 34556,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 22,
    "Net Demand": 34519,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 23,
    "Net Demand": 35038,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 5,
    Hour: 24,
    "Net Demand": 33569,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 1,
    "Net Demand": 33742,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 2,
    "Net Demand": 33563,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 3,
    "Net Demand": 32736,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 4,
    "Net Demand": 33511,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 5,
    "Net Demand": 32873,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 6,
    "Net Demand": 33582,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 7,
    "Net Demand": 34219,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 8,
    "Net Demand": 32698,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 9,
    "Net Demand": 30738,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 10,
    "Net Demand": 29640,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 11,
    "Net Demand": 25219,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 12,
    "Net Demand": 27423,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 13,
    "Net Demand": 26692,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 14,
    "Net Demand": 28028,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 15,
    "Net Demand": 28591,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 16,
    "Net Demand": 29854,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 17,
    "Net Demand": 31692,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 18,
    "Net Demand": 30959,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 19,
    "Net Demand": 30431,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 20,
    "Net Demand": 34708,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 21,
    "Net Demand": 35125,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 22,
    "Net Demand": 34605,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 23,
    "Net Demand": 34381,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 6,
    Hour: 24,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 1,
    "Net Demand": 32708,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 2,
    "Net Demand": 32032,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 3,
    "Net Demand": 31214,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 4,
    "Net Demand": 33286,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 5,
    "Net Demand": 34034,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 6,
    "Net Demand": 32829,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 7,
    "Net Demand": 31370,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 8,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 9,
    "Net Demand": 31142,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 10,
    "Net Demand": 31553,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 11,
    "Net Demand": 31631,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 12,
    "Net Demand": 30988,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 13,
    "Net Demand": 29572,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 14,
    "Net Demand": 28812,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 15,
    "Net Demand": 29196,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 16,
    "Net Demand": 28953,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 17,
    "Net Demand": 32759,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 18,
    "Net Demand": 33837,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 19,
    "Net Demand": 32851,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 20,
    "Net Demand": 34013,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 21,
    "Net Demand": 33566,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 22,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 23,
    "Net Demand": 32391,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 7,
    Hour: 24,
    "Net Demand": 32863,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 1,
    "Net Demand": 32199,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 2,
    "Net Demand": 32353,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 3,
    "Net Demand": 31193,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 4,
    "Net Demand": 33787,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 5,
    "Net Demand": 33542,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 6,
    "Net Demand": 32702,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 7,
    "Net Demand": 31795,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 8,
    "Net Demand": 31170,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 9,
    "Net Demand": 30358,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 10,
    "Net Demand": 28957,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 11,
    "Net Demand": 26872,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 12,
    "Net Demand": 28440,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 13,
    "Net Demand": 29420,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 14,
    "Net Demand": 29849,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 15,
    "Net Demand": 29538,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 16,
    "Net Demand": 30862,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 17,
    "Net Demand": 27345,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 18,
    "Net Demand": 27080,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 19,
    "Net Demand": 34081,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 20,
    "Net Demand": 35285,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 21,
    "Net Demand": 34473,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 22,
    "Net Demand": 33349,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 23,
    "Net Demand": 32150,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 8,
    Hour: 24,
    "Net Demand": 32349,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 1,
    "Net Demand": 32669,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 2,
    "Net Demand": 32049,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 3,
    "Net Demand": 35161,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 4,
    "Net Demand": 35081,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 5,
    "Net Demand": 33910,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 6,
    "Net Demand": 33469,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 7,
    "Net Demand": 33668,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 8,
    "Net Demand": 33413,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 9,
    "Net Demand": 31583,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 10,
    "Net Demand": 26845,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 11,
    "Net Demand": 23319,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 12,
    "Net Demand": 25439,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 13,
    "Net Demand": 25226,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 14,
    "Net Demand": 23559,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 15,
    "Net Demand": 24562,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 16,
    "Net Demand": 26520,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 17,
    "Net Demand": 29134,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 18,
    "Net Demand": 27965,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 19,
    "Net Demand": 27995,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 20,
    "Net Demand": 28705,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 21,
    "Net Demand": 28011,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 22,
    "Net Demand": 29266,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 23,
    "Net Demand": 27605,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 9,
    Hour: 24,
    "Net Demand": 27596,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 1,
    "Net Demand": 27468,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 2,
    "Net Demand": 26380,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 3,
    "Net Demand": 25872,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 4,
    "Net Demand": 26629,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 5,
    "Net Demand": 27249,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 6,
    "Net Demand": 25026,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 7,
    "Net Demand": 26145,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 8,
    "Net Demand": 24973,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 9,
    "Net Demand": 24670,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 10,
    "Net Demand": 22504,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 11,
    "Net Demand": 21981,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 12,
    "Net Demand": 22150,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 13,
    "Net Demand": 22060,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 14,
    "Net Demand": 23125,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 15,
    "Net Demand": 23576,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 16,
    "Net Demand": 25803,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 17,
    "Net Demand": 27020,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 18,
    "Net Demand": 28383,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 19,
    "Net Demand": 28928,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 20,
    "Net Demand": 28481,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 21,
    "Net Demand": 27320,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 22,
    "Net Demand": 27296,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 23,
    "Net Demand": 28394,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 10,
    Hour: 24,
    "Net Demand": 27772,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 1,
    "Net Demand": 27559,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 2,
    "Net Demand": 27374,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 3,
    "Net Demand": 27069,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 4,
    "Net Demand": 28396,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 5,
    "Net Demand": 28358,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 6,
    "Net Demand": 27210,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 7,
    "Net Demand": 27545,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 8,
    "Net Demand": 27006,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 9,
    "Net Demand": 25585,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 10,
    "Net Demand": 25410,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 11,
    "Net Demand": 22408,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 12,
    "Net Demand": 24557,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 13,
    "Net Demand": 27699,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 14,
    "Net Demand": 26449,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 15,
    "Net Demand": 25947,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 16,
    "Net Demand": 28444,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 17,
    "Net Demand": 29412,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 18,
    "Net Demand": 28175,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 19,
    "Net Demand": 26852,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 20,
    "Net Demand": 27777,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 21,
    "Net Demand": 27462,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 22,
    "Net Demand": 28389,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 23,
    "Net Demand": 32640,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 11,
    Hour: 24,
    "Net Demand": 32903,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 1,
    "Net Demand": 31748,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 2,
    "Net Demand": 31935,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 3,
    "Net Demand": 31207,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 4,
    "Net Demand": 31376,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 5,
    "Net Demand": 32073,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 6,
    "Net Demand": 32062,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 7,
    "Net Demand": 32456,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 8,
    "Net Demand": 31584,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 9,
    "Net Demand": 28627,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 10,
    "Net Demand": 27167,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 11,
    "Net Demand": 26667,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 12,
    "Net Demand": 26958,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 13,
    "Net Demand": 27264,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 14,
    "Net Demand": 27582,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 15,
    "Net Demand": 25444,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 16,
    "Net Demand": 18944,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 17,
    "Net Demand": 19016,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 18,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 19,
    "Net Demand": 20575,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 20,
    "Net Demand": 24182,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 21,
    "Net Demand": 31632,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 22,
    "Net Demand": 33676,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 23,
    "Net Demand": 33627,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 12,
    Hour: 24,
    "Net Demand": 33123,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 1,
    "Net Demand": 32432,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 2,
    "Net Demand": 31204,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 3,
    "Net Demand": 30738,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 4,
    "Net Demand": 30522,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 5,
    "Net Demand": 31646,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 6,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 7,
    "Net Demand": 31310,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 8,
    "Net Demand": 29853,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 9,
    "Net Demand": 29169,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 10,
    "Net Demand": 27890,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 11,
    "Net Demand": 26989,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 12,
    "Net Demand": 27399,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 13,
    "Net Demand": 27927,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 14,
    "Net Demand": 28646,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 15,
    "Net Demand": 30257,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 16,
    "Net Demand": 31701,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 17,
    "Net Demand": 31992,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 18,
    "Net Demand": 32035,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 19,
    "Net Demand": 31745,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 20,
    "Net Demand": 33010,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 21,
    "Net Demand": 30250,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 22,
    "Net Demand": 27629,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 23,
    "Net Demand": 30800,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 13,
    Hour: 24,
    "Net Demand": 34725,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 1,
    "Net Demand": 34261,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 2,
    "Net Demand": 33175,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 3,
    "Net Demand": 31610,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 4,
    "Net Demand": 32368,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 5,
    "Net Demand": 31939,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 6,
    "Net Demand": 32392,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 7,
    "Net Demand": 31690,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 8,
    "Net Demand": 30904,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 9,
    "Net Demand": 31294,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 10,
    "Net Demand": 30226,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 11,
    "Net Demand": 28688,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 12,
    "Net Demand": 29292,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 13,
    "Net Demand": 30131,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 14,
    "Net Demand": 28798,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 15,
    "Net Demand": 26947,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 16,
    "Net Demand": 30218,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 17,
    "Net Demand": 31979,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 18,
    "Net Demand": 32417,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 19,
    "Net Demand": 31878,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 20,
    "Net Demand": 32996,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 21,
    "Net Demand": 34078,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 22,
    "Net Demand": 32079,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 23,
    "Net Demand": 31700,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 14,
    Hour: 24,
    "Net Demand": 30968,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 1,
    "Net Demand": 32058,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 2,
    "Net Demand": 31266,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 3,
    "Net Demand": 30608,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 4,
    "Net Demand": 31545,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 5,
    "Net Demand": 31966,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 6,
    "Net Demand": 30897,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 7,
    "Net Demand": 29928,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 8,
    "Net Demand": 30413,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 9,
    "Net Demand": 29178,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 10,
    "Net Demand": 18015,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 11,
    "Net Demand": 14033,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 12,
    "Net Demand": 14531,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 13,
    "Net Demand": 14737,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 14,
    "Net Demand": 14813,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 15,
    "Net Demand": 15420,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 16,
    "Net Demand": 16036,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 17,
    "Net Demand": 17286,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 18,
    "Net Demand": 17904,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 19,
    "Net Demand": 18477,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 20,
    "Net Demand": 18611,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 21,
    "Net Demand": 21101,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 22,
    "Net Demand": 26874,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 23,
    "Net Demand": 30352,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 15,
    Hour: 24,
    "Net Demand": 31524,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 1,
    "Net Demand": 30965,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 2,
    "Net Demand": 31231,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 3,
    "Net Demand": 30414,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 4,
    "Net Demand": 29814,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 5,
    "Net Demand": 29282,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 6,
    "Net Demand": 30314,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 7,
    "Net Demand": 30839,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 8,
    "Net Demand": 29937,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 9,
    "Net Demand": 27612,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 10,
    "Net Demand": 27098,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 11,
    "Net Demand": 27001,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 12,
    "Net Demand": 26646,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 13,
    "Net Demand": 26370,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 14,
    "Net Demand": 27705,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 15,
    "Net Demand": 22010,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 16,
    "Net Demand": 20510,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 17,
    "Net Demand": 21544,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 18,
    "Net Demand": 22486,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 19,
    "Net Demand": 22275,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 20,
    "Net Demand": 23441,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 21,
    "Net Demand": 23304,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 22,
    "Net Demand": 23642,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 23,
    "Net Demand": 24348,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 16,
    Hour: 24,
    "Net Demand": 27574,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 1,
    "Net Demand": 33010,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 2,
    "Net Demand": 33097,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 3,
    "Net Demand": 32350,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 4,
    "Net Demand": 33331,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 5,
    "Net Demand": 32167,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 6,
    "Net Demand": 32064,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 7,
    "Net Demand": 31690,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 8,
    "Net Demand": 31231,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 9,
    "Net Demand": 30310,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 10,
    "Net Demand": 22249,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 11,
    "Net Demand": 19826,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 12,
    "Net Demand": 19794,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 13,
    "Net Demand": 19388,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 14,
    "Net Demand": 19793,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 15,
    "Net Demand": 20405,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 16,
    "Net Demand": 21381,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 17,
    "Net Demand": 20994,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 18,
    "Net Demand": 16125,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 19,
    "Net Demand": 15024,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 20,
    "Net Demand": 19438,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 21,
    "Net Demand": 18596,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 22,
    "Net Demand": 19069,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 23,
    "Net Demand": 22874,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 17,
    Hour: 24,
    "Net Demand": 24164,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 1,
    "Net Demand": 22727,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 2,
    "Net Demand": 23058,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 3,
    "Net Demand": 22961,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 4,
    "Net Demand": 22175,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 5,
    "Net Demand": 23005,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 6,
    "Net Demand": 23048,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 7,
    "Net Demand": 22159,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 8,
    "Net Demand": 21184,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 9,
    "Net Demand": 20671,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 10,
    "Net Demand": 19818,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 11,
    "Net Demand": 18287,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 12,
    "Net Demand": 17822,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 13,
    "Net Demand": 17390,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 14,
    "Net Demand": 18621,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 15,
    "Net Demand": 19614,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 16,
    "Net Demand": 19999,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 17,
    "Net Demand": 21737,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 18,
    "Net Demand": 22814,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 19,
    "Net Demand": 23502,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 20,
    "Net Demand": 24749,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 21,
    "Net Demand": 28226,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 22,
    "Net Demand": 32194,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 23,
    "Net Demand": 32557,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 18,
    Hour: 24,
    "Net Demand": 33426,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 1,
    "Net Demand": 28654,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 2,
    "Net Demand": 27491,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 3,
    "Net Demand": 26034,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 4,
    "Net Demand": 27004,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 5,
    "Net Demand": 26870,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 6,
    "Net Demand": 25746,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 7,
    "Net Demand": 24272,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 8,
    "Net Demand": 24255,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 9,
    "Net Demand": 23302,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 10,
    "Net Demand": 21782,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 11,
    "Net Demand": 20417,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 12,
    "Net Demand": 20710,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 13,
    "Net Demand": 18618,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 14,
    "Net Demand": 15654,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 15,
    "Net Demand": 15620,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 16,
    "Net Demand": 16871,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 17,
    "Net Demand": 23119,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 18,
    "Net Demand": 26322,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 19,
    "Net Demand": 26926,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 20,
    "Net Demand": 27912,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 21,
    "Net Demand": 26999,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 22,
    "Net Demand": 26374,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 23,
    "Net Demand": 26029,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 19,
    Hour: 24,
    "Net Demand": 26982,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 1,
    "Net Demand": 28409,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 2,
    "Net Demand": 28437,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 3,
    "Net Demand": 29252,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 4,
    "Net Demand": 31083,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 5,
    "Net Demand": 30054,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 6,
    "Net Demand": 26389,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 7,
    "Net Demand": 21058,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 8,
    "Net Demand": 22396,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 9,
    "Net Demand": 20777,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 10,
    "Net Demand": 19133,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 11,
    "Net Demand": 20887,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 12,
    "Net Demand": 19329,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 13,
    "Net Demand": 21261,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 14,
    "Net Demand": 20195,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 15,
    "Net Demand": 20907,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 16,
    "Net Demand": 21635,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 17,
    "Net Demand": 23342,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 18,
    "Net Demand": 25071,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 19,
    "Net Demand": 24940,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 20,
    "Net Demand": 25570,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 21,
    "Net Demand": 25242,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 22,
    "Net Demand": 25315,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 23,
    "Net Demand": 25211,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 20,
    Hour: 24,
    "Net Demand": 26008,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 1,
    "Net Demand": 32404,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 2,
    "Net Demand": 32449,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 3,
    "Net Demand": 32137,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 4,
    "Net Demand": 32998,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 5,
    "Net Demand": 33285,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 6,
    "Net Demand": 24990,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 7,
    "Net Demand": 27473,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 8,
    "Net Demand": 30531,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 9,
    "Net Demand": 29393,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 10,
    "Net Demand": 30314,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 11,
    "Net Demand": 21889,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 12,
    "Net Demand": 27858,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 13,
    "Net Demand": 27702,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 14,
    "Net Demand": 28442,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 15,
    "Net Demand": 27154,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 16,
    "Net Demand": 24715,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 17,
    "Net Demand": 25624,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 18,
    "Net Demand": 28764,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 19,
    "Net Demand": 31691,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 20,
    "Net Demand": 29543,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 21,
    "Net Demand": 28907,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 22,
    "Net Demand": 32636,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 23,
    "Net Demand": 24394,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 21,
    Hour: 24,
    "Net Demand": 34050,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 1,
    "Net Demand": 33893,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 2,
    "Net Demand": 33973,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 3,
    "Net Demand": 33129,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 4,
    "Net Demand": 33236,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 5,
    "Net Demand": 33475,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 6,
    "Net Demand": 29881,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 7,
    "Net Demand": 33362,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 8,
    "Net Demand": 29395,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 9,
    "Net Demand": 22135,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 10,
    "Net Demand": 19992,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 11,
    "Net Demand": 20776,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 12,
    "Net Demand": 21404,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 13,
    "Net Demand": 20801,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 14,
    "Net Demand": 20513,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 15,
    "Net Demand": 20559,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 16,
    "Net Demand": 21963,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 17,
    "Net Demand": 23849,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 18,
    "Net Demand": 22853,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 19,
    "Net Demand": 23034,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 20,
    "Net Demand": 24955,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 21,
    "Net Demand": 24422,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 22,
    "Net Demand": 23967,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 23,
    "Net Demand": 24366,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 22,
    Hour: 24,
    "Net Demand": 24711,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 1,
    "Net Demand": 24339,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 2,
    "Net Demand": 24189,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 3,
    "Net Demand": 23146,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 4,
    "Net Demand": 23405,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 5,
    "Net Demand": 23042,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 6,
    "Net Demand": 23576,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 7,
    "Net Demand": 23919,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 8,
    "Net Demand": 23568,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 9,
    "Net Demand": 21697,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 10,
    "Net Demand": 21172,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 11,
    "Net Demand": 21325,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 12,
    "Net Demand": 20585,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 13,
    "Net Demand": 19587,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 14,
    "Net Demand": 19878,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 15,
    "Net Demand": 20276,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 16,
    "Net Demand": 21528,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 17,
    "Net Demand": 22513,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 18,
    "Net Demand": 23756,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 19,
    "Net Demand": 24167,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 20,
    "Net Demand": 25312,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 21,
    "Net Demand": 25531,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 22,
    "Net Demand": 23956,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 23,
    "Net Demand": 24029,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 23,
    Hour: 24,
    "Net Demand": 24059,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 1,
    "Net Demand": 24364,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 2,
    "Net Demand": 24389,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 3,
    "Net Demand": 23761,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 4,
    "Net Demand": 24053,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 5,
    "Net Demand": 24031,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 6,
    "Net Demand": 24727,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 7,
    "Net Demand": 31420,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 8,
    "Net Demand": 31660,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 9,
    "Net Demand": 30266,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 10,
    "Net Demand": 29681,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 11,
    "Net Demand": 28442,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 12,
    "Net Demand": 24012,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 13,
    "Net Demand": 30522,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 14,
    "Net Demand": 29475,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 15,
    "Net Demand": 29256,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 16,
    "Net Demand": 30428,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 17,
    "Net Demand": 30998,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 18,
    "Net Demand": 31588,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 19,
    "Net Demand": 32139,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 20,
    "Net Demand": 32844,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 21,
    "Net Demand": 31941,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 22,
    "Net Demand": 32082,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 23,
    "Net Demand": 31025,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 24,
    Hour: 24,
    "Net Demand": 31479,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 1,
    "Net Demand": 31894,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 2,
    "Net Demand": 32203,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 3,
    "Net Demand": 31400,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 4,
    "Net Demand": 32214,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 5,
    "Net Demand": 32106,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 6,
    "Net Demand": 32117,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 7,
    "Net Demand": 31328,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 8,
    "Net Demand": 32248,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 9,
    "Net Demand": 28789,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 10,
    "Net Demand": 24697,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 11,
    "Net Demand": 23541,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 12,
    "Net Demand": 23825,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 13,
    "Net Demand": 24488,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 14,
    "Net Demand": 24223,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 15,
    "Net Demand": 24178,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 16,
    "Net Demand": 23962,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 17,
    "Net Demand": 25800,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 18,
    "Net Demand": 27014,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 19,
    "Net Demand": 27132,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 20,
    "Net Demand": 27654,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 21,
    "Net Demand": 27777,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 22,
    "Net Demand": 27149,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 23,
    "Net Demand": 28297,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 25,
    Hour: 24,
    "Net Demand": 23808,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 1,
    "Net Demand": 26976,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 2,
    "Net Demand": 22546,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 3,
    "Net Demand": 22551,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 4,
    "Net Demand": 23120,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 5,
    "Net Demand": 23409,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 6,
    "Net Demand": 23609,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 7,
    "Net Demand": 23909,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 8,
    "Net Demand": 21477,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 9,
    "Net Demand": 21478,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 10,
    "Net Demand": 22033,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 11,
    "Net Demand": 21796,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 12,
    "Net Demand": 22676,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 13,
    "Net Demand": 23007,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 14,
    "Net Demand": 23387,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 15,
    "Net Demand": 24004,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 16,
    "Net Demand": 23606,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 17,
    "Net Demand": 25756,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 18,
    "Net Demand": 28168,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 19,
    "Net Demand": 29002,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 20,
    "Net Demand": 32724,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 21,
    "Net Demand": 32924,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 22,
    "Net Demand": 35946,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 23,
    "Net Demand": 32130,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 26,
    Hour: 24,
    "Net Demand": 27988,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 1,
    "Net Demand": 27714,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 2,
    "Net Demand": 24885,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 3,
    "Net Demand": 20596,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 4,
    "Net Demand": 27635,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 5,
    "Net Demand": 27984,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 6,
    "Net Demand": 27885,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 7,
    "Net Demand": 27112,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 8,
    "Net Demand": 26131,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 9,
    "Net Demand": 26351,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 10,
    "Net Demand": 27913,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 11,
    "Net Demand": 26433,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 12,
    "Net Demand": 26109,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 13,
    "Net Demand": 26332,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 14,
    "Net Demand": 26671,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 15,
    "Net Demand": 27936,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 16,
    "Net Demand": 28751,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 17,
    "Net Demand": 30946,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 18,
    "Net Demand": 31397,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 19,
    "Net Demand": 30716,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 20,
    "Net Demand": 31926,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 21,
    "Net Demand": 31372,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 22,
    "Net Demand": 30699,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 23,
    "Net Demand": 31589,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 27,
    Hour: 24,
    "Net Demand": 33212,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 1,
    "Net Demand": 31913,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 2,
    "Net Demand": 31679,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 3,
    "Net Demand": 31966,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 4,
    "Net Demand": 32958,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 5,
    "Net Demand": 32876,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 6,
    "Net Demand": 32686,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 7,
    "Net Demand": 30421,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 8,
    "Net Demand": 23056,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 9,
    "Net Demand": 18445,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 10,
    "Net Demand": 18965,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 11,
    "Net Demand": 20898,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 12,
    "Net Demand": 21986,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 13,
    "Net Demand": 22340,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 14,
    "Net Demand": 21403,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 15,
    "Net Demand": 21654,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 16,
    "Net Demand": 21882,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 17,
    "Net Demand": 23444,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 18,
    "Net Demand": 22314,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 19,
    "Net Demand": 23668,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 20,
    "Net Demand": 23850,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 21,
    "Net Demand": 23761,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 22,
    "Net Demand": 24025,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 23,
    "Net Demand": 21724,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 28,
    Hour: 24,
    "Net Demand": 23957,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 1,
    "Net Demand": 25021,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 2,
    "Net Demand": 24090,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 3,
    "Net Demand": 23310,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 4,
    "Net Demand": 23460,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 5,
    "Net Demand": 23795,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 6,
    "Net Demand": 25336,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 7,
    "Net Demand": 23868,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 8,
    "Net Demand": 18906,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 9,
    "Net Demand": 17055,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 10,
    "Net Demand": 15686,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 11,
    "Net Demand": 14165,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 12,
    "Net Demand": 15481,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 13,
    "Net Demand": 19277,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 14,
    "Net Demand": 19753,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 15,
    "Net Demand": 20492,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 16,
    "Net Demand": 22239,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 17,
    "Net Demand": 22415,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 18,
    "Net Demand": 24346,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 19,
    "Net Demand": 24233,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 20,
    "Net Demand": 24853,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 21,
    "Net Demand": 23900,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 22,
    "Net Demand": 22044,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 23,
    "Net Demand": 23851,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 29,
    Hour: 24,
    "Net Demand": 23268,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 1,
    "Net Demand": 27477,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 2,
    "Net Demand": 29451,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 3,
    "Net Demand": 30881,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 4,
    "Net Demand": 31210,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 5,
    "Net Demand": 31005,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 6,
    "Net Demand": 32138,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 7,
    "Net Demand": 31857,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 8,
    "Net Demand": 30860,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 9,
    "Net Demand": 29075,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 10,
    "Net Demand": 29648,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 11,
    "Net Demand": 22609,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 12,
    "Net Demand": 25870,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 13,
    "Net Demand": 25392,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 14,
    "Net Demand": 22999,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 15,
    "Net Demand": 22803,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 16,
    "Net Demand": 24080,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 17,
    "Net Demand": 25272,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 18,
    "Net Demand": 27356,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 19,
    "Net Demand": 32782,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 20,
    "Net Demand": 32591,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 21,
    "Net Demand": 31700,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 22,
    "Net Demand": 31108,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 23,
    "Net Demand": 32412,
  },
  {
    Year: 2023,
    Month: 4,
    Day: 30,
    Hour: 24,
    "Net Demand": 32317,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 1,
    "Net Demand": 32303,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 2,
    "Net Demand": 31907,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 3,
    "Net Demand": 31552,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 4,
    "Net Demand": 32638,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 5,
    "Net Demand": 31760,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 6,
    "Net Demand": 31807,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 7,
    "Net Demand": 31801,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 8,
    "Net Demand": 28271,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 9,
    "Net Demand": 24747,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 10,
    "Net Demand": 23057,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 11,
    "Net Demand": 22416,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 12,
    "Net Demand": 27616,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 13,
    "Net Demand": 24271,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 14,
    "Net Demand": 26373,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 15,
    "Net Demand": 30105,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 16,
    "Net Demand": 28672,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 17,
    "Net Demand": 26287,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 18,
    "Net Demand": 29808,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 19,
    "Net Demand": 28660,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 20,
    "Net Demand": 28938,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 21,
    "Net Demand": 28818,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 22,
    "Net Demand": 33950,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 23,
    "Net Demand": 32618,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 1,
    Hour: 24,
    "Net Demand": 32766,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 1,
    "Net Demand": 25037,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 2,
    "Net Demand": 23207,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 3,
    "Net Demand": 22711,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 4,
    "Net Demand": 23463,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 5,
    "Net Demand": 23053,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 6,
    "Net Demand": 22853,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 7,
    "Net Demand": 23043,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 8,
    "Net Demand": 22231,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 9,
    "Net Demand": 21188,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 10,
    "Net Demand": 20334,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 11,
    "Net Demand": 18630,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 12,
    "Net Demand": 18500,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 13,
    "Net Demand": 18591,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 14,
    "Net Demand": 19051,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 15,
    "Net Demand": 21240,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 16,
    "Net Demand": 22357,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 17,
    "Net Demand": 24389,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 18,
    "Net Demand": 25097,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 19,
    "Net Demand": 25253,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 20,
    "Net Demand": 23725,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 21,
    "Net Demand": 21326,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 22,
    "Net Demand": 19818,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 23,
    "Net Demand": 19456,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 2,
    Hour: 24,
    "Net Demand": 20258,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 1,
    "Net Demand": 20596,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 2,
    "Net Demand": 20247,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 3,
    "Net Demand": 21250,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 4,
    "Net Demand": 20806,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 5,
    "Net Demand": 21594,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 6,
    "Net Demand": 20593,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 7,
    "Net Demand": 20492,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 8,
    "Net Demand": 23853,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 9,
    "Net Demand": 27052,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 10,
    "Net Demand": 26151,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 11,
    "Net Demand": 21817,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 12,
    "Net Demand": 19899,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 13,
    "Net Demand": 20041,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 14,
    "Net Demand": 20586,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 15,
    "Net Demand": 21294,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 16,
    "Net Demand": 19869,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 17,
    "Net Demand": 21429,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 18,
    "Net Demand": 21751,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 19,
    "Net Demand": 21680,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 20,
    "Net Demand": 21664,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 21,
    "Net Demand": 20223,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 22,
    "Net Demand": 25210,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 23,
    "Net Demand": 28036,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 3,
    Hour: 24,
    "Net Demand": 28591,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 1,
    "Net Demand": 28409,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 2,
    "Net Demand": 27359,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 3,
    "Net Demand": 27217,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 4,
    "Net Demand": 28615,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 5,
    "Net Demand": 27185,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 6,
    "Net Demand": 27388,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 7,
    "Net Demand": 29931,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 8,
    "Net Demand": 30288,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 9,
    "Net Demand": 29867,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 10,
    "Net Demand": 29886,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 11,
    "Net Demand": 28932,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 12,
    "Net Demand": 30055,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 13,
    "Net Demand": 29809,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 14,
    "Net Demand": 29795,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 15,
    "Net Demand": 30592,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 16,
    "Net Demand": 31188,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 17,
    "Net Demand": 32861,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 18,
    "Net Demand": 31879,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 19,
    "Net Demand": 25321,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 20,
    "Net Demand": 23733,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 21,
    "Net Demand": 23542,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 22,
    "Net Demand": 23798,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 23,
    "Net Demand": 23177,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 4,
    Hour: 24,
    "Net Demand": 23952,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 1,
    "Net Demand": 24292,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 2,
    "Net Demand": 23294,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 3,
    "Net Demand": 22664,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 4,
    "Net Demand": 22328,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 5,
    "Net Demand": 23479,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 6,
    "Net Demand": 23931,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 7,
    "Net Demand": 22655,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 8,
    "Net Demand": 20221,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 9,
    "Net Demand": 20364,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 10,
    "Net Demand": 19983,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 11,
    "Net Demand": 17715,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 12,
    "Net Demand": 19716,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 13,
    "Net Demand": 20372,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 14,
    "Net Demand": 20142,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 15,
    "Net Demand": 21035,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 16,
    "Net Demand": 21481,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 17,
    "Net Demand": 22216,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 18,
    "Net Demand": 23315,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 19,
    "Net Demand": 29902,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 20,
    "Net Demand": 31121,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 21,
    "Net Demand": 30997,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 22,
    "Net Demand": 31515,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 23,
    "Net Demand": 31939,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 5,
    Hour: 24,
    "Net Demand": 32371,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 1,
    "Net Demand": 32277,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 2,
    "Net Demand": 31514,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 3,
    "Net Demand": 31889,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 4,
    "Net Demand": 31798,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 5,
    "Net Demand": 31085,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 6,
    "Net Demand": 31292,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 7,
    "Net Demand": 31789,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 8,
    "Net Demand": 29408,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 9,
    "Net Demand": 28323,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 10,
    "Net Demand": 19322,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 11,
    "Net Demand": 17959,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 12,
    "Net Demand": 17621,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 13,
    "Net Demand": 18123,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 14,
    "Net Demand": 18713,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 15,
    "Net Demand": 19340,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 16,
    "Net Demand": 20360,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 17,
    "Net Demand": 21927,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 18,
    "Net Demand": 24292,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 19,
    "Net Demand": 23452,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 20,
    "Net Demand": 23870,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 21,
    "Net Demand": 23891,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 22,
    "Net Demand": 23759,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 23,
    "Net Demand": 23544,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 6,
    Hour: 24,
    "Net Demand": 23712,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 1,
    "Net Demand": 23249,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 2,
    "Net Demand": 22651,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 3,
    "Net Demand": 22182,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 4,
    "Net Demand": 22295,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 5,
    "Net Demand": 23380,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 6,
    "Net Demand": 23870,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 7,
    "Net Demand": 22815,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 8,
    "Net Demand": 21479,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 9,
    "Net Demand": 19769,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 10,
    "Net Demand": 19260,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 11,
    "Net Demand": 18366,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 12,
    "Net Demand": 18112,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 13,
    "Net Demand": 18260,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 14,
    "Net Demand": 19348,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 15,
    "Net Demand": 20970,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 16,
    "Net Demand": 21252,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 17,
    "Net Demand": 20786,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 18,
    "Net Demand": 23039,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 19,
    "Net Demand": 23265,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 20,
    "Net Demand": 24065,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 21,
    "Net Demand": 24381,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 22,
    "Net Demand": 23697,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 23,
    "Net Demand": 23028,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 7,
    Hour: 24,
    "Net Demand": 22786,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 1,
    "Net Demand": 22504,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 2,
    "Net Demand": 21411,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 3,
    "Net Demand": 24061,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 4,
    "Net Demand": 24821,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 5,
    "Net Demand": 23462,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 6,
    "Net Demand": 24306,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 7,
    "Net Demand": 22509,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 8,
    "Net Demand": 22527,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 9,
    "Net Demand": 22296,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 10,
    "Net Demand": 20124,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 11,
    "Net Demand": 20923,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 12,
    "Net Demand": 29583,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 13,
    "Net Demand": 25224,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 14,
    "Net Demand": 24288,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 15,
    "Net Demand": 24541,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 16,
    "Net Demand": 26254,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 17,
    "Net Demand": 26728,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 18,
    "Net Demand": 25417,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 19,
    "Net Demand": 27222,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 20,
    "Net Demand": 25002,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 21,
    "Net Demand": 27566,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 22,
    "Net Demand": 28099,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 23,
    "Net Demand": 28056,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 8,
    Hour: 24,
    "Net Demand": 27628,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 1,
    "Net Demand": 27759,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 2,
    "Net Demand": 27633,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 3,
    "Net Demand": 26951,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 4,
    "Net Demand": 27186,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 5,
    "Net Demand": 27315,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 6,
    "Net Demand": 26722,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 7,
    "Net Demand": 25610,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 8,
    "Net Demand": 26224,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 9,
    "Net Demand": 24845,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 10,
    "Net Demand": 23001,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 11,
    "Net Demand": 18923,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 12,
    "Net Demand": 19010,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 13,
    "Net Demand": 19426,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 14,
    "Net Demand": 24728,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 15,
    "Net Demand": 25030,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 16,
    "Net Demand": 24369,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 17,
    "Net Demand": 24927,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 18,
    "Net Demand": 28124,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 19,
    "Net Demand": 26700,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 20,
    "Net Demand": 30751,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 21,
    "Net Demand": 31671,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 22,
    "Net Demand": 31297,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 23,
    "Net Demand": 30596,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 9,
    Hour: 24,
    "Net Demand": 28484,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 1,
    "Net Demand": 25885,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 2,
    "Net Demand": 31283,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 3,
    "Net Demand": 31291,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 4,
    "Net Demand": 29001,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 5,
    "Net Demand": 31238,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 6,
    "Net Demand": 29267,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 7,
    "Net Demand": 26811,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 8,
    "Net Demand": 27207,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 9,
    "Net Demand": 27709,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 10,
    "Net Demand": 28439,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 11,
    "Net Demand": 28318,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 12,
    "Net Demand": 29331,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 13,
    "Net Demand": 29083,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 14,
    "Net Demand": 29407,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 15,
    "Net Demand": 29868,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 16,
    "Net Demand": 32196,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 17,
    "Net Demand": 32768,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 18,
    "Net Demand": 31895,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 19,
    "Net Demand": 30651,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 20,
    "Net Demand": 31231,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 21,
    "Net Demand": 30401,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 22,
    "Net Demand": 30076,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 23,
    "Net Demand": 31962,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 10,
    Hour: 24,
    "Net Demand": 31289,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 1,
    "Net Demand": 32466,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 2,
    "Net Demand": 31601,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 3,
    "Net Demand": 31301,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 4,
    "Net Demand": 31824,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 5,
    "Net Demand": 30930,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 6,
    "Net Demand": 31383,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 7,
    "Net Demand": 30627,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 8,
    "Net Demand": 30530,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 9,
    "Net Demand": 29720,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 10,
    "Net Demand": 23749,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 11,
    "Net Demand": 18335,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 12,
    "Net Demand": 20796,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 13,
    "Net Demand": 19295,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 14,
    "Net Demand": 18657,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 15,
    "Net Demand": 21796,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 16,
    "Net Demand": 22082,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 17,
    "Net Demand": 23457,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 18,
    "Net Demand": 25202,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 19,
    "Net Demand": 25217,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 20,
    "Net Demand": 25470,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 21,
    "Net Demand": 25209,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 22,
    "Net Demand": 25635,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 23,
    "Net Demand": 26595,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 11,
    Hour: 24,
    "Net Demand": 25996,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 1,
    "Net Demand": 26499,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 2,
    "Net Demand": 26000,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 3,
    "Net Demand": 26897,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 4,
    "Net Demand": 29183,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 5,
    "Net Demand": 26216,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 6,
    "Net Demand": 27716,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 7,
    "Net Demand": 27104,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 8,
    "Net Demand": 28871,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 9,
    "Net Demand": 29974,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 10,
    "Net Demand": 29968,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 11,
    "Net Demand": 30756,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 12,
    "Net Demand": 29340,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 13,
    "Net Demand": 30625,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 14,
    "Net Demand": 31497,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 15,
    "Net Demand": 30697,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 16,
    "Net Demand": 33828,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 17,
    "Net Demand": 35300,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 18,
    "Net Demand": 34548,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 19,
    "Net Demand": 34097,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 20,
    "Net Demand": 34246,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 21,
    "Net Demand": 33914,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 22,
    "Net Demand": 32850,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 23,
    "Net Demand": 33284,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 12,
    Hour: 24,
    "Net Demand": 28711,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 1,
    "Net Demand": 27851,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 2,
    "Net Demand": 30144,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 3,
    "Net Demand": 29449,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 4,
    "Net Demand": 30308,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 5,
    "Net Demand": 27429,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 6,
    "Net Demand": 29957,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 7,
    "Net Demand": 26545,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 8,
    "Net Demand": 24762,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 9,
    "Net Demand": 24742,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 10,
    "Net Demand": 25378,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 11,
    "Net Demand": 24596,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 12,
    "Net Demand": 20211,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 13,
    "Net Demand": 17986,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 14,
    "Net Demand": 23034,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 15,
    "Net Demand": 22481,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 16,
    "Net Demand": 24389,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 17,
    "Net Demand": 26852,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 18,
    "Net Demand": 27865,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 19,
    "Net Demand": 27656,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 20,
    "Net Demand": 27578,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 21,
    "Net Demand": 27570,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 22,
    "Net Demand": 27618,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 23,
    "Net Demand": 23490,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 13,
    Hour: 24,
    "Net Demand": 18606,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 1,
    "Net Demand": 18280,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 2,
    "Net Demand": 18290,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 3,
    "Net Demand": 18202,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 4,
    "Net Demand": 19310,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 5,
    "Net Demand": 18844,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 6,
    "Net Demand": 18090,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 7,
    "Net Demand": 17357,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 8,
    "Net Demand": 16878,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 9,
    "Net Demand": 16186,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 10,
    "Net Demand": 15048,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 11,
    "Net Demand": 15337,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 12,
    "Net Demand": 13846,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 13,
    "Net Demand": 13215,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 14,
    "Net Demand": 13698,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 15,
    "Net Demand": 15962,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 16,
    "Net Demand": 16048,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 17,
    "Net Demand": 16949,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 18,
    "Net Demand": 18924,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 19,
    "Net Demand": 19316,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 20,
    "Net Demand": 18960,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 21,
    "Net Demand": 18914,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 22,
    "Net Demand": 16691,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 23,
    "Net Demand": 17307,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 14,
    Hour: 24,
    "Net Demand": 16954,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 1,
    "Net Demand": 16959,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 2,
    "Net Demand": 15863,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 3,
    "Net Demand": 15480,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 4,
    "Net Demand": 15769,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 5,
    "Net Demand": 17318,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 6,
    "Net Demand": 23980,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 7,
    "Net Demand": 23870,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 8,
    "Net Demand": 23781,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 9,
    "Net Demand": 22313,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 10,
    "Net Demand": 21707,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 11,
    "Net Demand": 21771,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 12,
    "Net Demand": 21606,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 13,
    "Net Demand": 22305,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 14,
    "Net Demand": 21927,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 15,
    "Net Demand": 23235,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 16,
    "Net Demand": 17710,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 17,
    "Net Demand": 21914,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 18,
    "Net Demand": 25585,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 19,
    "Net Demand": 25189,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 20,
    "Net Demand": 25853,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 21,
    "Net Demand": 25328,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 22,
    "Net Demand": 25527,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 23,
    "Net Demand": 25314,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 15,
    Hour: 24,
    "Net Demand": 23151,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 1,
    "Net Demand": 23957,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 2,
    "Net Demand": 25910,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 3,
    "Net Demand": 24704,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 4,
    "Net Demand": 24099,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 5,
    "Net Demand": 24624,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 6,
    "Net Demand": 24270,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 7,
    "Net Demand": 19649,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 8,
    "Net Demand": 19450,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 9,
    "Net Demand": 20001,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 10,
    "Net Demand": 19466,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 11,
    "Net Demand": 19126,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 12,
    "Net Demand": 18446,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 13,
    "Net Demand": 17659,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 14,
    "Net Demand": 16735,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 15,
    "Net Demand": 18111,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 16,
    "Net Demand": 17892,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 17,
    "Net Demand": 19107,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 18,
    "Net Demand": 19942,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 19,
    "Net Demand": 20140,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 20,
    "Net Demand": 21087,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 21,
    "Net Demand": 20442,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 22,
    "Net Demand": 19827,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 23,
    "Net Demand": 20074,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 16,
    Hour: 24,
    "Net Demand": 20467,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 1,
    "Net Demand": 13888,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 2,
    "Net Demand": 15693,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 3,
    "Net Demand": 15393,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 4,
    "Net Demand": 15882,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 5,
    "Net Demand": 16220,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 6,
    "Net Demand": 16612,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 7,
    "Net Demand": 16098,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 8,
    "Net Demand": 15639,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 9,
    "Net Demand": 15625,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 10,
    "Net Demand": 14271,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 11,
    "Net Demand": 13520,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 12,
    "Net Demand": 13531,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 13,
    "Net Demand": 16326,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 14,
    "Net Demand": 15899,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 15,
    "Net Demand": 15953,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 16,
    "Net Demand": 15307,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 17,
    "Net Demand": 15766,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 18,
    "Net Demand": 16631,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 19,
    "Net Demand": 16351,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 20,
    "Net Demand": 16383,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 21,
    "Net Demand": 15577,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 22,
    "Net Demand": 17946,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 23,
    "Net Demand": 16812,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 17,
    Hour: 24,
    "Net Demand": 16558,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 1,
    "Net Demand": 16603,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 2,
    "Net Demand": 16170,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 3,
    "Net Demand": 15324,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 4,
    "Net Demand": 15414,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 5,
    "Net Demand": 17853,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 6,
    "Net Demand": 23845,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 7,
    "Net Demand": 22730,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 8,
    "Net Demand": 22611,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 9,
    "Net Demand": 22631,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 10,
    "Net Demand": 21839,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 11,
    "Net Demand": 21595,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 12,
    "Net Demand": 23006,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 13,
    "Net Demand": 23439,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 14,
    "Net Demand": 20593,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 15,
    "Net Demand": 22719,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 16,
    "Net Demand": 24573,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 17,
    "Net Demand": 26075,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 18,
    "Net Demand": 26074,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 19,
    "Net Demand": 24732,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 20,
    "Net Demand": 25727,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 21,
    "Net Demand": 25745,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 22,
    "Net Demand": 25895,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 23,
    "Net Demand": 25906,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 18,
    Hour: 24,
    "Net Demand": 25305,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 1,
    "Net Demand": 26059,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 2,
    "Net Demand": 25263,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 3,
    "Net Demand": 24279,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 4,
    "Net Demand": 25715,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 5,
    "Net Demand": 26628,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 6,
    "Net Demand": 25709,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 7,
    "Net Demand": 25212,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 8,
    "Net Demand": 24532,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 9,
    "Net Demand": 23888,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 10,
    "Net Demand": 23480,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 11,
    "Net Demand": 23159,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 12,
    "Net Demand": 24272,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 13,
    "Net Demand": 24396,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 14,
    "Net Demand": 23790,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 15,
    "Net Demand": 18622,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 16,
    "Net Demand": 21896,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 17,
    "Net Demand": 20813,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 18,
    "Net Demand": 25632,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 19,
    "Net Demand": 25367,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 20,
    "Net Demand": 26431,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 21,
    "Net Demand": 23435,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 22,
    "Net Demand": 19590,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 23,
    "Net Demand": 22241,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 19,
    Hour: 24,
    "Net Demand": 25559,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 1,
    "Net Demand": 25104,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 2,
    "Net Demand": 24842,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 3,
    "Net Demand": 25494,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 4,
    "Net Demand": 26606,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 5,
    "Net Demand": 25569,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 6,
    "Net Demand": 24602,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 7,
    "Net Demand": 14727,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 8,
    "Net Demand": 9390,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 9,
    "Net Demand": 9634,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 10,
    "Net Demand": 8220,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 11,
    "Net Demand": 11942,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 12,
    "Net Demand": 13545,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 13,
    "Net Demand": 13468,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 14,
    "Net Demand": 14110,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 15,
    "Net Demand": 16074,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 16,
    "Net Demand": 16132,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 17,
    "Net Demand": 16433,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 18,
    "Net Demand": 16391,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 19,
    "Net Demand": 14939,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 20,
    "Net Demand": 16717,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 21,
    "Net Demand": 17534,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 22,
    "Net Demand": 17822,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 23,
    "Net Demand": 17827,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 20,
    Hour: 24,
    "Net Demand": 19032,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 1,
    "Net Demand": 19187,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 2,
    "Net Demand": 18568,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 3,
    "Net Demand": 16231,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 4,
    "Net Demand": 15943,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 5,
    "Net Demand": 16833,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 6,
    "Net Demand": 17208,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 7,
    "Net Demand": 17093,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 8,
    "Net Demand": 15907,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 9,
    "Net Demand": 14371,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 10,
    "Net Demand": 12782,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 11,
    "Net Demand": 12313,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 12,
    "Net Demand": 17081,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 13,
    "Net Demand": 23136,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 14,
    "Net Demand": 22548,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 15,
    "Net Demand": 24637,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 16,
    "Net Demand": 23810,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 17,
    "Net Demand": 23805,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 18,
    "Net Demand": 23971,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 19,
    "Net Demand": 23983,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 20,
    "Net Demand": 24651,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 21,
    "Net Demand": 25385,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 22,
    "Net Demand": 26520,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 23,
    "Net Demand": 24302,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 21,
    Hour: 24,
    "Net Demand": 25597,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 1,
    "Net Demand": 22052,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 2,
    "Net Demand": 20929,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 3,
    "Net Demand": 19247,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 4,
    "Net Demand": 18859,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 5,
    "Net Demand": 19389,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 6,
    "Net Demand": 19181,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 7,
    "Net Demand": 17174,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 8,
    "Net Demand": 17570,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 9,
    "Net Demand": 16888,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 10,
    "Net Demand": 17126,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 11,
    "Net Demand": 18048,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 12,
    "Net Demand": 20895,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 13,
    "Net Demand": 24304,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 14,
    "Net Demand": 24052,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 15,
    "Net Demand": 24394,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 16,
    "Net Demand": 23575,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 17,
    "Net Demand": 22968,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 18,
    "Net Demand": 19171,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 19,
    "Net Demand": 24793,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 20,
    "Net Demand": 26797,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 21,
    "Net Demand": 27282,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 22,
    "Net Demand": 28247,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 23,
    "Net Demand": 25780,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 22,
    Hour: 24,
    "Net Demand": 26181,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 1,
    "Net Demand": 23766,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 2,
    "Net Demand": 24016,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 3,
    "Net Demand": 24438,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 4,
    "Net Demand": 25776,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 5,
    "Net Demand": 25638,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 6,
    "Net Demand": 26756,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 7,
    "Net Demand": 25528,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 8,
    "Net Demand": 17469,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 9,
    "Net Demand": 15691,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 10,
    "Net Demand": 14211,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 11,
    "Net Demand": 14500,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 12,
    "Net Demand": 15434,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 13,
    "Net Demand": 14365,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 14,
    "Net Demand": 14411,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 15,
    "Net Demand": 13442,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 16,
    "Net Demand": 16071,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 17,
    "Net Demand": 16410,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 18,
    "Net Demand": 16097,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 19,
    "Net Demand": 15788,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 20,
    "Net Demand": 17327,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 21,
    "Net Demand": 19023,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 22,
    "Net Demand": 18676,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 23,
    "Net Demand": 18278,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 23,
    Hour: 24,
    "Net Demand": 19231,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 1,
    "Net Demand": 19180,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 2,
    "Net Demand": 18498,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 3,
    "Net Demand": 17801,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 4,
    "Net Demand": 18309,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 5,
    "Net Demand": 18684,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 6,
    "Net Demand": 18820,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 7,
    "Net Demand": 17852,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 8,
    "Net Demand": 17324,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 9,
    "Net Demand": 13617,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 10,
    "Net Demand": 14155,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 11,
    "Net Demand": 13852,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 12,
    "Net Demand": 13911,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 13,
    "Net Demand": 14759,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 14,
    "Net Demand": 15314,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 15,
    "Net Demand": 16842,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 16,
    "Net Demand": 17208,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 17,
    "Net Demand": 17164,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 18,
    "Net Demand": 17319,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 19,
    "Net Demand": 18295,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 20,
    "Net Demand": 17521,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 21,
    "Net Demand": 18116,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 22,
    "Net Demand": 17855,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 23,
    "Net Demand": 16619,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 24,
    Hour: 24,
    "Net Demand": 15720,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 1,
    "Net Demand": 15670,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 2,
    "Net Demand": 16270,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 3,
    "Net Demand": 16761,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 4,
    "Net Demand": 17235,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 5,
    "Net Demand": 17501,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 6,
    "Net Demand": 17648,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 7,
    "Net Demand": 17980,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 8,
    "Net Demand": 18130,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 9,
    "Net Demand": 17984,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 10,
    "Net Demand": 18200,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 11,
    "Net Demand": 17291,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 12,
    "Net Demand": 14008,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 13,
    "Net Demand": 15155,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 14,
    "Net Demand": 16936,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 15,
    "Net Demand": 17361,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 16,
    "Net Demand": 17353,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 17,
    "Net Demand": 17356,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 18,
    "Net Demand": 16776,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 19,
    "Net Demand": 16414,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 20,
    "Net Demand": 16963,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 21,
    "Net Demand": 16872,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 22,
    "Net Demand": 17165,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 23,
    "Net Demand": 16686,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 25,
    Hour: 24,
    "Net Demand": 16373,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 1,
    "Net Demand": 16575,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 2,
    "Net Demand": 16507,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 3,
    "Net Demand": 16256,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 4,
    "Net Demand": 15936,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 5,
    "Net Demand": 16480,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 6,
    "Net Demand": 17354,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 7,
    "Net Demand": 17691,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 8,
    "Net Demand": 17669,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 9,
    "Net Demand": 17767,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 10,
    "Net Demand": 17289,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 11,
    "Net Demand": 17454,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 12,
    "Net Demand": 17385,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 13,
    "Net Demand": 15519,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 14,
    "Net Demand": 14711,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 15,
    "Net Demand": 16176,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 16,
    "Net Demand": 16058,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 17,
    "Net Demand": 16091,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 18,
    "Net Demand": 17129,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 19,
    "Net Demand": 17629,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 20,
    "Net Demand": 17695,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 21,
    "Net Demand": 17764,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 22,
    "Net Demand": 18173,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 23,
    "Net Demand": 17658,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 26,
    Hour: 24,
    "Net Demand": 17673,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 1,
    "Net Demand": 17918,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 2,
    "Net Demand": 17508,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 3,
    "Net Demand": 16582,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 4,
    "Net Demand": 17339,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 5,
    "Net Demand": 17692,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 6,
    "Net Demand": 18200,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 7,
    "Net Demand": 17496,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 8,
    "Net Demand": 16577,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 9,
    "Net Demand": 15557,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 10,
    "Net Demand": 14925,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 11,
    "Net Demand": 14333,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 12,
    "Net Demand": 16740,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 13,
    "Net Demand": 17266,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 14,
    "Net Demand": 17223,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 15,
    "Net Demand": 16700,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 16,
    "Net Demand": 16474,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 17,
    "Net Demand": 16885,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 18,
    "Net Demand": 17789,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 19,
    "Net Demand": 16815,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 20,
    "Net Demand": 19314,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 21,
    "Net Demand": 18472,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 22,
    "Net Demand": 18263,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 23,
    "Net Demand": 18480,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 27,
    Hour: 24,
    "Net Demand": 17337,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 1,
    "Net Demand": 16736,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 2,
    "Net Demand": 16846,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 3,
    "Net Demand": 16042,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 4,
    "Net Demand": 13244,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 5,
    "Net Demand": 13493,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 6,
    "Net Demand": 13371,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 7,
    "Net Demand": 17661,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 8,
    "Net Demand": 16593,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 9,
    "Net Demand": 15214,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 10,
    "Net Demand": 11592,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 11,
    "Net Demand": 7531,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 12,
    "Net Demand": 6920,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 13,
    "Net Demand": 6786,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 14,
    "Net Demand": 7085,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 15,
    "Net Demand": 7467,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 16,
    "Net Demand": 9128,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 17,
    "Net Demand": 9629,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 18,
    "Net Demand": 10773,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 19,
    "Net Demand": 10763,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 20,
    "Net Demand": 11391,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 21,
    "Net Demand": 11812,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 22,
    "Net Demand": 11615,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 23,
    "Net Demand": 11726,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 28,
    Hour: 24,
    "Net Demand": 11835,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 1,
    "Net Demand": 11936,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 2,
    "Net Demand": 11903,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 3,
    "Net Demand": 11379,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 4,
    "Net Demand": 11302,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 5,
    "Net Demand": 11335,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 6,
    "Net Demand": 11120,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 7,
    "Net Demand": 10299,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 8,
    "Net Demand": 12186,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 9,
    "Net Demand": 14608,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 10,
    "Net Demand": 13933,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 11,
    "Net Demand": 12749,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 12,
    "Net Demand": 13308,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 13,
    "Net Demand": 13071,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 14,
    "Net Demand": 12319,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 15,
    "Net Demand": 12847,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 16,
    "Net Demand": 14227,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 17,
    "Net Demand": 15215,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 18,
    "Net Demand": 16543,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 19,
    "Net Demand": 17118,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 20,
    "Net Demand": 17509,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 21,
    "Net Demand": 17871,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 22,
    "Net Demand": 18537,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 23,
    "Net Demand": 16820,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 29,
    Hour: 24,
    "Net Demand": 16212,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 1,
    "Net Demand": 16195,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 2,
    "Net Demand": 16161,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 3,
    "Net Demand": 16532,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 4,
    "Net Demand": 16845,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 5,
    "Net Demand": 16886,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 6,
    "Net Demand": 17030,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 7,
    "Net Demand": 17913,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 8,
    "Net Demand": 16898,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 9,
    "Net Demand": 15363,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 10,
    "Net Demand": 15046,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 11,
    "Net Demand": 14703,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 12,
    "Net Demand": 14564,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 13,
    "Net Demand": 14487,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 14,
    "Net Demand": 15001,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 15,
    "Net Demand": 17939,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 16,
    "Net Demand": 3776,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 17,
    "Net Demand": 0,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 18,
    "Net Demand": 3805,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 19,
    "Net Demand": 11969,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 20,
    "Net Demand": 15318,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 21,
    "Net Demand": 16000,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 22,
    "Net Demand": 16681,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 23,
    "Net Demand": 17133,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 30,
    Hour: 24,
    "Net Demand": 17427,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 1,
    "Net Demand": 16566,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 2,
    "Net Demand": 17077,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 3,
    "Net Demand": 17571,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 4,
    "Net Demand": 17139,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 5,
    "Net Demand": 16324,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 6,
    "Net Demand": 16898,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 7,
    "Net Demand": 16831,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 8,
    "Net Demand": 15865,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 9,
    "Net Demand": 18850,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 10,
    "Net Demand": 19017,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 11,
    "Net Demand": 18855,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 12,
    "Net Demand": 18858,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 13,
    "Net Demand": 18970,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 14,
    "Net Demand": 19059,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 15,
    "Net Demand": 19716,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 16,
    "Net Demand": 18569,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 17,
    "Net Demand": 19545,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 18,
    "Net Demand": 20941,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 19,
    "Net Demand": 20981,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 20,
    "Net Demand": 21283,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 21,
    "Net Demand": 21217,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 22,
    "Net Demand": 21789,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 23,
    "Net Demand": 21562,
  },
  {
    Year: 2023,
    Month: 5,
    Day: 31,
    Hour: 24,
    "Net Demand": 21329,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 1,
    "Net Demand": 33296,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 2,
    "Net Demand": 33134,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 3,
    "Net Demand": 32849,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 4,
    "Net Demand": 34630,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 5,
    "Net Demand": 33422,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 6,
    "Net Demand": 33774,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 7,
    "Net Demand": 33103,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 8,
    "Net Demand": 32370,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 9,
    "Net Demand": 30355,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 10,
    "Net Demand": 24378,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 11,
    "Net Demand": 20876,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 12,
    "Net Demand": 25574,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 13,
    "Net Demand": 26196,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 14,
    "Net Demand": 21840,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 15,
    "Net Demand": 22997,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 16,
    "Net Demand": 24490,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 17,
    "Net Demand": 28176,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 18,
    "Net Demand": 29377,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 19,
    "Net Demand": 28777,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 20,
    "Net Demand": 28967,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 21,
    "Net Demand": 28355,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 22,
    "Net Demand": 29344,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 23,
    "Net Demand": 28568,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 1,
    Hour: 24,
    "Net Demand": 29091,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 1,
    "Net Demand": 26504,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 2,
    "Net Demand": 21193,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 3,
    "Net Demand": 19039,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 4,
    "Net Demand": 19784,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 5,
    "Net Demand": 19706,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 6,
    "Net Demand": 20713,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 7,
    "Net Demand": 17152,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 8,
    "Net Demand": 15839,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 9,
    "Net Demand": 17816,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 10,
    "Net Demand": 17297,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 11,
    "Net Demand": 16805,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 12,
    "Net Demand": 16614,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 13,
    "Net Demand": 17533,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 14,
    "Net Demand": 16587,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 15,
    "Net Demand": 11604,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 16,
    "Net Demand": 11504,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 17,
    "Net Demand": 11950,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 18,
    "Net Demand": 12424,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 19,
    "Net Demand": 12188,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 20,
    "Net Demand": 13055,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 21,
    "Net Demand": 12502,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 22,
    "Net Demand": 11694,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 23,
    "Net Demand": 12200,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 2,
    Hour: 24,
    "Net Demand": 11764,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 1,
    "Net Demand": 10828,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 2,
    "Net Demand": 10882,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 3,
    "Net Demand": 10746,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 4,
    "Net Demand": 10776,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 5,
    "Net Demand": 10453,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 6,
    "Net Demand": 10394,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 7,
    "Net Demand": 9696,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 8,
    "Net Demand": 9153,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 9,
    "Net Demand": 8760,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 10,
    "Net Demand": 8353,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 11,
    "Net Demand": 6900,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 12,
    "Net Demand": 7882,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 13,
    "Net Demand": 6683,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 14,
    "Net Demand": 6189,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 15,
    "Net Demand": 7485,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 16,
    "Net Demand": 7551,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 17,
    "Net Demand": 9580,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 18,
    "Net Demand": 9493,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 19,
    "Net Demand": 9707,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 20,
    "Net Demand": 10823,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 21,
    "Net Demand": 10770,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 22,
    "Net Demand": 10793,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 23,
    "Net Demand": 11201,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 3,
    Hour: 24,
    "Net Demand": 13527,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 1,
    "Net Demand": 18847,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 2,
    "Net Demand": 16229,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 3,
    "Net Demand": 18758,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 4,
    "Net Demand": 19159,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 5,
    "Net Demand": 19056,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 6,
    "Net Demand": 19012,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 7,
    "Net Demand": 19593,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 8,
    "Net Demand": 20099,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 9,
    "Net Demand": 21315,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 10,
    "Net Demand": 18887,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 11,
    "Net Demand": 8309,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 12,
    "Net Demand": 8259,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 13,
    "Net Demand": 9524,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 14,
    "Net Demand": 12521,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 15,
    "Net Demand": 17267,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 16,
    "Net Demand": 21226,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 17,
    "Net Demand": 23597,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 18,
    "Net Demand": 24752,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 19,
    "Net Demand": 26877,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 20,
    "Net Demand": 29569,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 21,
    "Net Demand": 32576,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 22,
    "Net Demand": 31041,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 23,
    "Net Demand": 31379,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 4,
    Hour: 24,
    "Net Demand": 31805,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 1,
    "Net Demand": 33419,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 2,
    "Net Demand": 32531,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 3,
    "Net Demand": 32770,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 4,
    "Net Demand": 33250,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 5,
    "Net Demand": 32523,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 6,
    "Net Demand": 25739,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 7,
    "Net Demand": 29399,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 8,
    "Net Demand": 31095,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 9,
    "Net Demand": 30423,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 10,
    "Net Demand": 30074,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 11,
    "Net Demand": 27649,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 12,
    "Net Demand": 28605,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 13,
    "Net Demand": 29220,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 14,
    "Net Demand": 28798,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 15,
    "Net Demand": 28788,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 16,
    "Net Demand": 29908,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 17,
    "Net Demand": 32885,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 18,
    "Net Demand": 33749,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 19,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 20,
    "Net Demand": 35055,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 21,
    "Net Demand": 34556,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 22,
    "Net Demand": 34519,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 23,
    "Net Demand": 35038,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 5,
    Hour: 24,
    "Net Demand": 33569,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 1,
    "Net Demand": 33742,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 2,
    "Net Demand": 33563,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 3,
    "Net Demand": 32736,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 4,
    "Net Demand": 33511,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 5,
    "Net Demand": 32873,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 6,
    "Net Demand": 33582,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 7,
    "Net Demand": 34219,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 8,
    "Net Demand": 32698,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 9,
    "Net Demand": 30738,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 10,
    "Net Demand": 29640,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 11,
    "Net Demand": 25219,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 12,
    "Net Demand": 27423,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 13,
    "Net Demand": 26692,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 14,
    "Net Demand": 28028,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 15,
    "Net Demand": 28591,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 16,
    "Net Demand": 29854,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 17,
    "Net Demand": 31692,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 18,
    "Net Demand": 30959,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 19,
    "Net Demand": 30431,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 20,
    "Net Demand": 34708,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 21,
    "Net Demand": 35125,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 22,
    "Net Demand": 34605,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 23,
    "Net Demand": 34381,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 6,
    Hour: 24,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 1,
    "Net Demand": 32708,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 2,
    "Net Demand": 32032,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 3,
    "Net Demand": 31214,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 4,
    "Net Demand": 33286,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 5,
    "Net Demand": 34034,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 6,
    "Net Demand": 32829,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 7,
    "Net Demand": 31370,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 8,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 9,
    "Net Demand": 31142,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 10,
    "Net Demand": 31553,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 11,
    "Net Demand": 31631,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 12,
    "Net Demand": 30988,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 13,
    "Net Demand": 29572,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 14,
    "Net Demand": 28812,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 15,
    "Net Demand": 29196,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 16,
    "Net Demand": 28953,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 17,
    "Net Demand": 32759,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 18,
    "Net Demand": 33837,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 19,
    "Net Demand": 32851,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 20,
    "Net Demand": 34013,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 21,
    "Net Demand": 33566,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 22,
    "Net Demand": 33958,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 23,
    "Net Demand": 32391,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 7,
    Hour: 24,
    "Net Demand": 32863,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 1,
    "Net Demand": 32199,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 2,
    "Net Demand": 32353,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 3,
    "Net Demand": 31193,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 4,
    "Net Demand": 33787,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 5,
    "Net Demand": 33542,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 6,
    "Net Demand": 32702,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 7,
    "Net Demand": 31795,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 8,
    "Net Demand": 31170,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 9,
    "Net Demand": 30358,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 10,
    "Net Demand": 28957,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 11,
    "Net Demand": 26872,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 12,
    "Net Demand": 28440,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 13,
    "Net Demand": 29420,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 14,
    "Net Demand": 29849,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 15,
    "Net Demand": 29538,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 16,
    "Net Demand": 30862,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 17,
    "Net Demand": 27345,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 18,
    "Net Demand": 27080,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 19,
    "Net Demand": 34081,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 20,
    "Net Demand": 35285,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 21,
    "Net Demand": 34473,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 22,
    "Net Demand": 33349,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 23,
    "Net Demand": 32150,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 8,
    Hour: 24,
    "Net Demand": 32349,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 1,
    "Net Demand": 32669,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 2,
    "Net Demand": 32049,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 3,
    "Net Demand": 35161,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 4,
    "Net Demand": 35081,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 5,
    "Net Demand": 33910,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 6,
    "Net Demand": 33469,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 7,
    "Net Demand": 33668,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 8,
    "Net Demand": 33413,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 9,
    "Net Demand": 31583,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 10,
    "Net Demand": 26845,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 11,
    "Net Demand": 23319,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 12,
    "Net Demand": 25439,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 13,
    "Net Demand": 25226,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 14,
    "Net Demand": 23559,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 15,
    "Net Demand": 24562,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 16,
    "Net Demand": 26520,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 17,
    "Net Demand": 29134,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 18,
    "Net Demand": 27965,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 19,
    "Net Demand": 27995,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 20,
    "Net Demand": 28705,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 21,
    "Net Demand": 28011,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 22,
    "Net Demand": 29266,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 23,
    "Net Demand": 27605,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 9,
    Hour: 24,
    "Net Demand": 27596,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 1,
    "Net Demand": 27468,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 2,
    "Net Demand": 26380,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 3,
    "Net Demand": 25872,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 4,
    "Net Demand": 26629,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 5,
    "Net Demand": 27249,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 6,
    "Net Demand": 25026,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 7,
    "Net Demand": 26145,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 8,
    "Net Demand": 24973,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 9,
    "Net Demand": 24670,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 10,
    "Net Demand": 22504,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 11,
    "Net Demand": 21981,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 12,
    "Net Demand": 22150,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 13,
    "Net Demand": 22060,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 14,
    "Net Demand": 23125,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 15,
    "Net Demand": 23576,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 16,
    "Net Demand": 25803,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 17,
    "Net Demand": 27020,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 18,
    "Net Demand": 28383,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 19,
    "Net Demand": 28928,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 20,
    "Net Demand": 28481,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 21,
    "Net Demand": 27320,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 22,
    "Net Demand": 27296,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 23,
    "Net Demand": 28394,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 10,
    Hour: 24,
    "Net Demand": 27772,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 1,
    "Net Demand": 27559,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 2,
    "Net Demand": 27374,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 3,
    "Net Demand": 27069,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 4,
    "Net Demand": 28396,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 5,
    "Net Demand": 28358,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 6,
    "Net Demand": 27210,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 7,
    "Net Demand": 27545,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 8,
    "Net Demand": 27006,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 9,
    "Net Demand": 25585,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 10,
    "Net Demand": 25410,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 11,
    "Net Demand": 22408,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 12,
    "Net Demand": 24557,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 13,
    "Net Demand": 27699,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 14,
    "Net Demand": 26449,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 15,
    "Net Demand": 25947,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 16,
    "Net Demand": 28444,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 17,
    "Net Demand": 29412,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 18,
    "Net Demand": 28175,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 19,
    "Net Demand": 26852,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 20,
    "Net Demand": 27777,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 21,
    "Net Demand": 27462,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 22,
    "Net Demand": 28389,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 23,
    "Net Demand": 32640,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 11,
    Hour: 24,
    "Net Demand": 32903,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 1,
    "Net Demand": 31748,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 2,
    "Net Demand": 31935,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 3,
    "Net Demand": 31207,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 4,
    "Net Demand": 31376,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 5,
    "Net Demand": 32073,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 6,
    "Net Demand": 32062,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 7,
    "Net Demand": 32456,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 8,
    "Net Demand": 31584,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 9,
    "Net Demand": 28627,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 10,
    "Net Demand": 27167,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 11,
    "Net Demand": 26667,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 12,
    "Net Demand": 26958,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 13,
    "Net Demand": 27264,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 14,
    "Net Demand": 27582,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 15,
    "Net Demand": 25444,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 16,
    "Net Demand": 18944,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 17,
    "Net Demand": 19016,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 18,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 19,
    "Net Demand": 20575,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 20,
    "Net Demand": 24182,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 21,
    "Net Demand": 31632,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 22,
    "Net Demand": 33676,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 23,
    "Net Demand": 33627,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 12,
    Hour: 24,
    "Net Demand": 33123,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 1,
    "Net Demand": 32432,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 2,
    "Net Demand": 31204,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 3,
    "Net Demand": 30738,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 4,
    "Net Demand": 30522,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 5,
    "Net Demand": 31646,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 6,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 7,
    "Net Demand": 31310,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 8,
    "Net Demand": 29853,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 9,
    "Net Demand": 29169,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 10,
    "Net Demand": 27890,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 11,
    "Net Demand": 26989,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 12,
    "Net Demand": 27399,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 13,
    "Net Demand": 27927,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 14,
    "Net Demand": 28646,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 15,
    "Net Demand": 30257,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 16,
    "Net Demand": 31701,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 17,
    "Net Demand": 31992,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 18,
    "Net Demand": 32035,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 19,
    "Net Demand": 31745,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 20,
    "Net Demand": 33010,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 21,
    "Net Demand": 30250,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 22,
    "Net Demand": 27629,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 23,
    "Net Demand": 30800,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 13,
    Hour: 24,
    "Net Demand": 34725,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 1,
    "Net Demand": 34261,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 2,
    "Net Demand": 33175,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 3,
    "Net Demand": 31610,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 4,
    "Net Demand": 32368,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 5,
    "Net Demand": 31939,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 6,
    "Net Demand": 32392,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 7,
    "Net Demand": 31690,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 8,
    "Net Demand": 30904,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 9,
    "Net Demand": 31294,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 10,
    "Net Demand": 30226,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 11,
    "Net Demand": 28688,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 12,
    "Net Demand": 29292,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 13,
    "Net Demand": 30131,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 14,
    "Net Demand": 28798,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 15,
    "Net Demand": 26947,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 16,
    "Net Demand": 30218,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 17,
    "Net Demand": 31979,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 18,
    "Net Demand": 32417,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 19,
    "Net Demand": 31878,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 20,
    "Net Demand": 32996,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 21,
    "Net Demand": 34078,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 22,
    "Net Demand": 32079,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 23,
    "Net Demand": 31700,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 14,
    Hour: 24,
    "Net Demand": 30968,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 1,
    "Net Demand": 32058,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 2,
    "Net Demand": 31266,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 3,
    "Net Demand": 30608,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 4,
    "Net Demand": 31545,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 5,
    "Net Demand": 31966,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 6,
    "Net Demand": 30897,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 7,
    "Net Demand": 29928,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 8,
    "Net Demand": 30413,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 9,
    "Net Demand": 29178,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 10,
    "Net Demand": 18015,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 11,
    "Net Demand": 14033,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 12,
    "Net Demand": 14531,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 13,
    "Net Demand": 14737,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 14,
    "Net Demand": 14813,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 15,
    "Net Demand": 15420,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 16,
    "Net Demand": 16036,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 17,
    "Net Demand": 17286,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 18,
    "Net Demand": 17904,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 19,
    "Net Demand": 18477,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 20,
    "Net Demand": 18611,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 21,
    "Net Demand": 21101,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 22,
    "Net Demand": 26874,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 23,
    "Net Demand": 30352,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 15,
    Hour: 24,
    "Net Demand": 31524,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 1,
    "Net Demand": 30965,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 2,
    "Net Demand": 31231,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 3,
    "Net Demand": 30414,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 4,
    "Net Demand": 29814,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 5,
    "Net Demand": 29282,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 6,
    "Net Demand": 30314,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 7,
    "Net Demand": 30839,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 8,
    "Net Demand": 29937,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 9,
    "Net Demand": 27612,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 10,
    "Net Demand": 27098,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 11,
    "Net Demand": 27001,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 12,
    "Net Demand": 26646,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 13,
    "Net Demand": 26370,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 14,
    "Net Demand": 27705,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 15,
    "Net Demand": 22010,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 16,
    "Net Demand": 20510,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 17,
    "Net Demand": 21544,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 18,
    "Net Demand": 22486,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 19,
    "Net Demand": 22275,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 20,
    "Net Demand": 23441,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 21,
    "Net Demand": 23304,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 22,
    "Net Demand": 23642,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 23,
    "Net Demand": 24348,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 16,
    Hour: 24,
    "Net Demand": 27574,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 1,
    "Net Demand": 33010,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 2,
    "Net Demand": 33097,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 3,
    "Net Demand": 32350,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 4,
    "Net Demand": 33331,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 5,
    "Net Demand": 32167,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 6,
    "Net Demand": 32064,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 7,
    "Net Demand": 31690,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 8,
    "Net Demand": 31231,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 9,
    "Net Demand": 30310,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 10,
    "Net Demand": 22249,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 11,
    "Net Demand": 19826,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 12,
    "Net Demand": 19794,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 13,
    "Net Demand": 19388,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 14,
    "Net Demand": 19793,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 15,
    "Net Demand": 20405,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 16,
    "Net Demand": 21381,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 17,
    "Net Demand": 20994,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 18,
    "Net Demand": 16125,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 19,
    "Net Demand": 15024,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 20,
    "Net Demand": 19438,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 21,
    "Net Demand": 18596,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 22,
    "Net Demand": 19069,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 23,
    "Net Demand": 22874,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 17,
    Hour: 24,
    "Net Demand": 24164,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 1,
    "Net Demand": 22727,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 2,
    "Net Demand": 23058,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 3,
    "Net Demand": 22961,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 4,
    "Net Demand": 22175,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 5,
    "Net Demand": 23005,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 6,
    "Net Demand": 23048,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 7,
    "Net Demand": 22159,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 8,
    "Net Demand": 21184,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 9,
    "Net Demand": 20671,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 10,
    "Net Demand": 19818,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 11,
    "Net Demand": 18287,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 12,
    "Net Demand": 17822,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 13,
    "Net Demand": 17390,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 14,
    "Net Demand": 18621,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 15,
    "Net Demand": 19614,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 16,
    "Net Demand": 19999,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 17,
    "Net Demand": 21737,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 18,
    "Net Demand": 22814,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 19,
    "Net Demand": 23502,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 20,
    "Net Demand": 24749,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 21,
    "Net Demand": 28226,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 22,
    "Net Demand": 32194,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 23,
    "Net Demand": 32557,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 18,
    Hour: 24,
    "Net Demand": 33426,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 1,
    "Net Demand": 28654,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 2,
    "Net Demand": 27491,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 3,
    "Net Demand": 26034,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 4,
    "Net Demand": 27004,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 5,
    "Net Demand": 26870,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 6,
    "Net Demand": 25746,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 7,
    "Net Demand": 24272,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 8,
    "Net Demand": 24255,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 9,
    "Net Demand": 23302,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 10,
    "Net Demand": 21782,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 11,
    "Net Demand": 20417,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 12,
    "Net Demand": 20710,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 13,
    "Net Demand": 18618,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 14,
    "Net Demand": 15654,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 15,
    "Net Demand": 15620,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 16,
    "Net Demand": 16871,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 17,
    "Net Demand": 23119,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 18,
    "Net Demand": 26322,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 19,
    "Net Demand": 26926,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 20,
    "Net Demand": 27912,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 21,
    "Net Demand": 26999,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 22,
    "Net Demand": 26374,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 23,
    "Net Demand": 26029,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 19,
    Hour: 24,
    "Net Demand": 26982,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 1,
    "Net Demand": 28409,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 2,
    "Net Demand": 28437,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 3,
    "Net Demand": 29252,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 4,
    "Net Demand": 31083,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 5,
    "Net Demand": 30054,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 6,
    "Net Demand": 26389,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 7,
    "Net Demand": 21058,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 8,
    "Net Demand": 22396,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 9,
    "Net Demand": 20777,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 10,
    "Net Demand": 19133,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 11,
    "Net Demand": 20887,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 12,
    "Net Demand": 19329,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 13,
    "Net Demand": 21261,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 14,
    "Net Demand": 20195,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 15,
    "Net Demand": 20907,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 16,
    "Net Demand": 21635,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 17,
    "Net Demand": 23342,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 18,
    "Net Demand": 25071,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 19,
    "Net Demand": 24940,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 20,
    "Net Demand": 25570,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 21,
    "Net Demand": 25242,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 22,
    "Net Demand": 25315,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 23,
    "Net Demand": 25211,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 20,
    Hour: 24,
    "Net Demand": 26008,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 1,
    "Net Demand": 32404,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 2,
    "Net Demand": 32449,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 3,
    "Net Demand": 32137,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 4,
    "Net Demand": 32998,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 5,
    "Net Demand": 33285,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 6,
    "Net Demand": 24990,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 7,
    "Net Demand": 27473,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 8,
    "Net Demand": 30531,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 9,
    "Net Demand": 29393,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 10,
    "Net Demand": 30314,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 11,
    "Net Demand": 21889,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 12,
    "Net Demand": 27858,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 13,
    "Net Demand": 27702,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 14,
    "Net Demand": 28442,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 15,
    "Net Demand": 27154,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 16,
    "Net Demand": 24715,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 17,
    "Net Demand": 25624,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 18,
    "Net Demand": 28764,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 19,
    "Net Demand": 31691,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 20,
    "Net Demand": 29543,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 21,
    "Net Demand": 28907,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 22,
    "Net Demand": 32636,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 23,
    "Net Demand": 24394,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 21,
    Hour: 24,
    "Net Demand": 34050,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 1,
    "Net Demand": 33893,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 2,
    "Net Demand": 33973,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 3,
    "Net Demand": 33129,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 4,
    "Net Demand": 33236,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 5,
    "Net Demand": 33475,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 6,
    "Net Demand": 29881,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 7,
    "Net Demand": 33362,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 8,
    "Net Demand": 29395,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 9,
    "Net Demand": 22135,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 10,
    "Net Demand": 19992,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 11,
    "Net Demand": 20776,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 12,
    "Net Demand": 21404,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 13,
    "Net Demand": 20801,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 14,
    "Net Demand": 20513,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 15,
    "Net Demand": 20559,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 16,
    "Net Demand": 21963,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 17,
    "Net Demand": 23849,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 18,
    "Net Demand": 22853,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 19,
    "Net Demand": 23034,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 20,
    "Net Demand": 24955,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 21,
    "Net Demand": 24422,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 22,
    "Net Demand": 23967,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 23,
    "Net Demand": 24366,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 22,
    Hour: 24,
    "Net Demand": 24711,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 1,
    "Net Demand": 24339,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 2,
    "Net Demand": 24189,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 3,
    "Net Demand": 23146,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 4,
    "Net Demand": 23405,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 5,
    "Net Demand": 23042,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 6,
    "Net Demand": 23576,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 7,
    "Net Demand": 23919,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 8,
    "Net Demand": 23568,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 9,
    "Net Demand": 21697,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 10,
    "Net Demand": 21172,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 11,
    "Net Demand": 21325,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 12,
    "Net Demand": 20585,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 13,
    "Net Demand": 19587,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 14,
    "Net Demand": 19878,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 15,
    "Net Demand": 20276,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 16,
    "Net Demand": 21528,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 17,
    "Net Demand": 22513,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 18,
    "Net Demand": 23756,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 19,
    "Net Demand": 24167,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 20,
    "Net Demand": 25312,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 21,
    "Net Demand": 25531,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 22,
    "Net Demand": 23956,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 23,
    "Net Demand": 24029,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 23,
    Hour: 24,
    "Net Demand": 24059,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 1,
    "Net Demand": 24364,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 2,
    "Net Demand": 24389,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 3,
    "Net Demand": 23761,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 4,
    "Net Demand": 24053,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 5,
    "Net Demand": 24031,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 6,
    "Net Demand": 24727,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 7,
    "Net Demand": 31420,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 8,
    "Net Demand": 31660,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 9,
    "Net Demand": 30266,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 10,
    "Net Demand": 29681,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 11,
    "Net Demand": 28442,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 12,
    "Net Demand": 24012,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 13,
    "Net Demand": 30522,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 14,
    "Net Demand": 29475,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 15,
    "Net Demand": 29256,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 16,
    "Net Demand": 30428,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 17,
    "Net Demand": 30998,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 18,
    "Net Demand": 31588,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 19,
    "Net Demand": 32139,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 20,
    "Net Demand": 32844,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 21,
    "Net Demand": 31941,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 22,
    "Net Demand": 32082,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 23,
    "Net Demand": 31025,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 24,
    Hour: 24,
    "Net Demand": 31479,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 1,
    "Net Demand": 31894,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 2,
    "Net Demand": 32203,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 3,
    "Net Demand": 31400,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 4,
    "Net Demand": 32214,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 5,
    "Net Demand": 32106,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 6,
    "Net Demand": 32117,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 7,
    "Net Demand": 31328,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 8,
    "Net Demand": 32248,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 9,
    "Net Demand": 28789,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 10,
    "Net Demand": 24697,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 11,
    "Net Demand": 23541,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 12,
    "Net Demand": 23825,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 13,
    "Net Demand": 24488,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 14,
    "Net Demand": 24223,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 15,
    "Net Demand": 24178,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 16,
    "Net Demand": 23962,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 17,
    "Net Demand": 25800,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 18,
    "Net Demand": 27014,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 19,
    "Net Demand": 27132,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 20,
    "Net Demand": 27654,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 21,
    "Net Demand": 27777,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 22,
    "Net Demand": 27149,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 23,
    "Net Demand": 28297,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 25,
    Hour: 24,
    "Net Demand": 23808,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 1,
    "Net Demand": 26976,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 2,
    "Net Demand": 22546,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 3,
    "Net Demand": 22551,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 4,
    "Net Demand": 23120,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 5,
    "Net Demand": 23409,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 6,
    "Net Demand": 23609,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 7,
    "Net Demand": 23909,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 8,
    "Net Demand": 21477,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 9,
    "Net Demand": 21478,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 10,
    "Net Demand": 22033,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 11,
    "Net Demand": 21796,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 12,
    "Net Demand": 22676,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 13,
    "Net Demand": 23007,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 14,
    "Net Demand": 23387,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 15,
    "Net Demand": 24004,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 16,
    "Net Demand": 23606,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 17,
    "Net Demand": 25756,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 18,
    "Net Demand": 28168,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 19,
    "Net Demand": 29002,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 20,
    "Net Demand": 32724,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 21,
    "Net Demand": 32924,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 22,
    "Net Demand": 35946,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 23,
    "Net Demand": 32130,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 26,
    Hour: 24,
    "Net Demand": 27988,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 1,
    "Net Demand": 27714,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 2,
    "Net Demand": 24885,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 3,
    "Net Demand": 20596,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 4,
    "Net Demand": 27635,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 5,
    "Net Demand": 27984,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 6,
    "Net Demand": 27885,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 7,
    "Net Demand": 27112,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 8,
    "Net Demand": 26131,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 9,
    "Net Demand": 26351,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 10,
    "Net Demand": 27913,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 11,
    "Net Demand": 26433,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 12,
    "Net Demand": 26109,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 13,
    "Net Demand": 26332,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 14,
    "Net Demand": 26671,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 15,
    "Net Demand": 27936,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 16,
    "Net Demand": 28751,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 17,
    "Net Demand": 30946,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 18,
    "Net Demand": 31397,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 19,
    "Net Demand": 30716,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 20,
    "Net Demand": 31926,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 21,
    "Net Demand": 31372,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 22,
    "Net Demand": 30699,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 23,
    "Net Demand": 31589,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 27,
    Hour: 24,
    "Net Demand": 33212,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 1,
    "Net Demand": 31913,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 2,
    "Net Demand": 31679,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 3,
    "Net Demand": 31966,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 4,
    "Net Demand": 32958,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 5,
    "Net Demand": 32876,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 6,
    "Net Demand": 32686,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 7,
    "Net Demand": 30421,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 8,
    "Net Demand": 23056,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 9,
    "Net Demand": 18445,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 10,
    "Net Demand": 18965,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 11,
    "Net Demand": 20898,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 12,
    "Net Demand": 21986,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 13,
    "Net Demand": 22340,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 14,
    "Net Demand": 21403,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 15,
    "Net Demand": 21654,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 16,
    "Net Demand": 21882,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 17,
    "Net Demand": 23444,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 18,
    "Net Demand": 22314,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 19,
    "Net Demand": 23668,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 20,
    "Net Demand": 23850,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 21,
    "Net Demand": 23761,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 22,
    "Net Demand": 24025,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 23,
    "Net Demand": 21724,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 28,
    Hour: 24,
    "Net Demand": 23957,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 1,
    "Net Demand": 25021,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 2,
    "Net Demand": 24090,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 3,
    "Net Demand": 23310,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 4,
    "Net Demand": 23460,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 5,
    "Net Demand": 23795,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 6,
    "Net Demand": 25336,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 7,
    "Net Demand": 23868,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 8,
    "Net Demand": 18906,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 9,
    "Net Demand": 17055,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 10,
    "Net Demand": 15686,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 11,
    "Net Demand": 14165,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 12,
    "Net Demand": 15481,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 13,
    "Net Demand": 19277,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 14,
    "Net Demand": 19753,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 15,
    "Net Demand": 20492,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 16,
    "Net Demand": 22239,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 17,
    "Net Demand": 22415,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 18,
    "Net Demand": 24346,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 19,
    "Net Demand": 24233,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 20,
    "Net Demand": 24853,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 21,
    "Net Demand": 23900,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 22,
    "Net Demand": 22044,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 23,
    "Net Demand": 23851,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 29,
    Hour: 24,
    "Net Demand": 23268,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 1,
    "Net Demand": 27477,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 2,
    "Net Demand": 29451,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 3,
    "Net Demand": 30881,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 4,
    "Net Demand": 31210,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 5,
    "Net Demand": 31005,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 6,
    "Net Demand": 32138,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 7,
    "Net Demand": 31857,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 8,
    "Net Demand": 30860,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 9,
    "Net Demand": 29075,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 10,
    "Net Demand": 29648,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 11,
    "Net Demand": 22609,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 12,
    "Net Demand": 25870,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 13,
    "Net Demand": 25392,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 14,
    "Net Demand": 22999,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 15,
    "Net Demand": 22803,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 16,
    "Net Demand": 24080,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 17,
    "Net Demand": 25272,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 18,
    "Net Demand": 27356,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 19,
    "Net Demand": 32782,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 20,
    "Net Demand": 32591,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 21,
    "Net Demand": 31700,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 22,
    "Net Demand": 31108,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 23,
    "Net Demand": 32412,
  },
  {
    Year: 2023,
    Month: 6,
    Day: 30,
    Hour: 24,
    "Net Demand": 32317,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 1,
    "Net Demand": 31821,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 2,
    "Net Demand": 31666,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 3,
    "Net Demand": 31394,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 4,
    "Net Demand": 33097,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 5,
    "Net Demand": 31942,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 6,
    "Net Demand": 32278,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 7,
    "Net Demand": 31636,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 8,
    "Net Demand": 30936,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 9,
    "Net Demand": 29010,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 10,
    "Net Demand": 23298,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 11,
    "Net Demand": 19951,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 12,
    "Net Demand": 24441,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 13,
    "Net Demand": 25035,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 14,
    "Net Demand": 20873,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 15,
    "Net Demand": 21979,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 16,
    "Net Demand": 23405,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 17,
    "Net Demand": 26928,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 18,
    "Net Demand": 28075,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 19,
    "Net Demand": 27502,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 20,
    "Net Demand": 27684,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 21,
    "Net Demand": 27099,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 22,
    "Net Demand": 28044,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 23,
    "Net Demand": 27303,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 1,
    Hour: 24,
    "Net Demand": 27802,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 1,
    "Net Demand": 25330,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 2,
    "Net Demand": 20254,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 3,
    "Net Demand": 18196,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 4,
    "Net Demand": 18907,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 5,
    "Net Demand": 18833,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 6,
    "Net Demand": 19795,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 7,
    "Net Demand": 16393,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 8,
    "Net Demand": 15137,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 9,
    "Net Demand": 17027,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 10,
    "Net Demand": 16531,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 11,
    "Net Demand": 16061,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 12,
    "Net Demand": 15878,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 13,
    "Net Demand": 16756,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 14,
    "Net Demand": 15853,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 15,
    "Net Demand": 11090,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 16,
    "Net Demand": 10994,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 17,
    "Net Demand": 11421,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 18,
    "Net Demand": 11874,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 19,
    "Net Demand": 11648,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 20,
    "Net Demand": 12476,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 21,
    "Net Demand": 11948,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 22,
    "Net Demand": 11176,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 23,
    "Net Demand": 11660,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 2,
    Hour: 24,
    "Net Demand": 11243,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 1,
    "Net Demand": 10348,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 2,
    "Net Demand": 10400,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 3,
    "Net Demand": 10270,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 4,
    "Net Demand": 10298,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 5,
    "Net Demand": 9990,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 6,
    "Net Demand": 9933,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 7,
    "Net Demand": 9266,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 8,
    "Net Demand": 8748,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 9,
    "Net Demand": 8372,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 10,
    "Net Demand": 7983,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 11,
    "Net Demand": 6594,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 12,
    "Net Demand": 7532,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 13,
    "Net Demand": 6387,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 14,
    "Net Demand": 5914,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 15,
    "Net Demand": 7153,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 16,
    "Net Demand": 7217,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 17,
    "Net Demand": 9156,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 18,
    "Net Demand": 9073,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 19,
    "Net Demand": 9277,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 20,
    "Net Demand": 10343,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 21,
    "Net Demand": 10293,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 22,
    "Net Demand": 10315,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 23,
    "Net Demand": 10704,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 3,
    Hour: 24,
    "Net Demand": 12928,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 1,
    "Net Demand": 18012,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 2,
    "Net Demand": 15510,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 3,
    "Net Demand": 17928,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 4,
    "Net Demand": 18310,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 5,
    "Net Demand": 18212,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 6,
    "Net Demand": 18170,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 7,
    "Net Demand": 18725,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 8,
    "Net Demand": 19209,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 9,
    "Net Demand": 20371,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 10,
    "Net Demand": 18051,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 11,
    "Net Demand": 7941,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 12,
    "Net Demand": 7893,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 13,
    "Net Demand": 9102,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 14,
    "Net Demand": 11966,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 15,
    "Net Demand": 16502,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 16,
    "Net Demand": 20286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 17,
    "Net Demand": 22551,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 18,
    "Net Demand": 23656,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 19,
    "Net Demand": 25687,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 20,
    "Net Demand": 28259,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 21,
    "Net Demand": 31133,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 22,
    "Net Demand": 29666,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 23,
    "Net Demand": 29989,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 4,
    Hour: 24,
    "Net Demand": 30396,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 1,
    "Net Demand": 31939,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 2,
    "Net Demand": 31090,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 3,
    "Net Demand": 31318,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 4,
    "Net Demand": 31777,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 5,
    "Net Demand": 31082,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 6,
    "Net Demand": 24599,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 7,
    "Net Demand": 28097,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 8,
    "Net Demand": 29718,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 9,
    "Net Demand": 29076,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 10,
    "Net Demand": 28741,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 11,
    "Net Demand": 26425,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 12,
    "Net Demand": 27338,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 13,
    "Net Demand": 27926,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 14,
    "Net Demand": 27522,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 15,
    "Net Demand": 27513,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 16,
    "Net Demand": 28583,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 17,
    "Net Demand": 31429,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 18,
    "Net Demand": 32255,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 19,
    "Net Demand": 32454,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 20,
    "Net Demand": 33502,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 21,
    "Net Demand": 33025,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 22,
    "Net Demand": 32990,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 23,
    "Net Demand": 33486,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 5,
    Hour: 24,
    "Net Demand": 32083,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 1,
    "Net Demand": 32247,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 2,
    "Net Demand": 32077,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 3,
    "Net Demand": 31286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 4,
    "Net Demand": 32026,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 5,
    "Net Demand": 31417,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 6,
    "Net Demand": 32095,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 7,
    "Net Demand": 32703,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 8,
    "Net Demand": 31250,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 9,
    "Net Demand": 29377,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 10,
    "Net Demand": 28327,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 11,
    "Net Demand": 24102,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 12,
    "Net Demand": 26208,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 13,
    "Net Demand": 25510,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 14,
    "Net Demand": 26787,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 15,
    "Net Demand": 27324,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 16,
    "Net Demand": 28532,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 17,
    "Net Demand": 30288,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 18,
    "Net Demand": 29587,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 19,
    "Net Demand": 29083,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 20,
    "Net Demand": 33171,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 21,
    "Net Demand": 33569,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 22,
    "Net Demand": 33072,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 23,
    "Net Demand": 32858,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 6,
    Hour: 24,
    "Net Demand": 32453,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 1,
    "Net Demand": 31260,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 2,
    "Net Demand": 30613,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 3,
    "Net Demand": 29832,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 4,
    "Net Demand": 31812,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 5,
    "Net Demand": 32526,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 6,
    "Net Demand": 31375,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 7,
    "Net Demand": 29981,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 8,
    "Net Demand": 29174,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 9,
    "Net Demand": 29763,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 10,
    "Net Demand": 30155,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 11,
    "Net Demand": 30230,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 12,
    "Net Demand": 29615,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 13,
    "Net Demand": 28262,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 14,
    "Net Demand": 27536,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 15,
    "Net Demand": 27902,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 16,
    "Net Demand": 27671,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 17,
    "Net Demand": 31308,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 18,
    "Net Demand": 32338,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 19,
    "Net Demand": 31396,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 20,
    "Net Demand": 32506,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 21,
    "Net Demand": 32079,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 22,
    "Net Demand": 32454,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 23,
    "Net Demand": 30957,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 7,
    Hour: 24,
    "Net Demand": 31407,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 1,
    "Net Demand": 30773,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 2,
    "Net Demand": 30920,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 3,
    "Net Demand": 29811,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 4,
    "Net Demand": 32291,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 5,
    "Net Demand": 32056,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 6,
    "Net Demand": 31254,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 7,
    "Net Demand": 30386,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 8,
    "Net Demand": 29789,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 9,
    "Net Demand": 29014,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 10,
    "Net Demand": 27674,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 11,
    "Net Demand": 25681,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 12,
    "Net Demand": 27180,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 13,
    "Net Demand": 28117,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 14,
    "Net Demand": 28527,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 15,
    "Net Demand": 28230,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 16,
    "Net Demand": 29495,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 17,
    "Net Demand": 26134,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 18,
    "Net Demand": 25881,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 19,
    "Net Demand": 32572,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 20,
    "Net Demand": 33722,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 21,
    "Net Demand": 32946,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 22,
    "Net Demand": 31872,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 23,
    "Net Demand": 30726,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 8,
    Hour: 24,
    "Net Demand": 30916,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 1,
    "Net Demand": 31222,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 2,
    "Net Demand": 30629,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 3,
    "Net Demand": 33604,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 4,
    "Net Demand": 33527,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 5,
    "Net Demand": 32408,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 6,
    "Net Demand": 31986,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 7,
    "Net Demand": 32177,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 8,
    "Net Demand": 31933,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 9,
    "Net Demand": 30184,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 10,
    "Net Demand": 25656,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 11,
    "Net Demand": 22286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 12,
    "Net Demand": 24312,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 13,
    "Net Demand": 24109,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 14,
    "Net Demand": 22515,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 15,
    "Net Demand": 23474,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 16,
    "Net Demand": 25346,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 17,
    "Net Demand": 27843,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 18,
    "Net Demand": 26727,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 19,
    "Net Demand": 26755,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 20,
    "Net Demand": 27433,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 21,
    "Net Demand": 26771,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 22,
    "Net Demand": 27970,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 23,
    "Net Demand": 26382,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 9,
    Hour: 24,
    "Net Demand": 26373,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 1,
    "Net Demand": 26252,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 2,
    "Net Demand": 25211,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 3,
    "Net Demand": 24726,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 4,
    "Net Demand": 25449,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 5,
    "Net Demand": 26042,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 6,
    "Net Demand": 23917,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 7,
    "Net Demand": 24987,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 8,
    "Net Demand": 23867,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 9,
    "Net Demand": 23577,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 10,
    "Net Demand": 21507,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 11,
    "Net Demand": 21008,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 12,
    "Net Demand": 21169,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 13,
    "Net Demand": 21083,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 14,
    "Net Demand": 22101,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 15,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 16,
    "Net Demand": 24660,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 17,
    "Net Demand": 25823,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 18,
    "Net Demand": 27125,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 19,
    "Net Demand": 27646,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 20,
    "Net Demand": 27219,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 21,
    "Net Demand": 26110,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 22,
    "Net Demand": 26087,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 23,
    "Net Demand": 27136,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 10,
    Hour: 24,
    "Net Demand": 26542,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 1,
    "Net Demand": 26339,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 2,
    "Net Demand": 26161,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 3,
    "Net Demand": 25870,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 4,
    "Net Demand": 27138,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 5,
    "Net Demand": 27102,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 6,
    "Net Demand": 26005,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 7,
    "Net Demand": 26325,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 8,
    "Net Demand": 25810,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 9,
    "Net Demand": 24452,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 10,
    "Net Demand": 24284,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 11,
    "Net Demand": 21415,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 12,
    "Net Demand": 23469,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 13,
    "Net Demand": 26472,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 14,
    "Net Demand": 25278,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 15,
    "Net Demand": 24798,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 16,
    "Net Demand": 27184,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 17,
    "Net Demand": 28109,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 18,
    "Net Demand": 26927,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 19,
    "Net Demand": 25663,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 20,
    "Net Demand": 26546,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 21,
    "Net Demand": 26246,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 22,
    "Net Demand": 27132,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 23,
    "Net Demand": 31194,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 11,
    Hour: 24,
    "Net Demand": 31445,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 1,
    "Net Demand": 30341,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 2,
    "Net Demand": 30521,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 3,
    "Net Demand": 29825,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 4,
    "Net Demand": 29986,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 5,
    "Net Demand": 30652,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 6,
    "Net Demand": 30642,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 7,
    "Net Demand": 31018,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 8,
    "Net Demand": 30185,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 9,
    "Net Demand": 27359,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 10,
    "Net Demand": 25964,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 11,
    "Net Demand": 25486,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 12,
    "Net Demand": 25764,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 13,
    "Net Demand": 26057,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 14,
    "Net Demand": 26360,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 15,
    "Net Demand": 24317,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 16,
    "Net Demand": 18105,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 17,
    "Net Demand": 18174,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 18,
    "Net Demand": 19616,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 19,
    "Net Demand": 19664,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 20,
    "Net Demand": 23111,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 21,
    "Net Demand": 30231,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 22,
    "Net Demand": 32184,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 23,
    "Net Demand": 32137,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 12,
    Hour: 24,
    "Net Demand": 31656,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 1,
    "Net Demand": 30996,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 2,
    "Net Demand": 29822,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 3,
    "Net Demand": 29376,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 4,
    "Net Demand": 29170,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 5,
    "Net Demand": 30244,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 6,
    "Net Demand": 29604,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 7,
    "Net Demand": 29924,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 8,
    "Net Demand": 28531,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 9,
    "Net Demand": 27877,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 10,
    "Net Demand": 26655,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 11,
    "Net Demand": 25793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 12,
    "Net Demand": 26186,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 13,
    "Net Demand": 26690,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 14,
    "Net Demand": 27378,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 15,
    "Net Demand": 28917,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 16,
    "Net Demand": 30297,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 17,
    "Net Demand": 30575,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 18,
    "Net Demand": 30616,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 19,
    "Net Demand": 30338,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 20,
    "Net Demand": 31547,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 21,
    "Net Demand": 28910,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 22,
    "Net Demand": 26405,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 23,
    "Net Demand": 29436,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 13,
    Hour: 24,
    "Net Demand": 33187,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 1,
    "Net Demand": 32743,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 2,
    "Net Demand": 31705,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 3,
    "Net Demand": 30210,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 4,
    "Net Demand": 30934,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 5,
    "Net Demand": 30524,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 6,
    "Net Demand": 30957,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 7,
    "Net Demand": 30286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 8,
    "Net Demand": 29535,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 9,
    "Net Demand": 29908,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 10,
    "Net Demand": 28887,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 11,
    "Net Demand": 27418,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 12,
    "Net Demand": 27994,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 13,
    "Net Demand": 28796,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 14,
    "Net Demand": 27522,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 15,
    "Net Demand": 25754,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 16,
    "Net Demand": 28879,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 17,
    "Net Demand": 30563,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 18,
    "Net Demand": 30981,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 19,
    "Net Demand": 30466,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 20,
    "Net Demand": 31534,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 21,
    "Net Demand": 32568,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 22,
    "Net Demand": 30658,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 23,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 14,
    Hour: 24,
    "Net Demand": 29597,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 1,
    "Net Demand": 30638,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 2,
    "Net Demand": 29881,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 3,
    "Net Demand": 29253,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 4,
    "Net Demand": 30148,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 5,
    "Net Demand": 30550,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 6,
    "Net Demand": 29529,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 7,
    "Net Demand": 28602,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 8,
    "Net Demand": 29066,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 9,
    "Net Demand": 27886,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 10,
    "Net Demand": 17217,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 11,
    "Net Demand": 13411,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 12,
    "Net Demand": 13887,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 13,
    "Net Demand": 14084,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 14,
    "Net Demand": 14157,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 15,
    "Net Demand": 14737,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 16,
    "Net Demand": 15325,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 17,
    "Net Demand": 16520,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 18,
    "Net Demand": 17111,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 19,
    "Net Demand": 17658,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 20,
    "Net Demand": 17786,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 21,
    "Net Demand": 20167,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 22,
    "Net Demand": 25684,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 23,
    "Net Demand": 29008,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 15,
    Hour: 24,
    "Net Demand": 30128,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 1,
    "Net Demand": 29593,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 2,
    "Net Demand": 29847,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 3,
    "Net Demand": 29067,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 4,
    "Net Demand": 28493,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 5,
    "Net Demand": 27985,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 6,
    "Net Demand": 28971,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 7,
    "Net Demand": 29473,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 8,
    "Net Demand": 28611,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 9,
    "Net Demand": 26389,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 10,
    "Net Demand": 25898,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 11,
    "Net Demand": 25805,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 12,
    "Net Demand": 25466,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 13,
    "Net Demand": 25202,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 14,
    "Net Demand": 26477,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 15,
    "Net Demand": 21035,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 16,
    "Net Demand": 19602,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 17,
    "Net Demand": 20590,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 18,
    "Net Demand": 21490,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 19,
    "Net Demand": 21288,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 20,
    "Net Demand": 22403,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 21,
    "Net Demand": 22272,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 22,
    "Net Demand": 22595,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 23,
    "Net Demand": 23269,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 16,
    Hour: 24,
    "Net Demand": 26353,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 1,
    "Net Demand": 31547,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 2,
    "Net Demand": 31631,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 3,
    "Net Demand": 30917,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 4,
    "Net Demand": 31854,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 5,
    "Net Demand": 30743,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 6,
    "Net Demand": 30644,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 7,
    "Net Demand": 30286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 8,
    "Net Demand": 29847,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 9,
    "Net Demand": 28968,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 10,
    "Net Demand": 21264,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 11,
    "Net Demand": 18948,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 12,
    "Net Demand": 18918,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 13,
    "Net Demand": 18529,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 14,
    "Net Demand": 18916,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 15,
    "Net Demand": 19501,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 16,
    "Net Demand": 20434,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 17,
    "Net Demand": 20064,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 18,
    "Net Demand": 15411,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 19,
    "Net Demand": 14359,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 20,
    "Net Demand": 18577,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 21,
    "Net Demand": 17772,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 22,
    "Net Demand": 18224,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 23,
    "Net Demand": 21860,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 17,
    Hour: 24,
    "Net Demand": 23093,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 1,
    "Net Demand": 21721,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 2,
    "Net Demand": 22037,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 3,
    "Net Demand": 21944,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 4,
    "Net Demand": 21193,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 5,
    "Net Demand": 21986,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 6,
    "Net Demand": 22027,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 7,
    "Net Demand": 21177,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 8,
    "Net Demand": 20246,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 9,
    "Net Demand": 19756,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 10,
    "Net Demand": 18940,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 11,
    "Net Demand": 17477,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 12,
    "Net Demand": 17033,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 13,
    "Net Demand": 16620,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 14,
    "Net Demand": 17796,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 15,
    "Net Demand": 18745,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 16,
    "Net Demand": 19113,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 17,
    "Net Demand": 20775,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 18,
    "Net Demand": 21804,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 19,
    "Net Demand": 22461,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 20,
    "Net Demand": 23653,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 21,
    "Net Demand": 26976,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 22,
    "Net Demand": 30768,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 23,
    "Net Demand": 31115,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 18,
    Hour: 24,
    "Net Demand": 31945,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 1,
    "Net Demand": 27385,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 2,
    "Net Demand": 26273,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 3,
    "Net Demand": 24881,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 4,
    "Net Demand": 25808,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 5,
    "Net Demand": 25680,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 6,
    "Net Demand": 24606,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 7,
    "Net Demand": 23197,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 8,
    "Net Demand": 23180,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 9,
    "Net Demand": 22270,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 10,
    "Net Demand": 20817,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 11,
    "Net Demand": 19513,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 12,
    "Net Demand": 19793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 13,
    "Net Demand": 17793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 14,
    "Net Demand": 14961,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 15,
    "Net Demand": 14928,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 16,
    "Net Demand": 16124,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 17,
    "Net Demand": 22095,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 18,
    "Net Demand": 25156,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 19,
    "Net Demand": 25733,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 20,
    "Net Demand": 26676,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 21,
    "Net Demand": 25803,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 22,
    "Net Demand": 25206,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 23,
    "Net Demand": 24876,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 19,
    Hour: 24,
    "Net Demand": 25787,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 1,
    "Net Demand": 27150,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 2,
    "Net Demand": 27178,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 3,
    "Net Demand": 27957,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 4,
    "Net Demand": 29707,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 5,
    "Net Demand": 28723,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 6,
    "Net Demand": 25220,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 7,
    "Net Demand": 20125,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 8,
    "Net Demand": 21404,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 9,
    "Net Demand": 19856,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 10,
    "Net Demand": 18285,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 11,
    "Net Demand": 19962,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 12,
    "Net Demand": 18473,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 13,
    "Net Demand": 20320,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 14,
    "Net Demand": 19301,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 15,
    "Net Demand": 19981,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 16,
    "Net Demand": 20677,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 17,
    "Net Demand": 22308,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 18,
    "Net Demand": 23961,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 19,
    "Net Demand": 23835,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 20,
    "Net Demand": 24438,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 21,
    "Net Demand": 24124,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 22,
    "Net Demand": 24194,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 23,
    "Net Demand": 24095,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 20,
    Hour: 24,
    "Net Demand": 24856,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 1,
    "Net Demand": 30969,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 2,
    "Net Demand": 31012,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 3,
    "Net Demand": 30713,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 4,
    "Net Demand": 31537,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 5,
    "Net Demand": 31811,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 6,
    "Net Demand": 23883,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 7,
    "Net Demand": 26256,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 8,
    "Net Demand": 29179,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 9,
    "Net Demand": 28092,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 10,
    "Net Demand": 28971,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 11,
    "Net Demand": 20919,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 12,
    "Net Demand": 26624,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 13,
    "Net Demand": 26475,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 14,
    "Net Demand": 27183,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 15,
    "Net Demand": 25952,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 16,
    "Net Demand": 23620,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 17,
    "Net Demand": 24489,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 18,
    "Net Demand": 27490,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 19,
    "Net Demand": 30287,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 20,
    "Net Demand": 28234,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 21,
    "Net Demand": 27627,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 22,
    "Net Demand": 31191,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 23,
    "Net Demand": 23314,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 21,
    Hour: 24,
    "Net Demand": 32542,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 1,
    "Net Demand": 32391,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 2,
    "Net Demand": 32468,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 3,
    "Net Demand": 31662,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 4,
    "Net Demand": 31764,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 5,
    "Net Demand": 31992,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 6,
    "Net Demand": 28558,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 7,
    "Net Demand": 31884,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 8,
    "Net Demand": 28093,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 9,
    "Net Demand": 21154,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 10,
    "Net Demand": 19106,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 11,
    "Net Demand": 19856,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 12,
    "Net Demand": 20456,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 13,
    "Net Demand": 19880,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 14,
    "Net Demand": 19604,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 15,
    "Net Demand": 19648,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 16,
    "Net Demand": 20990,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 17,
    "Net Demand": 22793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 18,
    "Net Demand": 21841,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 19,
    "Net Demand": 22014,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 20,
    "Net Demand": 23850,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 21,
    "Net Demand": 23341,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 22,
    "Net Demand": 22905,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 23,
    "Net Demand": 23286,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 22,
    Hour: 24,
    "Net Demand": 23616,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 1,
    "Net Demand": 23261,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 2,
    "Net Demand": 23117,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 3,
    "Net Demand": 22121,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 4,
    "Net Demand": 22368,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 5,
    "Net Demand": 22022,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 6,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 7,
    "Net Demand": 22859,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 8,
    "Net Demand": 22524,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 9,
    "Net Demand": 20736,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 10,
    "Net Demand": 20234,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 11,
    "Net Demand": 20381,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 12,
    "Net Demand": 19673,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 13,
    "Net Demand": 18720,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 14,
    "Net Demand": 18997,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 15,
    "Net Demand": 19377,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 16,
    "Net Demand": 20574,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 17,
    "Net Demand": 21516,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 18,
    "Net Demand": 22703,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 19,
    "Net Demand": 23096,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 20,
    "Net Demand": 24191,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 21,
    "Net Demand": 24400,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 22,
    "Net Demand": 22895,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 23,
    "Net Demand": 22965,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 23,
    Hour: 24,
    "Net Demand": 22993,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 1,
    "Net Demand": 23285,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 2,
    "Net Demand": 23309,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 3,
    "Net Demand": 22709,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 4,
    "Net Demand": 22988,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 5,
    "Net Demand": 22967,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 6,
    "Net Demand": 23632,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 7,
    "Net Demand": 30029,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 8,
    "Net Demand": 30257,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 9,
    "Net Demand": 28925,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 10,
    "Net Demand": 28367,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 11,
    "Net Demand": 27182,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 12,
    "Net Demand": 22949,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 13,
    "Net Demand": 29170,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 14,
    "Net Demand": 28170,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 15,
    "Net Demand": 27960,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 16,
    "Net Demand": 29080,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 17,
    "Net Demand": 29625,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 18,
    "Net Demand": 30189,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 19,
    "Net Demand": 30716,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 20,
    "Net Demand": 31389,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 21,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 22,
    "Net Demand": 30661,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 23,
    "Net Demand": 29651,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 24,
    Hour: 24,
    "Net Demand": 30084,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 1,
    "Net Demand": 30481,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 2,
    "Net Demand": 30777,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 3,
    "Net Demand": 30009,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 4,
    "Net Demand": 30787,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 5,
    "Net Demand": 30684,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 6,
    "Net Demand": 30694,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 7,
    "Net Demand": 29941,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 8,
    "Net Demand": 30819,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 9,
    "Net Demand": 27514,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 10,
    "Net Demand": 23603,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 11,
    "Net Demand": 22498,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 12,
    "Net Demand": 22770,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 13,
    "Net Demand": 23403,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 14,
    "Net Demand": 23151,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 15,
    "Net Demand": 23107,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 16,
    "Net Demand": 22900,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 17,
    "Net Demand": 24657,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 18,
    "Net Demand": 25817,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 19,
    "Net Demand": 25930,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 20,
    "Net Demand": 26429,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 21,
    "Net Demand": 26547,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 22,
    "Net Demand": 25947,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 23,
    "Net Demand": 27044,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 25,
    Hour: 24,
    "Net Demand": 22754,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 1,
    "Net Demand": 25781,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 2,
    "Net Demand": 21548,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 3,
    "Net Demand": 21552,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 4,
    "Net Demand": 22096,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 5,
    "Net Demand": 22372,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 6,
    "Net Demand": 22564,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 7,
    "Net Demand": 22850,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 8,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 9,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 10,
    "Net Demand": 21057,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 11,
    "Net Demand": 20830,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 12,
    "Net Demand": 21671,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 13,
    "Net Demand": 21987,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 14,
    "Net Demand": 22351,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 15,
    "Net Demand": 22941,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 16,
    "Net Demand": 22560,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 17,
    "Net Demand": 24615,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 18,
    "Net Demand": 26921,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 19,
    "Net Demand": 27717,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 20,
    "Net Demand": 31275,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 21,
    "Net Demand": 31465,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 22,
    "Net Demand": 34354,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 23,
    "Net Demand": 30706,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 26,
    Hour: 24,
    "Net Demand": 26749,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 1,
    "Net Demand": 26486,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 2,
    "Net Demand": 23783,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 3,
    "Net Demand": 19683,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 4,
    "Net Demand": 26411,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 5,
    "Net Demand": 26744,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 6,
    "Net Demand": 26650,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 7,
    "Net Demand": 25912,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 8,
    "Net Demand": 24974,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 9,
    "Net Demand": 25184,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 10,
    "Net Demand": 26677,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 11,
    "Net Demand": 25262,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 12,
    "Net Demand": 24952,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 13,
    "Net Demand": 25166,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 14,
    "Net Demand": 25490,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 15,
    "Net Demand": 26698,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 16,
    "Net Demand": 27478,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 17,
    "Net Demand": 29575,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 18,
    "Net Demand": 30007,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 19,
    "Net Demand": 29355,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 20,
    "Net Demand": 30512,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 21,
    "Net Demand": 29982,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 22,
    "Net Demand": 29339,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 23,
    "Net Demand": 30189,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 27,
    Hour: 24,
    "Net Demand": 31741,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 1,
    "Net Demand": 30499,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 2,
    "Net Demand": 30276,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 3,
    "Net Demand": 30550,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 4,
    "Net Demand": 31498,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 5,
    "Net Demand": 31420,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 6,
    "Net Demand": 31238,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 7,
    "Net Demand": 29074,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 8,
    "Net Demand": 22035,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 9,
    "Net Demand": 17628,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 10,
    "Net Demand": 18125,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 11,
    "Net Demand": 19972,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 12,
    "Net Demand": 21012,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 13,
    "Net Demand": 21351,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 14,
    "Net Demand": 20455,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 15,
    "Net Demand": 20695,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 16,
    "Net Demand": 20913,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 17,
    "Net Demand": 22406,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 18,
    "Net Demand": 21326,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 19,
    "Net Demand": 22619,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 20,
    "Net Demand": 22793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 21,
    "Net Demand": 22708,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 22,
    "Net Demand": 22961,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 23,
    "Net Demand": 20762,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 28,
    Hour: 24,
    "Net Demand": 22896,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 1,
    "Net Demand": 23913,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 2,
    "Net Demand": 23023,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 3,
    "Net Demand": 22277,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 4,
    "Net Demand": 22420,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 5,
    "Net Demand": 22741,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 6,
    "Net Demand": 24213,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 7,
    "Net Demand": 22811,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 8,
    "Net Demand": 18069,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 9,
    "Net Demand": 16299,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 10,
    "Net Demand": 14991,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 11,
    "Net Demand": 13538,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 12,
    "Net Demand": 14795,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 13,
    "Net Demand": 18423,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 14,
    "Net Demand": 18878,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 15,
    "Net Demand": 19584,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 16,
    "Net Demand": 21254,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 17,
    "Net Demand": 21423,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 18,
    "Net Demand": 23267,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 19,
    "Net Demand": 23159,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 20,
    "Net Demand": 23752,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 21,
    "Net Demand": 22841,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 22,
    "Net Demand": 21068,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 23,
    "Net Demand": 22794,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 29,
    Hour: 24,
    "Net Demand": 22237,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 1,
    "Net Demand": 26260,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 2,
    "Net Demand": 28147,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 3,
    "Net Demand": 29513,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 4,
    "Net Demand": 29827,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 5,
    "Net Demand": 29631,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 6,
    "Net Demand": 30714,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 7,
    "Net Demand": 30446,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 8,
    "Net Demand": 29493,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 9,
    "Net Demand": 27787,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 10,
    "Net Demand": 28335,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 11,
    "Net Demand": 21607,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 12,
    "Net Demand": 24724,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 13,
    "Net Demand": 24267,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 14,
    "Net Demand": 21980,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 15,
    "Net Demand": 21793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 16,
    "Net Demand": 23014,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 17,
    "Net Demand": 24152,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 18,
    "Net Demand": 26144,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 19,
    "Net Demand": 31330,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 20,
    "Net Demand": 31148,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 21,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 22,
    "Net Demand": 29730,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 23,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 30,
    Hour: 24,
    "Net Demand": 30885,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 1,
    "Net Demand": 26260,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 2,
    "Net Demand": 28147,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 3,
    "Net Demand": 29513,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 4,
    "Net Demand": 29827,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 5,
    "Net Demand": 29631,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 6,
    "Net Demand": 30714,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 7,
    "Net Demand": 30446,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 8,
    "Net Demand": 29493,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 9,
    "Net Demand": 27787,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 10,
    "Net Demand": 28335,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 11,
    "Net Demand": 21607,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 12,
    "Net Demand": 24724,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 13,
    "Net Demand": 24267,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 14,
    "Net Demand": 21980,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 15,
    "Net Demand": 21793,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 16,
    "Net Demand": 23014,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 17,
    "Net Demand": 24152,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 18,
    "Net Demand": 26144,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 19,
    "Net Demand": 31330,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 20,
    "Net Demand": 31148,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 21,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 22,
    "Net Demand": 29730,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 23,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 7,
    Day: 31,
    Hour: 24,
    "Net Demand": 30885,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 1,
    "Net Demand": 31821,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 2,
    "Net Demand": 31666,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 3,
    "Net Demand": 31394,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 4,
    "Net Demand": 33097,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 5,
    "Net Demand": 31942,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 6,
    "Net Demand": 32278,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 7,
    "Net Demand": 31636,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 8,
    "Net Demand": 30936,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 9,
    "Net Demand": 29010,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 10,
    "Net Demand": 23298,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 11,
    "Net Demand": 19951,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 12,
    "Net Demand": 24441,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 13,
    "Net Demand": 25035,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 14,
    "Net Demand": 20873,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 15,
    "Net Demand": 21979,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 16,
    "Net Demand": 23405,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 17,
    "Net Demand": 26928,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 18,
    "Net Demand": 28075,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 19,
    "Net Demand": 27502,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 20,
    "Net Demand": 27684,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 21,
    "Net Demand": 27099,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 22,
    "Net Demand": 28044,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 23,
    "Net Demand": 27303,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 1,
    Hour: 24,
    "Net Demand": 27802,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 1,
    "Net Demand": 25330,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 2,
    "Net Demand": 20254,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 3,
    "Net Demand": 18196,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 4,
    "Net Demand": 18907,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 5,
    "Net Demand": 18833,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 6,
    "Net Demand": 19795,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 7,
    "Net Demand": 16393,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 8,
    "Net Demand": 15137,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 9,
    "Net Demand": 17027,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 10,
    "Net Demand": 16531,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 11,
    "Net Demand": 16061,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 12,
    "Net Demand": 15878,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 13,
    "Net Demand": 16756,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 14,
    "Net Demand": 15853,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 15,
    "Net Demand": 11090,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 16,
    "Net Demand": 10994,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 17,
    "Net Demand": 11421,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 18,
    "Net Demand": 11874,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 19,
    "Net Demand": 11648,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 20,
    "Net Demand": 12476,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 21,
    "Net Demand": 11948,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 22,
    "Net Demand": 11176,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 23,
    "Net Demand": 11660,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 2,
    Hour: 24,
    "Net Demand": 11243,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 1,
    "Net Demand": 10348,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 2,
    "Net Demand": 10400,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 3,
    "Net Demand": 10270,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 4,
    "Net Demand": 10298,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 5,
    "Net Demand": 9990,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 6,
    "Net Demand": 9933,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 7,
    "Net Demand": 9266,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 8,
    "Net Demand": 8748,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 9,
    "Net Demand": 8372,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 10,
    "Net Demand": 7983,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 11,
    "Net Demand": 6594,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 12,
    "Net Demand": 7532,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 13,
    "Net Demand": 6387,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 14,
    "Net Demand": 5914,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 15,
    "Net Demand": 7153,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 16,
    "Net Demand": 7217,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 17,
    "Net Demand": 9156,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 18,
    "Net Demand": 9073,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 19,
    "Net Demand": 9277,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 20,
    "Net Demand": 10343,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 21,
    "Net Demand": 10293,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 22,
    "Net Demand": 10315,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 23,
    "Net Demand": 10704,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 3,
    Hour: 24,
    "Net Demand": 12928,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 1,
    "Net Demand": 18012,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 2,
    "Net Demand": 15510,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 3,
    "Net Demand": 17928,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 4,
    "Net Demand": 18310,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 5,
    "Net Demand": 18212,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 6,
    "Net Demand": 18170,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 7,
    "Net Demand": 18725,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 8,
    "Net Demand": 19209,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 9,
    "Net Demand": 20371,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 10,
    "Net Demand": 18051,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 11,
    "Net Demand": 7941,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 12,
    "Net Demand": 7893,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 13,
    "Net Demand": 9102,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 14,
    "Net Demand": 11966,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 15,
    "Net Demand": 16502,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 16,
    "Net Demand": 20286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 17,
    "Net Demand": 22551,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 18,
    "Net Demand": 23656,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 19,
    "Net Demand": 25687,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 20,
    "Net Demand": 28259,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 21,
    "Net Demand": 31133,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 22,
    "Net Demand": 29666,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 23,
    "Net Demand": 29989,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 4,
    Hour: 24,
    "Net Demand": 30396,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 1,
    "Net Demand": 31939,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 2,
    "Net Demand": 31090,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 3,
    "Net Demand": 31318,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 4,
    "Net Demand": 31777,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 5,
    "Net Demand": 31082,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 6,
    "Net Demand": 24599,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 7,
    "Net Demand": 28097,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 8,
    "Net Demand": 29718,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 9,
    "Net Demand": 29076,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 10,
    "Net Demand": 28741,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 11,
    "Net Demand": 26425,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 12,
    "Net Demand": 27338,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 13,
    "Net Demand": 27926,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 14,
    "Net Demand": 27522,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 15,
    "Net Demand": 27513,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 16,
    "Net Demand": 28583,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 17,
    "Net Demand": 31429,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 18,
    "Net Demand": 32255,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 19,
    "Net Demand": 32454,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 20,
    "Net Demand": 33502,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 21,
    "Net Demand": 33025,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 22,
    "Net Demand": 32990,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 23,
    "Net Demand": 33486,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 5,
    Hour: 24,
    "Net Demand": 32083,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 1,
    "Net Demand": 32247,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 2,
    "Net Demand": 32077,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 3,
    "Net Demand": 31286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 4,
    "Net Demand": 32026,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 5,
    "Net Demand": 31417,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 6,
    "Net Demand": 32095,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 7,
    "Net Demand": 32703,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 8,
    "Net Demand": 31250,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 9,
    "Net Demand": 29377,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 10,
    "Net Demand": 28327,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 11,
    "Net Demand": 24102,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 12,
    "Net Demand": 26208,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 13,
    "Net Demand": 25510,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 14,
    "Net Demand": 26787,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 15,
    "Net Demand": 27324,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 16,
    "Net Demand": 28532,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 17,
    "Net Demand": 30288,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 18,
    "Net Demand": 29587,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 19,
    "Net Demand": 29083,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 20,
    "Net Demand": 33171,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 21,
    "Net Demand": 33569,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 22,
    "Net Demand": 33072,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 23,
    "Net Demand": 32858,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 6,
    Hour: 24,
    "Net Demand": 32453,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 1,
    "Net Demand": 31260,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 2,
    "Net Demand": 30613,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 3,
    "Net Demand": 29832,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 4,
    "Net Demand": 31812,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 5,
    "Net Demand": 32526,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 6,
    "Net Demand": 31375,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 7,
    "Net Demand": 29981,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 8,
    "Net Demand": 29174,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 9,
    "Net Demand": 29763,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 10,
    "Net Demand": 30155,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 11,
    "Net Demand": 30230,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 12,
    "Net Demand": 29615,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 13,
    "Net Demand": 28262,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 14,
    "Net Demand": 27536,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 15,
    "Net Demand": 27902,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 16,
    "Net Demand": 27671,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 17,
    "Net Demand": 31308,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 18,
    "Net Demand": 32338,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 19,
    "Net Demand": 31396,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 20,
    "Net Demand": 32506,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 21,
    "Net Demand": 32079,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 22,
    "Net Demand": 32454,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 23,
    "Net Demand": 30957,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 7,
    Hour: 24,
    "Net Demand": 31407,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 1,
    "Net Demand": 30773,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 2,
    "Net Demand": 30920,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 3,
    "Net Demand": 29811,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 4,
    "Net Demand": 32291,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 5,
    "Net Demand": 32056,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 6,
    "Net Demand": 31254,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 7,
    "Net Demand": 30386,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 8,
    "Net Demand": 29789,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 9,
    "Net Demand": 29014,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 10,
    "Net Demand": 27674,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 11,
    "Net Demand": 25681,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 12,
    "Net Demand": 27180,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 13,
    "Net Demand": 28117,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 14,
    "Net Demand": 28527,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 15,
    "Net Demand": 28230,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 16,
    "Net Demand": 29495,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 17,
    "Net Demand": 26134,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 18,
    "Net Demand": 25881,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 19,
    "Net Demand": 32572,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 20,
    "Net Demand": 33722,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 21,
    "Net Demand": 32946,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 22,
    "Net Demand": 31872,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 23,
    "Net Demand": 30726,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 8,
    Hour: 24,
    "Net Demand": 30916,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 1,
    "Net Demand": 31222,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 2,
    "Net Demand": 30629,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 3,
    "Net Demand": 33604,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 4,
    "Net Demand": 33527,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 5,
    "Net Demand": 32408,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 6,
    "Net Demand": 31986,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 7,
    "Net Demand": 32177,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 8,
    "Net Demand": 31933,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 9,
    "Net Demand": 30184,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 10,
    "Net Demand": 25656,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 11,
    "Net Demand": 22286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 12,
    "Net Demand": 24312,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 13,
    "Net Demand": 24109,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 14,
    "Net Demand": 22515,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 15,
    "Net Demand": 23474,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 16,
    "Net Demand": 25346,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 17,
    "Net Demand": 27843,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 18,
    "Net Demand": 26727,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 19,
    "Net Demand": 26755,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 20,
    "Net Demand": 27433,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 21,
    "Net Demand": 26771,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 22,
    "Net Demand": 27970,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 23,
    "Net Demand": 26382,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 9,
    Hour: 24,
    "Net Demand": 26373,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 1,
    "Net Demand": 26252,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 2,
    "Net Demand": 25211,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 3,
    "Net Demand": 24726,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 4,
    "Net Demand": 25449,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 5,
    "Net Demand": 26042,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 6,
    "Net Demand": 23917,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 7,
    "Net Demand": 24987,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 8,
    "Net Demand": 23867,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 9,
    "Net Demand": 23577,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 10,
    "Net Demand": 21507,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 11,
    "Net Demand": 21008,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 12,
    "Net Demand": 21169,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 13,
    "Net Demand": 21083,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 14,
    "Net Demand": 22101,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 15,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 16,
    "Net Demand": 24660,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 17,
    "Net Demand": 25823,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 18,
    "Net Demand": 27125,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 19,
    "Net Demand": 27646,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 20,
    "Net Demand": 27219,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 21,
    "Net Demand": 26110,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 22,
    "Net Demand": 26087,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 23,
    "Net Demand": 27136,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 10,
    Hour: 24,
    "Net Demand": 26542,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 1,
    "Net Demand": 26339,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 2,
    "Net Demand": 26161,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 3,
    "Net Demand": 25870,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 4,
    "Net Demand": 27138,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 5,
    "Net Demand": 27102,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 6,
    "Net Demand": 26005,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 7,
    "Net Demand": 26325,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 8,
    "Net Demand": 25810,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 9,
    "Net Demand": 24452,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 10,
    "Net Demand": 24284,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 11,
    "Net Demand": 21415,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 12,
    "Net Demand": 23469,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 13,
    "Net Demand": 26472,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 14,
    "Net Demand": 25278,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 15,
    "Net Demand": 24798,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 16,
    "Net Demand": 27184,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 17,
    "Net Demand": 28109,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 18,
    "Net Demand": 26927,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 19,
    "Net Demand": 25663,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 20,
    "Net Demand": 26546,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 21,
    "Net Demand": 26246,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 22,
    "Net Demand": 27132,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 23,
    "Net Demand": 31194,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 11,
    Hour: 24,
    "Net Demand": 31445,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 1,
    "Net Demand": 30341,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 2,
    "Net Demand": 30521,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 3,
    "Net Demand": 29825,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 4,
    "Net Demand": 29986,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 5,
    "Net Demand": 30652,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 6,
    "Net Demand": 30642,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 7,
    "Net Demand": 31018,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 8,
    "Net Demand": 30185,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 9,
    "Net Demand": 27359,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 10,
    "Net Demand": 25964,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 11,
    "Net Demand": 25486,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 12,
    "Net Demand": 25764,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 13,
    "Net Demand": 26057,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 14,
    "Net Demand": 26360,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 15,
    "Net Demand": 24317,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 16,
    "Net Demand": 18105,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 17,
    "Net Demand": 18174,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 18,
    "Net Demand": 19616,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 19,
    "Net Demand": 19664,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 20,
    "Net Demand": 23111,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 21,
    "Net Demand": 30231,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 22,
    "Net Demand": 32184,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 23,
    "Net Demand": 32137,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 12,
    Hour: 24,
    "Net Demand": 31656,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 1,
    "Net Demand": 30996,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 2,
    "Net Demand": 29822,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 3,
    "Net Demand": 29376,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 4,
    "Net Demand": 29170,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 5,
    "Net Demand": 30244,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 6,
    "Net Demand": 29604,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 7,
    "Net Demand": 29924,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 8,
    "Net Demand": 28531,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 9,
    "Net Demand": 27877,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 10,
    "Net Demand": 26655,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 11,
    "Net Demand": 25793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 12,
    "Net Demand": 26186,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 13,
    "Net Demand": 26690,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 14,
    "Net Demand": 27378,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 15,
    "Net Demand": 28917,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 16,
    "Net Demand": 30297,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 17,
    "Net Demand": 30575,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 18,
    "Net Demand": 30616,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 19,
    "Net Demand": 30338,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 20,
    "Net Demand": 31547,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 21,
    "Net Demand": 28910,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 22,
    "Net Demand": 26405,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 23,
    "Net Demand": 29436,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 13,
    Hour: 24,
    "Net Demand": 33187,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 1,
    "Net Demand": 32743,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 2,
    "Net Demand": 31705,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 3,
    "Net Demand": 30210,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 4,
    "Net Demand": 30934,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 5,
    "Net Demand": 30524,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 6,
    "Net Demand": 30957,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 7,
    "Net Demand": 30286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 8,
    "Net Demand": 29535,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 9,
    "Net Demand": 29908,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 10,
    "Net Demand": 28887,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 11,
    "Net Demand": 27418,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 12,
    "Net Demand": 27994,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 13,
    "Net Demand": 28796,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 14,
    "Net Demand": 27522,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 15,
    "Net Demand": 25754,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 16,
    "Net Demand": 28879,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 17,
    "Net Demand": 30563,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 18,
    "Net Demand": 30981,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 19,
    "Net Demand": 30466,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 20,
    "Net Demand": 31534,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 21,
    "Net Demand": 32568,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 22,
    "Net Demand": 30658,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 23,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 14,
    Hour: 24,
    "Net Demand": 29597,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 1,
    "Net Demand": 30638,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 2,
    "Net Demand": 29881,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 3,
    "Net Demand": 29253,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 4,
    "Net Demand": 30148,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 5,
    "Net Demand": 30550,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 6,
    "Net Demand": 29529,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 7,
    "Net Demand": 28602,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 8,
    "Net Demand": 29066,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 9,
    "Net Demand": 27886,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 10,
    "Net Demand": 17217,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 11,
    "Net Demand": 13411,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 12,
    "Net Demand": 13887,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 13,
    "Net Demand": 14084,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 14,
    "Net Demand": 14157,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 15,
    "Net Demand": 14737,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 16,
    "Net Demand": 15325,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 17,
    "Net Demand": 16520,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 18,
    "Net Demand": 17111,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 19,
    "Net Demand": 17658,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 20,
    "Net Demand": 17786,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 21,
    "Net Demand": 20167,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 22,
    "Net Demand": 25684,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 23,
    "Net Demand": 29008,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 15,
    Hour: 24,
    "Net Demand": 30128,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 1,
    "Net Demand": 29593,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 2,
    "Net Demand": 29847,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 3,
    "Net Demand": 29067,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 4,
    "Net Demand": 28493,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 5,
    "Net Demand": 27985,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 6,
    "Net Demand": 28971,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 7,
    "Net Demand": 29473,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 8,
    "Net Demand": 28611,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 9,
    "Net Demand": 26389,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 10,
    "Net Demand": 25898,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 11,
    "Net Demand": 25805,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 12,
    "Net Demand": 25466,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 13,
    "Net Demand": 25202,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 14,
    "Net Demand": 26477,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 15,
    "Net Demand": 21035,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 16,
    "Net Demand": 19602,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 17,
    "Net Demand": 20590,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 18,
    "Net Demand": 21490,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 19,
    "Net Demand": 21288,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 20,
    "Net Demand": 22403,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 21,
    "Net Demand": 22272,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 22,
    "Net Demand": 22595,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 23,
    "Net Demand": 23269,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 16,
    Hour: 24,
    "Net Demand": 26353,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 1,
    "Net Demand": 31547,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 2,
    "Net Demand": 31631,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 3,
    "Net Demand": 30917,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 4,
    "Net Demand": 31854,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 5,
    "Net Demand": 30743,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 6,
    "Net Demand": 30644,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 7,
    "Net Demand": 30286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 8,
    "Net Demand": 29847,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 9,
    "Net Demand": 28968,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 10,
    "Net Demand": 21264,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 11,
    "Net Demand": 18948,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 12,
    "Net Demand": 18918,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 13,
    "Net Demand": 18529,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 14,
    "Net Demand": 18916,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 15,
    "Net Demand": 19501,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 16,
    "Net Demand": 20434,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 17,
    "Net Demand": 20064,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 18,
    "Net Demand": 15411,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 19,
    "Net Demand": 14359,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 20,
    "Net Demand": 18577,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 21,
    "Net Demand": 17772,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 22,
    "Net Demand": 18224,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 23,
    "Net Demand": 21860,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 17,
    Hour: 24,
    "Net Demand": 23093,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 1,
    "Net Demand": 21721,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 2,
    "Net Demand": 22037,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 3,
    "Net Demand": 21944,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 4,
    "Net Demand": 21193,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 5,
    "Net Demand": 21986,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 6,
    "Net Demand": 22027,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 7,
    "Net Demand": 21177,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 8,
    "Net Demand": 20246,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 9,
    "Net Demand": 19756,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 10,
    "Net Demand": 18940,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 11,
    "Net Demand": 17477,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 12,
    "Net Demand": 17033,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 13,
    "Net Demand": 16620,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 14,
    "Net Demand": 17796,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 15,
    "Net Demand": 18745,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 16,
    "Net Demand": 19113,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 17,
    "Net Demand": 20775,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 18,
    "Net Demand": 21804,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 19,
    "Net Demand": 22461,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 20,
    "Net Demand": 23653,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 21,
    "Net Demand": 26976,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 22,
    "Net Demand": 30768,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 23,
    "Net Demand": 31115,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 18,
    Hour: 24,
    "Net Demand": 31945,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 1,
    "Net Demand": 27385,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 2,
    "Net Demand": 26273,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 3,
    "Net Demand": 24881,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 4,
    "Net Demand": 25808,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 5,
    "Net Demand": 25680,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 6,
    "Net Demand": 24606,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 7,
    "Net Demand": 23197,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 8,
    "Net Demand": 23180,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 9,
    "Net Demand": 22270,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 10,
    "Net Demand": 20817,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 11,
    "Net Demand": 19513,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 12,
    "Net Demand": 19793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 13,
    "Net Demand": 17793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 14,
    "Net Demand": 14961,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 15,
    "Net Demand": 14928,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 16,
    "Net Demand": 16124,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 17,
    "Net Demand": 22095,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 18,
    "Net Demand": 25156,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 19,
    "Net Demand": 25733,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 20,
    "Net Demand": 26676,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 21,
    "Net Demand": 25803,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 22,
    "Net Demand": 25206,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 23,
    "Net Demand": 24876,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 19,
    Hour: 24,
    "Net Demand": 25787,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 1,
    "Net Demand": 27150,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 2,
    "Net Demand": 27178,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 3,
    "Net Demand": 27957,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 4,
    "Net Demand": 29707,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 5,
    "Net Demand": 28723,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 6,
    "Net Demand": 25220,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 7,
    "Net Demand": 20125,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 8,
    "Net Demand": 21404,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 9,
    "Net Demand": 19856,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 10,
    "Net Demand": 18285,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 11,
    "Net Demand": 19962,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 12,
    "Net Demand": 18473,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 13,
    "Net Demand": 20320,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 14,
    "Net Demand": 19301,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 15,
    "Net Demand": 19981,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 16,
    "Net Demand": 20677,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 17,
    "Net Demand": 22308,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 18,
    "Net Demand": 23961,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 19,
    "Net Demand": 23835,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 20,
    "Net Demand": 24438,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 21,
    "Net Demand": 24124,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 22,
    "Net Demand": 24194,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 23,
    "Net Demand": 24095,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 20,
    Hour: 24,
    "Net Demand": 24856,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 1,
    "Net Demand": 30969,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 2,
    "Net Demand": 31012,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 3,
    "Net Demand": 30713,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 4,
    "Net Demand": 31537,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 5,
    "Net Demand": 31811,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 6,
    "Net Demand": 23883,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 7,
    "Net Demand": 26256,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 8,
    "Net Demand": 29179,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 9,
    "Net Demand": 28092,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 10,
    "Net Demand": 28971,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 11,
    "Net Demand": 20919,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 12,
    "Net Demand": 26624,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 13,
    "Net Demand": 26475,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 14,
    "Net Demand": 27183,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 15,
    "Net Demand": 25952,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 16,
    "Net Demand": 23620,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 17,
    "Net Demand": 24489,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 18,
    "Net Demand": 27490,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 19,
    "Net Demand": 30287,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 20,
    "Net Demand": 28234,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 21,
    "Net Demand": 27627,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 22,
    "Net Demand": 31191,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 23,
    "Net Demand": 23314,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 21,
    Hour: 24,
    "Net Demand": 32542,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 1,
    "Net Demand": 32391,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 2,
    "Net Demand": 32468,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 3,
    "Net Demand": 31662,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 4,
    "Net Demand": 31764,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 5,
    "Net Demand": 31992,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 6,
    "Net Demand": 28558,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 7,
    "Net Demand": 31884,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 8,
    "Net Demand": 28093,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 9,
    "Net Demand": 21154,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 10,
    "Net Demand": 19106,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 11,
    "Net Demand": 19856,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 12,
    "Net Demand": 20456,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 13,
    "Net Demand": 19880,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 14,
    "Net Demand": 19604,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 15,
    "Net Demand": 19648,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 16,
    "Net Demand": 20990,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 17,
    "Net Demand": 22793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 18,
    "Net Demand": 21841,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 19,
    "Net Demand": 22014,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 20,
    "Net Demand": 23850,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 21,
    "Net Demand": 23341,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 22,
    "Net Demand": 22905,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 23,
    "Net Demand": 23286,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 22,
    Hour: 24,
    "Net Demand": 23616,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 1,
    "Net Demand": 23261,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 2,
    "Net Demand": 23117,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 3,
    "Net Demand": 22121,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 4,
    "Net Demand": 22368,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 5,
    "Net Demand": 22022,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 6,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 7,
    "Net Demand": 22859,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 8,
    "Net Demand": 22524,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 9,
    "Net Demand": 20736,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 10,
    "Net Demand": 20234,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 11,
    "Net Demand": 20381,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 12,
    "Net Demand": 19673,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 13,
    "Net Demand": 18720,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 14,
    "Net Demand": 18997,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 15,
    "Net Demand": 19377,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 16,
    "Net Demand": 20574,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 17,
    "Net Demand": 21516,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 18,
    "Net Demand": 22703,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 19,
    "Net Demand": 23096,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 20,
    "Net Demand": 24191,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 21,
    "Net Demand": 24400,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 22,
    "Net Demand": 22895,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 23,
    "Net Demand": 22965,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 23,
    Hour: 24,
    "Net Demand": 22993,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 1,
    "Net Demand": 23285,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 2,
    "Net Demand": 23309,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 3,
    "Net Demand": 22709,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 4,
    "Net Demand": 22988,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 5,
    "Net Demand": 22967,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 6,
    "Net Demand": 23632,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 7,
    "Net Demand": 30029,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 8,
    "Net Demand": 30257,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 9,
    "Net Demand": 28925,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 10,
    "Net Demand": 28367,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 11,
    "Net Demand": 27182,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 12,
    "Net Demand": 22949,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 13,
    "Net Demand": 29170,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 14,
    "Net Demand": 28170,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 15,
    "Net Demand": 27960,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 16,
    "Net Demand": 29080,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 17,
    "Net Demand": 29625,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 18,
    "Net Demand": 30189,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 19,
    "Net Demand": 30716,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 20,
    "Net Demand": 31389,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 21,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 22,
    "Net Demand": 30661,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 23,
    "Net Demand": 29651,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 24,
    Hour: 24,
    "Net Demand": 30084,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 1,
    "Net Demand": 30481,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 2,
    "Net Demand": 30777,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 3,
    "Net Demand": 30009,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 4,
    "Net Demand": 30787,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 5,
    "Net Demand": 30684,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 6,
    "Net Demand": 30694,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 7,
    "Net Demand": 29941,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 8,
    "Net Demand": 30819,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 9,
    "Net Demand": 27514,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 10,
    "Net Demand": 23603,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 11,
    "Net Demand": 22498,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 12,
    "Net Demand": 22770,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 13,
    "Net Demand": 23403,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 14,
    "Net Demand": 23151,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 15,
    "Net Demand": 23107,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 16,
    "Net Demand": 22900,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 17,
    "Net Demand": 24657,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 18,
    "Net Demand": 25817,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 19,
    "Net Demand": 25930,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 20,
    "Net Demand": 26429,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 21,
    "Net Demand": 26547,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 22,
    "Net Demand": 25947,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 23,
    "Net Demand": 27044,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 25,
    Hour: 24,
    "Net Demand": 22754,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 1,
    "Net Demand": 25781,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 2,
    "Net Demand": 21548,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 3,
    "Net Demand": 21552,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 4,
    "Net Demand": 22096,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 5,
    "Net Demand": 22372,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 6,
    "Net Demand": 22564,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 7,
    "Net Demand": 22850,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 8,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 9,
    "Net Demand": 20526,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 10,
    "Net Demand": 21057,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 11,
    "Net Demand": 20830,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 12,
    "Net Demand": 21671,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 13,
    "Net Demand": 21987,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 14,
    "Net Demand": 22351,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 15,
    "Net Demand": 22941,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 16,
    "Net Demand": 22560,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 17,
    "Net Demand": 24615,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 18,
    "Net Demand": 26921,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 19,
    "Net Demand": 27717,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 20,
    "Net Demand": 31275,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 21,
    "Net Demand": 31465,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 22,
    "Net Demand": 34354,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 23,
    "Net Demand": 30706,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 26,
    Hour: 24,
    "Net Demand": 26749,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 1,
    "Net Demand": 26486,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 2,
    "Net Demand": 23783,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 3,
    "Net Demand": 19683,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 4,
    "Net Demand": 26411,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 5,
    "Net Demand": 26744,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 6,
    "Net Demand": 26650,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 7,
    "Net Demand": 25912,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 8,
    "Net Demand": 24974,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 9,
    "Net Demand": 25184,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 10,
    "Net Demand": 26677,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 11,
    "Net Demand": 25262,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 12,
    "Net Demand": 24952,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 13,
    "Net Demand": 25166,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 14,
    "Net Demand": 25490,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 15,
    "Net Demand": 26698,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 16,
    "Net Demand": 27478,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 17,
    "Net Demand": 29575,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 18,
    "Net Demand": 30007,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 19,
    "Net Demand": 29355,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 20,
    "Net Demand": 30512,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 21,
    "Net Demand": 29982,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 22,
    "Net Demand": 29339,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 23,
    "Net Demand": 30189,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 27,
    Hour: 24,
    "Net Demand": 31741,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 1,
    "Net Demand": 30499,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 2,
    "Net Demand": 30276,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 3,
    "Net Demand": 30550,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 4,
    "Net Demand": 31498,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 5,
    "Net Demand": 31420,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 6,
    "Net Demand": 31238,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 7,
    "Net Demand": 29074,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 8,
    "Net Demand": 22035,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 9,
    "Net Demand": 17628,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 10,
    "Net Demand": 18125,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 11,
    "Net Demand": 19972,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 12,
    "Net Demand": 21012,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 13,
    "Net Demand": 21351,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 14,
    "Net Demand": 20455,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 15,
    "Net Demand": 20695,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 16,
    "Net Demand": 20913,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 17,
    "Net Demand": 22406,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 18,
    "Net Demand": 21326,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 19,
    "Net Demand": 22619,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 20,
    "Net Demand": 22793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 21,
    "Net Demand": 22708,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 22,
    "Net Demand": 22961,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 23,
    "Net Demand": 20762,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 28,
    Hour: 24,
    "Net Demand": 22896,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 1,
    "Net Demand": 23913,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 2,
    "Net Demand": 23023,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 3,
    "Net Demand": 22277,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 4,
    "Net Demand": 22420,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 5,
    "Net Demand": 22741,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 6,
    "Net Demand": 24213,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 7,
    "Net Demand": 22811,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 8,
    "Net Demand": 18069,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 9,
    "Net Demand": 16299,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 10,
    "Net Demand": 14991,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 11,
    "Net Demand": 13538,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 12,
    "Net Demand": 14795,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 13,
    "Net Demand": 18423,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 14,
    "Net Demand": 18878,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 15,
    "Net Demand": 19584,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 16,
    "Net Demand": 21254,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 17,
    "Net Demand": 21423,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 18,
    "Net Demand": 23267,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 19,
    "Net Demand": 23159,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 20,
    "Net Demand": 23752,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 21,
    "Net Demand": 22841,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 22,
    "Net Demand": 21068,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 23,
    "Net Demand": 22794,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 29,
    Hour: 24,
    "Net Demand": 22237,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 1,
    "Net Demand": 26260,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 2,
    "Net Demand": 28147,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 3,
    "Net Demand": 29513,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 4,
    "Net Demand": 29827,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 5,
    "Net Demand": 29631,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 6,
    "Net Demand": 30714,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 7,
    "Net Demand": 30446,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 8,
    "Net Demand": 29493,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 9,
    "Net Demand": 27787,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 10,
    "Net Demand": 28335,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 11,
    "Net Demand": 21607,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 12,
    "Net Demand": 24724,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 13,
    "Net Demand": 24267,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 14,
    "Net Demand": 21980,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 15,
    "Net Demand": 21793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 16,
    "Net Demand": 23014,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 17,
    "Net Demand": 24152,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 18,
    "Net Demand": 26144,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 19,
    "Net Demand": 31330,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 20,
    "Net Demand": 31148,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 21,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 22,
    "Net Demand": 29730,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 23,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 30,
    Hour: 24,
    "Net Demand": 30885,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 1,
    "Net Demand": 26260,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 2,
    "Net Demand": 28147,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 3,
    "Net Demand": 29513,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 4,
    "Net Demand": 29827,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 5,
    "Net Demand": 29631,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 6,
    "Net Demand": 30714,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 7,
    "Net Demand": 30446,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 8,
    "Net Demand": 29493,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 9,
    "Net Demand": 27787,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 10,
    "Net Demand": 28335,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 11,
    "Net Demand": 21607,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 12,
    "Net Demand": 24724,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 13,
    "Net Demand": 24267,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 14,
    "Net Demand": 21980,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 15,
    "Net Demand": 21793,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 16,
    "Net Demand": 23014,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 17,
    "Net Demand": 24152,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 18,
    "Net Demand": 26144,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 19,
    "Net Demand": 31330,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 20,
    "Net Demand": 31148,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 21,
    "Net Demand": 30296,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 22,
    "Net Demand": 29730,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 23,
    "Net Demand": 30976,
  },
  {
    Year: 2023,
    Month: 8,
    Day: 31,
    Hour: 24,
    "Net Demand": 30885,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 1,
    "Net Demand": 34268,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 2,
    "Net Demand": 34101,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 3,
    "Net Demand": 33808,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 4,
    "Net Demand": 35641,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 5,
    "Net Demand": 34397,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 6,
    "Net Demand": 34760,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 7,
    "Net Demand": 34069,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 8,
    "Net Demand": 33315,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 9,
    "Net Demand": 31240,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 10,
    "Net Demand": 25089,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 11,
    "Net Demand": 21485,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 12,
    "Net Demand": 26321,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 13,
    "Net Demand": 26960,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 14,
    "Net Demand": 22477,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 15,
    "Net Demand": 23668,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 16,
    "Net Demand": 25204,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 17,
    "Net Demand": 28998,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 18,
    "Net Demand": 30234,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 19,
    "Net Demand": 29617,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 20,
    "Net Demand": 29813,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 21,
    "Net Demand": 29182,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 22,
    "Net Demand": 30200,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 23,
    "Net Demand": 29402,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 1,
    Hour: 24,
    "Net Demand": 29940,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 1,
    "Net Demand": 27277,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 2,
    "Net Demand": 21812,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 3,
    "Net Demand": 19595,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 4,
    "Net Demand": 20361,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 5,
    "Net Demand": 20281,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 6,
    "Net Demand": 21317,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 7,
    "Net Demand": 17653,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 8,
    "Net Demand": 16301,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 9,
    "Net Demand": 18336,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 10,
    "Net Demand": 17801,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 11,
    "Net Demand": 17296,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 12,
    "Net Demand": 17099,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 13,
    "Net Demand": 18044,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 14,
    "Net Demand": 17071,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 15,
    "Net Demand": 11943,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 16,
    "Net Demand": 11840,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 17,
    "Net Demand": 12299,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 18,
    "Net Demand": 12787,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 19,
    "Net Demand": 12544,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 20,
    "Net Demand": 13436,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 21,
    "Net Demand": 12867,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 22,
    "Net Demand": 12035,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 23,
    "Net Demand": 12556,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 2,
    Hour: 24,
    "Net Demand": 12107,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 1,
    "Net Demand": 11144,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 2,
    "Net Demand": 11199,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 3,
    "Net Demand": 11059,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 4,
    "Net Demand": 11090,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 5,
    "Net Demand": 10758,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 6,
    "Net Demand": 10697,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 7,
    "Net Demand": 9979,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 8,
    "Net Demand": 9420,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 9,
    "Net Demand": 9016,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 10,
    "Net Demand": 8597,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 11,
    "Net Demand": 7101,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 12,
    "Net Demand": 8112,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 13,
    "Net Demand": 6878,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 14,
    "Net Demand": 6369,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 15,
    "Net Demand": 7703,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 16,
    "Net Demand": 7772,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 17,
    "Net Demand": 9860,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 18,
    "Net Demand": 9770,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 19,
    "Net Demand": 9990,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 20,
    "Net Demand": 11138,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 21,
    "Net Demand": 11085,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 22,
    "Net Demand": 11108,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 23,
    "Net Demand": 11527,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 3,
    Hour: 24,
    "Net Demand": 13922,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 1,
    "Net Demand": 19397,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 2,
    "Net Demand": 16703,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 3,
    "Net Demand": 19306,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 4,
    "Net Demand": 19718,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 5,
    "Net Demand": 19613,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 6,
    "Net Demand": 19567,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 7,
    "Net Demand": 20164,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 8,
    "Net Demand": 20686,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 9,
    "Net Demand": 21938,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 10,
    "Net Demand": 19438,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 11,
    "Net Demand": 8552,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 12,
    "Net Demand": 8500,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 13,
    "Net Demand": 9802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 14,
    "Net Demand": 12886,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 15,
    "Net Demand": 17771,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 16,
    "Net Demand": 21845,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 17,
    "Net Demand": 24285,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 18,
    "Net Demand": 25474,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 19,
    "Net Demand": 27662,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 20,
    "Net Demand": 30432,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 21,
    "Net Demand": 33526,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 22,
    "Net Demand": 31947,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 23,
    "Net Demand": 32294,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 4,
    Hour: 24,
    "Net Demand": 32733,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 1,
    "Net Demand": 34395,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 2,
    "Net Demand": 33480,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 3,
    "Net Demand": 33726,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 4,
    "Net Demand": 34220,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 5,
    "Net Demand": 33472,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 6,
    "Net Demand": 26490,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 7,
    "Net Demand": 30257,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 8,
    "Net Demand": 32003,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 9,
    "Net Demand": 31311,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 10,
    "Net Demand": 30951,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 11,
    "Net Demand": 28456,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 12,
    "Net Demand": 29440,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 13,
    "Net Demand": 30073,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 14,
    "Net Demand": 29638,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 15,
    "Net Demand": 29629,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 16,
    "Net Demand": 30781,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 17,
    "Net Demand": 33845,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 18,
    "Net Demand": 34734,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 19,
    "Net Demand": 34949,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 20,
    "Net Demand": 36078,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 21,
    "Net Demand": 35564,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 22,
    "Net Demand": 35526,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 23,
    "Net Demand": 36061,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 5,
    Hour: 24,
    "Net Demand": 34549,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 1,
    "Net Demand": 34726,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 2,
    "Net Demand": 34543,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 3,
    "Net Demand": 33691,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 4,
    "Net Demand": 34489,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 5,
    "Net Demand": 33832,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 6,
    "Net Demand": 34563,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 7,
    "Net Demand": 35218,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 8,
    "Net Demand": 33653,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 9,
    "Net Demand": 31635,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 10,
    "Net Demand": 30505,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 11,
    "Net Demand": 25955,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 12,
    "Net Demand": 28223,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 13,
    "Net Demand": 27471,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 14,
    "Net Demand": 28846,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 15,
    "Net Demand": 29425,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 16,
    "Net Demand": 30725,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 17,
    "Net Demand": 32617,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 18,
    "Net Demand": 31862,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 19,
    "Net Demand": 31319,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 20,
    "Net Demand": 35721,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 21,
    "Net Demand": 36150,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 22,
    "Net Demand": 35615,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 23,
    "Net Demand": 35384,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 6,
    Hour: 24,
    "Net Demand": 34949,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 1,
    "Net Demand": 33663,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 2,
    "Net Demand": 32967,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 3,
    "Net Demand": 32125,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 4,
    "Net Demand": 34258,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 5,
    "Net Demand": 35027,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 6,
    "Net Demand": 33787,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 7,
    "Net Demand": 32286,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 8,
    "Net Demand": 31417,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 9,
    "Net Demand": 32051,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 10,
    "Net Demand": 32474,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 11,
    "Net Demand": 32554,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 12,
    "Net Demand": 31892,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 13,
    "Net Demand": 30435,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 14,
    "Net Demand": 29653,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 15,
    "Net Demand": 30048,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 16,
    "Net Demand": 29798,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 17,
    "Net Demand": 33715,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 18,
    "Net Demand": 34824,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 19,
    "Net Demand": 33810,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 20,
    "Net Demand": 35005,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 21,
    "Net Demand": 34545,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 22,
    "Net Demand": 34949,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 23,
    "Net Demand": 33337,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 7,
    Hour: 24,
    "Net Demand": 33822,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 1,
    "Net Demand": 33139,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 2,
    "Net Demand": 33298,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 3,
    "Net Demand": 32103,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 4,
    "Net Demand": 34773,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 5,
    "Net Demand": 34521,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 6,
    "Net Demand": 33657,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 7,
    "Net Demand": 32723,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 8,
    "Net Demand": 32080,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 9,
    "Net Demand": 31244,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 10,
    "Net Demand": 29802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 11,
    "Net Demand": 27656,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 12,
    "Net Demand": 29270,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 13,
    "Net Demand": 30278,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 14,
    "Net Demand": 30720,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 15,
    "Net Demand": 30400,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 16,
    "Net Demand": 31762,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 17,
    "Net Demand": 28143,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 18,
    "Net Demand": 27871,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 19,
    "Net Demand": 35076,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 20,
    "Net Demand": 36315,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 21,
    "Net Demand": 35479,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 22,
    "Net Demand": 34322,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 23,
    "Net Demand": 33088,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 8,
    Hour: 24,
    "Net Demand": 33293,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 1,
    "Net Demand": 33623,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 2,
    "Net Demand": 32984,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 3,
    "Net Demand": 36187,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 4,
    "Net Demand": 36104,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 5,
    "Net Demand": 34899,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 6,
    "Net Demand": 34445,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 7,
    "Net Demand": 34651,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 8,
    "Net Demand": 34388,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 9,
    "Net Demand": 32505,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 10,
    "Net Demand": 27629,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 11,
    "Net Demand": 24000,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 12,
    "Net Demand": 26181,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 13,
    "Net Demand": 25962,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 14,
    "Net Demand": 24247,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 15,
    "Net Demand": 25279,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 16,
    "Net Demand": 27294,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 17,
    "Net Demand": 29984,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 18,
    "Net Demand": 28781,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 19,
    "Net Demand": 28812,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 20,
    "Net Demand": 29542,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 21,
    "Net Demand": 28829,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 22,
    "Net Demand": 30120,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 23,
    "Net Demand": 28410,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 9,
    Hour: 24,
    "Net Demand": 28401,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 1,
    "Net Demand": 28270,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 2,
    "Net Demand": 27149,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 3,
    "Net Demand": 26627,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 4,
    "Net Demand": 27406,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 5,
    "Net Demand": 28045,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 6,
    "Net Demand": 25756,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 7,
    "Net Demand": 26908,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 8,
    "Net Demand": 25702,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 9,
    "Net Demand": 25390,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 10,
    "Net Demand": 23161,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 11,
    "Net Demand": 22623,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 12,
    "Net Demand": 22796,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 13,
    "Net Demand": 22704,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 14,
    "Net Demand": 23800,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 15,
    "Net Demand": 24264,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 16,
    "Net Demand": 26556,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 17,
    "Net Demand": 27809,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 18,
    "Net Demand": 29211,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 19,
    "Net Demand": 29772,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 20,
    "Net Demand": 29312,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 21,
    "Net Demand": 28118,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 22,
    "Net Demand": 28093,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 23,
    "Net Demand": 29223,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 10,
    Hour: 24,
    "Net Demand": 28583,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 1,
    "Net Demand": 28364,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 2,
    "Net Demand": 28173,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 3,
    "Net Demand": 27859,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 4,
    "Net Demand": 29225,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 5,
    "Net Demand": 29186,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 6,
    "Net Demand": 28004,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 7,
    "Net Demand": 28349,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 8,
    "Net Demand": 27794,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 9,
    "Net Demand": 26332,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 10,
    "Net Demand": 26152,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 11,
    "Net Demand": 23062,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 12,
    "Net Demand": 25273,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 13,
    "Net Demand": 28507,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 14,
    "Net Demand": 27221,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 15,
    "Net Demand": 26705,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 16,
    "Net Demand": 29274,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 17,
    "Net Demand": 30270,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 18,
    "Net Demand": 28998,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 19,
    "Net Demand": 27636,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 20,
    "Net Demand": 28587,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 21,
    "Net Demand": 28264,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 22,
    "Net Demand": 29218,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 23,
    "Net Demand": 33592,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 11,
    Hour: 24,
    "Net Demand": 33863,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 1,
    "Net Demand": 32674,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 2,
    "Net Demand": 32867,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 3,
    "Net Demand": 32118,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 4,
    "Net Demand": 32292,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 5,
    "Net Demand": 33009,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 6,
    "Net Demand": 32998,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 7,
    "Net Demand": 33403,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 8,
    "Net Demand": 32506,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 9,
    "Net Demand": 29462,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 10,
    "Net Demand": 27960,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 11,
    "Net Demand": 27445,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 12,
    "Net Demand": 27745,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 13,
    "Net Demand": 28060,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 14,
    "Net Demand": 28387,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 15,
    "Net Demand": 26187,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 16,
    "Net Demand": 19497,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 17,
    "Net Demand": 19571,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 18,
    "Net Demand": 21125,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 19,
    "Net Demand": 21176,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 20,
    "Net Demand": 24888,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 21,
    "Net Demand": 32555,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 22,
    "Net Demand": 34659,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 23,
    "Net Demand": 34608,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 12,
    Hour: 24,
    "Net Demand": 34090,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 1,
    "Net Demand": 33379,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 2,
    "Net Demand": 32114,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 3,
    "Net Demand": 31635,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 4,
    "Net Demand": 31413,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 5,
    "Net Demand": 32570,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 6,
    "Net Demand": 31880,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 7,
    "Net Demand": 32224,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 8,
    "Net Demand": 30725,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 9,
    "Net Demand": 30021,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 10,
    "Net Demand": 28704,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 11,
    "Net Demand": 27776,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 12,
    "Net Demand": 28199,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 13,
    "Net Demand": 28742,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 14,
    "Net Demand": 29482,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 15,
    "Net Demand": 31140,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 16,
    "Net Demand": 32627,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 17,
    "Net Demand": 32925,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 18,
    "Net Demand": 32970,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 19,
    "Net Demand": 32671,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 20,
    "Net Demand": 33973,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 21,
    "Net Demand": 31133,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 22,
    "Net Demand": 28436,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 23,
    "Net Demand": 31699,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 13,
    Hour: 24,
    "Net Demand": 35738,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 1,
    "Net Demand": 35261,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 2,
    "Net Demand": 34143,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 3,
    "Net Demand": 32533,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 4,
    "Net Demand": 33312,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 5,
    "Net Demand": 32871,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 6,
    "Net Demand": 33337,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 7,
    "Net Demand": 32615,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 8,
    "Net Demand": 31806,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 9,
    "Net Demand": 32208,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 10,
    "Net Demand": 31108,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 11,
    "Net Demand": 29526,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 12,
    "Net Demand": 30146,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 13,
    "Net Demand": 31010,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 14,
    "Net Demand": 29638,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 15,
    "Net Demand": 27734,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 16,
    "Net Demand": 31100,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 17,
    "Net Demand": 32913,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 18,
    "Net Demand": 33363,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 19,
    "Net Demand": 32808,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 20,
    "Net Demand": 33959,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 21,
    "Net Demand": 35072,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 22,
    "Net Demand": 33016,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 23,
    "Net Demand": 32625,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 14,
    Hour: 24,
    "Net Demand": 31872,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 1,
    "Net Demand": 32993,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 2,
    "Net Demand": 32178,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 3,
    "Net Demand": 31502,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 4,
    "Net Demand": 32466,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 5,
    "Net Demand": 32898,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 6,
    "Net Demand": 31799,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 7,
    "Net Demand": 30802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 8,
    "Net Demand": 31300,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 9,
    "Net Demand": 30030,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 10,
    "Net Demand": 18541,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 11,
    "Net Demand": 14442,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 12,
    "Net Demand": 14955,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 13,
    "Net Demand": 15167,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 14,
    "Net Demand": 15246,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 15,
    "Net Demand": 15870,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 16,
    "Net Demand": 16504,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 17,
    "Net Demand": 17790,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 18,
    "Net Demand": 18427,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 19,
    "Net Demand": 19016,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 20,
    "Net Demand": 19154,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 21,
    "Net Demand": 21717,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 22,
    "Net Demand": 27658,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 23,
    "Net Demand": 31238,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 15,
    Hour: 24,
    "Net Demand": 32444,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 1,
    "Net Demand": 31869,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 2,
    "Net Demand": 32142,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 3,
    "Net Demand": 31301,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 4,
    "Net Demand": 30684,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 5,
    "Net Demand": 30137,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 6,
    "Net Demand": 31199,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 7,
    "Net Demand": 31739,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 8,
    "Net Demand": 30811,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 9,
    "Net Demand": 28417,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 10,
    "Net Demand": 27889,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 11,
    "Net Demand": 27789,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 12,
    "Net Demand": 27424,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 13,
    "Net Demand": 27139,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 14,
    "Net Demand": 28513,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 15,
    "Net Demand": 22653,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 16,
    "Net Demand": 21109,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 17,
    "Net Demand": 22173,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 18,
    "Net Demand": 23142,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 19,
    "Net Demand": 22925,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 20,
    "Net Demand": 24125,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 21,
    "Net Demand": 23984,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 22,
    "Net Demand": 24332,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 23,
    "Net Demand": 25058,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 16,
    Hour: 24,
    "Net Demand": 28379,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 1,
    "Net Demand": 33973,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 2,
    "Net Demand": 34063,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 3,
    "Net Demand": 33294,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 4,
    "Net Demand": 34303,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 5,
    "Net Demand": 33106,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 6,
    "Net Demand": 33000,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 7,
    "Net Demand": 32615,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 8,
    "Net Demand": 32142,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 9,
    "Net Demand": 31195,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 10,
    "Net Demand": 22898,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 11,
    "Net Demand": 20405,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 12,
    "Net Demand": 20372,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 13,
    "Net Demand": 19954,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 14,
    "Net Demand": 20371,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 15,
    "Net Demand": 21001,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 16,
    "Net Demand": 22005,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 17,
    "Net Demand": 21607,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 18,
    "Net Demand": 16596,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 19,
    "Net Demand": 15463,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 20,
    "Net Demand": 20005,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 21,
    "Net Demand": 19139,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 22,
    "Net Demand": 19625,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 23,
    "Net Demand": 23541,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 17,
    Hour: 24,
    "Net Demand": 24869,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 1,
    "Net Demand": 23391,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 2,
    "Net Demand": 23731,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 3,
    "Net Demand": 23631,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 4,
    "Net Demand": 22822,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 5,
    "Net Demand": 23676,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 6,
    "Net Demand": 23720,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 7,
    "Net Demand": 22805,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 8,
    "Net Demand": 21802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 9,
    "Net Demand": 21274,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 10,
    "Net Demand": 20396,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 11,
    "Net Demand": 18821,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 12,
    "Net Demand": 18342,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 13,
    "Net Demand": 17898,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 14,
    "Net Demand": 19164,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 15,
    "Net Demand": 20187,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 16,
    "Net Demand": 20582,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 17,
    "Net Demand": 22372,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 18,
    "Net Demand": 23480,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 19,
    "Net Demand": 24188,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 20,
    "Net Demand": 25471,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 21,
    "Net Demand": 29050,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 22,
    "Net Demand": 33134,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 23,
    "Net Demand": 33507,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 18,
    Hour: 24,
    "Net Demand": 34401,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 1,
    "Net Demand": 29490,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 2,
    "Net Demand": 28293,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 3,
    "Net Demand": 26794,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 4,
    "Net Demand": 27792,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 5,
    "Net Demand": 27654,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 6,
    "Net Demand": 26497,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 7,
    "Net Demand": 24981,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 8,
    "Net Demand": 24963,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 9,
    "Net Demand": 23982,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 10,
    "Net Demand": 22417,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 11,
    "Net Demand": 21013,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 12,
    "Net Demand": 21315,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 13,
    "Net Demand": 19161,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 14,
    "Net Demand": 16111,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 15,
    "Net Demand": 16075,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 16,
    "Net Demand": 17364,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 17,
    "Net Demand": 23794,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 18,
    "Net Demand": 27090,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 19,
    "Net Demand": 27712,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 20,
    "Net Demand": 28727,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 21,
    "Net Demand": 27787,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 22,
    "Net Demand": 27144,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 23,
    "Net Demand": 26789,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 19,
    Hour: 24,
    "Net Demand": 27770,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 1,
    "Net Demand": 29238,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 2,
    "Net Demand": 29267,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 3,
    "Net Demand": 30106,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 4,
    "Net Demand": 31990,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 5,
    "Net Demand": 30931,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 6,
    "Net Demand": 27159,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 7,
    "Net Demand": 21672,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 8,
    "Net Demand": 23050,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 9,
    "Net Demand": 21383,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 10,
    "Net Demand": 19691,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 11,
    "Net Demand": 21497,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 12,
    "Net Demand": 19893,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 13,
    "Net Demand": 21882,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 14,
    "Net Demand": 20785,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 15,
    "Net Demand": 21517,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 16,
    "Net Demand": 22266,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 17,
    "Net Demand": 24024,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 18,
    "Net Demand": 25803,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 19,
    "Net Demand": 25668,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 20,
    "Net Demand": 26316,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 21,
    "Net Demand": 25979,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 22,
    "Net Demand": 26054,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 23,
    "Net Demand": 25947,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 20,
    Hour: 24,
    "Net Demand": 26767,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 1,
    "Net Demand": 33350,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 2,
    "Net Demand": 33396,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 3,
    "Net Demand": 33075,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 4,
    "Net Demand": 33962,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 5,
    "Net Demand": 34256,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 6,
    "Net Demand": 25719,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 7,
    "Net Demand": 28274,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 8,
    "Net Demand": 31422,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 9,
    "Net Demand": 30251,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 10,
    "Net Demand": 31199,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 11,
    "Net Demand": 22528,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 12,
    "Net Demand": 28671,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 13,
    "Net Demand": 28510,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 14,
    "Net Demand": 29272,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 15,
    "Net Demand": 27947,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 16,
    "Net Demand": 25436,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 17,
    "Net Demand": 26372,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 18,
    "Net Demand": 29603,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 19,
    "Net Demand": 32615,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 20,
    "Net Demand": 30405,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 21,
    "Net Demand": 29751,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 22,
    "Net Demand": 33589,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 23,
    "Net Demand": 25106,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 21,
    Hour: 24,
    "Net Demand": 35044,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 1,
    "Net Demand": 34882,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 2,
    "Net Demand": 34964,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 3,
    "Net Demand": 34096,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 4,
    "Net Demand": 34206,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 5,
    "Net Demand": 34452,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 6,
    "Net Demand": 30753,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 7,
    "Net Demand": 34336,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 8,
    "Net Demand": 30253,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 9,
    "Net Demand": 22781,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 10,
    "Net Demand": 20575,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 11,
    "Net Demand": 21383,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 12,
    "Net Demand": 22028,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 13,
    "Net Demand": 21409,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 14,
    "Net Demand": 21111,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 15,
    "Net Demand": 21158,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 16,
    "Net Demand": 22604,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 17,
    "Net Demand": 24545,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 18,
    "Net Demand": 23520,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 19,
    "Net Demand": 23706,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 20,
    "Net Demand": 25684,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 21,
    "Net Demand": 25135,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 22,
    "Net Demand": 24666,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 23,
    "Net Demand": 25077,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 22,
    Hour: 24,
    "Net Demand": 25432,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 1,
    "Net Demand": 25050,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 2,
    "Net Demand": 24894,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 3,
    "Net Demand": 23822,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 4,
    "Net Demand": 24088,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 5,
    "Net Demand": 23715,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 6,
    "Net Demand": 24264,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 7,
    "Net Demand": 24617,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 8,
    "Net Demand": 24256,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 9,
    "Net Demand": 22330,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 10,
    "Net Demand": 21790,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 11,
    "Net Demand": 21948,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 12,
    "Net Demand": 21186,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 13,
    "Net Demand": 20159,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 14,
    "Net Demand": 20458,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 15,
    "Net Demand": 20867,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 16,
    "Net Demand": 22156,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 17,
    "Net Demand": 23170,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 18,
    "Net Demand": 24449,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 19,
    "Net Demand": 24872,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 20,
    "Net Demand": 26050,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 21,
    "Net Demand": 26276,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 22,
    "Net Demand": 24655,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 23,
    "Net Demand": 24730,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 23,
    Hour: 24,
    "Net Demand": 24761,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 1,
    "Net Demand": 25075,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 2,
    "Net Demand": 25101,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 3,
    "Net Demand": 24455,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 4,
    "Net Demand": 24755,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 5,
    "Net Demand": 24733,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 6,
    "Net Demand": 25449,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 7,
    "Net Demand": 32337,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 8,
    "Net Demand": 32584,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 9,
    "Net Demand": 31149,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 10,
    "Net Demand": 30548,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 11,
    "Net Demand": 29272,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 12,
    "Net Demand": 24713,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 13,
    "Net Demand": 31412,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 14,
    "Net Demand": 30336,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 15,
    "Net Demand": 30110,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 16,
    "Net Demand": 31316,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 17,
    "Net Demand": 31903,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 18,
    "Net Demand": 32510,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 19,
    "Net Demand": 33077,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 20,
    "Net Demand": 33802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 21,
    "Net Demand": 32873,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 22,
    "Net Demand": 33018,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 23,
    "Net Demand": 31930,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 24,
    Hour: 24,
    "Net Demand": 32397,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 1,
    "Net Demand": 32825,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 2,
    "Net Demand": 33143,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 3,
    "Net Demand": 32316,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 4,
    "Net Demand": 33155,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 5,
    "Net Demand": 33043,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 6,
    "Net Demand": 33054,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 7,
    "Net Demand": 32242,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 8,
    "Net Demand": 33189,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 9,
    "Net Demand": 29630,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 10,
    "Net Demand": 25418,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 11,
    "Net Demand": 24228,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 12,
    "Net Demand": 24520,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 13,
    "Net Demand": 25202,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 14,
    "Net Demand": 24930,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 15,
    "Net Demand": 24884,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 16,
    "Net Demand": 24661,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 17,
    "Net Demand": 26553,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 18,
    "Net Demand": 27802,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 19,
    "Net Demand": 27924,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 20,
    "Net Demand": 28461,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 21,
    "Net Demand": 28588,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 22,
    "Net Demand": 27941,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 23,
    "Net Demand": 29123,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 25,
    Hour: 24,
    "Net Demand": 24503,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 1,
    "Net Demand": 27763,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 2,
    "Net Demand": 23204,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 3,
    "Net Demand": 23209,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 4,
    "Net Demand": 23795,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 5,
    "Net Demand": 24092,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 6,
    "Net Demand": 24298,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 7,
    "Net Demand": 24607,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 8,
    "Net Demand": 22104,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 9,
    "Net Demand": 22105,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 10,
    "Net Demand": 22676,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 11,
    "Net Demand": 22432,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 12,
    "Net Demand": 23338,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 13,
    "Net Demand": 23678,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 14,
    "Net Demand": 24070,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 15,
    "Net Demand": 24705,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 16,
    "Net Demand": 24295,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 17,
    "Net Demand": 26508,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 18,
    "Net Demand": 28990,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 19,
    "Net Demand": 29848,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 20,
    "Net Demand": 33679,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 21,
    "Net Demand": 33885,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 22,
    "Net Demand": 36995,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 23,
    "Net Demand": 33067,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 26,
    Hour: 24,
    "Net Demand": 28805,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 1,
    "Net Demand": 28523,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 2,
    "Net Demand": 25611,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 3,
    "Net Demand": 21197,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 4,
    "Net Demand": 28442,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 5,
    "Net Demand": 28801,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 6,
    "Net Demand": 28699,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 7,
    "Net Demand": 27904,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 8,
    "Net Demand": 26894,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 9,
    "Net Demand": 27120,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 10,
    "Net Demand": 28728,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 11,
    "Net Demand": 27204,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 12,
    "Net Demand": 26871,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 13,
    "Net Demand": 27101,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 14,
    "Net Demand": 27450,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 15,
    "Net Demand": 28751,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 16,
    "Net Demand": 29590,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 17,
    "Net Demand": 31849,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 18,
    "Net Demand": 32314,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 19,
    "Net Demand": 31612,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 20,
    "Net Demand": 32858,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 21,
    "Net Demand": 32287,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 22,
    "Net Demand": 31595,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 23,
    "Net Demand": 32510,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 27,
    Hour: 24,
    "Net Demand": 34182,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 1,
    "Net Demand": 32844,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 2,
    "Net Demand": 32604,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 3,
    "Net Demand": 32899,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 4,
    "Net Demand": 33920,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 5,
    "Net Demand": 33835,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 6,
    "Net Demand": 33640,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 7,
    "Net Demand": 31309,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 8,
    "Net Demand": 23729,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 9,
    "Net Demand": 18983,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 10,
    "Net Demand": 19519,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 11,
    "Net Demand": 21508,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 12,
    "Net Demand": 22627,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 13,
    "Net Demand": 22992,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 14,
    "Net Demand": 22028,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 15,
    "Net Demand": 22286,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 16,
    "Net Demand": 22521,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 17,
    "Net Demand": 24128,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 18,
    "Net Demand": 22966,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 19,
    "Net Demand": 24358,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 20,
    "Net Demand": 24546,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 21,
    "Net Demand": 24454,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 22,
    "Net Demand": 24726,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 23,
    "Net Demand": 22358,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 28,
    Hour: 24,
    "Net Demand": 24656,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 1,
    "Net Demand": 25752,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 2,
    "Net Demand": 24793,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 3,
    "Net Demand": 23990,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 4,
    "Net Demand": 24144,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 5,
    "Net Demand": 24490,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 6,
    "Net Demand": 26075,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 7,
    "Net Demand": 24565,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 8,
    "Net Demand": 19458,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 9,
    "Net Demand": 17552,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 10,
    "Net Demand": 16144,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 11,
    "Net Demand": 14579,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 12,
    "Net Demand": 15932,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 13,
    "Net Demand": 19839,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 14,
    "Net Demand": 20329,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 15,
    "Net Demand": 21090,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 16,
    "Net Demand": 22888,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 17,
    "Net Demand": 23070,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 18,
    "Net Demand": 25056,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 19,
    "Net Demand": 24940,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 20,
    "Net Demand": 25578,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 21,
    "Net Demand": 24597,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 22,
    "Net Demand": 22687,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 23,
    "Net Demand": 24547,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 29,
    Hour: 24,
    "Net Demand": 23947,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 1,
    "Net Demand": 28278,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 2,
    "Net Demand": 30311,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 3,
    "Net Demand": 31782,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 4,
    "Net Demand": 32121,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 5,
    "Net Demand": 31909,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 6,
    "Net Demand": 33076,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 7,
    "Net Demand": 32787,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 8,
    "Net Demand": 31760,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 9,
    "Net Demand": 29924,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 10,
    "Net Demand": 30513,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 11,
    "Net Demand": 23268,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 12,
    "Net Demand": 26625,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 13,
    "Net Demand": 26133,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 14,
    "Net Demand": 23670,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 15,
    "Net Demand": 23468,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 16,
    "Net Demand": 24783,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 17,
    "Net Demand": 26009,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 18,
    "Net Demand": 28154,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 19,
    "Net Demand": 33739,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 20,
    "Net Demand": 33542,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 21,
    "Net Demand": 32625,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 22,
    "Net Demand": 32016,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 23,
    "Net Demand": 33358,
  },
  {
    Year: 2023,
    Month: 9,
    Day: 30,
    Hour: 24,
    "Net Demand": 33260,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 1,
    "Net Demand": 30082,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 2,
    "Net Demand": 29935,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 3,
    "Net Demand": 29678,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 4,
    "Net Demand": 31288,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 5,
    "Net Demand": 30196,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 6,
    "Net Demand": 30514,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 7,
    "Net Demand": 29907,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 8,
    "Net Demand": 29245,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 9,
    "Net Demand": 27424,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 10,
    "Net Demand": 22025,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 11,
    "Net Demand": 18861,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 12,
    "Net Demand": 23105,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 13,
    "Net Demand": 23667,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 14,
    "Net Demand": 19732,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 15,
    "Net Demand": 20777,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 16,
    "Net Demand": 22126,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 17,
    "Net Demand": 25456,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 18,
    "Net Demand": 26541,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 19,
    "Net Demand": 25999,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 20,
    "Net Demand": 26171,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 21,
    "Net Demand": 25618,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 22,
    "Net Demand": 26511,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 23,
    "Net Demand": 25810,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 1,
    Hour: 24,
    "Net Demand": 26283,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 1,
    "Net Demand": 23946,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 2,
    "Net Demand": 19147,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 3,
    "Net Demand": 17201,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 4,
    "Net Demand": 17874,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 5,
    "Net Demand": 17804,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 6,
    "Net Demand": 18713,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 7,
    "Net Demand": 15497,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 8,
    "Net Demand": 14310,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 9,
    "Net Demand": 16096,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 10,
    "Net Demand": 15627,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 11,
    "Net Demand": 15183,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 12,
    "Net Demand": 15010,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 13,
    "Net Demand": 15840,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 14,
    "Net Demand": 14986,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 15,
    "Net Demand": 10484,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 16,
    "Net Demand": 10393,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 17,
    "Net Demand": 10796,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 18,
    "Net Demand": 11225,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 19,
    "Net Demand": 11011,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 20,
    "Net Demand": 11794,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 21,
    "Net Demand": 11295,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 22,
    "Net Demand": 10565,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 23,
    "Net Demand": 11023,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 2,
    Hour: 24,
    "Net Demand": 10628,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 1,
    "Net Demand": 9783,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 2,
    "Net Demand": 9831,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 3,
    "Net Demand": 9709,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 4,
    "Net Demand": 9735,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 5,
    "Net Demand": 9444,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 6,
    "Net Demand": 9390,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 7,
    "Net Demand": 8760,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 8,
    "Net Demand": 8269,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 9,
    "Net Demand": 7915,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 10,
    "Net Demand": 7547,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 11,
    "Net Demand": 6234,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 12,
    "Net Demand": 7121,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 13,
    "Net Demand": 6038,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 14,
    "Net Demand": 5591,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 15,
    "Net Demand": 6762,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 16,
    "Net Demand": 6822,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 17,
    "Net Demand": 8655,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 18,
    "Net Demand": 8577,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 19,
    "Net Demand": 8770,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 20,
    "Net Demand": 9778,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 21,
    "Net Demand": 9731,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 22,
    "Net Demand": 9751,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 23,
    "Net Demand": 10119,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 3,
    Hour: 24,
    "Net Demand": 12222,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 1,
    "Net Demand": 17028,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 2,
    "Net Demand": 14663,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 3,
    "Net Demand": 16948,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 4,
    "Net Demand": 17309,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 5,
    "Net Demand": 17217,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 6,
    "Net Demand": 17177,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 7,
    "Net Demand": 17701,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 8,
    "Net Demand": 18159,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 9,
    "Net Demand": 19258,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 10,
    "Net Demand": 17064,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 11,
    "Net Demand": 7507,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 12,
    "Net Demand": 7461,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 13,
    "Net Demand": 8604,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 14,
    "Net Demand": 11312,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 15,
    "Net Demand": 15600,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 16,
    "Net Demand": 19177,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 17,
    "Net Demand": 21319,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 18,
    "Net Demand": 22363,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 19,
    "Net Demand": 24283,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 20,
    "Net Demand": 26714,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 21,
    "Net Demand": 29431,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 22,
    "Net Demand": 28044,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 23,
    "Net Demand": 28349,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 4,
    Hour: 24,
    "Net Demand": 28734,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 1,
    "Net Demand": 30193,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 2,
    "Net Demand": 29390,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 3,
    "Net Demand": 29607,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 4,
    "Net Demand": 30040,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 5,
    "Net Demand": 29383,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 6,
    "Net Demand": 23254,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 7,
    "Net Demand": 26561,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 8,
    "Net Demand": 28094,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 9,
    "Net Demand": 27486,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 10,
    "Net Demand": 27170,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 11,
    "Net Demand": 24980,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 12,
    "Net Demand": 25844,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 13,
    "Net Demand": 26400,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 14,
    "Net Demand": 26018,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 15,
    "Net Demand": 26009,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 16,
    "Net Demand": 27021,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 17,
    "Net Demand": 29711,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 18,
    "Net Demand": 30492,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 19,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 20,
    "Net Demand": 31671,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 21,
    "Net Demand": 31220,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 22,
    "Net Demand": 31187,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 23,
    "Net Demand": 31656,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 5,
    Hour: 24,
    "Net Demand": 30329,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 1,
    "Net Demand": 30485,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 2,
    "Net Demand": 30323,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 3,
    "Net Demand": 29576,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 4,
    "Net Demand": 30276,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 5,
    "Net Demand": 29700,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 6,
    "Net Demand": 30341,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 7,
    "Net Demand": 30916,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 8,
    "Net Demand": 29542,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 9,
    "Net Demand": 27771,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 10,
    "Net Demand": 26779,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 11,
    "Net Demand": 22785,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 12,
    "Net Demand": 24775,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 13,
    "Net Demand": 24115,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 14,
    "Net Demand": 25323,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 15,
    "Net Demand": 25831,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 16,
    "Net Demand": 26972,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 17,
    "Net Demand": 28633,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 18,
    "Net Demand": 27970,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 19,
    "Net Demand": 27493,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 20,
    "Net Demand": 31358,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 21,
    "Net Demand": 31734,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 22,
    "Net Demand": 31264,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 23,
    "Net Demand": 31062,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 6,
    Hour: 24,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 1,
    "Net Demand": 29551,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 2,
    "Net Demand": 28940,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 3,
    "Net Demand": 28201,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 4,
    "Net Demand": 30073,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 5,
    "Net Demand": 30748,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 6,
    "Net Demand": 29660,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 7,
    "Net Demand": 28342,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 8,
    "Net Demand": 27579,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 9,
    "Net Demand": 28136,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 10,
    "Net Demand": 28507,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 11,
    "Net Demand": 28578,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 12,
    "Net Demand": 27996,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 13,
    "Net Demand": 26717,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 14,
    "Net Demand": 26031,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 15,
    "Net Demand": 26377,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 16,
    "Net Demand": 26158,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 17,
    "Net Demand": 29596,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 18,
    "Net Demand": 30571,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 19,
    "Net Demand": 29680,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 20,
    "Net Demand": 30729,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 21,
    "Net Demand": 30326,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 22,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 23,
    "Net Demand": 29265,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 7,
    Hour: 24,
    "Net Demand": 29691,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 1,
    "Net Demand": 29091,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 2,
    "Net Demand": 29230,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 3,
    "Net Demand": 28182,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 4,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 5,
    "Net Demand": 30304,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 6,
    "Net Demand": 29545,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 7,
    "Net Demand": 28725,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 8,
    "Net Demand": 28161,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 9,
    "Net Demand": 27428,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 10,
    "Net Demand": 26162,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 11,
    "Net Demand": 24278,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 12,
    "Net Demand": 25694,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 13,
    "Net Demand": 26580,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 14,
    "Net Demand": 26967,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 15,
    "Net Demand": 26687,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 16,
    "Net Demand": 27883,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 17,
    "Net Demand": 24706,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 18,
    "Net Demand": 24466,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 19,
    "Net Demand": 30791,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 20,
    "Net Demand": 31879,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 21,
    "Net Demand": 31145,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 22,
    "Net Demand": 30130,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 23,
    "Net Demand": 29046,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 8,
    Hour: 24,
    "Net Demand": 29227,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 1,
    "Net Demand": 29516,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 2,
    "Net Demand": 28955,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 3,
    "Net Demand": 31767,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 4,
    "Net Demand": 31694,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 5,
    "Net Demand": 30636,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 6,
    "Net Demand": 30238,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 7,
    "Net Demand": 30418,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 8,
    "Net Demand": 30188,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 9,
    "Net Demand": 28534,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 10,
    "Net Demand": 24254,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 11,
    "Net Demand": 21068,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 12,
    "Net Demand": 22983,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 13,
    "Net Demand": 22791,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 14,
    "Net Demand": 21285,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 15,
    "Net Demand": 22191,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 16,
    "Net Demand": 23960,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 17,
    "Net Demand": 26321,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 18,
    "Net Demand": 25266,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 19,
    "Net Demand": 25292,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 20,
    "Net Demand": 25934,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 21,
    "Net Demand": 25307,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 22,
    "Net Demand": 26441,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 23,
    "Net Demand": 24940,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 9,
    Hour: 24,
    "Net Demand": 24932,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 1,
    "Net Demand": 24817,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 2,
    "Net Demand": 23833,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 3,
    "Net Demand": 23375,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 4,
    "Net Demand": 24058,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 5,
    "Net Demand": 24619,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 6,
    "Net Demand": 22610,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 7,
    "Net Demand": 23621,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 8,
    "Net Demand": 22563,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 9,
    "Net Demand": 22288,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 10,
    "Net Demand": 20331,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 11,
    "Net Demand": 19859,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 12,
    "Net Demand": 20012,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 13,
    "Net Demand": 19931,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 14,
    "Net Demand": 20893,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 15,
    "Net Demand": 21300,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 16,
    "Net Demand": 23312,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 17,
    "Net Demand": 24412,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 18,
    "Net Demand": 25643,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 19,
    "Net Demand": 26135,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 20,
    "Net Demand": 25731,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 21,
    "Net Demand": 24683,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 22,
    "Net Demand": 24661,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 23,
    "Net Demand": 25653,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 10,
    Hour: 24,
    "Net Demand": 25091,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 1,
    "Net Demand": 24899,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 2,
    "Net Demand": 24731,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 3,
    "Net Demand": 24456,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 4,
    "Net Demand": 25655,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 5,
    "Net Demand": 25621,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 6,
    "Net Demand": 24584,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 7,
    "Net Demand": 24886,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 8,
    "Net Demand": 24399,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 9,
    "Net Demand": 23115,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 10,
    "Net Demand": 22957,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 11,
    "Net Demand": 20245,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 12,
    "Net Demand": 22186,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 13,
    "Net Demand": 25025,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 14,
    "Net Demand": 23896,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 15,
    "Net Demand": 23443,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 16,
    "Net Demand": 25698,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 17,
    "Net Demand": 26573,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 18,
    "Net Demand": 25455,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 19,
    "Net Demand": 24260,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 20,
    "Net Demand": 25095,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 21,
    "Net Demand": 24811,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 22,
    "Net Demand": 25649,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 23,
    "Net Demand": 29489,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 11,
    Hour: 24,
    "Net Demand": 29727,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 1,
    "Net Demand": 28683,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 2,
    "Net Demand": 28853,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 3,
    "Net Demand": 28194,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 4,
    "Net Demand": 28347,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 5,
    "Net Demand": 28977,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 6,
    "Net Demand": 28967,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 7,
    "Net Demand": 29323,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 8,
    "Net Demand": 28535,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 9,
    "Net Demand": 25863,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 10,
    "Net Demand": 24545,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 11,
    "Net Demand": 24093,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 12,
    "Net Demand": 24356,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 13,
    "Net Demand": 24632,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 14,
    "Net Demand": 24919,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 15,
    "Net Demand": 22988,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 16,
    "Net Demand": 17115,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 17,
    "Net Demand": 17180,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 18,
    "Net Demand": 18544,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 19,
    "Net Demand": 18589,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 20,
    "Net Demand": 21848,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 21,
    "Net Demand": 28579,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 22,
    "Net Demand": 30425,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 23,
    "Net Demand": 30381,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 12,
    Hour: 24,
    "Net Demand": 29925,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 1,
    "Net Demand": 29301,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 2,
    "Net Demand": 28192,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 3,
    "Net Demand": 27771,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 4,
    "Net Demand": 27576,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 5,
    "Net Demand": 28591,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 6,
    "Net Demand": 27986,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 7,
    "Net Demand": 28288,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 8,
    "Net Demand": 26972,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 9,
    "Net Demand": 26354,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 10,
    "Net Demand": 25198,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 11,
    "Net Demand": 24383,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 12,
    "Net Demand": 24754,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 13,
    "Net Demand": 25231,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 14,
    "Net Demand": 25881,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 15,
    "Net Demand": 27336,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 16,
    "Net Demand": 28641,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 17,
    "Net Demand": 28904,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 18,
    "Net Demand": 28943,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 19,
    "Net Demand": 28680,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 20,
    "Net Demand": 29823,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 21,
    "Net Demand": 27330,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 22,
    "Net Demand": 24962,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 23,
    "Net Demand": 27827,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 13,
    Hour: 24,
    "Net Demand": 31373,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 1,
    "Net Demand": 30953,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 2,
    "Net Demand": 29972,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 3,
    "Net Demand": 28559,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 4,
    "Net Demand": 29243,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 5,
    "Net Demand": 28856,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 6,
    "Net Demand": 29265,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 7,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 8,
    "Net Demand": 27921,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 9,
    "Net Demand": 28273,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 10,
    "Net Demand": 27308,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 11,
    "Net Demand": 25919,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 12,
    "Net Demand": 26464,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 13,
    "Net Demand": 27222,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 14,
    "Net Demand": 26018,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 15,
    "Net Demand": 24346,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 16,
    "Net Demand": 27301,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 17,
    "Net Demand": 28892,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 18,
    "Net Demand": 29288,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 19,
    "Net Demand": 28801,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 20,
    "Net Demand": 29811,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 21,
    "Net Demand": 30788,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 22,
    "Net Demand": 28983,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 23,
    "Net Demand": 28640,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 14,
    Hour: 24,
    "Net Demand": 27979,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 1,
    "Net Demand": 28963,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 2,
    "Net Demand": 28248,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 3,
    "Net Demand": 27654,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 4,
    "Net Demand": 28500,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 5,
    "Net Demand": 28880,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 6,
    "Net Demand": 27915,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 7,
    "Net Demand": 27039,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 8,
    "Net Demand": 27477,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 9,
    "Net Demand": 26362,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 10,
    "Net Demand": 16276,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 11,
    "Net Demand": 12678,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 12,
    "Net Demand": 13128,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 13,
    "Net Demand": 13315,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 14,
    "Net Demand": 13383,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 15,
    "Net Demand": 13931,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 16,
    "Net Demand": 14488,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 17,
    "Net Demand": 15617,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 18,
    "Net Demand": 16176,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 19,
    "Net Demand": 16693,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 20,
    "Net Demand": 16814,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 21,
    "Net Demand": 19064,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 22,
    "Net Demand": 24280,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 23,
    "Net Demand": 27422,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 15,
    Hour: 24,
    "Net Demand": 28481,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 1,
    "Net Demand": 27976,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 2,
    "Net Demand": 28216,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 3,
    "Net Demand": 27478,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 4,
    "Net Demand": 26936,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 5,
    "Net Demand": 26455,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 6,
    "Net Demand": 27388,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 7,
    "Net Demand": 27862,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 8,
    "Net Demand": 27047,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 9,
    "Net Demand": 24946,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 10,
    "Net Demand": 24482,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 11,
    "Net Demand": 24395,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 12,
    "Net Demand": 24074,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 13,
    "Net Demand": 23824,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 14,
    "Net Demand": 25030,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 15,
    "Net Demand": 19886,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 16,
    "Net Demand": 18530,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 17,
    "Net Demand": 19464,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 18,
    "Net Demand": 20315,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 19,
    "Net Demand": 20124,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 20,
    "Net Demand": 21178,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 21,
    "Net Demand": 21055,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 22,
    "Net Demand": 21360,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 23,
    "Net Demand": 21998,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 16,
    Hour: 24,
    "Net Demand": 24912,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 1,
    "Net Demand": 29823,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 2,
    "Net Demand": 29902,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 3,
    "Net Demand": 29227,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 4,
    "Net Demand": 30113,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 5,
    "Net Demand": 29062,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 6,
    "Net Demand": 28969,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 7,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 8,
    "Net Demand": 28216,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 9,
    "Net Demand": 27384,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 10,
    "Net Demand": 20101,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 11,
    "Net Demand": 17912,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 12,
    "Net Demand": 17884,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 13,
    "Net Demand": 17517,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 14,
    "Net Demand": 17882,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 15,
    "Net Demand": 18435,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 16,
    "Net Demand": 19317,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 17,
    "Net Demand": 18968,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 18,
    "Net Demand": 14568,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 19,
    "Net Demand": 13574,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 20,
    "Net Demand": 17562,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 21,
    "Net Demand": 16801,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 22,
    "Net Demand": 17228,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 23,
    "Net Demand": 20666,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 17,
    Hour: 24,
    "Net Demand": 21831,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 1,
    "Net Demand": 20533,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 2,
    "Net Demand": 20833,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 3,
    "Net Demand": 20744,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 4,
    "Net Demand": 20034,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 5,
    "Net Demand": 20784,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 6,
    "Net Demand": 20823,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 7,
    "Net Demand": 20020,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 8,
    "Net Demand": 19139,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 9,
    "Net Demand": 18676,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 10,
    "Net Demand": 17905,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 11,
    "Net Demand": 16522,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 12,
    "Net Demand": 16102,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 13,
    "Net Demand": 15711,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 14,
    "Net Demand": 16823,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 15,
    "Net Demand": 17721,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 16,
    "Net Demand": 18068,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 17,
    "Net Demand": 19639,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 18,
    "Net Demand": 20612,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 19,
    "Net Demand": 21233,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 20,
    "Net Demand": 22360,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 21,
    "Net Demand": 25501,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 22,
    "Net Demand": 29086,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 23,
    "Net Demand": 29414,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 18,
    Hour: 24,
    "Net Demand": 30199,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 1,
    "Net Demand": 25888,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 2,
    "Net Demand": 24837,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 3,
    "Net Demand": 23521,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 4,
    "Net Demand": 24397,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 5,
    "Net Demand": 24276,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 6,
    "Net Demand": 23261,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 7,
    "Net Demand": 21929,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 8,
    "Net Demand": 21913,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 9,
    "Net Demand": 21053,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 10,
    "Net Demand": 19679,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 11,
    "Net Demand": 18446,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 12,
    "Net Demand": 18711,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 13,
    "Net Demand": 16821,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 14,
    "Net Demand": 14143,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 15,
    "Net Demand": 14112,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 16,
    "Net Demand": 15243,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 17,
    "Net Demand": 20887,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 18,
    "Net Demand": 23781,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 19,
    "Net Demand": 24327,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 20,
    "Net Demand": 25218,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 21,
    "Net Demand": 24393,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 22,
    "Net Demand": 23828,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 23,
    "Net Demand": 23517,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 19,
    Hour: 24,
    "Net Demand": 24378,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 1,
    "Net Demand": 25666,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 2,
    "Net Demand": 25692,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 3,
    "Net Demand": 26429,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 4,
    "Net Demand": 28083,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 5,
    "Net Demand": 27153,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 6,
    "Net Demand": 23841,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 7,
    "Net Demand": 19025,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 8,
    "Net Demand": 20234,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 9,
    "Net Demand": 18771,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 10,
    "Net Demand": 17286,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 11,
    "Net Demand": 18871,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 12,
    "Net Demand": 17463,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 13,
    "Net Demand": 19209,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 14,
    "Net Demand": 18246,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 15,
    "Net Demand": 18889,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 16,
    "Net Demand": 19547,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 17,
    "Net Demand": 21089,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 18,
    "Net Demand": 22651,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 19,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 20,
    "Net Demand": 23102,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 21,
    "Net Demand": 22805,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 22,
    "Net Demand": 22871,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 23,
    "Net Demand": 22778,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 20,
    Hour: 24,
    "Net Demand": 23497,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 1,
    "Net Demand": 29276,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 2,
    "Net Demand": 29317,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 3,
    "Net Demand": 29034,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 4,
    "Net Demand": 29813,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 5,
    "Net Demand": 30072,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 6,
    "Net Demand": 22578,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 7,
    "Net Demand": 24821,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 8,
    "Net Demand": 27584,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 9,
    "Net Demand": 26556,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 10,
    "Net Demand": 27388,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 11,
    "Net Demand": 19776,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 12,
    "Net Demand": 25169,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 13,
    "Net Demand": 25028,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 14,
    "Net Demand": 25697,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 15,
    "Net Demand": 24533,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 16,
    "Net Demand": 22329,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 17,
    "Net Demand": 23150,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 18,
    "Net Demand": 25987,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 19,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 20,
    "Net Demand": 26691,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 21,
    "Net Demand": 26117,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 22,
    "Net Demand": 29486,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 23,
    "Net Demand": 22039,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 21,
    Hour: 24,
    "Net Demand": 30763,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 1,
    "Net Demand": 30621,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 2,
    "Net Demand": 30693,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 3,
    "Net Demand": 29931,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 4,
    "Net Demand": 30028,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 5,
    "Net Demand": 30243,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 6,
    "Net Demand": 26997,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 7,
    "Net Demand": 30142,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 8,
    "Net Demand": 26557,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 9,
    "Net Demand": 19998,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 10,
    "Net Demand": 18062,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 11,
    "Net Demand": 18771,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 12,
    "Net Demand": 19338,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 13,
    "Net Demand": 18793,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 14,
    "Net Demand": 18533,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 15,
    "Net Demand": 18574,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 16,
    "Net Demand": 19843,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 17,
    "Net Demand": 21547,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 18,
    "Net Demand": 20647,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 19,
    "Net Demand": 20810,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 20,
    "Net Demand": 22546,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 21,
    "Net Demand": 22065,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 22,
    "Net Demand": 21653,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 23,
    "Net Demand": 22014,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 22,
    Hour: 24,
    "Net Demand": 22326,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 1,
    "Net Demand": 21990,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 2,
    "Net Demand": 21854,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 3,
    "Net Demand": 20912,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 4,
    "Net Demand": 21145,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 5,
    "Net Demand": 20818,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 6,
    "Net Demand": 21300,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 7,
    "Net Demand": 21610,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 8,
    "Net Demand": 21293,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 9,
    "Net Demand": 19603,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 10,
    "Net Demand": 19128,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 11,
    "Net Demand": 19267,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 12,
    "Net Demand": 18598,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 13,
    "Net Demand": 17696,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 14,
    "Net Demand": 17959,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 15,
    "Net Demand": 18318,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 16,
    "Net Demand": 19449,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 17,
    "Net Demand": 20340,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 18,
    "Net Demand": 21462,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 19,
    "Net Demand": 21834,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 20,
    "Net Demand": 22868,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 21,
    "Net Demand": 23067,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 22,
    "Net Demand": 21643,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 23,
    "Net Demand": 21709,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 23,
    Hour: 24,
    "Net Demand": 21736,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 1,
    "Net Demand": 22012,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 2,
    "Net Demand": 22035,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 3,
    "Net Demand": 21468,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 4,
    "Net Demand": 21731,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 5,
    "Net Demand": 21712,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 6,
    "Net Demand": 22340,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 7,
    "Net Demand": 28387,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 8,
    "Net Demand": 28603,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 9,
    "Net Demand": 27344,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 10,
    "Net Demand": 26816,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 11,
    "Net Demand": 25696,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 12,
    "Net Demand": 21694,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 13,
    "Net Demand": 27575,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 14,
    "Net Demand": 26630,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 15,
    "Net Demand": 26432,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 16,
    "Net Demand": 27491,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 17,
    "Net Demand": 28006,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 18,
    "Net Demand": 28539,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 19,
    "Net Demand": 29037,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 20,
    "Net Demand": 29673,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 21,
    "Net Demand": 28858,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 22,
    "Net Demand": 28985,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 23,
    "Net Demand": 28030,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 24,
    Hour: 24,
    "Net Demand": 28440,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 1,
    "Net Demand": 28815,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 2,
    "Net Demand": 29094,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 3,
    "Net Demand": 28369,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 4,
    "Net Demand": 29105,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 5,
    "Net Demand": 29007,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 6,
    "Net Demand": 29017,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 7,
    "Net Demand": 28304,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 8,
    "Net Demand": 29135,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 9,
    "Net Demand": 26010,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 10,
    "Net Demand": 22313,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 11,
    "Net Demand": 21269,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 12,
    "Net Demand": 21525,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 13,
    "Net Demand": 22124,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 14,
    "Net Demand": 21885,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 15,
    "Net Demand": 21844,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 16,
    "Net Demand": 21649,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 17,
    "Net Demand": 23309,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 18,
    "Net Demand": 24406,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 19,
    "Net Demand": 24513,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 20,
    "Net Demand": 24984,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 21,
    "Net Demand": 25096,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 22,
    "Net Demand": 24528,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 23,
    "Net Demand": 25566,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 25,
    Hour: 24,
    "Net Demand": 21510,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 1,
    "Net Demand": 24372,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 2,
    "Net Demand": 20370,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 3,
    "Net Demand": 20374,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 4,
    "Net Demand": 20889,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 5,
    "Net Demand": 21149,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 6,
    "Net Demand": 21330,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 7,
    "Net Demand": 21601,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 8,
    "Net Demand": 19404,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 9,
    "Net Demand": 19404,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 10,
    "Net Demand": 19906,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 11,
    "Net Demand": 19692,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 12,
    "Net Demand": 20487,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 13,
    "Net Demand": 20786,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 14,
    "Net Demand": 21130,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 15,
    "Net Demand": 21687,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 16,
    "Net Demand": 21327,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 17,
    "Net Demand": 23270,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 18,
    "Net Demand": 25449,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 19,
    "Net Demand": 26202,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 20,
    "Net Demand": 29565,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 21,
    "Net Demand": 29746,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 22,
    "Net Demand": 32476,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 23,
    "Net Demand": 29028,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 26,
    Hour: 24,
    "Net Demand": 25287,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 1,
    "Net Demand": 25039,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 2,
    "Net Demand": 22483,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 3,
    "Net Demand": 18608,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 4,
    "Net Demand": 24968,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 5,
    "Net Demand": 25282,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 6,
    "Net Demand": 25194,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 7,
    "Net Demand": 24495,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 8,
    "Net Demand": 23609,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 9,
    "Net Demand": 23808,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 10,
    "Net Demand": 25219,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 11,
    "Net Demand": 23881,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 12,
    "Net Demand": 23588,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 13,
    "Net Demand": 23790,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 14,
    "Net Demand": 24097,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 15,
    "Net Demand": 25239,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 16,
    "Net Demand": 25976,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 17,
    "Net Demand": 27959,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 18,
    "Net Demand": 28367,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 19,
    "Net Demand": 27751,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 20,
    "Net Demand": 28844,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 21,
    "Net Demand": 28343,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 22,
    "Net Demand": 27736,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 23,
    "Net Demand": 28539,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 27,
    Hour: 24,
    "Net Demand": 30006,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 1,
    "Net Demand": 28832,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 2,
    "Net Demand": 28621,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 3,
    "Net Demand": 28881,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 4,
    "Net Demand": 29776,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 5,
    "Net Demand": 29702,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 6,
    "Net Demand": 29531,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 7,
    "Net Demand": 27485,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 8,
    "Net Demand": 20830,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 9,
    "Net Demand": 16665,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 10,
    "Net Demand": 17134,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 11,
    "Net Demand": 18881,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 12,
    "Net Demand": 19863,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 13,
    "Net Demand": 20184,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 14,
    "Net Demand": 19337,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 15,
    "Net Demand": 19564,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 16,
    "Net Demand": 19770,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 17,
    "Net Demand": 21181,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 18,
    "Net Demand": 20160,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 19,
    "Net Demand": 21383,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 20,
    "Net Demand": 21547,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 21,
    "Net Demand": 21467,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 22,
    "Net Demand": 21706,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 23,
    "Net Demand": 19627,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 28,
    Hour: 24,
    "Net Demand": 21644,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 1,
    "Net Demand": 22606,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 2,
    "Net Demand": 21765,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 3,
    "Net Demand": 21060,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 4,
    "Net Demand": 21195,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 5,
    "Net Demand": 21498,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 6,
    "Net Demand": 22890,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 7,
    "Net Demand": 21564,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 8,
    "Net Demand": 17081,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 9,
    "Net Demand": 15408,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 10,
    "Net Demand": 14172,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 11,
    "Net Demand": 12798,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 12,
    "Net Demand": 13986,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 13,
    "Net Demand": 17416,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 14,
    "Net Demand": 17846,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 15,
    "Net Demand": 18514,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 16,
    "Net Demand": 20092,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 17,
    "Net Demand": 20252,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 18,
    "Net Demand": 21995,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 19,
    "Net Demand": 21893,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 20,
    "Net Demand": 22454,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 21,
    "Net Demand": 21593,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 22,
    "Net Demand": 19916,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 23,
    "Net Demand": 21549,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 29,
    Hour: 24,
    "Net Demand": 21022,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 1,
    "Net Demand": 24824,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 2,
    "Net Demand": 26608,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 3,
    "Net Demand": 27900,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 4,
    "Net Demand": 28197,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 5,
    "Net Demand": 28012,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 6,
    "Net Demand": 29035,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 7,
    "Net Demand": 28782,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 8,
    "Net Demand": 27881,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 9,
    "Net Demand": 26268,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 10,
    "Net Demand": 26786,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 11,
    "Net Demand": 20426,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 12,
    "Net Demand": 23373,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 13,
    "Net Demand": 22941,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 14,
    "Net Demand": 20779,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 15,
    "Net Demand": 20601,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 16,
    "Net Demand": 21756,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 17,
    "Net Demand": 22832,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 18,
    "Net Demand": 24715,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 19,
    "Net Demand": 29618,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 20,
    "Net Demand": 29445,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 21,
    "Net Demand": 28640,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 22,
    "Net Demand": 28105,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 23,
    "Net Demand": 29283,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 30,
    Hour: 24,
    "Net Demand": 29197,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 1,
    "Net Demand": 29185,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 2,
    "Net Demand": 28827,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 3,
    "Net Demand": 28507,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 4,
    "Net Demand": 29487,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 5,
    "Net Demand": 28694,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 6,
    "Net Demand": 28737,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 7,
    "Net Demand": 28731,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 8,
    "Net Demand": 25542,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 9,
    "Net Demand": 22358,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 10,
    "Net Demand": 20831,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 11,
    "Net Demand": 20252,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 12,
    "Net Demand": 24950,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 13,
    "Net Demand": 21928,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 14,
    "Net Demand": 23827,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 15,
    "Net Demand": 27199,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 16,
    "Net Demand": 25904,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 17,
    "Net Demand": 23749,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 18,
    "Net Demand": 26931,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 19,
    "Net Demand": 25893,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 20,
    "Net Demand": 26145,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 21,
    "Net Demand": 26036,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 22,
    "Net Demand": 30673,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 23,
    "Net Demand": 29470,
  },
  {
    Year: 2023,
    Month: 10,
    Day: 31,
    Hour: 24,
    "Net Demand": 29603,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 1,
    "Net Demand": 30082,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 2,
    "Net Demand": 29935,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 3,
    "Net Demand": 29678,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 4,
    "Net Demand": 31288,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 5,
    "Net Demand": 30196,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 6,
    "Net Demand": 30514,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 7,
    "Net Demand": 29907,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 8,
    "Net Demand": 29245,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 9,
    "Net Demand": 27424,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 10,
    "Net Demand": 22025,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 11,
    "Net Demand": 18861,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 12,
    "Net Demand": 23105,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 13,
    "Net Demand": 23667,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 14,
    "Net Demand": 19732,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 15,
    "Net Demand": 20777,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 16,
    "Net Demand": 22126,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 17,
    "Net Demand": 25456,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 18,
    "Net Demand": 26541,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 19,
    "Net Demand": 25999,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 20,
    "Net Demand": 26171,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 21,
    "Net Demand": 25618,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 22,
    "Net Demand": 26511,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 23,
    "Net Demand": 25810,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 1,
    Hour: 24,
    "Net Demand": 26283,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 1,
    "Net Demand": 23946,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 2,
    "Net Demand": 19147,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 3,
    "Net Demand": 17201,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 4,
    "Net Demand": 17874,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 5,
    "Net Demand": 17804,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 6,
    "Net Demand": 18713,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 7,
    "Net Demand": 15497,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 8,
    "Net Demand": 14310,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 9,
    "Net Demand": 16096,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 10,
    "Net Demand": 15627,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 11,
    "Net Demand": 15183,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 12,
    "Net Demand": 15010,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 13,
    "Net Demand": 15840,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 14,
    "Net Demand": 14986,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 15,
    "Net Demand": 10484,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 16,
    "Net Demand": 10393,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 17,
    "Net Demand": 10796,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 18,
    "Net Demand": 11225,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 19,
    "Net Demand": 11011,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 20,
    "Net Demand": 11794,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 21,
    "Net Demand": 11295,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 22,
    "Net Demand": 10565,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 23,
    "Net Demand": 11023,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 2,
    Hour: 24,
    "Net Demand": 10628,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 1,
    "Net Demand": 9783,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 2,
    "Net Demand": 9831,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 3,
    "Net Demand": 9709,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 4,
    "Net Demand": 9735,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 5,
    "Net Demand": 9444,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 6,
    "Net Demand": 9390,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 7,
    "Net Demand": 8760,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 8,
    "Net Demand": 8269,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 9,
    "Net Demand": 7915,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 10,
    "Net Demand": 7547,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 11,
    "Net Demand": 6234,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 12,
    "Net Demand": 7121,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 13,
    "Net Demand": 6038,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 14,
    "Net Demand": 5591,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 15,
    "Net Demand": 6762,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 16,
    "Net Demand": 6822,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 17,
    "Net Demand": 8655,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 18,
    "Net Demand": 8577,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 19,
    "Net Demand": 8770,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 20,
    "Net Demand": 9778,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 21,
    "Net Demand": 9731,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 22,
    "Net Demand": 9751,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 23,
    "Net Demand": 10119,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 3,
    Hour: 24,
    "Net Demand": 12222,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 1,
    "Net Demand": 17028,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 2,
    "Net Demand": 14663,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 3,
    "Net Demand": 16948,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 4,
    "Net Demand": 17309,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 5,
    "Net Demand": 17217,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 6,
    "Net Demand": 17177,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 7,
    "Net Demand": 17701,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 8,
    "Net Demand": 18159,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 9,
    "Net Demand": 19258,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 10,
    "Net Demand": 17064,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 11,
    "Net Demand": 7507,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 12,
    "Net Demand": 7461,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 13,
    "Net Demand": 8604,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 14,
    "Net Demand": 11312,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 15,
    "Net Demand": 15600,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 16,
    "Net Demand": 19177,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 17,
    "Net Demand": 21319,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 18,
    "Net Demand": 22363,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 19,
    "Net Demand": 24283,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 20,
    "Net Demand": 26714,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 21,
    "Net Demand": 29431,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 22,
    "Net Demand": 28044,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 23,
    "Net Demand": 28349,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 4,
    Hour: 24,
    "Net Demand": 28734,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 1,
    "Net Demand": 30193,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 2,
    "Net Demand": 29390,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 3,
    "Net Demand": 29607,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 4,
    "Net Demand": 30040,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 5,
    "Net Demand": 29383,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 6,
    "Net Demand": 23254,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 7,
    "Net Demand": 26561,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 8,
    "Net Demand": 28094,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 9,
    "Net Demand": 27486,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 10,
    "Net Demand": 27170,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 11,
    "Net Demand": 24980,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 12,
    "Net Demand": 25844,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 13,
    "Net Demand": 26400,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 14,
    "Net Demand": 26018,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 15,
    "Net Demand": 26009,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 16,
    "Net Demand": 27021,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 17,
    "Net Demand": 29711,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 18,
    "Net Demand": 30492,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 19,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 20,
    "Net Demand": 31671,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 21,
    "Net Demand": 31220,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 22,
    "Net Demand": 31187,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 23,
    "Net Demand": 31656,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 5,
    Hour: 24,
    "Net Demand": 30329,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 1,
    "Net Demand": 30485,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 2,
    "Net Demand": 30323,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 3,
    "Net Demand": 29576,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 4,
    "Net Demand": 30276,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 5,
    "Net Demand": 29700,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 6,
    "Net Demand": 30341,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 7,
    "Net Demand": 30916,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 8,
    "Net Demand": 29542,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 9,
    "Net Demand": 27771,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 10,
    "Net Demand": 26779,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 11,
    "Net Demand": 22785,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 12,
    "Net Demand": 24775,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 13,
    "Net Demand": 24115,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 14,
    "Net Demand": 25323,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 15,
    "Net Demand": 25831,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 16,
    "Net Demand": 26972,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 17,
    "Net Demand": 28633,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 18,
    "Net Demand": 27970,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 19,
    "Net Demand": 27493,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 20,
    "Net Demand": 31358,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 21,
    "Net Demand": 31734,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 22,
    "Net Demand": 31264,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 23,
    "Net Demand": 31062,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 6,
    Hour: 24,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 1,
    "Net Demand": 29551,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 2,
    "Net Demand": 28940,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 3,
    "Net Demand": 28201,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 4,
    "Net Demand": 30073,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 5,
    "Net Demand": 30748,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 6,
    "Net Demand": 29660,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 7,
    "Net Demand": 28342,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 8,
    "Net Demand": 27579,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 9,
    "Net Demand": 28136,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 10,
    "Net Demand": 28507,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 11,
    "Net Demand": 28578,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 12,
    "Net Demand": 27996,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 13,
    "Net Demand": 26717,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 14,
    "Net Demand": 26031,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 15,
    "Net Demand": 26377,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 16,
    "Net Demand": 26158,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 17,
    "Net Demand": 29596,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 18,
    "Net Demand": 30571,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 19,
    "Net Demand": 29680,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 20,
    "Net Demand": 30729,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 21,
    "Net Demand": 30326,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 22,
    "Net Demand": 30680,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 23,
    "Net Demand": 29265,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 7,
    Hour: 24,
    "Net Demand": 29691,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 1,
    "Net Demand": 29091,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 2,
    "Net Demand": 29230,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 3,
    "Net Demand": 28182,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 4,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 5,
    "Net Demand": 30304,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 6,
    "Net Demand": 29545,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 7,
    "Net Demand": 28725,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 8,
    "Net Demand": 28161,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 9,
    "Net Demand": 27428,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 10,
    "Net Demand": 26162,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 11,
    "Net Demand": 24278,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 12,
    "Net Demand": 25694,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 13,
    "Net Demand": 26580,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 14,
    "Net Demand": 26967,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 15,
    "Net Demand": 26687,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 16,
    "Net Demand": 27883,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 17,
    "Net Demand": 24706,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 18,
    "Net Demand": 24466,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 19,
    "Net Demand": 30791,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 20,
    "Net Demand": 31879,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 21,
    "Net Demand": 31145,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 22,
    "Net Demand": 30130,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 23,
    "Net Demand": 29046,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 8,
    Hour: 24,
    "Net Demand": 29227,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 1,
    "Net Demand": 29516,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 2,
    "Net Demand": 28955,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 3,
    "Net Demand": 31767,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 4,
    "Net Demand": 31694,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 5,
    "Net Demand": 30636,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 6,
    "Net Demand": 30238,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 7,
    "Net Demand": 30418,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 8,
    "Net Demand": 30188,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 9,
    "Net Demand": 28534,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 10,
    "Net Demand": 24254,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 11,
    "Net Demand": 21068,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 12,
    "Net Demand": 22983,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 13,
    "Net Demand": 22791,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 14,
    "Net Demand": 21285,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 15,
    "Net Demand": 22191,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 16,
    "Net Demand": 23960,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 17,
    "Net Demand": 26321,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 18,
    "Net Demand": 25266,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 19,
    "Net Demand": 25292,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 20,
    "Net Demand": 25934,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 21,
    "Net Demand": 25307,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 22,
    "Net Demand": 26441,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 23,
    "Net Demand": 24940,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 9,
    Hour: 24,
    "Net Demand": 24932,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 1,
    "Net Demand": 24817,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 2,
    "Net Demand": 23833,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 3,
    "Net Demand": 23375,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 4,
    "Net Demand": 24058,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 5,
    "Net Demand": 24619,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 6,
    "Net Demand": 22610,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 7,
    "Net Demand": 23621,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 8,
    "Net Demand": 22563,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 9,
    "Net Demand": 22288,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 10,
    "Net Demand": 20331,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 11,
    "Net Demand": 19859,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 12,
    "Net Demand": 20012,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 13,
    "Net Demand": 19931,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 14,
    "Net Demand": 20893,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 15,
    "Net Demand": 21300,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 16,
    "Net Demand": 23312,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 17,
    "Net Demand": 24412,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 18,
    "Net Demand": 25643,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 19,
    "Net Demand": 26135,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 20,
    "Net Demand": 25731,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 21,
    "Net Demand": 24683,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 22,
    "Net Demand": 24661,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 23,
    "Net Demand": 25653,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 10,
    Hour: 24,
    "Net Demand": 25091,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 1,
    "Net Demand": 24899,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 2,
    "Net Demand": 24731,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 3,
    "Net Demand": 24456,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 4,
    "Net Demand": 25655,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 5,
    "Net Demand": 25621,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 6,
    "Net Demand": 24584,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 7,
    "Net Demand": 24886,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 8,
    "Net Demand": 24399,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 9,
    "Net Demand": 23115,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 10,
    "Net Demand": 22957,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 11,
    "Net Demand": 20245,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 12,
    "Net Demand": 22186,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 13,
    "Net Demand": 25025,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 14,
    "Net Demand": 23896,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 15,
    "Net Demand": 23443,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 16,
    "Net Demand": 25698,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 17,
    "Net Demand": 26573,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 18,
    "Net Demand": 25455,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 19,
    "Net Demand": 24260,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 20,
    "Net Demand": 25095,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 21,
    "Net Demand": 24811,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 22,
    "Net Demand": 25649,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 23,
    "Net Demand": 29489,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 11,
    Hour: 24,
    "Net Demand": 29727,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 1,
    "Net Demand": 28683,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 2,
    "Net Demand": 28853,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 3,
    "Net Demand": 28194,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 4,
    "Net Demand": 28347,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 5,
    "Net Demand": 28977,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 6,
    "Net Demand": 28967,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 7,
    "Net Demand": 29323,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 8,
    "Net Demand": 28535,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 9,
    "Net Demand": 25863,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 10,
    "Net Demand": 24545,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 11,
    "Net Demand": 24093,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 12,
    "Net Demand": 24356,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 13,
    "Net Demand": 24632,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 14,
    "Net Demand": 24919,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 15,
    "Net Demand": 22988,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 16,
    "Net Demand": 17115,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 17,
    "Net Demand": 17180,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 18,
    "Net Demand": 18544,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 19,
    "Net Demand": 18589,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 20,
    "Net Demand": 21848,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 21,
    "Net Demand": 28579,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 22,
    "Net Demand": 30425,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 23,
    "Net Demand": 30381,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 12,
    Hour: 24,
    "Net Demand": 29925,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 1,
    "Net Demand": 29301,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 2,
    "Net Demand": 28192,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 3,
    "Net Demand": 27771,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 4,
    "Net Demand": 27576,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 5,
    "Net Demand": 28591,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 6,
    "Net Demand": 27986,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 7,
    "Net Demand": 28288,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 8,
    "Net Demand": 26972,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 9,
    "Net Demand": 26354,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 10,
    "Net Demand": 25198,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 11,
    "Net Demand": 24383,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 12,
    "Net Demand": 24754,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 13,
    "Net Demand": 25231,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 14,
    "Net Demand": 25881,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 15,
    "Net Demand": 27336,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 16,
    "Net Demand": 28641,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 17,
    "Net Demand": 28904,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 18,
    "Net Demand": 28943,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 19,
    "Net Demand": 28680,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 20,
    "Net Demand": 29823,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 21,
    "Net Demand": 27330,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 22,
    "Net Demand": 24962,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 23,
    "Net Demand": 27827,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 13,
    Hour: 24,
    "Net Demand": 31373,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 1,
    "Net Demand": 30953,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 2,
    "Net Demand": 29972,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 3,
    "Net Demand": 28559,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 4,
    "Net Demand": 29243,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 5,
    "Net Demand": 28856,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 6,
    "Net Demand": 29265,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 7,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 8,
    "Net Demand": 27921,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 9,
    "Net Demand": 28273,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 10,
    "Net Demand": 27308,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 11,
    "Net Demand": 25919,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 12,
    "Net Demand": 26464,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 13,
    "Net Demand": 27222,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 14,
    "Net Demand": 26018,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 15,
    "Net Demand": 24346,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 16,
    "Net Demand": 27301,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 17,
    "Net Demand": 28892,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 18,
    "Net Demand": 29288,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 19,
    "Net Demand": 28801,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 20,
    "Net Demand": 29811,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 21,
    "Net Demand": 30788,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 22,
    "Net Demand": 28983,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 23,
    "Net Demand": 28640,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 14,
    Hour: 24,
    "Net Demand": 27979,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 1,
    "Net Demand": 28963,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 2,
    "Net Demand": 28248,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 3,
    "Net Demand": 27654,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 4,
    "Net Demand": 28500,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 5,
    "Net Demand": 28880,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 6,
    "Net Demand": 27915,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 7,
    "Net Demand": 27039,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 8,
    "Net Demand": 27477,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 9,
    "Net Demand": 26362,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 10,
    "Net Demand": 16276,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 11,
    "Net Demand": 12678,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 12,
    "Net Demand": 13128,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 13,
    "Net Demand": 13315,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 14,
    "Net Demand": 13383,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 15,
    "Net Demand": 13931,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 16,
    "Net Demand": 14488,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 17,
    "Net Demand": 15617,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 18,
    "Net Demand": 16176,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 19,
    "Net Demand": 16693,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 20,
    "Net Demand": 16814,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 21,
    "Net Demand": 19064,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 22,
    "Net Demand": 24280,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 23,
    "Net Demand": 27422,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 15,
    Hour: 24,
    "Net Demand": 28481,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 1,
    "Net Demand": 27976,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 2,
    "Net Demand": 28216,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 3,
    "Net Demand": 27478,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 4,
    "Net Demand": 26936,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 5,
    "Net Demand": 26455,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 6,
    "Net Demand": 27388,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 7,
    "Net Demand": 27862,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 8,
    "Net Demand": 27047,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 9,
    "Net Demand": 24946,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 10,
    "Net Demand": 24482,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 11,
    "Net Demand": 24395,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 12,
    "Net Demand": 24074,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 13,
    "Net Demand": 23824,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 14,
    "Net Demand": 25030,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 15,
    "Net Demand": 19886,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 16,
    "Net Demand": 18530,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 17,
    "Net Demand": 19464,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 18,
    "Net Demand": 20315,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 19,
    "Net Demand": 20124,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 20,
    "Net Demand": 21178,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 21,
    "Net Demand": 21055,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 22,
    "Net Demand": 21360,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 23,
    "Net Demand": 21998,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 16,
    Hour: 24,
    "Net Demand": 24912,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 1,
    "Net Demand": 29823,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 2,
    "Net Demand": 29902,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 3,
    "Net Demand": 29227,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 4,
    "Net Demand": 30113,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 5,
    "Net Demand": 29062,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 6,
    "Net Demand": 28969,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 7,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 8,
    "Net Demand": 28216,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 9,
    "Net Demand": 27384,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 10,
    "Net Demand": 20101,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 11,
    "Net Demand": 17912,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 12,
    "Net Demand": 17884,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 13,
    "Net Demand": 17517,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 14,
    "Net Demand": 17882,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 15,
    "Net Demand": 18435,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 16,
    "Net Demand": 19317,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 17,
    "Net Demand": 18968,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 18,
    "Net Demand": 14568,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 19,
    "Net Demand": 13574,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 20,
    "Net Demand": 17562,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 21,
    "Net Demand": 16801,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 22,
    "Net Demand": 17228,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 23,
    "Net Demand": 20666,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 17,
    Hour: 24,
    "Net Demand": 21831,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 1,
    "Net Demand": 20533,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 2,
    "Net Demand": 20833,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 3,
    "Net Demand": 20744,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 4,
    "Net Demand": 20034,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 5,
    "Net Demand": 20784,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 6,
    "Net Demand": 20823,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 7,
    "Net Demand": 20020,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 8,
    "Net Demand": 19139,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 9,
    "Net Demand": 18676,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 10,
    "Net Demand": 17905,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 11,
    "Net Demand": 16522,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 12,
    "Net Demand": 16102,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 13,
    "Net Demand": 15711,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 14,
    "Net Demand": 16823,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 15,
    "Net Demand": 17721,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 16,
    "Net Demand": 18068,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 17,
    "Net Demand": 19639,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 18,
    "Net Demand": 20612,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 19,
    "Net Demand": 21233,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 20,
    "Net Demand": 22360,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 21,
    "Net Demand": 25501,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 22,
    "Net Demand": 29086,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 23,
    "Net Demand": 29414,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 18,
    Hour: 24,
    "Net Demand": 30199,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 1,
    "Net Demand": 25888,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 2,
    "Net Demand": 24837,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 3,
    "Net Demand": 23521,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 4,
    "Net Demand": 24397,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 5,
    "Net Demand": 24276,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 6,
    "Net Demand": 23261,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 7,
    "Net Demand": 21929,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 8,
    "Net Demand": 21913,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 9,
    "Net Demand": 21053,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 10,
    "Net Demand": 19679,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 11,
    "Net Demand": 18446,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 12,
    "Net Demand": 18711,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 13,
    "Net Demand": 16821,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 14,
    "Net Demand": 14143,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 15,
    "Net Demand": 14112,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 16,
    "Net Demand": 15243,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 17,
    "Net Demand": 20887,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 18,
    "Net Demand": 23781,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 19,
    "Net Demand": 24327,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 20,
    "Net Demand": 25218,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 21,
    "Net Demand": 24393,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 22,
    "Net Demand": 23828,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 23,
    "Net Demand": 23517,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 19,
    Hour: 24,
    "Net Demand": 24378,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 1,
    "Net Demand": 25666,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 2,
    "Net Demand": 25692,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 3,
    "Net Demand": 26429,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 4,
    "Net Demand": 28083,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 5,
    "Net Demand": 27153,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 6,
    "Net Demand": 23841,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 7,
    "Net Demand": 19025,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 8,
    "Net Demand": 20234,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 9,
    "Net Demand": 18771,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 10,
    "Net Demand": 17286,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 11,
    "Net Demand": 18871,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 12,
    "Net Demand": 17463,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 13,
    "Net Demand": 19209,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 14,
    "Net Demand": 18246,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 15,
    "Net Demand": 18889,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 16,
    "Net Demand": 19547,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 17,
    "Net Demand": 21089,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 18,
    "Net Demand": 22651,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 19,
    "Net Demand": 22532,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 20,
    "Net Demand": 23102,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 21,
    "Net Demand": 22805,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 22,
    "Net Demand": 22871,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 23,
    "Net Demand": 22778,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 20,
    Hour: 24,
    "Net Demand": 23497,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 1,
    "Net Demand": 29276,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 2,
    "Net Demand": 29317,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 3,
    "Net Demand": 29034,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 4,
    "Net Demand": 29813,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 5,
    "Net Demand": 30072,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 6,
    "Net Demand": 22578,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 7,
    "Net Demand": 24821,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 8,
    "Net Demand": 27584,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 9,
    "Net Demand": 26556,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 10,
    "Net Demand": 27388,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 11,
    "Net Demand": 19776,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 12,
    "Net Demand": 25169,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 13,
    "Net Demand": 25028,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 14,
    "Net Demand": 25697,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 15,
    "Net Demand": 24533,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 16,
    "Net Demand": 22329,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 17,
    "Net Demand": 23150,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 18,
    "Net Demand": 25987,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 19,
    "Net Demand": 28631,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 20,
    "Net Demand": 26691,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 21,
    "Net Demand": 26117,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 22,
    "Net Demand": 29486,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 23,
    "Net Demand": 22039,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 21,
    Hour: 24,
    "Net Demand": 30763,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 1,
    "Net Demand": 30621,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 2,
    "Net Demand": 30693,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 3,
    "Net Demand": 29931,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 4,
    "Net Demand": 30028,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 5,
    "Net Demand": 30243,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 6,
    "Net Demand": 26997,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 7,
    "Net Demand": 30142,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 8,
    "Net Demand": 26557,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 9,
    "Net Demand": 19998,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 10,
    "Net Demand": 18062,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 11,
    "Net Demand": 18771,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 12,
    "Net Demand": 19338,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 13,
    "Net Demand": 18793,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 14,
    "Net Demand": 18533,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 15,
    "Net Demand": 18574,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 16,
    "Net Demand": 19843,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 17,
    "Net Demand": 21547,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 18,
    "Net Demand": 20647,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 19,
    "Net Demand": 20810,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 20,
    "Net Demand": 22546,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 21,
    "Net Demand": 22065,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 22,
    "Net Demand": 21653,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 23,
    "Net Demand": 22014,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 22,
    Hour: 24,
    "Net Demand": 22326,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 1,
    "Net Demand": 21990,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 2,
    "Net Demand": 21854,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 3,
    "Net Demand": 20912,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 4,
    "Net Demand": 21145,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 5,
    "Net Demand": 20818,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 6,
    "Net Demand": 21300,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 7,
    "Net Demand": 21610,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 8,
    "Net Demand": 21293,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 9,
    "Net Demand": 19603,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 10,
    "Net Demand": 19128,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 11,
    "Net Demand": 19267,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 12,
    "Net Demand": 18598,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 13,
    "Net Demand": 17696,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 14,
    "Net Demand": 17959,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 15,
    "Net Demand": 18318,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 16,
    "Net Demand": 19449,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 17,
    "Net Demand": 20340,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 18,
    "Net Demand": 21462,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 19,
    "Net Demand": 21834,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 20,
    "Net Demand": 22868,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 21,
    "Net Demand": 23067,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 22,
    "Net Demand": 21643,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 23,
    "Net Demand": 21709,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 23,
    Hour: 24,
    "Net Demand": 21736,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 1,
    "Net Demand": 22012,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 2,
    "Net Demand": 22035,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 3,
    "Net Demand": 21468,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 4,
    "Net Demand": 21731,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 5,
    "Net Demand": 21712,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 6,
    "Net Demand": 22340,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 7,
    "Net Demand": 28387,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 8,
    "Net Demand": 28603,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 9,
    "Net Demand": 27344,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 10,
    "Net Demand": 26816,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 11,
    "Net Demand": 25696,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 12,
    "Net Demand": 21694,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 13,
    "Net Demand": 27575,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 14,
    "Net Demand": 26630,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 15,
    "Net Demand": 26432,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 16,
    "Net Demand": 27491,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 17,
    "Net Demand": 28006,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 18,
    "Net Demand": 28539,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 19,
    "Net Demand": 29037,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 20,
    "Net Demand": 29673,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 21,
    "Net Demand": 28858,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 22,
    "Net Demand": 28985,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 23,
    "Net Demand": 28030,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 24,
    Hour: 24,
    "Net Demand": 28440,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 1,
    "Net Demand": 28815,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 2,
    "Net Demand": 29094,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 3,
    "Net Demand": 28369,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 4,
    "Net Demand": 29105,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 5,
    "Net Demand": 29007,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 6,
    "Net Demand": 29017,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 7,
    "Net Demand": 28304,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 8,
    "Net Demand": 29135,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 9,
    "Net Demand": 26010,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 10,
    "Net Demand": 22313,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 11,
    "Net Demand": 21269,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 12,
    "Net Demand": 21525,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 13,
    "Net Demand": 22124,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 14,
    "Net Demand": 21885,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 15,
    "Net Demand": 21844,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 16,
    "Net Demand": 21649,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 17,
    "Net Demand": 23309,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 18,
    "Net Demand": 24406,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 19,
    "Net Demand": 24513,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 20,
    "Net Demand": 24984,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 21,
    "Net Demand": 25096,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 22,
    "Net Demand": 24528,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 23,
    "Net Demand": 25566,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 25,
    Hour: 24,
    "Net Demand": 21510,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 1,
    "Net Demand": 24372,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 2,
    "Net Demand": 20370,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 3,
    "Net Demand": 20374,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 4,
    "Net Demand": 20889,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 5,
    "Net Demand": 21149,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 6,
    "Net Demand": 21330,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 7,
    "Net Demand": 21601,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 8,
    "Net Demand": 19404,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 9,
    "Net Demand": 19404,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 10,
    "Net Demand": 19906,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 11,
    "Net Demand": 19692,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 12,
    "Net Demand": 20487,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 13,
    "Net Demand": 20786,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 14,
    "Net Demand": 21130,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 15,
    "Net Demand": 21687,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 16,
    "Net Demand": 21327,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 17,
    "Net Demand": 23270,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 18,
    "Net Demand": 25449,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 19,
    "Net Demand": 26202,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 20,
    "Net Demand": 29565,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 21,
    "Net Demand": 29746,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 22,
    "Net Demand": 32476,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 23,
    "Net Demand": 29028,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 26,
    Hour: 24,
    "Net Demand": 25287,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 1,
    "Net Demand": 25039,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 2,
    "Net Demand": 22483,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 3,
    "Net Demand": 18608,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 4,
    "Net Demand": 24968,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 5,
    "Net Demand": 25282,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 6,
    "Net Demand": 25194,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 7,
    "Net Demand": 24495,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 8,
    "Net Demand": 23609,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 9,
    "Net Demand": 23808,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 10,
    "Net Demand": 25219,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 11,
    "Net Demand": 23881,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 12,
    "Net Demand": 23588,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 13,
    "Net Demand": 23790,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 14,
    "Net Demand": 24097,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 15,
    "Net Demand": 25239,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 16,
    "Net Demand": 25976,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 17,
    "Net Demand": 27959,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 18,
    "Net Demand": 28367,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 19,
    "Net Demand": 27751,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 20,
    "Net Demand": 28844,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 21,
    "Net Demand": 28343,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 22,
    "Net Demand": 27736,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 23,
    "Net Demand": 28539,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 27,
    Hour: 24,
    "Net Demand": 30006,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 1,
    "Net Demand": 28832,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 2,
    "Net Demand": 28621,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 3,
    "Net Demand": 28881,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 4,
    "Net Demand": 29776,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 5,
    "Net Demand": 29702,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 6,
    "Net Demand": 29531,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 7,
    "Net Demand": 27485,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 8,
    "Net Demand": 20830,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 9,
    "Net Demand": 16665,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 10,
    "Net Demand": 17134,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 11,
    "Net Demand": 18881,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 12,
    "Net Demand": 19863,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 13,
    "Net Demand": 20184,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 14,
    "Net Demand": 19337,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 15,
    "Net Demand": 19564,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 16,
    "Net Demand": 19770,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 17,
    "Net Demand": 21181,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 18,
    "Net Demand": 20160,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 19,
    "Net Demand": 21383,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 20,
    "Net Demand": 21547,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 21,
    "Net Demand": 21467,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 22,
    "Net Demand": 21706,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 23,
    "Net Demand": 19627,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 28,
    Hour: 24,
    "Net Demand": 21644,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 1,
    "Net Demand": 22606,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 2,
    "Net Demand": 21765,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 3,
    "Net Demand": 21060,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 4,
    "Net Demand": 21195,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 5,
    "Net Demand": 21498,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 6,
    "Net Demand": 22890,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 7,
    "Net Demand": 21564,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 8,
    "Net Demand": 17081,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 9,
    "Net Demand": 15408,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 10,
    "Net Demand": 14172,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 11,
    "Net Demand": 12798,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 12,
    "Net Demand": 13986,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 13,
    "Net Demand": 17416,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 14,
    "Net Demand": 17846,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 15,
    "Net Demand": 18514,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 16,
    "Net Demand": 20092,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 17,
    "Net Demand": 20252,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 18,
    "Net Demand": 21995,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 19,
    "Net Demand": 21893,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 20,
    "Net Demand": 22454,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 21,
    "Net Demand": 21593,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 22,
    "Net Demand": 19916,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 23,
    "Net Demand": 21549,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 29,
    Hour: 24,
    "Net Demand": 21022,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 1,
    "Net Demand": 24824,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 2,
    "Net Demand": 26608,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 3,
    "Net Demand": 27900,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 4,
    "Net Demand": 28197,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 5,
    "Net Demand": 28012,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 6,
    "Net Demand": 29035,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 7,
    "Net Demand": 28782,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 8,
    "Net Demand": 27881,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 9,
    "Net Demand": 26268,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 10,
    "Net Demand": 26786,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 11,
    "Net Demand": 20426,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 12,
    "Net Demand": 23373,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 13,
    "Net Demand": 22941,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 14,
    "Net Demand": 20779,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 15,
    "Net Demand": 20601,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 16,
    "Net Demand": 21756,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 17,
    "Net Demand": 22832,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 18,
    "Net Demand": 24715,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 19,
    "Net Demand": 29618,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 20,
    "Net Demand": 29445,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 21,
    "Net Demand": 28640,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 22,
    "Net Demand": 28105,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 23,
    "Net Demand": 29283,
  },
  {
    Year: 2023,
    Month: 11,
    Day: 30,
    Hour: 24,
    "Net Demand": 29197,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 1,
    "Net Demand": 32131,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 2,
    "Net Demand": 31975,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 3,
    "Net Demand": 31700,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 4,
    "Net Demand": 33419,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 5,
    "Net Demand": 32253,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 6,
    "Net Demand": 32593,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 7,
    "Net Demand": 31945,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 8,
    "Net Demand": 31238,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 9,
    "Net Demand": 29293,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 10,
    "Net Demand": 23525,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 11,
    "Net Demand": 20146,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 12,
    "Net Demand": 24680,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 13,
    "Net Demand": 25280,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 14,
    "Net Demand": 21076,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 15,
    "Net Demand": 22193,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 16,
    "Net Demand": 23633,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 17,
    "Net Demand": 27191,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 18,
    "Net Demand": 28349,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 19,
    "Net Demand": 27770,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 20,
    "Net Demand": 27954,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 21,
    "Net Demand": 27363,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 22,
    "Net Demand": 28318,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 23,
    "Net Demand": 27569,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 1,
    Hour: 24,
    "Net Demand": 28073,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 1,
    "Net Demand": 25577,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 2,
    "Net Demand": 20452,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 3,
    "Net Demand": 18373,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 4,
    "Net Demand": 19092,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 5,
    "Net Demand": 19017,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 6,
    "Net Demand": 19989,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 7,
    "Net Demand": 16553,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 8,
    "Net Demand": 15285,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 9,
    "Net Demand": 17193,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 10,
    "Net Demand": 16692,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 11,
    "Net Demand": 16218,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 12,
    "Net Demand": 16033,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 13,
    "Net Demand": 16919,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 14,
    "Net Demand": 16007,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 15,
    "Net Demand": 11199,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 16,
    "Net Demand": 11102,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 17,
    "Net Demand": 11532,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 18,
    "Net Demand": 11990,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 19,
    "Net Demand": 11762,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 20,
    "Net Demand": 12598,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 21,
    "Net Demand": 12065,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 22,
    "Net Demand": 11285,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 23,
    "Net Demand": 11774,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 2,
    Hour: 24,
    "Net Demand": 11353,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 1,
    "Net Demand": 10449,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 2,
    "Net Demand": 10501,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 3,
    "Net Demand": 10370,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 4,
    "Net Demand": 10399,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 5,
    "Net Demand": 10088,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 6,
    "Net Demand": 10030,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 7,
    "Net Demand": 9357,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 8,
    "Net Demand": 8833,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 9,
    "Net Demand": 8454,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 10,
    "Net Demand": 8061,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 11,
    "Net Demand": 6658,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 12,
    "Net Demand": 7606,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 13,
    "Net Demand": 6449,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 14,
    "Net Demand": 5972,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 15,
    "Net Demand": 7223,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 16,
    "Net Demand": 7287,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 17,
    "Net Demand": 9245,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 18,
    "Net Demand": 9161,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 19,
    "Net Demand": 9368,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 20,
    "Net Demand": 10444,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 21,
    "Net Demand": 10394,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 22,
    "Net Demand": 10415,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 23,
    "Net Demand": 10809,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 3,
    Hour: 24,
    "Net Demand": 13054,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 1,
    "Net Demand": 18188,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 2,
    "Net Demand": 15662,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 3,
    "Net Demand": 18102,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 4,
    "Net Demand": 18489,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 5,
    "Net Demand": 18390,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 6,
    "Net Demand": 18347,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 7,
    "Net Demand": 18908,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 8,
    "Net Demand": 19397,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 9,
    "Net Demand": 20570,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 10,
    "Net Demand": 18227,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 11,
    "Net Demand": 8019,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 12,
    "Net Demand": 7970,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 13,
    "Net Demand": 9191,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 14,
    "Net Demand": 12083,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 15,
    "Net Demand": 16663,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 16,
    "Net Demand": 20484,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 17,
    "Net Demand": 22771,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 18,
    "Net Demand": 23886,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 19,
    "Net Demand": 25937,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 20,
    "Net Demand": 28535,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 21,
    "Net Demand": 31437,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 22,
    "Net Demand": 29955,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 23,
    "Net Demand": 30281,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 4,
    Hour: 24,
    "Net Demand": 30692,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 1,
    "Net Demand": 32251,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 2,
    "Net Demand": 31393,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 3,
    "Net Demand": 31624,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 4,
    "Net Demand": 32087,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 5,
    "Net Demand": 31386,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 6,
    "Net Demand": 24839,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 7,
    "Net Demand": 28371,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 8,
    "Net Demand": 30008,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 9,
    "Net Demand": 29359,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 10,
    "Net Demand": 29022,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 11,
    "Net Demand": 26682,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 12,
    "Net Demand": 27605,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 13,
    "Net Demand": 28199,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 14,
    "Net Demand": 27791,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 15,
    "Net Demand": 27782,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 16,
    "Net Demand": 28862,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 17,
    "Net Demand": 31735,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 18,
    "Net Demand": 32569,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 19,
    "Net Demand": 32771,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 20,
    "Net Demand": 33829,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 21,
    "Net Demand": 33347,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 22,
    "Net Demand": 33312,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 23,
    "Net Demand": 33813,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 5,
    Hour: 24,
    "Net Demand": 32396,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 1,
    "Net Demand": 32562,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 2,
    "Net Demand": 32390,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 3,
    "Net Demand": 31591,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 4,
    "Net Demand": 32339,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 5,
    "Net Demand": 31723,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 6,
    "Net Demand": 32408,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 7,
    "Net Demand": 33022,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 8,
    "Net Demand": 31555,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 9,
    "Net Demand": 29663,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 10,
    "Net Demand": 28604,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 11,
    "Net Demand": 24337,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 12,
    "Net Demand": 26464,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 13,
    "Net Demand": 25758,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 14,
    "Net Demand": 27048,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 15,
    "Net Demand": 27591,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 16,
    "Net Demand": 28810,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 17,
    "Net Demand": 30584,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 18,
    "Net Demand": 29876,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 19,
    "Net Demand": 29366,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 20,
    "Net Demand": 33494,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 21,
    "Net Demand": 33896,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 22,
    "Net Demand": 33395,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 23,
    "Net Demand": 33178,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 6,
    Hour: 24,
    "Net Demand": 32770,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 1,
    "Net Demand": 31565,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 2,
    "Net Demand": 30912,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 3,
    "Net Demand": 30123,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 4,
    "Net Demand": 32122,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 5,
    "Net Demand": 32844,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 6,
    "Net Demand": 31681,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 7,
    "Net Demand": 30273,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 8,
    "Net Demand": 29459,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 9,
    "Net Demand": 30053,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 10,
    "Net Demand": 30449,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 11,
    "Net Demand": 30525,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 12,
    "Net Demand": 29904,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 13,
    "Net Demand": 28538,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 14,
    "Net Demand": 27805,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 15,
    "Net Demand": 28175,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 16,
    "Net Demand": 27941,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 17,
    "Net Demand": 31613,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 18,
    "Net Demand": 32654,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 19,
    "Net Demand": 31703,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 20,
    "Net Demand": 32823,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 21,
    "Net Demand": 32392,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 22,
    "Net Demand": 32771,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 23,
    "Net Demand": 31259,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 7,
    Hour: 24,
    "Net Demand": 31714,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 1,
    "Net Demand": 31073,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 2,
    "Net Demand": 31222,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 3,
    "Net Demand": 30102,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 4,
    "Net Demand": 32606,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 5,
    "Net Demand": 32369,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 6,
    "Net Demand": 31559,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 7,
    "Net Demand": 30683,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 8,
    "Net Demand": 30080,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 9,
    "Net Demand": 29297,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 10,
    "Net Demand": 27944,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 11,
    "Net Demand": 25932,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 12,
    "Net Demand": 27445,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 13,
    "Net Demand": 28391,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 14,
    "Net Demand": 28805,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 15,
    "Net Demand": 28505,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 16,
    "Net Demand": 29783,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 17,
    "Net Demand": 26389,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 18,
    "Net Demand": 26133,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 19,
    "Net Demand": 32890,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 20,
    "Net Demand": 34051,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 21,
    "Net Demand": 33267,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 22,
    "Net Demand": 32183,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 23,
    "Net Demand": 31025,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 8,
    Hour: 24,
    "Net Demand": 31218,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 1,
    "Net Demand": 31527,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 2,
    "Net Demand": 30928,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 3,
    "Net Demand": 33932,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 4,
    "Net Demand": 33854,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 5,
    "Net Demand": 32724,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 6,
    "Net Demand": 32298,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 7,
    "Net Demand": 32491,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 8,
    "Net Demand": 32245,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 9,
    "Net Demand": 30479,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 10,
    "Net Demand": 25906,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 11,
    "Net Demand": 22504,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 12,
    "Net Demand": 24549,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 13,
    "Net Demand": 24344,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 14,
    "Net Demand": 22735,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 15,
    "Net Demand": 23703,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 16,
    "Net Demand": 25593,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 17,
    "Net Demand": 28115,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 18,
    "Net Demand": 26987,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 19,
    "Net Demand": 27016,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 20,
    "Net Demand": 27701,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 21,
    "Net Demand": 27032,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 22,
    "Net Demand": 28242,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 23,
    "Net Demand": 26639,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 9,
    Hour: 24,
    "Net Demand": 26630,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 1,
    "Net Demand": 26508,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 2,
    "Net Demand": 25457,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 3,
    "Net Demand": 24968,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 4,
    "Net Demand": 25697,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 5,
    "Net Demand": 26297,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 6,
    "Net Demand": 24150,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 7,
    "Net Demand": 25231,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 8,
    "Net Demand": 24100,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 9,
    "Net Demand": 23807,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 10,
    "Net Demand": 21717,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 11,
    "Net Demand": 21213,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 12,
    "Net Demand": 21375,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 13,
    "Net Demand": 21289,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 14,
    "Net Demand": 22316,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 15,
    "Net Demand": 22751,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 16,
    "Net Demand": 24901,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 17,
    "Net Demand": 26075,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 18,
    "Net Demand": 27390,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 19,
    "Net Demand": 27916,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 20,
    "Net Demand": 27485,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 21,
    "Net Demand": 26365,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 22,
    "Net Demand": 26341,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 23,
    "Net Demand": 27401,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 10,
    Hour: 24,
    "Net Demand": 26801,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 1,
    "Net Demand": 26596,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 2,
    "Net Demand": 26417,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 3,
    "Net Demand": 26122,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 4,
    "Net Demand": 27403,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 5,
    "Net Demand": 27367,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 6,
    "Net Demand": 26259,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 7,
    "Net Demand": 26582,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 8,
    "Net Demand": 26061,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 9,
    "Net Demand": 24691,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 10,
    "Net Demand": 24521,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 11,
    "Net Demand": 21624,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 12,
    "Net Demand": 23698,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 13,
    "Net Demand": 26730,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 14,
    "Net Demand": 25524,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 15,
    "Net Demand": 25040,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 16,
    "Net Demand": 27449,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 17,
    "Net Demand": 28383,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 18,
    "Net Demand": 27190,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 19,
    "Net Demand": 25913,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 20,
    "Net Demand": 26805,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 21,
    "Net Demand": 26502,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 22,
    "Net Demand": 27397,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 23,
    "Net Demand": 31498,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 11,
    Hour: 24,
    "Net Demand": 31752,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 1,
    "Net Demand": 30637,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 2,
    "Net Demand": 30819,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 3,
    "Net Demand": 30116,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 4,
    "Net Demand": 30279,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 5,
    "Net Demand": 30951,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 6,
    "Net Demand": 30941,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 7,
    "Net Demand": 31321,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 8,
    "Net Demand": 30480,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 9,
    "Net Demand": 27626,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 10,
    "Net Demand": 26217,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 11,
    "Net Demand": 25734,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 12,
    "Net Demand": 26015,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 13,
    "Net Demand": 26311,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 14,
    "Net Demand": 26617,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 15,
    "Net Demand": 24554,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 16,
    "Net Demand": 18282,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 17,
    "Net Demand": 18351,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 18,
    "Net Demand": 19808,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 19,
    "Net Demand": 19856,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 20,
    "Net Demand": 23336,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 21,
    "Net Demand": 30526,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 22,
    "Net Demand": 32498,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 23,
    "Net Demand": 32451,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 12,
    Hour: 24,
    "Net Demand": 31965,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 1,
    "Net Demand": 31298,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 2,
    "Net Demand": 30113,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 3,
    "Net Demand": 29663,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 4,
    "Net Demand": 29455,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 5,
    "Net Demand": 30540,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 6,
    "Net Demand": 29893,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 7,
    "Net Demand": 30216,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 8,
    "Net Demand": 28809,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 9,
    "Net Demand": 28149,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 10,
    "Net Demand": 26915,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 11,
    "Net Demand": 26045,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 12,
    "Net Demand": 26441,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 13,
    "Net Demand": 26950,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 14,
    "Net Demand": 27645,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 15,
    "Net Demand": 29199,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 16,
    "Net Demand": 30593,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 17,
    "Net Demand": 30873,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 18,
    "Net Demand": 30915,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 19,
    "Net Demand": 30634,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 20,
    "Net Demand": 31855,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 21,
    "Net Demand": 29192,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 22,
    "Net Demand": 26663,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 23,
    "Net Demand": 29723,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 13,
    Hour: 24,
    "Net Demand": 33510,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 1,
    "Net Demand": 33063,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 2,
    "Net Demand": 32015,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 3,
    "Net Demand": 30505,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 4,
    "Net Demand": 31236,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 5,
    "Net Demand": 30822,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 6,
    "Net Demand": 31259,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 7,
    "Net Demand": 30582,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 8,
    "Net Demand": 29823,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 9,
    "Net Demand": 30200,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 10,
    "Net Demand": 29169,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 11,
    "Net Demand": 27685,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 12,
    "Net Demand": 28267,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 13,
    "Net Demand": 29077,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 14,
    "Net Demand": 27790,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 15,
    "Net Demand": 26005,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 16,
    "Net Demand": 29161,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 17,
    "Net Demand": 30861,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 18,
    "Net Demand": 31283,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 19,
    "Net Demand": 30763,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 20,
    "Net Demand": 31842,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 21,
    "Net Demand": 32886,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 22,
    "Net Demand": 30958,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 23,
    "Net Demand": 30592,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 14,
    Hour: 24,
    "Net Demand": 29885,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 1,
    "Net Demand": 30937,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 2,
    "Net Demand": 30173,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 3,
    "Net Demand": 29538,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 4,
    "Net Demand": 30442,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 5,
    "Net Demand": 30848,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 6,
    "Net Demand": 29817,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 7,
    "Net Demand": 28882,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 8,
    "Net Demand": 29349,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 9,
    "Net Demand": 28158,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 10,
    "Net Demand": 17385,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 11,
    "Net Demand": 13542,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 12,
    "Net Demand": 14022,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 13,
    "Net Demand": 14222,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 14,
    "Net Demand": 14295,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 15,
    "Net Demand": 14880,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 16,
    "Net Demand": 15475,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 17,
    "Net Demand": 16681,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 18,
    "Net Demand": 17278,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 19,
    "Net Demand": 17830,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 20,
    "Net Demand": 17960,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 21,
    "Net Demand": 20364,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 22,
    "Net Demand": 25934,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 23,
    "Net Demand": 29291,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 15,
    Hour: 24,
    "Net Demand": 30421,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 1,
    "Net Demand": 29882,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 2,
    "Net Demand": 30139,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 3,
    "Net Demand": 29350,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 4,
    "Net Demand": 28771,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 5,
    "Net Demand": 28258,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 6,
    "Net Demand": 29254,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 7,
    "Net Demand": 29760,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 8,
    "Net Demand": 28890,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 9,
    "Net Demand": 26646,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 10,
    "Net Demand": 26151,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 11,
    "Net Demand": 26057,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 12,
    "Net Demand": 25714,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 13,
    "Net Demand": 25448,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 14,
    "Net Demand": 26736,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 15,
    "Net Demand": 21241,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 16,
    "Net Demand": 19793,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 17,
    "Net Demand": 20790,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 18,
    "Net Demand": 21699,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 19,
    "Net Demand": 21496,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 20,
    "Net Demand": 22622,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 21,
    "Net Demand": 22489,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 22,
    "Net Demand": 22816,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 23,
    "Net Demand": 23496,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 16,
    Hour: 24,
    "Net Demand": 26610,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 1,
    "Net Demand": 31855,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 2,
    "Net Demand": 31940,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 3,
    "Net Demand": 31219,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 4,
    "Net Demand": 32165,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 5,
    "Net Demand": 31042,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 6,
    "Net Demand": 30943,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 7,
    "Net Demand": 30582,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 8,
    "Net Demand": 30139,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 9,
    "Net Demand": 29250,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 10,
    "Net Demand": 21471,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 11,
    "Net Demand": 19133,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 12,
    "Net Demand": 19102,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 13,
    "Net Demand": 18710,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 14,
    "Net Demand": 19101,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 15,
    "Net Demand": 19692,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 16,
    "Net Demand": 20633,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 17,
    "Net Demand": 20260,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 18,
    "Net Demand": 15561,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 19,
    "Net Demand": 14499,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 20,
    "Net Demand": 18758,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 21,
    "Net Demand": 17946,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 22,
    "Net Demand": 18402,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 23,
    "Net Demand": 22074,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 17,
    Hour: 24,
    "Net Demand": 23319,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 1,
    "Net Demand": 21933,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 2,
    "Net Demand": 22252,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 3,
    "Net Demand": 22158,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 4,
    "Net Demand": 21399,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 5,
    "Net Demand": 22200,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 6,
    "Net Demand": 22242,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 7,
    "Net Demand": 21384,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 8,
    "Net Demand": 20443,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 9,
    "Net Demand": 19948,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 10,
    "Net Demand": 19125,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 11,
    "Net Demand": 17647,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 12,
    "Net Demand": 17199,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 13,
    "Net Demand": 16782,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 14,
    "Net Demand": 17970,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 15,
    "Net Demand": 18928,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 16,
    "Net Demand": 19299,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 17,
    "Net Demand": 20977,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 18,
    "Net Demand": 22016,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 19,
    "Net Demand": 22680,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 20,
    "Net Demand": 23884,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 21,
    "Net Demand": 27239,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 22,
    "Net Demand": 31068,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 23,
    "Net Demand": 31419,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 18,
    Hour: 24,
    "Net Demand": 32257,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 1,
    "Net Demand": 27652,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 2,
    "Net Demand": 26530,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 3,
    "Net Demand": 25123,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 4,
    "Net Demand": 26060,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 5,
    "Net Demand": 25930,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 6,
    "Net Demand": 24846,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 7,
    "Net Demand": 23424,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 8,
    "Net Demand": 23407,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 9,
    "Net Demand": 22487,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 10,
    "Net Demand": 21020,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 11,
    "Net Demand": 19703,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 12,
    "Net Demand": 19986,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 13,
    "Net Demand": 17967,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 14,
    "Net Demand": 15106,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 15,
    "Net Demand": 15073,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 16,
    "Net Demand": 16281,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 17,
    "Net Demand": 22311,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 18,
    "Net Demand": 25402,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 19,
    "Net Demand": 25984,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 20,
    "Net Demand": 26936,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 21,
    "Net Demand": 26055,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 22,
    "Net Demand": 25452,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 23,
    "Net Demand": 25119,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 19,
    Hour: 24,
    "Net Demand": 26039,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 1,
    "Net Demand": 27415,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 2,
    "Net Demand": 27443,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 3,
    "Net Demand": 28230,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 4,
    "Net Demand": 29996,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 5,
    "Net Demand": 29003,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 6,
    "Net Demand": 25466,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 7,
    "Net Demand": 20321,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 8,
    "Net Demand": 21613,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 9,
    "Net Demand": 20050,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 10,
    "Net Demand": 18464,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 11,
    "Net Demand": 20157,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 12,
    "Net Demand": 18653,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 13,
    "Net Demand": 20518,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 14,
    "Net Demand": 19489,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 15,
    "Net Demand": 20176,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 16,
    "Net Demand": 20878,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 17,
    "Net Demand": 22526,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 18,
    "Net Demand": 24194,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 19,
    "Net Demand": 24068,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 20,
    "Net Demand": 24676,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 21,
    "Net Demand": 24359,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 22,
    "Net Demand": 24430,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 23,
    "Net Demand": 24330,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 20,
    Hour: 24,
    "Net Demand": 25098,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 1,
    "Net Demand": 31271,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 2,
    "Net Demand": 31314,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 3,
    "Net Demand": 31013,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 4,
    "Net Demand": 31845,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 5,
    "Net Demand": 32121,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 6,
    "Net Demand": 24116,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 7,
    "Net Demand": 26512,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 8,
    "Net Demand": 29463,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 9,
    "Net Demand": 28366,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 10,
    "Net Demand": 29254,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 11,
    "Net Demand": 21123,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 12,
    "Net Demand": 26884,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 13,
    "Net Demand": 26733,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 14,
    "Net Demand": 27448,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 15,
    "Net Demand": 26205,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 16,
    "Net Demand": 23851,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 17,
    "Net Demand": 24728,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 18,
    "Net Demand": 27758,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 19,
    "Net Demand": 30582,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 20,
    "Net Demand": 28509,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 21,
    "Net Demand": 27896,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 22,
    "Net Demand": 31495,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 23,
    "Net Demand": 23541,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 21,
    Hour: 24,
    "Net Demand": 32859,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 1,
    "Net Demand": 32707,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 2,
    "Net Demand": 32785,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 3,
    "Net Demand": 31971,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 4,
    "Net Demand": 32074,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 5,
    "Net Demand": 32304,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 6,
    "Net Demand": 28836,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 7,
    "Net Demand": 32195,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 8,
    "Net Demand": 28367,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 9,
    "Net Demand": 21361,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 10,
    "Net Demand": 19292,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 11,
    "Net Demand": 20050,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 12,
    "Net Demand": 20655,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 13,
    "Net Demand": 20074,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 14,
    "Net Demand": 19795,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 15,
    "Net Demand": 19840,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 16,
    "Net Demand": 21195,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 17,
    "Net Demand": 23015,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 18,
    "Net Demand": 22054,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 19,
    "Net Demand": 22228,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 20,
    "Net Demand": 24083,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 21,
    "Net Demand": 23568,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 22,
    "Net Demand": 23128,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 23,
    "Net Demand": 23514,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 22,
    Hour: 24,
    "Net Demand": 23847,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 1,
    "Net Demand": 23488,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 2,
    "Net Demand": 23343,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 3,
    "Net Demand": 22337,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 4,
    "Net Demand": 22586,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 5,
    "Net Demand": 22236,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 6,
    "Net Demand": 22751,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 7,
    "Net Demand": 23082,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 8,
    "Net Demand": 22744,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 9,
    "Net Demand": 20938,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 10,
    "Net Demand": 20431,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 11,
    "Net Demand": 20580,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 12,
    "Net Demand": 19865,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 13,
    "Net Demand": 18902,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 14,
    "Net Demand": 19182,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 15,
    "Net Demand": 19566,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 16,
    "Net Demand": 20775,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 17,
    "Net Demand": 21726,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 18,
    "Net Demand": 22925,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 19,
    "Net Demand": 23322,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 20,
    "Net Demand": 24427,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 21,
    "Net Demand": 24638,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 22,
    "Net Demand": 23118,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 23,
    "Net Demand": 23189,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 23,
    Hour: 24,
    "Net Demand": 23218,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 1,
    "Net Demand": 23512,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 2,
    "Net Demand": 23536,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 3,
    "Net Demand": 22930,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 4,
    "Net Demand": 23212,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 5,
    "Net Demand": 23191,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 6,
    "Net Demand": 23862,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 7,
    "Net Demand": 30322,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 8,
    "Net Demand": 30552,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 9,
    "Net Demand": 29207,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 10,
    "Net Demand": 28643,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 11,
    "Net Demand": 27447,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 12,
    "Net Demand": 23173,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 13,
    "Net Demand": 29454,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 14,
    "Net Demand": 28445,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 15,
    "Net Demand": 28233,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 16,
    "Net Demand": 29364,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 17,
    "Net Demand": 29914,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 18,
    "Net Demand": 30484,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 19,
    "Net Demand": 31015,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 20,
    "Net Demand": 31695,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 21,
    "Net Demand": 30824,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 22,
    "Net Demand": 30960,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 23,
    "Net Demand": 29940,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 24,
    Hour: 24,
    "Net Demand": 30378,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 1,
    "Net Demand": 30779,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 2,
    "Net Demand": 31077,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 3,
    "Net Demand": 30302,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 4,
    "Net Demand": 31088,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 5,
    "Net Demand": 30983,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 6,
    "Net Demand": 30994,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 7,
    "Net Demand": 30233,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 8,
    "Net Demand": 31120,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 9,
    "Net Demand": 27783,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 10,
    "Net Demand": 23834,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 11,
    "Net Demand": 22718,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 12,
    "Net Demand": 22992,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 13,
    "Net Demand": 23631,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 14,
    "Net Demand": 23376,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 15,
    "Net Demand": 23333,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 16,
    "Net Demand": 23124,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 17,
    "Net Demand": 24898,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 18,
    "Net Demand": 26069,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 19,
    "Net Demand": 26183,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 20,
    "Net Demand": 26687,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 21,
    "Net Demand": 26806,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 22,
    "Net Demand": 26200,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 23,
    "Net Demand": 27308,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 25,
    Hour: 24,
    "Net Demand": 22976,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 1,
    "Net Demand": 26033,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 2,
    "Net Demand": 21758,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 3,
    "Net Demand": 21762,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 4,
    "Net Demand": 22312,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 5,
    "Net Demand": 22591,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 6,
    "Net Demand": 22784,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 7,
    "Net Demand": 23073,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 8,
    "Net Demand": 20726,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 9,
    "Net Demand": 20727,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 10,
    "Net Demand": 21262,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 11,
    "Net Demand": 21034,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 12,
    "Net Demand": 21883,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 13,
    "Net Demand": 22202,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 14,
    "Net Demand": 22569,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 15,
    "Net Demand": 23165,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 16,
    "Net Demand": 22780,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 17,
    "Net Demand": 24856,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 18,
    "Net Demand": 27183,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 19,
    "Net Demand": 27987,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 20,
    "Net Demand": 31580,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 21,
    "Net Demand": 31772,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 22,
    "Net Demand": 34689,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 23,
    "Net Demand": 31006,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 26,
    Hour: 24,
    "Net Demand": 27010,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 1,
    "Net Demand": 26745,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 2,
    "Net Demand": 24015,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 3,
    "Net Demand": 19875,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 4,
    "Net Demand": 26669,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 5,
    "Net Demand": 27005,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 6,
    "Net Demand": 26910,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 7,
    "Net Demand": 26164,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 8,
    "Net Demand": 25218,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 9,
    "Net Demand": 25430,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 10,
    "Net Demand": 26937,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 11,
    "Net Demand": 25509,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 12,
    "Net Demand": 25196,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 13,
    "Net Demand": 25411,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 14,
    "Net Demand": 25739,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 15,
    "Net Demand": 26959,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 16,
    "Net Demand": 27746,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 17,
    "Net Demand": 29864,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 18,
    "Net Demand": 30300,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 19,
    "Net Demand": 29642,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 20,
    "Net Demand": 30810,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 21,
    "Net Demand": 30275,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 22,
    "Net Demand": 29625,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 23,
    "Net Demand": 30484,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 27,
    Hour: 24,
    "Net Demand": 32051,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 1,
    "Net Demand": 30797,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 2,
    "Net Demand": 30571,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 3,
    "Net Demand": 30849,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 4,
    "Net Demand": 31805,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 5,
    "Net Demand": 31726,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 6,
    "Net Demand": 31543,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 7,
    "Net Demand": 29358,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 8,
    "Net Demand": 22249,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 9,
    "Net Demand": 17800,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 10,
    "Net Demand": 18302,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 11,
    "Net Demand": 20167,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 12,
    "Net Demand": 21217,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 13,
    "Net Demand": 21559,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 14,
    "Net Demand": 20654,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 15,
    "Net Demand": 20897,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 16,
    "Net Demand": 21117,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 17,
    "Net Demand": 22624,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 18,
    "Net Demand": 21534,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 19,
    "Net Demand": 22840,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 20,
    "Net Demand": 23016,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 21,
    "Net Demand": 22930,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 22,
    "Net Demand": 23185,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 23,
    "Net Demand": 20965,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 28,
    Hour: 24,
    "Net Demand": 23119,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 1,
    "Net Demand": 24146,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 2,
    "Net Demand": 23248,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 3,
    "Net Demand": 22495,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 4,
    "Net Demand": 22639,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 5,
    "Net Demand": 22963,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 6,
    "Net Demand": 24450,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 7,
    "Net Demand": 23033,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 8,
    "Net Demand": 18245,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 9,
    "Net Demand": 16458,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 10,
    "Net Demand": 15137,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 11,
    "Net Demand": 13670,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 12,
    "Net Demand": 14939,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 13,
    "Net Demand": 18603,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 14,
    "Net Demand": 19062,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 15,
    "Net Demand": 19775,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 16,
    "Net Demand": 21461,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 17,
    "Net Demand": 21632,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 18,
    "Net Demand": 23494,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 19,
    "Net Demand": 23385,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 20,
    "Net Demand": 23984,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 21,
    "Net Demand": 23064,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 22,
    "Net Demand": 21273,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 23,
    "Net Demand": 23017,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 29,
    Hour: 24,
    "Net Demand": 22454,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 1,
    "Net Demand": 26516,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 2,
    "Net Demand": 28421,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 3,
    "Net Demand": 29801,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 4,
    "Net Demand": 30118,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 5,
    "Net Demand": 29920,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 6,
    "Net Demand": 31014,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 7,
    "Net Demand": 30743,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 8,
    "Net Demand": 29780,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 9,
    "Net Demand": 28058,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 10,
    "Net Demand": 28611,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 11,
    "Net Demand": 21818,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 12,
    "Net Demand": 24965,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 13,
    "Net Demand": 24504,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 14,
    "Net Demand": 22194,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 15,
    "Net Demand": 22005,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 16,
    "Net Demand": 23238,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 17,
    "Net Demand": 24388,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 18,
    "Net Demand": 26399,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 19,
    "Net Demand": 31636,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 20,
    "Net Demand": 31451,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 21,
    "Net Demand": 30591,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 22,
    "Net Demand": 30020,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 23,
    "Net Demand": 31279,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 30,
    Hour: 24,
    "Net Demand": 31187,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 1,
    "Net Demand": 26516,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 2,
    "Net Demand": 28421,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 3,
    "Net Demand": 29801,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 4,
    "Net Demand": 30118,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 5,
    "Net Demand": 29920,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 6,
    "Net Demand": 31014,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 7,
    "Net Demand": 30743,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 8,
    "Net Demand": 29780,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 9,
    "Net Demand": 28058,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 10,
    "Net Demand": 28611,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 11,
    "Net Demand": 21818,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 12,
    "Net Demand": 24965,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 13,
    "Net Demand": 24504,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 14,
    "Net Demand": 22194,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 15,
    "Net Demand": 22005,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 16,
    "Net Demand": 23238,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 17,
    "Net Demand": 24388,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 18,
    "Net Demand": 26399,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 19,
    "Net Demand": 31636,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 20,
    "Net Demand": 31451,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 21,
    "Net Demand": 30591,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 22,
    "Net Demand": 30020,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 23,
    "Net Demand": 31279,
  },
  {
    Year: 2023,
    Month: 12,
    Day: 31,
    Hour: 24,
    "Net Demand": 31187,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 1,
    "Net Demand": 27164,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 2,
    "Net Demand": 27032,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 3,
    "Net Demand": 26800,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 4,
    "Net Demand": 28253,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 5,
    "Net Demand": 27267,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 6,
    "Net Demand": 27555,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 7,
    "Net Demand": 27007,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 8,
    "Net Demand": 26409,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 9,
    "Net Demand": 24765,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 10,
    "Net Demand": 19889,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 11,
    "Net Demand": 17032,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 12,
    "Net Demand": 20865,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 13,
    "Net Demand": 21372,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 14,
    "Net Demand": 17818,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 15,
    "Net Demand": 18762,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 16,
    "Net Demand": 19980,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 17,
    "Net Demand": 22987,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 18,
    "Net Demand": 23967,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 19,
    "Net Demand": 23477,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 20,
    "Net Demand": 23633,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 21,
    "Net Demand": 23133,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 22,
    "Net Demand": 23940,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 23,
    "Net Demand": 23307,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 1,
    Hour: 24,
    "Net Demand": 23734,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 1,
    "Net Demand": 21623,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 2,
    "Net Demand": 17290,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 3,
    "Net Demand": 15533,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 4,
    "Net Demand": 16140,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 5,
    "Net Demand": 16077,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 6,
    "Net Demand": 16899,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 7,
    "Net Demand": 13994,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 8,
    "Net Demand": 12922,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 9,
    "Net Demand": 14535,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 10,
    "Net Demand": 14111,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 11,
    "Net Demand": 13711,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 12,
    "Net Demand": 13555,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 13,
    "Net Demand": 14304,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 14,
    "Net Demand": 13533,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 15,
    "Net Demand": 9467,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 16,
    "Net Demand": 9386,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 17,
    "Net Demand": 9749,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 18,
    "Net Demand": 10136,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 19,
    "Net Demand": 9944,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 20,
    "Net Demand": 10651,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 21,
    "Net Demand": 10200,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 22,
    "Net Demand": 9540,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 23,
    "Net Demand": 9954,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 2,
    Hour: 24,
    "Net Demand": 9598,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 1,
    "Net Demand": 8834,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 2,
    "Net Demand": 8878,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 3,
    "Net Demand": 8767,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 4,
    "Net Demand": 8791,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 5,
    "Net Demand": 8528,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 6,
    "Net Demand": 8480,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 7,
    "Net Demand": 7910,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 8,
    "Net Demand": 7467,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 9,
    "Net Demand": 7147,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 10,
    "Net Demand": 6815,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 11,
    "Net Demand": 5629,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 12,
    "Net Demand": 6430,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 13,
    "Net Demand": 5452,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 14,
    "Net Demand": 5049,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 15,
    "Net Demand": 6106,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 16,
    "Net Demand": 6161,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 17,
    "Net Demand": 7816,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 18,
    "Net Demand": 7745,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 19,
    "Net Demand": 7919,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 20,
    "Net Demand": 8830,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 21,
    "Net Demand": 8787,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 22,
    "Net Demand": 8805,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 23,
    "Net Demand": 9138,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 3,
    Hour: 24,
    "Net Demand": 11036,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 1,
    "Net Demand": 15376,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 2,
    "Net Demand": 13241,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 3,
    "Net Demand": 15304,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 4,
    "Net Demand": 15631,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 5,
    "Net Demand": 15547,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 6,
    "Net Demand": 15511,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 7,
    "Net Demand": 15985,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 8,
    "Net Demand": 16398,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 9,
    "Net Demand": 17390,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 10,
    "Net Demand": 15409,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 11,
    "Net Demand": 6779,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 12,
    "Net Demand": 6738,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 13,
    "Net Demand": 7770,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 14,
    "Net Demand": 10215,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 15,
    "Net Demand": 14087,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 16,
    "Net Demand": 17317,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 17,
    "Net Demand": 19251,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 18,
    "Net Demand": 20194,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 19,
    "Net Demand": 21928,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 20,
    "Net Demand": 24124,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 21,
    "Net Demand": 26577,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 22,
    "Net Demand": 25325,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 23,
    "Net Demand": 25600,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 4,
    Hour: 24,
    "Net Demand": 25948,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 1,
    "Net Demand": 27265,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 2,
    "Net Demand": 26540,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 3,
    "Net Demand": 26735,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 4,
    "Net Demand": 27127,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 5,
    "Net Demand": 26534,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 6,
    "Net Demand": 20999,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 7,
    "Net Demand": 23985,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 8,
    "Net Demand": 25369,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 9,
    "Net Demand": 24821,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 10,
    "Net Demand": 24535,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 11,
    "Net Demand": 22558,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 12,
    "Net Demand": 23337,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 13,
    "Net Demand": 23839,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 14,
    "Net Demand": 23494,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 15,
    "Net Demand": 23487,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 16,
    "Net Demand": 24400,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 17,
    "Net Demand": 26829,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 18,
    "Net Demand": 27534,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 19,
    "Net Demand": 27705,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 20,
    "Net Demand": 28599,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 21,
    "Net Demand": 28192,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 22,
    "Net Demand": 28162,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 23,
    "Net Demand": 28586,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 5,
    Hour: 24,
    "Net Demand": 27388,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 1,
    "Net Demand": 27528,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 2,
    "Net Demand": 27383,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 3,
    "Net Demand": 26707,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 4,
    "Net Demand": 27340,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 5,
    "Net Demand": 26819,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 6,
    "Net Demand": 27398,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 7,
    "Net Demand": 27918,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 8,
    "Net Demand": 26677,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 9,
    "Net Demand": 25078,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 10,
    "Net Demand": 24182,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 11,
    "Net Demand": 20575,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 12,
    "Net Demand": 22373,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 13,
    "Net Demand": 21776,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 14,
    "Net Demand": 22867,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 15,
    "Net Demand": 23326,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 16,
    "Net Demand": 24356,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 17,
    "Net Demand": 25856,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 18,
    "Net Demand": 25257,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 19,
    "Net Demand": 24827,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 20,
    "Net Demand": 28317,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 21,
    "Net Demand": 28656,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 22,
    "Net Demand": 28232,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 23,
    "Net Demand": 28049,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 6,
    Hour: 24,
    "Net Demand": 27704,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 1,
    "Net Demand": 26685,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 2,
    "Net Demand": 26133,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 3,
    "Net Demand": 25466,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 4,
    "Net Demand": 27156,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 5,
    "Net Demand": 27766,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 6,
    "Net Demand": 26784,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 7,
    "Net Demand": 25593,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 8,
    "Net Demand": 24905,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 9,
    "Net Demand": 25407,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 10,
    "Net Demand": 25742,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 11,
    "Net Demand": 25806,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 12,
    "Net Demand": 25281,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 13,
    "Net Demand": 24126,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 14,
    "Net Demand": 23507,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 15,
    "Net Demand": 23819,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 16,
    "Net Demand": 23622,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 17,
    "Net Demand": 26726,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 18,
    "Net Demand": 27606,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 19,
    "Net Demand": 26802,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 20,
    "Net Demand": 27749,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 21,
    "Net Demand": 27385,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 22,
    "Net Demand": 27705,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 23,
    "Net Demand": 26426,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 7,
    Hour: 24,
    "Net Demand": 26811,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 1,
    "Net Demand": 26270,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 2,
    "Net Demand": 26395,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 3,
    "Net Demand": 25449,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 4,
    "Net Demand": 27565,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 5,
    "Net Demand": 27365,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 6,
    "Net Demand": 26680,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 7,
    "Net Demand": 25940,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 8,
    "Net Demand": 25430,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 9,
    "Net Demand": 24768,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 10,
    "Net Demand": 23625,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 11,
    "Net Demand": 21923,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 12,
    "Net Demand": 23202,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 13,
    "Net Demand": 24002,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 14,
    "Net Demand": 24352,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 15,
    "Net Demand": 24099,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 16,
    "Net Demand": 25178,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 17,
    "Net Demand": 22310,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 18,
    "Net Demand": 22093,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 19,
    "Net Demand": 27805,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 20,
    "Net Demand": 28787,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 21,
    "Net Demand": 28124,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 22,
    "Net Demand": 27207,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 23,
    "Net Demand": 26229,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 8,
    Hour: 24,
    "Net Demand": 26392,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 1,
    "Net Demand": 26653,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 2,
    "Net Demand": 26147,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 3,
    "Net Demand": 28686,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 4,
    "Net Demand": 28620,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 5,
    "Net Demand": 27665,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 6,
    "Net Demand": 27305,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 7,
    "Net Demand": 27468,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 8,
    "Net Demand": 27260,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 9,
    "Net Demand": 25767,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 10,
    "Net Demand": 21901,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 11,
    "Net Demand": 19025,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 12,
    "Net Demand": 20754,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 13,
    "Net Demand": 20581,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 14,
    "Net Demand": 19221,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 15,
    "Net Demand": 20039,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 16,
    "Net Demand": 21637,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 17,
    "Net Demand": 23769,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 18,
    "Net Demand": 22815,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 19,
    "Net Demand": 22839,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 20,
    "Net Demand": 23419,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 21,
    "Net Demand": 22853,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 22,
    "Net Demand": 23876,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 23,
    "Net Demand": 22521,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 9,
    Hour: 24,
    "Net Demand": 22514,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 1,
    "Net Demand": 22410,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 2,
    "Net Demand": 21522,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 3,
    "Net Demand": 21108,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 4,
    "Net Demand": 21725,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 5,
    "Net Demand": 22231,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 6,
    "Net Demand": 20417,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 7,
    "Net Demand": 21330,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 8,
    "Net Demand": 20374,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 9,
    "Net Demand": 20127,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 10,
    "Net Demand": 18360,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 11,
    "Net Demand": 17933,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 12,
    "Net Demand": 18071,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 13,
    "Net Demand": 17998,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 14,
    "Net Demand": 18867,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 15,
    "Net Demand": 19234,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 16,
    "Net Demand": 21051,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 17,
    "Net Demand": 22044,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 18,
    "Net Demand": 23156,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 19,
    "Net Demand": 23600,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 20,
    "Net Demand": 23236,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 21,
    "Net Demand": 22289,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 22,
    "Net Demand": 22269,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 23,
    "Net Demand": 23165,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 10,
    Hour: 24,
    "Net Demand": 22658,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 1,
    "Net Demand": 22484,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 2,
    "Net Demand": 22333,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 3,
    "Net Demand": 22084,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 4,
    "Net Demand": 23167,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 5,
    "Net Demand": 23136,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 6,
    "Net Demand": 22199,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 7,
    "Net Demand": 22473,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 8,
    "Net Demand": 22033,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 9,
    "Net Demand": 20874,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 10,
    "Net Demand": 20731,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 11,
    "Net Demand": 18281,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 12,
    "Net Demand": 20034,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 13,
    "Net Demand": 22598,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 14,
    "Net Demand": 21579,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 15,
    "Net Demand": 21169,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 16,
    "Net Demand": 23206,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 17,
    "Net Demand": 23995,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 18,
    "Net Demand": 22987,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 19,
    "Net Demand": 21907,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 20,
    "Net Demand": 22661,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 21,
    "Net Demand": 22405,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 22,
    "Net Demand": 23161,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 23,
    "Net Demand": 26629,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 11,
    Hour: 24,
    "Net Demand": 26844,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 1,
    "Net Demand": 25901,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 2,
    "Net Demand": 26054,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 3,
    "Net Demand": 25460,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 4,
    "Net Demand": 25598,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 5,
    "Net Demand": 26167,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 6,
    "Net Demand": 26158,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 7,
    "Net Demand": 26479,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 8,
    "Net Demand": 25768,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 9,
    "Net Demand": 23355,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 10,
    "Net Demand": 22164,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 11,
    "Net Demand": 21756,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 12,
    "Net Demand": 21994,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 13,
    "Net Demand": 22243,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 14,
    "Net Demand": 22503,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 15,
    "Net Demand": 20759,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 16,
    "Net Demand": 15455,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 17,
    "Net Demand": 15514,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 18,
    "Net Demand": 16746,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 19,
    "Net Demand": 16786,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 20,
    "Net Demand": 19729,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 21,
    "Net Demand": 25807,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 22,
    "Net Demand": 27474,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 23,
    "Net Demand": 27434,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 12,
    Hour: 24,
    "Net Demand": 27023,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 1,
    "Net Demand": 26460,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 2,
    "Net Demand": 25457,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 3,
    "Net Demand": 25077,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 4,
    "Net Demand": 24901,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 5,
    "Net Demand": 25818,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 6,
    "Net Demand": 25272,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 7,
    "Net Demand": 25545,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 8,
    "Net Demand": 24356,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 9,
    "Net Demand": 23798,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 10,
    "Net Demand": 22754,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 11,
    "Net Demand": 22019,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 12,
    "Net Demand": 22354,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 13,
    "Net Demand": 22784,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 14,
    "Net Demand": 23371,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 15,
    "Net Demand": 24685,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 16,
    "Net Demand": 25864,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 17,
    "Net Demand": 26100,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 18,
    "Net Demand": 26136,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 19,
    "Net Demand": 25899,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 20,
    "Net Demand": 26931,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 21,
    "Net Demand": 24680,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 22,
    "Net Demand": 22541,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 23,
    "Net Demand": 25128,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 13,
    Hour: 24,
    "Net Demand": 28330,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 1,
    "Net Demand": 27951,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 2,
    "Net Demand": 27065,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 3,
    "Net Demand": 25789,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 4,
    "Net Demand": 26407,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 5,
    "Net Demand": 26057,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 6,
    "Net Demand": 26427,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 7,
    "Net Demand": 25854,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 8,
    "Net Demand": 25213,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 9,
    "Net Demand": 25531,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 10,
    "Net Demand": 24659,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 11,
    "Net Demand": 23405,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 12,
    "Net Demand": 23897,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 13,
    "Net Demand": 24582,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 14,
    "Net Demand": 23494,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 15,
    "Net Demand": 21985,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 16,
    "Net Demand": 24653,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 17,
    "Net Demand": 26090,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 18,
    "Net Demand": 26447,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 19,
    "Net Demand": 26008,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 20,
    "Net Demand": 26919,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 21,
    "Net Demand": 27802,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 22,
    "Net Demand": 26172,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 23,
    "Net Demand": 25863,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 14,
    Hour: 24,
    "Net Demand": 25265,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 1,
    "Net Demand": 26154,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 2,
    "Net Demand": 25508,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 3,
    "Net Demand": 24972,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 4,
    "Net Demand": 25736,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 5,
    "Net Demand": 26079,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 6,
    "Net Demand": 25207,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 7,
    "Net Demand": 24417,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 8,
    "Net Demand": 24812,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 9,
    "Net Demand": 23805,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 10,
    "Net Demand": 14698,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 11,
    "Net Demand": 11449,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 12,
    "Net Demand": 11855,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 13,
    "Net Demand": 12023,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 14,
    "Net Demand": 12086,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 15,
    "Net Demand": 12580,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 16,
    "Net Demand": 13083,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 17,
    "Net Demand": 14102,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 18,
    "Net Demand": 14607,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 19,
    "Net Demand": 15074,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 20,
    "Net Demand": 15183,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 21,
    "Net Demand": 17216,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 22,
    "Net Demand": 21925,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 23,
    "Net Demand": 24763,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 15,
    Hour: 24,
    "Net Demand": 25719,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 1,
    "Net Demand": 25263,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 2,
    "Net Demand": 25480,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 3,
    "Net Demand": 24813,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 4,
    "Net Demand": 24324,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 5,
    "Net Demand": 23890,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 6,
    "Net Demand": 24732,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 7,
    "Net Demand": 25160,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 8,
    "Net Demand": 24424,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 9,
    "Net Demand": 22527,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 10,
    "Net Demand": 22108,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 11,
    "Net Demand": 22029,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 12,
    "Net Demand": 21739,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 13,
    "Net Demand": 21514,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 14,
    "Net Demand": 22603,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 15,
    "Net Demand": 17957,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 16,
    "Net Demand": 16733,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 17,
    "Net Demand": 17576,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 18,
    "Net Demand": 18345,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 19,
    "Net Demand": 18173,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 20,
    "Net Demand": 19125,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 21,
    "Net Demand": 19013,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 22,
    "Net Demand": 19288,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 23,
    "Net Demand": 19864,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 16,
    Hour: 24,
    "Net Demand": 22496,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 1,
    "Net Demand": 26931,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 2,
    "Net Demand": 27002,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 3,
    "Net Demand": 26393,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 4,
    "Net Demand": 27193,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 5,
    "Net Demand": 26244,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 6,
    "Net Demand": 26159,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 7,
    "Net Demand": 25854,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 8,
    "Net Demand": 25479,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 9,
    "Net Demand": 24728,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 10,
    "Net Demand": 18152,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 11,
    "Net Demand": 16175,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 12,
    "Net Demand": 16149,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 13,
    "Net Demand": 15818,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 14,
    "Net Demand": 16148,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 15,
    "Net Demand": 16648,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 16,
    "Net Demand": 17444,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 17,
    "Net Demand": 17128,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 18,
    "Net Demand": 13155,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 19,
    "Net Demand": 12258,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 20,
    "Net Demand": 15858,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 21,
    "Net Demand": 15172,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 22,
    "Net Demand": 15557,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 23,
    "Net Demand": 18661,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 17,
    Hour: 24,
    "Net Demand": 19714,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 1,
    "Net Demand": 18542,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 2,
    "Net Demand": 18812,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 3,
    "Net Demand": 18732,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 4,
    "Net Demand": 18091,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 5,
    "Net Demand": 18768,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 6,
    "Net Demand": 18803,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 7,
    "Net Demand": 18078,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 8,
    "Net Demand": 17283,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 9,
    "Net Demand": 16865,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 10,
    "Net Demand": 16168,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 11,
    "Net Demand": 14919,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 12,
    "Net Demand": 14540,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 13,
    "Net Demand": 14188,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 14,
    "Net Demand": 15192,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 15,
    "Net Demand": 16002,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 16,
    "Net Demand": 16316,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 17,
    "Net Demand": 17734,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 18,
    "Net Demand": 18613,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 19,
    "Net Demand": 19174,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 20,
    "Net Demand": 20191,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 21,
    "Net Demand": 23028,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 22,
    "Net Demand": 26266,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 23,
    "Net Demand": 26562,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 18,
    Hour: 24,
    "Net Demand": 27270,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 1,
    "Net Demand": 23377,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 2,
    "Net Demand": 22429,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 3,
    "Net Demand": 21240,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 4,
    "Net Demand": 22031,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 5,
    "Net Demand": 21922,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 6,
    "Net Demand": 21005,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 7,
    "Net Demand": 19802,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 8,
    "Net Demand": 19788,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 9,
    "Net Demand": 19011,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 10,
    "Net Demand": 17770,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 11,
    "Net Demand": 16657,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 12,
    "Net Demand": 16897,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 13,
    "Net Demand": 15189,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 14,
    "Net Demand": 12771,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 15,
    "Net Demand": 12743,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 16,
    "Net Demand": 13764,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 17,
    "Net Demand": 18862,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 18,
    "Net Demand": 21475,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 19,
    "Net Demand": 21968,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 20,
    "Net Demand": 22772,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 21,
    "Net Demand": 22027,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 22,
    "Net Demand": 21517,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 23,
    "Net Demand": 21236,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 19,
    Hour: 24,
    "Net Demand": 22013,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 1,
    "Net Demand": 23177,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 2,
    "Net Demand": 23200,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 3,
    "Net Demand": 23866,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 4,
    "Net Demand": 25359,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 5,
    "Net Demand": 24520,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 6,
    "Net Demand": 21529,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 7,
    "Net Demand": 17180,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 8,
    "Net Demand": 18272,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 9,
    "Net Demand": 16951,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 10,
    "Net Demand": 15609,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 11,
    "Net Demand": 17041,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 12,
    "Net Demand": 15770,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 13,
    "Net Demand": 17346,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 14,
    "Net Demand": 16476,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 15,
    "Net Demand": 17057,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 16,
    "Net Demand": 17651,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 17,
    "Net Demand": 19044,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 18,
    "Net Demand": 20454,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 19,
    "Net Demand": 20347,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 20,
    "Net Demand": 20861,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 21,
    "Net Demand": 20594,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 22,
    "Net Demand": 20653,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 23,
    "Net Demand": 20568,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 20,
    Hour: 24,
    "Net Demand": 21218,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 1,
    "Net Demand": 26437,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 2,
    "Net Demand": 26474,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 3,
    "Net Demand": 26219,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 4,
    "Net Demand": 26922,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 5,
    "Net Demand": 27155,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 6,
    "Net Demand": 20388,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 7,
    "Net Demand": 22414,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 8,
    "Net Demand": 24909,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 9,
    "Net Demand": 23981,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 10,
    "Net Demand": 24731,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 11,
    "Net Demand": 17858,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 12,
    "Net Demand": 22728,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 13,
    "Net Demand": 22600,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 14,
    "Net Demand": 23205,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 15,
    "Net Demand": 22154,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 16,
    "Net Demand": 20164,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 17,
    "Net Demand": 20905,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 18,
    "Net Demand": 23467,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 19,
    "Net Demand": 25855,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 20,
    "Net Demand": 24102,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 21,
    "Net Demand": 23584,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 22,
    "Net Demand": 26626,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 23,
    "Net Demand": 19902,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 21,
    Hour: 24,
    "Net Demand": 27780,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 1,
    "Net Demand": 27651,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 2,
    "Net Demand": 27717,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 3,
    "Net Demand": 27028,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 4,
    "Net Demand": 27116,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 5,
    "Net Demand": 27310,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 6,
    "Net Demand": 24378,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 7,
    "Net Demand": 27218,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 8,
    "Net Demand": 23982,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 9,
    "Net Demand": 18059,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 10,
    "Net Demand": 16310,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 11,
    "Net Demand": 16950,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 12,
    "Net Demand": 17462,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 13,
    "Net Demand": 16971,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 14,
    "Net Demand": 16735,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 15,
    "Net Demand": 16773,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 16,
    "Net Demand": 17918,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 17,
    "Net Demand": 19457,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 18,
    "Net Demand": 18645,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 19,
    "Net Demand": 18792,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 20,
    "Net Demand": 20360,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 21,
    "Net Demand": 19925,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 22,
    "Net Demand": 19553,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 23,
    "Net Demand": 19879,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 22,
    Hour: 24,
    "Net Demand": 20160,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 1,
    "Net Demand": 19857,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 2,
    "Net Demand": 19734,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 3,
    "Net Demand": 18884,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 4,
    "Net Demand": 19095,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 5,
    "Net Demand": 18799,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 6,
    "Net Demand": 19234,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 7,
    "Net Demand": 19514,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 8,
    "Net Demand": 19228,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 9,
    "Net Demand": 17701,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 10,
    "Net Demand": 17273,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 11,
    "Net Demand": 17398,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 12,
    "Net Demand": 16794,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 13,
    "Net Demand": 15980,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 14,
    "Net Demand": 16217,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 15,
    "Net Demand": 16542,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 16,
    "Net Demand": 17563,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 17,
    "Net Demand": 18367,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 18,
    "Net Demand": 19381,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 19,
    "Net Demand": 19716,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 20,
    "Net Demand": 20651,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 21,
    "Net Demand": 20830,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 22,
    "Net Demand": 19544,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 23,
    "Net Demand": 19604,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 23,
    Hour: 24,
    "Net Demand": 19628,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 1,
    "Net Demand": 19877,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 2,
    "Net Demand": 19898,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 3,
    "Net Demand": 19386,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 4,
    "Net Demand": 19624,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 5,
    "Net Demand": 19606,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 6,
    "Net Demand": 20173,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 7,
    "Net Demand": 25634,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 8,
    "Net Demand": 25829,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 9,
    "Net Demand": 24692,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 10,
    "Net Demand": 24215,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 11,
    "Net Demand": 23204,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 12,
    "Net Demand": 19590,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 13,
    "Net Demand": 24901,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 14,
    "Net Demand": 24047,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 15,
    "Net Demand": 23868,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 16,
    "Net Demand": 24825,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 17,
    "Net Demand": 25290,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 18,
    "Net Demand": 25771,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 19,
    "Net Demand": 26221,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 20,
    "Net Demand": 26796,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 21,
    "Net Demand": 26059,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 22,
    "Net Demand": 26174,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 23,
    "Net Demand": 25312,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 24,
    Hour: 24,
    "Net Demand": 25682,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 1,
    "Net Demand": 26021,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 2,
    "Net Demand": 26273,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 3,
    "Net Demand": 25618,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 4,
    "Net Demand": 26282,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 5,
    "Net Demand": 26193,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 6,
    "Net Demand": 26202,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 7,
    "Net Demand": 25559,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 8,
    "Net Demand": 26309,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 9,
    "Net Demand": 23488,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 10,
    "Net Demand": 20149,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 11,
    "Net Demand": 19206,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 12,
    "Net Demand": 19437,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 13,
    "Net Demand": 19978,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 14,
    "Net Demand": 19763,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 15,
    "Net Demand": 19726,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 16,
    "Net Demand": 19549,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 17,
    "Net Demand": 21049,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 18,
    "Net Demand": 22039,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 19,
    "Net Demand": 22135,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 20,
    "Net Demand": 22561,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 21,
    "Net Demand": 22662,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 22,
    "Net Demand": 22149,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 23,
    "Net Demand": 23086,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 25,
    Hour: 24,
    "Net Demand": 19424,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 1,
    "Net Demand": 22008,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 2,
    "Net Demand": 18394,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 3,
    "Net Demand": 18398,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 4,
    "Net Demand": 18863,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 5,
    "Net Demand": 19098,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 6,
    "Net Demand": 19262,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 7,
    "Net Demand": 19506,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 8,
    "Net Demand": 17522,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 9,
    "Net Demand": 17523,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 10,
    "Net Demand": 17975,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 11,
    "Net Demand": 17782,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 12,
    "Net Demand": 18500,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 13,
    "Net Demand": 18770,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 14,
    "Net Demand": 19080,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 15,
    "Net Demand": 19584,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 16,
    "Net Demand": 19259,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 17,
    "Net Demand": 21013,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 18,
    "Net Demand": 22981,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 19,
    "Net Demand": 23661,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 20,
    "Net Demand": 26698,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 21,
    "Net Demand": 26861,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 22,
    "Net Demand": 29327,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 23,
    "Net Demand": 26213,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 26,
    Hour: 24,
    "Net Demand": 22834,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 1,
    "Net Demand": 22610,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 2,
    "Net Demand": 20302,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 3,
    "Net Demand": 16803,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 4,
    "Net Demand": 22546,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 5,
    "Net Demand": 22831,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 6,
    "Net Demand": 22750,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 7,
    "Net Demand": 22120,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 8,
    "Net Demand": 21319,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 9,
    "Net Demand": 21499,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 10,
    "Net Demand": 22773,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 11,
    "Net Demand": 21565,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 12,
    "Net Demand": 21301,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 13,
    "Net Demand": 21483,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 14,
    "Net Demand": 21760,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 15,
    "Net Demand": 22791,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 16,
    "Net Demand": 23457,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 17,
    "Net Demand": 25247,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 18,
    "Net Demand": 25616,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 19,
    "Net Demand": 25059,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 20,
    "Net Demand": 26047,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 21,
    "Net Demand": 25594,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 22,
    "Net Demand": 25046,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 23,
    "Net Demand": 25771,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 27,
    Hour: 24,
    "Net Demand": 27096,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 1,
    "Net Demand": 26036,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 2,
    "Net Demand": 25845,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 3,
    "Net Demand": 26080,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 4,
    "Net Demand": 26888,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 5,
    "Net Demand": 26822,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 6,
    "Net Demand": 26667,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 7,
    "Net Demand": 24819,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 8,
    "Net Demand": 18810,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 9,
    "Net Demand": 15048,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 10,
    "Net Demand": 15473,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 11,
    "Net Demand": 17050,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 12,
    "Net Demand": 17937,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 13,
    "Net Demand": 18226,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 14,
    "Net Demand": 17461,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 15,
    "Net Demand": 17666,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 16,
    "Net Demand": 17852,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 17,
    "Net Demand": 19127,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 18,
    "Net Demand": 18205,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 19,
    "Net Demand": 19309,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 20,
    "Net Demand": 19458,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 21,
    "Net Demand": 19385,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 22,
    "Net Demand": 19601,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 23,
    "Net Demand": 17724,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 28,
    Hour: 24,
    "Net Demand": 19545,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 1,
    "Net Demand": 20414,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 2,
    "Net Demand": 19654,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 3,
    "Net Demand": 19017,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 4,
    "Net Demand": 19139,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 5,
    "Net Demand": 19413,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 6,
    "Net Demand": 20670,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 7,
    "Net Demand": 19473,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 8,
    "Net Demand": 15424,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 9,
    "Net Demand": 13914,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 10,
    "Net Demand": 12797,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 11,
    "Net Demand": 11557,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 12,
    "Net Demand": 12630,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 13,
    "Net Demand": 15727,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 14,
    "Net Demand": 16115,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 15,
    "Net Demand": 16718,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 16,
    "Net Demand": 18143,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 17,
    "Net Demand": 18288,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 18,
    "Net Demand": 19862,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 19,
    "Net Demand": 19770,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 20,
    "Net Demand": 20276,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 21,
    "Net Demand": 19498,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 22,
    "Net Demand": 17985,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 23,
    "Net Demand": 19459,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 29,
    Hour: 24,
    "Net Demand": 18983,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 1,
    "Net Demand": 22417,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 2,
    "Net Demand": 24028,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 3,
    "Net Demand": 25194,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 4,
    "Net Demand": 25462,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 5,
    "Net Demand": 25295,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 6,
    "Net Demand": 26219,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 7,
    "Net Demand": 25991,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 8,
    "Net Demand": 25177,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 9,
    "Net Demand": 23721,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 10,
    "Net Demand": 24188,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 11,
    "Net Demand": 18445,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 12,
    "Net Demand": 21106,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 13,
    "Net Demand": 20716,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 14,
    "Net Demand": 18763,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 15,
    "Net Demand": 18603,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 16,
    "Net Demand": 19646,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 17,
    "Net Demand": 20618,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 18,
    "Net Demand": 22318,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 19,
    "Net Demand": 26745,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 20,
    "Net Demand": 26589,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 21,
    "Net Demand": 25862,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 22,
    "Net Demand": 25380,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 23,
    "Net Demand": 26443,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 30,
    Hour: 24,
    "Net Demand": 26365,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 1,
    "Net Demand": 22417,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 2,
    "Net Demand": 24028,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 3,
    "Net Demand": 25194,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 4,
    "Net Demand": 25462,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 5,
    "Net Demand": 25295,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 6,
    "Net Demand": 26219,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 7,
    "Net Demand": 25991,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 8,
    "Net Demand": 25177,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 9,
    "Net Demand": 23721,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 10,
    "Net Demand": 24188,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 11,
    "Net Demand": 18445,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 12,
    "Net Demand": 21106,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 13,
    "Net Demand": 20716,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 14,
    "Net Demand": 18763,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 15,
    "Net Demand": 18603,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 16,
    "Net Demand": 19646,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 17,
    "Net Demand": 20618,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 18,
    "Net Demand": 22318,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 19,
    "Net Demand": 26745,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 20,
    "Net Demand": 26589,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 21,
    "Net Demand": 25862,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 22,
    "Net Demand": 25380,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 23,
    "Net Demand": 26443,
  },
  {
    Year: 2024,
    Month: 1,
    Day: 31,
    Hour: 24,
    "Net Demand": 26365,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 1,
    "Net Demand": 28849,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 2,
    "Net Demand": 28709,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 3,
    "Net Demand": 28462,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 4,
    "Net Demand": 30006,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 5,
    "Net Demand": 28959,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 6,
    "Net Demand": 29264,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 7,
    "Net Demand": 28682,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 8,
    "Net Demand": 28047,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 9,
    "Net Demand": 26301,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 10,
    "Net Demand": 21122,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 11,
    "Net Demand": 18088,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 12,
    "Net Demand": 22159,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 13,
    "Net Demand": 22697,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 14,
    "Net Demand": 18923,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 15,
    "Net Demand": 19926,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 16,
    "Net Demand": 21219,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 17,
    "Net Demand": 24413,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 18,
    "Net Demand": 25454,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 19,
    "Net Demand": 24934,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 20,
    "Net Demand": 25099,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 21,
    "Net Demand": 24568,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 22,
    "Net Demand": 25425,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 23,
    "Net Demand": 24753,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 1,
    Hour: 24,
    "Net Demand": 25206,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 1,
    "Net Demand": 22964,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 2,
    "Net Demand": 18363,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 3,
    "Net Demand": 16496,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 4,
    "Net Demand": 17141,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 5,
    "Net Demand": 17074,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 6,
    "Net Demand": 17947,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 7,
    "Net Demand": 14862,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 8,
    "Net Demand": 13723,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 9,
    "Net Demand": 15437,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 10,
    "Net Demand": 14987,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 11,
    "Net Demand": 14561,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 12,
    "Net Demand": 14395,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 13,
    "Net Demand": 15191,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 14,
    "Net Demand": 14372,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 15,
    "Net Demand": 10055,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 16,
    "Net Demand": 9968,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 17,
    "Net Demand": 10354,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 18,
    "Net Demand": 10765,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 19,
    "Net Demand": 10560,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 20,
    "Net Demand": 11311,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 21,
    "Net Demand": 10832,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 22,
    "Net Demand": 10132,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 23,
    "Net Demand": 10571,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 2,
    Hour: 24,
    "Net Demand": 10193,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 1,
    "Net Demand": 9382,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 2,
    "Net Demand": 9429,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 3,
    "Net Demand": 9311,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 4,
    "Net Demand": 9337,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 5,
    "Net Demand": 9057,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 6,
    "Net Demand": 9006,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 7,
    "Net Demand": 8401,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 8,
    "Net Demand": 7931,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 9,
    "Net Demand": 7590,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 10,
    "Net Demand": 7238,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 11,
    "Net Demand": 5978,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 12,
    "Net Demand": 6829,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 13,
    "Net Demand": 5790,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 14,
    "Net Demand": 5362,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 15,
    "Net Demand": 6485,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 16,
    "Net Demand": 6543,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 17,
    "Net Demand": 8301,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 18,
    "Net Demand": 8225,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 19,
    "Net Demand": 8411,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 20,
    "Net Demand": 9377,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 21,
    "Net Demand": 9332,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 22,
    "Net Demand": 9351,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 23,
    "Net Demand": 9705,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 3,
    Hour: 24,
    "Net Demand": 11721,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 1,
    "Net Demand": 16330,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 2,
    "Net Demand": 14062,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 3,
    "Net Demand": 16253,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 4,
    "Net Demand": 16600,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 5,
    "Net Demand": 16512,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 6,
    "Net Demand": 16473,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 7,
    "Net Demand": 16976,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 8,
    "Net Demand": 17415,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 9,
    "Net Demand": 18469,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 10,
    "Net Demand": 16365,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 11,
    "Net Demand": 7200,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 12,
    "Net Demand": 7156,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 13,
    "Net Demand": 8252,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 14,
    "Net Demand": 10849,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 15,
    "Net Demand": 14961,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 16,
    "Net Demand": 18391,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 17,
    "Net Demand": 20445,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 18,
    "Net Demand": 21446,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 19,
    "Net Demand": 23288,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 20,
    "Net Demand": 25620,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 21,
    "Net Demand": 28225,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 22,
    "Net Demand": 26895,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 23,
    "Net Demand": 27188,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 4,
    Hour: 24,
    "Net Demand": 27557,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 1,
    "Net Demand": 28956,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 2,
    "Net Demand": 28186,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 3,
    "Net Demand": 28394,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 4,
    "Net Demand": 28809,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 5,
    "Net Demand": 28179,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 6,
    "Net Demand": 22302,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 7,
    "Net Demand": 25473,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 8,
    "Net Demand": 26943,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 9,
    "Net Demand": 26360,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 10,
    "Net Demand": 26057,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 11,
    "Net Demand": 23957,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 12,
    "Net Demand": 24785,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 13,
    "Net Demand": 25318,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 14,
    "Net Demand": 24952,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 15,
    "Net Demand": 24944,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 16,
    "Net Demand": 25914,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 17,
    "Net Demand": 28494,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 18,
    "Net Demand": 29242,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 19,
    "Net Demand": 29423,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 20,
    "Net Demand": 30373,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 21,
    "Net Demand": 29941,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 22,
    "Net Demand": 29909,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 23,
    "Net Demand": 30359,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 5,
    Hour: 24,
    "Net Demand": 29086,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 1,
    "Net Demand": 29236,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 2,
    "Net Demand": 29081,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 3,
    "Net Demand": 28364,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 4,
    "Net Demand": 29035,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 5,
    "Net Demand": 28483,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 6,
    "Net Demand": 29098,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 7,
    "Net Demand": 29649,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 8,
    "Net Demand": 28331,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 9,
    "Net Demand": 26633,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 10,
    "Net Demand": 25682,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 11,
    "Net Demand": 21851,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 12,
    "Net Demand": 23760,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 13,
    "Net Demand": 23127,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 14,
    "Net Demand": 24285,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 15,
    "Net Demand": 24773,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 16,
    "Net Demand": 25867,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 17,
    "Net Demand": 27460,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 18,
    "Net Demand": 26824,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 19,
    "Net Demand": 26367,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 20,
    "Net Demand": 30073,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 21,
    "Net Demand": 30434,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 22,
    "Net Demand": 29983,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 23,
    "Net Demand": 29789,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 6,
    Hour: 24,
    "Net Demand": 29423,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 1,
    "Net Demand": 28340,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 2,
    "Net Demand": 27754,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 3,
    "Net Demand": 27046,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 4,
    "Net Demand": 28841,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 5,
    "Net Demand": 29489,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 6,
    "Net Demand": 28445,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 7,
    "Net Demand": 27181,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 8,
    "Net Demand": 26449,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 9,
    "Net Demand": 26983,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 10,
    "Net Demand": 27339,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 11,
    "Net Demand": 27407,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 12,
    "Net Demand": 26849,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 13,
    "Net Demand": 25623,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 14,
    "Net Demand": 24965,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 15,
    "Net Demand": 25297,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 16,
    "Net Demand": 25087,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 17,
    "Net Demand": 28384,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 18,
    "Net Demand": 29318,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 19,
    "Net Demand": 28464,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 20,
    "Net Demand": 29470,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 21,
    "Net Demand": 29083,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 22,
    "Net Demand": 29423,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 23,
    "Net Demand": 28066,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 7,
    Hour: 24,
    "Net Demand": 28474,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 1,
    "Net Demand": 27899,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 2,
    "Net Demand": 28033,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 3,
    "Net Demand": 27027,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 4,
    "Net Demand": 29275,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 5,
    "Net Demand": 29062,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 6,
    "Net Demand": 28335,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 7,
    "Net Demand": 27549,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 8,
    "Net Demand": 27007,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 9,
    "Net Demand": 26304,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 10,
    "Net Demand": 25090,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 11,
    "Net Demand": 23283,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 12,
    "Net Demand": 24642,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 13,
    "Net Demand": 25491,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 14,
    "Net Demand": 25862,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 15,
    "Net Demand": 25594,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 16,
    "Net Demand": 26740,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 17,
    "Net Demand": 23693,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 18,
    "Net Demand": 23464,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 19,
    "Net Demand": 29530,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 20,
    "Net Demand": 30573,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 21,
    "Net Demand": 29869,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 22,
    "Net Demand": 28895,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 23,
    "Net Demand": 27856,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 8,
    Hour: 24,
    "Net Demand": 28029,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 1,
    "Net Demand": 28306,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 2,
    "Net Demand": 27769,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 3,
    "Net Demand": 30466,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 4,
    "Net Demand": 30396,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 5,
    "Net Demand": 29381,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 6,
    "Net Demand": 28999,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 7,
    "Net Demand": 29172,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 8,
    "Net Demand": 28951,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 9,
    "Net Demand": 27365,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 10,
    "Net Demand": 23260,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 11,
    "Net Demand": 20205,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 12,
    "Net Demand": 22041,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 13,
    "Net Demand": 21857,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 14,
    "Net Demand": 20413,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 15,
    "Net Demand": 21282,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 16,
    "Net Demand": 22979,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 17,
    "Net Demand": 25243,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 18,
    "Net Demand": 24231,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 19,
    "Net Demand": 24256,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 20,
    "Net Demand": 24871,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 21,
    "Net Demand": 24271,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 22,
    "Net Demand": 25358,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 23,
    "Net Demand": 23918,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 9,
    Hour: 24,
    "Net Demand": 23910,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 1,
    "Net Demand": 23800,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 2,
    "Net Demand": 22857,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 3,
    "Net Demand": 22417,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 4,
    "Net Demand": 23072,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 5,
    "Net Demand": 23610,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 6,
    "Net Demand": 21683,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 7,
    "Net Demand": 22653,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 8,
    "Net Demand": 21638,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 9,
    "Net Demand": 21375,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 10,
    "Net Demand": 19498,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 11,
    "Net Demand": 19046,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 12,
    "Net Demand": 19192,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 13,
    "Net Demand": 19114,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 14,
    "Net Demand": 20037,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 15,
    "Net Demand": 20427,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 16,
    "Net Demand": 22357,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 17,
    "Net Demand": 23412,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 18,
    "Net Demand": 24592,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 19,
    "Net Demand": 25064,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 20,
    "Net Demand": 24677,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 21,
    "Net Demand": 23672,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 22,
    "Net Demand": 23651,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 23,
    "Net Demand": 24602,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 10,
    Hour: 24,
    "Net Demand": 24063,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 1,
    "Net Demand": 23879,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 2,
    "Net Demand": 23718,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 3,
    "Net Demand": 23454,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 4,
    "Net Demand": 24604,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 5,
    "Net Demand": 24571,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 6,
    "Net Demand": 23576,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 7,
    "Net Demand": 23867,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 8,
    "Net Demand": 23399,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 9,
    "Net Demand": 22168,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 10,
    "Net Demand": 22017,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 11,
    "Net Demand": 19415,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 12,
    "Net Demand": 21277,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 13,
    "Net Demand": 24000,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 14,
    "Net Demand": 22917,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 15,
    "Net Demand": 22482,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 16,
    "Net Demand": 24645,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 17,
    "Net Demand": 25484,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 18,
    "Net Demand": 24413,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 19,
    "Net Demand": 23266,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 20,
    "Net Demand": 24067,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 21,
    "Net Demand": 23795,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 22,
    "Net Demand": 24598,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 23,
    "Net Demand": 28281,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 11,
    Hour: 24,
    "Net Demand": 28509,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 1,
    "Net Demand": 27508,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 2,
    "Net Demand": 27671,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 3,
    "Net Demand": 27039,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 4,
    "Net Demand": 27186,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 5,
    "Net Demand": 27790,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 6,
    "Net Demand": 27780,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 7,
    "Net Demand": 28121,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 8,
    "Net Demand": 27366,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 9,
    "Net Demand": 24804,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 10,
    "Net Demand": 23539,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 11,
    "Net Demand": 23105,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 12,
    "Net Demand": 23358,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 13,
    "Net Demand": 23623,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 14,
    "Net Demand": 23898,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 15,
    "Net Demand": 22046,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 16,
    "Net Demand": 16414,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 17,
    "Net Demand": 16477,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 18,
    "Net Demand": 17784,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 19,
    "Net Demand": 17827,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 20,
    "Net Demand": 20952,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 21,
    "Net Demand": 27408,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 22,
    "Net Demand": 29178,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 23,
    "Net Demand": 29136,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 12,
    Hour: 24,
    "Net Demand": 28699,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 1,
    "Net Demand": 28101,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 2,
    "Net Demand": 27037,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 3,
    "Net Demand": 26633,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 4,
    "Net Demand": 26446,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 5,
    "Net Demand": 27420,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 6,
    "Net Demand": 26839,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 7,
    "Net Demand": 27129,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 8,
    "Net Demand": 25866,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 9,
    "Net Demand": 25274,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 10,
    "Net Demand": 24166,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 11,
    "Net Demand": 23384,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 12,
    "Net Demand": 23740,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 13,
    "Net Demand": 24197,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 14,
    "Net Demand": 24821,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 15,
    "Net Demand": 26216,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 16,
    "Net Demand": 27468,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 17,
    "Net Demand": 27719,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 18,
    "Net Demand": 27757,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 19,
    "Net Demand": 27505,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 20,
    "Net Demand": 28601,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 21,
    "Net Demand": 26210,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 22,
    "Net Demand": 23939,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 23,
    "Net Demand": 26687,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 13,
    Hour: 24,
    "Net Demand": 30087,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 1,
    "Net Demand": 29685,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 2,
    "Net Demand": 28744,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 3,
    "Net Demand": 27389,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 4,
    "Net Demand": 28045,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 5,
    "Net Demand": 27673,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 6,
    "Net Demand": 28066,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 7,
    "Net Demand": 27458,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 8,
    "Net Demand": 26777,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 9,
    "Net Demand": 27115,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 10,
    "Net Demand": 26189,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 11,
    "Net Demand": 24857,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 12,
    "Net Demand": 25380,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 13,
    "Net Demand": 26107,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 14,
    "Net Demand": 24952,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 15,
    "Net Demand": 23349,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 16,
    "Net Demand": 26182,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 17,
    "Net Demand": 27709,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 18,
    "Net Demand": 28088,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 19,
    "Net Demand": 27621,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 20,
    "Net Demand": 28589,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 21,
    "Net Demand": 29527,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 22,
    "Net Demand": 27795,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 23,
    "Net Demand": 27467,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 14,
    Hour: 24,
    "Net Demand": 26833,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 1,
    "Net Demand": 27777,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 2,
    "Net Demand": 27090,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 3,
    "Net Demand": 26521,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 4,
    "Net Demand": 27332,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 5,
    "Net Demand": 27697,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 6,
    "Net Demand": 26771,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 7,
    "Net Demand": 25931,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 8,
    "Net Demand": 26351,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 9,
    "Net Demand": 25282,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 10,
    "Net Demand": 15609,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 11,
    "Net Demand": 12159,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 12,
    "Net Demand": 12590,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 13,
    "Net Demand": 12769,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 14,
    "Net Demand": 12835,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 15,
    "Net Demand": 13360,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 16,
    "Net Demand": 13894,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 17,
    "Net Demand": 14977,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 18,
    "Net Demand": 15513,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 19,
    "Net Demand": 16009,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 20,
    "Net Demand": 16125,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 21,
    "Net Demand": 18283,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 22,
    "Net Demand": 23285,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 23,
    "Net Demand": 26299,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 15,
    Hour: 24,
    "Net Demand": 27314,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 1,
    "Net Demand": 26830,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 2,
    "Net Demand": 27060,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 3,
    "Net Demand": 26352,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 4,
    "Net Demand": 25832,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 5,
    "Net Demand": 25371,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 6,
    "Net Demand": 26266,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 7,
    "Net Demand": 26720,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 8,
    "Net Demand": 25939,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 9,
    "Net Demand": 23924,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 10,
    "Net Demand": 23479,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 11,
    "Net Demand": 23395,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 12,
    "Net Demand": 23087,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 13,
    "Net Demand": 22848,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 14,
    "Net Demand": 24005,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 15,
    "Net Demand": 19071,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 16,
    "Net Demand": 17771,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 17,
    "Net Demand": 18667,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 18,
    "Net Demand": 19483,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 19,
    "Net Demand": 19300,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 20,
    "Net Demand": 20311,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 21,
    "Net Demand": 20192,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 22,
    "Net Demand": 20485,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 23,
    "Net Demand": 21096,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 16,
    Hour: 24,
    "Net Demand": 23891,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 1,
    "Net Demand": 28601,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 2,
    "Net Demand": 28677,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 3,
    "Net Demand": 28030,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 4,
    "Net Demand": 28880,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 5,
    "Net Demand": 27871,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 6,
    "Net Demand": 27782,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 7,
    "Net Demand": 27458,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 8,
    "Net Demand": 27060,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 9,
    "Net Demand": 26262,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 10,
    "Net Demand": 19278,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 11,
    "Net Demand": 17178,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 12,
    "Net Demand": 17151,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 13,
    "Net Demand": 16799,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 14,
    "Net Demand": 17150,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 15,
    "Net Demand": 17680,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 16,
    "Net Demand": 18526,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 17,
    "Net Demand": 18190,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 18,
    "Net Demand": 13972,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 19,
    "Net Demand": 13018,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 20,
    "Net Demand": 16842,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 21,
    "Net Demand": 16113,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 22,
    "Net Demand": 16522,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 23,
    "Net Demand": 19819,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 17,
    Hour: 24,
    "Net Demand": 20937,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 1,
    "Net Demand": 19692,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 2,
    "Net Demand": 19979,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 3,
    "Net Demand": 19894,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 4,
    "Net Demand": 19213,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 5,
    "Net Demand": 19933,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 6,
    "Net Demand": 19970,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 7,
    "Net Demand": 19199,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 8,
    "Net Demand": 18355,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 9,
    "Net Demand": 17911,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 10,
    "Net Demand": 17171,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 11,
    "Net Demand": 15845,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 12,
    "Net Demand": 15442,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 13,
    "Net Demand": 15068,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 14,
    "Net Demand": 16134,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 15,
    "Net Demand": 16995,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 16,
    "Net Demand": 17328,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 17,
    "Net Demand": 18834,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 18,
    "Net Demand": 19767,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 19,
    "Net Demand": 20363,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 20,
    "Net Demand": 21444,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 21,
    "Net Demand": 24457,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 22,
    "Net Demand": 27895,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 23,
    "Net Demand": 28209,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 18,
    Hour: 24,
    "Net Demand": 28962,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 1,
    "Net Demand": 24827,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 2,
    "Net Demand": 23820,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 3,
    "Net Demand": 22557,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 4,
    "Net Demand": 23398,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 5,
    "Net Demand": 23281,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 6,
    "Net Demand": 22308,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 7,
    "Net Demand": 21031,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 8,
    "Net Demand": 21016,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 9,
    "Net Demand": 20190,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 10,
    "Net Demand": 18873,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 11,
    "Net Demand": 17691,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 12,
    "Net Demand": 17945,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 13,
    "Net Demand": 16131,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 14,
    "Net Demand": 13563,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 15,
    "Net Demand": 13534,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 16,
    "Net Demand": 14618,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 17,
    "Net Demand": 20032,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 18,
    "Net Demand": 22807,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 19,
    "Net Demand": 23330,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 20,
    "Net Demand": 24185,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 21,
    "Net Demand": 23394,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 22,
    "Net Demand": 22852,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 23,
    "Net Demand": 22553,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 19,
    Hour: 24,
    "Net Demand": 23379,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 1,
    "Net Demand": 24615,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 2,
    "Net Demand": 24640,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 3,
    "Net Demand": 25346,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 4,
    "Net Demand": 26932,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 5,
    "Net Demand": 26041,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 6,
    "Net Demand": 22864,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 7,
    "Net Demand": 18245,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 8,
    "Net Demand": 19405,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 9,
    "Net Demand": 18002,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 10,
    "Net Demand": 16578,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 11,
    "Net Demand": 18098,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 12,
    "Net Demand": 16748,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 13,
    "Net Demand": 18422,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 14,
    "Net Demand": 17498,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 15,
    "Net Demand": 18115,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 16,
    "Net Demand": 18746,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 17,
    "Net Demand": 20225,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 18,
    "Net Demand": 21723,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 19,
    "Net Demand": 21609,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 20,
    "Net Demand": 22155,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 21,
    "Net Demand": 21871,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 22,
    "Net Demand": 21934,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 23,
    "Net Demand": 21844,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 20,
    Hour: 24,
    "Net Demand": 22535,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 1,
    "Net Demand": 28077,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 2,
    "Net Demand": 28116,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 3,
    "Net Demand": 27845,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 4,
    "Net Demand": 28592,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 5,
    "Net Demand": 28840,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 6,
    "Net Demand": 21653,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 7,
    "Net Demand": 23804,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 8,
    "Net Demand": 26454,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 9,
    "Net Demand": 25468,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 10,
    "Net Demand": 26265,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 11,
    "Net Demand": 18966,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 12,
    "Net Demand": 24138,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 13,
    "Net Demand": 24002,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 14,
    "Net Demand": 24644,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 15,
    "Net Demand": 23528,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 16,
    "Net Demand": 21415,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 17,
    "Net Demand": 22202,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 18,
    "Net Demand": 24922,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 19,
    "Net Demand": 27458,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 20,
    "Net Demand": 25597,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 21,
    "Net Demand": 25047,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 22,
    "Net Demand": 28278,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 23,
    "Net Demand": 21137,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 21,
    Hour: 24,
    "Net Demand": 29503,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 1,
    "Net Demand": 29366,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 2,
    "Net Demand": 29436,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 3,
    "Net Demand": 28705,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 4,
    "Net Demand": 28798,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 5,
    "Net Demand": 29004,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 6,
    "Net Demand": 25891,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 7,
    "Net Demand": 28907,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 8,
    "Net Demand": 25469,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 9,
    "Net Demand": 19179,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 10,
    "Net Demand": 17322,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 11,
    "Net Demand": 18002,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 12,
    "Net Demand": 18545,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 13,
    "Net Demand": 18023,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 14,
    "Net Demand": 17773,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 15,
    "Net Demand": 17813,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 16,
    "Net Demand": 19030,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 17,
    "Net Demand": 20664,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 18,
    "Net Demand": 19801,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 19,
    "Net Demand": 19958,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 20,
    "Net Demand": 21623,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 21,
    "Net Demand": 21161,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 22,
    "Net Demand": 20766,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 23,
    "Net Demand": 21112,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 22,
    Hour: 24,
    "Net Demand": 21411,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 1,
    "Net Demand": 21089,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 2,
    "Net Demand": 20958,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 3,
    "Net Demand": 20055,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 4,
    "Net Demand": 20279,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 5,
    "Net Demand": 19965,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 6,
    "Net Demand": 20427,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 7,
    "Net Demand": 20725,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 8,
    "Net Demand": 20421,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 9,
    "Net Demand": 18799,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 10,
    "Net Demand": 18344,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 11,
    "Net Demand": 18477,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 12,
    "Net Demand": 17836,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 13,
    "Net Demand": 16971,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 14,
    "Net Demand": 17223,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 15,
    "Net Demand": 17568,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 16,
    "Net Demand": 18653,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 17,
    "Net Demand": 19506,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 18,
    "Net Demand": 20583,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 19,
    "Net Demand": 20939,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 20,
    "Net Demand": 21931,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 21,
    "Net Demand": 22122,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 22,
    "Net Demand": 20756,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 23,
    "Net Demand": 20820,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 23,
    Hour: 24,
    "Net Demand": 20846,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 1,
    "Net Demand": 21110,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 2,
    "Net Demand": 21132,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 3,
    "Net Demand": 20588,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 4,
    "Net Demand": 20841,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 5,
    "Net Demand": 20822,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 6,
    "Net Demand": 21425,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 7,
    "Net Demand": 27224,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 8,
    "Net Demand": 27432,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 9,
    "Net Demand": 26224,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 10,
    "Net Demand": 25718,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 11,
    "Net Demand": 24643,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 12,
    "Net Demand": 20805,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 13,
    "Net Demand": 26446,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 14,
    "Net Demand": 25539,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 15,
    "Net Demand": 25349,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 16,
    "Net Demand": 26364,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 17,
    "Net Demand": 26859,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 18,
    "Net Demand": 27370,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 19,
    "Net Demand": 27847,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 20,
    "Net Demand": 28458,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 21,
    "Net Demand": 27675,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 22,
    "Net Demand": 27797,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 23,
    "Net Demand": 26882,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 24,
    Hour: 24,
    "Net Demand": 27275,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 1,
    "Net Demand": 27635,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 2,
    "Net Demand": 27902,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 3,
    "Net Demand": 27207,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 4,
    "Net Demand": 27912,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 5,
    "Net Demand": 27818,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 6,
    "Net Demand": 27828,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 7,
    "Net Demand": 27144,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 8,
    "Net Demand": 27941,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 9,
    "Net Demand": 24945,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 10,
    "Net Demand": 21399,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 11,
    "Net Demand": 20397,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 12,
    "Net Demand": 20643,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 13,
    "Net Demand": 21217,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 14,
    "Net Demand": 20988,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 15,
    "Net Demand": 20949,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 16,
    "Net Demand": 20762,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 17,
    "Net Demand": 22354,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 18,
    "Net Demand": 23406,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 19,
    "Net Demand": 23508,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 20,
    "Net Demand": 23961,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 21,
    "Net Demand": 24067,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 22,
    "Net Demand": 23523,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 23,
    "Net Demand": 24518,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 25,
    Hour: 24,
    "Net Demand": 20629,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 1,
    "Net Demand": 23373,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 2,
    "Net Demand": 19535,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 3,
    "Net Demand": 19539,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 4,
    "Net Demand": 20033,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 5,
    "Net Demand": 20283,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 6,
    "Net Demand": 20456,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 7,
    "Net Demand": 20716,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 8,
    "Net Demand": 18609,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 9,
    "Net Demand": 18609,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 10,
    "Net Demand": 19090,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 11,
    "Net Demand": 18885,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 12,
    "Net Demand": 19648,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 13,
    "Net Demand": 19934,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 14,
    "Net Demand": 20264,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 15,
    "Net Demand": 20799,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 16,
    "Net Demand": 20453,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 17,
    "Net Demand": 22317,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 18,
    "Net Demand": 24406,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 19,
    "Net Demand": 25128,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 20,
    "Net Demand": 28354,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 21,
    "Net Demand": 28527,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 22,
    "Net Demand": 31146,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 23,
    "Net Demand": 27839,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 26,
    Hour: 24,
    "Net Demand": 24251,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 1,
    "Net Demand": 24013,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 2,
    "Net Demand": 21562,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 3,
    "Net Demand": 17845,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 4,
    "Net Demand": 23945,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 5,
    "Net Demand": 24247,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 6,
    "Net Demand": 24161,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 7,
    "Net Demand": 23492,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 8,
    "Net Demand": 22642,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 9,
    "Net Demand": 22832,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 10,
    "Net Demand": 24185,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 11,
    "Net Demand": 22903,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 12,
    "Net Demand": 22622,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 13,
    "Net Demand": 22816,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 14,
    "Net Demand": 23109,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 15,
    "Net Demand": 24205,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 16,
    "Net Demand": 24912,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 17,
    "Net Demand": 26813,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 18,
    "Net Demand": 27204,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 19,
    "Net Demand": 26614,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 20,
    "Net Demand": 27663,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 21,
    "Net Demand": 27182,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 22,
    "Net Demand": 26599,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 23,
    "Net Demand": 27370,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 27,
    Hour: 24,
    "Net Demand": 28777,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 1,
    "Net Demand": 27651,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 2,
    "Net Demand": 27449,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 3,
    "Net Demand": 27697,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 4,
    "Net Demand": 28556,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 5,
    "Net Demand": 28485,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 6,
    "Net Demand": 28321,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 7,
    "Net Demand": 26359,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 8,
    "Net Demand": 19977,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 9,
    "Net Demand": 15982,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 10,
    "Net Demand": 16432,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 11,
    "Net Demand": 18107,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 12,
    "Net Demand": 19050,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 13,
    "Net Demand": 19357,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 14,
    "Net Demand": 18545,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 15,
    "Net Demand": 18762,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 16,
    "Net Demand": 18960,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 17,
    "Net Demand": 20313,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 18,
    "Net Demand": 19334,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 19,
    "Net Demand": 20507,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 20,
    "Net Demand": 20665,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 21,
    "Net Demand": 20588,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 22,
    "Net Demand": 20816,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 23,
    "Net Demand": 18823,
  },
  {
    Year: 2024,
    Month: 2,
    Day: 28,
    Hour: 24,
    "Net Demand": 20758,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 1,
    "Net Demand": 26213,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 2,
    "Net Demand": 25351,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 3,
    "Net Demand": 24719,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 4,
    "Net Demand": 25712,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 5,
    "Net Demand": 25634,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 6,
    "Net Demand": 25778,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 7,
    "Net Demand": 25503,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 8,
    "Net Demand": 24137,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 9,
    "Net Demand": 22187,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 10,
    "Net Demand": 21294,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 11,
    "Net Demand": 20811,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 12,
    "Net Demand": 21272,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 13,
    "Net Demand": 21874,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 14,
    "Net Demand": 20960,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 15,
    "Net Demand": 21609,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 16,
    "Net Demand": 22797,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 17,
    "Net Demand": 23002,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 18,
    "Net Demand": 23359,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 19,
    "Net Demand": 25569,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 20,
    "Net Demand": 27845,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 21,
    "Net Demand": 27989,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 22,
    "Net Demand": 26220,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 23,
    "Net Demand": 25241,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 1,
    Hour: 24,
    "Net Demand": 26671,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 1,
    "Net Demand": 25701,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 2,
    "Net Demand": 24926,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 3,
    "Net Demand": 24720,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 4,
    "Net Demand": 24954,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 5,
    "Net Demand": 26612,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 6,
    "Net Demand": 26314,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 7,
    "Net Demand": 25425,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 8,
    "Net Demand": 25480,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 9,
    "Net Demand": 24918,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 10,
    "Net Demand": 22917,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 11,
    "Net Demand": 21237,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 12,
    "Net Demand": 22139,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 13,
    "Net Demand": 22864,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 14,
    "Net Demand": 19487,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 15,
    "Net Demand": 19964,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 16,
    "Net Demand": 21927,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 17,
    "Net Demand": 23096,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 18,
    "Net Demand": 24152,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 19,
    "Net Demand": 24526,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 20,
    "Net Demand": 26489,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 21,
    "Net Demand": 26269,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 22,
    "Net Demand": 25808,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 23,
    "Net Demand": 25745,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 2,
    Hour: 24,
    "Net Demand": 26353,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 1,
    "Net Demand": 27645,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 2,
    "Net Demand": 27164,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 3,
    "Net Demand": 27099,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 4,
    "Net Demand": 27338,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 5,
    "Net Demand": 26896,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 6,
    "Net Demand": 26815,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 7,
    "Net Demand": 25777,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 8,
    "Net Demand": 24855,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 9,
    "Net Demand": 24388,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 10,
    "Net Demand": 21588,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 11,
    "Net Demand": 19700,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 12,
    "Net Demand": 20278,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 13,
    "Net Demand": 20580,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 14,
    "Net Demand": 20301,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 15,
    "Net Demand": 21087,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 16,
    "Net Demand": 22303,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 17,
    "Net Demand": 23294,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 18,
    "Net Demand": 24956,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 19,
    "Net Demand": 24898,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 20,
    "Net Demand": 23873,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 21,
    "Net Demand": 24109,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 22,
    "Net Demand": 24611,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 23,
    "Net Demand": 24488,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 3,
    Hour: 24,
    "Net Demand": 24748,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 1,
    "Net Demand": 25099,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 2,
    "Net Demand": 24045,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 3,
    "Net Demand": 24526,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 4,
    "Net Demand": 25900,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 5,
    "Net Demand": 26286,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 6,
    "Net Demand": 26461,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 7,
    "Net Demand": 25006,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 8,
    "Net Demand": 25738,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 9,
    "Net Demand": 24171,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 10,
    "Net Demand": 23115,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 11,
    "Net Demand": 20827,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 12,
    "Net Demand": 22362,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 13,
    "Net Demand": 17590,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 14,
    "Net Demand": 17116,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 15,
    "Net Demand": 21573,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 16,
    "Net Demand": 21490,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 17,
    "Net Demand": 23045,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 18,
    "Net Demand": 24822,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 19,
    "Net Demand": 23083,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 20,
    "Net Demand": 26052,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 21,
    "Net Demand": 25793,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 22,
    "Net Demand": 24919,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 23,
    "Net Demand": 25832,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 4,
    Hour: 24,
    "Net Demand": 26014,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 1,
    "Net Demand": 25332,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 2,
    "Net Demand": 23818,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 3,
    "Net Demand": 24432,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 4,
    "Net Demand": 24805,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 5,
    "Net Demand": 25137,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 6,
    "Net Demand": 24262,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 7,
    "Net Demand": 24232,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 8,
    "Net Demand": 24376,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 9,
    "Net Demand": 19295,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 10,
    "Net Demand": 14812,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 11,
    "Net Demand": 13308,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 12,
    "Net Demand": 14337,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 13,
    "Net Demand": 13990,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 14,
    "Net Demand": 13697,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 15,
    "Net Demand": 14733,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 16,
    "Net Demand": 15689,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 17,
    "Net Demand": 16786,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 18,
    "Net Demand": 18237,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 19,
    "Net Demand": 19465,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 20,
    "Net Demand": 23072,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 21,
    "Net Demand": 28221,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 22,
    "Net Demand": 29055,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 23,
    "Net Demand": 28941,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 5,
    Hour: 24,
    "Net Demand": 25761,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 1,
    "Net Demand": 25177,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 2,
    "Net Demand": 24946,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 3,
    "Net Demand": 24538,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 4,
    "Net Demand": 25826,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 5,
    "Net Demand": 26106,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 6,
    "Net Demand": 25858,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 7,
    "Net Demand": 22948,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 8,
    "Net Demand": 23721,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 9,
    "Net Demand": 23748,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 10,
    "Net Demand": 23052,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 11,
    "Net Demand": 21534,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 12,
    "Net Demand": 22573,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 13,
    "Net Demand": 22057,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 14,
    "Net Demand": 22437,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 15,
    "Net Demand": 19233,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 16,
    "Net Demand": 16892,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 17,
    "Net Demand": 14273,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 18,
    "Net Demand": 14961,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 19,
    "Net Demand": 15514,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 20,
    "Net Demand": 16787,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 21,
    "Net Demand": 18603,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 22,
    "Net Demand": 16739,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 23,
    "Net Demand": 14212,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 6,
    Hour: 24,
    "Net Demand": 13624,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 1,
    "Net Demand": 13457,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 2,
    "Net Demand": 19669,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 3,
    "Net Demand": 20204,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 4,
    "Net Demand": 21029,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 5,
    "Net Demand": 21444,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 6,
    "Net Demand": 21028,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 7,
    "Net Demand": 20337,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 8,
    "Net Demand": 22719,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 9,
    "Net Demand": 22063,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 10,
    "Net Demand": 21021,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 11,
    "Net Demand": 20331,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 12,
    "Net Demand": 21876,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 13,
    "Net Demand": 22530,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 14,
    "Net Demand": 20831,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 15,
    "Net Demand": 21794,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 16,
    "Net Demand": 24008,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 17,
    "Net Demand": 22573,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 18,
    "Net Demand": 24731,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 19,
    "Net Demand": 23970,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 20,
    "Net Demand": 26543,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 21,
    "Net Demand": 25724,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 22,
    "Net Demand": 25549,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 23,
    "Net Demand": 25922,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 7,
    Hour: 24,
    "Net Demand": 24912,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 1,
    "Net Demand": 22659,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 2,
    "Net Demand": 25810,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 3,
    "Net Demand": 27777,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 4,
    "Net Demand": 27703,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 5,
    "Net Demand": 25314,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 6,
    "Net Demand": 28498,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 7,
    "Net Demand": 26601,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 8,
    "Net Demand": 26813,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 9,
    "Net Demand": 23509,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 10,
    "Net Demand": 17595,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 11,
    "Net Demand": 14490,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 12,
    "Net Demand": 15431,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 13,
    "Net Demand": 14829,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 14,
    "Net Demand": 15423,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 15,
    "Net Demand": 16275,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 16,
    "Net Demand": 16898,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 17,
    "Net Demand": 17930,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 18,
    "Net Demand": 17961,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 19,
    "Net Demand": 18811,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 20,
    "Net Demand": 17575,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 21,
    "Net Demand": 12178,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 22,
    "Net Demand": 10235,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 23,
    "Net Demand": 10052,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 8,
    Hour: 24,
    "Net Demand": 9900,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 1,
    "Net Demand": 10735,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 2,
    "Net Demand": 11138,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 3,
    "Net Demand": 12128,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 4,
    "Net Demand": 16650,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 5,
    "Net Demand": 20576,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 6,
    "Net Demand": 20261,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 7,
    "Net Demand": 17621,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 8,
    "Net Demand": 18563,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 9,
    "Net Demand": 16979,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 10,
    "Net Demand": 9787,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 11,
    "Net Demand": 7204,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 12,
    "Net Demand": 7057,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 13,
    "Net Demand": 7435,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 14,
    "Net Demand": 6922,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 15,
    "Net Demand": 6800,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 16,
    "Net Demand": 8191,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 17,
    "Net Demand": 8963,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 18,
    "Net Demand": 10380,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 19,
    "Net Demand": 11168,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 20,
    "Net Demand": 13315,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 21,
    "Net Demand": 14512,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 22,
    "Net Demand": 20892,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 23,
    "Net Demand": 23237,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 9,
    Hour: 24,
    "Net Demand": 24451,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 1,
    "Net Demand": 24437,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 2,
    "Net Demand": 26828,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 3,
    "Net Demand": 28588,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 4,
    "Net Demand": 29221,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 5,
    "Net Demand": 29513,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 6,
    "Net Demand": 29361,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 7,
    "Net Demand": 28223,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 8,
    "Net Demand": 16951,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 9,
    "Net Demand": 17890,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 10,
    "Net Demand": 17393,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 11,
    "Net Demand": 16602,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 12,
    "Net Demand": 19525,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 13,
    "Net Demand": 20124,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 14,
    "Net Demand": 19820,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 15,
    "Net Demand": 20614,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 16,
    "Net Demand": 17569,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 17,
    "Net Demand": 17820,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 18,
    "Net Demand": 19074,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 19,
    "Net Demand": 18942,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 20,
    "Net Demand": 17366,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 21,
    "Net Demand": 20024,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 22,
    "Net Demand": 15218,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 23,
    "Net Demand": 15524,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 10,
    Hour: 24,
    "Net Demand": 20727,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 1,
    "Net Demand": 19526,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 2,
    "Net Demand": 19835,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 3,
    "Net Demand": 18697,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 4,
    "Net Demand": 20517,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 5,
    "Net Demand": 20077,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 6,
    "Net Demand": 21487,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 7,
    "Net Demand": 23390,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 8,
    "Net Demand": 24209,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 9,
    "Net Demand": 23879,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 10,
    "Net Demand": 22007,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 11,
    "Net Demand": 18116,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 12,
    "Net Demand": 14932,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 13,
    "Net Demand": 22423,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 14,
    "Net Demand": 24412,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 15,
    "Net Demand": 24850,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 16,
    "Net Demand": 25031,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 17,
    "Net Demand": 27560,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 18,
    "Net Demand": 27663,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 19,
    "Net Demand": 26604,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 20,
    "Net Demand": 24681,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 21,
    "Net Demand": 21849,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 22,
    "Net Demand": 19940,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 23,
    "Net Demand": 23788,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 11,
    Hour: 24,
    "Net Demand": 25923,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 1,
    "Net Demand": 30689,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 2,
    "Net Demand": 25674,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 3,
    "Net Demand": 26163,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 4,
    "Net Demand": 26367,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 5,
    "Net Demand": 25328,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 6,
    "Net Demand": 29168,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 7,
    "Net Demand": 29156,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 8,
    "Net Demand": 27897,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 9,
    "Net Demand": 27025,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 10,
    "Net Demand": 24831,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 11,
    "Net Demand": 24232,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 12,
    "Net Demand": 24115,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 13,
    "Net Demand": 25886,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 14,
    "Net Demand": 22872,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 15,
    "Net Demand": 23883,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 16,
    "Net Demand": 23815,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 17,
    "Net Demand": 26128,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 18,
    "Net Demand": 26930,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 19,
    "Net Demand": 27498,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 20,
    "Net Demand": 30689,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 21,
    "Net Demand": 33776,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 22,
    "Net Demand": 31574,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 23,
    "Net Demand": 27726,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 12,
    Hour: 24,
    "Net Demand": 27678,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 1,
    "Net Demand": 31828,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 2,
    "Net Demand": 32955,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 3,
    "Net Demand": 32704,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 4,
    "Net Demand": 28698,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 5,
    "Net Demand": 32741,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 6,
    "Net Demand": 33178,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 7,
    "Net Demand": 33725,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 8,
    "Net Demand": 32463,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 9,
    "Net Demand": 31681,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 10,
    "Net Demand": 26539,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 11,
    "Net Demand": 19231,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 12,
    "Net Demand": 23460,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 13,
    "Net Demand": 23681,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 14,
    "Net Demand": 22631,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 15,
    "Net Demand": 23908,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 16,
    "Net Demand": 25739,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 17,
    "Net Demand": 26558,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 18,
    "Net Demand": 27232,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 19,
    "Net Demand": 28052,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 20,
    "Net Demand": 29464,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 21,
    "Net Demand": 28234,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 22,
    "Net Demand": 27961,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 23,
    "Net Demand": 27369,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 13,
    Hour: 24,
    "Net Demand": 27989,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 1,
    "Net Demand": 23028,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 2,
    "Net Demand": 28019,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 3,
    "Net Demand": 27813,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 4,
    "Net Demand": 28966,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 5,
    "Net Demand": 28492,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 6,
    "Net Demand": 25005,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 7,
    "Net Demand": 23722,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 8,
    "Net Demand": 23719,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 9,
    "Net Demand": 26602,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 10,
    "Net Demand": 25654,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 11,
    "Net Demand": 29397,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 12,
    "Net Demand": 29896,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 13,
    "Net Demand": 29174,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 14,
    "Net Demand": 29622,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 15,
    "Net Demand": 30924,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 16,
    "Net Demand": 31894,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 17,
    "Net Demand": 31895,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 18,
    "Net Demand": 31349,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 19,
    "Net Demand": 33041,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 20,
    "Net Demand": 33884,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 21,
    "Net Demand": 29858,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 22,
    "Net Demand": 28158,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 23,
    "Net Demand": 31963,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 14,
    Hour: 24,
    "Net Demand": 32869,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 1,
    "Net Demand": 31910,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 2,
    "Net Demand": 32676,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 3,
    "Net Demand": 32444,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 4,
    "Net Demand": 31664,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 5,
    "Net Demand": 33475,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 6,
    "Net Demand": 33229,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 7,
    "Net Demand": 33152,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 8,
    "Net Demand": 32985,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 9,
    "Net Demand": 29587,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 10,
    "Net Demand": 27713,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 11,
    "Net Demand": 28565,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 12,
    "Net Demand": 21569,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 13,
    "Net Demand": 20513,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 14,
    "Net Demand": 21122,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 15,
    "Net Demand": 22870,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 16,
    "Net Demand": 25208,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 17,
    "Net Demand": 27329,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 18,
    "Net Demand": 32011,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 19,
    "Net Demand": 31574,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 20,
    "Net Demand": 33320,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 21,
    "Net Demand": 33426,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 22,
    "Net Demand": 32981,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 23,
    "Net Demand": 33074,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 15,
    Hour: 24,
    "Net Demand": 34922,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 1,
    "Net Demand": 36580,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 2,
    "Net Demand": 37357,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 3,
    "Net Demand": 33067,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 4,
    "Net Demand": 33131,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 5,
    "Net Demand": 33035,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 6,
    "Net Demand": 34061,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 7,
    "Net Demand": 31924,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 8,
    "Net Demand": 33058,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 9,
    "Net Demand": 31626,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 10,
    "Net Demand": 31279,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 11,
    "Net Demand": 29804,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 12,
    "Net Demand": 31463,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 13,
    "Net Demand": 30358,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 14,
    "Net Demand": 29958,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 15,
    "Net Demand": 29297,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 16,
    "Net Demand": 30108,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 17,
    "Net Demand": 30295,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 18,
    "Net Demand": 32042,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 19,
    "Net Demand": 30626,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 20,
    "Net Demand": 33293,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 21,
    "Net Demand": 34980,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 22,
    "Net Demand": 35480,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 23,
    "Net Demand": 33707,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 16,
    Hour: 24,
    "Net Demand": 33171,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 1,
    "Net Demand": 33080,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 2,
    "Net Demand": 33249,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 3,
    "Net Demand": 32387,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 4,
    "Net Demand": 33407,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 5,
    "Net Demand": 33157,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 6,
    "Net Demand": 33508,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 7,
    "Net Demand": 33098,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 8,
    "Net Demand": 32660,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 9,
    "Net Demand": 30679,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 10,
    "Net Demand": 30522,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 11,
    "Net Demand": 29270,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 12,
    "Net Demand": 28758,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 13,
    "Net Demand": 28494,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 14,
    "Net Demand": 29373,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 15,
    "Net Demand": 29697,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 16,
    "Net Demand": 30938,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 17,
    "Net Demand": 31876,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 18,
    "Net Demand": 31713,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 19,
    "Net Demand": 34010,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 20,
    "Net Demand": 34787,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 21,
    "Net Demand": 34462,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 22,
    "Net Demand": 33767,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 23,
    "Net Demand": 34397,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 17,
    Hour: 24,
    "Net Demand": 34626,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 1,
    "Net Demand": 33703,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 2,
    "Net Demand": 33733,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 3,
    "Net Demand": 32724,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 4,
    "Net Demand": 33370,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 5,
    "Net Demand": 34075,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 6,
    "Net Demand": 33004,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 7,
    "Net Demand": 31806,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 8,
    "Net Demand": 31507,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 9,
    "Net Demand": 30858,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 10,
    "Net Demand": 30027,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 11,
    "Net Demand": 28953,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 12,
    "Net Demand": 29351,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 13,
    "Net Demand": 25963,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 14,
    "Net Demand": 29297,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 15,
    "Net Demand": 29694,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 16,
    "Net Demand": 31443,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 17,
    "Net Demand": 31458,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 18,
    "Net Demand": 32600,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 19,
    "Net Demand": 34031,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 20,
    "Net Demand": 35342,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 21,
    "Net Demand": 34278,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 22,
    "Net Demand": 34020,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 23,
    "Net Demand": 34504,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 18,
    Hour: 24,
    "Net Demand": 33982,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 1,
    "Net Demand": 34855,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 2,
    "Net Demand": 35588,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 3,
    "Net Demand": 33999,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 4,
    "Net Demand": 31406,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 5,
    "Net Demand": 32104,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 6,
    "Net Demand": 30890,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 7,
    "Net Demand": 29795,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 8,
    "Net Demand": 32749,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 9,
    "Net Demand": 32596,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 10,
    "Net Demand": 30832,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 11,
    "Net Demand": 29064,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 12,
    "Net Demand": 28918,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 13,
    "Net Demand": 29472,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 14,
    "Net Demand": 30102,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 15,
    "Net Demand": 31029,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 16,
    "Net Demand": 30522,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 17,
    "Net Demand": 30713,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 18,
    "Net Demand": 33934,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 19,
    "Net Demand": 33218,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 20,
    "Net Demand": 34041,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 21,
    "Net Demand": 34236,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 22,
    "Net Demand": 34236,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 23,
    "Net Demand": 34762,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 19,
    Hour: 24,
    "Net Demand": 34199,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 1,
    "Net Demand": 27076,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 2,
    "Net Demand": 26708,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 3,
    "Net Demand": 27274,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 4,
    "Net Demand": 33392,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 5,
    "Net Demand": 33163,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 6,
    "Net Demand": 33488,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 7,
    "Net Demand": 33271,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 8,
    "Net Demand": 33546,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 9,
    "Net Demand": 33725,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 10,
    "Net Demand": 30138,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 11,
    "Net Demand": 27017,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 12,
    "Net Demand": 25778,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 13,
    "Net Demand": 25737,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 14,
    "Net Demand": 25367,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 15,
    "Net Demand": 24238,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 16,
    "Net Demand": 25252,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 17,
    "Net Demand": 24011,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 18,
    "Net Demand": 23417,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 19,
    "Net Demand": 23118,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 20,
    "Net Demand": 30545,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 21,
    "Net Demand": 33709,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 22,
    "Net Demand": 33330,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 23,
    "Net Demand": 33799,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 20,
    Hour: 24,
    "Net Demand": 35424,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 1,
    "Net Demand": 34159,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 2,
    "Net Demand": 35034,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 3,
    "Net Demand": 34507,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 4,
    "Net Demand": 33592,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 5,
    "Net Demand": 34291,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 6,
    "Net Demand": 35142,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 7,
    "Net Demand": 34867,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 8,
    "Net Demand": 33440,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 9,
    "Net Demand": 32177,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 10,
    "Net Demand": 28263,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 11,
    "Net Demand": 25683,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 12,
    "Net Demand": 24192,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 13,
    "Net Demand": 23516,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 14,
    "Net Demand": 24833,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 15,
    "Net Demand": 29534,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 16,
    "Net Demand": 31616,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 17,
    "Net Demand": 32147,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 18,
    "Net Demand": 32159,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 19,
    "Net Demand": 32626,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 20,
    "Net Demand": 32860,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 21,
    "Net Demand": 33740,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 22,
    "Net Demand": 34165,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 23,
    "Net Demand": 34013,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 21,
    Hour: 24,
    "Net Demand": 33446,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 1,
    "Net Demand": 32838,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 2,
    "Net Demand": 34209,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 3,
    "Net Demand": 33333,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 4,
    "Net Demand": 33295,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 5,
    "Net Demand": 26871,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 6,
    "Net Demand": 25950,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 7,
    "Net Demand": 27061,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 8,
    "Net Demand": 26860,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 9,
    "Net Demand": 27040,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 10,
    "Net Demand": 25087,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 11,
    "Net Demand": 22302,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 12,
    "Net Demand": 23298,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 13,
    "Net Demand": 20125,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 14,
    "Net Demand": 22559,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 15,
    "Net Demand": 25149,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 16,
    "Net Demand": 21110,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 17,
    "Net Demand": 21974,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 18,
    "Net Demand": 26116,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 19,
    "Net Demand": 26545,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 20,
    "Net Demand": 27214,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 21,
    "Net Demand": 24644,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 22,
    "Net Demand": 19770,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 23,
    "Net Demand": 26222,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 22,
    Hour: 24,
    "Net Demand": 27339,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 1,
    "Net Demand": 23476,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 2,
    "Net Demand": 23360,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 3,
    "Net Demand": 26669,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 4,
    "Net Demand": 27009,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 5,
    "Net Demand": 21000,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 6,
    "Net Demand": 19766,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 7,
    "Net Demand": 25415,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 8,
    "Net Demand": 26064,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 9,
    "Net Demand": 25079,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 10,
    "Net Demand": 20832,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 11,
    "Net Demand": 15294,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 12,
    "Net Demand": 16084,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 13,
    "Net Demand": 21705,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 14,
    "Net Demand": 22203,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 15,
    "Net Demand": 22128,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 16,
    "Net Demand": 22828,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 17,
    "Net Demand": 25004,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 18,
    "Net Demand": 26032,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 19,
    "Net Demand": 26607,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 20,
    "Net Demand": 28004,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 21,
    "Net Demand": 27304,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 22,
    "Net Demand": 26579,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 23,
    "Net Demand": 26913,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 23,
    Hour: 24,
    "Net Demand": 26520,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 1,
    "Net Demand": 26510,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 2,
    "Net Demand": 25943,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 3,
    "Net Demand": 25834,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 4,
    "Net Demand": 25968,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 5,
    "Net Demand": 26483,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 6,
    "Net Demand": 26317,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 7,
    "Net Demand": 25878,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 8,
    "Net Demand": 25960,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 9,
    "Net Demand": 25507,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 10,
    "Net Demand": 23943,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 11,
    "Net Demand": 22151,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 12,
    "Net Demand": 22830,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 13,
    "Net Demand": 23132,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 14,
    "Net Demand": 23272,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 15,
    "Net Demand": 24392,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 16,
    "Net Demand": 24042,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 17,
    "Net Demand": 24280,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 18,
    "Net Demand": 25481,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 19,
    "Net Demand": 26071,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 20,
    "Net Demand": 27333,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 21,
    "Net Demand": 27584,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 22,
    "Net Demand": 26903,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 23,
    "Net Demand": 27045,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 24,
    Hour: 24,
    "Net Demand": 27336,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 1,
    "Net Demand": 27883,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 2,
    "Net Demand": 27414,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 3,
    "Net Demand": 26160,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 4,
    "Net Demand": 26751,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 5,
    "Net Demand": 26763,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 6,
    "Net Demand": 28066,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 7,
    "Net Demand": 27708,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 8,
    "Net Demand": 25360,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 9,
    "Net Demand": 26909,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 10,
    "Net Demand": 25953,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 11,
    "Net Demand": 22430,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 12,
    "Net Demand": 25595,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 13,
    "Net Demand": 27623,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 14,
    "Net Demand": 29919,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 15,
    "Net Demand": 31699,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 16,
    "Net Demand": 32630,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 17,
    "Net Demand": 34858,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 18,
    "Net Demand": 32232,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 19,
    "Net Demand": 31536,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 20,
    "Net Demand": 33675,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 21,
    "Net Demand": 33938,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 22,
    "Net Demand": 33764,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 23,
    "Net Demand": 34783,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 25,
    Hour: 24,
    "Net Demand": 32369,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 1,
    "Net Demand": 28152,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 2,
    "Net Demand": 28465,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 3,
    "Net Demand": 29240,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 4,
    "Net Demand": 33668,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 5,
    "Net Demand": 32889,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 6,
    "Net Demand": 32870,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 7,
    "Net Demand": 32456,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 8,
    "Net Demand": 32946,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 9,
    "Net Demand": 31464,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 10,
    "Net Demand": 31731,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 11,
    "Net Demand": 29894,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 12,
    "Net Demand": 29906,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 13,
    "Net Demand": 29702,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 14,
    "Net Demand": 29113,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 15,
    "Net Demand": 23887,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 16,
    "Net Demand": 24905,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 17,
    "Net Demand": 25856,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 18,
    "Net Demand": 26712,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 19,
    "Net Demand": 26562,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 20,
    "Net Demand": 32356,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 21,
    "Net Demand": 32932,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 22,
    "Net Demand": 32672,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 23,
    "Net Demand": 33297,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 26,
    Hour: 24,
    "Net Demand": 34258,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 1,
    "Net Demand": 35301,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 2,
    "Net Demand": 34896,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 3,
    "Net Demand": 32702,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 4,
    "Net Demand": 33364,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 5,
    "Net Demand": 33257,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 6,
    "Net Demand": 33337,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 7,
    "Net Demand": 33275,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 8,
    "Net Demand": 32647,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 9,
    "Net Demand": 32765,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 10,
    "Net Demand": 31498,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 11,
    "Net Demand": 30148,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 12,
    "Net Demand": 31398,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 13,
    "Net Demand": 31497,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 14,
    "Net Demand": 31059,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 15,
    "Net Demand": 30004,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 16,
    "Net Demand": 33282,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 17,
    "Net Demand": 34337,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 18,
    "Net Demand": 33051,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 19,
    "Net Demand": 34172,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 20,
    "Net Demand": 33710,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 21,
    "Net Demand": 33812,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 22,
    "Net Demand": 34457,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 23,
    "Net Demand": 34587,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 27,
    Hour: 24,
    "Net Demand": 34692,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 1,
    "Net Demand": 34064,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 2,
    "Net Demand": 33805,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 3,
    "Net Demand": 34480,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 4,
    "Net Demand": 33156,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 5,
    "Net Demand": 33749,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 6,
    "Net Demand": 33933,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 7,
    "Net Demand": 34393,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 8,
    "Net Demand": 33526,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 9,
    "Net Demand": 33289,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 10,
    "Net Demand": 31651,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 11,
    "Net Demand": 30872,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 12,
    "Net Demand": 27369,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 13,
    "Net Demand": 26467,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 14,
    "Net Demand": 31483,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 15,
    "Net Demand": 31140,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 16,
    "Net Demand": 33271,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 17,
    "Net Demand": 34331,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 18,
    "Net Demand": 32602,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 19,
    "Net Demand": 33462,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 20,
    "Net Demand": 33236,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 21,
    "Net Demand": 33954,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 22,
    "Net Demand": 33693,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 23,
    "Net Demand": 34159,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 28,
    Hour: 24,
    "Net Demand": 34017,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 1,
    "Net Demand": 34696,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 2,
    "Net Demand": 33770,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 3,
    "Net Demand": 30812,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 4,
    "Net Demand": 27721,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 5,
    "Net Demand": 27714,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 6,
    "Net Demand": 28551,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 7,
    "Net Demand": 30067,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 8,
    "Net Demand": 31036,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 9,
    "Net Demand": 32329,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 10,
    "Net Demand": 30195,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 11,
    "Net Demand": 29490,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 12,
    "Net Demand": 30347,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 13,
    "Net Demand": 26756,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 14,
    "Net Demand": 29074,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 15,
    "Net Demand": 30807,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 16,
    "Net Demand": 33115,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 17,
    "Net Demand": 29835,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 18,
    "Net Demand": 29058,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 19,
    "Net Demand": 30039,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 20,
    "Net Demand": 31056,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 21,
    "Net Demand": 34031,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 22,
    "Net Demand": 33269,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 23,
    "Net Demand": 31728,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 29,
    Hour: 24,
    "Net Demand": 34128,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 1,
    "Net Demand": 33889,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 2,
    "Net Demand": 34034,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 3,
    "Net Demand": 34552,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 4,
    "Net Demand": 34095,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 5,
    "Net Demand": 33849,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 6,
    "Net Demand": 32836,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 7,
    "Net Demand": 31835,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 8,
    "Net Demand": 31363,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 9,
    "Net Demand": 28869,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 10,
    "Net Demand": 27526,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 11,
    "Net Demand": 27367,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 12,
    "Net Demand": 26196,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 13,
    "Net Demand": 27485,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 14,
    "Net Demand": 27073,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 15,
    "Net Demand": 27834,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 16,
    "Net Demand": 28948,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 17,
    "Net Demand": 30389,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 18,
    "Net Demand": 30786,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 19,
    "Net Demand": 30639,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 20,
    "Net Demand": 33607,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 21,
    "Net Demand": 35388,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 22,
    "Net Demand": 35131,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 23,
    "Net Demand": 35023,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 30,
    Hour: 24,
    "Net Demand": 35196,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 1,
    "Net Demand": 36099,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 2,
    "Net Demand": 32386,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 3,
    "Net Demand": 16281,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 4,
    "Net Demand": 32133,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 5,
    "Net Demand": 33157,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 6,
    "Net Demand": 32515,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 7,
    "Net Demand": 32765,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 8,
    "Net Demand": 29369,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 9,
    "Net Demand": 29049,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 10,
    "Net Demand": 28640,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 11,
    "Net Demand": 28759,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 12,
    "Net Demand": 27656,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 13,
    "Net Demand": 28117,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 14,
    "Net Demand": 28375,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 15,
    "Net Demand": 29602,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 16,
    "Net Demand": 29783,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 17,
    "Net Demand": 31029,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 18,
    "Net Demand": 33132,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 19,
    "Net Demand": 33421,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 20,
    "Net Demand": 33721,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 21,
    "Net Demand": 33669,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 22,
    "Net Demand": 29442,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 23,
    "Net Demand": 32349,
  },
  {
    Year: 2024,
    Month: 3,
    Day: 31,
    Hour: 24,
    "Net Demand": 33872,
  },
];
