function generateStatusActionTypes(baseTypes) {
  const actionTypes = {};
  for (const baseType of baseTypes) {
    actionTypes[`${baseType}_INIT`] = `${baseType}_INIT`;
    actionTypes[`${baseType}_BEGIN`] = `${baseType}_BEGIN`;
    actionTypes[`${baseType}_DONE`] = `${baseType}_DONE`;
    actionTypes[`${baseType}_FAIL`] = `${baseType}_FAIL`;
  }
  return actionTypes;
}

function generateNoStatusActionTypes(baseTypes) {
  const actionTypes = {};
  for (const baseType of baseTypes) {
    actionTypes[baseType] = baseType;
  }
  return actionTypes;
}

export default {
  ...generateNoStatusActionTypes([
    "AUTH_LOGOUT",
    "SET_FORM",
    "RESET_FORM",
    "SET_FILE",
    "RESET_FILE",
    "SET_FORM_3_1",
    "RESET_FORM_3_1",
    "DELETE_TOD_ROW",
    "ADD_TOD_ROW",
  ]),
  ...generateStatusActionTypes([
    "AUTH_LOGIN",
    "EVALUATION_RESULTS_PDF",
    "EVALUATION_RESULTS",
    "GET_CLIENTS",
    "GET_CLIENT_REPORTS",
    "FETCH_MANAGER_DATA",
    "FETCH_CLIENT_DATA",
    "UPLOAD_FILE",
    "GOGREEN_EVALUATION",
    "EVALUATION_OUTPUT",
    "OE_UPLOAD_PDF"
  ]),
};
