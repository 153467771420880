import loadable from "@loadable/component";

// Load components dynamically
export const LoadableHome = loadable(() => import("./pages/Home"));
export const LoadableNewClient = loadable(() => import("./pages/NewClient"));
export const LoadableLogin = loadable(() => import("./pages/Login"));
export const LoadableForgotPassword = loadable(() =>
  import("./pages/ForgotPassword")
);
export const LoadableEvaluation = loadable(() =>
  import("./pages/Evaluation")
);

export const LoadableEvaluationOutput = loadable(() =>
  import("./pages/EvaluationOutput")
);

export const LoadableExistingClients = loadable(() =>
  import("./pages/ExistingClients")
);

export const LoadableClientReports = loadable(() =>
  import("./pages/ClientReports")
);