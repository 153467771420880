import stateTypes from "../../constants/stateTypes";
import actionTypes from "../actionTypes";

function getInitialState(keys) {
  const initialState = {};
  for (const key of keys) {
    initialState[key] = {
      state: stateTypes.STATE_INIT,
      error: null,
    };
  }
  return initialState;
}

const initialState = getInitialState(["loginStatus", "evaluationResultsPdf"]);

function init(state, key) {
  return {
    ...state,
    [key]: {
      state: stateTypes.STATE_INIT,
      error: null,
    },
  };
}

function begin(state, key) {
  return {
    ...state,
    [key]: {
      state: stateTypes.STATE_BEGIN,
      error: null,
    },
  };
}

function done(state, key) {
  return {
    ...state,
    [key]: {
      state: stateTypes.STATE_DONE,
      error: null,
    },
  };
}

function fail(state, key, error) {
  error = error || "Something went wrong!";
  return {
    ...state,
    [key]: {
      state: stateTypes.STATE_FAIL,
      error: error,
    },
  };
}

function statusReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN_BEGIN:
      return begin(state, "loginStatus");
    case actionTypes.AUTH_LOGIN_DONE:
      return done(state, "loginStatus");
    case actionTypes.AUTH_LOGIN_FAIL:
      return fail(state, "loginStatus", action.error?.message);
    case actionTypes.AUTH_LOGIN_INIT:
      return init(state, "loginStatus");

    case actionTypes.EVALUATION_RESULTS_PDF_BEGIN:
      return begin(state, "evaluationResultsPdf");
    case actionTypes.EVALUATION_RESULTS_PDF_DONE:
      return done(state, "evaluationResultsPdf");
    case actionTypes.EVALUATION_RESULTS_PDF_FAIL:
      return fail(state, "evaluationResultsPdf", action.error?.message);
    case actionTypes.EVALUATION_RESULTS_PDF_INIT:
      return init(state, "evaluationResultsPdf");

    case actionTypes.EVALUATION_RESULTS_BEGIN:
      return begin(state, "evaluationResults");
    case actionTypes.EVALUATION_RESULTS_DONE:
      return done(state, "evaluationResults");
    case actionTypes.EVALUATION_RESULTS_FAIL:
      return fail(state, "evaluationResults", action.error?.message);
    case actionTypes.EVALUATION_RESULTS_INIT:
      return init(state, "evaluationResults");

    case actionTypes.GET_CLIENTS_BEGIN:
      return begin(state, "get_clients");
    case actionTypes.GET_CLIENTS_DONE:
      return done(state, "get_clients");
    case actionTypes.GET_CLIENTS_FAIL:
      return fail(state, "get_clients", action.error?.message);
    case actionTypes.GET_CLIENTS_INIT:
      return init(state, "get_clients");
    
    case actionTypes.GET_CLIENT_REPORTS_BEGIN:
      return begin(state, "get_client_reports");
    case actionTypes.GET_CLIENT_REPORTS_DONE:
      return done(state, "get_client_reports");
    case actionTypes.GET_CLIENT_REPORTS_FAIL:
      return fail(state, "get_client_reports", action.error?.message);
    case actionTypes.GET_CLIENT_REPORTS_INIT:
      return init(state, "get_client_reports");

    case actionTypes.EVALUATION_OUTPUT_BEGIN:
      return begin(state, "evaluation_output");
    case actionTypes.EVALUATION_OUTPUT_DONE:
      return done(state, "evaluation_output");
    case actionTypes.EVALUATION_OUTPUT_FAIL:
      return fail(state, "evaluation_output", action.error?.message);
    case actionTypes.EVALUATION_OUTPUT_INIT:
      return init(state, "evaluation_output");

    case actionTypes.OE_UPLOAD_PDF_BEGIN:
      return begin(state, "oe_upload_pdf");
    case actionTypes.OE_UPLOAD_PDF_DONE:
      return done(state, "oe_upload_pdf");
    case actionTypes.OE_UPLOAD_PDF_FAIL:
      return fail(state, "oe_upload_pdf", action.error?.message);
    case actionTypes.OE_UPLOAD_PDF_INIT:
      return init(state, "oe_upload_pdf");

    default:
      return state;
  }
}

export default statusReducer;
