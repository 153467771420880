import React from "react";
import { Provider } from "react-redux";
import RouteHandler from "./RouteHandler";
import configureStore from "./redux/store";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackToTop from "./components/BackToTop";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <RouteHandler />
      <BackToTop />
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={false}
        transition={Flip}
      />
    </Provider>
  );
}

export default App;
