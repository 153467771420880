import axios from "axios";
import { clearUserDataWithToken } from "./helperFunctions";

function createAxios(auth, baseURL) {
  const headers = {};

  if (auth) {
    const token = localStorage.getItem("authToken");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  }

  const instance = axios.create({
    baseURL,

    headers,
    params: {
      code: decodeURIComponent(process.env.REACT_APP_BASE_URL_CODE),
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        clearUserDataWithToken();
        setTimeout(() => {
          if (!error.request.responseURL.includes("managers-login"))
            window.location.href = "/login";
        }, 1000);
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

function getAxios(auth = false, baseUrl = "") {
  return createAxios(auth, baseUrl);
}

export default getAxios;
