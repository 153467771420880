import Papa from "papaparse";

export const storeUserDataWithToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const clearUserDataWithToken = () => {
  localStorage.removeItem("authToken");
};

export const getUserDataWithToken = () => {
  const token = localStorage.getItem("authToken");
  return { token };
};

export const parseCSVFile = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length > 0) {
          reject("Error processing the CSV file.");
        } else {
          const parsedData = result.data.map((row) => {
            const mappedRow = {};
            for (const key in row) {
              const formattedKey = key.trim();
              mappedRow[formattedKey] = isNaN(row[key])
                ? row[key]
                : parseFloat(row[key]);
            }
            return mappedRow;
          });
          resolve(parsedData);
        }
      },
      error: (error) => reject(error.message),
    });
  });
};

export const handleFileChange = async (
  event,
  formName,
  apiFileTypeKey,
  fileTypeKey,
  fileDataKey,
  mId,
  cId,
  dispatchActions,
  fileType = "csv",
  maxFileSize = 50 * 1024 * 1024
) => {
  const file = event.target.files[0];

  if (!file) return;

  if (file.type !== `text/${fileType}`) {
    alert(`Invalid file type. Please upload a ${fileType.toUpperCase()} file.`);
    event.target.value = "";
    return;
  }

  if (file.size > maxFileSize) {
    alert(
      `File size exceeds the ${
        maxFileSize / (1024 * 1024)
      } MB limit. Please upload a smaller file.`
    );
    event.target.value = "";
    return;
  }
  const clientId = cId;
  const managerId = mId;

  await dispatchActions.uploadFileToBlob(
    event,
    formName,
    file,
    apiFileTypeKey,
    fileTypeKey,
    fileDataKey,
    clientId,
    managerId
  );
};
