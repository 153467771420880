import { combineReducers } from "redux";
import statusReducer from "./statusReducer";
import authReducer from "./authReducer";
import formReducer from "./formReducer";
import evaluationReducer from "./evaluationReducer";
import clientReducer from "./clientReducer";

const rootReducer = combineReducers({
  statusReducer: statusReducer,
  authReducer: authReducer,
  formReducer: formReducer,
  evaluationReducer: evaluationReducer,
  clientReducer: clientReducer,
});

export default rootReducer;
