import React from "react";

function Loader() {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-10 z-50">
      <div className="w-16 h-16 border-t-4 border-green-500 border-solid rounded-full animate-spin"></div>
    </div>
  );
}

export default Loader;
