import getAxios from "../../utils/axios";
import actionTypes from "../actionTypes";
import _get from "lodash-es/get";
import { toast } from "react-toastify";
import {
  storeUserDataWithToken,
  clearUserDataWithToken,
} from "../../utils/helperFunctions";

export function loginUser(credentials) {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTH_LOGIN_BEGIN });

    getAxios(false)
      .post(
        process.env.REACT_APP_BASE_URL + "/api/ergos-managers-login",
        credentials
      )
      .then(({ data }) => {
        const { token_data, name, email, manager_id, msg } = data;
        storeUserDataWithToken(token_data.token);
        dispatch({
          type: actionTypes.AUTH_LOGIN_DONE,
          payload: {
            name: name,
            email: email,
            id: manager_id,
          },
        });
        toast.success(msg || "Login Success");
      })
      .catch((err) => {
        const errorMsg = _get(
          err,
          ["response", "data", "msg"],
          _get(err, "message", "Something went wrong!")
        );
        dispatch({
          type: actionTypes.AUTH_LOGIN_FAIL,
          error: errorMsg,
        });
        toast.error(errorMsg);
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    clearUserDataWithToken();
    dispatch({ type: actionTypes.AUTH_LOGOUT });
    toast.success("Logged out successfully");
  };
}

export function fetchManagerData() {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MANAGER_DATA_BEGIN });

    getAxios(true)
      .get(process.env.REACT_APP_BASE_URL + "/api/ergos-get-managers-info")
      .then(({ data }) => {
        const { name, email, id } = data.response_data[0];
        dispatch({
          type: actionTypes.FETCH_MANAGER_DATA_DONE,
          payload: {
            name: name,
            email: email,
            id: id,
          },
        });
      })
      .catch((err) => {
        const errorMsg = _get(
          err,
          ["response", "data", "msg"],
          _get(err, "message", "Something went wrong!")
        );
        dispatch({
          type: actionTypes.FETCH_MANAGER_DATA_FAIL,
          error: errorMsg,
        });
        toast.error(errorMsg || "Failed to fetch user data.");
      });
  };
}
