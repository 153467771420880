import actionTypes from "../actionTypes";

function clientReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.GET_CLIENT_REPORTS_DONE:
      return {
        ...state,
        clientReports: action.payload,
      };

    case actionTypes.GET_CLIENTS_DONE:
      return {
        ...state,
        clientData: action.payload
      };

    default:
      return state;
  }
}

export default clientReducer;
