import {
  EVALUATION_STEP_1_FORM,
  EVALUATION_STEP_2_FORM,
  NEW_CLIENT_FORM,
  EVALUATION_STEP_3_1_FORM,
  EVALUATION_STEP_3_2_FORM,
  EVALUATION_STEP_3_3_FORM,
  EVALUATION_STEP_3_4_FORM,
  EVALUATION_STEP_3_5_FORM,
  EVALUATION_STEP_4_0_FORM,
  EVALUATION_STEP_4_1_FORM,
  EVALUATION_STEP_4_2_FORM,
  EVALUATION_STEP_4_3_FORM,
  EVALUATION_STEP_4_4_FORM,
  EVALUATION_STEP_4_5_FORM,
} from "../../utils/constants";
import actionTypes from "../actionTypes";

const initialRow = {
  openingHours: "",
  closingHours: "",
  peakOffPeak: "",
  tariff: "",
};

let intialState = {
  [EVALUATION_STEP_1_FORM]: {
    clientId: "",
    clientName: "",
    unitName: "",
    state: "",
    city: "",
    substationName: "",
    lat: "",
    long: "",
    connectionLoad: "",
    ctPtRatio: "",
  },
  [EVALUATION_STEP_2_FORM]: {
    checkboxGrid: false,
    checkboxSolar: false,
    checkboxWind: false,
    checkboxIntraStateBanking: false,
  },
  [EVALUATION_STEP_3_1_FORM]: {
    rows: [initialRow],
  },
  [EVALUATION_STEP_3_2_FORM]: {
    banking_settlement_rule: "",
    banking_charges: "",
    banking_limit: "",
  },
  [EVALUATION_STEP_3_3_FORM]: {
    solar_power_injection_state: "",
    solar_capacity: "",
    solar_file: null,
    solar_file_data: [],
  },
  [EVALUATION_STEP_3_4_FORM]: {
    wind_power_injection_state: "",
    wind_capacity: "",
    wind_file: null,
    wind_file_data: [],
  },
  [EVALUATION_STEP_3_5_FORM]: {
    grid_historic_file: null,
    grid_historic_file_data: [],
  },
  [EVALUATION_STEP_4_0_FORM]: {
    checkboxBattery4: false,
    checkboxSolar4: false,
    checkboxWind4: false,
    checkboxPowerExchange4: false,
  },
  [EVALUATION_STEP_4_1_FORM]: {
    solar_plant_location: "",
    solar_pv_profile_file: null,
    solar_pv_profile_file_data: [],
    solar_energy_price_landed: "",
    solar_inefficiency: "",
  },
  [EVALUATION_STEP_4_2_FORM]: {
    wind_plant_location: "",
    wind_generation_profile_file: null,
    wind_generation_profile_file_data: [],
    wind_energy_price_landed: "",
    wind_inefficiency: "",
  },
  [EVALUATION_STEP_4_3_FORM]: {
    battery_minimum_state_of_charge: "",
    initial_state_of_charge: "",
    round_trip_efficiency: "",
    energy_cost_of_per_kwh_battery: "",
    number_of_cycles: "",
    power_capacity_cost: "",
  },
  [EVALUATION_STEP_4_4_FORM]: {
    power_exchange_price_forecast_file: null,
    power_exchange_price_forecast_file_data: [],
    allow_solar_to_sell_on_px: "",
    allow_wind_to_sell_on_px: "",
    allow_battery_to_sell_on_px: "",
  },
  [EVALUATION_STEP_4_5_FORM]: {
    power_exchange_purchase_price_forecast_file: null,
    power_exchange_purchase_price_forecast_file_data: [],
    allow_purchase_from_px: "",
  },
  [NEW_CLIENT_FORM]: {
    clientName: "",
    clientContactNumber: "",
    clientEmail: "",
    clientKeyContactPerson: "",
    clientCountry: "",
    clientId: "",
  },
  isLoading: false,
};

function formReducer(state = intialState, action) {
  switch (action.type) {
    case actionTypes.SET_FORM:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload?.form],
          [action.payload?.field]: action.payload?.value,
        },
      };
    case actionTypes.RESET_FORM:
      return {
        ...state,
        [action.payload.form]: intialState[action.payload.form],
      };
    case actionTypes.SET_FILE:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          [action.payload.field]: action.payload.file,
        },
      };
    case actionTypes.RESET_FILE:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          [action.payload.field]: null,
        },
      };
    case actionTypes.ADD_TOD_ROW:
      return {
        ...state,
        [EVALUATION_STEP_3_1_FORM]: {
          ...state[EVALUATION_STEP_3_1_FORM],
          rows: [
            ...state[EVALUATION_STEP_3_1_FORM].rows,
            {
              openingHours: "",
              closingHours: "",
              peakOffPeak: "",
              tariff: "",
            },
          ],
        },
      };
    case actionTypes.DELETE_TOD_ROW:
      const updatedRows = state[EVALUATION_STEP_3_1_FORM].rows
        .filter((_, index) => index !== action.payload.index)
        .map((row, index) => ({
          ...row,
          name: `TOD ${index + 1}`,
        }));

      return {
        ...state,
        [EVALUATION_STEP_3_1_FORM]: {
          ...state[EVALUATION_STEP_3_1_FORM],
          rows: updatedRows,
        },
      };
    case actionTypes.SET_FORM_3_1:
      return {
        ...state,
        [EVALUATION_STEP_3_1_FORM]: {
          ...state[EVALUATION_STEP_3_1_FORM],
          rows: state[EVALUATION_STEP_3_1_FORM].rows.map((row, index) =>
            index === action.payload.index
              ? { ...row, [action.payload.field]: action.payload.value }
              : row
          ),
        },
      };
    case actionTypes.RESET_FORM_3_1:
      return {
        ...state,
        [EVALUATION_STEP_3_1_FORM]: {
          ...state[EVALUATION_STEP_3_1_FORM],
          rows: [initialRow],
        },
      };
    case actionTypes.UPLOAD_FILE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPLOAD_FILE_DONE:
    case actionTypes.UPLOAD_FILE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default formReducer;
