import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[rgb(92,182,92)] text-white text-center py-3 px-2 fixed inset-x-0 bottom-0 z-20">
      <p>
        Contact System Administrator for training:{" "}
        <a className="text-green-800 font-bold underline">
          ergcon@joulewise.com
        </a>
      </p>
    </footer>
  );
};

export default Footer;
