import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  LoadableHome,
  LoadableLogin,
  LoadableNewClient,
  LoadableEvaluation,
  LoadableEvaluationOutput,
  LoadableExistingClients,
  LoadableClientReports,
} from "./Loadable";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import Header from "./components/Header";
import Footer from "./components/Footer";

function RouteHandler() {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  return (
    <Router>
      <Header />
      <div className="pb-16">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />

            <Route
              path="/home"
              element={
                isAuthenticated ? <LoadableHome /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/login"
              element={
                isAuthenticated ? <Navigate to="/" /> : <LoadableLogin />
              }
            />
            <Route
              path="/new-client"
              element={
                isAuthenticated ? (
                  <LoadableNewClient />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/evaluation/:id"
              element={
                isAuthenticated ? (
                  <LoadableEvaluation />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/evaluation-output/:id/:manufacturingId"
              element={
                isAuthenticated ? (
                  <LoadableEvaluationOutput />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/existing-clients"
              element={
                isAuthenticated ? (
                  <LoadableExistingClients />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/client-reports/:id"
              element={
                isAuthenticated ? (
                  <LoadableClientReports />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </Router>
  );
}

export default RouteHandler;
