import React, { useState, useRef, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  logoutUser,
  fetchManagerData,
} from "../redux/dispatchers/authDispatcher";
import { useNavigate } from "react-router-dom";
import endpoints from "../constants/endpoints";
import { withRouter } from "../utils/withRouter";

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const isAuthenticated = props.isAuthenticated;
  const userData = props.userData;

  useEffect(() => {
    if (isAuthenticated && !userData?.name) {
      props.actions.fetchManagerData();
    }
  }, [isAuthenticated, userData?.name, props.actions]);

  const handleLogout = () => {
    props.actions.logoutUser();
    navigate("/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goHome = () => {
    navigate("/");
  };

  return (
    <header
      className={`sticky top-0 z-50 shadow-md bg-white text-amber-900 p-4 flex justify-between items-center  sm:flex-row flex-col sm:gap-0 gap-y-2 ${
        isAuthenticated ? "block" : "hidden"
      }`}
    >
      <div className="text-lg font-bold">
        <a className="hover:text-gray-400 cursor-pointer">
          <img
            onClick={goHome}
            src={endpoints.logoUrl}
            alt="Company Logo"
            className="h-8"
          />
        </a>
      </div>

      <div className="relative" ref={dropdownRef}>
        <button
          onClick={toggleDropdown}
          className="flex items-center focus:outline-none"
        >
          <FaUserCircle size={28} />
          <span className="ml-2">{userData?.name || ""}</span>
          <svg
            className={` ml-1 w-4 h-4 transform ${
              dropdownOpen ? "rotate-180" : ""
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 12l-5-5h10l-5 5z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-56 p-3 bg-white text-black rounded-md shadow-lg z-10 border-2 border-green-700">
            <div className="px-4 py-2 text-sm border-b border-1 border-green-700">
              <div className="break-words font-medium text-base">
                {userData?.name || ""}
              </div>
              <div className="text-gray-600 break-all">
                {userData?.email || ""}
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="w-full px-4 py-2 text-md text-red-600 font-semibold text-center"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

const actions = {
  logoutUser,
  fetchManagerData,
};

export default withRouter(
  connect(
    (state) => ({
      isAuthenticated: state.authReducer.isAuthenticated,
      userData: state.authReducer.userData,
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(Header)
);
