import actionTypes from "../actionTypes";

const initialState = {
  evaluationData: null,
  gogreenEvaluationError: null,
  gogreenEvaluationLoading: false,
};

function evaluationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.EVALUATION_RESULTS_DONE:
      return {
        ...state,
        evaluationData: action.payload,
      };

    case actionTypes.GOGREEN_EVALUATION_BEGIN:
      return {
        ...state,
        gogreenEvaluationLoading: true,
        gogreenEvaluationError: null,
      };

    case actionTypes.GOGREEN_EVALUATION_DONE:
      return {
        ...state,
        gogreenEvaluationLoading: false,
        gogreenEvaluationData: action.payload,
      };
    case actionTypes.GOGREEN_EVALUATION_FAIL:
      return {
        ...state,
        gogreenEvaluationLoading: false,
        gogreenEvaluationError: action.error,
      };

    case actionTypes.EVALUATION_OUTPUT_DONE: 
      return {
        ...state,
        evaluationOutput: action.payload,
      }

    default:
      return state;
  }
}

export default evaluationReducer;
